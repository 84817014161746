import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { IEmailableTipsByType } from '../models/email';
import { ISMSRecipientsByType } from '../models/sms';

const PHONE_NUMBER_REGEXP: RegExp = /^\+(?:[0-9][ ]?){6,14}[0-9]$/;
const URI_REGEX: RegExp = /^http(s?):\/\/([^?#]*)(\?([^#]*))?(#(.*))?/;
const INTERNAL_LINK_REGEX: RegExp = /^\//; // To be extended if needed
const ALPHANUMERIC_REGEX: RegExp = /^[a-zA-Z0-9_]*$/;
const TIME_MANUAL_INPUT_REGEX: RegExp = /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/;
const MAX_LENGTH_INPUT = Number.MAX_SAFE_INTEGER;
const MIN_LENGTH_INPUT = 3;
const MAX_NUMBER = Number.MAX_SAFE_INTEGER;
const MIN_NUMBER = Number.MIN_SAFE_INTEGER;

export class NogginValidators {
  static phoneNumber(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    const RegExpInstance = new RegExp(PHONE_NUMBER_REGEXP);

    if (!RegExpInstance.test(control.value)) {
      return { phoneNumber: true };
    }

    return null;
  }

  static uri(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    const RegExpInstance = new RegExp(URI_REGEX);

    if (!RegExpInstance.test(control.value)) {
      return { uri: true };
    }

    return null;
  }

  static emailRecipients(control: AbstractControl): ValidationErrors | null {
    const recipients: IEmailableTipsByType = control.value;
    if (
      recipients &&
      ((recipients.to && recipients.to.length) ||
      (recipients.toFormulas && recipients.toFormulas.length))
    ) {
      return null;
    }

    return { recipients: true };
  }

  static internalLink(control: AbstractControl): ValidationErrors | null {
    if (!control.value) { return null; }
    const RegExpInstance = new RegExp(INTERNAL_LINK_REGEX);

    if (!RegExpInstance.test(control.value)) {
      return { link: true };
    }

    return null;
  }

  static messageRecipients(control: AbstractControl): ValidationErrors | null {
    const recipients: ISMSRecipientsByType = control.value;
    if (
      recipients &&
      ((recipients.to && recipients.to.length) ||
      (recipients.toFormulas && recipients.toFormulas.length))
    ) {
      return null;
    }

    return { recipients: true };
  }

  static emailTemplateSubjectValidator(control: AbstractControl): ValidationErrors | null {
    const subject = control.value;

    if (!subject && !subject.html) {
      return null;
    }

    if (subject.html === '') {
      return { emptySubject: true };
    }

    return null;
  }

  static alphanumericValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    const RegExpInstance = new RegExp(ALPHANUMERIC_REGEX);

    if (!RegExpInstance.test(control.value)) {
      return { alphanumeric: true };
    }

    return null;
  }

  static requiredFieldWithSubstitutionValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    if (!value || !value.html) {
      return { requiredSubstitutionFieldEmpty: true };
    }

    return null;
  }

  static manualTimeInputValidation(control: AbstractControl): ValidationErrors | null {
    if (!control.value) { return null; }
    const regExpInstance = new RegExp(TIME_MANUAL_INPUT_REGEX);

    if (!regExpInstance.test(control.value)) {
      return { timeIncorrect: true };
    }

    return null;
  }

  static maxLength(control: AbstractControl): ValidationErrors | null {
    const _validator = Validators.maxLength(MAX_LENGTH_INPUT);
    return _validator(control);
  }

  static minLength(control: AbstractControl): ValidationErrors | null {
    const _validator = Validators.minLength(MIN_LENGTH_INPUT);
    return _validator(control);
  }
  
  static maxNumber(control: AbstractControl): ValidationErrors | null {
    const _validator = Validators.max(MAX_NUMBER);
    return _validator(control);
  }
  
  static minNumber(control: AbstractControl): ValidationErrors | null {
    const _validator = Validators.min(MIN_NUMBER);
    return _validator(control);
   }

}
