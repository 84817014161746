import * as tslib_1 from "tslib";
import { CompositeNodeConverter } from './CompositeNodeConverter';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import { isEmpty } from 'lodash';
import { createConnection, createFormulaFromStringInterpolation, createSetVarNode, createSubProcessNode, createSwitchNode } from './utils/create-node-utils';
import { retrieveFileLocationProcessTips } from '../node-editors/shared/file-location/file-location-types';
import * as i0 from "@angular/core";
var NS = 'RetrieveFileConverterService';
var RetrieveFileConverterService = /** @class */ (function (_super) {
    tslib_1.__extends(RetrieveFileConverterService, _super);
    function RetrieveFileConverterService() {
        var _this = _super.call(this) || this;
        _this.type = 'processnode/logic/subprocess';
        _this.processNodeType = 'processnodetype/logic/subprocess';
        return _this;
    }
    RetrieveFileConverterService.prototype.convert = function (nodeData) {
        if (!this.isValid(nodeData)) {
            throw new InvalidWorkflowNodeDataError("Please correct invalid data on retrieve-file node [" + nodeData.fields.title + "]", nodeData);
        }
        var _a = nodeData.fields, locationTip = _a.locationTip, variableToSetKey = _a.variableToSetKey, filePath = _a.filePath, securityPolicy = _a.securityPolicy, locationType = _a.locationType;
        var vars = {
            'Location tip': locationTip || 'ARRAY()',
            'Location type': locationType,
            'Var key': variableToSetKey,
            'File path': createFormulaFromStringInterpolation(filePath),
            'Security policy': securityPolicy || 'app/security-policy/system-all-users-read-write-delete'
        };
        var setVarStatusNode = createSetVarNode(vars, nodeData.tip);
        var subprocessNode = createSubProcessNode(retrieveFileLocationProcessTips[locationType], vars);
        var doneConnectionForSetVarNode = createConnection('done', subprocessNode);
        setVarStatusNode.connections.push(doneConnectionForSetVarNode);
        var switchStatusNode = createSwitchNode({ name: 'VAR', args: ['Status'] }, ['fileError']);
        var doneConnectionForSubprocessNode = createConnection('done', switchStatusNode);
        subprocessNode.connections.push(doneConnectionForSubprocessNode);
        var nodesAndLink = {
            nodes: [
                setVarStatusNode,
                subprocessNode,
                switchStatusNode
            ],
            links: [
                doneConnectionForSetVarNode,
                doneConnectionForSubprocessNode
            ]
        };
        this.populateOutgoingLinks(nodeData).forEach(function (link) {
            switchStatusNode.connections.push(link);
            nodesAndLink.links.push(link);
        });
        return nodesAndLink;
    };
    RetrieveFileConverterService.prototype.isValid = function (nodeData) {
        return !isEmpty(nodeData.fields.title)
            && !isEmpty(nodeData.fields.filePath)
            && !isEmpty(nodeData.fields.locationType)
            && !isEmpty(nodeData.fields.variableToSetKey);
    };
    RetrieveFileConverterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RetrieveFileConverterService_Factory() { return new RetrieveFileConverterService(); }, token: RetrieveFileConverterService, providedIn: "root" });
    return RetrieveFileConverterService;
}(CompositeNodeConverter));
export { RetrieveFileConverterService };
