import * as tslib_1 from "tslib";
import { of, throwError } from 'rxjs';
import { catchError, flatMap, map, switchMap, takeUntil } from 'rxjs/operators';
import { PlaceholderSideSheetComponent } from './object-launch/placeholder-side-sheet/placeholder-side-sheet.component';
import { OBJECT_LAUNCH_OPERATION, REDIRECT_TYPES } from './object-launch';
import { ObjectTypeSideSheetComponent } from './object-launch/object-type-side-sheet/object-type-side-sheet.component';
import * as i0 from "@angular/core";
import * as i1 from "../side-sheet/side-sheet.service";
import * as i2 from "./get-object-and-form.service";
import * as i3 from "./object-launch-formulas.service";
import * as i4 from "./lifecycle-workflow-redirect.service";
import * as i5 from "../data/formula-multi.service";
import * as i6 from "./object-launch-default.service";
import * as i7 from "./well-known-types.service";
/**
 *
 * Dependency injecting the ObjectLaunchService within ObjectOpenSideSheetComponent, EditFormSideSheetComponent and well-known side sheet
 * will result in circular dependency. Use OBJECT_LAUNCH_SERVICE_TOKEN injection token.
 * Refer ObjectOpenSideSheetComponent for reference
 */
var ObjectLaunchService = /** @class */ (function () {
    function ObjectLaunchService(sideSheetService, getObjectAndFormService, objectLaunchFormulasService, lifecycleWorkflowRedirect, formulaMultiService, objectLaunchDefaultService, wellKnownType) {
        this.sideSheetService = sideSheetService;
        this.getObjectAndFormService = getObjectAndFormService;
        this.objectLaunchFormulasService = objectLaunchFormulasService;
        this.lifecycleWorkflowRedirect = lifecycleWorkflowRedirect;
        this.formulaMultiService = formulaMultiService;
        this.objectLaunchDefaultService = objectLaunchDefaultService;
        this.wellKnownType = wellKnownType;
    }
    ObjectLaunchService.prototype.pushSheet = function (params, cancel$) {
        var _this = this;
        // To prevent the placeholder from being pushed even if nothing is subscribing to `pushSheet`,
        // pushing the placeholder has been placed inside an observable
        var pushPlaceholderSheet = of(true)
            .pipe(map(function () { return _this.sideSheetService.push(PlaceholderSideSheetComponent).componentInstance; }));
        return pushPlaceholderSheet
            .pipe(switchMap(function (placeholder) {
            if (params.operation === OBJECT_LAUNCH_OPERATION.VIEW) {
                return _this.loadView(params, cancel$, placeholder);
            }
            return _this.loadCreateEdit(params, cancel$, placeholder);
        }));
    };
    ObjectLaunchService.prototype.replaceSheet = function (params, cancel$) {
        var _this = this;
        var pushPlaceholderSheet = of(true)
            .pipe(map(function () { return _this.sideSheetService.replace(PlaceholderSideSheetComponent).componentInstance; }));
        return pushPlaceholderSheet
            .pipe(switchMap(function (placeholder) {
            if (params.operation === OBJECT_LAUNCH_OPERATION.VIEW) {
                return _this.loadView(params, cancel$, placeholder);
            }
            return _this.loadCreateEdit(params, cancel$, placeholder);
        }));
    };
    /*
    * Act as a facade for side sheet service, so we don't have to inject it
    * when we are using the object-launch service
    */
    ObjectLaunchService.prototype.pop = function (useHistory) {
        if (useHistory === void 0) { useHistory = false; }
        this.sideSheetService.pop(useHistory);
    };
    ObjectLaunchService.prototype.cleanAndPop = function (useHistory) {
        if (useHistory === void 0) { useHistory = false; }
        this.sideSheetService.makeClean();
        this.pop(useHistory);
    };
    ObjectLaunchService.prototype.loadView = function (params, cancel$, placeholder) {
        var _this = this;
        return this
            .getObjectAndFormService
            .getObject$(params.objectTip)
            .pipe(flatMap(function (object) {
            return _this.throwErrorForDynamicObjectType(object, object.objectType.$tip);
        }), switchMap(function (o) { return _this.lifecycleWorkflowRedirect.redirectViaOutletWithObject(o, cancel$); }), catchError(function (err) { return handleError(err, placeholder); }), switchMap(function (_a) {
            var object = _a.object, redirected = _a.redirected;
            if (redirected) {
                return of(null);
            }
            var component = _this.wellKnownType.loadViewConfig(object);
            var props = tslib_1.__assign({}, params, { object: object });
            return _this.replaceComponent(component, props, cancel$);
        }), takeUntil(cancel$));
    };
    ObjectLaunchService.prototype.loadCreateEdit = function (params, cancel$, placeholder) {
        var _this = this;
        var launchSuperType$ = function (formObjectAndType) {
            var component = ObjectTypeSideSheetComponent;
            var props = tslib_1.__assign({}, params, { formObjectAndType: formObjectAndType });
            return _this.replaceComponent(component, props, cancel$);
        };
        var launchObject$ = function (fot) {
            return _this.throwErrorForDynamicObjectType(fot, fot.objectAndType.objectType.$tip).pipe(switchMap(function (formObjectAndType) {
                return _this.lifecycleWorkflowRedirect.redirectViaOutletWithFormAndObject(formObjectAndType, params.preview);
            }), switchMap(function (formObjectAndType) { return _this.objectLaunchDefaultService.setSequenceFieldReadOnly(formObjectAndType); }), switchMap(function (formObjectAndType) { return _this.objectLaunchDefaultService.populateFormDefaultValues(formObjectAndType, params); }), map(function (formObjectAndType) { return _this.wellKnownType.loadEditCreateConfig(formObjectAndType, params.formTip); }), switchMap(function (_a) {
                var formObjectAndType = _a.formObjectAndType, component = _a.component, objectLaunchOverrides = _a.objectLaunchOverrides;
                var props = tslib_1.__assign({}, params, { formObjectAndType: formObjectAndType }, objectLaunchOverrides);
                return _this.replaceComponent(component, props, cancel$);
            }), map(addRedirectToDashboardIfRequired));
        };
        return this.getObjectAndFormService.getFormObjectAndType(params).pipe(flatMap(function (object) {
            if (object.isSuperType) {
                return launchSuperType$(object);
            }
            return launchObject$(object);
        }), catchError(function (err) { return handleError(err, placeholder); }), takeUntil(cancel$));
    };
    ObjectLaunchService.prototype.replaceComponent = function (component, props, cancel$) {
        var componentInstance = this.sideSheetService.replace(component, props).componentInstance;
        if (componentInstance.setProps) {
            componentInstance.setProps(props);
        }
        // in the case that we do not have a done$ subject
        // emit done when cancel fires to complete any observables
        var done$ = null;
        if (componentInstance.done$) {
            done$ = componentInstance.done$.pipe(takeUntil(cancel$));
        }
        return done$ || cancel$;
    };
    ObjectLaunchService.prototype.throwErrorForDynamicObjectType = function (result, typeTip) {
        return this.objectLaunchFormulasService.isRestrictedSystemType$(typeTip)
            .pipe(flatMap(function (isSystemType) {
            if (isSystemType) {
                return throwError({ errorMessage: 'Users cannot load dynamic system objects' });
            }
            return of(result);
        }));
    };
    ObjectLaunchService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ObjectLaunchService_Factory() { return new ObjectLaunchService(i0.ɵɵinject(i1.SideSheetService), i0.ɵɵinject(i2.GetObjectAndFormService), i0.ɵɵinject(i3.ObjectLaunchFormulasService), i0.ɵɵinject(i4.LifecycleWorkflowRedirectService), i0.ɵɵinject(i5.FormulaMultiService), i0.ɵɵinject(i6.ObjectLaunchDefaultService), i0.ɵɵinject(i7.WellKnownTypesService)); }, token: ObjectLaunchService, providedIn: "root" });
    return ObjectLaunchService;
}());
export { ObjectLaunchService };
export function addRedirectToDashboardIfRequired(result) {
    var objectTip = result.objectTip, defaultDashboardTip = result.defaultDashboardTip;
    if (defaultDashboardTip && objectTip) {
        var redirect = {
            redirectLocation: "app/s/dashboard/" + encodeURIComponent(defaultDashboardTip) + "/" + encodeURIComponent(objectTip),
            type: REDIRECT_TYPES.dashboard
        };
        return tslib_1.__assign({}, result, { redirect: redirect });
    }
    return tslib_1.__assign({}, result, { redirect: result.redirect || null });
}
function handleError(err, placeholder) {
    placeholder.setProps({ errorMessage: err.errorMessage || 'Error loading object.' });
    return throwError(err);
}
