import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-number-format-formly',
  template: `<app-single-object-chooser-field
              [placeholder]="to.placeholder"
              [formControl]="formControl"
              [label]="to.label"
              [required]="to.required"
              [typeTips]="typeTips"
              [emitStringNotArray]="true"
              [readonly]="to['readonly']"
      ></app-single-object-chooser-field>`
})
export class FormlyFieldNumberFormatComponent extends FieldType {
  typeTips = ['number-format'];
}
