<app-side-sheet-header>
  <span>{{ title }}</span>
</app-side-sheet-header>
<app-side-sheet-section [showBorder]="false" [showHeader]="false" *ngIf="!showBadge; else showBadgeNumber">
  <app-side-sheet-list>
    <app-side-sheet-list-item [hasChevron]="true" *ngFor="let item of list; let i = index;"
      (click)="selectItem.next(item)">
      <div>{{ item.label }}</div>
    </app-side-sheet-list-item>
  </app-side-sheet-list>
</app-side-sheet-section>

<ng-template #showBadgeNumber>
  <app-side-sheet-list>
    <app-side-sheet-list-item *ngFor="let item of list; let i = index;">
      <div class="d-flex justify-content-between align-items-center" (click)="selectItem.next(item)">
        <div>
          <span class="badge badge-pill"
            [ngClass]="{ 'badge-success': by === 'Relative' && formula }">{{ by === 'Relative' && formula ? 1 : 0 }}</span>
          <span class="ml-2">{{ item.label }}</span>
        </div>
        <div class="h1 m-0 ml-2">
          <span class="mdi mdi-chevron-right"></span>
        </div>
      </div>
    </app-side-sheet-list-item>
  </app-side-sheet-list>
</ng-template>
