import { DataTypes, IObjectScheme } from '../data/models/scheme';
import { IObject } from '../data/models/object';
import { Formula, Tip } from '../data/models/types';
import { SUPER_TYPES } from '../object/type-chooser-side-sheet/super-types';

export type ON_DELETE_OPTIONS = 'remove' | 'cascade' | 'follow' | 'no-action';

// This is field interface for eno type 'field'. Do not confuse with IField in types.ts for eno field interface IField
export interface IField extends IObject {
  label: string;
  description?: string;
  datatype: FIELD_DATA_TYPE;
  name?: string;
  required?: boolean;
  default?: Formula;
  defaultI18n?: string;
  calculation?: Formula;
  calculationFieldDependencies?: IField[];
  minlen?: number;
  maxlen?: number;
  minlenexc?: number;
  maxlenexc?: number;
  mincount?: number;
  maxcount?: number;
  mincountexc?: number;
  maxcountexc?: number;
  minval?: number;
  maxval?: number;
  minvalexc?: number;
  maxvalexc?: number;
  regexp?: string;
  whitelist?: Formula;
  whitelistI18n?: string[];
  blacklist?: string[];
  blacklistI18n?: string[];
  numberFormat?: string;
  multiline?: boolean;
  typerestrict?: Tip[];
  totaldigits?: number;
  fractiondigits?: number;
  uniquevalues?: boolean;
  mergePolicy?: 'Replace' | 'NotAtAll';
  typeGeoRestrict?: TYPE_GEO_RESTRICT;
  tag?: string[];
  implements?: Tip[];
  id?: string;
  searchable?: boolean;
  searchIndexValue?: Formula;
  onDelete?: ON_DELETE_OPTIONS;
}

export enum TYPE_GEO_RESTRICT {
  POINT = 'point',
  LINE = 'linestring',
  POLYGON = 'polygon'
}

const fieldScheme: IObjectScheme = {
  'field/label': { type: DataTypes.i18n },
  'field/description': { type: DataTypes.i18n },
  'field/datatype': {},
  'field/name': {},
  'field/required': { type: DataTypes.boolean },
  'field/default': { type: DataTypes.formula },
  'field/default-i18n': { type: DataTypes.i18n },
  'field/calculation': {},
  'field/minlen': { type: DataTypes.number },
  'field/maxlen': { type: DataTypes.number },
  'field/minlenexc': { type: DataTypes.number },
  'field/maxlenexc': { type: DataTypes.number },
  'field/mincount': { type: DataTypes.number },
  'field/maxcount': { type: DataTypes.number },
  'field/mincountexc': { type: DataTypes.number },
  'field/maxcountexc': { type: DataTypes.number },
  'field/minval': { type: DataTypes.number },
  'field/maxval': { type: DataTypes.number },
  'field/minvalexc': { type: DataTypes.number },
  'field/maxvalexc': { type: DataTypes.number },
  'field/regexp': {},
  'field/whitelist': { type: DataTypes.formula },
  'field/whitelist-i18n': { type: DataTypes.i18nArray },
  'field/blacklist': { type: DataTypes.stringArray },
  'field/blacklist-i18n': { type: DataTypes.i18nArray },
  'field/multiline': { type: DataTypes.boolean },
  'field/typerestrict': { type: DataTypes.stringArray },
  'field/totaldigits': { type: DataTypes.number },
  'field/fractiondigits': { type: DataTypes.number },
  'field/uniquevalues': { type: DataTypes.boolean },
  'field/type-geo-restrict': { type: DataTypes.string },
  'field/number-format': { type: DataTypes.string },
  'field/merge-policy': {},
  'field/implements': { type: DataTypes.stringArray },
  'field/tag': { type: DataTypes.stringArray },
  'field/searchable': { type: DataTypes.boolean },
  'field/search-index-value': { type: DataTypes.formula },
  'field/on-delete': { type: DataTypes.string },
};

fieldScheme['field/calculation-field-dependencies'] = { type: DataTypes.objectArray, scheme: fieldScheme, mutable: true };

export { fieldScheme };

export enum FIELD_DATA_TYPE {
  string_i18n = 'string-i18n',
  string = 'string',
  blob = 'blob',
  number = 'number',
  decimal = 'decimal',
  bool = 'bool',
  date = 'date',
  datetime = 'datetime',
  object = 'object',
  email = 'email',
  phone = 'phone',
  uri = 'uri',
  geography = 'geography',
  formula = 'formula',
  string_formula = 'string-formula',
  string_i18n_formula = 'string-i18n-formula',
  json = 'json',
  html = 'html'
}

export enum EXTENDED_FIELD_DATA_TYPE {
  sub_object = 'sub-object',
  file = 'file',
  list = 'list',
  address = 'address',
  point = 'point',
  line = 'line',
  polygon = 'polygon',
  assignable = 'assignable',
  symbology = 'symbology',
  hierarchical = 'hierarchical'
}

export type ALL_FIELD_DATA_TYPE = FIELD_DATA_TYPE | EXTENDED_FIELD_DATA_TYPE;

export const EXTENDED_FIELD_ACTUAL_TYPE_MAP: {
  [key in EXTENDED_FIELD_DATA_TYPE]: {
    fieldDataType: FIELD_DATA_TYPE;
    typeRestrict?: Tip[];
    typeGeoRestrict?: TYPE_GEO_RESTRICT
  }
} = {
  [EXTENDED_FIELD_DATA_TYPE.file]: {
    fieldDataType: FIELD_DATA_TYPE.object,
    typeRestrict: ['file']
  },
  [EXTENDED_FIELD_DATA_TYPE.list]: {
    fieldDataType: FIELD_DATA_TYPE.object,
    typeRestrict: ['app/list']
  },
  [EXTENDED_FIELD_DATA_TYPE.address]: {
    fieldDataType: FIELD_DATA_TYPE.object,
    typeRestrict: ['app/address']
  },
  [EXTENDED_FIELD_DATA_TYPE.point]: {
    fieldDataType: FIELD_DATA_TYPE.geography,
    typeGeoRestrict: TYPE_GEO_RESTRICT.POINT
  },
  [EXTENDED_FIELD_DATA_TYPE.line]: {
    fieldDataType: FIELD_DATA_TYPE.geography,
    typeGeoRestrict: TYPE_GEO_RESTRICT.LINE
  },
  [EXTENDED_FIELD_DATA_TYPE.polygon]: {
    fieldDataType: FIELD_DATA_TYPE.geography,
    typeGeoRestrict: TYPE_GEO_RESTRICT.POLYGON
  },
  [EXTENDED_FIELD_DATA_TYPE.assignable]: {
    fieldDataType: FIELD_DATA_TYPE.object,
    typeRestrict: ['app/assignment']
  },
  [EXTENDED_FIELD_DATA_TYPE.symbology]: {
    fieldDataType: FIELD_DATA_TYPE.json
  },
  [EXTENDED_FIELD_DATA_TYPE.sub_object]: {
    fieldDataType: FIELD_DATA_TYPE.object
  },
  [EXTENDED_FIELD_DATA_TYPE.hierarchical]: {
    fieldDataType: FIELD_DATA_TYPE.object,
    typeRestrict: [...SUPER_TYPES.filter(x => x.isHierarchical).map(x => x.$tip), 'app/group']
  }
};
