import { Injectable } from '@angular/core';
import { Eno } from './models/Eno';
import { IQueryResponse, IDimResult, IAttrResult, IQueryResult } from './models/types';
import { Observable } from 'rxjs';
import { EnoService } from './eno.service';
import { take } from 'rxjs//operators';
import { EnoCacheService } from './eno-cache.service';
import { IQueryExtraInfo } from './query.service';

@Injectable({
  providedIn: 'root'
})
export class QueryResponseParserService {
  constructor(private _enoService: EnoService, private _enoCacheService: EnoCacheService) { }

  public parse(responseEno: Eno): Observable<IQueryResponse> {
    return new Observable<IQueryResponse>((observer) => {
      const attrTips = responseEno.getFieldValues('response/query/attributes');
      const runtimeAttrs = responseEno.getFieldValues('response/query/runtime-attributes');
      const dimTips = responseEno.getFieldValues('response/query/dimensions');
      const runtimeDims = responseEno.getFieldValues('response/query/runtime-dimensions');
      const result = responseEno.getFieldValues('response/query/result');
      const structuredResult: IQueryResponse = {
        attributes: null,
        dimensions: null,
        results: null,
        execTime: responseEno.getFieldNumberValue('response/query/exec-time')
      };

      this._enoService.readEnos(attrTips, { useCache: true }).pipe(take(1)).subscribe((attrEnos) => {
        const dimEnos: Eno[] = [];

        dimTips.forEach((dimTip) => {
          dimEnos.push(this._enoCacheService.getEno(dimTip));
        });

        structuredResult.attributes = attrEnos.map((attrEno) => {
          return {tip: attrEno.tip, label: attrEno.getFieldStringValue('query/attribute/label')};
        });

        runtimeAttrs.forEach(runtimeAttr => {
          const decodedAttr: IQueryExtraInfo = JSON.parse(runtimeAttr);

          structuredResult.attributes.push({label: decodedAttr.label});
        });

        structuredResult.dimensions = dimEnos.map((dimEno) => {
          return {
            label: dimEno.getFieldStringValue('response/query/dimension/label'),
            values: dimEno.getFieldValues('response/query/dimension/value')
          };
        });

        runtimeDims.forEach(runtimeDim => {
          const originalDim = JSON.parse(runtimeDim);
          structuredResult.dimensions.push({ label: originalDim.label, values: originalDim.value });
        });

        structuredResult.results = this._unpack(result, structuredResult.dimensions, structuredResult.attributes);

        observer.next(structuredResult);
        observer.complete();
      });
    });
  }

  public parseTipOneDimensionQuery<T>(queryResponse: IQueryResponse): T[] {
    const result: T[] = [];

    queryResponse.results.forEach((dimObj) => {
      let eachDimResult;

      for (const tip in dimObj) {
        if (!dimObj[tip]) {
          continue;
        }

        eachDimResult = {...dimObj[tip]};
        eachDimResult.$tip = tip;
      }

      result.push(eachDimResult as unknown as T);
    });

    return result;
  }

  private _unpack(
    flatResult: string[],
    dims: IDimResult[],
    attrs: IAttrResult[],
    result: IQueryResult[] = [],
    depth: number = 0
  ): IQueryResult[] {
    if (depth === dims.length - 1) {
      let i = 0;

      dims[dims.length - 1].values.forEach((lastDimValue) => {
        const leafResult: IQueryResult = {};
        leafResult[lastDimValue] = {};

        if (flatResult[i] === '{#}') {
          i += attrs.length;

          return;
        }

        for (const attr of attrs) {
          leafResult[lastDimValue][attr.label] = flatResult[i++];
        }

        result.push(leafResult);
      });

      return result;
    }

    // TODO this is ignoring errors. EIM-3721
    if (!dims[depth]) {
      return [];
    }

    dims[depth].values.forEach((dimValue, i) => {
      const subResult: IQueryResult[] = [];
      const subResultSize = flatResult.length / dims[depth].values.length;
      const subResultStart = i * subResultSize;
      const subResultEnd = subResultStart + subResultSize;

      result[i] = {};
      result[i][dimValue] = subResult;

      this._unpack(flatResult.slice(subResultStart, subResultEnd), dims, attrs, subResult, depth + 1);
    });

    return result;
  }
}
