var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
import { DataTypes } from '../data/models/scheme';
import { FIELD_DATA_TYPE } from './field';
export var chartScheme = {
    'app/chart:title': { type: DataTypes.i18n },
    'app/chart:configuration': { type: DataTypes.json },
    'app/chart:data-source': { name: 'dataSource', type: DataTypes.jsonArray },
    'app/chart:preview-input': { name: 'previewInput', type: DataTypes.jsonArray }
};
export var EChartType;
(function (EChartType) {
    EChartType["LINE"] = "Line";
    EChartType["AREA"] = "Area";
    EChartType["STACKED_AREA"] = "Stacked area";
    EChartType["STACKED_AREA_100"] = "100% stacked area";
    EChartType["COLUMN"] = "Column";
    EChartType["STACKED_COLUMN"] = "Stacked column";
    EChartType["STACKED_COLUMN_100"] = "100% stacked column";
    EChartType["BAR"] = "Bar";
    EChartType["STACKED_BAR"] = "Stacked bar";
    EChartType["STACKED_BAR_100"] = "100% stacked bar";
    EChartType["PIE"] = "Pie";
    EChartType["DOUHNUT"] = "Doughnut";
    EChartType["SCATTER"] = "Scatter";
    EChartType["BUBBLE"] = "Bubble";
    EChartType["TIMELINE"] = "Timeline";
})(EChartType || (EChartType = {}));
export var CHART_TYPE_SETTINGS = (_a = {},
    _a[EChartType.LINE] = {
        datasetOptions: {
            type: 'line',
            fill: false,
            pointRadius: 3
        }
    },
    _a[EChartType.PIE] = {
        datasetOptions: {
            type: 'pie'
        },
        options: {
            cutoutPercentage: 0
        }
    },
    _a[EChartType.DOUHNUT] = {
        datasetOptions: {
            type: 'doughnut'
        },
        options: {
            cutoutPercentage: 50
        }
    },
    _a[EChartType.COLUMN] = {
        datasetOptions: {
            type: 'bar'
        }
    },
    _a[EChartType.AREA] = {
        datasetOptions: {
            type: 'line',
            fill: true
        }
    },
    _a[EChartType.SCATTER] = {
        datasetOptions: {
            type: 'scatter',
            showLine: false,
            fill: false
        }
    },
    _a);
// @toDo: add MOVING_AVERAGE = 'Moving average' - EIM-1279
export var EyAxisMetric;
(function (EyAxisMetric) {
    EyAxisMetric["AVERAGE"] = "Average";
    EyAxisMetric["COUNT"] = "Count";
    EyAxisMetric["SUM"] = "Sum";
    EyAxisMetric["CUMULATIVE_SUM"] = "Cumulative sum";
    EyAxisMetric["STANDARD_DEVIATION"] = "Standard deviation";
    EyAxisMetric["VALUES"] = "Values";
})(EyAxisMetric || (EyAxisMetric = {}));
export var EDateTimeGroupBy;
(function (EDateTimeGroupBy) {
    EDateTimeGroupBy["NO_GROUPING"] = "No grouping";
    EDateTimeGroupBy["YEAR"] = "Year";
    EDateTimeGroupBy["MONTH"] = "Month";
    EDateTimeGroupBy["DATE"] = "Date";
    EDateTimeGroupBy["DAY_OF_THE_WEEK"] = "Day of the week";
    EDateTimeGroupBy["MONTH_PER_YEAR"] = "Month per year";
})(EDateTimeGroupBy || (EDateTimeGroupBy = {}));
var NO_GROUPING_DATE = {
    offset: true,
    time: { min: '', max: '', unit: 'second', displayFormats: { second: 'DD MMM YYYY' }, tooltipFormat: 'DD MMM YYYY' },
    distribution: 'series',
    ticks: { source: 'data' }
};
var NO_GROUPING_DATETIME = {
    offset: true,
    time: { min: '', max: '', unit: 'second', displayFormats: { second: 'DD MMM YYYY HH:mm' }, tooltipFormat: 'DD MMM YYYY HH:mm' },
    distribution: 'series',
    ticks: { source: 'data' }
};
var YEAR_DATE_DATETIME = {
    offset: true,
    time: { min: '', max: '', unit: 'year', tooltipFormat: 'YYYY' },
    distribution: 'series',
    ticks: { source: 'data' }
};
var MONTH_DATE_DATETIME = {
    offset: true,
    time: { min: '1999-12-15', max: '2000-12-16', unit: 'month', displayFormats: { month: 'MMM' }, tooltipFormat: 'MMM' },
    distribution: 'linear',
    ticks: { source: 'auto' }
};
var DATE_DATE_DATETIME = {
    offset: true,
    time: { min: '', max: '', unit: 'day', displayFormats: { day: 'DD MMM YYYY' }, tooltipFormat: 'DD MMM YYYY' },
    distribution: 'series',
    ticks: { source: 'data' }
};
var DAY_OF_THE_WEEK_DATE_DATETIME = {
    offset: true,
    time: { min: '2019-02-09T12:00:00', max: '2019-02-16T12:00:00', unit: 'day', displayFormats: { day: 'dddd' }, tooltipFormat: 'dddd' },
    distribution: 'linear',
    ticks: { source: 'auto' }
};
var MONTH_PER_YEAR_DATE_DATETIME = {
    offset: true,
    time: { min: '', max: '', unit: 'month', displayFormats: { month: 'MMM YYYY' }, tooltipFormat: 'MMM YYYY' },
    distribution: 'series',
    ticks: { source: 'data' }
};
export var datetimeGroupByXAxisSettings = (_b = {},
    _b[EDateTimeGroupBy.NO_GROUPING] = (_c = {},
        _c[FIELD_DATA_TYPE.date] = NO_GROUPING_DATE,
        _c[FIELD_DATA_TYPE.datetime] = NO_GROUPING_DATETIME,
        _c),
    _b[EDateTimeGroupBy.YEAR] = (_d = {},
        _d[FIELD_DATA_TYPE.date] = YEAR_DATE_DATETIME,
        _d[FIELD_DATA_TYPE.datetime] = YEAR_DATE_DATETIME,
        _d),
    _b[EDateTimeGroupBy.MONTH] = (_e = {},
        _e[FIELD_DATA_TYPE.date] = MONTH_DATE_DATETIME,
        _e[FIELD_DATA_TYPE.datetime] = MONTH_DATE_DATETIME,
        _e),
    _b[EDateTimeGroupBy.DATE] = (_f = {},
        _f[FIELD_DATA_TYPE.date] = DATE_DATE_DATETIME,
        _f[FIELD_DATA_TYPE.datetime] = DATE_DATE_DATETIME,
        _f),
    _b[EDateTimeGroupBy.DAY_OF_THE_WEEK] = (_g = {},
        _g[FIELD_DATA_TYPE.date] = DAY_OF_THE_WEEK_DATE_DATETIME,
        _g[FIELD_DATA_TYPE.datetime] = DAY_OF_THE_WEEK_DATE_DATETIME,
        _g),
    _b[EDateTimeGroupBy.MONTH_PER_YEAR] = (_h = {},
        _h[FIELD_DATA_TYPE.date] = MONTH_PER_YEAR_DATE_DATETIME,
        _h[FIELD_DATA_TYPE.datetime] = MONTH_PER_YEAR_DATE_DATETIME,
        _h),
    _b);
export var ELineDashStyle;
(function (ELineDashStyle) {
    ELineDashStyle["SOLID"] = "Solid";
    ELineDashStyle["SHORT_DASH"] = "Short dash";
    ELineDashStyle["SHORT_DOT"] = "Short dot";
    ELineDashStyle["SHORT_DASH_DOT"] = "Short dash dot";
    ELineDashStyle["SHORT_DASH_DOT_DOT"] = "Short dash dot dot";
    ELineDashStyle["DOT"] = "Dot";
    ELineDashStyle["DASH"] = "Dash";
    ELineDashStyle["LONG_DASH"] = "Long dash";
    ELineDashStyle["DASH_DOT"] = "Dash dot";
    ELineDashStyle["LONG_DASH_DOT"] = "Long dash dot";
    ELineDashStyle["LONG_DASH_DOT_DOT"] = "Long dash dot dot";
})(ELineDashStyle || (ELineDashStyle = {}));
export var DASH_STYLE_OPTIONS = (_j = {},
    _j[ELineDashStyle.SOLID] = [],
    _j[ELineDashStyle.SHORT_DASH] = [5, 5],
    _j[ELineDashStyle.SHORT_DOT] = [1, 5],
    _j[ELineDashStyle.SHORT_DASH_DOT] = [5, 5, 1, 5],
    _j[ELineDashStyle.SHORT_DASH_DOT_DOT] = [5, 5, 1, 5, 1, 5],
    _j[ELineDashStyle.DOT] = [1, 10],
    _j[ELineDashStyle.DASH] = [10, 10],
    _j[ELineDashStyle.LONG_DASH] = [20, 10],
    _j[ELineDashStyle.DASH_DOT] = [10, 10, 1, 10],
    _j[ELineDashStyle.LONG_DASH_DOT] = [20, 10, 1, 10],
    _j[ELineDashStyle.LONG_DASH_DOT_DOT] = [20, 10, 1, 10, 1, 10],
    _j);
export var EPointMarkerSymbol;
(function (EPointMarkerSymbol) {
    EPointMarkerSymbol["AUTO"] = "Auto";
    EPointMarkerSymbol["CIRCLE"] = "Circle";
    EPointMarkerSymbol["SQUARE"] = "Square";
    EPointMarkerSymbol["DIAMOND"] = "Diamond";
    EPointMarkerSymbol["TRIANGLE"] = "Triangle";
    EPointMarkerSymbol["STAR"] = "Star";
})(EPointMarkerSymbol || (EPointMarkerSymbol = {}));
export var POINT_STYLE_OPTIONS = (_k = {},
    _k[EPointMarkerSymbol.AUTO] = 'circle',
    _k[EPointMarkerSymbol.CIRCLE] = 'circle',
    _k[EPointMarkerSymbol.SQUARE] = 'rect',
    _k[EPointMarkerSymbol.DIAMOND] = 'rectRot',
    _k[EPointMarkerSymbol.TRIANGLE] = 'triangle',
    _k[EPointMarkerSymbol.STAR] = 'star',
    _k);
export var defaultTickMinMax = [
    {
        suggestedMin: 0,
        suggestedMax: 0
    },
    {
        suggestedMin: 0,
        suggestedMax: 0
    }
];
