<app-auth-chrome
    *ngIf="state !== SignInStatus.SUCCESS; else successState"
    i18n-formType="@@forgotPassword.authChrome.formType"
    formType="Reset"
    [showMessage]="!!messageState"
    i18n-primaryTitle="@@forgotPassword.authChrome.primaryTitle"
    primaryTitle="Forgot your password? Don't worry, we'll send you an email to reset your password.">
  <ng-container
      eim-i18n-property="message"
      i18n="@@forgotPassword.authChrome.message">{
    messageState,
      select,
      failure {Something is wrong and we cannot reset your password right now. Please try again later.}
      }</ng-container>
  <form
      [formGroup]="forgotPasswordForm"
      (ngSubmit)="forgotPasswordForm.valid && onResetPassword()"
      [appSpinner]="state === SignInStatus.IN_PROGRESS">
    <div class="form-group">
      <label
          i18n="@@forgotPassword.authChrome.forgotPasswordForm.emailLabel"
          for="email"
          class="sr-only">Your Email</label>
      <input
          class="form-control"
          type="email"
          id="email"
          name="email"
          i18n-placeholder="@@forgotPassword.authChrome.forgotPasswordForm.emailPlaceholder"
          placeholder="Your Email"
          autocomplete="off"
          formControlName="email">
    </div>
    <button
        i18n="@@forgotPassword.authChrome.forgotPasswordForm.resetPasswordButton"
        class="btn btn-block eim-button_noggin"
        type="submit"
        data-test="reset-password">Reset password</button>
  </form>
  <div class="d-flex justify-content-center back-to-login" *ngIf="isNativeApp">
    <span
        i18n="@@forgotPassword.authChrome.forgotPasswordForm.backToLogin"
        class="back-to-login-text"
        (click)="goBackToLogin()">Go back to login</span>
  </div>
</app-auth-chrome>
<ng-template #successState>
  <app-auth-chrome
      i18n-formType="@@forgotPassword.authChrome.formType"
      formType="Reset">
    <div class="text-center" data-test="reset-success">
      <h6 i18n="@@forgotPassword.authChrome.sentLink">We have sent a link to reset your password to:</h6>
      <h6><strong>{{ forgotPasswordForm.value.email }}</strong></h6>
      <h6 i18n="@@forgotPassword.authChrome.clickLink">Please go to the reset password email sent to you and click the link.</h6>
    </div>
    <div *ngIf="isNativeApp">
      <h5 i18n="@@forgotPassword.authChrome.or">OR</h5>
    </div>
    <div class="d-flex justify-content-center back-to-login-success-state" *ngIf="isNativeApp">
      <span
          i18n="@@forgotPassword.authChrome.logInAgain"
          class="back-to-login-text"
          (click)="goBackToLogin()">Log in again</span>
    </div>
  </app-auth-chrome>
</ng-template>
