import { Component, ViewChild, OnInit } from '@angular/core';
import { SideSheetService } from '../../side-sheet/side-sheet.service';
import { SideSheetListModes, SideSheetListComponent } from '../side-sheet-list/side-sheet-list.component';
import { get } from 'lodash';
import { IFormSideSheetResponse } from '../../models/form';

@Component({
  selector: 'app-side-sheet-option-list',
  templateUrl: './side-sheet-option-list.component.html',
  styleUrls: ['./side-sheet-option-list.component.scss']
})
export class SideSheetOptionListComponent implements OnInit {
  @ViewChild('optionList', { static: false }) optionList: SideSheetListComponent;

  label: string;
  searchBox = false;
  filterText = '';
  listType: SideSheetListModes;
  selected: any | any[];
  required = true;
  options: {
    label: string,
    value: any,
    icon?: string,
    disabled?: boolean,
  }[];

  hideBack = false;
  actionText: string;

  done: (response: IFormSideSheetResponse) => void;

  constructor(private sideSheetService: SideSheetService) { }

  ngOnInit(): void {
    if (!this.selected) {
      this.selected = [null];
    }
    if (this.selected && !Array.isArray(this.selected)) {
      this.selected = [this.selected];
    }
  }

  
  get isSingleSelect(): boolean {
    return  this.listType === SideSheetListModes.SINGLE_SELECT;
  }

  onSelectedChange() {
    if (this.isSingleSelect) {
      this.onDone();
    }
  }

  onDone() {
    this.done(<IFormSideSheetResponse>{
      selected: this.listType === SideSheetListModes.MULTI_SELECT ? this.selected : get(this.selected, '[0]')
    });
    this.sideSheetService.pop();
  }
}
