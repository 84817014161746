import * as tslib_1 from "tslib";
import { map } from 'rxjs/operators';
import { flatten, head, last, reverse, truncate, get } from 'lodash';
import { getSpecialContextFormulaLabel } from '../../util/special-context-formula';
import { getMostInnerContextPartsFromContextFormula } from '../../util/context-formula-wrapper';
import { Parser } from './field-formula-side-sheet/formula';
import * as i0 from "@angular/core";
import * as i1 from "../../data/formula.service";
import * as i2 from "../../data/formula-multi.service";
var LABEL_FORMULA = 'FIELD("field/label")';
var TYPE_LABEL_FORMULA = 'FIELD("type/label")';
var FormulaLabelService = /** @class */ (function () {
    function FormulaLabelService(formulaService, formulaMultiService) {
        this.formulaService = formulaService;
        this.formulaMultiService = formulaMultiService;
    }
    FormulaLabelService.prototype.transform = function (formulaString, _a, contextTypeTip) {
        var _b = _a === void 0 ? {} : _a, branches = _b.branches, vars = _b.vars;
        return this.transformBatch([{ formulaString: formulaString, branches: branches, vars: vars, contextTypeTip: contextTypeTip }]).pipe(map(head));
    };
    FormulaLabelService.prototype.transformBatch = function (formulaLabelParamsBatch) {
        var _a = formulaLabelParamsBatch
            .reduce(function (acc, formulaParam) {
            var titleLabelOrder = [];
            // exceptions: Context type (Beginning)
            if (formulaParam.contextTypeTip) {
                var formulaServiceParams = {
                    formula: TYPE_LABEL_FORMULA,
                    context: formulaParam.contextTypeTip,
                    vars: formulaParam.vars,
                    contextBranches: formulaParam.branches
                };
                acc.formulasToBatch.push(formulaServiceParams);
                titleLabelOrder.push('label');
            }
            var _a = getMostInnerContextPartsFromContextFormula(formulaParam.formulaString), outerFormulas = _a.outerFormulas, mostInnerContextFormula = _a.mostInnerContextFormula;
            tslib_1.__spread([mostInnerContextFormula], reverse(outerFormulas)).forEach(function (formula) {
                // exceptions: Special context formulas (Custom formula, current user formula, meta data formula, relative date formula,
                // common field formula, etc.)
                var specialContextFormulaLabel = getSpecialContextFormulaLabel(formula);
                if (specialContextFormulaLabel) {
                    titleLabelOrder.push('meta');
                    acc.metaLabels.push(specialContextFormulaLabel);
                    return;
                }
                // build field label formulas
                var parsedFormula = Parser(formula);
                if (parsedFormula.name === 'FIELD' || parsedFormula.name === 'FMT_FIELD') {
                    var formulaServiceParams = {
                        formula: LABEL_FORMULA,
                        context: get(parsedFormula, 'args[0]'),
                        vars: formulaParam.vars,
                        contextBranches: formulaParam.branches
                    };
                    acc.formulasToBatch.push(formulaServiceParams);
                    titleLabelOrder.push('label');
                }
                return acc;
            }, []);
            acc.labelOrder.push(titleLabelOrder);
            return acc;
        }, { labelOrder: [], metaLabels: [], formulasToBatch: [] }), labelOrder = _a.labelOrder, metaLabels = _a.metaLabels, formulasToBatch = _a.formulasToBatch;
        return this
            .formulaMultiService
            .evaluate(formulasToBatch)
            .pipe(map(flatten), map(groupFormulaResults(labelOrder, metaLabels)), map(function (r) { return r.map(truncateResults); }));
    };
    FormulaLabelService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FormulaLabelService_Factory() { return new FormulaLabelService(i0.ɵɵinject(i1.FormulaService), i0.ɵɵinject(i2.FormulaMultiService)); }, token: FormulaLabelService, providedIn: "root" });
    return FormulaLabelService;
}());
export { FormulaLabelService };
export function groupFormulaResults(labelOrder, metaLabels) {
    return function groupingResults(results) {
        var foo = labelOrder.reduce(function (acc, order) {
            var row = order.map(function (v) {
                if (v === 'meta') {
                    return metaLabels.shift();
                }
                if (v === 'label') {
                    return results.shift();
                }
            });
            return tslib_1.__spread(acc, [row]);
        }, []);
        return foo;
    };
}
function truncateResults(results) {
    if (results.length <= 2) {
        return results.map(function (result) { return truncate(result); }).join(' › ');
    }
    return truncate(head(results), { length: 15, omission: '…' })
        + ' › …'.repeat(Math.min(results.length - 2, 3))
        + ' › '
        + truncate(last(results), { length: 15, omission: '…' });
}
