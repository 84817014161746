import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-field-checkbox',
  template: `
      <label class="be-checkbox custom-control custom-checkbox">
          <input class="custom-control-input" type="checkbox"
            [class.is-invalid]="showError"
            [attr.data-test]="to.testId"
            [formControl]="formControl"
            [indeterminate]="defaultOptions.templateOptions.indeterminate"
            [attr.disabled]="to.readonly ? '' : null"
            [required]="to.required">
          <span class="custom-control-label custom-control-color">{{to.label}}</span>
      </label>
  `,
})
export class FormlyFieldCheckboxComponent extends FieldType implements OnInit {
  defaultOptions = {
    templateOptions: {
      indeterminate: false,
    },
  };

  ngOnInit() {
    if (typeof this.formControl.value !== 'boolean') {
      this.formControl.patchValue(false);
    }
  }
}
