import * as tslib_1 from "tslib";
import { forkJoin, of } from 'rxjs';
import { first, map, switchMap, take, tap } from 'rxjs/operators';
import { head, get } from 'lodash';
import { typeScheme } from '../models/type';
import { fieldToScheme } from '../util/field-to-scheme';
import { isSuperType } from './type-chooser-side-sheet/super-types';
import { supertypeScheme } from '../models/supertype';
import { getDataTypeAndMetaFromField } from '../util/get-data-type-and-meta-from-field';
import { hasSubObjectSecurityLabel } from '../util/sub-objects/sub-object-security';
import * as i0 from "@angular/core";
import * as i1 from "../data/object-service/object.service";
import * as i2 from "../data/eno.service";
import * as i3 from "../data/formula.service";
import * as i4 from "../data/query.service";
var ObjectService = /** @class */ (function () {
    function ObjectService(dataObjectService, enoService, formulaService, queryService) {
        this.dataObjectService = dataObjectService;
        this.enoService = enoService;
        this.formulaService = formulaService;
        this.queryService = queryService;
    }
    // Generates and returns a scheme for the given object type
    ObjectService.prototype.getScheme = function (type) {
        return type.field.reduce(function (scheme, field) {
            var $tip = field.$tip;
            var _datatype = getDataTypeAndMetaFromField({ field: field })._datatype;
            scheme[$tip] = fieldToScheme(field, _datatype);
            return scheme;
        }, {});
    };
    // Returns an observable with the object data, type, and scheme
    // todo rename to objectTypeAndScheme because that's what it is
    ObjectService.prototype.getObjectAndType = function (tip, branchTip, cache) {
        var _this = this;
        var objectAndType = { objectData: null, objectType: null, objectScheme: null };
        return this.enoService.readEno(tip, { branch: branchTip, useCache: cache }).pipe(take(1), switchMap(function (objectEno) { return _this.dataObjectService.getObject(objectEno.source.type, typeScheme); }), tap(function (objectType) { return objectAndType.objectType = objectType; }), map(function (objectType) { return _this.getScheme(objectType); }), tap(function (scheme) { return objectAndType.objectScheme = scheme; }), 
        // this is the same object we already just got
        switchMap(function () { return _this.dataObjectService.getObject(tip, objectAndType.objectScheme, branchTip); }), tap(function (objectData) { return objectAndType.objectData = objectData; }), map(function () { return objectAndType; }));
    };
    ObjectService.prototype.getObjectTypeSchemeSubObject = function (tip, branchTip, cache) {
        var _this = this;
        return this.getObjectAndType(tip, branchTip, cache)
            .pipe(switchMap(function (objectAndType) {
            var security = get(objectAndType, 'objectData.$security', null);
            return _this.enoService
                .readEno(security)
                .pipe(map(function (securityEno) {
                // all sub objects will have this variant label
                var securityTip = head(securityEno.getFieldValues('security/policy/label'));
                var isSubObject = hasSubObjectSecurityLabel(securityTip);
                return tslib_1.__assign({}, objectAndType, { isSubObject: isSubObject });
            }));
        }));
    };
    ObjectService.prototype.getObjectsDataWithTypeAndScheme = function (tips) {
        var _this = this;
        // eventually make this better than just
        // plural get objects just get the object type once if multiple objects are the same type
        var obt$$ = tips.map(function (tip) { return _this.getObjectAndType(tip).pipe(first()); });
        return forkJoin(obt$$);
    };
    // Returns an observable with type and scheme, but empty data
    ObjectService.prototype.getType = function (typeTip) {
        var _this = this;
        var objectAndType = { objectData: {}, objectType: null, objectScheme: null };
        return this.getPlainType(typeTip)
            .pipe(tap(function (objectType) { return objectAndType.objectType = objectType; }), map(function (objectType) { return _this.getScheme(objectType); }), tap(function (scheme) { return objectAndType.objectScheme = scheme; }), map(function () { return objectAndType; }));
    };
    ObjectService.prototype.getPlainType = function (typeTip) {
        var objectTypeScheme = isSuperType(typeTip) ? supertypeScheme : typeScheme;
        return this.dataObjectService.getObject(typeTip, objectTypeScheme);
    };
    // Updates an object
    ObjectService.prototype.updateObject = function (objectData, objectScheme, branchTip) {
        return this.dataObjectService.setObject(objectData, objectScheme, branchTip).pipe(map(function (batch) {
            if (batch.length > 0) {
                return batch[batch.length - 1].tip;
            }
            // Nothing has been changed
            return null;
        }));
    };
    // Deletes an object
    ObjectService.prototype.deleteObject = function (objectData) {
        return this.dataObjectService.deleteObject(objectData).pipe(take(1));
    };
    ObjectService.prototype.getTitles = function (tips) {
        if (!tips || tips.length === 0) {
            return of([]);
        }
        if (!Array.isArray(tips)) {
            tips = [tips];
        }
        return this.formulaService.evaluate("TITLE(ARRAY(\"" + tips.join('","') + "\"))").pipe(map(function (result) {
            return tips.map(function (tip, index) {
                return {
                    $tip: tip,
                    title: result[index]
                };
            });
        }));
    };
    ObjectService.prototype.getAllTypesExtendingSupertypes$ = function (superTypeTips, dynamicFilters, dynamicAttrs) {
        if (dynamicFilters === void 0) { dynamicFilters = []; }
        if (dynamicAttrs === void 0) { dynamicAttrs = []; }
        return this.queryService.execute1dFirst('eim/query/get-all-type-tips-extending-super-type', {
            dimensionOptions: [
                {
                    label: 'Tip dimension',
                    formula: 'TIP()'
                }
            ],
            extraAttributes: dynamicAttrs,
            extraFilters: dynamicFilters,
            vars: {
                'Super type tips': superTypeTips
            }
        });
    };
    ObjectService.prototype.isSuperType$ = function (typeTip) {
        return of(isSuperType(typeTip));
    };
    ObjectService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ObjectService_Factory() { return new ObjectService(i0.ɵɵinject(i1.ObjectService), i0.ɵɵinject(i2.EnoService), i0.ɵɵinject(i3.FormulaService), i0.ɵɵinject(i4.QueryService)); }, token: ObjectService, providedIn: "root" });
    return ObjectService;
}());
export { ObjectService };
