import { chain, find, get, isString, values } from 'lodash';

import dataConstants from '../../data/constants';
import { II18n } from '../../data/models/types';

export interface II18nValues {
  [localeId: string]: string;
}

export interface IFieldI18nValueMap {
  [localeId: string]: string[];
}

export function getI18nValue(value: string | II18nValues): string {
  if (!value) {
    return '';
  }

  if (isString(value)) {
    return value;
  }

  for (const localeId of dataConstants.ACCEPTABLE_LOCALE_IDS) {
    if (isString(value[localeId])) {
      return value[localeId];
    }
  }

  const firstValue = values(value)[0];

  return isString(firstValue) ? firstValue : '';
}

export function constructI18nValues(value: string, originalValue?: string | II18nValues): II18nValues {
  if (originalValue === undefined) {
    return { [dataConstants.LOCALE_ID]: value };
  }

  if (isString(originalValue)) {
    if (dataConstants.LOCALE_ID === dataConstants.LANG_DEFAULT) {
      return { [dataConstants.LOCALE_ID]: value };
    }

    return { [dataConstants.LANG_DEFAULT]: originalValue, [dataConstants.LOCALE_ID]: value };
  }

  const i18nValues: II18nValues = { ...originalValue };

  i18nValues[dataConstants.LOCALE_ID] = value;

  return i18nValues;
}

export function getFieldI18nValues(i18ns: II18n[]): string[] {
  if (!i18ns) {
    return [];
  }

  const firstI18nValues: string[] = get(i18ns, [0, 'value'], []);
  const fieldI18nValueMap: IFieldI18nValueMap = chain(i18ns).keyBy('lang').mapValues('value').value();
  const valueLength: number = firstI18nValues.length;
  const i18nValues: string[] = [];

  for (let i = 0; i < valueLength; i++) {
    for (const localeId of dataConstants.ACCEPTABLE_LOCALE_IDS) {
      const candidateValues: string[] = fieldI18nValueMap[localeId];

      if (!candidateValues) {
        continue;
      }

      if (candidateValues[i]) {
        i18nValues.push(candidateValues[i]);

        break;
      }
    }

    if (!i18nValues[i]) {
      const firstI18nWithValue: II18n = find(i18ns, (i18n: II18n) => Boolean(get(i18n, ['value', i])));

      i18nValues.push(get(firstI18nWithValue, ['value', i]) || '');
    }
  }

  return i18nValues;
}
