/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sso-auth.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./sso-auth.component";
import * as i5 from "./sso-auth.service";
import * as i6 from "../../util/logger.service";
import * as i7 from "../../data/session-manager.service";
import * as i8 from "../../util/location.service";
var styles_SSOAuthComponent = [i0.styles];
var RenderType_SSOAuthComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SSOAuthComponent, data: {} });
export { RenderType_SSOAuthComponent as RenderType_SSOAuthComponent };
function View_SSOAuthComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_SSOAuthComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h4", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Validating token"]))], null, null); }
function View_SSOAuthComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-block eim-button_noggin"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToApp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Go to app"]))], null, null); }
function View_SSOAuthComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "d-flex flex-column"], ["data-test", "sso-validating-token"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["src", "/assets/v1.627.2-beta/assets/img/loading-animations/noggin-waiting.gif"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SSOAuthComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SSOAuthComponent_4)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.showGoToAppButton; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.showGoToAppButton; _ck(_v, 5, 0, currVal_1); }, null); }
function View_SSOAuthComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "d-flex flex-column"], ["data-test", "sso-error-message"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u201CSorry, we were unable to single-sign you in. Please try again or contact your administrator.\u201D"])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "a", [["class", "btn btn-primary home-button"], ["routerLink", "/signin"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Try again"]))], function (_ck, _v) { var currVal_2 = "/signin"; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).target; var currVal_1 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_0, currVal_1); }); }
export function View_SSOAuthComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SSOAuthComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfThen: [1, "ngIfThen"], ngIfElse: [2, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["validatingToken", 2]], null, 0, null, View_SSOAuthComponent_2)), (_l()(), i1.ɵand(0, [["error", 2]], null, 0, null, View_SSOAuthComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showErrorMessage; var currVal_1 = i1.ɵnov(_v, 3); var currVal_2 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_SSOAuthComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sso-auth", [], null, null, null, View_SSOAuthComponent_0, RenderType_SSOAuthComponent)), i1.ɵdid(1, 114688, null, 0, i4.SSOAuthComponent, [i3.ActivatedRoute, i5.SSOAuthService, i6.LoggerService, i7.SessionManagerService, i8.LocationService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SSOAuthComponentNgFactory = i1.ɵccf("app-sso-auth", i4.SSOAuthComponent, View_SSOAuthComponent_Host_0, {}, {}, []);
export { SSOAuthComponentNgFactory as SSOAuthComponentNgFactory };
