import { IInnerOperator, IOperators, reverseOperator } from './operators';
import { OPERATOR_TYPE } from '../../../models/query';
import { Parser } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { isCurrentUserFormula } from '../../../util/current-user-formula';
import { isFormulaSpec } from '../../../util/is-formula-spec';

const personnelContains: IInnerOperator = {
  opNo: 2,
  toFormula: (leftArg, rightArg) => {
    let rightArgFormula = rightArg;
    if (!isFormulaSpec(rightArg) && isCurrentUserFormula(rightArg as string)) {
      rightArgFormula = Parser(rightArg as string);
    }

    // HAS_INTERSECT(
    //    ARRAY(
    //       FIELD("app/personnel:teams", ${ leftArg }),
    //       FIELD("app/personnel:users", ${ leftArg })
    //    ),
    //    ARRAY(
    //       ${ rightArg },
    //       REFERENCES("app/team:roles", ${ rightArg }),
    //       REFERENCES(
    //          "app/team:roles",
    //          REFERENCES("app/team-role:users", ${ rightArg })
    //       )
    //    )
    // )
    return {
      name: 'HAS_INTERSECT',
      args: [
        {
          name: 'ARRAY',
          args: [
            {
              name: 'FIELD',
              args: [
                'app/personnel:teams',
                leftArg
              ]
            },
            {
              name: 'FIELD',
              args: [
                'app/personnel:users',
                leftArg
              ]
            }
          ]
        },
        {
          name: 'ARRAY',
          args: [
            rightArgFormula,
            {
              name: 'REFERENCES',
              args: [
                'app/team:roles',
                rightArgFormula
              ]
            },
            {
              name: 'REFERENCES',
              args: [
                'app/team:roles',
                {
                  name: 'REFERENCES',
                  args: [
                    'app/team-role:users',
                    rightArgFormula
                  ]
                }
              ]
            }
          ]
        }
      ]
    };
  }
};

const personnelNotContains = reverseOperator(personnelContains);

export const personnelOperators: IOperators = {
  operators: {
    [OPERATOR_TYPE.CONTAINS]: personnelContains,
    [OPERATOR_TYPE.NOT_CONTAINS]: personnelNotContains
  },
  getParts: formula => {
    let currentPart = formula;
    let negative = false;

    if (currentPart.name === 'NOT') {
      currentPart = currentPart.args[0];
      negative = true;
    }

    if (currentPart.name === 'HAS_INTERSECT') {
      return {
        operator: negative ? OPERATOR_TYPE.NOT_CONTAINS : OPERATOR_TYPE.CONTAINS,
        leftArg: currentPart.args[0].args[0].args[1],
        rightArg: currentPart.args[1].args[0]
      };
    }

    throw new Error('Unknown operators: ' + currentPart.name);
  }
};
