import { Observable, of } from 'rxjs';
import { switchMap, map, first, catchError } from 'rxjs/operators';
import dataConstants from '../data/constants';
import { EnoFactory } from '../data/EnoFactory';
import { get } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "../data/ensrv.service";
import * as i2 from "../data/process.service";
import * as i3 from "../data/session-manager.service";
import * as i4 from "../data/formula.service";
var USER_LABEL = 'app/security-label/user';
export var SignInStatus;
(function (SignInStatus) {
    SignInStatus["SUCCESS"] = "success";
    SignInStatus["BAD"] = "bad";
    SignInStatus["FAILURE"] = "failure";
    SignInStatus["IN_PROGRESS"] = "inProgress";
    SignInStatus["AWAITING"] = "awaiting";
    SignInStatus["INVITED"] = "invited";
    SignInStatus["SUSPENDED"] = "suspended";
    SignInStatus["REQUESTED"] = "requested";
    SignInStatus["ACCOUNT_LOCK"] = "accountLock";
    SignInStatus["WEAK_PASSWORD"] = "weakPassword";
})(SignInStatus || (SignInStatus = {}));
export var SignOutStatus;
(function (SignOutStatus) {
    SignOutStatus["SUCCESS"] = "Success";
    SignOutStatus["FAILURE"] = "Failure";
})(SignOutStatus || (SignOutStatus = {}));
var varStatusToResultStatusMap = {
    success: SignInStatus.SUCCESS,
    bad: SignInStatus.BAD,
    suspended: SignInStatus.SUSPENDED,
    invited: SignInStatus.INVITED,
    requested: SignInStatus.REQUESTED,
    'awaiting verification': SignInStatus.AWAITING,
    account_lock: SignInStatus.ACCOUNT_LOCK
};
var INVALID_CREDENTIALS = 'error/message/auth/invalid-credential';
var TOO_MANY_ATTEMPTS = 'error/message/auth/too-many-auth-attempts';
var OP_POLICY = dataConstants.SECURITY.OP;
var AuthService = /** @class */ (function () {
    function AuthService(ensrvService, processService, sessionManager, formulaService) {
        this.ensrvService = ensrvService;
        this.processService = processService;
        this.sessionManager = sessionManager;
        this.formulaService = formulaService;
        this._opAuthResetTokenFactory = new EnoFactory('op/auth/reset-token', OP_POLICY);
    }
    /**
     * Returns true if the user is authenticated, otherwise false
     */
    AuthService.prototype.isAuthenticated = function () {
        return this.formulaService.evaluate("HAS_LABELS(\"" + USER_LABEL + "\")").pipe(map(function (formulaResult) {
            return formulaResult[0] === 'true';
        }), catchError(function () {
            return of(false);
        }));
    };
    /**
     * Authenticate the user given an email, password, and optional profile. Returns an observable
     * which will return a positive response if successful, or error if not.
     */
    AuthService.prototype.signIn = function (email, password, profile) {
        var _this = this;
        this.sessionManager.changeToken(null);
        var opGenTokenFactory = new EnoFactory('op/auth/gen-token', OP_POLICY);
        var opGenToken = opGenTokenFactory.setFields([
            { tip: 'op/auth/gen-token:key', value: [email] },
            { tip: 'op/auth/gen-token:secret', value: [password] },
            { tip: 'op/auth/gen-token:payload', value: [email] }
        ]).makeEno();
        return this.ensrvService.send([opGenToken]).pipe(switchMap(function (enos) {
            var errorEno = enos.filter(function (eno) { return get(eno, 'source.type', null) === 'error'; })[0];
            if (errorEno) {
                var errorMessageTip = errorEno.getFieldStringValue('error/message/tip');
                switch (errorMessageTip) {
                    case INVALID_CREDENTIALS:
                        return of(SignInStatus.BAD);
                    case TOO_MANY_ATTEMPTS:
                        return of(SignInStatus.ACCOUNT_LOCK);
                }
            }
            var tokenResponseEno = enos.filter(function (eno) { return get(eno, 'source.type', null) === 'response/auth/gen-token'; })[0];
            var vars = {
                'Auth key': [email],
                'Auth token': [tokenResponseEno.getFieldStringValue('response/auth/gen-token:token')],
            };
            if (profile) {
                vars['Profile tip'] = [profile];
            }
            var lastProfile = _this.sessionManager.getLastProfileWithEmail(email);
            if (lastProfile) {
                vars['Profile tip'] = [lastProfile];
            }
            // Start the signin process with the generated token
            // Login process returns user tip, person tip, and a profile tip
            return _this.processService.start('eim/process/auth/login', vars).pipe(first(function (responseInfo) {
                return responseInfo.finished;
            }), map(function (responseInfo) {
                return _this._processVarsToResultStatus(responseInfo.vars);
            }));
        }));
    };
    AuthService.prototype._processVarsToResultStatus = function (resultVars) {
        if (!resultVars.Status || !resultVars['JWT token'] || varStatusToResultStatusMap[resultVars.Status[0]] === undefined) {
            return SignInStatus.FAILURE;
        }
        this.sessionManager.updateSessionInfoAndRedirectToOriginalRequestedDestination(resultVars['JWT token'][0]);
        return varStatusToResultStatusMap[resultVars.Status[0]];
    };
    /**
     * Sign the user out. Returns an observable when complete.
     */
    AuthService.prototype.signOut = function () {
        var _this = this;
        return this.processService.start('eim/process/auth/logout').pipe(first(function (responseInfo) {
            return responseInfo.finished;
        }), map(function (responseInfo) {
            // in real life this never gets called
            // ensrv sets a token the causes a refresh before we get here
            var success = responseInfo.vars['Status'][0] === SignOutStatus.SUCCESS;
            if (success) {
                _this.sessionManager.updateSessionInfo(null);
            }
            return success;
        }), catchError(function () {
            return of(false);
        }));
    };
    /**
     * Send the user a reset password link. Returns an observable when complete.
     */
    AuthService.prototype.forgotPassword = function (email) {
        return this.processService.start('eim/process/auth/request-forgotten-password', { 'Auth key': [email] }).pipe(first(function (responseInfo) {
            return responseInfo.finished;
        }), map(function () {
            // Always return true for the moment, as regardless of user's status, we always show success message in the screen.
            return true;
        }));
    };
    AuthService.prototype.resetPassword = function (email, newSecret, token) {
        var opEno = this._opAuthResetTokenFactory.setFields([
            { tip: 'op/auth/reset-token:key', value: [email] },
            { tip: 'op/auth/reset-token:token', value: [token] },
            { tip: 'op/auth/reset-token:new-secret', value: [newSecret] }
        ]).makeEno();
        var opTip = opEno.tip;
        var responseObservable = this.ensrvService.send([opEno]).pipe(first(), switchMap(function (enos) {
            var responseEno = enos.filter(function (eno) { return get(eno, 'source.type', null) === 'response/auth/reset-token'; })[0];
            var errorEno = enos.filter(function (eno) { return get(eno, 'source.type', null) === 'error'; })[0];
            if (errorEno && responseEno.getFieldValues('response/auth/reset-token:error')[0] === errorEno.tip) {
                return of(errorEno);
            }
            else {
                return of(null);
            }
        }));
        return responseObservable;
    };
    AuthService.prototype.activateNewEmail = function (oldEmail, newEmail, token) {
        var opEno = this._opAuthResetTokenFactory.setFields([
            { tip: 'op/auth/reset-token:key', value: [oldEmail] },
            { tip: 'op/auth/reset-token:token', value: [token] },
            { tip: 'op/auth/reset-token:new-key', value: [newEmail] }
        ]).makeEno();
        var opTip = opEno.tip;
        var responseObservable = this.ensrvService.getEnoReceiver('response/auth/reset-token').pipe(first(function (responseEno) {
            return responseEno.getFieldStringValue('response/auth/reset-token:op') === opTip;
        }), map(function (responseEno) {
            return responseEno.getFieldValues('response/auth/reset-token:error').length === 0;
        }));
        this.ensrvService.send([opEno]).subscribe();
        return responseObservable;
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.EnsrvService), i0.ɵɵinject(i2.ProcessService), i0.ɵɵinject(i3.SessionManagerService), i0.ɵɵinject(i4.FormulaService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
