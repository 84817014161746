import * as tslib_1 from "tslib";
import { of, throwError } from 'rxjs';
import { first, map, switchMap, filter } from 'rxjs/operators';
import { uniq, flatten, head, sortBy } from 'lodash';
import { flatModuleScheme } from '../../models/module';
import * as i0 from "@angular/core";
import * as i1 from "../../data/query.service";
import * as i2 from "../../data/object-service/object.service";
import * as i3 from "../../data/eno.service";
import * as i4 from "../../data/process.service";
import * as i5 from "../../data/formula.service";
export var MODULE_POLICY_TYPE;
(function (MODULE_POLICY_TYPE) {
    MODULE_POLICY_TYPE["INSTANCE"] = "app/module:instance-policy";
    MODULE_POLICY_TYPE["TYPE"] = "app/module:type-policy";
    MODULE_POLICY_TYPE["WORKFLOW_PROCESS"] = "app/module:workflow-process-policy";
})(MODULE_POLICY_TYPE || (MODULE_POLICY_TYPE = {}));
var ModuleService = /** @class */ (function () {
    function ModuleService(queryService, objectService, enoService, processService, formulaService) {
        this.queryService = queryService;
        this.objectService = objectService;
        this.enoService = enoService;
        this.processService = processService;
        this.formulaService = formulaService;
    }
    ModuleService.prototype.getModules = function (offset, limit) {
        if (offset === void 0) { offset = 0; }
        return this.queryService
            .execute1dArray('eim/query/get-all-modules', {
            dimensionOptions: [{
                    label: 'Tip dimension',
                    formula: 'TIP()',
                    sortby: ['FIELD("app/module:name")'],
                    sortdir: ['asc'],
                    offset: offset,
                    limit: limit
                }]
        })
            .pipe(map(function (modules) { return modules.map(formatToModuleSummary); }));
    };
    ModuleService.prototype.getModulesAndConfigs = function (modules, offset, limit) {
        if (offset === void 0) { offset = 0; }
        var options = {
            dimensionOptions: [{
                    label: 'Tip dimension',
                    formula: 'TIP()',
                    sortby: [],
                    sortdir: ['asc'],
                    offset: offset,
                    limit: limit
                }],
            vars: { 'Module tips': modules }
        };
        return this.queryService
            .execute1dArray('eim/query/get-module-config', options)
            .pipe(map(function (results) {
            var moduleAndConfigs = sortBy(flatten(results
                .map(function (result) {
                return result
                    .configTitles
                    .map(function (_, i) { return ({
                    $tip: head(result.$tip),
                    moduleName: head(result.title),
                    modifiedDate: result.configModifiedDate[i],
                    name: result.configTitles[i],
                    type: result.configType[i]
                }); });
            })), ['moduleName', 'type', 'name']);
            return moduleAndConfigs;
        }));
    };
    ModuleService.prototype.getBlacklistedModuleFieldValues = function (_a) {
        var moduleTip = _a.moduleTip, moduleField = _a.moduleField, _b = _a.offset, offset = _b === void 0 ? 0 : _b, limit = _a.limit;
        return this.queryService.execute1dFirst("eim/query/get-module-" + moduleField, {
            vars: {
                'Module tip': [moduleTip]
            },
            dimensionOptions: [{
                    label: 'Tip dimension',
                    formula: 'TIP()',
                    offset: offset,
                    limit: limit,
                    sortby: ['TITLE()'],
                    sortdir: ['asc']
                }],
            extraFilters: [{
                    label: 'Is in blacklist of provided module',
                    formula: "INARRAY(FIELD(\"app/module:blacklist\", VAR(\"Module tip\")), TIP())"
                }]
        });
    };
    ModuleService.prototype.getModuleFieldValues = function (moduleTip, moduleField, offset, limit) {
        if (offset === void 0) { offset = 0; }
        return this.queryService.execute1dFirst("eim/query/get-module-" + moduleField, {
            vars: {
                'Module tip': [moduleTip]
            },
            dimensionOptions: [{
                    label: 'Tip dimension',
                    formula: 'TIP()',
                    offset: offset,
                    limit: limit,
                    sortby: ['TITLE()'],
                    sortdir: ['asc']
                }]
        });
    };
    ModuleService.prototype.getModule = function (moduleTip) {
        /** @oprime - The following is deliberately commented out so we can revert when saving modules to branches */
        // return this.objectService.getObject<IFlatModule>(moduleTip, flatModuleScheme).pipe(
        //   first(),
        //   switchMap(moduleInMaster => {
        //     return this.objectService.getObject<IModule>(moduleTip, moduleScheme, moduleInMaster.branch);
        //   }),
        //   map(module => setUndefinedFieldsToEmptyArrays<IModule>(module))
        // );
        return this.objectService.getObject(moduleTip, flatModuleScheme).pipe(map(function (module) { return setUndefinedFieldsToEmptyArrays(module); }));
    };
    ModuleService.prototype.updateModule = function (_a) {
        var tip = _a.tip, name = _a.name, description = _a.description, solutions = _a.solutions, _b = _a.unlockModule, unlockModule = _b === void 0 ? false : _b;
        var processVars = {
            'Tip': [tip],
            'Name': [name],
            'Description': [description],
            'Solutions': solutions,
            'Unlock module': [unlockModule]
        };
        return this
            .processService
            .start('eim/process/module/update-module', processVars)
            .pipe(filter(function (_a) {
            var finished = _a.finished;
            return Boolean(finished);
        }), switchMap(function (processResponse) {
            var isCoreModule = processResponse.vars['Is core module'];
            return isCoreModule ? throwError('Unable to update core module') : of(undefined);
        }));
    };
    ModuleService.prototype.getFlatModule = function (moduleTip, useCache) {
        return this.objectService.getObject(moduleTip, flatModuleScheme, undefined, undefined, useCache).pipe(
        /** @oprime - The following is deliberately commented out so we can revert when saving modules to branches */
        // first(),
        // switchMap(moduleInMaster => {
        //   return this.objectService.getObject<IFlatModule>(moduleTip, flatModuleScheme, moduleInMaster.branch);
        // }),
        map(function (module) { return setUndefinedFieldsToEmptyArrays(module); }));
    };
    ModuleService.prototype.getModuleTallies = function (moduleTip) {
        return this
            .queryService
            .execute1dArray('eim/query/get-module-tallies', {
            dimensionOptions: [{
                    label: 'Tip dimension',
                    formula: 'TIP()',
                    sortby: ['TYPE(TIP())'],
                    sortdir: ['asc'],
                    offset: 0
                }],
            vars: { Module: [moduleTip] }
        })
            .pipe(map(function (results) {
            var count = {
                objectTypes: 0,
                lists: 0,
                queries: 0,
                dashboards: 0,
                workflows: 0,
                workflowPanels: 0,
                forms: 0,
                planTemplates: 0,
                messageEmailTemplates: 0,
                messageSmsTemplates: 0,
                charts: 0,
                mapDataSources: 0,
                mapLayers: 0,
                maps: 0,
                sequences: 0,
                riskMatrices: 0,
                messageVoiceTemplates: 0,
                printTemplates: 0,
                printTemplateUsages: 0
            };
            results.forEach(function (result) {
                switch (head(result.$type)) {
                    case 'app/object-type':
                        count.objectTypes += 1;
                        break;
                    case 'app/list':
                        count.lists += 1;
                        break;
                    case 'query':
                        count.queries += 1;
                        break;
                    case 'app/dashboard':
                        count.dashboards += 1;
                        break;
                    case 'app/workflow':
                        count.workflows += 1;
                        break;
                    case 'app/workflow-panel':
                        count.workflowPanels += 1;
                        break;
                    case 'app/form':
                        count.forms += 1;
                        break;
                    case 'app/plan':
                        count.planTemplates += 1;
                        break;
                    case 'app/email/outbound-template':
                        count.messageEmailTemplates += 1;
                        break;
                    case 'app/sms/outbound-template':
                        count.messageSmsTemplates += 1;
                        break;
                    case 'app/chart':
                        count.charts += 1;
                        break;
                    case 'app/map':
                        count.maps += 1;
                        break;
                    case 'app/map/layer':
                        count.mapLayers += 1;
                        break;
                    case 'app/map/source':
                        count.mapDataSources += 1;
                        break;
                    case 'app/sequence':
                        count.sequences += 1;
                        break;
                    case 'app/risk-matrix':
                        count.riskMatrices += 1;
                        break;
                    case 'app/voice-message/outbound-template':
                        count.messageVoiceTemplates += 1;
                        break;
                    case 'app/print-template':
                        count.printTemplates += 1;
                        break;
                    case 'app/print-template-usage':
                        count.printTemplateUsages += 1;
                        break;
                    default: break;
                }
            });
            return count;
        }));
    };
    ModuleService.prototype.getModulePolicy = function (moduleTip, policyType) {
        return this.formulaService
            .evaluate("FIELD(\"" + policyType + "\")", moduleTip)
            .pipe(map(function (formulaResult) { return formulaResult[0]; }));
    };
    ModuleService.prototype.updateFlatModule = function (module) {
        return this.objectService.setObject(module, flatModuleScheme).pipe(map(function () {
            return true;
        }));
    };
    ModuleService.prototype.addTipToFlatModuleField = function (_a) {
        var _this = this;
        var moduleTip = _a.moduleTip, moduleField = _a.moduleField, tip = _a.tip;
        return this.getFlatModule(moduleTip).pipe(first(), switchMap(function (module) {
            module[moduleField] = uniq(tslib_1.__spread(module[moduleField], [tip]));
            return _this.updateFlatModule(module);
        }));
    };
    ModuleService.prototype.createModule = function (name, description) {
        var processVars = {
            'Name': [name],
            'Description': [description]
        };
        return this.processService
            .start('eim/process/module/create-module', processVars)
            .pipe(filter(function (_a) {
            var finished = _a.finished;
            return Boolean(finished);
        }), switchMap(function (processResponse) {
            var moduleTip = processResponse.vars['Module']
                ? processResponse.vars['Module'][0]
                : null;
            if (!moduleTip) {
                return throwError('Failed to create module');
            }
            return of(moduleTip);
        }));
    };
    ModuleService.prototype.publishModule = function (module) {
        return this.enoService.mergeBranch(module.branch);
    };
    ModuleService.prototype.deleteModuleConfig = function (tips) {
        var processTip = 'eim/process/module/delete-module-config';
        var processVars = { 'Config tips': tips };
        return this
            .processService
            .start(processTip, processVars)
            .pipe(filter(function (_a) {
            var finished = _a.finished;
            return Boolean(finished);
        }), switchMap(function (processResponse) {
            var isModuleConfigPatched = head(processResponse.vars['Is module config patched']);
            if (!isModuleConfigPatched) {
                return throwError('Failed to patch module config');
            }
            return of(undefined);
        }));
    };
    ModuleService.prototype.deleteModule = function (tip) {
        var _this = this;
        return this.getFlatModule(tip, true)
            .pipe(switchMap(function (flatModule) {
            // -!-!-!- DELETE BACKUP OPTION AFTER MIGRATION - EIM-2691 -!-!-!-
            if (!flatModule.instancePolicy ||
                !flatModule.typePolicy ||
                !flatModule.workflowProcessPolicy ||
                !flatModule.labels) {
                return _this.objectService.deleteObject({ $tip: tip });
            }
            var vars = { 'Module': [tip] };
            return _this.processService
                .start('eim/process/module/delete-module', vars)
                .pipe(filter(function (_a) {
                var finished = _a.finished;
                return Boolean(finished);
            }), switchMap(function (processResponse) {
                var isDeleted = !!processResponse.vars['Is deleted'] && !!processResponse.vars['Is deleted'][0];
                if (!isDeleted) {
                    return throwError('Failed to delete module');
                }
                return of(undefined);
            }));
        }));
    };
    ModuleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ModuleService_Factory() { return new ModuleService(i0.ɵɵinject(i1.QueryService), i0.ɵɵinject(i2.ObjectService), i0.ɵɵinject(i3.EnoService), i0.ɵɵinject(i4.ProcessService), i0.ɵɵinject(i5.FormulaService)); }, token: ModuleService, providedIn: "root" });
    return ModuleService;
}());
export { ModuleService };
function formatToModuleSummary(module) {
    return {
        $tip: head(module.$tip),
        name: head(module.name),
        description: head(module.description),
        solutions: module.solutionNames.length
            ? sortBy(module.solutionNames.map(function (name, i) { return ({ name: name, color: module.solutionColors[i] }); }), ['name'])
            : []
    };
}
// tslint:disable-next-line: cyclomatic-complexity
function setUndefinedFieldsToEmptyArrays(module) {
    module.objectTypes = module.objectTypes || [];
    module.lists = module.lists || [];
    module.workflows = module.workflows || [];
    module.queries = module.queries || [];
    module.dashboards = module.dashboards || [];
    module.workflowPanels = module.workflowPanels || [];
    module.forms = module.forms || [];
    module.planTemplates = module.planTemplates || [];
    module.messageEmailTemplates = module.messageEmailTemplates || [];
    module.messageSmsTemplates = module.messageSmsTemplates || [];
    module.charts = module.charts || [];
    module.mapDataSources = module.mapDataSources || [];
    module.mapLayers = module.mapLayers || [];
    module.maps = module.maps || [];
    module.sequences = module.sequences || [];
    module.riskMatrices = module.riskMatrices || [];
    module.messageVoiceTemplates = module.messageVoiceTemplates || [];
    module.printTemplates = module.printTemplates || [];
    module.printTemplateUsages = module.printTemplateUsages || [];
    return module;
}
