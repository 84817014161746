import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OUTCOME_LABEL_MAP } from '../workflow-designer-enums';

export interface ILinkConfig {
  outcome: string;
  delaySec: number;
}

@Component({
  selector: 'app-link-editor',
  templateUrl: './link-editor.component.html',
  styleUrls: ['./link-editor.component.scss']
})
export class LinkEditorComponent implements OnInit { // This component is a SideSheet
  propsForm: FormGroup = new FormGroup({
    outcome: new FormControl('', Validators.required),
    delaySec: new FormControl(0)
  });
  outcomeOptions: {
    label: string;
    value: string;
  }[] = [];

  // Mandatory variables to give. Once give it, do not modify or read it.
  outcome: string;
  delaySec: number;
  outcomeCandidates: string[];
  done: (data: ILinkConfig) => void = () => { };

  constructor() { }

  ngOnInit() {
    this.propsForm.setValue({ outcome: this.outcome, delaySec: this.delaySec });

    this.outcomeOptions = this.outcomeCandidates.map((outcome) => {
      return {
        label: OUTCOME_LABEL_MAP[outcome] || outcome,
        value: outcome
      };
    });
  }

  onDone() {
    this.done({
      outcome: this.propsForm.controls.outcome.value,
      delaySec: this.propsForm.controls.delaySec.value
    });
  }

}
