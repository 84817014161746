import { IFormOption } from '../models/form';

export const FormColors: IFormOption[] = [
  { label: 'blue', value: '#029CBE' },
  { label: 'aqua Green', value: '#02BE9C' },
  { label: 'green', value: '#00b777' },
  { label: 'red', value: '#e65a5a' },
  { label: 'yellow', value: '#f2c329' },
  { label: 'orange', value: '#f27e29' },
  { label: 'black', value: '#111111' },
  { label: 'grey', value: '#c2c2c2' }
];

export const LaunchPadColors: IFormOption[] = [
  { label: 'blue', value: '#EEF1F8' },
  { label: 'dark blue', value: '#A7BFDB' },
  { label: 'mint green', value: '#87CFC9' },
  { label: 'pink', value: '#CF87B8' },
  { label: 'lime green', value: '#D7F07C' }
];

export const LaunchPadTitleColors: IFormOption[] = [
  { label: 'black', value: '#6b6b6b' },
  { label: 'white', value: '#FFFFFF' }
];

export const ButtonWidgetColors: IFormOption[] = [
  { label: 'blue', value: '#57C4D7' },
  { label: 'mint green', value: '#76DFAB' },
  { label: 'yellow', value: '#FAD84E' },
  { label: 'orange', value: '#F08F35' },
  { label: 'salmon red', value: '#EC575C' },
  { label: 'violet', value: '#D93CF4' },
  { label: 'black', value: '#000000' },
  { label: 'grey', value: '#C8C8C8' }
];

export const FormIcons: IFormOption[] = [
  { label: 'Home', value: 'home', icon: 'mdi-home' },
  { label: 'Star', value: 'star', icon: 'mdi-star' },
  { label: 'Case', value: 'case', icon: 'mdi-case' },
  { label: 'Folder', value: 'folder', icon: 'mdi-folder' },
  { label: 'Account', value: 'account', icon: 'mdi-account' },
  { label: 'Face', value: 'face', icon: 'mdi-face' },
  { label: 'Comment', value: 'comment', icon: 'mdi-comment' },
  { label: 'Settings', value: 'settings', icon: 'mdi-settings' },
  { label: 'Calendar', value: 'calendar', icon: 'mdi-calendar' },
  { label: 'Time', value: 'time', icon: 'mdi-time' }
];

export const SITE_SELECTOR_PAGE_URL = 'https://signin.noggin.io?autoRedirect=false';

export enum LoadingState {
  inProgress = 'in-progress',
  failed = 'failed',
  loaded = 'loaded',
  empty = 'empty'
}

export const ASTERISKS = '**********';

export const DEBOUNCE_TIME = 150;

export const VOICE_MESSAGE_LANGUAGES: IFormOption[] = [
  { value: 'zh-CN', label: 'Chinese (Mandarin)'},
  { value: 'en-US', label: 'English, United States'},
  { value: 'fr-FR', label: 'French, France'},
  { value: 'es-US', label: 'Spanish, American'}
];

export const MESSAGE_OUT_BOUND_ENOS_TYPE = ['app/email/outbound', 'app/sms/outbound'];

export const MESSAGE_IN_BOUND_ENOS_TYPE = ['app/email/inbound', 'app/sms/inbound'];

export enum DateValidation {
  none = 3,
  notAllowFuture = 1,
  notAllowPast = 2,
}

export const DATE_VALIDATION_OPTIONS = [{
  value: DateValidation.notAllowFuture,
  label: 'Do not allow dates in the future'
}, {
  value: DateValidation.notAllowPast,
  label: 'Do not allow dates in the past'
}];
