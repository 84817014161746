import { DataTypes, IObjectScheme } from '../data/models/scheme';
import { IObject } from '../data/models/object';
import { IField, fieldScheme, ON_DELETE_OPTIONS } from './field';
import { ISupertype, supertypeScheme } from './supertype';
import { Tip } from '../data/models/types';

export interface IType extends IObject {
  label: string;
  name?: string;
  description: string;
  title: string;
  summary: string;
  type?: string;
  auditLog?: boolean;
  field?: IField[];
  supertypes?: ISupertype[];
  searchableMetafield?: Tip[];
  typerestrict?: string;
}

export const typeScheme: IObjectScheme = {
  'type/label': { type: DataTypes.i18n },
  'type/name': { type: DataTypes.string },
  'type/description': { type: DataTypes.i18n },
  'type/title': { type: DataTypes.i18n },
  'type/summary': { type: DataTypes.i18n },
  'type/audit-log': { type: DataTypes.boolean },
  'type/field': {
    type: DataTypes.objectArray,
    mutable: true,
    scheme: fieldScheme
  },
  'type/implements': {
    name: 'supertypes',
    type: DataTypes.objectArray,
    scheme: supertypeScheme
  },
  'type/searchable-metafield': {
    type: DataTypes.stringArray,
  }
};

