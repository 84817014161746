import { Injectable } from '@angular/core';
import { IWorkflow } from '../../../models/workflow';
import { CompositeNodeConverter, INodeAndLink } from './CompositeNodeConverter';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import { IProcessConnection, IProcessNodeSetVariable, IProcessNodeSubprocess, IProcessNodeSwitch } from '../../../models/process';
import dataConstants from '../../../data/constants';
import { CONTEXT_VAR_NAME } from '../../../message/message-utils.service';
import { get } from 'lodash';
import { ISendVoiceMessageEditor } from '../node-editors/send-voice-message-editor/send-voice-message-editor.component';
import {
  createConnection,
  createFormulaFromStringInterpolation,
  createSetVarNode,
  createSubProcessNode,
  createSwitchNode
} from './utils/create-node-utils';
import { Parser, Stringify } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';

@Injectable({
  providedIn: 'root'
})
export class SendVoiceMessageConverterService extends CompositeNodeConverter {

  constructor() {
    super();
  }

  convert(nodeData: ISendVoiceMessageEditor, workflow: IWorkflow): INodeAndLink {
    if (!this.isValid(nodeData)) {
      throw new InvalidWorkflowNodeDataError(`Please correct invalid data on send Voice message node`, nodeData);
    }

    // var node
    const vars = {
      'Outbound source tip': `ARRAY("${nodeData.fields.outboundSource.$tip}")`,
      'Dashboard context tip': get(nodeData.fields.outboundSource, 'contextFormula', 'ARRAY()')
    };
    const setVarNode = createSetVarNode(vars, nodeData.tip);

    // sub process node
    const additionalVarKeys: string[] = [
      ...workflow.inputs.map(input => input.key),
      ...workflow.actors.map(actor => actor.key),
      ...workflow.variables.map(variable => variable.key)
    ];
    const subProcessNode = createSubProcessNode('eim/process/message/send-voice-message', vars);
    subProcessNode.varKeys = [...subProcessNode.varKeys, ...additionalVarKeys]; // mutating list with desired options.

    // switch node
    const switchNode = createSwitchNode({name: 'VAR', args: ['Status']});
    switchNode.switchCases = ['Sent', 'Failed'];  // mutating list with desired options.

    // link from setvar to sub-process
    const doneConnectionForSetVarNode = createConnection('done', subProcessNode);
    setVarNode.connections.push(doneConnectionForSetVarNode);

    // link from sub-process to switch
    const doneConnectionForSubprocessNode = createConnection('done', switchNode);
    subProcessNode.connections.push(doneConnectionForSubprocessNode);

    const nodesAndLink: INodeAndLink = {
      nodes: [
        setVarNode,
        subProcessNode,
        switchNode
      ],
      links: [
        doneConnectionForSetVarNode,
        doneConnectionForSubprocessNode
      ]
    };

    this.populateOutgoingLinks(nodeData).forEach(link => {
      switchNode.connections.push(link);
      nodesAndLink.links.push(link);
    });

    return nodesAndLink;
  }

  private isValid(nodeData: ISendVoiceMessageEditor): boolean {
    return !!nodeData.fields.outboundSource.$tip;
  }
}
