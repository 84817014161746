import { Injectable } from '@angular/core';
import {
  CanLoad,
  Route, Router,
  UrlSegment,
} from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShellRedirectGuard implements CanLoad {
  constructor(private router: Router) { }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    if ((segments[0].path === 'app') && (segments[1].path !== 's')) {
      const newUrl = segments.reduce((acc, segment) => {
        if (segment.path === 'app') {
          return `${acc}/${segment.toString()}/s`;
        }
        return `${acc}/${segment.toString()}`;
      }, '');

      this.router.navigateByUrl(newUrl);
      return false;
    }

    return true;
  }
}
