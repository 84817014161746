<div class="auth-container d-flex flex-column">
  <div class="auth-layout">
    <header class="auth-header d-flex justify-content-center">
      <img src="/assets/v1.627.2-beta/assets/img/logo.svg" alt="logo" class="logo-img">&nbsp; <span class="header-divider"> | </span>&nbsp;
      <span
          i18n="@@authChrome.headerText"
          class="header-text">{formType, select, Login {Login} other {{{formType}}}}</span>
    </header>
    <main>
      <h6 class="mb-4 text-center px-5" *ngIf="primaryTitle">{{ primaryTitle }}</h6>
      <div class="px-sm-5">
        <app-alert
            *ngIf="showMessage"
            type="danger">
          <ng-container *ngIf="message; then messageText; else messageContent"></ng-container>
          <ng-template #messageText>{{ message }}</ng-template>
          <ng-template #messageContent>
            <ng-content select="[eim-i18n-property=message]"></ng-content>
          </ng-template>
        </app-alert>
        <p
            *ngIf="showAuthErrorDesc"
            class="text-center px-5 pb-3">
          <ng-content select="[eim-i18n-property=authErrorDesc]"></ng-content>
        </p>
      </div>
      <div class="px-5 pb-3">
        <h6 class="mb-4 text-center">
          <ng-container *ngIf="secondaryTitle; then secondaryTitleText; else secondaryTitleContent"></ng-container>
          <ng-template #secondaryTitleText>{{ secondaryTitle }}</ng-template>
          <ng-template #secondaryTitleContent>
            <ng-content select="[eim-i18n-property=secondaryTitle]"></ng-content>
          </ng-template>
        </h6>
        <ng-content></ng-content>
      </div>
    </main>
  </div>
  <footer>
    <div class="d-block d-sm-none my-4 text-center copyright-text">
      <span>Copyright &copy; {{ currentYear }} Noggin - <a i18n="@@PrivacyPolicy" href="https://www.noggin.io/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></span>
    </div>
    <img src="/assets/v1.627.2-beta/assets/img/auth-footer.svg" alt="footer" class="w-100">
    <div class="d-none d-sm-block copyright copyright-text">
      Copyright &copy; {{ currentYear }} Noggin - <a i18n="@@PrivacyPolicy" href="https://www.noggin.io/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
    </div>
  </footer>
</div>
