import { Injectable } from '@angular/core';
import { ObjectService } from './object-service/object.service';
import { ILocale, localeScheme } from '../models/locale';
import { map, catchError, first } from 'rxjs/operators';
import { defaults, head } from 'lodash';
import { of, ReplaySubject } from 'rxjs';
import dataConstants from './constants';
import { Batch } from './models/types';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  localeSubject = new ReplaySubject<ILocale>(1);

  constructor(
    private objectService: ObjectService
  ) {
    this.objectService.getObject<ILocale>('app/locale', localeScheme).pipe(
      map((value: ILocale) => {
        return defaults(value, defaultLocaleSettings);
      }),
      catchError((error) => of(defaultLocaleSettings)),
      first()
    ).subscribe(
      (value: ILocale) => {
        this.localeSubject.next(defaults(value, defaultLocaleSettings));
      }
    );
  }

  update(locale: ILocale) {
    return this.objectService.setObject(locale, localeScheme).pipe(
      first(),
      map((batch: Batch) => {
        if (batch.length > 0) {
          return head(batch).tip;
        }
      })
    );
  }
}

export const defaultLocaleSettings: ILocale = {
  localeId: dataConstants.LANG_DEFAULT,
  timezone: 'Australia/Sydney',
  dateformat: 'DD MMM YYYY',
  datetimeformat: 'DD MMM YYYY HH:mm'
};
