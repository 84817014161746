import { Injectable } from '@angular/core';
import { NodeConverterBase } from './NodeConverterBase';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import { IProcessNodeDuplicate } from '../../../models/process';
import { IDuplicateObjectEditor } from '../node-editors/duplicate-object-editor/duplicate-object-editor.component';
import { isEmpty } from 'lodash';
import dataConstants from '../../../data/constants';

@Injectable({
  providedIn: 'root'
})
export class DuplicateObjectNodeConverterService extends NodeConverterBase {
  type = 'processnode/data/duplicate';
  processNodeType = 'processnodetype/data/duplicate';

  public convert(nodeData: IDuplicateObjectEditor): IProcessNodeDuplicate {
    if (!this.isValid(nodeData)) {
      throw new InvalidWorkflowNodeDataError(`Please correct invalid data on duplicate object node [${nodeData.fields.title}]`, nodeData);
    }

    return {
      $type: this.type,
      $security: dataConstants.SECURITY.INSTANCE_USER_ADMIN,
      type: this.processNodeType,
      connections: [],
      point: [0, 0],
      key: nodeData.tip,
      duplicateTip: [nodeData.fields.objectToDuplicate.formula],
      duplicateFields: nodeData.fields.objectFields.map(o => o.tip),
      duplicateTipVar: nodeData.fields.variableToSetKey,
      duplicateSourceBranch: dataConstants.BRANCH_MASTER,
      duplicateDestBranch: dataConstants.BRANCH_MASTER,
      duplicateBackwardRefs: false,
      duplicateTipVarScope: 'Global',
    };
  }

  private isValid(nodeData: IDuplicateObjectEditor): boolean {
    return !isEmpty(nodeData.fields.objectToDuplicate ? nodeData.fields.objectToDuplicate.formula : null);
  }

  constructor() {
    super();
  }
}
