import { EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { isEqual, uniqueId } from 'lodash';
import { LoadingState } from '../../shared/constants';
import { SideSheetListItemComponent, ToggleType } from '../side-sheet-list-item/side-sheet-list-item.component';
export var SideSheetListModes;
(function (SideSheetListModes) {
    SideSheetListModes["DRAG"] = "drag";
    SideSheetListModes["MULTI_SELECT"] = "multiselect";
    SideSheetListModes["SINGLE_SELECT"] = "singleselect";
    SideSheetListModes["TALLY"] = "tally";
    SideSheetListModes["NONE"] = "none";
})(SideSheetListModes || (SideSheetListModes = {}));
var SideSheetListComponent = /** @class */ (function () {
    function SideSheetListComponent(dragulaService) {
        this.dragulaService = dragulaService;
        this.recentMode = SideSheetListModes.NONE;
        this.mode = SideSheetListModes.NONE;
        this.selectedChange = new EventEmitter();
        this.showMore = new EventEmitter();
        this.showMoreButton = false;
        /**
         * If true, will emit 'selectedChange' event as soon as the page loads, which may cause issues in your logic
         * as 'selected' will always be empty. This is why 'emitChangeEventOnLoad' variable was added, so it can be switched off.
         */
        this.emitChangeEventOnLoad = true;
        /*
         * can be used to pass in preselected values
         */
        this.selected = [];
        /**
         * requiredSingleSelect
         * Only has affect when mode === SideSheetListModes.SINGLE_SELECT.
         * If true, will toggle item on/off when clicked.
         * If false, will not let user deselect (like a radio button).
         */
        this.requiredSingleSelect = false;
        this.loadingState = LoadingState.loaded;
        this.draggableModel = [];
        this.draggableModelChange = new EventEmitter();
        this.listItems = [];
        this.dragGroupName = uniqueId('list-dragula-group-');
        this.SideSheetListModes = SideSheetListModes;
        this.subscriptions = new Subscription();
    }
    Object.defineProperty(SideSheetListComponent.prototype, "initItems", {
        set: function (listItems) {
            this.listItems = listItems;
            if (!listItems.length) {
                return;
            }
            this.updateListItems(listItems, this.recentMode);
        },
        enumerable: true,
        configurable: true
    });
    SideSheetListComponent.prototype.ngOnChanges = function (changes) {
        if (changes.mode) {
            this.recentMode = changes.mode.currentValue;
            if (!this.listItems) {
                return;
            }
            this.updateListItems(this.listItems, changes.mode.currentValue);
            this.prepareDragulaGroups();
        }
    };
    SideSheetListComponent.prototype.clearSelected = function () {
        if (!this.listItems) {
            return;
        }
        this.listItems.forEach(function (item) { return item.value = false; });
        this.selected = [];
    };
    SideSheetListComponent.prototype.updateListItems = function (listItems, mode) {
        var _this = this;
        if (mode === void 0) { mode = SideSheetListModes.NONE; }
        listItems.forEach(function (listItem) {
            // no need to define item types if it is draggable
            if (mode === SideSheetListModes.DRAG) {
                listItem.toggleType = ToggleType.DRAG;
                return;
            }
            if (mode === SideSheetListModes.NONE) {
                listItem.toggleType = ToggleType.NO_TOGGLE;
                listItem.onToggle = function () { };
            }
            else if (mode === SideSheetListModes.SINGLE_SELECT) {
                listItem.toggleType = ToggleType.SINGLE_CHECK;
                listItem.onToggle = function () { return _this.toggleItem(listItem, mode); };
            }
            else if (mode === SideSheetListModes.MULTI_SELECT) {
                listItem.onToggle = function () { return _this.toggleItem(listItem, mode); };
                listItem.toggleType = ToggleType.MULTI_CHECK;
            }
            else if (mode === SideSheetListModes.TALLY) {
                listItem.toggleType = ToggleType.NUMBER;
            }
        });
        // init array of selected values if it is not provided
        if ([SideSheetListModes.SINGLE_SELECT, SideSheetListModes.MULTI_SELECT].indexOf(mode) > -1) {
            if (!this.selected.length) {
                this.selected = this.listItems.filter(function (item) { return !!item.value; }).map(function (item) { return item.key; });
            }
            else {
                if (mode === SideSheetListModes.SINGLE_SELECT && this.selected.length > 1) {
                    this.selected.splice(1);
                }
                this.listItems.forEach(function (item) {
                    item.value = _this.selected.filter(function (selectedItem) { return isEqual(selectedItem, item.key); }).length > 0;
                });
            }
            if (this.emitChangeEventOnLoad) {
                this.selectedChange.emit(this.selected);
            }
        }
        // needed to avoid ExpressionChangedAfterItHasBeenCheckedError on list item component
        listItems.forEach(function (listItem) {
            listItem.detectChanges();
        });
    };
    SideSheetListComponent.prototype.prepareDragulaGroups = function () {
        var _this = this;
        if (this.mode === SideSheetListModes.DRAG) {
            this.dragulaService.destroy(this.dragGroupName);
            this.dragulaService.createGroup(this.dragGroupName, {
                moves: function (el, container, handle) {
                    return (handle.classList.contains('handle') ||
                        (handle.parentElement && handle.parentElement.classList.contains('handle')) ||
                        (handle.parentElement && handle.parentElement.parentElement && handle.parentElement.parentElement.classList.contains('handle')));
                },
                direction: 'vertical'
            });
            this.subscriptions.add(this.dragulaService.drop(this.dragGroupName).subscribe(function () {
                _this.draggableModelChange.emit(_this.draggableModel);
            }));
        }
    };
    SideSheetListComponent.prototype.ngOnDestroy = function () {
        this.dragulaService.destroy(this.dragGroupName);
        this.subscriptions.unsubscribe();
    };
    SideSheetListComponent.prototype.toggleItem = function (listItem, mode) {
        if (listItem.disabled) {
            return;
        }
        if (mode === SideSheetListModes.SINGLE_SELECT) {
            // don't allow deselecting item if it is required single select
            if (!this.requiredSingleSelect || !listItem.value) {
                this.toggleSingleSelect(listItem);
            }
        }
        else if (mode === SideSheetListModes.MULTI_SELECT) {
            this.toggleMultiSelect(listItem);
        }
        this.selectedChange.emit(this.selected);
    };
    SideSheetListComponent.prototype.toggleSingleSelect = function (listItem) {
        // uncheck all items
        this.listItems.filter(function (item) { return item !== listItem; }).forEach(function (item) { return item.value = false; });
        // toggle item
        listItem.value = !listItem.value;
        // update list of selected keys
        this.selected = listItem.value ? [listItem.key] : [];
    };
    SideSheetListComponent.prototype.toggleMultiSelect = function (listItem) {
        listItem.value = !listItem.value;
        // update list of selected keys
        var index = this.selected.indexOf(listItem.key);
        if (!listItem.value && index > -1) {
            this.selected.splice(index, 1);
        }
        else if (index === -1) {
            this.selected.push(listItem.key);
        }
    };
    SideSheetListComponent.prototype.toggleAll = function (state) {
        var _this = this;
        if (state) {
            this.listItems.forEach(function (item) {
                item.value = true;
                if (!_this.selected.includes(item.key)) {
                    _this.selected.push(item.key);
                }
            });
        }
        else {
            this.selected = [];
            this.listItems.forEach(function (item) { return item.value = false; });
        }
        this.selectedChange.emit(this.selected);
    };
    SideSheetListComponent.prototype.onShowMore = function () {
        this.showMore.emit();
    };
    return SideSheetListComponent;
}());
export { SideSheetListComponent };
