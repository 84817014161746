import * as tslib_1 from "tslib";
import { reverse } from 'lodash';
import { Parser, Stringify } from '../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { getMostInnerContextPartsFromContextFormula } from './context-formula-wrapper';
export function getFieldTipsFromFormulaString(formulaStr) {
    if (!!formulaStr) {
        return getFieldTipsFromFormulaSpec(Parser(formulaStr));
    }
    return [];
}
// formula spec is what the Parser returns
export function getFieldTipsFromFormulaSpec(formulaSpec) {
    if (formulaSpec.name === 'CONTEXT') {
        var _a = getMostInnerContextPartsFromContextFormula(Stringify(formulaSpec)), outerFormulas = _a.outerFormulas, mostInnerContextFormula = _a.mostInnerContextFormula;
        return tslib_1.__spread([mostInnerContextFormula], reverse(outerFormulas)).reduce(function (acc, formula) {
            var parsedFormula = Parser(formula);
            if (parsedFormula.name === 'FIELD' || parsedFormula.name === 'FMT_FIELD') {
                return tslib_1.__spread(acc, [parsedFormula.args[0]]);
            }
            return acc;
        }, []);
    }
    return backwardsCompatible(formulaSpec);
}
// below are for backwards compatible
function backwardsCompatible(formulaSpec) {
    if (formulaSpec.name !== 'FIELD' && formulaSpec.name !== 'FMT_FIELD') {
        return [];
    }
    return formulaSpec.args.reduce(function (acc, formulaArg, index) {
        // we want the first string value
        if (typeof formulaArg === 'string' && index === 0) {
            return tslib_1.__spread(acc, [formulaArg]);
        }
        // if the other values are objects re-run them
        if (typeof formulaArg === 'object') {
            var fieldTips = getFieldTipsFromFormulaSpec(formulaArg);
            return tslib_1.__spread(fieldTips, acc);
        }
        // not the first string or an object just ignore
        return acc;
    }, []);
}
