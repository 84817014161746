import { OnDestroy } from '@angular/core';
import { map, first, switchMap } from 'rxjs/operators';
import { ReplaySubject, of } from 'rxjs';
import { ModuleValidationError } from '../../data/errors/client/ModuleValidationError';
import * as i0 from "@angular/core";
import * as i1 from "../../data/query.service";
import * as i2 from "../../util/feature-flag/feature-flag.service";
var ModulePackageService = /** @class */ (function () {
    function ModulePackageService(queryService, featureFlagService) {
        this.queryService = queryService;
        this.featureFlagService = featureFlagService;
        this.allModuleStates = new ReplaySubject(1);
        this.allModuleStates$ = this.allModuleStates.asObservable();
    }
    ModulePackageService.prototype.isModuleUnlocked = function (moduleTip, defaultEnabled) {
        if (defaultEnabled === void 0) { defaultEnabled = false; }
        var getModuleLockedState$ = this.allModuleStates$
            .pipe(map(function (allModuleStatesMap) {
            if (allModuleStatesMap.has(moduleTip)) {
                return allModuleStatesMap.get(moduleTip);
            }
            return defaultEnabled;
        }));
        return this
            .featureFlagService
            .getFeatureFlag$('feature-check-module-locked-state')
            .pipe(first(), switchMap(function (isFeatureFlagActive) {
            return isFeatureFlagActive
                ? getModuleLockedState$
                : of(true);
        }));
    };
    ModulePackageService.prototype.loadAllModuleStates = function () {
        var _this = this;
        this.subscription = this.queryService.execute1dFirst('eim/query/get-all-module-states-query').pipe(map(function (moduleStates) { return moduleStates.reduce(function (allModuleStatesMap, moduleState) {
            return allModuleStatesMap.set(moduleState.$tip, moduleState.isUnlocked === '1');
        }, new Map()); })).subscribe(function (allModuleStatesMap) {
            _this.allModuleStates.next(allModuleStatesMap);
        });
    };
    ModulePackageService.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    ModulePackageService.prototype.handleError = function (action, moduleTip, error) {
        return this
            .isModuleUnlocked(moduleTip)
            .pipe(first(), map(function (isUnlocked) {
            if (!isUnlocked) {
                throw new ModuleValidationError('Unable to make changes to the configuration of a locked module.');
            }
            if (error) {
                throw error;
            }
            throw new Error("Unable to " + action + " at this time.");
        }));
    };
    ModulePackageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ModulePackageService_Factory() { return new ModulePackageService(i0.ɵɵinject(i1.QueryService), i0.ɵɵinject(i2.FeatureFlagService)); }, token: ModulePackageService, providedIn: "root" });
    return ModulePackageService;
}());
export { ModulePackageService };
