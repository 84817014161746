import * as tslib_1 from "tslib";
import { clone } from 'lodash';
var AccessDeniedError = /** @class */ (function (_super) {
    tslib_1.__extends(AccessDeniedError, _super);
    function AccessDeniedError(enoTipOrSid, branches) {
        var _this = _super.call(this) || this;
        _this.message = branches === undefined ? 'Access denied on sid ' + enoTipOrSid :
            'Access denied on tip and branch ' + enoTipOrSid + ', ' + branches.toString();
        Object.setPrototypeOf(_this, AccessDeniedError.prototype);
        _this.name = 'AccessDeniedError';
        if (branches !== undefined) {
            _this.tip = enoTipOrSid;
            _this.branches = clone(branches);
            return _this;
        }
        _this.sid = enoTipOrSid;
        return _this;
    }
    return AccessDeniedError;
}(Error));
export { AccessDeniedError };
