import { first, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { get } from 'lodash';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../data/process.service";
import * as i2 from "../dom-tokens";
var CheckSSOProvidersService = /** @class */ (function () {
    function CheckSSOProvidersService(processService, _window) {
        this.processService = processService;
        this._window = _window;
    }
    CheckSSOProvidersService.prototype.checkEmailAddressAndRedirect$ = function (emailAddress) {
        var _this = this;
        var vars = { 'email': [emailAddress] };
        return this.processService
            .start('eim/process/sso/get-user-provider', vars)
            .pipe(first(), map(function (result) { return ({
            email: get(result, 'vars.email[0]', null),
            tip: get(result, 'vars.tip[0]', null),
            type: get(result, 'vars.type[0]', null)
        }); }), switchMap(function (params) {
            var tip = params.tip;
            var email = params.email;
            var type = params.type;
            if (type === 'noggin' && environment.enCloudEnv !== 'development') {
                // Always use the Noggin idP for Noggin users
                tip = 'app/sso/saml/idp/noggin';
            }
            if (tip && type) {
                var namespace = environment.ns;
                var serviceUri = environment.host;
                var url = serviceUri + '/saml-sp/login'
                    + '?ns=' + encodeURIComponent(namespace)
                    + '&idp=' + encodeURIComponent(tip)
                    + '&email=' + encodeURIComponent(email);
                _this._window.location.href = url;
                return of(false);
            }
            return of(true);
        }));
    };
    CheckSSOProvidersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CheckSSOProvidersService_Factory() { return new CheckSSOProvidersService(i0.ɵɵinject(i1.ProcessService), i0.ɵɵinject(i2.WINDOW)); }, token: CheckSSOProvidersService, providedIn: "root" });
    return CheckSSOProvidersService;
}());
export { CheckSSOProvidersService };
