import * as tslib_1 from "tslib";
import { NodeConverterBase } from './NodeConverterBase';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import dataConstants from '../../../data/constants';
import * as i0 from "@angular/core";
var DeleteNodeConverterService = /** @class */ (function (_super) {
    tslib_1.__extends(DeleteNodeConverterService, _super);
    function DeleteNodeConverterService() {
        var _this = _super.call(this) || this;
        _this.type = 'processnode/data/delete';
        _this.processNodeType = 'processnodetype/data/delete';
        return _this;
    }
    DeleteNodeConverterService.prototype.convert = function (nodeData) {
        if (!this.isValid(nodeData)) {
            throw new InvalidWorkflowNodeDataError("Please correct invalid data on delete node [" + nodeData.fields.title + "]", nodeData);
        }
        return {
            $type: this.type,
            $security: dataConstants.SECURITY.INSTANCE_USER_ADMIN,
            type: this.processNodeType,
            connections: [],
            point: [0, 0],
            key: nodeData.tip,
            deleteTip: nodeData.fields.objects.map(function (x) { return x.formula; }),
            deleteSrcBranch: dataConstants.BRANCH_MASTER,
            deleteDestBranch: dataConstants.BRANCH_MASTER,
            deleteSecurity: 'app/security-policy/instance-user-user' // @toDo implement proper security
        };
    };
    DeleteNodeConverterService.prototype.isValid = function (nodeData) {
        return !(nodeData.fields.objects.length === 0);
    };
    DeleteNodeConverterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DeleteNodeConverterService_Factory() { return new DeleteNodeConverterService(); }, token: DeleteNodeConverterService, providedIn: "root" });
    return DeleteNodeConverterService;
}(NodeConverterBase));
export { DeleteNodeConverterService };
