import { distinctUntilChanged } from 'rxjs/operators';
import { Eno } from '../data/models/Eno';
import { isEqual } from 'lodash';

export function distinctUntilSidsChanged() {
  return distinctUntilChanged((prev: Eno[], next: Eno[]) => {
    const previousSids = prev.map(mapSids);
    const nextSids = next.map(mapSids);

    return isEqual(previousSids, nextSids);
  });
}

function mapSids(eno: Eno) {
  return eno.sid;
}
