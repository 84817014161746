import { INodeEditorData } from '../workflow-designer-interfaces';

export class InvalidWorkflowNodeDataError extends Error {
  public data: INodeEditorData;

  constructor(message: string, data: INodeEditorData) {
    super(message);

    Object.setPrototypeOf(this, InvalidWorkflowNodeDataError.prototype);

    this.data = data;
    this.name = 'InvalidWorkflowNodeDataError';
  }
}
