import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { LoadingState } from '../../shared/constants';
import { Tip } from '../../data/models/types';
import { UserService, IUserSummary } from '../../settings/users/user.service';
import { SideSheetListModes } from '../../side-sheet/side-sheet-list/side-sheet-list.component';
import { async } from 'rxjs/internal/scheduler/async';

@Component({
  selector: 'app-select-users-side-sheet',
  templateUrl: './select-users-side-sheet.component.html',
  styleUrls: ['./select-users-side-sheet.component.scss']
})
export class SelectUsersSideSheetComponent implements OnInit {
  @Input() mode: SideSheetListModes.SINGLE_SELECT | SideSheetListModes.MULTI_SELECT = SideSheetListModes.SINGLE_SELECT;

  title = 'Select users';
  initiallySelectedTips: Tip[] = [];
  selectedUsers: Tip[] = [];
  doNotDisplayInitiallySelectedUsers = false;
  users$: Observable<IUserSummary[]>;
  users: IUserSummary[];
  subscriptions = new Subscription();
  filterText: string;
  loadingStates = LoadingState;
  done: (selectedUser: IUserSummary | IUserSummary[]) => void;

  constructor(
    private userService: UserService
  ) { }

  ngOnInit() {
    this.selectedUsers = [...this.initiallySelectedTips];
    this.users$ = this.userService.getAllUsers().pipe(
      map((users: IUserSummary[]) => {
        this.users = (this.doNotDisplayInitiallySelectedUsers) ?
          users.filter(user => this.initiallySelectedTips.indexOf(user.$tip) === -1) : users;
        return this.users;
      })
    );
  }

  onSelectedChange(selectedUserTips: Tip[]) {
    if (this.isSingleSelect) {
      this.onDone(selectedUserTips);
    }
  }

  onDone(selectedUserTips: Tip[]) {
    switch (this.mode) {
      case SideSheetListModes.SINGLE_SELECT:
        if (selectedUserTips && selectedUserTips.length) {
          this.done(this.users.find(x => x.$tip === selectedUserTips[0]));
        }

        break;
      case SideSheetListModes.MULTI_SELECT:
        this.done(this.users.filter((user) => {
          return selectedUserTips.indexOf(user.$tip) > -1;
        }));

        break;
    }
  }

  get isSingleSelect() {
    return this.mode === SideSheetListModes.SINGLE_SELECT;
  }

  userLabel(user: IUserSummary): string {
    return (user.fullName).replace(/(^ +)|( +$)/g, '') || user.email;
  }
}
