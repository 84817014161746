import { IForm } from '../../settings/form-designer/models/form';
import { IObjectType } from '../../models/object-type';
import { IType } from '../../models/type';

export const apiKeyMetaCreateForm: IForm = {
  name: 'API key form',
  description: 'A form for an API key meta object',
  size: 'regular',
  version: 1,
  contextType: {
    type: {
      $tip: 'app/api-key-meta',
      label: 'API key' // title for form
    } as IType
  } as IObjectType,
  tabs: [
    {
      tabName: 'tab1',
      rows: [
        {
          fields: [{
            type: 'input',
            templateOptions: {
              type: 'text',
              label: 'Name',
              placeholder: 'Enter API key name',
              contextTypeTip: 'app/api-key-meta:name',
              required: true
            },
          }]
        },
        {
          fields: [{
            wrappers: ['side-sheet-section-wrapper'],
            type: 'object-chooser',
            templateOptions: {
              label: 'Profiles',
              placeholder: 'Select profiles',
              typeTips: ['app/profile'],
              contextTypeTip: 'app/api-key-meta:profiles',
              multi: true,
              required: true,
              showBorder: true,
              allowNavAway: false,
              canCreateNew: false
            },
          }]
        },
      ]
    }
  ]
};

export const apiKeyMetaEditForm: IForm = {
  name: 'API key form',
  description: 'A form for an API key meta object',
  size: 'regular',
  version: 1,
  contextType: {
    type: {
      $tip: 'app/api-key-meta',
      label: 'API key'
    } as IType
  } as IObjectType,
  tabs: [
    {
      tabName: 'tab1',
      rows: [
        {
          fields: [{
            type: 'input',
            templateOptions: {
              type: 'text',
              label: 'Name',
              placeholder: 'Enter API key name',
              contextTypeTip: 'app/api-key-meta:name',
              required: true
            },
          }]
        },
        {
          fields: [{
            type: 'display-formula',
            templateOptions: {
              label: 'Creation date',
              formula: `
              TO_DATETIME(
                CONCAT(
                  "@",
                  FLOOR(SERVER_T("createdDate"))
                )
              )`,
              isDateTime: true
            },
          }]
        },
        {
          fields: [{
            type: 'display-formula',
            templateOptions: {
              label: 'Created by',
              formula: `
                CONCAT(
                  FIELD("app/user:email", SERVER_T("createdBy")),
                  IF(
                    EQUALS(
                      SERVER_T("createdBy"),
                      FIELD("app/user-session:user", REFERENCES("app/user-session:session", SESSION()))
                    ),
                    " (you)",
                    ""
                  )
                )
              `
            },
          }]
        },
        {
          fields: [{
            wrappers: ['side-sheet-section-wrapper'],
            type: 'object-chooser',
            templateOptions: {
              label: 'Profiles',
              placeholder: 'Select profiles',
              typeTips: ['app/profile'],
              contextTypeTip: 'app/api-key-meta:profiles',
              multi: true,
              required: true,
              showBorder: true,
              allowNavAway: false,
              canCreateNew: false
            },
          }]
        },
      ]
    }
  ]
};
