import * as tslib_1 from "tslib";
import { CompositeNodeConverter } from './CompositeNodeConverter';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import { isEmpty } from 'lodash';
import { createConnection, createSetVarNode, createSubProcessNode, createSwitchNode } from './utils/create-node-utils';
import * as i0 from "@angular/core";
var NS = 'UpdateStageConverterService';
var UpdateStageConverterService = /** @class */ (function (_super) {
    tslib_1.__extends(UpdateStageConverterService, _super);
    function UpdateStageConverterService() {
        var _this = _super.call(this) || this;
        _this.type = 'processnode/logic/subprocess';
        _this.processNodeType = 'processnodetype/logic/subprocess';
        return _this;
    }
    UpdateStageConverterService.prototype.convert = function (nodeData) {
        if (!this.isValid(nodeData)) {
            throw new InvalidWorkflowNodeDataError("Please correct invalid data on update-stage node [" + nodeData.fields.title + "]", nodeData);
        }
        var _a = nodeData.fields, formula = _a.formula, stage = _a.stage;
        var vars = {
            'Object tip': formula,
            Stage: stage,
        };
        var setVarStatusNode = createSetVarNode(vars, nodeData.tip);
        var subprocessNode = createSubProcessNode('eim/process/workflow/update-stage', vars);
        var doneConnectionForSetVarNode = createConnection('done', subprocessNode);
        setVarStatusNode.connections.push(doneConnectionForSetVarNode);
        var switchStatusNode = createSwitchNode({ name: 'VAR', args: ['Status'] });
        var doneConnectionForSubprocessNode = createConnection('done', switchStatusNode);
        subprocessNode.connections.push(doneConnectionForSubprocessNode);
        var nodesAndLink = {
            nodes: [
                setVarStatusNode,
                subprocessNode,
                switchStatusNode
            ],
            links: [
                doneConnectionForSetVarNode,
                doneConnectionForSubprocessNode
            ]
        };
        this.populateOutgoingLinks(nodeData).forEach(function (link) {
            switchStatusNode.connections.push(link);
            nodesAndLink.links.push(link);
        });
        return nodesAndLink;
    };
    UpdateStageConverterService.prototype.isValid = function (nodeData) {
        return !isEmpty(nodeData.fields.title)
            && !isEmpty(nodeData.fields.formula)
            && !isEmpty(nodeData.fields.stage);
    };
    UpdateStageConverterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UpdateStageConverterService_Factory() { return new UpdateStageConverterService(); }, token: UpdateStageConverterService, providedIn: "root" });
    return UpdateStageConverterService;
}(CompositeNodeConverter));
export { UpdateStageConverterService };
