import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError, combineLatest, of } from 'rxjs';
import { catchError, mapTo, switchMap, map, first } from 'rxjs/operators';

import { ModuleService, MODULE_POLICY_TYPE } from '../../modules/module.service';
import dataConstants from '../../../data/constants';
import { Batch, Tip } from '../../../data/models/types';
import { FormulaService, FormulaResult } from '../../../data/formula.service';
import { ObjectService } from '../../../data/object-service/object.service';
import { flatFormScheme, formScheme, IForm } from '../models/form';
import { formOptionsGroups, IFormOptionsGroups } from '../form-options';
import { last } from 'lodash';
import { ModulePackageService } from '../../modules/module-package.service';


@Injectable({
  providedIn: 'root'
})
export class FormDesignerService {
  // use the map to quickly lookup a shadow element
  private formOptions = new Map();
  formOptionGroups$: Observable<IFormOptionsGroups[]>;
  formPublicOptionGroups$: Observable<IFormOptionsGroups[]>;

  paletteState$ = new BehaviorSubject(true);
  propertiesState$ = new BehaviorSubject(true);

  constructor(
    private objectService: ObjectService,
    private moduleService: ModuleService,
    private formulaService: FormulaService,
    private modulePackageService: ModulePackageService
  ) {

    // we set each item in a map so we can quickly lookup
    // the listId key when creating a shadow element with dragula
    // we only need to create this list once .. can include hidden elements
    const options = formOptionsGroups(true);
    options.forEach((group) => {
      group.options.forEach(option => {
        this.formOptions.set(option.listId, option);
      });
    });

    this.formOptionGroups$ = of(formOptionsGroups(true));
    this.formPublicOptionGroups$ = of(formOptionsGroups(false, true));
  }

  loadForm(formTip): Observable<IForm> {
    return this
      .objectService
      .getObject(formTip, formScheme)
      .pipe(
        map((form: IForm) => {
          if (!form.contextType) {
            throw new Error('Form has no object attached');
          }
          return form;
        }),
        catchError(error => throwError({ errorMessage: 'Error loading form', error }))
      );
  }

  getFormModuleName(moduleTip: Tip): Observable<string> {
    return this.formulaService.evaluate('FIELD("app/module:name")', moduleTip).pipe(
      map((formulaResult: FormulaResult) => formulaResult[0])
    );
  }

  getFormOption(listId) {
    return this.formOptions.get(listId);
  }

  createNewForm(formParams: IForm, moduleTip: Tip): Observable<Tip> {
    const formEno = {
      $type: 'app/form',
      ...formParams
    };

    return this.moduleService
      .getModulePolicy(moduleTip, MODULE_POLICY_TYPE.INSTANCE)
      .pipe(
        first(),
        switchMap((instancePolicy: Tip) => {
          return this.objectService
            .setObject(
              formEno,
              flatFormScheme,
              dataConstants.BRANCH_MASTER,
              instancePolicy || 'app/security-policy/instance-user-admin' // -!-!-!- DELETE BACKUP OPTION AFTER MIGRATION - EIM-2691 -!-!-!-
            )
            .pipe(first());
        }),
        switchMap((batch: Batch) => {
          const tip = batch[batch.length - 1].tip;
          return this.moduleService
            .addTipToFlatModuleField({
              moduleTip,
              moduleField: 'forms',
              tip
            })
            .pipe(mapTo(tip));
        }),
        catchError(() => {
          return this.modulePackageService.handleError('save form', moduleTip);
        })
      );
  }

  cloneForm(
    { newForm, originalFormTip, moduleTip }:
    { newForm: IForm, originalFormTip: Tip, moduleTip: Tip }
  ): Observable<Tip> {
    const getModuleInstancePolicy$ = this.moduleService.getModulePolicy(moduleTip, MODULE_POLICY_TYPE.INSTANCE);
    const getOriginalForm$ = this.objectService.getObject(originalFormTip, flatFormScheme, undefined, undefined, true);

    return combineLatest(getOriginalForm$, getModuleInstancePolicy$)
      .pipe(
        map(([originalForm, moduleInstancePolicy]: [IForm, Tip]) => ({
          ...originalForm,
          $tip: undefined,
          $security: moduleInstancePolicy || 'app/security-policy/instance-user-admin', // -!-!-!- DELETE BACKUP OPTION AFTER MIGRATION - EIM-2691 -!-!-!-
          name: newForm.name,
          description: newForm.description,
          size: newForm.size,
          version: 1
        })),
        switchMap(formToSave => this.objectService.setObject(formToSave, flatFormScheme)),
        map((batch: Batch) => last(batch).tip),
        switchMap((tip: Tip) => {
          return this.moduleService
            .addTipToFlatModuleField({ moduleTip, moduleField: 'forms', tip })
            .pipe(mapTo(tip));
        }),
        catchError(() => {
          return this.modulePackageService.handleError('clone form', moduleTip);
        })
      );
  }

  deleteForm(tip: Tip, moduleTip?: Tip): Observable<never | unknown> {
    return this.moduleService.deleteModuleConfig([tip]).pipe(
      catchError(() => {
        return this.modulePackageService.handleError('delete form', moduleTip);
      })
      );
  }

  saveForm(_form: IForm, moduleTip?: Tip): Observable<Batch> {
    const form = {
      ..._form,
      contextType: _form.contextType.$tip
    };

    return this.objectService.setObject(
      form,
      flatFormScheme
    ).pipe(
      catchError(() => {
        return this.modulePackageService.handleError('save form', moduleTip);
      })
    );
  }
}
