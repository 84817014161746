import { localeScheme } from '../models/locale';
import { map, catchError, first } from 'rxjs/operators';
import { defaults, head } from 'lodash';
import { of, ReplaySubject } from 'rxjs';
import dataConstants from './constants';
import * as i0 from "@angular/core";
import * as i1 from "./object-service/object.service";
var LocaleService = /** @class */ (function () {
    function LocaleService(objectService) {
        var _this = this;
        this.objectService = objectService;
        this.localeSubject = new ReplaySubject(1);
        this.objectService.getObject('app/locale', localeScheme).pipe(map(function (value) {
            return defaults(value, defaultLocaleSettings);
        }), catchError(function (error) { return of(defaultLocaleSettings); }), first()).subscribe(function (value) {
            _this.localeSubject.next(defaults(value, defaultLocaleSettings));
        });
    }
    LocaleService.prototype.update = function (locale) {
        return this.objectService.setObject(locale, localeScheme).pipe(first(), map(function (batch) {
            if (batch.length > 0) {
                return head(batch).tip;
            }
        }));
    };
    LocaleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocaleService_Factory() { return new LocaleService(i0.ɵɵinject(i1.ObjectService)); }, token: LocaleService, providedIn: "root" });
    return LocaleService;
}());
export { LocaleService };
export var defaultLocaleSettings = {
    localeId: dataConstants.LANG_DEFAULT,
    timezone: 'Australia/Sydney',
    dateformat: 'DD MMM YYYY',
    datetimeformat: 'DD MMM YYYY HH:mm'
};
