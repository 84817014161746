var _a;
import { reverseOperator } from './operators';
import { Parser } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { OPERATOR_TYPE } from '../../../models/query';
import { isCurrentUserFormula } from '../../../util/current-user-formula';
import { isFormulaSpec } from '../../../util/is-formula-spec';
var ɵ0 = function (leftArg, rightArg) {
    var rightArgFormula = rightArg;
    if (!isFormulaSpec(rightArg) && isCurrentUserFormula(rightArg)) {
        rightArgFormula = Parser(rightArg);
    }
    return {
        name: 'EQUALS',
        args: [leftArg, rightArgFormula]
    };
};
var objectEqual = {
    opNo: 2,
    toFormula: ɵ0
};
var objectNotEqual = reverseOperator(objectEqual);
var ɵ1 = function (leftArg, rightArg) { return ({
    name: 'OP',
    args: [
        {
            name: 'TYPE',
            args: [leftArg]
        },
        '==',
        rightArg
    ]
}); };
var objectIsOfType = {
    opNo: 2,
    toFormula: ɵ1
};
var objectNotIsOfType = reverseOperator(objectIsOfType);
var ɵ2 = function (leftArg) { return ({
    name: 'EQUALS',
    args: [
        {
            name: 'ARRAY',
            args: []
        },
        leftArg
    ]
}); };
var objectIsEmpty = {
    opNo: 1,
    toFormula: ɵ2
};
var objectIsNotEmpty = reverseOperator(objectIsEmpty);
var ɵ3 = function (leftArg, rightArg) {
    var rightArgFormula = rightArg;
    if (!isFormulaSpec(rightArg) && isCurrentUserFormula(rightArg)) {
        rightArgFormula = Parser(rightArg);
    }
    return {
        name: 'HAS_INTERSECT',
        args: [
            leftArg,
            rightArgFormula
        ]
    };
};
var objectContainsAny = {
    opNo: 2,
    toFormula: ɵ3
};
var objectNotContainsAny = reverseOperator(objectContainsAny);
var ɵ4 = function (leftArg, rightArg) {
    var rightArgFormula = rightArg;
    if (!isFormulaSpec(rightArg) && isCurrentUserFormula(rightArg)) {
        rightArgFormula = Parser(rightArg);
    }
    return {
        name: 'OP',
        args: [
            {
                name: 'EQUALS',
                args: [
                    {
                        name: 'COUNT',
                        args: [
                            leftArg
                        ]
                    },
                    {
                        name: 'COUNT',
                        args: [
                            {
                                name: 'INTERSECT',
                                args: [
                                    leftArg,
                                    rightArgFormula
                                ]
                            }
                        ]
                    }
                ]
            },
            '&&',
            objectIsNotEmpty.toFormula(leftArg)
        ]
    };
};
var objectIn = {
    opNo: 2,
    toFormula: ɵ4
};
var objectNotIn = reverseOperator(objectIn);
var ɵ5 = function (leftArg, rightArg) {
    var rightArgFormula = rightArg;
    if (!isFormulaSpec(rightArg) && isCurrentUserFormula(rightArg)) {
        rightArgFormula = Parser(rightArg);
    }
    return {
        name: 'HAS_INTERSECT',
        args: [
            leftArg,
            {
                name: 'UNIQUE',
                args: [
                    {
                        name: 'FIELD_VALUES',
                        args: [
                            'app/group:contacts',
                            {
                                name: 'ARRAY',
                                args: [
                                    rightArgFormula,
                                    {
                                        name: 'ANCESTORS',
                                        args: [
                                            'app/group:parent',
                                            rightArgFormula
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    };
};
var contactObjectInGroup = {
    opNo: 2,
    toFormula: ɵ5
};
var contactObjectNotInGroup = reverseOperator(contactObjectInGroup);
var ɵ6 = function (leftArg, rightArg) {
    var rightArgFormula = rightArg;
    if (!isFormulaSpec(rightArg) && isCurrentUserFormula(rightArg)) {
        rightArgFormula = Parser(rightArg);
    }
    return {
        name: 'OP',
        args: [
            objectIsNotEmpty.toFormula(rightArgFormula),
            '&&',
            {
                name: 'EQUALS',
                args: [
                    {
                        name: 'COUNT',
                        args: [
                            rightArgFormula
                        ]
                    },
                    {
                        name: 'COUNT',
                        args: [
                            {
                                name: 'INTERSECT',
                                args: [
                                    leftArg,
                                    rightArgFormula
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    };
};
var objectContainsAll = {
    opNo: 2,
    toFormula: ɵ6
};
var objectNotContainsAll = reverseOperator(objectContainsAll);
export var objectOperators = {
    operators: (_a = {},
        _a[OPERATOR_TYPE.EQUALS] = objectEqual,
        _a[OPERATOR_TYPE.NOT_EQUALS] = objectNotEqual,
        _a[OPERATOR_TYPE.IS_OF_TYPE] = objectIsOfType,
        _a[OPERATOR_TYPE.NOT_IS_OF_TYPE] = objectNotIsOfType,
        _a[OPERATOR_TYPE.IS_EMPTY] = objectIsEmpty,
        _a[OPERATOR_TYPE.NOT_IS_EMPTY] = objectIsNotEmpty,
        _a[OPERATOR_TYPE.IN] = objectIn,
        _a[OPERATOR_TYPE.NOT_IN] = objectNotIn,
        _a[OPERATOR_TYPE.CONTACT_IN_GROUP] = contactObjectInGroup,
        _a[OPERATOR_TYPE.CONTACT_NOT_IN_GROUP] = contactObjectNotInGroup,
        _a[OPERATOR_TYPE.CONTAINS_ALL] = objectContainsAll,
        _a[OPERATOR_TYPE.NOT_CONTAINS_ALL] = objectNotContainsAll,
        _a[OPERATOR_TYPE.CONTAINS_ANY] = objectContainsAny,
        _a[OPERATOR_TYPE.NOT_CONTAINS_ANY] = objectNotContainsAny,
        _a),
    getParts: function (formula) {
        var currentPart = formula;
        var negative = false;
        if (currentPart.name === 'NOT') {
            currentPart = currentPart.args[0];
            negative = true;
        }
        if (currentPart.name === 'EQUALS') {
            var leftArg = currentPart.args[0];
            if (leftArg.name === 'ARRAY' && !leftArg.args.length) {
                return {
                    operator: negative ? OPERATOR_TYPE.NOT_IS_EMPTY : OPERATOR_TYPE.IS_EMPTY,
                    leftArg: currentPart.args[1]
                };
            }
            else {
                return {
                    operator: negative ? OPERATOR_TYPE.NOT_EQUALS : OPERATOR_TYPE.EQUALS,
                    leftArg: currentPart.args[0],
                    rightArg: currentPart.args[1]
                };
            }
        }
        if (currentPart.name === 'OP') {
            if (currentPart.args[0].name === 'EQUALS') {
                return {
                    operator: negative ? OPERATOR_TYPE.NOT_IN : OPERATOR_TYPE.IN,
                    leftArg: currentPart.args[0].args[1].args[0].args[0],
                    rightArg: currentPart.args[0].args[1].args[0].args[1]
                };
            }
            if (currentPart.args[0].name === 'NOT') {
                return {
                    operator: negative ? OPERATOR_TYPE.NOT_CONTAINS_ALL : OPERATOR_TYPE.CONTAINS_ALL,
                    leftArg: currentPart.args[2].args[1].args[0].args[0],
                    rightArg: currentPart.args[2].args[1].args[0].args[1]
                };
            }
            return {
                operator: negative ? OPERATOR_TYPE.NOT_IS_OF_TYPE : OPERATOR_TYPE.IS_OF_TYPE,
                leftArg: currentPart.args[0].args[0],
                rightArg: currentPart.args[2]
            };
        }
        if (currentPart.name === 'HAS_INTERSECT') {
            if (currentPart.args[1] && currentPart.args[1].name === 'UNIQUE') {
                return {
                    operator: negative ? OPERATOR_TYPE.CONTACT_NOT_IN_GROUP : OPERATOR_TYPE.CONTACT_IN_GROUP,
                    leftArg: currentPart.args[0],
                    rightArg: currentPart.args[1].args[0].args[1].args[0]
                };
            }
            return {
                operator: negative ? OPERATOR_TYPE.NOT_CONTAINS_ANY : OPERATOR_TYPE.CONTAINS_ANY,
                leftArg: currentPart.args[0],
                rightArg: currentPart.args[1]
            };
        }
        throw new Error('Unknown operators: ' + currentPart.name);
    }
};
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
