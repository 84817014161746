import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { first, map, mapTo } from 'rxjs/operators';
import * as moment from 'moment';
import { isArray, get } from 'lodash';

import { FIELD_DATA_TYPE, IField } from '../models/field';
import { FormulaMultiService } from '../data/formula-multi.service';
import { ILoadParams, } from './object-launch';
import { IFormObjectAndType } from './get-object-and-form.service';
import { CONTEXT_VAR_NAME } from '../message/message-utils.service';

@Injectable({
  providedIn: 'root',
})

export class ObjectLaunchDefaultService {
  constructor(
    private formulaMultiService: FormulaMultiService
  ) {}

  populateFormDefaultValues(formObjectAndType: IFormObjectAndType, params: ILoadParams): Observable<IFormObjectAndType> {
    if (formObjectAndType.objectAndType.objectData.$tip) {
      return of(formObjectAndType);
    }

    const fields: IField[] = formObjectAndType.objectAndType.objectType.field.filter((field: IField) => Boolean(field.default));

    if (!fields || fields.length === 0) {
      return of(formObjectAndType);
    }

    return forkJoin(fields.map((field: IField) => {
      return this.populateFieldDefaultValue(field, params, formObjectAndType.objectAndType.objectData);
    })).pipe(
      mapTo(formObjectAndType)
    );
  }

  private populateFieldDefaultValue(field: IField, params: ILoadParams, objectData: object): Observable<boolean> {
    const dataType: FIELD_DATA_TYPE = field.datatype;
    const formula = params.typeTip
      ? { formula: field.default, context: params.contextTip, watch: false, vars: { 'Type': [params.typeTip], CONTEXT_VAR_NAME: [params.contextTip] } }
      : { formula: field.default, context: params.contextTip, watch: false, vars: { CONTEXT_VAR_NAME: [params.contextTip] } };

    // tslint:disable-next-line: max-line-length
    return this.formulaMultiService.evaluate([formula]).pipe(
      first(),
      map((formulaResult: string[][]) => {
        if (formulaResult && formulaResult[0] && formulaResult[0].length > 0) {
          this.setObjectDataValues(
            field,
            formulaResult[0],
            objectData,
            [FIELD_DATA_TYPE.date, FIELD_DATA_TYPE.datetime].includes(dataType)
          );
        }

        return true;
      })
    );
  }

  private setObjectDataValues(
    field: IField,
    fieldValue: string | string[] | moment.Moment,
    objectData: object,
    isDateType: boolean = false
  ) {
    if (field.maxcount === 1) {
      objectData[field.$tip] = this.getValue(isDateType, isArray(fieldValue) ? fieldValue[0] : fieldValue);

      return;
    }

    objectData[field.$tip] = isArray(fieldValue)
      ? fieldValue.map((value => this.getValue(isDateType, value)))
      : [this.getValue(isDateType, fieldValue)];
  }

  getValue(isDateType: boolean, fieldValue: string | moment.Moment) {
    return isDateType ? moment(fieldValue) : fieldValue;
  }

  setSequenceFieldReadOnly(formObjectAndType: IFormObjectAndType): Observable<IFormObjectAndType> {
    if (!get(formObjectAndType, 'objectAndType.objectType.field', null)) {
      return of(formObjectAndType);
    }

    const sequenceField = formObjectAndType.objectAndType.objectType.field.find(f =>
      f.tag && f.tag.findIndex(tag => tag.includes('sequenceTip')) > -1
    );

    if (sequenceField) {
      formObjectAndType.form.tabs.forEach(tab => {
        tab.rows.forEach(row => {
          row.fields.forEach(field => {
            if (field.templateOptions.contextTypeTip === sequenceField.$tip) {
              field.conditionalFields.readonly.value = 'true';
            }
          });
        });
      });
    }

    return of(formObjectAndType);
  }
}
