import { OnDestroy, OnInit } from '@angular/core';
import { sortBy } from 'lodash';
import { Subject } from 'rxjs';
import { IObjectLaunchDone, IObjectLaunchProps } from '../../object-launch';
import { takeUntil } from 'rxjs/operators';
import { ToggleType } from '../../../side-sheet/side-sheet-list-item/side-sheet-list-item.component';
var ObjectTypeSideSheetComponent = /** @class */ (function () {
    function ObjectTypeSideSheetComponent(queryService, toastService, objectLaunchService) {
        this.queryService = queryService;
        this.toastService = toastService;
        this.objectLaunchService = objectLaunchService;
        this.isLoading = true;
        this.types = [];
        this.done$ = new Subject();
        this.unsubscribe$ = new Subject();
        this.ToggleType = ToggleType;
        this.filterText = '';
    }
    ObjectTypeSideSheetComponent.prototype.ngOnInit = function () {
        this.loadObjectTypes();
    };
    ObjectTypeSideSheetComponent.prototype.setProps = function (props) {
        this.superTypeTip = props.typeTip;
    };
    ObjectTypeSideSheetComponent.prototype.loadObjectTypes = function () {
        var _this = this;
        this.queryService.execute1dFirst('eim/query/get-all-objects-types-with-super-types', {
            vars: {
                superTypeTip: [this.superTypeTip]
            }
        })
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(function (objectTypes) {
            _this.types = sortBy(objectTypes, 'label');
            _this.isLoading = false;
        }, function () {
            _this.toastService.showErrorToast('Unable to load types');
            _this.objectLaunchService.pop();
            _this.isLoading = false;
        });
    };
    ObjectTypeSideSheetComponent.prototype.onToggle = function (typeTip) {
        if (this.selectedTypeTip === typeTip) {
            this.selectedTypeTip = '';
        }
        else {
            this.selectedTypeTip = typeTip;
        }
        this.onPrimary();
    };
    ObjectTypeSideSheetComponent.prototype.onPrimary = function () {
        var _this = this;
        this.objectLaunchService.pushSheet({ typeTip: this.selectedTypeTip }, this.unsubscribe$)
            .subscribe(function (_a) {
            var objectTip = _a.objectTip;
            if (objectTip) {
                _this.objectLaunchService.pop();
                _this.done$.next({ objectTip: objectTip });
            }
        });
    };
    ObjectTypeSideSheetComponent.prototype.ngOnDestroy = function () {
        this.done$.complete();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    };
    return ObjectTypeSideSheetComponent;
}());
export { ObjectTypeSideSheetComponent };
