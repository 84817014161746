import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnDestroy } from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { get } from 'lodash';
import { IObjectLaunchDone, IObjectLaunchProps } from '../../formly-noggin/object-launch';
import { IObjectTypeSchemeSubObject, ObjectService } from '../object.service';
import { PrintHtmlPreviewSideSheetComponent } from '../../print/print-html-preview-side-sheet/print-html-preview-side-sheet.component';
import { DEFAULT_PRINT_TEMPLATE, DEFAULT_SETTING_PRINT, } from '../../print/print';
var ObjectOpenSideSheetComponent = /** @class */ (function () {
    function ObjectOpenSideSheetComponent(objectService, objectLaunch, securityService, sideSheetService, objectRenderService, cdr) {
        this.objectService = objectService;
        this.objectLaunch = objectLaunch;
        this.securityService = securityService;
        this.sideSheetService = sideSheetService;
        this.objectRenderService = objectRenderService;
        this.cdr = cdr;
        this.loadingError = null;
        this.unsubscribe$ = new Subject();
        this.objectRenderData = '';
        this.done$ = new Subject();
    }
    ObjectOpenSideSheetComponent.prototype.setProps = function (_a) {
        var objectTip = _a.objectTip;
        this.objectTip = objectTip;
        this.load();
        // eventually replace renderer with object renderer
        // this.getObjectRenderData(objectTip);
    };
    ObjectOpenSideSheetComponent.prototype.getObjectRenderData = function (objectTip) {
        var _this = this;
        this.objectRenderService.getObjectsToRender([objectTip])
            .subscribe(function (v) { return _this.objectRenderData = v; });
    };
    ObjectOpenSideSheetComponent.prototype.load = function () {
        var _this = this;
        this.unsubscribe$.next(); // finish any existing subscriptions
        var objectAndType$ = this.objectService.getObjectTypeSchemeSubObject(this.objectTip);
        var canUpdate$ = this.securityService.canUpdate(this.objectTip);
        var objectTitle$ = this.objectService.getTitles([this.objectTip]).pipe(map(function (objectTitles) { return objectTitles[0].title || ''; }));
        combineLatest([objectAndType$, canUpdate$, objectTitle$])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 3), objectAndType = _b[0], canUpdate = _b[1], title = _b[2];
            // clear to destroy render component
            _this.clear();
            _this.cdr.detectChanges();
            // reassign values
            _this.objectType = get(objectAndType, 'objectType', null);
            _this.objectData = get(objectAndType, 'objectData', null);
            _this.title = title;
            var isSubObject = get(objectAndType, 'isSubObject', false);
            _this.canUpdate = canUpdate && !isSubObject;
            _this.cdr.markForCheck();
            if (_this.canUpdate) {
                _this.redirectToEditMode();
            }
        }, function () {
            _this.loadingError = 'Unable to load object at this time';
            _this.cdr.markForCheck();
        });
    };
    ObjectOpenSideSheetComponent.prototype.redirectToEditMode = function () {
        var _this = this;
        this.objectLaunch.replaceSheet({ objectTip: this.objectTip }, this.unsubscribe$)
            .subscribe(function (_a) {
            var deleted = _a.deleted;
            // pop the edit side sheet
            _this.sideSheetService.pop();
        });
    };
    ObjectOpenSideSheetComponent.prototype.print = function () {
        var componentInstance = this.sideSheetService.push(PrintHtmlPreviewSideSheetComponent).componentInstance;
        componentInstance.setProps({ templateTip: DEFAULT_PRINT_TEMPLATE, settingTip: DEFAULT_SETTING_PRINT, contextTip: this.objectTip });
    };
    ObjectOpenSideSheetComponent.prototype.clear = function () {
        this.objectData = null;
        this.objectType = null;
        this.title = null;
        this.canUpdate = null;
    };
    ObjectOpenSideSheetComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    };
    return ObjectOpenSideSheetComponent;
}());
export { ObjectOpenSideSheetComponent };
