import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  Renderer2,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { uniqueId as UniqueId } from 'lodash';

import { SideSheetService } from '../../side-sheet/side-sheet.service';
import { DynamicFieldChooserSidesheetComponent } from '../../shared/dynamic-field-chooser/dynamic-field-chooser-side-sheet.component';
import { Formula, Tip } from '../../data/models/types';
import { RECIPIENT_SUPER_TYPE_TIP } from '../../data/string-interpolation/substitution-meta.service';
import {
  CONTENT_TYPE,
  INTERPOLATION_TYPE,
  ISubstitution,
  ISubstitutionCandidate,
  ISubstitutionMeta,
  StringInterpolationService
} from '../string-interpolation/string-interpolation.service';
import { IWorkflowVariables } from '../../models/workflow';
import { WINDOW } from '../../dom-tokens';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { FormulaLabelService, IFormulaLabelParams } from '../../object/field-formula-side-sheet/formula-label.service';
import { MetaDataKey } from '../../object/field-formula-side-sheet/meta-data-formulas';
import { DisplayFormulaWrapperService, IDisplayFormulaRule } from '../../util/display-formula-wrapper.service';
import { DOCUMENT } from '@angular/common';

export enum STRING_INTERPOLATE_TEXT_FIELD_MODE {
  SINGLE_LINE = 'single-line',
  MULTI_LINE = 'multi-line'
}

export enum VALUE_FORMAT {
  SUBSTITUTIONS = 'substitutions',
  FORMULA = 'formula'
}

const STRING_INTERPOLATE_TEXT_FIELD_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => StringInterpolateTextFieldComponent),
  multi: true
};

@Component({
  selector: 'app-string-interpolate-text-field',
  templateUrl: './string-interpolate-text-field.component.html',
  styleUrls: ['./string-interpolate-text-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    STRING_INTERPOLATE_TEXT_FIELD_CONTROL_VALUE_ACCESSOR
  ]
})
export class StringInterpolateTextFieldComponent implements ControlValueAccessor, AfterViewInit {
  @HostBinding('class.eim-forms_form-control-host')
  private readonly _eimFormsFormControlHostClass = true;

  @ViewChild('formFieldEl', { static: false }) formFieldEl: ElementRef;

  @Input() label: string;

  @Input() required = false;

  @Input() optional = false;

  @Input() readonly = false;

  @Input() placeholder = '';

  @Input() mode = STRING_INTERPOLATE_TEXT_FIELD_MODE.SINGLE_LINE;

  @Input() recipientSuperTypeTip: RECIPIENT_SUPER_TYPE_TIP;

  @Input() interpolationType: INTERPOLATION_TYPE = INTERPOLATION_TYPE.message;

  @Input() contextTypeTips: Tip[] = [];

  @Input() workflowVariables: IWorkflowVariables;

  @Input() valueFormat: VALUE_FORMAT = VALUE_FORMAT.SUBSTITUTIONS;

  @Input() useContextFormula = false;

  @Input() displayFormulaRule: IDisplayFormulaRule;

  @Input() showSequence: boolean;

  // required for showModuleOptions
  @Input() showModuleOptions: boolean;
  @Input() moduleTip: Tip;

  @Input() preserveLineBreaks = false;

  @Input() hideSystemCurrentUserOption = false;

  value: ISubstitutionMeta | Formula;

  isDisabled: boolean;

  uniqueId = UniqueId('app-string-interpolate-text-field-');

  currentCursorPositionNode: Node;

  currentCursorPositionOffset: number;

  STRING_INTERPOLATE_TEXT_FIELD_MODE = STRING_INTERPOLATE_TEXT_FIELD_MODE;

  loading$ = new BehaviorSubject<boolean>(false);

  isTextHighlighted = false;
  highlightedTextStart: number;
  highlightedTextEnd: number;

  constructor(
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private sideSheetService: SideSheetService,
    private formulaLabelService: FormulaLabelService,
    private displayFormulaWrapperService: DisplayFormulaWrapperService,
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document
  ) {}

  onChange: (_: ISubstitutionMeta | Formula) => void = () => {};

  onTouched: () => void = () => {};

  writeValue(value: any): void {
    if (this.valueFormat === VALUE_FORMAT.SUBSTITUTIONS || typeof value !== 'string') {
      this.initValue(value);
      return;
    }

    value = StringInterpolationService.getSubstitutionMetaFromStringifiedFormula(value);

    const labelFormulasToEvaluate: IFormulaLabelParams[] = (value as ISubstitutionMeta)
      .substitutions
      .map((substitution: ISubstitution) => ({
        formulaString: this.displayFormulaWrapperService.removeDisplayFormulaWrapper(substitution.value)
      }));

    if (!labelFormulasToEvaluate.length) {
      this.initValue(value);
      this.loading$.next(false);
      return;
    }

    this.loading$.next(true);

    this.formulaLabelService.transformBatch(labelFormulasToEvaluate).pipe(first()).subscribe(
      (labels) => {
        (value as ISubstitutionMeta)
          .substitutions
          .forEach((substitution: ISubstitution, i: number) => {
            if (labels[i]) {
              substitution.label = labels[i];
            }
          });

        this.initValue(value);
        this.loading$.next(false);
      },
      () => {
        (value as ISubstitutionMeta).substitutions.forEach(
          (substitution: ISubstitution, i: number) => {
            substitution.label = '';
          }
        );

        this.initValue(value);
        this.loading$.next(false);
      }
    );
  }

  initValue(value: any): void {
    // Return early if view hasn't rendered yet
    // Will nullify this.value if provided value is falsy or isn't a valid ISubstitutionMeta
    if (!this.formFieldEl) {
      this.value = value && this.instanceOfISubstitutionMeta(value) ? value : null;
      return;
    }

    // Nullify this.value and return early if provided value is falsey or isn't a valid ISubstitutionMeta
    if (!value || !this.instanceOfISubstitutionMeta(value)) {
      this.value = null;
      this.renderer.setProperty(this.formFieldEl.nativeElement, 'innerHTML', '');
      return;
    }

    const valueHtmlAsViewHtml = StringInterpolationService.getViewTextFromDataHtml(value, this.renderer);

    this.renderer.setProperty(this.formFieldEl.nativeElement, 'innerHTML', valueHtmlAsViewHtml);
    this.formatNewValue(false);
  }

  registerOnChange(fn: (_: ISubstitutionMeta) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.cdr.markForCheck();
  }

  ngAfterViewInit() {
    this.writeValue(this.value);
  }

  formatNewValue(emitChangeEvent: boolean = true) {
    const newValue = this.formFieldEl.nativeElement.innerHTML;
    const newValueFormatted = StringInterpolationService.getSubstitutionMetaFromViewHtml(
      newValue,
      this.renderer,
      CONTENT_TYPE.TEXT_CONTENT,
      this.preserveLineBreaks
    );

    if (this.valueFormat === VALUE_FORMAT.FORMULA) {
      StringInterpolationService.getStringifiedFormulaFromSubstitutionMeta(newValueFormatted);
    }

    this.value = newValueFormatted;

    if (emitChangeEvent) {
      const valueToEmit = this.valueFormat === VALUE_FORMAT.FORMULA
        ? StringInterpolationService.getStringifiedFormulaFromSubstitutionMeta(newValueFormatted)
        : newValueFormatted;
      this.onChange(valueToEmit);
    }
  }

  openStringInterpolationSelectSideSheet() {
    const sheetRef = this.sideSheetService.push(DynamicFieldChooserSidesheetComponent);
    const instance = sheetRef.componentInstance as DynamicFieldChooserSidesheetComponent;

    instance.recipientSuperTypeTip = this.recipientSuperTypeTip;
    instance.interpolationType = this.interpolationType;
    instance.contextTypeTips = this.contextTypeTips;
    instance.workflowVariables = this.workflowVariables;
    instance.useContextFormula = this.useContextFormula;
    instance.showSequence = this.showSequence;
    instance.showModuleOptions = this.showModuleOptions;
    instance.moduleTip = this.moduleTip;
    instance.hideSystemCurrentUserOption = this.hideSystemCurrentUserOption;

    this.showSequence
      ? instance.displayFormulaRule = { ...this.displayFormulaRule, stripHTML: true, list: true, date: true, datetime: true }
      : instance.displayFormulaRule = { ...this.displayFormulaRule, stripHTML: true, date: true, datetime: true };

    if (this.interpolationType === INTERPOLATION_TYPE.general) {
      instance.metaDataKeysToExclude = [MetaDataKey.title, MetaDataKey.urlLink];
    } else if (this.interpolationType === INTERPOLATION_TYPE.message) {
      instance.metaDataKeysToExclude = [];
    }

    instance.done = (substitutionCandidate: ISubstitutionCandidate) => {
      this.sideSheetService.pop();

      // Create interpolation blot
      const interpolationElement: HTMLElement = StringInterpolationService.createInterpolationSpanElement(
        substitutionCandidate,
        this.renderer
      );

      // If there is no currentCursorPositionNode (cursor isn't inside formFieldEl),
      // Append the interpolation blot to the nativeElement
      if (!this.currentCursorPositionNode || this.currentCursorPositionOffset === null) {
        this.renderer.appendChild(this.formFieldEl.nativeElement, interpolationElement);
        this.formatNewValue();
        return;
      }

      // With the currentCursorPositionNode and the currentCursorPositionOffset within that node
      // Extract the textContent before and after the cursor offset into two separate variables
      //  Clear the currentCursorPositionNode textContent
      const textContent = this.currentCursorPositionNode.textContent.slice();
      const contentBefore = textContent.substring(0, this.currentCursorPositionOffset);
      const contentAfter = textContent.substring(this.currentCursorPositionOffset);
      this.currentCursorPositionNode.textContent = '';

      // Insert the interpolation blot inside the now cleared currentCursorPositionNode
      // Append the previously extracted before and after textContent to the now-inserted interpolation blot
      const currentCursorPositionParentNode = this.renderer.parentNode(this.currentCursorPositionNode);
      const currentCursorPositionNextSibling = this.renderer.nextSibling(this.currentCursorPositionNode);
      this.renderer.insertBefore(currentCursorPositionParentNode, interpolationElement, currentCursorPositionNextSibling);

      interpolationElement.insertAdjacentText('beforebegin', contentBefore);
      interpolationElement.insertAdjacentText('afterend', contentAfter);

      this.formatNewValue();
    };
  }

  // 'keyup' + 'mouseup' events
  updateCurrentCursorPositionValues() {
    const sel: Selection = this.window.getSelection();
    this.isTextHighlighted = !!sel.toString();
    if (this.isTextHighlighted && sel.rangeCount !== 0) {
      const selectionRange: Range = sel.getRangeAt(0);
      this.highlightedTextStart = selectionRange.startOffset;
      this.highlightedTextEnd = selectionRange.endOffset;
    } else {
      this.highlightedTextStart = this.highlightedTextEnd = -1;
    }

    // Set to null if selected element is not a descendent of #formFieldEl
    if (!this.formFieldEl.nativeElement.contains(sel.anchorNode.parentElement)) {
      this.currentCursorPositionNode = null;
      this.currentCursorPositionOffset = null;
      return;
    }

    // Set to previous sibling if selected element is inside a substitution blot
    if (sel.anchorNode.parentElement.parentElement.getAttribute('data-substitution')) {
      const previousSibling = sel.anchorNode.parentElement.parentElement.previousSibling;
      this.currentCursorPositionNode = previousSibling;
      if (previousSibling) {
        this.currentCursorPositionOffset = previousSibling.textContent.length;
      }
      return;
    }

    this.currentCursorPositionNode = sel.anchorNode;
    this.currentCursorPositionOffset = sel.anchorOffset;
  }

  // 'keydown' event
  preventIfEnterKeyPressed(event: KeyboardEvent) {
    if (event.key.toLowerCase() === 'enter') {
      event.preventDefault();
      this.formFieldEl.nativeElement.blur();
    }
  }

  // 'paste' event
  sanitizePaste(event: ClipboardEvent) {
    event.stopPropagation();
    event.preventDefault();
    const clipboardData = event.clipboardData ||
      (window as any).clipboardData; // to support IE

    if (!clipboardData || !clipboardData.getData) {
      return;
    }

    const clipboardDataAsText = event.clipboardData ?
      clipboardData.getData('text/plain') :
      clipboardData.getData('Text'); // to support IE

    if (!clipboardDataAsText) {
      return;
    }

    const clipboardDataAsTextEl = this.renderer.createText(clipboardDataAsText);

    if (this.currentCursorPositionNode) {
      if (this.currentCursorPositionOffset >= 0 && this.currentCursorPositionNode.textContent !== '') {
        let result = '';
        if (this.isTextHighlighted && this.highlightedTextEnd > this.highlightedTextStart) {   // try to replace the highlighted text
          const sourceText = this.currentCursorPositionNode.textContent;
          const prefix = sourceText.substring(0, this.highlightedTextStart);
          const suffix = sourceText.substring(this.highlightedTextEnd);
          result = prefix + clipboardDataAsTextEl.textContent + suffix;
        } else {  // paste the text inline
          const sourceText = this.currentCursorPositionNode.textContent;
          const prefix = sourceText.substring(0, this.currentCursorPositionOffset);
          const suffix = sourceText.substring(this.currentCursorPositionOffset);
          result = prefix + clipboardDataAsTextEl.textContent + suffix;
        }
        this.renderer.setValue(this.currentCursorPositionNode, result);
      } else {
        const currentCursorPositionParentNode = this.renderer.parentNode(this.currentCursorPositionNode);
        const currentCursorPositionNextSibling = this.renderer.nextSibling(this.currentCursorPositionNode);
        this.renderer.insertBefore(currentCursorPositionParentNode, clipboardDataAsTextEl, currentCursorPositionNextSibling);
      }
    } else {
      this.renderer.appendChild(this.formFieldEl.nativeElement, clipboardDataAsTextEl);
    }

    this.formatNewValue();
  }

  removeContextSubstitution() {
    const {
      changed,
      newHtml
    } = StringInterpolationService.removeSubstitutionByContextFormula(this.formFieldEl.nativeElement.innerHTML, this.renderer);

    if (changed) {
      this.renderer.setProperty(this.formFieldEl.nativeElement, 'innerHTML', newHtml);
      this.formatNewValue(true);
    }
  }

  private instanceOfISubstitutionMeta(substitutionMeta: any): substitutionMeta is ISubstitutionMeta {
    return substitutionMeta &&
      typeof substitutionMeta === 'object' &&
      'html' in substitutionMeta &&
      'substitutions' in substitutionMeta;
  }
}
