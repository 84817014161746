/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-not-authorized.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./page-not-authorized.component";
var styles_PageNotAuthorizedComponent = [i0.styles];
var RenderType_PageNotAuthorizedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageNotAuthorizedComponent, data: {} });
export { RenderType_PageNotAuthorizedComponent as RenderType_PageNotAuthorizedComponent };
export function View_PageNotAuthorizedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "screen"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "background"], ["src", "/assets/v1.627.2-beta/assets/img/403/nogg_noaccess.svg"], ["width", "300px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Hold up!"])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "h6", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You do not have access to this page."])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You need to be an administrator to access it."])), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "a", [["class", "btn btn-primary home-button"], ["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Let's go home!"]))], function (_ck, _v) { var currVal_2 = "/"; _ck(_v, 12, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 12).target; var currVal_1 = i1.ɵnov(_v, 12).href; _ck(_v, 11, 0, currVal_0, currVal_1); }); }
export function View_PageNotAuthorizedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-not-authorized", [], null, null, null, View_PageNotAuthorizedComponent_0, RenderType_PageNotAuthorizedComponent)), i1.ɵdid(1, 49152, null, 0, i4.PageNotAuthorizedComponent, [], null, null)], null, null); }
var PageNotAuthorizedComponentNgFactory = i1.ɵccf("app-page-not-authorized", i4.PageNotAuthorizedComponent, View_PageNotAuthorizedComponent_Host_0, {}, {}, []);
export { PageNotAuthorizedComponentNgFactory as PageNotAuthorizedComponentNgFactory };
