import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PageNotAuthorizedComponent } from './page-not-authorized.component';

@NgModule({
  declarations: [PageNotAuthorizedComponent],
  imports: [RouterModule],
  exports: [PageNotAuthorizedComponent]
})
export class PageNotAuthorizedModule { }
