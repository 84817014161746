var _a;
import { reverseOperator } from './operators';
import { OPERATOR_TYPE } from '../../../models/query';
var ɵ0 = function (leftArg, rightArg) { return ({
    name: 'EQUALS',
    args: [
        {
            name: 'DATE_DIFF', args: [
                { name: 'TO_DATE', args: [leftArg] },
                { name: 'TO_DATE', args: [rightArg] },
                'seconds'
            ]
        },
        0
    ]
}); };
var dateEquals = {
    opNo: 2,
    toFormula: ɵ0
};
var dateNotEquals = reverseOperator(dateEquals);
var ɵ1 = function (leftArg, rightArg) { return ({
    name: 'OP',
    args: [
        {
            name: 'DATE_DIFF', args: [
                { name: 'TO_DATE', args: [leftArg] },
                { name: 'TO_DATE', args: [{ name: 'FIRST', args: [rightArg] }] },
                'seconds'
            ]
        },
        '<',
        0
    ]
}); };
var dateLessThan = {
    opNo: 2,
    toFormula: ɵ1
};
var dateGreaterThanOrEqualTo = reverseOperator(dateLessThan);
var ɵ2 = function (leftArg, rightArg) { return ({
    name: 'OP',
    args: [
        {
            name: 'DATE_DIFF', args: [
                { name: 'TO_DATE', args: [leftArg] },
                { name: 'TO_DATE', args: [{ name: 'LAST', args: [rightArg] }] },
                'seconds'
            ]
        },
        '>',
        0
    ]
}); };
var dateGreaterThan = {
    opNo: 2,
    toFormula: ɵ2
};
var dateLessThanOrEqualTo = reverseOperator(dateGreaterThan);
var ɵ3 = function (leftArg, rightArg) { return ({
    name: 'OP',
    args: [
        dateGreaterThanOrEqualTo.toFormula(leftArg, rightArg),
        '&&',
        dateLessThanOrEqualTo.toFormula(leftArg, rightArg)
    ]
}); };
var dateWithIn = {
    opNo: 2,
    toFormula: ɵ3
};
var dateNotWithIn = reverseOperator(dateWithIn);
export var dateOperators = {
    operators: (_a = {},
        _a[OPERATOR_TYPE.EQUALS] = dateEquals,
        _a[OPERATOR_TYPE.NOT_EQUALS] = dateNotEquals,
        _a[OPERATOR_TYPE.WITHIN] = dateWithIn,
        _a[OPERATOR_TYPE.NOT_WITHIN] = dateNotWithIn,
        _a[OPERATOR_TYPE.LESS_THAN] = dateLessThan,
        _a[OPERATOR_TYPE.GREATER_THAN] = dateGreaterThan,
        _a[OPERATOR_TYPE.LESS_THAN_EQUAL_TO] = dateLessThanOrEqualTo,
        _a[OPERATOR_TYPE.GREATER_THAN_EQUAL_TO] = dateGreaterThanOrEqualTo,
        _a),
    getParts: function (formula) {
        var currentPart = formula;
        var negative = false;
        if (currentPart.name === 'NOT') {
            currentPart = currentPart.args[0];
            negative = true;
        }
        if (currentPart.name === 'OP') {
            if (currentPart.args[1] === '<') {
                return {
                    operator: negative ? OPERATOR_TYPE.GREATER_THAN_EQUAL_TO : OPERATOR_TYPE.LESS_THAN,
                    leftArg: currentPart.args[0].args[0].args[0],
                    rightArg: currentPart.args[0].args[1].args[0].args[0]
                };
            }
            if (currentPart.args[1] === '>') {
                return {
                    operator: negative ? OPERATOR_TYPE.LESS_THAN_EQUAL_TO : OPERATOR_TYPE.GREATER_THAN,
                    leftArg: currentPart.args[0].args[0].args[0],
                    rightArg: currentPart.args[0].args[1].args[0].args[0]
                };
            }
            if (currentPart.args[1] === '&&') {
                return {
                    operator: negative ? OPERATOR_TYPE.NOT_WITHIN : OPERATOR_TYPE.WITHIN,
                    leftArg: currentPart.args[0].args[0].args[0].args[0].args[0],
                    rightArg: currentPart.args[0].args[0].args[0].args[1].args[0].args[0]
                };
            }
            throw new Error('Unknown operators:' + currentPart.name);
        }
        if (currentPart.name === 'EQUALS') {
            return {
                operator: negative ? OPERATOR_TYPE.NOT_EQUALS : OPERATOR_TYPE.EQUALS,
                leftArg: currentPart.args[0].args[0].args[0],
                rightArg: currentPart.args[0].args[1].args[0]
            };
        }
        throw new Error('Unknown operators: ' + currentPart.name);
    }
};
export { ɵ0, ɵ1, ɵ2, ɵ3 };
