import * as tslib_1 from "tslib";
import { PipeTransform } from '@angular/core';
import { get, has, isString } from 'lodash';
import { getI18nValue } from '../i18n/i18n-value/i18n-value';
import dataConstants from '../data/constants';
/*
 * pipe to filter array of objects by filter string
 * Usage:
 * *ngFor="let item of items | filter:filterText:['propertyName1', 'nestedObject.ropertyName2']"
 *
 * where filterText is a property holding entered text to use for filtering, prop names - properties to match filter text
 * give true to the forth parameter to make it case sensitive
 */
var FilterPipe = /** @class */ (function () {
    function FilterPipe() {
    }
    FilterPipe.prototype.transform = function (value, filterString, propNames, caseSensitive) {
        var e_1, _a, e_2, _b;
        if (caseSensitive === void 0) { caseSensitive = false; }
        if (!value || !value.length || !filterString) {
            return value;
        }
        if (!caseSensitive) {
            filterString = filterString.toLowerCase();
        }
        var arrResult = [];
        try {
            for (var value_1 = tslib_1.__values(value), value_1_1 = value_1.next(); !value_1_1.done; value_1_1 = value_1.next()) {
                var item = value_1_1.value;
                // if item is a string and there are no filter properties specified
                if ((!propNames || !propNames.length)) {
                    if (this.isII18nValuesType(item)) {
                        var i18nValue = getI18nValue(item);
                        var targetStr = caseSensitive ? i18nValue : i18nValue.toLowerCase();
                        if (targetStr.indexOf(filterString) !== -1) {
                            arrResult.push(item);
                            break;
                        }
                    }
                }
                else {
                    try {
                        // if filter properties are specified, filter by 
                        for (var propNames_1 = (e_2 = void 0, tslib_1.__values(propNames)), propNames_1_1 = propNames_1.next(); !propNames_1_1.done; propNames_1_1 = propNames_1.next()) {
                            var propName = propNames_1_1.value;
                            var propValue = get(item, propName, '');
                            if (propValue) {
                                var i18nValue = getI18nValue(propValue);
                                var targetStr = caseSensitive ? i18nValue : i18nValue.toLowerCase();
                                if (targetStr.indexOf(filterString) !== -1) {
                                    arrResult.push(item);
                                    break;
                                }
                            }
                        }
                    }
                    catch (e_2_1) { e_2 = { error: e_2_1 }; }
                    finally {
                        try {
                            if (propNames_1_1 && !propNames_1_1.done && (_b = propNames_1.return)) _b.call(propNames_1);
                        }
                        finally { if (e_2) throw e_2.error; }
                    }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (value_1_1 && !value_1_1.done && (_a = value_1.return)) _a.call(value_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return arrResult;
    };
    FilterPipe.prototype.isII18nValuesType = function (item) {
        var e_3, _a;
        if (isString(item)) {
            return true;
        }
        try {
            for (var _b = tslib_1.__values(dataConstants.ACCEPTABLE_LOCALE_IDS), _c = _b.next(); !_c.done; _c = _b.next()) {
                var localeId = _c.value;
                if (has(item, localeId)) {
                    return true;
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_3) throw e_3.error; }
        }
        return false;
    };
    return FilterPipe;
}());
export { FilterPipe };
