import { AssetPath } from './asset-path';

const m = /^https:\/\/services\.([^\.]+)\.elasticnoggin.com$/.exec((window as any).EIM.host);

export const environment = {
  production: true,
  ns: (window as any).EIM.ns,
  host: (window as any).EIM.host,
  enSrvFatPath: '/ensrv/',
  enSrvThinPath: '/ensrv',
  pubSubPath: '/pubsub',
  mediaServicePath: '/mediaservice',
  assetPath: AssetPath,
  gisServicePath: '/gis',
  twilioPath: '/twilio',
  sftpServicePath: '/sftp',
  s3ServicePath: '/s3',
  smtpServicePath: '/smtp',
  importServicePath: '/import',
  printServicePath: '/print',
  restPath: '/rest',
  integrationBucketPath: '/integration-bucket',
  i18nPath: '/i18n',
  testConnectionPath: '/testConnection',
  enCloudEnv: (m && m[1] ? m[1] : 'development'),
  release: AssetPath.replace(/^\/assets\//, '') || 'development',
  sentryDsn: 'https://d7d65db440944b61bf8e9acfede0ddcc@sentry.io/1425096',
  enableAnimations: true,
  learn: { // Note: the learn domain will get mutated in learn.component.ts for test and dev environments
    homeUrl: 'https://learn.noggin.io',
    tokenUrl: 'https://learn.noggin.io/token'
  },
  forum: {
    homeUrl: 'https://integrations.noggin.io/forums',
    tokenUrl: 'https://integrations.noggin.io/forums/init'
  },
  goJSLicenceKey: '73f04ee1b01c28c702d90776423d6bf919a57863c8841ea3080445f6e408381c279dea2a55d78cc680ea5efa1e2ec9d8d896397dc34e0739b43883d911ebd4fdbb3123b2440b418ca15022d189fc7ff1fa7823a2c3a767a1db7884edeba9dc9c59'
};
