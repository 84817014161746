var _a, _b;
import { EXTENDED_FIELD_DATA_TYPE, FIELD_DATA_TYPE } from '../models/field';
import { DataTypes } from '../data/models/scheme';
import { AssignedScheme } from '../models/assignable';
import { getDataTypeAndMetaFromField } from './get-data-type-and-meta-from-field';
// array types
var arrayTypeMap = (_a = {},
    _a[FIELD_DATA_TYPE.string] = DataTypes.stringArray,
    _a[FIELD_DATA_TYPE.string_i18n] = DataTypes.i18nArray,
    _a[FIELD_DATA_TYPE.json] = DataTypes.jsonArray,
    _a[FIELD_DATA_TYPE.bool] = DataTypes.booleanArray,
    _a[FIELD_DATA_TYPE.number] = DataTypes.numberArray,
    _a[FIELD_DATA_TYPE.object] = DataTypes.stringArray,
    _a[FIELD_DATA_TYPE.datetime] = DataTypes.datetimeArray,
    _a[FIELD_DATA_TYPE.date] = DataTypes.datetimeArray,
    _a[FIELD_DATA_TYPE.uri] = DataTypes.uriArray,
    _a[FIELD_DATA_TYPE.email] = DataTypes.emailArray,
    _a[FIELD_DATA_TYPE.phone] = DataTypes.phoneArray,
    _a[FIELD_DATA_TYPE.geography] = DataTypes.geographyArray,
    _a);
// types
var typeMap = (_b = {},
    _b[FIELD_DATA_TYPE.string] = DataTypes.string,
    _b[FIELD_DATA_TYPE.string_i18n] = DataTypes.i18n,
    _b[FIELD_DATA_TYPE.json] = DataTypes.json,
    _b[FIELD_DATA_TYPE.bool] = DataTypes.boolean,
    _b[FIELD_DATA_TYPE.number] = DataTypes.number,
    _b[FIELD_DATA_TYPE.object] = DataTypes.string,
    _b[FIELD_DATA_TYPE.datetime] = DataTypes.datetime,
    _b[FIELD_DATA_TYPE.date] = DataTypes.datetime,
    _b[FIELD_DATA_TYPE.uri] = DataTypes.uri,
    _b[FIELD_DATA_TYPE.email] = DataTypes.email,
    _b[FIELD_DATA_TYPE.phone] = DataTypes.phone,
    _b[FIELD_DATA_TYPE.geography] = DataTypes.geography,
    _b);
export function fieldToScheme(field, allFieldDataType) {
    var $tip = field.$tip, datatype = field.datatype, maxcount = field.maxcount;
    // TODO test ternary
    var _allFieldDataType = allFieldDataType
        ? allFieldDataType
        : getDataTypeAndMetaFromField({ field: field });
    // extendedDataType will return extended computed data types:
    // file, address etc
    if (_allFieldDataType === EXTENDED_FIELD_DATA_TYPE.assignable) {
        return {
            name: $tip,
            type: DataTypes.object,
            scheme: AssignedScheme,
            mutable: true
        };
    }
    if (maxcount && maxcount === 1) {
        return simpleTypeScheme($tip, typeMap[datatype]);
    }
    return simpleTypeScheme($tip, arrayTypeMap[datatype]);
}
function simpleTypeScheme($tip, type) {
    return {
        name: $tip,
        type: type
    };
}
