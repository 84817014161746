import * as tslib_1 from "tslib";
import { of } from 'rxjs';
import { first, map, filter } from 'rxjs/operators';
import { tagToMap } from '../util/tag-utils';
import { uniq, sortedUniq, union, get, set } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "./process.service";
import * as i2 from "./formula.service";
import * as i3 from "./query.service";
import * as i4 from "./eno.service";
import * as i5 from "../shell/services/my-profile.service";
export var SYSTEM_SECURITY_POLICIES = [
    { $tip: 'app/security-policy/system-all-users-read', name: 'All users read-only' },
    { $tip: 'app/security-policy/system-all-users-read-write', name: 'All users read/write' },
    { $tip: 'app/security-policy/system-all-users-read-write-delete', name: 'All users read/write/delete' }
];
var SecurityService = /** @class */ (function () {
    function SecurityService(processService, formulaService, queryService, enoService, myProfileService) {
        this.processService = processService;
        this.formulaService = formulaService;
        this.queryService = queryService;
        this.enoService = enoService;
        this.myProfileService = myProfileService;
        this.securityPolicyVariantCache = {};
    }
    // Retrieves a list of security policies
    SecurityService.prototype.getSecurityPolicies$ = function () {
        return this.queryService.execute1dFirst('eim/query/get-security-policies', {
            dimensionOptions: [{
                    label: 'Tip dimension',
                    formula: 'TIP()',
                    sortby: ['TITLE()'],
                    sortdir: ['asc']
                }]
        }).pipe(map(function (results) { return results.map(function (result) { return ({
            $tip: result.$tip,
            name: result.Name
        }); }); }), map(function (results) { return tslib_1.__spread(SYSTEM_SECURITY_POLICIES, results); }));
    };
    // Retrieve a security policy
    SecurityService.prototype.getSecurityPolicy$ = function (tip) {
        return this.enoService.readEno(tip).pipe(map(function (eno) {
            var tags = tagToMap(eno.getFieldValues('security/policy/tag'));
            var cohorts = tags.has('cohortstash') ? JSON.parse(tags.get('cohortstash')) : {};
            var securityPolicy = tslib_1.__assign({ $tip: eno.tip, name: eno.getFieldStringValue('security/policy/label'), description: tags.get('description') || '', type: tags.get('policytype') }, cohorts);
            return securityPolicy;
        }));
    };
    // Create a new security policy
    SecurityService.prototype.createSecurityPolicy$ = function (securityPolicy) {
        var vars = this.convertSecurityPolicyToVars(securityPolicy);
        return this
            .processService
            .start('eim/process/security/create-security-policy', vars)
            .pipe(filter(function (_a) {
            var finished = _a.finished;
            return Boolean(finished);
        }), first(), map(function (response) {
            if (response && response.vars && response.vars['SecurityPolicy'] && response.vars['SecurityPolicy'].length > 0) {
                return response.vars['SecurityPolicy'][0];
            }
            throw new Error('Unable to create new security policy');
        }));
    };
    // Update a central security policy
    SecurityService.prototype.updateSecurityPolicy$ = function (securityPolicy) {
        var vars = tslib_1.__assign({ SecurityPolicyTip: [securityPolicy.$tip] }, this.convertSecurityPolicyToVars(securityPolicy));
        return this
            .processService
            .start('eim/process/security/update-security-policy', vars)
            .pipe(filter(function (_a) {
            var finished = _a.finished;
            return Boolean(finished);
        }), first(), map(function (response) {
            if (response && response.vars && response.vars['Success'] && response.vars['Success'].length > 0) {
                return response.vars['Success'][0] === 'true';
            }
            throw new Error('Unable to update security policy');
        }));
    };
    // Delete an existing security policy
    SecurityService.prototype.deleteSecurityPolicy$ = function (tip) {
        var vars = { SecurityPolicyTip: [tip] };
        return this
            .processService
            .start('eim/process/security/delete-security-policy', vars)
            .pipe(filter(function (_a) {
            var finished = _a.finished;
            return Boolean(finished);
        }), first(), map(function (response) {
            if (response && response.vars && response.vars['Success'] && response.vars['Success'].length > 0) {
                return response.vars['Success'][0] === 'true';
            }
            throw new Error('Unable to delete security policy');
        }));
    };
    // Duplicate an existing security policy
    SecurityService.prototype.duplicateSecurityPolicy$ = function (tip) {
        var vars = { SecurityPolicyTip: [tip] };
        return this
            .processService
            .start('eim/process/security/duplicate-security-policy', vars)
            .pipe(filter(function (_a) {
            var finished = _a.finished;
            return Boolean(finished);
        }), first(), map(function (response) {
            if (response && response.vars && response.vars['SecurityPolicy'] && response.vars['SecurityPolicy'].length > 0) {
                return response.vars['SecurityPolicy'][0];
            }
            throw new Error('Unable to duplicate security policy');
        }));
    };
    // Creates a bespoke security policy
    SecurityService.prototype.createBespokeSecurityPolicy$ = function (securityPolicy) {
        var vars = this.convertSecurityPolicyToVars(securityPolicy);
        return this
            .processService
            .start('eim/process/security/create-bespoke-security-policy', vars)
            .pipe(filter(function (_a) {
            var finished = _a.finished;
            return Boolean(finished);
        }), first(), map(function (response) {
            if (response && response.vars && response.vars['SecurityPolicy'] && response.vars['SecurityPolicy'].length > 0) {
                return response.vars['SecurityPolicy'][0];
            }
            throw new Error('Unable to create bespoke security policy');
        }));
    };
    // Updates a bespoke security policy
    SecurityService.prototype.updateBespokeSecurityPolicy$ = function (objectTip, securityPolicy) {
        var vars = tslib_1.__assign({ ObjectTip: [objectTip] }, this.convertSecurityPolicyToVars(securityPolicy));
        return this
            .processService
            .start('eim/process/security/update-bespoke-security-policy', vars)
            .pipe(filter(function (_a) {
            var finished = _a.finished;
            return Boolean(finished);
        }), first(), map(function (response) {
            if (response && response.vars && response.vars['Success'] && response.vars['Success'].length > 0) {
                return response.vars['Success'][0] === 'true';
            }
            throw new Error('Unable to update bespoke security policy');
        }));
    };
    // Gets the security policy variant tip given the primary object
    SecurityService.prototype.getSecurityPolicyVariantTip$ = function (variant, primaryObjectTip, primarySecurityPolicyTip) {
        var _this = this;
        var vars = { Variant: [variant] };
        if (primaryObjectTip) {
            vars['PrimaryObjectTip'] = [primaryObjectTip];
        }
        if (primarySecurityPolicyTip) {
            var cachedVariantSecurityPolicyTip = get(this, ['securityPolicyVariantCache', primarySecurityPolicyTip, variant]);
            if (cachedVariantSecurityPolicyTip) {
                return of(cachedVariantSecurityPolicyTip);
            }
            vars['PrimarySecurityPolicyTip'] = [primarySecurityPolicyTip];
        }
        return this
            .processService
            .start('eim/process/security/get-security-policy-variant', vars)
            .pipe(filter(function (_a) {
            var finished = _a.finished;
            return Boolean(finished);
        }), map(function (response) {
            var variantSecurityPolicyTip = get(response, 'vars.VariantSecurityPolicyTip', []);
            if (variantSecurityPolicyTip.length > 0) {
                set(_this, ['securityPolicyVariantCache', primarySecurityPolicyTip, variant], variantSecurityPolicyTip[0]);
                return variantSecurityPolicyTip[0];
            }
            throw new Error('Unable to get security policy variant');
        }));
    };
    // Return the security policy tip that should be applied to new objects
    SecurityService.prototype.getNewObjectSecurityPolicyTip$ = function () {
        return this.myProfileService.getMyProfile$().pipe(map(function (myProfile) {
            return myProfile.defaultPolicy || 'app/security-policy/system-all-users-read-write-delete';
        }));
    };
    // Returns the unique tips in security cohorts
    SecurityService.prototype.cohortsTips = function (cohorts) {
        var tips = [];
        if (!cohorts) {
            return tips;
        }
        Object.keys(cohorts).forEach(function (key) {
            cohorts[key].forEach(function (cohort) {
                tips.push(cohort.tip);
            });
        });
        return uniq(tips);
    };
    // Returns true if the "All profiles" was selected, meaning all authenticated users
    SecurityService.prototype.isAllCohorts = function (cohortsTips) {
        return cohortsTips.indexOf('__ALL__') > -1;
    };
    // Returns a summary label for security cohorts
    SecurityService.prototype.cohortsLabelSummary = function (cohorts) {
        var labels = [];
        if (!cohorts) {
            return null;
        }
        Object.keys(cohorts).forEach(function (key) {
            cohorts[key].forEach(function (cohort) {
                if (cohort.label) {
                    labels.push(cohort.label);
                }
            });
        });
        labels.sort();
        labels = sortedUniq(labels);
        if (labels.length > 2) {
            var excess = labels.length - 2;
            labels.splice(2, excess, '+' + excess + ' more');
        }
        return labels.join(', ');
    };
    // Convert an ISecurityPolicy to IVars for calling EnSrv
    SecurityService.prototype.convertSecurityPolicyToVars = function (securityPolicy) {
        var readCohorts = this.cohortsTips(securityPolicy.readCohorts);
        var deleteCohorts = this.cohortsTips(securityPolicy.deleteCohorts);
        var changeCohorts = uniq(union(this.cohortsTips(securityPolicy.changeCohorts), deleteCohorts));
        var collaborateCohorts = uniq(union(this.cohortsTips(securityPolicy.collaborateCohorts), changeCohorts));
        var allDeleteCohorts = this.isAllCohorts(deleteCohorts);
        var allReadCohorts = this.isAllCohorts(readCohorts);
        var allChangeCohorts = this.isAllCohorts(changeCohorts);
        var allCollaborateCohorts = this.isAllCohorts(collaborateCohorts);
        return {
            name: [securityPolicy.name],
            description: [securityPolicy.description],
            readCohorts: allReadCohorts ? [] : readCohorts,
            collaborateCohorts: allCollaborateCohorts ? [] : collaborateCohorts,
            changeCohorts: allChangeCohorts ? [] : changeCohorts,
            deleteCohorts: allDeleteCohorts ? [] : deleteCohorts,
            allReadCohorts: [allReadCohorts ? 'true' : 'false'],
            allCollaborateCohorts: [allCollaborateCohorts ? 'true' : 'false'],
            allChangeCohorts: [allChangeCohorts ? 'true' : 'false'],
            allDeleteCohorts: [allDeleteCohorts ? 'true' : 'false'],
            cohortStash: [
                JSON.stringify({
                    readCohorts: securityPolicy.readCohorts,
                    collaborateCohorts: securityPolicy.collaborateCohorts,
                    changeCohorts: securityPolicy.changeCohorts,
                    deleteCohorts: securityPolicy.deleteCohorts
                })
            ]
        };
    };
    // Returns true if the current user can perform all the given actions on all the given objects
    SecurityService.prototype.canAction = function (objectTips, securityActions) {
        var formula = 'CAN_ACTION(SECURITY(VAR("Objects")), VAR("Actions"))';
        return this.formulaService.evaluate(formula, null, null, { Objects: objectTips, Actions: securityActions }).pipe(map(function (result) { return result && result[0] === 'true'; }));
    };
    // Returns true if the current user can read the given object
    SecurityService.prototype.canRead = function (objectTip) {
        var tips = stringOrStringArrayToStringArray(objectTip);
        return this.canAction(tips, ['security/action/read']);
    };
    // Returns true if the current user can update the given object
    SecurityService.prototype.canUpdate = function (objectTip) {
        var tips = stringOrStringArrayToStringArray(objectTip);
        return this.canAction(tips, ['security/action/update']);
    };
    // Returns true if the current user can delete the given object
    SecurityService.prototype.canDelete = function (objectTip) {
        var tips = stringOrStringArrayToStringArray(objectTip);
        return this.canAction(tips, ['security/action/delete']);
    };
    // Returns true if the current user can collaborate on the given object
    SecurityService.prototype.canCollaborate = function (objectTip) {
        var tips = stringOrStringArrayToStringArray(objectTip);
        return this.canAction(tips, ['app/security-action/collaborate']);
    };
    // "collaborate" is a separate concept that does not inherit "update" permission or vice versa.
    // "collaborate" only applies to context objects not sub-objects, e.g. chat posts, plans etc.
    SecurityService.prototype.canUpdateOrCollaborate = function (objectTip) {
        var objectTips = stringOrStringArrayToStringArray(objectTip);
        var formula = "\nOR(\n   CAN_ACTION(\n      SECURITY(\n         VAR(\"Objects\")\n      ),\n      \"security/action/update\"\n   ),\n   CAN_ACTION(\n      SECURITY(\n         VAR(\"Objects\")\n      ),\n      \"app/security-action/collaborate\"\n   )\n)\n    ";
        return this.formulaService.evaluate(formula, null, null, { Objects: objectTips }).pipe(map(function (result) { return result && result[0] === 'true'; }));
    };
    // Returns true if the current user can create an instance of the given object type
    SecurityService.prototype.canCreate = function (objectTypeTip) {
        var tips = stringOrStringArrayToStringArray(objectTypeTip);
        return this.canAction(tips, ['security/action/create']);
    };
    SecurityService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SecurityService_Factory() { return new SecurityService(i0.ɵɵinject(i1.ProcessService), i0.ɵɵinject(i2.FormulaService), i0.ɵɵinject(i3.QueryService), i0.ɵɵinject(i4.EnoService), i0.ɵɵinject(i5.MyProfileService)); }, token: SecurityService, providedIn: "root" });
    return SecurityService;
}());
export { SecurityService };
function stringOrStringArrayToStringArray(input) {
    return Array.isArray(input) ? input : [input];
}
