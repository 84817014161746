import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./auth-guard.service";
import * as i2 from "./admin-guard.service";
var AuthAdminGuard = /** @class */ (function () {
    function AuthAdminGuard(authGuard, adminGuard) {
        this.authGuard = authGuard;
        this.adminGuard = adminGuard;
    }
    AuthAdminGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        return this
            .authGuard
            .canActivate(route, state)
            .pipe(switchMap(function (canActivate) {
            return canActivate
                ? _this.adminGuard.canActivate(route, state)
                : of(false);
        }));
    };
    AuthAdminGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthAdminGuard_Factory() { return new AuthAdminGuard(i0.ɵɵinject(i1.AuthGuardService), i0.ɵɵinject(i2.AdminGuardService)); }, token: AuthAdminGuard, providedIn: "root" });
    return AuthAdminGuard;
}());
export { AuthAdminGuard };
