import { BehaviorSubject, Observable } from 'rxjs';
import dataConstants from './constants';
import { get } from 'lodash';
import { decodeJWT } from '../auth/decode-jwt';
import { environment } from '../../environments/environment';
import { SHA256 } from 'crypto-js';
import * as i0 from "@angular/core";
import * as i1 from "../util/location.service";
import * as i2 from "../util/logger.service";
import * as i3 from "../dom-tokens";
var LOCAL_STORAGE_CONST = dataConstants.LOCAL_STORAGE;
export var REDIRECT_AFTER_LOGIN_STORAGE_KEY = 'redirect-to';
var SESSION_MANAGER_LOG_PREFIX = '[session-manager]';
var SessionManagerService = /** @class */ (function () {
    function SessionManagerService(locationService, loggerService, localStorage) {
        this.locationService = locationService;
        this.loggerService = loggerService;
        this.localStorage = localStorage;
        this.sessionInfoSubject = new BehaviorSubject(this.loadSessionFromLocalStorage());
    }
    Object.defineProperty(SessionManagerService.prototype, "originalRequestedDestination", {
        get: function () {
            return this.localStorage.getItem(REDIRECT_AFTER_LOGIN_STORAGE_KEY);
        },
        enumerable: true,
        configurable: true
    });
    SessionManagerService.prototype.setOriginalRequestedDestination = function (value) {
        this.localStorage.setItem(REDIRECT_AFTER_LOGIN_STORAGE_KEY, value);
    };
    SessionManagerService.prototype.clearOriginalRequestedDestination = function () {
        this.localStorage.removeItem(REDIRECT_AFTER_LOGIN_STORAGE_KEY);
    };
    SessionManagerService.prototype.getAuthToken = function () {
        return get(this.sessionInfoSubject.getValue(), 'token', '');
    };
    SessionManagerService.prototype.getSessionId = function () {
        return get(this.sessionInfoSubject.getValue(), 'id', '');
    };
    SessionManagerService.prototype.getSessionInfo$ = function () {
        return this.sessionInfoSubject.asObservable();
    };
    Object.defineProperty(SessionManagerService.prototype, "tokenChanged$", {
        // @deprecated just session info
        get: function () {
            return this.sessionInfoSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    // used by sso login and auth service only for tokens we know should be valid
    // should be called always set token or something
    SessionManagerService.prototype.updateSessionInfoAndRedirectToOriginalRequestedDestination = function (sessionToken) {
        var currentToken = this.getAuthToken();
        if (!this.shouldSetToken(sessionToken, currentToken)) {
            return;
        }
        this.setToken(sessionToken);
        this.redirect(this.originalRequestedDestination);
    };
    // used by ensrv.service used for temporary session tokens
    SessionManagerService.prototype.updateSessionInfo = function (tokenToSet) {
        var currentToken = this.getAuthToken();
        if (!this.shouldSetToken(tokenToSet, currentToken)) {
            return;
        }
        if (anonymousToAnonymous(currentToken, tokenToSet)) {
            this.changeToken(tokenToSet);
            return;
        }
        this.setToken(tokenToSet);
        this.redirect();
    };
    SessionManagerService.prototype.getLastProfileWithEmail = function (email) {
        return this.localStorage.getItem(this.getLastProfileKey(email)) || '';
    };
    SessionManagerService.prototype.getLastProfileKey = function (email) {
        return LOCAL_STORAGE_CONST.SESSION_LAST_PROFILE + "-" + SHA256(email);
    };
    SessionManagerService.prototype.setLastProfile = function (email, profileTip) {
        this.localStorage.setItem(this.getLastProfileKey(email), profileTip);
    };
    SessionManagerService.prototype.shouldSetToken = function (tokenToSet, currentToken) {
        if (tokenToSet === '') {
            // sometime server 503s responses return these we dont really want to set them
            return false;
        }
        if (tokenToSet === currentToken) {
            // lets not waste our time
            return false;
        }
        return true;
    };
    SessionManagerService.prototype.setToken = function (tokenToSet) {
        if (!tokenToSet) {
            this.localStorage.removeItem(LOCAL_STORAGE_CONST.SESSION_TOKEN);
        }
        else {
            this.localStorage.setItem(LOCAL_STORAGE_CONST.SESSION_TOKEN, tokenToSet);
        }
        this.loggerService.info(SESSION_MANAGER_LOG_PREFIX + " set new token - sessionId: ", get(decodeJWT(tokenToSet), 'sessionId', null));
        return true;
    };
    SessionManagerService.prototype.changeToken = function (tokenToSet) {
        this.setToken(tokenToSet);
        this.loggerService.info(SESSION_MANAGER_LOG_PREFIX + " change token");
        // the next request will not be pristine update sessionInfoSubject
        this.sessionInfoSubject.next(this.loadSessionFromLocalStorage());
    };
    SessionManagerService.prototype.redirect = function (redirectUrl) {
        // This is the case token has been changed somehow (ex. revoking access, login, switch profile, logout)
        // It is always safer to refresh whenever token is changed (Owen)
        var redirect = redirectUrl || '/';
        this.loggerService.info(SESSION_MANAGER_LOG_PREFIX + " redirecting to " + redirect);
        this.locationService.redirect(redirect);
    };
    SessionManagerService.prototype.loadSessionFromLocalStorage = function () {
        var jwtToken = this.localStorage.getItem(LOCAL_STORAGE_CONST.SESSION_TOKEN);
        var _a = decodeJWT(jwtToken), user = _a.user, profile = _a.profile, sessionId = _a.sessionId, inactivityTimeout = _a.inactivityTimeout, sessionExpiry = _a.sessionExpiry, namespace = _a.namespace;
        if (!namespaceValid(namespace, this.loggerService.info.bind(this))) {
            this.loggerService.info(SESSION_MANAGER_LOG_PREFIX + " namespace invalid clearing and redirecting");
            this.localStorage.removeItem(LOCAL_STORAGE_CONST.SESSION_TOKEN);
            this.locationService.redirect('/');
            return;
        }
        var localSessionDetails = {
            id: sessionId,
            token: jwtToken,
            user: user,
            profile: profile,
            inactivityTimeout: inactivityTimeout,
            sessionExpiry: sessionExpiry
        };
        this.loggerService.info(SESSION_MANAGER_LOG_PREFIX + " namespace valid returning - sessionId:", localSessionDetails.id);
        return localSessionDetails;
    };
    SessionManagerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionManagerService_Factory() { return new SessionManagerService(i0.ɵɵinject(i1.LocationService), i0.ɵɵinject(i2.LoggerService), i0.ɵɵinject(i3.LOCAL_STORAGE)); }, token: SessionManagerService, providedIn: "root" });
    return SessionManagerService;
}());
export { SessionManagerService };
function anonymousToAnonymous(currentToken, tokenToSet) {
    // anonymous or null -> user-session (redirect)
    // anonymous or null -> anonymous or null (no-redirect)
    // user-session -> user-session (redirect)
    // user-session -> anonymous (redirect)
    if (isAnonymousOrEmptySession(currentToken) && isAnonymousOrEmptySession(tokenToSet)) {
        return true;
    }
}
function isAnonymousOrEmptySession(token) {
    // our anonymous tokens don't have session expiry
    // or null token has null session expiry
    return !decodeJWT(token).sessionExpiry;
}
export function namespaceValid(namespace, logFn) {
    if (namespace === null) {
        // namespace null is pristine see comment on pristine session
        logFn(SESSION_MANAGER_LOG_PREFIX + " namespace is valid - null (pristine)");
        return true;
    }
    if (namespace === environment.ns) {
        logFn(SESSION_MANAGER_LOG_PREFIX + " namespace is valid - environment is correct");
        return true;
    }
    return false;
}
