import { Formula } from '../data/models/types';
import { FormulaSpec, QuoteString, Stringify } from '../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { isFormulaSpec } from './is-formula-spec';
import { ASSIGN_TO_SYSTEM_VALUE } from '../shared/dynamic-field-chooser/assign-to-system-side-sheet/assign-to-system-side-sheet.component';

export const CURRENT_USER_FORMULA = 'FIELD("app/user-session:user",REFERENCES("app/user-session:session",SESSION()))';

export function isCurrentUserFormula(formula: Formula | FormulaSpec): boolean {
  const stringFormula = isFormulaSpec(formula) ? Stringify(formula) : formula;

  return !!stringFormula && (stringFormula.replace(/,\s/g, ',') === CURRENT_USER_FORMULA.replace(/,\s/g, ',') ||
    stringFormula.replace(/,\s/g, ',') === QuoteString(CURRENT_USER_FORMULA) ||
    stringFormula.replace(/,\s/g, ',') === ASSIGN_TO_SYSTEM_VALUE.CURRENT_USER.replace(/,\s/g, ',') ||
    stringFormula.replace(/,\s/g, ',') === QuoteString(ASSIGN_TO_SYSTEM_VALUE.CURRENT_USER.replace(/,\s/g, ',')));
}
