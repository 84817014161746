import { FieldType } from '@ngx-formly/core';
import { Component } from '@angular/core';

@Component({
  selector: 'app-formly-boolean-chooser',
  template: `
      <app-boolean-chooser-field
          [formControl]="formControl"
          [label]="field.templateOptions.label">
      </app-boolean-chooser-field>
  `
})
export class FormlyBooleanChooserComponent extends FieldType {

}
