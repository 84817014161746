import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ViewContainerRef, ChangeDetectorRef } from '@angular/core';
import { of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { FieldWrapper } from '@ngx-formly/core';
var FormlyWrapperFormFieldComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FormlyWrapperFormFieldComponent, _super);
    // formEditingService will only be present when designing the form
    function FormlyWrapperFormFieldComponent(cdr, formEditingService) {
        var _this = _super.call(this) || this;
        _this.cdr = cdr;
        _this.formEditingService = formEditingService;
        _this.isSelectedSub = new Subscription();
        _this.isSelected = false;
        _this.fullWidth = true;
        return _this;
    }
    FormlyWrapperFormFieldComponent.prototype.click = function () {
        if (!(this.form && this.field && this.field['designMode'])) {
            return;
        }
        this.formEditingService.selectFormField(this.field.key);
    };
    FormlyWrapperFormFieldComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.fullWidth = this.field['fullWidth'] || false;
        if (this.formEditingService) {
            this.isSelectedSub = this.formEditingService.currentlySelectedKey$.pipe(map(function (v) {
                if (!_this.field || !_this.field.key) {
                    return;
                }
                return v === _this.field.key;
            })).subscribe(function (isSelected) {
                _this.isSelected = isSelected;
                _this.cdr.markForCheck();
            });
        }
        else {
            of(false);
        }
    };
    FormlyWrapperFormFieldComponent.prototype.ngOnDestroy = function () {
        this.isSelectedSub.unsubscribe();
    };
    return FormlyWrapperFormFieldComponent;
}(FieldWrapper));
export { FormlyWrapperFormFieldComponent };
