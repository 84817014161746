import { Injectable } from '@angular/core';
import { CompositeNodeConverter, INodeAndLink } from './CompositeNodeConverter';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import { isEmpty } from 'lodash';
import { IRetrieveFileEditor } from '../node-editors/retrieve-file-editor/retrieve-file-editor.component';
import {
  createConnection,
  createFormulaFromStringInterpolation,
  createSetVarNode,
  createSubProcessNode,
  createSwitchNode
} from './utils/create-node-utils';
import { retrieveFileLocationProcessTips } from '../node-editors/shared/file-location/file-location-types';

const NS = 'RetrieveFileConverterService';

@Injectable({
  providedIn: 'root'
})
export class RetrieveFileConverterService extends CompositeNodeConverter {
  type = 'processnode/logic/subprocess';
  processNodeType = 'processnodetype/logic/subprocess';

  constructor() {
    super();
  }

  public convert(nodeData: IRetrieveFileEditor): INodeAndLink {
    if (!this.isValid(nodeData)) {
      throw new InvalidWorkflowNodeDataError(`Please correct invalid data on retrieve-file node [${ nodeData.fields.title }]`, nodeData);
    }

    const { locationTip, variableToSetKey, filePath, securityPolicy, locationType } = nodeData.fields;

    const vars = {
      'Location tip': locationTip || 'ARRAY()',
      'Location type': locationType,
      'Var key': variableToSetKey,
      'File path': createFormulaFromStringInterpolation(filePath),
      'Security policy': securityPolicy || 'app/security-policy/system-all-users-read-write-delete'
    };

    const setVarStatusNode = createSetVarNode(vars, nodeData.tip);

    const subprocessNode = createSubProcessNode(retrieveFileLocationProcessTips[locationType], vars);

    const doneConnectionForSetVarNode = createConnection('done', subprocessNode);
    setVarStatusNode.connections.push(doneConnectionForSetVarNode);

    const switchStatusNode = createSwitchNode({ name: 'VAR', args: ['Status'] }, ['fileError']);

    const doneConnectionForSubprocessNode = createConnection('done', switchStatusNode);
    subprocessNode.connections.push(doneConnectionForSubprocessNode);

    const nodesAndLink: INodeAndLink = {
      nodes: [
        setVarStatusNode,
        subprocessNode,
        switchStatusNode
      ],
      links: [
        doneConnectionForSetVarNode,
        doneConnectionForSubprocessNode
      ]
    };

    this.populateOutgoingLinks(nodeData).forEach(link => {
      switchStatusNode.connections.push(link);
      nodesAndLink.links.push(link);
    });

    return nodesAndLink;
  }

  private isValid(nodeData: IRetrieveFileEditor): boolean {
    return !isEmpty(nodeData.fields.title)
      && !isEmpty(nodeData.fields.filePath)
      && !isEmpty(nodeData.fields.locationType)
      && !isEmpty(nodeData.fields.variableToSetKey);
  }
}
