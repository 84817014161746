import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { get, intersection, some } from 'lodash';
import { Subscription } from 'rxjs';
import { getMostInnerContextFromContextFormula, insertMostInnerContextToContextFormula, removeMostInnerContextFromContextFormula } from '../../../util/context-formula-wrapper';
import { isCustomFormula } from '../../../util/custom-formula';
import { typeScheme } from '../../../models/type';
import { isSuperType } from '../../type-chooser-side-sheet/super-types';
import { metaDataFormulas, MetaDataKey } from '../meta-data-formulas';
import { CustomFormulaSideSheetComponent } from '../custom-formula/custom-formula-side-sheet/custom-formula-side-sheet.component';
import { QuoteString } from './formula';
import { ToggleType } from '../../../side-sheet/side-sheet-list-item/side-sheet-list-item.component';
export var TIP_FORMULA = 'TIP()';
var FieldFormulaSideSheetComponent = /** @class */ (function () {
    function FieldFormulaSideSheetComponent(objectService, sideSheetService, toastService, fieldFormulaSideSheetService, displayFormulaWrapperService) {
        this.objectService = objectService;
        this.sideSheetService = sideSheetService;
        this.toastService = toastService;
        this.fieldFormulaSideSheetService = fieldFormulaSideSheetService;
        this.displayFormulaWrapperService = displayFormulaWrapperService;
        this.allowCustomFormula = false;
        this.selected = [];
        this.showRelatedItems = true;
        this.showMetaItems = true;
        this.showMetaData = true;
        this.metaDataKeysToExcluded = [MetaDataKey.urlLink];
        this.formatsFields = false;
        this.isSort = false;
        this.searchableFieldsOnly = false;
        this.isDetailWidget = false;
        this.isLastSheet = true;
        this.isLoading = true;
        this.metaDataFormulas = [];
        this.isSuperType = false;
        this.hideAdditionalFields = true;
        this.subscriptions = new Subscription();
    }
    FieldFormulaSideSheetComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.selected) {
            this.selected = this.displayFormulaWrapperService.removeDisplayFormulaWrappers(this.selected);
        }
        this.isSuperType = isSuperType(this.objectTypeTip);
        if ((!this.dataTypeConstraint || this.dataTypeConstraint === 'object'
            || get(this.fieldFormulaSideSheetService, 'objectTypesWithAdditionalFields', []).length > 0) && !this.isDetailWidget) {
            this.hideAdditionalFields = false;
        }
        if (!this.isDetailWidget) {
            this.getDisplayMetaDataFormulas();
        }
        if (this.objectType) {
            this.getFieldsFromObjectType();
            this.isLoading = false;
            return;
        }
        if (this.isSuperType) {
            // get fields for super types
            this.subscriptions.add(this.fieldFormulaSideSheetService.getSuperTypeFields(this.objectTypeTip)
                .subscribe(function (x) { return _this.isLoading = false; }, function (err) {
                _this.toastService.showErrorToast('Unable to load related object type fields');
                _this.sideSheetService.pop();
            }));
        }
        else {
            // get fields for normal object types
            this.subscriptions.add(this.objectService.getObject(this.objectTypeTip, typeScheme).subscribe(function (objectType) {
                _this.objectType = objectType;
                _this.getFieldsFromObjectType();
                _this.isLoading = false;
            }, function (err) {
                _this.toastService.showErrorToast('Unable to load object type or access was denied');
                _this.sideSheetService.pop();
            }));
        }
    };
    FieldFormulaSideSheetComponent.prototype.getDisplayMetaDataFormulas = function () {
        var _this = this;
        this.metaDataFormulas = metaDataFormulas.filter(function (meta) {
            if (_this.metaDataKeysToExcluded.includes(meta.key)) {
                return false;
            }
            if (_this.searchableFieldsOnly && !meta.searchable) {
                return false;
            }
            if (_this.dataTypeConstraint && !meta.hasDrillDown) {
                if (_this.dataTypeConstraint !== meta.datatype) {
                    return false;
                }
                if (_this.objectTypeConstraint && !_this.isSuperType && !isSuperType(_this.objectTypeConstraint)) {
                    if (meta.key === MetaDataKey.thisObject && _this.objectTypeTip !== _this.objectTypeConstraint) {
                        return false;
                    }
                    if (meta.key !== MetaDataKey.thisObject
                        && meta.typerestrict
                        && !some(meta.typerestrict, function (t) { return t === _this.objectTypeConstraint; })) {
                        return false;
                    }
                }
            }
            return true;
        });
    };
    FieldFormulaSideSheetComponent.prototype.getFieldsFromObjectType = function () {
        var _this = this;
        // Get the fields that should be shown in the "Fields" section
        // They must be non-objects and must comply with a data type constraint if there is one
        this.fieldItems = (this.objectType.field || []).filter(function (field) {
            if (_this.searchableFieldsOnly && !field.searchable) {
                return false;
            }
            return field.datatype !== 'object'
                && (!_this.dataTypeConstraint || _this.dataTypeConstraint === field.datatype)
                && (!_this.tags || intersection((field.tag || []), _this.tags).length > 0);
        });
        // Get the object fields they can descend down
        this.relatedItems = this.showRelatedItems
            ? (this.objectType.field || []).filter(function (field) {
                return field.datatype === 'object' && field.typerestrict && field.typerestrict.length === 1;
            })
            : [];
    };
    // The "Done" button is pressed
    FieldFormulaSideSheetComponent.prototype.onPrimary = function () {
        var _this = this;
        this.subscriptions.add(this.displayFormulaWrapperService.addDisplayFormulaWrapper(this.selected, this.objectTypeTip, this.displayFormulaRule).subscribe(function (formulas) { return _this.finalize(formulas); }));
    };
    FieldFormulaSideSheetComponent.prototype.finalize = function (formulas) {
        this.onDone(formulas);
        this.sideSheetService.pop();
    };
    // Return the FIELD() formula for selection given the field tip
    FieldFormulaSideSheetComponent.prototype.fieldFormula = function (fieldTip) {
        return this.formatsFields && this.isLastSheet ? "FMT_FIELD(" + QuoteString(fieldTip) + ")" : "FIELD(" + QuoteString(fieldTip) + ")";
    };
    // return specific formula to get common field
    // we use FIELD_BY_NAME to get field by name
    // but in order to also filter out fields by type we add json encoded data that will be used when displaying results
    FieldFormulaSideSheetComponent.prototype.commonFieldFormula = function (field) {
        var fieldData = {
            name: field.name,
            datatype: field.datatype,
            tag: field.tag,
            typerestrict: field.typerestrict,
            typeGeoRestrict: field.typeGeoRestrict
        };
        // tslint:disable-next-line:max-line-length
        return "LAST(ARRAY(\"COMMON_FIELD_FORMULA\"," + QuoteString(JSON.stringify(fieldData)) + ",COALESCE(FIELD_BY_NAME(" + QuoteString(field.name) + "),ARRAY(\"\"))))";
    };
    // Returns true if the given formula is selected
    FieldFormulaSideSheetComponent.prototype.isSelected = function (formulaStr) {
        return this.selected.indexOf(formulaStr) > -1;
    };
    // returns true if formulaStr is a formula for field fieldTip
    FieldFormulaSideSheetComponent.prototype.isBasedOnField = function (formulaStr, fieldTip) {
        var fieldFormula = this.fieldFormula(fieldTip);
        if (formulaStr.startsWith('CONTEXT')) {
            return getMostInnerContextFromContextFormula(formulaStr) === fieldFormula;
        }
        // code below is for backwards compatible
        var len = fieldFormula.length;
        var str = formulaStr.replace(/\)+$/, ')');
        return str.substr(str.length - len, len) === fieldFormula;
    };
    // Returns the number of selected formulas that are based on the given field tip
    FieldFormulaSideSheetComponent.prototype.baseSelectedCount = function (fieldTip) {
        var _this = this;
        return this.selected.filter(function (formulaStr) { return _this.isBasedOnField(formulaStr, fieldTip); }).length;
    };
    // get count of selected formulas that are based on any field of the object type
    FieldFormulaSideSheetComponent.prototype.selectedCountForObjType = function (objType) {
        var _this = this;
        return this.selected.filter(function (formulaStr) { return objType.field.some(function (field) { return _this.isBasedOnField(formulaStr, field.$tip); }); }).length;
    };
    // Toggle the selected state of a given formula
    FieldFormulaSideSheetComponent.prototype.toggleSelected = function (formulaStr) {
        var pos = this.selected.indexOf(formulaStr);
        if (pos > -1) {
            this.selected.splice(pos, 1); // Already there, remove
        }
        else if (this.maxSelect === 1) {
            this.selected = [formulaStr]; // Single-select
        }
        else if (!this.maxSelect || this.maxSelect > this.selected.length) {
            this.selected.push(formulaStr); // Multi-select and not yet at maximum
        }
    };
    FieldFormulaSideSheetComponent.prototype.openAdditionalFieldSheet = function (objType) {
        var _this = this;
        // get selected formulas for the additional fields for this object type
        var selectedSubset = this.selected.filter(function (formulaStr) { return objType.field.some(function (field) { return _this.isBasedOnField(formulaStr, field.$tip); }); });
        var sheetRef = this.sideSheetService.push(FieldFormulaSideSheetComponent);
        var instance = sheetRef.componentInstance;
        instance.selected = selectedSubset;
        instance.objectType = tslib_1.__assign({}, objType, { summary: '', description: '', title: '' });
        instance.allowCustomFormula = false;
        instance.dataTypeConstraint = this.dataTypeConstraint;
        instance.objectTypeConstraint = this.objectTypeConstraint;
        instance.maxSelect = this.maxSubSelect || this.maxSelect;
        instance.maxSubSelect = this.maxSubSelect;
        instance.metaDataKeysToExcluded = this.metaDataKeysToExcluded;
        instance.formatsFields = this.formatsFields;
        // dont show meta data for additional fields ( metadata on the super type will be the same )
        instance.showMetaData = false;
        instance.onDone = function (resultingFormulaStrs) {
            _this.isLastSheet = false;
            if (_this.maxSelect === 1) {
                _this.selected = [];
            }
            else {
                _this.selected = _this.selected.filter(function (formulaStr) { return selectedSubset.indexOf(formulaStr) === -1; });
            }
            resultingFormulaStrs.forEach(function (formulaStr) {
                if (!_this.maxSelect || _this.selected.length < _this.maxSelect) {
                    _this.selected.push(formulaStr);
                }
            });
        };
    };
    // Open the side sheet for the given related object field
    FieldFormulaSideSheetComponent.prototype.openRelatedFieldSheet = function (field, handleSuperType) {
        var _this = this;
        if (handleSuperType === void 0) { handleSuperType = false; }
        // get formula string for the field
        var selectedSubset = this.selected.filter(function (formulaStr) { return _this.isBasedOnField(formulaStr, field.$tip); });
        var startingFormulaStrs = selectedSubset.map(function (formulaStr) { return _this.removeFieldFormula(formulaStr, field.$tip); });
        var sheetRef = this.sideSheetService.push(FieldFormulaSideSheetComponent);
        var instance = sheetRef.componentInstance;
        instance.selected = startingFormulaStrs;
        // todo: get type somehow ? if there is no typerestrict (e g on super type fields)
        if (field.typerestrict) {
            instance.objectTypeTip = field.typerestrict[0];
        }
        instance.allowCustomFormula = false;
        instance.dataTypeConstraint = this.dataTypeConstraint;
        instance.objectTypeConstraint = this.objectTypeConstraint;
        instance.maxSelect = this.maxSubSelect || this.maxSelect;
        instance.maxSubSelect = this.maxSubSelect;
        instance.metaDataKeysToExcluded = this.metaDataKeysToExcluded;
        instance.formatsFields = this.formatsFields;
        instance.onDone = function (resultingFormulaStrs) {
            _this.isLastSheet = false;
            // if only single select allowed -  reset selected list
            if (_this.maxSelect === 1) {
                _this.selected = [];
            }
            else {
                // if multi select allowed - remove for the given object field from selected
                _this.selected = _this.selected.filter(function (formulaStr) { return selectedSubset.indexOf(formulaStr) === -1; });
            }
            // add formulas selected in side sheet to the list of selected
            resultingFormulaStrs.forEach(function (formulaStr) {
                if (!_this.maxSelect || _this.selected.length < _this.maxSelect) {
                    var typerestrict = get(field, 'typerestrict.0');
                    if (handleSuperType && field.name && typerestrict) {
                        // Wrap the common field in a check to check that the field's has datatype=object
                        // We need to because the common field value might not be an object type
                        var fieldFormulaStr = 'IF(EQUALS(FIELD("field/datatype",FIRST(FIELD_TIP('
                            + QuoteString(typerestrict) + ',' + QuoteString(field.name)
                            + '))),"object"),' + _this.commonFieldFormula(field) + ',ARRAY())';
                        _this.selected.push(_this.insertFieldFormula(formulaStr, fieldFormulaStr));
                    }
                    else if (handleSuperType) {
                        // No data type constraint or no field name, so we can't drill down in to the common field
                        _this.selected.push('ARRAY()');
                    }
                    else {
                        _this.selected.push(_this.insertFieldFormula(formulaStr, _this.fieldFormula(field.$tip)));
                    }
                }
            });
        };
    };
    FieldFormulaSideSheetComponent.prototype.openCustomFormulaSideSheet = function () {
        var _this = this;
        var componentInstance = this.sideSheetService.push(CustomFormulaSideSheetComponent).componentInstance;
        var formula = get(this, "selected[" + this.customFormulaIndex + "]", '');
        componentInstance.setProps(formula.substr('COALESCE('.length, formula.length - 10));
        componentInstance.done$.subscribe(function (customFormula) {
            _this.appendCustomFormula(customFormula);
        });
    };
    Object.defineProperty(FieldFormulaSideSheetComponent.prototype, "customFormulaIndex", {
        get: function () {
            return this.selected.findIndex(isCustomFormula);
        },
        enumerable: true,
        configurable: true
    });
    FieldFormulaSideSheetComponent.prototype.appendCustomFormula = function (customFormulaStr) {
        var customFormulaIndex = this.customFormulaIndex;
        // remove custom formula
        if (customFormulaIndex !== -1) {
            this.selected.splice(customFormulaIndex, 1);
        }
        // if custom formula is entered, append the custom formula entry
        if (customFormulaStr && customFormulaStr.length) {
            if (this.maxSelect === 1) {
                this.selected = [];
            }
            this.selected.push("COALESCE(" + customFormulaStr + ")");
            return;
        }
    };
    // Appends the given FIELD(<fieldTip>) to the inner most argument list
    FieldFormulaSideSheetComponent.prototype.insertFieldFormula = function (wrappingFormulaStr, fieldFormulaStr) {
        return insertMostInnerContextToContextFormula(wrappingFormulaStr, fieldFormulaStr);
    };
    // Removes the FIELD<fieldTip> from the innermost argument list
    FieldFormulaSideSheetComponent.prototype.removeFieldFormula = function (formulaStr, fieldTip) {
        if (formulaStr.startsWith('CONTEXT')) {
            return removeMostInnerContextFromContextFormula(formulaStr);
        }
        // code below is for backwards compatible
        var fieldFormula = this.fieldFormula(fieldTip);
        if (formulaStr === fieldFormula) {
            return TIP_FORMULA;
        }
        var depth = /\)+$/.exec(formulaStr).length;
        return formulaStr.substr(0, formulaStr.length - depth - fieldFormula.length - 1) + ')'.repeat(depth);
    };
    FieldFormulaSideSheetComponent.prototype.checkForFormula = function (metaData) {
        if (this.isSort && metaData.sortFormula) {
            return metaData.sortFormula;
        }
        return metaData.formula;
    };
    FieldFormulaSideSheetComponent.prototype.metaToggleType = function (meta) {
        if (meta.hasDrillDown) {
            return ToggleType.NUMBER;
        }
        if (this.maxSelect === 1) {
            return ToggleType.SINGLE_CHECK;
        }
        return ToggleType.MULTI_CHECK;
    };
    FieldFormulaSideSheetComponent.prototype.isBasedOnMeta = function (formula, meta) {
        var formulaLookFor = this.checkForFormula(meta);
        return formula.indexOf(formulaLookFor) > -1;
    };
    FieldFormulaSideSheetComponent.prototype.metaSelectedValue = function (meta) {
        var _this = this;
        if (!meta.hasDrillDown) {
            return this.isSelected(this.checkForFormula(meta));
        }
        return this.selected.filter(function (formula) { return _this.isBasedOnMeta(formula, meta); }).length;
    };
    FieldFormulaSideSheetComponent.prototype.metaToggle = function ($event, meta) {
        var _this = this;
        if (!meta.hasDrillDown) {
            return this.toggleSelected($event);
        }
        // get formula string for the field
        var selectedSubset = this.selected.filter(function (formulaStr) { return _this.isBasedOnMeta(formulaStr, meta); });
        var startingFormulaStrs = selectedSubset.map(function (formulaStr) { return removeMostInnerContextFromContextFormula(formulaStr); });
        var sheetRef = this.sideSheetService.push(FieldFormulaSideSheetComponent);
        var instance = sheetRef.componentInstance;
        instance.selected = startingFormulaStrs;
        if (meta.typerestrict) {
            instance.objectTypeTip = meta.typerestrict[0];
        }
        instance.allowCustomFormula = false;
        instance.dataTypeConstraint = this.dataTypeConstraint;
        instance.objectTypeConstraint = this.objectTypeConstraint;
        instance.maxSelect = this.maxSubSelect || this.maxSelect;
        instance.maxSubSelect = this.maxSubSelect;
        instance.metaDataKeysToExcluded = this.metaDataKeysToExcluded;
        instance.formatsFields = this.formatsFields;
        instance.onDone = function (resultingFormulaStrs) {
            _this.isLastSheet = false;
            // if only single select allowed -  reset selected list
            if (_this.maxSelect === 1) {
                _this.selected = [];
            }
            else {
                // if multi select allowed - remove for the given object field from selected
                _this.selected = _this.selected.filter(function (formulaStr) { return selectedSubset.indexOf(formulaStr) === -1; });
            }
            // add formulas selected in side sheet to the list of selected
            resultingFormulaStrs.forEach(function (formulaStr) {
                if (!_this.maxSelect || _this.selected.length < _this.maxSelect) {
                    _this.selected.push(insertMostInnerContextToContextFormula(formulaStr, _this.checkForFormula(meta)));
                }
            });
        };
    };
    return FieldFormulaSideSheetComponent;
}());
export { FieldFormulaSideSheetComponent };
