import { Formula } from '../data/models/types';
import { getMetaDataFormula, IMetaDataFormulas } from '../object/field-formula-side-sheet/meta-data-formulas';
import { getCommonFieldFormulaFieldName, isCommonFieldFormula } from './common-field-formula';
import { getPartsFromContextFormula } from './context-formula-wrapper';
import { isCustomFormula } from './custom-formula';
import { getRelativeDateFormula } from '../side-sheet/generic-side-sheets/relative-dates-side-sheet/relative-dates-side-sheet.component';
import { isCurrentUserFormula } from './current-user-formula';

export enum SPECIAL_CONTEXT_FORMULA_TYPE {
  CUSTOM_FORMULA = 'Custom formula',
  META_DATA_FORMULA = 'Meta data',
  RELATIVE_DATE_FORMULA = 'Relative date',
  CURRENT_USER_FORMULA = 'Current user',
  COMMON_FIELD_FORMULA = 'Common field'
}

export interface ISpecialContextFormulaMeta {
  type: SPECIAL_CONTEXT_FORMULA_TYPE;
  outerFormula: Formula;
  metaData?: IMetaDataFormulas;
  relativeDateFormula?: { label: string; value: string };
}

export function getSpecialContextFormulaLabel(formula: Formula): string | null {
  const contextFormulaMeta = getSpecialContextFormulaMeta(formula);
  if (contextFormulaMeta) {
    switch (contextFormulaMeta.type) {
      // exceptions: Custom formula (Leaf)
      case SPECIAL_CONTEXT_FORMULA_TYPE.CUSTOM_FORMULA:

      // exceptions: Current user formula (Leaf)
      case SPECIAL_CONTEXT_FORMULA_TYPE.CURRENT_USER_FORMULA:
        return contextFormulaMeta.type;

      // exceptions: Meta data formula (Leaf)
      case SPECIAL_CONTEXT_FORMULA_TYPE.META_DATA_FORMULA:
        return contextFormulaMeta.metaData.label;

      // exceptions: Relative dates (Leaf)
      case SPECIAL_CONTEXT_FORMULA_TYPE.RELATIVE_DATE_FORMULA:
        return contextFormulaMeta.relativeDateFormula.label;

      // exceptions: Common field formula (Leaf - kind of)
      case SPECIAL_CONTEXT_FORMULA_TYPE.COMMON_FIELD_FORMULA:
        return getCommonFieldFormulaFieldName(contextFormulaMeta.outerFormula);
    }
  }
  return null;
}

export function getSpecialContextFormulaMeta(formula: Formula): ISpecialContextFormulaMeta | null {
  const outerParts = getPartsFromContextFormula(formula);

  // Custom formula
  if (isCustomFormula(outerParts.formula)) {
    return {
      type: SPECIAL_CONTEXT_FORMULA_TYPE.CUSTOM_FORMULA,
      outerFormula: outerParts.formula
    };
  }

  // Meta data formula
  const metaData = getMetaDataFormula(outerParts.formula);
  if (metaData) {
    return {
      type: SPECIAL_CONTEXT_FORMULA_TYPE.META_DATA_FORMULA,
      outerFormula: outerParts.formula,
      metaData
    };
  }

  // Relative dates
  const relativeDateFormula = getRelativeDateFormula(outerParts.formula);
  if (relativeDateFormula) {
    return {
      type: SPECIAL_CONTEXT_FORMULA_TYPE.RELATIVE_DATE_FORMULA,
      outerFormula: outerParts.formula,
      relativeDateFormula
    };
  }

  // Current user formula
  if (isCurrentUserFormula(outerParts.formula)) {
    return {
      type: SPECIAL_CONTEXT_FORMULA_TYPE.CURRENT_USER_FORMULA,
      outerFormula: outerParts.formula,
    };
  }

  // Common field formula
  if (isCommonFieldFormula(outerParts.formula)) {
    return {
      type: SPECIAL_CONTEXT_FORMULA_TYPE.COMMON_FIELD_FORMULA,
      outerFormula: outerParts.formula
    };
  }

  return null;
}
