import * as tslib_1 from "tslib";
import { NodeConverterBase } from './NodeConverterBase';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import { stringInterpolateFormula } from '../utils/converter-utils';
import { createSetVarNode } from './utils/create-node-utils';
import { get } from 'lodash';
import { Stringify } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import * as i0 from "@angular/core";
import * as i1 from "../../../var/filter-input/filter-input.service";
var SetVariableNodeConverterService = /** @class */ (function (_super) {
    tslib_1.__extends(SetVariableNodeConverterService, _super);
    function SetVariableNodeConverterService(filterInputService) {
        var _this = _super.call(this) || this;
        _this.filterInputService = filterInputService;
        _this.type = 'processnode/reference/setvar';
        _this.processNodeType = 'processnodetype/reference/setvar';
        return _this;
    }
    SetVariableNodeConverterService.prototype.convert = function (nodeData) {
        var e_1, _a;
        if (!this.isValid(nodeData)) {
            throw new InvalidWorkflowNodeDataError("Please correct invalid data on set variable node [" + nodeData.fields.title + "]", nodeData);
        }
        var vars = {};
        if (nodeData.fields.valueFormula.queryAndFilterOption) {
            var queryFormulaSpec = {
                name: 'QUERY',
                args: [
                    nodeData.fields.valueFormula.queryAndFilterOption.queryTip,
                    'branch/master',
                    0,
                    1000
                ]
            };
            var formula = Stringify(queryFormulaSpec);
            if (nodeData.fields.valueFormula.queryAndFilterOption.filterInputs &&
                nodeData.fields.valueFormula.queryAndFilterOption.filterInputs.length > 0) { // handles a var node which is using a filter  with filter options
                var filterVars = this.filterInputService.convertWorkflowFilterInputsToVars(nodeData.fields.valueFormula.queryAndFilterOption.filterInputs);
                if (filterVars != null) {
                    try {
                        for (var _b = tslib_1.__values(Object.keys(filterVars)), _c = _b.next(); !_c.done; _c = _b.next()) {
                            var key = _c.value;
                            var value = get(filterVars, [key, '0'], null);
                            if (value) {
                                vars[key] = value;
                            }
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                }
                vars[nodeData.fields.variableToSetKey] = formula;
                return createSetVarNode(vars, nodeData.tip);
            }
            else { // handles a var node which is using a filter with no filter options
                vars[nodeData.fields.variableToSetKey] = formula;
                return createSetVarNode(vars, nodeData.tip);
            }
        }
        else { // handles var node for all other cases.
            vars[nodeData.fields.variableToSetKey] = stringInterpolateFormula(nodeData.fields.valueFormula);
            return createSetVarNode(vars, nodeData.tip);
        }
    };
    SetVariableNodeConverterService.prototype.isValid = function (nodeData) {
        var formula = nodeData.fields.valueFormula ? nodeData.fields.valueFormula.formula : null;
        var key = nodeData.fields.variableToSetKey;
        var formulaIsValid = !(formula === null || formula === undefined || formula === '');
        var keyIsValid = !(key === null || key === undefined || key === '');
        var queryFilterValid = nodeData.fields.valueFormula && nodeData.fields.valueFormula.queryAndFilterOption ?
            (nodeData.fields.valueFormula.queryAndFilterOption.queryTip != null) : false;
        return (formulaIsValid || queryFilterValid) && keyIsValid;
    };
    SetVariableNodeConverterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SetVariableNodeConverterService_Factory() { return new SetVariableNodeConverterService(i0.ɵɵinject(i1.FilterInputService)); }, token: SetVariableNodeConverterService, providedIn: "root" });
    return SetVariableNodeConverterService;
}(NodeConverterBase));
export { SetVariableNodeConverterService };
