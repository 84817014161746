import { Injectable, Renderer2 } from '@angular/core';
import { cloneDeep, forEach} from 'lodash';

import { IEmailOutboundSource, IEmailOutboundTemplate, IEmailSubstitution } from '../../models/email';
import { Formula, HTML, Tip } from '../../data/models/types';
import { ISMSOutboundSource, ISMSOutboundTemplate, ISMSSubstitution } from '../../models/sms';
import { CONTEXT_VAR_NAME } from '../../message/message-utils.service';
import { getContextFromContextFormula } from '../../util/context-formula-wrapper';
import { SubstitutionMetaService } from '../../data/string-interpolation/substitution-meta.service';
import { decodeHtmlEntities, replaceHtmlTags, stripHtml } from '../../util/html-utils';

const INTERPOLATION_KEY_REGEX = /\{\{([G|M|P|R|S])\d+\}\}/;

export enum INTERPOLATION_TYPE {
  general = 'G',
  message = 'M',
  recipient = 'P',
  responseLink = 'R',
  subject = 'S'
}

// INPUT used for rich-text-editor
// SPAN used for string-interpolate-text-field
export enum INTERPOLATION_TAG_TYPE {
  INPUT = 'input',
  SPAN = 'span'
}

export enum SUBSTITUTION_PROPERTY {
  MESSAGE = 'messageSubstitutions',
  SUBJECT = 'subjectSubstitutions'
}

export enum CONTENT_TYPE {
  INNER_HTML = 'innerHTML',
  TEXT_CONTENT = 'textContent'
}

/**
 * @example
 * {
 *   type: 'M',
 *   label: 'Current date',
 *   value: 'TO_DATE(NOW())',
 * }
 */
export interface ISubstitutionCandidate {
  type: INTERPOLATION_TYPE;
  label: string;
  value: Formula;
}

/**
 * @example
 * {
 *   key: '{{M0}}',
 *   type: 'M',
 *   label: 'Current date',
 *   value: 'TO_DATE(NOW())',
 * }
 */
export interface ISubstitution extends ISubstitutionCandidate {
  key: string;
}

export interface ISubstitutionMeta {
  html: HTML | string; // if string, it expects textContent only
  substitutions: ISubstitution[];
}

@Injectable({
  providedIn: 'root'
})
export class StringInterpolationService {
  constructor() {}

  private static getSubstitutionKeyFromType(substitutionType: INTERPOLATION_TYPE, index: number): string {
    return `{{${substitutionType}${index}}}`;
  }

  private static getSubstitutionTypeFromKey(substitutionKey: string): INTERPOLATION_TYPE {
    return substitutionKey.match(INTERPOLATION_KEY_REGEX)[1] as INTERPOLATION_TYPE;
  }

  private static makeInterpolationElement(
    substitution: ISubstitution,
    renderer: Renderer2,
    interpolationTagType: INTERPOLATION_TAG_TYPE = INTERPOLATION_TAG_TYPE.INPUT
  ): HTML {
    const substitutionCandidate: ISubstitutionCandidate = {
      type: StringInterpolationService.getSubstitutionTypeFromKey(substitution.key),
      label: substitution.label,
      value: substitution.value,
    };
    const wrapperHtml: HTMLElement = renderer.createElement('div') as HTMLElement;
    const tag: HTMLElement = interpolationTagType === INTERPOLATION_TAG_TYPE.INPUT
      ? StringInterpolationService.createInterpolationInputElement(substitutionCandidate, renderer)
      : StringInterpolationService.createInterpolationSpanElement(substitutionCandidate, renderer);

    renderer.appendChild(wrapperHtml, tag);

    return wrapperHtml.innerHTML;
  }

  private static getSubstitutionFromEmailSMSSubstitution(
    emailSubstitution: IEmailSubstitution | ISMSSubstitution,
    interpolationType: INTERPOLATION_TYPE = INTERPOLATION_TYPE.message
  ): ISubstitution {
    const substitution: ISubstitution = cloneDeep(emailSubstitution) as ISubstitution;

    substitution.type = interpolationType;

    return substitution;
  }

  static createInterpolationInputElement(substitutionCandidate: ISubstitutionCandidate, renderer: Renderer2) {
    const input: HTMLElement = renderer.createElement('input') as HTMLElement;

    renderer.addClass(input, 'eim-forms_form-control-string-interpolation_interpolation-blot');
    renderer.setAttribute(input, 'disabled', 'true');
    renderer.setAttribute(input, 'readonly', 'readonly');
    renderer.setAttribute(input, 'value', substitutionCandidate.label);
    renderer.setAttribute(input, 'size', substitutionCandidate.label.length.toString());
    renderer.setAttribute(input, 'data-substitution', JSON.stringify(substitutionCandidate));

    return input;
  }

  static createInterpolationSpanElement(substitutionCandidate: ISubstitutionCandidate, renderer: Renderer2) {
    const span: HTMLElement = renderer.createElement('span') as HTMLElement;
    renderer.addClass(span, 'eim-forms_form-control-string-interpolation_interpolation-blot');
    renderer.setAttribute(span, 'data-substitution', JSON.stringify(substitutionCandidate));

    const spanInner: HTMLElement = renderer.createElement('span') as HTMLElement;
    renderer.setAttribute(span, 'contenteditable', 'false');

    const spanInnerText = renderer.createText(substitutionCandidate.label);

    renderer.appendChild(spanInner, spanInnerText);
    renderer.appendChild(span, spanInner);

    return span;
  }

  static getSubstitutionMetaFromEmailOutboundSource(
    emailOutboundSource: IEmailOutboundSource | IEmailOutboundTemplate,
    substitutionProperty: SUBSTITUTION_PROPERTY = SUBSTITUTION_PROPERTY.MESSAGE
  ): ISubstitutionMeta {
    let html;
    let interpolationType;

    switch (substitutionProperty) {
      case SUBSTITUTION_PROPERTY.MESSAGE:
        html = emailOutboundSource.body;
        interpolationType = INTERPOLATION_TYPE.message;
        break;
      case SUBSTITUTION_PROPERTY.SUBJECT:
        html = emailOutboundSource.subject;
        interpolationType = INTERPOLATION_TYPE.subject;
        break;
    }

    const substitutions: IEmailSubstitution[] = emailOutboundSource[substitutionProperty] || [];

    return {
      html,
      substitutions: substitutions.map(substitution => this.getSubstitutionFromEmailSMSSubstitution(substitution, interpolationType))
    };
  }

  static getSubstitutionMetaFromSmsOutboundSource(
    smsOutboundSource: ISMSOutboundSource | ISMSOutboundTemplate
  ): ISubstitutionMeta {
    const html = smsOutboundSource.body;
    const interpolationType = INTERPOLATION_TYPE.message;

    const substitutions: ISMSSubstitution[] = smsOutboundSource[SUBSTITUTION_PROPERTY.MESSAGE] || [];

    return {
      html,
      substitutions: substitutions.map(substitution => this.getSubstitutionFromEmailSMSSubstitution(substitution, interpolationType))
    };
  }

  static extractEmailSMSSubstitutions<T extends { key: string; label: string; value: Formula; }>(
    substitutions: ISubstitution[],
    enoType: Tip = 'app/email/substitution'
  ): T[] {
    return (substitutions || []).map(
      (substitution: ISubstitution) => {
        (substitution as any).$type = enoType;

        return substitution as unknown as T;
      }
    );
  }

  /**
   * Converts the HTML to be presented (in Quill for example) to the HTML to be saved in ENOS (e.g. "app/email/outbound-source:body").
   */
  static getSubstitutionMetaFromViewHtml(
    viewHtml: HTML,
    renderer: Renderer2,
    contentType: CONTENT_TYPE = CONTENT_TYPE.INNER_HTML,
    preserveLineBreaks = false
  ): ISubstitutionMeta {
    const wrapperHtml: HTMLElement = renderer.createElement('div') as HTMLElement;
    wrapperHtml.innerHTML = viewHtml;

    const interpolationSpans: NodeListOf<HTMLElement> = wrapperHtml.querySelectorAll('[data-substitution]');
    if (interpolationSpans.length === 0) {
      const html = contentType === CONTENT_TYPE.INNER_HTML ? viewHtml :
        preserveLineBreaks ? this.removeHtmlAndCreateLineBreaks(viewHtml) : wrapperHtml.textContent;
      return {
        html,
        substitutions: []
      };
    }

    const substitutions: ISubstitution[] = [];

    forEach(interpolationSpans, (interpolationSpan: HTMLElement, index: number) => {
      const substitutionCandidate: ISubstitutionCandidate = JSON.parse(interpolationSpan.getAttribute('data-substitution'));
      const substitutionKey = StringInterpolationService.getSubstitutionKeyFromType(substitutionCandidate.type, index);

      interpolationSpan.insertAdjacentHTML('afterend', substitutionKey);
      interpolationSpan.parentNode.removeChild(interpolationSpan);

      substitutions.push({
        key: substitutionKey,
        type: substitutionCandidate.type,
        label: substitutionCandidate.label,
        value: substitutionCandidate.value
      });
    });

    if (contentType === CONTENT_TYPE.TEXT_CONTENT) {

      let textContent = this.removeHtmlAndCreateLineBreaks(wrapperHtml.innerHTML);
      if (textContent && !preserveLineBreaks && (textContent.includes('\n') || textContent.includes('\r'))) { // check if new lines have to be removed
        // remove escaped newlines
        textContent = textContent.replace(/[\r\n]/g, '');
      }
      return {
        html: textContent,
        substitutions
      };
    }
    const metaDiv = renderer.createElement('div') as HTMLElement;

    renderer.setAttribute(metaDiv, 'data-substitutions', JSON.stringify(substitutions).replace(/\{\{/g, '{-{'));
    renderer.appendChild(wrapperHtml, metaDiv);

    return {
      html: wrapperHtml.innerHTML,
      substitutions
    };
  }

  static removeSubstitutionByContextFormula(
    viewHtml: HTML,
    renderer: Renderer2,
    contextFormula: Formula = `VAR("${ CONTEXT_VAR_NAME }")`
  ): { changed: boolean, newHtml: HTML} {
    const wrapperHtml: HTMLElement = renderer.createElement('div') as HTMLElement;
    wrapperHtml.innerHTML = viewHtml;

    const interpolationSpans: NodeListOf<HTMLElement> = wrapperHtml.querySelectorAll(`[data-substitution]`);
    let changed = false;
    interpolationSpans.forEach(interpolationSpan => {
      const substitution: ISubstitution = JSON.parse(interpolationSpan.dataset.substitution);
      const contextOfSubstitution = getContextFromContextFormula(substitution.value);
      if (contextOfSubstitution && contextOfSubstitution === contextFormula) {
        interpolationSpan.parentNode.removeChild(interpolationSpan);
        changed = true;
      }
    });
    return { changed, newHtml: wrapperHtml.innerHTML };
  }

  /**
   * Converts the HTML to be saved in ENOS (e.g. "app/email/outbound-source:body") to the HTML to be presented (in Quill for example).
   */
  static getViewHtmlFromDataHtml(dataHtml: HTML, renderer: Renderer2): HTML {
    const wrapperHtml: HTMLElement = renderer.createElement('div') as HTMLElement;

    wrapperHtml.innerHTML = dataHtml;

    const metaDiv: HTMLElement = wrapperHtml.querySelector('[data-substitutions]');

    if (!metaDiv) {
      return dataHtml;
    }

    wrapperHtml.removeChild(metaDiv);

    dataHtml = wrapperHtml.innerHTML;

    const substitutions: ISubstitution[] = JSON.parse(metaDiv.getAttribute('data-substitutions').replace(/\{\-\{/g, '{{'));

    substitutions.forEach((substitution: ISubstitution) => {
      const interpolationHtml: HTML = StringInterpolationService.makeInterpolationElement(
        substitution,
        renderer,
        INTERPOLATION_TAG_TYPE.INPUT
      );

      if (interpolationHtml) {
        dataHtml = dataHtml.replace(substitution.key, interpolationHtml);
      }
    });

    return dataHtml;
  }

  /**
   * Converts the subMeta to be saved in ENOS
   * (e.g. subMeta: { html: "app/email/outbound-source:subject", substitutions: "app/email/outbound-source:subject-substitutions"})
   * to the HTML to be presented (in Quill for example).
   */
  static getViewTextFromDataHtml(subMeta: ISubstitutionMeta, renderer: Renderer2): HTML {
    let dataHtml = subMeta.html;
    const substitutions = subMeta.substitutions;

    if (!dataHtml) {
      return '';
    }
    if (!substitutions.length) {
      return dataHtml;
    }

    substitutions.forEach((substitution: ISubstitution) => {
      const interpolationHtml: HTML = StringInterpolationService.makeInterpolationElement(
        substitution,
        renderer,
        INTERPOLATION_TAG_TYPE.SPAN
      );

      if (interpolationHtml) {
        dataHtml = dataHtml.replace(substitution.key, interpolationHtml);
      }
    });

    return dataHtml;
  }

  static getStringifiedFormulaFromSubstitutionMeta(subMeta: ISubstitutionMeta): Formula {
    const { substitutions } = subMeta;
    let { html } = subMeta;

    if (!html) {
      return '';
    }

    if (!substitutions.length) {
      return html;
    }

    substitutions
      .forEach(substitution => html = html.replace(substitution.key, `{=${substitution.value}}`));

    return html;
  }

  static getSubstitutionMetaFromStringifiedFormula(formula: Formula): ISubstitutionMeta {
    const subMeta: ISubstitutionMeta = {
      html: formula,
      substitutions: []
    };

    let count = 0;
    formula.replace(/{=([A-Z|_]+\([^=]*\))}/g, (match, sub, string) => {
      subMeta.substitutions.push({
        key: `{{${INTERPOLATION_TYPE.general}${count}}}`,
        type: INTERPOLATION_TYPE.general,
        label: SubstitutionMetaService.getLabelFromSystemFormula(sub) || `Label ${count}`,
        value: sub
      });

      subMeta.html = subMeta.html.replace(match, subMeta.substitutions[count].key);

      count++;

      return subMeta.substitutions[count - 1].key;
    });

    return subMeta;
  }

  /**
   * @param input
   * this method will find all 'divs' and convert them into new line characters
   */
  static removeHtmlAndCreateLineBreaks(input: HTML): string {
    return stripHtml(replaceHtmlTags(decodeHtmlEntities(input)));
  }

}
