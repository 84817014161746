import { Component, OnInit } from '@angular/core';
import { ILayerControlLegend, LayerControlLegendService } from '../layer-control-legend.service';
import { ToggleType } from '../../../../side-sheet/side-sheet-list-item/side-sheet-list-item.component';

@Component({
  selector: 'app-layer-control-legend-side-sheet',
  templateUrl: './layer-control-legend-side-sheet.component.html',
  styleUrls: ['./layer-control-legend-side-sheet.component.scss']
})
export class LayerControlLegendSideSheetComponent implements OnInit {
  // required
  value: ILayerControlLegend;
  serviceId: string;

  // internal
  objectKeys = Object.keys;
  toggleTypes = ToggleType;
  allChecked = true;

  constructor(
    private layerControlLegendService: LayerControlLegendService
  ) { }

  ngOnInit(): void {
    Object.keys(this.value).some(key => {
      if (!this.value[key].visible) {
        this.allChecked = false;
        return true;
      }
    });
  }

  toggleAllVisibility() {
    this.allChecked = !this.allChecked;
    Object.keys(this.value).forEach(key => this.value[key].visible = this.allChecked);
    this.layerControlLegendService.visibility$.next([this.value, this.serviceId]);
  }

  toggleLayerVisibility(key: string) {
    this.value[key].visible = !this.value[key].visible;

    let newAllChecked = true;

    Object.keys(this.value).some(i => {
      if (!this.value[i].visible) {
        newAllChecked = false;
        return true;
      }
    });

    this.allChecked = newAllChecked;

    this.layerControlLegendService.visibility$.next([this.value, this.serviceId]);
  }

}
