import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { LoadingState } from '../../shared/constants';
import { SideSheetListModes } from '../../side-sheet/side-sheet-list/side-sheet-list.component';
var SelectProfilesSideSheetComponent = /** @class */ (function () {
    function SelectProfilesSideSheetComponent(profileService) {
        this.profileService = profileService;
        this.mode = SideSheetListModes.SINGLE_SELECT;
        this.initiallySelectedTips = [];
        this.selectedProfiles = [];
        this.doNotDisplayInitiallySelectedProfiles = false;
        this.subscriptions = new Subscription();
        this.loadingStates = LoadingState;
        this.allSelected = false;
        this.showAll = true;
    }
    SelectProfilesSideSheetComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.selectedProfiles = tslib_1.__spread(this.initiallySelectedTips);
        this.allSelected = this.selectedProfiles.indexOf('__ALL__') > -1;
        this.profiles$ = this.profileService.getProfiles().pipe(map(function (profiles) {
            var _profiles = tslib_1.__spread(profiles);
            _profiles = (_this.doNotDisplayInitiallySelectedProfiles)
                ? _profiles.filter(function (profile) { return _this.initiallySelectedTips.indexOf(profile.$tip) === -1; })
                : _profiles;
            if (_this.mode === SideSheetListModes.MULTI_SELECT && _this.showAll) {
                _profiles.unshift({ $tip: '__ALL__', name: 'All profiles', description: 'All profiles' });
            }
            _this.profiles = _profiles;
            return _this.profiles;
        }), shareReplay());
    };
    SelectProfilesSideSheetComponent.prototype.onDone = function (selectedProfileTips) {
        switch (this.mode) {
            case SideSheetListModes.SINGLE_SELECT:
                if (selectedProfileTips && selectedProfileTips.length) {
                    this.done(this.profiles.find(function (x) { return x.$tip === selectedProfileTips[0]; }));
                }
                break;
            case SideSheetListModes.MULTI_SELECT:
                this.done(this.profiles.filter(function (profile) {
                    return selectedProfileTips.indexOf(profile.$tip) > -1;
                }));
                break;
        }
    };
    SelectProfilesSideSheetComponent.prototype.toggleProfile = function (profileTip) {
        if (profileTip === '__ALL__') {
            this.allSelected = (this.selectedProfiles.indexOf(profileTip) === -1);
        }
    };
    return SelectProfilesSideSheetComponent;
}());
export { SelectProfilesSideSheetComponent };
