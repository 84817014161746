import { FieldType } from '@ngx-formly/core';
import { Component } from '@angular/core';

@Component({
  selector: 'app-option-list',
  template: `
    <app-single-option-list-field
        [formControl]="formControl"
        [label]="field.templateOptions.label"
        [searchBox]="field.templateOptions.searchBox"
        [placeholder]="field.templateOptions.placeholder"
        [required]="field.templateOptions.required"
        [options]="field.templateOptions.options"
        [readonly]="field.templateOptions.readonly"
        >
    </app-single-option-list-field>
  `
})
export class FormlyFieldOptionListComponent extends FieldType {

}
