import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { get } from 'lodash';
var NavigationSideSheetComponent = /** @class */ (function () {
    function NavigationSideSheetComponent() {
        this.list = [];
        this.title = 'Please select from the list';
        this.selectItem = new Subject();
    }
    NavigationSideSheetComponent.prototype.setProps = function (_a) {
        var list = _a.list, title = _a.title;
        this.list = list;
        this.title = title;
        this.formula = get(list, '[0].formula', null);
        this.by = get(list, '[0].by', null);
        this.showBadge = get(list, '[0].showBadge', false);
    };
    NavigationSideSheetComponent.prototype.ngOnDestroy = function () {
        this.selectItem.complete();
    };
    return NavigationSideSheetComponent;
}());
export { NavigationSideSheetComponent };
