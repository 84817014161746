import { Injectable, OnDestroy } from '@angular/core';
import { QueryService } from '../../data/query.service';
import { combineLatest, Observable, ReplaySubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

export type AllFeatureFlagsMap = Map<string, boolean>;

export interface IGetFeatureFlags {
  name: string;
  defaultEnabled?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService implements OnDestroy {
  subscription: Subscription;
  private allFeatureFlags = new ReplaySubject<AllFeatureFlagsMap>(1);
  allFeatureFlags$: Observable<AllFeatureFlagsMap> = this.allFeatureFlags.asObservable();

  constructor(private queryService: QueryService) {}

  // Fetch a feature flag by name, returning a default if not available
  getFeatureFlag$(name: string, defaultEnabled: boolean = false): Observable<boolean> {
    return this.allFeatureFlags$
      .pipe(
        map(allFeatureFlagsMap => {
          if (allFeatureFlagsMap.has(name)) {
            return allFeatureFlagsMap.get(name);
          }
          return defaultEnabled;
        })
      );
  }

  getFeatureFlags$(getFeatureFlags: IGetFeatureFlags[]): Observable<boolean[]> {
    const featureFlags$ = getFeatureFlags
      .map(({name, defaultEnabled}) => this.getFeatureFlag$(name, defaultEnabled));
    return combineLatest(featureFlags$);
  }

  loadAllFeatureFlags() {
    this.subscription = this.queryService.execute1dFirst('eim/query/get-all-feature-flags-query').pipe(
      map(
        (featureFlags: {name: string, enabled: string}[]) => featureFlags.reduce(
          (allFeatureFlagsMap, featureFlag) => {
            return allFeatureFlagsMap.set(featureFlag.name, featureFlag.enabled === 'true');
          },
          new Map()
        )
      )
    ).subscribe(allFeatureFlagsMap => {
      this.allFeatureFlags.next(allFeatureFlagsMap);
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
