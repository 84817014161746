import { Directive, Renderer2, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

const SPINNER_TEMPLATE = `
<svg width="40px" height="40px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
  <circle fill="none" stroke-width="4" stroke-linecap="round" cx="33" cy="33" r="30" class="circle"></circle>
</svg>`;

@Directive({
  selector: '[appSpinner]'
})
export class SpinnerDirective implements OnInit, OnDestroy {
  constructor(private renderer: Renderer2, private el: ElementRef) {}

  @Input('appSpinner') set showSpinner(value: boolean) {
    this.setSpinner(value);
  }

  @Input('appSpinnerMask') mask = true;
  @Input('showSpinner$') showSpinner$: Observable<boolean>;

  subscription: Subscription;

  ngOnInit() {
    const div = this.renderer.createElement('div');
    this.renderer.addClass(div, 'be-spinner');
    this.renderer.setProperty(div, 'innerHTML', SPINNER_TEMPLATE);
    this.renderer.insertBefore(this.el.nativeElement, div, this.el.nativeElement.firstChild);
    this.renderer.addClass(this.el.nativeElement, 'be-loading');

    if (!this.mask) {
      this.renderer.addClass(this.el.nativeElement, 'be-loading-nomask');
    }

    if (this.showSpinner$) {
      this.subscription = this.showSpinner$.subscribe((v) => this.setSpinner(v));
    }
  }

  private setSpinner(value) {
    if (value) {
      this.renderer.addClass(this.el.nativeElement, 'be-loading-active');
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'be-loading-active');
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
