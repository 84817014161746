import { Component, HostBinding } from '@angular/core';
import { ConsoleService } from './util/console.service';
import { SentryService } from './util/sentry.service';
import { GtmService } from './util/gtm.service';
import { environment } from '../environments/environment';
import { InactivityLogoutService } from './auth/inactivity-logout/inactivity-logout.service';
import { SessionExpiryAlertService } from './auth/session-expiry-alert/session-expiry-alert.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  // Will disable animations app-wide if set to true
  // See: https://angular.io/api/animations/trigger#disable-animations-application-wide
  @HostBinding('@.disabled')
  animationsDisabled = !environment.enableAnimations;

  constructor(
    private consoleService: ConsoleService,
    private sentryService: SentryService,
    private gtmService: GtmService,
    private inactivityLogoutService: InactivityLogoutService,
    private sessionExpiryAlertService: SessionExpiryAlertService
  ) {
    // comment out until we need to turn this on
    this.inactivityLogoutService.init();
    this.sessionExpiryAlertService.init();
    window['EN'] = this.consoleService;
    this.sentryService.init();
    this.gtmService.init();
  }
}
