import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { saveAs } from 'file-saver';
import { BehaviorSubject, combineLatest, of, Subject, throwError } from 'rxjs';
import { filter, switchMap, takeUntil, takeWhile, first } from 'rxjs/operators';
import { TRACKING_STATUS } from '../print';
import { SideSheetBase } from '../../side-sheet/side-sheet-base';
import * as moment from 'moment';
var PrintPdfExportSideSheetComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PrintPdfExportSideSheetComponent, _super);
    function PrintPdfExportSideSheetComponent(printService, objectService, sideSheetService, toastService) {
        var _this = _super.call(this) || this;
        _this.printService = printService;
        _this.objectService = objectService;
        _this.sideSheetService = sideSheetService;
        _this.toastService = toastService;
        _this.loading$ = new BehaviorSubject(true);
        _this.unsubscribe$ = new Subject();
        return _this;
    }
    PrintPdfExportSideSheetComponent.prototype.setProps = function (props) {
        this.props = props;
        this.triggerDownload();
    };
    PrintPdfExportSideSheetComponent.prototype.triggerDownload = function () {
        var _this = this;
        combineLatest([
            this.generatePdf(),
            this.objectService.getTitles([this.props.contextTip])
        ])
            .pipe(first())
            .subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 2), data = _b[0], _c = tslib_1.__read(_b[1], 1), title = _c[0].title;
            _this.loading$.next(false);
            _this.contextTitle = title;
            _this.saveAs(data);
        }, function () {
            _this.toastService.showErrorToast('Unable to download. Please try again later.');
            _this.sideSheetService.pop();
        });
    };
    PrintPdfExportSideSheetComponent.prototype.generatePdf = function () {
        var _this = this;
        var trackingTip = this.props.trackingTip;
        return this
            .printService
            .generatePdf(trackingTip)
            .pipe(switchMap(function () { return _this.printService.getTrackingObject(trackingTip); }), takeWhile(function (tracking) { return tracking.generatePdfStatus !== TRACKING_STATUS.COMPLETED; }, true), switchMap(function (tracking) {
            if (tracking.generateHtmlStatus === TRACKING_STATUS.FAILED) {
                return throwError('error processing pdf');
            }
            return of(tracking);
        }), filter(function (tracking) { return tracking.generatePdfStatus === TRACKING_STATUS.COMPLETED; }), switchMap(function () { return _this.printService.download(trackingTip, 'pdf'); }));
    };
    PrintPdfExportSideSheetComponent.prototype.download = function () {
        this
            .printService
            .download(this.props.trackingTip, 'pdf')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(this.saveAs.bind(this));
    };
    PrintPdfExportSideSheetComponent.prototype.saveAs = function (data) {
        saveAs(data, this.contextTitle + "-" + moment().format('DD-MMM-YYYY') + ".pdf");
    };
    PrintPdfExportSideSheetComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    };
    return PrintPdfExportSideSheetComponent;
}(SideSheetBase));
export { PrintPdfExportSideSheetComponent };
