import { NgModule } from '@angular/core';
import { AssetPipe } from './asset.pipe';

@NgModule({
  declarations: [
    AssetPipe
  ],
  exports: [
    AssetPipe
  ]
})
export class AssetPipeModule { }
