import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
// This service is for convenient way to mock when writing test as it is hard to mock window object in typescript
export class LocationService {
  constructor() {}

  public redirect(url: string) {
    window.location.href = url;
  }

  public refresh() {
    window.location.reload();
  }

  public refreshToRoot() {
    window.location.href = window.location.href.replace(window.location.pathname, '');
  }
}
