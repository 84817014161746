import { environment } from '../../environments/environment';
var SERVER = "centos@54.66.173.51";
var FOLDER = "project/elastic-noggin-server";
var LOGS = "docker-compose logs -f --tail 100 ensrv";
function loginAndTailLogs(grepsParams, linesToReturn) {
    var GREPS = grepsParams.reduce(function (acc, grepParam) { return acc + " | grep -A " + linesToReturn + " " + grepParam + " --line-buffered"; }, '');
    return ssh(GREPS);
}
function ssh(GREPS) {
    return "\n  Copy this:\n  ----\n     \n    ssh " + SERVER + " -t \"cd " + FOLDER + "; " + LOGS + " " + GREPS + "\"\n    \n  ----\n  ";
}
var TailLogs = /** @class */ (function () {
    function TailLogs() {
        this.linesToReturn = 20;
        this.sessionId = 'no-session-id!!';
    }
    Object.defineProperty(TailLogs.prototype, "truncatedSessionId", {
        get: function () {
            return this.sessionId.substring(0, 6);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TailLogs.prototype, "trucatedNamespace", {
        get: function () {
            return environment.ns.substr(0, 6);
        },
        enumerable: true,
        configurable: true
    });
    TailLogs.prototype.setSessionId = function (sessionId) {
        this.sessionId = sessionId;
    };
    Object.defineProperty(TailLogs.prototype, "help", {
        get: function () {
            return "\n      Tail logs provides utility snippets you can use to tail the docker compose logs on the showcase server.\n      \n      Try\n      - session\n      - process\n      \n      Don't see what you like? Add it!\n    ";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TailLogs.prototype, "namespace", {
        get: function () {
            return loginAndTailLogs([this.trucatedNamespace], this.linesToReturn);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TailLogs.prototype, "session", {
        get: function () {
            return loginAndTailLogs([this.truncatedSessionId], this.linesToReturn);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TailLogs.prototype, "process", {
        get: function () {
            return loginAndTailLogs(["'\\\"tag\\\":\\\"process\\\".*" + this.truncatedSessionId + "'"], this.linesToReturn);
        },
        enumerable: true,
        configurable: true
    });
    return TailLogs;
}());
export { TailLogs };
