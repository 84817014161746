import * as tslib_1 from "tslib";
import { CompositeNodeConverter } from './CompositeNodeConverter';
import { createConnection, createSetVarNode, createSubProcessNode, createSwitchNode } from './utils/create-node-utils';
import * as i0 from "@angular/core";
var ImportEnosConverterService = /** @class */ (function (_super) {
    tslib_1.__extends(ImportEnosConverterService, _super);
    function ImportEnosConverterService() {
        return _super.call(this) || this;
    }
    ImportEnosConverterService.prototype.convert = function (nodeData) {
        var _a = nodeData.fields, primaryKeyFieldTip = _a.primaryKeyFieldTip, typeTip = _a.typeTip, fileToImportTip = _a.fileToImportTip, matchingBehaviour = _a.matchingBehaviour, enoSecurityPolicy = _a.enoSecurityPolicy, importReportVariableToSetKey = _a.importReportVariableToSetKey, modifiedObjectsVariableToSetKey = _a.modifiedObjectsVariableToSetKey, warningThreshold = _a.warningThreshold;
        var vars = {
            'Type tip': typeTip,
            'Matching behaviour': matchingBehaviour,
            'Eno security policy': enoSecurityPolicy,
            'File to import tip': fileToImportTip.formula,
            'Primary key field tip': primaryKeyFieldTip,
            // 'Warning threshold': warningThreshold.toString(10),  // for now leave out
            'Import report variable to set key': importReportVariableToSetKey,
            'Modified objects variable to set key': modifiedObjectsVariableToSetKey
        };
        // create nodes
        var setVarNode = createSetVarNode(vars, nodeData.tip);
        var importEnosSubProcess = createSubProcessNode('eim/process/workflow/import-enos', vars);
        var switchNode = createSwitchNode({ name: 'VAR', args: ['Status'] });
        // connect setVar "done" to "importEnosSubProcess"
        var importEnosSubProcessDone = createConnection('done', switchNode);
        importEnosSubProcess.connections.push(importEnosSubProcessDone);
        // connect sub process "done" to "switchNode"
        var setVarDone = createConnection('done', importEnosSubProcess);
        setVarNode.connections.push(setVarDone);
        var nodesAndLink = {
            nodes: [
                setVarNode,
                importEnosSubProcess,
                switchNode
            ],
            links: [
                setVarDone,
                importEnosSubProcessDone
            ]
        };
        this.populateOutgoingLinks(nodeData).forEach(function (link) {
            switchNode.connections.push(link);
            nodesAndLink.links.push(link);
        });
        return nodesAndLink;
    };
    ImportEnosConverterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ImportEnosConverterService_Factory() { return new ImportEnosConverterService(); }, token: ImportEnosConverterService, providedIn: "root" });
    return ImportEnosConverterService;
}(CompositeNodeConverter));
export { ImportEnosConverterService };
