import {
  STRING_INTERPOLATE_TEXT_FORMLY_CONFIG,
  OBJECT_CHOOSER_FORMLY_CONFIG
} from '../../../../formly-noggin/ngx-formly-bootstrap/bootstrap.config';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { INTERPOLATION_TYPE } from '../../../../form/string-interpolation/string-interpolation.service';
import { VALUE_FORMAT } from '../../../../form/string-interpolate-text-field/string-interpolate-text-field.component';

export const sequenceFormFields: FormlyFieldConfig[] = [
  {
    key: 'name',
    type: 'input',
    defaultValue: '',
    templateOptions: {
      label: 'Name',
      type: 'text',
      required: true,
      placeholder: 'Enter a name'
    },
    validators: {
      validation: ['required']
    }
  },
  {
    key: 'objectType',
    type: OBJECT_CHOOSER_FORMLY_CONFIG.name,
    templateOptions: {
      label: 'Object Type',
      required: false,
      typeTips: ['app/object-type'],
      placeholder: 'Select an object type'
    }
  },
  {
    key: 'numOfDigits',
    type: 'input',
    defaultValue: 0,
    templateOptions: {
      label: 'Number of digits in the sequence',
      type: 'number',
      required: true
    },
    validators: {
      validation: ['required']
    }
  },
  {
    type: 'text-content',
    templateOptions: {
      html: `
        <p class="eim-font-color_form-element-label">
          If the current sequence value is 3 and you specifiy number of digits as 4, then the sequence number will be displayed as 0003.
        </p>
      `
    },
  },
  {
    key: 'label',
    type: STRING_INTERPOLATE_TEXT_FORMLY_CONFIG.name,
    templateOptions: {
      label: 'Sequence label',
      required: true,
      // tslint:disable-next-line: max-line-length
      description: 'If the current sequence value is 3 and you specify number of digits as 4, then the sequence number will be displayed as 0003',
      mode: 'multi-line',
      contextTypeTips: [],
      showSequence: true,
      placeholder: 'Enter a label',
      interpolationType: INTERPOLATION_TYPE.general,
      valueFormat: VALUE_FORMAT.SUBSTITUTIONS
    },
    validators: {
      validation: ['requiredFieldWithSubstitution']
    }
  },
  {
    type: 'text-content',
    templateOptions: {
      html: `
        <p class="eim-font-color_form-element-label">
          The sequence label is generated and shown in the app.
        </p>
      `
    },
  },
  {
    key: 'key',
    type: STRING_INTERPOLATE_TEXT_FORMLY_CONFIG.name,
    templateOptions: {
      label: 'Sequence reset key',
      mode: 'multi-line',
      contextTypeTips: [],
      placeholder: 'Enter a key',
      interpolationType: INTERPOLATION_TYPE.general,
      valueFormat: VALUE_FORMAT.SUBSTITUTIONS
    }
  },
  {
    type: 'text-content',
    templateOptions: {
      html: `
        <p class="eim-font-color_form-element-label">
          The reset key resets the sequence number to 0. For example, if the reset key was the Current month - Current year, the sequence 
          number would be reset to 0 when a month starts.
        </p>
      `
    },
  },
];
