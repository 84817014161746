import { DataTypes, IObjectScheme } from '../data/models/scheme';
import { IObject } from '../data/models/object';
import { IField, fieldScheme } from './field';

export interface ISupertype extends IObject {
  label: string;
  description: string;
  title: string;
  summary: string;
  field?: IField[];
}

export const supertypeScheme: IObjectScheme = {
  'super-type:label': { type: DataTypes.i18n },
  'super-type:description': { type: DataTypes.i18n },
  'super-type:title': { type: DataTypes.i18n },
  'super-type:summary': { type: DataTypes.i18n },
  'super-type:field': {
    type: DataTypes.objectArray,
    mutable: true,
    scheme: fieldScheme
  }
};
