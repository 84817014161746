import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-side-sheet-section-wrapper',
  template: `
      <app-side-sheet-section
          [title]="to?.title"
          [showBorder]="to?.showBorder || false"
          [collapsible]="to?.collapsible"
          [buttonCollapseWithTitle]="to?.buttonCollapseWithTitle"
          [tallyPill]="to?.tallyPill"
          [tallyPillValue]="to?.tallyPillValue"
          [showHeader]="to?.showHeader || false"
      >
          <ng-template #fieldComponent></ng-template>
      </app-side-sheet-section>

  `
})
export class FormlyWrapperSideSheetSectionComponent extends FieldWrapper {
  @ViewChild('fieldComponent', {static: false, read: ViewContainerRef }) fieldComponent!: ViewContainerRef;
}
