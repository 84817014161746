import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { combineLatest, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { chain, get, isEmpty } from 'lodash';
import { removeEscapesFromJSON } from '../../util/remove-escapes';
import { getSystemTypesMap, SYSTEM_TYPES } from '../../object/type-chooser-side-sheet/system-types';
import { SUPER_TYPES } from '../../object/type-chooser-side-sheet/super-types';
import * as i0 from "@angular/core";
import * as i1 from "../query.service";
import * as i2 from "../../shell/services/my-profile.service";
export var ALL_TYPE_META_MAP_VALUE; // added a global property to use in places where we cant inject the service.
// we need a better way to use the AllTypeMetaMap in non-services
// do not use this unless there is a compelling reason to.
// Using the allTypes$ observable is preferred
var AllObjectTypesService = /** @class */ (function () {
    function AllObjectTypesService(queryService, myProfileService) {
        this.queryService = queryService;
        this.myProfileService = myProfileService;
        this.allObjectTypes = new ReplaySubject(1);
        this.allTypes$ = this.allObjectTypes.asObservable();
        // all object types service caches all the object types so we can quickly launch them.
        this.loadAllObjectTypes();
    }
    AllObjectTypesService.prototype.getLifecycleWorkflowFromTypeTip$ = function (typeOrSuperTypeTip) {
        return this.getDefaultFromTypeTip(typeOrSuperTypeTip, 'lifecycleWorkflow');
    };
    // @deprecated.
    AllObjectTypesService.prototype.getDefaultDashboardTipFromTypeTip$ = function (typeOrSuperTypeTip) {
        return this.getDefaultFromTypeTip(typeOrSuperTypeTip, 'defaultDashboard');
    };
    // @deprecated.
    AllObjectTypesService.prototype.getDefaultFormTipFromTypeTip$ = function (typeOrSuperTypeTip) {
        return this.getDefaultFromTypeTip(typeOrSuperTypeTip, 'defaultForm');
    };
    AllObjectTypesService.prototype.getDashboardTipFromTypeTip$ = function (typeOrSuperTypeTip) {
        return this.getDefaultFromTypeTip(typeOrSuperTypeTip, 'dashboard');
    };
    AllObjectTypesService.prototype.getFormTipFromTypeTip$ = function (typeOrSuperTypeTip) {
        return this.getDefaultFromTypeTip(typeOrSuperTypeTip, 'form');
    };
    AllObjectTypesService.prototype.getSymbologyForType$ = function (typeOrSuperTypeTip) {
        return this.allTypes$
            .pipe(map(function (metaMap) { return getSymbology(typeOrSuperTypeTip, metaMap); }));
    };
    AllObjectTypesService.prototype.getSearchableMetafields$ = function (typeTip) {
        return this.allTypes$.pipe(map(function (metaMap) { return getSearchableMetafields(typeTip, metaMap); }));
    };
    AllObjectTypesService.prototype.getObjectType$ = function (typeOrSuperTypeTip) {
        return this.allTypes$
            .pipe(map(function (metaMap) { return metaMap.get(typeOrSuperTypeTip) || null; }));
    };
    AllObjectTypesService.prototype.objectTypeInCache$ = function (typeOrSuperTypeTip) {
        return this.allTypes$
            .pipe(map(function (metaMap) { return metaMap.has(typeOrSuperTypeTip); }));
    };
    AllObjectTypesService.prototype.loadAllObjectTypes = function () {
        var _this = this;
        var getAllObjectTypes$ = this.getImplementsForTypes() // TODO EIM-5392
            .pipe(switchMap(function () { return _this.queryService.execute('eim/query/get-all-object-types-master-query'); }), map(createMapFromResults), map(addMissingKnownSystemTypes), map(addMissingKnownSuperTypes), map(collateIsImplementedBy));
        this.subscription = combineLatest([this.myProfileService.getMyProfile$(), getAllObjectTypes$]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), myProfile = _b[0], allTypeMetaMap = _b[1];
            return addObjectTypeSettings(myProfile, allTypeMetaMap);
        }))
            .subscribe(function (allTypeMetaMap) {
            ALL_TYPE_META_MAP_VALUE = allTypeMetaMap;
            _this.allObjectTypes.next(allTypeMetaMap);
        });
    };
    AllObjectTypesService.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    AllObjectTypesService.prototype.getImplementsForTypes = function () {
        var query = 'eim/query/get-all-types-implements-for-object-type-master';
        return this.queryService.execute(query);
    };
    AllObjectTypesService.prototype.getDefaultFromTypeTip = function (typeTipOrSuperTypeTip, valueToGet) {
        return this.allTypes$
            .pipe(map(getMetaMapResult(typeTipOrSuperTypeTip)), map(function (metaMapParams) { return metaMapParams[valueToGet] || null; }));
    };
    AllObjectTypesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AllObjectTypesService_Factory() { return new AllObjectTypesService(i0.ɵɵinject(i1.QueryService), i0.ɵɵinject(i2.MyProfileService)); }, token: AllObjectTypesService, providedIn: "root" });
    return AllObjectTypesService;
}());
export { AllObjectTypesService };
function getSymbology(typeTip, metaMap) {
    var symbologies = [];
    var type = metaMap.get(typeTip);
    if (!type) {
        return symbologies;
    }
    if (type.superType) {
        type.isImplementedBy.forEach(function (implementsType) {
            var subSymbology = getSymbology(implementsType, metaMap);
            symbologies = symbologies.concat(subSymbology);
        });
    }
    if (!type.superType && !isEmpty(type.symbology)) {
        symbologies.push({ symbology: type.symbology, typeTip: type.innerType });
    }
    return symbologies;
}
function getSearchableMetafields(typeTip, metaMap) {
    var type = metaMap.get(typeTip);
    if (!type || type.superType) {
        return [];
    }
    return type.searchableMetafields;
}
function createMapFromResults(response) {
    var systemTypesMap = getSystemTypesMap();
    return response.results
        .reduce(function (acc, value) {
        var result = extractResult(value);
        var symbology = extractSymbology(result);
        var implementsSuperTypes = extractTipsFromSeparatedString(result, 'implementsSuperTypes');
        var searchableMetafields = extractTipsFromSeparatedString(result, 'searchableMetafields');
        var innerTypeTip = result.innerType;
        var type = tslib_1.__assign({}, result, { symbology: symbology,
            implementsSuperTypes: implementsSuperTypes,
            searchableMetafields: searchableMetafields, systemType: systemTypesMap.has(innerTypeTip), isImplementedBy: new Set(), superType: false });
        acc.set(innerTypeTip, type);
        return acc;
    }, new Map());
}
function addObjectTypeSettings(profile, metaMap) {
    var profileObjectTypeSettingMap = chain(profile.objectTypeSettings).keyBy('$tip').value();
    metaMap.forEach(function (allObjectType) {
        var objectTypeTip = allObjectType.appObjectType;
        if (!objectTypeTip) {
            return;
        }
        var profileObjectTypeSetting = profileObjectTypeSettingMap[objectTypeTip];
        if (!profileObjectTypeSetting) {
            allObjectType.dashboard = allObjectType.defaultDashboard || null;
            allObjectType.form = allObjectType.defaultForm || null;
            return;
        }
        allObjectType.dashboard = profileObjectTypeSetting.defaultDashboard || null;
        allObjectType.form = profileObjectTypeSetting.defaultForm || null;
    });
    return metaMap;
}
function addMissingKnownSystemTypes(metaMap) {
    return SYSTEM_TYPES.reduce(function (acc, _a) {
        var $tip = _a.$tip, label = _a.label;
        var existingType = acc.get($tip);
        if (!existingType) {
            var type = {
                innerType: $tip,
                label: label,
                symbology: {},
                implementsSuperTypes: [],
                searchableMetafields: [],
                systemType: true,
                isImplementedBy: new Set(),
                superType: false
            };
            acc.set($tip, type);
        }
        return acc;
    }, metaMap);
}
function addMissingKnownSuperTypes(metaMap) {
    return SUPER_TYPES.reduce(function (acc, _a) {
        var $tip = _a.$tip, label = _a.label;
        var type = {
            superTypeTip: $tip,
            label: label,
            symbology: {},
            implementsSuperTypes: [],
            searchableMetafields: [],
            systemType: false,
            isImplementedBy: new Set(),
            superType: true
        };
        acc.set($tip, type);
        return acc;
    }, metaMap);
}
function collateIsImplementedBy(metaMap) {
    metaMap.forEach(function (value, key) {
        if (value.implementsSuperTypes) {
            value.implementsSuperTypes.forEach(setIsImplementedByType(key, metaMap));
        }
    });
    return metaMap;
}
function setIsImplementedByType(innerType, metaMap) {
    return function addType(superType) {
        var _superType = metaMap.get(superType);
        if (!_superType) {
            return console.error("Cannot get " + superType + " from metaMap, " + innerType + " implements this supertype");
        }
        try {
            _superType.isImplementedBy.add(innerType);
        }
        catch (e) {
            return console.error("Cannot add to supertype " + superType + " from " + innerType + ".", metaMap);
        }
    };
}
function getMetaMapResult(tip) {
    return function getMapResult(metaMap) {
        var result = metaMap.get(tip);
        if (!result) {
            throw new Error("Type tip \"" + tip + "\" is not present in the all types cache");
        }
        return result;
    };
}
function extractTipsFromSeparatedString(obj, attributeLabel) {
    var superTypesCommaSeparatedString = get(obj, attributeLabel, '');
    if (isEmpty(superTypesCommaSeparatedString)) {
        return [];
    }
    return superTypesCommaSeparatedString.split(',');
}
function extractSymbology(obj) {
    var symbology = {};
    if (!isEmpty(obj.symbology)) {
        try {
            var jsonString = removeEscapesFromJSON(obj.symbology);
            symbology = JSON.parse(jsonString);
        }
        catch (e) {
            console.error("could not extract symbology for", obj);
        }
    }
    return symbology;
}
export function extractResult(obj) {
    // first property of the object is just the
    return obj[Object.keys(obj)[0]];
}
