import { IObjectScheme } from '../data/models/scheme';
import { IObject } from '../data/models/object';

export { ILocale, localeScheme };

interface ILocale extends IObject {
  localeId?: string;
  timezone: string;
  dateformat: string;
  datetimeformat: string;
}

const localeScheme: IObjectScheme = {
  'app/type/locale:locale-id': {},
  'app/type/locale:timezone': {},
  'app/type/locale:dateformat': {},
  'app/type/locale:datetimeformat': {}
};
