import { map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { head } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "../all-object-types/all-object-type.service";
import * as i2 from "../formula.service";
var ObjectBehaviourService = /** @class */ (function () {
    function ObjectBehaviourService(allObjectTypesService, formulaService) {
        this.allObjectTypesService = allObjectTypesService;
        this.formulaService = formulaService;
    }
    ObjectBehaviourService.prototype.resolveObjectBehaviour = function (objectTip, typeTip) {
        var _this = this;
        return (typeTip ? of([typeTip]) : this.formulaService.evaluate("TYPE(TIP())", objectTip)).pipe(switchMap(function (_typeTip) { return _this.allObjectTypesService.getDashboardTipFromTypeTip$(head(_typeTip)); }), map(function (dashboardTip) {
            if (dashboardTip) {
                var dashboardUrl = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port
                    + ("/app/s/dashboard/" + encodeURIComponent(dashboardTip) + "/" + encodeURIComponent(objectTip));
                return {
                    url: dashboardUrl
                };
            }
            else {
                return null;
            }
        }));
    };
    ObjectBehaviourService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ObjectBehaviourService_Factory() { return new ObjectBehaviourService(i0.ɵɵinject(i1.AllObjectTypesService), i0.ɵɵinject(i2.FormulaService)); }, token: ObjectBehaviourService, providedIn: "root" });
    return ObjectBehaviourService;
}());
export { ObjectBehaviourService };
