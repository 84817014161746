import { of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "../data/session-manager.service";
import * as i3 from "@angular/router";
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(_authService, _sessionManagerService, _router) {
        this._authService = _authService;
        this._sessionManagerService = _sessionManagerService;
        this._router = _router;
    }
    AuthGuardService.prototype.canActivate = function (route, state) {
        var _this = this;
        return this._sessionManagerService.getSessionInfo$().pipe(first(), switchMap(function (sessionInfo) {
            if (!sessionInfo.token || !sessionInfo.user || !sessionInfo.profile) {
                return of(false);
            }
            return _this._authService.isAuthenticated();
        }), switchMap(function (isAuthenticated) {
            if (!isAuthenticated) {
                _this._sessionManagerService.setOriginalRequestedDestination(state.url);
                _this._router.navigate(['/signin']);
                return of(false);
            }
            _this._sessionManagerService.clearOriginalRequestedDestination();
            return of(true);
        }));
    };
    AuthGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuardService_Factory() { return new AuthGuardService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.SessionManagerService), i0.ɵɵinject(i3.Router)); }, token: AuthGuardService, providedIn: "root" });
    return AuthGuardService;
}());
export { AuthGuardService };
