import { cloneDeep, get } from 'lodash';
import dataConstants from '../../data/constants';
import { PROCESS_NODE_TYPE } from './workflow-designer-enums';
import * as i0 from "@angular/core";
import * as i1 from "./node-converters/decision-node-converter.service";
import * as i2 from "./node-converters/subworkflow-node-converter.service";
import * as i3 from "./node-converters/update-object-node-converter.service";
import * as i4 from "./node-converters/create-object-node-converter.service";
import * as i5 from "./node-converters/start-node-converter.service";
import * as i6 from "./node-converters/end-node-converter.service";
import * as i7 from "./node-converters/set-variable-node-converter.service";
import * as i8 from "./node-converters/delete-node-converter.service";
import * as i9 from "./node-converters/duplicate-object-node-converter.service";
import * as i10 from "./node-converters/switch-node-converter.service";
import * as i11 from "./node-converters/send-email-converter.service";
import * as i12 from "./node-converters/send-sms-converter.service";
import * as i13 from "./node-converters/workflow-ux-node-converter.service";
import * as i14 from "./node-converters/update-security-node-converter.service";
import * as i15 from "./node-converters/save-file-node-converter.service";
import * as i16 from "./node-converters/generate-pdf-converter.service";
import * as i17 from "./node-converters/export-enos-converter.service";
import * as i18 from "./node-converters/import-enos-converter.service";
import * as i19 from "./node-converters/send-notification-converter.service";
import * as i20 from "./node-converters/retrieve-file-converter.service";
import * as i21 from "./node-converters/send-voice-message-converter.service";
import * as i22 from "./node-converters/query-external-map-converter.service";
import * as i23 from "./node-converters/update-stage-converter.service";
import * as i24 from "./node-converters/for-each-node-converter.service";
import * as i25 from "./node-converters/pause-until-node-converter-service/pause-until-node-converter.service";
import * as i26 from "../../util/logger.service";
var NODE_TYPE;
(function (NODE_TYPE) {
    NODE_TYPE[NODE_TYPE["BASE"] = 0] = "BASE";
    NODE_TYPE[NODE_TYPE["COMPOSITE"] = 1] = "COMPOSITE";
})(NODE_TYPE || (NODE_TYPE = {}));
export var ADMIN_GRANT = 'app/security-grant/admin';
var ProcessConverterService = /** @class */ (function () {
    function ProcessConverterService(decisionNodeConverterService, subworkflowNodeConverterService, updateObjectConverterService, createObjectConverterService, startNodeConverterService, endNodeConverterService, setVariableNodeConverterService, deleteNodeConverterService, duplicateNodeConverterService, switchNodeConverterService, sendEmailConverterService, sendSmsConverterService, workflowUXConverterService, updateSecurityNodeConverterService, saveFileNodeConverterService, generatePdfConverterService, exportEnoConverterService, importEnosConverterService, sendNotificationConverterService, retrieveFileConverterService, sendVoiceMessageConverterService, queryExternalConverterService, updateStageConverterService, forEachNodeConverterService, pauseUntilNodeConverterService, loggerService) {
        this.decisionNodeConverterService = decisionNodeConverterService;
        this.subworkflowNodeConverterService = subworkflowNodeConverterService;
        this.updateObjectConverterService = updateObjectConverterService;
        this.createObjectConverterService = createObjectConverterService;
        this.startNodeConverterService = startNodeConverterService;
        this.endNodeConverterService = endNodeConverterService;
        this.setVariableNodeConverterService = setVariableNodeConverterService;
        this.deleteNodeConverterService = deleteNodeConverterService;
        this.duplicateNodeConverterService = duplicateNodeConverterService;
        this.switchNodeConverterService = switchNodeConverterService;
        this.sendEmailConverterService = sendEmailConverterService;
        this.sendSmsConverterService = sendSmsConverterService;
        this.workflowUXConverterService = workflowUXConverterService;
        this.updateSecurityNodeConverterService = updateSecurityNodeConverterService;
        this.saveFileNodeConverterService = saveFileNodeConverterService;
        this.generatePdfConverterService = generatePdfConverterService;
        this.exportEnoConverterService = exportEnoConverterService;
        this.importEnosConverterService = importEnosConverterService;
        this.sendNotificationConverterService = sendNotificationConverterService;
        this.retrieveFileConverterService = retrieveFileConverterService;
        this.sendVoiceMessageConverterService = sendVoiceMessageConverterService;
        this.queryExternalConverterService = queryExternalConverterService;
        this.updateStageConverterService = updateStageConverterService;
        this.forEachNodeConverterService = forEachNodeConverterService;
        this.pauseUntilNodeConverterService = pauseUntilNodeConverterService;
        this.loggerService = loggerService;
        this.processNodeConverterMap = {};
        this.processNodeCompositeCoverterMap = {};
        // Add node converter here
        this.processNodeConverterMap[PROCESS_NODE_TYPE.DECISION] = decisionNodeConverterService;
        this.processNodeConverterMap[PROCESS_NODE_TYPE.SUBWORKFLOW] = subworkflowNodeConverterService;
        this.processNodeConverterMap[PROCESS_NODE_TYPE.UPDATE_OBJECT] = updateObjectConverterService;
        this.processNodeConverterMap[PROCESS_NODE_TYPE.CREATE_OBJECT] = createObjectConverterService;
        this.processNodeConverterMap[PROCESS_NODE_TYPE.SET_VARIABLE] = setVariableNodeConverterService;
        this.processNodeConverterMap[PROCESS_NODE_TYPE.DELETE_OBJECT] = deleteNodeConverterService;
        this.processNodeConverterMap[PROCESS_NODE_TYPE.DUPLICATE_OBJECT] = duplicateNodeConverterService;
        this.processNodeConverterMap[PROCESS_NODE_TYPE.SWITCH] = switchNodeConverterService;
        this.processNodeConverterMap[PROCESS_NODE_TYPE.END] = endNodeConverterService;
        this.processNodeConverterMap[PROCESS_NODE_TYPE.START] = startNodeConverterService;
        // Add composite converter here. This is for workflow nodes that are converted into potentially multiple processnodes and connections
        this.processNodeCompositeCoverterMap[PROCESS_NODE_TYPE.SEND_EMAIL] = sendEmailConverterService;
        this.processNodeCompositeCoverterMap[PROCESS_NODE_TYPE.SEND_SMS] = sendSmsConverterService;
        this.processNodeCompositeCoverterMap[PROCESS_NODE_TYPE.WORKFLOW_UX] = workflowUXConverterService;
        this.processNodeCompositeCoverterMap[PROCESS_NODE_TYPE.UPDATE_SECURITY] = updateSecurityNodeConverterService;
        this.processNodeCompositeCoverterMap[PROCESS_NODE_TYPE.SAVE_FILE] = saveFileNodeConverterService;
        this.processNodeCompositeCoverterMap[PROCESS_NODE_TYPE.GENERATE_PDF] = generatePdfConverterService;
        this.processNodeCompositeCoverterMap[PROCESS_NODE_TYPE.EXPORT_ENOS] = exportEnoConverterService;
        this.processNodeCompositeCoverterMap[PROCESS_NODE_TYPE.IMPORT_ENOS] = importEnosConverterService;
        this.processNodeCompositeCoverterMap[PROCESS_NODE_TYPE.SEND_NOTIFICATION] = sendNotificationConverterService;
        this.processNodeCompositeCoverterMap[PROCESS_NODE_TYPE.RETRIEVE_FILE] = retrieveFileConverterService;
        this.processNodeCompositeCoverterMap[PROCESS_NODE_TYPE.SEND_VOICE_MESSAGE] = sendVoiceMessageConverterService;
        this.processNodeCompositeCoverterMap[PROCESS_NODE_TYPE.QUERY_EXTERNAL_MAP] = queryExternalConverterService;
        this.processNodeCompositeCoverterMap[PROCESS_NODE_TYPE.UPDATE_STAGE] = updateStageConverterService;
        this.processNodeCompositeCoverterMap[PROCESS_NODE_TYPE.FOR_EACH] = forEachNodeConverterService;
        this.processNodeCompositeCoverterMap[PROCESS_NODE_TYPE.PAUSE_UNTIL] = pauseUntilNodeConverterService;
    }
    /*
     * This is the entry of the whole converting from workflow to process
     *
     * @Throws: InvalidWorkflowNodeDataError thrown when validation fails, such as required field is not given
     */
    ProcessConverterService.prototype.convertWorkFlowToProcess = function (workflow, nodeDataForProcess) {
        // nodeDataForProcess.filter(o => o.processNodeType === PROCESS_NODE_TYPE.WORKFLOW_UX).forEach(o => {
        //   let lifecycleObjectProp = NodeEditorLightUtils.getCombinedWorkflowProps(workflow)
        //     .find(prop => prop.key === o.fields.contextObjectSelectedPropertyKey);
        //   if (lifecycleObjectProp) {
        //     this.removeExistingWorkflowUXObjectInputs(workflow);
        //     lifecycleObjectProp = cloneDeep(lifecycleObjectProp);
        //     lifecycleObjectProp.key = 'Object';
        //     workflow.inputs.push( lifecycleObjectProp );
        //     console.log('workflow.inputs', workflow.inputs);
        //   }
        // });
        var clonedNodeData = cloneDeep(nodeDataForProcess);
        var nodeDataWithToLink = this.convertLinkDataToNodeLink(workflow.diagramData.linkDataArray, clonedNodeData);
        var process = this.createDraftProcess(workflow);
        process.grants = workflow.isAdminMode ? ADMIN_GRANT : null;
        var _a = this.createDraftNodesAndConnections(nodeDataWithToLink, workflow), nodes = _a.nodes, links = _a.links;
        this.linkAll(process, nodes, links);
        return process;
    };
    // private removeExistingWorkflowUXObjectInputs(workflow: IWorkflow): void {
    //   for (let i = workflow.inputs.length - 1; i >= 0; i--) {
    //     const inp = workflow.inputs[i];
    //     if (inp.key === 'Object') {
    //       workflow.inputs.splice(i, 1);
    //     }
    //   }
    // }
    /*
     * This method accepts all the existent link data in GoJS graph and all the nodes, then
     * populate toLink property to each node. This does deep clones the given nodes to populate the returning value.
     */
    ProcessConverterService.prototype.convertLinkDataToNodeLink = function (links, nodeData) {
        var resultNodeData = cloneDeep(nodeData);
        var nodeLinkData = {};
        links.forEach(function (link) {
            if (!nodeLinkData[link.from]) {
                nodeLinkData[link.from] = {};
            }
            link.delaySec = link.delaySec || 0;
            var outcomeAndDelayKey = JSON.stringify({ outcome: link.value, delaySec: link.delaySec });
            if (!nodeLinkData[link.from][outcomeAndDelayKey]) {
                nodeLinkData[link.from][outcomeAndDelayKey] = [];
            }
            nodeLinkData[link.from][outcomeAndDelayKey].push(link.to);
        });
        resultNodeData.forEach(function (aResultNodeData) {
            aResultNodeData.toLink = nodeLinkData[aResultNodeData.tip] || {};
        });
        return resultNodeData;
    };
    /*
     * This returns IProcess but not containing nodes
     */
    ProcessConverterService.prototype.createDraftProcess = function (workflow) {
        var process = {
            $tip: get(workflow, 'process.$tip', undefined),
            $type: 'process',
            title: workflow.name,
            description: workflow.description,
            nodes: [],
            env: 'Server'
        };
        return process;
    };
    /*
     * This returns IProcessNode[] but not containing connections and IProcessConnection[] but not containing toNodes
     * Connections and toNode will be populated in linkAll method
     * nodes must contain toLink property
     *
     * @Throws: InvalidWorkflowNodeDataError thrown when validation fails, such as required field is not given
     */
    ProcessConverterService.prototype.createDraftNodesAndConnections = function (nodeData, workflow) {
        var _this = this;
        var links = [];
        var nodes = [];
        nodeData.forEach(function (aNodeData) {
            if (!_this.processNodeConverterMap[aNodeData.processNodeType] && !_this.processNodeCompositeCoverterMap[aNodeData.processNodeType]) {
                _this.loggerService.error("[" + aNodeData.processNodeType + "]'s converter is not implemented.           Please implement converter for [" + aNodeData.processNodeType + "],           which extends either NodeConverter or CompositeNodeConverter class.           And do not forget to add converter map in ProcessConverterService", aNodeData);
                throw Error(aNodeData.processNodeType + "'s converter is not implemented.");
            }
            var nodeType = _this.processNodeConverterMap[aNodeData.processNodeType] ? NODE_TYPE.BASE : NODE_TYPE.COMPOSITE;
            Object.keys(aNodeData.toLink).forEach(function (outcomeAndDelaySecKey) {
                var _a = JSON.parse(outcomeAndDelaySecKey), outcome = _a.outcome, delaySec = _a.delaySec;
                if (nodeType === NODE_TYPE.COMPOSITE) {
                    return;
                }
                links.push({
                    $type: 'processconnection',
                    $security: dataConstants.SECURITY.INSTANCE_USER_ADMIN,
                    outcome: outcome,
                    toNodes: [],
                    point: [0, 0],
                    delaySec: delaySec,
                    toNodeKeys: aNodeData.toLink[outcomeAndDelaySecKey],
                    fromNodeKey: aNodeData.tip
                });
            });
            if (nodeType === NODE_TYPE.BASE) {
                nodes.push(_this.processNodeConverterMap[aNodeData.processNodeType].convert(aNodeData, workflow));
                return;
            }
            var nodesAndLinks = _this.processNodeCompositeCoverterMap[aNodeData.processNodeType].convert(aNodeData, workflow);
            links = links.concat(nodesAndLinks.links);
            nodes = nodes.concat(nodesAndLinks.nodes);
        });
        return { nodes: nodes, links: links };
    };
    /*
     * Connect process and nodes and links all together and actual process, nodes, and links will be modified
     * as all the parameters are reference
     */
    ProcessConverterService.prototype.linkAll = function (process, nodes, links) {
        process.nodes = nodes;
        var keyToNodeMap = {};
        nodes.forEach(function (node) {
            if (node.key === null) {
                return;
            }
            keyToNodeMap[node.key] = node;
        });
        links.forEach(function (link) {
            // fromNodeKey does not exist for links generated by composite node
            if (link.fromNodeKey) {
                keyToNodeMap[link.fromNodeKey].connections.push(link);
            }
            // toNodeKeys does not exist for the last
            if (link.toNodeKeys) {
                link.toNodes = link.toNodeKeys.map(function (nodeKey) {
                    return keyToNodeMap[nodeKey];
                });
            }
        });
    };
    ProcessConverterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProcessConverterService_Factory() { return new ProcessConverterService(i0.ɵɵinject(i1.DecisionNodeConverterService), i0.ɵɵinject(i2.SubworkflowNodeConverterService), i0.ɵɵinject(i3.UpdateObjectNodeConverterService), i0.ɵɵinject(i4.CreateObjectNodeConverterService), i0.ɵɵinject(i5.StartNodeConverterService), i0.ɵɵinject(i6.EndNodeConverterService), i0.ɵɵinject(i7.SetVariableNodeConverterService), i0.ɵɵinject(i8.DeleteNodeConverterService), i0.ɵɵinject(i9.DuplicateObjectNodeConverterService), i0.ɵɵinject(i10.SwitchNodeConverterService), i0.ɵɵinject(i11.SendEmailConverterService), i0.ɵɵinject(i12.SendSmsConverterService), i0.ɵɵinject(i13.WorkflowUXNodeConverterService), i0.ɵɵinject(i14.UpdateSecurityNodeConverterService), i0.ɵɵinject(i15.SaveFileNodeConverterService), i0.ɵɵinject(i16.GeneratePdfConverterService), i0.ɵɵinject(i17.ExportEnoConverterService), i0.ɵɵinject(i18.ImportEnosConverterService), i0.ɵɵinject(i19.SendNotificationConverterService), i0.ɵɵinject(i20.RetrieveFileConverterService), i0.ɵɵinject(i21.SendVoiceMessageConverterService), i0.ɵɵinject(i22.QueryExternalMapConverterService), i0.ɵɵinject(i23.UpdateStageConverterService), i0.ɵɵinject(i24.ForEachNodeConverterService), i0.ɵɵinject(i25.PauseUntilNodeConverterService), i0.ɵɵinject(i26.LoggerService)); }, token: ProcessConverterService, providedIn: "root" });
    return ProcessConverterService;
}());
export { ProcessConverterService };
