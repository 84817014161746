import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { SHA256 } from 'crypto-js';
import { isFunction } from 'lodash';
import * as io from 'socket.io-client';

import { environment } from '../../environments/environment';
import { ISessionInfo, SessionManagerService } from './session-manager.service';

export enum ESessionMessageDataType {
  tipWatch = 'ENO_WATCH',
  criteriaWatch = 'CRITERIA_WATCH',
  queryWatch = 'QUERY_WATCH',
  formulaWatch = 'FORMULA_WATCH',
  processResponse = 'PROCESS_RESPONSE'
}

interface IRawMessage {
  topic: string;
  data: string;
}

export interface IMessage {
  topic: string;
}

export interface ISessionMessage extends IMessage {
  data: ISessionMessageData;
}

export interface ISessionMessageData {
  type: ESessionMessageDataType;
}

@Injectable({
  providedIn: 'root'
})
export class PubSubService implements OnDestroy {
  private _socket;

  private _SessionMessageTopic: string;
  private _sessionMessageSubject: Subject<ISessionMessageData>;
  private _socketDisconnected: boolean;

  constructor(
    private _sessionManagerService: SessionManagerService,
    private ngZone: NgZone
  ) {
    this._initSocket();
    this._initSessionMessage();
    this._initMessageReceiver();
    this._socketDisconnected = false;
  }

  private static _isSessionMessage(message: IMessage) {
    return message.topic.startsWith('session-');
  }

  private static _generateSessionMessageTopic(sessionToken: string) {
    return `session-${SHA256(sessionToken).toString()}`;
  }

  private _initSocket() {
    this.ngZone.runOutsideAngular(() => {
      // uncomment this when you are connecting your front-end to a dev, test or prod instance
      // to proxy pub-sub socket.io, see proxy.conf.json
      // this._socket = io('localhost:4200', { path: environment.pubSubPath + '/socket.io/' });
      this._socket = io(environment.host, { path: environment.pubSubPath + '/socket.io/' });

      this._socket.on('connect', (msg) => {
        if (this._socketDisconnected === true) {
          // if the original connection was lost, we need to reconnect our listener
          this._socket.emit('subscribe', { topic: this._SessionMessageTopic });
          this._socketDisconnected = false;
        }
      });

      this._socket.on('disconnect', (msg) => {
        this._socketDisconnected = true;
      });
    });
  }

  private _initSessionMessage() {
    this._sessionMessageSubject = new Subject<ISessionMessageData>();

    this._sessionManagerService.getSessionInfo$().pipe(
      first((sessionInfo: ISessionInfo) => !!sessionInfo.token)
    ).subscribe(
      (sessionInfo: ISessionInfo) => {
        this._SessionMessageTopic = PubSubService._generateSessionMessageTopic(sessionInfo.token);

        this._socket.emit('subscribe', { topic: this._SessionMessageTopic });
      }
    );
  }

  private _initMessageReceiver() {
    // needed for protractor please see README.md for more info
    this.ngZone.runOutsideAngular(() => {
      this._socket.on('receive', (message: IRawMessage) => {
        // @todo The message received here could be a different message (an ad hoc message, e.g. chat) than a session message.
        if (PubSubService._isSessionMessage(message)) {
          this.ngZone.run(() => {
            this._sessionMessageSubject.next(JSON.parse(message.data) as ISessionMessageData);
          });
        }
      });
    });
  }

  ngOnDestroy() {
    if (this._SessionMessageTopic) {
      this._socket.emit('unsubscribe', { topic: this._SessionMessageTopic });
    }
  }

  receiveSessionMessage(
    type: ESessionMessageDataType,
    when?: (messageData: ISessionMessageData) => boolean
  ): Observable<ISessionMessageData> {
    return this._sessionMessageSubject.pipe(
      filter((messageData: ISessionMessageData) => messageData.type === type),
      filter((messageData: ISessionMessageData) => isFunction(when) ? when(messageData) : true)
    );
  }

  // @todo This is not used now. The type of the message data needs to be revisited.
  publish(data: Object) {
    this._socket.emit('publish', { topic: this._SessionMessageTopic, data: JSON.stringify(data) });
  }
}
