import { Inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthHttpClientService } from '../auth/auth-http-client.service';
import { Observable } from 'rxjs';
import { LoadScriptService } from '../shared/load-script/load-script.service';
import { map, switchMap } from 'rxjs/operators';
import { updateSpecLogo } from './api';
import { WINDOW } from '../dom-tokens';

export const REDOC_SCRIPT = `${environment.assetPath}/assets/js/redoc/redoc.standalone.js`;

export interface IRedoc {
  init: (openAPISpec, options, element) => void;
}

interface IWindowWithRedoc {
  Redoc: IRedoc;
}

@Injectable({
  providedIn: 'root'
})
export class APIService {

  restAPIServicePath: string;

  constructor(
    private authHttpClient: AuthHttpClientService,
    private loadScript: LoadScriptService,
    @Inject(WINDOW) private window: IWindowWithRedoc
  ) {
    this.restAPIServicePath = `${environment.host}${environment.restPath}`;
  }

  getOpenAPISpec(): Observable<{openAPISpec: ArrayBuffer, redoc: IRedoc}> {
    // use this url with the proxy.conf file when testing
    // const url = 'host/rest/docs/openapi.yaml'; // test url

    const url = `${this.restAPIServicePath}/docs/openapi.yaml`;
    return this.loadScript.load(REDOC_SCRIPT)
      .pipe(
        switchMap(() => this.authHttpClient.get(url)),
        map(updateSpecLogo),
        // redoc is available after we load the script
        map((openAPISpec: ArrayBuffer) => ({openAPISpec, redoc: this.window.Redoc}))
      );
  }

}
