import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { SideSheetBase } from '../../side-sheet/side-sheet-base';
import { PrintService } from '../print.service';
import { IPrintProps, IPrintResponse, TRACKING_STATUS, DEFAULT_SETTING_PRINT } from '../print';
import { SideSheetService } from '../../side-sheet/side-sheet.service';
import { PrintPdfExportSideSheetComponent } from '../print-pdf-export-side-sheet/print-pdf-export-side-sheet.component';
import { BehaviorSubject, of, Subject, throwError } from 'rxjs';
import { filter, switchMap, takeUntil, takeWhile, tap, first } from 'rxjs/operators';
import { ToastService } from '../../shell/services/toast.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Tip } from '../../data/models/types';
import { PrintingManagerService } from '../../settings/modules/printing/printing-manager.service';
import { IFormSideSheetResponse } from '../../models/form';
import { SideSheetListModes } from '../../side-sheet/side-sheet-list/side-sheet-list.component';
import { IPrintTemplateUsage } from '../../models/print-template';
import { SideSheetOptionListComponent } from '../../side-sheet/side-sheet-option-list/side-sheet-option-list.component';
import { IFormQueryResult } from '../../settings/object-type/object-type.service';


@Component({
  selector: 'app-print-html-preview-side-sheet',
  templateUrl: './print-html-preview-side-sheet.component.html',
  styleUrls: [ './print-html-preview-side-sheet.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintHtmlPreviewSideSheetComponent extends SideSheetBase<IPrintProps, null> implements  OnDestroy {

  readonly loading$ = new BehaviorSubject<boolean>(true);
  htmlContent: SafeResourceUrl;

  private props: IPrintProps;
  private readonly unsubscribe$ = new Subject();

  constructor(private printService: PrintService,
              private sideSheetService: SideSheetService,
              private toastService: ToastService,
              private domSanitizer: DomSanitizer,
              private printingManagerService: PrintingManagerService,
              private cdr: ChangeDetectorRef) {
    super();
  }

  setProps(props: IPrintProps) {
    this.props = props;
    this.onPrinting(this.props);
  }

  onPrinting(props: IPrintProps) {
    this.printingManagerService.getObjectPrintTemplateUsages(props.contextTip).pipe(
      first()
    ).subscribe(
      (printTemplateUsages: IFormQueryResult[]) => {
        switch (printTemplateUsages.length) {
          case 0:
            this.triggerGenerateHtml(props);
            break;
          case 1:
            this.printByTemplate(printTemplateUsages[0].$tip);
            break;
          default:
            this.openPrintTemplateUsageList(printTemplateUsages);
            break;
        }
      }
    );
  }

  openPrintTemplateUsageList(printTemplateUsages: IFormQueryResult[]) {
    const drilldownRef = this.sideSheetService.push(SideSheetOptionListComponent);
    drilldownRef.componentInstance.listType = SideSheetListModes.SINGLE_SELECT;
    drilldownRef.componentInstance.options = printTemplateUsages.sort().map(item => ({ label: item.name, value: item.$tip }));
    drilldownRef.componentInstance.label = 'Select print template';
    drilldownRef.componentInstance.hideBack = true;

    drilldownRef.componentInstance.done = (response: IFormSideSheetResponse) => {
      this.printByTemplate(response.selected);
    };
  }

  printByTemplate(templateTip: Tip) {
    this.printingManagerService.getPrintTemplateUsage(templateTip)
      .pipe(first())
      .subscribe((printUsage: IPrintTemplateUsage) => {
        const props: IPrintProps = {
          templateTip: printUsage.exportFormat,
          settingTip: DEFAULT_SETTING_PRINT,
          contextTip: this.props.contextTip,
          formTip: printUsage.form
        };
        this.triggerGenerateHtml(props);
      });
  }


  private triggerGenerateHtml(props: IPrintProps) {
    this
      .printService
      .generateHtml(props)
      .pipe(
        tap(({ trackingTip }: IPrintResponse) => this.props.trackingTip = trackingTip),
        switchMap(() => this.printService.getTrackingObject(this.props.trackingTip)),
        takeWhile(tracking => tracking.generateHtmlStatus === TRACKING_STATUS.PROGRESS, true),
        switchMap(tracking => {
          if (tracking.generateHtmlStatus === TRACKING_STATUS.FAILED) {
            return throwError('error processing generate html');
          }
          return of(tracking);
        }),
        filter(tracking => tracking.generateHtmlStatus === TRACKING_STATUS.COMPLETED),
        switchMap(() => this.printService.download(this.props.trackingTip)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        (htmlContent) => {
          this.loading$.next(false);
          this.htmlContent = this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(htmlContent));
          this.cdr.markForCheck();
        },
        () => {
          this.toastService.showErrorToast('Unable to generate preview. Please try again later.');
          this.sideSheetService.pop();
        }
      );
  }

  download() {
    if (!this.loading$.getValue()) {
      this.sideSheetService.pop();
      const { componentInstance } = this.sideSheetService.push(PrintPdfExportSideSheetComponent) as {
        componentInstance: PrintPdfExportSideSheetComponent
      };

      componentInstance.setProps(this.props);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
