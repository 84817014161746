// originally in object-service written by owen

import { Eno } from '../models/Eno';
import { DataTypes, IFieldScheme } from '../models/scheme';
import { CAST_VALUE_FUNCS } from './js-object-eno-field-conversion';

export function fieldTipToName(fieldTip: string): string {
  const m = /[a-z0-9_-]+$/i.exec(fieldTip);
  if (!m) {
    throw new Error('[ObjectService] Cannot automatically determine a property name: ' + fieldTip);
  }
  return m[0].replace(/\-([a-z])/gi, (_, alpha) => alpha.toUpperCase()).replace(/[^a-zA-Z0-9]/gi, '');
}

export function castValue(eno: Eno, fieldTip: string, fieldScheme: IFieldScheme) {
  const value = eno.getFieldValues(fieldTip);
  return CAST_VALUE_FUNCS[fieldScheme.type || DataTypes.string](value);
}
