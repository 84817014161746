var _a;
import * as tslib_1 from "tslib";
import { DataTypes } from '../data/models/scheme';
import { SUPER_TYPES } from '../object/type-chooser-side-sheet/super-types';
export var TYPE_GEO_RESTRICT;
(function (TYPE_GEO_RESTRICT) {
    TYPE_GEO_RESTRICT["POINT"] = "point";
    TYPE_GEO_RESTRICT["LINE"] = "linestring";
    TYPE_GEO_RESTRICT["POLYGON"] = "polygon";
})(TYPE_GEO_RESTRICT || (TYPE_GEO_RESTRICT = {}));
var fieldScheme = {
    'field/label': { type: DataTypes.i18n },
    'field/description': { type: DataTypes.i18n },
    'field/datatype': {},
    'field/name': {},
    'field/required': { type: DataTypes.boolean },
    'field/default': { type: DataTypes.formula },
    'field/default-i18n': { type: DataTypes.i18n },
    'field/calculation': {},
    'field/minlen': { type: DataTypes.number },
    'field/maxlen': { type: DataTypes.number },
    'field/minlenexc': { type: DataTypes.number },
    'field/maxlenexc': { type: DataTypes.number },
    'field/mincount': { type: DataTypes.number },
    'field/maxcount': { type: DataTypes.number },
    'field/mincountexc': { type: DataTypes.number },
    'field/maxcountexc': { type: DataTypes.number },
    'field/minval': { type: DataTypes.number },
    'field/maxval': { type: DataTypes.number },
    'field/minvalexc': { type: DataTypes.number },
    'field/maxvalexc': { type: DataTypes.number },
    'field/regexp': {},
    'field/whitelist': { type: DataTypes.formula },
    'field/whitelist-i18n': { type: DataTypes.i18nArray },
    'field/blacklist': { type: DataTypes.stringArray },
    'field/blacklist-i18n': { type: DataTypes.i18nArray },
    'field/multiline': { type: DataTypes.boolean },
    'field/typerestrict': { type: DataTypes.stringArray },
    'field/totaldigits': { type: DataTypes.number },
    'field/fractiondigits': { type: DataTypes.number },
    'field/uniquevalues': { type: DataTypes.boolean },
    'field/type-geo-restrict': { type: DataTypes.string },
    'field/number-format': { type: DataTypes.string },
    'field/merge-policy': {},
    'field/implements': { type: DataTypes.stringArray },
    'field/tag': { type: DataTypes.stringArray },
    'field/searchable': { type: DataTypes.boolean },
    'field/search-index-value': { type: DataTypes.formula },
    'field/on-delete': { type: DataTypes.string },
};
fieldScheme['field/calculation-field-dependencies'] = { type: DataTypes.objectArray, scheme: fieldScheme, mutable: true };
export { fieldScheme };
export var FIELD_DATA_TYPE;
(function (FIELD_DATA_TYPE) {
    FIELD_DATA_TYPE["string_i18n"] = "string-i18n";
    FIELD_DATA_TYPE["string"] = "string";
    FIELD_DATA_TYPE["blob"] = "blob";
    FIELD_DATA_TYPE["number"] = "number";
    FIELD_DATA_TYPE["decimal"] = "decimal";
    FIELD_DATA_TYPE["bool"] = "bool";
    FIELD_DATA_TYPE["date"] = "date";
    FIELD_DATA_TYPE["datetime"] = "datetime";
    FIELD_DATA_TYPE["object"] = "object";
    FIELD_DATA_TYPE["email"] = "email";
    FIELD_DATA_TYPE["phone"] = "phone";
    FIELD_DATA_TYPE["uri"] = "uri";
    FIELD_DATA_TYPE["geography"] = "geography";
    FIELD_DATA_TYPE["formula"] = "formula";
    FIELD_DATA_TYPE["string_formula"] = "string-formula";
    FIELD_DATA_TYPE["string_i18n_formula"] = "string-i18n-formula";
    FIELD_DATA_TYPE["json"] = "json";
    FIELD_DATA_TYPE["html"] = "html";
})(FIELD_DATA_TYPE || (FIELD_DATA_TYPE = {}));
export var EXTENDED_FIELD_DATA_TYPE;
(function (EXTENDED_FIELD_DATA_TYPE) {
    EXTENDED_FIELD_DATA_TYPE["sub_object"] = "sub-object";
    EXTENDED_FIELD_DATA_TYPE["file"] = "file";
    EXTENDED_FIELD_DATA_TYPE["list"] = "list";
    EXTENDED_FIELD_DATA_TYPE["address"] = "address";
    EXTENDED_FIELD_DATA_TYPE["point"] = "point";
    EXTENDED_FIELD_DATA_TYPE["line"] = "line";
    EXTENDED_FIELD_DATA_TYPE["polygon"] = "polygon";
    EXTENDED_FIELD_DATA_TYPE["assignable"] = "assignable";
    EXTENDED_FIELD_DATA_TYPE["symbology"] = "symbology";
    EXTENDED_FIELD_DATA_TYPE["hierarchical"] = "hierarchical";
})(EXTENDED_FIELD_DATA_TYPE || (EXTENDED_FIELD_DATA_TYPE = {}));
export var EXTENDED_FIELD_ACTUAL_TYPE_MAP = (_a = {},
    _a[EXTENDED_FIELD_DATA_TYPE.file] = {
        fieldDataType: FIELD_DATA_TYPE.object,
        typeRestrict: ['file']
    },
    _a[EXTENDED_FIELD_DATA_TYPE.list] = {
        fieldDataType: FIELD_DATA_TYPE.object,
        typeRestrict: ['app/list']
    },
    _a[EXTENDED_FIELD_DATA_TYPE.address] = {
        fieldDataType: FIELD_DATA_TYPE.object,
        typeRestrict: ['app/address']
    },
    _a[EXTENDED_FIELD_DATA_TYPE.point] = {
        fieldDataType: FIELD_DATA_TYPE.geography,
        typeGeoRestrict: TYPE_GEO_RESTRICT.POINT
    },
    _a[EXTENDED_FIELD_DATA_TYPE.line] = {
        fieldDataType: FIELD_DATA_TYPE.geography,
        typeGeoRestrict: TYPE_GEO_RESTRICT.LINE
    },
    _a[EXTENDED_FIELD_DATA_TYPE.polygon] = {
        fieldDataType: FIELD_DATA_TYPE.geography,
        typeGeoRestrict: TYPE_GEO_RESTRICT.POLYGON
    },
    _a[EXTENDED_FIELD_DATA_TYPE.assignable] = {
        fieldDataType: FIELD_DATA_TYPE.object,
        typeRestrict: ['app/assignment']
    },
    _a[EXTENDED_FIELD_DATA_TYPE.symbology] = {
        fieldDataType: FIELD_DATA_TYPE.json
    },
    _a[EXTENDED_FIELD_DATA_TYPE.sub_object] = {
        fieldDataType: FIELD_DATA_TYPE.object
    },
    _a[EXTENDED_FIELD_DATA_TYPE.hierarchical] = {
        fieldDataType: FIELD_DATA_TYPE.object,
        typeRestrict: tslib_1.__spread(SUPER_TYPES.filter(function (x) { return x.isHierarchical; }).map(function (x) { return x.$tip; }), ['app/group'])
    },
    _a);
