import * as tslib_1 from "tslib";
import { fromPairs } from 'lodash';
import { NOW_VAR_NAME } from '../../../util/current-datetime.service';
import { Stringify } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { createSetVarNode } from './utils/create-node-utils';
import { NodeConverterBase } from './NodeConverterBase';
import * as i0 from "@angular/core";
var StartNodeConverterService = /** @class */ (function (_super) {
    tslib_1.__extends(StartNodeConverterService, _super);
    function StartNodeConverterService() {
        var _this = _super.call(this) || this;
        _this.type = 'processnode/reference/setvar';
        _this.processNodeType = 'processnodetype/reference/setvar';
        return _this;
    }
    StartNodeConverterService.prototype.convert = function (nodeData, workflow) {
        var vars = workflow.actors.length > 0 ?
            fromPairs(workflow.actors.map(function (a) { return [a.key, Stringify({ name: 'ARRAY', args: [a.actorTip] })]; }))
            : { 'prop-empty': null };
        vars[NOW_VAR_NAME] = 'NOW()';
        return createSetVarNode(vars, nodeData.tip);
    };
    StartNodeConverterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StartNodeConverterService_Factory() { return new StartNodeConverterService(); }, token: StartNodeConverterService, providedIn: "root" });
    return StartNodeConverterService;
}(NodeConverterBase));
export { StartNodeConverterService };
