import { IExtendedFormlyFieldConfig, IFormBuilderFieldConfig, INogginFormFieldConfig } from '../form.types';
import { FORM_OPTIONS_NOGGIN } from '../form-options-noggin';
import { FORM_OPTIONS_PALLET } from '../form-options-pallet';

export function uniqueFieldKey(field: IExtendedFormlyFieldConfig): string {
  /*
    Unique field key is just:
     - The "type"
     - & "templateOption.type" (effectively a subtype)
  */
  const subtypePresent = field.templateOptions && field.templateOptions.type;
  const key = subtypePresent
    ? `${field.type}-${field.templateOptions.type}`
    : field.type;

  return key;
}

export function addNogginConfigToFormField(field: IExtendedFormlyFieldConfig): INogginFormFieldConfig {
  const props = FORM_OPTIONS_NOGGIN[uniqueFieldKey(field)];
  return {...field, ...props};
}

export function addPaletteConfigToFormField(field: INogginFormFieldConfig | IExtendedFormlyFieldConfig): IFormBuilderFieldConfig {
  const uniqueKey = uniqueFieldKey(field);
  const props = FORM_OPTIONS_PALLET[uniqueKey];
  return {
    ...field,
    listId: uniqueKey,
    key: null,
    ...props
  };
}

export const transformExtendFormlyFieldToFormBuilderField = (field: IExtendedFormlyFieldConfig):
  IFormBuilderFieldConfig => addPaletteConfigToFormField(addNogginConfigToFormField(field));

