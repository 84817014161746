import { ChangeDetectorRef, ElementRef, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SignInStatus } from '../auth.service';
import { debounceTime, distinctUntilChanged, first } from 'rxjs/operators';
import { SITE_SELECTOR_PAGE_URL } from '../../shared/constants';
var SignInComponent = /** @class */ (function () {
    function SignInComponent(activatedRoute, authService, checkSSOProvidersService, deviceDetectorService, locationService, cdr) {
        this.activatedRoute = activatedRoute;
        this.authService = authService;
        this.checkSSOProvidersService = checkSSOProvidersService;
        this.deviceDetectorService = deviceDetectorService;
        this.locationService = locationService;
        this.cdr = cdr;
        this.signInForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
            password: new FormControl('', Validators.required)
        });
        this.loading = false;
        this.passwordSignIn = false;
        this.isNativeApp = false;
        this.status = null;
    }
    Object.defineProperty(SignInComponent.prototype, "emailValid", {
        get: function () {
            return this.signInForm.get('email').valid;
        },
        enumerable: true,
        configurable: true
    });
    SignInComponent.prototype.ngOnInit = function () {
        var _this = this;
        // This message shows up when a user clicks a activation link that has already been activated
        var message = this.activatedRoute.snapshot.queryParams.message;
        if (message) {
            this.message = message;
        }
        this
            .signInForm
            .get('email')
            .valueChanges
            .pipe(debounceTime(150), distinctUntilChanged())
            .subscribe(function () {
            // reset password sign in if email is changed
            _this.passwordSignIn = false;
        });
        this.isNativeApp = !this.deviceDetectorService.isDesktop();
    };
    SignInComponent.prototype.checkEmailAddress = function () {
        var _this = this;
        this.loading = true;
        var email = this.signInForm.value.email;
        this.checkSSOProvidersService.checkEmailAddressAndRedirect$(email)
            .pipe(first())
            .subscribe(function (passwordSignIn) {
            _this.loading = false;
            _this.passwordSignIn = passwordSignIn;
            _this.cdr.detectChanges();
            if (passwordSignIn) {
                _this.focusPassword();
            }
        }, function (err) {
            _this.loading = false;
            _this.status = SignInStatus.FAILURE;
        });
    };
    SignInComponent.prototype.focusPassword = function () {
        this.password.nativeElement.focus();
    };
    SignInComponent.prototype.onSignIn = function () {
        var _this = this;
        this.loading = true;
        this.message = '';
        this.status = null;
        this.authService.signIn(this.signInForm.value.email, this.signInForm.value.password).subscribe(function (signInStatus) {
            // Automatically refreshed with signed-in token in session-manager service due to token change
            _this.loading = false;
            if (signInStatus !== SignInStatus.SUCCESS) {
                _this.status = signInStatus;
            }
        }, function () {
            _this.loading = false;
            _this.status = SignInStatus.FAILURE;
        });
    };
    SignInComponent.prototype.changeSite = function () {
        this.locationService.redirect(SITE_SELECTOR_PAGE_URL);
    };
    return SignInComponent;
}());
export { SignInComponent };
