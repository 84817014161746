import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-chrome',
  templateUrl: './auth-chrome.component.html',
  styleUrls: ['./auth-chrome.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthChromeComponent implements OnInit {
  @Input() primaryTitle: string;

  @Input() secondaryTitle: string;

  @Input() formType = 'Login';

  @Input() message: string;

  @Input() showMessage = false;

  @Input() showAuthErrorDesc = false;

  @Input() secondaryTitleDiv;

  currentYear: number;

  constructor() {}

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }
}
