export function convertToCSV(objArray: Array<{[a: string]: string}>): string {
  return objArray.reduce((acc, item) => {
    let line = '';
    Object.keys(item).forEach(key => {
      if (line !== '') {
        line += ',';
      }

      line += item[key];
    });

    acc += line + '\r\n';

    return acc;

  }, '');

}
