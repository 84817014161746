import * as tslib_1 from "tslib";
import { init, configureScope } from '@sentry/browser';
import { environment } from '../../environments/environment';
import { get } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "./gtm.service";
import * as i2 from "../shell/services/me.service";
var SentryService = /** @class */ (function () {
    function SentryService(gtmService, meService) {
        this.gtmService = gtmService;
        this.meService = meService;
        this.onNativeApp = false;
    }
    SentryService.prototype.init = function () {
        var e_1, _a;
        init({
            dsn: environment.sentryDsn,
            release: environment.release,
            environment: environment.enCloudEnv
        });
        configureScope(function (scope) {
            scope.setTag('namespace', environment.ns);
            scope.setTag('serviceUri', environment.host);
            scope.setTag('customerUri', window.location.origin);
        });
        if (window.location.search) {
            var paramList = window.location.search.slice(1).split('&'); // remove the '?'
            if (paramList && paramList.length > 0) {
                try {
                    for (var paramList_1 = tslib_1.__values(paramList), paramList_1_1 = paramList_1.next(); !paramList_1_1.done; paramList_1_1 = paramList_1.next()) {
                        var param = paramList_1_1.value;
                        if (param && param.split('=').length === 2) {
                            if (param.split('=')[0] === 'nativeApp') {
                                this.onNativeApp = param.split('=')[1] === 'true';
                            }
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (paramList_1_1 && !paramList_1_1.done && (_a = paramList_1.return)) _a.call(paramList_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
        }
        var systemInfo = {
            release: environment.release,
            environment: environment.enCloudEnv,
            namespace: environment.ns,
            serviceUri: environment.host,
            customerUri: window.location.origin,
            onNativeApp: this.onNativeApp === true ? 'true' : 'false'
        };
        console.info(systemInfo);
        this.gtmService.push(systemInfo);
        this.meService.me$.subscribe(function (me) {
            if (me) {
                configureScope(function (scope) {
                    var fullName = get(me, 'person.fullName', 'name-not-available');
                    scope.setUser({
                        id: me.$tip,
                        username: ("" + fullName).replace(/(^ )|( $)/g, ''),
                        email: me.email
                    });
                });
            }
        });
    };
    SentryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SentryService_Factory() { return new SentryService(i0.ɵɵinject(i1.GtmService), i0.ɵɵinject(i2.MeService)); }, token: SentryService, providedIn: "root" });
    return SentryService;
}());
export { SentryService };
