import { Inject, Injectable, OnDestroy, NgZone } from '@angular/core';
import { IInactivityConfig, InactivityCountdownTimer } from 'inactivity-countdown-timer';
import { INACTIVITY_COUNTDOWN_TIMER } from './inactivity-countdown-provider';
import { AuthService } from '../auth.service';
import { ToastService } from '../../shell/services/toast.service';
import { Notification } from '@noggin/angular2-notifications';
import { SessionManagerService } from '../../data/session-manager.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LoggerService } from '../../util/logger.service';

@Injectable({
  providedIn: 'root'
})
export class InactivityLogoutService implements OnDestroy {
  notification: Notification;
  destroy$ = new Subject();
  constructor(
    @Inject(INACTIVITY_COUNTDOWN_TIMER) private inactivityCountdownTimer: InactivityCountdownTimer,
    private sessionInfo: SessionManagerService,
    private toastService: ToastService,
    private authService: AuthService,
    private loggerService: LoggerService,
    private ngZone: NgZone
  ) { }

  init() {
    const inactivityConfigBase: IInactivityConfig = {
      startCountDownTimerAt: 1000 * 60, // 60s
      timeoutCallback: this.timeOut.bind(this),
      countDownCallback: this.countDown.bind(this),
      countDownCancelledCallback: this.cancelCountDown.bind(this),
      throttleDuration: 30 * 1000 // 30s
    };

    this.sessionInfo.getSessionInfo$()
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(({token, inactivityTimeout}) => {
        if (!token && this.inactivityCountdownTimer.started) {
          this.loggerService.info(`[inactivity-logout-service] stopping inactivity logout timer, no session to logout from.`);
          // needed for protractor please see README.md for more info
          this.ngZone.runOutsideAngular(() => {
            this.inactivityCountdownTimer.stop();
          });
          return;
        }


        if (!inactivityTimeout && this.inactivityCountdownTimer.started) {
          // tslint:disable-next-line:max-line-length
          this.loggerService.info(`[inactivity-logout-service] stopping inactivity logout timer, 'Session inactivity timeout' set to zero.`);
          // needed for protractor please see README.md for more info
          this.ngZone.runOutsideAngular(() => {
            this.inactivityCountdownTimer.stop();
          });
          return;
        }

        if (token && inactivityTimeout) {
          const idleTimeoutTime = inactivityTimeout * 1000;
          // tslint:disable-next-line:max-line-length
          const logMessage = `[inactivity-logout-service] starting the inactivity logout timer, with a value from the session token of: ${inactivityTimeout / 60} minutes.`;
          this.loggerService.info(logMessage);
          // needed for protractor please see README.md for more info
          this.ngZone.runOutsideAngular(() => {
            this.inactivityCountdownTimer.setup({...inactivityConfigBase, idleTimeoutTime});
            this.inactivityCountdownTimer.start();
          });
        }
      });
  }

  timeOut() {
    this.authService.signOut();
  }

  countDown(seconds_left) {
    const message = `Your session will expire in ${seconds_left} seconds. Please click anywhere to keep working.`;
    if (!this.notification) {
      this.notification = this.toastService.showWarningToast(message, {timeOut: 0});
    }
  }

  cancelCountDown() {
    this.toastService.remove(this.notification.id);
    this.notification = null;
    this.toastService.showSuccessToast(`We have extended your session.`);
  }

  ngOnDestroy(): void {
    this.inactivityCountdownTimer.cleanup();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
