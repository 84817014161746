<div
    class="alert"
    [ngClass]="'alert-' + type"
    role="alert">
  <div class="icon">
    <span
        class="mdi"
        [class.mdi-alert-circle]="type === 'danger'"
        [class.mdi-check]="type === 'success'"
        [class.mdi-info-outline]="type === 'info'"
        [class.mdi-alert-triangle]="type === 'warning'">
    </span>
  </div>
  <div class="message">
    <ng-content></ng-content>
  </div>
</div>
