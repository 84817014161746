import { Inject, Injectable } from '@angular/core';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { ProcessService } from '../data/process.service';
import { Observable, of, throwError } from 'rxjs';
import { get } from 'lodash';
import { WINDOW } from '../dom-tokens';
import { environment } from '../../environments/environment';
import { SessionManagerService } from '../data/session-manager.service';

interface ICheckEmailResult {
  email: string | null;
  tip: string | null;
  type: string | null;
}

@Injectable({
  providedIn: 'root'
})
export class CheckSSOProvidersService {

  constructor(
    private processService: ProcessService,
    @Inject(WINDOW) private _window: Window) { }

  checkEmailAddressAndRedirect$(emailAddress: string): Observable<boolean> {

    const vars = { 'email': [emailAddress] };

    return this.processService
      .start('eim/process/sso/get-user-provider', vars)
      .pipe(
        first(),
        map((result) => ({
          email: get(result, 'vars.email[0]', null),
          tip: get(result, 'vars.tip[0]', null),
          type: get(result, 'vars.type[0]', null)
        })),
        switchMap((params: ICheckEmailResult) => {
          let tip = params.tip;
          const email = params.email;
          const type = params.type;
          if (type === 'noggin' && environment.enCloudEnv !== 'development') {
            // Always use the Noggin idP for Noggin users
            tip = 'app/sso/saml/idp/noggin';
          }
          if (tip && type) {
            const namespace = environment.ns;
            const serviceUri = environment.host;
            const url = serviceUri + '/saml-sp/login'
                                   + '?ns=' + encodeURIComponent(namespace)
                                   + '&idp=' + encodeURIComponent(tip)
                                   + '&email=' + encodeURIComponent(email);
            this._window.location.href = url;
            return of(false);
          }
          return of(true);
        })
      );
  }
}
