import { Pipe, PipeTransform } from '@angular/core';
import { stripHtml } from '../../util/html-utils';

@Pipe({
  name: 'stripHtml'
})
export class StripHtmlPipe implements PipeTransform {

  transform(value: string): any {
    return stripHtml(value);
  }

}
