import { Component, OnInit } from '@angular/core';
import { ListDesignerService } from '../../../list-designer/list-designer.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Tip } from '../../../../data/models/types';
import { SideSheetService } from '../../../../side-sheet/side-sheet.service';
import { ToastService } from '../../../../shell/services/toast.service';
import { first } from 'rxjs/operators';
import { IList } from '../../../../models/list';
import { LoadingState } from '../../../../shared/constants';
import { ListSideSheetComponent } from '../../../list-designer/sidesheets/list-side-sheet/list-side-sheet.component';
import { ModulePackageService } from '../../module-package.service';

@Component({
  selector: 'app-list-create-side-sheet',
  templateUrl: './list-create-side-sheet.component.html',
  styleUrls: ['./list-create-side-sheet.component.scss']
})
export class ListCreateSideSheetComponent implements OnInit {

  moduleTip: Tip;
  isModuleUnlocked: boolean;

  listForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    description: new FormControl('')
  });

  loadingState: LoadingState = LoadingState.inProgress;
  loadingStates = LoadingState;

  done: () => void = () => {};

  constructor(
    private listService: ListDesignerService,
    private modulePackageService: ModulePackageService,
    private sideSheetService: SideSheetService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this
      .modulePackageService
      .isModuleUnlocked(this.moduleTip)
      .pipe(first())
      .subscribe((isModuleUnlocked: boolean) => {
        this.isModuleUnlocked = isModuleUnlocked;
        this.loadingState = LoadingState.loaded;
      });
  }

  create() {
    const newList: IList = {
      name: this.listForm.value.name,
      description: this.listForm.value.description,
      items: [],
      values: []
    };

    this.loadingState = LoadingState.inProgress;
    this.listService.createList(newList, this.moduleTip).pipe(
      first()
    ).subscribe(
      ((newListTip: Tip) => {
        this.onListCreateComplete(newListTip);
      }),
      (error) => {
        this.toastService.showErrorToast(error.message);
        this.loadingState = LoadingState.failed;
      }
    );
  }

  private onListCreateComplete(newListTip: Tip) {
    this.sideSheetService.pop();
    this.done();
    this.toastService.showSuccessToast('List successfully created');

    const sheetRef = this.sideSheetService.push(ListSideSheetComponent);
    const componentInstance: ListSideSheetComponent = sheetRef.componentInstance;
    componentInstance.moduleTip = this.moduleTip;
    componentInstance.listTip = newListTip;
    componentInstance.isModuleUnlocked = this.isModuleUnlocked;
  }
}
