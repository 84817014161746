import dataConstants from './constants';
import { cloneDeep } from 'lodash';
import * as i0 from "@angular/core";
var EnoCacheService = /** @class */ (function () {
    function EnoCacheService() {
        this._enos = new Map();
    }
    EnoCacheService.prototype.hasEno = function (tip, branch) {
        if (branch === void 0) { branch = dataConstants.BRANCH_MASTER; }
        return this._enos.has(this._getKey(tip, branch));
    };
    EnoCacheService.prototype.getEno = function (tip, branch) {
        if (branch === void 0) { branch = dataConstants.BRANCH_MASTER; }
        return this._enos.get(this._getKey(tip, branch));
    };
    // If branch argument is given, it has higher priority than eno's actual branch
    // Because we cache enos on the requested branch in op/pull
    EnoCacheService.prototype.setEno = function (tip, eno, branch) {
        if (!eno.source) {
            // Do not store acknowledgment
            return;
        }
        this._enos.set(this._getKey(tip, branch || eno.getBranch()), cloneDeep(eno));
    };
    EnoCacheService.prototype._getKey = function (tip, branch) {
        return tip + ":" + branch;
    };
    EnoCacheService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EnoCacheService_Factory() { return new EnoCacheService(); }, token: EnoCacheService, providedIn: "root" });
    return EnoCacheService;
}());
export { EnoCacheService };
