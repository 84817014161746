import { ReplaySubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
// inspired from https://codeburst.io/lazy-loading-external-javascript-libraries-in-angular-3d86ada54ec7
var LoadScriptService = /** @class */ (function () {
    function LoadScriptService(document) {
        this.document = document;
        this._loadedLibraries = {};
    }
    LoadScriptService.prototype.load = function (url) {
        var _this = this;
        if (this._loadedLibraries[url]) {
            return this._loadedLibraries[url].asObservable();
        }
        this._loadedLibraries[url] = new ReplaySubject();
        var script = this.document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = url;
        script.onload = function () {
            _this._loadedLibraries[url].next();
            _this._loadedLibraries[url].complete();
        };
        this.document.body.appendChild(script);
        return this._loadedLibraries[url].asObservable();
    };
    LoadScriptService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoadScriptService_Factory() { return new LoadScriptService(i0.ɵɵinject(i1.DOCUMENT)); }, token: LoadScriptService, providedIn: "root" });
    return LoadScriptService;
}());
export { LoadScriptService };
