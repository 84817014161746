import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { first, map, switchMap, filter } from 'rxjs/operators';
import { uniq, flatten, head, sortBy } from 'lodash';

import { flatModuleScheme, IFlatModule, IModule, IModuleConfigCount } from '../../models/module';
import { QueryService, IQueryOption } from '../../data/query.service';
import { ObjectService } from '../../data/object-service/object.service';
import { EnoService } from '../../data/eno.service';
import { Tip } from '../../data/models/types';
import { IProcessResponse, ProcessService } from '../../data/process.service';
import { FormulaResult, FormulaService } from '../../data/formula.service';

export interface IModuleSummary {
  $tip: string;
  name: string;
  description?: string;
  solutions?: {
    name: string;
    color: string;
  }[];
  solutionNames?: string;
}

export interface IModuleFieldValue {
  $tip: string;
  name: string;
}

export interface IModuleAndConfigs {
  $tip: string;
  moduleName: string;
  modifiedDate: string;
  name: string;
  type: string;
}

interface IAddTipToFlatModuleField {
  moduleTip: string;
  moduleField: string;
  tip: string;
}

export interface IModuleManagerSideSheet {
  moduleTip: Tip;
}

export enum MODULE_POLICY_TYPE {
  INSTANCE = 'app/module:instance-policy',
  TYPE = 'app/module:type-policy',
  WORKFLOW_PROCESS = 'app/module:workflow-process-policy'
}

@Injectable({
  providedIn: 'root'
})
export class ModuleService {
  constructor(
    private queryService: QueryService,
    private objectService: ObjectService,
    private enoService: EnoService,
    private processService: ProcessService,
    private formulaService: FormulaService
  ) { }

  getModules(offset: number = 0, limit?: number): Observable<IModuleSummary[]> {
    return this.queryService
      .execute1dArray(
        'eim/query/get-all-modules',
        {
          dimensionOptions: [{
            label: 'Tip dimension',
            formula: 'TIP()',
            sortby: ['FIELD("app/module:name")'],
            sortdir: ['asc'],
            offset,
            limit
          }]
        }
      )
      .pipe(map(modules => modules.map(formatToModuleSummary)));
  }

  getModulesAndConfigs(modules: Tip[], offset: number = 0, limit?: number): Observable<IModuleAndConfigs[]> {
    const options: IQueryOption = {
      dimensionOptions: [{
        label: 'Tip dimension',
        formula: 'TIP()',
        sortby: [],
        sortdir: ['asc'],
        offset,
        limit
      }],
      vars: { 'Module tips': modules }
    };

    return this.queryService
      .execute1dArray('eim/query/get-module-config', options)
      .pipe(
        map(results => {
          const moduleAndConfigs: IModuleAndConfigs[] = sortBy(
            flatten(
              results
                .map(result => {
                  return result
                    .configTitles
                    .map((_, i) => ({
                      $tip: head(result.$tip), // module tip
                      moduleName: head(result.title),
                      modifiedDate: result.configModifiedDate[i],
                      name: result.configTitles[i],
                      type: result.configType[i]
                    }));
                })
            ),
            ['moduleName', 'type', 'name']
          );

          return moduleAndConfigs;
        })
      );
  }

  getBlacklistedModuleFieldValues(
    { moduleTip, moduleField, offset = 0, limit }:
      { moduleTip: Tip, moduleField: string, offset?: number, limit?: number }
  ): Observable<IModuleSummary[]> {
    return this.queryService.execute1dFirst<IModuleSummary>(
      `eim/query/get-module-${moduleField}`,
      {
        vars: {
          'Module tip': [moduleTip]
        },
        dimensionOptions: [{
          label: 'Tip dimension',
          formula: 'TIP()',
          offset,
          limit,
          sortby: ['TITLE()'],
          sortdir: ['asc']
        }],
        extraFilters: [{
          label: 'Is in blacklist of provided module',
          formula: `INARRAY(FIELD("app/module:blacklist", VAR("Module tip")), TIP())`
        }]
      }
    );
  }

  getModuleFieldValues(
    moduleTip: Tip,
    moduleField: string,
    offset: number = 0,
    limit?: number
  ): Observable<IModuleSummary[]> {
    return this.queryService.execute1dFirst<IModuleSummary>(
      `eim/query/get-module-${moduleField}`,
      {
        vars: {
          'Module tip': [moduleTip]
        },
        dimensionOptions: [{
          label: 'Tip dimension',
          formula: 'TIP()',
          offset,
          limit,
          sortby: ['TITLE()'],
          sortdir: ['asc']
        }]
      }
    );
  }

  getModule(moduleTip: string): Observable<IModule> {
    /** @oprime - The following is deliberately commented out so we can revert when saving modules to branches */

    // return this.objectService.getObject<IFlatModule>(moduleTip, flatModuleScheme).pipe(
    //   first(),
    //   switchMap(moduleInMaster => {
    //     return this.objectService.getObject<IModule>(moduleTip, moduleScheme, moduleInMaster.branch);
    //   }),
    //   map(module => setUndefinedFieldsToEmptyArrays<IModule>(module))
    // );
    return this.objectService.getObject<IModule>(moduleTip, flatModuleScheme).pipe(
      map(module => setUndefinedFieldsToEmptyArrays<IModule>(module))
    );
  }

  updateModule(
    { tip, name, description, solutions, unlockModule = false }:
      { tip: Tip, name: string, description: string, solutions: Tip[], unlockModule?: boolean }
  ): Observable<void> {
    const processVars = {
      'Tip': [tip],
      'Name': [name],
      'Description': [description],
      'Solutions': solutions,
      'Unlock module': [unlockModule]
    };

    return this
      .processService
      .start(
        'eim/process/module/update-module',
        processVars
      )
      .pipe(
        filter(({ finished }: { finished: boolean }) => Boolean(finished)),
        switchMap((processResponse: IProcessResponse) => {
          const isCoreModule = processResponse.vars['Is core module'];
          return isCoreModule ? throwError('Unable to update core module') : of(undefined);
        })
      );
  }

  getFlatModule(moduleTip: string, useCache?: null | true | false): Observable<IFlatModule> {
    return this.objectService.getObject<IFlatModule>(moduleTip, flatModuleScheme, undefined, undefined, useCache).pipe(

      /** @oprime - The following is deliberately commented out so we can revert when saving modules to branches */

      // first(),
      // switchMap(moduleInMaster => {
      //   return this.objectService.getObject<IFlatModule>(moduleTip, flatModuleScheme, moduleInMaster.branch);
      // }),
      map(module => setUndefinedFieldsToEmptyArrays<IFlatModule>(module))
    );
  }

  getModuleTallies(moduleTip: Tip): Observable<IModuleConfigCount> {
    return this
      .queryService
      .execute1dArray(
        'eim/query/get-module-tallies',
        {
          dimensionOptions: [{
            label: 'Tip dimension',
            formula: 'TIP()',
            sortby: ['TYPE(TIP())'],
            sortdir: ['asc'],
            offset: 0
          }],
          vars: { Module: [moduleTip] }
        }
      )
      .pipe(
        map(results => {
          const count = {
            objectTypes: 0,
            lists: 0,
            queries: 0,
            dashboards: 0,
            workflows: 0,
            workflowPanels: 0,
            forms: 0,
            planTemplates: 0,
            messageEmailTemplates: 0,
            messageSmsTemplates: 0,
            charts: 0,
            mapDataSources: 0,
            mapLayers: 0,
            maps: 0,
            sequences: 0,
            riskMatrices: 0,
            messageVoiceTemplates: 0,
            printTemplates: 0,
            printTemplateUsages: 0
          };

          results.forEach(result => {
            switch (head(result.$type)) {
              case 'app/object-type': count.objectTypes += 1; break;
              case 'app/list': count.lists += 1; break;
              case 'query': count.queries += 1; break;
              case 'app/dashboard': count.dashboards += 1; break;
              case 'app/workflow': count.workflows += 1; break;
              case 'app/workflow-panel': count.workflowPanels += 1; break;
              case 'app/form': count.forms += 1; break;
              case 'app/plan': count.planTemplates += 1; break;
              case 'app/email/outbound-template': count.messageEmailTemplates += 1; break;
              case 'app/sms/outbound-template': count.messageSmsTemplates += 1; break;
              case 'app/chart': count.charts += 1; break;
              case 'app/map': count.maps += 1; break;
              case 'app/map/layer': count.mapLayers += 1; break;
              case 'app/map/source': count.mapDataSources += 1; break;
              case 'app/sequence': count.sequences += 1; break;
              case 'app/risk-matrix': count.riskMatrices += 1; break;
              case 'app/voice-message/outbound-template': count.messageVoiceTemplates += 1; break;
              case 'app/print-template': count.printTemplates += 1; break;
              case 'app/print-template-usage': count.printTemplateUsages += 1; break;
              default: break;
            }
          });

          return count;
        })
      );
  }

  getModulePolicy(moduleTip: string, policyType: MODULE_POLICY_TYPE): Observable<Tip> {
    return this.formulaService
      .evaluate(`FIELD("${policyType}")`, moduleTip)
      .pipe(map((formulaResult: FormulaResult) => formulaResult[0]));
  }

  updateFlatModule(module: IFlatModule): Observable<boolean> {
    return this.objectService.setObject(module, flatModuleScheme).pipe(map(
      () => {
        return true;
      }
    ));
  }

  addTipToFlatModuleField({ moduleTip, moduleField, tip }: IAddTipToFlatModuleField): Observable<boolean> {
    return this.getFlatModule(moduleTip).pipe(
      first(),
      switchMap((module: IFlatModule) => {
        module[moduleField] = uniq([...module[moduleField], tip]);
        return this.updateFlatModule(module);
      })
    );
  }

  createModule(name: string, description: string): Observable<Tip | never> {
    const processVars = {
      'Name': [name],
      'Description': [description]
    };

    return this.processService
      .start('eim/process/module/create-module', processVars)
      .pipe(
        filter(({ finished }: { finished: boolean }) => Boolean(finished)),
        switchMap((processResponse: IProcessResponse) => {
          const moduleTip: Tip | null = processResponse.vars['Module']
            ? processResponse.vars['Module'][0]
            : null;

          if (!moduleTip) {
            return throwError('Failed to create module');
          }

          return of(moduleTip);
        })
      );
  }

  publishModule(module: IModule): Observable<boolean> {
    return this.enoService.mergeBranch(module.branch);
  }

  deleteModuleConfig(tips: Tip[]): Observable<never | void> {
    const processTip = 'eim/process/module/delete-module-config';
    const processVars = { 'Config tips': tips };

    return this
      .processService
      .start(processTip, processVars)
      .pipe(
        filter(({ finished }: { finished: boolean }) => Boolean(finished)),
        switchMap((processResponse: IProcessResponse) => {
          const isModuleConfigPatched: boolean | null = head(processResponse.vars['Is module config patched']);

          if (!isModuleConfigPatched) {
            return throwError('Failed to patch module config');
          }

          return of(undefined);
        })
      );
  }

  deleteModule(tip: Tip): Observable<true | never | void> {

    return this.getFlatModule(tip, true)
      .pipe(
        switchMap((flatModule: IFlatModule) => {
          // -!-!-!- DELETE BACKUP OPTION AFTER MIGRATION - EIM-2691 -!-!-!-
          if (
            !flatModule.instancePolicy ||
            !flatModule.typePolicy ||
            !flatModule.workflowProcessPolicy ||
            !flatModule.labels) {
            return this.objectService.deleteObject({ $tip: tip });
          }

          const vars = { 'Module': [tip] };

          return this.processService
            .start('eim/process/module/delete-module', vars)
            .pipe(
              filter(({ finished }: { finished: boolean }) => Boolean(finished)),
              switchMap((processResponse: IProcessResponse) => {
                const isDeleted: boolean = !!processResponse.vars['Is deleted'] && !!processResponse.vars['Is deleted'][0];

                if (!isDeleted) {
                  return throwError('Failed to delete module');
                }

                return of(undefined);
              })
            );
        })
      );
  }
}

function formatToModuleSummary(module): IModuleSummary {
  return {
    $tip: head(module.$tip),
    name: head(module.name),
    description: head(module.description),
    solutions: module.solutionNames.length
      ? sortBy(
        module.solutionNames.map(
          (name, i) => ({ name, color: module.solutionColors[i] })
        ),
        ['name']
      )
      : []
  };
}

// tslint:disable-next-line: cyclomatic-complexity
function setUndefinedFieldsToEmptyArrays<T extends IFlatModule | IModule>(module: T): T {
  module.objectTypes = module.objectTypes || [];
  module.lists = module.lists || [];
  module.workflows = module.workflows || [];
  module.queries = module.queries || [];
  module.dashboards = module.dashboards || [];
  module.workflowPanels = module.workflowPanels || [];
  module.forms = module.forms || [];
  module.planTemplates = module.planTemplates || [];
  module.messageEmailTemplates = module.messageEmailTemplates || [];
  module.messageSmsTemplates = module.messageSmsTemplates || [];
  module.charts = module.charts || [];
  module.mapDataSources = module.mapDataSources || [];
  module.mapLayers = module.mapLayers || [];
  module.maps = module.maps || [];
  module.sequences = module.sequences || [];
  module.riskMatrices = module.riskMatrices || [];
  module.messageVoiceTemplates = module.messageVoiceTemplates || [];
  module.printTemplates = module.printTemplates || [];
  module.printTemplateUsages = module.printTemplateUsages || [];
  return module;
}
