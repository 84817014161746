import * as tslib_1 from "tslib";
import { CompositeNodeConverter } from './CompositeNodeConverter';
import { Stringify } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { isEmpty } from 'lodash';
import { createConnection, createFormulaFromStringInterpolation, createSetVarNode, createSubProcessNode, createSwitchNode } from './utils/create-node-utils';
import * as i0 from "@angular/core";
var ExportEnoConverterService = /** @class */ (function (_super) {
    tslib_1.__extends(ExportEnoConverterService, _super);
    function ExportEnoConverterService() {
        return _super.call(this) || this;
    }
    ExportEnoConverterService.prototype.convert = function (nodeData) {
        var _a = nodeData.fields, filename = _a.filename, queryAndFilter = _a.queryAndFilter, securityPolicy = _a.securityPolicy, typeTip = _a.typeTip, variableToSetKey = _a.variableToSetKey;
        var vars = {
            'Type tip': typeTip,
            'Var key': variableToSetKey,
            Filename: createFileName(filename, typeTip),
            'Query tip': queryAndFilter.queryTip,
            'Query and filter options JSON': JSON.stringify(queryAndFilter),
            'Security policy': securityPolicy
        };
        // create nodes
        var setVarNode = createSetVarNode(vars, nodeData.tip);
        var exportEnosSubProcess = createSubProcessNode('eim/process/workflow/export-enos', vars);
        var switchNode = createSwitchNode({ name: 'VAR', args: ['Status'] });
        // connect setVar "done" to "exportEnosSubProcess"
        var setVarDone = createConnection('done', exportEnosSubProcess);
        setVarNode.connections.push(setVarDone);
        // connect sub process "done" to "switchNode"
        var exportEnosSubProcessDone = createConnection('done', switchNode);
        exportEnosSubProcess.connections.push(exportEnosSubProcessDone);
        var nodesAndLink = {
            nodes: [
                setVarNode,
                exportEnosSubProcess,
                switchNode
            ],
            links: [
                setVarDone,
                exportEnosSubProcessDone
            ]
        };
        this.populateOutgoingLinks(nodeData).forEach(function (link) {
            switchNode.connections.push(link);
            nodesAndLink.links.push(link);
        });
        return nodesAndLink;
    };
    ExportEnoConverterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ExportEnoConverterService_Factory() { return new ExportEnoConverterService(); }, token: ExportEnoConverterService, providedIn: "root" });
    return ExportEnoConverterService;
}(CompositeNodeConverter));
export { ExportEnoConverterService };
function createFileName(filename, typeTip) {
    if (isEmpty(filename) || isEmpty(filename.html)) {
        return createDefaultFileNameFromObjectType(typeTip);
    }
    return createFormulaFromStringInterpolation(filename);
}
export function createDefaultFileNameFromObjectType(typeTip) {
    return Stringify({
        name: 'CONCAT',
        args: [
            'export-',
            {
                name: 'TITLE',
                args: [
                    {
                        name: 'REFERENCES',
                        args: [
                            'app/object-type:type',
                            typeTip
                        ]
                    }
                ]
            },
            '-UTC-',
            {
                name: 'FORMAT_DATETIME',
                args: [{ name: 'NOW', args: [] }, 'Ymd-His']
            },
        ]
    });
}
