import { Injectable } from '@angular/core';
import { NodeConverterBase } from './NodeConverterBase';
import { IProcessNodeIf } from '../../../models/process';
import { IDecisionEditor } from '../node-editors/decision-editor/decision-editor.component';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import dataConstants from '../../../data/constants';

@Injectable({
  providedIn: 'root'
})
export class DecisionNodeConverterService extends NodeConverterBase {
  type = 'processnode/logic/if';
  processNodeType = 'processnodetype/logic/if';

  public convert(nodeData: IDecisionEditor): IProcessNodeIf {
    if (!this.isValid(nodeData)) {
      throw new InvalidWorkflowNodeDataError(`Please correct invalid data on decision node [${nodeData.fields.title}]`, nodeData);
    }

    return {
      $type: this.type,
      $security: dataConstants.SECURITY.INSTANCE_USER_ADMIN,
      type: this.processNodeType,
      connections: [],
      point: [0, 0],
      key: nodeData.tip,
      expression: nodeData.fields.expression
    };
  }

  private isValid(nodeData: IDecisionEditor): boolean {
    const expression = nodeData.fields.expression;

    return !(expression === '' || expression === null);
  }

  constructor() {
    super();
  }
}
