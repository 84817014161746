import { DataTypes, IObjectScheme } from '../data/models/scheme';
import { IObject } from '../data/models/object';
import { IUser, IUserReadOnly, userScheme } from './user';

export { ITeam, teamScheme, ITeamRole, teamRoleScheme, teamSummaryScheme, teamRoleSummaryScheme, teamAndTeamRoleSummaryScheme };

interface ITeamRole extends IObject {
  name: string;
  description: string;
  users: IUserReadOnly[];
}

const teamRoleScheme: IObjectScheme = {
  'app/team-role:name': {},
  'app/team-role:description': {},
  'app/team-role:users': {
    type: DataTypes.objectArray,
    scheme: userScheme
  }
};

const teamRoleSummaryScheme: IObjectScheme = {
  'app/team-role:name': {},
  'app/team-role:description': {},
  'app/team-role:users': { type: DataTypes.stringArray }
};

interface ITeam extends IObject {
  name: string;
  description: string;
  status: string;
  roles: ITeamRole[];
  containsUser?: boolean;
}

const teamScheme: IObjectScheme = {
  'app/team:name': {},
  'app/team:description': {},
  'app/team:status': {},
  'app/team:roles': {
    type: DataTypes.objectArray,
    scheme: teamRoleScheme
  }
};

const teamSummaryScheme: IObjectScheme = {
  'app/team:name': {},
  'app/team:description': {},
  'app/team:status': {},
  'app/team:roles': { type: DataTypes.stringArray }
};

const teamAndTeamRoleSummaryScheme: IObjectScheme = {
  'app/team:name': {},
  'app/team:description': {},
  'app/team:status': {},
  'app/team:roles': {
    type: DataTypes.objectArray,
    scheme: teamRoleSummaryScheme
  }
};
