import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { MeService } from '../shell/services/me.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuardService implements CanActivate {

  constructor(private meService: MeService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    return this.meService.isAdmin().pipe(
      switchMap((isAdmin) => {
        if (!isAdmin) {
          this.router.navigate(['/not-authorized']);
        }
        return of(isAdmin)
      })
    )


  }

}
