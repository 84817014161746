import { JwtHelperService } from '@auth0/angular-jwt';
import { Tip } from '../data/models/types';
import { get } from 'lodash';

export interface IJwtTokenPayload {
  user: Tip | null;
  profile: Tip | null;
  sessionId: Tip | null;
  sessionExpiry: Date | null;
  namespace: string | null;
  inactivityTimeout: number | null;
}

const payloadDefaults = (): IJwtTokenPayload => ({
  user: null,
  profile: null,
  sessionId: null,
  namespace: null,
  inactivityTimeout: null,
  sessionExpiry: null
});

const jwtHelperService = new JwtHelperService();
export function decodeJWT(jwtToken: string): IJwtTokenPayload {

  if (!jwtToken) {
    return payloadDefaults();
  }

  let decoded;
  try {
    decoded = jwtHelperService.decodeToken(atob(jwtToken));
  } catch {
    return payloadDefaults();
  }

  const sessionExpiry = extractDateFromExpOrReturnNull(get(decoded, 'exp', null));

  if (lessThan30MinutesUntilExpiry(sessionExpiry)) {
    // return null for tokens < 30 minutes so people get logged ou
    return payloadDefaults();
  }

  return {
    user: get(decoded, 'customPayload.userTip', null),
    profile: get(decoded, 'customPayload.profileTip', null),
    sessionId: get(decoded, 'sessionId', null),
    namespace: get(decoded, 'namespace', null),
    sessionExpiry,
    inactivityTimeout: returnNumberOrNull(get(decoded, 'customPayload.inactivityTimeout', null))
  };
}

function returnNumberOrNull(input): number | null {
  if (typeof input !== 'number') {
    return null;
  }
  return input;
}

function extractDateFromExpOrReturnNull(input): Date | null {
  if (typeof input !== 'number') {
    return null;
  }

  return new Date(input * 1000);
}

function lessThan30MinutesUntilExpiry(sessionExpiry) {
  if (!(sessionExpiry instanceof Date)) {
    return false;
  }

  const thirtyMinutesMs = 30 * 60 * 1000;
  const inThirtyMinutesMs = Date.now() + thirtyMinutesMs;
  return sessionExpiry.valueOf() < inThirtyMinutesMs;
}
