import { ConfigOption } from '@ngx-formly/core';
import { FormlyWrapperFormFieldComponent } from './wrappers/form-field.wrappers';
import { FormlyFieldInputComponent } from './types/input';
import { FormlyFieldMultiLineTextComponent } from './types/multi-line-text';
import { FormlyFieldSectionHeaderComponent } from './types/section-header';
import { FormlyFieldOptionListComponent } from './types/option-list';
import { FormlyFieldRichTextComponent } from './types/rich-text';
import { FormlyFieldTextContent } from './types/text-content';
import { FormlyFieldDateTimeComponent } from './types/date-time';
import { FormlyFieldCheckboxComponent } from './types/checkbox';
import { FormlyFieldConditionalFieldSelectorComponent } from './types/conditional-field-selector';
import { FormlyFieldFileUploadComponent } from './types/file-upload';
import { FormlyWrapperNogginSectionComponent } from './wrappers/noggin-form-section.wrappers';
import { FormlyFieldListItemsSelectComponent } from './types/list-items-select';
import { FormlyFieldObjectChooserComponent } from './types/object-chooser';
import { FormlyFieldQueryChooserComponent } from './types/query-chooser';
import { FormlyFieldChartContentComponent } from './types/chart-content';
import { FormlyFieldChartChooserComponent } from './types/chart-chooser';
import { FormlyFieldGeoChooserComponent } from './types/geo-chooser';
import { FormlyFieldTableContentComponent } from './types/table-content';
import { FormlyFieldAddressChooserComponent } from './types/address-chooser';
import { FormlyFieldAssignToComponent } from './types/assign-to';
import { FormlyBooleanChooserComponent } from './types/boolean-chooser';
import { FormlyFieldTypeChooserComponent } from './types/type-chooser';
import { FormlyFieldObjectTypeChooserComponent } from './types/object-type-chooser';
import { FormlyFieldSimpleQueryChooserComponent } from './types/simple-query-chooser';
import { FormlyFieldTableColumnsComponent } from './types/table-columns';
import { FormlyFieldHiddenInputComponent } from './types/hidden-inputs';
import { FormlyFieldStringInterpolateTextComponent } from './types/string-interpolate-text';
import { FormlyFieldStringInterpolateRichTextComponent } from './types/string-interpolate-rich-text';
import { FormlyFieldNumberFormatComponent } from './types/number-format';
import { FormlyFieldNumberFormatPreviewComponent } from './types/number-format-preview';
import { FormlyFieldToggleComponent } from './types/toggle';
import { NogginValidators } from '../../form/noggin-form-validators';
import { Validators } from '@angular/forms';
import { FormlyFieldStringEncryptionComponent } from './types/string-encryption';
import { FormlyFieldSubObjectChooserComponent } from './types/sub-object-chooser';
import { FormlyFieldColorPickerComponent } from './types/color-picker';
import { FormlyFieldRecipientChooserComponent } from './types/recipient-chooser';
import { FormlyWrapperSideSheetSectionComponent } from './wrappers/side-sheet-section.wrapper';

/*
 SECTION
*/

export const SECTION_HEADER_FORMLY_CONFIG = {
  name: 'section-header',
  component: FormlyFieldSectionHeaderComponent,
  wrappers: ['form-field']
};

export const FORMLY_FORM_WRAPPER_SIDE_SHEET_SECTION_COMPONENT = {
  name: 'side-sheet-section-wrapper',
  component: FormlyWrapperSideSheetSectionComponent
};

/*
 CONTENT
*/

export const TEXT_CONTENT_FORMLY_CONFIG = {
  name: 'text-content',
  component: FormlyFieldTextContent ,
  wrappers: ['form-field']
};

export const CHART_CONTENT_FORMLY_CONFIG = {
  name: 'chart-content',
  component: FormlyFieldChartContentComponent,
  wrappers: ['form-field']
};

export const TABLE_CONTENT_FORMLY_CONFIG = {
  name: 'table-content',
  component: FormlyFieldTableContentComponent,
  wrappers: ['form-field']
};

/*
 FORM FIELDS
*/

export const OPTION_LIST_FORMLY_CONFIG = {
  name: 'option-list',
  component: FormlyFieldOptionListComponent,
  wrappers: ['form-field']
};

export const RICH_TEXT_FORMLY_CONFIG = {
  name: 'rich-text',
  component: FormlyFieldRichTextComponent,
  wrappers: ['form-field']
};

export const HIDDEN_INPUT_FORMLY_CONFIG = {
  name: 'hidden-input',
  component: FormlyFieldHiddenInputComponent,
  wrappers: ['form-field']
};

export const DATE_TIME_FORMLY_CONFIG = {
  name: 'date-time',
  component: FormlyFieldDateTimeComponent,
  wrappers: ['form-field']
};

export const MULTI_LINE_TEXT_FORMLY_CONFIG = {
  name: 'multi-line-text',
  component: FormlyFieldMultiLineTextComponent,
  wrappers: ['form-field']
};

export const CHECKBOX_FORMLY_CONFIG = {
  name: 'checkbox',
  component: FormlyFieldCheckboxComponent,
  wrappers: ['form-field']
};

export const TOGGLE_FORMLY_CONFIG = {
  name: 'toggle',
  component: FormlyFieldToggleComponent,
  wrappers: ['form-field']
};

export const BOOLEAN_CHOOSER_FORMLY_CONFIG = {
  name: 'boolean-chooser',
  component: FormlyBooleanChooserComponent,
  wrappers: ['form-field']
};

export const FILE_UPLOAD_FORMLY_CONFIG = {
  name: 'file-upload',
  component: FormlyFieldFileUploadComponent,
  wrappers: ['form-field']
};

export const CONDITIONAL_FIELD_SELECTOR_FORMLY_CONFIG = {
  name: 'conditional-selector',
  component: FormlyFieldConditionalFieldSelectorComponent,
  wrappers: ['form-field']
};

export const LIST_ITEMS_SELECT_FORMLY_CONFIG = {
  name: 'list-items-select',
  component: FormlyFieldListItemsSelectComponent,
  wrappers: ['form-field']
};

export const OBJECT_CHOOSER_FORMLY_CONFIG = {
  name: 'object-chooser',
  component: FormlyFieldObjectChooserComponent,
  wrappers: ['form-field']
};

export const SUB_OBJECT_CHOOSER_FORMLY_CONFIG = {
  name: 'sub-object-chooser',
  component: FormlyFieldSubObjectChooserComponent,
  wrappers: ['form-field']
};

export const OBJECT_TYPE_CHOOSER_FORMLY_CONFIG = {
  name: 'object-type-chooser',
  component: FormlyFieldObjectTypeChooserComponent,
  wrappers: ['form-field']
};

export const SIMPLE_QUERY_CHOOSER_FORMLY_CONFIG = {
  name: 'simple-query-chooser',
  component: FormlyFieldSimpleQueryChooserComponent,
  wrappers: ['form-field']
};

export const TABLE_COLUMNS_FORMLY_CONFIG = {
  name: 'table-columns',
  component: FormlyFieldTableColumnsComponent,
  wrappers: ['form-field']
};

export const INPUT_FORMLY_CONFIG = {
  name: 'input',
  component: FormlyFieldInputComponent,
  wrappers: ['form-field']
  /*defaultOptions: {
    validators: {
      validation: ['max-length', 'min-length']
    }
  },*/
};

export const NUMBER_FORMLY_CONFIG = {
  name: 'number',
  extends: 'input',
  defaultOptions: {
    validators: {
      validation: ['max', 'min']
    }
  }
};

export const PHONE_NUMBER_FORMLY_CONFIG = {
  name: 'phone-number',
  extends: 'input',
  defaultOptions: {
    validators: {
      validation: ['phone-number']
    }
  },
};

export const EMAIL_ADDRESS_FORMLY_CONFIG = {
  name: 'email-address',
  extends: 'input',
  defaultOptions: {
    validators: {
      validation: ['email']
    }
  },
};

export const WEB_ADDRESS_FORMLY_CONFIG = {
  name: 'web-address',
  extends: 'input',
  defaultOptions: {
    validators: {
      validation: ['uri']
    }
  }
};

export const GEO_CHOOSER_FORMLY_CONFIG = {
  name: 'geo-chooser',
  component: FormlyFieldGeoChooserComponent,
  wrappers: ['form-field']
};

export const ADDRESS_CHOOSER_FORMLY_CONFIG = {
  name: 'address-chooser',
  component: FormlyFieldAddressChooserComponent,
  wrappers: ['form-field']
};

/*
 INTERNAL FIELDS (used by the form editor)
*/

export const QUERY_CHOOSER_FORMLY_CONFIG = {
  name: 'query-chooser',
  component: FormlyFieldQueryChooserComponent,
  wrappers: ['form-field']
};

export const CHART_CHOOSER_FORMLY_CONFIG = {
  name: 'chart-chooser',
  component: FormlyFieldChartChooserComponent,
};

export const ASSIGN_TO_FORMLY_CONFIG = {
  name: 'assign-to',
  component: FormlyFieldAssignToComponent,
};

export const TYPE_CHOOSER_FORMLY_CONFIG = {
  name: 'app-formly-type-chooser',
  component: FormlyFieldTypeChooserComponent,
  wrappers: ['form-field']
};

export const RECIPIENT_CHOOSER_FORMLY_CONFIG = {
  name: 'formly-recipient-chooser',
  component: FormlyFieldRecipientChooserComponent,
  wrappers: ['form-field']
};

/*
 STRING INTERPOLATE FIELDS
*/

export const STRING_INTERPOLATE_TEXT_FORMLY_CONFIG = {
  name: 'string-interpolate-text',
  component: FormlyFieldStringInterpolateTextComponent,
  wrappers: ['form-field']
};

export const STRING_INTERPOLATE_RICH_TEXT_FORMLY_CONFIG = {
  name: 'string-interpolate-rich-text',
  component: FormlyFieldStringInterpolateRichTextComponent ,
  wrappers: ['form-field']
};

/*
 NUMBER FORMAT
*/

export const NUMBER_FORMAT_FORMLY_CONFIG = {
  name: 'number-format',
  component: FormlyFieldNumberFormatComponent,
  wrappers: []
};

export const NUMBER_FORMAT_PREVIEW_FORMLY_CONFIG = {
  name: 'number-format-preview',
  component: FormlyFieldNumberFormatPreviewComponent,
  wrappers: ['form-field']
};

/*
  ENCRYPTION
*/

export const STRING_ENCRYPTION_FORMLY_CONFIG = {
  name: 'string-encryption',
  component: FormlyFieldStringEncryptionComponent
};

/*
 WRAPPERS
*/

export const FORMLY_FORM_FIELD_WRAPPER_CONFIG = {
  name: 'form-field',
  component: FormlyWrapperFormFieldComponent
};

export const FORMLY_NOGGIN_SECTION_WRAPPER_CONFIG = {
  name: 'noggin-section',
  component: FormlyWrapperNogginSectionComponent
};

/*
 VALIDATORS
*/

export const FORMLY_VALIDATOR_PHONE_NUMBER = {
  name: 'phone-number',
  validation: NogginValidators.phoneNumber
};

export const FORMLY_VALIDATOR_URI = {
  name: 'uri',
  validation: NogginValidators.uri
};

export const FORMLY_VALIDATOR_EMAIL = {
  name: 'email',
  validation: Validators.email
};

export const FORMLY_VALIDATOR_MAX_LENGTH_INPUT = {
  name: 'max-length',
  validation: NogginValidators.maxLength
};

export const FORMLY_VALIDATOR_MIN_LENGTH_INPUT = {
  name: 'min-length',
  validation: NogginValidators.minLength
};

export const FORMLY_VALIDATOR_MAX_NUMBER = {
  name: 'max',
  validation: NogginValidators.maxNumber
};

export const FORMLY_VALIDATOR_MIN_NUMBER = {
  name: 'min',
  validation: NogginValidators.minNumber
};

export const FORMLY_COLOR_PICKER = {
  name: 'color-picker',
  component: FormlyFieldColorPickerComponent
};

export const BOOTSTRAP_FORMLY_CONFIG: ConfigOption = {
  types: [
    // section
    SECTION_HEADER_FORMLY_CONFIG,
    FORMLY_FORM_WRAPPER_SIDE_SHEET_SECTION_COMPONENT,
    // content
    TEXT_CONTENT_FORMLY_CONFIG,
    CHART_CONTENT_FORMLY_CONFIG,
    TABLE_CONTENT_FORMLY_CONFIG,
    // fields
    OPTION_LIST_FORMLY_CONFIG,
    RICH_TEXT_FORMLY_CONFIG,
    INPUT_FORMLY_CONFIG,
    HIDDEN_INPUT_FORMLY_CONFIG,
    DATE_TIME_FORMLY_CONFIG,
    MULTI_LINE_TEXT_FORMLY_CONFIG,
    NUMBER_FORMLY_CONFIG,
    CHECKBOX_FORMLY_CONFIG,
    BOOLEAN_CHOOSER_FORMLY_CONFIG,
    FILE_UPLOAD_FORMLY_CONFIG,
    CONDITIONAL_FIELD_SELECTOR_FORMLY_CONFIG,
    LIST_ITEMS_SELECT_FORMLY_CONFIG,
    OBJECT_CHOOSER_FORMLY_CONFIG,
    OBJECT_TYPE_CHOOSER_FORMLY_CONFIG,
    SIMPLE_QUERY_CHOOSER_FORMLY_CONFIG,
    TABLE_COLUMNS_FORMLY_CONFIG,
    PHONE_NUMBER_FORMLY_CONFIG,
    EMAIL_ADDRESS_FORMLY_CONFIG,
    WEB_ADDRESS_FORMLY_CONFIG,
    GEO_CHOOSER_FORMLY_CONFIG,
    ADDRESS_CHOOSER_FORMLY_CONFIG,
    SUB_OBJECT_CHOOSER_FORMLY_CONFIG,

    // internal fields (used by the form editor)
    QUERY_CHOOSER_FORMLY_CONFIG,
    CHART_CHOOSER_FORMLY_CONFIG,
    ASSIGN_TO_FORMLY_CONFIG,
    TYPE_CHOOSER_FORMLY_CONFIG,
    RECIPIENT_CHOOSER_FORMLY_CONFIG,

    // string interpolate fields
    STRING_INTERPOLATE_TEXT_FORMLY_CONFIG,
    STRING_INTERPOLATE_RICH_TEXT_FORMLY_CONFIG,

    // number format
    NUMBER_FORMAT_FORMLY_CONFIG,
    NUMBER_FORMAT_PREVIEW_FORMLY_CONFIG,

    // toggle config
    TOGGLE_FORMLY_CONFIG,
    // encryption
    STRING_ENCRYPTION_FORMLY_CONFIG,

    // color picker
    FORMLY_COLOR_PICKER
  ],
  wrappers: [
    FORMLY_FORM_FIELD_WRAPPER_CONFIG,
    FORMLY_NOGGIN_SECTION_WRAPPER_CONFIG
  ],
  validators: [
    FORMLY_VALIDATOR_MAX_NUMBER,
    FORMLY_VALIDATOR_MIN_NUMBER,
    FORMLY_VALIDATOR_MAX_LENGTH_INPUT,
    FORMLY_VALIDATOR_MIN_LENGTH_INPUT,
    FORMLY_VALIDATOR_EMAIL,
    FORMLY_VALIDATOR_PHONE_NUMBER,
    FORMLY_VALIDATOR_URI
  ]
};

