import { Tip } from '../../data/models/types';

interface ISuperType {
  $tip: Tip;
  label: string;
}

export const SUPER_TYPES = [
    { $tip: 'app/super-type/contact', label: 'All contacts' },
    { $tip: 'app/super-type/email-capable', label: 'All email recipients' },
    { $tip: 'app/super-type/sms-capable', label: 'All SMS recipients' },
    { $tip: 'app/super-type/org-structure', label: 'All organizational structures', isHierarchical: true },
    { $tip: 'app/super-type/control', label: 'All controls', isHierarchical: true },
    { $tip: 'app/super-type/risk', label: 'All risks', isHierarchical: true },
    { $tip: 'app/super-type/event', label: 'All events' },
    { $tip: 'app/super-type/log', label: 'All logs' },
    { $tip: 'app/super-type/asset', label: 'All assets' },
    { $tip: 'app/super-type/assignable', label: 'All assignable' },
    { $tip: 'app/super-type/report', label: 'All reports' },
    { $tip: 'app/super-type/inspection', label: 'All inspections' },
    { $tip: 'app/super-type/voice-message-capable', label: 'All voice message recipients' },
];

let superTypesMap = null;

export function getSuperTypesMap(): Map<Tip, ISuperType> {
  if (! superTypesMap) {
      superTypesMap = SUPER_TYPES.reduce((acc, superType) => {
          acc.set(superType.$tip, superType);
          return acc;
      }, new Map());
  }
  return superTypesMap;
}

export function isSuperType(tip: Tip): boolean {
  return getSuperTypesMap().has(tip);
}
