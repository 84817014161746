import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

import { SessionManagerService } from '../data/session-manager.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(
    private _authService: AuthService,
    private _sessionManagerService: SessionManagerService,
    private _router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this._sessionManagerService.getSessionInfo$().pipe(
      first(),
      switchMap(
        (sessionInfo) => {
          if (!sessionInfo.token || !sessionInfo.user || !sessionInfo.profile) {
            return of(false);
          }
          return this._authService.isAuthenticated();
        }
      ),
      switchMap((isAuthenticated: boolean) => {
        if (!isAuthenticated) {
          this._sessionManagerService.setOriginalRequestedDestination(state.url);
          this._router.navigate(['/signin']);
          return of(false);
        }
        this._sessionManagerService.clearOriginalRequestedDestination();
        return of(true);
      })
    );
  }
}
