import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-address-chooser',
  template: `
    <app-address-chooser-field
            [formControl]="formControl"
            [label]="to.label"
            [placeholder]="to.placeholder"
            [required]="to.required"
            [readonly]="to['readonly']"
            [mapTip]="to['mapTip']">
    </app-address-chooser-field>
  `,
})
export class FormlyFieldAddressChooserComponent extends FieldType {

}
