import { Component, Input, ChangeDetectorRef, Output, EventEmitter, OnInit, HostListener } from '@angular/core';

export enum ToggleType {
  NO_TOGGLE = 'no-toggle',
  SINGLE_CHECK = 'single-check',
  MULTI_CHECK = 'multi-check',
  DRAG = 'drag',
  NUMBER = 'number'
}

@Component({
  selector: 'app-side-sheet-list-item',
  templateUrl: './side-sheet-list-item.component.html',
  styleUrls: ['./side-sheet-list-item.component.scss']
})
export class SideSheetListItemComponent implements OnInit {
  @Input() toggleType: ToggleType = ToggleType.NO_TOGGLE;
  @Input() value?: boolean | number;
  @Input() disabled = false;
  @Input() hasChevron;
  @Input() hideUnderline = false;
  @Input() key: string;
  @Input() customCheckboxClass: string = null;
  @Output() toggle = new EventEmitter<string>();
  @Output() moreInfo = new EventEmitter<string>();
  @Output() navAway = new EventEmitter<string>();
  @Input() showMoreInfo: boolean | null;
  @Output() actionClickToolSection = new EventEmitter<string>();
  @Input() allowNavAway: boolean | null;
  @Input() hasToolSection: boolean | null;
  @Input() showHoverState = false;

  onToggle: () => void = () => {};

  ngOnInit(): void {
    if (this.hasChevron == null) {
      this.hasChevron = this.toggleType === ToggleType.NUMBER;
    }
  }

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  onClick(event: Event) {
    if (this.disabled) {
      event.stopPropagation();
      return;
    }
    if (this.toggle.observers.length) {
      this.toggle.emit(this.key);
    }
    this.onToggle();
  }

  onMoreInfo() {
    if (this.moreInfo.observers.length) {
      this.moreInfo.emit(this.key);
    }
  }

  detectChanges() {
    this.changeDetectorRef.detectChanges();
  }

  onNavAway(event) {
    event.stopImmediatePropagation();
    this.navAway.emit(this.key);
  }

  onClickToolSection(event) {
    event.stopImmediatePropagation();
    this.actionClickToolSection.emit(this.key);
  }
}
