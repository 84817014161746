import { FIELD_DATA_TYPE } from '../../models/field';
import { Formula, Tip } from '../../data/models/types';
import { CONTEXT_VAR_NAME } from '../../message/message-utils.service';

export enum MetaDataKey {
  thisObject = 'THIS_OBJECT',
  title = 'TITLE',
  createdDate = 'CREATED_DATE',
  lastModifiedDate = 'LAST_MODIFIED_DATE',
  objectStage = 'OBJECT_STAGE',
  personnel = 'PERSONNEL',
  urlLink = 'URL_LINK',
  type = 'TYPE',
  createdBy = 'CREATED_BY',
  lastModifiedBy = 'LAST_MODIFIED_BY'
}

export interface IMetaDataFormulas {
  key: MetaDataKey;
  label: string;
  datatype: FIELD_DATA_TYPE;
  formula: Formula;
  searchable: boolean;
  typerestrict?: Tip[];
  displayFormula?: Formula;
  sortFormula?: Formula;
  hasDrillDown?: boolean;
}

export const metaDataFormulas: IMetaDataFormulas[] = [
  {
    key: MetaDataKey.thisObject,
    label: 'This object',
    datatype: FIELD_DATA_TYPE.object,
    formula: 'TIP()',
    searchable: true
  },
  {
    key: MetaDataKey.title,
    label: 'Title',
    datatype: FIELD_DATA_TYPE.string,
    formula: 'TITLE()',
    sortFormula: 'META_FIELD("app/search-meta-fields/title")',
    searchable: true
  },
  // @todo Need to add MetaDataKey.summary.
  {
    key: MetaDataKey.title,
    label: 'Summary',
    datatype: FIELD_DATA_TYPE.string,
    formula: 'SUMMARY()',
    sortFormula: 'META_FIELD("app/search-meta-fields/summary")',
    searchable: true
  },
  {
    key: MetaDataKey.createdDate,
    label: 'Created date & time',
    datatype: FIELD_DATA_TYPE.datetime,
    formula: 'TO_DATETIME(CONCAT("@", FLOOR(SERVER_T("createdDate"))))',
    sortFormula: 'META_FIELD("app/search-meta-fields/created-date")',
    searchable: true
  },
  {
    key: MetaDataKey.lastModifiedDate,
    label: 'Last modified date & time',
    datatype: FIELD_DATA_TYPE.datetime,
    formula: 'TO_DATETIME(CONCAT("@", FLOOR(SERVER_T("modifiedDate"))))',
    sortFormula: 'META_FIELD("app/search-meta-fields/modified-date")',
    searchable: true
  },
  {
    key: MetaDataKey.objectStage,
    label: 'Object stage',
    datatype: FIELD_DATA_TYPE.string_i18n,
    formula: 'FIELD("app/object-stage:name", FIELD("app/object-stage-state:stage", REFERENCES("app/object-stage-state:object")))',
    searchable: false
  },
  {
    key: MetaDataKey.personnel,
    label: 'Personnel',
    datatype: FIELD_DATA_TYPE.object,
    typerestrict: ['app/personnel'],
    formula: 'REFERENCES("app/personnel:object")',
    displayFormula: 'TITLE(REFERENCES("app/personnel:object"))',
    searchable: false
  },
  {
    key: MetaDataKey.urlLink,
    label: 'URL link to this object',
    datatype: FIELD_DATA_TYPE.string,
    // tslint:disable-next-line:max-line-length
    formula: `CONCAT(FIELD("app/system-info:customer-uri", "app/system-info"), "/app/s/object-link/", TIP(), "/", VAR("${CONTEXT_VAR_NAME}"))`,
    searchable: false
  },
  {
    key: MetaDataKey.type,
    label: 'Type',
    datatype: FIELD_DATA_TYPE.string,
    formula: `FIELD("type/label", TYPE(TIP()))`,
    searchable: false
  },
  {
    key: MetaDataKey.createdBy,
    label: 'Created by',
    datatype: FIELD_DATA_TYPE.object,
    typerestrict: ['app/user'],
    formula: 'SERVER_T("createdBy")',
    searchable: false,
    hasDrillDown: true,
  },
  {
    key: MetaDataKey.lastModifiedBy,
    label: 'Last modified by',
    datatype: FIELD_DATA_TYPE.object,
    typerestrict: ['app/user'],
    formula: 'SERVER_T("modifiedBy")',
    searchable: false,
    hasDrillDown: true,
  },
];

let metaDataMap = null;

function getMetaDataMap(): Map<string, IMetaDataFormulas> {
  if (!metaDataMap) {
    metaDataMap = new Map();
    metaDataFormulas.forEach((formula) => {
      metaDataMap.set(formula.formula.replace(/,\s/g, ','), formula);
      if (formula.displayFormula) {
        metaDataMap.set(formula.displayFormula.replace(/,\s/g, ','), formula);
      }
      if (formula.sortFormula) {
        metaDataMap.set(formula.sortFormula.replace(/,\s/g, ','), formula);
      }
    });
  }

  return metaDataMap;
}

export function getMetaDataFormula(formulaStr): IMetaDataFormulas | null {
  const map = getMetaDataMap();

  return map.get(formulaStr.replace(/,\s/g, ',')) || null;
}
