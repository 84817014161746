import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatetimePickerComponent } from './datetime-picker.component';
import { DlDateTimePickerMomentModule } from 'angular-bootstrap-datetimepicker';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    DatetimePickerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    DlDateTimePickerMomentModule
  ],
  exports: [
    DatetimePickerComponent
  ],
})
export class DatetimePickerModule { }
