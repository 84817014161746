import { DataTypes, IObjectScheme } from '../data/models/scheme';
import { IObject } from '../data/models/object';
import { Tip } from '../data/models/types';

export interface ICellSettings {
    coordinates: {
        x: number;
        y: number;
    };
    label: string;
    color?: string;
    range?: {
        low: number;
        high: number;
    };
    scoreLabel?: string;
}

export interface IRiskMatrix extends IObject {
    title: string;
    objectType: Tip;
    filter: Tip;
    xAxis: Tip;
    yAxis: Tip;
    score?: Tip;
    isScoreBased?: boolean;
    cellSettings: ICellSettings[];
}

export const riskMatrixScheme: IObjectScheme = {
    'app/risk-matrix:title': { type: DataTypes.i18n },
    'app/risk-matrix:object-type': { type: DataTypes.string },
    'app/risk-matrix:filter': { type: DataTypes.string },
    'app/risk-matrix:x-axis': { type: DataTypes.string },
    'app/risk-matrix:y-axis': { type: DataTypes.string },
    'app/risk-matrix:score': { type: DataTypes.string },
    'app/risk-matrix:is-score-based': { type: DataTypes.boolean },
    'app/risk-matrix:cell-settings': { type: DataTypes.jsonArray }
};
