import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionManagerService } from '../data/session-manager.service';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthHttpClientService {

  constructor(private http: HttpClient, private sessionManager: SessionManagerService) { }

  get(url: string, options?) {
    return this.http.get(url, {...options, ...this.httpOptions()});
  }

  post<T>(url: string, body: any): Observable<T> {
    return this.http.post<T>(url, body, this.httpOptions());
  }

  delete<T>(url: string): Observable<T> {
    return this.http.delete<T>(url, this.httpOptions());
  }

  private httpOptions() {
    const token = this.sessionManager.getAuthToken();

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'en-namespace': environment.ns
    });

    return {
      headers
    };
  }
}
