import {
  Component,
  HostBinding,
  HostListener,
  OnInit,
  OnDestroy,
  Optional,
  ViewChild,
  ViewContainerRef,
  ChangeDetectorRef
} from '@angular/core';
import { of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { FieldWrapper } from '@ngx-formly/core';

import { FormEditingService } from '../../../settings/form-designer/services-core/form-editing.service';

@Component({
  selector: 'app-wrapper-form-field',
  template: `
    <ng-template #fieldComponent></ng-template>
  `,
  styleUrls: ['./form-field.wrappers.scss']
})
export class FormlyWrapperFormFieldComponent extends FieldWrapper implements OnInit, OnDestroy {
  isSelectedSub = new Subscription();

  @ViewChild('fieldComponent', { static: false, read: ViewContainerRef }) fieldComponent!: ViewContainerRef;

  @HostBinding('class.is-selected') isSelected = false;

  @HostBinding('attr.data-full-width') fullWidth = true;

  @HostListener('click') click() {
    if (!(this.form && this.field && this.field['designMode'])) {
      return;
    }

    this.formEditingService.selectFormField(this.field.key);
  }

  // formEditingService will only be present when designing the form
  constructor(
    private cdr: ChangeDetectorRef,
    @Optional() private formEditingService?: FormEditingService
  ) {
    super();
  }

  ngOnInit(): void {
    this.fullWidth = this.field['fullWidth'] || false;

    if (this.formEditingService) {
      this.isSelectedSub = this.formEditingService.currentlySelectedKey$.pipe(
        map((v) => {
          if (!this.field || !this.field.key) {
            return;
          }

          return v === this.field.key;
        })
      ).subscribe(
        (isSelected: boolean) => {
          this.isSelected = isSelected;
          this.cdr.markForCheck();
        }
      );
    } else {
      of(false);
    }
  }

  ngOnDestroy() {
    this.isSelectedSub.unsubscribe();
  }
}
