import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutes } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DragulaService } from 'ng2-dragula';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotificationAnimationType, SimpleNotificationsModule } from '@noggin/angular2-notifications';
import { MatSliderModule } from '@angular/material';
import { XRayInterceptor } from './xray.interceptor';
import { LearnComponent } from './learn/learn.component';
import { LOCAL_STORAGE_PROVIDER, SAVE_AS_PROVIDER, WINDOW_PROVIDER } from './dom-tokens';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { APIModule } from './api/api.module';
import { AngularPageVisibilityModule } from 'angular-page-visibility';
import { CookieService } from 'ngx-cookie-service';
import { ForumComponent } from './forum/forum.component';

@NgModule({
  declarations: [
    AppComponent,
    LearnComponent,
    ForumComponent,
  ],
  imports: [
    // --- START Browser and Browser Animation need to be in the root of the project
    BrowserModule,
    BrowserAnimationsModule,
    // --- END

    SimpleNotificationsModule.forRoot({
      position: ['top', 'center'],
      timeOut: 5000,
      showProgressBar: false,
      pauseOnHover: true,
      lastOnBottom: true,
      clickToClose: true,
      clickIconToClose: false,
      maxStack: 6,
      theClass: 'eim-toast',
      animate: NotificationAnimationType.FromTop
    }),
    // this is needed for range-slider field component, it is also necessary to import exact same module to range-slider-field
    MatSliderModule,
    APIModule,
    AppRoutes,
    AuthModule,
    HttpClientModule,
    AngularPageVisibilityModule,

    DeviceDetectorModule.forRoot()
  ],
  bootstrap: [AppComponent],
  providers: [
    DragulaService,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XRayInterceptor,
      multi: true
    },
    WINDOW_PROVIDER,
    LOCAL_STORAGE_PROVIDER,
    SAVE_AS_PROVIDER
  ]
})
export class AppModule {}
