import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProcessService } from '../data/process.service';
import { first, map } from 'rxjs/operators';
import { get } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class IsPasswordLoginService {

  constructor(private processService: ProcessService) {

  }

  checkEmailAddress$(emailAddress: string): Observable<boolean> {
    const vars = { 'email': [emailAddress] };

    return this.processService
      .start('eim/process/sso/is-password-login', vars)
      .pipe(
        first(),
        map((result) => {
          return get(result, 'vars.isPasswordLogin[0]', null);
        })
      );
  }
}
