import { II18nValues } from '../i18n/i18n-value/i18n-value';
import { IObject } from '../data/models/object';
import { IObjectScheme, DataTypes } from '../data/models/scheme';
import { Tip } from '../data/models/types';
import { INavConfig } from '../form/navigation-config-field/navigation-config';

export {
  IMenuItem,
  IMainMenuItem,
  ICreateMenuItem,
  IObjectTypeSetting,
  IProfile,
  profileScheme
};

interface IMenuItem {
  label: string | II18nValues;
}

interface IMainMenuItem extends IMenuItem {
  type: 'folder' | 'divider' | 'navConfig';
  navConfig?: INavConfig;
  childMenu?: IMainMenuItem[];
  openInNew?: boolean;
  icon?: string;
}

interface ICreateMenuItem extends IMenuItem {
  navConfig: INavConfig;
}

interface IObjectTypeSetting {
  $tip: Tip;
  defaultDashboard?: Tip;
  defaultForm?: Tip;
}

interface IProfile extends IObject {
  name: string;
  key: string;
  description: string;
  mainMenus?: IMainMenuItem[];
  createMenus?: ICreateMenuItem[];
  universalButtons?: IMenuItem[];
  objectTypeSettings?: IObjectTypeSetting[];
  defaultPolicy?: string;
}

const profileScheme: IObjectScheme = {
  'app/profile:name': { type: DataTypes.i18n },
  'app/profile:key': {},
  'app/profile:description': { type: DataTypes.i18n },
  'app/profile:main-menus': { type: DataTypes.jsonArray },
  'app/profile:universal-buttons': { type: DataTypes.jsonArray },
  'app/profile:create-menus': { type: DataTypes.jsonArray },
  'app/profile:object-type-settings': { type: DataTypes.jsonArray },
  'app/profile:default-policy': { type: DataTypes.string }
};
