import { DataTypes, IObjectScheme } from '../data/models/scheme';
import { IObject } from '../data/models/object';
import { checkListScheme, ICheckList } from './checklist';
import { Tip } from '../data/models/types';

export { IPlan, IPlanSummary, planSummaryScheme, planScheme };

interface IPlan extends IObject {
  name: string;
  description: string;
  checklists: ICheckList[];
  strategy?: string;
  isTemplate?: boolean;
  isActivated?: boolean;
  context?: Tip;
  isIncomplete?: boolean;
}

interface IPlanSummary extends IObject {
  name: string;
  description: string;
}

const planScheme: IObjectScheme = {
  'app/plan:name': { type: DataTypes.i18n },
  'app/plan:description': { type: DataTypes.i18n },
  'app/plan:strategy': { type: DataTypes.string },
  'app/plan:checklists': { type: DataTypes.objectArray, scheme: checkListScheme, mutable: true },
  'app/plan:is-template': { type: DataTypes.boolean },
  'app/plan:is-activated': { type: DataTypes.boolean },
  'app/plan:context': { type: DataTypes.string },
  'app/plan:is-incomplete': { type: DataTypes.boolean }
};

const planSummaryScheme: IObjectScheme = {
  'app/plan:name': { type: DataTypes.i18n },
  'app/plan:description': { type: DataTypes.i18n },
  'app/plan:strategy': { type: DataTypes.i18n },
  'app/plan:is-template': { type: DataTypes.boolean },
  'app/plan:is-activated': { type: DataTypes.boolean },
  'app/plan:context': { type: DataTypes.string },
  'app/plan:is-incomplete': { type: DataTypes.boolean }
};
