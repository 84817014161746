/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./custom-date-range-side-sheet.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../side-sheet-header/side-sheet-header.component.ngfactory";
import * as i3 from "../side-sheet-header/side-sheet-header.component";
import * as i4 from "../side-sheet.service";
import * as i5 from "../../../../../../node_modules/@ngx-formly/core/ngx-formly-core.ngfactory";
import * as i6 from "@ngx-formly/core";
import * as i7 from "@angular/forms";
import * as i8 from "./custom-date-range-side-sheet.component";
var styles_CustomDateRangeSideSheetComponent = [i0.styles];
var RenderType_CustomDateRangeSideSheetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomDateRangeSideSheetComponent, data: {} });
export { RenderType_CustomDateRangeSideSheetComponent as RenderType_CustomDateRangeSideSheetComponent };
export function View_CustomDateRangeSideSheetComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-side-sheet-header", [["action", "Done"]], null, [[null, "actioned"], [null, "goBack"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("actioned" === en)) {
        var pd_0 = (_co.onDone() !== false);
        ad = (pd_0 && ad);
    } if (("goBack" === en)) {
        var pd_1 = (_co.onCancel() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_SideSheetHeaderComponent_0, i2.RenderType_SideSheetHeaderComponent)), i1.ɵdid(2, 245760, null, 0, i3.SideSheetHeaderComponent, [i4.SideSheetService], { action: [0, "action"] }, { actioned: "actioned", goBack: "goBack" }), (_l()(), i1.ɵted(-1, 0, [" Custom range date "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "formly-form", [], null, null, null, i5.View_FormlyForm_0, i5.RenderType_FormlyForm)), i1.ɵprd(512, null, i6.FormlyFormBuilder, i6.FormlyFormBuilder, [i6.FormlyConfig, i1.ComponentFactoryResolver, i1.Injector]), i1.ɵdid(6, 966656, null, 0, i6.FormlyForm, [i6.FormlyFormBuilder, i6.FormlyConfig, [8, null], [2, i7.FormGroupDirective]], { form: [0, "form"], model: [1, "model"], fields: [2, "fields"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Done"; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.customRangeDateForm; var currVal_2 = _co.model; var currVal_3 = _co.fields; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_CustomDateRangeSideSheetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-custom-date-range-side-sheet", [], null, null, null, View_CustomDateRangeSideSheetComponent_0, RenderType_CustomDateRangeSideSheetComponent)), i1.ɵdid(1, 49152, null, 0, i8.CustomDateRangeSideSheetComponent, [i4.SideSheetService], null, null)], null, null); }
var CustomDateRangeSideSheetComponentNgFactory = i1.ɵccf("app-custom-date-range-side-sheet", i8.CustomDateRangeSideSheetComponent, View_CustomDateRangeSideSheetComponent_Host_0, {}, {}, []);
export { CustomDateRangeSideSheetComponentNgFactory as CustomDateRangeSideSheetComponentNgFactory };
