import { FieldType } from '@ngx-formly/core';
import { Component } from '@angular/core';

@Component({
  selector: 'app-conditional-field-selector',
  template: `
    <app-conditional-field-selector-field
        [label]="to.label"
        [formControl]="formControl"
        [placeholder]="to.placeholder"
        [fieldContextTip]="to.fieldContextTip"
        [conditionsText]="to.conditionsText"
        [fieldKey]="field.key"
    ></app-conditional-field-selector-field>
  `
})
export class FormlyFieldConditionalFieldSelectorComponent extends FieldType {

}
