import { IProcessNode } from '../../../models/process';
import { IWorkflow } from '../../../models/workflow';
import { Tip } from '../../../data/models/types';
import { INodeEditorData } from '../workflow-designer-interfaces';

export abstract class NodeConverterBase {
  // The processnodetype tip of the node that is converted into
  abstract processNodeType: Tip;
  // The processnode type tip of the node that is converted into
  abstract type: Tip;

  /*
   * convert nodeData to actual process node eno data
   * nodeData should have toLink property
   *
   * IProcessNode does not contain connections populated
   *
   * NOTE THAT whenever a new NodeConverterBase is implemented, things below should be done
   *
   * 1. Add interface and scheme in process.ts and extend the scheme to processNodeSchemeUnion
   * 2. Add converter map in process-converter.service.ts constructor
   * 3. Do not forget to throw InvalidWorkflowNodeDataError when the given data is invalid
   * 4. Mostly, you just have to create a new service which extends CompositeNodeConverter
   *
   * @Throws: InvalidWorkflowNodeDataError thrown when validation fails, such as required field is not given
   */
  abstract convert(nodeData: INodeEditorData, workflow: IWorkflow): IProcessNode;

  protected constructor() { }
}
