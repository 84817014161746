import * as moment from 'moment';

export interface ICustomDateRangeForm {
  from: moment.Moment; // ISO string
  to: moment.Moment; // ISO string
}

export const CUSTOM_DATE_RANGE_FORM_FIELDS = [
  {
    wrappers: ['side-sheet-section-wrapper'],
    templateOptions: {
      title: 'Select date range',
      showHeader: true
    },
    fieldGroup: [
      {
        key: 'from',
        type: 'date-time',
        templateOptions: {
          label: 'From',
          type: 'date',
          placeholder: 'Select from date'
        },
      },
      {
        key: 'to',
        type: 'date-time',
        templateOptions: {
          label: 'To',
          type: 'date',
          placeholder: 'Select to date'
        },
      }
    ]
  }
];
