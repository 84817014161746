import * as tslib_1 from "tslib";
import { DataTypes } from '../data/models/scheme';
import { cloneDeep } from 'lodash';
export { processScheme, processNodeScheme, processConnectionScheme, processNodeIfScheme, processNodeNoOpScheme, processNodeSwitchScheme, processNodeSubprocessScheme, processNodeUpdateEnoScheme, processNodeCreateScheme, processNodeDeleteScheme, processNodeDuplicateScheme, processNodeSetVariableScheme, processNodeForeachScheme };
var iconFileScheme = {
    'file/name': {},
    'file/mime': {},
    'file/size': { type: DataTypes.number },
    'file/data': {},
};
/**
 * Moved 'processconnection/tonode' below 'processNodeScheme' to avoid circular reference issue
 */
var processConnectionScheme = {
    'processconnection/outcome': { type: DataTypes.i18n },
    'processconnection/point': { type: DataTypes.numberArray },
    'processconnection/delay': { name: 'delaySec', type: DataTypes.number }
};
var processNodeScheme = {
    'processnode/type': {},
    'processnode/env': {},
    'processnode/point': { type: DataTypes.numberArray },
};
var processNodeIfScheme = cloneDeep(processNodeScheme);
processNodeIfScheme['processnode/logic/if/expr'] = { name: 'expression', type: DataTypes.string };
var processNodeSubprocessScheme = cloneDeep(processNodeScheme);
processNodeSubprocessScheme['processnode/logic/subprocess/process'] = { type: DataTypes.formula };
processNodeSubprocessScheme['processnode/logic/subprocess/var-key'] = { name: 'varKeys', type: DataTypes.stringArray };
processNodeSubprocessScheme['processnode/logic/subprocess/subvar-key'] = { name: 'subVarKeys', type: DataTypes.stringArray };
processNodeSubprocessScheme['processnode/logic/subprocess/output-var-key'] = { name: 'outputVarKeys', type: DataTypes.stringArray };
processNodeSubprocessScheme['processnode/logic/subprocess/output-subvar-key'] = { name: 'outputSubVarKeys', type: DataTypes.stringArray };
processNodeSubprocessScheme['processnode/logic/subprocess/output-var-scope'] = { name: 'outputVarScope', type: DataTypes.string };
processNodeSubprocessScheme['processnode/logic/subprocess/timeout'] = { name: 'timeoutMs', type: DataTypes.number };
processNodeSubprocessScheme['processnode/logic/subprocess/wait-for-process-complete'] = {
    name: 'waitForProcessComplete',
    type: DataTypes.boolean
};
var processNodeNoOpScheme = cloneDeep(processNodeScheme);
var processNodeUpdateEnoScheme = cloneDeep(processNodeScheme);
processNodeUpdateEnoScheme['processnode/data/update-eno/object'] = { name: 'updateEnoObject', type: DataTypes.string };
processNodeUpdateEnoScheme['processnode/data/update-eno/fields'] = { name: 'updateEnoFields', type: DataTypes.stringArray };
processNodeUpdateEnoScheme['processnode/data/update-eno/values'] = { name: 'updateEnoValues', type: DataTypes.formulaArray };
processNodeUpdateEnoScheme['processnode/data/update-eno/branch-src'] = { name: 'updateEnoBranchSrc', type: DataTypes.string };
processNodeUpdateEnoScheme['processnode/data/update-eno/branch-dest'] = { name: 'updateEnoBranchDest', type: DataTypes.string };
processNodeUpdateEnoScheme['processnode/data/update-eno/lang'] = { name: 'updateEnoLang', type: DataTypes.string };
processNodeUpdateEnoScheme['processnode/data/update-eno/security'] = { name: 'updateEnoSecurity', type: DataTypes.string };
var processNodeDeleteScheme = cloneDeep(processNodeScheme);
processNodeDeleteScheme['processnode/data/delete/tip'] = { name: 'deleteTip', type: DataTypes.stringArray };
processNodeDeleteScheme['processnode/data/delete/srcbranch'] = { name: 'deleteSrcBranch', type: DataTypes.string };
processNodeDeleteScheme['processnode/data/delete/destbranch'] = { name: 'deleteDestBranch', type: DataTypes.string };
processNodeDeleteScheme['processnode/data/delete/security'] = { name: 'deleteSecurity', type: DataTypes.string };
var processNodeCreateScheme = cloneDeep(processNodeScheme);
processNodeCreateScheme['processnode/data/create/type'] = { name: 'createType', type: DataTypes.string };
processNodeCreateScheme['processnode/data/create/security'] = { name: 'createSecurity', type: DataTypes.string };
processNodeCreateScheme['processnode/data/create/branch'] = { name: 'createBranch', type: DataTypes.string };
processNodeCreateScheme['processnode/data/create/fields'] = { name: 'createFields', type: DataTypes.stringArray };
processNodeCreateScheme['processnode/data/create/data'] = { name: 'createData', type: DataTypes.stringArray };
processNodeCreateScheme['processnode/data/create/datacount'] = { name: 'createDataCount', type: DataTypes.stringArray };
processNodeCreateScheme['processnode/data/create/lang'] = { name: 'createLang', type: DataTypes.string };
processNodeCreateScheme['processnode/data/create/tip-var-key'] = { name: 'createTipVarKey', type: DataTypes.string };
processNodeCreateScheme['processnode/data/create/tip-var-scope'] = { name: 'createTipVarScope', type: DataTypes.string };
var processNodeDuplicateScheme = cloneDeep(processNodeScheme);
processNodeDuplicateScheme['processnode/data/duplicate/tip'] = { name: 'duplicateTip', type: DataTypes.stringArray };
processNodeDuplicateScheme['processnode/data/duplicate/fields'] = { name: 'duplicateFields', type: DataTypes.stringArray };
processNodeDuplicateScheme['processnode/data/duplicate/source-branch'] = { name: 'duplicateSourceBranch', type: DataTypes.string };
processNodeDuplicateScheme['processnode/data/duplicate/dest-branch'] = { name: 'duplicateDestBranch', type: DataTypes.string };
processNodeDuplicateScheme['processnode/data/duplicate/tip-var'] = { name: 'duplicateTipVar', type: DataTypes.string };
processNodeDuplicateScheme['processnode/data/duplicate/tip-var-scope'] = { name: 'duplicateTipVarScope', type: DataTypes.string };
processNodeDuplicateScheme['processnode/data/duplicate/duplicate-backward-refs'] = {
    name: 'duplicateBackwardRefs',
    type: DataTypes.boolean
};
var processNodeSetVariableScheme = cloneDeep(processNodeScheme);
processNodeSetVariableScheme['processnode/reference/setvar/scope'] = { name: 'setVarScope', type: DataTypes.string };
processNodeSetVariableScheme['processnode/reference/setvar/key'] = { name: 'setVarKey', type: DataTypes.stringArray };
processNodeSetVariableScheme['processnode/reference/setvar/value'] = { name: 'setVarValue', type: DataTypes.formulaArray };
var processNodeSwitchScheme = cloneDeep(processNodeScheme);
processNodeSetVariableScheme['processnode/logic/switch/expr'] = { name: 'switchExpr', type: DataTypes.string };
processNodeSetVariableScheme['processnode/logic/switch/default'] = { name: 'switchDefault', type: DataTypes.string };
processNodeSetVariableScheme['processnode/logic/switch/cases'] = { name: 'switchCases', type: DataTypes.stringArray };
var processNodeForeachScheme = cloneDeep(processNodeScheme);
processNodeForeachScheme['processnode/logic/foreach:source'] = { name: 'source', type: DataTypes.formula };
processNodeForeachScheme['processnode/logic/foreach:current'] = { name: 'currentElement', type: DataTypes.string };
processNodeForeachScheme['processnode/logic/foreach:index'] = { name: 'currentIndex', type: DataTypes.string };
processNodeForeachScheme['processnode/logic/foreach:stack'] = { name: 'stack', type: DataTypes.string };
/////////////////////// DO NOT MOVE ITEMS BELLOW TO ABOVE OR CHANGE DECLARATION ORDER /////////////////////
// YOU MUST ADD NEW NODE SCHEME HERE WHENEVER A NEW NODE SCHEME IS ADDED
// Be careful not to define same property name on scheme
var processNodeSchemeUnion = tslib_1.__assign({ 'processnode/connection': {
        name: 'connections',
        type: DataTypes.objectArray,
        mutable: true,
        scheme: processConnectionScheme
    } }, processNodeScheme, processNodeIfScheme, processNodeNoOpScheme, processNodeSwitchScheme, processNodeSubprocessScheme, processNodeUpdateEnoScheme, processNodeCreateScheme, processNodeDeleteScheme, processNodeDuplicateScheme, processNodeSetVariableScheme, processNodeForeachScheme);
// must come after 'processNodeScheme' to avoid circular reference issue
processConnectionScheme['processconnection/tonode'] = {
    name: 'toNodes',
    type: DataTypes.objectArray,
    mutable: true,
    scheme: processNodeSchemeUnion,
    circularPlaceholder: { $type: 'processnode/no-op' }
};
processNodeScheme['processnode/connection'] = {
    name: 'connections',
    type: DataTypes.objectArray,
    mutable: true,
    scheme: processConnectionScheme
};
var processScheme = {
    'process/title': { type: DataTypes.i18n },
    'process/description': { type: DataTypes.i18n },
    'process/node': {
        name: 'nodes',
        type: DataTypes.objectArray,
        mutable: true,
        scheme: processNodeSchemeUnion
    },
    'process/grants': { type: DataTypes.string },
    'process/env': { type: DataTypes.string },
    'process/required-vars': { type: DataTypes.stringArray },
    'process/required-description': { name: 'requiredVarsDescription', type: DataTypes.stringArray },
    'process/optional-vars': { type: DataTypes.stringArray },
    'process/optional-description': { name: 'optionalVarsDescription', type: DataTypes.stringArray }
};
