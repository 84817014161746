import { BehaviorSubject, of } from 'rxjs';
import { switchMap, first, map, filter } from 'rxjs/operators';
import { get, head } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "../../data/session-manager.service";
import * as i2 from "../../data/process.service";
import * as i3 from "../../data/i18n.service";
var MeService = /** @class */ (function () {
    function MeService(_sessionManagerService, processService, i18nService) {
        var _this = this;
        this._sessionManagerService = _sessionManagerService;
        this.processService = processService;
        this.i18nService = i18nService;
        this._meSubject = new BehaviorSubject(null);
        this._sessionManagerService.getSessionInfo$().pipe(first(), switchMap(function (sessionInfo) { return _this._resolveMe(sessionInfo); })).subscribe(function (user) {
            // Called asynchronously.
            _this.i18nService.checkLocale$(user);
            return _this._meSubject.next(user);
        });
        this._sessionManagerService.tokenChanged$.pipe(switchMap(function (sessionInfo) { return _this._resolveMe(sessionInfo); })).subscribe(function (user) { return _this._meSubject.next(user); });
    }
    Object.defineProperty(MeService.prototype, "me$", {
        get: function () {
            return this.getMe$();
        },
        enumerable: true,
        configurable: true
    });
    MeService.prototype.getMe$ = function () {
        return this._meSubject.asObservable();
    };
    MeService.prototype._resolveMe = function (sessionInfo) {
        var userTip = sessionInfo && sessionInfo.user;
        return userTip ? this.getCurrentUser() : of(null);
    };
    MeService.prototype.getCurrentUser = function () {
        return this.processService.start('eim/process/shell/me').pipe(filter(function (response) { return response.finished; }), first(), map(function (response) {
            return {
                $tip: head(get(response, 'vars.tip', [])),
                email: head(get(response, 'vars.email', [])),
                status: head(get(response, 'vars.status', [])),
                profiles: get(response, 'vars.profiles', []),
                identityProvider: head(get(response, 'vars.identityProvider', [])),
                preference: head(get(response, 'vars.preference', [])),
                privateSecurityGrant: head(get(response, 'vars.privateSecurityGrant', [])),
                fullName: head(get(response, 'vars.fullName', [])),
                contactTip: head(get(response, 'vars.contactTip', [])),
                avatarTip: head(get(response, 'vars.avatarTip', [])),
                localeId: head(get(response, 'vars.locale-id', [])),
            };
        }));
    };
    MeService.prototype.isAdmin = function () {
        return this.getMe$()
            .pipe(filter(Boolean), map(function (me) { return get(me, 'profiles', []).includes('app/profile/administrator'); }));
    };
    MeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MeService_Factory() { return new MeService(i0.ɵɵinject(i1.SessionManagerService), i0.ɵɵinject(i2.ProcessService), i0.ɵɵinject(i3.I18nService)); }, token: MeService, providedIn: "root" });
    return MeService;
}());
export { MeService };
