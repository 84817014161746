import * as tslib_1 from "tslib";
import { InjectionToken } from '@angular/core';
import { GroupCreateSideSheetComponent } from '../groups/sidesheets/group-create-side-sheet/group-create-side-sheet.component';
import { GroupEditSideSheetComponent } from '../groups/sidesheets/group-edit-side-sheet/group-edit-side-sheet.component';
import { PlanEditSideSheetComponent } from '../plans/plan-edit-side-sheet/plan-edit-side-sheet.component';
import { EditFormSideSheetComponent } from './form-renderer/edit-form-side-sheet/edit-form-side-sheet.component';
import { generalSecuritySettingsForm } from './well-known-types/general-security-settings-form';
import { apiKeyMetaCreateForm, apiKeyMetaEditForm } from './well-known-types/api-key-meta-form';
import { brandingSettingsCreateForm } from './well-known-types/branding-settings-form';
import { numberFormatForm } from './well-known-types/number-format-form';
import { localeSettingsCreateForm } from './well-known-types/locale-settings-form';
export var OBJECT_LAUNCH_SERVICE_TOKEN = new InjectionToken('ObjectLaunchService');
export var REDIRECT_TYPES;
(function (REDIRECT_TYPES) {
    REDIRECT_TYPES["dashboard"] = "dashboard";
    REDIRECT_TYPES["workflow"] = "workflow";
})(REDIRECT_TYPES || (REDIRECT_TYPES = {}));
export var OBJECT_LAUNCH_OPERATION;
(function (OBJECT_LAUNCH_OPERATION) {
    OBJECT_LAUNCH_OPERATION["CREATE"] = "create";
    OBJECT_LAUNCH_OPERATION["EDIT"] = "edit";
    OBJECT_LAUNCH_OPERATION["VIEW"] = "view";
})(OBJECT_LAUNCH_OPERATION || (OBJECT_LAUNCH_OPERATION = {}));
export var WELL_KNOWN_OBJECT_TYPE;
(function (WELL_KNOWN_OBJECT_TYPE) {
    WELL_KNOWN_OBJECT_TYPE["GROUP"] = "app/group";
    WELL_KNOWN_OBJECT_TYPE["PLAN"] = "app/plan";
    WELL_KNOWN_OBJECT_TYPE["PROTECTIVE_MARKING"] = "app/au-gov/protective-marking";
    WELL_KNOWN_OBJECT_TYPE["PROTECTIVE_MARKING_SETTINGS"] = "app/au-gov/protective-markings-settings";
    WELL_KNOWN_OBJECT_TYPE["GENERAL_SECURITY_SETTINGS"] = "app/general-security-settings";
    WELL_KNOWN_OBJECT_TYPE["API_KEY_META"] = "app/api-key-meta";
    WELL_KNOWN_OBJECT_TYPE["BRANDING_SETTINGS"] = "app/branding-settings";
    WELL_KNOWN_OBJECT_TYPE["LOCALE_SETTINGS"] = "app/type/locale";
    WELL_KNOWN_OBJECT_TYPE["NUMBER_FORMAT"] = "number-format"; // defined by the backend (no app/)
})(WELL_KNOWN_OBJECT_TYPE || (WELL_KNOWN_OBJECT_TYPE = {}));
export var nonStandardCreationTypes = [
    'app/plan'
];
/**
 * Note: When adding more well-known side sheet
 * Can add done$ = new Subject <IObjectLaunchDone>() - for callback
 * Can add setProps(props: IObjectLaunchProps) - for passing inputs
 */
export function createWellKnownObjectMapping(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var objectType = _a.objectType, formObjectAndType = _a.formObjectAndType;
    var mapping = (_b = {},
        _b[WELL_KNOWN_OBJECT_TYPE.GROUP] = (_c = {},
            _c[OBJECT_LAUNCH_OPERATION.CREATE] = { component: GroupCreateSideSheetComponent, objectLaunchOverrides: {}, formObjectAndType: formObjectAndType },
            _c[OBJECT_LAUNCH_OPERATION.EDIT] = { component: GroupEditSideSheetComponent, objectLaunchOverrides: {}, formObjectAndType: formObjectAndType },
            _c),
        _b[WELL_KNOWN_OBJECT_TYPE.PLAN] = (_d = {},
            _d[OBJECT_LAUNCH_OPERATION.VIEW] = { component: PlanEditSideSheetComponent, objectLaunchOverrides: {}, formObjectAndType: formObjectAndType },
            _d[OBJECT_LAUNCH_OPERATION.EDIT] = { component: PlanEditSideSheetComponent, objectLaunchOverrides: {}, formObjectAndType: formObjectAndType },
            _d),
        _b);
    // general security settings
    var apiKeyMetaOverrides = {
        hideSecurityOptions: true,
    };
    var apiKeyMeta = function (customForm) { return ({
        component: EditFormSideSheetComponent,
        objectLaunchOverrides: apiKeyMetaOverrides,
        formObjectAndType: overrideForm({ formObjectAndType: formObjectAndType, customForm: customForm })
    }); };
    mapping[WELL_KNOWN_OBJECT_TYPE.API_KEY_META] = (_e = {},
        _e[OBJECT_LAUNCH_OPERATION.CREATE] = apiKeyMeta(apiKeyMetaCreateForm),
        _e[OBJECT_LAUNCH_OPERATION.EDIT] = apiKeyMeta(apiKeyMetaEditForm),
        _e);
    // protective markings overrides
    var protectiveMarkingOverrides = {
        hideSecurityOptions: true,
        securityPolicyTip: 'app/security-policy/instance-admin-admin'
    };
    var protectiveMarking = {
        component: EditFormSideSheetComponent,
        objectLaunchOverrides: protectiveMarkingOverrides,
        formObjectAndType: formObjectAndType
    };
    mapping[WELL_KNOWN_OBJECT_TYPE.PROTECTIVE_MARKING] = (_f = {},
        _f[OBJECT_LAUNCH_OPERATION.CREATE] = protectiveMarking,
        _f[OBJECT_LAUNCH_OPERATION.EDIT] = protectiveMarking,
        _f);
    // protective markings overrides
    // protective marking settings
    var protectiveMarkingSettingsOverrides = {
        hideSecurityOptions: true,
    };
    var protectiveMarkingSettings = {
        component: EditFormSideSheetComponent,
        objectLaunchOverrides: protectiveMarkingSettingsOverrides,
        formObjectAndType: formObjectAndType
    };
    mapping[WELL_KNOWN_OBJECT_TYPE.PROTECTIVE_MARKING_SETTINGS] = (_g = {},
        _g[OBJECT_LAUNCH_OPERATION.EDIT] = protectiveMarkingSettings,
        _g);
    // protective marking settings
    // general security settings
    var generalSecuritySettingsOverrides = {
        hideSecurityOptions: true,
    };
    var generalSecuritySettings = {
        component: EditFormSideSheetComponent,
        objectLaunchOverrides: generalSecuritySettingsOverrides,
        formObjectAndType: overrideForm({ formObjectAndType: formObjectAndType, customForm: generalSecuritySettingsForm })
    };
    mapping[WELL_KNOWN_OBJECT_TYPE.GENERAL_SECURITY_SETTINGS] = (_h = {},
        _h[OBJECT_LAUNCH_OPERATION.EDIT] = generalSecuritySettings,
        _h);
    // general security settings
    // branding settings
    var brandingSettingsOverrides = {
        hideSecurityOptions: true,
    };
    var brandingSettings = {
        component: EditFormSideSheetComponent,
        objectLaunchOverrides: brandingSettingsOverrides,
        formObjectAndType: overrideForm({ formObjectAndType: formObjectAndType, customForm: brandingSettingsCreateForm })
    };
    mapping[WELL_KNOWN_OBJECT_TYPE.BRANDING_SETTINGS] = (_j = {},
        _j[OBJECT_LAUNCH_OPERATION.EDIT] = brandingSettings,
        _j);
    // branding settings
    // Locale Timezone settings
    var localeTimezoneSettingsOverrides = {
        hideSecurityOptions: true,
    };
    var localeTimezoneSettings = {
        component: EditFormSideSheetComponent,
        objectLaunchOverrides: localeTimezoneSettingsOverrides,
        formObjectAndType: overrideForm({ formObjectAndType: formObjectAndType, customForm: localeSettingsCreateForm })
    };
    mapping[WELL_KNOWN_OBJECT_TYPE.LOCALE_SETTINGS] = (_k = {},
        _k[OBJECT_LAUNCH_OPERATION.EDIT] = localeTimezoneSettings,
        _k);
    // Locale Timezone settings
    // number format
    var numberFormatOverrides = {
        hideSecurityOptions: true,
        securityPolicyTip: 'app/security-policy/instance-user-admin'
    };
    var numberFormatSettings = {
        component: EditFormSideSheetComponent,
        objectLaunchOverrides: numberFormatOverrides,
        formObjectAndType: overrideForm({ formObjectAndType: formObjectAndType, customForm: numberFormatForm })
    };
    mapping[WELL_KNOWN_OBJECT_TYPE.NUMBER_FORMAT] = (_l = {},
        _l[OBJECT_LAUNCH_OPERATION.EDIT] = numberFormatSettings,
        _l[OBJECT_LAUNCH_OPERATION.CREATE] = numberFormatSettings,
        _l);
    // number format
    return mapping[objectType];
}
function overrideForm(_a) {
    var formObjectAndType = _a.formObjectAndType, customForm = _a.customForm;
    return tslib_1.__assign({}, formObjectAndType, { form: customForm });
}
