var _a;
import { cloneDeep } from 'lodash';
import { getDataTypeAndMetaFromField } from '../../../util/get-data-type-and-meta-from-field';
import { ADDRESS_FORM_CONFIG, ASSIGNABLE_FORM_CONFIG, CHECKBOX_FORM_CONFIG, DATE_FORM_CONFIG, DATE_TIME_FORM_CONFIG, EMAIL_ADDRESS_FORM_CONFIG, FILE_UPLOAD_FORM_CONFIG, LINE_FORM_CONFIG, LIST_ITEMS_SELECT_FORM_CONFIG, NUMBER_FORM_CONFIG, OBJECT_CHOOSER_FORM_CONFIG, PHONE_NUMBER_FORM_CONFIG, POINT_FORM_CONFIG, POLYGON_FORM_CONFIG, RICH_TEXT_FORM_CONFIG, SINGLE_LINE_TEXT_FORM_CONFIG, SUB_OBJECT_CHOOSER_FORM_CONFIG, WEB_ADDRESS_FORM_CONFIG } from '../../../settings/form-designer/form-options';
import { EXTENDED_FIELD_DATA_TYPE, FIELD_DATA_TYPE } from '../../../models/field';
export function createFormFromType(_a) {
    var objectType = _a.objectType;
    var rows = objectType.field.map(createRowFromField);
    var tabs = [{
            tabName: 'Tab 1',
            rows: rows
        }];
    var contextType = {
        type: {
            $tip: objectType.$tip,
            // label needed to display header in side sheet
            label: objectType.label,
            // need the fields to create contextTypeOptions (think Field Options)
            // to get meta data for lists, object etc
            field: objectType.field
        }
    };
    var form = {
        name: 'Generated form',
        description: '',
        size: 'regular',
        version: 1,
        contextType: contextType,
        tabs: tabs
    };
    return form;
}
function createRowFromField(field) {
    var _datatype = getDataTypeAndMetaFromField({ field: field })._datatype;
    var rowFields = [];
    try {
        var config = datatypeMap[_datatype](field);
        if (config) {
            rowFields.push(config);
        }
    }
    catch (e) {
        throw new Error("CreateRowFromField failed\n      Field: " + JSON.stringify(field, null, 2));
    }
    return { fields: rowFields };
}
var datatypeMap = (_a = {},
    _a[FIELD_DATA_TYPE.string_i18n] = createFieldFromConfig(SINGLE_LINE_TEXT_FORM_CONFIG),
    _a[FIELD_DATA_TYPE.string] = createFieldFromConfig(SINGLE_LINE_TEXT_FORM_CONFIG),
    _a[FIELD_DATA_TYPE.blob] = function () { return null; },
    _a[FIELD_DATA_TYPE.number] = createFieldFromConfig(NUMBER_FORM_CONFIG),
    _a[FIELD_DATA_TYPE.decimal] = createFieldFromConfig(NUMBER_FORM_CONFIG),
    _a[FIELD_DATA_TYPE.bool] = createFieldFromConfig(CHECKBOX_FORM_CONFIG),
    _a[FIELD_DATA_TYPE.date] = createFieldFromConfig(DATE_FORM_CONFIG),
    _a[FIELD_DATA_TYPE.datetime] = createFieldFromConfig(DATE_TIME_FORM_CONFIG),
    _a[FIELD_DATA_TYPE.object] = createFieldFromConfig(OBJECT_CHOOSER_FORM_CONFIG),
    _a[FIELD_DATA_TYPE.email] = createFieldFromConfig(EMAIL_ADDRESS_FORM_CONFIG),
    _a[FIELD_DATA_TYPE.phone] = createFieldFromConfig(PHONE_NUMBER_FORM_CONFIG),
    _a[FIELD_DATA_TYPE.uri] = createFieldFromConfig(WEB_ADDRESS_FORM_CONFIG),
    _a[FIELD_DATA_TYPE.formula] = function () { return null; },
    _a[FIELD_DATA_TYPE.string_formula] = function () { return null; },
    _a[FIELD_DATA_TYPE.json] = function () { return null; },
    _a[FIELD_DATA_TYPE.html] = createFieldFromConfig(RICH_TEXT_FORM_CONFIG),
    // extended types
    _a[EXTENDED_FIELD_DATA_TYPE.file] = createFieldFromConfig(FILE_UPLOAD_FORM_CONFIG),
    _a[EXTENDED_FIELD_DATA_TYPE.list] = createFieldFromConfig(LIST_ITEMS_SELECT_FORM_CONFIG),
    _a[EXTENDED_FIELD_DATA_TYPE.address] = createFieldFromConfig(ADDRESS_FORM_CONFIG),
    _a[EXTENDED_FIELD_DATA_TYPE.point] = createFieldFromConfig(POINT_FORM_CONFIG),
    _a[EXTENDED_FIELD_DATA_TYPE.line] = createFieldFromConfig(LINE_FORM_CONFIG),
    _a[EXTENDED_FIELD_DATA_TYPE.polygon] = createFieldFromConfig(POLYGON_FORM_CONFIG),
    _a[EXTENDED_FIELD_DATA_TYPE.assignable] = createFieldFromConfig(ASSIGNABLE_FORM_CONFIG),
    _a[EXTENDED_FIELD_DATA_TYPE.sub_object] = createFieldFromConfig(SUB_OBJECT_CHOOSER_FORM_CONFIG),
    _a);
function createFieldFromConfig(config) {
    return function (field) {
        var fieldConfig = cloneDeep(config);
        fieldConfig.templateOptions.contextTypeTip = field.$tip;
        fieldConfig.templateOptions.label = field.label;
        fieldConfig.templateOptions.placeholder = field.label;
        if (field.maxlen) {
            fieldConfig.templateOptions.maxLength = field.maxlen;
        }
        if (field.minlen) {
            fieldConfig.templateOptions.minLength = field.minlen;
            field.required = true;
        }
        fieldConfig.conditionalFields.required.value = (field.mincount > 0 || field.required) ? 'true' : 'false';
        return fieldConfig;
    };
}
