import { Component, HostBinding } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-string-interpolate-text',
  template: `
    <app-string-interpolate-text-field
        [formControl]="formControl"
        [label]="field.templateOptions.label"
        [placeholder]="field.templateOptions.placeholder"
        [required]="field.templateOptions.required"
        [readonly]="field.templateOptions.readonly"
        [mode]="field.templateOptions.mode"
        [recipientSuperTypeTip]="field.templateOptions.recipientSuperTypeTip"
        [interpolationType]="field.templateOptions.interpolationType"
        [contextTypeTips]="field.templateOptions.contextTypeTips"
        [valueFormat]="field.templateOptions.valueFormat"
        [showSequence]="field.templateOptions.showSequence"
        [workflowVariables]="field.templateOptions.workflowVariables"
        [useContextFormula]="field.templateOptions?.useContextFormula"
        [displayFormulaRule]="field.templateOptions?.displayFormulaRule"
    >
    </app-string-interpolate-text-field>
  `,
})
export class FormlyFieldStringInterpolateTextComponent extends FieldType {
  @HostBinding('class') classes = 'flex-fill';
}
