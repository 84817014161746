import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoadingState } from '../../shared/constants';
import { SideSheetListModes } from '../../side-sheet/side-sheet-list/side-sheet-list.component';
var SelectTeamsSideSheetComponent = /** @class */ (function () {
    function SelectTeamsSideSheetComponent(teamService) {
        this.teamService = teamService;
        this.mode = SideSheetListModes.SINGLE_SELECT;
        this.title = 'Select teams';
        this.initiallySelectedTips = [];
        this.selectedTeams = [];
        this.doNotDisplayInitiallySelectedTeams = false;
        this.subscriptions = new Subscription();
        this.loadingStates = LoadingState;
    }
    SelectTeamsSideSheetComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.selectedTeams = tslib_1.__spread(this.initiallySelectedTips);
        this.teams$ = this.teamService.getAnalyzedTeams().pipe(map(function (analyzedTeams) { return analyzedTeams.teams; }), map(function (teams) {
            _this.teams = (_this.doNotDisplayInitiallySelectedTeams) ?
                teams.filter(function (team) { return _this.initiallySelectedTips.indexOf(team.$tip) === -1; }) : teams;
            return _this.teams;
        }));
    };
    SelectTeamsSideSheetComponent.prototype.onDone = function (selectedTeamTips) {
        switch (this.mode) {
            case SideSheetListModes.SINGLE_SELECT:
                if (selectedTeamTips && selectedTeamTips.length) {
                    this.done(this.teams.find(function (x) { return x.$tip === selectedTeamTips[0]; }));
                }
                break;
            case SideSheetListModes.MULTI_SELECT:
                this.done(this.teams.filter(function (team) {
                    return selectedTeamTips.indexOf(team.$tip) > -1;
                }));
                break;
        }
    };
    return SelectTeamsSideSheetComponent;
}());
export { SelectTeamsSideSheetComponent };
