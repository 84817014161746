import { GeometryObject } from 'geojson';
import * as moment from 'moment';

import { DataTypes } from '../models/scheme';

/**
 * The following functions are used to convert ENO field values to object values
 */
export const CAST_VALUE_FUNCS = {};
CAST_VALUE_FUNCS[DataTypes.booleanArray] = (value) => value.map(val => val === 'true');
CAST_VALUE_FUNCS[DataTypes.boolean] = (value) => value.length > 0 && value[0] === 'true';
CAST_VALUE_FUNCS[DataTypes.numberArray] = (value) => value.map(parseFloat);
CAST_VALUE_FUNCS[DataTypes.number] = (value) => value.length > 0 ? parseFloat(value[0]) : null;
CAST_VALUE_FUNCS[DataTypes.datetimeArray] = (value) => value.map(val => moment(val, moment.ISO_8601)).filter(val => val.isValid());
CAST_VALUE_FUNCS[DataTypes.datetime] = (value) => {
  return value.length > 0 && moment(value[0], moment.ISO_8601).isValid() ? moment(value[0], moment.ISO_8601) : null;
};
CAST_VALUE_FUNCS[DataTypes.stringArray] = (value) => value.slice();
CAST_VALUE_FUNCS[DataTypes.i18nArray] = CAST_VALUE_FUNCS[DataTypes.stringArray];
CAST_VALUE_FUNCS[DataTypes.emailArray] = CAST_VALUE_FUNCS[DataTypes.stringArray];
CAST_VALUE_FUNCS[DataTypes.uriArray] = CAST_VALUE_FUNCS[DataTypes.stringArray];
CAST_VALUE_FUNCS[DataTypes.phoneArray] = CAST_VALUE_FUNCS[DataTypes.stringArray];
CAST_VALUE_FUNCS[DataTypes.string] = (value) => value.length > 0 ? value[0] : null;
CAST_VALUE_FUNCS[DataTypes.i18n] = CAST_VALUE_FUNCS[DataTypes.string];
CAST_VALUE_FUNCS[DataTypes.email] = CAST_VALUE_FUNCS[DataTypes.string];
CAST_VALUE_FUNCS[DataTypes.uri] = CAST_VALUE_FUNCS[DataTypes.string];
CAST_VALUE_FUNCS[DataTypes.phone] = CAST_VALUE_FUNCS[DataTypes.string];
CAST_VALUE_FUNCS[DataTypes.jsonArray] = (value) => value.map(val => JSON.parse(val));
CAST_VALUE_FUNCS[DataTypes.json] = (value) => value.length > 0 && value[0] !== '' ? JSON.parse(value[0]) : null;
CAST_VALUE_FUNCS[DataTypes.geographyArray] = (value) => value.map(val => JSON.parse(val) as GeometryObject);
CAST_VALUE_FUNCS[DataTypes.geography] = (value) => value.length > 0 && value[0] !== '' ? JSON.parse(value[0]) as GeometryObject : null;
CAST_VALUE_FUNCS[DataTypes.formula] = (value) => value.length > 0 ? value[0] : null;
CAST_VALUE_FUNCS[DataTypes.formulaArray] = (value) => value.slice();
/**
 * The following functions are used to object values to ENO field values
 */
export const PARSE_VALUE_FUNCS = {};
PARSE_VALUE_FUNCS[DataTypes.string] = (value) => [value];
PARSE_VALUE_FUNCS[DataTypes.stringArray] = (value) => value.slice();
PARSE_VALUE_FUNCS[DataTypes.i18n] = (value) => [value];
PARSE_VALUE_FUNCS[DataTypes.i18nArray] = (value) => value.slice();
PARSE_VALUE_FUNCS[DataTypes.email] = (value) => [value];
PARSE_VALUE_FUNCS[DataTypes.emailArray] = (value) => value.slice();
PARSE_VALUE_FUNCS[DataTypes.uri] = (value) => [value];
PARSE_VALUE_FUNCS[DataTypes.uriArray] = (value) => value.slice();
PARSE_VALUE_FUNCS[DataTypes.phone] = (value) => [value];
PARSE_VALUE_FUNCS[DataTypes.phoneArray] = (value) => value.slice();
PARSE_VALUE_FUNCS[DataTypes.number] = (value) => [value.toString()];
PARSE_VALUE_FUNCS[DataTypes.numberArray] = (value) => value.map(val => val.toString());
PARSE_VALUE_FUNCS[DataTypes.boolean] = (value) => [value ? 'true' : 'false'];
PARSE_VALUE_FUNCS[DataTypes.booleanArray] = (value) => value.map(val => val ? 'true' : 'false');
PARSE_VALUE_FUNCS[DataTypes.datetime] = (value) => moment.isMoment(value) && value.isValid() ? [value.toISOString(true)] : [];
PARSE_VALUE_FUNCS[DataTypes.datetimeArray] = (value) => value.filter(val => moment.isMoment(val) && val.isValid()).map(
  val => val.toISOString(true)
);
PARSE_VALUE_FUNCS[DataTypes.json] = (value) => [JSON.stringify(value)];
PARSE_VALUE_FUNCS[DataTypes.jsonArray] = (value) => value.map(val => JSON.stringify(val));
PARSE_VALUE_FUNCS[DataTypes.geography] = (value) => [JSON.stringify(value)];
PARSE_VALUE_FUNCS[DataTypes.geographyArray] = (value) => value.map(val => JSON.stringify(val));
PARSE_VALUE_FUNCS[DataTypes.formula] = (value) => [value];
PARSE_VALUE_FUNCS[DataTypes.formulaArray] = (value) => value.slice();
