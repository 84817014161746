import { IObject } from '../data/models/object';
import { Formula, Tip } from '../data/models/types';
import { DataTypes, IObjectScheme } from '../data/models/scheme'
import * as moment from 'moment';

export interface IVoiceMessageRecipientsByType {
  to?: Tip[];
  toFormulas?: string[];
}

export enum VOICE_MESSAGE_COMPOSE_TYPE {
  NEW = 'new',
  REPLY = 'reply',
  FORWARD = 'forward',
  WORKFLOW = 'workflow'
}

export enum VOICE_MESSAGE_PRIMITIVE_FOLDER {
  INBOX = 'Inbox',
  SENT = 'Sent',
  DRAFT = 'Draft'
}

export enum VOICE_MESSAGE_OUTBOUND_SRC_STATUS {
  DRAFT = 'draft',
  SENT = 'sent'
}

export interface IVoiceMessageSubstitution extends IObject {
  key: string;
  label: string;
  value: Formula;
}

/*  // required for phase 2
export const voiceMessageSubstitutionScheme: IObjectScheme = {
  'app/voice-message/substitution:key': { },
  'app/voice-message/substitution:label': { },
  'app/voice-message/substitution:value': { type: DataTypes.formula }
};
*/

export interface IVoiceMessageAdhocNumber extends IObject {
  label: string;
  number: string;
}

export const voiceMessageAdhocNumberScheme: IObjectScheme = {
  'app/voice-message/addhoc-number:label': { },
  'app/voice-message/addhoc-number:number': { }
};

export interface IVoiceMessageRecipient extends IObject {
  name: string;
  number: string;
  status: string;
  statusMessage?: string;
  response?: string;
  source: Tip;
  substitutionFormulas?: string[];
  token: string;
}

export const voiceMessageRecipientScheme: IObjectScheme = {
  'app/voice-message/recipient:name': { },
  'app/voice-message/recipient:number': { },
  'app/voice-message/recipient:status': { },
  'app/voice-message/recipient:status-message': { },
  'app/voice-message/recipient:response': { },
  'app/voice-message/recipient:source': { },
  'app/voice-message/recipient:substitution-formulas': { type: DataTypes.stringArray },
};

export interface IVoiceMessageFromNumber extends IObject {
  number: string;
  label: string;
  verified: boolean;
}

export const voiceMessageFromNumberScheme: IObjectScheme = {
  'app/voice-message/from-number:number': { },
  'app/voice-message/from-number:label': { },
  'app/voice-message/from-number:verified': { type: DataTypes.boolean },
};

export interface IVoiceMessageOutboundSource extends IObject {
  to?: Tip[];
  toNumberFormula?: string;
  fromNumber: Tip;
  context?: Tip;
  contextFormula?: Formula;
  contextFormulaMeta?: string;
  body: string;
  language: string;
  status: VOICE_MESSAGE_OUTBOUND_SRC_STATUS;
  // messageSubstitutions?: IVoiceMessageSubstitution[]; // required for phase 2
  // recipientSubstitutionFormulas?: string[];  // not sure if this is even required
  billingCode?: Tip;
  draftSavedDatetime?: moment.Moment;
  template?: Tip;
  relatedObjects?: Tip;
}

export const voiceMessageOutboundSourceScheme: IObjectScheme = {
  'app/voice-message/outbound-source:to': { type: DataTypes.stringArray },
  'app/voice-message/outbound-source:to-number-formula': { type: DataTypes.formula },
  'app/voice-message/outbound-source:from-number': { },
  'app/voice-message/outbound-source:context': { },
  'app/voice-message/outbound-source:context-formula': { type: DataTypes.formula },
  'app/voice-message/outbound-source:context-formula-meta': { },
  'app/voice-message/outbound-source:language': { },
  'app/voice-message/outbound-source:body': { },
  'app/voice-message/outbound-source:billing-code': { },
  'app/voice-message/outbound-source:status': { },
  'app/voice-message/outbound-source:draft-saved-datetime': { type: DataTypes.datetime },
  'app/voice-message/outbound-source:template': { },
  'app/voice-message/outbound-source:related-objects': { },
};

export interface IVoiceMessageOutbound extends IObject {
  voiceMessageSource: IVoiceMessageOutboundSource;
  sentSource: IVoiceMessageOutboundSource;
  to: Tip[];
  status: 'draft' | 'sent';
  statusMessage?: string;
  fromName: string;
  fromNumber: string;
  sentDatetime?: moment.Moment;
}

export const voiceMessageOutboundScheme: IObjectScheme = {
  'app/voice-message/outbound:voice-message-source': {
    type: DataTypes.object,
    scheme: voiceMessageOutboundSourceScheme
  },
  'app/voice-message/outbound:sent-source': {
    type: DataTypes.object,
    scheme: voiceMessageOutboundSourceScheme
  },
  'app/voice-message/outbound:to': { type: DataTypes.stringArray },
  'app/voice-message/outbound:status': { },
  'app/voice-message/outbound:status-message': { },
  'app/voice-message/outbound:from-name': { },
  'app/voice-message/outbound:from-number': { },
  'app/voice-message/outbound:sent-datetime': { type: DataTypes.datetime }
};

export interface IVoiceMessageOutboundTemplate extends IObject {
  name: string;
  contextType?: Tip;
  to: Tip[];
  fromNumber: Tip;
  language: string;
  body: string;
  billingCode?: Tip;
  // messageSubstitutions?: IVoiceMessageSubstitution[]; // required for phase 2
  // recipientSubstitutionFormulas?: Tip[]; // check if required for phase 2
}

export const voiceMessageOutboundTemplateScheme: IObjectScheme = {
  'app/voice-message/outbound-template:name': { },
  'app/voice-message/outbound-template:context-type': { },
  'app/voice-message/outbound-template:to': { type: DataTypes.stringArray },
  'app/voice-message/outbound-template:from-number': { },
  'app/voice-message/outbound-template:language': { },
  'app/voice-message/outbound-template:body': { },
  'app/voice-message/outbound-template:billing-code': { },
  /*  // required for phase 2
  'app/voice-message/outbound-template:message-substitutions': {
    type: DataTypes.objectArray,
    mutable: true,
    scheme: voiceMessageSubstitutionScheme
  },
  'app/voice-message/outbound-template:recipient-substitution-formulas': { type: DataTypes.stringArray },
  */
};

export interface IVoiceMessageInfo extends IObject {
  name: string;
  number: string;
}

export const voiceMessageInfoScheme: IObjectScheme = {
  'app/voice-message/number-info:name': { },
  'app/voice-message/number-info:number': { }
};
