import * as tslib_1 from "tslib";
import { environment } from '../../environments/environment';
import { get } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "../shell/services/me.service";
var GtmService = /** @class */ (function () {
    function GtmService(meService) {
        this.meService = meService;
    }
    GtmService.prototype.init = function (dataLayerWindow) {
        var _this = this;
        this.dataLayerWindow = dataLayerWindow || this.dataLayerWindow || window;
        this.push({
            release: environment.release,
            environment: environment.enCloudEnv,
            namespace: environment.ns,
            serviceUri: environment.host,
            customerUri: window.location.origin
        });
        this.meService.getMe$().subscribe(function (me) {
            if (me) {
                var fullName = get(me, 'fullName');
                _this.pushEvent('userSet', {
                    userName: ("" + fullName).replace(/(^\s+)|(\s+$)/g, ''),
                    userEmail: me.email,
                    userTip: me.$tip,
                    isAdministrator: (me.profiles.indexOf('app/profile/administrator') > -1 ? 'Yes' : 'No')
                });
            }
        });
    };
    GtmService.prototype.pushEvent = function (eventName, props) {
        this.dataLayer().push(tslib_1.__assign({}, props, { event: eventName }));
    };
    GtmService.prototype.push = function (props) {
        this.dataLayer().push(props);
    };
    GtmService.prototype.dataLayer = function () {
        this.dataLayerWindow = this.dataLayerWindow || window;
        if (!this.dataLayerWindow.dataLayer) {
            this.dataLayerWindow.dataLayer = [];
        }
        return this.dataLayerWindow.dataLayer;
    };
    GtmService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GtmService_Factory() { return new GtmService(i0.ɵɵinject(i1.MeService)); }, token: GtmService, providedIn: "root" });
    return GtmService;
}());
export { GtmService };
