import { Injectable } from '@angular/core';

import dataConstants from './constants';
import { SessionManagerService } from './session-manager.service';
import { Batch, Sid, Tip } from './models/types';
import { Eno } from './models/Eno';
import { OpPullService } from './op-pull.service';

interface IEnoBranchGroup {
  [branch: string]: {
    tips: Tip[];
    ifNotSids: Sid[];
  };
}

@Injectable({
  providedIn: 'root'
})
export class EnoWatchService {
  private readonly _enoTipsWatched: Set<Tip> = new Set();

  constructor(
    private _opPullService: OpPullService
  ) {
  }

  private _getKey(tip: Tip, branch: Tip): string {
    return `${tip}:${branch}`;
  }

  isWatched(tip: Tip, branch: Tip = dataConstants.BRANCH_MASTER): boolean {
    return this._enoTipsWatched.has(this._getKey(tip, branch));
  }

  markAsWatched(tipOrTips: Tip | Tip[], branch: Tip = dataConstants.BRANCH_MASTER) {
    const tips = typeof tipOrTips === 'string' ? [tipOrTips] : tipOrTips;

    tips.forEach((tip) => {
      this._enoTipsWatched.add(this._getKey(tip, branch));

      if (branch !== dataConstants.BRANCH_MASTER) {
        this._enoTipsWatched.add(this._getKey(tip, dataConstants.BRANCH_MASTER));
      }
    });
  }

  getWatchOpPulls(enos: Eno[]): Batch {
    const branchGroup: IEnoBranchGroup = {};
    const opPulls: Batch = [];

    for (const eno of enos) {
      if (eno.source.security === dataConstants.SECURITY.OP) {
        continue;
      }

      const branch = eno.getBranch();

      if (this._enoTipsWatched.has(this._getKey(eno.tip, branch))) {
        continue;
      }

      if (eno.source.deleted) {
        continue;
      }

      if (branchGroup[branch] === undefined) {
        branchGroup[branch] = { tips: [], ifNotSids: [] };
      }

      branchGroup[branch].tips.push(eno.tip);
      branchGroup[branch].ifNotSids.push(eno.sid);
    }

    for (const branch of Object.keys(branchGroup)) {
      opPulls.push(this._opPullService.createOpPull({
        tip: branchGroup[branch].tips,
        ifNotSid: branchGroup[branch].ifNotSids,
        branch: branch === dataConstants.BRANCH_MASTER ? [branch] : [branch, dataConstants.BRANCH_MASTER]
      }));
    }

    return opPulls;
  }
}
