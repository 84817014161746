import { environment } from '../../environments/environment';
import { map, switchMap } from 'rxjs/operators';
import { updateSpecLogo } from './api';
import * as i0 from "@angular/core";
import * as i1 from "../auth/auth-http-client.service";
import * as i2 from "../shared/load-script/load-script.service";
import * as i3 from "../dom-tokens";
export var REDOC_SCRIPT = environment.assetPath + "/assets/js/redoc/redoc.standalone.js";
var APIService = /** @class */ (function () {
    function APIService(authHttpClient, loadScript, window) {
        this.authHttpClient = authHttpClient;
        this.loadScript = loadScript;
        this.window = window;
        this.restAPIServicePath = "" + environment.host + environment.restPath;
    }
    APIService.prototype.getOpenAPISpec = function () {
        // use this url with the proxy.conf file when testing
        // const url = 'host/rest/docs/openapi.yaml'; // test url
        var _this = this;
        var url = this.restAPIServicePath + "/docs/openapi.yaml";
        return this.loadScript.load(REDOC_SCRIPT)
            .pipe(switchMap(function () { return _this.authHttpClient.get(url); }), map(updateSpecLogo), 
        // redoc is available after we load the script
        map(function (openAPISpec) { return ({ openAPISpec: openAPISpec, redoc: _this.window.Redoc }); }));
    };
    APIService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function APIService_Factory() { return new APIService(i0.ɵɵinject(i1.AuthHttpClientService), i0.ɵɵinject(i2.LoadScriptService), i0.ɵɵinject(i3.WINDOW)); }, token: APIService, providedIn: "root" });
    return APIService;
}());
export { APIService };
