import { Injectable } from '@angular/core';
import { Observable, Subject, ReplaySubject, of } from 'rxjs';
import { distinctUntilChanged, filter, map, finalize, publishReplay, refCount, switchMap } from 'rxjs/operators';
import { cloneDeep, isEqual, head, mapValues } from 'lodash';

import dataConstants from './constants';
import { Formula, IQueryResponse, Tip } from './models/types';
import { Eno } from './models/Eno';
import { EnoFactory } from './EnoFactory';
import { IVars, VarsService } from './vars.service';
import { EnoService } from './eno.service';
import { ObjectService } from './object-service/object.service';
import { EnsrvService } from './ensrv.service';
import { QueryResponseParserService } from './query-response-parser.service';
import { ESessionMessageDataType, ISessionMessageData, PubSubService } from './pub-sub.service';
import { LoggerService } from '../util/logger.service';
import { debugConfig } from '../../debugConfig';
import { removeListEscapes } from '../util/remove-escapes';
import { CurrentDatetimeService, NOW_VAR_NAME } from '../util/current-datetime.service';
import { groupedExcessiveOutputThrottle } from './grouped-excessive-output-throttle';
import { I18nService } from './i18n.service';

export interface IQueryExtraInfo {
  label: string;
  formula: Formula;
}

export interface IDimensionOption extends IQueryExtraInfo {
  sortby?: string[];
  sortdir?: ('asc' | 'desc')[];
  offset?: number;
  limit?: number;
}

export interface IQueryOption {
  branch?: Tip;
  langs?: string[];
  watch?: boolean;
  vars?: IVars;
  extraFilters?: IQueryExtraInfo[];
  extraAttributes?: IQueryExtraInfo[];
  dimensionOptions?: IDimensionOption[];
}

interface IOpQueryQuery { // Apparently back-end team name it like this...
  attributes?: IQueryExtraInfo[];
  filters?: IQueryExtraInfo[];
  vars?: IVars;
  dimensions?: IDimensionOption[];
}

interface IResponseStationInfo {
  broadcaster: Subject<IQueryResponse>;
  observable: Observable<IQueryResponse>;
}

interface IOpStorage {
  [opTip: string]: Eno;
}

export interface IQueryWatchData extends ISessionMessageData {
  type: ESessionMessageDataType.queryWatch;
  op: Tip;
  tips: Tip[];
}

export interface IQueryGenericResult {
  [key: string]: any;
}

export const DEFAULT_DIMENSION: IQueryExtraInfo[] = [{ label: 'Tip dimension', formula: 'TIP()' }];

export type QueryResultArrayish<T> = { [key in keyof T]: T[key][] };

// To avoid negative lookbehind issue with minification tool (https://github.com/terser-js/terser/issues/269),
// RegExp has been split and will only be evaluated at runtime.
// If this issue is resolved, this line should become:
// export const UNESCAPED_COMMA_REGEXP: RegExp = /(?<!\\),/;

@Injectable({
  providedIn: 'root'
})
export class QueryService {

  private debugConfig = debugConfig;

  /**
   * Start listening for enos as they arrive from Ensrv
   */
  constructor(
    private _varsService: VarsService,
    private _enoService: EnoService,
    private _objectService: ObjectService,
    private _ensrvService: EnsrvService,
    private _pubSubService: PubSubService,
    private _loggerService: LoggerService,
    private _queryResponseParserService: QueryResponseParserService,
    private _currentDatetimeService: CurrentDatetimeService,
    private i18nService: I18nService
  ) {
    this._pubSubService.receiveSessionMessage(ESessionMessageDataType.queryWatch).pipe(
      filter((data: IQueryWatchData) => {
        return this._responseStation[data.op] !== undefined && this.opStorage[data.op] !== undefined;
      }),
      groupedExcessiveOutputThrottle(10000, (data: IQueryWatchData) => data.op, _loggerService)
    ).subscribe(
      (data: IQueryWatchData) => this.handleQueryWatchData(this.opStorage[data.op])
    );

    this._ensrvService.getEnoReceiver('response/query').subscribe(responseEno => {
      this._broadcastResult(responseEno);
    });
  }

  private _responseStation: { [queryOpTip: string]: IResponseStationInfo } = {};
  private _opQueryFactory: EnoFactory = new EnoFactory('op/query', dataConstants.SECURITY.OP);
  private opStorage: IOpStorage = {};

  /** return a comma separated result from a single array result */
  static commaSeparatedResult<T>(input: QueryResultArrayish<T>): T {
    return mapValues(input, (value: any[]) => value.join(', ')) as unknown as T;
  }

  /** return a comma separated result from an array result in array */
  static commaSeparatedResults<T>(inputs: QueryResultArrayish<T>[]): T[] {
    return inputs.map(i => QueryService.commaSeparatedResult<T>(i));
  }

  static firstResult<T>(input: QueryResultArrayish<T>): T {
    return mapValues(input, head) as unknown as T;
  }

  static firstResults<T>(inputs: QueryResultArrayish<T>[]): T[] {
    return inputs.map(i => QueryService.firstResult<T>(i));
  }

  private handleQueryWatchData(opQuery: Eno) {
    this._enoService.writeEno(opQuery).subscribe(
      () => {
        if (this.debugConfig.query.watch.watchProgress) {
          this._loggerService.debug('op/query is sent triggered by query watch', opQuery);
        }
      },
      (e) => {
        if (this.debugConfig.query.watch.watchProgress) {
          this._loggerService.error('op/query failed triggered by query watch', opQuery, e);
        }
      }
    );
  }

  private _broadcastResult(responseEno: Eno) {
    const opQueryTip = responseEno.getFieldStringValue('response/query/op-tip');

    const responseStation = this._responseStation[opQueryTip];

    if (!responseStation) {
      return;
    }

    this._queryResponseParserService.parse(responseEno).subscribe(
      queryResponse => responseStation.broadcaster.next(queryResponse),
      err => this._sendResponseBroadcasterError(opQueryTip, 'Unable to parse query response')
    );
  }

  /**
   * Executes a query mapping the results to a simple array of objects on the assumption the query is one-dimensional
   * Returns each attribute's first result, if you expect a comma separated value, use execute1dCommaSeparated
   * or if you expect the full array of result for the attribute, use execute1dArray
   */
  public execute1dFirst<T extends IQueryGenericResult>(
    queryTip: Tip,
    options: IQueryOption = {
      branch: dataConstants.BRANCH_MASTER,
      watch: true,
      vars: {},
      extraFilters: [],
      extraAttributes: [],
      dimensionOptions: DEFAULT_DIMENSION
    }
  ): Observable<T[]> {
    return this.execute1dArray<QueryResultArrayish<T>>(queryTip, options).pipe(
      map(results => QueryService.firstResults<T>(results))
    );
  }

  /**
   * Executes a query mapping the results to a simple array of objects on the assumption the query is one-dimensional
   * Returns each attribute's comma separated joined result, if you expect only the first value, use execute1dFirst
   * or if you expect the full array of result for the attribute, use execute1dArray
   */
  public execute1dCommaSeparated<T extends IQueryGenericResult>(
    queryTip: Tip,
    options: IQueryOption = {
      branch: dataConstants.BRANCH_MASTER,
      watch: true,
      vars: {},
      extraFilters: [],
      extraAttributes: [],
      dimensionOptions: DEFAULT_DIMENSION
    }
  ): Observable<T[]> {
    return this.execute1dArray<QueryResultArrayish<T>>(queryTip, options).pipe(
      map(results => QueryService.commaSeparatedResults<T>(results))
    );
  }

  /**
   * Executes a query mapping the results to a simple array of objects on the assumption the query is one-dimensional
   * Returns each attribute result as an array, if you expect a comma separated value, use execute1dCommaSeparated
   * or if you expect only the first value, use execute1dFirst
   */
  public execute1dArray<T extends QueryResultArrayish<IQueryGenericResult>>(
    queryTip: Tip,
    options: IQueryOption = {
      branch: dataConstants.BRANCH_MASTER,
      watch: true,
      vars: {},
      extraFilters: [],
      extraAttributes: [],
      dimensionOptions: DEFAULT_DIMENSION
    }
  ): Observable<T[]> {
    return this.execute(queryTip, options).pipe(
      map((response: IQueryResponse) => {
        const keys = response.dimensions[0].values;

        return response.results.map((resultWrapper, i) => {
          const result = resultWrapper[keys[i]];

          for (const property in result) {
            if (result.hasOwnProperty(property) && typeof result[property] === 'string') {
              const formattedResult = removeListEscapes(result[property]);
              result[property] = singleFilter(formattedResult);
            }
          }

          return result as unknown as T;
        });
      })
    );
  }

  /**
   * Executes a query on Ensrv
   */
  public execute(
    queryTip: Tip,
    options: IQueryOption = {
      branch: dataConstants.BRANCH_MASTER,
      watch: true,
      vars: {},
      extraFilters: [],
      extraAttributes: [],
      dimensionOptions: DEFAULT_DIMENSION
    }
  ): Observable<IQueryResponse> {
    return this.getLangsOption(options).pipe(
      switchMap((langs: string[]) => {
        this._normalizeQueryOptions(options, langs);
        const opQuery = this._prepareInlineOperation(queryTip, options);
        this.opStorage[opQuery.tip] = opQuery;
        const responseStationInfo = this._getResponseBroadcaster(opQuery.tip);

        this._ensrvService.send([opQuery]).subscribe(
          responseBatch => {
            if (responseBatch.filter(eno => eno.getType() === 'response/query').length === 0) {
              return this._sendResponseBroadcasterError(opQuery.tip, 'Query execution failed. Probably an invalid query.');
            }
          },
          () => this._sendResponseBroadcasterError(opQuery.tip, 'Query execution failed')
        );

        return responseStationInfo.observable.pipe(
          distinctUntilChanged((prev, next) => {
            return isEqual(prev.results, next.results);
          }),
          // need this as downstream service mutate the response :(
          map((v) => cloneDeep(v))
        );
      })
    );
  }

  private getLangsOption(options: IQueryOption): Observable<string[]> {
    if (Array.isArray(options.langs) && options.langs.length > 0) {
      return of(options.langs);
    }

    return this.i18nService.acceptableLocaleIds$;
  }

  private _normalizeQueryOptions(options: IQueryOption, langs: string[]) {
    options.branch = options.branch || dataConstants.BRANCH_MASTER;
    options.langs = langs;
    options.watch = options.watch === undefined ? true : options.watch;
    options.vars = options.vars || {};
    options.vars[NOW_VAR_NAME] = [this._currentDatetimeService.getCurrentDatetime()];
    options.extraFilters = options.extraFilters || [];
    options.extraAttributes = options.extraAttributes || [];
    options.dimensionOptions = options.dimensionOptions || DEFAULT_DIMENSION;
  }

  private _prepareInlineOperation(queryTip: Tip, options: IQueryOption): Eno {
    this._opQueryFactory
      .setField({ tip: 'op/query/tip', value: [queryTip] })
      .setField({ tip: 'op/query/branch', value: [options.branch] })
      .setField({ tip: 'op/query/watch', value: [options.watch ? 'true' : 'false'] })
      .setField({ tip: 'op/query/lang', value: options.langs });

    const opQueryQuery: IOpQueryQuery = {
      attributes: options.extraAttributes,
      filters: options.extraFilters,
      dimensions: options.dimensionOptions,
      vars: options.vars
    };
    this._opQueryFactory.setField({ tip: 'op/query/query', value: [JSON.stringify(opQueryQuery)] });

    return this._opQueryFactory.makeEno();
  }

  private _getResponseBroadcaster(opQueryTip: Tip): IResponseStationInfo {
    if (!this._responseStation[opQueryTip]) {
      const broadcaster = new ReplaySubject<IQueryResponse>(1);
      const observable = broadcaster.pipe(
        // finalize(() => this._ungetResponseBroadcaster(opQueryTip)), // todo - this broke SideSheetObjectChooserComponent - seems an issue with async pipe and finalize()
        publishReplay(1),
        refCount()
      );
      this._responseStation[opQueryTip] = { broadcaster, observable };
    }
    return this._responseStation[opQueryTip];
  }

  private _ungetResponseBroadcaster(opQueryTip: Tip) {
    if (this._responseStation[opQueryTip]) {
      const unwatchOpEnofactory = new EnoFactory('op/watch/unregister', dataConstants.SECURITY.OP);

      const unwatchOpEno = unwatchOpEnofactory
        .setFields([{ tip: 'op/watch/unregister:op-id', value: [opQueryTip] }])
        .makeEno();

      this
        ._ensrvService
        .send([unwatchOpEno])
        .subscribe();

      delete this._responseStation[opQueryTip];
    }
  }

  private _sendResponseBroadcasterError(opQueryTip: Tip, message: string) {
    if (this._responseStation[opQueryTip]) {
      this._responseStation[opQueryTip].broadcaster.error(new Error(message));
      delete this._responseStation[opQueryTip];
    }
  }

  deleteEmptyProp<T>(results: T[], propsToDelete: string[]): T[] {
    results.forEach((result) => {
      propsToDelete.forEach((prop) => {
        if (result[prop] === '') {
          delete result[prop];
        }
      });
    });

    return results;
  }
}

function singleFilter(array: string[]): string[] {
  if (array.length === 1 && array[0].length === 0) {
    return [];
  }

  return array;
}


