import * as tslib_1 from "tslib";
import { FieldType } from '@ngx-formly/core';
import { OnInit } from '@angular/core';
import { get } from 'lodash';
var FormlyFieldChartContentComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FormlyFieldChartContentComponent, _super);
    function FormlyFieldChartContentComponent(chartDesignerService) {
        var _this = _super.call(this) || this;
        _this.chartDesignerService = chartDesignerService;
        _this.chart$ = null;
        _this.config = {
            filterInputs: [],
            title: 'Unset',
            chartTip: null,
            canExport: false
        };
        return _this;
    }
    FormlyFieldChartContentComponent.prototype.ngOnInit = function () {
        this.setChart();
        this.contextTip = get(this.options, 'formState.objectAndType.objectData.$tip', null);
    };
    FormlyFieldChartContentComponent.prototype.setChart = function () {
        var config = get(this, 'to.chartConfig', null);
        if (config.chartTip) {
            this.config = config;
            this.chart$ = this.chartDesignerService.getChart(this.config.chartTip);
        }
    };
    return FormlyFieldChartContentComponent;
}(FieldType));
export { FormlyFieldChartContentComponent };
