import { ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

export type SheetSize = 'sm' | 'lg' | 'xl';

@Injectable({
  providedIn: 'root'
})
export class SideSheetService {
  sideSheet;

  clearing$ = new Subject();
  depth$ = new BehaviorSubject<number>(0);

  constructor() {}

  init(sideSheet) {
    this.sideSheet = sideSheet;
  }

  push(content: any, params?: {injector?: Injector, componentFactoryResolver?: ComponentFactoryResolver,
    onPop?: () => void, crumbPrefix?: string, crumbSuffix?: string }) {
    return this.sideSheet.push(content, params);
  }

  replace(content: any, params?: {injector?: Injector, componentFactoryResolver?: ComponentFactoryResolver, onPop?: () => void }) {
    return this.sideSheet.replace(content, params);
  }

  pop(useHistory = false) {
    return this.sideSheet.pop(useHistory);
  }

  clear(useHistory = false) {
    return this.sideSheet.clear(useHistory);
  }

  resizeTo(size: SheetSize) {
    return this.sideSheet.resizeTo(size);
  }

  getSize(): SheetSize {
    return this.sideSheet.size;
  }

  generateBreadcrumbString(): string {
    return this.sideSheet.generateBreadcrumbString();
  }

  pushBreadcrumbSuffix(input: string) {
    this.sideSheet.breadcrumbSuffix.push(input);
  }

  makeDirty(sideSheetStackId = -1) {
    // The setTimeout is here to make sure enough time is given for a side sheet to be popped before the dirtyStatus is updated.
    // @todo Have a better solution.
    setTimeout(() => this.sideSheet.makeDirty(sideSheetStackId));
  }

  makeClean() {
    return this.sideSheet.makeClean();
  }

  clearing() {
    this.clearing$.next();
  }

}
