import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnDestroy } from '@angular/core';
import { SideSheetBase } from '../../side-sheet/side-sheet-base';
import { TRACKING_STATUS, DEFAULT_SETTING_PRINT } from '../print';
import { PrintPdfExportSideSheetComponent } from '../print-pdf-export-side-sheet/print-pdf-export-side-sheet.component';
import { BehaviorSubject, of, Subject, throwError } from 'rxjs';
import { filter, switchMap, takeUntil, takeWhile, tap, first } from 'rxjs/operators';
import { SideSheetListModes } from '../../side-sheet/side-sheet-list/side-sheet-list.component';
import { SideSheetOptionListComponent } from '../../side-sheet/side-sheet-option-list/side-sheet-option-list.component';
var PrintHtmlPreviewSideSheetComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PrintHtmlPreviewSideSheetComponent, _super);
    function PrintHtmlPreviewSideSheetComponent(printService, sideSheetService, toastService, domSanitizer, printingManagerService, cdr) {
        var _this = _super.call(this) || this;
        _this.printService = printService;
        _this.sideSheetService = sideSheetService;
        _this.toastService = toastService;
        _this.domSanitizer = domSanitizer;
        _this.printingManagerService = printingManagerService;
        _this.cdr = cdr;
        _this.loading$ = new BehaviorSubject(true);
        _this.unsubscribe$ = new Subject();
        return _this;
    }
    PrintHtmlPreviewSideSheetComponent.prototype.setProps = function (props) {
        this.props = props;
        this.onPrinting(this.props);
    };
    PrintHtmlPreviewSideSheetComponent.prototype.onPrinting = function (props) {
        var _this = this;
        this.printingManagerService.getObjectPrintTemplateUsages(props.contextTip).pipe(first()).subscribe(function (printTemplateUsages) {
            switch (printTemplateUsages.length) {
                case 0:
                    _this.triggerGenerateHtml(props);
                    break;
                case 1:
                    _this.printByTemplate(printTemplateUsages[0].$tip);
                    break;
                default:
                    _this.openPrintTemplateUsageList(printTemplateUsages);
                    break;
            }
        });
    };
    PrintHtmlPreviewSideSheetComponent.prototype.openPrintTemplateUsageList = function (printTemplateUsages) {
        var _this = this;
        var drilldownRef = this.sideSheetService.push(SideSheetOptionListComponent);
        drilldownRef.componentInstance.listType = SideSheetListModes.SINGLE_SELECT;
        drilldownRef.componentInstance.options = printTemplateUsages.sort().map(function (item) { return ({ label: item.name, value: item.$tip }); });
        drilldownRef.componentInstance.label = 'Select print template';
        drilldownRef.componentInstance.hideBack = true;
        drilldownRef.componentInstance.done = function (response) {
            _this.printByTemplate(response.selected);
        };
    };
    PrintHtmlPreviewSideSheetComponent.prototype.printByTemplate = function (templateTip) {
        var _this = this;
        this.printingManagerService.getPrintTemplateUsage(templateTip)
            .pipe(first())
            .subscribe(function (printUsage) {
            var props = {
                templateTip: printUsage.exportFormat,
                settingTip: DEFAULT_SETTING_PRINT,
                contextTip: _this.props.contextTip,
                formTip: printUsage.form
            };
            _this.triggerGenerateHtml(props);
        });
    };
    PrintHtmlPreviewSideSheetComponent.prototype.triggerGenerateHtml = function (props) {
        var _this = this;
        this
            .printService
            .generateHtml(props)
            .pipe(tap(function (_a) {
            var trackingTip = _a.trackingTip;
            return _this.props.trackingTip = trackingTip;
        }), switchMap(function () { return _this.printService.getTrackingObject(_this.props.trackingTip); }), takeWhile(function (tracking) { return tracking.generateHtmlStatus === TRACKING_STATUS.PROGRESS; }, true), switchMap(function (tracking) {
            if (tracking.generateHtmlStatus === TRACKING_STATUS.FAILED) {
                return throwError('error processing generate html');
            }
            return of(tracking);
        }), filter(function (tracking) { return tracking.generateHtmlStatus === TRACKING_STATUS.COMPLETED; }), switchMap(function () { return _this.printService.download(_this.props.trackingTip); }), takeUntil(this.unsubscribe$))
            .subscribe(function (htmlContent) {
            _this.loading$.next(false);
            _this.htmlContent = _this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(htmlContent));
            _this.cdr.markForCheck();
        }, function () {
            _this.toastService.showErrorToast('Unable to generate preview. Please try again later.');
            _this.sideSheetService.pop();
        });
    };
    PrintHtmlPreviewSideSheetComponent.prototype.download = function () {
        if (!this.loading$.getValue()) {
            this.sideSheetService.pop();
            var componentInstance = this.sideSheetService.push(PrintPdfExportSideSheetComponent).componentInstance;
            componentInstance.setProps(this.props);
        }
    };
    PrintHtmlPreviewSideSheetComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    };
    return PrintHtmlPreviewSideSheetComponent;
}(SideSheetBase));
export { PrintHtmlPreviewSideSheetComponent };
