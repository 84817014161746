import { StripHtmlModule } from './../../shared/strip-html/strip-html.module';
import { NgModule } from '@angular/core';
import { SideSheetObjectChooserComponent } from './side-sheet-object-chooser.component';
import { CommonModule } from '@angular/common';
import { SideSheetHeaderModule } from '../side-sheet-header/side-sheet-header.module';
import { SideSheetSectionModule } from '../side-sheet-section/side-sheet-section.module';
import { SideSheetListModule } from '../side-sheet-list/side-sheet-list.module';
import { SideSheetListItemModule } from '../side-sheet-list-item/side-sheet-list-item.module';
import { EmptyStateModule } from '../../shared/empty-state/empty-state.module';
import { SpinnerModule } from '../../shared/spinner/spinner.module';
import { FilterAndAddModule } from '../../shared/filter-and-add/filter-and-add.module';
import { CanActionPipeModule } from '../../shared/can-action-pipe/can-action-pipe.module';
import { FilterPipeModule } from '../../shared/pipe.module';
import { SideSheetObjectChooserService } from './side-sheet-object-chooser.service';
import { AlertModule } from '../../shared/alert/alert.module';

@NgModule({
  declarations: [
    SideSheetObjectChooserComponent
  ],
  imports: [
    CommonModule,
    SideSheetHeaderModule,
    SideSheetSectionModule,
    SideSheetListModule,
    SideSheetListItemModule,
    EmptyStateModule,
    SpinnerModule,
    FilterAndAddModule,
    CanActionPipeModule,
    FilterPipeModule,
    AlertModule,
    StripHtmlModule
  ],
  providers: [
    SideSheetObjectChooserService
  ],
  exports: [
    SideSheetObjectChooserComponent
  ],
  entryComponents: [
    SideSheetObjectChooserComponent
  ]
})
export class SideSheetObjectChooserModule {}
