import { IInnerOperator, IOperators, reverseOperator } from './operators';
import { OPERATOR_TYPE } from '../../../models/query';

const dateEquals: IInnerOperator = {
  opNo: 2,
  toFormula: (leftArg, rightArg) => ({
    name: 'EQUALS',
    args: [
      {
        name: 'DATE_DIFF', args: [
          { name: 'TO_DATE', args: [leftArg] },
          { name: 'TO_DATE', args: [rightArg] },
          'seconds'
        ]
      },
      0
    ]
  })
};
const dateNotEquals = reverseOperator(dateEquals);

const dateLessThan: IInnerOperator = {
  opNo: 2,
  toFormula: (leftArg, rightArg) => ({
    name: 'OP',
    args: [
      {
        name: 'DATE_DIFF', args: [
          { name: 'TO_DATE', args: [leftArg] },
          { name: 'TO_DATE', args: [{ name: 'FIRST', args: [rightArg] }] },
          'seconds'
        ]
      },
      '<',
      0
    ]
  })
};
const dateGreaterThanOrEqualTo = reverseOperator(dateLessThan);

const dateGreaterThan: IInnerOperator = {
  opNo: 2,
  toFormula: (leftArg, rightArg) => ({
    name: 'OP',
    args: [
      {
        name: 'DATE_DIFF', args: [
          { name: 'TO_DATE', args: [leftArg] },
          { name: 'TO_DATE', args: [{ name: 'LAST', args: [rightArg] }] },
          'seconds'
        ]
      },
      '>',
      0
    ]
  })
};
const dateLessThanOrEqualTo = reverseOperator(dateGreaterThan);

const dateWithIn: IInnerOperator = {
  opNo: 2,
  toFormula: (leftArg, rightArg) => ({
    name: 'OP',
    args: [
      dateGreaterThanOrEqualTo.toFormula(leftArg, rightArg),
      '&&',
      dateLessThanOrEqualTo.toFormula(leftArg, rightArg)
    ]
  })
};
const dateNotWithIn = reverseOperator(dateWithIn);

export const dateOperators: IOperators = {
  operators: {
    [OPERATOR_TYPE.EQUALS]: dateEquals,
    [OPERATOR_TYPE.NOT_EQUALS]: dateNotEquals,
    [OPERATOR_TYPE.WITHIN]: dateWithIn,
    [OPERATOR_TYPE.NOT_WITHIN]: dateNotWithIn,
    [OPERATOR_TYPE.LESS_THAN]: dateLessThan, // leftArg is earlier than rightArg
    [OPERATOR_TYPE.GREATER_THAN]: dateGreaterThan, // leftArg is later than rightArg
    [OPERATOR_TYPE.LESS_THAN_EQUAL_TO]: dateLessThanOrEqualTo,
    [OPERATOR_TYPE.GREATER_THAN_EQUAL_TO]: dateGreaterThanOrEqualTo
  },
  getParts: formula => {
    let currentPart = formula;
    let negative = false;
    if (currentPart.name === 'NOT') {
      currentPart = currentPart.args[0];
      negative = true;
    }

    if (currentPart.name === 'OP') {
      if (currentPart.args[1] === '<') {
        return {
          operator: negative ? OPERATOR_TYPE.GREATER_THAN_EQUAL_TO : OPERATOR_TYPE.LESS_THAN,
          leftArg: currentPart.args[0].args[0].args[0],
          rightArg: currentPart.args[0].args[1].args[0].args[0]
        };
      }

      if (currentPart.args[1] === '>') {
        return {
          operator: negative ? OPERATOR_TYPE.LESS_THAN_EQUAL_TO : OPERATOR_TYPE.GREATER_THAN,
          leftArg: currentPart.args[0].args[0].args[0],
          rightArg: currentPart.args[0].args[1].args[0].args[0]
        };
      }

      if (currentPart.args[1] === '&&') {
        return {
          operator: negative ? OPERATOR_TYPE.NOT_WITHIN : OPERATOR_TYPE.WITHIN,
          leftArg: currentPart.args[0].args[0].args[0].args[0].args[0],
          rightArg: currentPart.args[0].args[0].args[0].args[1].args[0].args[0]
        };
      }

      throw new Error('Unknown operators:' + currentPart.name);
    }

    if (currentPart.name === 'EQUALS') {
      return {
        operator: negative ? OPERATOR_TYPE.NOT_EQUALS : OPERATOR_TYPE.EQUALS,
        leftArg: currentPart.args[0].args[0].args[0],
        rightArg: currentPart.args[0].args[1].args[0]
      };
    }

    throw new Error('Unknown operators: ' + currentPart.name);
  }
};
