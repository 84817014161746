import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/internal/operators';

import dataConstants from './constants';
import { EnoFactory } from './EnoFactory';
import { EnoService } from './eno.service';
import { Tip } from './models/types';
import { Eno } from './models/Eno';

export interface IVars {
  [key: string]: any[];
}

@Injectable({
  providedIn: 'root'
})
export class VarsService {
  private _varFactory = new EnoFactory('var', dataConstants.SECURITY.EVERYONE);

  constructor(private _enoService: EnoService) {}

  objectToEnos(vars: IVars): Eno[] {
    return Object.keys(vars).map((key) => {
      this._varFactory.setFields([
        { tip: 'var/key', value: [key] },
        { tip: 'var/value', value: vars[key] }
      ]);

      return this._varFactory.makeEno();
    });
  }

  enosToObject(varEnos: Eno[]): IVars {
    const result: IVars = {};

    varEnos.forEach((varEno) => {
      result[varEno.getFieldStringValue('var/key')] = varEno.getFieldValues('var/value');
    });

    return result;
  }

  tipsToObject(varTips: Tip[]): Observable<IVars> {
    return this._enoService.readEnos(varTips).pipe(
      take(1),
      map(this.enosToObject, this)
    );
  }
}
