import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { SideSheetService } from '../side-sheet.service';
import { IDateRange } from '../../models/date-range-filter';
import { CUSTOM_DATE_RANGE_FORM_FIELDS } from './custom-date-range-form';


@Component({
  selector: 'app-custom-date-range-side-sheet',
  templateUrl: './custom-date-range-side-sheet.component.html',
  styleUrls: ['./custom-date-range-side-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomDateRangeSideSheetComponent {
  done: (response: IDateRange) => void;

  goBack: () => void; // Called when the user pressed the back arrow

  from: string;
  to: string;
  customRangeDateForm = new FormGroup({
    from: new FormControl(''),
    to: new FormControl('')
  });

  fields = CUSTOM_DATE_RANGE_FORM_FIELDS;
  model: any = {};

  constructor(
    private sideSheetService: SideSheetService
  ) {

  }

  setProps(customRangeDate: any) {
    if (customRangeDate) {
      this.model = {
        from: customRangeDate.from && customRangeDate.from != 'Invalid date'? moment(customRangeDate.from) : null,
        to: customRangeDate.to && customRangeDate.to != 'Invalid date'  ? moment(customRangeDate.to) : null
      };
    }
  }

  onCancel() {
    this.goBack
      ? this.goBack()
      : this.sideSheetService.pop();
  }

  onDone(): void {
    this.done({
        from: this.model.from ? this.model.from.toISOString() : null,
        to: this.model.to ? this.model.to.toISOString() : null
    });
    this.sideSheetService.pop();
  }

}
