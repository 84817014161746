import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SimpleNotificationsModule } from '@noggin/angular2-notifications';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PinchZoomModule } from 'ngx-pinch-zoom';

import { SideSheetModule } from '../side-sheet/side-sheet.module';
import { SharedModule } from '../shared/shared.module';
import { LeftSidebarSubMenuDirective } from './directives/left-sidebar-sub-menu.directive';
import { LeftSidebarSubMenuListDirective } from './directives/left-sidebar-sub-menu-list.directive';
import { LeftSidebarSubMenuItemDirective } from './directives/left-sidebar-sub-menu-item.directive';
import { ShellComponent } from './shell/shell.component';
import { ModalComponent } from './modal/modal.component';
import { LeftSidebarComponent } from './left-sidebar/left-sidebar.component';
import { NotificationHistoryComponent } from './notification-history/notification-history.component';
import { HomeComponent } from './home/home.component';
import { PageNotFoundModule } from './page-not-found/page-not-found.module';
import { PreviewComponent } from './preview/preview.component';
import { SpinnerModule } from '../shared/spinner/spinner.module';
import { SwitchProfileSideSheetComponent } from './switch-profile-side-sheet/switch-profile-side-sheet.component';
import { EmptyStateModule } from '../shared/empty-state/empty-state.module';
import { AvatarViewModule } from '../shared/avatar-view/avatar-view.module';
import { NotificationCenterComponent } from './notification-center/notification-center.component';
import { AppListItemUpdateBackgroundColorDirective } from './directives/app-list-item-update-background-color.directive';
import { FilterAndAddModule } from '../shared/filter-and-add/filter-and-add.module';
import { AssetPipeModule } from '../shared/asset-pipe/asset-pipe.module';
import { PageNotAuthorizedModule } from './page-not-authorized/page-not-authorized.module';
import { FixTabNabbingModule } from '../shared/pipes/tab-nabbing/fix-tab-nabbing.module';
import { ClientBrandLogoModule } from './client-brand/client-brand-logo/client-brand-logo.module';
import { ClientBrandTitleModule } from './client-brand/client-brand-title/client-brand-title.module';
import { FromNowModule } from '../shared/pipes/from-now/from-now.module';
import { ZonedAsyncModule } from './../shared/pipes/zoned-async/zoned-async-module';
import { I18nModule } from '../i18n/i18n.module';
import { FeatureFlagModule } from '../util/feature-flag/feature-flag.module';
import { SearchBarModule } from '../shared/search-bar/search-bar.module';

@NgModule({
  declarations: [
    LeftSidebarSubMenuDirective,
    LeftSidebarSubMenuListDirective,
    LeftSidebarSubMenuItemDirective,
    ShellComponent,
    LeftSidebarComponent,
    NotificationHistoryComponent,
    ModalComponent,
    HomeComponent,
    PreviewComponent,
    SwitchProfileSideSheetComponent,
    NotificationCenterComponent,
    AppListItemUpdateBackgroundColorDirective,
  ],
  imports: [
    PinchZoomModule,
    PageNotFoundModule,
    CommonModule,
    RouterModule,
    NgbModule,
    PerfectScrollbarModule,
    SideSheetModule,
    SimpleNotificationsModule,
    SharedModule,
    SpinnerModule,
    PdfViewerModule,
    FormsModule,
    EmptyStateModule,
    AvatarViewModule,
    FilterAndAddModule,
    AssetPipeModule,
    MatIconModule,
    PageNotAuthorizedModule,
    FixTabNabbingModule,
    ClientBrandLogoModule,
    ClientBrandTitleModule,
    FromNowModule,
    ZonedAsyncModule,
    I18nModule,
    FeatureFlagModule,
    SearchBarModule,
  ],
  entryComponents: [
    SwitchProfileSideSheetComponent,
  ]
})
export class ShellModule {}
