import {
  ADDRESS_CHOOSER_FORMLY_CONFIG,
  BOOLEAN_CHOOSER_FORMLY_CONFIG,
  DATE_TIME_FORMLY_CONFIG,
  EMAIL_ADDRESS_FORMLY_CONFIG,
  GEO_CHOOSER_FORMLY_CONFIG,
  INPUT_FORMLY_CONFIG,
  NUMBER_FORMLY_CONFIG,
  PHONE_NUMBER_FORMLY_CONFIG,
  RICH_TEXT_FORMLY_CONFIG, STRING_INTERPOLATE_RICH_TEXT_FORMLY_CONFIG,
  STRING_INTERPOLATE_TEXT_FORMLY_CONFIG,
  WEB_ADDRESS_FORMLY_CONFIG
} from '../../../../../formly-noggin/ngx-formly-bootstrap/bootstrap.config';
import { FIELD_DATA_TYPE } from '../../../../../models/field';


export interface IFieldData {
  label: string;
  type: string;
  inputType?: string;
  placeholder?: string;
  isObject: boolean;
  dataOrObjectType: string[];
  stringInterpolatedType?: string;
}

export class WorkflowLineValueFormUtils {

  /* @toDo Add in remaining types. Severity: medium
  OPTION_LIST_FORMLY_CONFIG,
  RICH_TEXT_FORMLY_CONFIG,
  MULTI_LINE_TEXT_FORMLY_CONFIG,
  FILE_UPLOAD_FORMLY_CONFIG,
  // CONDITIONAL_FIELD_SELECTOR_FORMLY_CONFIG,
  LIST_ITEMS_SELECT_FORMLY_CONFIG,
  OBJECT_CHOOSER_FORMLY_CONFIG,
  */
  static getFieldData(): IFieldData[] {
    return [
      /**
       * primitive types
       */
      {
        type: BOOLEAN_CHOOSER_FORMLY_CONFIG.name,
        label: 'True or false',
        isObject: false,
        dataOrObjectType: [FIELD_DATA_TYPE.bool]
      },

      {
        type: NUMBER_FORMLY_CONFIG.name,
        label: 'Number',
        placeholder: 'Enter a number',
        inputType: 'number',
        isObject: false,
        dataOrObjectType: [FIELD_DATA_TYPE.number]
      },

      {
        type: DATE_TIME_FORMLY_CONFIG.name,
        label: 'Pick a date and time',
        placeholder: '',
        inputType: 'datetime',
        isObject: false,
        dataOrObjectType: [FIELD_DATA_TYPE.datetime]
      },

      {
        type: DATE_TIME_FORMLY_CONFIG.name,
        label: 'Pick a date',
        placeholder: '',
        inputType: 'date',
        isObject: false,
        dataOrObjectType: [FIELD_DATA_TYPE.date]
      },

      {
        type: INPUT_FORMLY_CONFIG.name,
        label: 'Text value',
        placeholder: 'Enter a text value',
        inputType: 'text',
        isObject: false,
        dataOrObjectType: [FIELD_DATA_TYPE.string, FIELD_DATA_TYPE.string_i18n],
        stringInterpolatedType: STRING_INTERPOLATE_TEXT_FORMLY_CONFIG.name
      },

      {
        type: PHONE_NUMBER_FORMLY_CONFIG.name,
        label: 'Phone number',
        placeholder: 'Enter a phone number',
        inputType: 'tel',
        dataOrObjectType: [FIELD_DATA_TYPE.phone],
        isObject: false
      },

      {
        type: WEB_ADDRESS_FORMLY_CONFIG.name,
        label: 'URL',
        inputType: 'url',
        placeholder: 'Enter a URL',
        dataOrObjectType: [FIELD_DATA_TYPE.uri],
        isObject: false
      },
      {
        type: EMAIL_ADDRESS_FORMLY_CONFIG.name,
        label: 'Email',
        placeholder: 'Enter an email address',
        inputType: 'email',
        dataOrObjectType: [FIELD_DATA_TYPE.email],
        isObject: false
      },
      {
        type: GEO_CHOOSER_FORMLY_CONFIG.name,
        label: '', // gets set based on typeGeoRestrict
        placeholder: '',
        dataOrObjectType: [FIELD_DATA_TYPE.geography],
        isObject: false
      },

      /**
       * object types
       */
      {
        type: ADDRESS_CHOOSER_FORMLY_CONFIG.name,
        label: 'Choose address',
        inputType: null,
        dataOrObjectType: ['app/address'],
        isObject: true
      },
      {
        type: RICH_TEXT_FORMLY_CONFIG.name,
        label: 'Enter a text value',
        inputType: null,
        dataOrObjectType: ['html'],
        isObject: false,
        stringInterpolatedType: STRING_INTERPOLATE_RICH_TEXT_FORMLY_CONFIG.name
      }
    ];
  }
}
