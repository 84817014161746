import * as tslib_1 from "tslib";
import { coerceToNumberIfPossible, isEmptyString } from './number-format-utils';
import { escapeRegExp } from 'lodash';
import Big from 'big.js';
import { Calculations } from './number-format-types';
export function addSuffix(_a) {
    var numberString = _a.numberString, format = _a.format;
    if (typeof format.suffix !== 'string') {
        return {
            numberString: numberString,
            format: format
        };
    }
    return {
        numberString: "" + numberString + format.suffix,
        format: format,
    };
}
export function addPrefix(_a) {
    var numberString = _a.numberString, format = _a.format;
    if (typeof format.prefix !== 'string') {
        return {
            numberString: numberString,
            format: format,
        };
    }
    return {
        numberString: "" + format.prefix + numberString,
        format: format,
    };
}
export function separateThousands(_a) {
    var numberString = _a.numberString, format = _a.format;
    if (typeof format.thousandsSeparator !== 'string') {
        return {
            numberString: numberString,
            format: format,
        };
    }
    var decimalSeparator = format.decimalSeparator || '.';
    var _b = tslib_1.__read(numberString.split(decimalSeparator), 2), integerString = _b[0], decimalString = _b[1];
    var thousandsRegex = new RegExp("(\\d)(?=((\\d){3})+(?!\\d))", 'g');
    var updatedInteger = integerString.replace(thousandsRegex, "$1" + format.thousandsSeparator);
    return {
        numberString: decimalString ? "" + updatedInteger + decimalSeparator + decimalString : updatedInteger,
        format: format,
    };
}
var GLOBAL_DECIMAL_REGEX = new RegExp(escapeRegExp('.'), 'g'); // select '.' \.
export function replaceDecimalSeparator(_a) {
    var numberString = _a.numberString, format = _a.format;
    if (typeof format.decimalSeparator !== 'string' || isEmptyString(format.decimalSeparator)) {
        return {
            numberString: numberString,
            format: format
        };
    }
    return {
        numberString: numberString.replace(GLOBAL_DECIMAL_REGEX, format.decimalSeparator),
        format: format
    };
}
export function formatDecimalPlaces(_a) {
    var number = _a.number, format = _a.format;
    var decimalPlaces = coerceToNumberIfPossible(format.decimalPlaces);
    if (!isNaN(decimalPlaces)) {
        // javascript does not round properly with .toFixed us bigNum
        var bigNum = new Big(number);
        var fixed = bigNum.toFixed(decimalPlaces);
        return {
            numberString: fixed.toString(),
            format: format
        };
    }
    return {
        numberString: number.toString(10),
        format: format
    };
}
export function formatCalculation(_a) {
    var number = _a.number, format = _a.format;
    if (format.calculations === Calculations.percent) {
        // need bigNum as JS does not like floats
        var bigNum = new Big(number);
        return {
            number: parseFloat(bigNum.times(100).toString()),
            format: format
        };
    }
    return { number: number, format: format };
}
