export const TO_BE_DEFINED_FORMULA = '---TO_BE_DEFINED_FORMULA---';

export const ADDRESS_TYPE_TIP = 'app/address';

export const LIST_TIP_TAG_PREFIX = 'listTip--';

export const DEFAULT_VALUE_TAG_PREFIX = 'defaultValue--';

export const VALUE_TYPE_LIST_TAG = 'valueType--app/list';

export const AUTOPOPULATE_TAG = 'autoPopulate--true';

export const LOG_TO_RELATED_TIMELINE_TAG = 'logToRelatedTimeline--true';

export const SEQUENCE_TIP_TAG_PREFIX = 'sequenceTip--';

export const VALUE_TYPE_SEQUENCE_TAG = 'valueType--app/sequence';

export const CALCULATION_EDITABLE = 'calculationEditable--true';

export const CALCULATION_AUTO_RECALCULATE = 'calculationAutoRecalculate--true';

export const CALCULATION_NO_VALUE_AS_ZERO = 'calculationNoValueAsZero--true';

export const SUB_OBJECT_TAG_KEY = 'isSubObject';
export const SUB_OBJECT_TRUE_TAG = `${SUB_OBJECT_TAG_KEY}--true`;
