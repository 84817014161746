import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionManagerService } from './data/session-manager.service';
import { environment } from '../environments/environment';

const xRayIncludesWhiteList = [
  environment.host
];

@Injectable()
export class XRayInterceptor implements HttpInterceptor {

  constructor(private sessionManager: SessionManagerService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isWhiteListed = xRayIncludesWhiteList
      .some((listValue) => req.url.includes(listValue));

    if (isWhiteListed) {
      const traceId = createAmznTraceId({
        sessionId: this.sessionManager.getSessionId(),
        namespace: environment.ns
      });

      const updatedReq = req.clone({
          setHeaders: {
            'X-Amzn-Trace-Id': traceId
          }
        }
      );
      return next.handle(updatedReq);
    } else {
      return next.handle(req);
    }
  }
}

interface IAmazonTraceId {
  namespace: string;
  sessionId?: string;
  resetCountTo?: number;
}

export function createAmznTraceId({sessionId, namespace, resetCountTo}: IAmazonTraceId, random= randomSeven) {
  const nowInSeconds = Math.floor(Date.now() / 1000);
  const nowInHex = nowInSeconds.toString(16);

  const count = hexCount(resetCountTo);
  const _sessionId = sessionId || `nos${random()}`;

  const tenCharNameSpace = tenCharString(namespace);
  const tenCharSessionId = tenCharString(_sessionId);
  const currentCount = count;

  return `Root=1-${nowInHex}-${tenCharNameSpace}${tenCharSessionId}${currentCount}`;
}

export function randomSeven() {
  return Math.floor((Math.random() * 1000000) + 1).toString().padStart(7, '0');
}

const _map = new Map();
export function tenCharString(string: string, map= _map) {

  const existing = map.get(string);
  if (existing) {
    return existing;
  }

  const length = string.length;
  const maxLength = string.length > 10
    ? 10
    : length;

  const result = string.substr(0, maxLength).padStart(10, '0');
  map.set(string, result);
  return result;
}

let _current = 0;
export function hexCount(current: number = _current): string {
  // 4294967295 is the maximum number for an 8 char hex number
  // parseInt(FFFF, 16)
  if (current < 65535) {
    current++;
  } else {
    current = 0;
  }

  // update our stateful current count
  _current = current;
  return current.toString(16).padStart(4, '0');
}

