import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { I18nValuePipe } from './i18n-value/i18n-value.pipe';

@NgModule({
  declarations: [
    I18nValuePipe
  ],
  exports: [
    I18nValuePipe
  ],
  imports: [
    CommonModule
  ]
})
export class I18nModule {}
