import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowMoreDirective } from './show-more.directive';

@NgModule({
  declarations: [
    ShowMoreDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ShowMoreDirective
  ]
})
export class ShowMoreModule { }
