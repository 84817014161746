import { first, map } from 'rxjs/operators';
import { get } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "../data/process.service";
var IsPasswordLoginService = /** @class */ (function () {
    function IsPasswordLoginService(processService) {
        this.processService = processService;
    }
    IsPasswordLoginService.prototype.checkEmailAddress$ = function (emailAddress) {
        var vars = { 'email': [emailAddress] };
        return this.processService
            .start('eim/process/sso/is-password-login', vars)
            .pipe(first(), map(function (result) {
            return get(result, 'vars.isPasswordLogin[0]', null);
        }));
    };
    IsPasswordLoginService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IsPasswordLoginService_Factory() { return new IsPasswordLoginService(i0.ɵɵinject(i1.ProcessService)); }, token: IsPasswordLoginService, providedIn: "root" });
    return IsPasswordLoginService;
}());
export { IsPasswordLoginService };
