import { Injectable } from '@angular/core';
import { concat, EMPTY, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, distinctUntilKeyChanged, filter, first, map, switchMap, finalize, publishReplay, refCount } from 'rxjs/operators';

import { CacheOpt, getCache } from '../util/cache';
import { LoggerService } from '../util/logger.service';
import { distinctUntilSidsChanged } from '../util/distinct-until-sids-changed';
import dataConstants from './constants';
import { EnoFactory } from './EnoFactory';
import { EnsrvService } from './ensrv.service';
import { EnoCacheService } from './eno-cache.service';
import { EnoWatchService } from './eno-watch.service';
import { Batch, Sid, Tip } from './models/types';
import { Eno } from './models/Eno';
import { AccessDeniedError } from './errors/server/AccessDeniedError';
import { OpPullService } from './op-pull.service';
import { ESessionMessageDataType, ISessionMessageData, PubSubService } from './pub-sub.service';

export interface ITipWatchData extends ISessionMessageData {
  type: ESessionMessageDataType.tipWatch;
  op: Tip;
  tip: Tip;
}

interface IEnoSubjectInfo {
  subject: Subject<Eno>;
  observable: Observable<Eno>;
}

interface IBatchSubjectInfo {
  subject: Subject<Batch>;
  observable: Observable<Batch>;
}

interface IReadEnoOptions {
  branch?: Tip;
  recursiveDepth?: number;
  recursiveFields?: Tip[];
  // prefer CacheOpts bool | null here for backwards compatibility
  useCache?: boolean | null | CacheOpt;
}

@Injectable({
  providedIn: 'root'
})
export class EnoService {
  private _enoFactory: EnoFactory = new EnoFactory();
  private _enoSubjects: { [opPullTip: string]: IEnoSubjectInfo } = {};
  private _batchSubjects: { [opPullTip: string]: IBatchSubjectInfo } = {};
  private awaitingPullTips: Set<Tip> = new Set<Tip>();

  constructor(
    private _ensrvService: EnsrvService,
    private _enoCacheService: EnoCacheService,
    private _enoWatchService: EnoWatchService,
    private _opPullService: OpPullService,
    private _pubSubService: PubSubService,
    private _loggerService: LoggerService
  ) {
    this._pubSubService.receiveSessionMessage(ESessionMessageDataType.tipWatch).pipe(
      filter((data: ITipWatchData) => {
        return !(this._enoSubjects[data.op] === undefined && this._batchSubjects[data.op] === undefined);
      })
    ).subscribe(
      (data: ITipWatchData) => this._handleTipWatchData(data)
    );
  }

  private _handleTipWatchData(data: ITipWatchData) {
    const enoSubject = this._enoSubjects[data.op];
    const batchSubject = this._batchSubjects[data.op];
    // This is op/pull with watch false and if-not-sid empty
    const opPullEno: Eno = this._opPullService.getOpPull(data.op);

    this._loggerService.debug('[ENO_WATCH] Handling....', data);

    if (enoSubject) {
      this._doOpPullForReadEno(
        opPullEno,
        enoSubject,
        opPullEno.getFieldStringValue('op/pull/tip'),
        opPullEno.getFieldValues('op/pull/branch')[0],
        opPullEno.getFieldNumberValue('op/pull/recursive-depth'),
        opPullEno.getFieldValues('op/pull/recursive-field')
      );

      return;
    }

    // @toDo: We can improve this part by specifying if-not-sid with sids we have in the cache. Severity: Low
    this._doOpPullForReadEnos(
      opPullEno,
      batchSubject,
      [],
      opPullEno.getFieldValues('op/pull/tip'),
      opPullEno.getFieldValues('op/pull/branch')[0]
    );
  }

  // Recursive pulling is just for pre-pulling objects to cache it.
  // So, note that readEno always returns a single eno even if recursive feature is used.
  readEno(
    tip: Tip,
    {
      branch = dataConstants.BRANCH_MASTER,
      recursiveDepth = 0,
      recursiveFields = [],
      useCache = null
    }: IReadEnoOptions =
      {
        branch: dataConstants.BRANCH_MASTER,
        recursiveDepth: 0,
        recursiveFields: [],
        useCache: null
      }
  ): Observable<Eno> {
    const enosInCache = this._enoCacheService.hasEno(tip, branch);
    const enoCached = this._enoCacheService.getEno(tip, branch);
    const toWatch = !this._enoWatchService.isWatched(tip, branch);

    const cacheOpt = getCache(useCache);
    const explicitUseCache = cacheOpt === CacheOpt.USE_CACHE;

    // @toDo: Always fallback to master. The below code should be the correct code when branch fallback is completely implemented
    // const branches = branch === dataConstants.BRANCH_MASTER ? [branch] : [branch, dataConstants.BRANCH_MASTER];
    const branches = branch === dataConstants.BRANCH_MASTER ? [branch] : [dataConstants.BRANCH_MASTER];
    // This opPull Eno is the deterministic op/pull eno without dynamic part: watch and ifNotSid
    const opPullKey: Tip = this._opPullService.createOpPull({
      tip: [tip],
      branch: branches,
      watch: true,
      recursiveDepth,
      recursiveField: recursiveFields
    }).tip;
    const opPullEnoToSend: Eno = this._opPullService.createOpPull({
      tip: [tip],
      branch: branches,
      watch: toWatch,
      recursiveDepth,
      recursiveField: recursiveFields
      // This being disabled is temporary. EIM-7110
      // ifNotSid: (recursiveDepth === 0 && enosInCache && !explicitUseCache ? [enoCached.sid] : [])
    });

    const subject = this._initEnoSubject(opPullKey);
    const enoObservable = subject.observable.pipe(
      distinctUntilKeyChanged('sid')
    );

    const cachedAndEnoObservable = concat(of(enoCached), enoObservable).pipe(
      distinctUntilKeyChanged('sid')
    );

    // if useCache is explicitly true return the cached eno, do not request an updated copy
    // only if we have enosInCache
    if (cacheOpt === CacheOpt.USE_CACHE && enosInCache) {
      return cachedAndEnoObservable;
    }

    // for all other cases request a fresh copy of the eno
    this._doOpPullForReadEno(opPullEnoToSend, subject, tip, branch, recursiveDepth, recursiveFields);

    // if useCache is explicitly false, we only want fresh result
    if (cacheOpt === CacheOpt.USE_NETWORK_NO_CACHE) {
      return enoObservable;
    }

    // otherwise check if we have enos cached
    if (enosInCache) {
      // we do, great, lets return them along with an observable that will emit the fresh result
      return cachedAndEnoObservable;
    }

    // finally if we didn't have any enos in our cache return the observable for the fresh result
    return enoObservable;
  }

  private _doOpPullForReadEno(
    opPullEno: Eno,
    subject: IEnoSubjectInfo,
    tip: Tip,
    branch: Tip,
    recursiveDepth: number,
    recursiveFields: Tip[]
  ) {
    // We do not want to send the same
    if (this.awaitingPullTips.has(opPullEno.tip)) {
      return;
    }

    this.awaitingPullTips.add(opPullEno.tip);

    this._ensrvService.send([opPullEno]).pipe(
      catchError((e) => {
        this.awaitingPullTips.delete(opPullEno.tip);
        this._loggerService.error(e);
        this._sendEnoSubjectError(subject, e);

        return EMPTY;
      })
    ).subscribe(
      (batch: Batch) => {
        this.awaitingPullTips.delete(opPullEno.tip);
        if (this._handleReadEnoError(subject, batch, tip, branch, recursiveDepth, recursiveFields)) {
          return;
        }

        this._enoWatchService.markAsWatched(tip, branch);
        this._cacheBatchOnRequestedBranch(batch, [tip], branch);

        const resultEno = this._extractTipEno(tip, batch);

        if (resultEno === null) {
          if (opPullEno.getFieldValues('op/pull/if-not-sid').length === 0) {
            // Show error if it wasn't a if-not-sid
            this._sendEnoSubjectError(
              subject,
              new Error(`op/pull response batch does not contain requested tip. tip: ${tip}, batch: [${batch.map(x => x.tip)}]`)
            );
          }
          return;
        }

        subject.subject.next(resultEno);
      }
    );
  }

  private _initEnoSubject(opPullTip: Tip): IEnoSubjectInfo {
    if (!this._enoSubjects[opPullTip]) {
      const subject = new Subject<Eno>();
      const observable = subject.pipe(
        finalize(() => this._removeEnoSubject(opPullTip)),
        publishReplay(1),
        refCount()
      );
      this._enoSubjects[opPullTip] = { subject, observable };
    }

    return this._enoSubjects[opPullTip];
  }

  private _removeEnoSubject(opPullTip: Tip) {
    if (this._enoSubjects[opPullTip]) {
      delete this._enoSubjects[opPullTip];
      // @todo Send op/watch/unregister
    }
  }

  private _sendEnoSubjectError(subject: IEnoSubjectInfo, error: Error) {
    Object.keys(this._enoSubjects).forEach(opPullTip => {
      if (this._enoSubjects[opPullTip] === subject) {
        subject.subject.error(error);
        delete this._enoSubjects[opPullTip];
      }
    });
  }

  private _extractTipEno(tip: Tip, batch: Batch): Eno {
    for (const eno of batch) {
      if (eno.tip === tip) {
        return eno;
      }
    }

    return null;
  }

  private _handleReadEnoError(
    subject: IEnoSubjectInfo,
    batch: Batch,
    tip: Tip,
    branch: Tip,
    recursiveDepth: number,
    recursiveFields: Tip[]
  ): boolean {
    const accessDeniedTip = this._checkAccessDenied(batch, tip, branch);

    if (accessDeniedTip) {
      this._sendEnoSubjectError(subject, new AccessDeniedError(accessDeniedTip, [branch]));

      return true;
    }

    if (this._containsError(batch)) {
      const e = new Error(`Error happens while reading object tip: ${tip}, branch: ${branch}, \
                          recursiveField: [${recursiveFields.join(', ')}], recursiveDepth: ${recursiveDepth}`);
      this._loggerService.error(e);
      this._sendEnoSubjectError(subject, e);

      return true;
    }

    return false;
  }

  readEnos(
    tips: Tip[],
    {
      branch = dataConstants.BRANCH_MASTER,
      useCache = null
    } = {
      branch: dataConstants.BRANCH_MASTER,
      useCache: null
    }
  ): Observable<Batch> {
    if (tips.length === 0) {
      return of([]);
    }

    let toWatch = false;

    const sidsNotToPull = [];
    let hitCacheAll = true;
    const batchFromCache: Batch = [];
    tips.forEach((tip: Tip) => {
      const hitCache = this._enoCacheService.hasEno(tip, branch);
      const enoCached = this._enoCacheService.getEno(tip, branch);

      toWatch = toWatch || !this._enoWatchService.isWatched(tip, branch);

      if (!hitCache) {
        hitCacheAll = false;
        return;
      }

      batchFromCache.push(enoCached);
      sidsNotToPull.push(enoCached.sid);
    });

    // Always fallback to master
    const branches = branch === dataConstants.BRANCH_MASTER ? [branch] : [branch, dataConstants.BRANCH_MASTER];
    const opPullKey: Tip = this._opPullService.createOpPull({
      tip: tips,
      branch: branches,
      watch: true
    }).tip;
    const opPullEnoToSend: Eno = this._opPullService.createOpPull({
      tip: tips,
      branch: branches,
      watch: toWatch
      // This being disabled is temporary. EIM-7110
      // ifNotSid: sidsNotToPull
    });
    const subject = this._initBatchSubject(opPullKey);

    if (!(hitCacheAll && useCache === true)) {
      this._doOpPullForReadEnos(opPullEnoToSend, subject, batchFromCache, tips, branch);
    }

    if (hitCacheAll) {
      return concat(of(batchFromCache), subject.observable).pipe(distinctUntilSidsChanged());
    }

    return subject.observable.pipe(distinctUntilSidsChanged());
  }

  private _doOpPullForReadEnos(opPullEno: Eno, subject: IBatchSubjectInfo, batchFromCache: Batch, tips: Tip[], branch: Tip) {
    if (this.awaitingPullTips.has(opPullEno.tip)) {
      return;
    }

    this.awaitingPullTips.add(opPullEno.tip);

    this._ensrvService.send([opPullEno]).pipe(
      catchError((e) => {
        this.awaitingPullTips.delete(opPullEno.tip);
        this._loggerService.error(e);
        this._sendBatchSubjectError(subject, e);

        return EMPTY;
      })
    ).subscribe(
      (batchFromServer: Batch) => {
        this.awaitingPullTips.delete(opPullEno.tip);
        if (this._handleReadEnosError(subject, batchFromServer, tips, branch)) {
          return;
        }

        this._enoWatchService.markAsWatched(tips, branch);
        this._cacheBatchOnRequestedBranch(batchFromServer, tips, branch);

        subject.subject.next(this._mergeBatch(tips, batchFromCache, batchFromServer));
      }
    );
  }

  private _initBatchSubject(opPullTip: Tip): IBatchSubjectInfo {
    if (!this._batchSubjects[opPullTip]) {
      const subject = new Subject<Batch>();
      const observable = subject.pipe();
      this._batchSubjects[opPullTip] = { subject, observable };
    }

    return this._batchSubjects[opPullTip];
  }

  private _sendBatchSubjectError(subject: IBatchSubjectInfo, error: Error) {
    Object.keys(this._batchSubjects).forEach(opPullTip => {
      if (this._batchSubjects[opPullTip] === subject) {
        subject.subject.error(error);
        delete this._batchSubjects[opPullTip];
      }
    });
  }

  // merge batchFromServer into batchFromCache preserving the order of tipsForOrder.
  // This method assumes given tips all exist either in batchFromCache or batchFromServer
  private _mergeBatch(tipsForOrder: Tip[], batchFromCache: Batch, batchFromServer: Batch): Batch {
    const concatenatedBatch = batchFromServer.concat(batchFromCache);

    return tipsForOrder.map((tip: Tip) => {
      for (const eno of concatenatedBatch) {
        if (tip === eno.tip) {
          return eno;
        }
      }
    });
  }

  private _handleReadEnosError(subject: IBatchSubjectInfo, batch: Batch, tips: Tip[], branch: Tip): boolean {
    const accessDeniedTip = this._checkAccessDenied(batch, tips, branch);

    if (accessDeniedTip) {
      this._sendBatchSubjectError(subject, new AccessDeniedError(accessDeniedTip, [branch]));

      return true;
    }

    if (this._containsError(batch)) {
      const e = new Error(`Error happens while reading object tips: [${tips.join(', ')}], branch: ${branch}`);
      this._loggerService.error(e);
      this._sendBatchSubjectError(subject, e);

      return true;
    }

    return false;
  }

  // tips are the tips we requested, this filter is necessary as response can come with any other enos
  private _cacheBatchOnRequestedBranch(batch: Batch, tips: Tip[], branch: Tip) {
    batch.filter((eno) => {
      return tips.indexOf(eno.tip) > -1;
    }).forEach((eno) => {
      // No matter which branch the actual eno belongs to, cache it against the requested branch
      // This is to support branch fallback, DO NOT REMOVE THIS CODE
      this._enoCacheService.setEno(eno.tip, eno, branch);
    });
  }

  // Returns the tip that is access denied, if access denied error is not found, returns null
  private _checkAccessDenied(batch: Batch, tips: Tip | Tip[], branch: Tip): Tip | null {
    if (!Array.isArray(tips)) {
      tips = [tips];
    }

    let result: Tip = null;

    batch.forEach((eno) => {
      if (eno.getType() === 'error' && eno.getFieldStringValue('error/message/tip') === 'error/message/security/access-denied' &&
        tips.indexOf(eno.getFieldStringValue('error/object/tip')) > -1 && branch === eno.getFieldStringValue('error/object/branch')) {
        result = eno.getFieldStringValue('error/object/tip');
      }
    });

    return result;
  }

  private _containsError(batch: Batch): boolean {
    for (const eno of batch) {
      if (eno.getType() === 'error') {
        return true;
      }
    }

    return false;
  }

  writeEno(eno: Eno): Observable<any> {
    return this.writeEnos([eno]);
  }

  writeEnos(enos: Batch): Observable<any> {
    let batch: Batch = enos;

    batch = batch.concat(this._enoWatchService.getWatchOpPulls(enos));

    return this._ensrvService.send(batch).pipe(
      switchMap((enosInResponse: Batch) => {
        // @toDo: Should handle validation failure, which is the case that serverT branch and clientT branch not matched. Severity: High
        const enoTipsWithErrors: Tip[] = [];
        const errorTips: Tip[] = [];

        for (const eno of enosInResponse) {
          if (eno.hasError()) {
            enoTipsWithErrors.push(eno.tip);

            continue;
          }

          // @todo Handle conflict-error. Severity: High
          if (eno.getType() === 'error') {
            errorTips.push(eno.tip);
          }
        }

        for (const enoToCache of enos) {
          if (enoTipsWithErrors.indexOf(enoToCache.tip) > -1) {
            continue;
          }

          this._enoCacheService.setEno(enoToCache.tip, enoToCache);
        }

        if (errorTips.length) {
          return throwError(`[EnoService] Failed to write Eno(s). Error object detected: [${errorTips.join(', ')}].`);
        }

        if (enoTipsWithErrors.length) {
          return throwError(`[EnoService] Failed to write Eno(s) "${enoTipsWithErrors.join(', ')}".`);
        }

        return of(enosInResponse);
      })
    );
  }

  mergeBranch(sourceBranch: Tip, targetBranch: Tip = dataConstants.BRANCH_MASTER, expectedSids: Sid[] = []): Observable<boolean> {
    const opMergeFactory = new EnoFactory('op/merge', dataConstants.SECURITY.OP);
    const opMergeEno = opMergeFactory.setFields([
      { tip: 'op/merge/branch', value: [sourceBranch] },
      { tip: 'op/merge/to', value: [targetBranch] },
      { tip: 'op/merge/expect', value: expectedSids }
    ]).makeEno();

    const responseMergeObserver = this._ensrvService.getEnoReceiver('response/merge').pipe(
      first((responseMerge) => {
        return responseMerge.getFieldStringValue('response/merge/op-tip') === opMergeEno.tip;
      }),
      switchMap((responseMerge) => {
        const mergeErrors = responseMerge.getFieldValues('response/merge/error');
        if (mergeErrors.length > 0) {
          return throwError(`Merge failed with errors. ${mergeErrors.toString()}`);
        }

        return of(true);
      })
    );

    this._ensrvService.send([opMergeEno]).subscribe(
      () => {},
      (e) => {
        this._loggerService.error('Failed to send op/merge', e);
      }
    );

    return responseMergeObserver;
  }

  // Delete a single eno by tip
  deleteEno(tip: Tip): Observable<Eno[]> {
    return this.readEno(tip).pipe(
      first(),
      switchMap(eno => {
        // we don't expect deleted objects to be watched until we support undelete, so marking it as watched to avoid creating opPull
        this._enoWatchService.markAsWatched(tip);
        return this.writeEno(this._enoFactory.setProtoToPatch(eno).setDeleted(true).makeEno());
      })
    );
  }

  // Delete multiple enos given tips
  deleteEnos(tips: Tip[]): Observable<Eno[]> {
    return this.readEnos(tips).pipe(
      first(),
      map(enos => enos.map(eno => {
        // we don't expect deleted objects to be watched until we support undelete, so marking it as watched to avoid creating opPull
        this._enoWatchService.markAsWatched(eno.tip);
        return this._enoFactory.setProtoToPatch(eno).setDeleted(true).makeEno();
      })),
      switchMap(deletedEnos => this.writeEnos(deletedEnos))
    );
  }

  getTypeTip(tip: Tip): Observable<Tip> {
    return this.getTypeTips([tip]).pipe(map((tips: Tip[]) => {
      return tips[0];
    }));
  }

  /**
   * This method preserves the order so that you can rely on the array index
   *
   * @param tips  The object tips to find out its type tips
   */
  getTypeTips(tips: Tip[]): Observable<Tip[]> {
    return this.readEnos(tips).pipe(
      map((batch: Batch) => {
        return batch.map(x => x.getType());
      }),
      first() // Type never changes, this shouldn't be long running observable
    );
  }
}
