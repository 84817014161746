var _a;
import { reverseOperator } from './operators';
import { OPERATOR_TYPE } from '../../../models/query';
var ɵ0 = function (leftArg, rightArg) { return ({
    name: 'EQUALS',
    args: [{ name: 'COALESCE', args: [leftArg, 'false'] }, { name: 'COALESCE', args: [rightArg, 'false'] }]
}); };
var booleanEqual = {
    opNo: 2,
    toFormula: ɵ0
};
var booleanNotEqual = reverseOperator(booleanEqual);
export var booleanOperators = {
    operators: (_a = {},
        _a[OPERATOR_TYPE.EQUALS] = booleanEqual,
        _a[OPERATOR_TYPE.NOT_EQUALS] = booleanNotEqual,
        _a),
    getParts: function (formula) {
        var currentPart = formula;
        var negative = false;
        if (currentPart.name === 'NOT') {
            currentPart = currentPart.args[0];
            negative = true;
        }
        if (currentPart.name === 'EQUALS') {
            return {
                operator: negative ? OPERATOR_TYPE.NOT_EQUALS : OPERATOR_TYPE.EQUALS,
                leftArg: currentPart.args[0].args[0],
                rightArg: currentPart.args[1].args[0]
            };
        }
        throw new Error('Unknown operators: ' + currentPart.name);
    }
};
export { ɵ0 };
