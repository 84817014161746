import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { GroupsService } from '../../groups.service';
import { LoadingState } from '../../../shared/constants';
import { IObjectLaunchDone, IObjectLaunchProps } from '../../../formly-noggin/object-launch';
import {
  ModalConfirmDeletionComponent,
  modalConfirmDeletionOptions
} from '../../../shared/modals/modal-confirm-deletion/modal-confirm-deletion.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Tip } from '../../../data/models/types';
import { IType } from '../../../models/type';
import { IObjectScheme } from '../../../data/models/scheme';
import { IObjectAndType, ObjectService } from '../../../object/object.service';
import { SideSheetService } from '../../../side-sheet/side-sheet.service';
import { ToastService } from '../../../shell/services/toast.service';
import { Router } from '@angular/router';

export enum ErrorType {
  default = 'default',
  save = 'save',
  delete = 'delete'
}

@Component({
  selector: 'app-group-edit-side-sheet',
  templateUrl: './group-edit-side-sheet.component.html',
  styleUrls: ['./group-edit-side-sheet.component.scss']
})
export class GroupEditSideSheetComponent implements AfterViewInit, OnDestroy {
  objectTip: Tip;
  objectBranchTip: Tip;
  objectData: object;
  objectType: IType;
  objectTypeTip = 'app/group';
  objectScheme: IObjectScheme;
  securityPolicyTip = 'app/security-policy/instance-user-admin';
  readonly = false;
  state: LoadingState = LoadingState.inProgress;
  errorType: ErrorType = ErrorType.default;

  LoadingState = LoadingState;

  private unsubscribe$ = new Subject();
  done$ = new Subject<IObjectLaunchDone>();

  constructor(
    private groupsService: GroupsService,
    private objectService: ObjectService,
    private sideSheetService: SideSheetService,
    private toastService: ToastService,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private router: Router
  ) { }

  ngAfterViewInit() {
    this.objectService.getObjectAndType(this.objectTip, this.objectBranchTip).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(
      (objectAndType: IObjectAndType) => {
        this.objectType = objectAndType.objectType;
        this.objectScheme = objectAndType.objectScheme;
        this.objectData = objectAndType.objectData;

        this.objectType.field = this.objectType.field.filter(field => {
          return field.$tip !== 'app/group:contacts' && field.$tip !== 'app/group:parent';
        });

        this.state = LoadingState.loaded;
        this.cdr.detectChanges();
      },
      () => {
        this.toastService.showErrorToast('Unable to open group form at this time');
        this.sideSheetService.pop();
      }
    );
  }

  setProps({ objectTip }: IObjectLaunchProps) {
    this.objectTip = objectTip;
  }

  ngOnDestroy() {
    this.done$.complete();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSave() {
    this.state = LoadingState.inProgress;
    this.objectService.updateObject(this.objectData, this.objectScheme, this.objectBranchTip).pipe(
      first()
    ).subscribe(() => {
      this.done$.next({ objectTip: this.objectTip });
      this.toastService.showSuccessToast('Saved ' + this.objectType.label + ' successfully');
      this.sideSheetService.pop();
    }, () => {
      this.errorType = ErrorType.save;
      this.state = LoadingState.failed;
    });
  }

  showDeleteModal() {
    const modalRef = this.modalService.open(ModalConfirmDeletionComponent, modalConfirmDeletionOptions);
    const modalInstance = modalRef.componentInstance as ModalConfirmDeletionComponent;
    modalInstance.objectType = 'group';
    modalRef.result
      .then(deleteModule => deleteModule ? this.onDelete() : null)
      .catch(() => { });
  }

  onDelete() {
    this.state = LoadingState.inProgress;
    this.groupsService.deleteGroups([this.objectTip]).pipe(
      first(),
    ).subscribe(() => {
      this.done$.next({ objectTip: this.objectTip, deleted: true });
      this.toastService.showSuccessToast('Deleted ' + this.objectType.label + '  successfully');
      this.sideSheetService.clear();
      this.router.navigateByUrl('/app/s/groups/all');
    }, () => {
      this.errorType = ErrorType.delete;
      this.state = LoadingState.failed;
    });
  }
}
