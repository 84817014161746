import { IObject } from '../data/models/object';
import { Tip } from '../data/models/types';
import { IWorkflowUXItem } from '../settings/workflow-designer/workflow-designer-interfaces';
import { IObjectScheme, DataTypes } from '../data/models/scheme';

export interface IWorkflowRuntime extends IObject {
  workflow: Tip;
  ux?: IWorkflowUXItem[];
  outcome?: string;
  currentObjectTip?: string;
}

export const workflowRuntimeScheme: IObjectScheme = {
  'app/workflow-runtime:workflow': { },
  'app/workflow-runtime:ux': { type: DataTypes.jsonArray },
  'app/workflow-runtime:outcome': { },
  'app/workflow-runtime:current-object-tip': { },
};
