/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./avatar-view.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./avatar-view.component";
import * as i4 from "../../data/media.service";
var styles_AvatarViewComponent = [i0.styles];
var RenderType_AvatarViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AvatarViewComponent, data: {} });
export { RenderType_AvatarViewComponent as RenderType_AvatarViewComponent };
function View_AvatarViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "circle-avatar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.ngIf; _ck(_v, 1, 0, currVal_0); }); }
function View_AvatarViewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "circle-initials d-flex justify-content-center align-items-center"]], [[2, "circle-initials-dark-font", null], [2, "circle-initials-light-font", null], [4, "font-size", null]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.useLightFont; var currVal_1 = _co.useLightFont; var currVal_2 = _co.fontSize; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.initials; _ck(_v, 1, 0, currVal_3); }); }
function View_AvatarViewComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "organization-default-icon pb-2"], ["src", "/assets/v1.627.2-beta/assets/img/organization-icon.svg"]], null, null, null, null, null))], null, null); }
function View_AvatarViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AvatarViewComponent_3)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AvatarViewComponent_4)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.type !== "app/organization"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.type === "app/organization"); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_AvatarViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_AvatarViewComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["showBackup", 2]], null, 0, null, View_AvatarViewComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.avatarURL$)); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_AvatarViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-avatar-view", [], [[4, "width", null], [4, "height", null]], null, null, View_AvatarViewComponent_0, RenderType_AvatarViewComponent)), i1.ɵdid(1, 638976, null, 0, i3.AvatarViewComponent, [i4.MediaService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).width; var currVal_1 = i1.ɵnov(_v, 1).height; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var AvatarViewComponentNgFactory = i1.ɵccf("app-avatar-view", i3.AvatarViewComponent, View_AvatarViewComponent_Host_0, { avatarTip: "avatarTip", size: "size", fontSize: "fontSize", useLightFont: "useLightFont", firstName: "firstName", lastName: "lastName", fullName: "fullName", type: "type" }, {}, []);
export { AvatarViewComponentNgFactory as AvatarViewComponentNgFactory };
