import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoadingState } from '../../shared/constants';
import { SideSheetListModes } from '../../side-sheet/side-sheet-list/side-sheet-list.component';
var SelectUsersSideSheetComponent = /** @class */ (function () {
    function SelectUsersSideSheetComponent(userService) {
        this.userService = userService;
        this.mode = SideSheetListModes.SINGLE_SELECT;
        this.title = 'Select users';
        this.initiallySelectedTips = [];
        this.selectedUsers = [];
        this.doNotDisplayInitiallySelectedUsers = false;
        this.subscriptions = new Subscription();
        this.loadingStates = LoadingState;
    }
    SelectUsersSideSheetComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.selectedUsers = tslib_1.__spread(this.initiallySelectedTips);
        this.users$ = this.userService.getAllUsers().pipe(map(function (users) {
            _this.users = (_this.doNotDisplayInitiallySelectedUsers) ?
                users.filter(function (user) { return _this.initiallySelectedTips.indexOf(user.$tip) === -1; }) : users;
            return _this.users;
        }));
    };
    SelectUsersSideSheetComponent.prototype.onSelectedChange = function (selectedUserTips) {
        if (this.isSingleSelect) {
            this.onDone(selectedUserTips);
        }
    };
    SelectUsersSideSheetComponent.prototype.onDone = function (selectedUserTips) {
        switch (this.mode) {
            case SideSheetListModes.SINGLE_SELECT:
                if (selectedUserTips && selectedUserTips.length) {
                    this.done(this.users.find(function (x) { return x.$tip === selectedUserTips[0]; }));
                }
                break;
            case SideSheetListModes.MULTI_SELECT:
                this.done(this.users.filter(function (user) {
                    return selectedUserTips.indexOf(user.$tip) > -1;
                }));
                break;
        }
    };
    Object.defineProperty(SelectUsersSideSheetComponent.prototype, "isSingleSelect", {
        get: function () {
            return this.mode === SideSheetListModes.SINGLE_SELECT;
        },
        enumerable: true,
        configurable: true
    });
    SelectUsersSideSheetComponent.prototype.userLabel = function (user) {
        return (user.fullName).replace(/(^ +)|( +$)/g, '') || user.email;
    };
    return SelectUsersSideSheetComponent;
}());
export { SelectUsersSideSheetComponent };
