import { map, take } from 'rxjs/internal/operators';
import dataConstants from './constants';
import { EnoFactory } from './EnoFactory';
import * as i0 from "@angular/core";
import * as i1 from "./eno.service";
var VarsService = /** @class */ (function () {
    function VarsService(_enoService) {
        this._enoService = _enoService;
        this._varFactory = new EnoFactory('var', dataConstants.SECURITY.EVERYONE);
    }
    VarsService.prototype.objectToEnos = function (vars) {
        var _this = this;
        return Object.keys(vars).map(function (key) {
            _this._varFactory.setFields([
                { tip: 'var/key', value: [key] },
                { tip: 'var/value', value: vars[key] }
            ]);
            return _this._varFactory.makeEno();
        });
    };
    VarsService.prototype.enosToObject = function (varEnos) {
        var result = {};
        varEnos.forEach(function (varEno) {
            result[varEno.getFieldStringValue('var/key')] = varEno.getFieldValues('var/value');
        });
        return result;
    };
    VarsService.prototype.tipsToObject = function (varTips) {
        return this._enoService.readEnos(varTips).pipe(take(1), map(this.enosToObject, this));
    };
    VarsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VarsService_Factory() { return new VarsService(i0.ɵɵinject(i1.EnoService)); }, token: VarsService, providedIn: "root" });
    return VarsService;
}());
export { VarsService };
