import { Injectable } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { switchMap, map, first, catchError } from 'rxjs/operators';

import dataConstants from '../data/constants';
import { Eno } from '../data/models/Eno';
import { EnoFactory } from '../data/EnoFactory';
import { IVars } from '../data/vars.service';
import { EnsrvService } from '../data/ensrv.service';
import { IProcessResponse, ProcessService } from '../data/process.service';
import { FormulaResult, FormulaService } from '../data/formula.service';
import { SessionManagerService } from '../data/session-manager.service';
import { Batch, Email, Tip } from '../data/models/types';
import { get } from 'lodash';

const USER_LABEL = 'app/security-label/user';

export enum SignInStatus {
  SUCCESS = 'success',
  BAD = 'bad',
  FAILURE = 'failure',
  IN_PROGRESS = 'inProgress',
  AWAITING = 'awaiting',
  INVITED = 'invited',
  SUSPENDED = 'suspended',
  REQUESTED = 'requested',
  ACCOUNT_LOCK = 'accountLock',
  WEAK_PASSWORD = 'weakPassword'
}

export enum SignOutStatus {
  SUCCESS = 'Success',
  FAILURE = 'Failure'
}

interface IVarStatusToResultStatusMap {
  [statusKey: string]: SignInStatus;
}

const varStatusToResultStatusMap: IVarStatusToResultStatusMap = {
  success: SignInStatus.SUCCESS,
  bad: SignInStatus.BAD,
  suspended: SignInStatus.SUSPENDED,
  invited: SignInStatus.INVITED,
  requested: SignInStatus.REQUESTED,
  'awaiting verification': SignInStatus.AWAITING,
  account_lock: SignInStatus.ACCOUNT_LOCK
};

const INVALID_CREDENTIALS = 'error/message/auth/invalid-credential';
const TOO_MANY_ATTEMPTS = 'error/message/auth/too-many-auth-attempts';

const OP_POLICY = dataConstants.SECURITY.OP;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _opAuthResetTokenFactory: EnoFactory;

  constructor(
    private ensrvService: EnsrvService,
    private processService: ProcessService,
    private sessionManager: SessionManagerService,
    private formulaService: FormulaService
  ) {
    this._opAuthResetTokenFactory = new EnoFactory('op/auth/reset-token', OP_POLICY);
  }

  /**
   * Returns true if the user is authenticated, otherwise false
   */
  isAuthenticated(): Observable<boolean> {
    return this.formulaService.evaluate(`HAS_LABELS("${USER_LABEL}")`).pipe(
      map(
        (formulaResult: FormulaResult) => {
          return formulaResult[0] === 'true';
        }
      ),
      catchError(
        () => {
          return of(false);
        }
      )
    );
  }

  /**
   * Authenticate the user given an email, password, and optional profile. Returns an observable
   * which will return a positive response if successful, or error if not.
   */
  signIn(email: string, password: string, profile?: string): Observable<SignInStatus> {
    this.sessionManager.changeToken(null);

    const opGenTokenFactory = new EnoFactory('op/auth/gen-token', OP_POLICY);
    const opGenToken = opGenTokenFactory.setFields([
      { tip: 'op/auth/gen-token:key', value: [email] },
      { tip: 'op/auth/gen-token:secret', value: [password] },
      { tip: 'op/auth/gen-token:payload', value: [email] }
    ]).makeEno();

    return this.ensrvService.send([opGenToken]).pipe(
      switchMap((enos: Batch) => {
        const errorEno: Eno = enos.filter((eno: Eno) => get(eno, 'source.type', null) === 'error')[0];

        if (errorEno) {
          const errorMessageTip: Tip = errorEno.getFieldStringValue('error/message/tip');
          switch (errorMessageTip) {
            case INVALID_CREDENTIALS:
              return of(SignInStatus.BAD);
            case TOO_MANY_ATTEMPTS:
              return of(SignInStatus.ACCOUNT_LOCK);
          }
        }

        const tokenResponseEno: Eno = enos.filter((eno: Eno) => get(eno, 'source.type', null) === 'response/auth/gen-token')[0];

        const vars: IVars = {
          'Auth key': [email],
          'Auth token': [tokenResponseEno.getFieldStringValue('response/auth/gen-token:token')],
        };

        if (profile) {
          vars['Profile tip'] = [profile];
        }

        const lastProfile = this.sessionManager.getLastProfileWithEmail(email);
        if (lastProfile) {
          vars['Profile tip'] = [lastProfile];
        }

        // Start the signin process with the generated token
        // Login process returns user tip, person tip, and a profile tip
        return this.processService.start('eim/process/auth/login', vars).pipe(
          first((responseInfo: IProcessResponse) => {
            return responseInfo.finished;
          }),
          map((responseInfo: IProcessResponse) => {
            return this._processVarsToResultStatus(responseInfo.vars);
          })
        );
      })
    );
  }

  private _processVarsToResultStatus(resultVars: IVars): SignInStatus {
    if (!resultVars.Status || !resultVars['JWT token'] || varStatusToResultStatusMap[resultVars.Status[0]] === undefined) {
      return SignInStatus.FAILURE;
    }

    this.sessionManager.updateSessionInfoAndRedirectToOriginalRequestedDestination(resultVars['JWT token'][0]);

    return varStatusToResultStatusMap[resultVars.Status[0]];
  }

  /**
   * Sign the user out. Returns an observable when complete.
   */
  signOut(): Observable<boolean> {
    return this.processService.start('eim/process/auth/logout').pipe(
      first((responseInfo: IProcessResponse) => {
        return responseInfo.finished;
      }),
      map((responseInfo: IProcessResponse) => {
        // in real life this never gets called
        // ensrv sets a token the causes a refresh before we get here
        const success = responseInfo.vars['Status'][0] === SignOutStatus.SUCCESS;

        if (success) {
          this.sessionManager.updateSessionInfo(null);
        }

        return success;
      }),
      catchError(
        () => {
          return of(false);
        }
      )
    );
  }

  /**
   * Send the user a reset password link. Returns an observable when complete.
   */
  forgotPassword(email: string): Observable<boolean> {
    return this.processService.start('eim/process/auth/request-forgotten-password', { 'Auth key': [email] }).pipe(
      first((responseInfo: IProcessResponse) => {
        return responseInfo.finished;
      }),
      map(() => {
        // Always return true for the moment, as regardless of user's status, we always show success message in the screen.
        return true;
      })
    );
  }

  resetPassword(email: Email, newSecret: string, token: string): Observable<Eno> {
    const opEno = this._opAuthResetTokenFactory.setFields([
      { tip: 'op/auth/reset-token:key', value: [email] },
      { tip: 'op/auth/reset-token:token', value: [token] },
      { tip: 'op/auth/reset-token:new-secret', value: [newSecret] }
    ]).makeEno();
    const opTip = opEno.tip;

    const responseObservable = this.ensrvService.send([opEno]).pipe(
      first(),
      switchMap((enos: Batch) => {
        const responseEno: Eno = enos.filter((eno: Eno) => get(eno, 'source.type', null) === 'response/auth/reset-token')[0];
        const errorEno: Eno = enos.filter((eno: Eno) => get(eno, 'source.type', null) === 'error')[0];
        if (errorEno && responseEno.getFieldValues('response/auth/reset-token:error')[0] === errorEno.tip) {
          return of(errorEno);
        } else {
          return of(null);
        }
      })
    );

    return responseObservable;
  }

  activateNewEmail(oldEmail: Email, newEmail: Email, token: string): Observable<boolean> {
    const opEno = this._opAuthResetTokenFactory.setFields([
      { tip: 'op/auth/reset-token:key', value: [oldEmail] },
      { tip: 'op/auth/reset-token:token', value: [token] },
      { tip: 'op/auth/reset-token:new-key', value: [newEmail] }
    ]).makeEno();
    const opTip = opEno.tip;

    const responseObservable = this.ensrvService.getEnoReceiver('response/auth/reset-token').pipe(
      first((responseEno: Eno) => {
        return responseEno.getFieldStringValue('response/auth/reset-token:op') === opTip;
      }),
      map((responseEno: Eno) => {
        return responseEno.getFieldValues('response/auth/reset-token:error').length === 0;
      })
    );

    this.ensrvService.send([opEno]).subscribe();

    return responseObservable;
  }
}
