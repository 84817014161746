export type TagKey = string;
export type TagValue = string;
export type TagMap = Map<TagKey, TagValue>;
export type TagKeyValueArray = Array<[TagKey, TagValue]>;

export function tagsToKeyValueArray(tags: string[]): TagKeyValueArray {
  return tags.map((tag) => {
    const split = /^([^(--)]+)--(.*(\n.*)*)/m.exec(tag);

    if (!split) {
      throw new Error(`
        Tag cannot be split into key and value
        Original tag: ${tag}
      `);
    }

    return [split[1], split[2]];
  });
}

export function tagToMap(tags: string[] = []): Map<TagKey, TagValue> {
  return new Map(tagsToKeyValueArray(tags) as any);
}
