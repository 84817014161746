import * as tslib_1 from "tslib";
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { last, flatten } from 'lodash';
import { EXTENDED_FIELD_DATA_TYPE, FIELD_DATA_TYPE, fieldScheme } from '../models/field';
import { MetaDataKey } from '../object/field-formula-side-sheet/meta-data-formulas';
import { SUPER_TYPES } from '../object/type-chooser-side-sheet/super-types';
import { getDataTypeAndMetaFromField } from './get-data-type-and-meta-from-field';
import { getSpecialContextFormulaLabel, getSpecialContextFormulaMeta, SPECIAL_CONTEXT_FORMULA_TYPE } from './special-context-formula';
import { getFieldTipsFromFormulaString } from './get-field-tips-from-formula-string';
import { getContextFromContextFormula } from './context-formula-wrapper';
import * as i0 from "@angular/core";
import * as i1 from "../data/object-service/object.service";
import * as i2 from "../object/object.service";
import * as i3 from "../data/formula.service";
var GetFormulaDatatypeService = /** @class */ (function () {
    function GetFormulaDatatypeService(objectDataService, objectService, formulaService) {
        this.objectDataService = objectDataService;
        this.objectService = objectService;
        this.formulaService = formulaService;
    }
    // Returns a data type and optional typerestrict if object given a formula spec
    GetFormulaDatatypeService.prototype.getFormulaDataType = function (formula, contextTypeTip, additionalMeta) {
        if (additionalMeta === void 0) { additionalMeta = {}; }
        var specialContextFormulaMeta = getSpecialContextFormulaMeta(formula);
        if (specialContextFormulaMeta) {
            return this.getSpecialContextFormulaDatatype(specialContextFormulaMeta, formula, contextTypeTip, additionalMeta);
        }
        return this.getFieldFormulaDatatype(formula, additionalMeta);
    };
    GetFormulaDatatypeService.prototype.getFieldFormulaDatatype = function (formula, additionalMeta) {
        var _this = this;
        if (additionalMeta === void 0) { additionalMeta = {}; }
        var fieldTip = last(getFieldTipsFromFormulaString(formula));
        if (!fieldTip) {
            return of(additionalMeta);
        }
        return this.objectDataService.getObject(fieldTip, fieldScheme).pipe(map(function (field) {
            var meta = getDataTypeAndMetaFromField({ field: field });
            return tslib_1.__assign({}, additionalMeta, { fieldLabel: field.label, datatype: field.datatype, typerestrict: field.typerestrict || [], typeGeoRestrict: field.typeGeoRestrict || undefined, listTip: meta.listTip || null, extendedDataType: field.datatype === meta._datatype ? undefined : meta._datatype, isMulti: field.maxcount && field.maxcount > 1 || false, numberFormatTip: field.numberFormat || null });
        }), switchMap(function (data) { return _this.updateHierarchicalData(data); }));
    };
    GetFormulaDatatypeService.prototype.getSpecialContextFormulaDatatype = function (specialContextFormulaMeta, formula, contextTypeTip, additionalMeta) {
        if (additionalMeta === void 0) { additionalMeta = {}; }
        var fieldLabel = getSpecialContextFormulaLabel(formula);
        switch (specialContextFormulaMeta.type) {
            case SPECIAL_CONTEXT_FORMULA_TYPE.CUSTOM_FORMULA:
                return of({
                    datatype: FIELD_DATA_TYPE.string,
                    fieldLabel: fieldLabel,
                    isMulti: false
                });
            case SPECIAL_CONTEXT_FORMULA_TYPE.META_DATA_FORMULA:
                var result = tslib_1.__assign({}, additionalMeta, { metaDataKey: specialContextFormulaMeta.metaData.key, datatype: specialContextFormulaMeta.metaData.datatype, fieldLabel: fieldLabel, isMulti: false });
                if (specialContextFormulaMeta.metaData.key === MetaDataKey.thisObject) {
                    if (contextTypeTip && contextTypeTip.length) {
                        result.typerestrict = flatten([contextTypeTip]);
                    }
                    var context = getContextFromContextFormula(formula);
                    if (context) {
                        return this.getFormulaDataType(context, contextTypeTip, result);
                    }
                }
                if (specialContextFormulaMeta.metaData.typerestrict) {
                    result.typerestrict = specialContextFormulaMeta.metaData.typerestrict;
                }
                return this.updateHierarchicalData(result);
            case SPECIAL_CONTEXT_FORMULA_TYPE.COMMON_FIELD_FORMULA:
                return this.updateHierarchicalData(tslib_1.__assign({}, this.getFormulaDataTypeForCommonField(this.formulaService.parse(specialContextFormulaMeta.outerFormula)), { fieldLabel: fieldLabel, isMulti: false }));
            case SPECIAL_CONTEXT_FORMULA_TYPE.CURRENT_USER_FORMULA:
                return this.updateHierarchicalData({
                    datatype: FIELD_DATA_TYPE.object,
                    typerestrict: ['app/user'],
                    fieldLabel: fieldLabel,
                    isMulti: false
                });
            case SPECIAL_CONTEXT_FORMULA_TYPE.RELATIVE_DATE_FORMULA:
                return of({
                    datatype: FIELD_DATA_TYPE.datetime,
                    fieldLabel: fieldLabel,
                    isMulti: false
                });
        }
    };
    GetFormulaDatatypeService.prototype.updateHierarchicalData = function (result) {
        if (!result.typerestrict || !result.typerestrict.length) {
            return of(result);
        }
        var hierarchicalSuperTypes = SUPER_TYPES.filter(function (x) { return x.isHierarchical; }).map(function (x) { return x.$tip; });
        // if object itself is a group or one of hierarchical super types - set filter meta to hierarchical
        if (tslib_1.__spread(hierarchicalSuperTypes, ['app/group']).includes(result.typerestrict[0])) {
            result.extendedDataType = EXTENDED_FIELD_DATA_TYPE.hierarchical;
            return of(result);
        }
        // otherwise get type to detect if it implemets hierarchical super type
        return this.objectService.getType(result.typerestrict[0]).pipe(map(function (objectAndType) {
            // if it is an object and it has supertypes - add them to filter meta
            if (objectAndType && objectAndType.objectType && objectAndType.objectType.supertypes) {
                result.implements = objectAndType.objectType.supertypes.map(function (x) { return x.$tip; });
                // if one of implemented super types is hierarchical set filter meta to hierarchical
                if (result.implements.some(function (x) { return hierarchicalSuperTypes.includes(x); })) {
                    result.extendedDataType = EXTENDED_FIELD_DATA_TYPE.hierarchical;
                }
            }
            return result;
        }));
    };
    // gets formula meta information for common field which will have data encoded as json in formula args
    GetFormulaDatatypeService.prototype.getFormulaDataTypeForCommonField = function (formula) {
        var fieldDataJson = formula.args[0].args[1];
        var fieldData = JSON.parse(fieldDataJson);
        var meta = getDataTypeAndMetaFromField({ field: fieldData });
        return {
            datatype: fieldData.datatype,
            typerestrict: fieldData.typerestrict || [],
            typeGeoRestrict: fieldData.typeGeoRestrict || undefined,
            listTip: meta.listTip || null,
            extendedDataType: fieldData.datatype === meta._datatype ? undefined : meta._datatype
        };
    };
    GetFormulaDatatypeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetFormulaDatatypeService_Factory() { return new GetFormulaDatatypeService(i0.ɵɵinject(i1.ObjectService), i0.ɵɵinject(i2.ObjectService), i0.ɵɵinject(i3.FormulaService)); }, token: GetFormulaDatatypeService, providedIn: "root" });
    return GetFormulaDatatypeService;
}());
export { GetFormulaDatatypeService };
