import * as tslib_1 from "tslib";
import { QueryFilterEditorService } from '../../query/query-filter-editor/query-filter-editor.service';
import { Parser } from '../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { queryScheme } from '../../models/query';
import { first, map, switchMap } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { chartScheme } from '../../models/chart';
import { isEmpty } from 'lodash';
import { riskMatrixScheme } from '../../models/risk-matrix';
import * as i0 from "@angular/core";
import * as i1 from "../../data/object-service/object.service";
import * as i2 from "../../data/formula-multi.service";
var FilterInputService = /** @class */ (function () {
    function FilterInputService(objectService, formulaMultiService) {
        this.objectService = objectService;
        this.formulaMultiService = formulaMultiService;
    }
    FilterInputService.parseVarNames = function (varNames) {
        return varNames.map(function (varName) { return JSON.parse(atob(varName)); });
    };
    FilterInputService.compareFilterInputVars = function (a, b) {
        return a.name === b.name ? 0 : (a.name > b.name ? 1 : -1);
    };
    FilterInputService.getVarsFromFormula = function (formula) {
        return FilterInputService.parseVarNames(QueryFilterEditorService.getVarNames(formula))
            .sort(FilterInputService.compareFilterInputVars);
    };
    FilterInputService.getEmptyFilterInputsFromFormulaStr = function (formulaStr) {
        var fullFilterFormula = Parser(formulaStr);
        var filterInputVars = FilterInputService.getVarsFromFormula(fullFilterFormula);
        return filterInputVars.map(function (filterInputVar) { return ({ variable: filterInputVar, value: '', by: null }); });
    };
    FilterInputService.getEmptyFilterInputsFromFormulaStrs = function (formulaStrs) {
        var formulaStr = "AND(" + formulaStrs.join(',') + ")";
        return FilterInputService.getEmptyFilterInputsFromFormulaStr(formulaStr);
    };
    // e.g.
    // filterInputs: [
    //   { variable: { name: 'A', type: 'string'}, value: '', by: null },
    //   { variable: { name: 'B', type: 'string'}, value: '', by: null }
    // ]
    // values: [
    //   { variable: { name: 'A', type: 'string'}, value: 'VALUE', by: 'Entering' },
    //   { variable: { name: 'C', type: 'string'}, value: 'ANOTHER VALUE', by: 'Entering' }
    // ]
    // filterInputs will be changed to: [
    //   { variable: { name: 'A', type: 'string'}, value: 'VALUE', by: 'Entering' },
    //   { variable: { name: 'B', type: 'string'}, value: '', by: null }
    // ]
    // as you can see, Variable C and its value was dropped
    FilterInputService.mergeExistingValues = function (filterInputs, values) {
        if (values && values.length !== 0) {
            values.map(function (value) { return filterInputs.forEach(function (filterInput) {
                if (JSON.stringify(value.variable) === JSON.stringify(filterInput.variable)) {
                    filterInput.value = value.value;
                    filterInput.by = value.by;
                }
            }); });
        }
    };
    FilterInputService.filterInputFulfilled = function (filterInputs) {
        return isEmpty(filterInputs) || filterInputs.filter(function (filterInput) { return isEmpty(filterInput.value); }).length === 0;
    };
    /*
    try using convertWorkflowFilterInputsToVars if a non-observable version is required. check below.
     */
    FilterInputService.prototype.convertFilterInputsToVars = function (filterInputs, contextTip, queryParams, noBase64) {
        if (noBase64 === void 0) { noBase64 = false; }
        if (!filterInputs) {
            filterInputs = [];
        }
        var vars = {};
        var contextVarNames = [];
        var contextFormulas = [];
        var relativeVarNames = [];
        var relativeFormulas = [];
        filterInputs.forEach(function (filterInput) {
            var varName = noBase64 ? filterInput.variable.name : btoa(JSON.stringify(filterInput.variable));
            if (filterInput.by === 'Entering') {
                vars[varName] = [filterInput.value];
            }
            else if (filterInput.by === 'Context') {
                contextVarNames.push(varName);
                contextFormulas.push(filterInput.value);
            }
            else if (filterInput.by === 'Relative') {
                relativeVarNames.push(varName);
                relativeFormulas.push(filterInput.value);
            }
            else if (filterInput.by === 'URL' && queryParams) {
                vars[varName] = queryParams.getAll(filterInput.value);
            }
            else if (filterInput.by === 'Field' && filterInput.formFieldTip && filterInput.formFieldTip !== filterInput.value) {
                vars[varName] = [filterInput.value];
            }
        });
        if (contextVarNames.length === 0 && relativeVarNames.length === 0) {
            return of(vars);
        }
        return this.formulaMultiService.evaluate(tslib_1.__spread(contextFormulas.map(function (contextFormula) { return ({
            formula: contextFormula,
            context: contextTip,
            watch: false
        }); }), relativeFormulas.map(function (relativeFormula) { return ({
            formula: relativeFormula,
            context: contextTip,
            watch: false
        }); }))).pipe(first(), map(function (results) {
            // context formulas
            var contextResults = results.slice(0, contextFormulas.length);
            contextResults.forEach(function (result, i) {
                vars[contextVarNames[i]] = result;
            });
            // relative datetime formulas
            var relativeResults = results.slice(contextFormulas.length);
            relativeResults.forEach(function (result, i) {
                vars[relativeVarNames[i]] = result;
            });
            return vars;
        }));
    };
    /*
    this is a simplified version of convertFilterInputsToVars for limited use cases and doesn't return an observable
    consider using/expanding this iff using observable is an issue
     */
    FilterInputService.prototype.convertWorkflowFilterInputsToVars = function (filterInputs) {
        if (filterInputs === void 0) { filterInputs = []; }
        var vars = {};
        filterInputs.forEach(function (filterInput) {
            var varName = btoa(JSON.stringify(filterInput.variable));
            vars[varName] = [filterInput.value];
        });
        return vars;
    };
    FilterInputService.prototype.getEmptyFilterInputsFromQueryTips = function (queryTips) {
        var _this = this;
        if (isEmpty(queryTips)) {
            return of([]);
        }
        return combineLatest(queryTips.map(function (queryTip) { return _this.objectService.getObject(queryTip, queryScheme).pipe(first()); })).pipe(map(function (queries) {
            var formulaStrs = queries.map(function (query) { return query.filters[0].formula; });
            return FilterInputService.getEmptyFilterInputsFromFormulaStrs(formulaStrs);
        }));
    };
    FilterInputService.prototype.getEmptyFilterInputsFromChartTip = function (chartTip) {
        var _this = this;
        return this.objectService.getObject(chartTip, chartScheme).pipe(first(), switchMap(function (chart) {
            return _this.getEmptyFilterInputsFromQueryTips(chart.dataSource.map(function (dataSource) { return dataSource.queryTip; }));
        }));
    };
    FilterInputService.prototype.getEmptyFilterInputsFromRiskMatrixTip = function (riskMatrixTip) {
        var _this = this;
        return this.objectService.getObject(riskMatrixTip, riskMatrixScheme).pipe(first(), switchMap(function (riskMatrix) {
            return _this.getEmptyFilterInputsFromQueryTips([riskMatrix.filter]);
        }));
    };
    FilterInputService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FilterInputService_Factory() { return new FilterInputService(i0.ɵɵinject(i1.ObjectService), i0.ɵɵinject(i2.FormulaMultiService)); }, token: FilterInputService, providedIn: "root" });
    return FilterInputService;
}());
export { FilterInputService };
