import { map } from 'rxjs/operators';
import { ASSIGN_TO_SYSTEM_VALUE } from '../../shared/dynamic-field-chooser/assign-to-system-side-sheet/assign-to-system-side-sheet.component';
import { QuoteString } from '../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { TIP_FORMULA } from '../../object/field-formula-side-sheet/field-formula-side-sheet/field-formula-side-sheet.component';
import { isEmpty } from 'lodash';
import { SEQUENCE_INTEGER_FORMULA, SEQUENCE_INTEGER_LABEL } from '../../shared/dynamic-field-chooser/sequence-integer';
import { addContextFormulaWrapper } from '../../util/context-formula-wrapper';
import * as i0 from "@angular/core";
import * as i1 from "../../object/field-formula-side-sheet/formula-label.service";
import * as i2 from "../../util/display-formula-wrapper.service";
export var RECIPIENT_SUPER_TYPE_TIP;
(function (RECIPIENT_SUPER_TYPE_TIP) {
    RECIPIENT_SUPER_TYPE_TIP["EMAIL"] = "app/super-type/email-capable";
    RECIPIENT_SUPER_TYPE_TIP["SMS"] = "app/super-type/sms-capable";
    RECIPIENT_SUPER_TYPE_TIP["VOICE"] = "app/super-type/voice-message-capable";
})(RECIPIENT_SUPER_TYPE_TIP || (RECIPIENT_SUPER_TYPE_TIP = {}));
export var SYSTEM_LABELS;
(function (SYSTEM_LABELS) {
    SYSTEM_LABELS["CURRENT_DATE"] = "Current date";
    SYSTEM_LABELS["CURRENT_DATE_AND_TIME"] = "Current date and time";
    SYSTEM_LABELS["CURRENT_MONTH"] = "Current month";
    SYSTEM_LABELS["CURRENT_YEAR"] = "Current year";
    SYSTEM_LABELS["CURRENT_USER"] = "Current user";
})(SYSTEM_LABELS || (SYSTEM_LABELS = {}));
var SubstitutionMetaService = /** @class */ (function () {
    function SubstitutionMetaService(formulaLabelService, displayFormulaWrapperService) {
        this.formulaLabelService = formulaLabelService;
        this.displayFormulaWrapperService = displayFormulaWrapperService;
    }
    SubstitutionMetaService.getLabelFromSystemFormula = function (formula) {
        switch (formula) {
            case ASSIGN_TO_SYSTEM_VALUE.CURRENT_DATE:
                return SYSTEM_LABELS.CURRENT_DATE;
            case ASSIGN_TO_SYSTEM_VALUE.CURRENT_DATETIME:
                return SYSTEM_LABELS.CURRENT_DATE_AND_TIME;
            case ASSIGN_TO_SYSTEM_VALUE.CURRENT_MONTH:
                return SYSTEM_LABELS.CURRENT_MONTH;
            case ASSIGN_TO_SYSTEM_VALUE.CURRENT_YEAR:
                return SYSTEM_LABELS.CURRENT_YEAR;
            case ASSIGN_TO_SYSTEM_VALUE.CURRENT_USER:
                return SYSTEM_LABELS.CURRENT_USER;
            case SEQUENCE_INTEGER_FORMULA:
                return SEQUENCE_INTEGER_LABEL;
            default:
                return null;
        }
    };
    SubstitutionMetaService.prototype.getSubstitutionCandidateFromSystem = function (formula, type) {
        switch (formula) {
            case ASSIGN_TO_SYSTEM_VALUE.CURRENT_DATE:
                return {
                    type: type,
                    label: SYSTEM_LABELS.CURRENT_DATE,
                    value: ASSIGN_TO_SYSTEM_VALUE.CURRENT_DATE
                };
            case ASSIGN_TO_SYSTEM_VALUE.CURRENT_DATETIME:
                return {
                    type: type,
                    label: SYSTEM_LABELS.CURRENT_DATE_AND_TIME,
                    value: ASSIGN_TO_SYSTEM_VALUE.CURRENT_DATETIME
                };
            case ASSIGN_TO_SYSTEM_VALUE.CURRENT_MONTH:
                return {
                    type: type,
                    label: SYSTEM_LABELS.CURRENT_MONTH,
                    value: ASSIGN_TO_SYSTEM_VALUE.CURRENT_MONTH
                };
            case ASSIGN_TO_SYSTEM_VALUE.CURRENT_YEAR:
                return {
                    type: type,
                    label: SYSTEM_LABELS.CURRENT_YEAR,
                    value: ASSIGN_TO_SYSTEM_VALUE.CURRENT_YEAR
                };
            case ASSIGN_TO_SYSTEM_VALUE.CURRENT_USER:
                return {
                    type: type,
                    label: SYSTEM_LABELS.CURRENT_USER,
                    value: ASSIGN_TO_SYSTEM_VALUE.CURRENT_USER
                };
            default:
                return null;
        }
    };
    SubstitutionMetaService.prototype.getSubstitutionCandidateFromFormula = function (formula, type, contextTypeTip) {
        var removedFormula = this.displayFormulaWrapperService.removeDisplayFormulaWrapper(formula); //  for those formulas which have had a special treatment - for eg. lists, date time etc. - just used to calculate the label
        return this.formulaLabelService.transform(removedFormula, {}, contextTypeTip).pipe(map(function (label) { return ({ type: type, label: label, value: formula }); }));
    };
    SubstitutionMetaService.prototype.getRecipientSubstitutionCandidateFromFormula = function (formula, type, contextTypeTip) {
        var sourceTip;
        switch (contextTypeTip) {
            case RECIPIENT_SUPER_TYPE_TIP.EMAIL:
                sourceTip = 'app/email/recipient:source';
                break;
            case RECIPIENT_SUPER_TYPE_TIP.SMS:
                sourceTip = 'app/sms/recipient:source';
                break;
            case RECIPIENT_SUPER_TYPE_TIP.VOICE:
                sourceTip = 'app/voice-message/recipient:source';
                break;
        }
        var formulaWithRecipientSource;
        if (sourceTip && formula !== TIP_FORMULA) {
            var recipientSourceField = "FIELD(" + QuoteString(sourceTip) + ")";
            formulaWithRecipientSource = addContextFormulaWrapper(formula, recipientSourceField);
        }
        var value = formulaWithRecipientSource || formula;
        return this.formulaLabelService
            .transform(formula)
            .pipe(map(function (label) { return ({ type: type, label: label, value: value }); }));
    };
    SubstitutionMetaService.prototype.replaceSubstitutions = function (text, substitutions) {
        if (isEmpty(substitutions)) {
            return text;
        }
        substitutions.forEach(function (sub) {
            text = text.replace(new RegExp(sub.key, 'g'), sub.label);
        });
        return text;
    };
    SubstitutionMetaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SubstitutionMetaService_Factory() { return new SubstitutionMetaService(i0.ɵɵinject(i1.FormulaLabelService), i0.ɵɵinject(i2.DisplayFormulaWrapperService)); }, token: SubstitutionMetaService, providedIn: "root" });
    return SubstitutionMetaService;
}());
export { SubstitutionMetaService };
