import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-geo-chooser',
  template: `
    <app-geo-chooser-field
            [formControl]="formControl"
            [label]="to.label"
            [placeholder]="to.placeholder"
            [required]="to.required"
            [readonly]="to['readonly']"
            [mode]="to.type"
            [mapTip]="to['mapTip']">
    </app-geo-chooser-field>
  `,
})
export class FormlyFieldGeoChooserComponent extends FieldType {

}
