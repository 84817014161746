import * as tslib_1 from "tslib";
var targetLocales = [
    { label: 'American Spanish (es)', value: 'es' },
    { label: 'French (fr)', value: 'fr' },
    { label: 'Simplified Chinese (zh)', value: 'zh' }
];
var locales = tslib_1.__spread([{ label: 'American English (en-us)', value: 'en-us' }], targetLocales);
export var dataConstants = {
    // The currently served locale ID
    LOCALE_ID: 'en-us',
    ACCEPTABLE_LOCALE_IDS: ['en-us'],
    LANG_DEFAULT: 'en-us',
    LOCALES: locales,
    TARGET_LOCALES: targetLocales,
    BRANCH_MASTER: 'branch/master',
    SECURITY: {
        OP: 'security/policy/op',
        EVERYONE: 'security/policy/everyone',
        INSTANCE_USER_ADMIN: 'app/security-policy/instance-user-admin',
        INSTANCE_USER_USER: 'app/security-policy/instance-user-user'
    },
    LOCAL_STORAGE: {
        SESSION_TOKEN: 'en-session-token',
        SESSION_LAST_PROFILE: 'en-last-profile'
    },
    HTTP_HEADER: {
        SESSION_TOKEN_HEADER: 'Session-Token',
        SESSION_ID_HEADER: 'Session-Id'
    }
};
export default dataConstants;
