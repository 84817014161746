import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-string-encryption',
  template: `
      <app-string-with-encryption-field
      [formControl]="formControl"
              [label]="to.label"
              [placeholder]="to.placeholder"
              [required]="to.required"
              [readonly]="to.readonly"
              [rows]="to.rows"
              [height]="to.height"
              [servicePath]="to.servicePath"
              (resetData)="reset($event)"
      ></app-string-with-encryption-field>
  `
})
export class FormlyFieldStringEncryptionComponent extends FieldType {
  reset($event) {
    this.formControl.markAsTouched();
    this.formControl.updateValueAndValidity();
  }
}
