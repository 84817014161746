import { Injectable } from '@angular/core';

import { CompositeNodeConverter, INodeAndLink } from './CompositeNodeConverter';
import { ISendEmailEditor } from '../node-editors/send-email-editor/send-email-editor.component';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import dataConstants from '../../../data/constants';
import { IProcessConnection, IProcessNodeSetVariable, IProcessNodeSubprocess, IProcessNodeSwitch } from '../../../models/process';
import { IWorkflow } from '../../../models/workflow';
import { CONTEXT_VAR_NAME } from '../../../message/message-utils.service';
import { get } from 'lodash';
import { Stringify } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { createConnection, createSetVarNode, createSubProcessNode, createSwitchNode } from './utils/create-node-utils';

@Injectable({
  providedIn: 'root'
})
export class SendEmailConverterService extends CompositeNodeConverter {
  constructor() {
    super();
  }

  convert(nodeData: ISendEmailEditor, workflow: IWorkflow): INodeAndLink {
    if (!this.isValid(nodeData)) {
      throw new InvalidWorkflowNodeDataError(`Please correct invalid data on send email node`, nodeData);
    }

    const vars = {
      'Outbound source tip': Stringify({ name: 'ARRAY', args: [nodeData.fields.outboundSource.$tip] }),
      [CONTEXT_VAR_NAME]: get(nodeData.fields.outboundSource, 'contextFormula', 'ARRAY()'),
    };

    const setVarStatusNode = createSetVarNode(vars, nodeData.tip);
    const additionalVarKeys: string[] = [
      ...workflow.inputs.map(input => input.key),
      ...workflow.actors.map(actor => actor.key),
      ...workflow.variables.map(variable => variable.key)
    ];

    const subprocessNode = createSubProcessNode(
      'eim/process/message/send-email',
      ['Outbound source tip', CONTEXT_VAR_NAME, ...additionalVarKeys],
      60000
    );

    const doneConnectionForSetVarNode = createConnection('done', subprocessNode);
    setVarStatusNode.connections.push(doneConnectionForSetVarNode);

    const switchStatusNode = createSwitchNode({ name: 'VAR', args: ['Status'] }, undefined, undefined, ['Sent', 'Failed']);

    const doneConnectionForSubprocessNode = createConnection('done', switchStatusNode);
    subprocessNode.connections.push(doneConnectionForSubprocessNode);

    const nodesAndLink: INodeAndLink = {
      nodes: [
        setVarStatusNode,
        subprocessNode,
        switchStatusNode
      ],
      links: [
        doneConnectionForSetVarNode,
        doneConnectionForSubprocessNode
      ]
    };
    this.populateOutgoingLinks(nodeData).forEach(link => {
      switchStatusNode.connections.push(link);
      nodesAndLink.links.push(link);
    });

    return nodesAndLink;
  }

  private isValid(nodeData: ISendEmailEditor) {
    return !!nodeData.fields.outboundSource.$tip;
  }
}
