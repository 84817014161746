import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { flatten, head, last, reverse, truncate, get } from 'lodash';
import { Formula, Tip } from '../../data/models/types';
import { FormulaService } from '../../data/formula.service';
import { IVars } from '../../data/vars.service';
import { FormulaMultiService } from '../../data/formula-multi.service';
import { getFieldTipsFromFormulaString } from '../../util/get-field-tips-from-formula-string';
import { getSpecialContextFormulaLabel } from '../../util/special-context-formula';
import { getMostInnerContextPartsFromContextFormula } from '../../util/context-formula-wrapper';
import { Parser, Stringify } from './field-formula-side-sheet/formula';

export interface IFormulaLabelParams {
  formulaString: Formula;
  branches?: Tip[];
  vars?: IVars;
  contextTypeTip?: Tip;
}

const LABEL_FORMULA = 'FIELD("field/label")';
const TYPE_LABEL_FORMULA = 'FIELD("type/label")';

@Injectable({
  providedIn: 'root'
})
export class FormulaLabelService {
  constructor(
    private formulaService: FormulaService,
    private formulaMultiService: FormulaMultiService
  ) {
  }

  transform(
    formulaString: string,
    { branches, vars }: { branches?: string[], vars?: IVars } = {},
    contextTypeTip?: Tip
  ): Observable<string> {
    return this.transformBatch([{ formulaString, branches, vars, contextTypeTip }]).pipe(map(head));
  }

  transformBatch(formulaLabelParamsBatch: IFormulaLabelParams[]): Observable<string[]> {
    const { labelOrder, metaLabels, formulasToBatch } = formulaLabelParamsBatch
      .reduce((acc, formulaParam) => {
        const titleLabelOrder: string[] = [];

        // exceptions: Context type (Beginning)
        if (formulaParam.contextTypeTip) {
          const formulaServiceParams = {
            formula: TYPE_LABEL_FORMULA,
            context: formulaParam.contextTypeTip,
            vars: formulaParam.vars,
            contextBranches: formulaParam.branches
          };
          acc.formulasToBatch.push(formulaServiceParams);
          titleLabelOrder.push('label');
        }

        const { outerFormulas, mostInnerContextFormula } = getMostInnerContextPartsFromContextFormula(formulaParam.formulaString);
        [mostInnerContextFormula, ...reverse(outerFormulas)].forEach(formula => {
          // exceptions: Special context formulas (Custom formula, current user formula, meta data formula, relative date formula,
          // common field formula, etc.)
          const specialContextFormulaLabel = getSpecialContextFormulaLabel(formula);
          if (specialContextFormulaLabel) {
            titleLabelOrder.push('meta');
            acc.metaLabels.push(specialContextFormulaLabel);
            return;
          }

          // build field label formulas
          const parsedFormula = Parser(formula);
          if (parsedFormula.name === 'FIELD' || parsedFormula.name === 'FMT_FIELD') {
            const formulaServiceParams = {
              formula: LABEL_FORMULA,
              context: get(parsedFormula, 'args[0]'),
              vars: formulaParam.vars,
              contextBranches: formulaParam.branches
            };
            acc.formulasToBatch.push(formulaServiceParams);
            titleLabelOrder.push('label');
          }

          return acc;
        }, []);

        acc.labelOrder.push(titleLabelOrder);

        return acc;
      }, { labelOrder: [], metaLabels: [], formulasToBatch: [] });

    return this
      .formulaMultiService
      .evaluate(formulasToBatch)
      .pipe(
        map(flatten),
        map(groupFormulaResults(labelOrder, metaLabels)),
        map((r) => r.map(truncateResults)),
      );
  }
}

export function groupFormulaResults(labelOrder: string[][], metaLabels: string[]) {
  return function groupingResults(results: string[]): any {
    const foo = labelOrder.reduce((acc, order) => {
      const row = order.map((v) => {
        if (v === 'meta') {
          return metaLabels.shift();
        }
        if (v === 'label') {
          return results.shift();
        }
      });
      return [...acc, row];
    }, []);

    return foo as any;
  };
}

function truncateResults(results: string[]) {
  if (results.length <= 2) {
    return results.map(result => truncate(result)).join(' › ');
  }
  return truncate(head(results), { length: 15, omission: '…' })
    + ' › …'.repeat(Math.min(results.length - 2, 3))
    + ' › '
    + truncate(last(results), { length: 15, omission: '…' });
}


