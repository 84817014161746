import { Injectable } from '@angular/core';
import { NodeConverterBase } from './NodeConverterBase';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import { IProcessNodeSetVariable } from '../../../models/process';
import { ISetVariableEditor } from '../node-editors/set-variable-editor/set-variable-editor.component';
import { stringInterpolateFormula } from '../utils/converter-utils';
import { FilterInputService } from '../../../var/filter-input/filter-input.service';
import { createSetVarNode } from './utils/create-node-utils';
import { Formula } from '../../../data/models/types';
import { get } from 'lodash';
import { FormulaSpec, Stringify } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';

@Injectable({
  providedIn: 'root'
})
export class SetVariableNodeConverterService extends NodeConverterBase {
  type = 'processnode/reference/setvar';
  processNodeType = 'processnodetype/reference/setvar';

  constructor(
    private filterInputService: FilterInputService
  ) {
    super();
  }

  public convert(nodeData: ISetVariableEditor): IProcessNodeSetVariable {

    if (!this.isValid(nodeData)) {
      throw new InvalidWorkflowNodeDataError(`Please correct invalid data on set variable node [${nodeData.fields.title}]`, nodeData);
    }

    const vars: {[key: string]: Formula} = {};

    if (nodeData.fields.valueFormula.queryAndFilterOption) {
      const queryFormulaSpec: FormulaSpec = {
        name: 'QUERY',
        args: [
          nodeData.fields.valueFormula.queryAndFilterOption.queryTip,
          'branch/master',
          0,
          1000
        ]
      };
      const formula = Stringify(queryFormulaSpec);
      if (nodeData.fields.valueFormula.queryAndFilterOption.filterInputs &&
        nodeData.fields.valueFormula.queryAndFilterOption.filterInputs.length > 0
      ) { // handles a var node which is using a filter  with filter options
        const filterVars =
          this.filterInputService.convertWorkflowFilterInputsToVars(nodeData.fields.valueFormula.queryAndFilterOption.filterInputs);
        if (filterVars != null) {
          for (const key of Object.keys(filterVars)) {
            const value = get(filterVars, [key, '0'], null) as string;
            if (value) {
              vars[key] = value;
            }
          }
        }
        vars[nodeData.fields.variableToSetKey] = formula;
        return createSetVarNode(vars, nodeData.tip);
      } else {  // handles a var node which is using a filter with no filter options
        vars[nodeData.fields.variableToSetKey] = formula;
        return createSetVarNode(vars, nodeData.tip);
      }
    } else {  // handles var node for all other cases.
      vars[nodeData.fields.variableToSetKey] = stringInterpolateFormula(nodeData.fields.valueFormula);
      return createSetVarNode(vars, nodeData.tip);
    }
  }

  private isValid(nodeData: ISetVariableEditor): boolean {
    const formula = nodeData.fields.valueFormula ? nodeData.fields.valueFormula.formula : null;
    const key = nodeData.fields.variableToSetKey;

    const formulaIsValid: boolean = !(formula === null || formula === undefined || formula === '');
    const keyIsValid: boolean = !(key === null || key === undefined || key === '');

    const queryFilterValid = nodeData.fields.valueFormula && nodeData.fields.valueFormula.queryAndFilterOption ?
      (nodeData.fields.valueFormula.queryAndFilterOption.queryTip != null) : false;

    return (formulaIsValid || queryFilterValid) && keyIsValid;
  }
}
