import * as tslib_1 from "tslib";
import { DataTypes } from '../../../data/models/scheme';
import { objectTypeScheme } from '../../../models/object-type';
var formBase = {
    'app/form:name': { type: DataTypes.i18n },
    'app/form:description': { type: DataTypes.i18n },
    'app/form:size': { type: DataTypes.string },
    'app/form:version': { type: DataTypes.number },
    'app/form:tabs': { type: DataTypes.jsonArray },
    'app/form:type': { type: DataTypes.string },
    'app/form:status': { type: DataTypes.string },
    'app/form:security-profile': { type: DataTypes.string },
    'app/form:url': { type: DataTypes.string },
    'app/form:show-form-breadcrumbs': { type: DataTypes.boolean }
};
export var formScheme = tslib_1.__assign({ 'app/form:context-type': {
        type: DataTypes.object,
        scheme: objectTypeScheme
    } }, formBase);
export var flatFormScheme = tslib_1.__assign({ 'app/form:context-type': { type: DataTypes.string } }, formBase);
export var FormState;
(function (FormState) {
    FormState["ACTIVE"] = "active";
    FormState["INACTIVE"] = "inactive";
})(FormState || (FormState = {}));
export var FormType;
(function (FormType) {
    FormType["REGULAR"] = "regular";
    FormType["PUBLIC"] = "public";
})(FormType || (FormType = {}));
