import { ReminderUnit } from '../form/assignable-field/reminder-units';
import { IObject } from '../data/models/object';
import { Tip } from '../data/models/types';
import { DataTypes, IObjectScheme } from '../data/models/scheme';

export const ASSIGNMENT_TYPE = 'app/assignment';

export interface IAssignable extends IObject {
  assignee: Tip | null;
  due: Date | null;
  // reminderValue: number | null;
  // reminderUnit: ReminderUnit | null;
}

export const AssignedScheme: IObjectScheme = {
  'app/assignment:assignee': { type: DataTypes.string },
  'app/assignment:due': { type: DataTypes.datetime },
  // 'app/assignment:reminder-value': { type: DataTypes.number },
  // 'app/assignment:reminder-unit': { type: DataTypes.string }
};
