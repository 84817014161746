import * as tslib_1 from "tslib";
import { transformExtendFormlyFieldToFormBuilderField } from './services-core/field-config';
import { SKETCH_TOOL } from '../../shared/map/map/map-util.service';
/*
  To configure the form you need to configure
    - form-option (this file)
    - form-options-noggin
    - form-options-pallet
*/
// section
export var SECTION_HEADER_FORM_CONFIG = {
    type: 'section-header',
    templateOptions: {
        label: 'Section header',
        title: 'Section title',
    },
};
// content
export var TEXT_CONTENT_FORM_CONFIG = {
    type: 'text-content',
    templateOptions: {
        label: 'Text content',
        html: "<h3>Text content</h3><p>Text content</p>",
    },
    conditionalFields: {
        visibility: {
            value: 'true',
            conditions: {}
        }
    }
};
export var CHART_CONTENT_FORM_CONFIG = {
    type: 'chart-content',
    templateOptions: {
        label: 'Chart content',
        chartConfig: {
            title: '',
            canExport: false,
            chartTip: null,
            filterInputs: ''
        }
    },
    conditionalFields: {
        visibility: {
            value: 'true',
            conditions: {}
        }
    }
};
export var TABLE_CONTENT_FORM_CONFIG = {
    type: 'table-content',
    templateOptions: {
        label: 'Table content',
        tableConfig: {
            title: '',
            limit: 10,
            objectTypeTip: null,
            queryTip: null,
            columns: [],
            filterInputs: []
        }
    },
    conditionalFields: {
        visibility: {
            value: 'true',
            conditions: {}
        }
    }
};
// fields
export var LIST_ITEMS_SELECT_FORM_CONFIG = {
    type: 'list-items-select',
    templateOptions: {
        label: 'List',
        placeholder: 'Select your option',
        contextTypeTip: '',
    },
    conditionalFields: {
        readonly: {
            value: 'false',
            conditions: {}
        },
        required: {
            value: 'false',
            conditions: {}
        },
        visibility: {
            value: 'true',
            conditions: {}
        }
    }
};
export var RICH_TEXT_FORM_CONFIG = {
    type: 'rich-text',
    templateOptions: {
        label: 'Rich text',
        contextTypeTip: '',
    },
    conditionalFields: {
        readonly: {
            value: 'false',
            conditions: {}
        },
        required: {
            value: 'false',
            conditions: {}
        },
        visibility: {
            value: 'true',
            conditions: {}
        }
    }
};
export var SINGLE_LINE_TEXT_FORM_CONFIG = {
    type: 'input',
    templateOptions: {
        type: 'text',
        label: 'Single-line text',
        placeholder: 'Single-line text',
        contextTypeTip: '',
    },
    conditionalFields: {
        readonly: {
            value: 'false',
            conditions: {}
        },
        required: {
            value: 'false',
            conditions: {}
        },
        visibility: {
            value: 'true',
            conditions: {}
        }
    }
};
export var PHONE_NUMBER_FORM_CONFIG = {
    type: 'phone-number',
    templateOptions: {
        type: 'tel',
        label: 'Phone number',
        placeholder: 'Phone number',
        contextTypeTip: ''
    },
    conditionalFields: {
        readonly: {
            value: 'false',
            conditions: {}
        },
        required: {
            value: 'false',
            conditions: {}
        },
        visibility: {
            value: 'true',
            conditions: {}
        }
    }
};
export var EMAIL_ADDRESS_FORM_CONFIG = {
    type: 'email-address',
    templateOptions: {
        type: 'email',
        label: 'Email address',
        placeholder: 'Email address',
        contextTypeTip: ''
    },
    conditionalFields: {
        readonly: {
            value: 'false',
            conditions: {}
        },
        required: {
            value: 'false',
            conditions: {}
        },
        visibility: {
            value: 'true',
            conditions: {}
        }
    }
};
export var WEB_ADDRESS_FORM_CONFIG = {
    type: 'web-address',
    templateOptions: {
        type: 'url',
        label: 'Web address',
        placeholder: 'Web address',
        contextTypeTip: ''
    },
    conditionalFields: {
        readonly: {
            value: 'false',
            conditions: {}
        },
        required: {
            value: 'false',
            conditions: {}
        },
        visibility: {
            value: 'true',
            conditions: {}
        }
    }
};
export var MULTI_LINE_TEXT_CONFIG = {
    type: 'multi-line-text',
    templateOptions: {
        label: 'Multi-line text',
        placeholder: 'Multi-line text',
        contextTypeTip: ''
    },
    conditionalFields: {
        readonly: {
            value: 'false',
            conditions: {}
        },
        required: {
            value: 'false',
            conditions: {}
        },
        visibility: {
            value: 'true',
            conditions: {}
        }
    }
};
export var DATE_FORM_CONFIG = {
    type: 'date-time',
    templateOptions: {
        type: 'date',
        label: 'Date',
        placeholder: 'Date',
        contextTypeTip: '',
        dateFormat: null,
        dateValidation: ''
    },
    conditionalFields: {
        readonly: {
            value: 'false',
            conditions: {}
        },
        required: {
            value: 'false',
            conditions: {}
        },
        visibility: {
            value: 'true',
            conditions: {}
        }
    }
};
export var DATE_TIME_FORM_CONFIG = {
    type: 'date-time',
    templateOptions: {
        // type !!!!! needs to match the key in the datetime field
        type: 'datetime',
        label: 'Date & time',
        placeholder: 'Date & time',
        contextTypeTip: '',
        dateFormat: null,
        timeFormat: null,
        timezone: 'User timezone',
        dateValidation: ''
    },
    conditionalFields: {
        readonly: {
            value: 'false',
            conditions: {}
        },
        required: {
            value: 'false',
            conditions: {}
        },
        visibility: {
            value: 'true',
            conditions: {}
        }
    }
};
export var NUMBER_FORM_CONFIG = {
    type: 'number',
    templateOptions: {
        label: 'Number',
        placeholder: 'Number',
        contextTypeTip: '',
        numberFormat: 'normal',
        numberPrefix: '',
        numberSuffix: '',
        numberThousandsSeparator: '',
        numberDecimalSeparator: '',
        numberDecimalPlaces: null
    },
    conditionalFields: {
        readonly: {
            value: 'false',
            conditions: {}
        },
        required: {
            value: 'false',
            conditions: {}
        },
        visibility: {
            value: 'true',
            conditions: {}
        }
    }
};
export var CHECKBOX_FORM_CONFIG = {
    type: 'checkbox',
    templateOptions: {
        label: 'Checkbox',
        contextTypeTip: '',
    },
    conditionalFields: {
        readonly: {
            value: 'false',
            conditions: {}
        },
        required: {
            value: 'false',
            conditions: {}
        },
        visibility: {
            value: 'true',
            conditions: {}
        }
    }
};
export var BOOLEAN_CHOOSER_FORM_CONFIG = {
    type: 'boolean-chooser',
    templateOptions: {
        label: 'Boolean Chooser',
        contextTypeTip: '',
    },
    conditionalFields: {
        readonly: {
            value: 'false',
            conditions: {}
        },
        required: {
            value: 'false',
            conditions: {}
        },
        visibility: {
            value: 'true',
            conditions: {}
        }
    }
};
export var FILE_UPLOAD_FORM_CONFIG = {
    type: 'file-upload',
    templateOptions: {
        label: 'File upload',
        contextTypeTip: '',
    },
    conditionalFields: {
        readonly: {
            value: 'false',
            conditions: {}
        },
        required: {
            value: 'false',
            conditions: {}
        },
        visibility: {
            value: 'true',
            conditions: {}
        }
    }
};
export var POINT_FORM_CONFIG = {
    type: 'geo-chooser',
    templateOptions: {
        type: SKETCH_TOOL.POINT,
        label: 'Point chooser',
        placeholder: 'Select point',
        contextTypeTip: '',
        mapTip: ''
    },
    conditionalFields: {
        readonly: {
            value: 'false',
            conditions: {}
        },
        required: {
            value: 'false',
            conditions: {}
        },
        visibility: {
            value: 'true',
            conditions: {}
        }
    }
};
export var LINE_FORM_CONFIG = {
    type: 'geo-chooser',
    templateOptions: {
        type: SKETCH_TOOL.POLYLINE,
        label: 'Line chooser',
        placeholder: 'Line not set',
        contextTypeTip: '',
        mapTip: ''
    },
    conditionalFields: {
        readonly: {
            value: 'false',
            conditions: {}
        },
        required: {
            value: 'false',
            conditions: {}
        },
        visibility: {
            value: 'true',
            conditions: {}
        }
    }
};
export var POLYGON_FORM_CONFIG = {
    type: 'geo-chooser',
    templateOptions: {
        type: SKETCH_TOOL.POLYGON,
        label: 'Polygon chooser',
        placeholder: 'Select polygon',
        contextTypeTip: '',
        mapTip: ''
    },
    conditionalFields: {
        readonly: {
            value: 'false',
            conditions: {}
        },
        required: {
            value: 'false',
            conditions: {}
        },
        visibility: {
            value: 'true',
            conditions: {}
        }
    }
};
export var ADDRESS_FORM_CONFIG = {
    type: 'address-chooser',
    templateOptions: {
        label: 'Address chooser',
        placeholder: 'Select address',
        contextTypeTip: '',
        mapTip: ''
    },
    conditionalFields: {
        readonly: {
            value: 'false',
            conditions: {}
        },
        required: {
            value: 'false',
            conditions: {}
        },
        visibility: {
            value: 'true',
            conditions: {}
        }
    }
};
export var OBJECT_CHOOSER_FORM_CONFIG = {
    type: 'object-chooser',
    templateOptions: {
        label: 'Object chooser',
        placeholder: 'Select object',
        contextTypeTip: '',
        filter: {},
        hierarchicalView: '',
        hidePlusButton: false
    },
    conditionalFields: {
        readonly: {
            value: 'false',
            conditions: {}
        },
        required: {
            value: 'false',
            conditions: {}
        },
        visibility: {
            value: 'true',
            conditions: {}
        }
    }
};
export var SUB_OBJECT_CHOOSER_FORM_CONFIG = {
    type: 'sub-object-chooser',
    templateOptions: {
        label: 'Sub-object chooser',
        placeholder: 'Select sub-object',
        contextTypeTip: ''
    },
    conditionalFields: {
        readonly: {
            value: 'false',
            conditions: {}
        },
        required: {
            value: 'false',
            conditions: {}
        },
        visibility: {
            value: 'true',
            conditions: {}
        }
    }
};
export var ASSIGNABLE_FORM_CONFIG = {
    type: 'assignable',
    templateOptions: {
        label: 'Assigned to',
        placeholder: 'Unassigned',
        contextTypeTip: ''
    },
    conditionalFields: {
        readonly: {
            value: 'false',
            conditions: {}
        },
        required: {
            value: 'false',
            conditions: {}
        },
        visibility: {
            value: 'true',
            conditions: {}
        }
    }
};
export var structureFormOptions = [
    SECTION_HEADER_FORM_CONFIG
];
var contentFormOptions = [
    TEXT_CONTENT_FORM_CONFIG,
    CHART_CONTENT_FORM_CONFIG,
    TABLE_CONTENT_FORM_CONFIG
];
var publicFormContentFormOptions = [
    TEXT_CONTENT_FORM_CONFIG
];
var fieldFormOptions = function (subObjects, isFormPublic) {
    if (isFormPublic === void 0) { isFormPublic = false; }
    var subObject = subObjects ? [SUB_OBJECT_CHOOSER_FORM_CONFIG] : [];
    var extendFieldTypes = !isFormPublic ? [
        ASSIGNABLE_FORM_CONFIG,
        ADDRESS_FORM_CONFIG,
        POINT_FORM_CONFIG,
        LINE_FORM_CONFIG,
        POLYGON_FORM_CONFIG
    ] : [];
    return tslib_1.__spread([
        SINGLE_LINE_TEXT_FORM_CONFIG,
        MULTI_LINE_TEXT_CONFIG,
        RICH_TEXT_FORM_CONFIG,
        NUMBER_FORM_CONFIG,
        CHECKBOX_FORM_CONFIG,
        DATE_FORM_CONFIG,
        DATE_TIME_FORM_CONFIG,
        LIST_ITEMS_SELECT_FORM_CONFIG,
        OBJECT_CHOOSER_FORM_CONFIG
    ], subObject, [
        PHONE_NUMBER_FORM_CONFIG,
        EMAIL_ADDRESS_FORM_CONFIG,
        WEB_ADDRESS_FORM_CONFIG,
        FILE_UPLOAD_FORM_CONFIG
    ], extendFieldTypes);
};
var ɵ0 = fieldFormOptions;
export var formOptionsGroups = function (subObjects, isPublicForm) {
    if (isPublicForm === void 0) { isPublicForm = false; }
    return [
        {
            title: 'Structure',
            options: structureFormOptions.map(transformExtendFormlyFieldToFormBuilderField)
        },
        {
            title: 'Content',
            options: isPublicForm
                ? publicFormContentFormOptions.map(transformExtendFormlyFieldToFormBuilderField)
                : contentFormOptions.map(transformExtendFormlyFieldToFormBuilderField)
        },
        {
            title: 'Fields',
            options: fieldFormOptions(subObjects, isPublicForm).map(transformExtendFormlyFieldToFormBuilderField)
        }
    ];
};
export { ɵ0 };
