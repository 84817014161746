import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { iif, Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { isEqual } from 'lodash';

import { IProcessResponse, ProcessService } from '../data/process.service';
import { AuthService } from './auth.service';
import { Email, Tip } from '../data/models/types';

const PROCESS_TIP = 'eim/process/auth/update-user-email-after-verification';

@Injectable({
  providedIn: 'root'
})
export class AcceptVerificationResolverService {
  constructor(
    private processService: ProcessService,
    private authService: AuthService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    const oldEmail: Email = route.queryParams.oldEmail;
    const newEmail: Email = route.queryParams.email;
    const userTip: Tip = route.queryParams.user;
    const token: string = route.queryParams.token;

    return this.changeCredentialThenUserRecord(oldEmail, newEmail, token, userTip);
  }

  private changeCredentialThenUserRecord(oldEmail: Email, newEmail: Email, token: string, userTip: Tip): Observable<boolean> {
    return this.authService.activateNewEmail(oldEmail, newEmail, token).pipe(
      switchMap(changeCredentialSuccess => changeCredentialSuccess ? this.changeUserRecord(newEmail, userTip) : of(false))
    );
  }

  private changeUserRecord(newEmail: Email, userTip: Tip): Observable<boolean> {
    return this.processService.start(
      PROCESS_TIP,
      {
        Email: [newEmail],
        User: [userTip]
      }
    ).pipe(
      first(({ finished }) => finished),
      map(({ vars }: IProcessResponse) => isEqual(vars['Verification status'], ['Successful']))
    );
  }
}
