import * as tslib_1 from "tslib";
import { NodeConverterBase } from './NodeConverterBase';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import { isEmpty } from 'lodash';
import dataConstants from '../../../data/constants';
import * as i0 from "@angular/core";
var DuplicateObjectNodeConverterService = /** @class */ (function (_super) {
    tslib_1.__extends(DuplicateObjectNodeConverterService, _super);
    function DuplicateObjectNodeConverterService() {
        var _this = _super.call(this) || this;
        _this.type = 'processnode/data/duplicate';
        _this.processNodeType = 'processnodetype/data/duplicate';
        return _this;
    }
    DuplicateObjectNodeConverterService.prototype.convert = function (nodeData) {
        if (!this.isValid(nodeData)) {
            throw new InvalidWorkflowNodeDataError("Please correct invalid data on duplicate object node [" + nodeData.fields.title + "]", nodeData);
        }
        return {
            $type: this.type,
            $security: dataConstants.SECURITY.INSTANCE_USER_ADMIN,
            type: this.processNodeType,
            connections: [],
            point: [0, 0],
            key: nodeData.tip,
            duplicateTip: [nodeData.fields.objectToDuplicate.formula],
            duplicateFields: nodeData.fields.objectFields.map(function (o) { return o.tip; }),
            duplicateTipVar: nodeData.fields.variableToSetKey,
            duplicateSourceBranch: dataConstants.BRANCH_MASTER,
            duplicateDestBranch: dataConstants.BRANCH_MASTER,
            duplicateBackwardRefs: false,
            duplicateTipVarScope: 'Global',
        };
    };
    DuplicateObjectNodeConverterService.prototype.isValid = function (nodeData) {
        return !isEmpty(nodeData.fields.objectToDuplicate ? nodeData.fields.objectToDuplicate.formula : null);
    };
    DuplicateObjectNodeConverterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DuplicateObjectNodeConverterService_Factory() { return new DuplicateObjectNodeConverterService(); }, token: DuplicateObjectNodeConverterService, providedIn: "root" });
    return DuplicateObjectNodeConverterService;
}(NodeConverterBase));
export { DuplicateObjectNodeConverterService };
