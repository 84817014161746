<ng-container *ngIf="showErrorMessage; then error; else validatingToken"></ng-container>
<ng-template #validatingToken >
  <div class="d-flex flex-column" data-test="sso-validating-token">
    <img src="/assets/v1.627.2-beta/assets/img/loading-animations/noggin-waiting.gif">
    <h4 class="text-center" *ngIf="!showGoToAppButton">Validating token</h4>
    <button *ngIf="showGoToAppButton"
            class="btn btn-block eim-button_noggin"
            type="button" (click)="goToApp()">Go to app</button>
  </div>
</ng-template>
<ng-template #error>
  <div class="d-flex flex-column" data-test="sso-error-message">
    <h4>“Sorry, we were unable to single-sign you in. Please try again or contact your administrator.”</h4>
    <div class="d-flex justify-content-center">
      <a
              class="btn btn-primary home-button"
              routerLink="/signin">Try again</a>
    </div>
  </div>
</ng-template>
