import { Injectable } from '@angular/core';
import { NodeConverterBase } from './NodeConverterBase';
import { ISubworkflowEditor } from '../node-editors/subworkflow-editor/subworkflow-editor.component';
import { IProcessNodeSubprocess } from '../../../models/process';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import dataConstants from '../../../data/constants';
import { Stringify } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { get } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class SubworkflowNodeConverterService extends NodeConverterBase {
  type = 'processnode/logic/subprocess';
  processNodeType = 'processnodetype/logic/subprocess';

  public convert(nodeData: ISubworkflowEditor): IProcessNodeSubprocess {

    if (!this.isValid(nodeData)) {
      throw new InvalidWorkflowNodeDataError(`Please correct invalid data on sub-workflow node [${nodeData.fields.title}]`, nodeData);
    }

    const subWorkflowProcessTipFormula = Stringify({
      name: 'FIELD',
      args: [
        'app/workflow:process',
        get(nodeData, 'fields.subworkflow', null)
      ]
    });

    return {
      $type: this.type,
      $security: dataConstants.SECURITY.INSTANCE_USER_ADMIN,
      type: this.processNodeType,
      connections: [],
      point: [0, 0],
      key: nodeData.tip,
      process: subWorkflowProcessTipFormula,
      varKeys: nodeData.fields.inputs,
      timeoutMs: nodeData.fields.timeoutAfter,
      waitForProcessComplete: nodeData.fields.waitForCompletion
    };
  }

  private isValid(nodeData: ISubworkflowEditor): boolean {
    const subworkflowProcess = nodeData.fields.subworkflowProcess;

    return !(subworkflowProcess === null || subworkflowProcess === undefined || subworkflowProcess === '');
  }

  constructor() {
    super();
  }
}
