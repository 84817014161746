import * as tslib_1 from "tslib";
import { forkJoin, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { FIELD_DATA_TYPE } from '../models/field';
import { addDateFormatWrapper, addDatetimeFormatWrapper } from './datetime-format-formula-wrapper';
import { get, isEqual, isEmpty } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "./get-formula-datatype.service";
import * as i2 from "../data/formula.service";
var DEFAULT_RULE = {
    date: false,
    datetime: false,
    list: false,
    stripHTML: false
};
var DisplayFormulaWrapperService = /** @class */ (function () {
    function DisplayFormulaWrapperService(getFormulaDataTypeService, formulaService) {
        this.getFormulaDataTypeService = getFormulaDataTypeService;
        this.formulaService = formulaService;
    }
    DisplayFormulaWrapperService.prototype.addDisplayFormulaWrapper = function (formulas, contextTypeTip, options) {
        var _this = this;
        // apply default rule
        options = tslib_1.__assign({}, DEFAULT_RULE, options);
        // if options are all set to false then do early return
        if (isEqual(options, DEFAULT_RULE)) {
            return of(formulas);
        }
        // do a early return if formulas are empty
        if (isEmpty(formulas)) {
            return of([]);
        }
        return forkJoin(formulas.map(function (formula) { return _this.getFormulaDataTypeService.getFormulaDataType(formula, contextTypeTip).pipe(map(function (meta) {
            if (meta.datatype === FIELD_DATA_TYPE.date && options.date) {
                return addDateFormatWrapper(formula);
            }
            if (meta.datatype === FIELD_DATA_TYPE.datetime && options.datetime) {
                return addDatetimeFormatWrapper(formula);
            }
            if (meta.datatype === FIELD_DATA_TYPE.html && options.stripHTML) {
                return addHtmlDisplayWrapper(formula);
            }
            if (meta.listTip && options.list) {
                return addListDisplayWrapper(formula, meta.listTip);
            }
            return formula;
        }), first()); }));
    };
    DisplayFormulaWrapperService.prototype.removeDisplayFormulaWrapper = function (formula) {
        var parsedFormula = this.formulaService.parse(formula);
        if (parsedFormula.name === 'CONCAT'
            && get(parsedFormula, 'args[0].name') === 'FORMAT_DATETIME') {
            return this.formulaService.stringify(get(parsedFormula, 'args[0].args[0]'));
        }
        if (parsedFormula.name === 'STRIP_TAGS'
            && get(parsedFormula, 'args[0].name') === 'REPLACE') {
            return this.formulaService.stringify(get(parsedFormula, 'args[0].args[0]'));
        }
        if (parsedFormula.name === 'CONCAT_WS'
            && get(parsedFormula, 'args[1].name') === 'MAP') {
            return this.formulaService.stringify(get(parsedFormula, 'args[1].args[0]'));
        }
        return formula;
    };
    DisplayFormulaWrapperService.prototype.removeDisplayFormulaWrappers = function (formulas) {
        var _this = this;
        return formulas.map(function (formula) { return _this.removeDisplayFormulaWrapper(formula); });
    };
    DisplayFormulaWrapperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DisplayFormulaWrapperService_Factory() { return new DisplayFormulaWrapperService(i0.ɵɵinject(i1.GetFormulaDatatypeService), i0.ɵɵinject(i2.FormulaService)); }, token: DisplayFormulaWrapperService, providedIn: "root" });
    return DisplayFormulaWrapperService;
}());
export { DisplayFormulaWrapperService };
function addHtmlDisplayWrapper(formula) {
    return "STRIP_TAGS(REPLACE(" + formula + ", \"</p>\", \"\n\"))";
}
function addListDisplayWrapper(formula, listTip) {
    // tslint:disable-next-line:max-line-length
    return "CONCAT_WS(\", \", MAP(" + formula + ", NTH(FIELD(\"app/list:labels\", \"" + listTip + "\"), INDEXOF(FIELD(\"app/list:values\", \"" + listTip + "\"), VAR(\"Value\")))))";
}
