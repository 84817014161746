import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { first, switchMap, map, takeUntil, filter } from 'rxjs/operators';
import { Tip } from '../../../../data/models/types';
import { IProcessResponse, ProcessService } from '../../../../data/process.service';
import { FormlyTemplateOptions } from '@ngx-formly/core';
import { IExtendedFormlyFieldConfig } from '../../../form-designer/form.types';
import { IWorkflowUXItem } from '../../../workflow-designer/workflow-designer-interfaces';
import { MeService } from '../../../../shell/services/me.service';
import { FormulaService } from '../../../../data/formula.service';
import { ObjectService } from '../../../../data/object-service/object.service';
import { IUserReadOnly } from '../../../../models/user';
import { IForm } from '../../../form-designer/models/form';
import { IWorkflowRuntime, workflowRuntimeScheme } from '../../../../models/workflow-runtime';
import { Stringify } from '../../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';

const NS = 'WorkflowEndUserService';
export const NON_FIELD_FORM_TYPES = ['section-header', 'chart-content', 'text-content', 'table-content'];

/**
 * Note that the dynamic outcomes are forced to camel case, so that they never collide with the UX_NODE_OUTCOME values.
 */
export enum UX_NODE_OUTCOME {
  primary = 'Primary',
  secondary = 'Secondary',
  noop = 'Noop' // does nothing. Used as default for switch process
}

export interface IErrorCheck {
  field: IExtendedFormlyFieldConfig;
  label: string;
}

@Injectable({
  providedIn: 'root'
})
export class WorkflowEndUserService {
  private newWorkflowRuntimeSubject$;

  public newUX$: Observable<IWorkflowUXItem> = new Observable();
  public newOutcome$: Observable<string> = new Observable();
  public newWorkflowRuntime$: Observable<IWorkflowRuntime> = new Observable();

  constructor(
    private processService: ProcessService,
    private meService: MeService,
    private formulaService: FormulaService,
    private objectService: ObjectService
  ) { }

  watchWorkflowRuntime(workflowRuntimeTip: Tip, cancel$: Observable<any>) {
    this.newWorkflowRuntimeSubject$ = new ReplaySubject<IWorkflowRuntime>(1);

    this.newWorkflowRuntime$ = this.newWorkflowRuntimeSubject$.asObservable();
    this.newUX$ = this.newWorkflowRuntimeSubject$.pipe(switchMap(({ ux }) => this.getWorkflowUXItem(ux)), filter(Boolean));
    this.newOutcome$ = this.newWorkflowRuntimeSubject$.pipe(map(({ outcome }) => outcome), filter(Boolean));

    this.objectService
        .getObject<IWorkflowRuntime>(workflowRuntimeTip, workflowRuntimeScheme)
        .pipe(takeUntil(cancel$))
        // .pollObject<IWorkflowRuntime>({ tip: workflowRuntimeTip, scheme: workflowRuntimeScheme, interval: 1000, cancel$})
        .subscribe(workflowRuntime => this.newWorkflowRuntimeSubject$.next(workflowRuntime));
  }

  runUXNodeOutcomeProcess(workflowRuntimeTip: Tip, outcome: string, objectTip: Tip, isGoverned: boolean): Observable<IProcessResponse> {
    return this.processService.start(
      'eim/process/workflow-ux/set-outcome-reference',
      {
        'Workflow runtime tip': [workflowRuntimeTip],
        Outcome: [outcome],
        'Object tip': objectTip ? [objectTip] : [],
        'Is governed': [isGoverned ? 'true' : 'false']
      }).pipe(
      first((processResponse: IProcessResponse) => processResponse.finished)
    );
  }

  getWorkflowUXItem(workflowUXItems: IWorkflowUXItem[]): Observable<IWorkflowUXItem | null> {
    if (!workflowUXItems || !workflowUXItems.length) {
      return of(null);
    }

    return this.checkForMatchingActors(workflowUXItems)
               .pipe(map(matchResult => this.getFirstValidWorkflowUXItem({ matchResult, workflowUXItems })));
  }

  /**
   * Gets the first Workflow UX rule object that resolves to true
   */
  private getFirstValidWorkflowUXItem({ matchResult, workflowUXItems }): IWorkflowUXItem {
    let workflowUXItem: IWorkflowUXItem;
    matchResult.some((isSuccessMatch: boolean, i) => {
      if (isSuccessMatch) {
        workflowUXItem = workflowUXItems[i];
        this.convertEmptyArraysToNull(workflowUXItem);
      }
      return isSuccessMatch;
    });

    return workflowUXItem || null;
  }

  /**
   * Converts any listed string values that have come through as empty arrays, into null
   */
  private convertEmptyArraysToNull(workflowUXItem: IWorkflowUXItem): void {
    for (const propName in workflowUXItem) {
      if (workflowUXItem[propName]) {
        const prop = workflowUXItem[propName];
        switch (propName) {
          case 'primaryButtonLabel':
          case 'instructionsSubtitle':
          case 'instructionsTitle':
          case 'instructionsIntent':
            workflowUXItem[propName] = Array.isArray(prop) && !prop.length ? null : prop;
            break;
        }
      }
    }
  }

  /**
   * Checks if any UX rules have actors that match the current user by 'user', 'profile' or 'team'.
   * Resolves to array of string booleans (eg ['true', 'false']) in the same order as the workflowUXItems.
   * true means matches
   */
  private checkForMatchingActors(workflowUXItems: IWorkflowUXItem[]): Observable<boolean[]> {
    const getFormula = (currentUser: IUserReadOnly) => Stringify({
      name: 'ARRAY',
      args: workflowUXItems.map(item =>
        !item.actors || !item.actors.length ?
          'true' :
          {
            name: 'NOT',
            args: [{
              name: 'EQUALS',
              args: [
                {
                  name: 'INTERSECT',
                  args: [
                    {
                      name: 'UNIQUE',
                      args: [
                        {
                          name: 'ARRAY',
                          args: [
                            currentUser.$tip,
                            ...currentUser.profiles,
                            { name: 'REFERENCES', args: ['app/team-role:users', currentUser.$tip] }
                          ]
                        }
                      ]
                    },
                    { name: 'ARRAY', args: item.actors }
                  ]
                },
                { name: 'ARRAY', args: [] }
              ]
            }]
          }
      )
    });

    return this
      .meService
      .me$
      .pipe(
        filter(Boolean),
        first(),
        switchMap((currentUser: IUserReadOnly) => this.formulaService.evaluate(getFormula(currentUser))),
        map(matches => matches.map(s => s === 'true'))
      );
  }

  /**
   * Cycles through form fields to look for potential errors
   */
  checkForErrors(form: IForm): IErrorCheck[] {
    if (!form) { return []; }

    const errList: { field: IExtendedFormlyFieldConfig, label: string }[] = [];

    this.getFields(form).forEach(field => {
      const opts: FormlyTemplateOptions = field.templateOptions;
      // NON_FIELD_FORM_TYPES are exempt from needing a contextTypeTip

      if (!opts || (!opts.contextTypeTip && !NON_FIELD_FORM_TYPES.includes(field.type))) {
        errList.push({
          field, label: opts ? opts.label : '(unknown)'
        });
      }
    });

    return errList;
  }

  private getFields(form: IForm): IExtendedFormlyFieldConfig[] {
    const fields: IExtendedFormlyFieldConfig[] = [];
    form.tabs.forEach(tab => {
      tab.rows.forEach(row => {
        row.fields.forEach(field => {
          fields.push(field);
        });
      });
    });

    return fields;
  }
}
