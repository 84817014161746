import { ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { first } from 'rxjs/operators';
import { initRedoc, getRedocEl } from './api';
var APIComponent = /** @class */ (function () {
    function APIComponent(elementRef, apiService) {
        this.elementRef = elementRef;
        this.apiService = apiService;
    }
    APIComponent.prototype.ngAfterViewInit = function () {
        this.getOpenAPISpecAndRender();
    };
    APIComponent.prototype.ngOnDestroy = function () {
        this
            .elementRef
            .nativeElement
            .querySelector('#redoc')
            .remove();
    };
    APIComponent.prototype.getOpenAPISpecAndRender = function () {
        var _this = this;
        this
            .apiService
            .getOpenAPISpec()
            .pipe(first())
            .subscribe(function (_a) {
            var openAPISpec = _a.openAPISpec, redoc = _a.redoc;
            initRedoc(redoc, openAPISpec, getRedocEl(_this.elementRef.nativeElement));
        });
    };
    return APIComponent;
}());
export { APIComponent };
