import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'asset'
})
export class AssetPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return `${environment.assetPath}${value}`;
  }
}
