import { IExtendedFormlyFieldConfig, IFormBuilderFieldConfig } from './form.types';
import { transformExtendFormlyFieldToFormBuilderField } from './services-core/field-config';
import { SKETCH_TOOL } from '../../shared/map/map/map-util.service';

/*
  To configure the form you need to configure
    - form-option (this file)
    - form-options-noggin
    - form-options-pallet
*/

// section

export const SECTION_HEADER_FORM_CONFIG: IExtendedFormlyFieldConfig = {
  type: 'section-header',
  templateOptions: {
    label: 'Section header',
    title: 'Section title',
  },
};

// content

export const TEXT_CONTENT_FORM_CONFIG: IExtendedFormlyFieldConfig = {
  type: 'text-content',
  templateOptions: {
    label: 'Text content',
    html: `<h3>Text content</h3><p>Text content</p>`,
  },
  conditionalFields: {
    visibility: {
      value: 'true',
      conditions: {}
    }
  }
};

export const CHART_CONTENT_FORM_CONFIG: IExtendedFormlyFieldConfig = {
  type: 'chart-content',
  templateOptions: {
    label: 'Chart content',
    chartConfig: {
      title: '',
      canExport: false,
      chartTip: null,
      filterInputs: ''
    }
  },
  conditionalFields: {
    visibility: {
      value: 'true',
      conditions: {}
    }
  }
};

export const TABLE_CONTENT_FORM_CONFIG: IExtendedFormlyFieldConfig = {
  type: 'table-content',
  templateOptions: {
    label: 'Table content',
    tableConfig: {
      title: '',
      limit: 10,
      objectTypeTip: null,
      queryTip: null,
      columns: [],
      filterInputs: []
    }
  },
  conditionalFields: {
    visibility: {
      value: 'true',
      conditions: {}
    }
  }
};

// fields

export const LIST_ITEMS_SELECT_FORM_CONFIG: IExtendedFormlyFieldConfig = {
  type: 'list-items-select',
  templateOptions: {
    label: 'List',
    placeholder: 'Select your option',
    contextTypeTip: '',
  },
  conditionalFields: {
    readonly: {
      value: 'false',
      conditions: {}
    },
    required: {
      value: 'false',
      conditions: {}
    },
    visibility: {
      value: 'true',
      conditions: {}
    }
  }
};

export const RICH_TEXT_FORM_CONFIG: IExtendedFormlyFieldConfig = {
  type: 'rich-text',
  templateOptions: {
    label: 'Rich text',
    contextTypeTip: '',
  },
  conditionalFields: {
    readonly: {
      value: 'false',
      conditions: {}
    },
    required: {
      value: 'false',
      conditions: {}
    },
    visibility: {
      value: 'true',
      conditions: {}
    }
  }
};

export const SINGLE_LINE_TEXT_FORM_CONFIG: IExtendedFormlyFieldConfig = {
  type: 'input',
  templateOptions: {
    type: 'text',
    label: 'Single-line text',
    placeholder: 'Single-line text',
    contextTypeTip: '',
  },
  conditionalFields: {
    readonly: {
      value: 'false',
      conditions: {}
    },
    required: {
      value: 'false',
      conditions: {}
    },
    visibility: {
      value: 'true',
      conditions: {}
    }
  }
};

export const PHONE_NUMBER_FORM_CONFIG: IExtendedFormlyFieldConfig = {
  type: 'phone-number',
  templateOptions: {
    type: 'tel',
    label: 'Phone number',
    placeholder: 'Phone number',
    contextTypeTip: ''
  },
  conditionalFields: {
    readonly: {
      value: 'false',
      conditions: {}
    },
    required: {
      value: 'false',
      conditions: {}
    },
    visibility: {
      value: 'true',
      conditions: {}
    }
  }
};

export const EMAIL_ADDRESS_FORM_CONFIG: IExtendedFormlyFieldConfig = {
  type: 'email-address',
  templateOptions: {
    type: 'email',
    label: 'Email address',
    placeholder: 'Email address',
    contextTypeTip: ''
  },
  conditionalFields: {
    readonly: {
      value: 'false',
      conditions: {}
    },
    required: {
      value: 'false',
      conditions: {}
    },
    visibility: {
      value: 'true',
      conditions: {}
    }
  }
};

export const WEB_ADDRESS_FORM_CONFIG: IExtendedFormlyFieldConfig = {
  type: 'web-address',
  templateOptions: {
    type: 'url',
    label: 'Web address',
    placeholder: 'Web address',
    contextTypeTip: ''
  },
  conditionalFields: {
    readonly: {
      value: 'false',
      conditions: {}
    },
    required: {
      value: 'false',
      conditions: {}
    },
    visibility: {
      value: 'true',
      conditions: {}
    }
  }
};

export const MULTI_LINE_TEXT_CONFIG: IExtendedFormlyFieldConfig = {
  type: 'multi-line-text',
  templateOptions: {
    label: 'Multi-line text',
    placeholder: 'Multi-line text',
    contextTypeTip: ''
  },
  conditionalFields: {
    readonly: {
      value: 'false',
      conditions: {}
    },
    required: {
      value: 'false',
      conditions: {}
    },
    visibility: {
      value: 'true',
      conditions: {}
    }
  }
};

export const DATE_FORM_CONFIG: IExtendedFormlyFieldConfig = {
  type: 'date-time',
  templateOptions: {
    type: 'date',
    label: 'Date',
    placeholder: 'Date',
    contextTypeTip: '',
    dateFormat: null,
    dateValidation: ''
  },
  conditionalFields: {
    readonly: {
      value: 'false',
      conditions: {}
    },
    required: {
      value: 'false',
      conditions: {}
    },
    visibility: {
      value: 'true',
      conditions: {}
    }
  }
};

export const DATE_TIME_FORM_CONFIG: IExtendedFormlyFieldConfig = {
  type: 'date-time',
  templateOptions: {
    // type !!!!! needs to match the key in the datetime field
    type: 'datetime',
    label: 'Date & time',
    placeholder: 'Date & time',
    contextTypeTip: '',
    dateFormat: null,
    timeFormat: null,
    timezone: 'User timezone',
    dateValidation: ''
  },
  conditionalFields: {
    readonly: {
      value: 'false',
      conditions: {}
    },
    required: {
      value: 'false',
      conditions: {}
    },
    visibility: {
      value: 'true',
      conditions: {}
    }
  }
};

export const NUMBER_FORM_CONFIG: IExtendedFormlyFieldConfig = {
  type: 'number',
  templateOptions: {
    label: 'Number',
    placeholder: 'Number',
    contextTypeTip: '',
    numberFormat: 'normal',
    numberPrefix: '',
    numberSuffix: '',
    numberThousandsSeparator: '',
    numberDecimalSeparator: '',
    numberDecimalPlaces: null
  },
  conditionalFields: {
    readonly: {
      value: 'false',
      conditions: {}
    },
    required: {
      value: 'false',
      conditions: {}
    },
    visibility: {
      value: 'true',
      conditions: {}
    }
  }
};

export const CHECKBOX_FORM_CONFIG: IExtendedFormlyFieldConfig = {
  type: 'checkbox',
  templateOptions: {
    label: 'Checkbox',
    contextTypeTip: '',
  },
  conditionalFields: {
    readonly: {
      value: 'false',
      conditions: {}
    },
    required: {
      value: 'false',
      conditions: {}
    },
    visibility: {
      value: 'true',
      conditions: {}
    }
  }
};

export const BOOLEAN_CHOOSER_FORM_CONFIG: IExtendedFormlyFieldConfig = {
  type: 'boolean-chooser',
  templateOptions: {
    label: 'Boolean Chooser',
    contextTypeTip: '',
  },
  conditionalFields: {
    readonly: {
      value: 'false',
      conditions: {}
    },
    required: {
      value: 'false',
      conditions: {}
    },
    visibility: {
      value: 'true',
      conditions: {}
    }
  }
};

export const FILE_UPLOAD_FORM_CONFIG: IExtendedFormlyFieldConfig = {
  type: 'file-upload',
  templateOptions: {
    label: 'File upload',
    contextTypeTip: '',
  },
  conditionalFields: {
    readonly: {
      value: 'false',
      conditions: {}
    },
    required: {
      value: 'false',
      conditions: {}
    },
    visibility: {
      value: 'true',
      conditions: {}
    }
  }
};

export const POINT_FORM_CONFIG: IExtendedFormlyFieldConfig = {
  type: 'geo-chooser',
  templateOptions: {
    type: SKETCH_TOOL.POINT,
    label: 'Point chooser',
    placeholder: 'Select point',
    contextTypeTip: '',
    mapTip: ''
  },
  conditionalFields: {
    readonly: {
      value: 'false',
      conditions: {}
    },
    required: {
      value: 'false',
      conditions: {}
    },
    visibility: {
      value: 'true',
      conditions: {}
    }
  }
};

export const LINE_FORM_CONFIG: IExtendedFormlyFieldConfig = {
  type: 'geo-chooser',
  templateOptions: {
    type: SKETCH_TOOL.POLYLINE,
    label: 'Line chooser',
    placeholder: 'Line not set',
    contextTypeTip: '',
    mapTip: ''
  },
  conditionalFields: {
    readonly: {
      value: 'false',
      conditions: {}
    },
    required: {
      value: 'false',
      conditions: {}
    },
    visibility: {
      value: 'true',
      conditions: {}
    }
  }
};

export const POLYGON_FORM_CONFIG: IExtendedFormlyFieldConfig = {
  type: 'geo-chooser',
  templateOptions: {
    type: SKETCH_TOOL.POLYGON,
    label: 'Polygon chooser',
    placeholder: 'Select polygon',
    contextTypeTip: '',
    mapTip: ''
  },
  conditionalFields: {
    readonly: {
      value: 'false',
      conditions: {}
    },
    required: {
      value: 'false',
      conditions: {}
    },
    visibility: {
      value: 'true',
      conditions: {}
    }
  }
};

export const ADDRESS_FORM_CONFIG: IExtendedFormlyFieldConfig = {
  type: 'address-chooser',
  templateOptions: {
    label: 'Address chooser',
    placeholder: 'Select address',
    contextTypeTip: '',
    mapTip: ''
  },
  conditionalFields: {
    readonly: {
      value: 'false',
      conditions: {}
    },
    required: {
      value: 'false',
      conditions: {}
    },
    visibility: {
      value: 'true',
      conditions: {}
    }
  }
};

export const OBJECT_CHOOSER_FORM_CONFIG: IExtendedFormlyFieldConfig = {
  type: 'object-chooser',
  templateOptions: {
    label: 'Object chooser',
    placeholder: 'Select object',
    contextTypeTip: '',
    filter: {},
    hierarchicalView: '',
    hidePlusButton: false
  },
  conditionalFields: {
    readonly: {
      value: 'false',
      conditions: {}
    },
    required: {
      value: 'false',
      conditions: {}
    },
    visibility: {
      value: 'true',
      conditions: {}
    }
  }
};

export const SUB_OBJECT_CHOOSER_FORM_CONFIG: IExtendedFormlyFieldConfig = {
  type: 'sub-object-chooser',
  templateOptions: {
    label: 'Sub-object chooser',
    placeholder: 'Select sub-object',
    contextTypeTip: ''
  },
  conditionalFields: {
    readonly: {
      value: 'false',
      conditions: {}
    },
    required: {
      value: 'false',
      conditions: {}
    },
    visibility: {
      value: 'true',
      conditions: {}
    }
  }
};

export const ASSIGNABLE_FORM_CONFIG: IExtendedFormlyFieldConfig = {
  type: 'assignable',
  templateOptions: {
    label: 'Assigned to',
    placeholder: 'Unassigned',
    contextTypeTip: ''
  },
  conditionalFields: {
    readonly: {
      value: 'false',
      conditions: {}
    },
    required: {
      value: 'false',
      conditions: {}
    },
    visibility: {
      value: 'true',
      conditions: {}
    }
  }
};

export const structureFormOptions: IExtendedFormlyFieldConfig[] = [
  SECTION_HEADER_FORM_CONFIG
];

const contentFormOptions: IExtendedFormlyFieldConfig[] = [
  TEXT_CONTENT_FORM_CONFIG,
  CHART_CONTENT_FORM_CONFIG,
  TABLE_CONTENT_FORM_CONFIG
];

const publicFormContentFormOptions: IExtendedFormlyFieldConfig[] = [
  TEXT_CONTENT_FORM_CONFIG
];

const fieldFormOptions = (subObjects: boolean, isFormPublic = false) => {
  const subObject = subObjects ? [SUB_OBJECT_CHOOSER_FORM_CONFIG] : [];

  const extendFieldTypes = !isFormPublic ? [
    ASSIGNABLE_FORM_CONFIG,
    ADDRESS_FORM_CONFIG,
    POINT_FORM_CONFIG,
    LINE_FORM_CONFIG,
    POLYGON_FORM_CONFIG
  ] : [];

  return [
    SINGLE_LINE_TEXT_FORM_CONFIG,
    MULTI_LINE_TEXT_CONFIG,
    RICH_TEXT_FORM_CONFIG,
    NUMBER_FORM_CONFIG,
    CHECKBOX_FORM_CONFIG,
    DATE_FORM_CONFIG,
    DATE_TIME_FORM_CONFIG,
    LIST_ITEMS_SELECT_FORM_CONFIG,
    OBJECT_CHOOSER_FORM_CONFIG,
    ...subObject,
    PHONE_NUMBER_FORM_CONFIG,
    EMAIL_ADDRESS_FORM_CONFIG,
    WEB_ADDRESS_FORM_CONFIG,
    FILE_UPLOAD_FORM_CONFIG,
    ...extendFieldTypes
  ] as IExtendedFormlyFieldConfig[];
};

export interface IFormOptionsGroups {
  title: string;
  options: IFormBuilderFieldConfig[];
}


export const formOptionsGroups = (subObjects, isPublicForm: boolean = false) => [
  {
    title: 'Structure',
    options: structureFormOptions.map(transformExtendFormlyFieldToFormBuilderField)
  },
  {
    title: 'Content',
    options: isPublicForm 
    ? publicFormContentFormOptions.map(transformExtendFormlyFieldToFormBuilderField) 
    : contentFormOptions.map(transformExtendFormlyFieldToFormBuilderField)
  },
  {
    title: 'Fields',
    options: fieldFormOptions(subObjects, isPublicForm).map(transformExtendFormlyFieldToFormBuilderField)
  }
] as IFormOptionsGroups[];