import { escapeRegExp, isEmpty } from 'lodash';
import {memoize} from 'lodash';
import { INumberFormat } from './number-format-types';

export function addCursorTrackerIfRequired(numberString: string, cursorPosition: number, cursorTracker): string {
  // if we don't have a cursor position or if its 1000| at the end of our string we dont need to bother
  if (!cursorPosition || cursorPosition > numberString.length) {
    return numberString;
  }

  return insertNearestNumber(numberString, cursorPosition, cursorTracker);
}

function insertNearestNumber(str, index, value) {
  const numberAfterIndex = isNumber(str.charAt(index));
  const numberBeforeIndex = isNumber(str.charAt(index - 1));
  if (numberAfterIndex || numberBeforeIndex) {
    return str.substring(0, index) + value + str.substring(index);
  }

  // otherwise find the closest number
  const firstHalfString = reverseString(str.substring(0, index));
  const closestNumberFirstHalf = firstHalfString.search(NUMBER_REGEXP);
  const secondHalfString = str.substring(index);
  const closestNumberSecondHalf = secondHalfString.search(NUMBER_REGEXP);

  if (closestNumberSecondHalf === -1 &&  closestNumberFirstHalf === -1) {
    return str;
  }

  if (closestNumberFirstHalf > closestNumberSecondHalf) {
    // if the closest first half is larger than the second the second is closer
    // 1, -000 index 2 move the index back 1 so we will insert at the 1
    return insertNearestNumber(str, index + closestNumberSecondHalf, value);
  }

  if (closestNumberSecondHalf >= closestNumberFirstHalf) {
    // if the closest second half is larger than the first the first is closer
    // 1, -000 index 3 move the index forward 1 so we will insert at the 1 after the -
    return insertNearestNumber(str, index - closestNumberFirstHalf, value);
  }
}

const NUMBER_REGEXP = new RegExp('([0-9])');
function isNumber(value: string): boolean {
  return NUMBER_REGEXP.test(value);
}

function reverseString(str) {
  let newString = '';
  for (let i = str.length - 1; i >= 0; i--) {
    newString += str[i];
  }
  return newString;
}

// memoize
memoize.Cache = WeakMap;
export const getCursorTrackerCharacter = memoize(_getCursorTrackerCharacter);
function _getCursorTrackerCharacter(format: INumberFormat): string {
  const options = ['¶', '»', 'À', 'Â', 'Ä', 'Å', 'Æ', 'ß', 'ý', 'þ', '^'];
  let option: null | string = null;
  for (let i = 0; i < options.length; i++) {
    const currentOption = options[i];
    let notInDecimalSeparator = true;
    if (format.decimalSeparator) {
      notInDecimalSeparator = !format.decimalSeparator.includes(currentOption);
    }
    let notInThousandsSeparator = true;
    if (format.thousandsSeparator) {
      notInThousandsSeparator = !format.thousandsSeparator.includes(currentOption);
    }
    if (notInDecimalSeparator && notInThousandsSeparator) {
      option = currentOption;
      break;
    }
  }

  return option;
}

export function calculateCursorPositionRemoveCursorTracker(
  initialNumberString: string, updatedNumberString: string, cursorPosition: number, cursorTracker):
  {_cursorPosition: number, _numberString: string} {

  const CURSOR_REGEX = new RegExp(escapeRegExp(cursorTracker));
  if (!cursorPosition) {
    return {_cursorPosition: cursorPosition, _numberString: updatedNumberString};
  }
  const initialIntegerLength = initialNumberString.length;
  if (cursorPosition > initialIntegerLength) {
    const addedLength = updatedNumberString.length - initialIntegerLength;
    return {_cursorPosition: cursorPosition + addedLength, _numberString: updatedNumberString};
  }

  return {
    _cursorPosition: updatedNumberString.indexOf(cursorTracker),
    _numberString: updatedNumberString.replace(CURSOR_REGEX, '')
  };
}

// These are JS methods, just not supported by IE11
// If you are coming from the future and we no longer support IE11
// Please remove and use the built in string methods :)
export function startsWith(stringVal: string, searchFor: string): boolean {
  return stringVal.substr(0, searchFor.length) === searchFor;
}

export function endsWith(stringVal: string, expectedEnding: string) {
  return stringVal.substr(stringVal.length - expectedEnding.length) === expectedEnding;
}

export function isEmptyString(value) {
  return typeof value === 'string' && isEmpty(value);
}

export function coerceToNumberIfPossible(maybeNumber: any): number {
  if (typeof maybeNumber === 'number') {
    return maybeNumber;
  }
  return parseFloat(maybeNumber);
}
