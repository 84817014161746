import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableListComponent } from './table-list.component';
import { TableHeadDirective } from './table-head.directive';
import { TableRowDirective } from './table-row.directive';
import { TableActionsDirective } from './table-actions.directive';
import { AlertModule } from '../alert/alert.module';
import { PaginationModule } from '../pagination/pagination.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { FormsModule } from '@angular/forms';
import { EmptyStateModule } from '../empty-state/empty-state.module';

@NgModule({
  declarations: [
    TableListComponent,
    TableHeadDirective,
    TableRowDirective,
    TableActionsDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    AlertModule,
    PaginationModule,
    SpinnerModule,
    EmptyStateModule
  ],
  exports: [
    TableListComponent,
    TableHeadDirective,
    TableRowDirective,
    TableActionsDirective,
  ]
})
export class TableListModule { }
