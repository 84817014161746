import { NgModule } from '@angular/core';
import { SideSheetSectionComponent } from './side-sheet-section.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [ CommonModule ],
  declarations: [ SideSheetSectionComponent ],
  exports: [ SideSheetSectionComponent ]
})
export class SideSheetSectionModule {}

