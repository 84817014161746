import * as tslib_1 from "tslib";
import { CompositeNodeConverter } from '../CompositeNodeConverter';
import { InvalidWorkflowNodeDataError } from '../InvalidWorkflowNodeDataError';
import { Stringify } from '../../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { createConnection, createNoOpNode } from '../utils/create-node-utils';
import * as i0 from "@angular/core";
import * as i1 from "../../../../data/formula.service";
var PauseUntilNodeConverterService = /** @class */ (function (_super) {
    tslib_1.__extends(PauseUntilNodeConverterService, _super);
    function PauseUntilNodeConverterService(formulaService) {
        var _this = _super.call(this) || this;
        _this.formulaService = formulaService;
        _this.type = 'processnode/no-op';
        _this.processNodeType = 'processnodetype/no-op';
        return _this;
    }
    PauseUntilNodeConverterService.prototype.convert = function (nodeData) {
        if (!this.isValid(nodeData)) {
            throw new InvalidWorkflowNodeDataError("Please correct invalid data on pause node [" + nodeData.fields.title + "]", nodeData);
        }
        var startNoopNode = createNoOpNode(nodeData.tip);
        var endNoopNode = createNoOpNode();
        var doneConnectionForStartNoop = createConnection('done', endNoopNode);
        doneConnectionForStartNoop.delaySec = this.createDelayFormula(nodeData);
        startNoopNode.connections.push(doneConnectionForStartNoop);
        // make the final collection of all nodes and links
        var nodesAndLink = {
            nodes: [
                startNoopNode,
                endNoopNode
            ],
            links: [
                doneConnectionForStartNoop
            ]
        };
        this.populateOutgoingLinks(nodeData).forEach(function (link) {
            endNoopNode.connections.push(link);
            nodesAndLink.links.push(link);
        });
        return nodesAndLink;
    };
    PauseUntilNodeConverterService.prototype.createDelayFormula = function (nodeData) {
        if (nodeData.fields.value.manualInput) {
            return this.createDelayFormulaForManualInput(nodeData.fields.value.manualInput);
        }
        else if (nodeData.fields.value.relative && nodeData.fields.value.relative !== '') {
            return this.createDelayFormulaForRelativeField(nodeData.fields.value.relative);
        }
        else if (nodeData.fields.value.fromObject && nodeData.fields.value.fromObject.formula) {
            return this.createDelayFormulaForObjectField(nodeData.fields.value.fromObject);
        }
        return 0;
    };
    PauseUntilNodeConverterService.prototype.createDelayFormulaForManualInput = function (input) {
        return Stringify({
            name: 'IF',
            args: [
                {
                    name: 'OP',
                    args: [
                        {
                            name: 'DATE_DIFF',
                            args: [
                                {
                                    name: 'SET_SECOND',
                                    args: [
                                        {
                                            name: 'SET_MINUTE',
                                            args: [
                                                {
                                                    name: 'SET_HOUR',
                                                    args: [
                                                        {
                                                            name: 'FORMAT_DATETIME',
                                                            args: [
                                                                {
                                                                    name: 'NOW',
                                                                    args: []
                                                                },
                                                                'Y-m-d\\TH:i:sO',
                                                                input.timezone
                                                            ]
                                                        },
                                                        input.hours
                                                    ]
                                                },
                                                input.minutes
                                            ]
                                        },
                                        '00'
                                    ]
                                },
                                {
                                    name: 'NOW',
                                    args: []
                                },
                                'seconds'
                            ]
                        },
                        '>=',
                        0
                    ]
                },
                {
                    name: 'DATE_DIFF',
                    args: [
                        {
                            name: 'SET_SECOND',
                            args: [
                                {
                                    name: 'SET_MINUTE',
                                    args: [
                                        {
                                            name: 'SET_HOUR',
                                            args: [
                                                {
                                                    name: 'FORMAT_DATETIME',
                                                    args: [
                                                        {
                                                            name: 'NOW',
                                                            args: []
                                                        },
                                                        'Y-m-d\\TH:i:sO',
                                                        input.timezone
                                                    ]
                                                },
                                                input.hours
                                            ]
                                        },
                                        input.minutes
                                    ]
                                },
                                '0'
                            ]
                        },
                        {
                            name: 'NOW',
                            args: []
                        },
                        'seconds'
                    ]
                },
                {
                    name: 'DATE_DIFF',
                    args: [
                        {
                            name: 'SET_SECOND',
                            args: [
                                {
                                    name: 'SET_MINUTE',
                                    args: [
                                        {
                                            name: 'SET_HOUR',
                                            args: [
                                                {
                                                    name: 'FORMAT_DATETIME',
                                                    args: [
                                                        {
                                                            name: 'DATE_ADD',
                                                            args: [
                                                                {
                                                                    name: 'NOW',
                                                                    args: []
                                                                },
                                                                1,
                                                                'day'
                                                            ]
                                                        },
                                                        'Y-m-d\\TH:i:sO',
                                                        input.timezone
                                                    ]
                                                },
                                                input.hours
                                            ]
                                        },
                                        input.minutes
                                    ]
                                },
                                '00'
                            ]
                        },
                        {
                            name: 'NOW',
                            args: []
                        },
                        'seconds'
                    ]
                },
            ]
        });
    };
    PauseUntilNodeConverterService.prototype.createDelayFormulaForRelativeField = function (input) {
        return Stringify({
            name: 'DATE_DIFF',
            args: [
                {
                    name: 'DATE_ADD',
                    args: [
                        {
                            name: 'NOW',
                            args: []
                        },
                        input,
                        'days'
                    ]
                },
                {
                    name: 'NOW',
                    args: []
                },
                'seconds'
            ]
        });
    };
    PauseUntilNodeConverterService.prototype.createDelayFormulaForObjectField = function (input) {
        return Stringify({
            name: 'MAX',
            args: [
                {
                    name: 'DATE_DIFF',
                    args: [
                        {
                            name: 'FORMAT_DATETIME',
                            args: [
                                this.formulaService.parse(input.formula),
                                'Y-m-d\\TH:i:sO'
                            ]
                        },
                        {
                            name: 'NOW',
                            args: []
                        },
                        'seconds'
                    ]
                },
                0
            ]
        });
    };
    PauseUntilNodeConverterService.prototype.isValid = function (nodeData) {
        var manualInputField = nodeData.fields.value.manualInput;
        var manualInputFieldsValidity = manualInputField && (manualInputField.hours != null)
            && manualInputField.minutes != null && manualInputField.timezone != null;
        var relativeFieldsValidity = nodeData.fields.value.relative && nodeData.fields.value.relative !== '';
        var fromObjectField = nodeData.fields.value.fromObject;
        var fromObjectFieldValidity = fromObjectField && (fromObjectField.formula != null) && (fromObjectField.selectedPropertyKey != null);
        return manualInputFieldsValidity || relativeFieldsValidity || fromObjectFieldValidity;
    };
    PauseUntilNodeConverterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PauseUntilNodeConverterService_Factory() { return new PauseUntilNodeConverterService(i0.ɵɵinject(i1.FormulaService)); }, token: PauseUntilNodeConverterService, providedIn: "root" });
    return PauseUntilNodeConverterService;
}(CompositeNodeConverter));
export { PauseUntilNodeConverterService };
