import { IInnerOperator, IOperators, reverseOperator } from './operators';
import { FormulaLike, FormulaSpec, Parser, Stringify } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { OPERATOR_TYPE } from '../../../models/query';
import { isFormulaSpec } from '../../../util/is-formula-spec';
import { Formula } from '../../../data/models/types';
import { getFieldTipsFromFormulaSpec, getFieldTipsFromFormulaString } from '../../../util/get-field-tips-from-formula-string';
import { last } from 'lodash';
import { addContextFormulaWrapperSpec, getContextFromContextFormula } from '../../../util/context-formula-wrapper';

const geographyIntersectsWith: IInnerOperator = {
  opNo: 2,
  toFormula: (leftArg, rightArg) => {
    const leftFieldTip: Formula = last(
      isFormulaSpec(leftArg) ? getFieldTipsFromFormulaSpec(leftArg) : getFieldTipsFromFormulaString(leftArg as string)
    );

    if (isFormulaSpec(leftArg) && leftFieldTip) {
      const leftObjectFormula: FormulaLike =
        leftArg.name === 'CONTEXT' ?
          Parser(getContextFromContextFormula(Stringify(leftArg)) || 'TIP()')
          : (leftArg.args[1] || { name: 'TIP', args: [] });
      return {
        name: 'INARRAY',
        args: [
          {
            name: 'GEO_FIELD_INTERSECTS',
            args: [
              rightArg,
              { name: 'ARRAY', args: [leftFieldTip] }
            ]
          },
          leftObjectFormula
        ]
      };
    } else {
      throw new Error('leftArg must be FIELD formula.');
    }
  }
};
const geographyNotIntersectsWith = reverseOperator(geographyIntersectsWith);

export const geographyOperators: IOperators = {
  operators: {
    [OPERATOR_TYPE.INTERSECTS_WITH]: geographyIntersectsWith,
    [OPERATOR_TYPE.NOT_INTERSECTS_WITH]: geographyNotIntersectsWith
  },
  getParts: formula => {
    let currentPart = formula;
    let negative = false;
    if (currentPart.name === 'NOT') {
      currentPart = currentPart.args[0];
      negative = true;
    }

    if (currentPart.name === 'INARRAY') {
      const operator = negative ? OPERATOR_TYPE.NOT_INTERSECTS_WITH : OPERATOR_TYPE.INTERSECTS_WITH;
      const rightArg = currentPart.args[0].args[0];
      const leftFieldFormula = { name: 'FIELD', args: [currentPart.args[0].args[1].args[0]] };
      let leftArg: FormulaLike;

      if ((currentPart.args[1] as FormulaSpec).name === 'TIP') {
        leftArg = leftFieldFormula;
      } else {
        leftArg = addContextFormulaWrapperSpec(leftFieldFormula, currentPart.args[1]);
      }

      return { operator, leftArg, rightArg };
    }

    throw new Error('Unknown operators: ' + currentPart.name);
  }
};
