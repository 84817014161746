import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { MeService } from '../shell/services/me.service';
import { get } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class GtmService {
  public dataLayerWindow: any;

  constructor(private meService: MeService) { }

  init(dataLayerWindow?: any) {
    this.dataLayerWindow = dataLayerWindow || this.dataLayerWindow || <any>window;
    this.push({
      release: environment.release,
      environment: environment.enCloudEnv,
      namespace: environment.ns,
      serviceUri: environment.host,
      customerUri: window.location.origin
    });
    this.meService.getMe$().subscribe(me => {
      if (me) {
        const fullName = get(me, 'fullName');
        this.pushEvent(
          'userSet',
          {
            userName: (`${fullName}`).replace(/(^\s+)|(\s+$)/g, ''),
            userEmail: me.email,
            userTip: me.$tip,
            isAdministrator: (me.profiles.indexOf('app/profile/administrator') > -1 ? 'Yes' : 'No')
          }
        );
      }
    });
  }

  pushEvent(eventName: string, props?: { [key: string]: string }) {
    this.dataLayer().push({ ...props, event: eventName });
  }

  push(props: { [key: string]: string }) {
    this.dataLayer().push(props);
  }

  dataLayer() {
    this.dataLayerWindow = this.dataLayerWindow || <any>window;
    if (!this.dataLayerWindow.dataLayer) {
      this.dataLayerWindow.dataLayer = [];
    }
    return this.dataLayerWindow.dataLayer;
  }
}
