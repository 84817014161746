import { Component, ChangeDetectionStrategy, ViewEncapsulation, ElementRef, AfterViewInit, OnDestroy, Inject } from '@angular/core';
import { APIService, IRedoc } from './api.service';
import { first } from 'rxjs/operators';
import { initRedoc, getRedocEl } from './api';

@Component({
  selector: 'app-api',
  template: '<div id="redoc"></div>',
  styleUrls: ['./api.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class APIComponent implements AfterViewInit, OnDestroy {

  constructor(
    private elementRef: ElementRef,
    private apiService: APIService,
  ) { }

  ngAfterViewInit() {
    this.getOpenAPISpecAndRender();
  }

  ngOnDestroy() {
    this
      .elementRef
      .nativeElement
      .querySelector('#redoc')
      .remove();
  }

  private getOpenAPISpecAndRender() {
    this
      .apiService
      .getOpenAPISpec()
      .pipe(first())
      .subscribe(({openAPISpec, redoc}: {openAPISpec: ArrayBuffer, redoc: IRedoc}) =>  {
          initRedoc(redoc, openAPISpec, getRedocEl(this.elementRef.nativeElement));
        }
      );
  }
}
