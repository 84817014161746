import { IInnerOperator, IOperators, reverseOperator } from './operators';
import { Parser } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { OPERATOR_TYPE } from '../../../models/query';
import { isCurrentUserFormula } from '../../../util/current-user-formula';
import { isFormulaSpec } from '../../../util/is-formula-spec';

const objectEqual: IInnerOperator = {
  opNo: 2,
  toFormula: (leftArg, rightArg) => {

    let rightArgFormula = rightArg;
    if (!isFormulaSpec(rightArg) && isCurrentUserFormula(rightArg as string)) {
      rightArgFormula = Parser(rightArg as string);
    }

    return {
      name: 'EQUALS',
      args: [leftArg, rightArgFormula]
    };
  }
};
const objectNotEqual = reverseOperator(objectEqual);

const objectIsOfType: IInnerOperator = {
  opNo: 2,
  toFormula: (leftArg, rightArg) => ({
    name: 'OP',
    args: [
      {
        name: 'TYPE',
        args: [leftArg]
      },
      '==',
      rightArg
    ]
  })
};
const objectNotIsOfType = reverseOperator(objectIsOfType);

const objectIsEmpty: IInnerOperator = {
  opNo: 1,
  toFormula: (leftArg) => ({
    name: 'EQUALS',
    args: [
      {
        name: 'ARRAY',
        args: []
      },
      leftArg
    ]
  })
};
const objectIsNotEmpty = reverseOperator(objectIsEmpty);

const objectContainsAny: IInnerOperator = {
  opNo: 2,
  toFormula: (leftArg, rightArg) => {

    let rightArgFormula = rightArg;
    if (!isFormulaSpec(rightArg) && isCurrentUserFormula(rightArg as string)) {
      rightArgFormula = Parser(rightArg as string);
    }

    return {
      name: 'HAS_INTERSECT',
      args: [
        leftArg,
        rightArgFormula
      ]
    };
  }
};
const objectNotContainsAny = reverseOperator(objectContainsAny);

const objectIn: IInnerOperator = {
  opNo: 2,
  toFormula: (leftArg, rightArg) => {

    let rightArgFormula = rightArg;
    if (!isFormulaSpec(rightArg) && isCurrentUserFormula(rightArg as string)) {
      rightArgFormula = Parser(rightArg as string);
    }

    return {
      name: 'OP',
      args: [
        {
          name: 'EQUALS',
          args: [
            {
              name: 'COUNT',
              args: [
                leftArg
              ]
            },
            {
              name: 'COUNT',
              args: [
                {
                  name: 'INTERSECT',
                  args: [
                    leftArg,
                    rightArgFormula
                  ]
                }
              ]
            }
          ]
        },
        '&&',
        objectIsNotEmpty.toFormula(leftArg)
      ]
    };
  }
};
const objectNotIn = reverseOperator(objectIn);

const contactObjectInGroup: IInnerOperator = {
  opNo: 2,
  toFormula: (leftArg, rightArg) => {
    let rightArgFormula = rightArg;
    if (!isFormulaSpec(rightArg) && isCurrentUserFormula(rightArg as string)) {
      rightArgFormula = Parser(rightArg as string);
    }

    return {
      name: 'HAS_INTERSECT',
      args: [
        leftArg,
        {
          name: 'UNIQUE',
          args: [
            {
              name: 'FIELD_VALUES',
              args: [
                'app/group:contacts',
                {
                  name: 'ARRAY',
                  args: [
                    rightArgFormula,
                    {
                      name: 'ANCESTORS',
                      args: [
                        'app/group:parent',
                        rightArgFormula
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    };
  }
};
const contactObjectNotInGroup = reverseOperator(contactObjectInGroup);

const objectContainsAll: IInnerOperator = {
  opNo: 2,
  toFormula: (leftArg, rightArg) => {

    let rightArgFormula = rightArg;
    if (!isFormulaSpec(rightArg) && isCurrentUserFormula(rightArg as string)) {
      rightArgFormula = Parser(rightArg as string);
    }

    return {
      name: 'OP',
      args: [
        objectIsNotEmpty.toFormula(rightArgFormula),
        '&&',
        {
          name: 'EQUALS',
          args: [
            {
              name: 'COUNT',
              args: [
                rightArgFormula
              ]
            },
            {
              name: 'COUNT',
              args: [
                {
                  name: 'INTERSECT',
                  args: [
                    leftArg,
                    rightArgFormula
                  ]
                }
              ]
            }
          ]
        }
      ]
    };
  }
};
const objectNotContainsAll = reverseOperator(objectContainsAll);

export const objectOperators: IOperators = {
  operators: {
    [OPERATOR_TYPE.EQUALS]: objectEqual,
    [OPERATOR_TYPE.NOT_EQUALS]: objectNotEqual,
    [OPERATOR_TYPE.IS_OF_TYPE]: objectIsOfType,
    [OPERATOR_TYPE.NOT_IS_OF_TYPE]: objectNotIsOfType,
    [OPERATOR_TYPE.IS_EMPTY]: objectIsEmpty,
    [OPERATOR_TYPE.NOT_IS_EMPTY]: objectIsNotEmpty,
    [OPERATOR_TYPE.IN]: objectIn,
    [OPERATOR_TYPE.NOT_IN]: objectNotIn,
    [OPERATOR_TYPE.CONTACT_IN_GROUP]: contactObjectInGroup,
    [OPERATOR_TYPE.CONTACT_NOT_IN_GROUP]: contactObjectNotInGroup,
    [OPERATOR_TYPE.CONTAINS_ALL]: objectContainsAll,
    [OPERATOR_TYPE.NOT_CONTAINS_ALL]: objectNotContainsAll,
    [OPERATOR_TYPE.CONTAINS_ANY]: objectContainsAny,
    [OPERATOR_TYPE.NOT_CONTAINS_ANY]: objectNotContainsAny
  },
  getParts: formula => {
    let currentPart = formula;
    let negative = false;

    if (currentPart.name === 'NOT') {
      currentPart = currentPart.args[0];
      negative = true;
    }

    if (currentPart.name === 'EQUALS') {
      const leftArg = currentPart.args[0];
      if (leftArg.name === 'ARRAY' && !leftArg.args.length) {
        return {
          operator: negative ? OPERATOR_TYPE.NOT_IS_EMPTY : OPERATOR_TYPE.IS_EMPTY,
          leftArg: currentPart.args[1]
        };
      } else {
        return {
          operator: negative ? OPERATOR_TYPE.NOT_EQUALS : OPERATOR_TYPE.EQUALS,
          leftArg: currentPart.args[0],
          rightArg: currentPart.args[1]
        };
      }
    }

    if (currentPart.name === 'OP') {
      if (currentPart.args[0].name === 'EQUALS') {
        return {
          operator: negative ? OPERATOR_TYPE.NOT_IN : OPERATOR_TYPE.IN,
          leftArg: currentPart.args[0].args[1].args[0].args[0],
          rightArg: currentPart.args[0].args[1].args[0].args[1]
        };
      }

      if (currentPart.args[0].name === 'NOT') {
        return {
          operator: negative ? OPERATOR_TYPE.NOT_CONTAINS_ALL : OPERATOR_TYPE.CONTAINS_ALL,
          leftArg: currentPart.args[2].args[1].args[0].args[0],
          rightArg: currentPart.args[2].args[1].args[0].args[1]
        };
      }

      return {
        operator: negative ? OPERATOR_TYPE.NOT_IS_OF_TYPE : OPERATOR_TYPE.IS_OF_TYPE,
        leftArg: currentPart.args[0].args[0],
        rightArg: currentPart.args[2]
      };
    }

    if (currentPart.name === 'HAS_INTERSECT') {
      if (currentPart.args[1] && currentPart.args[1].name === 'UNIQUE') {
        return {
          operator: negative ? OPERATOR_TYPE.CONTACT_NOT_IN_GROUP : OPERATOR_TYPE.CONTACT_IN_GROUP,
          leftArg: currentPart.args[0],
          rightArg: currentPart.args[1].args[0].args[1].args[0]
        };
      }
      return {
        operator: negative ? OPERATOR_TYPE.NOT_CONTAINS_ANY : OPERATOR_TYPE.CONTAINS_ANY,
        leftArg: currentPart.args[0],
        rightArg: currentPart.args[1]
      };
    }

    throw new Error('Unknown operators: ' + currentPart.name);
  }
};
