import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationSideSheetComponent } from './navigation-side-sheet.component';
import { SideSheetHeaderModule } from '../../side-sheet-header/side-sheet-header.module';
import { SideSheetListModule } from '../../side-sheet-list/side-sheet-list.module';
import { SideSheetListItemModule } from '../../side-sheet-list-item/side-sheet-list-item.module';
import { SideSheetSectionModule } from '../../side-sheet-section/side-sheet-section.module';

@NgModule({
  declarations: [
    NavigationSideSheetComponent
  ],
  imports: [
    CommonModule,
    SideSheetHeaderModule,
    SideSheetListModule,
    SideSheetListItemModule,
    SideSheetSectionModule
  ],
  exports: [
    NavigationSideSheetComponent
  ],
  entryComponents: [
    NavigationSideSheetComponent
  ]
})
export class NavigationSideSheetModule { }
