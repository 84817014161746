import { Tip } from '../data/models/types';
import { DataTypes, IObjectScheme } from '../data/models/scheme';
import { IObject } from '../data/models/object';

export interface IPrintProps {
  templateTip: Tip;
  contextTip: Tip;
  trackingTip?: Tip;
  settingTip?: Tip;
  formTip?: Tip;
  localeId?: string;
}

export enum TRACKING_STATUS {
  PROGRESS = 'in-progress',
  COMPLETED = 'completed',
  FAILED = 'failed'
}


export interface IPrintResponse {
  trackingTip: Tip;
}

export type format = 'html' | 'pdf';

export const DEFAULT_PRINT_TEMPLATE = 'app/print-template/default-template' as Tip;

export const DEFAULT_SETTING_PRINT = 'app/default-print-template-settings' as Tip;

export const printTrackingScheme: IObjectScheme = {
  'app/print-tracking:generate-html-status': { type: DataTypes.string },
  'app/print-tracking:generate-pdf-status': { type: DataTypes.string }
};

export interface IPrintTracking extends IObject {
  generateHtmlStatus: TRACKING_STATUS;
  generatePdfStatus: TRACKING_STATUS;
}
