import { Formula } from '../data/models/types';
import { FormulaSpec, Parser, Stringify } from '../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { get } from 'lodash';
import { isFormulaSpec } from './is-formula-spec';

// formulas: [FORMULA_1(), FORMULA_2()],
// contextFormula: [CONTEXT_1(), CONTEXT_2()]
// => [CONTEXT(CONTEXT_1(),FORMULA_1()), CONTEXT(CONTEXT_2(),FORMULA_2())]
export function addContextFormulaWrappers(formulas: Formula[], contextFormula: Array<FormulaSpec | Formula>): Formula[] {
  return formulas.map(
    (formula, index) => addContextFormulaWrapper(formula, contextFormula[index])
  );
}

// formula: FORMULA_1()
// context: CONTEXT_1()
// => CONTEXT(CONTEXT_1(), FORMULA_1())
export function addContextFormulaWrapper(formula: Formula | FormulaSpec, contextFormula: FormulaSpec | Formula): Formula {
  return Stringify(addContextFormulaWrapperSpec(formula, contextFormula));
}

export function addContextFormulaWrapperSpec(formula: Formula | FormulaSpec, contextFormula: FormulaSpec | Formula): FormulaSpec {
  return {
    name: 'CONTEXT',
    args: [
      isFormulaSpec(contextFormula) ? contextFormula : Parser(contextFormula),
      isFormulaSpec(formula) ? formula : Parser(formula)
    ]
  };
}

// formulas: [CONTEXT(CONTEXT_1(),FORMULA_1()), CONTEXT(CONTEXT_2(),FORMULA_2())]
// => [FORMULA_1(), FORMULA_2()]
export function removeContextFormulaWrappers(formulas: Formula[]): Formula[] {
  return getPartsFromContextFormulas(formulas).formula;
}

// formulas: CONTEXT(CONTEXT_1(), FORMULA_1())
// => FORMULA_1()
export function removeContextFormulaWrapper(formula: Formula): Formula {
  return getPartsFromContextFormula(formula).formula;
}

// formulas: [CONTEXT(CONTEXT_1(),FORMULA_1()), CONTEXT(CONTEXT_2(),FORMULA_2()), FORMULA_3()]
// => [CONTEXT_1(), CONTEXT_2(), null]
export function getContextFromContextFormulas(formulas: Formula[]): Formula[] {
  return getPartsFromContextFormulas(formulas).context;
}

// formulas: CONTEXT(CONTEXT_1(),FORMULA_1())
// => CONTEXT_1()
// formulas: FORMULA_2()
// => null
export function getContextFromContextFormula(formula: Formula): Formula {
  return getPartsFromContextFormula(formula).context;
}

// formula: CONTEXT(CONTEXT(FORMULA_3(),FORMULA_2()),FORMULA_1())
// => FORMULA_3()
export function getMostInnerContextFromContextFormula(formula: Formula): Formula {
  return getMostInnerContextPartsFromContextFormula(formula).mostInnerContextFormula;
}

// formula: CONTEXT(CONTEXT(FORMULA_3(),FORMULA_2()),FORMULA_1())
// => CONTEXT(FORMULA_2(),FORMULA_1())
export function removeMostInnerContextFromContextFormula(formula: Formula): Formula {
  return buildRecursiveContextFormula(getMostInnerContextPartsFromContextFormula(formula).outerFormulas);
}
// formula: CONTEXT(FORMULA_2(),FORMULA_1())
// contextFormula: FORMULA_3()
// => CONTEXT(CONTEXT(FORMULA_3(),FORMULA_2()),FORMULA_1())
export function insertMostInnerContextToContextFormula(formula: Formula, contextFormula: Formula): Formula {
  const { outerFormulas, mostInnerContextFormula } = getMostInnerContextPartsFromContextFormula(formula);

  return buildRecursiveContextFormula([...outerFormulas, mostInnerContextFormula, contextFormula]);
}

// formula: CONTEXT(CONTEXT(FORMULA_3(),FORMULA_2()),FORMULA_1())
// => {
//   outerFormulas: [FORMULA_1(), FORMULA_2()],
//   mostInnerContextFormula: FORMULA_3()
// }
export function getMostInnerContextPartsFromContextFormula(input: Formula): {
  outerFormulas: Formula[];
  mostInnerContextFormula: Formula
} {
  const extractedFormulas = [];
  let lastContext = null;
  let { formula, context } = getPartsFromContextFormula(input);

  while (context) {
    extractedFormulas.push(formula);
    lastContext = context;
    const result = getPartsFromContextFormula(lastContext);
    formula = result.formula;
    context = result.context;
  }

  return { outerFormulas: extractedFormulas, mostInnerContextFormula: formula };
}

// inputs: [FORMULA_1(), FORMULA_2(), FORMULA_3()]
// => CONTEXT(CONTEXT(FORMULA_3(),FORMULA_2()),FORMULA_1())
export function buildRecursiveContextFormula(inputs: Formula[]): Formula {
  const clonedInputs = inputs.slice();
  let lastContext;
  let lastFormula;

  while (clonedInputs.length > 1) {
    lastContext = clonedInputs.pop();
    lastFormula = clonedInputs.pop();
    clonedInputs.push(addContextFormulaWrapper(lastFormula, lastContext));
  }

  return clonedInputs.pop();
}

// formulas: [CONTEXT(CONTEXT_1(),FORMULA_1()), CONTEXT(CONTEXT_2(),FORMULA_2()), FORMULA_3()]
// => {
//   formula: [FORMULA_1(), FORMULA_2(), FORMULA_3()],
//   context: [CONTEXT_1(), CONTEXT_2(), null]
// }
export function getPartsFromContextFormulas(formulas: Formula[]): { formula: Formula[], context: Formula[] } {
  const formula: Formula[] = [];
  const context: Formula[] = [];

  formulas.forEach(f => {
    const result = getPartsFromContextFormula(f);
    formula.push(result.formula);
    context.push(result.context);
  });

  return { formula, context };
}

// input: CONTEXT(CONTEXT_1(),FORMULA_1())
// => {
//   formula: FORMULA_1(),
//   context: CONTEXT_1()
// }
//
// input: FORMULA_1()
// => {
//   formula: FORMULA_1(),
//   context: null
// }
export function getPartsFromContextFormula(input: Formula): { formula: Formula, context: Formula } {
  const parsedFormula = Parser(input);

  if (parsedFormula.name === 'CONTEXT') {
    return {
      formula: Stringify(get(parsedFormula, 'args[1]')),
      context: Stringify(get(parsedFormula, 'args[0]'))
    };
  }

  return {
    formula: input,
    context: null
  };
}
