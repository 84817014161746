import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { get, head} from 'lodash';
import { ContextTypeFieldOptions } from '../../context-type-field-option-utils/context-type-field-option-utils';
import { Tip } from '../../../data/models/types';
import { LoggerService } from '../../../util/logger.service';

@Component({
  selector: 'app-sub-object-chooser-formly',
  template: `
      <app-sub-object-chooser-field
              [label]="to.label"
              [required]="to.required"
              [readonly]="to.readonly"
              [formControl]="formControl"
              [typeTip]="typeTip"
              [placeholder]="to.placeholder"
              [single]="single"
      ></app-sub-object-chooser-field>
  `
})
export class FormlyFieldSubObjectChooserComponent extends FieldType implements OnInit {
  single = true;
  contextTypeOptions: ContextTypeFieldOptions;
  typeTip: null | Tip = null;

  constructor(private loggerService: LoggerService) {
    super();
  }

  ngOnInit(): void {
    this.contextTypeOptions = get(this, 'formState.contextTypeOptions', null);
    const contextTypeTip = get(this, 'field.templateOptions.contextTypeTip', null);

    const contextTypeOption = this.contextTypeOptions
      ? this.contextTypeOptions.values.get(contextTypeTip)
      : null;

    const isMulti = get(this, 'field.templateOptions.multi', false);

    if (isMulti || (contextTypeOption && contextTypeOption.maxcount !== 1)) {
      this.single = false;
    }

    const typeTips = get(contextTypeOption, 'typerestrict', []);

    if (typeTips.length > 1) {
      this.loggerService.error(`
        The sub object chooser should only instantiated with one type.
        Multiple types were provided. We will load the first one and hope that's right`, typeTips);
    }

    if (typeTips) {
      this.typeTip = head(typeTips);
    }
  }
}
