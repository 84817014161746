var _a;
import { reverseOperator } from './operators';
import { OPERATOR_TYPE } from '../../../models/query';
import { stringEqual, stringNotEqual } from './string-operators';
var ɵ0 = function (leftArg, rightArg) { return ({
    name: 'OP',
    args: [leftArg, '<', rightArg]
}); };
var numberLessThan = {
    opNo: 2,
    toFormula: ɵ0
};
var numberGreaterThanOrEqualTo = reverseOperator(numberLessThan);
var ɵ1 = function (leftArg, rightArg) { return ({
    name: 'OP',
    args: [leftArg, '>', rightArg]
}); };
var numberGreaterThan = {
    opNo: 2,
    toFormula: ɵ1
};
var numberLessThanOrEqualTo = reverseOperator(numberGreaterThan);
export var numberOperators = {
    operators: (_a = {},
        _a[OPERATOR_TYPE.EQUALS] = stringEqual,
        _a[OPERATOR_TYPE.NOT_EQUALS] = stringNotEqual,
        _a[OPERATOR_TYPE.LESS_THAN] = numberLessThan,
        _a[OPERATOR_TYPE.GREATER_THAN] = numberGreaterThan,
        _a[OPERATOR_TYPE.LESS_THAN_EQUAL_TO] = numberLessThanOrEqualTo,
        _a[OPERATOR_TYPE.GREATER_THAN_EQUAL_TO] = numberGreaterThanOrEqualTo,
        _a),
    getParts: function (formula) {
        var currentPart = formula;
        var negative = false;
        if (currentPart.name === 'NOT') {
            currentPart = currentPart.args[0];
            negative = true;
        }
        if (currentPart.name === 'OP') {
            if (currentPart.args[1] === '<') {
                return {
                    operator: negative ? OPERATOR_TYPE.GREATER_THAN_EQUAL_TO : OPERATOR_TYPE.LESS_THAN,
                    leftArg: currentPart.args[0],
                    rightArg: currentPart.args[2]
                };
            }
            if (currentPart.args[1] === '>') {
                return {
                    operator: negative ? OPERATOR_TYPE.LESS_THAN_EQUAL_TO : OPERATOR_TYPE.GREATER_THAN,
                    leftArg: currentPart.args[0],
                    rightArg: currentPart.args[2]
                };
            }
            throw new Error('Unknown operators:' + currentPart.name);
        }
        if (currentPart.name === 'EQUALS') {
            return {
                operator: negative ? OPERATOR_TYPE.NOT_EQUALS : OPERATOR_TYPE.EQUALS,
                leftArg: currentPart.args[0],
                rightArg: currentPart.args[1]
            };
        }
        throw new Error('Unknown operators: ' + currentPart.name);
    }
};
export { ɵ0, ɵ1 };
