import { Injectable, OnDestroy } from '@angular/core';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { chain, get, isEmpty } from 'lodash';

import { removeEscapesFromJSON } from '../../util/remove-escapes';
import { MyProfileService } from '../../shell/services/my-profile.service';
import { IObjectTypeSetting, IProfile } from '../../models/profile';
import { getSystemTypesMap, SYSTEM_TYPES } from '../../object/type-chooser-side-sheet/system-types';
import { SUPER_TYPES } from '../../object/type-chooser-side-sheet/super-types';
import { IObjectTypeSymbology } from '../../models/object-type';
import { IQueryResponse, Tip } from '../models/types';
import { QueryService } from '../query.service';

interface IAllObjectTypeBase {
  appObjectType?: Tip;
  defaultDashboard?: Tip;
  defaultForm?: Tip;
  dashboard?: Tip;
  form?: Tip;
  lifecycleWorkflow?: Tip;
  innerType?: Tip;
  superTypeTip?: Tip;
  label: string;
  superType: boolean;
  systemType: boolean;
}

interface IAllObjectTypeResults extends IAllObjectTypeBase {
  symbology: string;
  implementsSuperTypes: string;
  searchableMetafields: string;
}

export interface IAllObjectType extends IAllObjectTypeBase {
  implementsSuperTypes: Tip[];
  searchableMetafields: Tip[];
  isImplementedBy: Set<string>;
  symbology: IObjectTypeSymbology;
}

export type AllTypeMetaMap = Map<Tip, IAllObjectType>;

export interface ISymbologyResult {
  typeTip: Tip;
  symbology: IObjectTypeSymbology;
}

export let ALL_TYPE_META_MAP_VALUE: AllTypeMetaMap;  // added a global property to use in places where we cant inject the service.
                                                     // we need a better way to use the AllTypeMetaMap in non-services
                                                     // do not use this unless there is a compelling reason to.
                                                     // Using the allTypes$ observable is preferred
@Injectable({
  providedIn: 'root'
})
export class AllObjectTypesService implements OnDestroy {
  private allObjectTypes = new ReplaySubject<AllTypeMetaMap>(1);

  allTypes$: Observable<AllTypeMetaMap> = this.allObjectTypes.asObservable();

  subscription;

  constructor(
    private queryService: QueryService,
    private myProfileService: MyProfileService
  ) {
    // all object types service caches all the object types so we can quickly launch them.
    this.loadAllObjectTypes();
  }

  getLifecycleWorkflowFromTypeTip$(typeOrSuperTypeTip): Observable<Tip | null> {
    return this.getDefaultFromTypeTip(typeOrSuperTypeTip, 'lifecycleWorkflow');
  }

  // @deprecated.
  getDefaultDashboardTipFromTypeTip$(typeOrSuperTypeTip): Observable<Tip | null> {
    return this.getDefaultFromTypeTip(typeOrSuperTypeTip, 'defaultDashboard');
  }

  // @deprecated.
  getDefaultFormTipFromTypeTip$(typeOrSuperTypeTip): Observable<Tip | null> {
    return this.getDefaultFromTypeTip(typeOrSuperTypeTip, 'defaultForm');
  }

  getDashboardTipFromTypeTip$(typeOrSuperTypeTip): Observable<Tip | null> {
    return this.getDefaultFromTypeTip(typeOrSuperTypeTip, 'dashboard');
  }

  getFormTipFromTypeTip$(typeOrSuperTypeTip): Observable<Tip | null> {
    return this.getDefaultFromTypeTip(typeOrSuperTypeTip, 'form');
  }

  getSymbologyForType$(typeOrSuperTypeTip: Tip): Observable<ISymbologyResult[]> {
    return this.allTypes$
      .pipe(map((metaMap) => getSymbology(typeOrSuperTypeTip, metaMap)));
  }

  getSearchableMetafields$(typeTip: Tip): Observable<Tip[]> {
    return this.allTypes$.pipe(
      map((metaMap: Map<string, IAllObjectType>) => getSearchableMetafields(typeTip, metaMap))
    );
  }

  getObjectType$(typeOrSuperTypeTip: Tip): Observable<IAllObjectType | null> {
    return this.allTypes$
      .pipe(map((metaMap) => metaMap.get(typeOrSuperTypeTip) || null));
  }

  objectTypeInCache$(typeOrSuperTypeTip: Tip): Observable<Boolean> {
    return this.allTypes$
      .pipe(map((metaMap) => metaMap.has(typeOrSuperTypeTip)));
  }

  loadAllObjectTypes() { // this is slow call. Carefully consider options before piling on ...
    const getAllObjectTypes$ =
      this.getImplementsForTypes()  // TODO EIM-5392
        .pipe(
          switchMap(() => this.queryService.execute('eim/query/get-all-object-types-master-query')),
          map(createMapFromResults),
          map(addMissingKnownSystemTypes),
          map(addMissingKnownSuperTypes),
          map(collateIsImplementedBy)
        );

    this.subscription = combineLatest([this.myProfileService.getMyProfile$(), getAllObjectTypes$]).pipe(
      map(([myProfile, allTypeMetaMap]: [IProfile, AllTypeMetaMap]) => addObjectTypeSettings(myProfile, allTypeMetaMap)))
      .subscribe((allTypeMetaMap: AllTypeMetaMap) => {
        ALL_TYPE_META_MAP_VALUE = allTypeMetaMap;
        this.allObjectTypes.next(allTypeMetaMap);
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getImplementsForTypes(): Observable<any> {  // using this to set a watch of types of objects. We want to fetch all the objects if their 'type' changes.
    const query = 'eim/query/get-all-types-implements-for-object-type-master';
    return this.queryService.execute(query);
  }

  private getDefaultFromTypeTip(typeTipOrSuperTypeTip, valueToGet): Observable<Tip | null> {
    return this.allTypes$
      .pipe(
        map(getMetaMapResult(typeTipOrSuperTypeTip)),
        map((metaMapParams) => metaMapParams[valueToGet] || null)
      );
  }
}

function getSymbology(typeTip: string, metaMap: AllTypeMetaMap): ISymbologyResult[] {
  let symbologies = [];
  const type = metaMap.get(typeTip);
  if (!type) {
    return symbologies;
  }

  if (type.superType) {
    type.isImplementedBy.forEach((implementsType) => {
      const subSymbology = getSymbology(implementsType, metaMap);
      symbologies = symbologies.concat(subSymbology);
    });
  }

  if (!type.superType && !isEmpty(type.symbology)) {
    symbologies.push({ symbology: type.symbology, typeTip: type.innerType });
  }

  return symbologies;
}

function getSearchableMetafields(typeTip: Tip, metaMap: AllTypeMetaMap): Tip[] {
  const type: IAllObjectType = metaMap.get(typeTip);

  if (!type || type.superType) {
    return [];
  }

  return type.searchableMetafields;
}

function createMapFromResults(response: IQueryResponse): AllTypeMetaMap {
  const systemTypesMap = getSystemTypesMap();
  return response.results
    .reduce((acc, value) => {
      const result = extractResult(value);
      const symbology = extractSymbology(result);
      const implementsSuperTypes = extractTipsFromSeparatedString(result, 'implementsSuperTypes');
      const searchableMetafields = extractTipsFromSeparatedString(result, 'searchableMetafields');
      const innerTypeTip = result.innerType;

      const type = {
        ...result,
        symbology,
        implementsSuperTypes,
        searchableMetafields,
        systemType: systemTypesMap.has(innerTypeTip),
        isImplementedBy: new Set(),
        superType: false
      };
      acc.set(innerTypeTip, type);
      return acc;
    }, new Map());
}

function addObjectTypeSettings(profile: IProfile, metaMap: AllTypeMetaMap): AllTypeMetaMap {
  const profileObjectTypeSettingMap = chain(profile.objectTypeSettings).keyBy('$tip').value();

  metaMap.forEach(
    (allObjectType: IAllObjectType) => {
      const objectTypeTip: Tip = allObjectType.appObjectType;

      if (!objectTypeTip) {
        return;
      }

      const profileObjectTypeSetting: IObjectTypeSetting = profileObjectTypeSettingMap[objectTypeTip];

      if (!profileObjectTypeSetting) {
        allObjectType.dashboard = allObjectType.defaultDashboard || null;
        allObjectType.form = allObjectType.defaultForm || null;

        return;
      }

      allObjectType.dashboard = profileObjectTypeSetting.defaultDashboard || null;
      allObjectType.form = profileObjectTypeSetting.defaultForm || null;
    }
  );

  return metaMap;
}

function addMissingKnownSystemTypes(metaMap: AllTypeMetaMap): AllTypeMetaMap {
  return SYSTEM_TYPES.reduce((acc, { $tip, label }) => {
    const existingType = acc.get($tip);
    if (!existingType) {
      const type: IAllObjectType = {
        innerType: $tip,
        label,
        symbology: {},
        implementsSuperTypes: [],
        searchableMetafields: [],
        systemType: true,
        isImplementedBy: new Set(),
        superType: false
      };

      acc.set($tip, type);
    }

    return acc;
  }, metaMap);
}

function addMissingKnownSuperTypes(metaMap: AllTypeMetaMap): AllTypeMetaMap {
  return SUPER_TYPES.reduce((acc, { $tip, label }) => {
    const type: IAllObjectType = {
      superTypeTip: $tip,
      label,
      symbology: {},
      implementsSuperTypes: [],
      searchableMetafields: [],
      systemType: false,
      isImplementedBy: new Set(),
      superType: true
    };
    acc.set($tip, type);
    return acc;
  }, metaMap);
}

function collateIsImplementedBy(metaMap: AllTypeMetaMap): AllTypeMetaMap {
  metaMap.forEach((value, key) => {
    if (value.implementsSuperTypes) {
      value.implementsSuperTypes.forEach(setIsImplementedByType(key, metaMap));
    }
  });

  return metaMap;
}

function setIsImplementedByType(innerType: string, metaMap: AllTypeMetaMap) {
  return function addType(superType: string) {
    const _superType = metaMap.get(superType);
    if (!_superType) {
      return console.error(`Cannot get ${superType} from metaMap, ${innerType} implements this supertype`);
    }

    try {
      _superType.isImplementedBy.add(innerType);
    } catch (e) {
      return console.error(`Cannot add to supertype ${superType} from ${innerType}.`, metaMap);
    }
  };
}

function getMetaMapResult(tip: Tip) {
  return function getMapResult(metaMap: AllTypeMetaMap): IAllObjectType {
    const result = metaMap.get(tip);
    if (!result) {
      throw new Error(`Type tip "${tip}" is not present in the all types cache`);
    }
    return result;
  };
}

function extractTipsFromSeparatedString(obj: IAllObjectTypeResults, attributeLabel: string): string[] {
  const superTypesCommaSeparatedString = get(obj, attributeLabel, '');

  if (isEmpty(superTypesCommaSeparatedString)) {
    return [];
  }

  return superTypesCommaSeparatedString.split(',');
}

function extractSymbology(obj: IAllObjectTypeResults) {
  let symbology = {};
  if (!isEmpty(obj.symbology)) {
    try {
      const jsonString = removeEscapesFromJSON(obj.symbology);
      symbology = JSON.parse(jsonString);
    } catch (e) {
      console.error(`could not extract symbology for`, obj);
    }
  }
  return symbology;
}

export function extractResult(obj): IAllObjectTypeResults {
  // first property of the object is just the
  return obj[Object.keys(obj)[0]];
}
