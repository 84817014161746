import * as tslib_1 from "tslib";
import { chain, find, get, isString, values } from 'lodash';
import dataConstants from '../../data/constants';
export function getI18nValue(value) {
    var e_1, _a;
    if (!value) {
        return '';
    }
    if (isString(value)) {
        return value;
    }
    try {
        for (var _b = tslib_1.__values(dataConstants.ACCEPTABLE_LOCALE_IDS), _c = _b.next(); !_c.done; _c = _b.next()) {
            var localeId = _c.value;
            if (isString(value[localeId])) {
                return value[localeId];
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    var firstValue = values(value)[0];
    return isString(firstValue) ? firstValue : '';
}
export function constructI18nValues(value, originalValue) {
    var _a, _b, _c;
    if (originalValue === undefined) {
        return _a = {}, _a[dataConstants.LOCALE_ID] = value, _a;
    }
    if (isString(originalValue)) {
        if (dataConstants.LOCALE_ID === dataConstants.LANG_DEFAULT) {
            return _b = {}, _b[dataConstants.LOCALE_ID] = value, _b;
        }
        return _c = {}, _c[dataConstants.LANG_DEFAULT] = originalValue, _c[dataConstants.LOCALE_ID] = value, _c;
    }
    var i18nValues = tslib_1.__assign({}, originalValue);
    i18nValues[dataConstants.LOCALE_ID] = value;
    return i18nValues;
}
export function getFieldI18nValues(i18ns) {
    if (!i18ns) {
        return [];
    }
    var firstI18nValues = get(i18ns, [0, 'value'], []);
    var fieldI18nValueMap = chain(i18ns).keyBy('lang').mapValues('value').value();
    var valueLength = firstI18nValues.length;
    var i18nValues = [];
    var _loop_1 = function (i) {
        var e_2, _a;
        try {
            for (var _b = (e_2 = void 0, tslib_1.__values(dataConstants.ACCEPTABLE_LOCALE_IDS)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var localeId = _c.value;
                var candidateValues = fieldI18nValueMap[localeId];
                if (!candidateValues) {
                    continue;
                }
                if (candidateValues[i]) {
                    i18nValues.push(candidateValues[i]);
                    break;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        if (!i18nValues[i]) {
            var firstI18nWithValue = find(i18ns, function (i18n) { return Boolean(get(i18n, ['value', i])); });
            i18nValues.push(get(firstI18nWithValue, ['value', i]) || '');
        }
    };
    for (var i = 0; i < valueLength; i++) {
        _loop_1(i);
    }
    return i18nValues;
}
