import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CanPipe } from './can.pipe';

@NgModule({
  declarations: [CanPipe],
  imports: [CommonModule],
  exports: [CanPipe]
})
export class CanActionPipeModule { }
