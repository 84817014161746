import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var ShellRedirectGuard = /** @class */ (function () {
    function ShellRedirectGuard(router) {
        this.router = router;
    }
    ShellRedirectGuard.prototype.canLoad = function (route, segments) {
        if ((segments[0].path === 'app') && (segments[1].path !== 's')) {
            var newUrl = segments.reduce(function (acc, segment) {
                if (segment.path === 'app') {
                    return acc + "/" + segment.toString() + "/s";
                }
                return acc + "/" + segment.toString();
            }, '');
            this.router.navigateByUrl(newUrl);
            return false;
        }
        return true;
    };
    ShellRedirectGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ShellRedirectGuard_Factory() { return new ShellRedirectGuard(i0.ɵɵinject(i1.Router)); }, token: ShellRedirectGuard, providedIn: "root" });
    return ShellRedirectGuard;
}());
export { ShellRedirectGuard };
