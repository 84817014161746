import { FieldType } from '@ngx-formly/core';
import { Component, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-section-header',
  template: `
      <app-side-sheet-section
        [title]="to.title"
        [collapsible]="true"
        [collapsed]="collapsed"
        (click)="toggle()">
      </app-side-sheet-section>
  `,
})
export class FormlyFieldSectionHeaderComponent extends FieldType implements OnDestroy {

  collapsed = false;
  // we only have the wrapper component in the render view (not when designing)
  constructor() {
    super();
  }

  toggle() {
    if (this.to.toggle) {
      this.collapsed = !this.collapsed;
      this.to.toggle.next(this.collapsed);
    }
  }

  ngOnDestroy(): void {
    if (this.to.toggle) {
      this.to.toggle.complete();
    }
  }
}
