export enum CacheOpt {
  USE_CACHE = 'USE_CACHE',
  USE_CACHE_THEN_NETWORK = 'USE_CACHE_THEN_NETWORK',
  USE_NETWORK_NO_CACHE = 'USE_NETWORK_NO_CACHE',
}

export function getCache(value: boolean | null | CacheOpt): CacheOpt {
  if (value === true || value === CacheOpt.USE_CACHE) {
    return CacheOpt.USE_CACHE;
  }
  if (value === false || value === CacheOpt.USE_NETWORK_NO_CACHE) {
    return CacheOpt.USE_NETWORK_NO_CACHE;
  }

  return CacheOpt.USE_CACHE_THEN_NETWORK;
}
