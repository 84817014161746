import { IInnerOperator, IOperators, reverseOperator } from './operators';
import { OPERATOR_TYPE, IFilterMeta } from '../../../models/query';
import { objectOperators } from './object-operators';
import { SUPER_TYPES } from '../../../object/type-chooser-side-sheet/super-types';
import { Tip } from '../../../data/models/types';

const hierarchicalObjectContainsAny: IInnerOperator = {
  opNo: 2,
  toFormula: (leftArg, rightArg, meta: IFilterMeta) => {

    const parentFieldTip = getParentFieldTip(meta);
    return {
      name: 'HAS_INTERSECT',
      args: [
        {
          name: 'ARRAY',
          args: [
            {
              // this formula will actually find descendants
              name: 'ANCESTORS',
              args: [
                parentFieldTip,
                leftArg
              ]
            },
            leftArg
          ]
        },
        rightArg
      ]
    };
  }
};

const hierarchicalObjectIn: IInnerOperator = {
  opNo: 2,
  toFormula: (leftArg, rightArg, meta: IFilterMeta) => {

    const parentFieldTip = getParentFieldTip(meta);
    return {

      name: 'HAS_INTERSECT',
      args: [
        {
          name: 'ARRAY',
          args: [
            {
              // this formula will actually find ancestors
              name: 'DESCENDANTS',
              args: [
                parentFieldTip,
                leftArg
              ]
            },
            leftArg
          ]
        },
        rightArg
      ]
    };
  }
};

export function getParentFieldTip(meta: IFilterMeta) {
  let parentFieldTip = '', hierarchicalSuperTypeTip = '';
  if (!meta || !meta.typeRestrict) {
    return parentFieldTip;
  }
  const hierarchicalSuperTypes: Tip[] = SUPER_TYPES.filter(x => x.isHierarchical).map(x => x.$tip);

  // if object itself is a group or one of hierarchical super types - use it to get parent field
  if ([...hierarchicalSuperTypes, 'app/group'].includes(meta.typeRestrict)) {
    hierarchicalSuperTypeTip = meta.typeRestrict;
  // otherwise check if object implements any hierarchical type
  } else if (meta.implements && meta.implements.length) {
    const hierarchicalTypes = SUPER_TYPES.filter(x => x.isHierarchical).map(x => x.$tip);
    hierarchicalSuperTypeTip = meta.implements.find(x => hierarchicalTypes.includes(x));
  }
  if (hierarchicalSuperTypeTip) {
    parentFieldTip = hierarchicalSuperTypeTip + ':parent';
  }
  return parentFieldTip;
}

const hierarchicalObjectNotContainsAny = reverseOperator(hierarchicalObjectContainsAny);
const hierarchicalObjectNotIn = reverseOperator(hierarchicalObjectIn);

export const hierarchicalOperators: IOperators = {
  operators: {
    [OPERATOR_TYPE.CONTAINS_ANY]: hierarchicalObjectContainsAny,
    [OPERATOR_TYPE.NOT_CONTAINS_ANY]: hierarchicalObjectNotContainsAny,
    [OPERATOR_TYPE.IN]: hierarchicalObjectIn,
    [OPERATOR_TYPE.NOT_IN]: hierarchicalObjectNotIn,
    [OPERATOR_TYPE.EQUALS]: objectOperators.operators[OPERATOR_TYPE.EQUALS],
    [OPERATOR_TYPE.NOT_EQUALS]: objectOperators.operators[OPERATOR_TYPE.NOT_EQUALS],
    [OPERATOR_TYPE.IS_OF_TYPE]: objectOperators.operators[OPERATOR_TYPE.IS_OF_TYPE],
    [OPERATOR_TYPE.NOT_IS_OF_TYPE]: objectOperators.operators[OPERATOR_TYPE.NOT_IS_OF_TYPE]
  },
  getParts: formula => {
    let currentPart = formula;
    let negative = false;

    if (currentPart.name === 'NOT') {
      currentPart = currentPart.args[0];
      negative = true;
    }

    if (currentPart.name === 'EQUALS') {
      return objectOperators.getParts(formula);
    }

    if (currentPart.name === 'OP') {
      return {
        operator: negative ? OPERATOR_TYPE.NOT_IS_OF_TYPE : OPERATOR_TYPE.IS_OF_TYPE,
        leftArg: currentPart.args[0].args[0],
        rightArg: currentPart.args[2]
      };
    }

    const innerPart = currentPart.args[0].args[0];
    if (innerPart.name === 'ANCESTORS') {
      return {
        operator: negative ? OPERATOR_TYPE.NOT_CONTAINS_ANY : OPERATOR_TYPE.CONTAINS_ANY,
        leftArg: innerPart.args[1],
        rightArg: currentPart.args[1]
      };
    } else if (innerPart.name === 'DESCENDANTS') {
      return {
        operator: negative ? OPERATOR_TYPE.NOT_IN : OPERATOR_TYPE.IN,
        leftArg: innerPart.args[1],
        rightArg: currentPart.args[1]
      };
    }

    throw new Error('Unknown operators: ' + currentPart.name);
  }
};
