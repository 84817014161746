export enum Calculations {
  percent = 'percentage',
  number = 'number'
}

export interface INumberFormat {
  prefix?: string;
  suffix?: string;
  thousandsSeparator?: string;
  calculations?: Calculations;
  decimalSeparator?: string;
  decimalPlaces?: number | string;
}

export interface IFormattingFunctionNumber {
  number: number;
  format: INumberFormat;
}

export interface IFormattingFunctionString {
  numberString: string;
  format: INumberFormat;
}

export interface IParseFormattedNumberParams {
  formattedNumber: string;
  format: INumberFormat;
}

export interface IParsedNumber {
  number: number | string; // can return an empty string;
  format: INumberFormat;
}
