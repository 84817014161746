import { Component, OnDestroy, OnInit } from '@angular/core';
import { IProfile } from '../../models/profile';
import { Tip } from '../../data/models/types';
import { IProfileQueryResult, MyProfileService } from '../services/my-profile.service';
import { SessionManagerService } from '../../data/session-manager.service';
import { switchMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { LoadingState } from '../../shared/constants';

@Component({
  selector: 'app-switch-profile-side-sheet',
  templateUrl: './switch-profile-side-sheet.component.html',
  styleUrls: ['./switch-profile-side-sheet.component.scss']
})
export class SwitchProfileSideSheetComponent implements OnInit, OnDestroy {

  myProfile: IProfile;
  myProfiles: IProfileQueryResult[];
  filterText = '';
  selected: Tip;

  loadingState: LoadingState = LoadingState.inProgress;
  loadingStates = LoadingState;
  errorMessage: string;
  subscription = new Subscription();

  constructor(
    private sessionManagerService: SessionManagerService,
    private myProfileService: MyProfileService
  ) { }

  ngOnInit() {
    this.selected = this.myProfile.$tip;
    this.subscription.add(
      this.myProfileService.getMyProfiles$().subscribe(
        myProfiles => {
          this.myProfiles = myProfiles;
          this.loadingState = LoadingState.loaded;
        },
        err => {
          this.loadingState = LoadingState.failed;
          this.errorMessage = 'Unable to load profiles';
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onToggle(tip: Tip) {
    this.selected = tip;
    this.onDone(tip);
  }

  onDone(profileTip: string) {
    if (profileTip !== this.myProfile.$tip) {
      this.loadingState = LoadingState.inProgress;
      this.sessionManagerService.getSessionInfo$().pipe(
        switchMap(sessionInfo => this.myProfileService.switchProfile(profileTip, sessionInfo.user))
      ).subscribe(
        () => { },
        err => {
          this.loadingState = LoadingState.failed;
          this.errorMessage = 'Unable to switch profile';
        }
      );
    }
  }
}
