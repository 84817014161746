import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, first, map, filter } from 'rxjs/operators';
import { get, head } from 'lodash';

import { IUser, IUserReadOnly } from '../../models/user';
import { ProcessService } from '../../data/process.service';
import { ISessionInfo, SessionManagerService } from '../../data/session-manager.service';
import { I18nService } from '../../data/i18n.service';

@Injectable({
  providedIn: 'root'
})
export class MeService {
  private _meSubject: BehaviorSubject<IUserReadOnly>;

  constructor(
    private _sessionManagerService: SessionManagerService,
    private processService: ProcessService,
    private i18nService: I18nService
  ) {
    this._meSubject = new BehaviorSubject<IUserReadOnly>(null);

    this._sessionManagerService.getSessionInfo$().pipe(
      first(),
      switchMap((sessionInfo: ISessionInfo) => this._resolveMe(sessionInfo))
    ).subscribe(
      (user: IUserReadOnly) => {
        // Called asynchronously.
        this.i18nService.checkLocale$(user);

        return this._meSubject.next(user);
      }
    );

    this._sessionManagerService.tokenChanged$.pipe(
      switchMap((sessionInfo: ISessionInfo): Observable<IUserReadOnly> => this._resolveMe(sessionInfo))
    ).subscribe(
      (user: IUserReadOnly) => this._meSubject.next(user)
    );
  }

  get me$(): Observable<IUserReadOnly> {
    return this.getMe$();
  }

  getMe$(): Observable<IUserReadOnly> {
    return this._meSubject.asObservable();
  }

  private _resolveMe(sessionInfo: ISessionInfo): Observable<IUserReadOnly> {
    const userTip = sessionInfo && sessionInfo.user;
    return userTip ? this.getCurrentUser() : of(null);
  }

  private getCurrentUser(): Observable<IUserReadOnly> {
    return this.processService.start('eim/process/shell/me').pipe(
      filter(response => response.finished),
      first(),
      map(response => {
        return {
          $tip: head(get(response, 'vars.tip', [])),
          email: head(get(response, 'vars.email', [])),
          status: head(get(response, 'vars.status', [])),
          profiles: get(response, 'vars.profiles', []),
          identityProvider: head(get(response, 'vars.identityProvider', [])),
          preference: head(get(response, 'vars.preference', [])),
          privateSecurityGrant: head(get(response, 'vars.privateSecurityGrant', [])),
          fullName: head(get(response, 'vars.fullName', [])),
          contactTip: head(get(response, 'vars.contactTip', [])),
          avatarTip: head(get(response, 'vars.avatarTip', [])),
          localeId: head(get(response, 'vars.locale-id', [])),
        };
      })
    );
  }

  isAdmin(): Observable<boolean> {
    return this.getMe$()
      .pipe(
        filter(Boolean),
        map((me: IUser) => get(me, 'profiles', []).includes('app/profile/administrator'))
      );
  }
}
