var _a;
import { reverseOperator } from './operators';
import { OPERATOR_TYPE } from '../../../models/query';
var ɵ0 = function (leftArg, rightArg) { return ({
    name: 'EQUALS',
    args: [leftArg, rightArg]
}); };
export var stringEqual = {
    opNo: 2,
    toFormula: ɵ0
};
export var stringNotEqual = reverseOperator(stringEqual);
var ɵ1 = function (leftArg, rightArg) { return ({
    name: 'OP',
    args: [{ name: 'FIND_TEXT', args: [leftArg, rightArg] }, '==', 0]
}); };
var stringStartsWith = {
    opNo: 2,
    toFormula: ɵ1
};
var stringNotStartsWith = reverseOperator(stringStartsWith);
var ɵ2 = function (leftArg, rightArg) { return ({
    name: 'OP',
    args: [
        { name: 'FIND_TEXT', args: [leftArg, rightArg] },
        '==',
        {
            name: 'SUB', args: [
                { name: 'STRLEN', args: [leftArg] },
                { name: 'STRLEN', args: [rightArg] }
            ]
        }
    ]
}); };
var stringEndsWith = {
    opNo: 2,
    toFormula: ɵ2
};
var stringNotEndsWith = reverseOperator(stringEndsWith);
var ɵ3 = function (leftArg, rightArg) { return ({
    name: 'OP',
    args: [{ name: 'FIND_TEXT', args: [leftArg, rightArg] }, '!=', -1]
}); };
export var stringContains = {
    opNo: 2,
    toFormula: ɵ3
};
export var stringNotContains = reverseOperator(stringContains);
var ɵ4 = function (leftArg) { return ({
    name: 'INARRAY',
    args: [
        {
            name: 'ARRAY',
            args: [
                '',
                {
                    name: 'ARRAY',
                    args: []
                }
            ]
        },
        leftArg
    ]
}); };
export var stringIsEmpty = {
    opNo: 1,
    toFormula: ɵ4
};
export var stringIsNotEmpty = reverseOperator(stringIsEmpty);
var ɵ5 = function (leftArg, rightArg) { return ({
    name: 'OP',
    args: [-1, '!=', { name: 'FIND_TEXT', args: [rightArg, leftArg] }] // to distinguish with contains
}); };
var stringIn = {
    opNo: 2,
    toFormula: ɵ5
};
var stringNotIn = reverseOperator(stringIn);
export var stringOperators = {
    operators: (_a = {},
        _a[OPERATOR_TYPE.EQUALS] = stringEqual,
        _a[OPERATOR_TYPE.NOT_EQUALS] = stringNotEqual,
        _a[OPERATOR_TYPE.STARTS_WITH] = stringStartsWith,
        _a[OPERATOR_TYPE.NOT_STARTS_WITH] = stringNotStartsWith,
        _a[OPERATOR_TYPE.ENDS_WITH] = stringEndsWith,
        _a[OPERATOR_TYPE.NOT_ENDS_WITH] = stringNotEndsWith,
        _a[OPERATOR_TYPE.CONTAINS] = stringContains,
        _a[OPERATOR_TYPE.NOT_CONTAINS] = stringNotContains,
        _a[OPERATOR_TYPE.IS_EMPTY] = stringIsEmpty,
        _a[OPERATOR_TYPE.NOT_IS_EMPTY] = stringIsNotEmpty,
        _a[OPERATOR_TYPE.IN] = stringIn,
        _a[OPERATOR_TYPE.NOT_IN] = stringNotIn,
        _a),
    getParts: function (formula) {
        var currentPart = formula;
        var negative = false;
        if (currentPart.name === 'NOT') {
            currentPart = currentPart.args[0];
            negative = true;
        }
        if (currentPart.name === 'INARRAY') {
            return {
                operator: negative ? OPERATOR_TYPE.NOT_IS_EMPTY : OPERATOR_TYPE.IS_EMPTY,
                leftArg: currentPart.args[1]
            };
        }
        if (currentPart.name === 'OP') {
            if (currentPart.args[2] === 0) {
                return {
                    operator: negative ? OPERATOR_TYPE.NOT_STARTS_WITH : OPERATOR_TYPE.STARTS_WITH,
                    leftArg: currentPart.args[0].args[0],
                    rightArg: currentPart.args[0].args[1]
                };
            }
            if (currentPart.args[2] === -1) {
                return {
                    operator: negative ? OPERATOR_TYPE.NOT_CONTAINS : OPERATOR_TYPE.CONTAINS,
                    leftArg: currentPart.args[0].args[0],
                    rightArg: currentPart.args[0].args[1]
                };
            }
            if (currentPart.args[0] === -1) {
                return {
                    operator: negative ? OPERATOR_TYPE.NOT_IN : OPERATOR_TYPE.IN,
                    leftArg: currentPart.args[2].args[1],
                    rightArg: currentPart.args[2].args[0]
                };
            }
            if (currentPart.args[0].name === 'FIND_TEXT') {
                return {
                    operator: negative ? OPERATOR_TYPE.NOT_ENDS_WITH : OPERATOR_TYPE.ENDS_WITH,
                    leftArg: currentPart.args[0].args[0],
                    rightArg: currentPart.args[0].args[1]
                };
            }
            throw new Error('Unknown operators:' + currentPart.name);
        }
        if (currentPart.name === 'EQUALS') {
            return {
                operator: negative ? OPERATOR_TYPE.NOT_EQUALS : OPERATOR_TYPE.EQUALS,
                leftArg: currentPart.args[0],
                rightArg: currentPart.args[1]
            };
        }
        throw new Error('Unknown operators: ' + currentPart.name);
    }
};
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
