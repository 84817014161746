<app-auth-chrome
    [message]="message"
    [showMessage]="!!message || !!status"
    i18n-secondaryTitle="@@signIn.authChrome.secondaryTitle"
    secondaryTitle="Please enter your Noggin user information">
  <ng-container
      eim-i18n-property="message"
      i18n="@@signIn.authChrome.message">{
    status,
      select,
      bad {Sorry, we don’t recognize that username or password. Please try again.}
      failure {Something is wrong and we cannot log you in right now. Please try again later.}
      awaiting {We see your user account is not yet verified by your administrator. Please ask them to verify your account and then try log in again.}
      invited {We can see you have a user account, but it is not activated. Please go to the invitation email sent to you and click the account activation link.}
      requested {We have sent your administrator a request to verify your account. Please check with them and then try log in again.}
      suspended {Sorry, we cannot log you in as your account is suspended. Please contact your administrator.}
      accountLock {Sorry, your account has been locked out due to too many incorrect login attempts. Please try again in 5 minutes.}
      }</ng-container>
  <form
      [formGroup]="signInForm"
      [appSpinner]="loading">
    <div class="form-group">
      <label
          i18n="@@signIn.authChrome.signInForm.emailLabel"
          for="email"
          class="sr-only">Email</label>
      <input
          class="form-control"
          id="email"
          type="email"
          i18n-placeholder="@@signIn.authChrome.signInForm.emailPlaceholder"
          placeholder="Email"
          autocomplete="off"
          formControlName="email">
    </div>
    <div *ngIf="passwordSignIn" data-test="sign-in-section">
      <div class="form-group">
        <label
            i18n="@@signIn.authChrome.signInForm.passwordLabel"
            for="password"
            class="sr-only">Password</label>
        <input
            #password
            class="form-control"
            id="password"
            type="password"
            i18n-placeholder="@@signIn.authChrome.signInForm.passwordPlaceholder"
            placeholder="Password"
            formControlName="password">
      </div>
      <div class="d-flex justify-content-between form-group">
        <div>
          <label class="custom-control custom-checkbox m-0">
            <input class="custom-control-input" type="checkbox">
            <span
                i18n="@@signIn.authChrome.signInForm.rememberMeLabel"
                class="custom-control-label">Remember Me</span>
          </label>
        </div>
        <div class="forgot-password">
          <a
              i18n="@@signIn.authChrome.signInForm.forgotPasswordLabel"
              routerLink="/forgot-password"
              data-test="forgot-password">Forgot password?</a>
        </div>
      </div>
      <button
          i18n="@@signIn.authChrome.signInForm.logInButton"
          data-test="login-button"
          class="btn btn-block eim-button_noggin"
          type="submit"
          (click)="onSignIn()">Log in</button>
    </div>
    <button
        i18n="@@signIn.authChrome.signInForm.continueButton"
        *ngIf="!passwordSignIn"
        [disabled]="!emailValid"
        data-test="continue-button"
        class="btn btn-block eim-button_noggin"
        type="submit" (click)="checkEmailAddress()">Continue</button>
  </form>
  <div class="d-flex justify-content-center site-selector-link" *ngIf="isNativeApp">
    <span
        i18n="@@signIn.authChrome.anotherSite"
        (click)="changeSite()">Log in to another site</span>
  </div>
</app-auth-chrome>
