import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-rich-text',
  template: `
      <app-rich-jodit-text-field
        [formControl]="formControl"
        [label]="field.templateOptions.label"
        [readonly]="to.readonly"
        [required]="to.required">
      </app-rich-jodit-text-field>
  `
})
export class FormlyFieldRichTextComponent extends FieldType {
}
