import { Injectable } from '@angular/core';
import { ILinkData, INodeEditorData, INodeLinkData, INodeLinkKey } from './workflow-designer-interfaces';
import { cloneDeep, get } from 'lodash';
import { IProcess, IProcessConnection, IProcessNode } from '../../models/process';
import { IWorkflow } from '../../models/workflow';
import { DecisionNodeConverterService } from './node-converters/decision-node-converter.service';
import { NodeConverterBase } from './node-converters/NodeConverterBase';
import { CompositeNodeConverter, INodeAndLink } from './node-converters/CompositeNodeConverter';
import { LoggerService } from '../../util/logger.service';
import { SubworkflowNodeConverterService } from './node-converters/subworkflow-node-converter.service';
import { EndNodeConverterService } from './node-converters/end-node-converter.service';
import { UpdateObjectNodeConverterService } from './node-converters/update-object-node-converter.service';
import { CreateObjectNodeConverterService } from './node-converters/create-object-node-converter.service';
import { DeleteNodeConverterService } from './node-converters/delete-node-converter.service';
import { DuplicateObjectNodeConverterService } from './node-converters/duplicate-object-node-converter.service';
import { SetVariableNodeConverterService } from './node-converters/set-variable-node-converter.service';
import { SwitchNodeConverterService } from './node-converters/switch-node-converter.service';
import dataConstants from '../../data/constants';
import { SendEmailConverterService } from './node-converters/send-email-converter.service';
import { SendSmsConverterService } from './node-converters/send-sms-converter.service';
import { WorkflowUXNodeConverterService } from './node-converters/workflow-ux-node-converter.service';
import { StartNodeConverterService } from './node-converters/start-node-converter.service';
import { PROCESS_NODE_TYPE } from './workflow-designer-enums';
import { UpdateSecurityNodeConverterService } from './node-converters/update-security-node-converter.service';
import { SaveFileNodeConverterService } from './node-converters/save-file-node-converter.service';
import { GeneratePdfConverterService } from './node-converters/generate-pdf-converter.service';
import { ExportEnoConverterService } from './node-converters/export-enos-converter.service';
import { ImportEnosConverterService } from './node-converters/import-enos-converter.service';
import { SendNotificationConverterService } from './node-converters/send-notification-converter.service';
import { RetrieveFileConverterService } from './node-converters/retrieve-file-converter.service';
import { SendVoiceMessageConverterService } from './node-converters/send-voice-message-converter.service';
import { QueryExternalMapConverterService } from './node-converters/query-external-map-converter.service';
import { UpdateStageConverterService } from './node-converters/update-stage-converter.service';
import { ForEachNodeConverterService } from './node-converters/for-each-node-converter.service';
import { PauseUntilNodeConverterService } from './node-converters/pause-until-node-converter-service/pause-until-node-converter.service';

enum NODE_TYPE {
  BASE,
  COMPOSITE
}

interface IProcessNodeTypeConverterMap {
  [processNodeType: string]: NodeConverterBase;
}

interface IProcessNodeCompositeConverterMap {
  [processNodeType: string]: CompositeNodeConverter;
}

export const ADMIN_GRANT = 'app/security-grant/admin';

@Injectable({
  providedIn: 'root'
})
export class ProcessConverterService {
  private processNodeConverterMap: IProcessNodeTypeConverterMap = {};
  private processNodeCompositeCoverterMap: IProcessNodeCompositeConverterMap = {};

  constructor(
    private decisionNodeConverterService: DecisionNodeConverterService,
    private subworkflowNodeConverterService: SubworkflowNodeConverterService,
    private updateObjectConverterService: UpdateObjectNodeConverterService,
    private createObjectConverterService: CreateObjectNodeConverterService,
    private startNodeConverterService: StartNodeConverterService,
    private endNodeConverterService: EndNodeConverterService,
    private setVariableNodeConverterService: SetVariableNodeConverterService,
    private deleteNodeConverterService: DeleteNodeConverterService,
    private duplicateNodeConverterService: DuplicateObjectNodeConverterService,
    private switchNodeConverterService: SwitchNodeConverterService,
    private sendEmailConverterService: SendEmailConverterService,
    private sendSmsConverterService: SendSmsConverterService,
    private workflowUXConverterService: WorkflowUXNodeConverterService,
    private updateSecurityNodeConverterService: UpdateSecurityNodeConverterService,
    private saveFileNodeConverterService: SaveFileNodeConverterService,
    private generatePdfConverterService: GeneratePdfConverterService,
    private exportEnoConverterService: ExportEnoConverterService,
    private importEnosConverterService: ImportEnosConverterService,
    private sendNotificationConverterService: SendNotificationConverterService,
    private retrieveFileConverterService: RetrieveFileConverterService,
    private sendVoiceMessageConverterService: SendVoiceMessageConverterService,
    private queryExternalConverterService: QueryExternalMapConverterService,
    private updateStageConverterService: UpdateStageConverterService,
    private forEachNodeConverterService: ForEachNodeConverterService,
    private pauseUntilNodeConverterService: PauseUntilNodeConverterService,
    private loggerService: LoggerService
  ) {
    // Add node converter here
    this.processNodeConverterMap[PROCESS_NODE_TYPE.DECISION] = decisionNodeConverterService;
    this.processNodeConverterMap[PROCESS_NODE_TYPE.SUBWORKFLOW] = subworkflowNodeConverterService;
    this.processNodeConverterMap[PROCESS_NODE_TYPE.UPDATE_OBJECT] = updateObjectConverterService;
    this.processNodeConverterMap[PROCESS_NODE_TYPE.CREATE_OBJECT] = createObjectConverterService;
    this.processNodeConverterMap[PROCESS_NODE_TYPE.SET_VARIABLE] = setVariableNodeConverterService;
    this.processNodeConverterMap[PROCESS_NODE_TYPE.DELETE_OBJECT] = deleteNodeConverterService;
    this.processNodeConverterMap[PROCESS_NODE_TYPE.DUPLICATE_OBJECT] = duplicateNodeConverterService;
    this.processNodeConverterMap[PROCESS_NODE_TYPE.SWITCH] = switchNodeConverterService;
    this.processNodeConverterMap[PROCESS_NODE_TYPE.END] = endNodeConverterService;
    this.processNodeConverterMap[PROCESS_NODE_TYPE.START] = startNodeConverterService;
    // Add composite converter here. This is for workflow nodes that are converted into potentially multiple processnodes and connections
    this.processNodeCompositeCoverterMap[PROCESS_NODE_TYPE.SEND_EMAIL] = sendEmailConverterService;
    this.processNodeCompositeCoverterMap[PROCESS_NODE_TYPE.SEND_SMS] = sendSmsConverterService;
    this.processNodeCompositeCoverterMap[PROCESS_NODE_TYPE.WORKFLOW_UX] = workflowUXConverterService;
    this.processNodeCompositeCoverterMap[PROCESS_NODE_TYPE.UPDATE_SECURITY] = updateSecurityNodeConverterService;
    this.processNodeCompositeCoverterMap[PROCESS_NODE_TYPE.SAVE_FILE] = saveFileNodeConverterService;
    this.processNodeCompositeCoverterMap[PROCESS_NODE_TYPE.GENERATE_PDF] = generatePdfConverterService;
    this.processNodeCompositeCoverterMap[PROCESS_NODE_TYPE.EXPORT_ENOS] = exportEnoConverterService;
    this.processNodeCompositeCoverterMap[PROCESS_NODE_TYPE.IMPORT_ENOS] = importEnosConverterService;
    this.processNodeCompositeCoverterMap[PROCESS_NODE_TYPE.SEND_NOTIFICATION] = sendNotificationConverterService;
    this.processNodeCompositeCoverterMap[PROCESS_NODE_TYPE.RETRIEVE_FILE] = retrieveFileConverterService;
    this.processNodeCompositeCoverterMap[PROCESS_NODE_TYPE.SEND_VOICE_MESSAGE] = sendVoiceMessageConverterService;
    this.processNodeCompositeCoverterMap[PROCESS_NODE_TYPE.QUERY_EXTERNAL_MAP] = queryExternalConverterService;
    this.processNodeCompositeCoverterMap[PROCESS_NODE_TYPE.UPDATE_STAGE] = updateStageConverterService;
    this.processNodeCompositeCoverterMap[PROCESS_NODE_TYPE.FOR_EACH] = forEachNodeConverterService;
    this.processNodeCompositeCoverterMap[PROCESS_NODE_TYPE.PAUSE_UNTIL] = pauseUntilNodeConverterService;
  }

  /*
   * This is the entry of the whole converting from workflow to process
   *
   * @Throws: InvalidWorkflowNodeDataError thrown when validation fails, such as required field is not given
   */
  public convertWorkFlowToProcess(workflow: IWorkflow, nodeDataForProcess: INodeEditorData[]): IProcess {

    // nodeDataForProcess.filter(o => o.processNodeType === PROCESS_NODE_TYPE.WORKFLOW_UX).forEach(o => {
    //   let lifecycleObjectProp = NodeEditorLightUtils.getCombinedWorkflowProps(workflow)
    //     .find(prop => prop.key === o.fields.contextObjectSelectedPropertyKey);

    //   if (lifecycleObjectProp) {
    //     this.removeExistingWorkflowUXObjectInputs(workflow);
    //     lifecycleObjectProp = cloneDeep(lifecycleObjectProp);
    //     lifecycleObjectProp.key = 'Object';
    //     workflow.inputs.push( lifecycleObjectProp );
    //     console.log('workflow.inputs', workflow.inputs);
    //   }
    // });

    const clonedNodeData = cloneDeep(nodeDataForProcess);

    const nodeDataWithToLink = this.convertLinkDataToNodeLink(workflow.diagramData.linkDataArray, clonedNodeData);
    const process: IProcess = this.createDraftProcess(workflow);

    process.grants = workflow.isAdminMode ? ADMIN_GRANT : null;

    const { nodes, links } = this.createDraftNodesAndConnections(nodeDataWithToLink, workflow);

    this.linkAll(process, nodes, links);

    return process;
  }

  // private removeExistingWorkflowUXObjectInputs(workflow: IWorkflow): void {
  //   for (let i = workflow.inputs.length - 1; i >= 0; i--) {
  //     const inp = workflow.inputs[i];
  //     if (inp.key === 'Object') {
  //       workflow.inputs.splice(i, 1);
  //     }
  //   }
  // }

  /*
   * This method accepts all the existent link data in GoJS graph and all the nodes, then
   * populate toLink property to each node. This does deep clones the given nodes to populate the returning value.
   */
  private convertLinkDataToNodeLink(links: ILinkData[], nodeData: INodeEditorData[]): INodeEditorData[] {
    const resultNodeData: INodeEditorData[] = cloneDeep(nodeData);
    const nodeLinkData: { [nodeKey: string]: INodeLinkData } = { };

    links.forEach((link: ILinkData) => {
      if (!nodeLinkData[link.from]) {
        nodeLinkData[link.from] = { };
      }

      link.delaySec = link.delaySec || 0;

      const outcomeAndDelayKey: string = JSON.stringify({ outcome: link.value, delaySec: link.delaySec });

      if (!nodeLinkData[link.from][outcomeAndDelayKey]) {
        nodeLinkData[link.from][outcomeAndDelayKey] = [];
      }

      nodeLinkData[link.from][outcomeAndDelayKey].push(link.to);
    });

    resultNodeData.forEach((aResultNodeData: INodeEditorData) => {
      aResultNodeData.toLink = nodeLinkData[aResultNodeData.tip] || {};
    });

    return resultNodeData;
  }

  /*
   * This returns IProcess but not containing nodes
   */
  private createDraftProcess(workflow: IWorkflow): IProcess {
    const process: IProcess = {
      $tip: get(workflow, 'process.$tip', undefined),
      $type: 'process',
      title: workflow.name,
      description: workflow.description,
      nodes: [],
      env: 'Server'
    };

    return process;
  }

  /*
   * This returns IProcessNode[] but not containing connections and IProcessConnection[] but not containing toNodes
   * Connections and toNode will be populated in linkAll method
   * nodes must contain toLink property
   *
   * @Throws: InvalidWorkflowNodeDataError thrown when validation fails, such as required field is not given
   */
  private createDraftNodesAndConnections(nodeData: INodeEditorData[], workflow: IWorkflow): INodeAndLink {
    let links: IProcessConnection[] = [];
    let nodes: IProcessNode[] = [];

    nodeData.forEach((aNodeData) => {
      if (!this.processNodeConverterMap[aNodeData.processNodeType] && !this.processNodeCompositeCoverterMap[aNodeData.processNodeType]) {
        this.loggerService.error(`[${aNodeData.processNodeType}]'s converter is not implemented. \
          Please implement converter for [${aNodeData.processNodeType}], \
          which extends either NodeConverter or CompositeNodeConverter class. \
          And do not forget to add converter map in ProcessConverterService`, aNodeData);
        throw Error(`${aNodeData.processNodeType}'s converter is not implemented.`);
      }
      const nodeType: NODE_TYPE = this.processNodeConverterMap[aNodeData.processNodeType] ? NODE_TYPE.BASE : NODE_TYPE.COMPOSITE;

      Object.keys(aNodeData.toLink).forEach((outcomeAndDelaySecKey: string) => {
        const { outcome, delaySec } = JSON.parse(outcomeAndDelaySecKey) as INodeLinkKey;

        if (nodeType === NODE_TYPE.COMPOSITE) {
          return;
        }

        links.push({
          $type: 'processconnection',
          $security: dataConstants.SECURITY.INSTANCE_USER_ADMIN,
          outcome,
          toNodes: [],
          point: [0, 0], // This is meaningless to save GoJS points as connection eno will be grouped by outcome and delay
          delaySec,
          toNodeKeys: aNodeData.toLink[outcomeAndDelaySecKey],
          fromNodeKey: aNodeData.tip
        });
      });

      if (nodeType === NODE_TYPE.BASE) {
        nodes.push(this.processNodeConverterMap[aNodeData.processNodeType].convert(aNodeData, workflow));

        return;
      }

      const nodesAndLinks: INodeAndLink = this.processNodeCompositeCoverterMap[aNodeData.processNodeType].convert(aNodeData, workflow);

      links = links.concat(nodesAndLinks.links);
      nodes = nodes.concat(nodesAndLinks.nodes);
    });

    return { nodes, links };
  }

  /*
   * Connect process and nodes and links all together and actual process, nodes, and links will be modified
   * as all the parameters are reference
   */
  private linkAll(process: IProcess, nodes: IProcessNode[], links: IProcessConnection[]): void {
    process.nodes = nodes;

    const keyToNodeMap: { [key: string]: IProcessNode } = { };

    nodes.forEach((node) => {
      if (node.key === null) {
        return;
      }

      keyToNodeMap[node.key] = node;
    });

    links.forEach((link: IProcessConnection) => {
      // fromNodeKey does not exist for links generated by composite node
      if (link.fromNodeKey) {
        keyToNodeMap[link.fromNodeKey].connections.push(link);
      }

      // toNodeKeys does not exist for the last
      if (link.toNodeKeys) {
        link.toNodes = link.toNodeKeys.map((nodeKey) => {
          return keyToNodeMap[nodeKey];
        });
      }
    });
  }
}
