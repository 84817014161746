import * as tslib_1 from "tslib";
import { camelCase } from 'change-case';
import { UX_NODE_OUTCOME } from '../../modules/sidesheets/workflow-end-user-side-sheet/workflow-end-user.service';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import { CompositeNodeConverter } from './CompositeNodeConverter';
import { NodeEditorLightUtils } from '../utils/node-editor-light-utils';
import { WorkflowUXScreenItemType } from '../workflow-designer-enums';
import { stringInterpolateReplaceFormula } from '../utils/converter-utils';
import { createConnection, createSetVarNode, createSubProcessNode, createSwitchNode } from './utils/create-node-utils';
import { Stringify } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import * as i0 from "@angular/core";
var NS = 'WorkflowUXNodeConverterService';
var WorkflowUXNodeConverterService = /** @class */ (function (_super) {
    tslib_1.__extends(WorkflowUXNodeConverterService, _super);
    function WorkflowUXNodeConverterService() {
        var _this = _super.call(this) || this;
        _this.type = 'processnode/logic/subprocess';
        _this.processNodeType = 'processnodetype/logic/subprocess';
        return _this;
    }
    WorkflowUXNodeConverterService.prototype.convert = function (nodeData) {
        if (!this.isValid(nodeData)) {
            throw new InvalidWorkflowNodeDataError("Please correct invalid data on workflow-ux node [" + nodeData.fields.title + "]", nodeData);
        }
        this.modifyScreensBasedOnOutcome(nodeData);
        var _a = nodeData.fields, screens = _a.screens, contextObjectType = _a.contextObjectType, contextObjectSelectedPropertyKey = _a.contextObjectSelectedPropertyKey, isGoverned = _a.isGoverned, forceCreation = _a.forceCreation;
        var UX = this.getUXObject(screens, contextObjectType, contextObjectSelectedPropertyKey, isGoverned, forceCreation);
        var setVarNode = createSetVarNode({
            'Object var name': contextObjectSelectedPropertyKey,
            UX: UX
        }, nodeData.tip);
        var subprocessNode = createSubProcessNode('eim/process/workflow-ux', ['Workflow runtime tip', 'UX', 'Object var name'], 60000);
        var doneConnectionForSetVarNode = createConnection('done', subprocessNode);
        setVarNode.connections.push(doneConnectionForSetVarNode);
        var actionAndPrimaryButtonConnections = NodeEditorLightUtils.getWorkflowUXConnectionTypes(nodeData.fields.screens)
            .map(function (o) { return o.value; });
        var switchNode = createSwitchNode({ name: 'VAR', args: ['Outcome'] }, actionAndPrimaryButtonConnections, UX_NODE_OUTCOME.noop, [UX_NODE_OUTCOME.secondary]);
        var doneConnectionForSubprocessNode = createConnection('done', switchNode);
        subprocessNode.connections.push(doneConnectionForSubprocessNode);
        var nodesAndLink = {
            nodes: [
                setVarNode,
                subprocessNode,
                switchNode
            ],
            links: [
                doneConnectionForSetVarNode,
                doneConnectionForSubprocessNode
            ]
        };
        this.populateOutgoingLinks(nodeData).forEach(function (link) {
            switchNode.connections.push(link);
            nodesAndLink.links.push(link);
        });
        return nodesAndLink;
    };
    WorkflowUXNodeConverterService.prototype.modifyScreensBasedOnOutcome = function (nodeData) {
        var hasPrimaryOutcome = !!Object.keys(nodeData.toLink)
            .find(function (key) { return key.indexOf("\"outcome\":\"" + UX_NODE_OUTCOME.primary + "\"") !== -1; });
        var hasSecondaryOutcome = !!Object.keys(nodeData.toLink)
            .find(function (key) { return key.indexOf("\"outcome\":\"" + UX_NODE_OUTCOME.secondary + "\"") !== -1; });
        nodeData.fields.screens.forEach(function (screen) {
            // forms still need primary button so be able to save themselves
            var isForm = screen.screenItemType === WorkflowUXScreenItemType.differentForm
                || screen.screenItemType === WorkflowUXScreenItemType.objectDefaultForm;
            if (!hasPrimaryOutcome && !isForm) {
                screen.primaryButtonLabel = null;
            }
            screen.hasSecondaryConnection = hasSecondaryOutcome;
            // if action buttons exist, but are not used, sets them to null
            if (!screen.actionButtonLabels) {
                return;
            }
            var linkTypes = NodeEditorLightUtils.getWorkflowUXConnectionTypesForScreen(screen, false);
            linkTypes.forEach(function (linkType) {
                var outcome = Object.keys(nodeData.toLink).find(function (key) { return key.indexOf("\"outcome\":\"" + linkType.value + "\"") !== -1; });
                if (!outcome) {
                    var indexOfOutcome = screen.actionButtonLabels.indexOf(linkType.name);
                    if (indexOfOutcome !== -1) {
                        screen.actionButtonLabels.splice(indexOfOutcome, 1);
                    }
                }
            });
        });
    };
    WorkflowUXNodeConverterService.prototype.isValid = function (nodeData) {
        return nodeData.fields.contextObjectType
            && nodeData.fields.screens
            && !!nodeData.fields.screens.length
            && !!nodeData.fields.contextObjectSelectedPropertyKey;
    };
    WorkflowUXNodeConverterService.prototype.getUXObject = function (screens, typeTip, contextObjectSelectedPropertyKey, isGoverned, forceCreation) {
        if (isGoverned === void 0) { isGoverned = true; }
        if (forceCreation === void 0) { forceCreation = false; }
        return Stringify({
            name: 'ARRAY',
            args: screens.map(function (o) { return ({
                name: 'JSON_BUILD',
                args: [
                    JSON.stringify({
                        type: 'object',
                        property: {
                            actors: {
                                type: 'stringArray',
                                formula: Stringify({
                                    name: 'ARRAY',
                                    args: (o.selectedActorKeys || []).map(function (key) { return ({ name: 'VAR', args: [key] }); })
                                })
                            },
                            screen: {
                                type: 'string',
                                value: o.screenItemType
                            },
                            objectTip: {
                                type: 'string',
                                formula: Stringify({ name: 'VAR', args: [contextObjectSelectedPropertyKey] }),
                                noPropertyWhenEmpty: true
                            },
                            isGoverned: {
                                type: 'boolean',
                                value: isGoverned
                            },
                            forceCreation: {
                                type: 'boolean',
                                value: forceCreation
                            },
                            typeTip: {
                                type: 'string',
                                value: typeTip
                            },
                            form: {
                                type: 'string',
                                value: o.formItemTip
                            },
                            sideSheetTitle: {
                                type: 'string',
                                formula: stringInterpolateReplaceFormula({ html: o.sideSheetTitle, substitutions: o.sideSheetTitleSubstitutions }),
                            },
                            primaryButtonLabel: {
                                type: 'string',
                                value: o.primaryButtonLabel
                            },
                            hasSecondaryConnection: {
                                type: 'boolean',
                                value: o.hasSecondaryConnection
                            },
                            actionButtonLabels: {
                                type: 'stringArray',
                                value: o.actionButtonLabels ? o.actionButtonLabels : null
                            },
                            actionButtonOutcomes: {
                                type: 'stringArray',
                                value: o.actionButtonLabels ? o.actionButtonLabels.map(function (name) { return camelCase(name); }) : null
                            },
                            instructionsSubtitle: {
                                type: 'string',
                                formula: stringInterpolateReplaceFormula({ html: o.instructionsSubtitle, substitutions: o.instructionsSubtitleSubstitutions }),
                            },
                            instructionsTitle: {
                                type: 'string',
                                formula: stringInterpolateReplaceFormula({ html: o.instructionsTitle, substitutions: o.instructionsTitleSubstitutions }),
                            },
                            instructionsIntent: {
                                type: 'string',
                                value: o.instructionsIntent
                            }
                        }
                    }),
                    'root'
                ]
            }); })
        });
    };
    WorkflowUXNodeConverterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WorkflowUXNodeConverterService_Factory() { return new WorkflowUXNodeConverterService(); }, token: WorkflowUXNodeConverterService, providedIn: "root" });
    return WorkflowUXNodeConverterService;
}(CompositeNodeConverter));
export { WorkflowUXNodeConverterService };
