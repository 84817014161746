import { OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, first, tap } from 'rxjs/operators';
import { cloneDeep, size } from 'lodash';
import { LoadingState } from '../../../../shared/constants';
import { ListEditSideSheetComponent } from '../list-edit-side-sheet/list-edit-side-sheet.component';
import { ListItemSideSheetComponent } from '../list-item-side-sheet/list-item-side-sheet.component';
import { ListItemCreateSideSheetComponent } from '../list-item-create-side-sheet/list-item-create-side-sheet.component';
import { ListUtilsService } from '../../list-utils.service';
import { ModuleValidationError } from '../../../../data/errors/client/ModuleValidationError';
var ListSideSheetComponent = /** @class */ (function () {
    function ListSideSheetComponent(sideSheetService, listService, toastService, operationalLimitService) {
        this.sideSheetService = sideSheetService;
        this.listService = listService;
        this.toastService = toastService;
        this.operationalLimitService = operationalLimitService;
        this.unsubscribe$ = new Subject();
        this.loadingStates = LoadingState;
        this.filterText = '';
        this.isDraggable = false;
    }
    ListSideSheetComponent.prototype.ngOnInit = function () {
        this.getList();
    };
    ListSideSheetComponent.prototype.getList = function () {
        var _this = this;
        this.loadingState = LoadingState.inProgress;
        this.listService
            .getList(this.listTip)
            .pipe(tap(function () { return _this.listService.getListCalled.next(); }), takeUntil(this.unsubscribe$))
            .subscribe(function (list) {
            _this.list = list;
            _this.branchTip = list.$branch;
            _this.loadingState = LoadingState.loaded;
        }, function () {
            _this.loadingState = LoadingState.failed;
        });
        this.operationalLimitService.getValue$('numberOfItemsPerListDesigner').pipe(first()).subscribe(function (operationalLimit) { return _this.itemsLimited = operationalLimit; });
    };
    ListSideSheetComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    };
    ListSideSheetComponent.prototype.editList = function () {
        var componentInstance = this
            .sideSheetService
            .push(ListEditSideSheetComponent).componentInstance;
        componentInstance.moduleTip = this.moduleTip;
        componentInstance
            .setProps({ list: this.list });
    };
    ListSideSheetComponent.prototype.openListItem = function (value) {
        var _this = this;
        var sheetRef = this.sideSheetService.push(ListItemSideSheetComponent, { onPop: function () { return _this.getList(); } });
        var componentInstance = sheetRef.componentInstance;
        componentInstance.list = this.list;
        componentInstance.listItemIndex = ListUtilsService.getListItemIndex(this.list.items, value);
        componentInstance.moduleTip = this.moduleTip;
    };
    ListSideSheetComponent.prototype.createListItem = function () {
        if (size(this.list.items) >= this.itemsLimited) {
            this.toastService.showErrorToast("You can only add " + this.itemsLimited + " items per list.");
            return;
        }
        var sheetRef = this.sideSheetService.push(ListItemCreateSideSheetComponent);
        var componentInstance = sheetRef.componentInstance;
        componentInstance.list = this.list;
        componentInstance.moduleTip = this.moduleTip;
    };
    ListSideSheetComponent.prototype.onDragStart = function () {
        this.isDraggable = true;
        this.listItemsBeforeReorder = cloneDeep(this.list.items);
    };
    ListSideSheetComponent.prototype.onDragCancel = function () {
        this.isDraggable = false;
        this.list.items = cloneDeep(this.listItemsBeforeReorder);
    };
    ListSideSheetComponent.prototype.onDragFinish = function () {
        var _this = this;
        this.loadingState = LoadingState.inProgress;
        this.listService.updateList(this.list, this.moduleTip).pipe(first(), tap(function () {
            _this.isDraggable = false;
            _this.loadingState = LoadingState.loaded;
        })).subscribe(function () { }, function (error) {
            error instanceof ModuleValidationError
                ? _this.toastService.showErrorToast(error.message)
                : _this.toastService.showErrorToast('Failed to update the list items order.');
        });
    };
    ListSideSheetComponent.prototype.goBack = function () {
        this.sideSheetService.pop();
    };
    return ListSideSheetComponent;
}());
export { ListSideSheetComponent };
