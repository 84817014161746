import { environment } from '../../environments/environment';

const SERVER = `centos@54.66.173.51`;
const FOLDER = `project/elastic-noggin-server`;
const LOGS = `docker-compose logs -f --tail 100 ensrv`;

function loginAndTailLogs(grepsParams: string[], linesToReturn: number): string {
  const GREPS = grepsParams.reduce((acc, grepParam) => `${acc} | grep -A ${linesToReturn} ${grepParam} --line-buffered`, '');
  return ssh(GREPS);
}

function ssh(GREPS) {
  return `
  Copy this:
  ----
     
    ssh ${SERVER} -t "cd ${FOLDER}; ${LOGS} ${GREPS}"
    
  ----
  `;
}

export class TailLogs {

  linesToReturn = 20;
  sessionId = 'no-session-id!!';
  get truncatedSessionId() {
    return this.sessionId.substring(0, 6);
  }

  get trucatedNamespace() {
    return environment.ns.substr(0, 6);
  }

  constructor() {

  }

  setSessionId(sessionId: string) {
    this.sessionId = sessionId;
  }

  get help() {
    return `
      Tail logs provides utility snippets you can use to tail the docker compose logs on the showcase server.
      
      Try
      - session
      - process
      
      Don't see what you like? Add it!
    `;
  }

  get namespace() {
    return loginAndTailLogs([this.trucatedNamespace], this.linesToReturn);
  }

  get session() {
    return loginAndTailLogs([this.truncatedSessionId], this.linesToReturn);
  }

  get process() {
    return loginAndTailLogs([`'\\"tag\\":\\"process\\".*${this.truncatedSessionId}'`], this.linesToReturn);
  }
}
