import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { APIComponent } from './api.component';

@NgModule({
  declarations: [APIComponent],
  imports: [
    CommonModule,
  ],
  exports: [
    APIComponent
  ],
})
export class APIModule { }
