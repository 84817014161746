var _a, _b, _c, _d;
export var FILE_NODE_LOCATION_TYPE;
(function (FILE_NODE_LOCATION_TYPE) {
    FILE_NODE_LOCATION_TYPE["FILE_UPLOAD_FIELD"] = "fieldUploadField";
    FILE_NODE_LOCATION_TYPE["OBJECT_ATTACHMENTS"] = "objectAttachments";
    FILE_NODE_LOCATION_TYPE["DOCUMENT_FOLDER"] = "documentFolder";
    FILE_NODE_LOCATION_TYPE["INTEGRATION_BUCKET"] = "integrationBucket";
    FILE_NODE_LOCATION_TYPE["S3_BUCKET"] = "s3Bucket";
    FILE_NODE_LOCATION_TYPE["SFTP"] = "sftp";
})(FILE_NODE_LOCATION_TYPE || (FILE_NODE_LOCATION_TYPE = {}));
export var FILE_NODE_LOCATION_MODE;
(function (FILE_NODE_LOCATION_MODE) {
    FILE_NODE_LOCATION_MODE["SAVE_FILE"] = "saveFile";
    FILE_NODE_LOCATION_MODE["RETRIEVE_FILE"] = "retrieveFile";
})(FILE_NODE_LOCATION_MODE || (FILE_NODE_LOCATION_MODE = {}));
;
export var internalLocationLabels = (_a = {},
    _a[FILE_NODE_LOCATION_MODE.SAVE_FILE] = [
        { locationType: FILE_NODE_LOCATION_TYPE.DOCUMENT_FOLDER, title: 'Select a document folder', hasChevron: true },
        { locationType: FILE_NODE_LOCATION_TYPE.OBJECT_ATTACHMENTS, title: 'Attach to an object', hasChevron: true },
        { locationType: FILE_NODE_LOCATION_TYPE.FILE_UPLOAD_FIELD, title: 'Add to file upload field', hasChevron: true },
    ],
    _a[FILE_NODE_LOCATION_MODE.RETRIEVE_FILE] = [
    // { locationType: FILE_NODE_LOCATION_TYPE.DOCUMENT_FOLDER, title: 'Select a document folder', hasChevron: true },
    // { locationType: FILE_NODE_LOCATION_TYPE.OBJECT_ATTACHMENTS, title: 'From an object', hasChevron: true },
    // { locationType: FILE_NODE_LOCATION_TYPE.FILE_UPLOAD_FIELD, title: 'From a file upload field', hasChevron: true },
    ],
    _a);
export var externalLocationLabels = (_b = {},
    _b[FILE_NODE_LOCATION_MODE.SAVE_FILE] = [
    // {
    //   locationType: FILE_NODE_LOCATION_TYPE.INTEGRATION_BUCKET,
    //   title: 'Integration bucket',
    //   featureFlag: 'feature-integration-bucket',
    //   hasChevron: false
    // },
    // {
    //   locationType: FILE_NODE_LOCATION_TYPE.S3_BUCKET,
    //   title: 'S3 Bucket',
    //   featureFlag: 'feature-amazon-s3-integrations-center',
    //   hasChevron: true
    // },
    // { locationType: FILE_NODE_LOCATION_TYPE.SFTP, title: 'SFTP', featureFlag: 'feature-sftp-integrations-center', hasChevron: true },
    ],
    _b[FILE_NODE_LOCATION_MODE.RETRIEVE_FILE] = [
        {
            locationType: FILE_NODE_LOCATION_TYPE.INTEGRATION_BUCKET,
            title: 'Integration bucket',
            featureFlag: 'feature-integration-bucket',
            hasChevron: false
        },
        {
            locationType: FILE_NODE_LOCATION_TYPE.S3_BUCKET,
            title: 'S3 Bucket',
            featureFlag: 'feature-amazon-s3-integrations-center',
            hasChevron: true
        },
        { locationType: FILE_NODE_LOCATION_TYPE.SFTP, title: 'SFTP', featureFlag: 'feature-sftp-integrations-center', hasChevron: true },
    ],
    _b);
// --------Save file node-----------
export var saveFileLocationProcessTips = (_c = {},
    _c[FILE_NODE_LOCATION_TYPE.DOCUMENT_FOLDER] = 'eim/process/workflow/save-file-document-folder',
    _c[FILE_NODE_LOCATION_TYPE.OBJECT_ATTACHMENTS] = 'eim/process/workflow/save-file-attachments',
    _c[FILE_NODE_LOCATION_TYPE.FILE_UPLOAD_FIELD] = 'eim/process/workflow/save-file-file-upload-field',
    _c[FILE_NODE_LOCATION_TYPE.INTEGRATION_BUCKET] = 'eim/process/workflow/save-file-external',
    _c[FILE_NODE_LOCATION_TYPE.S3_BUCKET] = 'dummy',
    _c[FILE_NODE_LOCATION_TYPE.SFTP] = 'dummy',
    _c);
// --------Retrieve file node---------
export var retrieveFileLocationProcessTips = (_d = {},
    _d[FILE_NODE_LOCATION_TYPE.DOCUMENT_FOLDER] = 'dummy',
    _d[FILE_NODE_LOCATION_TYPE.OBJECT_ATTACHMENTS] = 'dummy',
    _d[FILE_NODE_LOCATION_TYPE.FILE_UPLOAD_FIELD] = 'dummy',
    _d[FILE_NODE_LOCATION_TYPE.INTEGRATION_BUCKET] = 'eim/process/workflow/retrieve-file-external',
    _d[FILE_NODE_LOCATION_TYPE.S3_BUCKET] = 'eim/process/workflow/retrieve-file-external',
    _d[FILE_NODE_LOCATION_TYPE.SFTP] = 'eim/process/workflow/retrieve-file-external',
    _d);
