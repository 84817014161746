<div [class.side-sheet-list-item-outer-container]="showHoverState">
  <div [attr.data-test]="value ? 'selected' : null"
    class="side-sheet-list-item-container d-flex flex-row align-items-stretch justify-content-start mode-{{toggleType}}"
    data-test="side-sheet-list-item-container"
    [ngClass]="{'list-item-disabled': disabled, 'border-bottom-0': hideUnderline}" >
    <div *ngIf="toggleType !== 'no-toggle'"
        class="d-flex flex-row align-items-center"
        [ngClass]="customCheckboxClass"
        data-test="side-sheet-list-item-clickable"
        (click)="onClick($event)">
      <div class="list-item-control">
        <div *ngIf="toggleType === 'single-check'" class="list-item-single-check icon">
          <mat-icon *ngIf="value" class="eim-icon_sm" svgIcon="radio-button-checked" data-test="radio-button-checked"></mat-icon>
          <mat-icon *ngIf="!value" class="eim-icon_sm" svgIcon="radio-button-unchecked"></mat-icon>
        </div>
        <div *ngIf="toggleType === 'multi-check'" class="list-item-multi-check icon">
          <mat-icon *ngIf="value" svgIcon="checkbox" class="eim-color_icon eim-icon_sm" data-test="side-sheet-list-item-checked"></mat-icon>
          <mat-icon *ngIf="!value" svgIcon="checkbox-outline" class="eim-color_icon eim-icon_sm"></mat-icon>
        </div>
        <div *ngIf="toggleType === 'drag'" class="list-item-drag handle">
          <mat-icon svgIcon="move-item" class="eim-icon_handle drag-icon"></mat-icon>
        </div>
        <div *ngIf="toggleType === 'number'" class="list-item-number">
          <div class="list-item-number-inner" [ngClass]="{'list-item-number-more-than-0': value !== undefined ? value > 0 : false}">{{value}}</div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row align-items-center flex-grow-1 list-item-content right-content">
      <div (click)="onClick($event)" class="list-item-main-content flex-grow-1" data-test="side-sheet-list-item-text">
        <ng-content></ng-content>
      </div>
      <mat-icon (click)="onMoreInfo()" *ngIf="moreInfo.observers.length > 0 && showMoreInfo !== false" class="eim-icon_info-list-item no-shrink" svgIcon="info"></mat-icon>
      <mat-icon (click)="onClick($event)" *ngIf="hasChevron" class="eim-icon_chevron-right-list-item no-shrink" svgIcon="chevron"></mat-icon>
      <mat-icon (click)="onNavAway($event)" *ngIf="allowNavAway" svgIcon="launch" class="eim-color_icon eim-icon_sm ml-3 no-shrink"></mat-icon>
      <span *ngIf="hasToolSection" class="tool-icon">
        <mat-icon (click)="onClickToolSection($event)" svgIcon="admin-tool" class="flex-shrink-0 eim-font-color_nfc-on-white-buttons"></mat-icon>
      </span>   
    </div>
  </div>
</div>
