import { Injectable } from '@angular/core';
import { ObjectService } from '../../data/object-service/object.service';
import { Batch, Formula, Tip } from '../../data/models/types';
import {
  DATA_SOURCE_TYPES,
  flatMapScheme,
  GEOMETRY_TYPES,
  IFlatMap,
  IFlatMapExtent,
  IMap,
  IMapDataSource,
  IMapExtent,
  IMapLayer,
  IMapStyle,
  mapDataSourceScheme,
  mapExtentScheme,
  mapLayerScheme,
  MapLike,
  mapScheme,
  mapStyleScheme
} from '../../models/map';
import { combineLatest, Observable, of } from 'rxjs';
import { first, map, switchMap, catchError } from 'rxjs/operators';
import dataConstants from '../../data/constants';
import { DEFAULT_DIMENSION, QueryService } from '../../data/query.service';
import { MODULE_POLICY_TYPE, ModuleService } from '../modules/module.service';
import { cloneDeep, merge } from 'lodash';
import { DEFAULT_EXTENT_TIP } from '../../models/system-settings';
import { QuoteString } from '../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { getModuleTitleFormula } from '../modules/module.constant';
import { IQuery, queryScheme } from '../../models/query';
import { ModulePackageService } from '../modules/module-package.service';

export interface IMapCommonQueryResults {
  $tip: Tip;
  name: string;
}

export interface ILayerQueryResults extends IMapCommonQueryResults {
  dataSourceType: DATA_SOURCE_TYPES;
  isBaseMap: string; // need to cast this to boolean
}

export interface IMapQueryResults extends IMapCommonQueryResults {
  layerCount: string; // need to cast this to number
}

const securityFormula = 'EQUALS(SECURITY(), "app/security-policy/instance-user-runtime")';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  constructor(
    private objectService: ObjectService,
    private queryService: QueryService,
    private moduleService: ModuleService,
    private modulePackageService: ModulePackageService
  ) {
  }

  /************************* Map data source *****************************/

  getDataSources(moduleTip?: Tip): Observable<IMapDataSource[]> {
    const queryOptions = {
      extraAttributes: [
        { label: 'name', formula: 'FIELD("app/map/source:name")' },
        { label: 'type', formula: 'FIELD("app/map/source:type")' }
      ],
      extraFilters: [
        { label: 'Map source type filter', formula: 'ISTYPE("app/map/source")' },
        { label: 'Is user created', formula: `NOT(${ securityFormula })` }
      ],
      dimensionOptions: [merge(cloneDeep(DEFAULT_DIMENSION[0]), {
        sortby: ['FIELD("app/map/source:name")'],
        sortdir: ['asc']
      })]
    };
    if (moduleTip) {
      queryOptions.extraFilters.push(
        {
          label: 'In module data source field',
          formula: `INARRAY(REFERENCES("app/module:map-data-sources", TIP()), ${ QuoteString(moduleTip) })`
        }
      );
    } else {
      queryOptions.extraAttributes.push({ label: 'moduleName', formula: getModuleTitleFormula('app/map/source') });
    }

    return this.queryService.execute1dFirst<IMapDataSource>(
      'eim/query/generic-query',
      queryOptions
    );
  }

  getDataSource(dataSourceTip: Tip): Observable<IMapDataSource> {
    return this.objectService.getObject<IMapDataSource>(dataSourceTip, mapDataSourceScheme);
  }

  setDataSource(dataSource: IMapDataSource, moduleTip?: Tip): Observable<boolean> {
    return this.objectService.setObject(
      dataSource,
      mapDataSourceScheme
    ).pipe(map(() => true),
      catchError(() => {
        return this.modulePackageService.handleError('save data source', moduleTip);
      })
    );
  }

  deleteDataSource(dataSource: IMapDataSource, moduleTip?: Tip): Observable<never | unknown> {
    return this.moduleService.deleteModuleConfig([dataSource.$tip]).pipe(
      catchError(() => {
        return this.modulePackageService.handleError('delete data source', moduleTip);
      })
      );
  }

  createDataSource(dataSource: IMapDataSource, moduleTip: Tip): Observable<boolean> {
    return this.moduleService
      .getModulePolicy(moduleTip, MODULE_POLICY_TYPE.INSTANCE)
      .pipe(
        first(),
        switchMap((instancePolicy: Tip) => {
          return this.objectService
            .setObject(
              dataSource,
              mapDataSourceScheme,
              dataConstants.BRANCH_MASTER,
              instancePolicy || 'app/security-policy/instance-user-admin' // -!-!-!- DELETE BACKUP OPTION AFTER MIGRATION - EIM-2691 -!-!-!-
            )
            .pipe(first());
        }),
        switchMap((batch: Batch) => {
          const tip = batch[batch.length - 1].tip;
          return this.moduleService
            .addTipToFlatModuleField({
              moduleTip,
              moduleField: 'mapDataSources',
              tip
            });
        }),
        catchError(() => {
          return this.modulePackageService.handleError('save data source', moduleTip);
        })
      );
  }

  newDataSource(type: DATA_SOURCE_TYPES): IMapDataSource {
    return {
      $type: 'app/map/source',
      name: '',
      type,
      config: {}
    };
  }

  getCriteriaFromQueryTip(queryTip: Tip): Observable<Formula | null> {
    return this.objectService.getObject<IQuery>(queryTip, queryScheme).pipe(
      map(query => {
        const filterFormulas = query.filters ? query.filters.map(filter => filter.formula) : [];
        switch (filterFormulas.length) {
          case 0: return null;
          case 1: return filterFormulas[0];
          default: return `AND(${filterFormulas.join(',')})`;
        }
      })
    );
  }

  /************************* Map layer *****************************/

  getLayers(moduleTip?: Tip, notBaseMap?: boolean): Observable<ILayerQueryResults[]> {
    const queryOptions = {
      extraAttributes: [
        { label: 'name', formula: 'FIELD("app/map/layer:name")' },
        { label: 'dataSourceType', formula: 'FIELD("app/map/source:type", FIELD("app/map/layer:data-source"))' },
        { label: 'isBaseMap', formula: 'FIELD("app/map/layer:is-base-map")' }
      ],
      extraFilters: [
        { label: 'Map layer type filter', formula: 'ISTYPE("app/map/layer")' },
        { label: 'Is user created', formula: `NOT(${ securityFormula })` }
      ],
      dimensionOptions: [merge(cloneDeep(DEFAULT_DIMENSION[0]), {
        sortby: ['FIELD("app/map/source:type", FIELD("app/map/layer:data-source"))', 'FIELD("app/map/layer:name")'],
        sortdir: ['asc', 'asc']
      })]
    };
    if (moduleTip) {
      queryOptions.extraFilters.push(
        { label: 'In module layer field', formula: `INARRAY(REFERENCES("app/module:map-layers", TIP()), ${ QuoteString(moduleTip) })` }
      );
    } else {
        queryOptions.extraAttributes.push({ label: 'moduleName', formula: getModuleTitleFormula('app/map/layer') });
    }
    if (notBaseMap) {
      queryOptions.extraFilters.push(
        { label: 'Is not base map', formula: 'NOT(EQUALS(FIELD("app/map/layer:is-base-map"), "true"))' }
      );
    }
    return this.queryService.execute1dFirst<ILayerQueryResults>(
      'eim/query/generic-query',
      queryOptions
    );
  }

  getLayer(layerTip: Tip): Observable<IMapLayer> {
    return this.objectService.getObject<IMapLayer>(layerTip, mapLayerScheme);
  }

  setLayer(layer: IMapLayer, moduleTip?: Tip): Observable<boolean> {
    return this.objectService.setObject(
      layer,
      mapLayerScheme
    ).pipe(map(() => true),
      catchError(() => {
        return this.modulePackageService.handleError('save layer', moduleTip);
      })
    );
  }

  deleteLayer(layer: IMapLayer, moduleTip?: Tip): Observable<never | unknown> {
    return this.moduleService.deleteModuleConfig([layer.$tip]).pipe(
      catchError(() => {
        return this.modulePackageService.handleError('delete layer', moduleTip);
      })
      );
  }

  createLayer(layer: IMapLayer, moduleTip: Tip): Observable<boolean> {
    return this.moduleService
      .getModulePolicy(moduleTip, MODULE_POLICY_TYPE.INSTANCE)
      .pipe(
        first(),
        switchMap((instancePolicy: Tip) => {
          return this.objectService
            .setObject(
              layer,
              mapLayerScheme,
              dataConstants.BRANCH_MASTER,
              instancePolicy || 'app/security-policy/instance-user-admin' // -!-!-!- DELETE BACKUP OPTION AFTER MIGRATION - EIM-2691 -!-!-!-
            )
            .pipe(first());
        }),
        switchMap((batch: Batch) => {
          const tip = batch[batch.length - 1].tip;
          return this.moduleService
            .addTipToFlatModuleField({
              moduleTip,
              moduleField: 'mapLayers',
              tip
            });
        }),
        catchError(() => {
          return this.modulePackageService.handleError('save layer', moduleTip);
        })
      );
  }

  newLayer(dataSourceTip: Tip): Observable<IMapLayer> {
    return this.getDataSource(dataSourceTip).pipe(
      first(),
      map((dataSource: IMapDataSource) => ({
        $type: 'app/map/layer',
        name: '',
        dataSource,
        isBaseMap: false,
        geometryType: null,
        styleType: null,
        style: null,
        heatmapRadius: 0,
        hasMinZoom: false,
        hasMaxZoom: false,
        minZoomLevel: 0,
        maxZoomLevel: 23,
        opacity: 80,
        visibleProperties: []
      } as IMapLayer))
    );
  }

  /************************* Map *****************************/

  getMaps(moduleTip?: Tip): Observable<IMapQueryResults[]> {
    const queryOptions = {
      extraAttributes: [
        { label: 'name', formula: 'FIELD("app/map:name")' },
        { label: 'layerCount', formula: 'COUNT(FIELD("app/map:layers"))' }
      ],
      extraFilters: [
        { label: 'Map type filter', formula: 'ISTYPE("app/map")' },
        { label: 'Is user created', formula: `NOT(${ securityFormula })` }
      ],
      dimensionOptions: [merge(cloneDeep(DEFAULT_DIMENSION[0]), {
        sortby: ['FIELD("app/map:name")'],
        sortdir: ['asc']
      })]
    };
    if (moduleTip) {
      queryOptions.extraFilters.push(
        { label: 'In module map field', formula: `INARRAY(REFERENCES("app/module:maps", TIP()), ${ QuoteString(moduleTip) })` }
      );
    }
    return this.queryService.execute1dFirst<IMapQueryResults>(
      'eim/query/generic-query',
      queryOptions
    );
  }

  getMap(mapInput: MapLike): Observable<IMap> {
    if (this.isMapTip(mapInput)) {
      return this.getMapFromTip(mapInput);
    } else if (this.isFlatMap(mapInput)) {
      return this.getMapFromFlatMap(mapInput);
    } else if (this.isMap(mapInput)) {
      return of(mapInput);
    }
  }

  isMapTip(mapInput: MapLike): mapInput is Tip {
    return (mapInput as IFlatMap).name === undefined;
  }

  isFlatMap(mapInput: MapLike): mapInput is IFlatMap {
    return (mapInput as IMap).baseMap.name === undefined;
  }

  isMap(mapInput: MapLike): mapInput is IMap {
    return (mapInput as IMap).baseMap.name !== undefined;
  }

  getMapFromTip(mapTip: Tip): Observable<IMap> {
    return this.objectService.getObject<IMap>(mapTip, mapScheme);
  }

  getMapFromFlatMap(flatMap: IFlatMap): Observable<IMap> {
    const observables = [
      this.getLayer(flatMap.baseMap).pipe(first()),
      ...flatMap.layers.map(layer => this.getLayer(layer).pipe(first()))
    ];
    if (flatMap.extent.layer) {
      flatMap.extent.layer.forEach(layer => observables.push(this.getLayer(layer).pipe(first())));
    }
    return combineLatest(observables).pipe(
      switchMap((layers: IMapLayer[]) => {
        const baseMap = layers.shift();
        const extentLayer = flatMap.extent.layer ? layers.splice(flatMap.layers.length, flatMap.extent.layer.length) : undefined;
        return of({
          name: flatMap.name,
          baseMap,
          layers,
          extent: {
            type: flatMap.extent.type,
            layer: extentLayer,
            polygon: flatMap.extent.polygon,
            zoomLevel: flatMap.extent.zoomLevel
          } as IMapExtent
        } as IMap);
      })
    );
  }

  getFlatMap(mapTip: Tip): Observable<IFlatMap> {
    return this.objectService.getObject<IFlatMap>(mapTip, flatMapScheme);
  }

  setFlatMap(mapObj: IFlatMap, moduleTip?: Tip): Observable<boolean> {
    return this.objectService.setObject(
      mapObj,
      flatMapScheme
    ).pipe(map(() => true),
      catchError(() => {
        return this.modulePackageService.handleError('save flat map', moduleTip);
      })
    );
  }

  deleteMap(mapObj: IMap | IFlatMap, moduleTip?: Tip): Observable<never | unknown> {
    return this.moduleService.deleteModuleConfig([mapObj.$tip]).pipe(
        catchError(() => {
          return this.modulePackageService.handleError('delete flat map', moduleTip);
        })
      );
  }

  createFlatMap(mapObj: IFlatMap, moduleTip: Tip): Observable<boolean> {
    return this.moduleService
      .getModulePolicy(moduleTip, MODULE_POLICY_TYPE.INSTANCE)
      .pipe(
        first(),
        switchMap((instancePolicy: Tip) => {
          return this.objectService
            .setObject(
              mapObj,
              flatMapScheme,
              dataConstants.BRANCH_MASTER,
              instancePolicy || 'app/security-policy/instance-user-admin' // -!-!-!- DELETE BACKUP OPTION AFTER MIGRATION - EIM-2691 -!-!-!-
            )
            .pipe(first());
        }),
        switchMap((batch: Batch) => {
          const tip = batch[batch.length - 1].tip;
          return this.moduleService
            .addTipToFlatModuleField({
              moduleTip,
              moduleField: 'maps',
              tip
            });
        }),
        catchError(() => {
          return this.modulePackageService.handleError('save flat map', moduleTip);
        })
      );
  }

  newFlatMap(): IFlatMap {
    return {
      $type: 'app/map',
      name: '',
      baseMap: null,
      layers: [],
      extent: {
        $type: 'app/map/extent',
        type: null
      } as IFlatMapExtent
    };
  }

  /************************* Map extent *****************************/
  getDefaultExtent(): Observable<IMapExtent> {
    return this.objectService.getObject<IMapExtent>(DEFAULT_EXTENT_TIP, mapExtentScheme);
  }


  /************************* Base map *****************************/

  getBaseMaps(stockBaseMap?: boolean): Observable<IMapCommonQueryResults[]> {
    const queryOptions = {
      extraAttributes: [
        { label: 'name', formula: 'FIELD("app/map/layer:name")' }
      ],
      extraFilters: [
        { label: 'Map layer type filter', formula: 'ISTYPE("app/map/layer")' },
        { label: 'Is base map', formula: 'EQUALS(FIELD("app/map/layer:is-base-map"), "true")' },
      ],
      dimensionOptions: [merge(cloneDeep(DEFAULT_DIMENSION[0]), {
        sortby: ['FIELD("app/map/layer:name")'],
        sortdir: ['asc']
      })]
    };
    if (stockBaseMap) {
      queryOptions.extraFilters.push(
        { label: 'Is noggin shipped', formula: securityFormula }
      );
    } else {
      queryOptions.extraFilters.push(
        { label: 'Is user created', formula: `NOT(${ securityFormula })` }
      );
    }
    return this.queryService.execute1dFirst<IMapCommonQueryResults>(
      'eim/query/generic-query',
      queryOptions
    );
  }

  /************************* Map style *****************************/

  getStyles(geometryType: GEOMETRY_TYPES): Observable<IMapCommonQueryResults[]> {
    const queryOptions = {
      extraAttributes: [
        { label: 'name', formula: 'FIELD("app/map/style:name")' }
      ],
      extraFilters: [
        { label: 'Map style type filter', formula: 'ISTYPE("app/map/style")' },
        { label: 'Is geometry type', formula: `EQUALS(FIELD("app/map/style:type"), ${ QuoteString(geometryType) })` }
      ],
      dimensionOptions: [merge(cloneDeep(DEFAULT_DIMENSION[0]), {
        sortby: ['FIELD("app/map/style:name")'],
        sortdir: ['asc']
      })]
    };

    return this.queryService.execute1dFirst<IMapCommonQueryResults>(
      'eim/query/generic-query',
      queryOptions
    );
  }

  getStyle(styleTip: Tip): Observable<IMapStyle> {
    return this.objectService.getObject<IMapStyle>(styleTip, mapStyleScheme);
  }

  setStyle(styleObj: IMapStyle, moduleTip?: Tip): Observable<Tip> {
    return this.objectService.setObject(
      styleObj,
      mapStyleScheme,
      dataConstants.BRANCH_MASTER,
      'app/security-policy/instance-user-admin'
    ).pipe(map((batch: Batch) => batch[batch.length - 1].tip),
      catchError(() => {
        return this.modulePackageService.handleError('save style', moduleTip);
      })
    );
  }

  newStyle(geometryType: GEOMETRY_TYPES): IMapStyle {
    return {
      $type: 'app/map/style',
      name: '',
      type: geometryType,
      config: null
    };
  }

  deleteStyle(styleObj: IMapStyle, moduleTip?: Tip): Observable<never | unknown> {
    return this.moduleService.deleteModuleConfig([styleObj.$tip]).pipe(
      catchError(() => {
        return this.modulePackageService.handleError('delete style', moduleTip);
      })
      );
  }
}
