import * as tslib_1 from "tslib";
import { Observable, of, ReplaySubject } from 'rxjs';
import { first, switchMap, map, takeUntil, filter } from 'rxjs/operators';
import { workflowRuntimeScheme } from '../../../../models/workflow-runtime';
import { Stringify } from '../../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import * as i0 from "@angular/core";
import * as i1 from "../../../../data/process.service";
import * as i2 from "../../../../shell/services/me.service";
import * as i3 from "../../../../data/formula.service";
import * as i4 from "../../../../data/object-service/object.service";
var NS = 'WorkflowEndUserService';
export var NON_FIELD_FORM_TYPES = ['section-header', 'chart-content', 'text-content', 'table-content'];
/**
 * Note that the dynamic outcomes are forced to camel case, so that they never collide with the UX_NODE_OUTCOME values.
 */
export var UX_NODE_OUTCOME;
(function (UX_NODE_OUTCOME) {
    UX_NODE_OUTCOME["primary"] = "Primary";
    UX_NODE_OUTCOME["secondary"] = "Secondary";
    UX_NODE_OUTCOME["noop"] = "Noop"; // does nothing. Used as default for switch process
})(UX_NODE_OUTCOME || (UX_NODE_OUTCOME = {}));
var WorkflowEndUserService = /** @class */ (function () {
    function WorkflowEndUserService(processService, meService, formulaService, objectService) {
        this.processService = processService;
        this.meService = meService;
        this.formulaService = formulaService;
        this.objectService = objectService;
        this.newUX$ = new Observable();
        this.newOutcome$ = new Observable();
        this.newWorkflowRuntime$ = new Observable();
    }
    WorkflowEndUserService.prototype.watchWorkflowRuntime = function (workflowRuntimeTip, cancel$) {
        var _this = this;
        this.newWorkflowRuntimeSubject$ = new ReplaySubject(1);
        this.newWorkflowRuntime$ = this.newWorkflowRuntimeSubject$.asObservable();
        this.newUX$ = this.newWorkflowRuntimeSubject$.pipe(switchMap(function (_a) {
            var ux = _a.ux;
            return _this.getWorkflowUXItem(ux);
        }), filter(Boolean));
        this.newOutcome$ = this.newWorkflowRuntimeSubject$.pipe(map(function (_a) {
            var outcome = _a.outcome;
            return outcome;
        }), filter(Boolean));
        this.objectService
            .getObject(workflowRuntimeTip, workflowRuntimeScheme)
            .pipe(takeUntil(cancel$))
            // .pollObject<IWorkflowRuntime>({ tip: workflowRuntimeTip, scheme: workflowRuntimeScheme, interval: 1000, cancel$})
            .subscribe(function (workflowRuntime) { return _this.newWorkflowRuntimeSubject$.next(workflowRuntime); });
    };
    WorkflowEndUserService.prototype.runUXNodeOutcomeProcess = function (workflowRuntimeTip, outcome, objectTip, isGoverned) {
        return this.processService.start('eim/process/workflow-ux/set-outcome-reference', {
            'Workflow runtime tip': [workflowRuntimeTip],
            Outcome: [outcome],
            'Object tip': objectTip ? [objectTip] : [],
            'Is governed': [isGoverned ? 'true' : 'false']
        }).pipe(first(function (processResponse) { return processResponse.finished; }));
    };
    WorkflowEndUserService.prototype.getWorkflowUXItem = function (workflowUXItems) {
        var _this = this;
        if (!workflowUXItems || !workflowUXItems.length) {
            return of(null);
        }
        return this.checkForMatchingActors(workflowUXItems)
            .pipe(map(function (matchResult) { return _this.getFirstValidWorkflowUXItem({ matchResult: matchResult, workflowUXItems: workflowUXItems }); }));
    };
    /**
     * Gets the first Workflow UX rule object that resolves to true
     */
    WorkflowEndUserService.prototype.getFirstValidWorkflowUXItem = function (_a) {
        var _this = this;
        var matchResult = _a.matchResult, workflowUXItems = _a.workflowUXItems;
        var workflowUXItem;
        matchResult.some(function (isSuccessMatch, i) {
            if (isSuccessMatch) {
                workflowUXItem = workflowUXItems[i];
                _this.convertEmptyArraysToNull(workflowUXItem);
            }
            return isSuccessMatch;
        });
        return workflowUXItem || null;
    };
    /**
     * Converts any listed string values that have come through as empty arrays, into null
     */
    WorkflowEndUserService.prototype.convertEmptyArraysToNull = function (workflowUXItem) {
        for (var propName in workflowUXItem) {
            if (workflowUXItem[propName]) {
                var prop = workflowUXItem[propName];
                switch (propName) {
                    case 'primaryButtonLabel':
                    case 'instructionsSubtitle':
                    case 'instructionsTitle':
                    case 'instructionsIntent':
                        workflowUXItem[propName] = Array.isArray(prop) && !prop.length ? null : prop;
                        break;
                }
            }
        }
    };
    /**
     * Checks if any UX rules have actors that match the current user by 'user', 'profile' or 'team'.
     * Resolves to array of string booleans (eg ['true', 'false']) in the same order as the workflowUXItems.
     * true means matches
     */
    WorkflowEndUserService.prototype.checkForMatchingActors = function (workflowUXItems) {
        var _this = this;
        var getFormula = function (currentUser) { return Stringify({
            name: 'ARRAY',
            args: workflowUXItems.map(function (item) {
                return !item.actors || !item.actors.length ?
                    'true' :
                    {
                        name: 'NOT',
                        args: [{
                                name: 'EQUALS',
                                args: [
                                    {
                                        name: 'INTERSECT',
                                        args: [
                                            {
                                                name: 'UNIQUE',
                                                args: [
                                                    {
                                                        name: 'ARRAY',
                                                        args: tslib_1.__spread([
                                                            currentUser.$tip
                                                        ], currentUser.profiles, [
                                                            { name: 'REFERENCES', args: ['app/team-role:users', currentUser.$tip] }
                                                        ])
                                                    }
                                                ]
                                            },
                                            { name: 'ARRAY', args: item.actors }
                                        ]
                                    },
                                    { name: 'ARRAY', args: [] }
                                ]
                            }]
                    };
            })
        }); };
        return this
            .meService
            .me$
            .pipe(filter(Boolean), first(), switchMap(function (currentUser) { return _this.formulaService.evaluate(getFormula(currentUser)); }), map(function (matches) { return matches.map(function (s) { return s === 'true'; }); }));
    };
    /**
     * Cycles through form fields to look for potential errors
     */
    WorkflowEndUserService.prototype.checkForErrors = function (form) {
        if (!form) {
            return [];
        }
        var errList = [];
        this.getFields(form).forEach(function (field) {
            var opts = field.templateOptions;
            // NON_FIELD_FORM_TYPES are exempt from needing a contextTypeTip
            if (!opts || (!opts.contextTypeTip && !NON_FIELD_FORM_TYPES.includes(field.type))) {
                errList.push({
                    field: field, label: opts ? opts.label : '(unknown)'
                });
            }
        });
        return errList;
    };
    WorkflowEndUserService.prototype.getFields = function (form) {
        var fields = [];
        form.tabs.forEach(function (tab) {
            tab.rows.forEach(function (row) {
                row.fields.forEach(function (field) {
                    fields.push(field);
                });
            });
        });
        return fields;
    };
    WorkflowEndUserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WorkflowEndUserService_Factory() { return new WorkflowEndUserService(i0.ɵɵinject(i1.ProcessService), i0.ɵɵinject(i2.MeService), i0.ɵɵinject(i3.FormulaService), i0.ɵɵinject(i4.ObjectService)); }, token: WorkflowEndUserService, providedIn: "root" });
    return WorkflowEndUserService;
}());
export { WorkflowEndUserService };
