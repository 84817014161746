import { Formula } from '../data/models/types';
import { get } from 'lodash';
import { Parser } from '../object/field-formula-side-sheet/field-formula-side-sheet/formula';

export function getCommonFieldFormulaFieldName(formula: Formula): string {
  // if (formulaSpec.args.length
  //   && formulaSpec.name === 'LAST'
  //   && (formulaSpec.args[0] as FormulaSpec).args[0] === 'COMMON_FIELD_FORMULA') {
  //   const arrayFormula = formulaSpec.args[0] as FormulaSpec;
  //   const coalesceFormula = arrayFormula.args[2] as FormulaSpec;
  //   const fieldNameFormula = coalesceFormula.args[0];
  //   return of((fieldNameFormula as FormulaSpec).args[0] as string);
  // }

  if (isCommonFieldFormula(formula)) {
    const formulaSpec = Parser(formula);
    return get(formulaSpec, 'args[0].args[2].args[0].args[0]') as string;
  }

  return '';
}

export function isCommonFieldFormula(formula: Formula): boolean {
  const formulaSpec = Parser(formula);
  return formulaSpec.name === 'LAST' && get(formulaSpec, 'args[0].args[0]') === 'COMMON_FIELD_FORMULA';
}
