import { Component, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { debounce, debounceTime, distinctUntilChanged, distinct } from 'rxjs/operators';

@Component({
  selector: 'app-color-picker-formly',
  template: `
    <app-color-picker-field
      [formControl]="formControl"
      [label]="to.label"
      [required]="to.requiredrequired"
      [optional]="to.optional"
      [readonly]="to.readonly"
      [options]="to.options"
      [allowCustomColors]="to.allowCustomColors"
      (addColor)="onColorAdded($event)"
    ></app-color-picker-field>`
})
export class FormlyFieldColorPickerComponent extends FieldType {
  onColorAdded($event) {
    this.formControl.updateValueAndValidity();
  }
}
