import { NgZone, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { SHA256 } from 'crypto-js';
import { isFunction } from 'lodash';
import * as io from 'socket.io-client';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "./session-manager.service";
export var ESessionMessageDataType;
(function (ESessionMessageDataType) {
    ESessionMessageDataType["tipWatch"] = "ENO_WATCH";
    ESessionMessageDataType["criteriaWatch"] = "CRITERIA_WATCH";
    ESessionMessageDataType["queryWatch"] = "QUERY_WATCH";
    ESessionMessageDataType["formulaWatch"] = "FORMULA_WATCH";
    ESessionMessageDataType["processResponse"] = "PROCESS_RESPONSE";
})(ESessionMessageDataType || (ESessionMessageDataType = {}));
var PubSubService = /** @class */ (function () {
    function PubSubService(_sessionManagerService, ngZone) {
        this._sessionManagerService = _sessionManagerService;
        this.ngZone = ngZone;
        this._initSocket();
        this._initSessionMessage();
        this._initMessageReceiver();
        this._socketDisconnected = false;
    }
    PubSubService._isSessionMessage = function (message) {
        return message.topic.startsWith('session-');
    };
    PubSubService._generateSessionMessageTopic = function (sessionToken) {
        return "session-" + SHA256(sessionToken).toString();
    };
    PubSubService.prototype._initSocket = function () {
        var _this = this;
        this.ngZone.runOutsideAngular(function () {
            // uncomment this when you are connecting your front-end to a dev, test or prod instance
            // to proxy pub-sub socket.io, see proxy.conf.json
            // this._socket = io('localhost:4200', { path: environment.pubSubPath + '/socket.io/' });
            _this._socket = io(environment.host, { path: environment.pubSubPath + '/socket.io/' });
            _this._socket.on('connect', function (msg) {
                if (_this._socketDisconnected === true) {
                    // if the original connection was lost, we need to reconnect our listener
                    _this._socket.emit('subscribe', { topic: _this._SessionMessageTopic });
                    _this._socketDisconnected = false;
                }
            });
            _this._socket.on('disconnect', function (msg) {
                _this._socketDisconnected = true;
            });
        });
    };
    PubSubService.prototype._initSessionMessage = function () {
        var _this = this;
        this._sessionMessageSubject = new Subject();
        this._sessionManagerService.getSessionInfo$().pipe(first(function (sessionInfo) { return !!sessionInfo.token; })).subscribe(function (sessionInfo) {
            _this._SessionMessageTopic = PubSubService._generateSessionMessageTopic(sessionInfo.token);
            _this._socket.emit('subscribe', { topic: _this._SessionMessageTopic });
        });
    };
    PubSubService.prototype._initMessageReceiver = function () {
        var _this = this;
        // needed for protractor please see README.md for more info
        this.ngZone.runOutsideAngular(function () {
            _this._socket.on('receive', function (message) {
                // @todo The message received here could be a different message (an ad hoc message, e.g. chat) than a session message.
                if (PubSubService._isSessionMessage(message)) {
                    _this.ngZone.run(function () {
                        _this._sessionMessageSubject.next(JSON.parse(message.data));
                    });
                }
            });
        });
    };
    PubSubService.prototype.ngOnDestroy = function () {
        if (this._SessionMessageTopic) {
            this._socket.emit('unsubscribe', { topic: this._SessionMessageTopic });
        }
    };
    PubSubService.prototype.receiveSessionMessage = function (type, when) {
        return this._sessionMessageSubject.pipe(filter(function (messageData) { return messageData.type === type; }), filter(function (messageData) { return isFunction(when) ? when(messageData) : true; }));
    };
    // @todo This is not used now. The type of the message data needs to be revisited.
    PubSubService.prototype.publish = function (data) {
        this._socket.emit('publish', { topic: this._SessionMessageTopic, data: JSON.stringify(data) });
    };
    PubSubService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PubSubService_Factory() { return new PubSubService(i0.ɵɵinject(i1.SessionManagerService), i0.ɵɵinject(i0.NgZone)); }, token: PubSubService, providedIn: "root" });
    return PubSubService;
}());
export { PubSubService };
