import * as tslib_1 from "tslib";
import { FieldType } from '@ngx-formly/core';
var FormlyFieldColorPickerComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FormlyFieldColorPickerComponent, _super);
    function FormlyFieldColorPickerComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FormlyFieldColorPickerComponent.prototype.onColorAdded = function ($event) {
        this.formControl.updateValueAndValidity();
    };
    return FormlyFieldColorPickerComponent;
}(FieldType));
export { FormlyFieldColorPickerComponent };
