/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./side-sheet-section.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./side-sheet-section.component";
var styles_SideSheetSectionComponent = [i0.styles];
var RenderType_SideSheetSectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SideSheetSectionComponent, data: {} });
export { RenderType_SideSheetSectionComponent as RenderType_SideSheetSectionComponent };
function View_SideSheetSectionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "side-sheet-section-divider"]], null, null, null, null, null))], null, null); }
function View_SideSheetSectionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "collapse-control icon mdi d-flex flex-column align-items-center"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.invertCollapsedState() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "collapse-control icon mdi d-flex flex-column align-items-center"; var currVal_1 = (_co.collapsed ? "mdi-chevron-right" : "mdi-chevron-down"); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_SideSheetSectionComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "strong", [["data-test", "section-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_SideSheetSectionComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "strong", [["data-test", "section-title"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
function View_SideSheetSectionComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "tally-pill ml-2"]], [[2, "empty-tally-pill", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " selected"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.tallyPillValue < 1); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.tallyPillValue; _ck(_v, 2, 0, currVal_1); }); }
function View_SideSheetSectionComponent_7(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 2), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_SideSheetSectionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "card-header d-flex flex-row align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideSheetSectionComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "title d-flex flex-row align-items-center flex-grow-1"]], [[4, "cursor", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.collapsible && _co.invertCollapsedState()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideSheetSectionComponent_4)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["titleText", 2]], null, 0, null, View_SideSheetSectionComponent_5)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideSheetSectionComponent_6)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideSheetSectionComponent_7)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.collapsible; _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.title; var currVal_3 = i1.ɵnov(_v, 6); _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_4 = _co.tallyPill; _ck(_v, 8, 0, currVal_4); var currVal_5 = (!_co.buttonCollapseWithTitle || !_co.collapsed); _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.collapsible ? "pointer" : "auto"); _ck(_v, 3, 0, currVal_1); }); }
export function View_SideSheetSectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideSheetSectionComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideSheetSectionComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "collapsible-spacer px-3"]], [[4, "display", null]], null, null, null, null)), i1.ɵncd(null, 3)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showBorder; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.showHeader; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.collapsed ? "none" : "block"); _ck(_v, 4, 0, currVal_2); }); }
export function View_SideSheetSectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-side-sheet-section", [], null, null, null, View_SideSheetSectionComponent_0, RenderType_SideSheetSectionComponent)), i1.ɵdid(1, 49152, null, 0, i3.SideSheetSectionComponent, [], null, null)], null, null); }
var SideSheetSectionComponentNgFactory = i1.ɵccf("app-side-sheet-section", i3.SideSheetSectionComponent, View_SideSheetSectionComponent_Host_0, { title: "title", showBorder: "showBorder", collapsible: "collapsible", collapsed: "collapsed", buttonCollapseWithTitle: "buttonCollapseWithTitle", tallyPill: "tallyPill", tallyPillValue: "tallyPillValue", showHeader: "showHeader" }, {}, ["[eim-i18n-property=title]", "[extra-text]", "[buttons]", "*"]);
export { SideSheetSectionComponentNgFactory as SideSheetSectionComponentNgFactory };
