import * as tslib_1 from "tslib";
var InvalidWorkflowNodeDataError = /** @class */ (function (_super) {
    tslib_1.__extends(InvalidWorkflowNodeDataError, _super);
    function InvalidWorkflowNodeDataError(message, data) {
        var _this = _super.call(this, message) || this;
        Object.setPrototypeOf(_this, InvalidWorkflowNodeDataError.prototype);
        _this.data = data;
        _this.name = 'InvalidWorkflowNodeDataError';
        return _this;
    }
    return InvalidWorkflowNodeDataError;
}(Error));
export { InvalidWorkflowNodeDataError };
