import { DataTypes, IObjectScheme } from '../../../data/models/scheme';
import { IFormRow } from '../form.types';
import { IObject } from '../../../data/models/object';
import { IObjectType, objectTypeScheme } from '../../../models/object-type';

const formBase: IObjectScheme = {
  'app/form:name': { type: DataTypes.i18n },
  'app/form:description': { type: DataTypes.i18n },
  'app/form:size': { type: DataTypes.string },
  'app/form:version': { type: DataTypes.number },
  'app/form:tabs': { type: DataTypes.jsonArray },
  'app/form:type': { type: DataTypes.string },
  'app/form:status': { type: DataTypes.string },
  'app/form:security-profile': { type: DataTypes.string },
  'app/form:url': { type: DataTypes.string },
  'app/form:show-form-breadcrumbs': { type: DataTypes.boolean }
};

export const formScheme: IObjectScheme = {
  'app/form:context-type': {
    type: DataTypes.object,
    scheme: objectTypeScheme
  },
  ...formBase
};

export const flatFormScheme: IObjectScheme = {
  'app/form:context-type': { type: DataTypes.string },
  ...formBase
};

export enum FormState {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export interface IFormTab {
  tabName: string;
  rows: IFormRow[];
}

interface IFormBase extends IObject {
  name: string;
  description: string;
  size: string;
  version: number;
  titleOverride?: string;
  tabs: IFormTab[];
  securityProfile?: string;
  status?: string;
}

export interface IFlatForm extends IFormBase {
  contextType: string[];
}

export interface IForm extends IFormBase {
  contextType: IObjectType;
  type?: FormType;
  moduleName?: string;
  showFormBreadcrumbs?: boolean;
}

export enum FormType {
  REGULAR = 'regular',
  PUBLIC = 'public'
}
