import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideSheetListComponent } from './side-sheet-list.component';
import { SpinnerModule } from '../../shared/spinner/spinner.module';
import { DragulaModule } from 'ng2-dragula';

@NgModule({
  declarations: [
    SideSheetListComponent
  ],
  imports: [
    CommonModule,
    SpinnerModule,
    DragulaModule
  ],
  exports: [
    SideSheetListComponent
  ]
})
export class SideSheetListModule { }
