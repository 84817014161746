import * as tslib_1 from "tslib";
import { CompositeNodeConverter } from './CompositeNodeConverter';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import { get } from 'lodash';
import { createConnection, createSetVarNode, createSubProcessNode, createSwitchNode } from './utils/create-node-utils';
import * as i0 from "@angular/core";
var SendVoiceMessageConverterService = /** @class */ (function (_super) {
    tslib_1.__extends(SendVoiceMessageConverterService, _super);
    function SendVoiceMessageConverterService() {
        return _super.call(this) || this;
    }
    SendVoiceMessageConverterService.prototype.convert = function (nodeData, workflow) {
        if (!this.isValid(nodeData)) {
            throw new InvalidWorkflowNodeDataError("Please correct invalid data on send Voice message node", nodeData);
        }
        // var node
        var vars = {
            'Outbound source tip': "ARRAY(\"" + nodeData.fields.outboundSource.$tip + "\")",
            'Dashboard context tip': get(nodeData.fields.outboundSource, 'contextFormula', 'ARRAY()')
        };
        var setVarNode = createSetVarNode(vars, nodeData.tip);
        // sub process node
        var additionalVarKeys = tslib_1.__spread(workflow.inputs.map(function (input) { return input.key; }), workflow.actors.map(function (actor) { return actor.key; }), workflow.variables.map(function (variable) { return variable.key; }));
        var subProcessNode = createSubProcessNode('eim/process/message/send-voice-message', vars);
        subProcessNode.varKeys = tslib_1.__spread(subProcessNode.varKeys, additionalVarKeys); // mutating list with desired options.
        // switch node
        var switchNode = createSwitchNode({ name: 'VAR', args: ['Status'] });
        switchNode.switchCases = ['Sent', 'Failed']; // mutating list with desired options.
        // link from setvar to sub-process
        var doneConnectionForSetVarNode = createConnection('done', subProcessNode);
        setVarNode.connections.push(doneConnectionForSetVarNode);
        // link from sub-process to switch
        var doneConnectionForSubprocessNode = createConnection('done', switchNode);
        subProcessNode.connections.push(doneConnectionForSubprocessNode);
        var nodesAndLink = {
            nodes: [
                setVarNode,
                subProcessNode,
                switchNode
            ],
            links: [
                doneConnectionForSetVarNode,
                doneConnectionForSubprocessNode
            ]
        };
        this.populateOutgoingLinks(nodeData).forEach(function (link) {
            switchNode.connections.push(link);
            nodesAndLink.links.push(link);
        });
        return nodesAndLink;
    };
    SendVoiceMessageConverterService.prototype.isValid = function (nodeData) {
        return !!nodeData.fields.outboundSource.$tip;
    };
    SendVoiceMessageConverterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SendVoiceMessageConverterService_Factory() { return new SendVoiceMessageConverterService(); }, token: SendVoiceMessageConverterService, providedIn: "root" });
    return SendVoiceMessageConverterService;
}(CompositeNodeConverter));
export { SendVoiceMessageConverterService };
