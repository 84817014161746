import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-field-chart-chooser',
  template: `
    <app-chart-chooser
        [formControl]="formControl"
        [contextTypeTip]="to.contextTypeTip"
        [contextTypeTitle]="to.contextTypeTitle">
    </app-chart-chooser>
  `
})
export class FormlyFieldChartChooserComponent extends FieldType {}
