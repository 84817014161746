import * as tslib_1 from "tslib";
import { cloneDeep, merge, isArray, isEqual, union } from 'lodash';
import dataConstants from '../constants';
export { Eno };
var Eno = /** @class */ (function () {
    function Eno(proto) {
        merge(this, cloneDeep(proto));
    }
    Eno.prototype.toJson = function (option) {
        var e_1, _a;
        if (option === void 0) { option = {}; }
        var json = Object.create(null);
        try {
            for (var _b = tslib_1.__values(Object.keys(this)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var property = _c.value;
                if (option && option.propWhiteList && option.propWhiteList.indexOf(property) === -1) {
                    continue;
                }
                json[property] = cloneDeep(this[property]);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return json;
    };
    Eno.prototype.getBranch = function () {
        return this.serverT ?
            this.serverT.branch :
            this.clientT ? this.clientT.branch : null;
    };
    Eno.prototype.getType = function () {
        return this.source ? this.source.type : null;
    };
    Eno.prototype.getSessionId = function () {
        return this.serverT ? this.serverT.session : null;
    };
    Eno.prototype.hasError = function () {
        return this.serverT && this.serverT.error && this.serverT.error.length > 0;
    };
    Eno.prototype.getFieldFormula = function (fieldTip) {
        var field = this.source.field.filter(function (f) { return f.tip === fieldTip; })[0];
        return field && field.formula && field.formula.length > 0 ? field.formula[0] : null;
    };
    // The second argument "lang" is now not used within the app.
    // Left it for potential future use.
    Eno.prototype.getFieldValues = function (fieldTip, lang) {
        if (lang === void 0) { lang = dataConstants.LOCALE_ID; }
        var field = this.source.field.filter(function (f) { return f.tip === fieldTip; })[0];
        if (!field) {
            return [];
        }
        if (field.value) {
            return field.value;
        }
        if (field.formula) {
            return field.formula;
        }
        if (!field.i18n) {
            return [];
        }
        var maybeLang = lang.substr(0, 2);
        var maybeLangIndex = null;
        var defaultLangIndex = null;
        for (var i = 0; i < field.i18n.length; i++) {
            if (field.i18n[i].lang === lang) {
                return field.i18n[i].value;
            }
            if (maybeLangIndex === null && field.i18n[i].lang.substr(0, 2) === maybeLang) {
                maybeLangIndex = i;
            }
            else if (defaultLangIndex === null && field.i18n[i].lang === dataConstants.LANG_DEFAULT) {
                defaultLangIndex = i;
            }
        }
        if (maybeLangIndex !== null) {
            return field.i18n[maybeLangIndex].value;
        }
        if (defaultLangIndex !== null) {
            return field.i18n[defaultLangIndex].value;
        }
        return [];
    };
    Eno.prototype.getFieldRawI18n = function (fieldTip) {
        var field = this.source.field.filter(function (f) { return f.tip === fieldTip; })[0];
        if (isArray(field.i18n)) {
            return tslib_1.__spread(field.i18n);
        }
        if (isArray(field.value)) {
            return [{ lang: dataConstants.LANG_DEFAULT, value: field.value }];
        }
        return [];
    };
    // The second argument "lang" is now not used within the app.
    // Left it for potential future use.
    Eno.prototype.getFieldStringValue = function (fieldTip, lang) {
        if (lang === void 0) { lang = dataConstants.LOCALE_ID; }
        return this.getFieldValues(fieldTip, lang).join(', ') || null;
    };
    // The second argument "lang" is now not used within the app.
    // Left it for potential future use.
    Eno.prototype.getFieldNumberValue = function (fieldTip, lang) {
        if (lang === void 0) { lang = dataConstants.LOCALE_ID; }
        return parseFloat(this.getFieldValues(fieldTip, lang)[0]) || null;
    };
    // The second argument "lang" is now not used within the app.
    // Left it for potential future use.
    Eno.prototype.getFieldBooleanValue = function (fieldTip, lang) {
        if (lang === void 0) { lang = dataConstants.LOCALE_ID; }
        var fieldValues = this.getFieldValues(fieldTip, lang);
        return fieldValues && fieldValues.filter(function (value) { return value !== 'true'; }).length === 0;
    };
    // The second argument "lang" is now not used within the app.
    // Left it for potential future use.
    Eno.prototype.getFieldJsonValue = function (fieldTip, lang) {
        if (lang === void 0) { lang = dataConstants.LOCALE_ID; }
        var value = this.getFieldValues(fieldTip, lang);
        if (value && value.length > 0) {
            return JSON.parse(value[0]);
        }
        return null;
    };
    // Do deep comparison of this eno and the given eno to check if anything is different.
    // Note that this does not compare nonce and parent as this is for comparing content
    Eno.prototype.isContentDiff = function (eno) {
        return this.source.deleted !== eno.source.deleted ||
            this.source.type !== eno.source.type ||
            this.source.security !== eno.source.security ||
            this._isFieldDiff(eno);
    };
    Eno.prototype._isFieldDiff = function (eno) {
        var e_2, _a;
        var allFieldTips = union(this.source.field.map(function (iField) { return iField.tip; }), eno.source.field.map(function (iField) { return iField.tip; }));
        try {
            for (var allFieldTips_1 = tslib_1.__values(allFieldTips), allFieldTips_1_1 = allFieldTips_1.next(); !allFieldTips_1_1.done; allFieldTips_1_1 = allFieldTips_1.next()) {
                var fieldTip = allFieldTips_1_1.value;
                var isDiff = !isEqual(this.getFieldValues(fieldTip), eno.getFieldValues(fieldTip));
                if (isDiff) {
                    return true;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (allFieldTips_1_1 && !allFieldTips_1_1.done && (_a = allFieldTips_1.return)) _a.call(allFieldTips_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return false;
    };
    return Eno;
}());
