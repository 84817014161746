import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-type-chooser',
  template: `
    <app-type-chooser
            [formControl]="formControl"
            [label]="to.label"
            [inline]="to.inline"
            [placeholder]="to.placeholder"
            [required]="to.required"
            [readonly]="to.readonly"
            [optional]="to.optional"
            [includeSystemTypes]="to.includeSystemTypes"
            [includeSuperTypes]="to.includeSuperTypes"
            (change)="to.change"
    >
    </app-type-chooser>
  `,
})
export class FormlyFieldTypeChooserComponent extends FieldType {

}
