import * as tslib_1 from "tslib";
import { FieldType } from '@ngx-formly/core';
import { OnInit } from '@angular/core';
import { get } from 'lodash';
var FormlyFieldListItemsSelectComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FormlyFieldListItemsSelectComponent, _super);
    function FormlyFieldListItemsSelectComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.single = true;
        return _this;
    }
    FormlyFieldListItemsSelectComponent.prototype.ngOnInit = function () {
        var fieldTip = get(this, 'field.templateOptions.contextTypeTip', null);
        var fieldOptions = get(this, 'formState.contextTypeOptions', null);
        this.fieldOption = fieldTip && fieldOptions ? fieldOptions.values.get(fieldTip) : null;
        this.single = !this.fieldOption || this.fieldOption.maxcount === 1;
        this.listTip = this.fieldOption && this.fieldOption.listTip ? this.fieldOption.listTip : null;
    };
    FormlyFieldListItemsSelectComponent.prototype.calculate = function () {
        this.formState.calculation$.next({
            fieldTip: this.field.key,
            calculationFormula: this.fieldOption.calculation,
            calculationNoValueAsZero: !!this.fieldOption.calculationNoValueAsZero
        });
    };
    return FormlyFieldListItemsSelectComponent;
}(FieldType));
export { FormlyFieldListItemsSelectComponent };
