import * as tslib_1 from "tslib";
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import { CompositeNodeConverter } from './CompositeNodeConverter';
import { createConnection, createForEachNode, createSubProcessNode } from './utils/create-node-utils';
import { Parser, Stringify } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { get, isEmpty } from 'lodash';
import * as i0 from "@angular/core";
var ForEachNodeConverterService = /** @class */ (function (_super) {
    tslib_1.__extends(ForEachNodeConverterService, _super);
    function ForEachNodeConverterService() {
        var _this = _super.call(this) || this;
        _this.type = 'processnode/logic/foreach';
        _this.processNodeType = 'processnodetype/logic/foreach';
        return _this;
    }
    ForEachNodeConverterService.prototype.convert = function (nodeData) {
        if (!this.isValid(nodeData)) {
            throw new InvalidWorkflowNodeDataError("Please correct invalid data on for-each node [" + nodeData.fields.title + "]", nodeData);
        }
        var currentElementVarKey = 'Current-' + Math.random(); // use random to avoid potential nested loop variable collision.
        var forEachNode = createForEachNode({
            name: 'SLICE',
            args: [Parser(nodeData.fields.loopSource.formula), 0, 1000]
        }, currentElementVarKey, nodeData.tip);
        var vars = [];
        var subVars = [];
        var outputSubVars = [];
        var outputVars = [];
        // tslint:disable-next-line:no-string-literal
        if (isEmpty(nodeData.fields.inputOutputMaps) && nodeData.fields['subWorkflowInputKey']) {
            // backwards compatible
            vars.push(currentElementVarKey);
            // tslint:disable-next-line:no-string-literal
            subVars.push(nodeData.fields['subWorkflowInputKey']);
        }
        else {
            nodeData.fields.inputOutputMaps.inputMaps.forEach(function (_a, index) {
                var parentProperty = _a.parentProperty, subInputKey = _a.subInputKey;
                if (!parentProperty || !parentProperty.selectedPropertyKey || !subInputKey) {
                    return;
                }
                if (index === 0) {
                    vars.push(currentElementVarKey);
                    subVars.push(subInputKey);
                    return;
                }
                vars.push(parentProperty.selectedPropertyKey);
                subVars.push(subInputKey);
            });
            nodeData.fields.inputOutputMaps.outputMaps.forEach(function (_a) {
                var parentVariableKey = _a.parentVariableKey, subVariableKey = _a.subVariableKey;
                if (!parentVariableKey || !subVariableKey) {
                    return;
                }
                outputSubVars.push(subVariableKey);
                outputVars.push(parentVariableKey);
            });
        }
        var subProcessTipFormula = Stringify({
            name: 'FIELD',
            args: [
                'app/workflow:process',
                get(nodeData, 'fields.subWorkflowTip[0]', null)
            ]
        });
        // 10m timeout by default (not currently working as timeout is not implemented on server-side)
        var subProcessNode = createSubProcessNode(subProcessTipFormula, vars, 10 * 60 * 1000, subVars, outputSubVars, outputVars);
        // link from for-each to sub-process
        var iterationConnectionForForEachNode = createConnection('foreach', subProcessNode);
        forEachNode.connections.push(iterationConnectionForForEachNode);
        // done link from sub-process to for-each
        var doneConnectionForSubprocessNode = createConnection('done', forEachNode);
        subProcessNode.connections.push(doneConnectionForSubprocessNode);
        // timeout link from sub-process to for-each
        var timeoutConnectionForSubprocessNode = createConnection('timeout', forEachNode);
        subProcessNode.connections.push(timeoutConnectionForSubprocessNode);
        // make the final collection of all nodes and links
        var nodesAndLink = {
            nodes: [
                forEachNode,
                subProcessNode
            ],
            links: [
                iterationConnectionForForEachNode,
                doneConnectionForSubprocessNode,
                timeoutConnectionForSubprocessNode
            ]
        };
        this.populateOutgoingLinks(nodeData).forEach(function (link) {
            forEachNode.connections.push(link);
            nodesAndLink.links.push(link);
        });
        return nodesAndLink;
    };
    ForEachNodeConverterService.prototype.isValid = function (nodeData) {
        return !isEmpty(nodeData.fields.title)
            && !isEmpty(nodeData.fields.loopSource)
            && !isEmpty(nodeData.fields.loopSource.formula)
            && !isEmpty(nodeData.fields.subWorkflowTip);
    };
    ForEachNodeConverterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ForEachNodeConverterService_Factory() { return new ForEachNodeConverterService(); }, token: ForEachNodeConverterService, providedIn: "root" });
    return ForEachNodeConverterService;
}(CompositeNodeConverter));
export { ForEachNodeConverterService };
