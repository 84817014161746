import { Injectable } from '@angular/core';
import { init, configureScope, captureException } from '@sentry/browser';
import { environment } from '../../environments/environment';
import { GtmService } from './gtm.service';
import { MeService } from '../shell/services/me.service';
import { get } from 'lodash';
@Injectable({
  providedIn: 'root'
})
export class SentryService {

  public onNativeApp = false;

  constructor(private gtmService: GtmService, private meService: MeService) { }

    init() {
        init({
            dsn: environment.sentryDsn,
            release: environment.release,
            environment: environment.enCloudEnv
        });
        configureScope(scope => {
            scope.setTag('namespace', environment.ns);
            scope.setTag('serviceUri', environment.host);
            scope.setTag('customerUri', window.location.origin);
        });

        if (window.location.search) {
            const paramList = window.location.search.slice(1).split('&');   // remove the '?'
            if (paramList && paramList.length > 0) {
                for (const param of paramList) {
                    if (param && param.split('=').length === 2) {
                        if (param.split('=')[0] === 'nativeApp') {
                            this.onNativeApp = param.split('=')[1] === 'true';
                        }
                    }
                }
            }
        }
        const systemInfo = {
            release: environment.release,
            environment: environment.enCloudEnv,
            namespace: environment.ns,
            serviceUri: environment.host,
            customerUri: window.location.origin,
            onNativeApp: this.onNativeApp === true ? 'true' : 'false'
        };
        console.info(systemInfo);
        this.gtmService.push(systemInfo);

        this.meService.me$.subscribe(me => {
            if (me) {
                configureScope(scope => {
                    const fullName = get(me, 'person.fullName', 'name-not-available');

                    scope.setUser({
                        id: me.$tip,
                        username: (`${fullName}`).replace(/(^ )|( $)/g, ''),
                        email: me.email
                    });
                });
            }
        });
    }
}
