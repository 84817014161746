export enum NodeLayout {
  START = 'StartNode', // end node
  STEP = 'StepNode', // node with title and edit button
  END = 'EndNode' // end node
}

export enum NodeInfoItemType {
  PLAIN_TEXT = 1,
  DROPDOWN = 2,
  LABEL = 3
}

export enum ComponentState {
  IN_PROGRESS = 1,
  SUCCESS = 2,
  FAILED = 3
}

export enum EPortRule {
  FROM = 1,
  TO = 2,
  BIDIRECTIONAL = 3
}

// these values are bound to real data, so cannot be changed
export enum WorkflowUXScreenItemType {
  objectDefaultForm = 'objectDefaultForm',
  differentForm = 'differentForm',
  objectDetails = 'objectDetails',
  successScreen = 'successScreen',
  progressScreen = 'progressScreen'
}

export enum OUTCOME_LABEL_MAP {
  done = 'Finished',
  failed = 'Failed',
  finally = 'Always',
  true = 'True',
  false = 'False',
  hibernated = 'Async execution',
  timeout = 'Timeout',
  default = 'Default',
  fileError = 'File error'
}

export enum SELECT_LINK_RETURN_TYPE {
  link = 1,
  node = 2,
  linkData = 3
}

export enum PROCESS_NODE_TYPE {
  START = 'Start',
  END = 'End',
  DECISION = 'Decision',
  SUBWORKFLOW = 'Subworkflow',
  UPDATE_OBJECT = 'UpdateObject',
  WORKFLOW_UX = 'WorkflowUX',
  DELETE_OBJECT = 'DeleteObject',
  DUPLICATE_OBJECT = 'DuplicateObject',
  SWITCH = 'Switch',
  CREATE_OBJECT = 'CreateObject',
  SET_VARIABLE = 'SetVariable',
  SEND_EMAIL = 'SendEmail',
  SEND_SMS = 'SendSMS',
  UPDATE_SECURITY = 'UpdateSecurity',
  UPDATE_STAGE = 'UpdateStage',
  SAVE_FILE = 'SaveFile',
  GENERATE_PDF = 'GeneratePDF',
  EXPORT_ENOS = 'ExportEnos',
  IMPORT_ENOS = 'Import Enos',
  SEND_NOTIFICATION = 'SendNotification',
  RETRIEVE_FILE = 'RetrieveFile',
  SEND_VOICE_MESSAGE = 'SendVoiceMessage',
  QUERY_EXTERNAL_MAP = 'QueryExternalMap',
  FOR_EACH = 'ForEach',
  PAUSE_UNTIL = 'PauseUntil'
}
