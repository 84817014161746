import { Component, Input, OnInit } from '@angular/core';
import { get, intersection, some } from 'lodash';
import { Subscription } from 'rxjs';

import {
  getMostInnerContextFromContextFormula,
  insertMostInnerContextToContextFormula,
  removeMostInnerContextFromContextFormula
} from '../../../util/context-formula-wrapper';
import { isCustomFormula } from '../../../util/custom-formula';
import { DisplayFormulaWrapperService, IDisplayFormulaRule } from '../../../util/display-formula-wrapper.service';
import { ToastService } from '../../../shell/services/toast.service';
import { SideSheetService } from '../../../side-sheet/side-sheet.service';
import { IType, typeScheme } from '../../../models/type';
import { IField } from '../../../models/field';
import { Formula, Tip } from '../../../data/models/types';
import { ObjectService } from '../../../data/object-service/object.service';
import { isSuperType } from '../../type-chooser-side-sheet/super-types';
import { IMetaDataFormulas, metaDataFormulas, MetaDataKey } from '../meta-data-formulas';
import { CustomFormulaSideSheetComponent } from '../custom-formula/custom-formula-side-sheet/custom-formula-side-sheet.component';
import { FieldFormulaSideSheetService } from './field-formula-side-sheet.service';
import { QuoteString } from './formula';
import { ToggleType } from '../../../side-sheet/side-sheet-list-item/side-sheet-list-item.component';

export const TIP_FORMULA = 'TIP()';

@Component({
  selector: 'app-field-formula-side-sheet',
  templateUrl: './field-formula-side-sheet.component.html',
  styleUrls: ['./field-formula-side-sheet.component.scss']
})
export class FieldFormulaSideSheetComponent implements OnInit {
  @Input() title: string;
  @Input() minSelect: number;
  @Input() maxSelect: number;
  @Input() maxSubSelect: number; // maxSubSelect will affect subsequent drill-down depths
  @Input() objectTypeTip: Tip;
  @Input() allowCustomFormula = false;
  @Input() selected: Formula[] = [];
  // Datatype
  @Input() dataTypeConstraint: string;    // We’re only looking for fields of this data type
  // Typerestrict
  @Input() objectTypeConstraint: string;  // If we’re only looking for "object" fields, then only those with this typerestrict constraint
  @Input() tags: string[];
  @Input() showRelatedItems = true;
  @Input() showMetaItems = true;
  @Input() showMetaData = true;
  @Input() metaDataKeysToExcluded: MetaDataKey[] = [MetaDataKey.urlLink];
  // the side sheet will return a formula that is for display purpose, which means formatting date, datetime,
  // list and rich-text according the rule set below
  @Input() displayFormulaRule: IDisplayFormulaRule;
  @Input() formatsFields = false;
  @Input() isSort = false;

  @Input() searchableFieldsOnly = false;
  @Input() isDetailWidget: boolean = false;

  private isLastSheet = true;

  onDone: (selected: string[]) => void;   // Called when the user presses “Done"

  isLoading = true;
  objectType: IType;
  fieldItems: IField[];
  relatedItems: IField[];
  metaDataFormulas: IMetaDataFormulas[] = [];
  isSuperType = false;
  hideAdditionalFields = true;

  subscriptions = new Subscription();

  constructor(
    private objectService: ObjectService,
    private sideSheetService: SideSheetService,
    private toastService: ToastService,
    public fieldFormulaSideSheetService: FieldFormulaSideSheetService,
    private displayFormulaWrapperService: DisplayFormulaWrapperService
  ) {}

  ngOnInit() {
    if (this.selected) {
      this.selected = this.displayFormulaWrapperService.removeDisplayFormulaWrappers(this.selected);
    }

    this.isSuperType = isSuperType(this.objectTypeTip);

    if ((!this.dataTypeConstraint || this.dataTypeConstraint === 'object'
      || get(this.fieldFormulaSideSheetService, 'objectTypesWithAdditionalFields', []).length > 0) && !this.isDetailWidget) {
      this.hideAdditionalFields = false;
    }

    if(!this.isDetailWidget) {
      this.getDisplayMetaDataFormulas();
    }

    if (this.objectType) {
      this.getFieldsFromObjectType();
      this.isLoading = false;
      return;
    }
    if (this.isSuperType) {
      // get fields for super types
      this.subscriptions.add(
        this.fieldFormulaSideSheetService.getSuperTypeFields(this.objectTypeTip)
          .subscribe(x => this.isLoading = false,
            err => {
              this.toastService.showErrorToast('Unable to load related object type fields');
              this.sideSheetService.pop();
            })
      );
    } else {
      // get fields for normal object types
      this.subscriptions.add(this.objectService.getObject<IType>(this.objectTypeTip, typeScheme).subscribe(
        objectType => {
          this.objectType = objectType;
          this.getFieldsFromObjectType();
          this.isLoading = false;
        },
        err => {
          this.toastService.showErrorToast('Unable to load object type or access was denied');
          this.sideSheetService.pop();
        }
      ));
    }
  }

  private getDisplayMetaDataFormulas() {
    this.metaDataFormulas = metaDataFormulas.filter(
      (meta: IMetaDataFormulas) => {
        if (this.metaDataKeysToExcluded.includes(meta.key)) {
          return false;
        }

        if (this.searchableFieldsOnly && !meta.searchable) {
          return false;
        }

        if (this.dataTypeConstraint && !meta.hasDrillDown) {
          if (this.dataTypeConstraint !== meta.datatype) {
            return false;
          }

          if (this.objectTypeConstraint && !this.isSuperType && !isSuperType(this.objectTypeConstraint)) {
            if (meta.key === MetaDataKey.thisObject && this.objectTypeTip !== this.objectTypeConstraint) {
              return false;
            }

            if (meta.key !== MetaDataKey.thisObject
              && meta.typerestrict
              && !some(meta.typerestrict, t => t === this.objectTypeConstraint)) {
              return false;
            }
          }
        }

        return true;
      }
    );
  }

  private getFieldsFromObjectType() {
    // Get the fields that should be shown in the "Fields" section
    // They must be non-objects and must comply with a data type constraint if there is one
    this.fieldItems = (this.objectType.field || []).filter(field => {
      if (this.searchableFieldsOnly && !field.searchable) {
        return false;
      }

      return field.datatype !== 'object'
        && (!this.dataTypeConstraint || this.dataTypeConstraint === field.datatype)
        && (!this.tags || intersection((field.tag || []), this.tags).length > 0);
    });

    // Get the object fields they can descend down
    this.relatedItems = this.showRelatedItems
      ? (this.objectType.field || []).filter(field => {
        return field.datatype === 'object' && field.typerestrict && field.typerestrict.length === 1;
      })
      : [];
  }

  // The "Done" button is pressed
  onPrimary() {
    this.subscriptions.add(
      this.displayFormulaWrapperService.addDisplayFormulaWrapper(this.selected, this.objectTypeTip, this.displayFormulaRule).subscribe(
        formulas => this.finalize(formulas)
      )
    );
  }

  private finalize(formulas: Formula[]) {
    this.onDone(formulas);
    this.sideSheetService.pop();
  }

  // Return the FIELD() formula for selection given the field tip
  fieldFormula(fieldTip: string): string {
    return this.formatsFields && this.isLastSheet ? `FMT_FIELD(${QuoteString(fieldTip)})` : `FIELD(${QuoteString(fieldTip)})`;
  }

  // return specific formula to get common field
  // we use FIELD_BY_NAME to get field by name
  // but in order to also filter out fields by type we add json encoded data that will be used when displaying results
  commonFieldFormula(field: IField): string {
    const fieldData = {
      name: field.name,
      datatype: field.datatype,
      tag: field.tag,
      typerestrict: field.typerestrict,
      typeGeoRestrict: field.typeGeoRestrict
    };
    // tslint:disable-next-line:max-line-length
    return `LAST(ARRAY(\"COMMON_FIELD_FORMULA\",${QuoteString(JSON.stringify(fieldData))},COALESCE(FIELD_BY_NAME(${QuoteString(field.name)}),ARRAY(\"\"))))`;
  }

  // Returns true if the given formula is selected
  isSelected(formulaStr: string) {
    return this.selected.indexOf(formulaStr) > -1;
  }

  // returns true if formulaStr is a formula for field fieldTip
  isBasedOnField(formulaStr: string, fieldTip: string): boolean {
    const fieldFormula = this.fieldFormula(fieldTip);
    if (formulaStr.startsWith('CONTEXT')) {
      return getMostInnerContextFromContextFormula(formulaStr) === fieldFormula;
    }

    // code below is for backwards compatible
    const len = fieldFormula.length;
    const str = formulaStr.replace(/\)+$/, ')');
    return str.substr(str.length - len, len) === fieldFormula;
  }

  // Returns the number of selected formulas that are based on the given field tip
  baseSelectedCount(fieldTip: string): number {
    return this.selected.filter(formulaStr => this.isBasedOnField(formulaStr, fieldTip)).length;
  }

  // get count of selected formulas that are based on any field of the object type
  selectedCountForObjType(objType: {
    field: IField[],
    label: string,
    moduleName: string,
    $tip: Tip,
    $type: string
  }) {
    return this.selected.filter(
      formulaStr => objType.field.some(field => this.isBasedOnField(formulaStr, field.$tip))
    ).length;
  }

  // Toggle the selected state of a given formula
  toggleSelected(formulaStr: string) {
    const pos = this.selected.indexOf(formulaStr);
    if (pos > -1) {
      this.selected.splice(pos, 1); // Already there, remove
    } else if (this.maxSelect === 1) {
      this.selected = [ formulaStr ]; // Single-select
    } else if (!this.maxSelect || this.maxSelect > this.selected.length) {
      this.selected.push(formulaStr); // Multi-select and not yet at maximum
    }
  }

  openAdditionalFieldSheet(objType: {
    field: IField[],
    label: string,
    moduleName: string,
    $tip: Tip,
    $type: string
  }) {
    // get selected formulas for the additional fields for this object type
    const selectedSubset = this.selected.filter(
      formulaStr => objType.field.some(field => this.isBasedOnField(formulaStr, field.$tip))
    );

    const sheetRef = this.sideSheetService.push(FieldFormulaSideSheetComponent);
    const instance = sheetRef.componentInstance as FieldFormulaSideSheetComponent;

    instance.selected = selectedSubset;

    instance.objectType = { ...objType, summary: '', description: '', title: '' };

    instance.allowCustomFormula = false;
    instance.dataTypeConstraint = this.dataTypeConstraint;
    instance.objectTypeConstraint = this.objectTypeConstraint;
    instance.maxSelect = this.maxSubSelect || this.maxSelect;
    instance.maxSubSelect = this.maxSubSelect;
    instance.metaDataKeysToExcluded = this.metaDataKeysToExcluded;
    instance.formatsFields = this.formatsFields;

    // dont show meta data for additional fields ( metadata on the super type will be the same )
    instance.showMetaData = false;

    instance.onDone = (resultingFormulaStrs: string[]) => {
      this.isLastSheet = false;

      if (this.maxSelect === 1) {
        this.selected = [];
      } else {
        this.selected = this.selected.filter(formulaStr => selectedSubset.indexOf(formulaStr) === -1);
      }
      resultingFormulaStrs.forEach(formulaStr => {
        if (!this.maxSelect || this.selected.length < this.maxSelect) {
          this.selected.push(formulaStr);
        }
      });
    };
  }

  // Open the side sheet for the given related object field
  openRelatedFieldSheet(field: IField, handleSuperType: boolean = false) {
    // get formula string for the field
    const selectedSubset = this.selected.filter(formulaStr => this.isBasedOnField(formulaStr, field.$tip));
    const startingFormulaStrs = selectedSubset.map(formulaStr => this.removeFieldFormula(formulaStr, field.$tip));
    const sheetRef = this.sideSheetService.push(FieldFormulaSideSheetComponent);
    const instance = sheetRef.componentInstance as FieldFormulaSideSheetComponent;

    instance.selected = startingFormulaStrs;
    // todo: get type somehow ? if there is no typerestrict (e g on super type fields)
    if (field.typerestrict) {
      instance.objectTypeTip = field.typerestrict[0];
    }
    instance.allowCustomFormula = false;
    instance.dataTypeConstraint = this.dataTypeConstraint;
    instance.objectTypeConstraint = this.objectTypeConstraint;
    instance.maxSelect = this.maxSubSelect || this.maxSelect;
    instance.maxSubSelect = this.maxSubSelect;
    instance.metaDataKeysToExcluded = this.metaDataKeysToExcluded;
    instance.formatsFields = this.formatsFields;
    instance.onDone = (resultingFormulaStrs: string[]) => {
      this.isLastSheet = false;

      // if only single select allowed -  reset selected list
      if (this.maxSelect === 1) {
        this.selected = [];
      } else {
        // if multi select allowed - remove for the given object field from selected
        this.selected = this.selected.filter(formulaStr => selectedSubset.indexOf(formulaStr) === -1);
      }
      // add formulas selected in side sheet to the list of selected
      resultingFormulaStrs.forEach(formulaStr => {
        if (!this.maxSelect || this.selected.length < this.maxSelect) {
          const typerestrict: Tip = get(field, 'typerestrict.0');

          if (handleSuperType && field.name && typerestrict) {
            // Wrap the common field in a check to check that the field's has datatype=object
            // We need to because the common field value might not be an object type
            const fieldFormulaStr = 'IF(EQUALS(FIELD("field/datatype",FIRST(FIELD_TIP('
                                  + QuoteString(typerestrict) + ',' + QuoteString(field.name)
                                  + '))),"object"),' + this.commonFieldFormula(field) + ',ARRAY())';
            this.selected.push(this.insertFieldFormula(formulaStr, fieldFormulaStr));
          } else if (handleSuperType) {
            // No data type constraint or no field name, so we can't drill down in to the common field
            this.selected.push('ARRAY()');
          } else {
            this.selected.push(this.insertFieldFormula(formulaStr, this.fieldFormula(field.$tip)));
          }
        }
      });
    };
  }

  openCustomFormulaSideSheet() {
    const { componentInstance } = this.sideSheetService.push(CustomFormulaSideSheetComponent) as
      { componentInstance: CustomFormulaSideSheetComponent };

    const formula = get(this, `selected[${this.customFormulaIndex}]`, '');

    componentInstance.setProps(formula.substr('COALESCE('.length, formula.length - 10));

    componentInstance.done$.subscribe(customFormula => {
      this.appendCustomFormula(customFormula);

    });
  }

  get customFormulaIndex(): number {
    return this.selected.findIndex(isCustomFormula);
  }

  private appendCustomFormula(customFormulaStr: string) {
    const customFormulaIndex = this.customFormulaIndex;
    // remove custom formula
    if (customFormulaIndex !== -1) {
      this.selected.splice(customFormulaIndex, 1);
    }

    // if custom formula is entered, append the custom formula entry
    if (customFormulaStr && customFormulaStr.length) {
      if (this.maxSelect === 1) {
        this.selected = [];
      }
      this.selected.push(`COALESCE(${customFormulaStr})`);
      return;
    }
  }

  // Appends the given FIELD(<fieldTip>) to the inner most argument list
  insertFieldFormula(wrappingFormulaStr: string, fieldFormulaStr: string) {
    return insertMostInnerContextToContextFormula(wrappingFormulaStr, fieldFormulaStr);
  }

  // Removes the FIELD<fieldTip> from the innermost argument list
  removeFieldFormula(formulaStr: string, fieldTip: string) {
    if (formulaStr.startsWith('CONTEXT')) {
      return removeMostInnerContextFromContextFormula(formulaStr);
    }

    // code below is for backwards compatible
    const fieldFormula = this.fieldFormula(fieldTip);
    if (formulaStr === fieldFormula) {
      return TIP_FORMULA;
    }

    const depth = /\)+$/.exec(formulaStr).length;
    return formulaStr.substr(0, formulaStr.length - depth - fieldFormula.length - 1) + ')'.repeat(depth);
  }

  checkForFormula(metaData: IMetaDataFormulas): string {
    if (this.isSort && metaData.sortFormula) {
      return metaData.sortFormula;
    }
    return metaData.formula;
  }

  metaToggleType(meta: IMetaDataFormulas): ToggleType {
    if (meta.hasDrillDown) {
      return ToggleType.NUMBER;
    }

    if (this.maxSelect === 1) {
      return ToggleType.SINGLE_CHECK;
    }

    return ToggleType.MULTI_CHECK;
  }

  isBasedOnMeta(formula: Formula, meta) {
    const formulaLookFor = this.checkForFormula(meta);

    return formula.indexOf(formulaLookFor) > -1;
  }

  metaSelectedValue(meta: IMetaDataFormulas): boolean | number {
    if (!meta.hasDrillDown) {
      return this.isSelected(this.checkForFormula(meta));
    }

    return this.selected.filter(formula => this.isBasedOnMeta(formula, meta)).length;
  }

  metaToggle($event: Formula, meta: IMetaDataFormulas) {
    if (!meta.hasDrillDown) {
      return this.toggleSelected($event);
    }

    // get formula string for the field
    const selectedSubset = this.selected.filter(formulaStr => this.isBasedOnMeta(formulaStr, meta));
    const startingFormulaStrs = selectedSubset.map(formulaStr => removeMostInnerContextFromContextFormula(formulaStr));

    const sheetRef = this.sideSheetService.push(FieldFormulaSideSheetComponent);
    const instance = sheetRef.componentInstance as FieldFormulaSideSheetComponent;

    instance.selected = startingFormulaStrs;

    if (meta.typerestrict) {
      instance.objectTypeTip = meta.typerestrict[0];
    }
    instance.allowCustomFormula = false;
    instance.dataTypeConstraint = this.dataTypeConstraint;
    instance.objectTypeConstraint = this.objectTypeConstraint;
    instance.maxSelect = this.maxSubSelect || this.maxSelect;
    instance.maxSubSelect = this.maxSubSelect;
    instance.metaDataKeysToExcluded = this.metaDataKeysToExcluded;
    instance.formatsFields = this.formatsFields;
    instance.onDone = (resultingFormulaStrs: string[]) => {
      this.isLastSheet = false;

      // if only single select allowed -  reset selected list
      if (this.maxSelect === 1) {
        this.selected = [];
      } else {
        // if multi select allowed - remove for the given object field from selected
        this.selected = this.selected.filter(formulaStr => selectedSubset.indexOf(formulaStr) === -1);
      }
      // add formulas selected in side sheet to the list of selected
      resultingFormulaStrs.forEach(formulaStr => {
        if (!this.maxSelect || this.selected.length < this.maxSelect) {
          this.selected.push(insertMostInnerContextToContextFormula(formulaStr, this.checkForFormula(meta)));
        }
      });
    };
  }
}
