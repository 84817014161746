import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { Tip } from '../../data/models/types';
import { IProcessResponse, ProcessService } from '../../data/process.service';
import { ObjectService } from '../../data/object-service/object.service';
import { ISessionInfo, SessionManagerService } from '../../data/session-manager.service';
import { IProfile, profileScheme } from '../../models/profile';
import { LocationService } from '../../util/location.service';
import { DEFAULT_DIMENSION, QueryService } from '../../data/query.service';
import { FormulaService } from '../../data/formula.service';
import { MeService } from './me.service';

export enum SwitchProfileResultStatus {
  SUCCESS,
  FAILED,
  ALREADY_SAME_PROFILE
}

interface IVarStatusToResultStatusMap {
  [statusKey: string]: SwitchProfileResultStatus;
}

const varStatusToResultStatusMap: IVarStatusToResultStatusMap = {
  success: SwitchProfileResultStatus.SUCCESS,
  failed: SwitchProfileResultStatus.FAILED,
  'already same profile': SwitchProfileResultStatus.ALREADY_SAME_PROFILE
};

export interface IProfileQueryResult {
  $tip: string;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class MyProfileService {
  constructor(
    private queryService: QueryService,
    private formulaService: FormulaService,
    private objectService: ObjectService,
    private processService: ProcessService,
    private sessionManagerService: SessionManagerService,
    private locationService: LocationService,
    private meService: MeService
  ) { }

  // @deprecated.
  get myProfile$(): Observable<IProfile> {
    return this.getMyProfile$();
  }

  getMyProfile$(): Observable<IProfile> {
    return this.sessionManagerService.getSessionInfo$().pipe(
      switchMap((sessionInfo: ISessionInfo) => this.objectService.getObject<IProfile>(sessionInfo.profile, profileScheme))
    );
  }

  getProfileNumber$(): Observable<number> {
    return this.sessionManagerService.getSessionInfo$().pipe(
      switchMap(sessionInfo => this.formulaService.evaluate('COUNT(FIELD("app/user:profiles"))', sessionInfo.user)),
      map(result => parseInt(result[0], 10))
    );
  }

  getMyProfiles$(): Observable<IProfileQueryResult[]> {
    return this.sessionManagerService.getSessionInfo$().pipe(
      switchMap(
        (sessionInfo: ISessionInfo) =>
          this.queryService.execute1dArray<{ $tip: string[]; name: string[] }>(
            'eim/query/generic-query',
            {
              extraAttributes: [
                { label: 'name', formula: 'FIELD("app/profile:name")' }
              ],
              extraFilters: [
                { label: 'Query type filter', formula: 'ISTYPE("app/profile")' },
                {
                  label: 'Belongs to the user',
                  formula: `INARRAY(FIELD("app/user:profiles", VAR("User tip")), TIP())`
                }
              ],
              vars: {
                'User tip': [sessionInfo.user]
              },
              dimensionOptions: [{ label: 'Tip dimension', formula: 'TIP()', sortby: ['FIELD("app/profile:name")'] }]
            }
          )
      ),
      map(
        (result: { $tip: string[]; name: string[] }[]) =>
          result.map(r => ({ $tip: r.$tip[0], name: r.name[0] }))
      )
    );
  }

  switchProfile(profileTip: Tip, userTip: Tip): Observable<SwitchProfileResultStatus> {
    return this.processService.start(
      'eim/process/auth/switch-profile',
      {
        'Profile tip': [profileTip],
        'User tip': [userTip]
      }
    ).pipe(
      first((processResponse: IProcessResponse) => {
        return processResponse.finished;
      }),
      switchMap((processResponse: IProcessResponse) => {
        const returnVars = processResponse.vars;

        if (returnVars && returnVars['Status'] && returnVars['JWT token']) {
          const resultStatus = varStatusToResultStatusMap[returnVars['Status'][0]];

          this.sessionManagerService.updateSessionInfo(returnVars['JWT token'][0]);
          this.meService.getMe$().pipe(first()).subscribe(_ => this.sessionManagerService.setLastProfile(_.email, profileTip));
          this.locationService.refreshToRoot();

          return of(resultStatus);
        }

        return throwError('Failed to switch profile.');
      })
    );
  }
}
