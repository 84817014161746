import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { SideSheetListModes } from '../../side-sheet-list/side-sheet-list.component';
import { NOW_VAR_NAME } from '../../../util/current-datetime.service';
// predefined date formulas
// note: make sure not to have spaces between formula arguments
var NOW_FORMULA = "VAR(\"" + NOW_VAR_NAME + "\")";
var YESTERDAY = "DATE_ADD(" + NOW_FORMULA + ",-1,\"days\")";
var TOMORROW = "DATE_ADD(" + NOW_FORMULA + ",1,\"days\")";
var LAST_MONTH = "DATE_ADD(" + NOW_FORMULA + ",-1,\"months\")";
var NEXT_MONTH = "DATE_ADD(" + NOW_FORMULA + ",1,\"months\")";
var LAST_YEAR = "DATE_ADD(" + NOW_FORMULA + ",-1,\"years\")";
var NEXT_YEAR = "DATE_ADD(" + NOW_FORMULA + ",1,\"years\")";
var LAST_90_DAYS = "DATE_ADD(" + NOW_FORMULA + ",-90,\"days\")";
var NEXT_90_DAYS = "DATE_ADD(" + NOW_FORMULA + ",90,\"days\")";
var FIRST_SECOND_OF_YESTERDAY = setFirstSecondOfDay(YESTERDAY);
var LAST_SECOND_OF_YESTERDAY = setLastSecondOfDay(YESTERDAY);
var FIRST_SECOND_OF_TODAY = setFirstSecondOfDay(NOW_FORMULA);
var LAST_SECOND_OF_TODAY = setLastSecondOfDay(NOW_FORMULA);
var FIRST_SECOND_OF_TOMORROW = setFirstSecondOfDay(TOMORROW);
var LAST_SECOND_OF_TOMORROW = setLastSecondOfDay(TOMORROW);
var FIRST_SECOND_OF_LAST_WEEK = setFirstSecondOfDay(produceWeekDays('-6'));
var LAST_SECOND_OF_LAST_WEEK = setLastSecondOfDay(produceWeekDays('0'));
var FIRST_SECOND_OF_THIS_WEEK = setFirstSecondOfDay(produceWeekDays('1'));
var LAST_SECOND_OF_THIS_WEEK = setLastSecondOfDay(produceWeekDays('7'));
var FIRST_SECOND_OF_NEXT_WEEK = setFirstSecondOfDay(produceWeekDays('8'));
var LAST_SECOND_OF_NEXT_WEEK = setLastSecondOfDay(produceWeekDays('14'));
var FIRST_SECOND_OF_LAST_MONTH = setFirstSecondOfMonth(LAST_MONTH);
var LAST_SECOND_OF_LAST_MONTH = setLastSecondOfMonth(LAST_MONTH);
var FIRST_SECOND_OF_THIS_MONTH = setFirstSecondOfMonth(NOW_FORMULA);
var LAST_SECOND_OF_THIS_MONTH = setLastSecondOfMonth(NOW_FORMULA);
var FIRST_SECOND_OF_NEXT_MONTH = setFirstSecondOfMonth(NEXT_MONTH);
var LAST_SECOND_OF_NEXT_MONTH = setLastSecondOfMonth(NEXT_MONTH);
var FIRST_SECOND_OF_LAST_YEAR = setFirstSecondOfYear(LAST_YEAR);
var LAST_SECOND_OF_LAST_YEAR = setLastSecondOfYear(LAST_YEAR);
var FIRST_SECOND_OF_THIS_YEAR = setFirstSecondOfYear(NOW_FORMULA);
var LAST_SECOND_OF_THIS_YEAR = setLastSecondOfYear(NOW_FORMULA);
var FIRST_SECOND_OF_NEXT_YEAR = setFirstSecondOfYear(NEXT_YEAR);
var LAST_SECOND_OF_NEXT_YEAR = setLastSecondOfYear(NEXT_YEAR);
var FIRST_SECOND_OF_LAST_90_DAYS = setFirstSecondOfDay(LAST_90_DAYS);
var FIRST_SECOND_OF_NEXT_90_DAYS = setFirstSecondOfDay(NOW_FORMULA);
var LAST_SECOND_OF_NEXT_90_DAYS = setLastSecondOfDay(NEXT_90_DAYS);
function produceWeekDays(numberOfDays) {
    return "DATE_ADD(" + NOW_FORMULA + ",SUB(" + numberOfDays + ",DAY_OF_WEEK(" + NOW_FORMULA + ")),\"days\")";
}
function setFirstSecondOfYear(baseFormula) {
    return setFirstSecondOfDay("SET_DAY(SET_MONTH(" + baseFormula + ",1),1)");
}
function setLastSecondOfYear(baseFormula) {
    return setLastSecondOfDay("SET_DAY(SET_MONTH(" + baseFormula + ",12),31)");
}
function setFirstSecondOfMonth(baseFormula) {
    return setFirstSecondOfDay("SET_DAY(" + baseFormula + ",1)");
}
function setLastSecondOfMonth(baseFormula) {
    return setLastSecondOfDay("DATE_ADD(" + baseFormula + ",SUB(DAYS_IN_MONTH(" + baseFormula + "),DAY(" + baseFormula + ")),\"days\")");
}
function setFirstSecondOfDay(baseFormula) {
    return "SET_SECOND(SET_MINUTE(SET_HOUR(" + baseFormula + ",0),0),0)";
}
function setLastSecondOfDay(baseFormula) {
    return "SET_SECOND(SET_MINUTE(SET_HOUR(" + baseFormula + ",23),59),59)";
}
export var RELATIVE_DATE_FORMULAS = [
    {
        label: 'Yesterday',
        value: "ARRAY(" + FIRST_SECOND_OF_YESTERDAY + "," + LAST_SECOND_OF_YESTERDAY + ")"
    },
    {
        label: 'Today',
        value: "ARRAY(" + FIRST_SECOND_OF_TODAY + "," + LAST_SECOND_OF_TODAY + ")"
    },
    {
        label: 'Tomorrow',
        value: "ARRAY(" + FIRST_SECOND_OF_TOMORROW + "," + LAST_SECOND_OF_TOMORROW + ")"
    },
    {
        label: 'Last week',
        value: "ARRAY(" + FIRST_SECOND_OF_LAST_WEEK + "," + LAST_SECOND_OF_LAST_WEEK + ")"
    },
    {
        label: 'This week',
        value: "ARRAY(" + FIRST_SECOND_OF_THIS_WEEK + "," + LAST_SECOND_OF_THIS_WEEK + ")"
    },
    {
        label: 'Next week',
        value: "ARRAY(" + FIRST_SECOND_OF_NEXT_WEEK + "," + LAST_SECOND_OF_NEXT_WEEK + ")"
    },
    {
        label: 'Last month',
        value: "ARRAY(" + FIRST_SECOND_OF_LAST_MONTH + "," + LAST_SECOND_OF_LAST_MONTH + ")"
    },
    {
        label: 'This month',
        value: "ARRAY(" + FIRST_SECOND_OF_THIS_MONTH + "," + LAST_SECOND_OF_THIS_MONTH + ")"
    },
    {
        label: 'Next month',
        value: "ARRAY(" + FIRST_SECOND_OF_NEXT_MONTH + "," + LAST_SECOND_OF_NEXT_MONTH + ")"
    },
    {
        label: 'Last year',
        value: "ARRAY(" + FIRST_SECOND_OF_LAST_YEAR + "," + LAST_SECOND_OF_LAST_YEAR + ")"
    },
    {
        label: 'This year',
        value: "ARRAY(" + FIRST_SECOND_OF_THIS_YEAR + "," + LAST_SECOND_OF_THIS_YEAR + ")"
    },
    {
        label: 'Next year',
        value: "ARRAY(" + FIRST_SECOND_OF_NEXT_YEAR + "," + LAST_SECOND_OF_NEXT_YEAR + ")"
    },
    {
        label: 'Last 90 days',
        value: "ARRAY(" + FIRST_SECOND_OF_LAST_90_DAYS + "," + NOW_FORMULA + ")"
    },
    {
        label: 'Next 90 days',
        value: "ARRAY(" + FIRST_SECOND_OF_NEXT_90_DAYS + "," + LAST_SECOND_OF_NEXT_90_DAYS + ")"
    }
];
export var RELATIVE_DATETIME_FORMULAS = tslib_1.__spread([
    {
        label: 'Last 24 hours',
        value: "ARRAY(" + YESTERDAY + "," + NOW_FORMULA + ")"
    },
    {
        label: 'Next 24 hours',
        value: "ARRAY(" + NOW_FORMULA + "," + TOMORROW + ")"
    }
], RELATIVE_DATE_FORMULAS);
// this is for the default value
export var POINT_RELATIVE_DATE_FORMULAS = [
    {
        label: 'Today',
        value: NOW_FORMULA
    },
    {
        label: 'Yesterday',
        value: YESTERDAY
    },
    {
        label: 'Tomorrow',
        value: TOMORROW
    },
    {
        label: '7 days ago',
        value: "DATE_ADD(" + NOW_FORMULA + ",-7,\"days\")"
    },
    {
        label: '7 days from now',
        value: "DATE_ADD(" + NOW_FORMULA + ",7,\"days\")"
    },
    {
        label: '30 days ago',
        value: "DATE_ADD(" + NOW_FORMULA + ",-30,\"days\")"
    },
    {
        label: '30 days from now',
        value: "DATE_ADD(" + NOW_FORMULA + ",30,\"days\")"
    },
    {
        label: '365 days ago',
        value: "DATE_ADD(" + NOW_FORMULA + ",-365,\"days\")"
    },
    {
        label: '365 days from now',
        value: "DATE_ADD(" + NOW_FORMULA + ",365,\"days\")"
    }
];
var RelativeDatesSideSheetComponent = /** @class */ (function () {
    function RelativeDatesSideSheetComponent(sideSheetService) {
        this.sideSheetService = sideSheetService;
        this.title = 'Select value field';
        this.SideSheetListModes = SideSheetListModes;
        // set to true if result is required as a date rather than datetime
        this.isDate = false;
        // set to true if the list is a single point rather than a range (this is for default value)
        // if isSinglePoint is true and isDate is true, the result formula will be wrapped with TO_DATE() for backwards compatibility
        this.isSinglePoint = false;
    }
    RelativeDatesSideSheetComponent.prototype.ngOnInit = function () {
        this.options = this.isSinglePoint ? POINT_RELATIVE_DATE_FORMULAS : (this.isDate ? RELATIVE_DATE_FORMULAS : RELATIVE_DATETIME_FORMULAS);
        if (this.selectedFormula) {
            var formula = getRelativeDateFormula(this.selectedFormula);
            if (formula) {
                this.optionList.selected = [formula.value];
            }
        }
    };
    RelativeDatesSideSheetComponent.prototype.onDone = function () {
        var result = this.optionList.selected && this.optionList.selected[0];
        if (this.isSinglePoint && this.isDate && result) {
            result = "TO_DATE(" + result + ")";
        }
        this.done(result);
        this.sideSheetService.pop();
    };
    return RelativeDatesSideSheetComponent;
}());
export { RelativeDatesSideSheetComponent };
export function getRelativeDateFormula(strFormula) {
    var formulaValue = strFormula && strFormula.startsWith('TO_DATE') ? strFormula.replace(/^TO_DATE\(|\)$/g, '') : strFormula;
    return (formulaValue.startsWith('ARRAY') ? RELATIVE_DATETIME_FORMULAS : POINT_RELATIVE_DATE_FORMULAS).find(function (x) { return x.value === formulaValue; });
}
