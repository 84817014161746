import * as tslib_1 from "tslib";
import { FieldType } from '@ngx-formly/core';
import { OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { get } from 'lodash';
var FormlyFieldSimpleQueryChooserComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FormlyFieldSimpleQueryChooserComponent, _super);
    function FormlyFieldSimpleQueryChooserComponent(route) {
        var _this = _super.call(this) || this;
        _this.route = route;
        _this.withFilterInputs = true;
        _this.subscriptions = new Subscription();
        return _this;
    }
    FormlyFieldSimpleQueryChooserComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setProperties(true);
        var objectTypeTipControl = this.form.get('templateOptions').get('tableConfig').get('objectTypeTip');
        var queryTipControl = this.form.get('templateOptions').get('tableConfig').get('queryTip');
        var filterInputsControl = this.form.get('templateOptions').get('tableConfig').get('filterInputs');
        var sortingOptionsControl = this.form.get('templateOptions').get('tableConfig').get('sortingOptions');
        if (objectTypeTipControl) {
            this.subscriptions.add(objectTypeTipControl.valueChanges.subscribe(function () { return _this.setProperties(); }));
        }
        this.subscriptions.add(this.formControl.valueChanges.subscribe(function (queryAndFilterOptions) {
            if (queryTipControl) {
                queryTipControl.setValue(queryAndFilterOptions.queryTip);
            }
            if (filterInputsControl) {
                filterInputsControl.setValue(queryAndFilterOptions.filterInputs);
            }
            if (sortingOptionsControl) {
                sortingOptionsControl.setValue(queryAndFilterOptions.sortingOptions);
            }
        }));
        // This will work for the references within primary router outlet.
        this.moduleTip = get(this.options, 'formState.moduleTip', null) || this.route.snapshot.params['moduleTip'];
        if (Object.keys(this.to).includes('withFilterInputs')) {
            this.withFilterInputs = this.to.withFilterInputs;
        }
    };
    FormlyFieldSimpleQueryChooserComponent.prototype.setProperties = function (firstTime) {
        if (firstTime === void 0) { firstTime = false; }
        var newObjectTypeTip = get(this, 'field.templateOptions.objectTypeTip', null);
        if (!firstTime && this.objectTypeTip !== newObjectTypeTip) {
            this.formControl.setValue({ queryTip: null, filterInputs: [] });
        }
        this.objectTypeTip = newObjectTypeTip;
    };
    FormlyFieldSimpleQueryChooserComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    return FormlyFieldSimpleQueryChooserComponent;
}(FieldType));
export { FormlyFieldSimpleQueryChooserComponent };
