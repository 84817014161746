import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { ToastService } from '../../shell/services/toast.service';
import { SideSheetService } from '../../side-sheet/side-sheet.service';
import { INTERPOLATION_TYPE, ISubstitutionCandidate } from '../../form/string-interpolation/string-interpolation.service';
import { RECIPIENT_SUPER_TYPE_TIP, SubstitutionMetaService } from '../../data/string-interpolation/substitution-meta.service';
import {
  FieldFormulaSideSheetComponent
} from '../../object/field-formula-side-sheet/field-formula-side-sheet/field-formula-side-sheet.component';
import { Formula, Tip } from '../../data/models/types';
import { AssignToSystemSideSheetComponent } from './assign-to-system-side-sheet/assign-to-system-side-sheet.component';
import { IWorkflowVariables } from '../../models/workflow';
// tslint:disable-next-line:max-line-length
import { IValueChooserItem } from '../../settings/workflow-designer/node-editors/shared/workflow-property-selector/workflow-property-chooser/workflow-property-chooser.component';
import { ResponseLinksSideSheetComponent } from './response-links-side-sheet/response-links-side-sheet.component';
import { DisplayFormulaWrapperService, IDisplayFormulaRule } from '../../util/display-formula-wrapper.service';
import { addContextFormulaWrapper, removeContextFormulaWrapper } from '../../util/context-formula-wrapper';
import { CONTEXT_VAR_NAME } from '../../message/message-utils.service';
import { MetaDataKey } from '../../object/field-formula-side-sheet/meta-data-formulas';
import { getSpecialContextFormulaMeta } from '../../util/special-context-formula';
import { get } from 'lodash';
import { SEQUENCE_INTEGER_FORMULA, SEQUENCE_INTEGER_LABEL } from './sequence-integer';
import {
  AssignToModuleOptionsSideSheetComponent
} from './assign-to-module-options-side-sheet/assign-to-module-options-side-sheet.component';

@Component({
  selector: 'app-dynamic-field-chooser-side-sheet',
  templateUrl: './dynamic-field-chooser-side-sheet.component.html',
  styleUrls: ['./dynamic-field-chooser-side-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicFieldChooserSidesheetComponent {
  // required for Recipients
  recipientSuperTypeTip: RECIPIENT_SUPER_TYPE_TIP;

  interpolationType: INTERPOLATION_TYPE;

  contextTypeTips: Tip[] = [];

  workflowVariables: IWorkflowVariables;

  metaDataKeysToExclude: MetaDataKey[] = [MetaDataKey.urlLink];

  displayFormulaRule: IDisplayFormulaRule = { date: true, datetime: true, list: true };

  // internal
  loading$ = new BehaviorSubject<boolean>(false);

  INTERPOLATION_TYPE = INTERPOLATION_TYPE;

  useContextFormula = false;

  hideSystemCurrentUserOption = false;

  // for sequence
  showSequence: boolean;

  // for module options
  showModuleOptions: boolean;
  moduleTip: Tip;

  // required
  done: (substitutionCandidate: ISubstitutionCandidate) => void = (_) => {};

  constructor(
    private sideSheetService: SideSheetService,
    private substitutionMetaService: SubstitutionMetaService,
    private toastService: ToastService,
    private displayFormulaWrapperService: DisplayFormulaWrapperService
  ) {}

  openAssignToSystemSideSheet() {
    const sheetRef = this.sideSheetService.push(AssignToSystemSideSheetComponent);
    const sheetInstance: AssignToSystemSideSheetComponent = sheetRef.componentInstance;

    sheetInstance.hideCurrentUserOption = this.hideSystemCurrentUserOption;

    sheetInstance.done = (selectedItem: string) => {
      const substitutionCandidate = this.substitutionMetaService.getSubstitutionCandidateFromSystem(
        selectedItem,
        this.interpolationType
      );

      if (!substitutionCandidate) {
        this.sideSheetService.pop(); // removes AssignToSystemSideSheetComponent
        this.sideSheetService.pop(); // removes this
        return;
      }

      this.done(substitutionCandidate);
      this.sideSheetService.pop(); // removes AssignToSystemSideSheetComponent
    };
  }

  openFieldFormulaSideSheet() {
    const sheetRef = this.sideSheetService.push(FieldFormulaSideSheetComponent);
    const sheetInstance: FieldFormulaSideSheetComponent = sheetRef.componentInstance;

    sheetInstance.objectTypeTip = this.recipientSuperTypeTip;
    sheetInstance.minSelect = 1;
    sheetInstance.maxSelect = 1;
    sheetInstance.metaDataKeysToExcluded = this.metaDataKeysToExclude;
    sheetInstance.displayFormulaRule = this.displayFormulaRule;

    sheetInstance.onDone = (formulaStrs: string[]) => {
      if (!formulaStrs || !formulaStrs.length) {
        this.sideSheetService.pop(); // removes this
      }

      const formula = formulaStrs[0];

      this.loading$.next(true);
      this.substitutionMetaService
        .getRecipientSubstitutionCandidateFromFormula(formula, this.interpolationType, this.recipientSuperTypeTip)
        .pipe(first())
        .subscribe(
          (substitutionCandidate: ISubstitutionCandidate) => {
            this.loading$.next(false);
            this.done(substitutionCandidate); // should remove this
          },
          () => {
            this.toastService.showErrorToast('Unable to retrieve value at this time');
            this.sideSheetService.pop(); // removes this
          }
        );
    };
  }

  openResponseLinksSideSheet() {
    const sheetRef = this.sideSheetService.push(ResponseLinksSideSheetComponent);
    const sheetInstance: ResponseLinksSideSheetComponent = sheetRef.componentInstance;

    sheetInstance.done = substitutionCandidate => this.done(substitutionCandidate);
  }

  openContextTypeFieldFormulaSideSheet(contextTypeTip: Tip) {
    const sheetRef = this.sideSheetService.push(FieldFormulaSideSheetComponent);
    const sheetInstance: FieldFormulaSideSheetComponent = sheetRef.componentInstance;

    sheetInstance.objectTypeTip = contextTypeTip;
    sheetInstance.minSelect = 1;
    sheetInstance.maxSelect = 1;
    sheetInstance.metaDataKeysToExcluded = this.metaDataKeysToExclude;
    sheetInstance.displayFormulaRule = this.displayFormulaRule;
    sheetInstance.formatsFields = true;

    sheetInstance.onDone = (formulaStrs: Formula[]) => {
      if (!formulaStrs || !formulaStrs.length) {
        this.sideSheetService.pop(); // removes this
      }

      const formula = formulaStrs[0];

      this.loading$.next(true);

      this.substitutionMetaService
        .getSubstitutionCandidateFromFormula(formula, this.interpolationType, contextTypeTip)
        .pipe(first())
        .subscribe(
          (substitutionCandidate: ISubstitutionCandidate) => {
            if (get(getSpecialContextFormulaMeta(substitutionCandidate.value), 'metaData.key') === MetaDataKey.urlLink) {
              substitutionCandidate.label = 'URL link';
              if (this.recipientSuperTypeTip === RECIPIENT_SUPER_TYPE_TIP.EMAIL) {
                substitutionCandidate.value = `REPLACE("<a href='%Link%'>Link</a>", "%Link%", ${ substitutionCandidate.value })`;
              }
            }
            // override the value with a context formula
            if (this.useContextFormula) {
              substitutionCandidate.value = addContextFormulaWrapper(substitutionCandidate.value, {
                name: 'VAR',
                args: [CONTEXT_VAR_NAME]
              });
            }

            this.loading$.next(false);
            this.done(substitutionCandidate); // should remove this
          },
          () => {
            this.toastService.showErrorToast('Unable to retrieve value at this time');
            this.sideSheetService.pop(); // removes this
          }
        );
    };
  }

  onWorkflowVariablesChosen(varType: string, item: IValueChooserItem) {
    this.loading$.next(true);

    // remove the display wrapper to be evaluated for label.
    const displayWrapperRemovedFormula = this.displayFormulaWrapperService.removeDisplayFormulaWrapper(
      removeContextFormulaWrapper(item.formula)
    );

    this.substitutionMetaService
      .getSubstitutionCandidateFromFormula(displayWrapperRemovedFormula, this.interpolationType)
      .pipe(first())
      .subscribe(
        (substitutionCandidate: ISubstitutionCandidate) => {
          // override the value back the wrapped formula;
          substitutionCandidate.value = item.formula;
          // check if the result label is an empty string, if it is then replace it with the variable label instead.
          if (!substitutionCandidate.label) {
            substitutionCandidate.label = this.workflowVariables[varType][item.selectedIndex].label;
          } else {
            substitutionCandidate.label =
              `${ this.workflowVariables[varType][item.selectedIndex].label } > ${ substitutionCandidate.label }`;
          }
          this.loading$.next(false);
          this.done(substitutionCandidate); // should remove this
        },
        () => {
          this.toastService.showErrorToast('Unable to retrieve value at this time');
          this.sideSheetService.pop(); // removes this
        }
      );
  }

  insertSequenceInteger() {
    const substitutionCandidate: ISubstitutionCandidate = {
      label: SEQUENCE_INTEGER_LABEL,
      type: INTERPOLATION_TYPE.general,
      value: SEQUENCE_INTEGER_FORMULA
    };

    this.done(substitutionCandidate);
  }

  openModuleDefaultValuesSideSheet() {
    const sheetRef = this.sideSheetService.push(AssignToModuleOptionsSideSheetComponent);
    const sheetInstance: AssignToModuleOptionsSideSheetComponent = sheetRef.componentInstance;

    sheetInstance.setProps({ moduleTip: this.moduleTip });

    sheetInstance.done = (substitutionCandidate: ISubstitutionCandidate) => {
      if (!substitutionCandidate) {
        this.sideSheetService.pop();
        this.sideSheetService.pop();
        return;
      }

      this.done(substitutionCandidate);
      this.sideSheetService.pop();
    };
  }
}
