import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { FieldType } from '@ngx-formly/core';
import { get } from 'lodash';
import { supertypeScheme } from '../../../models/supertype';
import { typeScheme } from '../../../models/type';
import { FIELD_DATA_TYPE } from '../../../models/field';
import { QuoteString } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
var FormlyFieldTableColumnsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FormlyFieldTableColumnsComponent, _super);
    function FormlyFieldTableColumnsComponent(objectService) {
        var _this = _super.call(this) || this;
        _this.objectService = objectService;
        _this.subscriptions = new Subscription();
        return _this;
    }
    FormlyFieldTableColumnsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setProperties(true);
        var objectTypeTipControl = this.form.get('templateOptions').get('tableConfig').get('objectTypeTip');
        if (objectTypeTipControl) {
            this.subscriptions.add(objectTypeTipControl.valueChanges.subscribe(function () { return _this.setProperties(); }));
        }
    };
    FormlyFieldTableColumnsComponent.prototype.updateColumnItems = function (columnProps) {
        var objectTypeTipControl = this.form.get('templateOptions').get('tableConfig').get('columnItems');
        if (objectTypeTipControl) {
            objectTypeTipControl.setValue(columnProps);
        }
    };
    FormlyFieldTableColumnsComponent.prototype.setProperties = function (firstTime) {
        var _this = this;
        if (firstTime === void 0) { firstTime = false; }
        var newObjectTypeTip = get(this, 'field.templateOptions.objectTypeTip', null);
        if (!firstTime && this.objectTypeTip !== newObjectTypeTip) {
            this.formControl.setValue([]);
            if (newObjectTypeTip) {
                var scheme = /^app\/super-type\//.test(newObjectTypeTip) ? supertypeScheme : typeScheme;
                this.objectService.getObject(newObjectTypeTip, scheme).pipe(first()).subscribe(function (objectType) {
                    _this.form.get('templateOptions').get('tableConfig').get('columnItems').setValue([]);
                    _this.formControl.setValue(objectType.field.filter(function (field) { return field.datatype !== FIELD_DATA_TYPE.object; }).map(function (field) { return "FIELD(" + QuoteString(field.$tip) + ")"; }));
                });
            }
        }
        this.objectTypeTip = get(this, 'field.templateOptions.objectTypeTip', null);
    };
    FormlyFieldTableColumnsComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    return FormlyFieldTableColumnsComponent;
}(FieldType));
export { FormlyFieldTableColumnsComponent };
