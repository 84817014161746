/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-not-found.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./page-not-found.component";
var styles_PageNotFoundComponent = [i0.styles];
var RenderType_PageNotFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageNotFoundComponent, data: {} });
export { RenderType_PageNotFoundComponent as RenderType_PageNotFoundComponent };
export function View_PageNotFoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "screen"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "background"], ["src", "/assets/v1.627.2-beta/assets/img/404/404-background.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "cloud"], ["src", "/assets/v1.627.2-beta/assets/img/404/404-cloud.gif"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Whoops\u2026"])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "h6", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["It looks like you\u2019re lost."])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You may have an old link or maybe the page was moved."])), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "a", [["class", "btn btn-primary home-button"], ["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Let's go home!"]))], function (_ck, _v) { var currVal_2 = "/"; _ck(_v, 13, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 13).target; var currVal_1 = i1.ɵnov(_v, 13).href; _ck(_v, 12, 0, currVal_0, currVal_1); }); }
export function View_PageNotFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-not-found", [], null, null, null, View_PageNotFoundComponent_0, RenderType_PageNotFoundComponent)), i1.ɵdid(1, 114688, null, 0, i4.PageNotFoundComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageNotFoundComponentNgFactory = i1.ɵccf("app-page-not-found", i4.PageNotFoundComponent, View_PageNotFoundComponent_Host_0, {}, {}, []);
export { PageNotFoundComponentNgFactory as PageNotFoundComponentNgFactory };
