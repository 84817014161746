import * as tslib_1 from "tslib";
import { concat, EMPTY, of, Subject, throwError } from 'rxjs';
import { catchError, distinctUntilKeyChanged, filter, first, map, switchMap, finalize, publishReplay, refCount } from 'rxjs/operators';
import { CacheOpt, getCache } from '../util/cache';
import { distinctUntilSidsChanged } from '../util/distinct-until-sids-changed';
import dataConstants from './constants';
import { EnoFactory } from './EnoFactory';
import { AccessDeniedError } from './errors/server/AccessDeniedError';
import { ESessionMessageDataType } from './pub-sub.service';
import * as i0 from "@angular/core";
import * as i1 from "./ensrv.service";
import * as i2 from "./eno-cache.service";
import * as i3 from "./eno-watch.service";
import * as i4 from "./op-pull.service";
import * as i5 from "./pub-sub.service";
import * as i6 from "../util/logger.service";
var EnoService = /** @class */ (function () {
    function EnoService(_ensrvService, _enoCacheService, _enoWatchService, _opPullService, _pubSubService, _loggerService) {
        var _this = this;
        this._ensrvService = _ensrvService;
        this._enoCacheService = _enoCacheService;
        this._enoWatchService = _enoWatchService;
        this._opPullService = _opPullService;
        this._pubSubService = _pubSubService;
        this._loggerService = _loggerService;
        this._enoFactory = new EnoFactory();
        this._enoSubjects = {};
        this._batchSubjects = {};
        this.awaitingPullTips = new Set();
        this._pubSubService.receiveSessionMessage(ESessionMessageDataType.tipWatch).pipe(filter(function (data) {
            return !(_this._enoSubjects[data.op] === undefined && _this._batchSubjects[data.op] === undefined);
        })).subscribe(function (data) { return _this._handleTipWatchData(data); });
    }
    EnoService.prototype._handleTipWatchData = function (data) {
        var enoSubject = this._enoSubjects[data.op];
        var batchSubject = this._batchSubjects[data.op];
        // This is op/pull with watch false and if-not-sid empty
        var opPullEno = this._opPullService.getOpPull(data.op);
        this._loggerService.debug('[ENO_WATCH] Handling....', data);
        if (enoSubject) {
            this._doOpPullForReadEno(opPullEno, enoSubject, opPullEno.getFieldStringValue('op/pull/tip'), opPullEno.getFieldValues('op/pull/branch')[0], opPullEno.getFieldNumberValue('op/pull/recursive-depth'), opPullEno.getFieldValues('op/pull/recursive-field'));
            return;
        }
        // @toDo: We can improve this part by specifying if-not-sid with sids we have in the cache. Severity: Low
        this._doOpPullForReadEnos(opPullEno, batchSubject, [], opPullEno.getFieldValues('op/pull/tip'), opPullEno.getFieldValues('op/pull/branch')[0]);
    };
    // Recursive pulling is just for pre-pulling objects to cache it.
    // So, note that readEno always returns a single eno even if recursive feature is used.
    EnoService.prototype.readEno = function (tip, _a) {
        var _b = _a === void 0 ? {
            branch: dataConstants.BRANCH_MASTER,
            recursiveDepth: 0,
            recursiveFields: [],
            useCache: null
        } : _a, _c = _b.branch, branch = _c === void 0 ? dataConstants.BRANCH_MASTER : _c, _d = _b.recursiveDepth, recursiveDepth = _d === void 0 ? 0 : _d, _e = _b.recursiveFields, recursiveFields = _e === void 0 ? [] : _e, _f = _b.useCache, useCache = _f === void 0 ? null : _f;
        var enosInCache = this._enoCacheService.hasEno(tip, branch);
        var enoCached = this._enoCacheService.getEno(tip, branch);
        var toWatch = !this._enoWatchService.isWatched(tip, branch);
        var cacheOpt = getCache(useCache);
        var explicitUseCache = cacheOpt === CacheOpt.USE_CACHE;
        // @toDo: Always fallback to master. The below code should be the correct code when branch fallback is completely implemented
        // const branches = branch === dataConstants.BRANCH_MASTER ? [branch] : [branch, dataConstants.BRANCH_MASTER];
        var branches = branch === dataConstants.BRANCH_MASTER ? [branch] : [dataConstants.BRANCH_MASTER];
        // This opPull Eno is the deterministic op/pull eno without dynamic part: watch and ifNotSid
        var opPullKey = this._opPullService.createOpPull({
            tip: [tip],
            branch: branches,
            watch: true,
            recursiveDepth: recursiveDepth,
            recursiveField: recursiveFields
        }).tip;
        var opPullEnoToSend = this._opPullService.createOpPull({
            tip: [tip],
            branch: branches,
            watch: toWatch,
            recursiveDepth: recursiveDepth,
            recursiveField: recursiveFields
            // This being disabled is temporary. EIM-7110
            // ifNotSid: (recursiveDepth === 0 && enosInCache && !explicitUseCache ? [enoCached.sid] : [])
        });
        var subject = this._initEnoSubject(opPullKey);
        var enoObservable = subject.observable.pipe(distinctUntilKeyChanged('sid'));
        var cachedAndEnoObservable = concat(of(enoCached), enoObservable).pipe(distinctUntilKeyChanged('sid'));
        // if useCache is explicitly true return the cached eno, do not request an updated copy
        // only if we have enosInCache
        if (cacheOpt === CacheOpt.USE_CACHE && enosInCache) {
            return cachedAndEnoObservable;
        }
        // for all other cases request a fresh copy of the eno
        this._doOpPullForReadEno(opPullEnoToSend, subject, tip, branch, recursiveDepth, recursiveFields);
        // if useCache is explicitly false, we only want fresh result
        if (cacheOpt === CacheOpt.USE_NETWORK_NO_CACHE) {
            return enoObservable;
        }
        // otherwise check if we have enos cached
        if (enosInCache) {
            // we do, great, lets return them along with an observable that will emit the fresh result
            return cachedAndEnoObservable;
        }
        // finally if we didn't have any enos in our cache return the observable for the fresh result
        return enoObservable;
    };
    EnoService.prototype._doOpPullForReadEno = function (opPullEno, subject, tip, branch, recursiveDepth, recursiveFields) {
        var _this = this;
        // We do not want to send the same
        if (this.awaitingPullTips.has(opPullEno.tip)) {
            return;
        }
        this.awaitingPullTips.add(opPullEno.tip);
        this._ensrvService.send([opPullEno]).pipe(catchError(function (e) {
            _this.awaitingPullTips.delete(opPullEno.tip);
            _this._loggerService.error(e);
            _this._sendEnoSubjectError(subject, e);
            return EMPTY;
        })).subscribe(function (batch) {
            _this.awaitingPullTips.delete(opPullEno.tip);
            if (_this._handleReadEnoError(subject, batch, tip, branch, recursiveDepth, recursiveFields)) {
                return;
            }
            _this._enoWatchService.markAsWatched(tip, branch);
            _this._cacheBatchOnRequestedBranch(batch, [tip], branch);
            var resultEno = _this._extractTipEno(tip, batch);
            if (resultEno === null) {
                if (opPullEno.getFieldValues('op/pull/if-not-sid').length === 0) {
                    // Show error if it wasn't a if-not-sid
                    _this._sendEnoSubjectError(subject, new Error("op/pull response batch does not contain requested tip. tip: " + tip + ", batch: [" + batch.map(function (x) { return x.tip; }) + "]"));
                }
                return;
            }
            subject.subject.next(resultEno);
        });
    };
    EnoService.prototype._initEnoSubject = function (opPullTip) {
        var _this = this;
        if (!this._enoSubjects[opPullTip]) {
            var subject = new Subject();
            var observable = subject.pipe(finalize(function () { return _this._removeEnoSubject(opPullTip); }), publishReplay(1), refCount());
            this._enoSubjects[opPullTip] = { subject: subject, observable: observable };
        }
        return this._enoSubjects[opPullTip];
    };
    EnoService.prototype._removeEnoSubject = function (opPullTip) {
        if (this._enoSubjects[opPullTip]) {
            delete this._enoSubjects[opPullTip];
            // @todo Send op/watch/unregister
        }
    };
    EnoService.prototype._sendEnoSubjectError = function (subject, error) {
        var _this = this;
        Object.keys(this._enoSubjects).forEach(function (opPullTip) {
            if (_this._enoSubjects[opPullTip] === subject) {
                subject.subject.error(error);
                delete _this._enoSubjects[opPullTip];
            }
        });
    };
    EnoService.prototype._extractTipEno = function (tip, batch) {
        var e_1, _a;
        try {
            for (var batch_1 = tslib_1.__values(batch), batch_1_1 = batch_1.next(); !batch_1_1.done; batch_1_1 = batch_1.next()) {
                var eno = batch_1_1.value;
                if (eno.tip === tip) {
                    return eno;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (batch_1_1 && !batch_1_1.done && (_a = batch_1.return)) _a.call(batch_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return null;
    };
    EnoService.prototype._handleReadEnoError = function (subject, batch, tip, branch, recursiveDepth, recursiveFields) {
        var accessDeniedTip = this._checkAccessDenied(batch, tip, branch);
        if (accessDeniedTip) {
            this._sendEnoSubjectError(subject, new AccessDeniedError(accessDeniedTip, [branch]));
            return true;
        }
        if (this._containsError(batch)) {
            var e = new Error("Error happens while reading object tip: " + tip + ", branch: " + branch + ",                           recursiveField: [" + recursiveFields.join(', ') + "], recursiveDepth: " + recursiveDepth);
            this._loggerService.error(e);
            this._sendEnoSubjectError(subject, e);
            return true;
        }
        return false;
    };
    EnoService.prototype.readEnos = function (tips, _a) {
        var _this = this;
        var _b = _a === void 0 ? {
            branch: dataConstants.BRANCH_MASTER,
            useCache: null
        } : _a, _c = _b.branch, branch = _c === void 0 ? dataConstants.BRANCH_MASTER : _c, _d = _b.useCache, useCache = _d === void 0 ? null : _d;
        if (tips.length === 0) {
            return of([]);
        }
        var toWatch = false;
        var sidsNotToPull = [];
        var hitCacheAll = true;
        var batchFromCache = [];
        tips.forEach(function (tip) {
            var hitCache = _this._enoCacheService.hasEno(tip, branch);
            var enoCached = _this._enoCacheService.getEno(tip, branch);
            toWatch = toWatch || !_this._enoWatchService.isWatched(tip, branch);
            if (!hitCache) {
                hitCacheAll = false;
                return;
            }
            batchFromCache.push(enoCached);
            sidsNotToPull.push(enoCached.sid);
        });
        // Always fallback to master
        var branches = branch === dataConstants.BRANCH_MASTER ? [branch] : [branch, dataConstants.BRANCH_MASTER];
        var opPullKey = this._opPullService.createOpPull({
            tip: tips,
            branch: branches,
            watch: true
        }).tip;
        var opPullEnoToSend = this._opPullService.createOpPull({
            tip: tips,
            branch: branches,
            watch: toWatch
            // This being disabled is temporary. EIM-7110
            // ifNotSid: sidsNotToPull
        });
        var subject = this._initBatchSubject(opPullKey);
        if (!(hitCacheAll && useCache === true)) {
            this._doOpPullForReadEnos(opPullEnoToSend, subject, batchFromCache, tips, branch);
        }
        if (hitCacheAll) {
            return concat(of(batchFromCache), subject.observable).pipe(distinctUntilSidsChanged());
        }
        return subject.observable.pipe(distinctUntilSidsChanged());
    };
    EnoService.prototype._doOpPullForReadEnos = function (opPullEno, subject, batchFromCache, tips, branch) {
        var _this = this;
        if (this.awaitingPullTips.has(opPullEno.tip)) {
            return;
        }
        this.awaitingPullTips.add(opPullEno.tip);
        this._ensrvService.send([opPullEno]).pipe(catchError(function (e) {
            _this.awaitingPullTips.delete(opPullEno.tip);
            _this._loggerService.error(e);
            _this._sendBatchSubjectError(subject, e);
            return EMPTY;
        })).subscribe(function (batchFromServer) {
            _this.awaitingPullTips.delete(opPullEno.tip);
            if (_this._handleReadEnosError(subject, batchFromServer, tips, branch)) {
                return;
            }
            _this._enoWatchService.markAsWatched(tips, branch);
            _this._cacheBatchOnRequestedBranch(batchFromServer, tips, branch);
            subject.subject.next(_this._mergeBatch(tips, batchFromCache, batchFromServer));
        });
    };
    EnoService.prototype._initBatchSubject = function (opPullTip) {
        if (!this._batchSubjects[opPullTip]) {
            var subject = new Subject();
            var observable = subject.pipe();
            this._batchSubjects[opPullTip] = { subject: subject, observable: observable };
        }
        return this._batchSubjects[opPullTip];
    };
    EnoService.prototype._sendBatchSubjectError = function (subject, error) {
        var _this = this;
        Object.keys(this._batchSubjects).forEach(function (opPullTip) {
            if (_this._batchSubjects[opPullTip] === subject) {
                subject.subject.error(error);
                delete _this._batchSubjects[opPullTip];
            }
        });
    };
    // merge batchFromServer into batchFromCache preserving the order of tipsForOrder.
    // This method assumes given tips all exist either in batchFromCache or batchFromServer
    EnoService.prototype._mergeBatch = function (tipsForOrder, batchFromCache, batchFromServer) {
        var concatenatedBatch = batchFromServer.concat(batchFromCache);
        return tipsForOrder.map(function (tip) {
            var e_2, _a;
            try {
                for (var concatenatedBatch_1 = tslib_1.__values(concatenatedBatch), concatenatedBatch_1_1 = concatenatedBatch_1.next(); !concatenatedBatch_1_1.done; concatenatedBatch_1_1 = concatenatedBatch_1.next()) {
                    var eno = concatenatedBatch_1_1.value;
                    if (tip === eno.tip) {
                        return eno;
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (concatenatedBatch_1_1 && !concatenatedBatch_1_1.done && (_a = concatenatedBatch_1.return)) _a.call(concatenatedBatch_1);
                }
                finally { if (e_2) throw e_2.error; }
            }
        });
    };
    EnoService.prototype._handleReadEnosError = function (subject, batch, tips, branch) {
        var accessDeniedTip = this._checkAccessDenied(batch, tips, branch);
        if (accessDeniedTip) {
            this._sendBatchSubjectError(subject, new AccessDeniedError(accessDeniedTip, [branch]));
            return true;
        }
        if (this._containsError(batch)) {
            var e = new Error("Error happens while reading object tips: [" + tips.join(', ') + "], branch: " + branch);
            this._loggerService.error(e);
            this._sendBatchSubjectError(subject, e);
            return true;
        }
        return false;
    };
    // tips are the tips we requested, this filter is necessary as response can come with any other enos
    EnoService.prototype._cacheBatchOnRequestedBranch = function (batch, tips, branch) {
        var _this = this;
        batch.filter(function (eno) {
            return tips.indexOf(eno.tip) > -1;
        }).forEach(function (eno) {
            // No matter which branch the actual eno belongs to, cache it against the requested branch
            // This is to support branch fallback, DO NOT REMOVE THIS CODE
            _this._enoCacheService.setEno(eno.tip, eno, branch);
        });
    };
    // Returns the tip that is access denied, if access denied error is not found, returns null
    EnoService.prototype._checkAccessDenied = function (batch, tips, branch) {
        if (!Array.isArray(tips)) {
            tips = [tips];
        }
        var result = null;
        batch.forEach(function (eno) {
            if (eno.getType() === 'error' && eno.getFieldStringValue('error/message/tip') === 'error/message/security/access-denied' &&
                tips.indexOf(eno.getFieldStringValue('error/object/tip')) > -1 && branch === eno.getFieldStringValue('error/object/branch')) {
                result = eno.getFieldStringValue('error/object/tip');
            }
        });
        return result;
    };
    EnoService.prototype._containsError = function (batch) {
        var e_3, _a;
        try {
            for (var batch_2 = tslib_1.__values(batch), batch_2_1 = batch_2.next(); !batch_2_1.done; batch_2_1 = batch_2.next()) {
                var eno = batch_2_1.value;
                if (eno.getType() === 'error') {
                    return true;
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (batch_2_1 && !batch_2_1.done && (_a = batch_2.return)) _a.call(batch_2);
            }
            finally { if (e_3) throw e_3.error; }
        }
        return false;
    };
    EnoService.prototype.writeEno = function (eno) {
        return this.writeEnos([eno]);
    };
    EnoService.prototype.writeEnos = function (enos) {
        var _this = this;
        var batch = enos;
        batch = batch.concat(this._enoWatchService.getWatchOpPulls(enos));
        return this._ensrvService.send(batch).pipe(switchMap(function (enosInResponse) {
            var e_4, _a, e_5, _b;
            // @toDo: Should handle validation failure, which is the case that serverT branch and clientT branch not matched. Severity: High
            var enoTipsWithErrors = [];
            var errorTips = [];
            try {
                for (var enosInResponse_1 = tslib_1.__values(enosInResponse), enosInResponse_1_1 = enosInResponse_1.next(); !enosInResponse_1_1.done; enosInResponse_1_1 = enosInResponse_1.next()) {
                    var eno = enosInResponse_1_1.value;
                    if (eno.hasError()) {
                        enoTipsWithErrors.push(eno.tip);
                        continue;
                    }
                    // @todo Handle conflict-error. Severity: High
                    if (eno.getType() === 'error') {
                        errorTips.push(eno.tip);
                    }
                }
            }
            catch (e_4_1) { e_4 = { error: e_4_1 }; }
            finally {
                try {
                    if (enosInResponse_1_1 && !enosInResponse_1_1.done && (_a = enosInResponse_1.return)) _a.call(enosInResponse_1);
                }
                finally { if (e_4) throw e_4.error; }
            }
            try {
                for (var enos_1 = tslib_1.__values(enos), enos_1_1 = enos_1.next(); !enos_1_1.done; enos_1_1 = enos_1.next()) {
                    var enoToCache = enos_1_1.value;
                    if (enoTipsWithErrors.indexOf(enoToCache.tip) > -1) {
                        continue;
                    }
                    _this._enoCacheService.setEno(enoToCache.tip, enoToCache);
                }
            }
            catch (e_5_1) { e_5 = { error: e_5_1 }; }
            finally {
                try {
                    if (enos_1_1 && !enos_1_1.done && (_b = enos_1.return)) _b.call(enos_1);
                }
                finally { if (e_5) throw e_5.error; }
            }
            if (errorTips.length) {
                return throwError("[EnoService] Failed to write Eno(s). Error object detected: [" + errorTips.join(', ') + "].");
            }
            if (enoTipsWithErrors.length) {
                return throwError("[EnoService] Failed to write Eno(s) \"" + enoTipsWithErrors.join(', ') + "\".");
            }
            return of(enosInResponse);
        }));
    };
    EnoService.prototype.mergeBranch = function (sourceBranch, targetBranch, expectedSids) {
        var _this = this;
        if (targetBranch === void 0) { targetBranch = dataConstants.BRANCH_MASTER; }
        if (expectedSids === void 0) { expectedSids = []; }
        var opMergeFactory = new EnoFactory('op/merge', dataConstants.SECURITY.OP);
        var opMergeEno = opMergeFactory.setFields([
            { tip: 'op/merge/branch', value: [sourceBranch] },
            { tip: 'op/merge/to', value: [targetBranch] },
            { tip: 'op/merge/expect', value: expectedSids }
        ]).makeEno();
        var responseMergeObserver = this._ensrvService.getEnoReceiver('response/merge').pipe(first(function (responseMerge) {
            return responseMerge.getFieldStringValue('response/merge/op-tip') === opMergeEno.tip;
        }), switchMap(function (responseMerge) {
            var mergeErrors = responseMerge.getFieldValues('response/merge/error');
            if (mergeErrors.length > 0) {
                return throwError("Merge failed with errors. " + mergeErrors.toString());
            }
            return of(true);
        }));
        this._ensrvService.send([opMergeEno]).subscribe(function () { }, function (e) {
            _this._loggerService.error('Failed to send op/merge', e);
        });
        return responseMergeObserver;
    };
    // Delete a single eno by tip
    EnoService.prototype.deleteEno = function (tip) {
        var _this = this;
        return this.readEno(tip).pipe(first(), switchMap(function (eno) {
            // we don't expect deleted objects to be watched until we support undelete, so marking it as watched to avoid creating opPull
            _this._enoWatchService.markAsWatched(tip);
            return _this.writeEno(_this._enoFactory.setProtoToPatch(eno).setDeleted(true).makeEno());
        }));
    };
    // Delete multiple enos given tips
    EnoService.prototype.deleteEnos = function (tips) {
        var _this = this;
        return this.readEnos(tips).pipe(first(), map(function (enos) { return enos.map(function (eno) {
            // we don't expect deleted objects to be watched until we support undelete, so marking it as watched to avoid creating opPull
            _this._enoWatchService.markAsWatched(eno.tip);
            return _this._enoFactory.setProtoToPatch(eno).setDeleted(true).makeEno();
        }); }), switchMap(function (deletedEnos) { return _this.writeEnos(deletedEnos); }));
    };
    EnoService.prototype.getTypeTip = function (tip) {
        return this.getTypeTips([tip]).pipe(map(function (tips) {
            return tips[0];
        }));
    };
    /**
     * This method preserves the order so that you can rely on the array index
     *
     * @param tips  The object tips to find out its type tips
     */
    EnoService.prototype.getTypeTips = function (tips) {
        return this.readEnos(tips).pipe(map(function (batch) {
            return batch.map(function (x) { return x.getType(); });
        }), first() // Type never changes, this shouldn't be long running observable
        );
    };
    EnoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EnoService_Factory() { return new EnoService(i0.ɵɵinject(i1.EnsrvService), i0.ɵɵinject(i2.EnoCacheService), i0.ɵɵinject(i3.EnoWatchService), i0.ɵɵinject(i4.OpPullService), i0.ɵɵinject(i5.PubSubService), i0.ɵɵinject(i6.LoggerService)); }, token: EnoService, providedIn: "root" });
    return EnoService;
}());
export { EnoService };
