import { IInnerOperator, IOperators, reverseOperator } from './operators';
import { OPERATOR_TYPE } from '../../../models/query';

const datetimeEquals: IInnerOperator = {
  opNo: 2,
  toFormula: (leftArg, rightArg) => ({
    name: 'EQUALS',
    args: [
      { name: 'DATE_DIFF', args: [leftArg, rightArg, 'seconds'] },
      0
    ]
  })
};
const datetimeNotEquals = reverseOperator(datetimeEquals);

const datetimeLessThan: IInnerOperator = {
  opNo: 2,
  toFormula: (leftArg, rightArg) => ({
    name: 'OP',
    args: [
      { name: 'DATE_DIFF', args: [leftArg, { name: 'FIRST', args: [rightArg] }, 'seconds'] },
      '<',
      0
    ]
  })
};
const datetimeGreaterThanOrEqualTo = reverseOperator(datetimeLessThan);

const datetimeGreaterThan: IInnerOperator = {
  opNo: 2,
  toFormula: (leftArg, rightArg) => ({
    name: 'OP',
    args: [
      { name: 'DATE_DIFF', args: [leftArg, { name: 'LAST', args: [rightArg] }, 'seconds'] },
      '>',
      0
    ]
  })
};
const datetimeLessThanOrEqualTo = reverseOperator(datetimeGreaterThan);

const datetimeWithIn: IInnerOperator = {
  opNo: 2,
  toFormula: (leftArg, rightArg) => ({
    name: 'OP',
    args: [
      datetimeGreaterThanOrEqualTo.toFormula(leftArg, rightArg),
      '&&',
      datetimeLessThanOrEqualTo.toFormula(leftArg, rightArg)
    ]
  })
};
const datetimeNotWithIn = reverseOperator(datetimeWithIn);

export const datetimeOperators: IOperators = {
  operators: {
    [OPERATOR_TYPE.EQUALS]: datetimeEquals,
    [OPERATOR_TYPE.NOT_EQUALS]: datetimeNotEquals,
    [OPERATOR_TYPE.WITHIN]: datetimeWithIn,
    [OPERATOR_TYPE.NOT_WITHIN]: datetimeNotWithIn,
    [OPERATOR_TYPE.LESS_THAN]: datetimeLessThan, // leftArg is earlier than rightArg
    [OPERATOR_TYPE.GREATER_THAN]: datetimeGreaterThan, // leftArg is later than rightArg
    [OPERATOR_TYPE.LESS_THAN_EQUAL_TO]: datetimeLessThanOrEqualTo,
    [OPERATOR_TYPE.GREATER_THAN_EQUAL_TO]: datetimeGreaterThanOrEqualTo
  },
  getParts: formula => {
    let currentPart = formula;
    let negative = false;
    if (currentPart.name === 'NOT') {
      currentPart = currentPart.args[0];
      negative = true;
    }

    if (currentPart.name === 'OP') {
      if (currentPart.args[1] === '<') {
        return {
          operator: negative ? OPERATOR_TYPE.GREATER_THAN_EQUAL_TO : OPERATOR_TYPE.LESS_THAN,
          leftArg: currentPart.args[0].args[0],
          rightArg: currentPart.args[0].args[1].args[0]
        };
      }

      if (currentPart.args[1] === '>') {
        return {
          operator: negative ? OPERATOR_TYPE.LESS_THAN_EQUAL_TO : OPERATOR_TYPE.GREATER_THAN,
          leftArg: currentPart.args[0].args[0],
          rightArg: currentPart.args[0].args[1].args[0]
        };
      }

      if (currentPart.args[1] === '&&') {
        return {
          operator: negative ? OPERATOR_TYPE.NOT_WITHIN : OPERATOR_TYPE.WITHIN,
          leftArg: currentPart.args[0].args[0].args[0].args[0],
          rightArg: currentPart.args[0].args[0].args[0].args[1].args[0]
        };
      }

      throw new Error('Unknown operators:' + currentPart.name);
    }

    if (currentPart.name === 'EQUALS') {
      return {
        operator: negative ? OPERATOR_TYPE.NOT_EQUALS : OPERATOR_TYPE.EQUALS,
        leftArg: currentPart.args[0].args[0],
        rightArg: currentPart.args[0].args[1].args[0]
      };
    }

    throw new Error('Unknown operators: ' + currentPart.name);
  }
};
