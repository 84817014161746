import { Injectable } from '@angular/core';
import { EMPTY, merge, Observable, of, pipe, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';
import { cloneDeep, isEmpty } from 'lodash';

import { CacheOpt } from '../util/cache';
import dataConstants from '../data/constants';
import { EnsrvService } from './ensrv.service';
import { ESessionMessageDataType, ISessionMessageData, PubSubService } from './pub-sub.service';
import { Formula, Tip } from './models/types';
import { Eno } from './models/Eno';
import { EnoFactory } from './EnoFactory';
import { EnoCacheService } from './eno-cache.service';
import { IVars } from './vars.service';
import { CurrentDatetimeService, NOW_VAR_NAME } from '../util/current-datetime.service';
import { I18nService } from './i18n.service';
import { StaticResultAnalysis } from '../object/field-formula-side-sheet/field-formula-side-sheet/formula';

// @todo The watch flag is actually not optional. The server throws errors when watch is not explicitly provided.
export interface IFormulaMultiParams {
  formula: Formula;
  watch?: boolean;
  context?: Tip;
  type?: Tip;
  contextBranches?: Tip[];
  vars?: IVars;
}

// If you want to watch you cannot pass the tip in directly
// {formula: `REFERENCES("app/team-role:users", ${$tip})`},
// {formula: `REFERENCES("app/team-role:users", TIP())`, watch: true, context: $tip},

export interface IFormulaWatchSessionMessageData extends ISessionMessageData {
  type: ESessionMessageDataType.formulaWatch;
  op: Tip;
  tips: Tip[];
}

@Injectable({
  providedIn: 'root'
})
export class FormulaMultiService {
  requestEnoResponseEnoMap: Map<string, string> = new Map();

  constructor(
    private ensrvService: EnsrvService,
    private enoCache: EnoCacheService,
    private pubSubService: PubSubService,
    private i18nService: I18nService,
    private currentDatetimeService: CurrentDatetimeService
  ) {}

  evaluate(formulas: IFormulaMultiParams[], cache: CacheOpt = CacheOpt.USE_CACHE_THEN_NETWORK): Observable<string[][]> {
    if (isEmpty(formulas)) {
      return of([]);
    }

    // If the entire formula batch has static results then simply return them
    try {
      return of(formulas.map(formula => StaticResultAnalysis(formula.formula, formula.vars)));
    } catch (err) {}
  
    this.addNowVariable(formulas);

    return this.makeFormulaOperationEno$(formulas).pipe(
      switchMap((formulaOperationEno: Eno) => {
        const responseTip = this.requestEnoResponseEnoMap.get(formulaOperationEno.tip) || '';
        const existingEno = this.enoCache.getEno(responseTip);

        const formulaResponse$ = this.ensrvService
          .getEnoReceiver('response/formula')
          .pipe(transformMultiFormulaResponse(formulaOperationEno, this.requestEnoResponseEnoMap, this.enoCache));

        const formulaResponseWithCachedValue$ = this.ensrvService
          .getEnoReceiver('response/formula')
          .pipe(
            startWith(existingEno),
            transformMultiFormulaResponse(formulaOperationEno, this.requestEnoResponseEnoMap, this.enoCache)
          );

        const send = () => {
          return this.ensrvService.send([formulaOperationEno]).pipe(
            catchError((e) => {
              const error = new Error(`
            Error sending multi formula
            ------
            For request eno: ${JSON.stringify(formulaOperationEno, null, 4)}
            ------
            Original error: ${e}
           `);

              return throwError(error);
            }),
            // we never actually want to emit values via the send
            // values are emitted by the receiver
            // we just want to subscribe to it so it will get unsubscribed
            filter(() => false)
          );
        };

        const watch$ = formulas.filter((formulaParams: IFormulaMultiParams) => formulaParams.watch).length
          ? this.pubSubService.receiveSessionMessage(ESessionMessageDataType.formulaWatch).pipe(
            filter((data: IFormulaWatchSessionMessageData) => data.op === formulaOperationEno.tip),
            switchMap(send)
          )
          : EMPTY;

        if (cache === CacheOpt.USE_CACHE && existingEno) {
          return merge(formulaResponseWithCachedValue$, watch$);
        }

        const send$ = send();

        if (cache === CacheOpt.USE_CACHE_THEN_NETWORK && existingEno) {
          // using merge as want to be subscribed to send$
          return merge(send$, formulaResponseWithCachedValue$, watch$);
        }

        // the default is effectively the USE_NETWORK_NO_CACHE option
        // using merge as want to be subscribed to send$
        return merge(send$, formulaResponse$, watch$);
      })
    );
  }

  private makeFormulaOperationEno$(formulas: IFormulaMultiParams[]): Observable<Eno> {
    return this.i18nService.acceptableLocaleIds$.pipe(
      map(
        (acceptableLocaleIds: string[]) => formulaFactory
          .setBranch(dataConstants.BRANCH_MASTER)
          .setField('op/formula:multi-formula', [JSON.stringify(setDefaults(formulas))])
          .setField('op/formula:lang', acceptableLocaleIds)
          .makeEno()
      )
    );
  }

  private addNowVariable(formulas: IFormulaMultiParams[]): void {
    formulas.forEach(formula => {
      if (!formula.vars) {
        formula.vars = {};
      }
      formula.vars[NOW_VAR_NAME] = [this.currentDatetimeService.getCurrentDatetime()];
    });
  }
}

function enoTip(tip: Tip) {
  return function isEnoTip(formulaResponseEno: Eno) {
    const formulaOpTip = formulaResponseEno.getFieldStringValue('response/formula:op');
    return tip === formulaOpTip;
  };
}

function transformMultiFormulaResponse(multiFormulaEno, requestResponseEnoMap, enoCache) {
  return pipe(
    map(cloneDeep),
    filter(enoTip(multiFormulaEno.tip)),
    distinctUntilChanged(formulaEquivalent),
    map((formulaResponseEno: Eno) => {
      requestResponseEnoMap.set(multiFormulaEno.tip, formulaResponseEno.tip);
      const [jsonResult] = formulaResponseEno.getFieldValues('response/formula:multi-result');
      const [errorsResult] = formulaResponseEno.getFieldValues('response/formula:multi-errors');

      if (errorsResult) {
        const errorTips = JSON.parse(errorsResult) || [];

        const errors = errorTips
          .reduce((acc, error) => {
            if (error) {
              // the errors will be in the eno cache
              acc.push(enoCache.getEno(error));
            }
            return acc;
          }, []);

        if (errors.length) {
          throw new Error(`Error fetching formulas ${JSON.stringify(errors, null, 4)}`);
        }
      }

      const result = JSON.parse(jsonResult);
      return result;
    }),
    catchError((e) => {
      const error = new Error(`
            Error with formula response
            
            ------
            For request eno: ${JSON.stringify(multiFormulaEno, null, 4)}
            
            ------
            Original error: ${e}
           `);

      return throwError(error);
    })
  );
}

const formulaFactory = new EnoFactory('op/formula', dataConstants.SECURITY.OP);

function setDefaults(formulas: IFormulaMultiParams[]): IFormulaMultiParams[] {
  return formulas.map((formula) => ({
    contextBranches: [dataConstants.BRANCH_MASTER],
    watch: true,
    ...formula
  }));
}

function formulaEquivalent(prev, next): boolean {
  const [previousJsonResult] = prev.getFieldValues('response/formula:multi-result');
  const [previousErrorsResult] = prev.getFieldValues('response/formula:multi-errors');
  const [nextJsonResult] = next.getFieldValues('response/formula:multi-result');
  const [nextErrorsResult] = next.getFieldValues('response/formula:multi-errors');
  const resultsEqual = previousJsonResult === nextJsonResult;
  const errorsEqual = previousErrorsResult === nextErrorsResult;

  return resultsEqual && errorsEqual;
}
