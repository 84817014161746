import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { first, tap, takeUntil } from 'rxjs/operators';
import { cloneDeep, size } from 'lodash';

import { IList, IListItem } from '../../../../models/list';
import { LoadingState } from '../../../../shared/constants';
import { SideSheetService } from '../../../../side-sheet/side-sheet.service';
import { ToastService } from '../../../../shell/services/toast.service';
import { ListDesignerService } from '../../list-designer.service';
import { ListItemCreateSideSheetComponent } from '../list-item-create-side-sheet/list-item-create-side-sheet.component';
import { ListItemEditSideSheetComponent } from '../list-item-edit-side-sheet/list-item-edit-side-sheet.component';
import { ListUtilsService } from '../../list-utils.service';
import { Tip } from '../../../../data/models/types';
import { ModuleValidationError } from '../../../../data/errors/client/ModuleValidationError';
import { OperationalLimitService } from '../../../../data/operational-limit.service';

@Component({
  selector: 'app-list-item-side-sheet',
  templateUrl: './list-item-side-sheet.component.html',
  styleUrls: ['./list-item-side-sheet.component.scss']
})
export class ListItemSideSheetComponent implements OnInit, OnDestroy {
  moduleTip: Tip;
  list: IList;
  listItem: IListItem;
  parentListItem: IListItem;
  listItemIndex: number;

  loadingState: LoadingState;
  loadingStates = LoadingState;
  filterText = '';
  isDraggable = false;
  listItemsBeforeReorder: IListItem[];
  itemsLimited: number;

  unsubscribe$ = new Subject();

  constructor(
    private sideSheetService: SideSheetService,
    private listService: ListDesignerService,
    private toastService: ToastService,
    private operationalLimitService: OperationalLimitService
  ) {}

  ngOnInit() {
    this.initialiseList();

    this.listService.getListCalled
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.initialiseList());
      this.operationalLimitService.getValue$('numberOfItemsPerListDesigner').pipe(
        first()
      ).subscribe(operationalLimit => this.itemsLimited = operationalLimit);  
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initialiseList() {
    if (!this.listItem) {
      this.listItem = this.list.items[this.listItemIndex];
    }
  }

  openListItem(value: string) {
    const selecteditemIndex = ListUtilsService.getListItemIndex(this.listItem.items, value);
    const sheetRef = this.sideSheetService.push(ListItemSideSheetComponent);
    const componentInstance: ListItemSideSheetComponent = sheetRef.componentInstance;

    componentInstance.list = this.list;
    componentInstance.listItem = this.listItem.items[selecteditemIndex];
    componentInstance.parentListItem = this.listItem;
    componentInstance.listItemIndex = selecteditemIndex;
    componentInstance.moduleTip = this.moduleTip;
  }

  createListItem() {
    if (size(this.listItem.items) >= this.itemsLimited) {
      this.toastService.showErrorToast(`You can only add ${this.itemsLimited} items per list.`)
      return;
    }

    const sheetRef = this.sideSheetService.push(ListItemCreateSideSheetComponent);
    const componentInstance: ListItemCreateSideSheetComponent = sheetRef.componentInstance;

    componentInstance.list = this.list;
    componentInstance.parentListItem = this.listItem;
    componentInstance.moduleTip = this.moduleTip;
  }

  editListItem() {
    
    const sheetRef = this.sideSheetService.push(ListItemEditSideSheetComponent);
    const componentInstance: ListItemEditSideSheetComponent = sheetRef.componentInstance;

    componentInstance.list = this.list;
    componentInstance.listItem = this.listItem;
    componentInstance.listItemIndex = this.listItemIndex;
    componentInstance.parentListItem = this.parentListItem;
    componentInstance.moduleTip = this.moduleTip;
    componentInstance.done = () => {
      this.sideSheetService.pop();
      this.sideSheetService.pop();
    };
  }

  onDragStart() {
    this.isDraggable = true;
    this.listItemsBeforeReorder = cloneDeep(this.listItem.items);
  }

  onDragCancel() {
    this.isDraggable = false;
    this.listItem.items = cloneDeep(this.listItemsBeforeReorder);
  }

  onDragFinish() {
    this.loadingState = LoadingState.inProgress;
    this.listService.updateList(this.list, this.moduleTip).pipe(
      first(),
      tap(() => {
        this.isDraggable = false;
        this.loadingState = LoadingState.loaded;
      })
    ).subscribe(
      () => {},
      (error) => {
        error instanceof ModuleValidationError
          ? this.toastService.showErrorToast(error.message)
          : this.toastService.showErrorToast('Failed to update the list items order.');
      }
    );
  }
}
