<div class="sidesheet-header-container d-flex flex-row align-items-center justify-content-between">
  <div class="w-20 mx-3 text-left" [ngClass]="{ 'w-15': longerText }">
    <mat-icon
        *ngIf="((depth$ | async) > 0 && !hideBack) && !showCancelButton || showBackIcon"
        data-test="left-back"
        svgIcon="back"
        class="eim-color_icon"
        (click)="back($event)"></mat-icon>
    <button
        i18n="@@sideSheetHeader.cancel"
        *ngIf="(depth$ | async) > 0 && showCancelButton && cancel"
        class="btn btn-secondary eim-button_size-h-sm eim-button_size-w-sm"
        data-test="left-cancel"
        (click)="back($event)">{cancel, select, Cancel {Cancel} other {{{cancel}}}}</button>
    <button
        i18n="@@sideSheetHeader.cancel"
        *ngIf="(depth$ | async) === 0 && action && cancel"
        class="btn btn-secondary eim-button_size-h-sm eim-button_size-w-sm"
        data-test="left-cancel-depth-0"
        (click)="back($event)">{cancel, select, Cancel {Cancel} other {{{cancel}}}}</button>
  </div>
  <div
      class="sidesheet-header-title text-truncate w-60"
      [ngClass]="{'w-70': longerText, 'mx-0': longerText}"
      data-test="header-title">
    <ng-content></ng-content>
  </div>
  <div class="w-20 mx-3 text-right" [ngClass]="{'w-15': longerText}">
    <mat-icon
        *ngIf="displayClose"
        data-test="right-close"
        svgIcon="close"
        class="eim-color_icon"
        (click)="close($event)"></mat-icon>
    <button
        i18n="@@sideSheetHeader.action"
        *ngIf="action"
        data-test="right-action"
        class="btn eim-button_size-h-sm eim-button_size-w-sm btn-primary"
        [disabled]="actionButtonDisabled"
        (click)="onAction($event)">{action, select, Done {Done} Save {Save} Next {Next} other {{{action}}}}</button>
  </div>
</div>
