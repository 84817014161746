import { Panel, Binding, Shape, TextBlock, Size, GraphObject, Node, Margin, Adornment } from 'gojs';
import { COLORS, SHAPES, FONT_NAME_2 } from '../workflow-designer-constants';

const NS = 'GoJSLinks';
const $ = GraphObject.make;

const LINK_HANDLE_W = 10;

export interface IPortStyle {
  figure?: string;
  stroke: string;
  strokeWidth: number;
  fill: string;
}

// based on LinkingBaseTool, but with limited properties
export interface ITempLink {
  temporaryFromPort: IPortStyle;
  temporaryToPort: IPortStyle;
  temporaryLinkLine: IPortStyle;
}

export class GoJSLinks {
  static getTemporaryLinkStyles(): ITempLink {
    return {
      // this is the styling for shape that highlights the port you're linking from
      temporaryFromPort: {
        figure: SHAPES.CIRCLE,
        stroke: COLORS.PORT_SELECT,
        strokeWidth: 2,
        fill: null
      },
      // this is the styling for shape that highlights the entire node
      temporaryToPort: {
        figure: SHAPES.R_RECT,
        stroke: COLORS.PORT_SELECT,
        strokeWidth: 2,
        fill: null
      },
      temporaryLinkLine: {
        stroke: COLORS.PORT_SELECT, // this is for the line
        strokeWidth: 1,
        fill: COLORS.PORT_SELECT // this is for the line's arrow
      }
    };
  }

  constructor() {}

  /**
   * Couldn't add this to constructor because needs to be added after the diagram is created,
   * as the diagram needs GoJSLinks during initialization.
   */
  // setUtils(utils: WorkflowDesignerUtils): void {
  //     this.utils = utils;
  // }

  /**
   * Returns a node for the ports of the linking and relinking tools, which
   * only appears during the re/linking operation, as a sort of highlight.
   */
  getTempLinkingNode(): Node {
    return $(
      Node,
      'Spot',
      {
        layerName: 'Tool'
      },
      $(Shape, SHAPES.CIRCLE, {
        stroke: null,
        //   strokeWidth: 2,
        fill: COLORS.PORT_SELECT,
        width: LINK_HANDLE_W,
        height: LINK_HANDLE_W
      })
    );
  }

  /**
   * Gets the shape for the end of the links when they are selected.
   * This should not be confused with the 'temporary' link shape, which
   * only appears during the re/linking operation, as a sort of highlight.
   */
  getPortLinkingShape(): Shape {
    return $(Shape, SHAPES.CIRCLE, {
      segmentIndex: -1,
      cursor: 'pointer',
      desiredSize: new Size(LINK_HANDLE_W, LINK_HANDLE_W),
      fill: COLORS.PORT_SELECT,
      stroke: COLORS.WHITE,
      strokeWidth: 1
    });
  }

  /**
   * Returns arrowhead shape
   */
  getArrowHeadShape(): Shape {
    return $(Shape, // the arrowhead
      {
        toArrow: 'Triangle',
        stroke: null,
        fill: COLORS.DARK_GREY,
        scale: 1.5,
        margin: new Margin(0, 0, 0, 20)
      }
    );
  }

  /**
   * Returns the shape for the unselected path
   */
  getLinkPathShape(): Shape {
    return $(Shape, {
      margin: new Margin(0, 0, 0, -10),
      isPanelMain: true,
      stroke: COLORS.DARK_GREY,
      strokeWidth: 2
    });
  }

  /**
   * Returns link label panel
   */
  getLinkLabelPanel(): Panel {
    return $(Panel, 'Auto',
      $(Shape, 'RoundedRectangle', {
        fill: COLORS.LINK_LABEL_BG,
        stroke: null
      }),
      $(
        TextBlock,
        {
          textAlign: 'center',
          font: '11px ' + FONT_NAME_2,
          stroke: COLORS.WHITE,
          margin: new Margin(2, 4),
          minSize: new Size(15, NaN),
          editable: false
        },
        new Binding('text')
      )
    );
  }

  /**
   * Returns the configuration for 'link adornments', which are the highlighted states of the links between nodes
   */
  getLinkSelectionAdornmentTemplate(): Adornment {
    return $(Adornment, 'Link',
      $(
        Shape,
        // isPanelMain declares that this Shape shares the Link.geometry
        {
          isPanelMain: true,
          fill: null,
          stroke: COLORS.LINK_LABEL_BG,
          strokeWidth: 0 // use selection object's strokeWidth
        }
      )
    );
  }

  /**
   * Returns the shape for the reshaping handles that appear when you select a link
   */
  getLinkShapingHandleShape(): Shape {
    return $(Shape, SHAPES.DIAMOND, {
      desiredSize: new Size(7, 7),
      fill: COLORS.WHITE,
      stroke: COLORS.PORT_SELECT
    });
  }
}
