import { FieldType } from '@ngx-formly/core';
import { Component } from '@angular/core';

@Component({
  selector: 'app-text-content',
  template: `<div [innerHTML]="to.html | sanitizeHtml"></div>`,
})
export class FormlyFieldTextContent extends FieldType {

}
