import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { last, flatten } from 'lodash';

import { EXTENDED_FIELD_DATA_TYPE, FIELD_DATA_TYPE, fieldScheme, IField, TYPE_GEO_RESTRICT } from '../models/field';
import { Formula, Tip } from '../data/models/types';
import { ObjectService as ObjectDataService } from '../data/object-service/object.service';
import { FormulaService } from '../data/formula.service';
import { FormulaSpec } from '../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { MetaDataKey } from '../object/field-formula-side-sheet/meta-data-formulas';
import { SUPER_TYPES } from '../object/type-chooser-side-sheet/super-types';
import { ObjectService, IObjectAndType } from '../object/object.service';
import { getDataTypeAndMetaFromField } from './get-data-type-and-meta-from-field';
import {
  getSpecialContextFormulaLabel,
  getSpecialContextFormulaMeta,
  ISpecialContextFormulaMeta,
  SPECIAL_CONTEXT_FORMULA_TYPE
} from './special-context-formula';
import { getFieldTipsFromFormulaString } from './get-field-tips-from-formula-string';
import { getContextFromContextFormula } from './context-formula-wrapper';

export interface IFormulaDatatypeMeta {
  datatype?: FIELD_DATA_TYPE;
  typerestrict?: string[];
  typeGeoRestrict?: TYPE_GEO_RESTRICT;
  items?: string[];
  listTip?: Tip;
  metaDataKey?: MetaDataKey;
  extendedDataType?: EXTENDED_FIELD_DATA_TYPE;
  implements?: Tip[];
  isMulti?: boolean;
  fieldLabel?: string;
  numberFormatTip?: Tip;
}

@Injectable({
  providedIn: 'root'
})
export class GetFormulaDatatypeService {

  constructor(
    private objectDataService: ObjectDataService,
    private objectService: ObjectService,
    private formulaService: FormulaService
  ) { }

  // Returns a data type and optional typerestrict if object given a formula spec
  getFormulaDataType(
    formula: Formula,
    contextTypeTip?: Tip | Tip[],
    additionalMeta: IFormulaDatatypeMeta = {}
  ): Observable<IFormulaDatatypeMeta> {
    const specialContextFormulaMeta = getSpecialContextFormulaMeta(formula);

    if (specialContextFormulaMeta) {
      return this.getSpecialContextFormulaDatatype(specialContextFormulaMeta, formula, contextTypeTip, additionalMeta);
    }

    return this.getFieldFormulaDatatype(formula, additionalMeta);
  }

  private getFieldFormulaDatatype(formula: Formula, additionalMeta: IFormulaDatatypeMeta = {}): Observable<IFormulaDatatypeMeta> {
    const fieldTip = last(getFieldTipsFromFormulaString(formula));

    if (!fieldTip) {
      return of(additionalMeta);
    }

    return this.objectDataService.getObject<IField>(fieldTip, fieldScheme).pipe(
      map((field: IField) => {
        const meta = getDataTypeAndMetaFromField({ field });

        return {
          ...additionalMeta,
          fieldLabel: field.label,
          datatype: field.datatype,
          typerestrict: field.typerestrict || [],
          typeGeoRestrict: field.typeGeoRestrict || undefined,
          listTip: meta.listTip || null,
          extendedDataType: field.datatype === meta._datatype ? undefined : (meta._datatype as EXTENDED_FIELD_DATA_TYPE),
          isMulti: field.maxcount && field.maxcount > 1 || false,
          numberFormatTip: field.numberFormat || null
        };
      }),
      switchMap((data: IFormulaDatatypeMeta) => this.updateHierarchicalData(data))
    );
  }

  private getSpecialContextFormulaDatatype(
    specialContextFormulaMeta: ISpecialContextFormulaMeta,
    formula: Formula,
    contextTypeTip?: Tip | Tip[],
    additionalMeta: IFormulaDatatypeMeta = {}
  ): Observable<IFormulaDatatypeMeta> {
    const fieldLabel = getSpecialContextFormulaLabel(formula);
    switch (specialContextFormulaMeta.type) {
      case SPECIAL_CONTEXT_FORMULA_TYPE.CUSTOM_FORMULA:
        return of({
          datatype: FIELD_DATA_TYPE.string,
          fieldLabel,
          isMulti: false
        });
      case SPECIAL_CONTEXT_FORMULA_TYPE.META_DATA_FORMULA:
        const result: IFormulaDatatypeMeta = {
          ...additionalMeta,
          metaDataKey: specialContextFormulaMeta.metaData.key,
          datatype: specialContextFormulaMeta.metaData.datatype,
          fieldLabel,
          isMulti: false
        };

        if (specialContextFormulaMeta.metaData.key === MetaDataKey.thisObject) {
          if (contextTypeTip && contextTypeTip.length) {
            result.typerestrict = flatten([contextTypeTip]);
          }

          const context = getContextFromContextFormula(formula);

          if (context) {
            return this.getFormulaDataType(context, contextTypeTip, result);
          }
        }

        if (specialContextFormulaMeta.metaData.typerestrict) {
          result.typerestrict = specialContextFormulaMeta.metaData.typerestrict;
        }

        return this.updateHierarchicalData(result);
      case SPECIAL_CONTEXT_FORMULA_TYPE.COMMON_FIELD_FORMULA:
        return this.updateHierarchicalData({
          ...this.getFormulaDataTypeForCommonField(
            this.formulaService.parse(specialContextFormulaMeta.outerFormula)
          ),
          fieldLabel,
          isMulti: false
        });
      case SPECIAL_CONTEXT_FORMULA_TYPE.CURRENT_USER_FORMULA:
        return this.updateHierarchicalData({
          datatype: FIELD_DATA_TYPE.object,
          typerestrict: ['app/user'],
          fieldLabel,
          isMulti: false
        });
      case SPECIAL_CONTEXT_FORMULA_TYPE.RELATIVE_DATE_FORMULA:
        return of({
          datatype: FIELD_DATA_TYPE.datetime,
          fieldLabel,
          isMulti: false
        });
    }
  }

  updateHierarchicalData(result: IFormulaDatatypeMeta): Observable<IFormulaDatatypeMeta> {
    if (!result.typerestrict || !result.typerestrict.length) {
      return of(result);
    }

    const hierarchicalSuperTypes: Tip[] = SUPER_TYPES.filter(x => x.isHierarchical).map(x => x.$tip);

    // if object itself is a group or one of hierarchical super types - set filter meta to hierarchical
    if ([...hierarchicalSuperTypes, 'app/group'].includes(result.typerestrict[0])) {
      result.extendedDataType = EXTENDED_FIELD_DATA_TYPE.hierarchical;
      return of(result);
    }

    // otherwise get type to detect if it implemets hierarchical super type
    return this.objectService.getType(result.typerestrict[0]).pipe(
      map((objectAndType: IObjectAndType) => {

        // if it is an object and it has supertypes - add them to filter meta
        if (objectAndType && objectAndType.objectType && objectAndType.objectType.supertypes) {
          result.implements = objectAndType.objectType.supertypes.map(x => x.$tip);
          // if one of implemented super types is hierarchical set filter meta to hierarchical
          if (result.implements.some(x => hierarchicalSuperTypes.includes(x))) {
            result.extendedDataType = EXTENDED_FIELD_DATA_TYPE.hierarchical;
          }
        }

        return result;
      })
    );
  }

  // gets formula meta information for common field which will have data encoded as json in formula args
  getFormulaDataTypeForCommonField(formula: FormulaSpec) {
    const fieldDataJson: string = (formula.args[0] as FormulaSpec).args[1] as string;
    const fieldData: {
      name?: string;
      datatype: FIELD_DATA_TYPE;
      tag?: string[];
      typerestrict?: Tip[];
      typeGeoRestrict?: TYPE_GEO_RESTRICT;
    } = JSON.parse(fieldDataJson);

    const meta = getDataTypeAndMetaFromField({ field: fieldData });
    return {
      datatype: fieldData.datatype,
      typerestrict: fieldData.typerestrict || [],
      typeGeoRestrict: fieldData.typeGeoRestrict || undefined,
      listTip: meta.listTip || null,
      extendedDataType: fieldData.datatype === meta._datatype ? undefined : (meta._datatype as EXTENDED_FIELD_DATA_TYPE)
    };
  }
}
