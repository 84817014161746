import { compose } from 'lodash/fp';
import { isEmpty } from 'lodash';
import { coerceToNumberIfPossible, isEmptyString } from './number-format-utils';
import { addPrefix, addSuffix, formatCalculation, formatDecimalPlaces, replaceDecimalSeparator, separateThousands } from './format-number-functions';
import { formatParamStringToNumber, removeDuplicateAndNonPrefixOperators, removePrefix, removeSuffix, removeValuesOtherThanDecimalSeparatorOrSign, reverseFormatCalculation, revertToStandardDecimalSeparatorRemoveDuplicates } from './parse-formatted-number-functions';
export function formatNumber(number, format, _console) {
    return _formatNumber({ number: number, format: format }, _console).numberString;
}
export function _formatNumber(_a, _console) {
    var number = _a.number, format = _a.format;
    if (typeof number === 'string' && isEmpty(number)) {
        // if it's an empty string just return it
        // might just be an initial value
        return {
            numberString: number,
            format: format,
        };
    }
    var _number = coerceToNumberIfPossible(number);
    if (isNaN(_number)) {
        return {
            numberString: 'Invalid input - not a number',
            format: format,
        };
    }
    if (!isValidFormat(format)) {
        return {
            numberString: 'Invalid format',
            format: format,
        };
    }
    var fn = compose(addSuffix, // this is called last
    addPrefix, separateThousands, // do this after we change the decimal separator
    replaceDecimalSeparator, // add decimal separator
    formatDecimalPlaces, // truncate to required decimal places return string
    formatCalculation);
    return fn({ number: _number, format: format });
}
export function parseFormattedNumber(_a) {
    // order is very important think before changing
    var formattedNumber = _a.formattedNumber, format = _a.format, cursorPosition = _a.cursorPosition;
    if (typeof formattedNumber === 'string' && isEmpty(formattedNumber)) {
        // if it's an empty string just return it
        // might just be an initial value
        return {
            number: formattedNumber,
            format: format
        };
    }
    var fn = compose(reverseFormatCalculation, // this is called last
    formatParamStringToNumber, removeDuplicateAndNonPrefixOperators, revertToStandardDecimalSeparatorRemoveDuplicates, removeValuesOtherThanDecimalSeparatorOrSign, removePrefix, removeSuffix);
    return fn({ numberString: formattedNumber, format: format });
}
export function isValidFormat(format, _console) {
    if (_console === void 0) { _console = console; }
    // ignore empty string
    if (isEmptyString(format.decimalSeparator) || isEmptyString(format.thousandsSeparator)) {
        return true;
    }
    if (format.decimalSeparator && format.decimalSeparator.indexOf(format.thousandsSeparator) > -1) {
        _console.warn("Decimal separator must not contain thousands separator");
        return false;
    }
    if (format.thousandsSeparator && format.thousandsSeparator.indexOf(format.decimalSeparator) > -1) {
        _console.warn("Thousands separator must not contain decimal separator");
        return false;
    }
    return true;
}
