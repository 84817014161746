import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthGuardService } from './auth-guard.service';
import { AdminGuardService } from './admin-guard.service';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthAdminGuard implements CanActivate {

  constructor(
    private authGuard: AuthGuardService,
    private adminGuard: AdminGuardService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this
      .authGuard
      .canActivate(route, state)
      .pipe(
        switchMap((canActivate: boolean) => {
          return canActivate
            ? this.adminGuard.canActivate(route, state)
            : of(false);
        })
      );
  }
  
}
