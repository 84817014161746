import * as tslib_1 from "tslib";
import dataConstants from '../../../data/constants';
import { NodeConverterBase } from './NodeConverterBase';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import { Stringify, Parser } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { ConnectionUtils } from '../utils/connection-utils';
import * as i0 from "@angular/core";
var SwitchNodeConverterService = /** @class */ (function (_super) {
    tslib_1.__extends(SwitchNodeConverterService, _super);
    function SwitchNodeConverterService() {
        var _this = _super.call(this) || this;
        _this.type = 'processnode/logic/switch';
        _this.processNodeType = 'processnodetype/logic/switch';
        return _this;
    }
    SwitchNodeConverterService.prototype.convert = function (nodeData) {
        if (!this.isValid(nodeData)) {
            throw new InvalidWorkflowNodeDataError("Please correct invalid data on switch node [" + nodeData.fields.title + "]", nodeData);
        }
        var fieldFormula = Parser(nodeData.fields.formula);
        var expressionFormula = Stringify({
            name: 'COALESCE',
            args: nodeData.fields.cases.map(function (x, index) {
                var caseFormula = Parser(x.formula);
                return {
                    name: 'IF',
                    args: [
                        {
                            name: 'AND',
                            args: [
                                {
                                    name: 'EQUALS',
                                    args: [
                                        { name: 'COUNT', args: [fieldFormula] },
                                        { name: 'COUNT', args: [caseFormula] }
                                    ]
                                },
                                {
                                    name: 'EQUALS',
                                    args: [
                                        { name: 'COUNT', args: [
                                                { name: 'INTERSECT', args: [
                                                        fieldFormula,
                                                        caseFormula
                                                    ] }
                                            ] },
                                        { name: 'COUNT', args: [fieldFormula] }
                                    ]
                                }
                            ]
                        },
                        ConnectionUtils.getCaseIdentifier(x),
                        { name: 'ARRAY', args: [] }
                    ]
                };
            })
        });
        return {
            $type: this.type,
            $security: dataConstants.SECURITY.INSTANCE_USER_ADMIN,
            type: this.processNodeType,
            connections: [],
            point: [0, 0],
            key: nodeData.tip,
            switchExpr: expressionFormula,
            switchDefault: 'default',
            switchCases: nodeData.fields.cases.map(function (x, index) { return ConnectionUtils.getCaseIdentifier(x); })
        };
    };
    SwitchNodeConverterService.prototype.isValid = function (nodeData) {
        return !(nodeData.fields.formula === '' || nodeData.fields.cases.length === 0);
    };
    SwitchNodeConverterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SwitchNodeConverterService_Factory() { return new SwitchNodeConverterService(); }, token: SwitchNodeConverterService, providedIn: "root" });
    return SwitchNodeConverterService;
}(NodeConverterBase));
export { SwitchNodeConverterService };
