import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, filter, map, retry, tap, timeout } from 'rxjs/operators';
import { get } from 'lodash';

import { environment } from '../../environments/environment';
import dataConstants from './constants';
import { ISessionInfo, SessionManagerService } from './session-manager.service';
import { Batch, IEno } from './models/types';
import { Eno } from './models/Eno';
import { debugConfig } from '../../debugConfig';
import { LoggerService } from '../util/logger.service';
import { EnoCacheService } from './eno-cache.service';
import { captureMessage, Severity } from '@sentry/browser';

const SESSION_TOKEN_HEADER = dataConstants.HTTP_HEADER.SESSION_TOKEN_HEADER;
const SESSION_ID_HEADER = dataConstants.HTTP_HEADER.SESSION_ID_HEADER;
const NUM_RETRIES = 2;
export const XHR_TIMEOUT = 30000;
const THIN_OPERATIONS = ['op/pull', 'op/formula', 'op/query'];

@Injectable({
  providedIn: 'root'
})
export class EnsrvService {
  private _baseFatUriHttp = environment.host + environment.enSrvFatPath;
  private _baseThinUriHttp = environment.host + environment.enSrvThinPath;

  private _enoBroadcaster: Subject<Eno> = null;
  private _sessionInfo: ISessionInfo = null;

  constructor(
    private _http: HttpClient,
    private _sessionManager: SessionManagerService,
    private _enoCacheService: EnoCacheService,
    private _loggerService: LoggerService
  ) {
    this._enoBroadcaster = new Subject<Eno>();

    this._sessionManager.getSessionInfo$().subscribe((sessionInfo) => {
      this._sessionInfo = sessionInfo;
    });
  }

  /**
   * Given a batch, determine the best URI to post it to.
   * Batches which just contain certain operations are more performant when sent to their dedicated route
   *
   * @param batch
   */
  private getUri(batch: Batch): string {
    if (singleEno(batch) && firstItemIsThinOperation(batch)) {
      return this._baseThinUriHttp + '/' + batch[0].source.type;
    }

    return this._baseFatUriHttp;
  }

  /**
   * Send a batch of enos to ensrv. Returns an
   * @param batch
   */
  public send(batch: Batch): Observable<Batch> {
    const headers = {
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Conn-Isolation': 'true'
    };

    const sessionInfo = this._sessionInfo;

    if (sessionInfo.token) {
      headers[SESSION_TOKEN_HEADER] = sessionInfo.token;
    }

    if (sessionInfo.id) {
      headers[SESSION_ID_HEADER] = sessionInfo.id;
    }

    const XHR_ID = parseInt((Math.random() * 100000) + '', 10);

    const outBatchTypes = batch.map((eno) => {
      return eno.source.type;
    });

    if (debugConfig.api.outboundBatchMonitor) {
      this._loggerService.debug(`[Out (XHR) - ${XHR_ID}] (${outBatchTypes.join(', ')})`, batch);
    }

    return this._http.post<IEno[]>(
      this.getUri(batch),
      batch.map(eno => eno.toJson()),
      {
        headers,
        params: {
          ns: environment.ns
        },
        observe: 'response'
      }
    ).pipe(
      timeout(XHR_TIMEOUT),
      retry(NUM_RETRIES),
      catchError((err: HttpErrorResponse) => {
        const message = 'Unexpected error returned from EnSrv:\n\n' + JSON.stringify(err);
        this._loggerService.warn(message);
        captureMessage(message, Severity.Warning);
        return throwError(err);
      }),
      tap((response) => this.updateSessionInfo(response)),
      map(createEnosFromResponse),
      tap((incomingBatch: Batch) => {
        const inBatchTypes = incomingBatch.map((eno) => {
          return eno.source ? eno.source.type : 'Ack';
        });

        if (debugConfig.api.inboundBatchMonitor) {
          this._loggerService.debug(
            `[In (XHR) - ${XHR_ID}] (${inBatchTypes.join(', ')})`,
            incomingBatch
          );
        }

        // Bellow two loops are intentionally separated to cache all the eno first before broadcasting enos
        // DO NOT MERGE THESE 2 LOOPS
        incomingBatch.forEach((eno) => {
          this._enoCacheService.setEno(eno.tip, eno);
        });

        incomingBatch.forEach((eno) => {
          this._enoBroadcaster.next(eno);
        });
      })
    );
  }

  public getEnoReceiver(type?: string): Observable<Eno> {
    if (type) {
      return this._enoBroadcaster.pipe(
        filter((eno: Eno) => {
          return eno.source && eno.source.type === type;
        })
      );
    }

    return this._enoBroadcaster.asObservable();
  }

  updateSessionInfo(response: HttpResponse<IEno[]>) {
    const sessionToken = response.headers.get(SESSION_TOKEN_HEADER);
    if (sessionToken) {
      this._sessionManager.updateSessionInfo(sessionToken);
    }
  }
}

function createEnosFromResponse(response: HttpResponse<IEno[]>): Batch {
  if (!response.body) {
    return [];
  }

  return response
    .body
    .map((eno: IEno) => new Eno(eno));
}

function singleEno(batch): boolean {
  return batch.length === 1;
}

function firstItemIsThinOperation(batch): boolean {
  const type = get(batch, [ 0, 'source' , 'type'], null);
  return THIN_OPERATIONS.indexOf(type) > -1;
}
