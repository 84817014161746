import { IObject } from '../data/models/object';
import { Formula, Tip } from '../data/models/types';
import { DataTypes, IObjectScheme } from '../data/models/scheme';
import { MESSAGE_PROCESSED_STATE } from './message';
import * as moment from 'moment';

export interface ISMSRecipientsByType {
  to?: Tip[];
  toFormulas?: string[];
}

export enum SMS_COMPOSE_TYPE {
  NEW = 'new',
  REPLY = 'reply',
  FORWARD = 'forward',
  WORKFLOW = 'workflow'
}

export enum SMS_PRIMITIVE_FOLDER {
  INBOX = 'Inbox',
  SENT = 'Sent',
  DRAFT = 'Draft'
}

export enum SMS_OUTBOUND_SRC_STATUS {
  DRAFT = 'draft',
  SENT = 'sent'
}

export interface ISMSSubstitution extends IObject {
  key: string;
  label: string;
  value: Formula;
}

export const smsSubstitutionScheme: IObjectScheme = {
  'app/sms/substitution:key': { },
  'app/sms/substitution:label': { },
  'app/sms/substitution:value': { type: DataTypes.formula }
};

export interface IAddhocNumber extends IObject {
  label: string;
  number: string;
}

export const addhocNumberScheme: IObjectScheme = {
  'app/sms/addhoc-number:label': { },
  'app/sms/addhoc-number:number': { }
};

export interface ISMSRecipient extends IObject {
  name: string;
  number: string;
  status: string;
  statusMessage?: string;
  response?: string;
  source: Tip;
  substitutionFormulas?: string[];
  token: string;
}

export const smsRecipientScheme: IObjectScheme = {
  'app/sms/recipient:name': { },
  'app/sms/recipient:number': { },
  'app/sms/recipient:status': { },
  'app/sms/recipient:status-message': { },
  'app/sms/recipient:response': { },
  'app/sms/recipient:source': { },
  'app/sms/recipient:substitution-formulas': { type: DataTypes.stringArray },
  'app/sms/recipient:token': { }
};

export interface ISMSFromNumber extends IObject {
  number: string;
  label: string;
  verified: boolean;
}

export const smsFromNumberScheme: IObjectScheme = {
  'app/sms/from-number:number': { },
  'app/sms/from-number:label': { },
  'app/sms/from-number:verified': { type: DataTypes.boolean },
};

export interface ISMSOutboundSource extends IObject {
  to?: Tip[];
  toNumberFormula?: string;
  fromNumber: Tip;
  context?: Tip;
  contextFormula?: Formula;
  contextFormulaMeta?: string;
  subject: string;
  body: string;
  status: SMS_OUTBOUND_SRC_STATUS;
  messageSubstitutions?: ISMSSubstitution[];
  recipientSubstitutionFormulas?: string[];
  billingCode?: Tip;
  draftSavedDatetime?: moment.Moment;
  template?: Tip;
  relatedObjects?: Tip;
}

export const smsOutboundSourceScheme: IObjectScheme = {
  'app/sms/outbound-source:to': { type: DataTypes.stringArray },
  'app/sms/outbound-source:to-number-formula': { type: DataTypes.formula },
  'app/sms/outbound-source:from-number': { },
  'app/sms/outbound-source:context': { },
  'app/sms/outbound-source:context-formula': { type: DataTypes.formula },
  'app/sms/outbound-source:context-formula-meta': { },
  'app/sms/outbound-source:subject': { },
  'app/sms/outbound-source:body': { },
  'app/sms/outbound-source:status': { },
  'app/sms/outbound-source:billing-code': { },
  'app/sms/outbound-source:message-substitutions': {
    type: DataTypes.objectArray,
    mutable: true,
    scheme: smsSubstitutionScheme
  },
  'app/sms/outbound-source:recipient-substitution-formulas': { type: DataTypes.stringArray },
  'app/sms/outbound-source:draft-saved-datetime': { type: DataTypes.datetime },
  'app/sms/outbound-source:template': { },
  'app/sms/outbound-source:related-objects': { },
};

export interface ISMSOutbound extends IObject {
  smsSource: ISMSOutboundSource;
  sentSource: ISMSOutboundSource;
  to: Tip[];
  status: 'draft' | 'sent';
  statusMessage?: string;
  fromName: string;
  fromNumber: string;
  sentDatetime?: moment.Moment;
}

export const smsOutboundScheme: IObjectScheme = {
  'app/sms/outbound:sms-source': {
    type: DataTypes.object,
    scheme: smsOutboundSourceScheme
  },
  'app/sms/outbound:sent-source': {
    type: DataTypes.object,
    scheme: smsOutboundSourceScheme
  },
  'app/sms/outbound:to': { type: DataTypes.stringArray },
  'app/sms/outbound:status': { },
  'app/sms/outbound:status-message': { },
  'app/sms/outbound:from-name': { },
  'app/sms/outbound:from-number': { },
  'app/sms/outbound:sent-datetime': { type: DataTypes.datetime },
};

export interface ISMSOutboundTemplate extends IObject {
  name: string;
  contextType?: Tip;
  to: Tip[];
  fromNumber: Tip;
  subject: string;
  body: string;
  billingCode?: Tip;
  messageSubstitutions?: ISMSSubstitution[];
  recipientSubstitutionFormulas?: Tip[];
}

export const smsOutboundTemplateScheme: IObjectScheme = {
  'app/sms/outbound-template:name': { },
  'app/sms/outbound-template:context-type': { },
  'app/sms/outbound-template:to': { type: DataTypes.stringArray },
  'app/sms/outbound-template:from-number': { },
  'app/sms/outbound-template:subject': { },
  'app/sms/outbound-template:body': { },
  'app/sms/outbound-template:billing-code': { },
  'app/sms/outbound-template:message-substitutions': {
    type: DataTypes.objectArray,
    mutable: true,
    scheme: smsSubstitutionScheme
  },
  'app/sms/outbound-template:recipient-substitution-formulas': { type: DataTypes.stringArray },
};

export interface ISMSInfo extends IObject {
  name: string;
  number: string;
}

export const smsInfoScheme: IObjectScheme = {
  'app/sms/number-info:name': { },
  'app/sms/number-info:number': { }
};

export interface ISMSInbound extends IObject {
  to: ISMSInfo[];
  from: ISMSInfo;
  subject: string;
  sentDatetime: moment.Moment;
  body: string;
  original: string;
  processedState: MESSAGE_PROCESSED_STATE;
  readState: boolean;
  relatedObjects?: Tip;
}

export const smsInboundScheme: IObjectScheme = {
  'app/sms/inbound:to': { type: DataTypes.objectArray, scheme: smsInfoScheme },
  'app/sms/inbound:from': { type: DataTypes.object, scheme: smsInfoScheme },
  'app/sms/inbound:subject': { },
  'app/sms/inbound:sent-datetime': { type: DataTypes.datetime },
  'app/sms/inbound:body': { },
  'app/sms/inbound:original': {
    type: DataTypes.json
  },
  'app/sms/inbound:processed-state': { },
  'app/sms/inbound:read-state': { type: DataTypes.boolean },
  'app/sms/inbound:related-objects': { },
};
