import { EXTENDED_FIELD_DATA_TYPE, FIELD_DATA_TYPE } from '../models/field';
import { tagToMap } from './tag-utils';
import { get } from 'lodash';
import { SUB_OBJECT_TAG_KEY } from '../settings/object-type/object-type-designer-field/constants';
export function getDataTypeAndMetaFromField(_a) {
    var field = _a.field;
    var tag = field.tag;
    var tagMap = tagToMap(tag);
    if (isFileType(field)) {
        return { _datatype: EXTENDED_FIELD_DATA_TYPE.file };
    }
    if (isAddressType(field)) {
        return { _datatype: EXTENDED_FIELD_DATA_TYPE.address };
    }
    if (isAssignableType(field)) {
        return { _datatype: EXTENDED_FIELD_DATA_TYPE.assignable };
    }
    if (isSubObjectType(field, tagMap)) {
        return {
            _datatype: EXTENDED_FIELD_DATA_TYPE.sub_object,
            typerestrict: get(field, 'typerestrict', null)
        };
    }
    if (isObjectType(field, tagMap)) {
        // need is object as file is also an object type
        return { _datatype: FIELD_DATA_TYPE.object, typerestrict: get(field, 'typerestrict', null) };
    }
    if (field.datatype === FIELD_DATA_TYPE.geography) {
        var geographyMap = {
            point: EXTENDED_FIELD_DATA_TYPE.point,
            linestring: EXTENDED_FIELD_DATA_TYPE.line,
            polygon: EXTENDED_FIELD_DATA_TYPE.polygon
        };
        return { _datatype: geographyMap[field.typeGeoRestrict] };
    }
    var valueType = tagMap.get('valueType');
    var listTip = tagMap.get('listTip');
    var sequenceTip = tagMap.get('sequenceTip');
    if (valueType === 'app/list' && listTip) {
        return {
            _datatype: EXTENDED_FIELD_DATA_TYPE.list,
            listTip: tagMap.get('listTip')
        };
    }
    if (valueType === 'app/sequence' && sequenceTip) {
        return {
            _datatype: FIELD_DATA_TYPE.string,
            sequenceTip: tagMap.get('sequenceTip'),
            isSequence: true
        };
    }
    return { _datatype: field.datatype };
}
function isObjectType(field, tagMap) {
    var typerestrict = get(field, 'typerestrict', []);
    var isObject = field.datatype === FIELD_DATA_TYPE.object;
    var notSpecificObjectType = isNotSpecificObjectType(typerestrict);
    return isObject && notSpecificObjectType;
}
function isSubObjectType(field, tagMap) {
    var datatype = field.datatype;
    var isObject = datatype === FIELD_DATA_TYPE.object;
    var subObjectTag = hasSubObjectTag(tagMap);
    return isObject && subObjectTag;
}
function isNotSpecificObjectType(typerestrict) {
    var notFile = typerestrict[0] !== 'file';
    var notAddress = typerestrict[0] !== 'app/address';
    var notAssignable = typerestrict[0] !== 'app/assignable';
    return notFile && notAddress && notAssignable;
}
function isFileType(field) {
    var typerestrict = field.typerestrict, datatype = field.datatype;
    var isObject = datatype === FIELD_DATA_TYPE.object;
    var fileTypeRestrict = typerestrict && typerestrict[0] === 'file';
    return isObject && fileTypeRestrict;
}
function hasSubObjectTag(tagMap) {
    return tagMap.get(SUB_OBJECT_TAG_KEY) === 'true';
}
function isAddressType(field) {
    var typerestrict = field.typerestrict, datatype = field.datatype;
    var isObject = datatype === FIELD_DATA_TYPE.object;
    var addressTypeRestrict = typerestrict && typerestrict[0] === 'app/address';
    return isObject && addressTypeRestrict;
}
function isAssignableType(field) {
    var typerestrict = field.typerestrict, datatype = field.datatype;
    var isObject = datatype === FIELD_DATA_TYPE.object;
    var assignableTypeRestrict = typerestrict && typerestrict[0] === 'app/assignment';
    return isObject && assignableTypeRestrict;
}
