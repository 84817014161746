<div>
    <app-side-sheet-header
    action="Done"
    (actioned)="onDone()"
    (goBack)="onCancel()">
      Custom range date
    </app-side-sheet-header>
    <formly-form
      [form]="customRangeDateForm"
      [fields]="fields"
      [model]="model">
    </formly-form>
  </div>