import * as tslib_1 from "tslib";
export var APP_PLAN_TIP = 'app/plan';
export var APP_PLAN_TIP_LABEL = 'Plans';
export var APP_CHECKLIST_TIP = 'app/checklist';
export var APP_CHECKLIST_TIP_LABEL = 'Check lists';
export var APP_GROUP_TIP = 'app/group';
export var APP_GROUP_TIP_LABEL = 'Groups';
export var APP_CHECKLIST_ITEM_TIP = 'app/checklistitem';
export var APP_CHECKLIST_ITEM_TIP_LABEL = 'Check list items';
export var APP_DOCUMENT_TIP = 'app/document';
export var APP_DOCUMENT_TIP_LABEL = 'Documents';
export var APP_EMAIL_INBOUND_TIP = 'app/email/inbound';
export var APP_EMAIL_INBOUND_TIP_LABEL = 'Inbound emails';
export var APP_EMAIL_OUTBOUND_TIP = 'app/email/outbound';
export var APP_EMAIL_OUTBOUND_TIP_LABEL = 'Outbound emails';
export var APP_SMS_INBOUND_TIP = 'app/sms/inbound';
export var APP_SMS_INBOUND_TIP_LABEL = 'Inbound SMS';
export var APP_SMS_OUTBOUND_TIP = 'app/sms/outbound';
export var APP_SMS_OUTBOUND_TIP_LABEL = 'Outbound SMS';
export var APP_TEAM_TIP = 'app/team';
export var APP_TEAM_TIP_LABEL = 'Teams';
export var APP_TEAM_ROLE_TIP = 'app/team-role';
export var APP_TEAM_ROLE_TIP_LABEL = 'Roles in teams';
export var APP_USER_TIP = 'app/user';
export var APP_USER_TIP_LABEL = 'Users';
export var APP_PROFILE_TIP = 'app/profile';
export var APP_PROFILE_TIP_LABEL = 'Profiles';
export var APP_TIMELINE_POST_TIP = 'app/timeline-post';
export var APP_TIMELINE_POST_TIP_LABEL = 'Timeline posts';
export var USER_SELECTABLE_SYSTEM_TYPES = [
    { $tip: APP_PLAN_TIP, label: APP_PLAN_TIP_LABEL, userSelectable: true },
    { $tip: APP_CHECKLIST_TIP, label: APP_CHECKLIST_TIP_LABEL, userSelectable: true },
    { $tip: APP_CHECKLIST_ITEM_TIP, label: APP_CHECKLIST_ITEM_TIP_LABEL, userSelectable: true },
    { $tip: APP_DOCUMENT_TIP, label: APP_DOCUMENT_TIP_LABEL, userSelectable: true },
    { $tip: APP_GROUP_TIP, label: APP_GROUP_TIP_LABEL, userSelectable: true },
    { $tip: APP_EMAIL_INBOUND_TIP, label: APP_EMAIL_INBOUND_TIP_LABEL, userSelectable: true },
    { $tip: APP_EMAIL_OUTBOUND_TIP, label: APP_EMAIL_OUTBOUND_TIP_LABEL, userSelectable: true },
    { $tip: APP_SMS_INBOUND_TIP, label: APP_SMS_INBOUND_TIP_LABEL, userSelectable: true },
    { $tip: APP_SMS_OUTBOUND_TIP, label: APP_SMS_OUTBOUND_TIP_LABEL, userSelectable: true },
    { $tip: APP_TEAM_TIP, label: APP_TEAM_TIP_LABEL, userSelectable: true },
    { $tip: APP_TEAM_ROLE_TIP, label: APP_TEAM_ROLE_TIP_LABEL, userSelectable: true },
    { $tip: APP_USER_TIP, label: APP_USER_TIP_LABEL, userSelectable: true },
    { $tip: APP_PROFILE_TIP, label: APP_PROFILE_TIP_LABEL, userSelectable: true },
    { $tip: APP_TIMELINE_POST_TIP, label: APP_TIMELINE_POST_TIP_LABEL, userSelectable: true },
];
export var NON_USER_SELECTABLE_SYSTEM_TYPES = [
    { $tip: 'app/email/recipient', label: 'Email recipient', userSelectable: false },
    { $tip: 'app/sms/recipient', label: 'Email recipient', userSelectable: false },
    { $tip: 'app/sms/outbound-template', label: 'Template', userSelectable: false },
    { $tip: 'app/email/outbound-template', label: 'Template', userSelectable: false },
    { $tip: 'app/sso/saml/idp', label: 'SAML identity provider', userSelectable: false },
    { $tip: 'app/x509', label: 'x509', userSelectable: false },
    { $tip: 'app/sms/outbound-template', label: 'Template', userSelectable: false },
    { $tip: 'app/au-gov/protective-marking', label: 'Protective marking', userSelectable: false },
    { $tip: 'app/au-gov/protective-markings-settings', label: 'Protective marking settings', userSelectable: false },
    { $tip: 'app/general-security-settings', label: 'General security settings', userSelectable: false },
    { $tip: 'app/api-key-meta', label: 'API key', userSelectable: false },
    { $tip: 'number-format', label: 'Number format', userSelectable: false },
    { $tip: 'app/branding-settings', label: 'Branding', userSelectable: false },
    { $tip: 'app/type/locale', label: 'Locale', userSelectable: false },
    { $tip: 'app/voice-message/recipient', label: 'Voice message recipient', userSelectable: false },
    { $tip: 'app/voice-message/outbound-template', label: 'Template', userSelectable: false },
];
export var SYSTEM_TYPES = tslib_1.__spread(USER_SELECTABLE_SYSTEM_TYPES, NON_USER_SELECTABLE_SYSTEM_TYPES);
var systemTypesMap = null;
export function getSystemTypesMap() {
    if (!systemTypesMap) {
        systemTypesMap = SYSTEM_TYPES.reduce(function (acc, systemType) {
            acc.set(systemType.$tip, systemType);
            return acc;
        }, new Map());
    }
    return systemTypesMap;
}
