import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';

import { SideSheetService, SheetSize } from '../side-sheet.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-side-sheet-header',
  templateUrl: './side-sheet-header.component.html',
  styleUrls: ['./side-sheet-header.component.scss']
})
export class SideSheetHeaderComponent implements OnInit, OnDestroy {
  private _disabled: boolean;

  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  set disabled(value: boolean) {
    this._disabled = value;
    if (value != null) {
      this.useFallBackDisable();
    }
  }

  @Input() action: string;
  @Input() cancel = 'Cancel';
  @Input() disabled$: Observable<boolean>;  // added for those cases where calling change detection manually in the parent component causes a problem.
                                            // Consider using this instead of 'disabled' going forward. Check 'EditFormSideSheetComponent'
  @Input() size: SheetSize = 'sm';
  @Input() showCancelButton = false;
  @Input() showBackIcon = false;
  @Input() showClose = true;
  @Input() longerText = false;
  @Input() hideBack = false;

  @Output() actioned: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() goBack: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() closed: EventEmitter<Event> = new EventEmitter<Event>();

  depth$: BehaviorSubject<number> = this.sideSheetService.depth$;
  actionButtonDisabled: boolean;
  actionButtonDisabledSubscription: Subscription;

  get displayClose() {
    return !this.action && this.showClose;
  }

  constructor(
    private sideSheetService: SideSheetService
  ) { }

  ngOnInit() {
    this.sideSheetService.resizeTo(this.size);

    if (this.disabled$) {
      this.actionButtonDisabledSubscription = this.disabled$.subscribe((value) => {
        this.actionButtonDisabled = value;
      });
    } else {
      this.useFallBackDisable();
    }
  }

  useFallBackDisable() {
    if (!this.disabled$) {
      this.actionButtonDisabled = this.disabled;
    }
  }

  ngOnDestroy(): void {
    if (this.actionButtonDisabledSubscription) {
      this.actionButtonDisabledSubscription.unsubscribe();
    }
  }

  back($event: Event) {
    if (this.goBack.observers.length) {
      this.goBack.emit($event);
    } else {
      this.sideSheetService.pop();
    }
  }

  close($event) {
    if (this.closed.observers.length) {
      this.closed.emit($event);
    }
    this.sideSheetService.clear(true);
  }

  onAction($event: Event) {
    this.actioned.emit($event);
  }
}
