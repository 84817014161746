import { Injectable } from '@angular/core';
import { ProcessService, IProcessResponse } from './process.service';
import { IVars } from './vars.service';
import { Observable, of } from 'rxjs';
import { first, map, filter } from 'rxjs/operators';
import { FormulaResult, FormulaService } from './formula.service';
import { Tip } from './models/types';
import { IQueryGenericResult, QueryService } from '../data/query.service';
import { EnoService } from '../data/eno.service';
import { MyProfileService } from '../shell/services/my-profile.service';
import { tagToMap } from '../util/tag-utils';
import { uniq, sortedUniq, union, get, set } from 'lodash';

export type TVariant = 'readonly' | 'collaboratereadonly' | 'dependency';

export interface ISecurityPolicySummary {
  $tip: Tip;
  name: string;
  type?: string;
}

export interface ISecurityCohort {
  tip: Tip;
  label?: string;
}

export interface ISecurityCohorts {
  profiles?: ISecurityCohort[];
  teams?: ISecurityCohort[];
  roles?: ISecurityCohort[];
  users?: ISecurityCohort[];
}

export interface ISecurityPolicy {
  $tip?: Tip;
  name: string;
  description?: string;
  type: 'system' | 'central' | 'bespoke';
  readCohorts?: ISecurityCohorts;
  changeCohorts?: ISecurityCohorts;
  deleteCohorts?: ISecurityCohorts;
  collaborateCohorts?: ISecurityCohorts;
}

export const SYSTEM_SECURITY_POLICIES = [
  { $tip: 'app/security-policy/system-all-users-read', name: 'All users read-only' },
  { $tip: 'app/security-policy/system-all-users-read-write', name: 'All users read/write' },
  { $tip: 'app/security-policy/system-all-users-read-write-delete', name: 'All users read/write/delete' }
];

@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  securityPolicyVariantCache = {};

  constructor(
    private processService: ProcessService,
    private formulaService: FormulaService,
    private queryService: QueryService,
    private enoService: EnoService,
    private myProfileService: MyProfileService
  ) {}

  // Retrieves a list of security policies
  getSecurityPolicies$(): Observable<ISecurityPolicySummary[]> {
    return this.queryService.execute1dFirst<IQueryGenericResult>(
      'eim/query/get-security-policies',
      {
        dimensionOptions: [{
          label: 'Tip dimension',
          formula: 'TIP()',
          sortby: ['TITLE()'],
          sortdir: ['asc']
        }]
      }
    ).pipe(
      map(results => results.map((result: IQueryGenericResult) => ({
        $tip: result.$tip,
        name: result.Name
      }))),
      map(results => [
        ...SYSTEM_SECURITY_POLICIES,
        ...results
      ])
    );
  }

  // Retrieve a security policy
  getSecurityPolicy$(tip: Tip): Observable<ISecurityPolicy> {
    return this.enoService.readEno(tip).pipe(
      map(eno => {
        const tags = tagToMap(eno.getFieldValues('security/policy/tag'));
        const cohorts = tags.has('cohortstash') ? JSON.parse(tags.get('cohortstash')) : {};
        const securityPolicy = {
          $tip: eno.tip,
          name: eno.getFieldStringValue('security/policy/label'),
          description: tags.get('description') || '',
          type: tags.get('policytype'),
          ...cohorts
        };
        return securityPolicy;
      })
    );
  }

  // Create a new security policy
  createSecurityPolicy$(securityPolicy: ISecurityPolicy): Observable<Tip> {
    const vars: IVars = this.convertSecurityPolicyToVars(securityPolicy);

    return this
      .processService
      .start('eim/process/security/create-security-policy', vars)
      .pipe(
        filter(({ finished }: { finished: boolean }) => Boolean(finished)),
        first(),
        map((response: IProcessResponse) => {
          if (response && response.vars && response.vars['SecurityPolicy'] && response.vars['SecurityPolicy'].length > 0) {
            return response.vars['SecurityPolicy'][0];
          }
          throw new Error('Unable to create new security policy');
        })
      );
  }

  // Update a central security policy
  updateSecurityPolicy$(securityPolicy: ISecurityPolicy): Observable<boolean> {
    const vars: IVars = { SecurityPolicyTip: [securityPolicy.$tip], ...this.convertSecurityPolicyToVars(securityPolicy) };

    return this
      .processService
      .start('eim/process/security/update-security-policy', vars)
      .pipe(
        filter(({ finished }: { finished: boolean }) => Boolean(finished)),
        first(),
        map((response: IProcessResponse) => {
          if (response && response.vars && response.vars['Success'] && response.vars['Success'].length > 0) {
            return response.vars['Success'][0] === 'true';
          }
          throw new Error('Unable to update security policy');
        })
      );
  }

  // Delete an existing security policy
  deleteSecurityPolicy$(tip: Tip): Observable<boolean> {
    const vars: IVars = { SecurityPolicyTip: [tip] };
    return this
      .processService
      .start('eim/process/security/delete-security-policy', vars)
      .pipe(
        filter(({ finished }: { finished: boolean }) => Boolean(finished)),
        first(),
        map((response: IProcessResponse) => {
          if (response && response.vars && response.vars['Success'] && response.vars['Success'].length > 0) {
            return response.vars['Success'][0] === 'true';
          }
          throw new Error('Unable to delete security policy');
        })
      );
  }

  // Duplicate an existing security policy
  duplicateSecurityPolicy$(tip: Tip): Observable<Tip> {
    const vars: IVars = { SecurityPolicyTip: [tip] };
    return this
      .processService
      .start('eim/process/security/duplicate-security-policy', vars)
      .pipe(
        filter(({ finished }: { finished: boolean }) => Boolean(finished)),
        first(),
        map((response: IProcessResponse) => {
          if (response && response.vars && response.vars['SecurityPolicy'] && response.vars['SecurityPolicy'].length > 0) {
            return response.vars['SecurityPolicy'][0];
          }
          throw new Error('Unable to duplicate security policy');
        })
      );
  }

  // Creates a bespoke security policy
  createBespokeSecurityPolicy$(securityPolicy: ISecurityPolicy): Observable<Tip> {
    const vars: IVars = this.convertSecurityPolicyToVars(securityPolicy);
    return this
      .processService
      .start('eim/process/security/create-bespoke-security-policy', vars)
      .pipe(
        filter(({ finished }: { finished: boolean }) => Boolean(finished)),
        first(),
        map((response: IProcessResponse) => {
          if (response && response.vars && response.vars['SecurityPolicy'] && response.vars['SecurityPolicy'].length > 0) {
            return response.vars['SecurityPolicy'][0];
          }
          throw new Error('Unable to create bespoke security policy');
        })
      );
  }

  // Updates a bespoke security policy
  updateBespokeSecurityPolicy$(objectTip: Tip, securityPolicy: ISecurityPolicy): Observable<boolean> {
    const vars: IVars = { ObjectTip: [objectTip], ...this.convertSecurityPolicyToVars(securityPolicy) };
    return this
      .processService
      .start('eim/process/security/update-bespoke-security-policy', vars)
      .pipe(
        filter(({ finished }: { finished: boolean }) => Boolean(finished)),
        first(),
        map((response: IProcessResponse) => {
          if (response && response.vars && response.vars['Success'] && response.vars['Success'].length > 0) {
            return response.vars['Success'][0] === 'true';
          }
          throw new Error('Unable to update bespoke security policy');
        })
      );
  }

  // Gets the security policy variant tip given the primary object
  getSecurityPolicyVariantTip$(variant: TVariant, primaryObjectTip?: Tip, primarySecurityPolicyTip?: Tip): Observable<Tip> {
    const vars: IVars = { Variant: [variant] };
    if (primaryObjectTip) {
      vars['PrimaryObjectTip'] = [primaryObjectTip];
    }
    if (primarySecurityPolicyTip) {
      const cachedVariantSecurityPolicyTip = get(this, ['securityPolicyVariantCache', primarySecurityPolicyTip, variant]);
      if (cachedVariantSecurityPolicyTip) {
        return of(cachedVariantSecurityPolicyTip);
      }
      vars['PrimarySecurityPolicyTip'] = [primarySecurityPolicyTip];
    }
    return this
      .processService
      .start('eim/process/security/get-security-policy-variant', vars)
      .pipe(
        filter(({ finished }: { finished: boolean }) => Boolean(finished)),
        map(response => {
          const variantSecurityPolicyTip = get(response, 'vars.VariantSecurityPolicyTip', []);
          if (variantSecurityPolicyTip.length > 0) {
            set(this, ['securityPolicyVariantCache', primarySecurityPolicyTip, variant], variantSecurityPolicyTip[0]);
            return variantSecurityPolicyTip[0];
          }
          throw new Error('Unable to get security policy variant');
        })
      );
  }

  // Return the security policy tip that should be applied to new objects
  getNewObjectSecurityPolicyTip$(): Observable<Tip> {
    return this.myProfileService.getMyProfile$().pipe(
      map(myProfile => {
        return myProfile.defaultPolicy || 'app/security-policy/system-all-users-read-write-delete';
      })
    );
  }

  // Returns the unique tips in security cohorts
  cohortsTips(cohorts: ISecurityCohorts): Tip[] {
    const tips = [];
    if (!cohorts) {
      return tips;
    }
    Object.keys(cohorts).forEach(key => {
      cohorts[key].forEach((cohort: ISecurityCohort) => {
        tips.push(cohort.tip);
      });
    });
    return uniq(tips);
  }

  // Returns true if the "All profiles" was selected, meaning all authenticated users
  isAllCohorts(cohortsTips: Tip[]): boolean {
    return cohortsTips.indexOf('__ALL__') > -1;
  }

  // Returns a summary label for security cohorts
  cohortsLabelSummary(cohorts: ISecurityCohorts): string {
    let labels = [];
    if (!cohorts) {
      return null;
    }
    Object.keys(cohorts).forEach(key => {
      cohorts[key].forEach((cohort: ISecurityCohort) => {
        if (cohort.label) {
          labels.push(cohort.label);
        }
      });
    });
    labels.sort();
    labels = sortedUniq(labels);
    if (labels.length > 2) {
      const excess = labels.length - 2;
      labels.splice(2, excess, '+' + excess + ' more');
    }
    return labels.join(', ');
  }

  // Convert an ISecurityPolicy to IVars for calling EnSrv
  private convertSecurityPolicyToVars(securityPolicy: ISecurityPolicy): IVars {
    const readCohorts = this.cohortsTips(securityPolicy.readCohorts);
    const deleteCohorts = this.cohortsTips(securityPolicy.deleteCohorts);
    const changeCohorts = uniq(union(this.cohortsTips(securityPolicy.changeCohorts), deleteCohorts));
    const collaborateCohorts = uniq(union(this.cohortsTips(securityPolicy.collaborateCohorts), changeCohorts));

    const allDeleteCohorts = this.isAllCohorts(deleteCohorts);
    const allReadCohorts = this.isAllCohorts(readCohorts);
    const allChangeCohorts = this.isAllCohorts(changeCohorts);
    const allCollaborateCohorts = this.isAllCohorts(collaborateCohorts);

    return {
      name: [securityPolicy.name],
      description: [securityPolicy.description],
      readCohorts: allReadCohorts ? [] : readCohorts,
      collaborateCohorts: allCollaborateCohorts ? [] : collaborateCohorts,
      changeCohorts: allChangeCohorts ? [] : changeCohorts,
      deleteCohorts: allDeleteCohorts ? [] : deleteCohorts,
      allReadCohorts: [allReadCohorts ? 'true' : 'false'],
      allCollaborateCohorts: [allCollaborateCohorts ? 'true' : 'false'],
      allChangeCohorts: [allChangeCohorts ? 'true' : 'false'],
      allDeleteCohorts: [allDeleteCohorts ? 'true' : 'false'],
      cohortStash: [
        JSON.stringify({
          readCohorts: securityPolicy.readCohorts,
          collaborateCohorts: securityPolicy.collaborateCohorts,
          changeCohorts: securityPolicy.changeCohorts,
          deleteCohorts: securityPolicy.deleteCohorts
        })
      ]
    };
  }

  // Returns true if the current user can perform all the given actions on all the given objects
  canAction(objectTips: string[], securityActions: string[]): Observable<boolean> {
    const formula = 'CAN_ACTION(SECURITY(VAR("Objects")), VAR("Actions"))';

    return this.formulaService.evaluate(formula, null, null, { Objects: objectTips, Actions: securityActions }).pipe(
      map(result => result && result[0] === 'true')
    );
  }

  // Returns true if the current user can read the given object
  canRead(objectTip: string | string[]): Observable<boolean> {
    const tips = stringOrStringArrayToStringArray(objectTip);
    return this.canAction(tips, ['security/action/read']);
  }

  // Returns true if the current user can update the given object
  canUpdate(objectTip: string | string[]): Observable<boolean> {
    const tips = stringOrStringArrayToStringArray(objectTip);
    return this.canAction(tips, ['security/action/update']);
  }

  // Returns true if the current user can delete the given object
  canDelete(objectTip: string | string[]): Observable<boolean> {
    const tips = stringOrStringArrayToStringArray(objectTip);
    return this.canAction(tips, ['security/action/delete']);
  }

  // Returns true if the current user can collaborate on the given object
  canCollaborate(objectTip: string | string[]): Observable<boolean> {
    const tips = stringOrStringArrayToStringArray(objectTip);
    return this.canAction(tips, ['app/security-action/collaborate']);
  }

  // "collaborate" is a separate concept that does not inherit "update" permission or vice versa.
  // "collaborate" only applies to context objects not sub-objects, e.g. chat posts, plans etc.
  canUpdateOrCollaborate(objectTip: string | string[]): Observable<boolean> {
    const objectTips = stringOrStringArrayToStringArray(objectTip);
    const formula = `
OR(
   CAN_ACTION(
      SECURITY(
         VAR("Objects")
      ),
      "security/action/update"
   ),
   CAN_ACTION(
      SECURITY(
         VAR("Objects")
      ),
      "app/security-action/collaborate"
   )
)
    `;

    return this.formulaService.evaluate(formula, null, null, { Objects: objectTips }).pipe(
      map((result: FormulaResult) => result && result[0] === 'true')
    );
  }

  // Returns true if the current user can create an instance of the given object type
  canCreate(objectTypeTip: string | string[]): Observable<boolean> {
    const tips = stringOrStringArrayToStringArray(objectTypeTip);
    return this.canAction(tips, ['security/action/create']);
  }
}

function stringOrStringArrayToStringArray(input: string | string[]) {
  return Array.isArray(input) ? input : [input];
}
