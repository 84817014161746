import { FieldType } from '@ngx-formly/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { get } from 'lodash';

import { Tip } from '../../../data/models/types';
import { IQueryAndFilterOptions } from '../../../query/query-chooser/query-chooser.component';

@Component({
  selector: 'app-simple-query-filter-chooser',
  template: `
    <app-query-chooser
        [formControl]="formControl"
        [label]="to.label"
        [required]="to.required"
        [placeholder]="to.placeholder"
        [objectTypeTip]="objectTypeTip"
        [contextTypeTip]="to.contextTypeTip"
        [contextTypeTitle]="to.contextTypeTitle"
        [moduleTip]="moduleTip"
        [withFilterInputs]="withFilterInputs"
        [sortSearchableFieldsOnly]="to.sortSearchableFieldsOnly"></app-query-chooser>
  `
})
export class FormlyFieldSimpleQueryChooserComponent extends FieldType implements OnInit, OnDestroy {
  objectTypeTip: Tip;
  moduleTip: Tip;
  withFilterInputs = true;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    this.setProperties(true);

    const objectTypeTipControl = this.form.get('templateOptions').get('tableConfig').get('objectTypeTip');
    const queryTipControl = this.form.get('templateOptions').get('tableConfig').get('queryTip');
    const filterInputsControl = this.form.get('templateOptions').get('tableConfig').get('filterInputs');
    const sortingOptionsControl = this.form.get('templateOptions').get('tableConfig').get('sortingOptions');

    if (objectTypeTipControl) {
      this.subscriptions.add(objectTypeTipControl.valueChanges.subscribe(() => this.setProperties()));
    }

    this.subscriptions.add(this.formControl.valueChanges.subscribe(
      (queryAndFilterOptions: IQueryAndFilterOptions) => {
        if (queryTipControl) {
          queryTipControl.setValue(queryAndFilterOptions.queryTip);
        }

        if (filterInputsControl) {
          filterInputsControl.setValue(queryAndFilterOptions.filterInputs);
        }

        if (sortingOptionsControl) {
          sortingOptionsControl.setValue(queryAndFilterOptions.sortingOptions);
        }
      }
    ));

    // This will work for the references within primary router outlet.
    this.moduleTip = get(this.options, 'formState.moduleTip', null) || this.route.snapshot.params['moduleTip'];

    if (Object.keys(this.to).includes('withFilterInputs')) {
      this.withFilterInputs = this.to.withFilterInputs;
    }
  }

  setProperties(firstTime: boolean = false) {
    const newObjectTypeTip: Tip = get(this, 'field.templateOptions.objectTypeTip', null);

    if (!firstTime && this.objectTypeTip !== newObjectTypeTip) {
      this.formControl.setValue({ queryTip: null, filterInputs: [] });
    }

    this.objectTypeTip = newObjectTypeTip;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
