import { Injectable } from '@angular/core';
import { IForEachNodeEditor } from '../node-editors/for-each-node-editor/for-each-node-editor.component';
import { IProcessNodeForeach } from '../../../models/process';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import { CompositeNodeConverter, INodeAndLink } from './CompositeNodeConverter';
import { createConnection, createForEachNode, createSubProcessNode } from './utils/create-node-utils';
import { Parser, Stringify } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { get, isEmpty } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ForEachNodeConverterService extends CompositeNodeConverter {
  type = 'processnode/logic/foreach';
  processNodeType = 'processnodetype/logic/foreach';

  constructor(
  ) {
    super();
  }

  public convert(nodeData: IForEachNodeEditor): INodeAndLink {
    if (!this.isValid(nodeData)) {
      throw new InvalidWorkflowNodeDataError(`Please correct invalid data on for-each node [${nodeData.fields.title}]`, nodeData);
    }

    const currentElementVarKey = 'Current-' + Math.random(); // use random to avoid potential nested loop variable collision.

    const forEachNode: IProcessNodeForeach = createForEachNode(
      {
        name: 'SLICE',
        args: [Parser(nodeData.fields.loopSource.formula), 0, 1000]
      },
      currentElementVarKey,
      nodeData.tip
    );

    const vars = [];
    const subVars = [];
    const outputSubVars = [];
    const outputVars = [];

    // tslint:disable-next-line:no-string-literal
    if (isEmpty(nodeData.fields.inputOutputMaps) && nodeData.fields['subWorkflowInputKey']) {
      // backwards compatible
      vars.push(currentElementVarKey);
      // tslint:disable-next-line:no-string-literal
      subVars.push(nodeData.fields['subWorkflowInputKey']);
    } else {
      nodeData.fields.inputOutputMaps.inputMaps.forEach(({ parentProperty, subInputKey }, index) => {
        if (!parentProperty || !parentProperty.selectedPropertyKey || !subInputKey) {
          return;
        }

        if (index === 0) {
          vars.push(currentElementVarKey);
          subVars.push(subInputKey);

          return;
        }

        vars.push(parentProperty.selectedPropertyKey);
        subVars.push(subInputKey);
      });
      nodeData.fields.inputOutputMaps.outputMaps.forEach(({ parentVariableKey, subVariableKey }) => {
        if (!parentVariableKey || !subVariableKey) {
          return;
        }

        outputSubVars.push(subVariableKey);
        outputVars.push(parentVariableKey);
      });
    }

    const subProcessTipFormula = Stringify({
      name: 'FIELD',
      args: [
        'app/workflow:process',
        get(nodeData, 'fields.subWorkflowTip[0]', null)
      ]
    });

    // 10m timeout by default (not currently working as timeout is not implemented on server-side)
    const subProcessNode = createSubProcessNode(subProcessTipFormula, vars, 10 * 60 * 1000, subVars, outputSubVars, outputVars);

    // link from for-each to sub-process
    const iterationConnectionForForEachNode = createConnection('foreach', subProcessNode);
    forEachNode.connections.push(iterationConnectionForForEachNode);

    // done link from sub-process to for-each
    const doneConnectionForSubprocessNode = createConnection('done', forEachNode);
    subProcessNode.connections.push(doneConnectionForSubprocessNode);

    // timeout link from sub-process to for-each
    const timeoutConnectionForSubprocessNode = createConnection('timeout', forEachNode);
    subProcessNode.connections.push(timeoutConnectionForSubprocessNode);

    // make the final collection of all nodes and links
    const nodesAndLink: INodeAndLink = {
      nodes: [
        forEachNode,
        subProcessNode
      ],
      links: [
        iterationConnectionForForEachNode,
        doneConnectionForSubprocessNode,
        timeoutConnectionForSubprocessNode
      ]
    };

    this.populateOutgoingLinks(nodeData).forEach(link => {
      forEachNode.connections.push(link);
      nodesAndLink.links.push(link);
    });

    return nodesAndLink;
  }

  private isValid(nodeData: IForEachNodeEditor): boolean {
    return !isEmpty(nodeData.fields.title)
      && !isEmpty(nodeData.fields.loopSource)
      && !isEmpty(nodeData.fields.loopSource.formula)
      && !isEmpty(nodeData.fields.subWorkflowTip);
  }

}
