import { IInnerOperator, IOperators, reverseOperator } from './operators';
import { OPERATOR_TYPE } from '../../../models/query';
import { stringEqual, stringNotEqual } from './string-operators';

const numberLessThan: IInnerOperator = {
  opNo: 2,
  toFormula: (leftArg, rightArg) => ({
    name: 'OP',
    args: [leftArg, '<', rightArg]
  })
};
const numberGreaterThanOrEqualTo = reverseOperator(numberLessThan);

const numberGreaterThan: IInnerOperator = {
  opNo: 2,
  toFormula: (leftArg, rightArg) => ({
    name: 'OP',
    args: [leftArg, '>', rightArg]
  })
};
const numberLessThanOrEqualTo = reverseOperator(numberGreaterThan);

export const numberOperators: IOperators = {
  operators: {
    [OPERATOR_TYPE.EQUALS]: stringEqual,
    [OPERATOR_TYPE.NOT_EQUALS]: stringNotEqual,
    [OPERATOR_TYPE.LESS_THAN]: numberLessThan,
    [OPERATOR_TYPE.GREATER_THAN]: numberGreaterThan,
    [OPERATOR_TYPE.LESS_THAN_EQUAL_TO]: numberLessThanOrEqualTo,
    [OPERATOR_TYPE.GREATER_THAN_EQUAL_TO]: numberGreaterThanOrEqualTo
  },
  getParts: formula => {
    let currentPart = formula;
    let negative = false;
    if (currentPart.name === 'NOT') {
      currentPart = currentPart.args[0];
      negative = true;
    }

    if (currentPart.name === 'OP') {
      if (currentPart.args[1] === '<') {
        return {
          operator: negative ? OPERATOR_TYPE.GREATER_THAN_EQUAL_TO : OPERATOR_TYPE.LESS_THAN,
          leftArg: currentPart.args[0],
          rightArg: currentPart.args[2]
        };
      }

      if (currentPart.args[1] === '>') {
        return {
          operator: negative ? OPERATOR_TYPE.LESS_THAN_EQUAL_TO : OPERATOR_TYPE.GREATER_THAN,
          leftArg: currentPart.args[0],
          rightArg: currentPart.args[2]
        };
      }

      throw new Error('Unknown operators:' + currentPart.name);
    }

    if (currentPart.name === 'EQUALS') {
      return {
        operator: negative ? OPERATOR_TYPE.NOT_EQUALS : OPERATOR_TYPE.EQUALS,
        leftArg: currentPart.args[0],
        rightArg: currentPart.args[1]
      };
    }

    throw new Error('Unknown operators: ' + currentPart.name);
  }
};
