import * as tslib_1 from "tslib";
import { flatMapScheme, mapDataSourceScheme, mapExtentScheme, mapLayerScheme, mapScheme, mapStyleScheme } from '../../models/map';
import { combineLatest, of } from 'rxjs';
import { first, map, switchMap, catchError } from 'rxjs/operators';
import dataConstants from '../../data/constants';
import { DEFAULT_DIMENSION } from '../../data/query.service';
import { MODULE_POLICY_TYPE } from '../modules/module.service';
import { cloneDeep, merge } from 'lodash';
import { DEFAULT_EXTENT_TIP } from '../../models/system-settings';
import { QuoteString } from '../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { getModuleTitleFormula } from '../modules/module.constant';
import { queryScheme } from '../../models/query';
import * as i0 from "@angular/core";
import * as i1 from "../../data/object-service/object.service";
import * as i2 from "../../data/query.service";
import * as i3 from "../modules/module.service";
import * as i4 from "../modules/module-package.service";
var securityFormula = 'EQUALS(SECURITY(), "app/security-policy/instance-user-runtime")';
var MapService = /** @class */ (function () {
    function MapService(objectService, queryService, moduleService, modulePackageService) {
        this.objectService = objectService;
        this.queryService = queryService;
        this.moduleService = moduleService;
        this.modulePackageService = modulePackageService;
    }
    /************************* Map data source *****************************/
    MapService.prototype.getDataSources = function (moduleTip) {
        var queryOptions = {
            extraAttributes: [
                { label: 'name', formula: 'FIELD("app/map/source:name")' },
                { label: 'type', formula: 'FIELD("app/map/source:type")' }
            ],
            extraFilters: [
                { label: 'Map source type filter', formula: 'ISTYPE("app/map/source")' },
                { label: 'Is user created', formula: "NOT(" + securityFormula + ")" }
            ],
            dimensionOptions: [merge(cloneDeep(DEFAULT_DIMENSION[0]), {
                    sortby: ['FIELD("app/map/source:name")'],
                    sortdir: ['asc']
                })]
        };
        if (moduleTip) {
            queryOptions.extraFilters.push({
                label: 'In module data source field',
                formula: "INARRAY(REFERENCES(\"app/module:map-data-sources\", TIP()), " + QuoteString(moduleTip) + ")"
            });
        }
        else {
            queryOptions.extraAttributes.push({ label: 'moduleName', formula: getModuleTitleFormula('app/map/source') });
        }
        return this.queryService.execute1dFirst('eim/query/generic-query', queryOptions);
    };
    MapService.prototype.getDataSource = function (dataSourceTip) {
        return this.objectService.getObject(dataSourceTip, mapDataSourceScheme);
    };
    MapService.prototype.setDataSource = function (dataSource, moduleTip) {
        var _this = this;
        return this.objectService.setObject(dataSource, mapDataSourceScheme).pipe(map(function () { return true; }), catchError(function () {
            return _this.modulePackageService.handleError('save data source', moduleTip);
        }));
    };
    MapService.prototype.deleteDataSource = function (dataSource, moduleTip) {
        var _this = this;
        return this.moduleService.deleteModuleConfig([dataSource.$tip]).pipe(catchError(function () {
            return _this.modulePackageService.handleError('delete data source', moduleTip);
        }));
    };
    MapService.prototype.createDataSource = function (dataSource, moduleTip) {
        var _this = this;
        return this.moduleService
            .getModulePolicy(moduleTip, MODULE_POLICY_TYPE.INSTANCE)
            .pipe(first(), switchMap(function (instancePolicy) {
            return _this.objectService
                .setObject(dataSource, mapDataSourceScheme, dataConstants.BRANCH_MASTER, instancePolicy || 'app/security-policy/instance-user-admin' // -!-!-!- DELETE BACKUP OPTION AFTER MIGRATION - EIM-2691 -!-!-!-
            )
                .pipe(first());
        }), switchMap(function (batch) {
            var tip = batch[batch.length - 1].tip;
            return _this.moduleService
                .addTipToFlatModuleField({
                moduleTip: moduleTip,
                moduleField: 'mapDataSources',
                tip: tip
            });
        }), catchError(function () {
            return _this.modulePackageService.handleError('save data source', moduleTip);
        }));
    };
    MapService.prototype.newDataSource = function (type) {
        return {
            $type: 'app/map/source',
            name: '',
            type: type,
            config: {}
        };
    };
    MapService.prototype.getCriteriaFromQueryTip = function (queryTip) {
        return this.objectService.getObject(queryTip, queryScheme).pipe(map(function (query) {
            var filterFormulas = query.filters ? query.filters.map(function (filter) { return filter.formula; }) : [];
            switch (filterFormulas.length) {
                case 0: return null;
                case 1: return filterFormulas[0];
                default: return "AND(" + filterFormulas.join(',') + ")";
            }
        }));
    };
    /************************* Map layer *****************************/
    MapService.prototype.getLayers = function (moduleTip, notBaseMap) {
        var queryOptions = {
            extraAttributes: [
                { label: 'name', formula: 'FIELD("app/map/layer:name")' },
                { label: 'dataSourceType', formula: 'FIELD("app/map/source:type", FIELD("app/map/layer:data-source"))' },
                { label: 'isBaseMap', formula: 'FIELD("app/map/layer:is-base-map")' }
            ],
            extraFilters: [
                { label: 'Map layer type filter', formula: 'ISTYPE("app/map/layer")' },
                { label: 'Is user created', formula: "NOT(" + securityFormula + ")" }
            ],
            dimensionOptions: [merge(cloneDeep(DEFAULT_DIMENSION[0]), {
                    sortby: ['FIELD("app/map/source:type", FIELD("app/map/layer:data-source"))', 'FIELD("app/map/layer:name")'],
                    sortdir: ['asc', 'asc']
                })]
        };
        if (moduleTip) {
            queryOptions.extraFilters.push({ label: 'In module layer field', formula: "INARRAY(REFERENCES(\"app/module:map-layers\", TIP()), " + QuoteString(moduleTip) + ")" });
        }
        else {
            queryOptions.extraAttributes.push({ label: 'moduleName', formula: getModuleTitleFormula('app/map/layer') });
        }
        if (notBaseMap) {
            queryOptions.extraFilters.push({ label: 'Is not base map', formula: 'NOT(EQUALS(FIELD("app/map/layer:is-base-map"), "true"))' });
        }
        return this.queryService.execute1dFirst('eim/query/generic-query', queryOptions);
    };
    MapService.prototype.getLayer = function (layerTip) {
        return this.objectService.getObject(layerTip, mapLayerScheme);
    };
    MapService.prototype.setLayer = function (layer, moduleTip) {
        var _this = this;
        return this.objectService.setObject(layer, mapLayerScheme).pipe(map(function () { return true; }), catchError(function () {
            return _this.modulePackageService.handleError('save layer', moduleTip);
        }));
    };
    MapService.prototype.deleteLayer = function (layer, moduleTip) {
        var _this = this;
        return this.moduleService.deleteModuleConfig([layer.$tip]).pipe(catchError(function () {
            return _this.modulePackageService.handleError('delete layer', moduleTip);
        }));
    };
    MapService.prototype.createLayer = function (layer, moduleTip) {
        var _this = this;
        return this.moduleService
            .getModulePolicy(moduleTip, MODULE_POLICY_TYPE.INSTANCE)
            .pipe(first(), switchMap(function (instancePolicy) {
            return _this.objectService
                .setObject(layer, mapLayerScheme, dataConstants.BRANCH_MASTER, instancePolicy || 'app/security-policy/instance-user-admin' // -!-!-!- DELETE BACKUP OPTION AFTER MIGRATION - EIM-2691 -!-!-!-
            )
                .pipe(first());
        }), switchMap(function (batch) {
            var tip = batch[batch.length - 1].tip;
            return _this.moduleService
                .addTipToFlatModuleField({
                moduleTip: moduleTip,
                moduleField: 'mapLayers',
                tip: tip
            });
        }), catchError(function () {
            return _this.modulePackageService.handleError('save layer', moduleTip);
        }));
    };
    MapService.prototype.newLayer = function (dataSourceTip) {
        return this.getDataSource(dataSourceTip).pipe(first(), map(function (dataSource) { return ({
            $type: 'app/map/layer',
            name: '',
            dataSource: dataSource,
            isBaseMap: false,
            geometryType: null,
            styleType: null,
            style: null,
            heatmapRadius: 0,
            hasMinZoom: false,
            hasMaxZoom: false,
            minZoomLevel: 0,
            maxZoomLevel: 23,
            opacity: 80,
            visibleProperties: []
        }); }));
    };
    /************************* Map *****************************/
    MapService.prototype.getMaps = function (moduleTip) {
        var queryOptions = {
            extraAttributes: [
                { label: 'name', formula: 'FIELD("app/map:name")' },
                { label: 'layerCount', formula: 'COUNT(FIELD("app/map:layers"))' }
            ],
            extraFilters: [
                { label: 'Map type filter', formula: 'ISTYPE("app/map")' },
                { label: 'Is user created', formula: "NOT(" + securityFormula + ")" }
            ],
            dimensionOptions: [merge(cloneDeep(DEFAULT_DIMENSION[0]), {
                    sortby: ['FIELD("app/map:name")'],
                    sortdir: ['asc']
                })]
        };
        if (moduleTip) {
            queryOptions.extraFilters.push({ label: 'In module map field', formula: "INARRAY(REFERENCES(\"app/module:maps\", TIP()), " + QuoteString(moduleTip) + ")" });
        }
        return this.queryService.execute1dFirst('eim/query/generic-query', queryOptions);
    };
    MapService.prototype.getMap = function (mapInput) {
        if (this.isMapTip(mapInput)) {
            return this.getMapFromTip(mapInput);
        }
        else if (this.isFlatMap(mapInput)) {
            return this.getMapFromFlatMap(mapInput);
        }
        else if (this.isMap(mapInput)) {
            return of(mapInput);
        }
    };
    MapService.prototype.isMapTip = function (mapInput) {
        return mapInput.name === undefined;
    };
    MapService.prototype.isFlatMap = function (mapInput) {
        return mapInput.baseMap.name === undefined;
    };
    MapService.prototype.isMap = function (mapInput) {
        return mapInput.baseMap.name !== undefined;
    };
    MapService.prototype.getMapFromTip = function (mapTip) {
        return this.objectService.getObject(mapTip, mapScheme);
    };
    MapService.prototype.getMapFromFlatMap = function (flatMap) {
        var _this = this;
        var observables = tslib_1.__spread([
            this.getLayer(flatMap.baseMap).pipe(first())
        ], flatMap.layers.map(function (layer) { return _this.getLayer(layer).pipe(first()); }));
        if (flatMap.extent.layer) {
            flatMap.extent.layer.forEach(function (layer) { return observables.push(_this.getLayer(layer).pipe(first())); });
        }
        return combineLatest(observables).pipe(switchMap(function (layers) {
            var baseMap = layers.shift();
            var extentLayer = flatMap.extent.layer ? layers.splice(flatMap.layers.length, flatMap.extent.layer.length) : undefined;
            return of({
                name: flatMap.name,
                baseMap: baseMap,
                layers: layers,
                extent: {
                    type: flatMap.extent.type,
                    layer: extentLayer,
                    polygon: flatMap.extent.polygon,
                    zoomLevel: flatMap.extent.zoomLevel
                }
            });
        }));
    };
    MapService.prototype.getFlatMap = function (mapTip) {
        return this.objectService.getObject(mapTip, flatMapScheme);
    };
    MapService.prototype.setFlatMap = function (mapObj, moduleTip) {
        var _this = this;
        return this.objectService.setObject(mapObj, flatMapScheme).pipe(map(function () { return true; }), catchError(function () {
            return _this.modulePackageService.handleError('save flat map', moduleTip);
        }));
    };
    MapService.prototype.deleteMap = function (mapObj, moduleTip) {
        var _this = this;
        return this.moduleService.deleteModuleConfig([mapObj.$tip]).pipe(catchError(function () {
            return _this.modulePackageService.handleError('delete flat map', moduleTip);
        }));
    };
    MapService.prototype.createFlatMap = function (mapObj, moduleTip) {
        var _this = this;
        return this.moduleService
            .getModulePolicy(moduleTip, MODULE_POLICY_TYPE.INSTANCE)
            .pipe(first(), switchMap(function (instancePolicy) {
            return _this.objectService
                .setObject(mapObj, flatMapScheme, dataConstants.BRANCH_MASTER, instancePolicy || 'app/security-policy/instance-user-admin' // -!-!-!- DELETE BACKUP OPTION AFTER MIGRATION - EIM-2691 -!-!-!-
            )
                .pipe(first());
        }), switchMap(function (batch) {
            var tip = batch[batch.length - 1].tip;
            return _this.moduleService
                .addTipToFlatModuleField({
                moduleTip: moduleTip,
                moduleField: 'maps',
                tip: tip
            });
        }), catchError(function () {
            return _this.modulePackageService.handleError('save flat map', moduleTip);
        }));
    };
    MapService.prototype.newFlatMap = function () {
        return {
            $type: 'app/map',
            name: '',
            baseMap: null,
            layers: [],
            extent: {
                $type: 'app/map/extent',
                type: null
            }
        };
    };
    /************************* Map extent *****************************/
    MapService.prototype.getDefaultExtent = function () {
        return this.objectService.getObject(DEFAULT_EXTENT_TIP, mapExtentScheme);
    };
    /************************* Base map *****************************/
    MapService.prototype.getBaseMaps = function (stockBaseMap) {
        var queryOptions = {
            extraAttributes: [
                { label: 'name', formula: 'FIELD("app/map/layer:name")' }
            ],
            extraFilters: [
                { label: 'Map layer type filter', formula: 'ISTYPE("app/map/layer")' },
                { label: 'Is base map', formula: 'EQUALS(FIELD("app/map/layer:is-base-map"), "true")' },
            ],
            dimensionOptions: [merge(cloneDeep(DEFAULT_DIMENSION[0]), {
                    sortby: ['FIELD("app/map/layer:name")'],
                    sortdir: ['asc']
                })]
        };
        if (stockBaseMap) {
            queryOptions.extraFilters.push({ label: 'Is noggin shipped', formula: securityFormula });
        }
        else {
            queryOptions.extraFilters.push({ label: 'Is user created', formula: "NOT(" + securityFormula + ")" });
        }
        return this.queryService.execute1dFirst('eim/query/generic-query', queryOptions);
    };
    /************************* Map style *****************************/
    MapService.prototype.getStyles = function (geometryType) {
        var queryOptions = {
            extraAttributes: [
                { label: 'name', formula: 'FIELD("app/map/style:name")' }
            ],
            extraFilters: [
                { label: 'Map style type filter', formula: 'ISTYPE("app/map/style")' },
                { label: 'Is geometry type', formula: "EQUALS(FIELD(\"app/map/style:type\"), " + QuoteString(geometryType) + ")" }
            ],
            dimensionOptions: [merge(cloneDeep(DEFAULT_DIMENSION[0]), {
                    sortby: ['FIELD("app/map/style:name")'],
                    sortdir: ['asc']
                })]
        };
        return this.queryService.execute1dFirst('eim/query/generic-query', queryOptions);
    };
    MapService.prototype.getStyle = function (styleTip) {
        return this.objectService.getObject(styleTip, mapStyleScheme);
    };
    MapService.prototype.setStyle = function (styleObj, moduleTip) {
        var _this = this;
        return this.objectService.setObject(styleObj, mapStyleScheme, dataConstants.BRANCH_MASTER, 'app/security-policy/instance-user-admin').pipe(map(function (batch) { return batch[batch.length - 1].tip; }), catchError(function () {
            return _this.modulePackageService.handleError('save style', moduleTip);
        }));
    };
    MapService.prototype.newStyle = function (geometryType) {
        return {
            $type: 'app/map/style',
            name: '',
            type: geometryType,
            config: null
        };
    };
    MapService.prototype.deleteStyle = function (styleObj, moduleTip) {
        var _this = this;
        return this.moduleService.deleteModuleConfig([styleObj.$tip]).pipe(catchError(function () {
            return _this.modulePackageService.handleError('delete style', moduleTip);
        }));
    };
    MapService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MapService_Factory() { return new MapService(i0.ɵɵinject(i1.ObjectService), i0.ɵɵinject(i2.QueryService), i0.ɵɵinject(i3.ModuleService), i0.ɵɵinject(i4.ModulePackageService)); }, token: MapService, providedIn: "root" });
    return MapService;
}());
export { MapService };
