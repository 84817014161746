import { IObject } from '../data/models/object';
import { DataTypes, IObjectScheme } from '../data/models/scheme';

/************************* Mapping *****************************/


export const MAPPING_TIP = 'app/system-settings/mapping';
export const DEFAULT_MAP_TIP = 'app/map/default-map';
export const DEFAULT_EXTENT_TIP = 'app/map/default-extent';

export enum MAPPING_SYSTEM {
  ESRI = 'esri',
  MAPBOX = 'mapbox'
}

export interface IMappingGlobalSettings extends IObject {
  system: MAPPING_SYSTEM;
  useDefaultGeocoder: boolean;
}

export const mappingGlobalSettingsScheme: IObjectScheme = {
  'app/system-settings/mapping-type:system': { type: DataTypes.string },
  'app/system-settings/mapping-type:use-default-geocoder': { type: DataTypes.boolean }
};
