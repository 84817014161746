import { DataTypes, IObjectScheme } from '../data/models/scheme';
import { IObject } from '../data/models/object';
import { Formula, Tip } from '../data/models/types';

import { cloneDeep } from 'lodash';

export {
  IProcess,
  IProcessNode,
  IProcessConnection,
  processScheme,
  processNodeScheme,
  processConnectionScheme,
  IProcessNodeIf,
  processNodeIfScheme,
  IProcessNodeNoOp,
  processNodeNoOpScheme,
  IProcessNodeSwitch,
  processNodeSwitchScheme,
  IProcessNodeSubprocess,
  processNodeSubprocessScheme,
  IProcessNodeUpdateEno,
  processNodeUpdateEnoScheme,
  IProcessNodeCreate,
  processNodeCreateScheme,
  IProcessNodeDelete,
  processNodeDeleteScheme,
  IProcessNodeDuplicate,
  processNodeDuplicateScheme,
  IProcessNodeSetVariable,
  processNodeSetVariableScheme,
  IProcessNodeForeach,
  processNodeForeachScheme
};

interface IProcessConnection extends IObject {
  outcome: string;
  toNodes: IProcessNode[];
  point: number[];
  delaySec?: number | Formula;
  // Only used for converting from GoJS to process. This is the unique keys of toNodes in GoJS.
  toNodeKeys?: string[];
  // Only used for converting from GoJS to process. This is the unique key of the node that this connection starts from
  fromNodeKey?: string;
}

interface IIconFile extends IObject {
  name: string;
  mime: string;
  size: number;
  data: string;
}

interface IProcessNode extends IObject {
  type: Tip;
  connections: IProcessConnection[];
  env?: string;
  point: number[];
  key?: string; // This is the unique key that represents the node in GoJS
}

interface IProcess extends IObject {
  title: string;
  description: string;
  nodes: IProcessNode[];
  grants?: string;
  env: string;
  requiredVars?: string[];
  requiredVarsDescription?: string[];
  optionalVars?: string[];
  optionalVarsDescription?: string[];
}

const iconFileScheme: IObjectScheme = {
  'file/name': {},
  'file/mime': {},
  'file/size': { type: DataTypes.number },
  'file/data': {},
};

/**
 * Moved 'processconnection/tonode' below 'processNodeScheme' to avoid circular reference issue
 */
const processConnectionScheme: IObjectScheme = {
  'processconnection/outcome': { type: DataTypes.i18n },
  'processconnection/point': { type: DataTypes.numberArray },
  'processconnection/delay': { name: 'delaySec', type: DataTypes.number }
};

type VAR_SCOPE = 'Global' | 'Local';

const processNodeScheme: IObjectScheme = {
  'processnode/type': {},
  'processnode/env': {},
  'processnode/point': { type: DataTypes.numberArray },
};

// IF node
interface IProcessNodeIf extends IProcessNode {
  expression: Formula;
}
const processNodeIfScheme = cloneDeep(processNodeScheme);
processNodeIfScheme['processnode/logic/if/expr'] = { name: 'expression', type: DataTypes.string };

// Subprocess node
interface IProcessNodeSubprocess extends IProcessNode {
  process: Formula;
  varKeys: string[];
  subVarKeys?: string[];
  outputVarKeys?: string[];
  outputSubVarKeys?: string[];
  outputVarScope?: VAR_SCOPE;
  timeoutMs?: number;
  waitForProcessComplete?: boolean;
}
const processNodeSubprocessScheme = cloneDeep(processNodeScheme);
processNodeSubprocessScheme['processnode/logic/subprocess/process'] = { type: DataTypes.formula };
processNodeSubprocessScheme['processnode/logic/subprocess/var-key'] = { name: 'varKeys', type: DataTypes.stringArray };
processNodeSubprocessScheme['processnode/logic/subprocess/subvar-key'] = { name: 'subVarKeys', type: DataTypes.stringArray };
processNodeSubprocessScheme['processnode/logic/subprocess/output-var-key'] = { name: 'outputVarKeys', type: DataTypes.stringArray };
processNodeSubprocessScheme['processnode/logic/subprocess/output-subvar-key'] = { name: 'outputSubVarKeys', type: DataTypes.stringArray };
processNodeSubprocessScheme['processnode/logic/subprocess/output-var-scope'] = { name: 'outputVarScope', type: DataTypes.string };
processNodeSubprocessScheme['processnode/logic/subprocess/timeout'] = { name: 'timeoutMs', type: DataTypes.number };
processNodeSubprocessScheme['processnode/logic/subprocess/wait-for-process-complete'] = {
  name: 'waitForProcessComplete',
  type: DataTypes.boolean
};

// No op node
interface IProcessNodeNoOp extends IProcessNode {
  dummy?: never;
}
const processNodeNoOpScheme = cloneDeep(processNodeScheme);

// Update eno node
interface IProcessNodeUpdateEno extends IProcessNode {
  updateEnoObject: Formula | Tip;
  updateEnoFields?: Tip[];
  updateEnoValues?: Formula[];
  updateEnoBranchSrc?: Tip;
  updateEnoBranchDest?: Tip;
  updateEnoLang?: string;
  updateEnoSecurity?: string;
}
const processNodeUpdateEnoScheme = cloneDeep(processNodeScheme);
processNodeUpdateEnoScheme['processnode/data/update-eno/object'] = { name: 'updateEnoObject', type: DataTypes.string };
processNodeUpdateEnoScheme['processnode/data/update-eno/fields'] = { name: 'updateEnoFields', type: DataTypes.stringArray };
processNodeUpdateEnoScheme['processnode/data/update-eno/values'] = { name: 'updateEnoValues', type: DataTypes.formulaArray };
processNodeUpdateEnoScheme['processnode/data/update-eno/branch-src'] = { name: 'updateEnoBranchSrc', type: DataTypes.string };
processNodeUpdateEnoScheme['processnode/data/update-eno/branch-dest'] = { name: 'updateEnoBranchDest', type: DataTypes.string };
processNodeUpdateEnoScheme['processnode/data/update-eno/lang'] = { name: 'updateEnoLang', type: DataTypes.string };
processNodeUpdateEnoScheme['processnode/data/update-eno/security'] = { name: 'updateEnoSecurity', type: DataTypes.string };

// Delete node
interface IProcessNodeDelete extends IProcessNode {
  deleteTip: Formula[];
  deleteSrcBranch?: Tip;
  deleteDestBranch?: Tip;
  deleteSecurity: Tip;
}
const processNodeDeleteScheme = cloneDeep(processNodeScheme);
processNodeDeleteScheme['processnode/data/delete/tip'] = { name: 'deleteTip', type: DataTypes.stringArray };
processNodeDeleteScheme['processnode/data/delete/srcbranch'] = { name: 'deleteSrcBranch', type: DataTypes.string };
processNodeDeleteScheme['processnode/data/delete/destbranch'] = { name: 'deleteDestBranch', type: DataTypes.string };
processNodeDeleteScheme['processnode/data/delete/security'] = { name: 'deleteSecurity', type: DataTypes.string };

// Create object node
interface IProcessNodeCreate extends IProcessNode {
  createType: Tip;
  createSecurity?: string;
  createBranch?: Tip;
  createFields?: string[];
  createData: string[];
  createDataCount: string[];
  createLang: string;
  createTipVarKey: string;
  createTipVarScope?: VAR_SCOPE;
}
const processNodeCreateScheme = cloneDeep(processNodeScheme);
processNodeCreateScheme['processnode/data/create/type'] = { name: 'createType', type: DataTypes.string };
processNodeCreateScheme['processnode/data/create/security'] = { name: 'createSecurity', type: DataTypes.string };
processNodeCreateScheme['processnode/data/create/branch'] = { name: 'createBranch', type: DataTypes.string };
processNodeCreateScheme['processnode/data/create/fields'] = { name: 'createFields', type: DataTypes.stringArray };
processNodeCreateScheme['processnode/data/create/data'] = { name: 'createData', type: DataTypes.stringArray };
processNodeCreateScheme['processnode/data/create/datacount'] = { name: 'createDataCount', type: DataTypes.stringArray };
processNodeCreateScheme['processnode/data/create/lang'] = { name: 'createLang', type: DataTypes.string };
processNodeCreateScheme['processnode/data/create/tip-var-key'] = { name: 'createTipVarKey', type: DataTypes.string };
processNodeCreateScheme['processnode/data/create/tip-var-scope'] = { name: 'createTipVarScope', type: DataTypes.string };


// Duplicate object node
interface IProcessNodeDuplicate extends IProcessNode {
  duplicateTip: Formula[];
  duplicateFields?: string[];
  duplicateSourceBranch?: Tip;
  duplicateDestBranch?: Tip;
  duplicateTipVar?: string;
  duplicateTipVarScope?: VAR_SCOPE;
  duplicateBackwardRefs: boolean;
}
const processNodeDuplicateScheme = cloneDeep(processNodeScheme);
processNodeDuplicateScheme['processnode/data/duplicate/tip'] = { name: 'duplicateTip', type: DataTypes.stringArray };
processNodeDuplicateScheme['processnode/data/duplicate/fields'] = { name: 'duplicateFields', type: DataTypes.stringArray };
processNodeDuplicateScheme['processnode/data/duplicate/source-branch'] = { name: 'duplicateSourceBranch', type: DataTypes.string };
processNodeDuplicateScheme['processnode/data/duplicate/dest-branch'] = { name: 'duplicateDestBranch', type: DataTypes.string };
processNodeDuplicateScheme['processnode/data/duplicate/tip-var'] = { name: 'duplicateTipVar', type: DataTypes.string };
processNodeDuplicateScheme['processnode/data/duplicate/tip-var-scope'] = { name: 'duplicateTipVarScope', type: DataTypes.string };
processNodeDuplicateScheme['processnode/data/duplicate/duplicate-backward-refs'] = {
  name: 'duplicateBackwardRefs',
  type: DataTypes.boolean
};



// Set Variable node
interface IProcessNodeSetVariable extends IProcessNode {
  setVarScope: VAR_SCOPE;
  setVarKey: string[];
  setVarValue: Formula[];
}
const processNodeSetVariableScheme = cloneDeep(processNodeScheme);
processNodeSetVariableScheme['processnode/reference/setvar/scope'] = { name: 'setVarScope', type: DataTypes.string };
processNodeSetVariableScheme['processnode/reference/setvar/key'] = { name: 'setVarKey', type: DataTypes.stringArray };
processNodeSetVariableScheme['processnode/reference/setvar/value'] = { name: 'setVarValue', type: DataTypes.formulaArray };


// Switch node
interface IProcessNodeSwitch extends IProcessNode {
  switchExpr: Formula;
  switchDefault: string;
  switchCases: string[];
}
const processNodeSwitchScheme = cloneDeep(processNodeScheme);
processNodeSetVariableScheme['processnode/logic/switch/expr'] = { name: 'switchExpr', type: DataTypes.string };
processNodeSetVariableScheme['processnode/logic/switch/default'] = { name: 'switchDefault', type: DataTypes.string };
processNodeSetVariableScheme['processnode/logic/switch/cases'] = { name: 'switchCases', type: DataTypes.stringArray };

// Foreach node
interface IProcessNodeForeach extends IProcessNode {
  source: Formula;
  currentElement: string;
  currentIndex?: string;
  stack?: string;
}
const processNodeForeachScheme = cloneDeep(processNodeScheme);
processNodeForeachScheme['processnode/logic/foreach:source'] = { name: 'source', type: DataTypes.formula };
processNodeForeachScheme['processnode/logic/foreach:current'] = { name: 'currentElement', type: DataTypes.string };
processNodeForeachScheme['processnode/logic/foreach:index'] = { name: 'currentIndex', type: DataTypes.string };
processNodeForeachScheme['processnode/logic/foreach:stack'] = { name: 'stack', type: DataTypes.string };

/////////////////////// DO NOT MOVE ITEMS BELLOW TO ABOVE OR CHANGE DECLARATION ORDER /////////////////////

// YOU MUST ADD NEW NODE SCHEME HERE WHENEVER A NEW NODE SCHEME IS ADDED
// Be careful not to define same property name on scheme
const processNodeSchemeUnion = {
  'processnode/connection': {
    name: 'connections',
    type: DataTypes.objectArray,
    mutable: true,
    scheme: processConnectionScheme
  },
  ...processNodeScheme,
  ...processNodeIfScheme,
  ...processNodeNoOpScheme,
  ...processNodeSwitchScheme,
  ...processNodeSubprocessScheme,
  ...processNodeUpdateEnoScheme,
  ...processNodeCreateScheme,
  ...processNodeDeleteScheme,
  ...processNodeDuplicateScheme,
  ...processNodeSetVariableScheme,
  ...processNodeForeachScheme
};

// must come after 'processNodeScheme' to avoid circular reference issue
processConnectionScheme['processconnection/tonode'] = {
  name: 'toNodes',
  type: DataTypes.objectArray,
  mutable: true,
  scheme: processNodeSchemeUnion,
  circularPlaceholder: { $type: 'processnode/no-op' }
};

processNodeScheme['processnode/connection'] = {
  name: 'connections',
  type: DataTypes.objectArray,
  mutable: true,
  scheme: processConnectionScheme
};

const processScheme: IObjectScheme = {
  'process/title': { type: DataTypes.i18n },
  'process/description': { type: DataTypes.i18n },
  'process/node': {
    name: 'nodes',
    type: DataTypes.objectArray,
    mutable: true,
    scheme: processNodeSchemeUnion
  },
  'process/grants': { type: DataTypes.string },
  'process/env': { type: DataTypes.string },
  'process/required-vars': { type: DataTypes.stringArray },
  'process/required-description': { name: 'requiredVarsDescription', type: DataTypes.stringArray },
  'process/optional-vars': { type: DataTypes.stringArray },
  'process/optional-description': { name: 'optionalVarsDescription', type: DataTypes.stringArray }
};
