import { Component, HostBinding } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-multi-line-text',
  template: `
      <app-multi-text-field
              [formControl]="formControl"
              [label]="to.label"
              [placeholder]="to.placeholder"
              [required]="to.required"
              [readonly]="to.readonly"
              [rows]="to.rows"
      ></app-multi-text-field>
  `
})
export class FormlyFieldMultiLineTextComponent extends FieldType {
  @HostBinding('class') classes = 'flex-fill';
}
