import * as moment from 'moment';
import * as i0 from "@angular/core";
export var NOW_VAR_NAME = '---NOW---';
var CurrentDatetimeService = /** @class */ (function () {
    function CurrentDatetimeService() {
    }
    CurrentDatetimeService.prototype.getCurrentDatetime = function () {
        return moment().format('YYYY-MM-DDTHH:mm:ssZZ');
    };
    CurrentDatetimeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CurrentDatetimeService_Factory() { return new CurrentDatetimeService(); }, token: CurrentDatetimeService, providedIn: "root" });
    return CurrentDatetimeService;
}());
export { CurrentDatetimeService };
