import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { getModuleTitleFormula } from '../../settings/modules/module.constant';
import { map, switchMap } from 'rxjs/operators';
import { get, isEmpty } from 'lodash';
import { Subject } from 'rxjs';
import { getSearchInTypeQueryOptions } from '../../data/query-options';
export var DEFAULT_OBJECT_CHOOSER_QUERY_TIP = 'eim/query/get-object-titles';
var SideSheetObjectChooserService = /** @class */ (function () {
    function SideSheetObjectChooserService(filterInputService, queryService) {
        this.filterInputService = filterInputService;
        this.queryService = queryService;
        this.unsubscribe$ = new Subject();
    }
    SideSheetObjectChooserService.prototype.loadObjects = function (_a, searchValue) {
        var _this = this;
        var queryAndFilter = _a.queryAndFilter, showModuleInfo = _a.showModuleInfo, typeTips = _a.typeTips, extraFilters = _a.extraFilters, limit = _a.limit, offset = _a.offset;
        var query = get(queryAndFilter, 'queryTip', null) || DEFAULT_OBJECT_CHOOSER_QUERY_TIP;
        var filterInputs = get(queryAndFilter, 'filterInputs', null);
        var sortingOptions = get(queryAndFilter, 'sortingOptions', null);
        var dimensionOption = getDimensionOption(sortingOptions, limit, offset);
        var extraAttributes = [
            { label: 'Title', formula: 'TITLE()' },
            { label: 'Summary', formula: 'SUMMARY()' }
        ];
        extraFilters = extraFilters || [];
        if (showModuleInfo) {
            extraAttributes.push({ label: 'Module Name', formula: getModuleTitleFormula(typeTips[0]) });
        }
        if (typeTips.includes('app/object-type')) {
            extraFilters.push({
                label: 'Is not module options object-type',
                formula: 'EQUALS(REFERENCES("app/module:options-object-type", TIP()), ARRAY())'
            });
        }
        if (typeTips.includes('app/user')) {
            extraFilters.push({
                label: 'Is not user status deprovisioned',
                formula: 'NOT(EQUALS(FIELD("app/user:status"), "deprovisioned"))'
            });
        }
        if (isEmpty(filterInputs)) {
            var vars = { typeTips: typeTips };
            var options = this.setOptions(vars, extraAttributes, extraFilters, dimensionOption);
            return this.getData(options, query, typeTips, searchValue);
        }
        return this.filterInputService
            .convertFilterInputsToVars(filterInputs)
            .pipe(map(function (vars) { return _this.setOptions(vars, extraAttributes, extraFilters, dimensionOption); }), switchMap(function (options) { return _this.getData(options, query, typeTips, searchValue); }));
    };
    SideSheetObjectChooserService.prototype.setOptions = function (vars, extraAttributes, extraFilters, dimensionOption) {
        var options = {
            watch: true,
            vars: vars,
            extraAttributes: extraAttributes,
            extraFilters: extraFilters || [],
            dimensionOptions: [dimensionOption]
        };
        return options;
    };
    SideSheetObjectChooserService.prototype.getData = function (options, query, typeTips, searchValue) {
        var searchInOptions = searchValue ? getSearchInTypeQueryOptions(options, typeTips, searchValue) : options;
        return this.queryService
            .execute1dFirst(query, searchInOptions);
    };
    SideSheetObjectChooserService.prototype.ngOnDestroy = function () {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    };
    return SideSheetObjectChooserService;
}());
export { SideSheetObjectChooserService };
export function getDimensionOption(sortingOptions, limit, offset) {
    var e_1, _a;
    if (isEmpty(sortingOptions)) {
        return { label: 'Tip dimension', formula: 'TIP()', sortby: ['TITLE()'], limit: limit, offset: offset };
    }
    var dimensionOption = {
        label: 'Tip dimension',
        formula: 'TIP()',
        sortby: [],
        sortdir: [],
        limit: limit,
        offset: offset
    };
    try {
        for (var sortingOptions_1 = tslib_1.__values(sortingOptions), sortingOptions_1_1 = sortingOptions_1.next(); !sortingOptions_1_1.done; sortingOptions_1_1 = sortingOptions_1.next()) {
            var sortingOption = sortingOptions_1_1.value;
            dimensionOption.sortby.push(sortingOption.formula);
            dimensionOption.sortdir.push(sortingOption.sortDirection);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (sortingOptions_1_1 && !sortingOptions_1_1.done && (_a = sortingOptions_1.return)) _a.call(sortingOptions_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return dimensionOption;
}
