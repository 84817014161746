import { FieldType } from '@ngx-formly/core';
import { Component } from '@angular/core';

@Component({
  selector: 'app-recipient-chooser',
  template: `
    <app-voice-message-recipient-chooser-field
        [formControl]="formControl"
        [label]="to.label"
        [inline]="to.inline"
        [placeholder]="to.placeholder"
        [required]="to.required"
        [disabled]="to.disabled"
        [readonly]="to.readonly"
        [outboundSource]="to.outboundSource"
        [workflowVariables] = "to.workflowVariables"
    ></app-voice-message-recipient-chooser-field>
  `
})
export class FormlyFieldRecipientChooserComponent extends FieldType {
}