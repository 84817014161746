import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { cloneDeep, get } from 'lodash';
import { TableComponent } from '../../../shared/table/table.component';
var FormlyFieldTableContentComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FormlyFieldTableContentComponent, _super);
    function FormlyFieldTableContentComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FormlyFieldTableContentComponent.prototype.ngOnInit = function () {
        this.tableParams = cloneDeep(this.to.tableConfig);
        if (this.tableParams.limit) {
            this.tableParams.limit = +this.tableParams.limit;
        }
        this.tableParams.contextTip = get(this.options, 'formState.objectAndType.objectData.$tip', null);
        this.tableParams.sortSearchableFieldsOnly = true;
    };
    return FormlyFieldTableContentComponent;
}(FieldType));
export { FormlyFieldTableContentComponent };
