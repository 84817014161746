import { IForm } from '../../settings/form-designer/models/form';
import { IType } from '../../models/type';
import { IObjectType } from '../../models/object-type';
export const numberFormatForm: IForm  = {
  name: 'Number format form',
  description: '',
  size: 'regular',
  version: 1,
  titleOverride: 'Define number format',
  contextType: { type: { $tip: 'number-format' } as IType } as IObjectType,
  tabs: [
    {
      tabName: 'Tab 1',
      rows: [
        {
          fields: [
            {
              type: 'input',
              templateOptions: {
                type: 'text',
                label: 'Name',
                placeholder: 'My custom format',
                contextTypeTip: 'number-format:name',
                required: true
              },
            }
          ]
        },
        {
          fields: [
            {
              type: 'number',
              templateOptions: {
                label: 'Preview value',
                contextTypeTip: 'number-format:preview-value',
                required: true
              },
            }
          ]
        },
        {
          fields: [
            {
              type: 'number-format-preview',
              templateOptions: {},
            }
          ]
        },
        {
          fields: [
            {
              type: 'option-list',
              templateOptions: {
                label: 'Calculations',
                placeholder: 'Number',
                contextTypeTip: 'number-format:calculations',
                options: [
                  {label: 'Number', value: undefined},
                  {label: 'Percent', value: 'percentage'}
                ]
              },
            }
          ]
        },
        {
          fields: [
            {
              type: 'input',
              templateOptions: {
                type: 'text',
                label: 'Prefix',
                placeholder: '',
                contextTypeTip: 'number-format:prefix'
              },
            }
          ]
        },
        {
          fields: [
            {
              type: 'input',
              templateOptions: {
                type: 'text',
                label: 'Number suffix',
                placeholder: '',
                contextTypeTip: 'number-format:suffix'
              },
            }
          ]
        },
        {
          fields: [
            {
              type: 'number',
              templateOptions: {
                label: 'Decimal places',
                contextTypeTip: 'number-format:decimal-places',
                numberFormat: 'normal',
              },
            }
          ]
        },
        {
          fields: [
            {
              type: 'input',
              templateOptions: {
                type: 'text',
                label: 'Thousands separator',
                contextTypeTip: 'number-format:thousands-separator'
              }
            }
          ]
        },
        {
          fields: [
            {
              type: 'input',
              templateOptions: {
                type: 'text',
                label: 'Decimal separator',
                placeholder: '.',
                contextTypeTip: 'number-format:decimal-separator'
              }
            }
          ]
        },
      ]
    }
  ]
};
