import * as tslib_1 from "tslib";
import { CompositeNodeConverter } from './CompositeNodeConverter';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import { Parser, Stringify } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { isEmpty } from 'lodash';
import { createConnection, createFormulaFromStringInterpolation, createSetVarNode, createSubProcessNode, createSwitchNode } from './utils/create-node-utils';
import * as i0 from "@angular/core";
var NS = 'GeneratePdfConverterService';
var GeneratePdfConverterService = /** @class */ (function (_super) {
    tslib_1.__extends(GeneratePdfConverterService, _super);
    function GeneratePdfConverterService() {
        var _this = _super.call(this) || this;
        _this.type = 'processnode/logic/subprocess';
        _this.processNodeType = 'processnodetype/logic/subprocess';
        return _this;
    }
    GeneratePdfConverterService.prototype.convert = function (nodeData) {
        if (!this.isValid(nodeData)) {
            throw new InvalidWorkflowNodeDataError("Please correct invalid data on generate-pdf node [" + nodeData.fields.title + "]", nodeData);
        }
        var _a = nodeData.fields, formula = _a.formula, variableToSetKey = _a.variableToSetKey, filename = _a.filename, securityPolicy = _a.securityPolicy, form = _a.form;
        var filenameFormula;
        if (isEmpty(filename) || isEmpty(filename.html)) {
            filenameFormula = Stringify({
                name: 'CONCAT',
                args: [
                    { name: 'TITLE', args: [Parser(formula)] },
                    '-UTC',
                    { name: 'FORMAT_DATETIME', args: [{ name: 'NOW', args: [] }, 'Ymd-His'] },
                    '.pdf'
                ]
            });
        }
        else {
            filenameFormula = Stringify({
                name: 'CONCAT',
                args: [Parser(createFormulaFromStringInterpolation(filename)), '.pdf']
            });
        }
        var vars = {
            'Object tip': formula,
            'Var key': variableToSetKey,
            Filename: filenameFormula,
            'Security policy': securityPolicy || 'app/security-policy/system-all-users-read-write-delete',
            'Form tip': form || 'ARRAY()'
        };
        var setVarStatusNode = createSetVarNode(vars, nodeData.tip);
        var subprocessNode = createSubProcessNode('eim/process/workflow/generate-pdf', vars);
        var doneConnectionForSetVarNode = createConnection('done', subprocessNode);
        setVarStatusNode.connections.push(doneConnectionForSetVarNode);
        var switchStatusNode = createSwitchNode({ name: 'VAR', args: ['Status'] });
        var doneConnectionForSubprocessNode = createConnection('done', switchStatusNode);
        subprocessNode.connections.push(doneConnectionForSubprocessNode);
        var nodesAndLink = {
            nodes: [
                setVarStatusNode,
                subprocessNode,
                switchStatusNode
            ],
            links: [
                doneConnectionForSetVarNode,
                doneConnectionForSubprocessNode
            ]
        };
        this.populateOutgoingLinks(nodeData).forEach(function (link) {
            switchStatusNode.connections.push(link);
            nodesAndLink.links.push(link);
        });
        return nodesAndLink;
    };
    GeneratePdfConverterService.prototype.isValid = function (nodeData) {
        return !isEmpty(nodeData.fields.title)
            && !isEmpty(nodeData.fields.formula)
            && !isEmpty(nodeData.fields.variableToSetKey);
    };
    GeneratePdfConverterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GeneratePdfConverterService_Factory() { return new GeneratePdfConverterService(); }, token: GeneratePdfConverterService, providedIn: "root" });
    return GeneratePdfConverterService;
}(CompositeNodeConverter));
export { GeneratePdfConverterService };
