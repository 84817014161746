/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alert.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./alert.component";
var styles_AlertComponent = [i0.styles];
var RenderType_AlertComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertComponent, data: {} });
export { RenderType_AlertComponent as RenderType_AlertComponent };
export function View_AlertComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "alert"], ["role", "alert"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "mdi"]], [[2, "mdi-alert-circle", null], [2, "mdi-check", null], [2, "mdi-info-outline", null], [2, "mdi-alert-triangle", null]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "message"]], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "alert"; var currVal_1 = ("alert-" + _co.type); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.type === "danger"); var currVal_3 = (_co.type === "success"); var currVal_4 = (_co.type === "info"); var currVal_5 = (_co.type === "warning"); _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4, currVal_5); }); }
export function View_AlertComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alert", [], null, null, null, View_AlertComponent_0, RenderType_AlertComponent)), i1.ɵdid(1, 114688, null, 0, i3.AlertComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertComponentNgFactory = i1.ɵccf("app-alert", i3.AlertComponent, View_AlertComponent_Host_0, { type: "type" }, {}, ["*"]);
export { AlertComponentNgFactory as AlertComponentNgFactory };
