import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideSheetListItemComponent } from './side-sheet-list-item.component';
import { MatIconModule } from '@angular/material';
import { DragulaModule } from 'ng2-dragula';

@NgModule({
  declarations: [
    SideSheetListItemComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    DragulaModule
  ],
  exports: [
    SideSheetListItemComponent
  ],
  entryComponents: [
    SideSheetListItemComponent
  ]
})
export class SideSheetListItemModule { }

