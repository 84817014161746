import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNumber, isString, uniq } from 'lodash';
import { FIELD_DATA_TYPE, TYPE_GEO_RESTRICT, EXTENDED_FIELD_DATA_TYPE, ALL_FIELD_DATA_TYPE } from '../../models/field';
import { IList, IListItem, listScheme } from '../../models/list';
import { Tip } from '../../data/models/types';
import { ObjectService } from '../../data/object-service/object.service';
import { FormulaLike, FormulaSpec, Parser } from '../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { getRelativeDateFormula } from '../../side-sheet/generic-side-sheets/relative-dates-side-sheet/relative-dates-side-sheet.component';
import { NOW_VAR_NAME } from '../../util/current-datetime.service';
import { MetaDataKey } from '../../object/field-formula-side-sheet/meta-data-formulas';
import { IOperators } from './operators/operators';
import { booleanOperators } from './operators/boolean-operators';
import { objectOperators } from './operators/object-operators';
import { stringOperators } from './operators/string-operators';
import { numberOperators } from './operators/number-operators';
import { dateOperators } from './operators/date-operators';
import { datetimeOperators } from './operators/datetime-operators';
import { isFormulaSpec } from '../../util/is-formula-spec';
import { geographyOperators } from './operators/geography-operators';
import { assigneeOperators } from './operators/assignee-operators';
import { personnelOperators } from './operators/personnel-operators';
import { hierarchicalOperators } from './operators/hierarchical-operators';
import { IFilterMeta } from '../../models/query';
import { isCurrentUserFormula } from '../../util/current-user-formula';
import { listOperators, SPECIAL_VAR_NAME } from './operators/list-operators';
import { get } from 'lodash';
import { getFieldTipsFromFormulaString } from '../../util/get-field-tips-from-formula-string';

export type RightArgBy = 'Entering' | 'Selecting' | 'Variable' | 'Relative';

export const intersectsTypeGeoRestrictMap: { [key in TYPE_GEO_RESTRICT]: TYPE_GEO_RESTRICT[] } = {
  [TYPE_GEO_RESTRICT.POINT]: [TYPE_GEO_RESTRICT.LINE, TYPE_GEO_RESTRICT.POLYGON],
  [TYPE_GEO_RESTRICT.LINE]: [TYPE_GEO_RESTRICT.POINT, TYPE_GEO_RESTRICT.LINE, TYPE_GEO_RESTRICT.POLYGON],
  [TYPE_GEO_RESTRICT.POLYGON]: [TYPE_GEO_RESTRICT.POINT, TYPE_GEO_RESTRICT.LINE, TYPE_GEO_RESTRICT.POLYGON]
};

@Injectable({
  providedIn: 'root'
})
export class QueryFilterEditorService {
  static DATATYPE_OPERATOR: {
    [key in MetaDataKey | ALL_FIELD_DATA_TYPE]: IOperators;
  } = {
    [MetaDataKey.thisObject]: objectOperators,
    [MetaDataKey.title]: stringOperators,
    [MetaDataKey.createdDate]: datetimeOperators,
    [MetaDataKey.lastModifiedDate]: datetimeOperators,
    [MetaDataKey.objectStage]: stringOperators,
    [MetaDataKey.personnel]: personnelOperators,
    [MetaDataKey.urlLink]: stringOperators,
    [MetaDataKey.type]: stringOperators,
    [MetaDataKey.createdBy]: objectOperators,
    [MetaDataKey.lastModifiedBy]: objectOperators,
    [FIELD_DATA_TYPE.string]: stringOperators,
    [FIELD_DATA_TYPE.string_i18n]: stringOperators,
    [FIELD_DATA_TYPE.blob]: stringOperators,
    [FIELD_DATA_TYPE.number]: numberOperators,
    [FIELD_DATA_TYPE.decimal]: numberOperators,
    [FIELD_DATA_TYPE.bool]: booleanOperators,
    [FIELD_DATA_TYPE.date]: dateOperators,
    [FIELD_DATA_TYPE.datetime]: datetimeOperators,
    [FIELD_DATA_TYPE.object]: objectOperators,
    [FIELD_DATA_TYPE.email]: stringOperators,
    [FIELD_DATA_TYPE.phone]: stringOperators,
    [FIELD_DATA_TYPE.uri]: stringOperators,
    [FIELD_DATA_TYPE.geography]: geographyOperators,
    [FIELD_DATA_TYPE.formula]: stringOperators,
    [FIELD_DATA_TYPE.string_formula]: stringOperators,
    [FIELD_DATA_TYPE.string_i18n_formula]: stringOperators,
    [FIELD_DATA_TYPE.json]: stringOperators,
    [FIELD_DATA_TYPE.html]: stringOperators,
    [EXTENDED_FIELD_DATA_TYPE.sub_object]: objectOperators,
    [EXTENDED_FIELD_DATA_TYPE.file]: objectOperators,
    [EXTENDED_FIELD_DATA_TYPE.list]: listOperators,
    [EXTENDED_FIELD_DATA_TYPE.address]: objectOperators,
    [EXTENDED_FIELD_DATA_TYPE.point]: geographyOperators,
    [EXTENDED_FIELD_DATA_TYPE.line]: geographyOperators,
    [EXTENDED_FIELD_DATA_TYPE.polygon]: geographyOperators,
    [EXTENDED_FIELD_DATA_TYPE.assignable]: assigneeOperators,
    [EXTENDED_FIELD_DATA_TYPE.symbology]: objectOperators,
    [EXTENDED_FIELD_DATA_TYPE.hierarchical]: hierarchicalOperators
  };

  constructor(
    private objectService: ObjectService
  ) {
  }

  static isLineFormula(formula: any): boolean {
    return isFormulaSpec(formula) && !QueryFilterEditorService.isGroupFormula(formula);
  }

  static isGroupFormula(formula: any): boolean {
    return isFormulaSpec(formula) && (formula.name === 'AND' || formula.name === 'OR');
  }

  static toFormula(
    dataTypeOrMetaDataKey: ALL_FIELD_DATA_TYPE | MetaDataKey,
    op: string,
    leftArg: FormulaLike,
    rightArg?: FormulaLike,
    filterMeta?: IFilterMeta
  ): FormulaSpec {
    return QueryFilterEditorService.DATATYPE_OPERATOR[dataTypeOrMetaDataKey].operators[op].toFormula(leftArg, rightArg, filterMeta);
  }

  static getRightArgBy(formulaString: string): RightArgBy {
    if (!formulaString) {
      return null;
    }

    const formula = Parser(formulaString);
    const argTips: Array<string> = getFieldTipsFromFormulaString(formulaString);

    if (isString(formula) || isNumber(formula)) {
      return 'Entering';
    }

    if (!isFormulaSpec(formula)) {
      // TODO: replace below to loggerService
      // console.warn(`[QueryFilterEditorService] unsupported value: ${formula}.`);

      return null;
    }

    if (isCurrentUserFormula(formulaString) || get(argTips, '0', '').includes('app/user-session:user')) {
      return 'Relative';
    }

    if (formula.name === 'TIP' && formula.args.length === 0) {
      return 'Selecting';
    }

    if (formula.name === 'CONTEXT' || (formula.name === 'FIELD' && formula.args.length === 1 && isString(formula.args[0]))) {
      return 'Selecting';
    }

    if (formula.name === 'VAR' && formula.args.length === 1 && isString(formula.args[0])) {
      return 'Variable';
    }

    if (getRelativeDateFormula(formulaString)) {
      return 'Relative';
    }

    // TODO: replace below to loggerService
    // console.warn(`[QueryFilterEditorService] unsupported custom formula: ${formula}.`);

    return null;
  }

  static getVarNames(formula: FormulaLike, varNames: string[] = []): string[] {
    if (isFormulaSpec(formula)) {
      if (formula.name === 'VAR' && formula.args.length === 1 && isString(formula.args[0]) &&
        formula.args[0] !== NOW_VAR_NAME && formula.args[0] !== SPECIAL_VAR_NAME) {
        varNames.push(formula.args[0] as string);

        return uniq(varNames);
      }

      return formula.args.reduce((_varNames: string[], _formula: FormulaLike) => {
        return QueryFilterEditorService.getVarNames(_formula, _varNames);
      }, varNames);
    }

    return uniq(varNames);
  }

  getFieldItems(listTip: Tip): Observable<IListItem[]> {
    if (listTip) {
      return this.objectService.getObject<IList>(listTip, listScheme)
        .pipe(map((listObject) => listObject.items));
    }
    return of([]);
  }
}
