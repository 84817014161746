import { Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { isEqual } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "../data/process.service";
import * as i2 from "./auth.service";
var PROCESS_TIP = 'eim/process/auth/update-user-email-after-verification';
var AcceptVerificationResolverService = /** @class */ (function () {
    function AcceptVerificationResolverService(processService, authService) {
        this.processService = processService;
        this.authService = authService;
    }
    AcceptVerificationResolverService.prototype.resolve = function (route) {
        var oldEmail = route.queryParams.oldEmail;
        var newEmail = route.queryParams.email;
        var userTip = route.queryParams.user;
        var token = route.queryParams.token;
        return this.changeCredentialThenUserRecord(oldEmail, newEmail, token, userTip);
    };
    AcceptVerificationResolverService.prototype.changeCredentialThenUserRecord = function (oldEmail, newEmail, token, userTip) {
        var _this = this;
        return this.authService.activateNewEmail(oldEmail, newEmail, token).pipe(switchMap(function (changeCredentialSuccess) { return changeCredentialSuccess ? _this.changeUserRecord(newEmail, userTip) : of(false); }));
    };
    AcceptVerificationResolverService.prototype.changeUserRecord = function (newEmail, userTip) {
        return this.processService.start(PROCESS_TIP, {
            Email: [newEmail],
            User: [userTip]
        }).pipe(first(function (_a) {
            var finished = _a.finished;
            return finished;
        }), map(function (_a) {
            var vars = _a.vars;
            return isEqual(vars['Verification status'], ['Successful']);
        }));
    };
    AcceptVerificationResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AcceptVerificationResolverService_Factory() { return new AcceptVerificationResolverService(i0.ɵɵinject(i1.ProcessService), i0.ɵɵinject(i2.AuthService)); }, token: AcceptVerificationResolverService, providedIn: "root" });
    return AcceptVerificationResolverService;
}());
export { AcceptVerificationResolverService };
