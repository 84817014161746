import * as tslib_1 from "tslib";
import { FieldType } from '@ngx-formly/core';
var FormlyFieldChartChooserComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FormlyFieldChartChooserComponent, _super);
    function FormlyFieldChartChooserComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return FormlyFieldChartChooserComponent;
}(FieldType));
export { FormlyFieldChartChooserComponent };
