<app-side-sheet-header [disabled]="!selected || !selected.length || selected === myProfile.$tip"
                       >Switch profile
</app-side-sheet-header>
<app-alert *ngIf="loadingState === loadingStates.failed" type="danger">{{ errorMessage }}</app-alert>
<div [appSpinner]="loadingState === loadingStates.inProgress">
  <app-side-sheet-section [showBorder]="false">
    <app-filter-and-add [(filterText)]="filterText"></app-filter-and-add>
  </app-side-sheet-section>

  <app-side-sheet-section title="Profiles">
    <app-side-sheet-list-item
      toggleType="single-check"
      *ngFor="let profile of myProfiles | filter:filterText:['name']"
      (toggle)="onToggle(profile.$tip)"
      [value]="selected === profile.$tip">
      {{ profile.name }}
    </app-side-sheet-list-item>
  </app-side-sheet-section>
</div>
