import * as tslib_1 from "tslib";
import { NodeConverterBase } from './NodeConverterBase';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import dataConstants from '../../../data/constants';
import * as i0 from "@angular/core";
var DecisionNodeConverterService = /** @class */ (function (_super) {
    tslib_1.__extends(DecisionNodeConverterService, _super);
    function DecisionNodeConverterService() {
        var _this = _super.call(this) || this;
        _this.type = 'processnode/logic/if';
        _this.processNodeType = 'processnodetype/logic/if';
        return _this;
    }
    DecisionNodeConverterService.prototype.convert = function (nodeData) {
        if (!this.isValid(nodeData)) {
            throw new InvalidWorkflowNodeDataError("Please correct invalid data on decision node [" + nodeData.fields.title + "]", nodeData);
        }
        return {
            $type: this.type,
            $security: dataConstants.SECURITY.INSTANCE_USER_ADMIN,
            type: this.processNodeType,
            connections: [],
            point: [0, 0],
            key: nodeData.tip,
            expression: nodeData.fields.expression
        };
    };
    DecisionNodeConverterService.prototype.isValid = function (nodeData) {
        var expression = nodeData.fields.expression;
        return !(expression === '' || expression === null);
    };
    DecisionNodeConverterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DecisionNodeConverterService_Factory() { return new DecisionNodeConverterService(); }, token: DecisionNodeConverterService, providedIn: "root" });
    return DecisionNodeConverterService;
}(NodeConverterBase));
export { DecisionNodeConverterService };
