import { Injectable } from '@angular/core';
import { IFormObjectAndType } from './get-object-and-form.service';
import { EditFormSideSheetComponent } from './form-renderer/edit-form-side-sheet/edit-form-side-sheet.component';
import {
  createWellKnownObjectMapping,
  IObjectLaunchOverrideProps,
  OBJECT_LAUNCH_OPERATION,
} from './object-launch';
import { get } from 'lodash';
import { ObjectOpenSideSheetComponent } from '../object/object-open-side-sheet/object-open-side-sheet.component';
import { IObjectAndType } from '../object/object.service';
import { Tip } from '../data/models/types';

export interface IWellKnownReturnValue {
  component: any;
  formObjectAndType: IFormObjectAndType;
  objectLaunchOverrides: IObjectLaunchOverrideProps;
}

/**
 * returns side sheet based on below conditions :
 *
 * Well known type -
 *          Create sideSheet based on WELL_KNOWN_OBJECT_MAPPING - when object data is not available
 *          Edit sideSheet based on WELL_KNOWN_OBJECT_MAPPING - when object data is available and operation is not provided.
 *                                                              or object data is available and operation is edit.
 *          View sideSheet based on WELL_KNOWN_OBJECT_MAPPING - when object data is available and operation is view
 *          ObjectOpenSideSheetComponent -  when object data is available and WELL_KNOWN_OBJECT_MAPPING is not available
 *
 * Other type -
 *          ObjectOpenSideSheetComponent - when operation is view
 *          EditFormSideSheetComponent - when operation is not view or not provided.
 *
 */

@Injectable({
  providedIn: 'root'
})
export class WellKnownTypesService {

  constructor() { }

  loadEditCreateConfig(formObjectAndType: IFormObjectAndType, formTip?: Tip): IWellKnownReturnValue {
    const { objectAndType } = formObjectAndType;

    // if we have a form tip
    // use that instead of the well know type
    if (formTip) {
      return { component: EditFormSideSheetComponent, formObjectAndType, objectLaunchOverrides: {}};
    }

    const objectType = get(objectAndType, 'objectType.$tip', null);
    const wellKnownConfig = createWellKnownObjectMapping({objectType, formObjectAndType});

    if (wellKnownConfig && get(objectAndType, 'objectData.$tip', null)) {
      return wellKnownConfig[OBJECT_LAUNCH_OPERATION.EDIT];
    }
    if (wellKnownConfig) {
      return wellKnownConfig[OBJECT_LAUNCH_OPERATION.CREATE];
    }

    return { component: EditFormSideSheetComponent, formObjectAndType, objectLaunchOverrides: {}};
  }

  loadViewConfig(object: IObjectAndType) {
    const objectType = get(object, 'objectType.$tip', null);
    const wellKnownConfig = createWellKnownObjectMapping({objectType});

    if (get(wellKnownConfig, [OBJECT_LAUNCH_OPERATION.VIEW])) {
      return wellKnownConfig[OBJECT_LAUNCH_OPERATION.VIEW].component;
    }

    return ObjectOpenSideSheetComponent;
  }

}
