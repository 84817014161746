import { IUserSummary } from '../settings/users/user.service';
import { IObjectScheme, DataTypes } from '../data/models/scheme';
import { IObject } from '../data/models/object';
import { Tip } from '../data/models/types';
import { ITeamSummary } from '../team/team.service';
import { userSummaryScheme } from './user';
import { teamRoleScheme, ITeamRole, teamSummaryScheme } from './team';

export { ICheckListItem, checkListItemScheme, ICheckListItemSummary, checkListItemUpdateScheme, ICheckListItemUpdate };

interface ICheckListItem extends IObject {
  text: string;
  assignee?: IUserSummary | ITeamSummary | ITeamRole;
  status?: CheckListItemStatus;
  scrollTo?: boolean;
  plan?: Tip;   // checklist belonging to which plan reference
}

interface ICheckListItemUpdate extends IObject {
  text: string;
  assignee: Tip;
  status: CheckListItemStatus;
}

interface ICheckListItemSummary extends ICheckListItem {
  checklistName?: string;
  checklistTip?: string;
  checklistContext?: Tip;
  checklistContextTypeTitle?: string;
  checklistContextTitle?: string;
  planName?: string;
  planContext?: Tip;
  planContextTypeTitle?: string;
  planContextTitle?: string;
  modifiedDate?: number;
}

const checkListItemScheme: IObjectScheme = {
  'app/checklistitem:text': { type: DataTypes.i18n },
  'app/checklistitem:assignee': {
    type: DataTypes.object,
    scheme: { ...userSummaryScheme, ...teamSummaryScheme, ...teamRoleScheme }
  },
  'app/checklistitem:status': { type: DataTypes.string }
};

const checkListItemUpdateScheme: IObjectScheme = {
  'app/checklistitem:text': { type: DataTypes.i18n },
  'app/checklistitem:assignee': { type: DataTypes.string },
  'app/checklistitem:status': { type: DataTypes.string }
};

export enum CheckListItemStatus {
  IN_PROGRESS = 'in-progress',
  NOT_STARTED = 'not-started',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled'
}
