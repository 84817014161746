import * as tslib_1 from "tslib";
import { Observable } from 'rxjs';
import { take } from 'rxjs//operators';
import * as i0 from "@angular/core";
import * as i1 from "./eno.service";
import * as i2 from "./eno-cache.service";
var QueryResponseParserService = /** @class */ (function () {
    function QueryResponseParserService(_enoService, _enoCacheService) {
        this._enoService = _enoService;
        this._enoCacheService = _enoCacheService;
    }
    QueryResponseParserService.prototype.parse = function (responseEno) {
        var _this = this;
        return new Observable(function (observer) {
            var attrTips = responseEno.getFieldValues('response/query/attributes');
            var runtimeAttrs = responseEno.getFieldValues('response/query/runtime-attributes');
            var dimTips = responseEno.getFieldValues('response/query/dimensions');
            var runtimeDims = responseEno.getFieldValues('response/query/runtime-dimensions');
            var result = responseEno.getFieldValues('response/query/result');
            var structuredResult = {
                attributes: null,
                dimensions: null,
                results: null,
                execTime: responseEno.getFieldNumberValue('response/query/exec-time')
            };
            _this._enoService.readEnos(attrTips, { useCache: true }).pipe(take(1)).subscribe(function (attrEnos) {
                var dimEnos = [];
                dimTips.forEach(function (dimTip) {
                    dimEnos.push(_this._enoCacheService.getEno(dimTip));
                });
                structuredResult.attributes = attrEnos.map(function (attrEno) {
                    return { tip: attrEno.tip, label: attrEno.getFieldStringValue('query/attribute/label') };
                });
                runtimeAttrs.forEach(function (runtimeAttr) {
                    var decodedAttr = JSON.parse(runtimeAttr);
                    structuredResult.attributes.push({ label: decodedAttr.label });
                });
                structuredResult.dimensions = dimEnos.map(function (dimEno) {
                    return {
                        label: dimEno.getFieldStringValue('response/query/dimension/label'),
                        values: dimEno.getFieldValues('response/query/dimension/value')
                    };
                });
                runtimeDims.forEach(function (runtimeDim) {
                    var originalDim = JSON.parse(runtimeDim);
                    structuredResult.dimensions.push({ label: originalDim.label, values: originalDim.value });
                });
                structuredResult.results = _this._unpack(result, structuredResult.dimensions, structuredResult.attributes);
                observer.next(structuredResult);
                observer.complete();
            });
        });
    };
    QueryResponseParserService.prototype.parseTipOneDimensionQuery = function (queryResponse) {
        var result = [];
        queryResponse.results.forEach(function (dimObj) {
            var eachDimResult;
            for (var tip in dimObj) {
                if (!dimObj[tip]) {
                    continue;
                }
                eachDimResult = tslib_1.__assign({}, dimObj[tip]);
                eachDimResult.$tip = tip;
            }
            result.push(eachDimResult);
        });
        return result;
    };
    QueryResponseParserService.prototype._unpack = function (flatResult, dims, attrs, result, depth) {
        var _this = this;
        if (result === void 0) { result = []; }
        if (depth === void 0) { depth = 0; }
        if (depth === dims.length - 1) {
            var i_1 = 0;
            dims[dims.length - 1].values.forEach(function (lastDimValue) {
                var e_1, _a;
                var leafResult = {};
                leafResult[lastDimValue] = {};
                if (flatResult[i_1] === '{#}') {
                    i_1 += attrs.length;
                    return;
                }
                try {
                    for (var attrs_1 = tslib_1.__values(attrs), attrs_1_1 = attrs_1.next(); !attrs_1_1.done; attrs_1_1 = attrs_1.next()) {
                        var attr = attrs_1_1.value;
                        leafResult[lastDimValue][attr.label] = flatResult[i_1++];
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (attrs_1_1 && !attrs_1_1.done && (_a = attrs_1.return)) _a.call(attrs_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                result.push(leafResult);
            });
            return result;
        }
        // TODO this is ignoring errors. EIM-3721
        if (!dims[depth]) {
            return [];
        }
        dims[depth].values.forEach(function (dimValue, i) {
            var subResult = [];
            var subResultSize = flatResult.length / dims[depth].values.length;
            var subResultStart = i * subResultSize;
            var subResultEnd = subResultStart + subResultSize;
            result[i] = {};
            result[i][dimValue] = subResult;
            _this._unpack(flatResult.slice(subResultStart, subResultEnd), dims, attrs, subResult, depth + 1);
        });
        return result;
    };
    QueryResponseParserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function QueryResponseParserService_Factory() { return new QueryResponseParserService(i0.ɵɵinject(i1.EnoService), i0.ɵɵinject(i2.EnoCacheService)); }, token: QueryResponseParserService, providedIn: "root" });
    return QueryResponseParserService;
}());
export { QueryResponseParserService };
