import * as tslib_1 from "tslib";
import { SHA256 } from 'crypto-js';
import { cloneDeep, isEqual } from 'lodash';
import { Eno } from './models/Eno';
import dataConstants from './constants';
import { sidStringify } from './sidStringify';
var EMPTY_NONCE_TYPES = [
    'var',
    'query/dimension',
    'op/query',
    'op/query/dimension',
    'op/pull',
    'op/merge',
    'op/formula',
    'op/session',
    'op/watch/register',
    'op/watch/unregister',
    'op/auth/gen-token',
    'op/auth/reset-token',
    'op/auth/register',
    'app/address'
];
var EnoFactory = /** @class */ (function () {
    function EnoFactory(typeOrEnoProto, security) {
        if (typeOrEnoProto === void 0) { typeOrEnoProto = null; }
        if (security === void 0) { security = null; }
        this._proto = null;
        this._patchTargetTip = null;
        this._useEmptyNonce = false;
        this._useTipNonce = false;
        if (typeOrEnoProto && typeof typeOrEnoProto !== 'string') {
            this.setProto(typeOrEnoProto);
            return;
        }
        this.reset(typeOrEnoProto, security);
    }
    EnoFactory.prototype.makeEno = function () {
        var isValid = this._isSettingValid();
        if (!isValid) {
            throw new Error('Eno Factory setting has not enough information to make an eno.');
        }
        this._cleanField();
        this._proto.source.nonce = this._useEmptyNonce || this._emptyNonceRequired() ? '' :
            this._useTipNonce && this._patchTargetTip ? this._patchTargetTip : this._getRandomNonce();
        this._generateSid();
        this._cleanTransaction();
        this._proto.tip = this._patchTargetTip || this._proto.sid;
        return new Eno(this._proto);
    };
    EnoFactory.prototype._getRandomNonce = function () {
        return Math.random() + '';
    };
    EnoFactory.prototype._cleanTransaction = function () {
        if (this._proto.clientT === null) {
            this._resetClientT();
        }
        var clientT = this._proto.clientT;
        clientT.sequence = clientT.sequence || 1;
        clientT.createdDate = clientT.modifiedDate = (new Date()).valueOf();
        this._proto.serverT = null;
    };
    EnoFactory.prototype._emptyNonceRequired = function () {
        return EMPTY_NONCE_TYPES.indexOf(this._proto.source.type) > -1;
    };
    EnoFactory.prototype._generateSid = function () {
        this._proto.sid = SHA256(sidStringify(this._proto.source)).toString();
    };
    EnoFactory.prototype._cleanField = function () {
        this._proto.source.field = this._proto.source.field.filter(function (field) {
            return (field.value && field.value.length !== 0)
                || (field.i18n && _containsNonEmptyValue())
                || field.formula;
            function _containsNonEmptyValue() {
                var nonEmptyValueFound = false;
                for (var i = 0; i < field.i18n.length; i++) {
                    if (field.i18n[i].value && field.i18n[i].value.length > 0) {
                        nonEmptyValueFound = true;
                        break;
                    }
                }
                return nonEmptyValueFound;
            }
        });
    };
    EnoFactory.prototype._isSettingValid = function () {
        return !(this._proto.source === null ||
            this._proto.source.type === null ||
            this._proto.source.security === null);
    };
    EnoFactory.prototype.reset = function (type, security) {
        if (type === void 0) { type = null; }
        if (security === void 0) { security = null; }
        this._patchTargetTip = null;
        this._useEmptyNonce = false;
        this._proto = {
            source: {
                deleted: false,
                type: type,
                security: security,
                parent: [],
                field: [],
                nonce: this._getRandomNonce()
            },
            tip: null,
            sid: null,
            serverT: null,
            clientT: null
        };
        return this;
    };
    EnoFactory.prototype.setProto = function (eno) {
        this.reset();
        if (eno.clientT) {
            this._proto.clientT = cloneDeep(eno.clientT);
        }
        if (eno.source) {
            this._proto.source = cloneDeep(eno.source);
        }
        return this;
    };
    EnoFactory.prototype.setProtoToPatch = function (eno) {
        if (!eno.source) {
            throw new Error('You can\'t patch acknowledgement');
        }
        this.reset();
        this._patchTargetTip = eno.tip;
        if (eno.clientT) {
            this._proto.clientT = cloneDeep(eno.clientT);
            this._proto.clientT.sequence++;
        }
        else {
            this._resetClientT();
            this._proto.clientT.sequence = 1;
        }
        this._proto.source = cloneDeep(eno.source);
        this._proto.source.parent = [eno.sid];
        return this;
    };
    EnoFactory.prototype.resetFields = function () {
        this._proto.source.field = [];
        return this;
    };
    EnoFactory.prototype.setWellKnownTip = function (tip) {
        this._patchTargetTip = tip;
        return this;
    };
    EnoFactory.prototype.setType = function (type) {
        this._proto.source.field = this._proto.source.type !== type ? [] : this._proto.source.field;
        this._proto.source.type = type;
        return this;
    };
    EnoFactory.prototype.useEmptyNonce = function () {
        this._useEmptyNonce = true;
        return this;
    };
    EnoFactory.prototype.useTipNonce = function () {
        this._useTipNonce = true;
        return this;
    };
    EnoFactory.prototype.useRandomNonce = function () {
        this._useTipNonce = false;
        this._useEmptyNonce = false;
        return this;
    };
    EnoFactory.prototype.setI18nValue = function (fieldTip, value, lang) {
        var e_1, _a;
        // @deprecated
        // Right now, lang should only be provided by yaml files or console patching.
        lang = lang || dataConstants.LOCALE_ID;
        value = value.filter(this._normalizeValuesFilter);
        var fieldFound = false;
        try {
            for (var _b = tslib_1.__values(this._proto.source.field), _c = _b.next(); !_c.done; _c = _b.next()) {
                var field = _c.value;
                if (field.tip === fieldTip) {
                    fieldFound = true;
                    this._updateExistingI18n(field, value, lang);
                    break;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (fieldFound) {
            return this;
        }
        this._proto.source.field.push({ tip: fieldTip, i18n: [{ lang: lang, value: value }] });
        return this;
    };
    EnoFactory.prototype._updateExistingI18n = function (field, value, lang) {
        var e_2, _a;
        field.i18n = field.i18n || [];
        if (field.value) {
            field.i18n = [{ lang: dataConstants.LANG_DEFAULT, value: field.value }];
            delete field.value;
        }
        var i18nValue = field.i18n.find(function (i18n) { return i18n.lang === lang; });
        if (i18nValue && isEqual(i18nValue.value, value)) {
            return;
        }
        // Invalidate the values for other locales when the default locale value is updated.
        if (lang === dataConstants.LANG_DEFAULT) {
            field.i18n = [{ lang: lang, value: value }];
            return;
        }
        var langFound = false;
        try {
            for (var _b = tslib_1.__values(field.i18n), _c = _b.next(); !_c.done; _c = _b.next()) {
                var i18n = _c.value;
                if (i18n.lang === lang) {
                    langFound = true;
                    i18n.value = value;
                    break;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        if (!langFound) {
            field.i18n.push({ lang: lang, value: value });
        }
    };
    // Not recommended to use this method to set i18n field
    EnoFactory.prototype.setField = function (newFieldOrTip, value) {
        var newField = typeof newFieldOrTip === 'string' ? { tip: newFieldOrTip, value: value } : newFieldOrTip;
        newField = this._normalizeIField(newField);
        var fieldFound = false;
        for (var i = 0; i < this._proto.source.field.length; i++) {
            var field = this._proto.source.field[i];
            if (field.tip === newField.tip) {
                fieldFound = true;
                this._proto.source.field[i] = newField;
                break;
            }
        }
        if (fieldFound) {
            return this;
        }
        this._proto.source.field.push(newField);
        return this;
    };
    EnoFactory.prototype.setFieldFormula = function (fieldTip, formulas) {
        var newField = { tip: fieldTip, formula: formulas };
        newField = this._normalizeIField(newField);
        var fieldFound = false;
        for (var i = 0; i < this._proto.source.field.length; i++) {
            var field = this._proto.source.field[i];
            if (field.tip === newField.tip) {
                fieldFound = true;
                this._proto.source.field[i] = newField;
                break;
            }
        }
        if (fieldFound) {
            return this;
        }
        this._proto.source.field.push(newField);
        return this;
    };
    EnoFactory.prototype._normalizeIField = function (field) {
        var _this = this;
        if (!field.i18n && !field.formula) {
            field.value = field.value === null || field.value === undefined ? [] : field.value;
            field.value = field.value.filter(this._normalizeValuesFilter);
            return field;
        }
        if (field.i18n) {
            field.i18n.forEach(function (i18n) {
                i18n.value = i18n.value === null || i18n.value === undefined ? [] : i18n.value;
                i18n.value = i18n.value.filter(_this._normalizeValuesFilter);
            });
        }
        return field;
    };
    EnoFactory.prototype._normalizeValuesFilter = function (val) {
        return val !== null && val !== undefined;
    };
    EnoFactory.prototype.setFields = function (newFields) {
        var _this = this;
        newFields.forEach(function (newField) {
            _this.setField(newField);
        });
        return this;
    };
    EnoFactory.prototype.setSecurity = function (security) {
        this._proto.source.security = security;
        return this;
    };
    EnoFactory.prototype.setDeleted = function (deleted) {
        this._proto.source.deleted = deleted;
        this.resetFields();
        return this;
    };
    EnoFactory.prototype.setBranch = function (branch) {
        if (branch === void 0) { branch = dataConstants.BRANCH_MASTER; }
        if (this._proto.clientT === null) {
            this._resetClientT(branch);
            return this;
        }
        this._proto.clientT.branch = branch;
        return this;
    };
    EnoFactory.prototype._resetClientT = function (branch) {
        if (branch === void 0) { branch = dataConstants.BRANCH_MASTER; }
        this._proto.clientT = {
            branch: branch,
            sequence: null,
            createdDate: null,
            modifiedDate: null
        };
    };
    EnoFactory.prototype.setSequence = function (sequence) {
        if (this._proto.clientT === null) {
            this._resetClientT();
        }
        this._proto.clientT.sequence = sequence;
        return this;
    };
    return EnoFactory;
}());
export { EnoFactory };
