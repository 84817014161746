import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import dataConstants from './constants';
import { Tip } from './models/types';
import { LocationService } from '../util/location.service';
import { LoggerService } from '../util/logger.service';
import { get } from 'lodash';
import { decodeJWT } from '../auth/decode-jwt';
import { LOCAL_STORAGE } from '../dom-tokens';
import { environment } from '../../environments/environment';
import { SHA256 } from 'crypto-js';

const LOCAL_STORAGE_CONST = dataConstants.LOCAL_STORAGE;
export const REDIRECT_AFTER_LOGIN_STORAGE_KEY = 'redirect-to';

export interface ISessionInfo {
  id: Tip | null;
  token: string | null;
  user?: Tip | null;
  profile?: Tip | null;
  sessionExpiry: Date | null;
  inactivityTimeout: number | null;
}

const SESSION_MANAGER_LOG_PREFIX = '[session-manager]';

@Injectable({
  providedIn: 'root'
})
export class SessionManagerService {
  private readonly sessionInfoSubject = new BehaviorSubject<ISessionInfo>(this.loadSessionFromLocalStorage());
  constructor(
    private locationService: LocationService,
    private loggerService: LoggerService,
    @Inject(LOCAL_STORAGE) private localStorage: Storage
  ) { }

  get originalRequestedDestination(): string | null {
    return this.localStorage.getItem(REDIRECT_AFTER_LOGIN_STORAGE_KEY);
  }

  setOriginalRequestedDestination(value: string | null) {
    this.localStorage.setItem(REDIRECT_AFTER_LOGIN_STORAGE_KEY, value);
  }

  clearOriginalRequestedDestination() {
    this.localStorage.removeItem(REDIRECT_AFTER_LOGIN_STORAGE_KEY);
  }

  getAuthToken(): string {
    return get(this.sessionInfoSubject.getValue(), 'token', '');
  }

  getSessionId(): string {
    return get(this.sessionInfoSubject.getValue(), 'id', '');
  }

  getSessionInfo$(): Observable<ISessionInfo> {
    return this.sessionInfoSubject.asObservable();
  }

  // @deprecated just session info
  get tokenChanged$(): Observable<ISessionInfo> {
    return this.sessionInfoSubject.asObservable();
  }

  // used by sso login and auth service only for tokens we know should be valid
  // should be called always set token or something
  updateSessionInfoAndRedirectToOriginalRequestedDestination(sessionToken) {
    const currentToken = this.getAuthToken();
    if (!this.shouldSetToken(sessionToken, currentToken)) {
      return;
    }
    this.setToken(sessionToken);

    this.redirect(this.originalRequestedDestination);
  }

  // used by ensrv.service used for temporary session tokens
  updateSessionInfo(tokenToSet: string) {
    const currentToken = this.getAuthToken();
    if (!this.shouldSetToken(tokenToSet, currentToken)) {
      return;
    }

    if (anonymousToAnonymous(currentToken, tokenToSet)) {
      this.changeToken(tokenToSet);
      return;
    }

    this.setToken(tokenToSet);
    this.redirect();
  }

  getLastProfileWithEmail(email: string): string {
    return this.localStorage.getItem(this.getLastProfileKey(email)) || '';
  }

  getLastProfileKey(email: string): string {
    return `${LOCAL_STORAGE_CONST.SESSION_LAST_PROFILE}-${SHA256(email)}`;
  }

  setLastProfile(email: string, profileTip: Tip) {
    this.localStorage.setItem(this.getLastProfileKey(email), profileTip);
  }

  shouldSetToken(tokenToSet, currentToken): boolean {
    if (tokenToSet === '') {
      // sometime server 503s responses return these we dont really want to set them
      return false;
    }

    if (tokenToSet === currentToken) {
      // lets not waste our time
      return false;
    }

    return true;
  }

  setToken(tokenToSet: string): boolean {
    if (!tokenToSet) {
      this.localStorage.removeItem(LOCAL_STORAGE_CONST.SESSION_TOKEN);
    } else {
      this.localStorage.setItem(LOCAL_STORAGE_CONST.SESSION_TOKEN, tokenToSet);
    }

    this.loggerService.info(`${SESSION_MANAGER_LOG_PREFIX} set new token - sessionId: `,
      get(decodeJWT(tokenToSet), 'sessionId', null));

    return true;
  }

  changeToken(tokenToSet) {
    this.setToken(tokenToSet);
    this.loggerService.info(`${SESSION_MANAGER_LOG_PREFIX} change token`);
    // the next request will not be pristine update sessionInfoSubject
    this.sessionInfoSubject.next(this.loadSessionFromLocalStorage());
  }

  private redirect(redirectUrl?: string) {
    // This is the case token has been changed somehow (ex. revoking access, login, switch profile, logout)
    // It is always safer to refresh whenever token is changed (Owen)
    const redirect = redirectUrl || '/';
    this.loggerService.info(`${SESSION_MANAGER_LOG_PREFIX} redirecting to ${redirect}`);
    this.locationService.redirect(redirect);
  }

  private loadSessionFromLocalStorage() {
    const jwtToken = this.localStorage.getItem(LOCAL_STORAGE_CONST.SESSION_TOKEN);
    const { user, profile, sessionId, inactivityTimeout, sessionExpiry, namespace } = decodeJWT(jwtToken);

    if (!namespaceValid(namespace, this.loggerService.info.bind(this))) {
      this.loggerService.info(`${SESSION_MANAGER_LOG_PREFIX} namespace invalid clearing and redirecting`);
      this.localStorage.removeItem(LOCAL_STORAGE_CONST.SESSION_TOKEN);
      this.locationService.redirect('/');
      return;
    }

    const localSessionDetails = {
      id: sessionId,
      token: jwtToken,
      user,
      profile,
      inactivityTimeout,
      sessionExpiry
    };

    this.loggerService.info(`${SESSION_MANAGER_LOG_PREFIX} namespace valid returning - sessionId:`, localSessionDetails.id);
    return localSessionDetails;
  }
}

function anonymousToAnonymous(currentToken: string, tokenToSet: string) {
  // anonymous or null -> user-session (redirect)
  // anonymous or null -> anonymous or null (no-redirect)
  // user-session -> user-session (redirect)
  // user-session -> anonymous (redirect)

  if (isAnonymousOrEmptySession(currentToken) && isAnonymousOrEmptySession(tokenToSet)) {
    return true;
  }
}

function isAnonymousOrEmptySession(token) {
  // our anonymous tokens don't have session expiry
  // or null token has null session expiry
  return !decodeJWT(token).sessionExpiry;
}

export function namespaceValid(namespace, logFn) {
  if (namespace === null) {
    // namespace null is pristine see comment on pristine session
    logFn(`${SESSION_MANAGER_LOG_PREFIX} namespace is valid - null (pristine)`);
    return true;
  }
  if (namespace === environment.ns) {
    logFn(`${SESSION_MANAGER_LOG_PREFIX} namespace is valid - environment is correct`);
    return true;
  }
  return false;
}
