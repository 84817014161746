import { RouterModule } from '@angular/router';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { SignedOutComponent } from './auth/signed-out/signed-out.component';
import { AcceptInviteComponent } from './auth/accept-invite/accept-invite.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { AcceptInviteResolverService } from './auth/accept-invite-resolver.service';
import { AcceptVerificationComponent } from './auth/accept-verification/accept-verification.component';
import { AcceptVerificationResolverService } from './auth/accept-verification-resolver.service';
import { PageNotFoundComponent } from './shell/page-not-found/page-not-found.component';
import { PageNotAuthorizedComponent } from './shell/page-not-authorized/page-not-authorized.component';
import { AuthGuardService } from './auth/auth-guard.service';
import { ShellRedirectGuard } from './shell-redirect.guard';
import { LearnComponent } from './learn/learn.component';
import { ActivateGuard } from './auth/activate.guard';
import { SSOAuthComponent } from './auth/sso-auth/sso-auth.component';
import { APIComponent } from './api/api.component';
import { AuthAdminGuard } from './auth/auth-admin-guard.guard';
import { ForumComponent } from './forum/forum.component';
var ɵ0 = { issuingService: 'saml' }, ɵ1 = { issuingService: 'support' }, ɵ2 = function () { return import("./slash-app.module.ngfactory").then(function (m) { return m.SlashAppModuleNgFactory; }); };
var routes = [
    { path: 'signin', component: SignInComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'signed-out', component: SignedOutComponent },
    {
        path: 'accept-invite',
        component: AcceptInviteComponent,
        canActivate: [ActivateGuard],
        resolve: { isTokenValid: AcceptInviteResolverService }
    },
    {
        path: 'accept-verification',
        component: AcceptVerificationComponent,
        resolve: { isTokenValid: AcceptVerificationResolverService }
    },
    { path: 'sso-auth', component: SSOAuthComponent, data: ɵ0 },
    { path: 'support-auth', component: SSOAuthComponent, data: ɵ1 },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'learn', component: LearnComponent, canActivate: [AuthGuardService] },
    { path: 'forum', component: ForumComponent, canActivate: [AuthGuardService] },
    { path: 'api/docs', component: APIComponent, canActivate: [AuthAdminGuard] },
    {
        path: 'app',
        canLoad: [ShellRedirectGuard],
        canActivate: [AuthGuardService],
        loadChildren: ɵ2
    },
    { path: '', redirectTo: '/app/s', pathMatch: 'full' },
    { path: 'not-authorized', component: PageNotAuthorizedComponent },
    { path: '**', component: PageNotFoundComponent }
];
export var AppRoutes = RouterModule.forRoot(routes);
export { ɵ0, ɵ1, ɵ2 };
