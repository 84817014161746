import { Injectable } from '@angular/core';
import { CompositeNodeConverter, INodeAndLink } from './CompositeNodeConverter';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import { isEmpty } from 'lodash';
import { Formula } from '../../../data/models/types';
import {
  createConnection,
  createSetVarNode,
  createSubProcessNode,
  createSwitchNode
} from './utils/create-node-utils';
import { IUpdateStageEditor } from '../node-editors/update-stage-editor/update-stage-editor.component';

const NS = 'UpdateStageConverterService';

@Injectable({
  providedIn: 'root'
})
export class UpdateStageConverterService extends CompositeNodeConverter {
  type = 'processnode/logic/subprocess';
  processNodeType = 'processnodetype/logic/subprocess';

  constructor() {
    super();
  }

  public convert(nodeData: IUpdateStageEditor): INodeAndLink {
    if (!this.isValid(nodeData)) {
      throw new InvalidWorkflowNodeDataError(`Please correct invalid data on update-stage node [${ nodeData.fields.title }]`, nodeData);
    }

    const { formula, stage } = nodeData.fields;

    const vars: {[key in string]: Formula} = {
      'Object tip': formula,
      Stage: stage,
    };

    const setVarStatusNode = createSetVarNode(vars, nodeData.tip);

    const subprocessNode = createSubProcessNode('eim/process/workflow/update-stage', vars);

    const doneConnectionForSetVarNode = createConnection('done', subprocessNode);
    setVarStatusNode.connections.push(doneConnectionForSetVarNode);

    const switchStatusNode = createSwitchNode({ name: 'VAR', args: ['Status'] });

    const doneConnectionForSubprocessNode = createConnection('done', switchStatusNode);
    subprocessNode.connections.push(doneConnectionForSubprocessNode);

    const nodesAndLink: INodeAndLink = {
      nodes: [
        setVarStatusNode,
        subprocessNode,
        switchStatusNode
      ],
      links: [
        doneConnectionForSetVarNode,
        doneConnectionForSubprocessNode
      ]
    };

    this.populateOutgoingLinks(nodeData).forEach(link => {
      switchStatusNode.connections.push(link);
      nodesAndLink.links.push(link);
    });

    return nodesAndLink;
  }

  private isValid(nodeData: IUpdateStageEditor): boolean {
    return !isEmpty(nodeData.fields.title)
      && !isEmpty(nodeData.fields.formula)
      && !isEmpty(nodeData.fields.stage);
  }
}
