<app-side-sheet-header
    i18n-action="@@sideSheetHeader.action.Done"
    action="{{ isSingleSelect ? '': 'Done' }}"
    (actioned)="onDone()"
    (goBack)="onCancel()">{{ title }}</app-side-sheet-header>
<ng-container>
  <app-side-sheet-section
      [showHeader]="false"
      [showBorder]="false">
      <app-filter-and-add
        *ngIf="!canCreateNew"
        (keyup.enter)="loadSearchData($event.target.value)"
        (keyup.shift.enter)="loadSearchData($event.target.value)"
        [(filterText)]="filterText"></app-filter-and-add>
      <app-filter-and-add
          *ngIf="canCreateNew"
          (keyup.enter)="loadSearchData($event.target.value)"
          (keyup.shift.enter)="loadSearchData($event.target.value)"
          [showAddButton]="typeTips | can : 'create' | async"
          (add)="addObject()">
      </app-filter-and-add>
  </app-side-sheet-section>
  <app-alert
      i18n="@@sideSheetObjectChooser.alert"
      *ngIf="loadingState === 'failed'"
      type="danger">Failed to load data.</app-alert>
  <app-side-sheet-section [showHeader]="false" *ngIf="queryContainer && ((hasQueryResults$ | async) !== null) else loading">
    <app-side-sheet-list
        *ngIf="(hasQueryResults$ | async) else empty"
        [emitChangeEventOnLoad]="false"
        [mode]="listType"
        [showMoreButton]="showMoreButton"
        [loadingState]="loadingState"
        (showMore)="onShowMore()"
        [(selected)]="selected"
        (selectedChange)="itemSelectedChange($event)"> 
      <ng-container *ngFor="let query of queryContainer">
        <app-side-sheet-list-item
            *ngFor="let option of query | async | slice: 0:limit; let i = index"
            [key]="option['$tip'] || option['Tip']"
            [allowNavAway]="allowNavAway"
            (navAway)="onOpenObject(option)">
          <div *ngIf="showModuleInfo; else defaultView">
            <div class="option-title">{{ option['Title'] }}</div>
            <div class="eim-font-size_small">{{ option['Module Name'] }}</div>
          </div>
          <ng-template #defaultView>
            <div class="d-flex flex-column justify-content-center">
              <div class="option-title crop-long-text">{{ option['Title'] }}</div>
              <div data-test="option-summary" class="eim-font-size_small crop-long-text">{{ option['Summary'] | stripHtml }}</div>
            </div>
          </ng-template>
        </app-side-sheet-list-item>
      </ng-container>
    </app-side-sheet-list>
    <ng-template #empty>
      <app-empty-state
          imgSrc="/assets/img/empty-states/no-groups.svg"
          i18n-label="@@sideSheetObjectChooser.emptyState.label"
          label="No options here"></app-empty-state>
    </ng-template>
  </app-side-sheet-section>
</ng-container>
<ng-template #loading>
  <div
      [appSpinner]="true"
      class="mt-6"></div>
</ng-template>
