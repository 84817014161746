import { IProcessConnection, IProcessNode } from '../../../models/process';
import { IWorkflow } from '../../../models/workflow';
import dataConstants from '../../../data/constants';
import { INodeEditorData, INodeLinkKey } from '../workflow-designer-interfaces';

export interface INodeAndLink {
  nodes: IProcessNode[];
  links: IProcessConnection[];
}

export abstract class CompositeNodeConverter {
  /*
   * convert nodeData to actual process node eno data
   * nodeData should have toLink property
   *
   * Provide process node "key" property to only the first node. Give null to any other process nodes's key
   * Do not generate the first link(incoming). It is automatic.
   *
   * For example, if this is the target conversion,
   * N1 -> N2 -> N3 ->
   * then, 3 nodes and 3 links should be returned and all the linking should be done by the converter
   * except the incoming link.
   * Only N1 need key property, give null to key of N2 and N3
   * Make sure populate all the connections of nodes and toNodes of links
   *
   * Do not forget to call populateOutgoingLinks function before return
   *
   * NOTE THAT whenever a new CompositeNodeConverter is implemented, things bellow should be done
   *
   * 1. Add interface and scheme in process.ts and extend the scheme to processNodeSchemeUnion
   * 2. Add converter map in process-converter.service.ts constructor
   * 3. Do not forget to throw InvalidWorkflowNodeDataError when the given data is invalid
   *
   * @Throws: InvalidWorkflowNodeDataError thrown when validation fails, such as required field is not given
   */
  abstract convert(nodeData: INodeEditorData, workflow: IWorkflow): INodeAndLink;

  protected populateOutgoingLinks(nodeData: INodeEditorData): IProcessConnection[] {
    const links: IProcessConnection[] = [];

    Object.keys(nodeData.toLink).forEach((outcomeAndDelaySecKey: string) => {
      const { outcome, delaySec } = JSON.parse(outcomeAndDelaySecKey) as INodeLinkKey;

      const link: IProcessConnection = {
        $type: 'processconnection',
        $security: dataConstants.SECURITY.INSTANCE_USER_ADMIN,
        outcome,
        toNodes: [], // This will be populated by toNodeKeys later by linkAll() function in process-converter.service
        point: [0, 0], // This is meaningless to save GoJS points as connection eno will be grouped by outcome and delay
        delaySec,
        toNodeKeys: nodeData.toLink[outcomeAndDelaySecKey]
      };

      links.push(link);
    });

    return links;
  }

  protected constructor() { }
}
