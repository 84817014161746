import { DataTypes, IObjectScheme } from '../data/models/scheme';
import { IObject } from '../data/models/object';
import { Tip } from '../data/models/types';
import { IObjectType, objectTypeScheme } from './object-type';
import { IObjectStep, objectStepScheme } from './object-step';

export {
  IWorkflowPanel,
  workflowPanelScheme,
  IWorkflowPanelFlat,
  workflowPanelFlatScheme,
  IWorkflowPanelWithSteps,
  workflowPanelWithStepsScheme,
  IWorkflowPanelSummary
};

interface IWorkflowPanel extends IObject {
  name: string;
  description: string;
  global: boolean;
  objectType?: IObjectType;
  steps: IObjectStep[];
}

const workflowPanelScheme: IObjectScheme = {
  'app/workflow-panel:name': { type: DataTypes.i18n },
  'app/workflow-panel:description': { type: DataTypes.i18n },
  'app/workflow-panel:global': { type: DataTypes.boolean },
  'app/workflow-panel:object-type': {
    type: DataTypes.object,
    scheme: objectTypeScheme
  },
  'app/workflow-panel:steps': {
    type: DataTypes.objectArray,
    mutable: true,
    scheme: objectStepScheme
  }
};

interface IWorkflowPanelFlat extends IObject {
  name: string;
  description: string;
  global: boolean;
  objectType?: Tip;
  steps: Tip[];
}

interface IWorkflowPanelSummary extends IObject {
  name: string;
  moduleName: string;
}


const workflowPanelFlatScheme: IObjectScheme = {
  'app/workflow-panel:name': { type: DataTypes.i18n },
  'app/workflow-panel:description': { type: DataTypes.i18n },
  'app/workflow-panel:global': { type: DataTypes.boolean },
  'app/workflow-panel:object-type': {},
  'app/workflow-panel:steps': { type: DataTypes.stringArray }
};

interface IWorkflowPanelWithSteps extends IObject {
  name: string;
  description: string;
  dashboardTitle?: string;
  global: boolean;
  objectType?: Tip;
  steps: IObjectStep[];
}

const workflowPanelWithStepsScheme: IObjectScheme = {
  'app/workflow-panel:name': { type: DataTypes.i18n },
  'app/workflow-panel:description': { type: DataTypes.i18n },
  'app/workflow-panel:dashboard-title': {type: DataTypes.i18n},
  'app/workflow-panel:global': { type: DataTypes.boolean },
  'app/workflow-panel:object-type': {},
  'app/workflow-panel:steps': {
    type: DataTypes.objectArray,
    mutable: true,
    scheme: objectStepScheme
  }
};
