import { EnoFactory } from './EnoFactory';
import dataConstants from './constants';
import * as i0 from "@angular/core";
var OpPullService = /** @class */ (function () {
    function OpPullService() {
        this._opPullFactory = new EnoFactory('op/pull', dataConstants.SECURITY.OP);
        this._opPullCache = new Map();
    }
    OpPullService.prototype.createOpPull = function (option) {
        this._normalizeOpPullOption(option);
        var opPullEno = this._opPullFactory.setFields([
            { tip: 'op/pull/tip', value: option.tip },
            { tip: 'op/pull/sid', value: option.sid },
            { tip: 'op/pull/branch', value: option.branch },
            { tip: 'op/pull/watch', value: [option.watch + ''] },
            { tip: 'op/pull/recursive-depth', value: option.recursiveDepth === 0 ? [] : [option.recursiveDepth + ''] },
            { tip: 'op/pull/recursive-field', value: option.recursiveField },
            { tip: 'op/pull/if-not-sid', value: option.ifNotSid },
        ]).makeEno();
        this._opPullCache.set(opPullEno.tip, opPullEno);
        return opPullEno;
    };
    OpPullService.prototype._normalizeOpPullOption = function (option) {
        option.sid = option.sid || [];
        option.branch = option.branch || [dataConstants.BRANCH_MASTER];
        option.watch = option.watch === undefined ? true : option.watch;
        option.recursiveDepth = option.recursiveDepth || 0;
        option.recursiveField = option.recursiveField || [];
        option.ifNotSid = option.ifNotSid || [];
    };
    // This method is mainly for re-do op/pull triggered by tip watch
    // That's why by default it resets watch and if-not-sid
    OpPullService.prototype.getOpPull = function (tip, watch) {
        if (watch === void 0) { watch = false; }
        var opPullEno = this._opPullCache.get(tip);
        if (opPullEno === undefined) {
            return null;
        }
        var factoryToPatch = new EnoFactory();
        factoryToPatch.setProto(opPullEno);
        factoryToPatch.setField({ tip: 'op/pull/watch', value: [watch + ''] });
        factoryToPatch.setField({ tip: 'op/pull/if-not-sid', value: [] });
        return factoryToPatch.makeEno();
    };
    OpPullService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OpPullService_Factory() { return new OpPullService(); }, token: OpPullService, providedIn: "root" });
    return OpPullService;
}());
export { OpPullService };
