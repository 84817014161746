import { DataTypes, IObjectScheme } from '../data/models/scheme';
import { IObject } from '../data/models/object';
import { Formula, Tip } from '../data/models/types';
import { FormGroup } from '@angular/forms';
import { Type } from '@angular/core';
import { GeometryObject, Point } from 'geojson';
import { ISymbology } from './object-type';
import { environment } from '../../environments/environment';
import { IFilterInput } from '../var/filter-input/filter-input.service';

export enum GEOMETRY_TYPES {
  POINT = 'point',
  LINE = 'line',
  POLYGON = 'polygon'
}

/************************* Map data source *****************************/

export enum DATA_SOURCE_TYPES {
  NOGGIN_QUERY = 'noggin/query',
  EXTERNAL_GEOJSON = 'external/geojson',
  EXTERNAL_GEORSS = 'external/georss',
  EXTERNAL_KML = 'external/kml',
  EXTERNAL_WMS = 'external/wms',
  MAPBOX_VECTOR_TILES = 'mapbox/vector-tiles',
  ESRI_FEATURE_LAYER = 'esri/feature-layer',
  ESRI_DYNAMIC_MAP_SERVICE = 'esri/dynamic-map-service',
  ESRI_TILED_MAP_SERVICE = 'esri/tiled-map-service',
  ESRI_VECTOR_TILE_SERVICE = 'esri/vector-tile-service',
  ESRI_IMAGE_SERVICE = 'esri/image-service'
}

export const dataSourceCategories: { label: string; types: DATA_SOURCE_TYPES[] }[] = [
  {
    label: 'Noggin',
    types: [
      DATA_SOURCE_TYPES.NOGGIN_QUERY
    ]
  },
  {
    label: 'External feeds',
    types: [
      DATA_SOURCE_TYPES.EXTERNAL_GEOJSON,
      DATA_SOURCE_TYPES.EXTERNAL_GEORSS,
      DATA_SOURCE_TYPES.EXTERNAL_KML,
      DATA_SOURCE_TYPES.EXTERNAL_WMS
    ]
  },
  {
    label: 'MapBox',
    types: [
      DATA_SOURCE_TYPES.MAPBOX_VECTOR_TILES,
    ]
  },
  {
    label: 'Esri',
    types: [
      DATA_SOURCE_TYPES.ESRI_FEATURE_LAYER,
      DATA_SOURCE_TYPES.ESRI_DYNAMIC_MAP_SERVICE,
      DATA_SOURCE_TYPES.ESRI_TILED_MAP_SERVICE,
      DATA_SOURCE_TYPES.ESRI_VECTOR_TILE_SERVICE,
      DATA_SOURCE_TYPES.ESRI_IMAGE_SERVICE
    ]
  }
];

export const displayName: { [key in DATA_SOURCE_TYPES]: string } = {
  [DATA_SOURCE_TYPES.NOGGIN_QUERY]: 'Query',
  [DATA_SOURCE_TYPES.EXTERNAL_GEOJSON]: 'GeoJSON',
  [DATA_SOURCE_TYPES.EXTERNAL_GEORSS]: 'GeoRSS',
  [DATA_SOURCE_TYPES.EXTERNAL_KML]: 'KML',
  [DATA_SOURCE_TYPES.EXTERNAL_WMS]: 'WMS',
  [DATA_SOURCE_TYPES.MAPBOX_VECTOR_TILES]: 'Vector tiles',
  [DATA_SOURCE_TYPES.ESRI_FEATURE_LAYER]: 'Feature layer',
  [DATA_SOURCE_TYPES.ESRI_DYNAMIC_MAP_SERVICE]: 'Dynamic map service',
  [DATA_SOURCE_TYPES.ESRI_TILED_MAP_SERVICE]: 'Tiled map service',
  [DATA_SOURCE_TYPES.ESRI_VECTOR_TILE_SERVICE]: 'Vector tile service',
  [DATA_SOURCE_TYPES.ESRI_IMAGE_SERVICE]: 'Image service'
};

export interface IMapDataSourceConfig {
  geometryType?: GEOMETRY_TYPES;
  query?: {
    objectTypeTip: Tip;
    queryTip: Tip;
    fields: Formula[];
    geographyField: Formula;
  };
  url?: string; // TODO: replace with proper type when External source finished
  srid?: string; // TODO: replace with proper type when External source finished
  sublayerName?: string;
  authentication?: string; // TODO: replace with proper type when Authentication designed
  featureQuery?: string;
}

export interface IMapDataSource extends IObject {
  name: string;
  type: DATA_SOURCE_TYPES;
  config: IMapDataSourceConfig;
  moduleName?: string;
}

export const mapDataSourceScheme: IObjectScheme = {
  'app/map/source:name': { type: DataTypes.i18n },
  'app/map/source:type': { type: DataTypes.string },
  'app/map/source:config': { type: DataTypes.json }
};

export interface IMapDataSourceMapConfigFlags {
  query: boolean;
  url: boolean;
  srid: boolean;
  sublayerName?: boolean;
  authentication?: 'Basic' | 'Mapbox token' | 'ArcGIS token'; // TODO: replace with proper type when Authentication designed
  featureQuery?: boolean;
}

interface IMapDataSourceMapConfig {
  formComponent: Type<IMapDataSourceFormComponent>;
  flags: IMapDataSourceMapConfigFlags;
}

export type MapDataSourceMap = {
  [key in DATA_SOURCE_TYPES]: IMapDataSourceMapConfig;
};

export interface IMapDataSourceFormComponent {
  dataSource: IMapDataSource;
  flags: IMapDataSourceMapConfigFlags;
  form: FormGroup;
  getFormData: () => IMapDataSource;
}


/************************* Map style *****************************/

export enum STROKE_DASH_STYLE {
  SOLID = 'solid',
  DASH = 'dash',
  DASH_DOT = 'dash-dot',
  DOT = 'dot',
  LONG_DASH = 'long-dash',
  LONG_DASH_DOT = 'long-dash-dot',
  LONG_DASH_DOT_DOT = 'long-dash-dot-dot',
  SHORT_DASH = 'short-dash',
  SHORT_DASH_DOT = 'short-dash-dot',
  SHORT_DASH_DOT_DOT = 'short-dash-dot-dot',
  SHORT_DOT = 'short-dot',
  NONE = 'none'
}

export const lineDashStyleNamePicMap: { [key in STROKE_DASH_STYLE]: { name: string, picUrl?: string } } = {
  [STROKE_DASH_STYLE.DASH]: {
    name: 'Dash',
    picUrl: `${ environment.assetPath }/assets/img/maps/dash-styles/${ STROKE_DASH_STYLE.DASH }.png`
  },
  [STROKE_DASH_STYLE.DASH_DOT]: {
    name: 'Dash dot',
    picUrl: `${ environment.assetPath }/assets/img/maps/dash-styles/${ STROKE_DASH_STYLE.DASH_DOT }.png`
  },
  [STROKE_DASH_STYLE.DOT]: {
    name: 'Dot',
    picUrl: `${ environment.assetPath }/assets/img/maps/dash-styles/${ STROKE_DASH_STYLE.DOT }.png`
  },
  [STROKE_DASH_STYLE.LONG_DASH]: {
    name: 'Long dash',
    picUrl: `${ environment.assetPath }/assets/img/maps/dash-styles/${ STROKE_DASH_STYLE.LONG_DASH }.png`
  },
  [STROKE_DASH_STYLE.LONG_DASH_DOT]: {
    name: 'Long dash dot',
    picUrl: `${ environment.assetPath }/assets/img/maps/dash-styles/${ STROKE_DASH_STYLE.LONG_DASH_DOT }.png`
  },
  [STROKE_DASH_STYLE.LONG_DASH_DOT_DOT]: {
    name: 'Long dash dot dot',
    picUrl: `${ environment.assetPath }/assets/img/maps/dash-styles/${ STROKE_DASH_STYLE.LONG_DASH_DOT_DOT }.png`
  },
  [STROKE_DASH_STYLE.NONE]: {
    name: 'None'
  },
  [STROKE_DASH_STYLE.SHORT_DASH]: {
    name: 'Short dash',
    picUrl: `${ environment.assetPath }/assets/img/maps/dash-styles/${ STROKE_DASH_STYLE.SHORT_DASH }.png`
  },
  [STROKE_DASH_STYLE.SHORT_DASH_DOT]: {
    name: 'Short dash dot',
    picUrl: `${ environment.assetPath }/assets/img/maps/dash-styles/${ STROKE_DASH_STYLE.SHORT_DASH_DOT }.png`
  },
  [STROKE_DASH_STYLE.SHORT_DASH_DOT_DOT]: {
    name: 'Short dash dot dot',
    picUrl: `${ environment.assetPath }/assets/img/maps/dash-styles/${ STROKE_DASH_STYLE.SHORT_DASH_DOT_DOT }.png`
  },
  [STROKE_DASH_STYLE.SHORT_DOT]: {
    name: 'Short dot',
    picUrl: `${ environment.assetPath }/assets/img/maps/dash-styles/${ STROKE_DASH_STYLE.SHORT_DOT }.png`
  },
  [STROKE_DASH_STYLE.SOLID]: {
    name: 'Solid',
    picUrl: `${ environment.assetPath }/assets/img/maps/dash-styles/${ STROKE_DASH_STYLE.SOLID }.png`
  }
};

export enum SYMBOLOGY_SIZE {
  SMALL = 'small', // 22x22 icon font-size 1.2rem
  MEDIUM = 'medium', // 30x30 icon font-size 1.8rem
  LARGE = 'large' // 42x42 icon font-size 2.6rem
}

export interface IPointStyleConfig {
  symbology: ISymbology;
  symbologySize: SYMBOLOGY_SIZE;
}

export interface ILineStyleConfig {
  strokeColor: string;
  strokeWidth: number;
  strokeDashStyle: STROKE_DASH_STYLE;
}

export interface IPolygonStyleConfig {
  fillColor: string;
  strokeColor: string;
  strokeWidth: number;
}

export type StyleConfigLike = IPointStyleConfig | ILineStyleConfig | IPolygonStyleConfig;
export type StyleConfigCombined = IPointStyleConfig & ILineStyleConfig & IPolygonStyleConfig;

export interface IMapStyle extends IObject {
  $type: 'app/map/style';
  name: string;
  type: GEOMETRY_TYPES;
  config: StyleConfigLike;
}

export const mapStyleScheme: IObjectScheme = {
  'app/map/style:name': { type: DataTypes.i18n },
  'app/map/style:type': { type: DataTypes.string },
  'app/map/style:config': { type: DataTypes.json }
};


/************************* Map layer *****************************/

export enum STYLE_TYPES {
  DEFAULT = 'default',
  STYLE = 'style',
  HEATMAP = 'heatmap'
}

export interface IMapLayer extends IObject {
  $type: 'app/map/layer';
  name: string;
  dataSource: IMapDataSource;
  isBaseMap: boolean;
  geometryType?: GEOMETRY_TYPES;
  styleType: STYLE_TYPES;
  style: Tip;
  heatmapRadius: number;
  hasMinZoom: boolean;
  hasMaxZoom: boolean;
  minZoomLevel: number;
  maxZoomLevel: number;
  opacity: number;
  visibleProperties: any[]; // TODO: replace with proper type when visible properties are ready
}

export const mapLayerScheme: IObjectScheme = {
  'app/map/layer:name': { type: DataTypes.i18n },
  'app/map/layer:data-source': { type: DataTypes.object, scheme: mapDataSourceScheme },
  'app/map/layer:is-base-map': { type: DataTypes.boolean },
  'app/map/layer:geometry-type': { type: DataTypes.string },
  'app/map/layer:style-type': { type: DataTypes.string },
  'app/map/layer:style': { type: DataTypes.string },
  'app/map/layer:heatmap-radius': { type: DataTypes.number },
  'app/map/layer:has-min-zoom': { type: DataTypes.boolean },
  'app/map/layer:has-max-zoom': { type: DataTypes.boolean },
  'app/map/layer:min-zoom-level': { type: DataTypes.number },
  'app/map/layer:max-zoom-level': { type: DataTypes.number },
  'app/map/layer:opacity': { type: DataTypes.number },
  'app/map/layer:visible-properties': { type: DataTypes.stringArray } // TODO: replace with proper scheme when its ready
};

export interface IMapLayerMapConfigFlags {
  baseMap: boolean;
  geometryType: boolean;
  subLayers: boolean;
  layerStyle: boolean;
  attributes: boolean;
  heatmap: boolean;
}

interface IMapLayerMapConfig {
  flags: IMapLayerMapConfigFlags;
}

export type MapLayerMap = {
  [key in DATA_SOURCE_TYPES]: IMapLayerMapConfig;
};

/************************* Map extent *****************************/

export enum EXTENT_TYPES {
  DEFAULT = 'default',
  LOCATION = 'location',
  POLYGON = 'polygon',
  LAYER = 'layer',
  ALL_LAYERS = 'all-layers'
}

export const extentTypeNameMap: { [key in EXTENT_TYPES]: string } = {
  [EXTENT_TYPES.DEFAULT]: 'Honor system extent default',
  [EXTENT_TYPES.LOCATION]: 'User\'s current location',
  [EXTENT_TYPES.POLYGON]: 'Define custom region on the map',
  [EXTENT_TYPES.LAYER]: 'Specific layers',
  [EXTENT_TYPES.ALL_LAYERS]: 'All layers'
};

export interface IMapExtent extends IObject {
  type: EXTENT_TYPES;
  layer?: IMapLayer[];
  polygon?: GeometryObject;
  zoomLevel?: number;
}

export const mapExtentScheme: IObjectScheme = {
  'app/map/extent:type': { type: DataTypes.string },
  'app/map/extent:layer': { type: DataTypes.objectArray, scheme: mapLayerScheme },
  'app/map/extent:polygon': { type: DataTypes.geography },
  'app/map/extent:zoom-level': { type: DataTypes.number }
};

export interface IFlatMapExtent extends IObject {
  type: EXTENT_TYPES;
  layer?: Tip[];
  polygon?: GeometryObject;
  zoomLevel?: number;
}

export const flatMapExtentScheme: IObjectScheme = {
  'app/map/extent:type': { type: DataTypes.string },
  'app/map/extent:layer': { type: DataTypes.stringArray },
  'app/map/extent:polygon': { type: DataTypes.geography },
  'app/map/extent:zoom-level': { type: DataTypes.number }
};


/************************* Map *****************************/

export interface IMap extends IObject {
  name: string;
  baseMap: IMapLayer;
  layers: IMapLayer[];
  extent: IMapExtent;
}

export const mapScheme: IObjectScheme = {
  'app/map:name': { type: DataTypes.i18n },
  'app/map:base-map': { type: DataTypes.object, scheme: mapLayerScheme },
  'app/map:layers': { type: DataTypes.objectArray, scheme: mapLayerScheme },
  'app/map:extent': { type: DataTypes.object, scheme: mapExtentScheme }
};

export interface IFlatMap extends IObject {
  name: string;
  baseMap: Tip;
  layers: Tip[];
  extent: IFlatMapExtent;
}

export const flatMapScheme: IObjectScheme = {
  'app/map:name': { type: DataTypes.i18n },
  'app/map:base-map': { type: DataTypes.string },
  'app/map:layers': { type: DataTypes.stringArray },
  'app/map:extent': { type: DataTypes.object, scheme: flatMapExtentScheme, mutable: true }
};

export type MapLike = Tip | IFlatMap | IMap;


export interface IMapChooserConfig {
  mapTip: Tip;
  overrideExtent?: Tip;
  overrideBaseMap?: Tip;
  filterInputs?: IFilterInput[];
  featureQueryFilterInputs?: IFilterInput[];
}


/************************* Address *****************************/

export interface IAddress extends IObject {
  displayStreet: string;
  point: Point;
  meta: IGISFeature;
}

export const addressScheme: IObjectScheme = {
  'app/address:display-street': { type: DataTypes.i18n },
  'app/address:point': { type: DataTypes.geography },
  'app/address:meta': { type: DataTypes.json }
};


export interface IGISFeature {
  // only type the info we are interested in
  place_name: string;
  center: number[]; // lon, lat
  geometry: Point; // most likely will be a point
}

export interface IGISResult {
  // only type the info we are interested in
  features: IGISFeature[];
}
