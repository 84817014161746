import { pipe, Subject } from 'rxjs';
import { filter, map, tap, merge } from 'rxjs/operators';
/*
* Throttles groups of values based on the duration passed in
*
* Only throttles excessive values it emits the last throttled value when the throttle
* times out so the user also has the latest data
*
* Group by function lets you select what values a throttled as a group
*/
// tslint:disable-next-line:max-line-length
export function groupedExcessiveOutputThrottle(throttleDuration, groupKeyFunction, loggerService) {
    var throttleMap = new Map();
    var lastValues = new Subject();
    var lastValueObs$ = lastValues.asObservable();
    return pipe(map(function (data) { return ({ data: data, groupKey: groupKeyFunction(data) }); }), filter(function (_a) {
        var groupKey = _a.groupKey, data = _a.data;
        var hasValue = throttleMap.has(groupKey);
        if (hasValue) {
            var skipped = throttleMap.get(groupKey).skipped;
            var update = {
                skipped: skipped + 1,
                data: data
            };
            throttleMap.set(groupKey, update);
        }
        return !hasValue;
    }), tap(function (_a) {
        var data = _a.data, groupKey = _a.groupKey;
        throttleMap.set(groupKey, { data: data, skipped: 0 });
        setTimeout(function () {
            var currentThrottle = throttleMap.get(groupKey);
            if (currentThrottle.skipped > 1 && loggerService) {
                // if we only have one skipped value we still get it just at the end of the throttle
                loggerService.info("Throttled (skipped) " + (currentThrottle.skipped - 1) + " requests for " + groupKey);
            }
            if (currentThrottle.skipped > 0) {
                lastValues.next(currentThrottle.data);
                if (loggerService) {
                    loggerService.info("Throttled (delayed) requests with data", currentThrottle.data);
                }
            }
            throttleMap.delete(groupKey);
        }, throttleDuration);
    }), map(function (_a) {
        var data = _a.data;
        return data;
    }), merge(lastValueObs$));
}
