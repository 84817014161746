import { Formula, Tip } from '../../../../../data/models/types';

export enum FILE_NODE_LOCATION_TYPE {
  FILE_UPLOAD_FIELD = 'fieldUploadField',
  OBJECT_ATTACHMENTS = 'objectAttachments',
  DOCUMENT_FOLDER = 'documentFolder',
  INTEGRATION_BUCKET = 'integrationBucket',
  S3_BUCKET = 's3Bucket',
  SFTP = 'sftp'
}

export interface IFileLocationValue {
  locationType: FILE_NODE_LOCATION_TYPE;
  locationTip?: Tip;
  locationFormula?: Formula;
  selectedPropertyKey?: string;
}

export enum FILE_NODE_LOCATION_MODE {
  SAVE_FILE = 'saveFile',
  RETRIEVE_FILE = 'retrieveFile'
}

export interface LocationList {
  locationType: FILE_NODE_LOCATION_TYPE;
  title: string;
  hasChevron: boolean;
  featureFlag?: string;
};

type LocationLabels = {
  [key in FILE_NODE_LOCATION_MODE]: LocationList[]
};

export const internalLocationLabels: LocationLabels = {
  [FILE_NODE_LOCATION_MODE.SAVE_FILE]: [
    { locationType: FILE_NODE_LOCATION_TYPE.DOCUMENT_FOLDER, title: 'Select a document folder', hasChevron: true },
    { locationType: FILE_NODE_LOCATION_TYPE.OBJECT_ATTACHMENTS, title: 'Attach to an object', hasChevron: true },
    { locationType: FILE_NODE_LOCATION_TYPE.FILE_UPLOAD_FIELD, title: 'Add to file upload field', hasChevron: true },
  ],
  [FILE_NODE_LOCATION_MODE.RETRIEVE_FILE]: [
    // { locationType: FILE_NODE_LOCATION_TYPE.DOCUMENT_FOLDER, title: 'Select a document folder', hasChevron: true },
    // { locationType: FILE_NODE_LOCATION_TYPE.OBJECT_ATTACHMENTS, title: 'From an object', hasChevron: true },
    // { locationType: FILE_NODE_LOCATION_TYPE.FILE_UPLOAD_FIELD, title: 'From a file upload field', hasChevron: true },
  ]
};

export const externalLocationLabels: LocationLabels = {
  [FILE_NODE_LOCATION_MODE.SAVE_FILE]: [
    // {
    //   locationType: FILE_NODE_LOCATION_TYPE.INTEGRATION_BUCKET,
    //   title: 'Integration bucket',
    //   featureFlag: 'feature-integration-bucket',
    //   hasChevron: false
    // },
    // {
    //   locationType: FILE_NODE_LOCATION_TYPE.S3_BUCKET,
    //   title: 'S3 Bucket',
    //   featureFlag: 'feature-amazon-s3-integrations-center',
    //   hasChevron: true
    // },
    // { locationType: FILE_NODE_LOCATION_TYPE.SFTP, title: 'SFTP', featureFlag: 'feature-sftp-integrations-center', hasChevron: true },
  ],
  [FILE_NODE_LOCATION_MODE.RETRIEVE_FILE]: [
    {
      locationType: FILE_NODE_LOCATION_TYPE.INTEGRATION_BUCKET,
      title: 'Integration bucket',
      featureFlag: 'feature-integration-bucket',
      hasChevron: false
    },
    {
      locationType: FILE_NODE_LOCATION_TYPE.S3_BUCKET,
      title: 'S3 Bucket',
      featureFlag: 'feature-amazon-s3-integrations-center',
      hasChevron: true
    },
    { locationType: FILE_NODE_LOCATION_TYPE.SFTP, title: 'SFTP', featureFlag: 'feature-sftp-integrations-center', hasChevron: true },
  ]
};

// --------Save file node-----------
export const saveFileLocationProcessTips: { [key in FILE_NODE_LOCATION_TYPE]: string } = {
  [FILE_NODE_LOCATION_TYPE.DOCUMENT_FOLDER]: 'eim/process/workflow/save-file-document-folder',
  [FILE_NODE_LOCATION_TYPE.OBJECT_ATTACHMENTS]: 'eim/process/workflow/save-file-attachments',
  [FILE_NODE_LOCATION_TYPE.FILE_UPLOAD_FIELD]: 'eim/process/workflow/save-file-file-upload-field',
  [FILE_NODE_LOCATION_TYPE.INTEGRATION_BUCKET]: 'eim/process/workflow/save-file-external',
  [FILE_NODE_LOCATION_TYPE.S3_BUCKET]: 'dummy',
  [FILE_NODE_LOCATION_TYPE.SFTP]: 'dummy',
};

// --------Retrieve file node---------
export const retrieveFileLocationProcessTips: { [key in FILE_NODE_LOCATION_TYPE]: string } = {
  [FILE_NODE_LOCATION_TYPE.DOCUMENT_FOLDER]: 'dummy',
  [FILE_NODE_LOCATION_TYPE.OBJECT_ATTACHMENTS]: 'dummy',
  [FILE_NODE_LOCATION_TYPE.FILE_UPLOAD_FIELD]: 'dummy',
  [FILE_NODE_LOCATION_TYPE.INTEGRATION_BUCKET]: 'eim/process/workflow/retrieve-file-external',
  [FILE_NODE_LOCATION_TYPE.S3_BUCKET]: 'eim/process/workflow/retrieve-file-external',
  [FILE_NODE_LOCATION_TYPE.SFTP]: 'eim/process/workflow/retrieve-file-external',
};
