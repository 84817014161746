/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./side-sheet-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/spinner/spinner.directive";
import * as i3 from "ng2-dragula";
import * as i4 from "@angular/common";
import * as i5 from "./side-sheet-list.component";
var styles_SideSheetListComponent = [i0.styles];
var RenderType_SideSheetListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SideSheetListComponent, data: {} });
export { RenderType_SideSheetListComponent as RenderType_SideSheetListComponent };
function View_SideSheetListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "btn btn-secondary m-3"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onShowMore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Show more"]))], null, null); }
export function View_SideSheetListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "d-flex flex-column"]], [[4, "minHeight", null]], null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.SpinnerDirective, [i1.Renderer2, i1.ElementRef], { showSpinner: [0, "showSpinner"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "flex-grow-1"], ["style", "min-height:50px;"]], null, [[null, "dragulaModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dragulaModelChange" === en)) {
        var pd_0 = ((_co.draggableModel = ((_co.mode === _co.SideSheetListModes.DRAG) ? $event : null)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i3.DragulaDirective, [i1.ElementRef, i3.DragulaService], { dragula: [0, "dragula"], dragulaModel: [1, "dragulaModel"] }, { dragulaModelChange: "dragulaModelChange" }), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideSheetListComponent_1)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.loadingState === "in-progress"); _ck(_v, 1, 0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "", ((_co.mode === _co.SideSheetListModes.DRAG) ? _co.dragGroupName : null), ""); var currVal_3 = ((_co.mode === _co.SideSheetListModes.DRAG) ? _co.draggableModel : null); _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = _co.showMoreButton; _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = "60px"; _ck(_v, 0, 0, currVal_0); }); }
export function View_SideSheetListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-side-sheet-list", [], null, null, null, View_SideSheetListComponent_0, RenderType_SideSheetListComponent)), i1.ɵdid(1, 704512, null, 1, i5.SideSheetListComponent, [i3.DragulaService], null, null), i1.ɵqud(603979776, 1, { initItems: 1 })], null, null); }
var SideSheetListComponentNgFactory = i1.ɵccf("app-side-sheet-list", i5.SideSheetListComponent, View_SideSheetListComponent_Host_0, { mode: "mode", showMoreButton: "showMoreButton", emitChangeEventOnLoad: "emitChangeEventOnLoad", selected: "selected", requiredSingleSelect: "requiredSingleSelect", loadingState: "loadingState", draggableModel: "draggableModel" }, { selectedChange: "selectedChange", showMore: "showMore", draggableModelChange: "draggableModelChange" }, ["*"]);
export { SideSheetListComponentNgFactory as SideSheetListComponentNgFactory };
