/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navigation-side-sheet.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../side-sheet-list-item/side-sheet-list-item.component.ngfactory";
import * as i3 from "../../side-sheet-list-item/side-sheet-list-item.component";
import * as i4 from "../../side-sheet-section/side-sheet-section.component.ngfactory";
import * as i5 from "../../side-sheet-section/side-sheet-section.component";
import * as i6 from "../../side-sheet-list/side-sheet-list.component.ngfactory";
import * as i7 from "../../side-sheet-list/side-sheet-list.component";
import * as i8 from "ng2-dragula";
import * as i9 from "@angular/common";
import * as i10 from "../../side-sheet-header/side-sheet-header.component.ngfactory";
import * as i11 from "../../side-sheet-header/side-sheet-header.component";
import * as i12 from "../../side-sheet.service";
import * as i13 from "./navigation-side-sheet.component";
var styles_NavigationSideSheetComponent = [i0.styles];
var RenderType_NavigationSideSheetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavigationSideSheetComponent, data: {} });
export { RenderType_NavigationSideSheetComponent as RenderType_NavigationSideSheetComponent };
function View_NavigationSideSheetComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-side-sheet-list-item", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectItem.next(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SideSheetListItemComponent_0, i2.RenderType_SideSheetListItemComponent)), i1.ɵdid(1, 114688, [[1, 4]], 0, i3.SideSheetListItemComponent, [i1.ChangeDetectorRef], { hasChevron: [0, "hasChevron"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_0 = true; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.label; _ck(_v, 3, 0, currVal_1); }); }
function View_NavigationSideSheetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "app-side-sheet-section", [], null, null, null, i4.View_SideSheetSectionComponent_0, i4.RenderType_SideSheetSectionComponent)), i1.ɵdid(1, 49152, null, 0, i5.SideSheetSectionComponent, [], { showBorder: [0, "showBorder"], showHeader: [1, "showHeader"] }, null), (_l()(), i1.ɵeld(2, 0, null, 3, 4, "app-side-sheet-list", [], null, null, null, i6.View_SideSheetListComponent_0, i6.RenderType_SideSheetListComponent)), i1.ɵdid(3, 704512, null, 1, i7.SideSheetListComponent, [i8.DragulaService], null, null), i1.ɵqud(603979776, 1, { initItems: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_NavigationSideSheetComponent_2)), i1.ɵdid(6, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = false; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.list; _ck(_v, 6, 0, currVal_2); }, null); }
function View_NavigationSideSheetComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "app-side-sheet-list-item", [], null, null, null, i2.View_SideSheetListItemComponent_0, i2.RenderType_SideSheetListItemComponent)), i1.ɵdid(1, 114688, [[2, 4]], 0, i3.SideSheetListItemComponent, [i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 10, "div", [["class", "d-flex justify-content-between align-items-center"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectItem.next(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "span", [["class", "badge badge-pill"]], null, null, null, null, null)), i1.ɵprd(512, null, i9.ɵNgClassImpl, i9.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i9.NgClass, [i9.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { "badge-success": 0 }), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "ml-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "h1 m-0 ml-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "span", [["class", "mdi mdi-chevron-right"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = "badge badge-pill"; var currVal_1 = _ck(_v, 7, 0, ((_co.by === "Relative") && _co.formula)); _ck(_v, 6, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (((_co.by === "Relative") && _co.formula) ? 1 : 0); _ck(_v, 8, 0, currVal_2); var currVal_3 = _v.context.$implicit.label; _ck(_v, 10, 0, currVal_3); }); }
function View_NavigationSideSheetComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-side-sheet-list", [], null, null, null, i6.View_SideSheetListComponent_0, i6.RenderType_SideSheetListComponent)), i1.ɵdid(1, 704512, null, 1, i7.SideSheetListComponent, [i8.DragulaService], null, null), i1.ɵqud(603979776, 2, { initItems: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_NavigationSideSheetComponent_4)), i1.ɵdid(4, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.list; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_NavigationSideSheetComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-side-sheet-header", [], null, null, null, i10.View_SideSheetHeaderComponent_0, i10.RenderType_SideSheetHeaderComponent)), i1.ɵdid(1, 245760, null, 0, i11.SideSheetHeaderComponent, [i12.SideSheetService], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationSideSheetComponent_1)), i1.ɵdid(5, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["showBadgeNumber", 2]], null, 0, null, View_NavigationSideSheetComponent_3))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_1 = !_co.showBadge; var currVal_2 = i1.ɵnov(_v, 6); _ck(_v, 5, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); }); }
export function View_NavigationSideSheetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navigation-side-sheet", [], null, null, null, View_NavigationSideSheetComponent_0, RenderType_NavigationSideSheetComponent)), i1.ɵdid(1, 180224, null, 0, i13.NavigationSideSheetComponent, [], null, null)], null, null); }
var NavigationSideSheetComponentNgFactory = i1.ɵccf("app-navigation-side-sheet", i13.NavigationSideSheetComponent, View_NavigationSideSheetComponent_Host_0, {}, {}, []);
export { NavigationSideSheetComponentNgFactory as NavigationSideSheetComponentNgFactory };
