import { EventEmitter, OnInit } from '@angular/core';
import { get } from 'lodash';
var FilterAndAddComponent = /** @class */ (function () {
    function FilterAndAddComponent() {
        this._show = true;
        this.filterTextChange = new EventEmitter();
        this.add = new EventEmitter();
    }
    Object.defineProperty(FilterAndAddComponent.prototype, "showAddButton", {
        set: function (show) {
            this._show = show;
            this.setShowAdd();
        },
        enumerable: true,
        configurable: true
    });
    FilterAndAddComponent.prototype.ngOnInit = function () {
        this.setShowAdd();
    };
    FilterAndAddComponent.prototype.setShowAdd = function () {
        this.showAdd = this._show && this.addButtonConnected();
    };
    FilterAndAddComponent.prototype.addButtonConnected = function () {
        // add button is connected if it has subscribers
        var length = get(this, 'add.observers.length', 0);
        return length > 0;
    };
    return FilterAndAddComponent;
}());
export { FilterAndAddComponent };
