import * as tslib_1 from "tslib";
import { OnDestroy, NgZone } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./inactivity-countdown-provider";
import * as i2 from "../../data/session-manager.service";
import * as i3 from "../../shell/services/toast.service";
import * as i4 from "../auth.service";
import * as i5 from "../../util/logger.service";
var InactivityLogoutService = /** @class */ (function () {
    function InactivityLogoutService(inactivityCountdownTimer, sessionInfo, toastService, authService, loggerService, ngZone) {
        this.inactivityCountdownTimer = inactivityCountdownTimer;
        this.sessionInfo = sessionInfo;
        this.toastService = toastService;
        this.authService = authService;
        this.loggerService = loggerService;
        this.ngZone = ngZone;
        this.destroy$ = new Subject();
    }
    InactivityLogoutService.prototype.init = function () {
        var _this = this;
        var inactivityConfigBase = {
            startCountDownTimerAt: 1000 * 60,
            timeoutCallback: this.timeOut.bind(this),
            countDownCallback: this.countDown.bind(this),
            countDownCancelledCallback: this.cancelCountDown.bind(this),
            throttleDuration: 30 * 1000 // 30s
        };
        this.sessionInfo.getSessionInfo$()
            .pipe(takeUntil(this.destroy$))
            .subscribe(function (_a) {
            var token = _a.token, inactivityTimeout = _a.inactivityTimeout;
            if (!token && _this.inactivityCountdownTimer.started) {
                _this.loggerService.info("[inactivity-logout-service] stopping inactivity logout timer, no session to logout from.");
                // needed for protractor please see README.md for more info
                _this.ngZone.runOutsideAngular(function () {
                    _this.inactivityCountdownTimer.stop();
                });
                return;
            }
            if (!inactivityTimeout && _this.inactivityCountdownTimer.started) {
                // tslint:disable-next-line:max-line-length
                _this.loggerService.info("[inactivity-logout-service] stopping inactivity logout timer, 'Session inactivity timeout' set to zero.");
                // needed for protractor please see README.md for more info
                _this.ngZone.runOutsideAngular(function () {
                    _this.inactivityCountdownTimer.stop();
                });
                return;
            }
            if (token && inactivityTimeout) {
                var idleTimeoutTime_1 = inactivityTimeout * 1000;
                // tslint:disable-next-line:max-line-length
                var logMessage = "[inactivity-logout-service] starting the inactivity logout timer, with a value from the session token of: " + inactivityTimeout / 60 + " minutes.";
                _this.loggerService.info(logMessage);
                // needed for protractor please see README.md for more info
                _this.ngZone.runOutsideAngular(function () {
                    _this.inactivityCountdownTimer.setup(tslib_1.__assign({}, inactivityConfigBase, { idleTimeoutTime: idleTimeoutTime_1 }));
                    _this.inactivityCountdownTimer.start();
                });
            }
        });
    };
    InactivityLogoutService.prototype.timeOut = function () {
        this.authService.signOut();
    };
    InactivityLogoutService.prototype.countDown = function (seconds_left) {
        var message = "Your session will expire in " + seconds_left + " seconds. Please click anywhere to keep working.";
        if (!this.notification) {
            this.notification = this.toastService.showWarningToast(message, { timeOut: 0 });
        }
    };
    InactivityLogoutService.prototype.cancelCountDown = function () {
        this.toastService.remove(this.notification.id);
        this.notification = null;
        this.toastService.showSuccessToast("We have extended your session.");
    };
    InactivityLogoutService.prototype.ngOnDestroy = function () {
        this.inactivityCountdownTimer.cleanup();
        this.destroy$.next();
        this.destroy$.complete();
    };
    InactivityLogoutService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InactivityLogoutService_Factory() { return new InactivityLogoutService(i0.ɵɵinject(i1.INACTIVITY_COUNTDOWN_TIMER), i0.ɵɵinject(i2.SessionManagerService), i0.ɵɵinject(i3.ToastService), i0.ɵɵinject(i4.AuthService), i0.ɵɵinject(i5.LoggerService), i0.ɵɵinject(i0.NgZone)); }, token: InactivityLogoutService, providedIn: "root" });
    return InactivityLogoutService;
}());
export { InactivityLogoutService };
