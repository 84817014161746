import { DEBUG_LEVEL } from './app/util/debugLevels';

export const debugConfig = Object.freeze({
  debugLevel: DEBUG_LEVEL.DEBUG,
  api: {
    inboundBatchMonitor: true,
    outboundBatchMonitor: true
  },
  security: {
    sessionChanged: true,
    tokenChanged: true
  },
  query: {
    watch: {
      watchProgress: false
    }
  }
});
