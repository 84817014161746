import { FormlyFieldConfig } from '@ngx-formly/core';

export const EDIT_LIST_STANDARD_FORM_FIELD_GROUP: FormlyFieldConfig = {
  wrappers: ['side-sheet-section-wrapper'],
  fieldGroup: [
    {
      key: 'name',
      type: 'input',
      templateOptions: {
        label: 'Name',
        placeholder: 'Enter a name',
        type: 'text',
        required: true
      }
    },
    {
      key: 'description',
      type: 'multi-line-text',
      templateOptions: {
        label: 'Description',
        placeholder: 'Enter a description'
      }
    },
  ]
};

export const EDIT_LIST_GOLDMASTER_FORM_FIELDS: FormlyFieldConfig[] = [{
  key: 'isUserModifiable',
  type: 'checkbox',
  templateOptions: {
    label: 'Is user-modifiable'
  }
}];
