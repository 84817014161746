import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterAndAddComponent } from './filter-and-add.component';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule
  ],
  declarations: [FilterAndAddComponent],
  exports: [FilterAndAddComponent]
})
export class FilterAndAddModule { }
