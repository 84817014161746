import { FieldType } from '@ngx-formly/core';
import { Component, OnInit } from '@angular/core';
import { get } from 'lodash';

import { Tip } from '../../../data/models/types';
import { ContextTypeFieldOptions, IContextTypeFieldOption } from '../../context-type-field-option-utils/context-type-field-option-utils';

@Component({
  selector: 'app-list-items-select',
  template: `
    <ng-container *ngIf="single; else multi">
      <app-list-item-single-select-field
          [formControl]="formControl"
          [label]="field.templateOptions.label"
          [listTip]="listTip"
          [placeholder]="field.templateOptions.placeholder"
          [readonly]="to.readonly"
          [required]="to.required"
          [calculated]="!!fieldOption?.calculation"
          (calculate)="calculate()">
      </app-list-item-single-select-field>
    </ng-container>
    <ng-template #multi>
      <app-list-item-multi-select-field
          [formControl]="formControl"
          [label]="field.templateOptions.label"
          [listTip]="listTip"
          [placeholder]="field.templateOptions.placeholder"
          [readonly]="to.readonly"
          [required]="to.required"
          [calculated]="!!fieldOption?.calculation"
          (calculate)="calculate()">
      </app-list-item-multi-select-field>
    </ng-template>
  `
})
export class FormlyFieldListItemsSelectComponent extends FieldType implements OnInit {
  fieldOption: IContextTypeFieldOption;

  single = true;

  listTip: null | string;

  ngOnInit(): void {
    const fieldTip: Tip = get(this, 'field.templateOptions.contextTypeTip', null);
    const fieldOptions: ContextTypeFieldOptions = get(this, 'formState.contextTypeOptions', null);

    this.fieldOption = fieldTip && fieldOptions ? fieldOptions.values.get(fieldTip) : null;
    this.single = !this.fieldOption || this.fieldOption.maxcount === 1;
    this.listTip = this.fieldOption && this.fieldOption.listTip ? this.fieldOption.listTip : null;
  }

  calculate() {
    this.formState.calculation$.next({
      fieldTip: this.field.key,
      calculationFormula: this.fieldOption.calculation,
      calculationNoValueAsZero: !!this.fieldOption.calculationNoValueAsZero
    });
  }
}
