import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { get } from 'lodash';
var FormlyFieldFileUploadComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FormlyFieldFileUploadComponent, _super);
    function FormlyFieldFileUploadComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.multi = false;
        return _this;
    }
    FormlyFieldFileUploadComponent.prototype.ngOnInit = function () {
        this.contextTypeOptions = get(this, 'formState.contextTypeOptions', null);
        this.securityPolicyTip = get(this, 'formState.contextSecurityPolicyTip', null);
        this.setParams();
    };
    FormlyFieldFileUploadComponent.prototype.setParams = function () {
        var contextTypeTip = get(this, 'field.templateOptions.contextTypeTip', null);
        var contextTypeOption = contextTypeTip ? this.contextTypeOptions.values.get(contextTypeTip) : null;
        this.multi = !!contextTypeOption && contextTypeOption.maxcount !== 1;
    };
    return FormlyFieldFileUploadComponent;
}(FieldType));
export { FormlyFieldFileUploadComponent };
