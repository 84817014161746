import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-assign-to-formly',
  template: `
    <app-assign-to-field
      [formControl]="formControl"
      [showUnAssignButton]="to.showUnAssignButton"
    ></app-assign-to-field>
  `,
})
export class FormlyFieldAssignToComponent extends FieldType {

}
