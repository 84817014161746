import { getI18nValue, II18nValues } from '../i18n/i18n-value/i18n-value';
import { IObjectScheme, DataTypes } from '../data/models/scheme';
import { IObject } from '../data/models/object';
import { II18n } from '../data/models/types';

export { IListItem, IListItemWithStringLabel, IList, listScheme, getListItemWithStringLabel };

interface IListItem {
  label: string | II18nValues;
  value: string;
  color?: string;
  icon?: string;
  items?: IListItem[];
}

interface IListItemWithStringLabel extends IListItem {
  label: string;
}

interface IList extends IObject {
  name: string;
  description?: string;
  items?: IListItem[];
  values?: string[];
  labels?: II18n[];
  isUserModifiable?: boolean;
}

const listScheme: IObjectScheme = {
  'app/list:name': { type: DataTypes.i18n },
  'app/list:description': { type: DataTypes.i18n },
  'app/list:items': { type: DataTypes.jsonArray },
  'app/list:values': { type: DataTypes.stringArray },
  'app/list:labels': { type: DataTypes.i18nObject },
  'app/list:is-user-modifiable': { type: DataTypes.boolean }
};

function getListItemWithStringLabel(listItem: IListItem): IListItemWithStringLabel {
  return { ...listItem, ...{ label: getI18nValue(listItem.label) } };
}
