export const GOLDMASTER_FEATURE_FLAG_NAME = 'feature-goldmaster';

export const CORE_MODULE_TIP = 'app/core-module';

export const CUSTOM_SOLUTION_TIP = 'app/solution/custom';

export const MODULE_TALLY_FORMULA = `
LOOKUP(
  JSON_BUILD("{
    \\"type\\": \\"object\\",
    \\"property\\": {
      \\"tip\\": {
        \\"type\\": \\"string\\",
        \\"formula\\": \\"TIP()\\"
      },
      \\"type\\": {
        \\"type\\": \\"string\\",
        \\"formula\\": \\"TYPE(TIP())\\"
      }
    }
  }"),
  AND(
    ISTYPE(
      ARRAY(
        "app/chart",
        "app/dashboard",
        "app/email/outbound-template",
        "app/form",
        "app/list",
        "app/map",
        "app/map/layer",
        "app/map/source",
        "app/object-type",
        "app/plan",
        "app/sequence",
        "app/sms/outbound-template",
        "app/solution",
        "app/workflow",
        "app/workflow-panel",
        "branch",
        "query",
        "security/label",
        "security/policy",
        "app/risk-matrix",
        "app/print-template",
        "app/print-template-usage"
      )
    ),
    INARRAY(
      ARRAY(
        FIELD("app/module:branch", VAR("Module")),
        FIELD("app/module:labels", VAR("Module")),
        FIELD("app/module:instance-policy", VAR("Module")),
        FIELD("app/module:type-policy", VAR("Module")),
        FIELD("app/module:workflow-process-policy", VAR("Module")),
        FIELD("app/module:object-types", VAR("Module")),
        FIELD("app/module:lists", VAR("Module")),
        FIELD("app/module:queries", VAR("Module")),
        FIELD("app/module:dashboards", VAR("Module")),
        FIELD("app/module:workflows", VAR("Module")),
        FIELD("app/module:workflow-panels", VAR("Module")),
        FIELD("app/module:forms", VAR("Module")),
        FIELD("app/module:plan-templates", VAR("Module")),
        FIELD("app/module:message-email-templates", VAR("Module")),
        FIELD("app/module:message-sms-templates", VAR("Module")),
        FIELD("app/module:message-voice-templates", VAR("Module"))
        FIELD("app/module:charts", VAR("Module")),
        FIELD("app/module:map-data-sources", VAR("Module")),
        FIELD("app/module:map-layers", VAR("Module")),
        FIELD("app/module:maps", VAR("Module")),
        FIELD("app/module:sequences", VAR("Module")),
        FIELD("app/module:risk-matrices", VAR("Module")),
        FIELD("app/module:print-templates", VAR("Module")),
        FIELD("app/module:print-template-usages", VAR("Module"))
      ),
      TIP()
    )
  )
)
`;

export const MODULE_REFERENCE_FORMULA = `TITLE(
        REFERENCES(
          SWITCH(
            TYPE(TIP()),
            ARRAY(
              "app/object-type", "app/module:object-types",
              "app/workflow", "app/module:workflows",
              "app/workflow-panel", "app/module:workflow-panels",
              "app/form", "app/module:forms",
              "app/list", "app/module:lists",
              "app/dashboard",  "app/module:dashboards",
              "app/chart", "app/module:charts",
              "query", "app/module:queries",
              "app/map/source", "app/module:map-data-sources",
              "app/map/layer", "app/module:map-layers",
              "app/map", "app/module:maps",
              "app/plan", "app/module:plan-templates",
              "app/email/outbound-template", "app/module:message-email-templates",
              "app/sms/outbound-template", "app/module:message-sms-templates",
              'app/voice-message/outbound-template': 'app/module:message-voice-templates',
              "app/sequence", "app/module:sequences",
              'app/risk-matrix', 'app/module:risk-matrices',
              'app/print-template', 'app/module:print-templates',
              'app/print-template-usage', 'app/module:print-template-usages'
            )
          )
        )
      )`;

export type configItems =
  'app/object-type'
  | 'app/workflow'
  | 'app/workflow-panel'
  | 'app/form'
  | 'app/list'
  | 'app/dashboard'
  | 'app/chart'
  | 'query'
  | 'app/map/source'
  | 'app/map/layer'
  | 'app/map'
  | 'app/plan'
  | 'app/email/outbound-template'
  | 'app/sms/outbound-template'
  | 'app/voice-message/outbound-template'
  | 'app/sequence'
  | 'app/risk-matrix'
  | 'app/print-template'
  | 'app/print-template-usage';

type IConfigItemsToModuleField = {
  [key in configItems]: string;
};


const TYPE_MODULE_FIELD_MAPPING: IConfigItemsToModuleField = {
  'app/object-type': 'app/module:object-types',
  'app/workflow': 'app/module:workflows',
  'app/workflow-panel': 'app/module:workflow-panels',
  'app/form': 'app/module:forms',
  'app/list': 'app/module:lists',
  'app/dashboard': 'app/module:dashboards',
  'app/chart': 'app/module:charts',
  'query': 'app/module:queries',
  'app/map/source': 'app/module:map-data-sources',
  'app/map/layer': 'app/module:map-layers',
  'app/map': 'app/module:maps',
  'app/plan': 'app/module:plan-templates',
  'app/email/outbound-template': 'app/module:message-email-templates',
  'app/sms/outbound-template': 'app/module:message-sms-templates',
  'app/voice-message/outbound-template': 'app/module:message-voice-templates',
  'app/sequence': 'app/module:sequences',
  'app/risk-matrix': 'app/module:risk-matrices',
  'app/print-template': 'app/module:print-templates',
  'app/print-template-usage': 'app/module:print-template-usages',
};

export const getModuleTitleFormula = (typeTip: configItems) => `TITLE(REFERENCES("${TYPE_MODULE_FIELD_MAPPING[typeTip]}"))`;
