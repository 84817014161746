import { Component, OnInit } from '@angular/core';
import { LoadingState } from '../../../../shared/constants';
import { SideSheetService } from '../../../../side-sheet/side-sheet.service';
import { ToastService } from '../../../../shell/services/toast.service';
import { first, map, switchMap } from 'rxjs/operators';
import { ListDesignerService } from '../../list-designer.service';
import { IList } from '../../../../models/list';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import {
  ModalConfirmDeletionComponent,
  modalConfirmDeletionOptions
} from '../../../../shared/modals/modal-confirm-deletion/modal-confirm-deletion.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EDIT_LIST_STANDARD_FORM_FIELD_GROUP, EDIT_LIST_GOLDMASTER_FORM_FIELDS } from './list-edit-form';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FeatureFlagService } from '../../../../util/feature-flag/feature-flag.service';
import { cloneDeep, head } from 'lodash';
import { Observable } from 'rxjs';
import { GOLDMASTER_FEATURE_FLAG_NAME } from '../../../modules/module.constant';
import { Tip } from '../../../../data/models/types';
import { ModuleValidationError } from '../../../../data/errors/client/ModuleValidationError';

@Component({
  selector: 'app-list-edit-side-sheet',
  templateUrl: './list-edit-side-sheet.component.html',
  styleUrls: ['./list-edit-side-sheet.component.scss']
})
export class ListEditSideSheetComponent implements OnInit {
  moduleTip: Tip;
  fields: FormlyFieldConfig[] = [];
  model: {
    name: string,
    description: string,
    isUserModifiable?: boolean
  };

  list: IList;
  listForm: FormGroup;

  loadingState: LoadingState;
  loadingStates = LoadingState;
  errorMessage: string;

  isGoldmaster$: Observable<boolean> = this
    .featureFlagService
    .getFeatureFlag$(GOLDMASTER_FEATURE_FLAG_NAME)
    .pipe(first());

  constructor(
    private sideSheetService: SideSheetService,
    private toastService: ToastService,
    private listService: ListDesignerService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private featureFlagService: FeatureFlagService
  ) { }

  setProps({ list }: { list: IList }) {
    this.list = list;
  }

  ngOnInit() {
    this.loadingState = LoadingState.inProgress;
    this
      .isGoldmaster$
      .subscribe(this.initForm.bind(this));
  }

  private initForm(isGoldmaster: boolean) {
    const fieldGroupsToAdd = cloneDeep(EDIT_LIST_STANDARD_FORM_FIELD_GROUP);

    if (isGoldmaster) {
      const goldmasterFieldGroupToAdd = head(cloneDeep(EDIT_LIST_GOLDMASTER_FORM_FIELDS));
      goldmasterFieldGroupToAdd.templateOptions.readonly = this.list.isUserModifiable || false;
      fieldGroupsToAdd.fieldGroup.push(goldmasterFieldGroupToAdd);
    }

    this.model = {
      name: this.list.name,
      description: this.list.description || null,
      isUserModifiable: this.list.isUserModifiable || false
    };

    const formControls = {
      name: [this.model.name, Validators.required],
      description: [this.model.description],
      isUserModifiable: [this.model.isUserModifiable]
    };

    this.listForm = this.fb.group(formControls);
    this.fields = [fieldGroupsToAdd];

    this.loadingState = LoadingState.loaded;
  }

  updateList() {
    this.loadingState = LoadingState.inProgress;
    this.isGoldmaster$.pipe(
      map((isGoldmaster: boolean) => {
        this.list.name = this.model.name;
        this.list.description = this.model.description;

        if (isGoldmaster) {
          this.list.isUserModifiable = this.model.isUserModifiable;
        }

        return this.list;
      }),
      switchMap((list: IList) => this.listService.updateList(list, this.moduleTip)),
      first()
    ).subscribe(
      () => {
        this.toastService.showSuccessToast('Saved list successfully');
        this.sideSheetService.pop();
      },
      (error) => {
        error instanceof ModuleValidationError
          ? this.toastService.showErrorToast(error.message)
          : this.errorMessage = 'Unable to save list at this time';
        this.loadingState = LoadingState.failed;
      }
    );
  }

  showDeleteModal() {
    const modalRef = this.modalService.open(ModalConfirmDeletionComponent, modalConfirmDeletionOptions);
    const modalInstance = modalRef.componentInstance as ModalConfirmDeletionComponent;
    modalInstance.objectType = 'list';
    modalRef.result
      .then(deleteModule => deleteModule ? this.deleteList() : null)
      .catch(() => {});
  }

  deleteList() {
    this.loadingState = LoadingState.inProgress;
    this.listService.deleteList(this.list.$tip, this.moduleTip).pipe(
      first()
    ).subscribe(
      () => {
        this.sideSheetService.clear();
        this.toastService.showSuccessToast('Deleted list successfully');
      },
      (error) => {
        error instanceof ModuleValidationError
          ? this.toastService.showErrorToast(error.message)
          : this.errorMessage = 'Unable to delete list at this time';
        this.loadingState = LoadingState.failed;
      }
    );
  }
}
