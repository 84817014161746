import { IObjectScheme, DataTypes } from '../data/models/scheme';
import { IObject } from '../data/models/object';
import { checkListItemScheme, ICheckListItem } from './checklist-item';
import { Tip } from '../data/models/types';

export { ICheckList, ICheckListSummary, checkListScheme, ChecklistsWidgetModes };

interface ICheckList extends IObject {
  name: string;
  items: ICheckListItem[];
  context?: Tip;
  subtitle?: string;
  modifiedDate?: number;
}

interface ICheckListSummary extends IObject {
  name: string;
  context?: Tip;
}

const checkListScheme: IObjectScheme = {
  'app/checklist:name': { type: DataTypes.i18n },
  'app/checklist:items': { type: DataTypes.objectArray, scheme: checkListItemScheme, mutable: true },
  'app/checklist:context': { type: DataTypes.string }
};

enum ChecklistsWidgetModes {
  // this mode indicates - display all checklsits in the system with items assigned to current user
  GLOBAL_ASSIGNEE = 'global-assignee',
  // this mode indicates - display checklsits linked to the context object
  // (including checklists in a plan that is linked to this context object) for any assignee
  CONTEXT_ANY = 'context-any'
}

