import {
  coerceToNumberIfPossible,
  isEmptyString
} from './number-format-utils';
import { escapeRegExp } from 'lodash';
import Big from 'big.js';
import { Calculations, IFormattingFunctionNumber, IFormattingFunctionString } from './number-format-types';

export function addSuffix({numberString, format}: IFormattingFunctionString): IFormattingFunctionString {
  if (typeof format.suffix !== 'string') {
    return {
      numberString,
      format
    };
  }
  return {
    numberString: `${numberString}${format.suffix}`,
    format,
  };
}

export function addPrefix({numberString, format}: IFormattingFunctionString): IFormattingFunctionString {
  if (typeof format.prefix !== 'string') {
    return {
      numberString,
      format,
    };
  }
  return {
    numberString: `${format.prefix}${numberString}`,
    format,
  };
}

export function separateThousands({numberString, format}: IFormattingFunctionString): IFormattingFunctionString {
  if (typeof format.thousandsSeparator !== 'string') {
    return {
      numberString,
      format,
    };
  }

  const decimalSeparator = format.decimalSeparator || '.';
  const [integerString, decimalString] = numberString.split(decimalSeparator);

  const thousandsRegex = new RegExp(`(\\d)(?=((\\d){3})+(?!\\d))`, 'g');
  const updatedInteger = integerString.replace(thousandsRegex, `$1${format.thousandsSeparator}`);

  return {
    numberString: decimalString ? `${updatedInteger}${decimalSeparator}${decimalString}` : updatedInteger,
    format,
  };
}

const GLOBAL_DECIMAL_REGEX = new RegExp(escapeRegExp('.'), 'g'); // select '.' \.
export function replaceDecimalSeparator({numberString, format}: IFormattingFunctionString): IFormattingFunctionString {
  if (typeof format.decimalSeparator !== 'string' || isEmptyString(format.decimalSeparator)) {
    return {
      numberString,
      format
    };
  }

  return {
    numberString: numberString.replace(GLOBAL_DECIMAL_REGEX, format.decimalSeparator),
    format
  };
}

export function formatDecimalPlaces({number, format}: IFormattingFunctionNumber): IFormattingFunctionString {
  const decimalPlaces = coerceToNumberIfPossible(format.decimalPlaces);
  if (!isNaN(decimalPlaces)) {
    // javascript does not round properly with .toFixed us bigNum
    const bigNum = new Big(number);
    const fixed = bigNum.toFixed(decimalPlaces);
    return {
      numberString: fixed.toString(),
      format
    };
  }
  return {
    numberString: number.toString(10),
    format
  };
}

export function formatCalculation({number, format}: IFormattingFunctionNumber): IFormattingFunctionNumber {
  if (format.calculations === Calculations.percent) {
    // need bigNum as JS does not like floats
    const bigNum: Big = new Big(number);
    return {
      number: parseFloat(bigNum.times(100).toString()),
      format
    };
  }

  return {number, format};
}
