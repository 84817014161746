import { Component, OnInit, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { cloneDeep, get } from 'lodash';

import { ITableParams } from '../../../shared/table/table-widget.service';
import { TableComponent } from '../../../shared/table/table.component';

@Component({
  selector: 'app-table-content',
  styles: [`
      h3 {
          font-size: 1.3rem;
      }
      .table-outline {
        border: 1px solid #D6E0E4;
        border-radius: 6px;
        margin-bottom: 1.154rem;
      }
      .table-outline > h3 {
        border-bottom: 1px solid #D6E0E4;
        padding: 0 10px;
        margin-top: 5px;
      }
  `],
  template: `
    <div *ngIf="to?.tableConfig?.objectTypeTip else placeholder" [class.table-outline]="tableComponent?.table?.total === 0">
      <h3>{{ to?.tableConfig?.title }}</h3>
      <app-table [tableParams]="tableParams"></app-table>
    </div>
    <ng-template #placeholder>
      <div class="table-outline">
        <h3 *ngIf="to?.tableConfig?.title">{{ to?.tableConfig?.title }}</h3>
        <app-empty-state
            label="Configure table"
            imgSrc="/assets/img/empty-states/no-table.svg"></app-empty-state>
      </div>
    </ng-template>
  `
})
export class FormlyFieldTableContentComponent extends FieldType implements OnInit {
  @ViewChild(TableComponent, { static: false }) tableComponent: TableComponent;
  tableParams: ITableParams;

  ngOnInit() {
    this.tableParams = cloneDeep(this.to.tableConfig);

    if (this.tableParams.limit) {
      this.tableParams.limit = +this.tableParams.limit;
    }

    this.tableParams.contextTip = get(this.options, 'formState.objectAndType.objectData.$tip', null);
    this.tableParams.sortSearchableFieldsOnly = true;
  }
}
