import * as tslib_1 from "tslib";
import { FieldType } from '@ngx-formly/core';
var FormlyFieldConditionalFieldSelectorComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FormlyFieldConditionalFieldSelectorComponent, _super);
    function FormlyFieldConditionalFieldSelectorComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return FormlyFieldConditionalFieldSelectorComponent;
}(FieldType));
export { FormlyFieldConditionalFieldSelectorComponent };
