/*
 * Strips HTML
 * Takes an input parameter HTML.
 *
 * remove all tags from a html string
 * and adding spaces before every closing tag for text to be displayed nicer
 * in case of multiple subsequent spaces on a web page they will be still displayed as one space
*/
import { HTML } from '../data/models/types';
import * as he from 'he';

export function stripHtml(value: string): string {
  if (typeof value === 'string') {
    return value.replace(/<\//g, ' \<\/').replace(/<(\s+>\s+|[^>])*>/gm, '').trim();
  }

  return value;
}

export function decodeHtmlEntities(value: HTML): HTML {
  for (const key in htmlEntitiesMap) {  // handling a special case .. seems like using the renderer to create a component replaces the whitespaces with a nonbreaking space
    const entity = htmlEntitiesMap[key];
    const regex = new RegExp(entity, 'g');
    value = value.replace(regex, key);
  }

  value = he.decode(value); // majority of cases are handled by this lib
  return value;
}

export function replaceHtmlTags(value: HTML): HTML {
  for (const key in htmlTags) {
    const replacement = htmlTags[key];
    const regex = new RegExp(key, 'g');
    value = value.replace(regex, replacement);
  }
  return value;
}

const htmlEntitiesMap = { // this list can be extended if more entities need to be handled - only use if necessary. most of the cases should be handled by the library
  ' ': '&nbsp;',
 // '&': '&amp;'    // always the last - iff required
};

const htmlTags = {
  '<br>': '',
  '</p>': '',
  '<p>': '\n',
  '</div>': '',
  '<div>': '\n'
};
