import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService, SignInStatus } from '../auth.service';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { LoggerService } from '../../util/logger.service';
import { AuthRegisterErrors } from '../accept-invite/auth-register-errors';
import { of } from 'rxjs';
import { Eno } from '../../data/models/Eno';

const TOO_MANY_ATTEMPTS = 'error/message/auth/too-many-auth-attempts';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup = new FormGroup({
    password: new FormControl('', Validators.required)
  });

  state: string;
  token: string;
  email: string;
  messageState: SignInStatus = null;

  constructor(
    private _authService: AuthService,
    private _route: ActivatedRoute,
    private _loggerService: LoggerService
  ) {}

  ngOnInit() {
    this.state = null;
    this.email = this._route.snapshot.queryParamMap.get('email');
    this.token = this._route.snapshot.queryParamMap.get('token');
  }

  onReset() {
    this.state = 'in-progress';
    this.messageState = null;

    this._authService.resetPassword(this.email, this.resetPasswordForm.value.password, this.token).pipe(
      switchMap(
        (errorEno: Eno) => {
          if (errorEno) {
            const resetError = errorEno.getFieldValues('error/message/tip');
            if (resetError.includes(AuthRegisterErrors.weakPassword)) {
              this.messageState = SignInStatus.WEAK_PASSWORD;
            } else if (resetError.includes(AuthRegisterErrors.invalidToken)) {
              this.messageState = SignInStatus.FAILURE;
            } else if (resetError.includes(TOO_MANY_ATTEMPTS)) {
              this.messageState = SignInStatus.ACCOUNT_LOCK;
            } else {
              this.messageState = SignInStatus.FAILURE;
            }
            return of(SignInStatus.FAILURE);
          }

          // If signIn is successful, JWT token will be changed and the application will be refreshed, which will take the user to home
          return this._authService.signIn(this.email, this.resetPasswordForm.value.password);
        }
      )
    ).subscribe(
      () => {
        this.state = null;
      },
      error => {
        this._loggerService.warn('Reset password - failure:', error);
        this.state = 'failure';
        this.messageState = SignInStatus.FAILURE;
      }
    );
  }
}
