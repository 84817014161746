import { Injectable } from '@angular/core';
import { IUpdateSecurityEditor } from '../node-editors/update-security-editor/update-security-editor.component';
import { CompositeNodeConverter, INodeAndLink } from './CompositeNodeConverter';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import { Parser, Stringify } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { isEmpty } from 'lodash';
import { createConnection, createForEachNode, createSetVarNode, createSwitchNode, createUpdateEnoNode } from './utils/create-node-utils';

const NS = 'UpdateSecurityNodeConverterService';

@Injectable({
  providedIn: 'root'
})
export class UpdateSecurityNodeConverterService extends CompositeNodeConverter {
  type = 'processnode/data/update-eno';
  processNodeType = 'processnodetype/data/update-eno';

  constructor() {
    super();
  }

  public convert(nodeData: IUpdateSecurityEditor): INodeAndLink {
    if (!this.isValid(nodeData)) {
      throw new InvalidWorkflowNodeDataError(`Please correct invalid data on update-security node [${ nodeData.fields.title }]`, nodeData);
    }

    const statusVarKey = 'Status-' + Math.random();
    const formula = Parser(nodeData.fields.formula);

    const setVarStatusHashNode = createSetVarNode(
      {
        [statusVarKey]: Stringify({
          name: 'IF',
          args: [
            { name: 'EQUALS', args: [formula, { name: 'ARRAY', args: [] }] },
            'failed',
            'done'
          ]
        })
      },
      nodeData.tip
    );

    const foreachWorkflowTipsNode = createForEachNode(formula, 'Tip to update');

    const doneConnectionForSetVarNode = createConnection('done', foreachWorkflowTipsNode);
    setVarStatusHashNode.connections.push(doneConnectionForSetVarNode);

    const updateEnoSecurityNode = createUpdateEnoNode({
      name: 'VAR',
      args: ['Tip to update']
    }, nodeData.fields.securityPolicy);

    const foreachConnectionForForeachNode = createConnection('foreach', updateEnoSecurityNode);
    foreachWorkflowTipsNode.connections.push(foreachConnectionForForeachNode);

    const finallyConnectionForUpdateEnoNode = createConnection('finally', foreachWorkflowTipsNode);
    updateEnoSecurityNode.connections.push(finallyConnectionForUpdateEnoNode);

    const setVarStatusHashFailed = createSetVarNode({
      [statusVarKey]: Stringify({
        name: 'ARRAY',
        args: ['failed']
      })
    });

    const failedConnectionForUpdateEnoNode = createConnection('failed', setVarStatusHashFailed);
    updateEnoSecurityNode.connections.push(failedConnectionForUpdateEnoNode);

    const switchStatusNode = createSwitchNode({ name: 'VAR', args: [statusVarKey] });

    const doneConnectionForForeachNode = createConnection('done', switchStatusNode);
    foreachWorkflowTipsNode.connections.push(doneConnectionForForeachNode);

    const nodesAndLink: INodeAndLink = {
      nodes: [
        setVarStatusHashNode,
        foreachWorkflowTipsNode,
        updateEnoSecurityNode,
        setVarStatusHashFailed,
        switchStatusNode,
      ],
      links: [
        doneConnectionForSetVarNode,
        foreachConnectionForForeachNode,
        finallyConnectionForUpdateEnoNode,
        failedConnectionForUpdateEnoNode,
        doneConnectionForForeachNode
      ]
    };

    this.populateOutgoingLinks(nodeData).forEach(link => {
      switchStatusNode.connections.push(link);
      nodesAndLink.links.push(link);
    });

    return nodesAndLink;
  }

  private isValid(nodeData: IUpdateSecurityEditor): boolean {
    return !isEmpty(nodeData.fields.title) && !isEmpty(nodeData.fields.formula) && !isEmpty(nodeData.fields.securityPolicy);
  }
}
