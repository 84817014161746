var _a, _b, _c, _d, _e, _f;
import { DataTypes } from '../data/models/scheme';
import { FIELD_DATA_TYPE } from './field';
import { get } from 'lodash';
export var queryAttributeScheme = {
    'query/attribute/label': { type: DataTypes.i18n },
    'query/attribute/formula': {}
};
export var queryFilterScheme = {
    'query/filter/label': { type: DataTypes.i18n },
    'query/filter/formula': {}
};
export var queryDimensionScheme = {
    'query/dimension/label': { type: DataTypes.i18n },
    'query/dimension/formula': {}
};
export var queryScheme = {
    'query/name': { type: DataTypes.i18n },
    'query/attributes': {
        type: DataTypes.objectArray,
        mutable: true,
        scheme: queryAttributeScheme
    },
    'query/filters': {
        type: DataTypes.objectArray,
        mutable: true,
        scheme: queryFilterScheme
    },
    'query/dimensions': {
        type: DataTypes.objectArray,
        mutable: true,
        scheme: queryDimensionScheme
    }
};
export var flatQueryScheme = {
    'query/name': { type: DataTypes.i18n },
    'query/attributes': {},
    'query/filters': {},
    'query/dimensions': {}
};
export var OPERATOR_TYPE;
(function (OPERATOR_TYPE) {
    OPERATOR_TYPE["EQUALS"] = "Equals";
    OPERATOR_TYPE["NOT_EQUALS"] = "Not equals";
    OPERATOR_TYPE["STARTS_WITH"] = "Starts with";
    OPERATOR_TYPE["NOT_STARTS_WITH"] = "Doesn't start with";
    OPERATOR_TYPE["ENDS_WITH"] = "Ends with";
    OPERATOR_TYPE["NOT_ENDS_WITH"] = "Doesn't end with";
    OPERATOR_TYPE["CONTAINS"] = "Contains";
    OPERATOR_TYPE["NOT_CONTAINS"] = "Doesn't contain";
    OPERATOR_TYPE["CONTAINS_ALL"] = "Contains all";
    OPERATOR_TYPE["NOT_CONTAINS_ALL"] = "Doesn't contain all";
    OPERATOR_TYPE["CONTAINS_ANY"] = "Contains any";
    OPERATOR_TYPE["NOT_CONTAINS_ANY"] = "Doesn't contain any";
    OPERATOR_TYPE["IS_EMPTY"] = "Is empty";
    OPERATOR_TYPE["NOT_IS_EMPTY"] = "Is not empty";
    OPERATOR_TYPE["IN"] = "In";
    OPERATOR_TYPE["NOT_IN"] = "Not in";
    OPERATOR_TYPE["LESS_THAN"] = "Less than";
    OPERATOR_TYPE["GREATER_THAN"] = "Greater than";
    OPERATOR_TYPE["LESS_THAN_EQUAL_TO"] = "Less than or equal to";
    OPERATOR_TYPE["GREATER_THAN_EQUAL_TO"] = "Greater than or equal to";
    OPERATOR_TYPE["INTERSECTS_WITH"] = "Intersects with";
    OPERATOR_TYPE["NOT_INTERSECTS_WITH"] = "Not intersects with";
    OPERATOR_TYPE["IS_OF_TYPE"] = "Is of type";
    OPERATOR_TYPE["NOT_IS_OF_TYPE"] = "Is not of type";
    OPERATOR_TYPE["WITHIN"] = "Within";
    OPERATOR_TYPE["NOT_WITHIN"] = "Not within";
    OPERATOR_TYPE["ASSIGNED_TO"] = "Is assigned to";
    OPERATOR_TYPE["NOT_ASSIGNED_TO"] = "Is not assigned to";
    OPERATOR_TYPE["CONTACT_IN_GROUP"] = "In a group";
    OPERATOR_TYPE["CONTACT_NOT_IN_GROUP"] = "Not in group";
})(OPERATOR_TYPE || (OPERATOR_TYPE = {}));
var functionMap = (_a = {},
    _a[FIELD_DATA_TYPE.geography] = (_b = {},
        _b[OPERATOR_TYPE.INTERSECTS_WITH] = 'openGeographyValueForm',
        _b[OPERATOR_TYPE.NOT_INTERSECTS_WITH] = 'openGeographyValueForm',
        _b['*'] = 'openGeographyValueForm',
        _b),
    _a[FIELD_DATA_TYPE.object] = {
        'app/person': (_c = {},
            _c[OPERATOR_TYPE.EQUALS] = 'someFunction' // TODO: for all supported types, if not found, will fall back to * rule
        ,
            _c),
        'app/personnel': (_d = {},
            _d['*'] = 'openAssignmentValueForm',
            _d),
        'app/assignment': (_e = {},
            _e['*'] = 'openAssignmentValueForm',
            _e),
        type: {
            '*': 'openTypeValueForm'
        },
        '*': (_f = {},
            _f[OPERATOR_TYPE.IS_OF_TYPE] = 'openTypeValueForm',
            _f[OPERATOR_TYPE.NOT_IS_OF_TYPE] = 'openTypeValueForm',
            _f)
    },
    _a.__listItem__ = 'openListItemValueForm',
    _a.__whitelist__ = 'openWhitelistValueForm',
    _a.__hierarchicalObject__ = 'openHierarchicalObjectForm',
    _a.__contactContainerObject__ = 'openContactInGroupValueForm',
    _a['*'] = 'openGenericEnterValueForm',
    _a);
export function getFunctionName(meta) {
    if (meta.isListItem) {
        return functionMap.__listItem__;
    }
    if (meta.whitelist && meta.whitelist.length) {
        return functionMap.__whitelist__;
    }
    if ((meta.typeRestrict === 'app/super-type/contact' ||
        (meta.implements && meta.implements.indexOf('app/super-type/contact') !== -1))
        && (meta.operator === OPERATOR_TYPE.CONTACT_IN_GROUP || meta.operator === OPERATOR_TYPE.CONTACT_NOT_IN_GROUP)) {
        return functionMap.__contactContainerObject__;
    }
    if (meta.isHierarchical && // open the HierarichalObjectChooserComponent depending LHS field type and operator
        (meta.operator !== OPERATOR_TYPE.IS_OF_TYPE && meta.operator !== OPERATOR_TYPE.NOT_IS_OF_TYPE)) {
        return functionMap.__hierarchicalObject__;
    }
    return get(functionMap, meta.fieldType + "['" + meta.typeRestrict + "']['" + meta.operator + "']", get(functionMap, meta.fieldType + "['" + meta.typeRestrict + "']['*']", get(functionMap, meta.fieldType + "['*']['" + meta.operator + "']", get(functionMap, meta.fieldType + "['*']['*']", get(functionMap, meta.fieldType + "['" + meta.operator + "']", meta.fieldType === 'object'
        ? functionMap['*']
        : get(functionMap, meta.fieldType + "['*']", functionMap['*']))))));
}
