import { OnDestroy, NgZone } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../data/session-manager.service";
import * as i2 from "../../shell/services/toast.service";
import * as i3 from "../auth.service";
import * as i4 from "../../util/logger.service";
import * as i5 from "../../dom-tokens";
var SessionExpiryAlertService = /** @class */ (function () {
    function SessionExpiryAlertService(sessionInfo, toastService, authService, loggerService, window, ngZone) {
        this.sessionInfo = sessionInfo;
        this.toastService = toastService;
        this.authService = authService;
        this.loggerService = loggerService;
        this.window = window;
        this.ngZone = ngZone;
        this.notification = null;
        this.notificationSubscription = null;
        this.destroy$ = new Subject();
        this.timerId = null;
    }
    SessionExpiryAlertService.prototype.init = function () {
        var _this = this;
        this.sessionInfo.getSessionInfo$()
            .pipe(takeUntil(this.destroy$))
            .subscribe(function (_a) {
            var token = _a.token, sessionExpiry = _a.sessionExpiry;
            _this.clearTimerAndMessage();
            if (token && sessionExpiry) {
                _this.startTimer(sessionExpiry);
            }
        });
    };
    SessionExpiryAlertService.prototype.alert = function () {
        var _this = this;
        var message = "\n      For security reasons, you will be forced to re-authenticate in 15 minutes.\n      Please save your work now, and click here to re-authenticate.\n    ";
        if (!this.notification) {
            this.notification = this.toastService.showWarningToast(message, { timeOut: 0 });
            this.notificationSubscription = this.notification.click.subscribe(function () {
                _this.authService.signOut();
            });
        }
    };
    SessionExpiryAlertService.prototype.startTimer = function (sessionExpiry) {
        var _this = this;
        // calculate timeout
        var fifteenMinutes = 15 * 60 * 1000; // 15 minutes
        var unixEpochTimeOut = sessionExpiry.valueOf() - fifteenMinutes;
        var now = Date.now();
        var timeoutIn = unixEpochTimeOut - now;
        this.loggerService.info("[session-expiry-alert-service] expiry alert scheduled for " + new Date(unixEpochTimeOut) + ".");
        // needed for protractor please see README.md for more info
        this.ngZone.runOutsideAngular(function () {
            // set timeout
            _this.timerId = _this.window
                .setTimeout(function () { return _this.alert(); }, timeoutIn);
        });
    };
    SessionExpiryAlertService.prototype.clearTimerAndMessage = function () {
        if (this.timerId) {
            this.window.clearTimeout(this.timerId);
            this.loggerService.info("[session-expiry-alert-service] expiry alert cleared");
            this.timerId = null;
        }
        if (this.notification) {
            this.toastService.remove(this.notification.id);
            this.notification = null;
        }
        if (this.notificationSubscription) {
            this.notificationSubscription.unsubscribe();
            this.notificationSubscription = null;
        }
    };
    SessionExpiryAlertService.prototype.ngOnDestroy = function () {
        this.clearTimerAndMessage();
        this.destroy$.next();
        this.destroy$.complete();
    };
    SessionExpiryAlertService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionExpiryAlertService_Factory() { return new SessionExpiryAlertService(i0.ɵɵinject(i1.SessionManagerService), i0.ɵɵinject(i2.ToastService), i0.ɵɵinject(i3.AuthService), i0.ɵɵinject(i4.LoggerService), i0.ɵɵinject(i5.WINDOW), i0.ɵɵinject(i0.NgZone)); }, token: SessionExpiryAlertService, providedIn: "root" });
    return SessionExpiryAlertService;
}());
export { SessionExpiryAlertService };
