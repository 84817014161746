import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientBrandLogoComponent } from './client-brand-logo.component';

@NgModule({
  declarations: [ClientBrandLogoComponent],
  imports: [
    CommonModule
  ],
  exports: [ClientBrandLogoComponent]
})
export class ClientBrandLogoModule { }
