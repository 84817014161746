import { ComponentFactoryResolver, Injector } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var SideSheetService = /** @class */ (function () {
    function SideSheetService() {
        this.clearing$ = new Subject();
        this.depth$ = new BehaviorSubject(0);
    }
    SideSheetService.prototype.init = function (sideSheet) {
        this.sideSheet = sideSheet;
    };
    SideSheetService.prototype.push = function (content, params) {
        return this.sideSheet.push(content, params);
    };
    SideSheetService.prototype.replace = function (content, params) {
        return this.sideSheet.replace(content, params);
    };
    SideSheetService.prototype.pop = function (useHistory) {
        if (useHistory === void 0) { useHistory = false; }
        return this.sideSheet.pop(useHistory);
    };
    SideSheetService.prototype.clear = function (useHistory) {
        if (useHistory === void 0) { useHistory = false; }
        return this.sideSheet.clear(useHistory);
    };
    SideSheetService.prototype.resizeTo = function (size) {
        return this.sideSheet.resizeTo(size);
    };
    SideSheetService.prototype.getSize = function () {
        return this.sideSheet.size;
    };
    SideSheetService.prototype.generateBreadcrumbString = function () {
        return this.sideSheet.generateBreadcrumbString();
    };
    SideSheetService.prototype.pushBreadcrumbSuffix = function (input) {
        this.sideSheet.breadcrumbSuffix.push(input);
    };
    SideSheetService.prototype.makeDirty = function (sideSheetStackId) {
        var _this = this;
        if (sideSheetStackId === void 0) { sideSheetStackId = -1; }
        // The setTimeout is here to make sure enough time is given for a side sheet to be popped before the dirtyStatus is updated.
        // @todo Have a better solution.
        setTimeout(function () { return _this.sideSheet.makeDirty(sideSheetStackId); });
    };
    SideSheetService.prototype.makeClean = function () {
        return this.sideSheet.makeClean();
    };
    SideSheetService.prototype.clearing = function () {
        this.clearing$.next();
    };
    SideSheetService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SideSheetService_Factory() { return new SideSheetService(); }, token: SideSheetService, providedIn: "root" });
    return SideSheetService;
}());
export { SideSheetService };
