import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, of, Observable, Subscription } from 'rxjs';
import { map, first, tap } from 'rxjs/operators';
import { get } from 'lodash';
import { ModalConfirmDeletionComponent, modalConfirmDeletionOptions } from '../../../shared/modals/modal-confirm-deletion/modal-confirm-deletion.component';
import { DEFAULT_PRINT_TEMPLATE, DEFAULT_SETTING_PRINT } from '../../../print/print';
import { PrintHtmlPreviewSideSheetComponent } from '../../../print/print-html-preview-side-sheet/print-html-preview-side-sheet.component';
import { ObjectSecuritySideSheetComponent } from '../../../object/object-security-side-sheet/object-security-side-sheet.component';
import { EditFormInstanceService } from './edit-form-instance.service';
import { FormGroup } from '@angular/forms';
import { Stringify } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
var EditFormSideSheetComponent = /** @class */ (function () {
    function EditFormSideSheetComponent(sideSheetService, formulaService, editFormInstanceService, modalService, cdr) {
        this.sideSheetService = sideSheetService;
        this.formulaService = formulaService;
        this.editFormInstanceService = editFormInstanceService;
        this.modalService = modalService;
        this.cdr = cdr;
        // these four observables could be rationalised into one
        this.form$ = this.editFormInstanceService.form$;
        this.typeFields$ = this.editFormInstanceService.typeFields$;
        this.objectData$ = this.editFormInstanceService.objectData$;
        this.formOptions$ = this.editFormInstanceService.formOptions$;
        this.loading$ = this.editFormInstanceService.loading$;
        this.appAlert$ = this.editFormInstanceService.appAlert$;
        this.deletable$ = this.editFormInstanceService.deletable$;
        this.formDisabledStatus$ = this.editFormInstanceService.formDisabledStatus$;
        this.formTitlePrefix$ = new BehaviorSubject({ prefix: '', postfix: '' });
        this.formTitle$ = combineLatest([this.form$, this.formTitlePrefix$]).pipe(map(extractTitle));
        this.formSize = 'sm';
        this.actionText = '';
        this.typeLabel = '';
        this.formValid = false;
        this.formDirty = false;
        this.preview = false;
        this.hideSecurityOptions = false;
        this.done$ = this.editFormInstanceService.done$;
        this.isEdit = false;
        this.showFormBreadcrumbs = false;
        this.formBreadcrumbs$ = new BehaviorSubject('');
        this.formGroup = new FormGroup({});
        this.showErrorBanner = false;
    }
    EditFormSideSheetComponent.prototype.print = function () {
        var componentInstance = this.sideSheetService.push(PrintHtmlPreviewSideSheetComponent).componentInstance;
        componentInstance.setProps(this.printProps);
    };
    EditFormSideSheetComponent.prototype.setProps = function (params) {
        var _this = this;
        this.formSize = this.getFormSize(get(params, 'formObjectAndType.form.size'));
        this.showFormBreadcrumbs = get(params, 'formObjectAndType.form.showFormBreadcrumbs', false);
        this.hideSecurityOptions = get(params, 'hideSecurityOptions', false);
        this.securityPolicyTip = get(params, 'securityPolicyTip', undefined);
        this.editFormInstanceService.load(params);
        this.formTitlePrefix$.next(formTitlePrefixPostFix(params));
        this.actionText = this.getActionText(params);
        this.typeLabel = getTypeLabel(params.formObjectAndType.form);
        this.preview = params.preview || false;
        this.printProps = this.getPrintProps(params);
        this.isEdit = !!params.objectTip;
        this.subs = this.getTitleForObject(params).pipe().subscribe(function (value) {
            if (value) {
                if (Array.isArray(value)) {
                    _this.sideSheetService.pushBreadcrumbSuffix(value[0]);
                }
                else {
                    _this.sideSheetService.pushBreadcrumbSuffix(value);
                }
            }
            else {
                _this.sideSheetService.pushBreadcrumbSuffix('');
            }
            _this.formBreadcrumbs$.next(_this.sideSheetService.generateBreadcrumbString());
        });
    };
    EditFormSideSheetComponent.prototype.getTitleForObject = function (params) {
        if (formTitlePrefixPostFix(params).prefix === 'New') {
            return of('new');
        }
        else {
            var objectTip = get(params, 'formObjectAndType.objectAndType.objectData.$tip', null);
            if (objectTip) {
                var titleFormula = Stringify({
                    name: 'TITLE',
                    args: [
                        objectTip
                    ]
                });
                return this.formulaService.evaluate(titleFormula);
            }
            else {
                return of('new');
            }
        }
    };
    EditFormSideSheetComponent.prototype.getPrintProps = function (params) {
        var printProps;
        var form = params.formObjectAndType.form;
        printProps = {
            templateTip: DEFAULT_PRINT_TEMPLATE,
            settingTip: DEFAULT_SETTING_PRINT,
            contextTip: params.objectTip
        };
        if (get(form, '$tip', false)) {
            printProps.formTip = form.$tip;
        }
        return printProps;
    };
    EditFormSideSheetComponent.prototype.save = function () {
        this.formGroup.updateValueAndValidity();
        if (!this.formValid) {
            this.showErrorBanner = true;
            this.formGroup.markAllAsTouched();
            return;
        }
        this.editFormInstanceService.save(this.object, { securityPolicyTip: this.securityPolicyTip, bespokeSecurityPolicy: this.bespokeSecurityPolicy });
        this.sideSheetService.makeClean();
    };
    EditFormSideSheetComponent.prototype.delete = function () {
        var _this = this;
        var modalRef = this.modalService.open(ModalConfirmDeletionComponent, modalConfirmDeletionOptions);
        var confirmDeleteComponent = modalRef.componentInstance;
        confirmDeleteComponent.objectType = this.typeLabel;
        modalRef.result
            .then(function (confirm) {
            if (confirm) {
                _this.editFormInstanceService.delete();
            }
        });
    };
    EditFormSideSheetComponent.prototype.setFormObject = function ($event) {
        this.object = $event;
    };
    EditFormSideSheetComponent.prototype.formStatus = function ($event) {
        this.formValid = $event.valid;
        if ($event.dirty && !this.formDirty) {
            this.formDirty = true;
            this.sideSheetService.makeDirty();
        }
        this.setFormStatus(this.formValid);
    };
    EditFormSideSheetComponent.prototype.setFormGroup = function ($event) {
        this.formGroup = $event;
    };
    EditFormSideSheetComponent.prototype.getActionText = function (params) {
        if (params.preview) {
            return '';
        }
        if (params.subObject) {
            return 'Done';
        }
        return 'Save';
    };
    // Opens the object security side sheet
    EditFormSideSheetComponent.prototype.setSecurity = function () {
        var _this = this;
        this.getSecurity$().pipe(first(), tap(function (securityPolicyTip) {
            var sheetRef = _this.sideSheetService.push(ObjectSecuritySideSheetComponent);
            if (_this.bespokeSecurityPolicy) {
                sheetRef.componentInstance.bespokeSecurityPolicy = _this.bespokeSecurityPolicy;
            }
            else {
                sheetRef.componentInstance.securityPolicyTip = securityPolicyTip;
            }
            sheetRef.componentInstance.onDone = function (newSecurityPolicyTip, newBespokeSecurityPolicy) {
                if (newSecurityPolicyTip) {
                    _this.securityPolicyTip = newSecurityPolicyTip;
                    _this.bespokeSecurityPolicy = null;
                }
                else {
                    _this.securityPolicyTip = null;
                    _this.bespokeSecurityPolicy = newBespokeSecurityPolicy;
                }
                _this.setFormStatus(_this.formValid);
                _this.cdr.detectChanges();
            };
        })).subscribe();
    };
    // Returns an observable with the current security tip
    EditFormSideSheetComponent.prototype.getSecurity$ = function () {
        if (this.securityPolicyTip) {
            return of(this.securityPolicyTip);
        }
        return this.objectData$.pipe(map(function (objectData) { return objectData.$security; }));
    };
    EditFormSideSheetComponent.prototype.setFormStatus = function ($valid) {
        var disabled = !$valid;
        this.formDisabledStatus$.next(disabled);
    };
    EditFormSideSheetComponent.prototype.getFormSize = function (formSize) {
        switch (formSize) {
            case 'large':
                return 'lg';
            case 'extraLarge':
                return 'xl';
            default:
                return 'sm';
        }
    };
    EditFormSideSheetComponent.prototype.ngOnDestroy = function () {
        if (this.subs) {
            this.subs.unsubscribe();
        }
    };
    return EditFormSideSheetComponent;
}());
export { EditFormSideSheetComponent };
function formTitlePrefixPostFix(params) {
    if (params.preview) {
        return { prefix: 'Preview', postfix: '' };
    }
    if (params.subObject) {
        if (typeof params.subObject === 'boolean') {
            return { prefix: 'New', postfix: '' };
        }
        return { prefix: 'Edit', postfix: '' };
    }
    return params.objectTip
        ? { prefix: 'Edit', postfix: '' }
        : { prefix: 'New', postfix: '' };
}
function getTypeLabel(form) {
    return get(form, 'contextType.type.label', '');
}
function getTitleOverride(form) {
    return get(form, 'titleOverride', null);
}
function extractTitle(_a) {
    var _b = tslib_1.__read(_a, 2), form = _b[0], _c = _b[1], prefix = _c.prefix, postfix = _c.postfix;
    var typeTitle = getTypeLabel(form);
    var titleOverride = getTitleOverride(form);
    if (titleOverride) {
        return titleOverride;
    }
    if (typeTitle) {
        return prefix + " " + typeTitle + " " + postfix;
    }
    return '';
}
