import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-accept-verification',
  templateUrl: './accept-verification.component.html',
  styleUrls: ['./accept-verification.component.scss']
})
export class AcceptVerificationComponent implements OnInit {
  isTokenValid: boolean;

  constructor(
    private _route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.isTokenValid = this._route.snapshot.data.isTokenValid;
  }
}
