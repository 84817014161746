import { Injectable } from '@angular/core';
import { Formula, Tip } from '../data/models/types';
import { GetFormulaDatatypeService, IFormulaDatatypeMeta } from './get-formula-datatype.service';
import { FormulaService } from '../data/formula.service';
import { forkJoin, Observable, of } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { FIELD_DATA_TYPE } from '../models/field';
import { addDateFormatWrapper, addDatetimeFormatWrapper } from './datetime-format-formula-wrapper';
import { get, isEqual, isEmpty } from 'lodash';

export interface IDisplayFormulaRule {
  date?: boolean;
  datetime?: boolean;
  list?: boolean;
  stripHTML?: boolean;
}

const DEFAULT_RULE: IDisplayFormulaRule = {
  date: false,
  datetime: false,
  list: false,
  stripHTML: false
};

@Injectable({
  providedIn: 'root'
})
export class DisplayFormulaWrapperService {

  constructor(
    private getFormulaDataTypeService: GetFormulaDatatypeService,
    private formulaService: FormulaService
  ) {
  }

  addDisplayFormulaWrapper(
    formulas: Formula[],
    contextTypeTip: Tip,
    options?: IDisplayFormulaRule
  ): Observable<Formula[]> {
    // apply default rule
    options = { ...DEFAULT_RULE, ...options };

    // if options are all set to false then do early return
    if (isEqual(options, DEFAULT_RULE)) {
      return of(formulas);
    }

    // do a early return if formulas are empty
    if (isEmpty(formulas)) {
      return of([]);
    }

    return forkJoin(
      formulas.map(
        formula => this.getFormulaDataTypeService.getFormulaDataType(formula, contextTypeTip).pipe(
          map((meta: IFormulaDatatypeMeta) => {
            if (meta.datatype === FIELD_DATA_TYPE.date && options.date) {
              return addDateFormatWrapper(formula);
            }

            if (meta.datatype === FIELD_DATA_TYPE.datetime && options.datetime) {
              return addDatetimeFormatWrapper(formula);
            }

            if (meta.datatype === FIELD_DATA_TYPE.html && options.stripHTML) {
              return addHtmlDisplayWrapper(formula);
            }

            if (meta.listTip && options.list) {
              return addListDisplayWrapper(formula, meta.listTip);
            }

            return formula;
          }),
          first()
        )
      )
    );
  }

  removeDisplayFormulaWrapper(formula: Formula): Formula {
    const parsedFormula = this.formulaService.parse(formula);

    if (parsedFormula.name === 'CONCAT'
      && get(parsedFormula, 'args[0].name') === 'FORMAT_DATETIME') {
      return this.formulaService.stringify(get(parsedFormula, 'args[0].args[0]'));
    }

    if (parsedFormula.name === 'STRIP_TAGS'
      && get(parsedFormula, 'args[0].name') === 'REPLACE') {
      return this.formulaService.stringify(get(parsedFormula, 'args[0].args[0]'));
    }

    if (parsedFormula.name === 'CONCAT_WS'
      && get(parsedFormula, 'args[1].name') === 'MAP') {
      return this.formulaService.stringify(get(parsedFormula, 'args[1].args[0]'));
    }

    return formula;
  }

  removeDisplayFormulaWrappers(formulas: Formula[]): Formula[] {
    return formulas.map(formula => this.removeDisplayFormulaWrapper(formula));
  }
}

function addHtmlDisplayWrapper(formula: Formula): Formula {
  return `STRIP_TAGS(REPLACE(${ formula }, "</p>", "\n"))`;
}

function addListDisplayWrapper(formula: Formula, listTip: Tip): Formula {
  // tslint:disable-next-line:max-line-length
  return `CONCAT_WS(", ", MAP(${ formula }, NTH(FIELD("app/list:labels", "${ listTip }"), INDEXOF(FIELD("app/list:values", "${ listTip }"), VAR("Value")))))`;
}

// MAP(
//   ARRAY(),
//   NTH(
//     FIELD("app/list:labels", "${ listTip }"),
//     INDEXOF(
//       FIELD("app/list:values", "${ listTip }"),
//       VAR("Value")
//     )
//   )
// )
