var _a;
import * as tslib_1 from "tslib";
import { reverseOperator } from './operators';
import { OPERATOR_TYPE } from '../../../models/query';
import { objectOperators } from './object-operators';
import { SUPER_TYPES } from '../../../object/type-chooser-side-sheet/super-types';
var ɵ0 = function (leftArg, rightArg, meta) {
    var parentFieldTip = getParentFieldTip(meta);
    return {
        name: 'HAS_INTERSECT',
        args: [
            {
                name: 'ARRAY',
                args: [
                    {
                        // this formula will actually find descendants
                        name: 'ANCESTORS',
                        args: [
                            parentFieldTip,
                            leftArg
                        ]
                    },
                    leftArg
                ]
            },
            rightArg
        ]
    };
};
var hierarchicalObjectContainsAny = {
    opNo: 2,
    toFormula: ɵ0
};
var ɵ1 = function (leftArg, rightArg, meta) {
    var parentFieldTip = getParentFieldTip(meta);
    return {
        name: 'HAS_INTERSECT',
        args: [
            {
                name: 'ARRAY',
                args: [
                    {
                        // this formula will actually find ancestors
                        name: 'DESCENDANTS',
                        args: [
                            parentFieldTip,
                            leftArg
                        ]
                    },
                    leftArg
                ]
            },
            rightArg
        ]
    };
};
var hierarchicalObjectIn = {
    opNo: 2,
    toFormula: ɵ1
};
export function getParentFieldTip(meta) {
    var parentFieldTip = '', hierarchicalSuperTypeTip = '';
    if (!meta || !meta.typeRestrict) {
        return parentFieldTip;
    }
    var hierarchicalSuperTypes = SUPER_TYPES.filter(function (x) { return x.isHierarchical; }).map(function (x) { return x.$tip; });
    // if object itself is a group or one of hierarchical super types - use it to get parent field
    if (tslib_1.__spread(hierarchicalSuperTypes, ['app/group']).includes(meta.typeRestrict)) {
        hierarchicalSuperTypeTip = meta.typeRestrict;
        // otherwise check if object implements any hierarchical type
    }
    else if (meta.implements && meta.implements.length) {
        var hierarchicalTypes_1 = SUPER_TYPES.filter(function (x) { return x.isHierarchical; }).map(function (x) { return x.$tip; });
        hierarchicalSuperTypeTip = meta.implements.find(function (x) { return hierarchicalTypes_1.includes(x); });
    }
    if (hierarchicalSuperTypeTip) {
        parentFieldTip = hierarchicalSuperTypeTip + ':parent';
    }
    return parentFieldTip;
}
var hierarchicalObjectNotContainsAny = reverseOperator(hierarchicalObjectContainsAny);
var hierarchicalObjectNotIn = reverseOperator(hierarchicalObjectIn);
export var hierarchicalOperators = {
    operators: (_a = {},
        _a[OPERATOR_TYPE.CONTAINS_ANY] = hierarchicalObjectContainsAny,
        _a[OPERATOR_TYPE.NOT_CONTAINS_ANY] = hierarchicalObjectNotContainsAny,
        _a[OPERATOR_TYPE.IN] = hierarchicalObjectIn,
        _a[OPERATOR_TYPE.NOT_IN] = hierarchicalObjectNotIn,
        _a[OPERATOR_TYPE.EQUALS] = objectOperators.operators[OPERATOR_TYPE.EQUALS],
        _a[OPERATOR_TYPE.NOT_EQUALS] = objectOperators.operators[OPERATOR_TYPE.NOT_EQUALS],
        _a[OPERATOR_TYPE.IS_OF_TYPE] = objectOperators.operators[OPERATOR_TYPE.IS_OF_TYPE],
        _a[OPERATOR_TYPE.NOT_IS_OF_TYPE] = objectOperators.operators[OPERATOR_TYPE.NOT_IS_OF_TYPE],
        _a),
    getParts: function (formula) {
        var currentPart = formula;
        var negative = false;
        if (currentPart.name === 'NOT') {
            currentPart = currentPart.args[0];
            negative = true;
        }
        if (currentPart.name === 'EQUALS') {
            return objectOperators.getParts(formula);
        }
        if (currentPart.name === 'OP') {
            return {
                operator: negative ? OPERATOR_TYPE.NOT_IS_OF_TYPE : OPERATOR_TYPE.IS_OF_TYPE,
                leftArg: currentPart.args[0].args[0],
                rightArg: currentPart.args[2]
            };
        }
        var innerPart = currentPart.args[0].args[0];
        if (innerPart.name === 'ANCESTORS') {
            return {
                operator: negative ? OPERATOR_TYPE.NOT_CONTAINS_ANY : OPERATOR_TYPE.CONTAINS_ANY,
                leftArg: innerPart.args[1],
                rightArg: currentPart.args[1]
            };
        }
        else if (innerPart.name === 'DESCENDANTS') {
            return {
                operator: negative ? OPERATOR_TYPE.NOT_IN : OPERATOR_TYPE.IN,
                leftArg: innerPart.args[1],
                rightArg: currentPart.args[1]
            };
        }
        throw new Error('Unknown operators: ' + currentPart.name);
    }
};
export { ɵ0, ɵ1 };
