import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { INTERPOLATION_TYPE, ISubstitutionCandidate } from '../../../form/string-interpolation/string-interpolation.service';
import { SideSheetListComponent } from '../../../side-sheet/side-sheet-list/side-sheet-list.component';
import { SideSheetService } from '../../../side-sheet/side-sheet.service';
import { head } from 'lodash';
// tslint:disable-next-line:max-line-length
import { WorkflowLineValueFormDrillDownComponent } from '../../../settings/workflow-designer/node-editors/shared/workflow-line-value-form-drill-down/workflow-line-value-form-drill-down.component';
import { FIELD_DATA_TYPE } from '../../../models/field';

@Component({
  selector: 'app-response-links-side-sheet',
  templateUrl: './response-links-side-sheet.component.html',
  styleUrls: ['./response-links-side-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResponseLinksSideSheetComponent {
  // defaults
  links: string[] = ['Yes', 'No', 'Maybe'];

  @ViewChild(SideSheetListComponent, {static: false}) list: SideSheetListComponent;

  done: (substitutionCandidate: ISubstitutionCandidate) => void = _ => {};
  constructor(
    private sideSheetService: SideSheetService
  ) { }

  onDone(answer?: string) {
    if (!answer) {
      answer = head(this.list.selected);
    }

    const substitutionCandidate: ISubstitutionCandidate = {
      type: INTERPOLATION_TYPE.responseLink,
      label: answer,
      value: `ARRAY("${ answer }")`
    };

    this.done(substitutionCandidate);
    this.sideSheetService.pop();
  }

  onNewLink() {
    const sheetRef = this.sideSheetService.push(WorkflowLineValueFormDrillDownComponent);
    const sheetInstance: WorkflowLineValueFormDrillDownComponent = sheetRef.componentInstance;

    sheetInstance.setProps({
      fieldDataType: FIELD_DATA_TYPE.string,
      objectType: null,
      typeGeoRestrict: null,
      value: null
    });

    sheetInstance.done = ({value}: {
      value: string;
      label: string;
    }) => {
      this.onDone(value);
      this.sideSheetService.pop();
    };
  }
}
