import { InjectionToken } from '@angular/core';
import { saveAs } from 'file-saver';

/**
 * A DI Token representing the window containing the main rendering context. In a browser, this is the window containing the DOM document.
 * @publicApi
 */
export const windowProviderFactory = () => (window as any);
export const WINDOW = new InjectionToken<Window>('WindowToken');
export const WINDOW_PROVIDER = { provide: WINDOW, useFactory: windowProviderFactory };


export const localStorageFactory = () => window.localStorage;
export const LOCAL_STORAGE = new InjectionToken<Window>('LocalStorage');
export const LOCAL_STORAGE_PROVIDER = { provide: LOCAL_STORAGE, useFactory: localStorageFactory };


export const saveAsProviderFactory = () => saveAs;
export const SAVE_AS = new InjectionToken<(data: Blob | string, filename?: string, disableAutoBOM?: boolean) => void>('SaveAsToken');
export const SAVE_AS_PROVIDER = { provide: SAVE_AS, useFactory: saveAsProviderFactory };
