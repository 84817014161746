import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideSheetHeaderModule } from '../../side-sheet-header/side-sheet-header.module';
import { SideSheetSectionModule } from '../../side-sheet-section/side-sheet-section.module';
import { SideSheetListModule } from '../../side-sheet-list/side-sheet-list.module';
import { SideSheetListItemModule } from '../../side-sheet-list-item/side-sheet-list-item.module';
import { EmptyStateModule } from '../../../shared/empty-state/empty-state.module';
import { SpinnerModule } from '../../../shared/spinner/spinner.module';
import { FilterAndAddModule } from '../../../shared/filter-and-add/filter-and-add.module';
import { DateWithinSideSheetComponent } from './date-within-side-sheet.component';

@NgModule({
  declarations: [
    DateWithinSideSheetComponent
  ],
  imports: [
    CommonModule,
    SideSheetHeaderModule,
    SideSheetSectionModule,
    SideSheetListModule,
    SideSheetListItemModule,
    EmptyStateModule,
    SpinnerModule,
    FilterAndAddModule
  ],
  exports: [
    DateWithinSideSheetComponent
  ],
  entryComponents: [
    DateWithinSideSheetComponent
  ],
})
export class DateWithinSideSheetModule { }
