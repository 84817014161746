import * as tslib_1 from "tslib";
import { combineLatest, forkJoin, of, throwError, interval as interval$ } from 'rxjs';
import { distinctUntilKeyChanged, first, map, switchMap, tap, mapTo, takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { find, compact, isEqual, concat as _concat } from 'lodash';
import dataConstants from '../constants';
import { EnoFactory } from '../EnoFactory';
import { DataTypes } from '../models/scheme';
import { scanScheme } from './scan-scheme';
import { CAST_VALUE_FUNCS, PARSE_VALUE_FUNCS } from './js-object-eno-field-conversion';
import { castValue, fieldTipToName } from './utils';
import * as i0 from "@angular/core";
import * as i1 from "../eno.service";
import * as i2 from "../session-manager.service";
import * as i3 from "../ensrv.service";
import * as i4 from "../op-pull.service";
var ObjectService = /** @class */ (function () {
    // Start listening for enos as they arrive from EnSrv
    function ObjectService(enoService, sessionManagerService, enSrvService, opPullService) {
        this.enoService = enoService;
        this.sessionManagerService = sessionManagerService;
        this.enSrvService = enSrvService;
        this.opPullService = opPullService;
    }
    /**
     * Scans an object scheme for the maximum nesting depth and the fields that should be followed
     */
    ObjectService.prototype.scanScheme = function (scheme, maxDepth, followFields, schemes) {
        if (maxDepth === void 0) { maxDepth = 0; }
        if (followFields === void 0) { followFields = []; }
        if (schemes === void 0) { schemes = []; }
        return scanScheme(scheme, maxDepth, followFields, schemes);
    };
    /**
     * Converts a field tip in to a property name
     */
    ObjectService.prototype.fieldTipToName = function (fieldTip) {
        return fieldTipToName(fieldTip);
    };
    /**
     * Converts a field in to a value according to the given scheme
     */
    ObjectService.prototype.castValue = function (eno, fieldTip, fieldScheme) {
        return castValue(eno, fieldTip, fieldScheme);
    };
    /**
     * Converts a value in to field according to the given scheme
     */
    ObjectService.prototype.parseValue = function (value, fieldScheme) {
        return PARSE_VALUE_FUNCS[fieldScheme.type || DataTypes.string](value);
    };
    /**
     * Gets a nested object structure according to the given scheme given the root tip
     */
    ObjectService.prototype.getObject = function (tip, scheme, branch, seen, useCache) {
        var _this = this;
        if (branch === void 0) { branch = dataConstants.BRANCH_MASTER; }
        if (seen === void 0) { seen = []; }
        if (!tip) {
            throw new Error('[ObjectService] Could not get object by tip. Object tip is not defined.');
        }
        if (seen.indexOf(tip) > -1) {
            throw new Error('[ObjectService] Circular reference detected: ' + tip);
        }
        seen.push(tip);
        var schemeScan = this.scanScheme(scheme);
        var _useCache = useCache !== undefined
            ? useCache // use explicit setting
            : (seen.length > 1 ? true : null);
        return this.enoService.readEno(tip, {
            branch: branch,
            recursiveFields: schemeScan.followFields,
            recursiveDepth: schemeScan.maxDepth,
            useCache: _useCache
        }).pipe(distinctUntilKeyChanged('sid'), switchMap(function (eno) { return _this.mapEnoToScheme(eno, scheme, branch, seen); }));
    };
    /**
     * emits the eno initially and periodically pull the eno with if-not-sid, if a new version came, it emits the new eno.
     * this method does not support nested objectScheme, the scheme has to have no object or objectArray fields.
     * use it when you can't wait for the getObject to reflect the change via eno watch or as a debugging tool.
     * beware to NOT use this method heavily as this is a consuming operation.
     * the difference between this method ant the getObject:
     * 1) getObject will register a op/pull watch to the server, server notifies changes via pub-sub causing front-end to pull again
     * 2) this method will NOT register a watch but rather hard polling the object.
     */
    ObjectService.prototype.pollObject = function (_a) {
        var _this = this;
        var tip = _a.tip, _b = _a.branch, branch = _b === void 0 ? dataConstants.BRANCH_MASTER : _b, scheme = _a.scheme, interval = _a.interval, cancel$ = _a.cancel$;
        if (Object.keys(scheme).some(function (key) { return scheme[key].type === DataTypes.object || scheme[key].type === DataTypes.objectArray; })) {
            throw new Error('[Poll Service] Has to be a scheme without object or objectArray fields!');
        }
        var ifNotSid = [];
        var pull$ = function () {
            var opPullEnoToSend = _this.opPullService.createOpPull({
                tip: [tip],
                branch: [branch],
                watch: false,
                recursiveDepth: 0,
                recursiveField: [],
                ifNotSid: ifNotSid
            });
            return _this.enSrvService.send([opPullEnoToSend]).pipe(first(), map(function (_a) {
                var _b = tslib_1.__read(_a, 1), eno = _b[0];
                return eno;
            }));
        };
        var checkError = function (eno) {
            if (eno.getType() === 'error') {
                throw new Error('[Poll Service] Server returns an error!');
            }
            return eno;
        };
        var distinctChecker = function (prev, next) {
            // if next doesn't exist, this means the if-not-sid options is working.
            return !next || !next.sid || prev.sid === next.sid;
        };
        return interval$(interval).pipe(takeUntil(cancel$), switchMap(pull$), distinctUntilChanged(distinctChecker), map(checkError), tap(function (eno) { return ifNotSid.push(eno.sid); }), switchMap(function (eno) { return _this.mapEnoToScheme(eno, scheme, branch); }));
    };
    ObjectService.prototype.mapEnoToScheme = function (eno, scheme, branch, seen) {
        var _this = this;
        if (branch === void 0) { branch = dataConstants.BRANCH_MASTER; }
        if (seen === void 0) { seen = []; }
        var fields = [
            of({ name: '$tip', value: eno.tip }),
            of({ name: '$security', value: eno.source.security }),
            of({ name: '$branch', value: eno.getBranch() }),
            of({ name: '$type', value: eno.getType() }),
            of({ name: '$sid', value: eno.sid })
        ].concat(eno.source.field.filter(function (field) {
            return eno.getFieldValues(field.tip).length > 0;
        } // Skip empty fields
        ).map(function (field) {
            var fieldScheme = scheme[field.tip] || {};
            var name = fieldScheme.name || _this.fieldTipToName(field.tip);
            var values = eno.getFieldValues(field.tip);
            if (fieldScheme.type === DataTypes.object) {
                return _this.getObject(values[0], fieldScheme.scheme, branch, seen.slice()).pipe(map(function (subObject) {
                    return { name: name, value: subObject };
                }));
            }
            if (fieldScheme.type === DataTypes.objectArray) {
                return combineLatest(values.map(function (valueTip) { return _this.getObject(valueTip, fieldScheme.scheme, branch, seen.slice()); })).pipe(map(function (subObjects) {
                    return { name: name, value: subObjects };
                }));
            }
            if (fieldScheme.type === DataTypes.i18nObject) {
                return of({ name: name, value: eno.getFieldRawI18n(field.tip) });
            }
            return of({ name: name, value: _this.castValue(eno, field.tip, fieldScheme) });
        }));
        return combineLatest(fields).pipe(map(function (props) {
            return props.reduce(function (out, prop) {
                out[prop.name] = prop.value;
                return out;
            }, {});
        }));
    };
    // @deprecated
    ObjectService.prototype.castObject = function (object, scheme) {
        var _this = this;
        return combineLatest(Object.keys(object).map(function (fieldTip) {
            var fieldScheme = scheme[fieldTip] || {};
            var name = _this.fieldTipToName(fieldTip);
            var value = object[fieldTip];
            if (name === 'tip' || name === 'security') {
                return of({ name: "$" + name, value: value });
            }
            if (fieldScheme.type === DataTypes.object) {
                return _this.getObject(value, fieldScheme.scheme).pipe(map(function (subObject) {
                    return { name: name, value: subObject };
                }));
            }
            if (fieldScheme.type === DataTypes.objectArray) {
                var arrValue = typeof value === 'string' ? value.split(',') : value;
                return combineLatest(arrValue.map(function (tip) { return _this.getObject(tip, fieldScheme.scheme); })).pipe(map(function (subObject) {
                    return { name: name, value: subObject };
                }));
            }
            return of({ name: name, value: CAST_VALUE_FUNCS[fieldScheme.type || DataTypes.string]([value]) });
        })).pipe(map(function (props) {
            return props.reduce(function (out, prop) {
                out[prop.name] = prop.value;
                return out;
            }, {});
        }));
    };
    // Retrieve the default security policy for the user
    ObjectService.prototype.defaultSecurityPolicy$ = function () {
        var _this = this;
        return this.sessionManagerService.getSessionInfo$().pipe(switchMap(function (sessionInfo) { return _this.enoService.readEno(sessionInfo.profile, { useCache: true }); }), map(function (profileEno) { return profileEno.getFieldStringValue('app/profile:default-policy'); }));
    };
    /**
     * Writes the given nested object structure according to the given scheme.
     *
     * If an object has a $tip property, then it will update the object.
     * If it doesn't, then it will create a new object. In this case $type should be given together
     * security should be given either by securityPolicy argument or $security in the obj
     *
     * If an object has a $security field, then it will set the security policy.
     * $security field is inherited down, but it can be overwritten at any point.
     *
     * $branch works differently from $security. $branch is not used to generate enos.
     * By default, branch is branch/master and inherited down.
     * If a branch is given as an argument, then all the nested objects will be created in the given branch
     */
    ObjectService.prototype.setObject = function (input, // The object data to save
    scheme, // The scheme that maps the object data to an eno
    branch, // The branch to save it in
    securityPolicy // The security policy to save with
    ) {
        var _this = this;
        // 1. Convert the nested object in to factories
        return this.objectToEnoFactory$({ input: input, scheme: scheme, branch: branch, securityPolicy: securityPolicy, seen: [] }).pipe(
        // 2. Convert the factories in to batches
        map(function (factoryState) { return _this.factoryStateToBatch(factoryState); }), 
        // 3. Send the batch
        switchMap(function (batches) { return _this.writeBatches(batches); }));
    };
    // The same as setObject but sets multiple at a time in the same batch
    ObjectService.prototype.setObjects = function (inputs, schemes, branches, securityPolicies) {
        var _this = this;
        var batches$$ = inputs.map(function (input, i) {
            var branch = branches ? branches[i] : null;
            var securityPolicy = securityPolicies ? securityPolicies[i] : null;
            var scheme = schemes[i] || schemes[0];
            return _this.objectToEnoFactory$({ input: input, scheme: scheme, branch: branch, securityPolicy: securityPolicy, seen: [] }).pipe(map(function (factoryState) { return _this.factoryStateToBatch(factoryState); }));
        });
        return forkJoin(batches$$).pipe(map(function (batches) { return ({
            writeBatch: _concat.apply(void 0, tslib_1.__spread([[]], batches.map(function (batch) { return batch.writeBatch; }))),
            deleteBatch: _concat.apply(void 0, tslib_1.__spread([[]], batches.map(function (batch) { return batch.deleteBatch; })))
        }); }), switchMap(function (batches) { return _this.writeBatches(batches); }));
    };
    ObjectService.prototype.writeBatches = function (batches) {
        var _this = this;
        return this.enoService.writeEnos(batches.writeBatch).pipe(switchMap(function (writeResponse) {
            if (batches.deleteBatch.length > 0) {
                return _this.enoService.writeEnos(batches.deleteBatch).pipe(map(function (deleteResponse) { return _concat(writeResponse, deleteResponse); }));
            }
            return of(writeResponse);
        }));
    };
    ObjectService.prototype.factoryStateToBatch = function (factoryState) {
        var batches = this.factoryStateToBatchCallbacks(factoryState);
        return {
            writeBatch: compact(batches.writeCallbacks.map(function (call) { return call(); })),
            deleteBatch: compact(batches.deleteCallbacks.map(function (call) { return call(); }))
        };
    };
    ObjectService.prototype.factoryStateToBatchCallbacks = function (factoryState, seen) {
        var _this = this;
        if (seen === void 0) { seen = []; }
        var batchCallbacks = [];
        var originalReferencedFactories = {};
        var actuallyReferencedTips = {};
        var objectFieldTips = Object.keys(factoryState.referencedFactories || {});
        var deletedCallbacks = [];
        seen.push(factoryState);
        // For each object field, recursively make enos, replacing with placeholders where circular references are detected
        objectFieldTips.forEach(function (fieldTip) {
            originalReferencedFactories[fieldTip] = factoryState.referencedFactories[fieldTip].slice();
            actuallyReferencedTips[fieldTip] = compact(factoryState.referencedFactories[fieldTip].map(function (referencedFactory) {
                if (typeof referencedFactory === 'string') {
                    return referencedFactory;
                }
                if (!referencedFactory.eno && seen.indexOf(referencedFactory) > -1) {
                    // We've already seen this factory - circular reference detected
                    var placeholderEno_1 = _this.makePlaceholderEno(factoryState, fieldTip);
                    if (placeholderEno_1) {
                        batchCallbacks.push(function () { return placeholderEno_1; });
                        deletedCallbacks.push(function () { return _this.makePlaceholderDelete(placeholderEno_1); });
                        return placeholderEno_1.tip;
                    }
                    return null;
                }
                if (!referencedFactory.eno) {
                    var refCallbacks = _this.factoryStateToBatchCallbacks(referencedFactory, seen);
                    batchCallbacks = _concat(batchCallbacks, refCallbacks.writeCallbacks);
                    deletedCallbacks = _concat(deletedCallbacks, refCallbacks.deleteCallbacks);
                }
                return referencedFactory.eno.tip;
            }));
            factoryState.enoFactory.setField(fieldTip, actuallyReferencedTips[fieldTip]);
        });
        // Make the actual eno, with placeholders
        factoryState.eno = factoryState.enoFactory.makeEno();
        if (!factoryState.previousEno || factoryState.previousEno.isContentDiff(factoryState.eno)) {
            batchCallbacks.push(function () { return factoryState.eno; });
        }
        // Add in the patch to remove the placeholder references
        batchCallbacks.push(function () { return _this.makePlaceholderPatch(factoryState, originalReferencedFactories, actuallyReferencedTips); });
        return { writeCallbacks: batchCallbacks, deleteCallbacks: deletedCallbacks };
    };
    // Makes a placeholder eno for temporary use
    ObjectService.prototype.makePlaceholderEno = function (factoryState, fieldTip) {
        var fieldScheme = factoryState.scheme[fieldTip];
        if (!fieldScheme) {
            return null;
        }
        var placeholder = fieldScheme.circularPlaceholder;
        if (!placeholder) {
            return null;
        }
        var placeholderFactory = new EnoFactory();
        placeholderFactory.setType(placeholder.$type);
        placeholderFactory.setSecurity(factoryState.securityPolicy);
        placeholderFactory.setBranch(factoryState.branch);
        return placeholderFactory.makeEno();
    };
    // Make the patch eno that removes the references to temporary placeholders, and restores the correct references
    ObjectService.prototype.makePlaceholderPatch = function (factoryState, originalReferencedFactories, actuallyReferencedTips) {
        var objectFieldTips = Object.keys(factoryState.referencedFactories || {});
        var doPatch = false;
        var patchFactory = new EnoFactory();
        patchFactory.setProtoToPatch(factoryState.eno);
        objectFieldTips.forEach(function (fieldTip) {
            var originalReferencedTips = originalReferencedFactories[fieldTip].map(function (factory) { return typeof factory === 'string' ? factory : factory.eno.tip; });
            if (!isEqual(originalReferencedTips, actuallyReferencedTips[fieldTip])) {
                patchFactory.setField(fieldTip, originalReferencedTips);
                doPatch = true;
            }
        });
        if (doPatch) {
            return patchFactory.makeEno();
        }
        return null;
    };
    // Makes the delete eno that actually removes the temporary placeholder
    ObjectService.prototype.makePlaceholderDelete = function (placeholderEno) {
        var deleteFactory = new EnoFactory();
        deleteFactory.setProtoToPatch(placeholderEno);
        deleteFactory.setDeleted(true);
        return deleteFactory.makeEno();
    };
    // Convert an eno factory template and object to a proper factory template
    ObjectService.prototype.objectToEnoFactory$ = function (factoryState) {
        var _this = this;
        // Just return if we've already seen this object
        var seen = find(factoryState.seen, function (i) { return i.input === factoryState.input; });
        if (seen) {
            return of(seen);
        }
        // Make a clean factory state based on our existing one
        factoryState = tslib_1.__assign({}, factoryState, { enoFactory: null, eno: null, referencedFactories: {} });
        // Mark this object as seen
        factoryState.seen.push(factoryState);
        return this.makeEnoFactoryFromObject$(factoryState).pipe(map(function (enoFactory) { return _this.setBranch(factoryState, enoFactory); }), switchMap(function (enoFactory) { return _this.setSecurityPolicy(factoryState, enoFactory); }), switchMap(function (enoFactory) { return _this.setFields(factoryState, enoFactory); }), mapTo(factoryState));
    };
    ObjectService.prototype.setFields = function (factoryState, enoFactory) {
        var _this = this;
        return forkJoin(Object.keys(factoryState.scheme).map(function (fieldTip) { return _this.setEnoFactoryField$(tslib_1.__assign({}, factoryState, { fieldTip: fieldTip })); })).pipe(mapTo(enoFactory));
    };
    ObjectService.prototype.setBranch = function (factoryState, enoFactory) {
        // Set the branch if provided
        if (factoryState.input.$branch) {
            enoFactory.setBranch(factoryState.input.$branch);
        }
        else if (factoryState.branch) {
            enoFactory.setBranch(factoryState.branch);
        }
        else if (!factoryState.input.$tip) {
            enoFactory.setBranch('branch/master');
        }
        return enoFactory;
    };
    ObjectService.prototype.setSecurityPolicy = function (factoryState, enoFactory) {
        // Set the security if provided
        if (factoryState.input.$security) {
            // new security from the input object by $security
            enoFactory.setSecurity(factoryState.input.$security);
        }
        else if (factoryState.securityPolicy) {
            // new security from setObject securityPolicy argument
            enoFactory.setSecurity(factoryState.securityPolicy);
        }
        else if (!factoryState.input.$tip) {
            // new eno uses the default security policy from the profile
            return this.defaultSecurityPolicy$().pipe(map(function (securityPolicyTip) {
                // Set the security policy if there is one
                if (!securityPolicyTip) {
                    throw new Error('[ObjectService] We don\'t have a default policy in our profile');
                }
                enoFactory.setSecurity(securityPolicyTip);
                return enoFactory;
            }));
        }
        return of(enoFactory);
    };
    // Creates a new ENO factory for an object considering if it is a new object or an update
    ObjectService.prototype.makeEnoFactoryFromObject$ = function (factoryState) {
        var enoFactory = new EnoFactory();
        factoryState.enoFactory = enoFactory;
        if (factoryState.input.$tip) {
            return this.enoService.readEno(factoryState.input.$tip, { useCache: true }).pipe(first(), tap(function (eno) { return factoryState.previousEno = eno; }), map(function (eno) { return enoFactory.setProtoToPatch(eno); }), mapTo(enoFactory));
        }
        if (factoryState.input.$type) {
            enoFactory.setType(factoryState.input.$type);
            return of(enoFactory);
        }
        return throwError(new Error('[ObjectService] All new objects must have a $type'));
    };
    ObjectService.prototype.setEnoFactoryFieldSubObject$ = function (factoryState) {
        var fieldScheme = factoryState.scheme[factoryState.fieldTip];
        var name = fieldScheme.name || this.fieldTipToName(factoryState.fieldTip);
        var originalValue = factoryState.input[name];
        if (typeof originalValue === 'string') {
            factoryState.enoFactory.setField(factoryState.fieldTip, [originalValue]);
            return of(null);
        }
        if (fieldScheme.mutable) {
            originalValue.$security = originalValue.$security || factoryState.input.$security;
            return this.objectToEnoFactory$(tslib_1.__assign({}, factoryState, { input: originalValue, scheme: fieldScheme.scheme })).pipe(tap(function (subEnoFactory) { return factoryState.referencedFactories[factoryState.fieldTip] = [subEnoFactory]; }));
        }
        if (originalValue.$tip) {
            factoryState.enoFactory.setField(factoryState.fieldTip, [originalValue.$tip]);
            return of(null);
        }
        return throwError(new Error('Attempted to create a new object in a immmutable sub-object: ' + factoryState.fieldTip));
    };
    ObjectService.prototype.setEnoFactoryFieldSubObjectArray$ = function (factoryState) {
        var _this = this;
        var fieldScheme = factoryState.scheme[factoryState.fieldTip];
        var name = fieldScheme.name || this.fieldTipToName(factoryState.fieldTip);
        var originalValue = factoryState.input[name];
        if (fieldScheme.mutable) {
            return forkJoin(originalValue.map(function (subValue) {
                if (typeof subValue === 'string') {
                    return subValue;
                }
                subValue.$security = subValue.$security || factoryState.input.$security;
                return _this.objectToEnoFactory$(tslib_1.__assign({}, factoryState, { input: subValue, scheme: fieldScheme.scheme }));
            })).pipe(tap(function (subEnoFactories) { return factoryState.referencedFactories[factoryState.fieldTip] = subEnoFactories; }));
        }
        if (originalValue.filter(function (sub) { return (typeof sub !== 'string' && !sub.$tip); }).length > 0) {
            return throwError(new Error('Attempted to create a new object in a immutable sub-object-array: ' + factoryState.fieldTip));
        }
        factoryState.enoFactory.setField(factoryState.fieldTip, originalValue.map(function (sub) { return typeof sub === 'string' ? sub : sub.$tip; }));
        return of(null);
    };
    // Set field data on an eno factory
    ObjectService.prototype.setEnoFactoryField$ = function (factoryState) {
        var fieldScheme = factoryState.scheme[factoryState.fieldTip];
        var name = fieldScheme.name || this.fieldTipToName(factoryState.fieldTip);
        var originalValue = factoryState.input[name];
        // Field value is not given
        if (!factoryState.input.hasOwnProperty(name)) {
            return of(true);
        }
        // Specifically name property is given but null or undefined. Or is it an empty array
        if (originalValue === null || originalValue === undefined || (Array.isArray(originalValue) && originalValue.length === 0)) {
            factoryState.enoFactory.setField(factoryState.fieldTip, []);
            return of(null);
        }
        // Give a number field an empty value, [], if the originalValue is ''.
        if (fieldScheme.type === DataTypes.number && originalValue === '') {
            factoryState.enoFactory.setField(factoryState.fieldTip, []);
            return of(null);
        }
        if (fieldScheme.type === DataTypes.i18nObject) {
            factoryState.enoFactory.setField({ tip: factoryState.fieldTip, i18n: originalValue });
            return of(null);
        }
        // The field is object field. May need recursive call
        if (fieldScheme.type === DataTypes.object) {
            return this.setEnoFactoryFieldSubObject$(factoryState);
        }
        // The field is object field but potentially multiple values. May need recursive call
        if (fieldScheme.type === DataTypes.objectArray) {
            return this.setEnoFactoryFieldSubObjectArray$(factoryState);
        }
        // Depending on the given scheme datatype, parse value
        var parsedValue = this.parseValue(originalValue, fieldScheme);
        // Datatype is i18n. need to set as I18n.
        if (fieldScheme.type === DataTypes.i18n || fieldScheme.type === DataTypes.i18nArray) {
            factoryState.enoFactory.setI18nValue(factoryState.fieldTip, parsedValue);
            return of(null);
        }
        // Datatype is a formula
        if (fieldScheme.type === DataTypes.formula || fieldScheme.type === DataTypes.formulaArray) {
            factoryState.enoFactory.setFieldFormula(factoryState.fieldTip, parsedValue);
            return of(null);
        }
        // All other cases. Just set the given value
        factoryState.enoFactory.setField(factoryState.fieldTip, parsedValue);
        return of(null);
    };
    // Delete a single object
    ObjectService.prototype.deleteObject = function (input) {
        return this.deleteObjects([input]);
    };
    // Delete multiple objects
    ObjectService.prototype.deleteObjects = function (inputs) {
        return this.enoService.deleteEnos(inputs.map(function (input) { return input.$tip; })).pipe(mapTo(true));
    };
    ObjectService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ObjectService_Factory() { return new ObjectService(i0.ɵɵinject(i1.EnoService), i0.ɵɵinject(i2.SessionManagerService), i0.ɵɵinject(i3.EnsrvService), i0.ɵɵinject(i4.OpPullService)); }, token: ObjectService, providedIn: "root" });
    return ObjectService;
}());
export { ObjectService };
