import * as tslib_1 from "tslib";
import { first, map, switchMap, tap, toArray } from 'rxjs/operators';
import { EnoFactory } from '../data/EnoFactory';
import { TailLogs } from './tail-logs';
import * as moment from 'moment';
import { convertToCSV } from './csv/csv-util';
import { chunk, flatten, head, sortBy, get, uniq, zipObject } from 'lodash';
import { combineLatest, concat, of } from 'rxjs';
import { extractJSONFromBuildJSON } from './un-build-json/un-build-json';
import { PROCESS_NODE_TYPE } from '../settings/workflow-designer/workflow-designer-enums';
import { GoJSLinks } from '../settings/workflow-designer/gojs-panels/gojs-links';
import { DiagramUtils } from '../settings/workflow-designer/utils/diagram-utils';
import { GraphLinksModel } from 'gojs';
import { ConnectionUtils } from '../settings/workflow-designer/utils/connection-utils';
import * as i0 from "@angular/core";
import * as i1 from "../data/session-manager.service";
import * as i2 from "../data/eno.service";
import * as i3 from "../data/process.service";
import * as i4 from "../data/formula.service";
import * as i5 from "../data/query.service";
import * as i6 from "@angular/common";
import * as i7 from "../settings/workflow-designer/workflow-designer.service";
import * as i8 from "../data/op-pull.service";
import * as i9 from "../data/ensrv.service";
var ConsoleService = /** @class */ (function () {
    function ConsoleService(sessionManagerService, enoService, processService, formulaService, queryService, document, workflowDesignerService, opPullService, enSrvService) {
        this.sessionManagerService = sessionManagerService;
        this.enoService = enoService;
        this.processService = processService;
        this.formulaService = formulaService;
        this.queryService = queryService;
        this.document = document;
        this.workflowDesignerService = workflowDesignerService;
        this.opPullService = opPullService;
        this.enSrvService = enSrvService;
        this._tailLogs = new TailLogs();
    }
    ConsoleService.prototype.formula = function (formula, options) {
        if (!options) {
            options = {};
        }
        this.formulaService.evaluate(formula, options.contextTip, options.contextBranches, options.vars).pipe(first()).subscribe(function (formulaResult) {
            if (options && options.callback) {
                options.callback(formulaResult);
            }
            else {
                console.log(formulaResult);
            }
        }, function (err) {
            if (options && options.callback) {
                options.callback(null, err);
            }
            else {
                console.error('[en-console] Failed to evaluate formula:', formula, err);
            }
        });
    };
    ConsoleService.prototype.formulaPromise = function (formula, options) {
        if (!options) {
            options = {};
        }
        return this.formulaService.evaluate(formula, options.contextTip, options.contextBranches, options.vars).pipe(first()).toPromise();
    };
    ConsoleService.prototype.tip = function (tip, options) {
        var _this = this;
        var readEnoOptions = {
            branch: get(options, 'branch', undefined),
            recursiveDepth: get(options, 'recursiveDepth', undefined),
            recursiveFields: get(options, 'recursiveFields', undefined)
        };
        this.enoService.readEno(tip, readEnoOptions).pipe(first()).subscribe(function (eno) {
            if (options && options.callback) {
                options.callback(eno);
            }
            else if (options && options.condensed === false) {
                console.log(eno);
            }
            else {
                console.log(_this.condenseEno(eno));
            }
        }, function (err) {
            if (options && options.callback) {
                options.callback(null, err);
            }
            else {
                console.error('[en-console] Failed to get eno:', tip, err);
            }
        });
    };
    ConsoleService.prototype.sid = function (sid, options) {
        var opPullOptions = tslib_1.__assign({}, options, { sid: [sid] });
        var pullEno = this.opPullService.createOpPull(opPullOptions);
        this
            .enSrvService
            .send([pullEno])
            .subscribe(function (resultBatch) {
            resultBatch.forEach(function (eno) {
                if (eno.sid === sid) {
                    console.log(eno);
                }
            });
        }, function (err) {
            console.error('[en-console] Failed to get eno:', sid, err);
        });
    };
    ConsoleService.prototype.tipPromise = function (tip, options) {
        var readEnoOptions = { branch: options ? options.branch : undefined };
        return this.enoService.readEno(tip, readEnoOptions).pipe(first()).toPromise();
    };
    ConsoleService.prototype.patch = function (tip, data, options) {
        var _this = this;
        var branch = options ? options.branch : undefined;
        this.enoService.readEno(tip, { branch: branch }).pipe(first()).pipe(switchMap(function (oldEno) {
            var enoFactory = new EnoFactory();
            enoFactory.setProtoToPatch(oldEno);
            Object.keys(data).filter(function (fieldTip) {
                return data[fieldTip] !== undefined && data[fieldTip] !== null;
            }).forEach(function (fieldTip) {
                if (data[fieldTip].constructor === Array) {
                    enoFactory.setField(fieldTip, data[fieldTip].map(function (value) { return '' + value; }));
                }
                else if (typeof data[fieldTip] === 'string' || typeof data[fieldTip] === 'number') {
                    enoFactory.setField(fieldTip, ['' + data[fieldTip]]);
                }
                else if (data[fieldTip].formula) {
                    enoFactory.setFieldFormula(fieldTip, data[fieldTip].formula.constructor === Array ? data[fieldTip].formula : [data[fieldTip].formula]);
                }
                else if (data[fieldTip].i18n) {
                    data[fieldTip].i18n.forEach(function (i18n) {
                        enoFactory.setI18nValue(fieldTip, i18n.value, i18n.lang);
                    });
                }
            });
            return _this.enoService.writeEno(enoFactory.makeEno());
        })).subscribe(function (batch) {
            if (options && options.callback) {
                options.callback(batch);
            }
            else {
                console.log(batch);
            }
        }, function (err) {
            if (options && options.callback) {
                options.callback(null, err);
            }
            else {
                console.error('[en-console] Failed to patch eno:', tip, err);
            }
        });
    };
    ConsoleService.prototype.startProcess = function (processTip, vars, callback) {
        var processVars = {};
        Object.keys(vars).forEach(function (key) {
            var values = (vars[key].constructor === Array ? vars[key] : [vars[key]]);
            processVars[key] = values.map(function (value) { return '' + value; });
        });
        this.processService.start(processTip, processVars).pipe(first()).subscribe(function (processResponse) {
            if (callback) {
                callback(processResponse);
            }
            else {
                console.log(processResponse);
            }
        }, function (err) {
            if (callback) {
                callback(null, err);
            }
            else {
                console.error('[en-console] Failed to start process:', processTip, vars, err);
            }
        });
    };
    ConsoleService.prototype.addUpdateStageNode = function (workflowObj) {
        if (workflowObj.nodes.some(function (node) { return node.processNodeType === PROCESS_NODE_TYPE.UPDATE_STAGE; })) {
            // tslint:disable-next-line:max-line-length
            console.warn("Workflow " + workflowObj.$tip + " ( " + workflowObj.name + ") already has Update Object Stage node, possibly already migrated, ignoring...");
            return of({ workflowObj: workflowObj, unchanged: true });
        }
        var workflowUXNodesWithStage = workflowObj.nodes.filter(function (node) { return node.processNodeType === PROCESS_NODE_TYPE.WORKFLOW_UX && !!get(node, 'fields.stage', false); });
        if (workflowUXNodesWithStage.length === 0) {
            // tslint:disable-next-line:max-line-length
            console.warn("Workflow " + workflowObj.$tip + " ( " + workflowObj.name + ") doesn't have Workflow UX node with stage, ignoring...");
            return of({ workflowObj: workflowObj, unchanged: true });
        }
        console.warn("Adding Update Object Stage node(s) to workflow " + workflowObj.$tip + " ( " + workflowObj.name + ")");
        var diagramData = workflowObj.diagramData;
        var currentKey = Math.max.apply(Math, tslib_1.__spread(workflowObj.nodes.map(function (node) { return parseInt(node.tip, 10); })));
        var stageTips = uniq(workflowUXNodesWithStage.map(function (node) { return node.fields.stage; }));
        var titleFormula = "CONTEXT(VAR(\"Stage tips\"), TITLE())";
        return this.formulaService
            .evaluate(titleFormula, undefined, undefined, { 'Stage tips': stageTips })
            .pipe(map(function (stageTitles) {
            var stages = zipObject(stageTips, stageTitles);
            workflowUXNodesWithStage.forEach(function (node) {
                var stage = node.fields.stage;
                if (!stage) {
                    return;
                }
                var formula = node.fields.contextObjectFormula;
                var objectType = node.fields.contextObjectType;
                var objectTypeSelectedKey = node.fields.contextObjectSelectedPropertyKey;
                var nodeEditorTemplate = {
                    processNodeType: 'UpdateStage',
                    heading: 'Update object stage',
                    fields: {
                        title: "Change stage to " + stages[stage],
                        formula: formula,
                        objectType: objectType,
                        objectTypeSelectedKey: objectTypeSelectedKey,
                        stage: stage
                    }
                };
                var nodeDataTemplate = {
                    portConfigs: [
                        { name: 'TopMiddle', direction: 3 },
                        { name: 'LeftMiddle', direction: 3 },
                        { name: 'RightMiddle', direction: 3 },
                        { name: 'BottomMiddle', direction: 3 },
                        { name: 'TopLeft', direction: 3 },
                        { name: 'TopRight', direction: 3 },
                        { name: 'BottomLeft', direction: 3 },
                        { name: 'BottomRight', direction: 3 },
                    ],
                    text: "Change stage to " + stages[stage],
                    heading: 'Update object stage',
                    type: 'StepNode',
                    loc: '0 0',
                    fill: '#12C598',
                    iconCode: '',
                    iconSize: 12,
                    linkTypes: [
                        { name: 'Finished', value: 'done', hasDivider: false },
                        { name: 'Failed', value: 'failed', hasDivider: false },
                        { name: 'Always', value: 'finally', hasDivider: false },
                    ],
                    info: {
                        mainHeading: 'Update object stage',
                        heading: "Change stage to " + stages[stage],
                        items: []
                    },
                    editorComponentName: 'UpdateStageEditorComponent'
                };
                var linkTemplate = {
                    points: [0, 0, 0, 0],
                    text: 'Finished',
                    value: 'done',
                    delaySec: 0
                };
                var linkDataArray = diagramData.linkDataArray.filter(function (link) { return link.from === node.tip; });
                if (linkDataArray.length === 0) {
                    // if the workflow UX node does not have connections
                    currentKey++;
                    var keyString = currentKey.toString();
                    // add outgoing link
                    diagramData.linkDataArray.push(tslib_1.__assign({}, linkTemplate, { from: node.tip, to: keyString }));
                    // add new nodeData
                    diagramData.nodeDataArray.push(tslib_1.__assign({}, nodeDataTemplate, { key: keyString }));
                    // add new node
                    workflowObj.nodes.push(tslib_1.__assign({}, nodeEditorTemplate, { tip: keyString }));
                }
                linkDataArray.forEach(function (link) {
                    currentKey++;
                    var keyString = currentKey.toString();
                    var targetKey = link.to;
                    // modify incoming link
                    link.to = keyString;
                    link.points = [0, 0, 0, 0];
                    // add outgoing link
                    diagramData.linkDataArray.push(tslib_1.__assign({}, linkTemplate, { from: keyString, to: targetKey }));
                    // add new nodeData
                    diagramData.nodeDataArray.push(tslib_1.__assign({}, nodeDataTemplate, { key: keyString }));
                    // add new node
                    workflowObj.nodes.push(tslib_1.__assign({}, nodeEditorTemplate, { tip: keyString }));
                });
                // add isGoverned to Workflow UX node
                node.fields.isGoverned = true;
            });
            return {
                workflowObj: tslib_1.__assign({}, workflowObj, { diagramData: diagramData, nodes: workflowObj.nodes }),
                unchanged: false
            };
        }));
    };
    // can supply tips or formula which resolves to workflow tips
    ConsoleService.prototype.convertWorkflows = function (_a) {
        var _this = this;
        var tips = _a.tips, formula = _a.formula, updateWorkflowFunc = _a.updateWorkflowFunc, dryRun = _a.dryRun, ignoreUnchangedWorkflow = _a.ignoreUnchangedWorkflow;
        if (!tips && !formula) {
            throw new Error('Need either tips or formula!');
        }
        var moduleFormula = "REFERENCES(\"app/module:workflows\", VAR(\"Workflow tip\"))";
        var counter = 1;
        var total = 0;
        var getTips = function () {
            if (tips) {
                return of(tips);
            }
            return _this.formulaService.evaluate(formula);
        };
        var convertEnoToWorkflow = function (workflowEno) {
            var workflowData = {
                $tip: workflowEno.tip,
                name: workflowEno.getFieldStringValue('app/workflow:name'),
                description: workflowEno.getFieldStringValue('app/workflow:description'),
                diagramData: workflowEno.getFieldJsonValue('app/workflow:diagramdata'),
                isAdminMode: workflowEno.getFieldBooleanValue('app/workflow:isAdminMode'),
                nodes: workflowEno.getFieldValues('app/workflow:nodes').map(function (v) { return JSON.parse(v); }),
                inputs: workflowEno.getFieldValues('app/workflow:inputs').map(function (v) { return JSON.parse(v); }),
                actors: workflowEno.getFieldValues('app/workflow:actors').map(function (v) { return JSON.parse(v); }),
                variables: workflowEno.getFieldValues('app/workflow:variables').map(function (v) { return JSON.parse(v); }),
            };
            if (updateWorkflowFunc) {
                return updateWorkflowFunc.call(_this, workflowData);
            }
            return of({ workflowObj: workflowData, unchanged: true });
        };
        var saveWorkflow = function (_a) {
            var workflowObj = _a.workflowObj, unchanged = _a.unchanged;
            return _this.formulaService.evaluate(moduleFormula, undefined, undefined, { 'Workflow tip': [workflowObj.$tip] }).pipe(
            // tslint:disable-next-line:max-line-length
            tap(function () { return console.warn((dryRun ? '[ DRY-RUN ] ' : '') + "[ " + counter++ + " of " + total + " ] Converting " + workflowObj.$tip + " ( " + workflowObj.name + ")"); }), switchMap(function (_a) {
                var _b = tslib_1.__read(_a, 1), moduleTip = _b[0];
                if (unchanged && ignoreUnchangedWorkflow) {
                    console.warn("Not converting workflow " + workflowObj.$tip + " ( " + workflowObj.name + ") since it is not changed.");
                    return of({});
                }
                var diagram = DiagramUtils.getDiagram(new GoJSLinks());
                diagram.model = new GraphLinksModel(workflowObj.diagramData.nodeDataArray, workflowObj.diagramData.linkDataArray);
                diagram.model.modelData = { nodeEditorDataList: workflowObj.nodes };
                var connectionUtils = new ConnectionUtils(diagram, null);
                var nodeDataForProcess = connectionUtils.getNodeEditorDataListFromStartNode();
                return _this.workflowDesignerService.saveWorkflow(workflowObj, nodeDataForProcess, moduleTip, dryRun);
            }), 
            // tslint:disable-next-line:no-console
            tap(function (convertedWorkflow) { return dryRun ? console.info(convertedWorkflow) : true; }), first());
        };
        getTips()
            .pipe(switchMap(function (workflowTips) { return _this.enoService.readEnos(workflowTips); }), first(), switchMap(function (enos) { return combineLatest(enos.map(function (eno) { return convertEnoToWorkflow(eno); })); }), tap(function (workflows) { return total = workflows.length; }), switchMap(function (workflows) { return concat.apply(void 0, tslib_1.__spread(workflows.map(function (workflow) { return saveWorkflow(workflow); }))).pipe(toArray()); })).subscribe(function () { return console.warn('Done!'); });
    };
    Object.defineProperty(ConsoleService.prototype, "tailLogs", {
        get: function () {
            // update session id
            this._tailLogs.setSessionId(this.sessionManagerService.getSessionId());
            // return a const so you get code completion in the console
            return this._tailLogs;
        },
        enumerable: true,
        configurable: true
    });
    ConsoleService.prototype.condenseEno = function (eno) {
        var output = { $tip: eno.tip };
        if (eno.source) {
            output.$type = eno.source.type;
            output.$security = eno.source.security;
            eno.source.field.forEach(function (field) {
                var key = field.tip;
                if (key.length > eno.source.type.length && key.substr(0, eno.source.type.length) === eno.source.type) {
                    key = key.substr(eno.source.type.length + 1, key.length - eno.source.type.length - 1);
                }
                if (field.value) {
                    output[key] = (field.value.length > 1 ? field.value : field.value[0]);
                }
                else if (field.i18n && field.i18n.length > 0) {
                    output[key] = (field.i18n[0].value.length > 1 ? field.i18n[0].value : field.i18n[0].value[0]);
                }
                else if (field.formula && field.formula.length > 0) {
                    output[key] = field.formula[0];
                }
            });
        }
        return output;
    };
    // 'getAllModuleConfig' generates a csv of all module config accessible by the user in the current instance
    // A series of tasks are performed in this method to get the module config:
    // 1. Collect all module tips via 'lookup' formula
    // 2. Split module tips into chunks of 50
    // 3. For every module chunk, create observable which executes 'get-module-config' query using the module tips in current chunk
    // 4. Run queries one after the other using rxjs 'concat'
    // 5. Flatten, format, and sort results
    // 6. Pass results onto exportCSVFile method
    ConsoleService.prototype.getAllModuleConfig = function (fileTitle) {
        var _this = this;
        if (fileTitle === void 0) { fileTitle = "module-config-" + moment().format('LL'); }
        var options = {
            dimensionOptions: [{
                    label: 'Tip dimension',
                    formula: 'TIP()',
                    sortby: [],
                    sortdir: ['asc'],
                    offset: 0,
                    limit: 10000
                }]
        };
        this.formulaService
            .evaluate('LOOKUP(TIP(), ISTYPE("app/module"))')
            .pipe(first(), map(function (moduleTips) { return chunk(moduleTips, 50); }), map(function (moduleChunks) { return moduleChunks
            .map(function (moduleChunk) { return _this.queryService
            .execute1dArray('eim/query/get-module-config', Object.assign(options, { vars: { 'Module tips': moduleChunk } }))
            .pipe(first()); }); }), switchMap(function (moduleConfigQueries$) { return concat.apply(void 0, tslib_1.__spread(moduleConfigQueries$)).pipe(toArray(), map(function (moduleConfigResults) { return flatten(moduleConfigResults); })); }), map(function (results) { return sortBy(flatten(results
            .map(function (result) {
            return result
                .configTitles
                .map(function (_, i) { return ({
                type: result.configType[i] || '-',
                name: result.configTitles[i] ? result.configTitles[i].replace(/,/g, '') : '-',
                module: head(result.title) ? head(result.title).replace(/,/g, '') : '-',
                modifiedDate: result.configModifiedDate[i]
                    ? moment(Number(result.configModifiedDate[i])).format('LL').replace(/,/g, '')
                    : '-'
            }); });
        })), ['type', 'module', 'name']); }))
            .subscribe(function (results) {
            _this.exportCSVFile(fileTitle, results, {
                type: 'Type',
                name: 'Name',
                module: 'Module',
                modifiedDate: 'Last modified date & time'
            });
        });
    };
    ConsoleService.prototype.unBuildJSON = function (_string) {
        var result = extractJSONFromBuildJSON(_string);
        var object = JSON.parse(result.replace(/\\\\/gi, "\\"));
        console.log(JSON.stringify(object, null, 4));
        console.log('use: https://json-to-js.com if you need to load in your editor');
    };
    ConsoleService.prototype.exportCSVFile = function (fileTitle, items, headers) {
        if (headers) {
            items.unshift(headers);
        }
        var csvContent = convertToCSV(items);
        var link = this.document.createElement('a');
        var url = URL.createObjectURL(new Blob([csvContent], { type: 'text/csv;charset=utf-8;' }));
        link.setAttribute('href', url);
        link.setAttribute('download', fileTitle + '.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    ConsoleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConsoleService_Factory() { return new ConsoleService(i0.ɵɵinject(i1.SessionManagerService), i0.ɵɵinject(i2.EnoService), i0.ɵɵinject(i3.ProcessService), i0.ɵɵinject(i4.FormulaService), i0.ɵɵinject(i5.QueryService), i0.ɵɵinject(i6.DOCUMENT), i0.ɵɵinject(i7.WorkflowDesignerService), i0.ɵɵinject(i8.OpPullService), i0.ɵɵinject(i9.EnsrvService)); }, token: ConsoleService, providedIn: "root" });
    return ConsoleService;
}());
export { ConsoleService };
