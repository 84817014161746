import { Component, ViewChild, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { SideSheetListComponent } from '../../../side-sheet/side-sheet-list/side-sheet-list.component';
import { Observable, of } from 'rxjs';
import { map, first, switchMap } from 'rxjs/operators';
import { head, isEmpty } from 'lodash';
import { INTERPOLATION_TYPE, ISubstitutionCandidate } from '../../../form/string-interpolation/string-interpolation.service';
import { getModuleOptionsFormula, getModuleOptionsLabel } from './module-options';
import { Tip } from '../../../data/models/types';
import { ObjectService } from '../../../data/object-service/object.service';
import { FormulaService } from '../../../data/formula.service';
import { typeScheme, IType } from '../../../models/type';
import { IField } from '../../../models/field';

@Component({
  selector: 'app-assign-to-module-options-side-sheet',
  templateUrl: './assign-to-module-options-side-sheet.component.html',
  styleUrls: ['./assign-to-module-options-side-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssignToModuleOptionsSideSheetComponent implements OnInit {
  @ViewChild('sideSheetList', {static: false}) sideSheetList: SideSheetListComponent;

  moduleTip: Tip;
  moduleOptionsFields$: Observable<IField[]>;

  private selectedLabel: string;

  done: (substitutionCandidate: ISubstitutionCandidate) => void = _ => {};

  constructor(
    private objectService: ObjectService,
    private formulaService: FormulaService
  ) {}

  setProps({ moduleTip }) {
    this.moduleTip = moduleTip;
  }

  ngOnInit() {
    if (!this.moduleTip) {
      return;
    }

    this.moduleOptionsFields$ = this
      .formulaService
      .evaluate('FIELD("app/object-type:type", FIELD("app/module:options-object-type"))', this.moduleTip)
      .pipe(
        first(),
        map(head),
        switchMap((moduleOptionsObjectTypeTypeTip: Tip) => {
          return moduleOptionsObjectTypeTypeTip
            ? this.getTypeFields$(moduleOptionsObjectTypeTypeTip)
            : of([]);
        })
      );
  }

  onDone() {
    if (isEmpty(this.sideSheetList.selected)) {
      this.done(null);
      return;
    }

    const selectedKey = head(this.sideSheetList.selected);

    this.done({
      type: INTERPOLATION_TYPE.general,
      label: getModuleOptionsLabel(this.selectedLabel),
      value: getModuleOptionsFormula(selectedKey, 'key')
    });
  }

  setSelectedLabel(fieldLabel: string) {
    this.selectedLabel = fieldLabel;
  }

  private getTypeFields$(moduleOptionsObjectTypeTypeTip: Tip): Observable<IField[]> {
    return this
      .objectService
      .getObject(moduleOptionsObjectTypeTypeTip, typeScheme)
      .pipe(
        first(),
        map((moduleOptionsObjectTypeTip: IType) => moduleOptionsObjectTypeTip.field || [])
      );
  }
}
