import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { addressScheme, IAddress, IGISFeature, IGISResult } from '../../models/map';
import { Point } from 'geojson';
import { Observable, of, ReplaySubject } from 'rxjs';
import { isEmpty, cloneDeep } from 'lodash';
import { map, catchError, distinctUntilChanged, retry, switchMap, tap, first } from 'rxjs/operators';
import { Tip } from '../../data/models/types';
import dataConstants from '../../data/constants';
import { ObjectService } from '../../data/object-service/object.service';
import { ISessionInfo, SessionManagerService } from '../../data/session-manager.service';
import { environment } from '../../../environments/environment';
import { LoggerService } from '../../util/logger.service';

// export const gisServicePath = `http://localhost:8888${ environment.gisServicePath }`;
export const gisServicePath = `${ environment.host }${ environment.gisServicePath }`;

@Injectable({
  providedIn: 'root'
})
export class GisService {
  sessionInfo: ISessionInfo;
  browserSupportsGeolocation = ('geolocation' in navigator);
  geolocation$: ReplaySubject<Point>;

  constructor(
    private httpClient: HttpClient,
    private objectService: ObjectService,
    private sessionManager: SessionManagerService,
    private loggerService: LoggerService
  ) {
    this.sessionManager.getSessionInfo$().pipe(
      retry(3)
    ).subscribe(
      sessionInfo => this.sessionInfo = sessionInfo
    );
  }

  getAddress(addressTip: Tip): Observable<IAddress> {
    return this.objectService.getObject(addressTip, addressScheme);
  }

  setAddress(address: IAddress): Observable<Tip> {
    const newAddress: IAddress = cloneDeep(address);
    delete newAddress.$tip;
    delete newAddress.$sid;
    // this will create a eno with empty nonce in EMPTY_NONCE_TYPES in EnoFactory.ts
    return this.objectService.setObject(
      newAddress,
      addressScheme,
      dataConstants.BRANCH_MASTER,
      'app/security-policy/instance-user-runtime'
    ).pipe(map(([{ tip }]) => tip));
  }

  newAddress(displayStreet?: string, point?: Point, meta?: IGISFeature): IAddress {
    return <IAddress>{
      $type: 'app/address',
      displayStreet: displayStreet || '',
      point: point || null,
      meta: meta || null
    };
  }

  sendGeocodeRequest(queryTerm: string): Observable<IGISFeature[]> {
    const encodedQueryURI = `${ encodeURIComponent(queryTerm) }.json`;
    const url = `${ gisServicePath }/geocode/${ encodedQueryURI }`;

    return this.getUserLocation().pipe(
      first(),
      map(point => ({ proximity: point.coordinates.join(',') })),
      catchError(() => of({})),
      switchMap(proximity => this.httpClient.get(url, {
          headers: this.getAuthHeaders(),
          params: { autocomplete: 'true', ...proximity }
      })),
      distinctUntilChanged(),
      map(({ features }: IGISResult) => features),
      catchError(err => {
        this.loggerService.error(`Unable to reach gis service: ${ err.message }`);
        return of(err);
      })
    );
  }

  getGeocodeResult(queryTerm: string): Observable<IAddress> {
    return this.sendGeocodeRequest(queryTerm).pipe(
      map(([meta]: [IGISFeature]) => {
        let displayStreet: string;
        let point: Point;
        if (meta) {
          displayStreet = meta.place_name;
          point = meta.geometry;
        } else {
          displayStreet = null;
          point = null;
        }

        return { point, displayStreet, meta };
      })
    );
  }

  reverseGeocode(point: Point): Observable<IAddress> {
    return this.getGeocodeResult(`${ point.coordinates[0] },${ point.coordinates[1] }`).pipe(
      map(({ displayStreet, meta }) => this.newAddress(displayStreet, point, meta))
    );
  }

  getAutocompleteResult(searchTerm: string): Observable<IAddress[]> {
    if (isEmpty(searchTerm)) {
      return of([]);
    }

    return this.sendGeocodeRequest(searchTerm).pipe(
      map((features: IGISFeature[]) => features.map(meta => this.newAddress(meta.place_name, meta.geometry, meta))),
      catchError(() => of([]))
    );
  }

  getUserLocation(): Observable<Point> {
    if (!this.geolocation$) {
      this.geolocation$ = new ReplaySubject<Point>(1);
      if (this.browserSupportsGeolocation) {
        navigator.geolocation.watchPosition(
          position => {
            if (!position) {
              throw new Error('Unable to locate');
            }
            this.geolocation$.next({
              type: 'Point',
              coordinates: [position.coords.longitude, position.coords.latitude]
            });
          },
          error => {
            this.geolocation$.error(new Error('Unable to locate: ' + error.message));
          },
          {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0
          }
        );
      } else {
        this.geolocation$.error(new Error('Unable to locate as the browser does not support it or has declined'));
      }
    }
    return this.geolocation$;
  }

  // Retrieve the authorization headers
  getAuthHeaders() {
    if (!this.sessionInfo) {
      throw new Error('Attempting to authorize against the gis service without a session token');
    }
    return {
      Authorization: `Bearer ${ this.sessionInfo.token }`,
      'en-namespace': environment.ns
    };
  }

  getQueryLayerUrl(layerTip: Tip) {
    return `${ gisServicePath }/esri/layer/${ environment.ns }/${ encodeURIComponent(layerTip) }`;
  }
}
