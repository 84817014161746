<div class="d-flex align-items-center">
  <div class="search-input flex-grow-1">
    <span class="search-input-icon mdi mdi-search"></span>
    <input
        class="form-control"
        type="text"
        [ngModel]="filterText"
        (ngModelChange)="filterTextChange.emit($event)">
  </div>
  <button
      *ngIf="showAdd"
      class="eim-button_minimized btn btn-secondary btn-add ml-3"
      data-test="filter-and-add-button"
      (click)="add.emit()">
    <mat-icon svgIcon="plus"></mat-icon>
  </button>
</div>
