import { FieldType } from '@ngx-formly/core';
import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { get } from 'lodash';

import { ChartDesignerService } from '../../../settings/chart-designer/chart-designer.service';
import { IChart } from '../../../models/chart';
import { Tip } from '../../../data/models/types';
import { IChartWidgetConfig } from '../../../dashboard/widgetConfig';

@Component({
  selector: 'app-chart-content',
  template: `
    <div *ngIf="config.chartTip else placeholder">
      <div
          style="height: 480px;"
          *ngIf="(chart$ | async) as chart;else loading">
        <app-chart
            [chart]="chart"
            [filterInputs]="config.filterInputs"
            [contextTip]="contextTip"></app-chart>
      </div>
    </div>
    <ng-template #placeholder>
      <app-empty-state imgSrc="/assets/img/empty-states/no-chart.svg"></app-empty-state>
    </ng-template>
    <ng-template #loading>
      <div
          data-test="chart-content-loading-spinner"
          [appSpinner]="true"></div>
    </ng-template>
  `
})
export class FormlyFieldChartContentComponent extends FieldType implements OnInit {
  chart$: Observable<IChart> = null;

  config: IChartWidgetConfig = {
    filterInputs: [],
    title: 'Unset',
    chartTip: null,
    canExport: false
  };

  contextTip: Tip;

  changes: Subscription;

  constructor(
    private chartDesignerService: ChartDesignerService
  ) {
    super();
  }

  ngOnInit(): void {
    this.setChart();

    this.contextTip = get(this.options, 'formState.objectAndType.objectData.$tip', null);
  }

  setChart() {
    const config = get(this, 'to.chartConfig', null);

    if (config.chartTip) {
      this.config = config;
      this.chart$ = this.chartDesignerService.getChart(this.config.chartTip);
    }
  }
}
