import { Injectable, OnDestroy } from '@angular/core';
import { Tip } from '../../data/models/types';
import { map, first, switchMap } from 'rxjs/operators';
import { Observable, Subscription, ReplaySubject, of } from 'rxjs';
import { QueryService } from '../../data/query.service';
import { ModuleValidationError } from '../../data/errors/client/ModuleValidationError';
import { FeatureFlagService } from '../../util/feature-flag/feature-flag.service';

export type AllModuleStatesMap = Map<Tip, boolean>;

@Injectable({
  providedIn: 'root'
})
export class ModulePackageService implements OnDestroy {
  subscription: Subscription;
  private allModuleStates = new ReplaySubject<AllModuleStatesMap>(1);
  allModuleStates$: Observable<AllModuleStatesMap> = this.allModuleStates.asObservable();

  constructor(
    private queryService: QueryService,
    private featureFlagService: FeatureFlagService
  ) { }

  isModuleUnlocked(moduleTip: Tip, defaultEnabled = false): Observable<boolean> {
    const getModuleLockedState$ = this.allModuleStates$
      .pipe(
        map(allModuleStatesMap => {
          if (allModuleStatesMap.has(moduleTip)) {
            return allModuleStatesMap.get(moduleTip);
          }
          return defaultEnabled;
        })
      );

    return this
      .featureFlagService
      .getFeatureFlag$('feature-check-module-locked-state')
      .pipe(
        first(),
        switchMap((isFeatureFlagActive: boolean) => {
          return isFeatureFlagActive
            ? getModuleLockedState$
            : of(true);
        })
      );
  }

  loadAllModuleStates() {
    this.subscription = this.queryService.execute1dFirst('eim/query/get-all-module-states-query').pipe(
      map(
        (moduleStates: { $tip: Tip, isUnlocked: string }[]) => moduleStates.reduce(
          (allModuleStatesMap, moduleState) => {
            return allModuleStatesMap.set(moduleState.$tip, moduleState.isUnlocked === '1');
          },
          new Map()
        )
      )
    ).subscribe(allModuleStatesMap => {
      this.allModuleStates.next(allModuleStatesMap);
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  handleError(action: string, moduleTip: Tip, error?: any): Observable<any> {
    return this
    .isModuleUnlocked(moduleTip)
    .pipe(
      first(),
      map((isUnlocked: boolean) => {
        if (!isUnlocked) {
          throw new ModuleValidationError('Unable to make changes to the configuration of a locked module.');
        }

        if (error) {
          throw error;
        }

        throw new Error(`Unable to ${action} at this time.`);
      })
      );
  }
}
