import { camelCase } from 'lodash';
import * as i0 from "@angular/core";
var AlphanumericConverterService = /** @class */ (function () {
    function AlphanumericConverterService() {
    }
    AlphanumericConverterService.applyAlphanumericValueToControl = function (controlA, controlB) {
        var controlAValue = controlA.value;
        if (!controlAValue) {
            controlB.setValue(controlAValue);
            return;
        }
        var controlAValueAlphanumeric = AlphanumericConverterService.generateAlphanumericValueFromString(controlAValue);
        controlB.setValue(controlAValueAlphanumeric);
    };
    AlphanumericConverterService.generateAlphanumericValueFromString = function (value) {
        return camelCase(value.replace(/^[^a-zA-Z]+/, '')).substr(0, 50);
    };
    AlphanumericConverterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AlphanumericConverterService_Factory() { return new AlphanumericConverterService(); }, token: AlphanumericConverterService, providedIn: "root" });
    return AlphanumericConverterService;
}());
export { AlphanumericConverterService };
