import { cloneDeep, merge, isArray, isEqual, union } from 'lodash';

import dataConstants from '../constants';
import { IClientT, IEno, IField, II18n, IServerT, ISource, Sid, Tip } from './types';

export { Eno };

interface IToJSONOption {
  propWhiteList?: string[];
}

class Eno implements IEno {
  readonly tip: Tip;
  readonly sid: Sid;
  readonly serverT?: IServerT;
  readonly clientT?: IClientT;
  readonly source?: ISource;

  constructor(proto: IEno) {
    merge(this, cloneDeep(proto));
  }

  toJson(option: IToJSONOption = {}): IEno {
    const json = Object.create(null);

    for (const property of Object.keys(this)) {
      if (option && option.propWhiteList && option.propWhiteList.indexOf(property) === -1) {
        continue;
      }

      json[property] = cloneDeep(this[property]);
    }

    return json;
  }

  getBranch(): Tip {
    return this.serverT ?
      this.serverT.branch :
      this.clientT ? this.clientT.branch : null;
  }

  getType(): Tip {
    return this.source ? this.source.type : null;
  }

  getSessionId(): Tip {
    return this.serverT ? this.serverT.session : null;
  }

  hasError(): boolean {
    return this.serverT && this.serverT.error && this.serverT.error.length > 0;
  }

  getFieldFormula(fieldTip: Tip): string {
    const field = this.source.field.filter((f: IField) => f.tip === fieldTip)[0];
    return field && field.formula && field.formula.length > 0 ? field.formula[0] : null;
  }

  // The second argument "lang" is now not used within the app.
  // Left it for potential future use.
  getFieldValues(fieldTip: Tip, lang = dataConstants.LOCALE_ID): string[] {
    const field = this.source.field.filter((f: IField) => f.tip === fieldTip)[0];

    if (!field) {
      return [];
    }

    if (field.value) {
      return field.value;
    }

    if (field.formula) {
      return field.formula;
    }

    if (!field.i18n) {
      return [];
    }

    const maybeLang = lang.substr(0, 2);

    let maybeLangIndex: number = null;
    let defaultLangIndex: number = null;

    for (let i = 0; i < field.i18n.length; i++) {
      if (field.i18n[i].lang === lang) {
        return field.i18n[i].value;
      }

      if (maybeLangIndex === null && field.i18n[i].lang.substr(0, 2) === maybeLang) {
        maybeLangIndex = i;
      } else if (defaultLangIndex === null && field.i18n[i].lang === dataConstants.LANG_DEFAULT) {
        defaultLangIndex = i;
      }
    }

    if (maybeLangIndex !== null) {
      return field.i18n[maybeLangIndex].value;
    }

    if (defaultLangIndex !== null) {
      return field.i18n[defaultLangIndex].value;
    }

    return [];
  }

  getFieldRawI18n(fieldTip: Tip): II18n[] {
    const field = this.source.field.filter((f: IField) => f.tip === fieldTip)[0];

    if (isArray(field.i18n)) {
      return [...field.i18n];
    }

    if (isArray(field.value)) {
      return [{ lang: dataConstants.LANG_DEFAULT, value: field.value }];
    }

    return [];
  }

  // The second argument "lang" is now not used within the app.
  // Left it for potential future use.
  getFieldStringValue(fieldTip: Tip, lang = dataConstants.LOCALE_ID): string | null {
    return this.getFieldValues(fieldTip, lang).join(', ') || null;
  }

  // The second argument "lang" is now not used within the app.
  // Left it for potential future use.
  getFieldNumberValue(fieldTip: Tip, lang = dataConstants.LOCALE_ID): number | null {
    return parseFloat(this.getFieldValues(fieldTip, lang)[0]) || null;
  }

  // The second argument "lang" is now not used within the app.
  // Left it for potential future use.
  getFieldBooleanValue(fieldTip: Tip, lang = dataConstants.LOCALE_ID): boolean {
    const fieldValues = this.getFieldValues(fieldTip, lang);

    return fieldValues && fieldValues.filter((value: string) => value !== 'true').length === 0;
  }

  // The second argument "lang" is now not used within the app.
  // Left it for potential future use.
  getFieldJsonValue(fieldTip: Tip, lang = dataConstants.LOCALE_ID): any | null {
    const value = this.getFieldValues(fieldTip, lang);

    if (value && value.length > 0) {
      return JSON.parse(value[0]);
    }

    return null;
  }

  // Do deep comparison of this eno and the given eno to check if anything is different.
  // Note that this does not compare nonce and parent as this is for comparing content
  isContentDiff(eno: Eno): boolean {
    return this.source.deleted !== eno.source.deleted ||
      this.source.type !== eno.source.type ||
      this.source.security !== eno.source.security ||
      this._isFieldDiff(eno);
  }

  private _isFieldDiff(eno: Eno): boolean {
    const allFieldTips = union(this.source.field.map(iField => iField.tip), eno.source.field.map(iField => iField.tip));
    for (const fieldTip of allFieldTips) {
      const isDiff = !isEqual(this.getFieldValues(fieldTip), eno.getFieldValues(fieldTip));

      if (isDiff) {
        return true;
      }
    }

    return false;
  }
}
