import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { first, shareReplay } from 'rxjs/operators';
import { LoadingState } from '../../shared/constants';
import { ModalConfirmDeletionComponent, modalConfirmDeletionOptions } from '../../shared/modals/modal-confirm-deletion/modal-confirm-deletion.component';
import { ObjectEditSideSheetComponent } from '../../object/object-edit-side-sheet/object-edit-side-sheet.component';
import { PlanStrategySideSheetComponent } from '../plan-strategy-side-sheet/plan-strategy-side-sheet.component';
import { ModuleValidationError } from '../../data/errors/client/ModuleValidationError';
var PlanEditSideSheetComponent = /** @class */ (function () {
    function PlanEditSideSheetComponent(planService, sideSheetService, toastService, modalService, securityService) {
        this.planService = planService;
        this.sideSheetService = sideSheetService;
        this.toastService = toastService;
        this.modalService = modalService;
        this.securityService = securityService;
        this.filterText = '';
        this.errorMessage = '';
        this.loadingState = LoadingState.inProgress;
        this.plan = {
            name: '',
            description: '',
            strategy: '',
            checklists: []
        };
        this.isInSelectMode = false;
        this.isDraggable = false;
        this.canActivate = false;
        this.loadingStates = LoadingState;
        this.subscriptions = new Subscription();
        this.done = function (plan) { };
        this.closed = function (plan) { };
        this.back = function (plan) { };
    }
    PlanEditSideSheetComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.add(this.planService.getPlan(this.objectTip).subscribe(function (plan) {
            _this.plan = plan;
            _this.canActivate = !plan.isTemplate && !plan.isActivated;
            _this.loadingState = LoadingState.loaded;
        }, function () {
            _this.loadingState = LoadingState.failed;
            _this.errorMessage = 'Could not get plan details at this time';
        }));
        this.canUpdate$ = this.securityService.canUpdate(this.objectTip).pipe(shareReplay(1));
    };
    PlanEditSideSheetComponent.prototype.setProps = function (_a) {
        var objectTip = _a.objectTip;
        this.objectTip = objectTip;
    };
    PlanEditSideSheetComponent.prototype.onClickAddChecklist = function () {
        var _this = this;
        if (!this.plan.checklists) {
            this.plan.checklists = [];
        }
        this.openObjectEditSideSheet({
            fieldTipsToDisplay: ['app/checklist:name'],
            onDone: function (objectData) {
                _this.plan.checklists.push({
                    $tip: objectData['$tip'],
                    $security: objectData['$security'],
                    $type: objectData['$type'],
                    name: objectData['app/checklist:name'],
                    items: []
                });
                _this.updatePlan();
            },
            enoType: 'app/checklist',
            enoSecurity: this.plan.$security
        });
    };
    PlanEditSideSheetComponent.prototype.openPlanEditSideSheet = function () {
        this.planService.openPlanEditSideSheet(this.plan, this.moduleTip);
    };
    PlanEditSideSheetComponent.prototype.openObjectEditSideSheet = function (_a) {
        var _b = _a.fieldTipsToDisplay, fieldTipsToDisplay = _b === void 0 ? [] : _b, _c = _a.onDone, onDone = _c === void 0 ? function (objectData) { } : _c, _d = _a.objectTip, objectTip = _d === void 0 ? null : _d, _e = _a.objectBranchTip, objectBranchTip = _e === void 0 ? null : _e, _f = _a.enoType, enoType = _f === void 0 ? null : _f, _g = _a.enoSecurity, enoSecurity = _g === void 0 ? null : _g;
        var sheetRef = this.sideSheetService.push(ObjectEditSideSheetComponent);
        sheetRef.componentInstance.title = 'Add checklist';
        sheetRef.componentInstance.objectTip = objectTip;
        sheetRef.componentInstance.objectBranchTip = objectBranchTip || this.objectBranchTip;
        sheetRef.componentInstance.mode = 'save';
        sheetRef.componentInstance.fieldTipsToDisplay = fieldTipsToDisplay;
        sheetRef.componentInstance.enoType = enoType;
        sheetRef.componentInstance.enoSecurity = enoSecurity;
        sheetRef.componentInstance.onDone = onDone;
    };
    PlanEditSideSheetComponent.prototype.onRequestDeleteChecklists = function (checklists) {
        var _this = this;
        var modalRef = this.modalService.open(ModalConfirmDeletionComponent, modalConfirmDeletionOptions);
        modalRef.componentInstance.objectType = 'checklists';
        modalRef.componentInstance.message = 'Do you really want to delete these checklists?';
        modalRef.result.then(function (modalResult) {
            return modalResult ? _this.deleteChecklists(checklists) : null;
        }).catch(function () { });
    };
    PlanEditSideSheetComponent.prototype.deleteChecklists = function (checklists) {
        var _this = this;
        this.planService.deleteChecklists(checklists, this.moduleTip).pipe(first()).subscribe(function () {
            _this.plan.checklists = _this.plan.checklists.filter(function (x) { return !checklists.includes(x.$tip); });
            _this.isInSelectMode = false;
        }, function (err) {
            err instanceof ModuleValidationError
                ? _this.toastService.showErrorToast(err.message)
                : _this.toastService.showErrorToast('Failed to delete checklists at this time.');
        });
    };
    PlanEditSideSheetComponent.prototype.onRequestDelete = function () {
        var _this = this;
        var modalRef = this.modalService.open(ModalConfirmDeletionComponent, modalConfirmDeletionOptions);
        modalRef.componentInstance.objectType = 'plan';
        modalRef.result.then(function (modaResult) {
            return modaResult ? _this.delete() : null;
        }).catch(function () { });
    };
    PlanEditSideSheetComponent.prototype.delete = function () {
        var _this = this;
        this.planService.deletePlan(this.plan, this.moduleTip).pipe(first()).subscribe(function () {
            _this.sideSheetService.pop();
            _this.toastService.showSuccessToast('Deleted plan successfully');
        }, function (err) {
            err instanceof ModuleValidationError
                ? _this.toastService.showErrorToast(err.message)
                : _this.toastService.showErrorToast('Failed to update plan at this time');
        });
    };
    PlanEditSideSheetComponent.prototype.updatePlan = function (onUpdated) {
        var _this = this;
        this.loadingState = LoadingState.inProgress;
        var updatingPlan$ = this.planService.updatePlan(this.plan, false, this.moduleTip).pipe(first());
        this.subscriptions.add(updatingPlan$.subscribe(function () {
            if (onUpdated) {
                onUpdated();
            }
            _this.loadingState = LoadingState.loaded;
            _this.toastService.showSuccessToast('Updated plan successfully');
        }, function (err) {
            err instanceof ModuleValidationError
                ? _this.toastService.showErrorToast(err.message)
                : _this.toastService.showErrorToast('Failed to update plan at this time');
            _this.loadingState = LoadingState.loaded;
        }));
        return updatingPlan$;
    };
    PlanEditSideSheetComponent.prototype.viewStrategy = function () {
        var sheetRef = this.sideSheetService.push(PlanStrategySideSheetComponent);
        var instance = sheetRef.componentInstance;
        instance.moduleTip = this.moduleTip;
        instance.setProps({ planTip: this.plan.$tip });
    };
    PlanEditSideSheetComponent.prototype.onClose = function () {
        this.closed(this.plan);
    };
    PlanEditSideSheetComponent.prototype.onDone = function () {
        var _this = this;
        if (this.canActivate) {
            // present modal here to set plan.isActivated to true or false and set plan.Incomplete to false
            this.loadingState = LoadingState.inProgress;
            var modalRef = this.modalService.open(ModalConfirmDeletionComponent, modalConfirmDeletionOptions);
            modalRef.componentInstance.title = 'New plan';
            modalRef.componentInstance.message = 'Do you want to save this plan as a draft or activate it now?';
            modalRef.componentInstance.confirmText = 'Activate now';
            modalRef.componentInstance.cancelText = 'Save as draft';
            modalRef.componentInstance.cancelButtonClass = 'btn-secondary';
            modalRef.result
                .then(function (confirmActivate) {
                if (confirmActivate) {
                    // on confirm activate plan
                    _this.plan.isActivated = true;
                    _this.plan.isIncomplete = false;
                }
                else {
                    // on cancel - save as draft
                    _this.plan.isActivated = false;
                    _this.plan.isIncomplete = false;
                }
                _this.updatePlan(function () {
                    _this.sideSheetService.pop();
                    _this.done(_this.plan);
                });
            })
                .catch(function () {
                _this.loadingState = LoadingState.loaded;
            });
        }
        else {
            this.sideSheetService.pop();
            this.done(this.plan);
        }
    };
    PlanEditSideSheetComponent.prototype.onGoBack = function () {
        this.back(this.plan);
        this.sideSheetService.pop();
    };
    PlanEditSideSheetComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    PlanEditSideSheetComponent.prototype.onDragFinish = function () {
        var _this = this;
        this.updatePlan(function () {
            _this.isDraggable = false;
            _this.checklistsList.isDragged = false;
        });
    };
    PlanEditSideSheetComponent.prototype.onDragStart = function () {
        this.isDraggable = !this.isDraggable;
        this.checklistsBeforeDrag = tslib_1.__spread(this.plan.checklists);
    };
    PlanEditSideSheetComponent.prototype.onDragCancel = function () {
        this.isDraggable = false;
        if (this.checklistsList.isDragged) {
            this.plan.checklists = this.checklistsBeforeDrag;
            this.checklistsList.isDragged = false;
        }
    };
    PlanEditSideSheetComponent.prototype.onChecklistsChange = function () {
        // don't update plan after dragNdrop, it will be updated onDragFinish
        if (!this.checklistsList.isDragged) {
            this.updatePlan();
        }
    };
    return PlanEditSideSheetComponent;
}());
export { PlanEditSideSheetComponent };
