import * as tslib_1 from "tslib";
import { first, map, switchMap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { get, isEmpty } from 'lodash';
import { EnoFactory } from '../../data/EnoFactory';
import dataConstants from '../../data/constants';
import { AuthRegisterErrors } from './auth-register-errors';
import * as i0 from "@angular/core";
import * as i1 from "../../data/process.service";
import * as i2 from "../../data/ensrv.service";
import * as i3 from "../check-ssoproviders.service";
import * as i4 from "../auth.service";
var AcceptInviteService = /** @class */ (function () {
    function AcceptInviteService(processService, ensrvService, checkSSOProvider, authService) {
        this.processService = processService;
        this.ensrvService = ensrvService;
        this.checkSSOProvider = checkSSOProvider;
        this.authService = authService;
    }
    AcceptInviteService.prototype.acceptInvitePassword = function (inviteParams) {
        /*
        * we want to call set password then updateInviteInfo
        * as as soon as we update the invite info we can no longer set the password
        * as there is a guard on this route
        */
        var _this = this;
        return this.setPassword(inviteParams).pipe(switchMap(function () { return _this.updateInviteInfo(inviteParams); }), switchMap(function () {
            return _this.authService.signIn(inviteParams.email, inviteParams.password);
        }));
    };
    AcceptInviteService.prototype.acceptInviteSSO = function (inviteParams) {
        var _this = this;
        return this.updateInviteInfo(inviteParams)
            .pipe(switchMap(function () {
            return _this.checkSSOProvider.checkEmailAddressAndRedirect$(inviteParams.email);
        }));
    };
    AcceptInviteService.prototype.setPassword = function (_a) {
        var email = _a.email, token = _a.token, password = _a.password;
        var registerOpEnoFactory = new EnoFactory('op/auth/register', dataConstants.SECURITY.OP);
        var registerOpEno = registerOpEnoFactory
            .setFields([
            { tip: 'op/auth/register:key', value: [email] },
            { tip: 'op/auth/register:token', value: [token] },
            { tip: 'op/auth/register:secret', value: [password] },
            { tip: 'op/auth/register:payload', value: [email] }
        ])
            .makeEno();
        return this.ensrvService.send([registerOpEno])
            .pipe(first(), map(extractRegisterEnoAndErrorsIfPresent), switchMap(function (_a) {
            var errors = _a.errors, registerEno = _a.registerEno;
            /*
            * We don't want to throw an error if the user already exists
            * As the update invite might have just failed and we want to
            * let the user retry that method after a refresh
            */
            if (errorOtherThanUserExists(errors)) {
                return throwError(errors);
            }
            return of(registerEno);
        }));
    };
    AcceptInviteService.prototype.updateInviteInfo = function (_a) {
        var email = _a.email, token = _a.token;
        return this.processService
            .start('eim/process/users/update-invite-info', {
            'Email': [email],
            'Token': [token]
        })
            .pipe(first(), switchMap(function (res) {
            if (get(res, 'vars.Token invalid[0]', false)) {
                return throwError('Invalid token passed');
            }
            if (get(res, 'vars.User status not invited[0]', false)) {
                return throwError('User status not invited');
            }
            return of(res);
        }));
    };
    AcceptInviteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AcceptInviteService_Factory() { return new AcceptInviteService(i0.ɵɵinject(i1.ProcessService), i0.ɵɵinject(i2.EnsrvService), i0.ɵɵinject(i3.CheckSSOProvidersService), i0.ɵɵinject(i4.AuthService)); }, token: AcceptInviteService, providedIn: "root" });
    return AcceptInviteService;
}());
export { AcceptInviteService };
export function errorOtherThanUserExists(errors) {
    return !isEmpty(errors) && !errors.includes(AuthRegisterErrors.userExists);
}
export function extractRegisterEnoAndErrorsIfPresent(batch) {
    return batch.reduce(function (acc, eno) {
        if (eno.getType() === 'response/auth/register') {
            acc.registerEno = eno;
        }
        if (eno.getType() === 'error') {
            var errors = eno.getFieldValues('error/message/tip');
            acc.errors = tslib_1.__spread(acc.errors, errors);
        }
        return acc;
    }, { registerEno: null, errors: [] });
}
