import * as tslib_1 from "tslib";
import { forkJoin, of, throwError } from 'rxjs';
import { first, map, mergeMap, switchMap } from 'rxjs/operators';
import { get, intersection, isArray } from 'lodash';
import { createFormFromType } from './form-renderer/utils/create-form-from-type';
import * as i0 from "@angular/core";
import * as i1 from "../object/object.service";
import * as i2 from "../data/formula.service";
import * as i3 from "../settings/form-designer/services-core/form-designer.service";
import * as i4 from "../data/all-object-types/all-object-type.service";
var GetObjectAndFormService = /** @class */ (function () {
    function GetObjectAndFormService(objectService, formulaService, formDesignerService, allObjectTypesService) {
        this.objectService = objectService;
        this.formulaService = formulaService;
        this.formDesignerService = formDesignerService;
        this.allObjectTypesService = allObjectTypesService;
    }
    GetObjectAndFormService.prototype.getFormObjectAndType = function (_a) {
        /*
          Use cases
          - Preview (Form tip) ✓
            - load new form (disable save button)
          - Create (Form tip) ✓
            - load form then type
          - Create (Type tip and Form tip) ✓
            - load type and form
          - Create (Type tip) ✓
            - typeTip is a super type
            - typeTip type has default form (load form)
            - typeTip no default form (dynamically create form)
          - Edit (Object tip) ✓
            - typeTip type has default form (load form)
            - typeTip no default form (dynamically create form)
          - Edit (Object tip & Form Tip) ✓
            - load object, load form check types match then initialise
          - Edit (SubObject) ✓
            - use existing object and type, load form
        */
        var formTip = _a.formTip, objectTip = _a.objectTip, typeTip = _a.typeTip, contextTip = _a.contextTip, queryParams = _a.queryParams, subObject = _a.subObject;
        if (formTip && objectTip) {
            return this.getFormAndObject$(formTip, objectTip);
        }
        if (typeTip && formTip && contextTip) {
            return this.getTypeThenFormWithContext$(typeTip, contextTip, formTip);
        }
        if (formTip && typeTip) {
            return this.getFormAndType$(formTip, typeTip);
        }
        if (contextTip && typeTip) {
            return this.getTypeThenFormWithContext$(typeTip, contextTip);
        }
        if (formTip) {
            return this.getFormThenType$(formTip);
        }
        if (typeTip) {
            return this.getTypeData$(typeTip, queryParams);
        }
        if (objectTip) {
            return this.getObjectThenForm$(objectTip);
        }
        if (subObject) {
            return this.useSubObjectToCreateForm(subObject);
        }
    };
    GetObjectAndFormService.prototype.getTypeData$ = function (typeTip, queryParams) {
        var _this = this;
        return this.objectService
            .isSuperType$(typeTip)
            .pipe(switchMap(function (isSuperType) {
            if (isSuperType) {
                return of({ isSuperType: true });
            }
            return _this.getTypeAndForm$(typeTip, queryParams);
        }));
    };
    /*
     The key to these services is they all return the same combination of IFormObjectAndType
   */
    GetObjectAndFormService.prototype.getTypeAndForm$ = function (typeTip, queryParams) {
        var _this = this;
        var getTypeGenerateForm$ = function () {
            return _this.getType$(typeTip)
                .pipe(map(function (objectAndType) {
                return [createFormFromType(objectAndType), objectAndType];
            }));
        };
        var getFormAndType$ = function (formTip) {
            return forkJoin([
                _this.getForm$(formTip),
                _this.getType$(typeTip)
            ]);
        };
        return this.getFormTip$(typeTip).pipe(switchMap(function (formTip) {
            if (formTip) {
                return getFormAndType$(formTip);
            }
            return getTypeGenerateForm$();
        }), map(mapToFormObjectAndType));
    };
    GetObjectAndFormService.prototype.getTypeThenFormWithContext$ = function (typeTip, contextTip, formTip) {
        var _this = this;
        var superType$ = of({ isSuperType: true });
        var getFormAndObjectAndType$ = forkJoin([
            formTip ? of(formTip) : this.getFormTip$(typeTip),
            this.getType$(typeTip),
            this.getContextTypeTip$(contextTip)
        ]).pipe(mergeMap(function (_a) {
            var _b = tslib_1.__read(_a, 3), _formTip = _b[0], objectAndType = _b[1], contextTypeTip = _b[2];
            var form$ = _formTip
                ? _this.getForm$(_formTip)
                : of(createFormFromType(objectAndType));
            var modifiedObjectAndType$ = of(objectAndType)
                .pipe(switchMap(prePopulateWithContext.call(_this, contextTypeTip, contextTip)));
            return forkJoin([form$, modifiedObjectAndType$])
                .pipe(map(mapToFormObjectAndType));
        }));
        return this.objectService.isSuperType$(typeTip)
            .pipe(switchMap(function (isSuperType) {
            return isSuperType
                ? superType$
                : getFormAndObjectAndType$;
        }));
    };
    GetObjectAndFormService.prototype.getObjectThenForm$ = function (objectTip) {
        var _this = this;
        return this.getObject$(objectTip)
            .pipe(switchMap(function (object) {
            var typeTip = object.objectType.$tip;
            return forkJoin([_this.getFormTip$(typeTip), of(object)]);
        }), switchMap(function (_a) {
            var _b = tslib_1.__read(_a, 2), formTip = _b[0], object = _b[1];
            var form$ = formTip
                ? _this.getForm$(formTip)
                : of(createFormFromType(object));
            return forkJoin([form$, of(object)]);
        }), map(verifyFormMatchesObjectType));
    };
    GetObjectAndFormService.prototype.getFormThenType$ = function (formTip) {
        var _this = this;
        return this.getForm$(formTip)
            .pipe(mergeMap(function (form) {
            return forkJoin([
                of(form),
                _this.getType$(form.contextType.type.$tip)
            ]).pipe(map(mapToFormObjectAndType));
        }));
    };
    GetObjectAndFormService.prototype.getFormAndType$ = function (formTip, typeTip) {
        return forkJoin([this.getForm$(formTip), this.getType$(typeTip)])
            .pipe(map(verifyFormMatchesObjectType));
    };
    GetObjectAndFormService.prototype.getFormAndObject$ = function (formTip, objectTip) {
        return forkJoin([this.getForm$(formTip), this.getObject$(objectTip)]).pipe(map(verifyFormMatchesObjectType));
    };
    GetObjectAndFormService.prototype.useSubObjectToCreateForm = function (subObject) {
        var _this = this;
        if (typeof subObject === 'boolean') {
            return throwError("You can only use \"subObject: true\" for creating new subObjects when editing you need to pass the subObject");
        }
        var typeTip = get(subObject, 'objectType.$tip', null);
        if (!typeTip) {
            return throwError('Cannot load sub object without an objectTypeTip');
        }
        return this.getFormTip$(typeTip)
            .pipe(switchMap(function (formTip) {
            var form$ = formTip
                ? _this.getForm$(formTip)
                : of(createFormFromType(subObject));
            return form$;
        }), map(function (form) { return ({ form: form, objectAndType: subObject }); }));
    };
    /*
      End [IForm, IObjectAndType]
    */
    GetObjectAndFormService.prototype.getObject$ = function (objectTip) {
        return this
            .objectService
            .getObjectAndType(objectTip)
            .pipe(first());
    };
    GetObjectAndFormService.prototype.getType$ = function (tip) {
        return this.objectService.getType(tip).pipe(first());
    };
    GetObjectAndFormService.prototype.getContextTypeTip$ = function (contextTip) {
        return this.formulaService.evaluate("TYPE(\"" + contextTip + "\")").pipe(first(), map(function (result) { return result[0]; }));
    };
    GetObjectAndFormService.prototype.getFormTip$ = function (typeTip) {
        return this.allObjectTypesService.getFormTipFromTypeTip$(typeTip).pipe(first());
    };
    GetObjectAndFormService.prototype.getForm$ = function (formTip) {
        return this
            .formDesignerService
            .loadForm(formTip)
            .pipe(first());
    };
    GetObjectAndFormService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetObjectAndFormService_Factory() { return new GetObjectAndFormService(i0.ɵɵinject(i1.ObjectService), i0.ɵɵinject(i2.FormulaService), i0.ɵɵinject(i3.FormDesignerService), i0.ɵɵinject(i4.AllObjectTypesService)); }, token: GetObjectAndFormService, providedIn: "root" });
    return GetObjectAndFormService;
}());
export { GetObjectAndFormService };
function verifyFormMatchesObjectType(_a) {
    var _b = tslib_1.__read(_a, 2), form = _b[0], type = _b[1];
    var contextTypeTip = get(form, 'contextType.type.$tip', null);
    var objectTypeTip = get(type, 'objectType.$tip', null);
    var isNull = Boolean(contextTypeTip === null) || Boolean(objectTypeTip === null);
    var contextTipNotEqualToObjectTip = contextTypeTip !== objectTypeTip;
    if (isNull || contextTipNotEqualToObjectTip) {
        throw new Error("\n              You cannot load this form with context type tip " + contextTypeTip + " for type tip " + objectTypeTip + ".\n              Types should be the same!");
    }
    return { form: form, objectAndType: type };
}
function mapToFormObjectAndType(_a) {
    var _b = tslib_1.__read(_a, 2), form = _b[0], type = _b[1];
    return { form: form, objectAndType: type };
}
// @todo Move this to a separate service.
function prePopulateWithContext(contextTypeTip, contextTip) {
    return function (objectAndType) {
        var _this = this;
        if (objectAndType.objectType.field && objectAndType.objectType.field.length) {
            var promises = objectAndType.objectType.field.map(function (field) { return makePrePopulateFieldPromise.call(_this, field, contextTypeTip, contextTip, objectAndType); });
            return forkJoin([Promise.all(promises)]).pipe(map(function () { return objectAndType; }));
        }
        return of(objectAndType);
    }.bind(this);
}
function makePrePopulateFieldPromise(field, contextTypeTip, contextTip, objectAndType) {
    var _this = this;
    return new Promise(function (resolve) {
        if (contextTypeTip &&
            field.tag &&
            field.tag.length &&
            field.tag[0] === 'autoPopulate--true' &&
            isArray(field.typerestrict)) {
            if (field.typerestrict.includes(contextTypeTip)) {
                setObjectAndTypeForPrePopulate(field, objectAndType, contextTip);
                return resolve();
            }
            return _this.objectService.getType(contextTypeTip).pipe(first()).subscribe(function (contextObjectAndType) {
                if (isArray(contextObjectAndType.objectType.supertypes) &&
                    intersection(field.typerestrict, contextObjectAndType.objectType.supertypes.map(function (superType) { return superType.$tip; })).length) {
                    setObjectAndTypeForPrePopulate(field, objectAndType, contextTip);
                    return resolve();
                }
                return resolve();
            });
        }
        return resolve();
    });
}
function setObjectAndTypeForPrePopulate(field, objectAndType, contextTip) {
    if (field.maxcount === 1) {
        objectAndType.objectData[field.$tip] = contextTip;
        return;
    }
    objectAndType.objectData[field.$tip] = [contextTip];
}
