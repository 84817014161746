import { first, map } from 'rxjs/operators';
import { isEqual } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "../data/process.service";
var AcceptInviteResolverService = /** @class */ (function () {
    function AcceptInviteResolverService(_processService) {
        this._processService = _processService;
    }
    AcceptInviteResolverService.prototype.resolve = function (route) {
        return this._processService.start('eim/process/auth/validate-token', {
            Token: [route.queryParams.token],
            Email: [route.queryParams.email]
        }).pipe(first(function (processResponse) { return processResponse.finished; }), map(function (processResponse) { return isEqual(processResponse.vars['Token valid'], ['true']); }));
    };
    AcceptInviteResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AcceptInviteResolverService_Factory() { return new AcceptInviteResolverService(i0.ɵɵinject(i1.ProcessService)); }, token: AcceptInviteResolverService, providedIn: "root" });
    return AcceptInviteResolverService;
}());
export { AcceptInviteResolverService };
