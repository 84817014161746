import { intersection } from 'lodash';
import { EXTENDED_FIELD_ACTUAL_TYPE_MAP, EXTENDED_FIELD_DATA_TYPE } from '../../models/field';
import { IAllObjectType } from '../../data/all-object-types/all-object-type.service';

export function  checkIfTypeImplementsHierarchicalType(typeFromMetaMap: IAllObjectType): boolean {
  if (typeFromMetaMap) {
    const typesImplemented = typeFromMetaMap.implementsSuperTypes;
    if (typesImplemented) {
      return intersection(typesImplemented,    // check if the object implements any hierarchical type
        EXTENDED_FIELD_ACTUAL_TYPE_MAP[EXTENDED_FIELD_DATA_TYPE.hierarchical].typeRestrict).length > 0;
      }
    }
  return false;
}
