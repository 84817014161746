import { Component, OnInit, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { Tip } from '../../data/models/types';
import { MediaService } from '../../data/media.service';

@Component({
  selector: 'app-avatar-view',
  templateUrl: './avatar-view.component.html',
  styleUrls: ['./avatar-view.component.scss']
})
export class AvatarViewComponent implements OnInit, OnChanges {
  @Input() avatarTip: Tip;

  @Input() size = '30px';

  @Input() fontSize = '1rem';

  @Input() useLightFont = false;

  @Input() firstName: string;

  @Input() lastName: string;

  @Input() fullName: string; // fullName takes precedence over firstName + lastName

  @Input() type: 'app/person' | 'app/organization' = 'app/person';

  @HostBinding('style.width') width;

  @HostBinding('style.height') height;

  avatarURL$: Observable<SafeResourceUrl>;
  initials: string;

  constructor(private mediaService: MediaService) {}

  ngOnInit() {
    this.initials = this.getInitials();
    this.width = this.height = this.size;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['avatarTip']) {
      this.avatarURL$ = this.avatarTip
        ? this.mediaService.previewUri(this.avatarTip)
        : of(null);
    }

    if (changes['fullName']) {
      this.initials = this.getInitials();
    }
  }

  getInitials(): string {
    if (!!this.fullName) {
      const fullNameArray = this.fullName.split(' ');
      
      if (!fullNameArray || !fullNameArray.length) {
        return;
      }

      return fullNameArray.length > 1
        ? fullNameArray[0].charAt(0).toUpperCase() + fullNameArray[1].charAt(0).toUpperCase()
        : fullNameArray[0].charAt(0).toUpperCase();
    }

    return this.firstName && this.lastName
      ? this.firstName.charAt(0).toUpperCase() + this.lastName.charAt(0).toUpperCase()
      : '-';
  }
}
