import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { RightArgBy } from '../../../query/query-filter-editor/query-filter-editor.service';
import { get } from 'lodash';

interface Item {
  label: string;
  value: any;
  formula?: string;
  by?: RightArgBy; 
  showBadge?: boolean;
}

@Component({
  selector: 'app-navigation-side-sheet',
  templateUrl: './navigation-side-sheet.component.html',
  styleUrls: ['./navigation-side-sheet.component.scss']
})
export class NavigationSideSheetComponent implements OnDestroy {
  list: Item[] = [];
  title = 'Please select from the list';

  formula: string;
  by: RightArgBy;
  showBadge: boolean;

  selectItem: Subject<Item> = new Subject();
  constructor() { }

  setProps({list, title}: { list: Item[], title: string}) {
    this.list = list;
    this.title = title;
    this.formula = get(list, '[0].formula', null);
    this.by = get(list, '[0].by', null);
    this.showBadge = get(list, '[0].showBadge', false);
  }

  ngOnDestroy() {
    this.selectItem.complete();
  }
}
