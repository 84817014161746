import * as tslib_1 from "tslib";
import { isDevMode } from '@angular/core';
import { debugConfig } from '../../debugConfig';
import { DEBUG_LEVEL } from './debugLevels';
import * as i0 from "@angular/core";
var LoggerService = /** @class */ (function () {
    function LoggerService() {
        this._debugLevel = isDevMode() ? debugConfig.debugLevel : DEBUG_LEVEL.DEBUG;
    }
    LoggerService.prototype.setDebugLevel = function (level) {
        this._debugLevel = level;
    };
    LoggerService.prototype.offAll = function () {
        this._debugLevel = DEBUG_LEVEL.OFF;
    };
    LoggerService.prototype.onAll = function () {
        this._debugLevel = DEBUG_LEVEL.ALL;
    };
    LoggerService.prototype.error = function (message) {
        var optionalParams = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            optionalParams[_i - 1] = arguments[_i];
        }
        if (this._debugLevel >= DEBUG_LEVEL.ERROR) {
            console.error.apply(console, tslib_1.__spread([message], optionalParams));
        }
    };
    LoggerService.prototype.warn = function (message) {
        var optionalParams = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            optionalParams[_i - 1] = arguments[_i];
        }
        if (this._debugLevel >= DEBUG_LEVEL.WARN) {
            console.warn.apply(console, tslib_1.__spread([message], optionalParams));
        }
    };
    LoggerService.prototype.info = function (message) {
        var optionalParams = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            optionalParams[_i - 1] = arguments[_i];
        }
        if (this._debugLevel >= DEBUG_LEVEL.INFO) {
            console.info.apply(console, tslib_1.__spread([message], optionalParams));
        }
    };
    LoggerService.prototype.debug = function (message) {
        var optionalParams = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            optionalParams[_i - 1] = arguments[_i];
        }
        if (this._debugLevel >= DEBUG_LEVEL.DEBUG) {
            console.debug.apply(console, tslib_1.__spread([message], optionalParams));
        }
    };
    LoggerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoggerService_Factory() { return new LoggerService(); }, token: LoggerService, providedIn: "root" });
    return LoggerService;
}());
export { LoggerService };
