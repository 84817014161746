import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface IPlaceholderProps {
  errorMessage: string;
}

@Component({
  selector: 'app-placeholder-side-sheet',
  templateUrl: './placeholder-side-sheet.component.html',
  styleUrls: ['./placeholder-side-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlaceholderSideSheetComponent implements OnDestroy {

  errorMessage$: BehaviorSubject<null | string> = new BehaviorSubject(null);
  constructor() { }

  setProps({errorMessage}: IPlaceholderProps) {
    this.errorMessage$.next(errorMessage);
  }

  ngOnDestroy() {
    this.errorMessage$.complete();
  }
}
