<app-side-sheet-header>
    <span>{{title || 'Select value field'}}</span>
  </app-side-sheet-header>
  <app-side-sheet-section [showBorder]="false" [showHeader]="false">
    <app-side-sheet-list 
    #optionList [mode]="SideSheetListModes.SINGLE_SELECT" 
    [showMoreButton]="false"
    [emitChangeEventOnLoad]="false"
    (selectedChange)="onDone()"
    >
       <app-side-sheet-list-item
        *ngFor="let option of options; let i = index"
        [key]="option.value">
        <span>{{option.label}}</span>
      </app-side-sheet-list-item>
    </app-side-sheet-list>
  </app-side-sheet-section>
  