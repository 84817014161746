import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { loadModules as esriLoadModules, loadScript } from 'esri-loader';
import { concat, from, Observable, of } from 'rxjs';
import { DATA_SOURCE_TYPES, EXTENT_TYPES, IMapExtent, IMapLayer } from '../../../models/map';
import { LoggerService } from '../../../util/logger.service';
import { catchError, first, map, switchMap, toArray } from 'rxjs/operators';
import { GeometryObject, Point } from 'geojson';
import { geojsonToArcGIS } from '@esri/arcgis-to-geojson-utils';
import { GisService, gisServicePath } from '../../../settings/map/gis.service';
import { environment } from '../../../../environments/environment';
import * as url from 'url';
import { IVars } from '../../../data/vars.service';
import {
  LayerControlLegendSideSheetComponent
} from '../layer-control-legend/layer-control-legend-side-sheet/layer-control-legend-side-sheet.component';
import { SideSheetService } from '../../../side-sheet/side-sheet.service';
import { ILayerControlLegend } from '../layer-control-legend/layer-control-legend.service';
import { get, isEmpty, union, head } from 'lodash';
import { formatNumber } from '@angular/common';
import { CurrentDatetimeService, NOW_VAR_NAME } from '../../../util/current-datetime.service';
import { MapService } from '../../../settings/map/map.service';
import esri = __esri;
import { IFilterInput } from '../../../var/filter-input/filter-input.service';

const featureQueryRegex = /{=(\w+)}/gm;

export enum SKETCH_TOOL {
  POINT = 'point',
  POLYLINE = 'polyline',
  POLYGON = 'polygon',
  RECTANGLE = 'rectangle',
  ADDRESS = 'address'
}

export const esriSketchToolMap = {
  [SKETCH_TOOL.POINT]: SKETCH_TOOL.POINT,
  [SKETCH_TOOL.POLYLINE]: SKETCH_TOOL.POLYLINE,
  [SKETCH_TOOL.POLYGON]: SKETCH_TOOL.POLYGON,
  [SKETCH_TOOL.RECTANGLE]: SKETCH_TOOL.RECTANGLE,
  [SKETCH_TOOL.ADDRESS]: SKETCH_TOOL.POINT
};

const layerModuleMap = {
  [DATA_SOURCE_TYPES.NOGGIN_QUERY]: 'esri/layers/FeatureLayer',
  [DATA_SOURCE_TYPES.EXTERNAL_GEOJSON]: 'esri/layers/GeoJSONLayer',
  [DATA_SOURCE_TYPES.EXTERNAL_GEORSS]: 'esri/layers/GeoRSSLayer',
  [DATA_SOURCE_TYPES.EXTERNAL_KML]: 'esri/layers/KMLLayer',
  [DATA_SOURCE_TYPES.EXTERNAL_WMS]: 'esri/layers/WMSLayer',
  [DATA_SOURCE_TYPES.ESRI_FEATURE_LAYER]: 'esri/layers/FeatureLayer',
  [DATA_SOURCE_TYPES.ESRI_DYNAMIC_MAP_SERVICE]: 'esri/layers/MapImageLayer',
  [DATA_SOURCE_TYPES.ESRI_TILED_MAP_SERVICE]: 'esri/layers/TileLayer',
  [DATA_SOURCE_TYPES.ESRI_VECTOR_TILE_SERVICE]: 'esri/layers/VectorTileLayer',
  [DATA_SOURCE_TYPES.ESRI_IMAGE_SERVICE]: 'esri/layers/ImageryLayer'
};

export const pointSymbol = {
  type: 'picture-marker',
  height: 38,
  width: 38,
  yoffset: 19,
  url: `${ environment.assetPath }/assets/img/maps/generic-marker.png`,
  color: null
};

export const activePointSymbol = {
  type: 'picture-marker',
  height: 38,
  width: 38,
  yoffset: 19,
  url: `${ environment.assetPath }/assets/img/maps/generic-active-marker.png`,
  color: null
};

const lineSymbol = {
  type: 'simple-line',
  color: [130, 130, 130, 1],
  width: 2
};

const polygonSymbol = {
  type: 'simple-fill',
  color: [150, 150, 150, .2],
  outline: {
    color: [50, 50, 50],
    width: 2
  }
};

export const DEFAULT_POINT_ZOOM_LEVEL = 15;

export const POPUP_ACTION_ID_OPEN_OBJECT = 'open-object';

const sketchToolSymbolMap = {
  [SKETCH_TOOL.POINT]: pointSymbol,
  [SKETCH_TOOL.POLYLINE]: lineSymbol,
  [SKETCH_TOOL.POLYGON]: polygonSymbol,
  [SKETCH_TOOL.RECTANGLE]: polygonSymbol,
  [SKETCH_TOOL.ADDRESS]: pointSymbol
};

export const SKETCH_ID = 'sketchLayer';

export const NOGGIN_QUERY_POPUP_TEMPLATE: esri.PopupTemplateProperties = {
  title: '{OBJECTTITLE}',
  actions: [
    {
      title: 'Open object',
      id: POPUP_ACTION_ID_OPEN_OBJECT,
      className: 'esri-icon-launch-link-external'
    } as esri.ActionButton
  ],
  content: (feature: esri.Feature): esri.ContentProperties[] => ([{
    type: 'fields',
    fieldInfos: (get(feature, 'graphic.layer.fields') as esri.Field[]).filter(
      ({ name }) => name !== 'OBJECTID' && name !== 'OBJECTTITLE'
    ).map(field => ({
      fieldName: field.name,
      label: field.alias
    }))
  }])
};

@Injectable({
  providedIn: 'root'
})
export class MapUtilService {

  constructor(
    private loggerService: LoggerService,
    private gisService: GisService,
    private sideSheetService: SideSheetService,
    private currentDatetimeService: CurrentDatetimeService,
    private mapService: MapService,
    @Inject(LOCALE_ID) private locale: string
  ) {
    loadScript({ version: '4.12' });
    MapUtilService.loadModules(['esri/config']).subscribe(([esriConfig]: [esri.config]) => {
      const interceptor: esri.RequestInterceptor = {
        headers: this.gisService.getAuthHeaders(),
        urls: gisServicePath,
        before: (params: { url: string, requestOptions: esri.RequestOptions }) => {
          const urlObj: url.Url = url.parse(params.url);
          const paths = urlObj.pathname.split('/');
          let pointer = paths.length - 1;
          if (paths[pointer] === 'query') {
            pointer = pointer - 2;
          }
          params.requestOptions.query.withExtent = decodeURIComponent(paths[pointer]);
          paths.splice(pointer, 1);
          pointer--;
          const vars = decodeURIComponent(paths[pointer]);
          paths.splice(pointer, 1);
          params.requestOptions.query.vars = vars;
          urlObj.pathname = paths.join('/');
          params.url = url.format(urlObj);
        },
        // @ts-ignore
        error: (error: esri.Error) => {
          if (error.name !== 'AbortError') {
            loggerService.error('[map-util-service] error setting ESRI interceptor:' + JSON.stringify(error));
          }
        }
      };

      esriConfig.request.interceptors = [interceptor];
    });
  }

  static loadModules(modules: string[]): Observable<any[]> {
    return from(esriLoadModules(modules));
  }

  static getExistingLayerIndex(layer: IMapLayer, layers: esri.Collection<esri.Layer>): number {
    return layers.findIndex(l => l.id.split('-')[0] === layer.$sid);
  }

  static getExistingLayer<T>(layer: IMapLayer, layers: esri.Collection<esri.Layer>): T | null {
    const index = MapUtilService.getExistingLayerIndex(layer, layers);
    if (index < 0) {
      return null;
    }

    return layers.getItemAt(index) as unknown as T;
  }

  static getLayerById(id: string, layers: esri.Collection<esri.Layer>): esri.Layer | undefined {
    return layers.find(l => l.id === id);
  }

  static generateLayerId(layer: IMapLayer): string {
    return `${ layer.$sid }-${ layer.dataSource.$sid }`;
  }

  loadGeoJSONtoGraphicsLayer(
    geo: GeometryObject,
    graphicsLayer: esri.GraphicsLayer,
    mapView: esri.MapView,
    sketchTool: SKETCH_TOOL
  ) {
    MapUtilService.loadModules([
      'esri/Graphic',
      'esri/geometry/support/jsonUtils',
      'esri/geometry/support/webMercatorUtils'
    ]).subscribe(
      (
        [esriGraphic, jsonUtils, webMercatorUtils]:
          [esri.GraphicConstructor, esri.jsonUtils, esri.webMercatorUtils]
      ) => {
        let geometry = jsonUtils.fromJSON(geojsonToArcGIS(geo));

        if (!mapView.spatialReference.isWGS84 && mapView.spatialReference.isWebMercator) {
          geometry = webMercatorUtils.geographicToWebMercator(geometry);
        }
        const symbol = sketchToolSymbolMap[sketchTool];
        const graphic = new esriGraphic({ geometry, symbol });
        graphicsLayer.add(graphic);
        if (sketchTool === SKETCH_TOOL.POINT || sketchTool === SKETCH_TOOL.ADDRESS) {
          mapView.goTo({
            target: graphic,
            zoom: DEFAULT_POINT_ZOOM_LEVEL
          });
        } else {
          mapView.goTo(graphic);
        }
      },
      error => {
        this.loggerService.error(`Error loading GeoJSON to layer: ${ error }`);
      }
    );
  }

  refreshLayers(
    layers: IMapLayer[],
    esriLayers: esri.Collection<esri.Layer>,
    queryAndFeatureQueryVars: [IVars, IVars] = [{}, {}],
    extent?: IMapExtent
  ): Observable<esri.Layer[]> {
    const layerIds = layers.map(layer => MapUtilService.generateLayerId(layer));

    // remove esri layers that does not exist any more except the graphic layer.
    esriLayers
      .filter(esriLayer => layerIds.indexOf(esriLayer.id) === -1 && esriLayer.id !== SKETCH_ID)
      .forEach(esriLayer => esriLayers.remove(esriLayer));

    // add the new layers
    return concat(...layers.map(
      layer => this.addLayer(layer, esriLayers, queryAndFeatureQueryVars, this.layerExtentOption(extent, layer)))
    ).pipe(
      toArray(),
      map(() => {
        // re-order the layers to match to input layers
        layerIds.forEach((layerId, newPos) => {
          esriLayers.reorder(MapUtilService.getLayerById(layerId, esriLayers), newPos);
        });

        return esriLayers.toArray();
      })
    );
  }

  layerExtentOption(extent: IMapExtent, layer?: IMapLayer): Observable<boolean> {
    if (isEmpty(extent)) {
      return of(false);
    }
    switch (extent.type) {
      case EXTENT_TYPES.ALL_LAYERS: return of(true);
      case EXTENT_TYPES.LOCATION:
      case EXTENT_TYPES.POLYGON: return of(false);
      case EXTENT_TYPES.LAYER: return of(!!get(extent, 'layer[0].$tip') && get(extent, 'layer').some(l => get(layer, '$tip') === l.$tip));
      case EXTENT_TYPES.DEFAULT:
        return this.mapService.getDefaultExtent().pipe(first(), switchMap(defaultExtent => this.layerExtentOption(defaultExtent)));
    }
  }

  addLayer(
    layer: IMapLayer,
    layers: esri.Collection<esri.Layer>,
    queryAndFeatureQueryVars: [IVars, IVars],
    withExtent$: Observable<boolean>
  ): Observable<esri.Layer> {
    const id = MapUtilService.generateLayerId(layer);

    const existingLayer = MapUtilService.getLayerById(id, layers);

    if (existingLayer) {
      return of(existingLayer); // do not add duplicate layer
    }

    return withExtent$.pipe(
      switchMap(withExtent => this.buildLayer(id, layer, queryAndFeatureQueryVars, withExtent)),
      map(esriLayer => {
        const existLayerIndex = MapUtilService.getExistingLayerIndex(layer, layers);

        if (existLayerIndex === -1) {
          layers.add(esriLayer);
        } else {
          layers.splice(existLayerIndex, 1, esriLayer);
        }

        return esriLayer;
      })
    );
  }

  buildLayer(id: string, layer: IMapLayer, queryAndFeatureQueryVars: [IVars, IVars], withExtent: boolean): Observable<esri.Layer> {
    if (
      layer.dataSource.type === DATA_SOURCE_TYPES.MAPBOX_VECTOR_TILES
    ) {
      return of();
    }

    let layerUrl: string;
    const additionalOption: {
      popupTemplate?: esri.PopupTemplateProperties,
      sublayers?: [{
        name: string
      }],
      definitionExpression?: string;
    } = {};

    if (layer.dataSource.type !== DATA_SOURCE_TYPES.NOGGIN_QUERY) {
      layerUrl = layer.dataSource.config.url;
      if (layer.dataSource.config.sublayerName) {
        additionalOption.sublayers = [{
          name: layer.dataSource.config.sublayerName
        }];
      }

      if (!isEmpty(layer.dataSource.config.featureQuery)) {
        additionalOption.definitionExpression =
          layer.dataSource.config.featureQuery.replace(featureQueryRegex, (match, name) => head(queryAndFeatureQueryVars[1][name]));
      }
    } else {
      // tslint:disable-next-line:max-line-length
      layerUrl = `${ this.gisService.getQueryLayerUrl(layer.$tip) }/${ encodeURIComponent(JSON.stringify(this.addNowVarToVars(queryAndFeatureQueryVars[0]))) }/${ withExtent }`;
      additionalOption.popupTemplate = NOGGIN_QUERY_POPUP_TEMPLATE;
    }

    return MapUtilService.loadModules([layerModuleMap[layer.dataSource.type]]).pipe(
      map(([esriLayerClass]) => new esriLayerClass({
        id,
        title: layer.name,
        url: layerUrl,
        opacity: layer.opacity ? layer.opacity / 100 : 1,
        ...additionalOption
      })),
      catchError(error => {
        this.loggerService.error(`Error building a layer: ${ error }`);
        return of(error);
      })
    );
  }

  addNowVarToVars(vars: IVars): IVars {
    return {
      ...vars,
      [NOW_VAR_NAME]: [this.currentDatetimeService.getCurrentDatetime()]
    };
  }

  openLayerControlLegendSideSheet(initialValue: ILayerControlLegend, serviceId: string) {
    const sheetRef = this.sideSheetService.push(LayerControlLegendSideSheetComponent);
    const sheetInstance: LayerControlLegendSideSheetComponent = sheetRef.componentInstance;

    sheetInstance.value = initialValue;
    sheetInstance.serviceId = serviceId;
  }

  formatPointCoordinates(point: Point): string {
    const coordinates = get(point, 'coordinates', []);
    return coordinates.slice().reverse()
      .map(coordinate => formatNumber(coordinate, this.locale, '1.0-5'))
      .join(', ');
  }

  extractReplacementFromFeatureQueries(featureQueries: string[]): string[] {
    let replacements: string[] = [];
    featureQueries.join().replace(featureQueryRegex, (match, name) => {
      replacements = union(replacements, [name]);
      return '';
    });
    return replacements;
  }

  mockFilterInputsFromFeatureQueries(featureQueries: string[]): IFilterInput[] {
    return this.extractReplacementFromFeatureQueries(featureQueries).map(
      replacement => ({
        variable: {
          name: replacement,
          type: null,
        },
        value: '',
        by: null
      })
    );
  }
}
