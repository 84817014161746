import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { LoadingState } from '../../shared/constants';
import { IsPasswordLoginService } from '../is-password-login.service';
import { first } from 'rxjs/operators';
import { AcceptInviteService } from './accept-invite.service';
import { LoggerService } from '../../util/logger.service';

// Validates that both passwords match
export const passwordsMatchValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const password = control.get('password');
  const again = control.get('again');

  return password && again && password.value && again.value && password.value !== again.value ? { 'passwordsMatch': false } : null;
};

@Component({
  selector: 'app-accept-invite',
  templateUrl: './accept-invite.component.html',
  styleUrls: ['./accept-invite.component.scss']
})
export class AcceptInviteComponent implements OnInit {
  isTokenValid: boolean;

  acceptInviteForm: FormGroup;

  state: LoadingState;
  email: string;
  token: string;
  passwordSignIn = false;
  checkingEmail = true;
  buttonText = '';
  authSecondaryTitle = '';
  errorStatus: 'weakPassword' | 'otherError' = null;

  constructor(
    private _route: ActivatedRoute,
    private acceptInviteService: AcceptInviteService,
    private isPasswordLoginService: IsPasswordLoginService,
    private loggerService: LoggerService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.isTokenValid = this._route.snapshot.data.isTokenValid;
    this.token = this._route.snapshot.queryParamMap.get('token');
    this.email = this._route.snapshot.queryParamMap.get('email');
    this.buildForm();

    this.isPasswordLoginService
      .checkEmailAddress$(this.email)
      .subscribe((passwordSignIn) => {
        this.checkingEmail = false;
        this.passwordSignIn = passwordSignIn;
        if (!passwordSignIn) {
          this.authSecondaryTitle = 'wait';
          this.onAcceptInvite();
        } else {
          this.authSecondaryTitle = 'enter';
          this.buttonText = passwordSignIn ? 'logIn' : 'activateAccount';
        }
      });
  }

  buildForm() {
    this.acceptInviteForm = this.fb.group(
      {
        password: ['', [Validators.required]],
        again: ['', [Validators.required]],
      },
      {validators: passwordsMatchValidator}
    );
  }

  onAcceptInvite() {
    this.state = LoadingState.inProgress;
    this.errorStatus = null;
    let acceptInvite$;

    if (this.passwordSignIn) {
      const {password} = this.acceptInviteForm.getRawValue();
      acceptInvite$ = this.acceptInviteService.acceptInvitePassword({
        token: this.token,
        email: this.email,
        password
      });
    } else {
      acceptInvite$ = this.acceptInviteService.acceptInviteSSO({
        email: this.email,
        token: this.token,
      });
    }

    acceptInvite$
      .pipe(first())
      .subscribe(
        () => {},
        error => {
          this.loggerService.error(error);
          this.state = LoadingState.failed;
          this.errorStatus = 'otherError';
          // @toDo: EIM-7410 Weak password code needs to be fixed, error.includes is not always a function, e.g. when network call failed
          // if (error.includes(AuthRegisterErrors.weakPassword)) {
          //   this.errorStatus = 'weakPassword';
          // } else {
          //   this.errorStatus = 'otherError';
          // }
        }
      );
  }
}
