import { ILinkType, ILinkData, INodeEditorData, INodeData } from '../workflow-designer-interfaces';
import { Node, Diagram, Part, Link } from 'gojs';
import { SELECT_LINK_RETURN_TYPE, OUTCOME_LABEL_MAP, PROCESS_NODE_TYPE } from '../workflow-designer-enums';
import { ILinkConfig, LinkEditorComponent } from '../link-editor/link-editor.component';
import { Observable, Subject } from 'rxjs';
import { UX_NODE_OUTCOME } from '../../modules/sidesheets/workflow-end-user-side-sheet/workflow-end-user.service';
import { IWorkflowSwitchCaseItem } from '../node-editors/shared/workflow-switch-case/workflow-switch-case.component';
import { SideSheetService } from '../../../side-sheet/side-sheet.service';

const NS = 'ConnectionUtils';

export class ConnectionUtils {

  /**
   * Returns a list of standard connection types for nodes with option to add more
   */
  static getBaseConnectionTypes(prependages?: ILinkType[], includeFinished = true): ILinkType[] {
    const links: ILinkType[] = [
      {
        name: OUTCOME_LABEL_MAP.failed,
        value: 'failed',
        hasDivider: false
      },
      {
        name: OUTCOME_LABEL_MAP.finally,
        value: 'finally',
        hasDivider: false
      }
    ];

    if (includeFinished) {
      links.unshift({
        name: OUTCOME_LABEL_MAP.done,
        value: 'done',
        hasDivider: false
      });
    }

    if (prependages) {
      return prependages.concat(links);
    } else {
      return links;
    }
  }

  static getWorkflowUXBaseConnectionTypes(appendages?: ILinkType[]): ILinkType[] {
    let links: ILinkType[] = [
      {
        name: UX_NODE_OUTCOME.secondary,
        value: UX_NODE_OUTCOME.secondary,
        hasDivider: true
      }
    ];

    if (appendages) {
      links[0].hasDivider = false;
      links = links.concat(appendages);
      links[links.length - 1].hasDivider = true;
    }
    return ConnectionUtils.getBaseConnectionTypes(links, false);
  }

  // using identifier based on case formula so that it does not depend on order which can change
  static getCaseIdentifier(caseItem: IWorkflowSwitchCaseItem): string {
    return 'case:' + JSON.stringify(caseItem.formula);
  }

  constructor(
    private diagram: Diagram,
    private sideSheetService: SideSheetService
  ) {}

  /**
   * Selects a link dynamically. Links don't have keys, so must use the
   * parent node and index of 'findLinksConnected'.
   */
  selectLink(nodeKey: string, linkIndex: number,
    returnType: SELECT_LINK_RETURN_TYPE = SELECT_LINK_RETURN_TYPE.link, triggerClick = false): Part | ILinkData {

    if (!nodeKey || linkIndex === null) {
      this.diagram.clearSelection();
      return null;
    }

    const node: Node = this.diagram.findNodeForKey(nodeKey);

    let index = 0;
    let selectedLink: Part;
    node.findLinksConnected().each(link => {
      if (linkIndex === index) {
        if (triggerClick) {
          link.click(null, link);
        }
        // need to use 'diagram.select', rather than 'link.isSelected = true', so that events are triggered.
        this.diagram.select(link);
        selectedLink = link;
      }
      index++;
    });

    if (returnType === SELECT_LINK_RETURN_TYPE.node) {
      return node;
    } else if (returnType === SELECT_LINK_RETURN_TYPE.link) {
      return selectedLink;
    } else {
      return selectedLink.part.data;
    }
  }

  /**
   * Returns a list of INodeEditorData from model data 'nodeEditorDataList', but only
   * those that originate from a Start node
   */
  getNodeEditorDataListFromStartNode(): INodeEditorData[] {

    const allNodes: INodeEditorData[] = this.diagram.model.modelData.nodeEditorDataList;

    const startNodes: INodeEditorData[] = allNodes.filter(o => o.processNodeType === PROCESS_NODE_TYPE.START);
    if (!startNodes.length) { return null; }

    const list: INodeEditorData[] = startNodes;
    startNodes.forEach((item: INodeEditorData) => {
      const node: Node = this.diagram.findNodeForKey(item.tip);

      if (node) {
        this.addNodesConnectedToThisOne(node, allNodes, list);
      }
    });

    return list;
  }

  /**
   * Returns nodes connected 'to' provided node (but not from), iterating down the links recursively
   */
  private addNodesConnectedToThisOne(thisNode: Node, allNodes: INodeEditorData[], list: INodeEditorData[] = []): void {

    thisNode.findLinksOutOf().iterator.each(link => {
      if (link.toNode) {

        // checks if exists already to stops circular refs adding multiple times
        const nodeExistsInList: boolean = list.map(o => o.tip).indexOf(link.toNode.data.key) === -1;
        if (nodeExistsInList) {
          list.push(allNodes.find(o => o.tip === link.toNode.data.key));
          this.addNodesConnectedToThisOne(link.toNode, allNodes, list);
        }

      }
    });
  }


  showSideSheetForLink(link: Link): Observable<ILinkData> {

    return new Observable( observer => {

      const sheetRef = this.sideSheetService.push(LinkEditorComponent);
      const componentInstance: LinkEditorComponent = sheetRef.componentInstance;
      const linkData: ILinkData = link.part.data;

      const fromNode: Node = this.diagram.findNodeForKey(linkData.from);
      const linkTypes: ILinkType[] = (fromNode.part.data as INodeData).linkTypes;
      componentInstance.outcomeCandidates = linkTypes.map(x => x.name);

      componentInstance.outcome = linkData.text;
      componentInstance.delaySec = linkData.delaySec;

      componentInstance.done = (data: ILinkConfig) => {
        this.updateLinkData(linkData, data, linkTypes);
        this.sideSheetService.pop();

        observer.next(linkData);
        observer.complete();
      };
    });
  }


  /**
   * Update linkdata based on the second parameter in a GoJS way.
   */
  private updateLinkData(linkData: ILinkData, newData: ILinkConfig, linkTypes: ILinkType[]): void {
    const HISTORY_NAME = 'Link data update';
    this.diagram.startTransaction(HISTORY_NAME);

    const thisLinkType: ILinkType = linkTypes.find(o => o.name === newData.outcome);

    linkData.delaySec = newData.delaySec;
    linkData.value = thisLinkType.value;
    linkData.text = thisLinkType.name;

    this.diagram.model.updateTargetBindings(linkData, 'text');

    this.diagram.commitTransaction(HISTORY_NAME);
  }

}
