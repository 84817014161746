import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, flatMap, map, switchMap, takeUntil } from 'rxjs/operators';
import { SideSheetService } from '../side-sheet/side-sheet.service';
import { IObjectAndType } from '../object/object.service';
import { FormulaMultiService } from '../data/formula-multi.service';
import { GetObjectAndFormService, IFormObjectAndType } from './get-object-and-form.service';
import { PlaceholderSideSheetComponent } from './object-launch/placeholder-side-sheet/placeholder-side-sheet.component';
import {
  ILoadParams,
  IObjectLaunchDone,
  IObjectLaunchDoneRedirect,
  IObjectLaunchDoneWithRedirect, IObjectLaunchProps,
  OBJECT_LAUNCH_OPERATION,
  REDIRECT_TYPES
} from './object-launch';
import { ObjectTypeSideSheetComponent } from './object-launch/object-type-side-sheet/object-type-side-sheet.component';
import { ObjectLaunchFormulasService } from './object-launch-formulas.service';
import { LifecycleWorkflowRedirectService } from './lifecycle-workflow-redirect.service';
import { ObjectLaunchDefaultService } from './object-launch-default.service';
import { IWellKnownReturnValue, WellKnownTypesService } from './well-known-types.service';

/**
 *
 * Dependency injecting the ObjectLaunchService within ObjectOpenSideSheetComponent, EditFormSideSheetComponent and well-known side sheet
 * will result in circular dependency. Use OBJECT_LAUNCH_SERVICE_TOKEN injection token.
 * Refer ObjectOpenSideSheetComponent for reference
 */
@Injectable({
  providedIn: 'root'
})
export class ObjectLaunchService {
  constructor(
    private sideSheetService: SideSheetService,
    private getObjectAndFormService: GetObjectAndFormService,
    private objectLaunchFormulasService: ObjectLaunchFormulasService,
    private lifecycleWorkflowRedirect: LifecycleWorkflowRedirectService,
    private formulaMultiService: FormulaMultiService,
    private objectLaunchDefaultService: ObjectLaunchDefaultService,
    private wellKnownType: WellKnownTypesService
  ) { }

  pushSheet(params: ILoadParams, cancel$: Observable<any>): Observable<IObjectLaunchDone | IObjectLaunchDoneRedirect> {
    // To prevent the placeholder from being pushed even if nothing is subscribing to `pushSheet`,
    // pushing the placeholder has been placed inside an observable
    const pushPlaceholderSheet = of(true)
      .pipe(
        map(() => this.sideSheetService.push(PlaceholderSideSheetComponent).componentInstance as PlaceholderSideSheetComponent)
      );

    return pushPlaceholderSheet
      .pipe(
        switchMap((placeholder: PlaceholderSideSheetComponent) => {
          if (params.operation === OBJECT_LAUNCH_OPERATION.VIEW) {
            return this.loadView(params, cancel$, placeholder);
          }
          return this.loadCreateEdit(params, cancel$, placeholder);
        })
      );
  }

  replaceSheet(params: ILoadParams, cancel$: Observable<any>): Observable<IObjectLaunchDone | IObjectLaunchDoneRedirect> {
    const pushPlaceholderSheet = of(true)
      .pipe(
        map(() => this.sideSheetService.replace(PlaceholderSideSheetComponent).componentInstance as PlaceholderSideSheetComponent)
      );

    return pushPlaceholderSheet
      .pipe(
        switchMap((placeholder: PlaceholderSideSheetComponent) => {
          if (params.operation === OBJECT_LAUNCH_OPERATION.VIEW) {
            return this.loadView(params, cancel$, placeholder);
          }
          return this.loadCreateEdit(params, cancel$, placeholder);
        })
      );
  }

  /*
  * Act as a facade for side sheet service, so we don't have to inject it
  * when we are using the object-launch service
  */
  pop(useHistory = false) {
    this.sideSheetService.pop(useHistory);
  }

  cleanAndPop(useHistory = false) {
    this.sideSheetService.makeClean();
    this.pop(useHistory);
  }

  private loadView(params: ILoadParams, cancel$, placeholder): Observable<IObjectLaunchDone> {
    return this
      .getObjectAndFormService
      .getObject$(params.objectTip)
      .pipe(
        flatMap((object: IObjectAndType) =>
          this.throwErrorForDynamicObjectType(object, object.objectType.$tip)
        ),
        switchMap((o: IObjectAndType) => this.lifecycleWorkflowRedirect.redirectViaOutletWithObject(o, cancel$)),
        catchError((err) => handleError(err, placeholder)),
        switchMap(({ object, redirected }: { object: IObjectAndType, redirected: boolean }) => {
          if (redirected) {
            return of(null);
          }
          const component = this.wellKnownType.loadViewConfig(object);
          const props = { ...params, object };
          return this.replaceComponent(component, props, cancel$);
        }),
        takeUntil(cancel$)
      );
  }

  private loadCreateEdit(params: ILoadParams, cancel$, placeholder): Observable<IObjectLaunchDone> {
    const launchSuperType$ = (formObjectAndType: IFormObjectAndType) => {
      const component = ObjectTypeSideSheetComponent;
      const props = { ...params, formObjectAndType };

      return this.replaceComponent(component, props, cancel$);
    };

    const launchObject$ = (fot: IFormObjectAndType) =>
      this.throwErrorForDynamicObjectType(fot, fot.objectAndType.objectType.$tip).pipe(
        switchMap(
          (formObjectAndType: IFormObjectAndType) =>
            this.lifecycleWorkflowRedirect.redirectViaOutletWithFormAndObject(formObjectAndType, params.preview)
        ),
        switchMap(
          (formObjectAndType: IFormObjectAndType) => this.objectLaunchDefaultService.setSequenceFieldReadOnly(formObjectAndType)
        ),
        switchMap(
          (formObjectAndType: IFormObjectAndType) => this.objectLaunchDefaultService.populateFormDefaultValues(formObjectAndType, params)
        ),
        map((formObjectAndType: IFormObjectAndType) => this.wellKnownType.loadEditCreateConfig(formObjectAndType, params.formTip)),
        switchMap(({ formObjectAndType, component, objectLaunchOverrides }: IWellKnownReturnValue) => {
          const props: IObjectLaunchProps = { ...params, formObjectAndType, ...objectLaunchOverrides };
          return this.replaceComponent(component, props, cancel$);
        }),
        map(addRedirectToDashboardIfRequired)
      );

    return this.getObjectAndFormService.getFormObjectAndType(params).pipe(
      flatMap((object: IFormObjectAndType) => {
        if (object.isSuperType) {
          return launchSuperType$(object);
        }
        return launchObject$(object);
      }),
      catchError((err) => handleError(err, placeholder)),
      takeUntil(cancel$)
    );
  }

  private replaceComponent(component, props, cancel$): Observable<IObjectLaunchDone> {
    const { componentInstance } = this.sideSheetService.replace(component, props);

    if (componentInstance.setProps) {
      componentInstance.setProps(props);
    }

    // in the case that we do not have a done$ subject
    // emit done when cancel fires to complete any observables
    let done$ = null;
    if (componentInstance.done$) {
      done$ = componentInstance.done$.pipe(takeUntil(cancel$));
    }

    return done$ || cancel$;
  }

  private throwErrorForDynamicObjectType(result: IObjectAndType | IFormObjectAndType, typeTip) {
    return this.objectLaunchFormulasService.isRestrictedSystemType$(typeTip)
      .pipe(
        flatMap((isSystemType) => {
          if (isSystemType) {
            return throwError({ errorMessage: 'Users cannot load dynamic system objects' });
          }
          return of(result);
        })
      );
  }
}

export function addRedirectToDashboardIfRequired(result: IObjectLaunchDone):
  IObjectLaunchDoneWithRedirect {
  const { objectTip, defaultDashboardTip } = result;

  if (defaultDashboardTip && objectTip) {
    const redirect: IObjectLaunchDoneRedirect = {
      redirectLocation: `app/s/dashboard/${encodeURIComponent(defaultDashboardTip)}/${encodeURIComponent(objectTip)}`,
      type: REDIRECT_TYPES.dashboard
    };
    return { ...result, redirect };
  }

  return { ...result, redirect: result.redirect || null };
}

function handleError(err, placeholder: PlaceholderSideSheetComponent) {
  placeholder.setProps({ errorMessage: err.errorMessage || 'Error loading object.' });

  return throwError(err);
}
