import { ALL_FIELD_DATA_TYPE, EXTENDED_FIELD_DATA_TYPE, FIELD_DATA_TYPE, IField, TYPE_GEO_RESTRICT } from '../models/field';
import { TagMap, tagToMap } from './tag-utils';
import { Tip } from '../data/models/types';
import { get } from 'lodash';
import { SUB_OBJECT_TAG_KEY } from '../settings/object-type/object-type-designer-field/constants';

interface IFieldEssential {
  datatype: FIELD_DATA_TYPE;
  typerestrict?: Tip[];
  typeGeoRestrict?: TYPE_GEO_RESTRICT;
  tag?: string[];
}

interface IDetermineDatatypeParams<T extends IFieldEssential> {
  field: T;
}

interface IDetermineDatatypeReturnParams {
  _datatype: ALL_FIELD_DATA_TYPE;
  listTip?: string;
  sequenceTip?: string;
  typerestrict?: Tip[] | null;
  isSequence?: boolean; // todo remove this in favour of making sequence a type
}

export function getDataTypeAndMetaFromField<T extends IFieldEssential>({ field }: IDetermineDatatypeParams<T>)
  : IDetermineDatatypeReturnParams {

  const { tag } = field;
  const tagMap = tagToMap(tag);

  if (isFileType(field)) {
    return { _datatype: EXTENDED_FIELD_DATA_TYPE.file };
  }

  if (isAddressType(field)) {
    return { _datatype: EXTENDED_FIELD_DATA_TYPE.address };
  }

  if (isAssignableType(field)) {
    return { _datatype: EXTENDED_FIELD_DATA_TYPE.assignable };
  }

  if (isSubObjectType(field, tagMap)) {
    return {
      _datatype: EXTENDED_FIELD_DATA_TYPE.sub_object,
      typerestrict: get(field, 'typerestrict', null)
    };
  }

  if (isObjectType(field, tagMap)) {
    // need is object as file is also an object type
    return { _datatype: FIELD_DATA_TYPE.object, typerestrict: get(field, 'typerestrict', null) };
  }

  if (field.datatype === FIELD_DATA_TYPE.geography) {
    const geographyMap = {
      point: EXTENDED_FIELD_DATA_TYPE.point,
      linestring: EXTENDED_FIELD_DATA_TYPE.line,
      polygon: EXTENDED_FIELD_DATA_TYPE.polygon
    };

    return { _datatype: geographyMap[field.typeGeoRestrict] };
  }

  const valueType = tagMap.get('valueType');
  const listTip = tagMap.get('listTip');
  const sequenceTip = tagMap.get('sequenceTip');

  if (valueType === 'app/list' && listTip) {
    return {
      _datatype: EXTENDED_FIELD_DATA_TYPE.list,
      listTip: tagMap.get('listTip')
    };
  }

  if (valueType === 'app/sequence' && sequenceTip) {
    return {
      _datatype: FIELD_DATA_TYPE.string,
      sequenceTip: tagMap.get('sequenceTip'),
      isSequence: true
    };
  }

  return { _datatype: field.datatype };
}

function isObjectType<T extends IFieldEssential>(field: T, tagMap: TagMap) {
  const typerestrict = get(field, 'typerestrict', []);
  const isObject = field.datatype === FIELD_DATA_TYPE.object;
  const notSpecificObjectType: boolean = isNotSpecificObjectType(typerestrict);

  return isObject && notSpecificObjectType;
}

function isSubObjectType<T extends IFieldEssential>(field: T, tagMap) {
  const { datatype } = field;
  const isObject = datatype === FIELD_DATA_TYPE.object;
  const subObjectTag = hasSubObjectTag(tagMap);
  return isObject && subObjectTag;
}

function isNotSpecificObjectType(typerestrict: string[]) {
  const notFile = typerestrict[0] !== 'file';
  const notAddress = typerestrict[0] !== 'app/address';
  const notAssignable = typerestrict[0] !== 'app/assignable';

  return notFile && notAddress && notAssignable;
}

function isFileType<T extends IFieldEssential>(field: T): boolean {
  const { typerestrict, datatype } = field;
  const isObject = datatype === FIELD_DATA_TYPE.object;
  const fileTypeRestrict: boolean = typerestrict && typerestrict[0] === 'file';

  return isObject && fileTypeRestrict;
}

function hasSubObjectTag(tagMap: TagMap): boolean {
  return tagMap.get(SUB_OBJECT_TAG_KEY) === 'true';
}

function isAddressType<T extends IFieldEssential>(field: T): boolean {
  const { typerestrict, datatype } = field;
  const isObject = datatype === FIELD_DATA_TYPE.object;
  const addressTypeRestrict: boolean = typerestrict && typerestrict[0] === 'app/address';

  return isObject && addressTypeRestrict;
}

function isAssignableType<T extends IFieldEssential>(field: T) {
  const { typerestrict, datatype } = field;
  const isObject = datatype === FIELD_DATA_TYPE.object;
  const assignableTypeRestrict: boolean = typerestrict && typerestrict[0] === 'app/assignment';

  return isObject && assignableTypeRestrict;
}
