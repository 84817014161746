import { Parser as LexerParser } from './formula-lexer';
import { merge, get, has } from 'lodash';
import { isFormulaSpec } from '../../../util/is-formula-spec';

let parser: any;

export type FormulaLike = FormulaSpec | string | number;

export interface FormulaSpec {
    name: string;
    args: Array<FormulaLike>;
}

// Create a new parser factory
function parserFactory() {
    const newParser = new LexerParser();
    newParser.yy = {
        handler: {
            helper: {
                number(num: number | string) {
                    switch (typeof num) {
                        case 'number':
                            return num;
                        case 'string':
                            if (!isNaN(num as any)) {
                                return num.indexOf('.') > -1 ? parseFloat(num) : parseInt(num, 10);
                            }
                    }
                    return num;
                },
                numberInverted(num: number | string) {
                    return this.number(num) * (-1);
                },
                string(str: string) {
                    return str.substring(1, str.length - 1).replace(/\\"/g, '"');
                },
                callFunction(funcName: string, args: any[] = []): FormulaSpec {
                    return { name: funcName.toUpperCase(), args };
                }
            }
        }
    };
    return newParser;
}

// Convert a formula string to a formula spec
export function Parser(formulaStr: string): FormulaSpec {
    if (!parser) {
        parser = parserFactory();
    }
    return parser.parse(formulaStr);
}

// Convert a formula spec to a formula string
export function Stringify(formula: FormulaSpec): string {
    const argStrs = formula.args.map(arg => {
        if (typeof arg === 'string') {
            return QuoteString(arg);
        }
        if (typeof arg === 'number') {
            return isNaN(arg) ? '' : arg.toString();
        }
        return Stringify(arg);
    });
    return formula.name + '(' + argStrs.join(',') + ')';
}

// DO NOT DIRECTLY IMPORT AND USE IT. Use FormulaService's quoteString.
// Using FormulaService's function will make unit test easier and isolated as directly exported function is hard to mock
// Safely quote a string
export function QuoteString(str: string): string {
    return '"' + str.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '"';
}

// Returns true if the formula is a formula spec. As opposed to a static string or number
export function IsFormulaSpec(formula: string | number | FormulaSpec) {
    return typeof formula !== 'string' && typeof formula !== 'number';
}

// Analyse the given formulas to determine if they are all static values, and if so return those values, otherwise null
export function StaticResultAnalysis(formulaStr: string, vars?: { [key: string]: string[] }): string[] {
    const formula = Parser(formulaStr);
    if (IsFormulaSpec(formula)) {
        if (formula.name === 'ARRAY' && formula.args.filter(IsFormulaSpec).length === 0) {
            return formula.args.map(String);
        }
        if (formula.name === 'VAR' && formula.args.length === 1 && !IsFormulaSpec(formula.args[0])) {
            const values = get(vars, String(formula.args[0]), null);
            if (values !== null) {
                return values;
            }
        }
    }
    throw new Error('Formula is not static');
}

// Extracts out function arguments in the given formula using the given template
// Will throw an error if the formula does not conform exactly to the template
// Returns an object with the template named key/value pairs of extracted information
export function ParseTemplate(formula: FormulaSpec, template: FormulaSpec): { [key: string]: string|number } {
    const result = {};

    if (formula.name !== template.name) {
        parseTemplateErr(formula, template, 'Unexpected formula name');
    }

    if (formula.args.length !== template.args.length) {
        parseTemplateErr(formula, template, 'Argument length different');
    }

    for (let i = 0; i < template.args.length; i++) {
        if (typeof template.args[i] === 'string' && (template.args[i] as string).substr(0, 1) === '$') {
            if (IsFormulaSpec(formula.args[i])) {
                parseTemplateErr(formula, template, 'Expected static value for argument ' + (1 + i));
            } else {
                result[template.args[i] as string] = formula.args[i];
            }
        } else if (!IsFormulaSpec(template.args[i])) {
            if (formula.args[i] !== template.args[i]) {
                parseTemplateErr(formula, template, 'Unexpected value argument ' + (1 + i));
            }
        } else {
            if (IsFormulaSpec(formula.args[i])) {
                merge(result, ParseTemplate(formula.args[i] as FormulaSpec, template.args[i] as FormulaSpec));
            } else {
                const subFormulaName = (template.args[i] as FormulaSpec).name;
                parseTemplateErr(formula, template, 'Expected ' + subFormulaName + ' formula for argument ' + (1 + i));
            }
        }
    }

    return result;
}

// Throws an error when a formula doesn't conform to a template with meaningful information
function parseTemplateErr(formula: FormulaSpec, template: FormulaSpec, message: string) {
    throw new Error('Does not conform to formula template: ' + message + ': ' + Stringify(formula) + ' != ' + Stringify(template));
}

// Returns a new formula spec given a template and inserts to replace values in the template
export function WriteTemplate(template: FormulaSpec, inserts: { [key: string]: string|number }): FormulaSpec {
    const formula = { name: template.name, args: [] };

    formula.args = template.args.map(arg => {
        if (IsFormulaSpec(arg)) {
            return WriteTemplate(arg as FormulaSpec, inserts);
        }
        if (typeof arg === 'string' && (arg as string).substr(0, 1) === '$') {
            return inserts.hasOwnProperty(arg) ? inserts[arg] : { name: 'ARRAY', args: [] };
        }
        return arg;
    });

    return formula;
}

// Parse a string with embedded formulas
export function ParseEmbedded(input: string): FormulaLike[] {
    const results: FormulaLike[] = [];
    let buffer = '';
    let state = 0;
    for (let i = 0; i < input.length; i++) {
        if (state === 0) {
            // Outside formula
            if (input[i] === '{' && input[i+1] === '=') {
                state = 1;
                i++;
                if (buffer.length > 0) {
                    results.push(buffer);
                    buffer = '';
                }
            } else {
                buffer += input[i];
            }
        } else if (state === 1) {
            // Inside formula, but outside quotes
            if (input[i] === '}') {
                try {
                    results.push(Parser(buffer));
                } catch (err) {
                    throw new Error('Invalid formula: ' + buffer);
                }
                buffer = '';
                state = 0;
            } else {
                if (input[i] === '"') {
                    state = 2;
                }
                buffer += input[i];
            }
        } else if (state === 2) {
            // Inside formula, and inside quotes
            if (input[i] === '\\') {
                state = 3;
            } else if (input[i] === '"') {
                state = 1;
            }
            buffer += input[i];
        } else if (state === 3) {
            // Inside formula, inside quotes, escaping
            state = 2;
            buffer += input[i];
        }
    }
    if (state !== 0) {
        throw new Error('Unfinished formula: ' + buffer);
    }
    if (buffer.length > 0) {
        results.push(buffer);
    }
    return results;
}

// Pre-define and cache our seed formula templates
let seedFormulaTemplates: { byTip: FormulaSpec[], byName: FormulaSpec[], fmtField: FormulaSpec[] };
const getSeedFormulaTemplates = () => {
    if (!seedFormulaTemplates) {
        seedFormulaTemplates = {
            byTip: [
                Parser('FIELD("$fieldTip")'),
                Parser('FIELD("$fieldTip", TIP())'),
                Parser('FIELD_VALUES("$fieldTip")'),
                Parser('FIELD_VALUES("$fieldTip", TIP())')
            ],
            byName: [
                Parser('FIELD_BY_NAME("$fieldName")'),
                Parser('FIELD_BY_NAME("$fieldName", TIP())'),
                Parser('FIELD_BY_KEY("$typeTip", "$fieldName")'),
                Parser('FIELD_BY_KEY("$typeTip", "$fieldName", TIP())')
            ],
            fmtField: [
                Parser('FMT_FIELD("$fieldTip", TIP())'),
                Parser('FMT_FIELD("$fieldTip")')
            ]
        };
    }
    return seedFormulaTemplates;
}

// Seed a formula with variables to replace out the current context's values best we can
// options.fieldNames is a map from fieldName => fieldTip
// options.ruleTips is a map from fieldTip => ruleTip for formatting
export function seedFormula(formula: FormulaLike, options?: { fieldNames?: { [key: string]: string }, ruleTips?: { [key: string]: string } }): FormulaLike {
    if (!isFormulaSpec(formula)) {
        return formula;
    }

    const { byTip, byName, fmtField } = getSeedFormulaTemplates();

    // Replace FIELD() and FIELD_VALUES() with a variable
    for (let i = 0; i < byTip.length; i++) {
        try {
            const match = ParseTemplate(formula, byTip[i]);
            return { name: 'VAR', args: [match.$fieldTip] };
        } catch (err) {};
    }

    // Replace FIELD_BY_NAME() and FIELD_BY_KEY() with a variable
    for (let i = 0; i < byName.length; i++) {
        try {
            const match = ParseTemplate(formula, byName[i]);
            const fieldTip = get(options, ['fieldNames', match.$fieldName]);
            if (fieldTip) {
                return { name: 'VAR', args: [fieldTip] };
            }
            return { name: 'ARRAY', args: [] }; // We don't have a field by that name
        } catch (err) {};
    }

    // Replace FMT_FIELD() with a variable
    for (let i = 0; i < fmtField.length; i++) {
        try {
            const match = ParseTemplate(formula, fmtField[i]);
            const ruleTip = get(options, ['ruleTips', match.$fieldTip]);
            if (ruleTip) {
                return { name: 'FMT', args: [ { name: 'VAR', args: [match.$fieldTip] }, ruleTip ]};
            }
            return { name: 'VAR', args: [match.$fieldTip] }; // No format rule, so just show as is
        } catch (err) {};
    }

    // Jump over CONTEXT() if the first argument is TIP(), otherwise don't continue
    if (formula.name === 'CONTEXT') {
        if (get(formula, 'args[0].name') === 'TIP' && get(formula, 'args[0].args', []).length === 0) {
            return seedFormula(formula.args[1], options);
        }
        if (formula.args.length > 0) {
            formula.args[0] = seedFormula(formula.args[0], options);
        }
        return formula;
    }

    // Continue deeper into the formula
    return {
        name: formula.name,
        args: formula.args.map(arg => seedFormula(arg, options))
    };
}

