import { Injectable } from '@angular/core';
import { Observable, throwError, combineLatest,  of } from 'rxjs';
import { map, first, switchMap, mapTo, catchError } from 'rxjs/operators';
import { Batch, Tip } from '../../data/models/types';
import { INodeEditorData, ILinkData } from './workflow-designer-interfaces';
import { ProcessConverterService } from './process-converter.service';
import { ModuleService, MODULE_POLICY_TYPE } from '../modules/module.service';
import dataConstants from '../../data/constants';
import { IWorkflow, IWorkflowRead, workflowReadScheme, workflowScheme } from '../../models/workflow';
import { IProcess, processScheme } from '../../models/process';
import { ObjectService } from '../../data/object-service/object.service';
import { FormulaService } from '../../data/formula.service';
import { head } from 'lodash';
import { ModulePackageService } from '../modules/module-package.service';
import { ProcessService } from '../../data/process.service';

export const EMPTY_DIAGRAM = { nodeDataArray: [], linkDataArray: [] };

@Injectable({
  providedIn: 'root'
})
export class WorkflowDesignerService {
  private currentWorkflow: IWorkflow;

  constructor(
    private processConverterService: ProcessConverterService,
    private processService: ProcessService,
    private objectService: ObjectService,
    private moduleService: ModuleService,
    private formulaService: FormulaService,
    private modulePackageService: ModulePackageService
  ) {}

  deleteWorkflow(tip: Tip, moduleTip?: Tip): Observable<boolean> {
    return combineLatest([
      this.deleteWorkflowTriggers(tip),
      this.softDeleteWorkflow(tip, moduleTip)
    ]).pipe(
      map(([deleteTriggerResponse, softDeleteWorkflowResponse]) => {
        return deleteTriggerResponse.finished && softDeleteWorkflowResponse;
      })
    );
  }

  deleteWorkflowTriggers(tip: Tip) {
    return this.processService.start('eim/process/disable-workflow-trigger', {'Workflow tip': [tip]});
  }

  softDeleteWorkflow(tip: Tip, moduleTip?: Tip) {
    return this.formulaService.evaluate('FIELD("app/workflow:process")', tip)
      .pipe(
        map(head),
        switchMap((process: Tip) => {
          const tipsToPatch = process
            ? [process, tip]
            : [tip];

          return this
            .moduleService
            .deleteModuleConfig(tipsToPatch);
        }),
        map(_ => true),
        catchError(() => {
          return this.modulePackageService.handleError('delete workflow', moduleTip);
        })
      );
  }

  // TODO EIM-6092. This should be using IWorkflowRead.
  getWorkflow(tip?: Tip): Observable<IWorkflow> {
    return this.objectService.getObject<IWorkflow>(tip, workflowReadScheme)
      .pipe(
        first((value) => {
          this.currentWorkflow = value;
          return true;
        })
      );
  }

  getWorkflowRead(tip?: Tip): Observable<IWorkflowRead> {
    return this.objectService.getObject<IWorkflowRead>(tip, workflowReadScheme).pipe(first());
  }

  saveWorkflow(workflow: IWorkflow, nodeDataForProcess: INodeEditorData[], moduleTip: Tip, dryRun = false): Observable<boolean> {
    try {
      if (nodeDataForProcess) {
        workflow.process = this.processConverterService.convertWorkFlowToProcess(workflow, nodeDataForProcess);
      } else {
        // if no nodeDataForProcess, clears the nodes prop on process
        if (workflow.process) {
          workflow.process.nodes = [];
        }
        workflow.process = workflow.process || this.getEmptyProcess();
      }

      return combineLatest([
        this.moduleService.getModulePolicy(moduleTip, MODULE_POLICY_TYPE.WORKFLOW_PROCESS),
        this.moduleService.getModulePolicy(moduleTip, MODULE_POLICY_TYPE.INSTANCE)
      ])
      .pipe(
        switchMap(([workflowProcessPolicy, instancePolicy]: [Tip, Tip]) => {
          if (workflow && workflow.process) {
            workflow.process.$security = workflowProcessPolicy
              || 'app/security-policy/process-workflow-user'; // -!-!-!- DELETE BACKUP OPTION AFTER MIGRATION - EIM-2691 -!-!-!-;
          }

          if (dryRun) {
            return of(workflow);
          }

          return this.objectService.setObject(
            workflow,
            workflowScheme,
            dataConstants.BRANCH_MASTER,
            instancePolicy || 'app/security-policy/instance-admin-admin', // -!-!-!- DELETE BACKUP OPTION AFTER MIGRATION - EIM-2691 -!-!-!-
          );
        }),
        map(convertedWorkflow => dryRun ? convertedWorkflow : true),
        catchError(() => {
          return this.modulePackageService.handleError('save workflow', moduleTip);
        })
      );
    } catch (e) {
      return throwError(e);
    }
  }

  saveWithoutProcess(workflow: IWorkflow, moduleTip: Tip): Observable<boolean> {
    try {
      return combineLatest([
        this.moduleService.getModulePolicy(moduleTip, MODULE_POLICY_TYPE.WORKFLOW_PROCESS),
        this.moduleService.getModulePolicy(moduleTip, MODULE_POLICY_TYPE.INSTANCE)
      ])
      .pipe(
        switchMap(([workflowProcessPolicy, instancePolicy]: [Tip, Tip]) => {
          workflow.process = this.getEmptyProcess(workflow.name, workflow.description, workflowProcessPolicy);
          return this.objectService.setObject(
            workflow,
            workflowScheme,
            dataConstants.BRANCH_MASTER,
            instancePolicy || 'app/security-policy/instance-admin-admin', // -!-!-!- DELETE BACKUP OPTION AFTER MIGRATION - EIM-2691 -!-!-!-
          );
        }),
        map(() => true),
        catchError(() => {
          return this.modulePackageService.handleError('save workflow', moduleTip);
        })
      );
    } catch (e) {
      return throwError(e);
    }
  }

  private getEmptyProcess(
    name: string = '',
    description: string = '',
    workflowProcessPolicy: Tip = 'app/security-policy/process-workflow-user', // -!-!-!- DELETE BACKUP OPTION AFTER MIGRATION - EIM-2691 -!-!-!-
  ): IProcess {
    return {
      $type: 'process',
      $security: workflowProcessPolicy,
      title: name,
      description,
      nodes: [],
      env: 'Server'
    };
  }

  /**
   * When creating a new workflow, this inserts an empty process
   */
  private createEmptyProcess(name: string, description: string, workflowProcessPolicy: Tip): Observable<IProcess> {
    const process: IProcess = this.getEmptyProcess(name, description, workflowProcessPolicy);
    return this.objectService
      .setObject(process, processScheme)
      .pipe(
        first(),
        map((batch: Batch) => {
          process.$tip = batch[0].tip;
          return process;
        })
      );
  }


  createNewWorkflow(name: string, description: string, moduleTip: Tip): Observable<Tip> {
    return this.moduleService
      .getModulePolicy(moduleTip, MODULE_POLICY_TYPE.WORKFLOW_PROCESS)
      .pipe(
        first(),
        switchMap((workflowProcessPolicy: Tip) => {
          return combineLatest([
            this.createEmptyProcess(name, description, workflowProcessPolicy).pipe(first()),
            this.moduleService.getModulePolicy(moduleTip, MODULE_POLICY_TYPE.INSTANCE).pipe(first())
          ]);
        }),
        switchMap(([process, instancePolicy]: [IProcess, Tip]) => {
          this.currentWorkflow = {
            $type: 'app/workflow',
            process,
            isAdminMode: false,
            diagramData: EMPTY_DIAGRAM,
            name,
            description,
            nodes: null,
            inputs: [],
            actors: [],
            variables: []
          };

          return this.objectService.setObject(
            this.currentWorkflow,
            workflowScheme,
            dataConstants.BRANCH_MASTER,
            instancePolicy || 'app/security-policy/instance-admin-admin', // -!-!-!- DELETE BACKUP OPTION AFTER MIGRATION - EIM-2691 -!-!-!-
          )
          .pipe(first());
        }),
        switchMap((batch: Batch) => {
          const tip = batch[batch.length - 1].tip;
          this.currentWorkflow.$tip = tip;
          return this.moduleService
            .addTipToFlatModuleField({
              moduleTip,
              moduleField: 'workflows',
              tip
            })
            .pipe(mapTo(tip));
        }),
        catchError(() => {
          return this.modulePackageService.handleError('save workflow', moduleTip);
        })
      );
  }

}
