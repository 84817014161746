import { Injectable } from '@angular/core';
import { CompositeNodeConverter, INodeAndLink } from './CompositeNodeConverter';
import { IExportEnosEditor } from '../node-editors/export-enos-editor/export-enos-editor.component';
import { Tip } from '../../../data/models/types';
import { Parser, Stringify } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { isEmpty } from 'lodash';
import {
  createConnection,
  createFormulaFromStringInterpolation,
  createSetVarNode,
  createSubProcessNode,
  createSwitchNode
} from './utils/create-node-utils';
import { ISubstitutionMeta } from '../../../form/string-interpolation/string-interpolation.service';

@Injectable({
  providedIn: 'root'
})
export class ExportEnoConverterService extends CompositeNodeConverter {
  constructor() {
    super();
  }

  public convert(nodeData: IExportEnosEditor): INodeAndLink {
    const {filename, queryAndFilter, securityPolicy, typeTip, variableToSetKey} = nodeData.fields;

    const vars = {
      'Type tip': typeTip,
      'Var key': variableToSetKey,
      Filename: createFileName(filename, typeTip),
      'Query tip': queryAndFilter.queryTip,
      'Query and filter options JSON': JSON.stringify(queryAndFilter),
      'Security policy': securityPolicy
    };

    // create nodes
    const setVarNode = createSetVarNode(vars, nodeData.tip);
    const exportEnosSubProcess = createSubProcessNode('eim/process/workflow/export-enos', vars);
    const switchNode = createSwitchNode({name: 'VAR', args: ['Status']});

    // connect setVar "done" to "exportEnosSubProcess"
    const setVarDone = createConnection('done', exportEnosSubProcess);
    setVarNode.connections.push(setVarDone);

    // connect sub process "done" to "switchNode"
    const exportEnosSubProcessDone = createConnection('done', switchNode);
    exportEnosSubProcess.connections.push(exportEnosSubProcessDone);

    const nodesAndLink: INodeAndLink = {
      nodes: [
        setVarNode,
        exportEnosSubProcess,
        switchNode
      ],
      links: [
        setVarDone,
        exportEnosSubProcessDone
      ]
    };

    this.populateOutgoingLinks(nodeData).forEach(link => {
      switchNode.connections.push(link);
      nodesAndLink.links.push(link);
    });

    return nodesAndLink;
  }

}

function createFileName(filename: ISubstitutionMeta, typeTip): string {
  if (isEmpty(filename) || isEmpty(filename.html)) {
    return createDefaultFileNameFromObjectType(typeTip);
  }
  return createFormulaFromStringInterpolation(filename);
}

export function createDefaultFileNameFromObjectType(typeTip: Tip) {
  return Stringify({
    name: 'CONCAT',
    args: [
      'export-',
      {
        name: 'TITLE',
        args: [
          {
            name: 'REFERENCES',
            args: [
              'app/object-type:type',
              typeTip
            ]
          }
        ]
      },
      '-UTC-',
      {
        name: 'FORMAT_DATETIME',
        args: [{ name: 'NOW', args: [] }, 'Ymd-His']
      },
    ]
  });
}
