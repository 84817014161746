import { IForm } from '../../settings/form-designer/models/form';
import { IObjectType } from '../../models/object-type';
import { IType } from '../../models/type';

const minutesToSeconds = x => x * 60;
const hourToSecond = x => x * minutesToSeconds(60);
const daysToSeconds = x => x * hourToSecond(24);

const durationField = ({fieldTip, label, options}) => ({
  type: 'option-list',
  templateOptions: {
    contextTypeTip: fieldTip,
    label,
    options: options
  },
});

export const generalSecuritySettingsForm: IForm = {
  name: 'General security settings form',
  description: '',
  size: 'regular',
  version: 1,
  titleOverride: 'Manage general security settings',
  contextType: {
    type: {
      $tip: 'app/general-security-settings',
    } as IType
  } as IObjectType,
  tabs: [
    {
      tabName: 'tab1',
      rows: [
        {
          fields: [
            durationField({
              fieldTip: 'app/general-security-settings:session-expiration-timeout',
              label: 'Session expiration timeout',
              options: [
                {label: '1 day', value: daysToSeconds(1)},
                {label: '7 days', value: daysToSeconds(7)},
                {label: '14 days', value: daysToSeconds(14)},
                {label: '30 days', value: daysToSeconds(30)},
                {label: '90 days', value: daysToSeconds(90)}
              ]
            }),
          ]
        },
        {
          fields: [
            durationField({
              fieldTip: 'app/general-security-settings:session-inactivity-timeout',
              label: 'Session inactivity timeout',
              options: [
                {label: 'No timeout', value: 0},
                {label: '15 minutes', value: minutesToSeconds(15)},
                {label: '20 minutes', value: minutesToSeconds(20)},
                {label: '30 minutes', value: minutesToSeconds(30)},
                {label: '60 minutes', value: minutesToSeconds(60)},
              ]
            })
          ]
        },
        {
          fields: [
            durationField({
              fieldTip: 'app/general-security-settings:invitation-expiration-time',
              label: 'Email invitation expiration time',
              options: [
                {label: '60 minutes', value: minutesToSeconds(60)},
                {label: '1 day', value: daysToSeconds(1)},
                {label: '2 days', value: daysToSeconds(2)},
                {label: '7 days', value: daysToSeconds(7)},
                {label: '30 days', value: daysToSeconds(30)},
              ]
            })
          ]
        }
      ]
    }
  ]
};
