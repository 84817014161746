import { Injectable } from '@angular/core';
import { NodeConverterBase } from './NodeConverterBase';
import { INodeEditorData } from '../workflow-designer-interfaces';
import { IProcessNodeNoOp } from '../../../models/process';
import dataConstants from '../../../data/constants';

@Injectable({
  providedIn: 'root'
})
export class EndNodeConverterService extends NodeConverterBase {
  type = 'processnode/no-op';
  processNodeType = 'processnodetype/no-op';

  public convert(nodeData: INodeEditorData): IProcessNodeNoOp {
    return {
      $type: this.type,
      $security: dataConstants.SECURITY.INSTANCE_USER_ADMIN,
      type: this.processNodeType,
      connections: [],
      point: [0, 0],
      key: nodeData.tip
    };
  }

  constructor() {
    super();
  }
}
