import * as tslib_1 from "tslib";
import * as momentTz from 'moment-timezone';
export function getTimezonesAsOptionList(isUserTimezone) {
    if (isUserTimezone === void 0) { isUserTimezone = false; }
    var userTimezone = [{
            value: 'User timezone',
            label: 'User timezone'
        }];
    var momentTimezones = momentTz.tz.names().map(function (name) { return ({
        label: name,
        value: name
    }); });
    return isUserTimezone ? tslib_1.__spread(userTimezone, momentTimezones) : tslib_1.__spread(momentTimezones);
}
export function getTimezonesAndOffsetsAsOptionList() {
    return momentTz.tz.names().map(function (name) { return ({
        label: name,
        value: name,
        offset: momentTz.tz(name).utcOffset(),
        offsetLabel: 'UTC ' + convertMinutesToHrsMin(momentTz.tz(name).utcOffset()),
    }); });
}
export function convertMinutesToHrsMin(minutes) {
    var isNegative = minutes < 0;
    var h = Math.floor(Math.abs(minutes) / 60);
    var m = Math.abs(minutes) % 60;
    var hour = h < 10 ? '0' + h : h;
    var min = m < 10 ? '0' + m : m;
    return isNegative ? "-" + hour + ":" + min : "+" + hour + ":" + min;
}
