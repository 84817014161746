import { Injectable } from '@angular/core';
import { NodeConverterBase } from './NodeConverterBase';
import { IUpdateObjectEditor } from '../node-editors/update-object-editor/update-object-editor.component';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import { IProcessNodeUpdateEno } from '../../../models/process';
import dataConstants from '../../../data/constants';
import { Formula } from '../../../data/models/types';
import { stringInterpolateFormula } from '../utils/converter-utils';

const NS = 'UpdateObjectNodeConverterService';

@Injectable({
  providedIn: 'root'
})
export class UpdateObjectNodeConverterService extends NodeConverterBase {
  type = 'processnode/data/update-eno';
  processNodeType = 'processnodetype/data/update-eno';

  public convert(nodeData: IUpdateObjectEditor): IProcessNodeUpdateEno {
    if (!this.isValid(nodeData)) {
      throw new InvalidWorkflowNodeDataError(`Please correct invalid data on update object node [${nodeData.fields.title}]`, nodeData);
    }

    const convertedFieldValues: Formula[] = nodeData.fields.fieldItems.map(fieldItem => stringInterpolateFormula({
      formula: fieldItem.fieldValue,
      isManualEntry: fieldItem.isManualEntry,
      isStringInterpolation: fieldItem.isStringInterpolation
    }));

    return {
      $type: this.type,
      $security: dataConstants.SECURITY.INSTANCE_USER_ADMIN,
      type: this.processNodeType,
      connections: [],
      point: [0, 0],
      key: nodeData.tip,
      updateEnoObject: nodeData.fields.formula,
      updateEnoFields: nodeData.fields.fieldItems.map(x => x.tip),
      updateEnoValues: convertedFieldValues,
      // @toDo Branches are hard-coded for the moment until we support branch for update object. Severity: medium
      updateEnoBranchSrc: `ARRAY("${dataConstants.BRANCH_MASTER}")`,
      updateEnoBranchDest: `ARRAY("${dataConstants.BRANCH_MASTER}")`,
      updateEnoLang: navigator.language.toLowerCase()
    };
  }

  private isValid(nodeData: IUpdateObjectEditor): boolean {
    return !(nodeData.fields.formula === '' || !nodeData.fields.fieldItems || !nodeData.fields.fieldItems.length);
  }

  constructor() {
    super();
  }
}
