import { Formula } from '../data/models/types';

export function addDatetimeFormatWrapper(formula: Formula): Formula {
  return addWrapper(formula, 'd M Y H:i');
}

export function addDateFormatWrapper(formula: Formula): Formula {
  return addWrapper(formula, 'd M Y');
}

export function addMonthFormatWrapper(formula: Formula): Formula {
  return addWrapperWithoutTimezone(formula, 'F');
}

export function addYearFormatWrapper(formula: Formula): Formula {
  return addWrapperWithoutTimezone(formula, 'Y');
}

function addWrapper(formula: Formula, format: string): Formula {
  // tslint:disable-next-line:max-line-length
  return `CONCAT(FORMAT_DATETIME(${ formula }, "${ format }", FIELD("app/type/locale:timezone", "app/locale")), " ", TIMEZONE_ABBREVIATION(FIELD("app/type/locale:timezone", "app/locale")))`;
}

function addWrapperWithoutTimezone(formula: Formula, format: string): Formula {
  return `FORMAT_DATETIME(${ formula }, "${ format }", FIELD("app/type/locale:timezone", "app/locale"))`;
}
