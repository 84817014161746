<app-auth-chrome>
  <ng-container *ngIf="isTokenValid; else invalidToken">
    <h6
        i18n="@@acceptVerification.authChrome.text"
        class="text-center">Your email address is verified.</h6>
    <button
        i18n="@@acceptVerification.authChrome.button"
        class="btn btn-block eim-button_noggin"
        routerLink="/signin">Log back in</button>
  </ng-container>
  <ng-template #invalidToken>
    <div class="text-center">
      <h6 i18n="@@acceptVerification.authChrome.invalidText-1">Sorry, we cannot verify your email address.</h6>
      <h6 i18n="@@acceptVerification.authChrome.invalidText-2">Please contact your system administrator.</h6>
    </div>
  </ng-template>
</app-auth-chrome>
