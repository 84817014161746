import { Component, ViewChild, ComponentFactoryResolver, HostBinding, AfterViewInit } from '@angular/core';
import { SideSheetContentDirective } from '../side-sheet-content.directive';
import { BehaviorSubject } from 'rxjs';
import { SheetSize } from '../side-sheet.service';
import { distinctUntilChanged, delay } from 'rxjs/operators';

@Component({
  selector: 'app-side-sheet-wrapper',
  templateUrl: './side-sheet-wrapper.component.html',
  styleUrls: ['./side-sheet-wrapper.component.scss'],
})
export class SideSheetWrapperComponent implements AfterViewInit {
  @ViewChild(SideSheetContentDirective, { static: true }) contentHost: SideSheetContentDirective;

  sizes = {
    xl: '80vw',
    lg: '60vw',
    sm: '40vw'
  };

  @HostBinding('style.width') width = this.sizes.sm;

  sidesheetComponent: any;
  resize$ = new BehaviorSubject<SheetSize>(null);

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver
  ) { }

  setProps(content, params) {
    const componentRef = this.createComponent(content, params);

    return componentRef;
  }

  resize(size: SheetSize) {
    // use observable to avoid ExpressionChangedAfterItHasBeenCheckedError
    this.resize$.next(size);
  }

  ngAfterViewInit() {
    this.resize$.pipe(distinctUntilChanged(), delay(0)).subscribe(size => {
      this.width = this.sizes[size];
    });
  }

  private createComponent(content, params) {
    if (params && params.injector && params.componentFactoryResolver) {
      const componentFactory = params.componentFactoryResolver.resolveComponentFactory(content);
      const viewContainerRef = this.contentHost.viewContainerRef;
      return viewContainerRef.createComponent(componentFactory, viewContainerRef.length, params.injector);
    } else {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(content);
      const viewContainerRef = this.contentHost.viewContainerRef;
      return viewContainerRef.createComponent(componentFactory);
    }
  }
}
