import { Injectable, OnDestroy } from '@angular/core';
import { FilterInputService, IFilterInput, ISortingOption } from '../../var/filter-input/filter-input.service';
import { IDimensionOption, IQueryExtraInfo, IQueryOption, QueryService } from '../../data/query.service';
import { Tip } from '../../data/models/types';
import { configItems, getModuleTitleFormula } from '../../settings/modules/module.constant';
import { map, switchMap } from 'rxjs/operators';
import { get, isEmpty } from 'lodash';
import { IQueryAndFilterOptions } from '../../query/query-chooser/query-chooser.component';
import { Observable, Subject } from 'rxjs';
import { getSearchInTypeQueryOptions } from '../../data/query-options';
import { IVars } from '../../data/vars.service';

export const DEFAULT_OBJECT_CHOOSER_QUERY_TIP = 'eim/query/get-object-titles';

export interface ILoadSideSheetObjectChooserObjectsParams {
  typeTips: Tip[];
  extraFilters: IQueryExtraInfo[];
  queryAndFilter: IQueryAndFilterOptions | {};
  showModuleInfo: boolean;
  limit?: number;
  offset?: number;
}

export interface ISideSheetObjectChooserObjectResults {
  $tip: string;
  title: string;
}

@Injectable()
export class SideSheetObjectChooserService implements OnDestroy {

  unsubscribe$ = new Subject();

  constructor(
    private filterInputService: FilterInputService,
    private queryService: QueryService,
  ) { }

  loadObjects({ queryAndFilter, showModuleInfo, typeTips, extraFilters, limit, offset }: ILoadSideSheetObjectChooserObjectsParams, searchValue?: string):
    Observable<ISideSheetObjectChooserObjectResults[]> {
    const query: Tip | null = get(queryAndFilter, 'queryTip', null) || DEFAULT_OBJECT_CHOOSER_QUERY_TIP;
    const filterInputs: IFilterInput[] | null = get(queryAndFilter, 'filterInputs', null);
    const sortingOptions: ISortingOption[] | null = get(queryAndFilter, 'sortingOptions', null);
    const dimensionOption: IDimensionOption = getDimensionOption(sortingOptions, limit, offset);

    const extraAttributes = [
      { label: 'Title', formula: 'TITLE()' },
      { label: 'Summary', formula: 'SUMMARY()' }
    ];

    extraFilters = extraFilters || [];

    if (showModuleInfo) {
      extraAttributes.push({ label: 'Module Name', formula: getModuleTitleFormula(typeTips[0] as configItems) });
    }

    if (typeTips.includes('app/object-type')) {
      extraFilters.push({
        label: 'Is not module options object-type',
        formula: 'EQUALS(REFERENCES("app/module:options-object-type", TIP()), ARRAY())'
      });
    }

    if (typeTips.includes('app/user')) {
      extraFilters.push({
        label: 'Is not user status deprovisioned',
        formula: 'NOT(EQUALS(FIELD("app/user:status"), "deprovisioned"))'
      });
    }

    if (isEmpty(filterInputs)) {
      const vars: IVars = { typeTips };
      const options: IQueryOption = this.setOptions(vars, extraAttributes, extraFilters, dimensionOption);
      return this.getData(options, query, typeTips, searchValue);
    }

    return this.filterInputService
      .convertFilterInputsToVars(filterInputs)
      .pipe(
        map((vars: IVars) => this.setOptions(vars, extraAttributes, extraFilters, dimensionOption)),
        switchMap((options: IQueryOption) => this.getData(options, query, typeTips, searchValue)),
      );

  }

  setOptions(vars: IVars, extraAttributes, extraFilters: IQueryExtraInfo[], dimensionOption: IDimensionOption): IQueryOption {
    const options = {
      watch: true,
      vars: vars,
      extraAttributes,
      extraFilters: extraFilters || [],
      dimensionOptions: [dimensionOption]
    };
    return options;
  }

  getData(options: IQueryOption, query: Tip, typeTips: string[], searchValue: string): Observable<ISideSheetObjectChooserObjectResults[]> {
    const searchInOptions: IQueryOption = searchValue ? getSearchInTypeQueryOptions(options, typeTips, searchValue) : options;
    return this.queryService
      .execute1dFirst<ISideSheetObjectChooserObjectResults>(
        query,
        searchInOptions
      );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

export function getDimensionOption(sortingOptions: ISortingOption[], limit, offset): IDimensionOption {
  if (isEmpty(sortingOptions)) {
    return { label: 'Tip dimension', formula: 'TIP()', sortby: ['TITLE()'], limit: limit, offset: offset };
  }

  const dimensionOption = {
    label: 'Tip dimension',
    formula: 'TIP()',
    sortby: [],
    sortdir: [],
    limit: limit,
    offset: offset
  };

  for (const sortingOption of sortingOptions) {
    dimensionOption.sortby.push(sortingOption.formula);
    dimensionOption.sortdir.push(sortingOption.sortDirection);
  }

  return dimensionOption;
}
