import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { LoadingState } from '../../shared/constants';
import { IPlan } from '../../models/plan';
import { Tip } from '../../data/models/types';
import { PlanService } from '../plan.service';
import { SecurityService } from '../../data/security.service';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-plan-strategy-side-sheet',
  templateUrl: './plan-strategy-side-sheet.component.html',
  styleUrls: ['./plan-strategy-side-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanStrategySideSheetComponent implements OnInit, OnDestroy {
  LoadingState = LoadingState;

  planTip: Tip;
  moduleTip: Tip;

  plan: IPlan;

  loadingState = LoadingState.inProgress;

  canUpdate$: Observable<boolean>;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private cdr: ChangeDetectorRef,
    private planService: PlanService,
    private securityService: SecurityService
  ) {}

  ngOnInit() {
    this.canUpdate$ = this.securityService.canUpdate(this.planTip).pipe(shareReplay(1));

    this.subscriptions.add(
      this.planService.getPlan(this.planTip).subscribe(
        (plan: IPlan) => {
          this.plan = plan;
          this.loadingState = LoadingState.loaded;

          this.cdr.markForCheck();
        }
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  setProps({ planTip }: { planTip: Tip }) {
    this.planTip = planTip;
  }

  openPlanEditSideSheet() {
    this.planService.openPlanEditSideSheet(this.plan, this.moduleTip);
  }
}
