import { FormulaLike, FormulaSpec } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { OPERATOR_TYPE, IFilterMeta } from '../../../models/query';
import { MetaDataKey } from '../../../object/field-formula-side-sheet/meta-data-formulas';
import { ALL_FIELD_DATA_TYPE, EXTENDED_FIELD_DATA_TYPE, FIELD_DATA_TYPE } from '../../../models/field';

export interface IInnerOperator {
  opNo: number;
  toFormula: (
    leftArg: FormulaLike,
    rightArg?: FormulaLike,
    filterMeta?: IFilterMeta
  ) => FormulaSpec;
}

export interface IOperator {
  [OPERATOR_TYPE.EQUALS]?: IInnerOperator;
  [OPERATOR_TYPE.NOT_EQUALS]?: IInnerOperator;
  [OPERATOR_TYPE.STARTS_WITH]?: IInnerOperator;
  [OPERATOR_TYPE.NOT_STARTS_WITH]?: IInnerOperator;
  [OPERATOR_TYPE.ENDS_WITH]?: IInnerOperator;
  [OPERATOR_TYPE.NOT_ENDS_WITH]?: IInnerOperator;
  [OPERATOR_TYPE.CONTAINS]?: IInnerOperator;
  [OPERATOR_TYPE.NOT_CONTAINS]?: IInnerOperator;
  [OPERATOR_TYPE.CONTAINS_ALL]?: IInnerOperator;
  [OPERATOR_TYPE.NOT_CONTAINS_ALL]?: IInnerOperator;
  [OPERATOR_TYPE.CONTAINS_ANY]?: IInnerOperator;
  [OPERATOR_TYPE.NOT_CONTAINS_ANY]?: IInnerOperator;
  [OPERATOR_TYPE.IS_EMPTY]?: IInnerOperator;
  [OPERATOR_TYPE.NOT_IS_EMPTY]?: IInnerOperator;
  [OPERATOR_TYPE.IN]?: IInnerOperator;
  [OPERATOR_TYPE.NOT_IN]?: IInnerOperator;
  [OPERATOR_TYPE.LESS_THAN]?: IInnerOperator;
  [OPERATOR_TYPE.GREATER_THAN]?: IInnerOperator;
  [OPERATOR_TYPE.LESS_THAN_EQUAL_TO]?: IInnerOperator;
  [OPERATOR_TYPE.GREATER_THAN_EQUAL_TO]?: IInnerOperator;
  [OPERATOR_TYPE.INTERSECTS_WITH]?: IInnerOperator;
  [OPERATOR_TYPE.NOT_INTERSECTS_WITH]?: IInnerOperator;
  [OPERATOR_TYPE.IS_OF_TYPE]?: IInnerOperator;
  [OPERATOR_TYPE.NOT_IS_OF_TYPE]?: IInnerOperator;
  [OPERATOR_TYPE.WITHIN]?: IInnerOperator;
  [OPERATOR_TYPE.NOT_WITHIN]?: IInnerOperator;
  [OPERATOR_TYPE.ASSIGNED_TO]?: IInnerOperator;
  [OPERATOR_TYPE.NOT_ASSIGNED_TO]?: IInnerOperator;
  [OPERATOR_TYPE.CONTACT_IN_GROUP]?: IInnerOperator;
  [OPERATOR_TYPE.CONTACT_NOT_IN_GROUP]?: IInnerOperator;
}

export interface IParts {
  operator: OPERATOR_TYPE;
  leftArg: FormulaLike;
  rightArg?: FormulaLike;
}

export interface IOperators {
  operators: IOperator;
  getParts: (FormulaSpec) => IParts;
}

export function reverseOperator(innerOperator: IInnerOperator): IInnerOperator {
  return {
    opNo: innerOperator.opNo,
    toFormula: (leftArg, rightArg?, meta?: IFilterMeta) => ({
      name: 'NOT',
      args: [innerOperator.opNo === 1 ? innerOperator.toFormula(leftArg, null, meta) : innerOperator.toFormula(leftArg, rightArg, meta)]
    })
  };
}
