import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { uniq } from 'lodash';

import { LOCAL_STORAGE } from '../dom-tokens';
import { LoggerService } from '../util/logger.service';
import { LocationService } from '../util/location.service';
import { IUserReadOnly } from '../models/user';
import { ILocale } from '../models/locale';
import dataConstants from './constants';
import { LocaleService } from './locale.service';

export const COOKIE_NAME_LOCALE_ID = 'noggin_apps_locale_id';

export const LOCAL_STORAGE_KEY_I18N_RELOADED = 'noggin_apps_i18n_reloaded';

@Injectable({
  providedIn: 'root'
})
export class I18nService {
  static localeId: string;

  private acceptableLocaleIdsSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([dataConstants.LANG_DEFAULT]);

  acceptableLocaleIds$: Observable<string[]> = this.acceptableLocaleIdsSubject.asObservable();

  constructor(
    @Inject(LOCALE_ID) private localeId: string,
    @Inject(LOCAL_STORAGE) private localStorage: Storage,
    private cookieService: CookieService,
    private loggerService: LoggerService,
    private locationService: LocationService,
    private localeService: LocaleService
  ) {
    const currentLocaleId: string = localeId.toLowerCase();

    dataConstants.LOCALE_ID = currentLocaleId;

    this.updateAcceptableLocaleIds(uniq([currentLocaleId, dataConstants.LANG_DEFAULT]));
  }

  checkLocale$(me: IUserReadOnly) {
    const currentLocaleId: string = dataConstants.LOCALE_ID;

    // Set the locale_id cookie to be the currently served locale ID.
    this.cookieService.set(COOKIE_NAME_LOCALE_ID, currentLocaleId, 60, '/');

    // Not signed in.
    // No need to reload.
    // The locale_id cookie or the Accept-Language HTTP header tells the server which locale to load.
    if (!me) {
      return;
    }

    this.updateAcceptableLocaleIds(uniq([currentLocaleId, me.localeId, dataConstants.LANG_DEFAULT]));

    const i18nReloaded: boolean = this.localStorage.getItem(LOCAL_STORAGE_KEY_I18N_RELOADED) === 'true';

    // There was no build the server was able to serve for the user's preferred locale ID.
    // i18nReloaded being true means the server has already tried to serve the desired locale ID and would have served "en-us" should the
    // desired locale ID request could not be met.
    if (i18nReloaded) {
      this.localStorage.removeItem(LOCAL_STORAGE_KEY_I18N_RELOADED);

      return;
    }

    this.localeService.localeSubject.pipe(
      first(),
      map(
        (locale: ILocale) => {
          this.updateAcceptableLocaleIds(
            uniq([dataConstants.LOCALE_ID, me.localeId, locale.localeId, dataConstants.LANG_DEFAULT])
          );

          const myLocaleId: string = me.localeId || locale.localeId || dataConstants.LANG_DEFAULT;

          if (currentLocaleId === myLocaleId) {
            return;
          }

          // A potential problem here is that if the server is not able to serve the user's preferred locale ID
          // an infinite reloading may happen.
          this.cookieService.set(COOKIE_NAME_LOCALE_ID, myLocaleId, 60, '/');

          this.refresh();
        }
      )
    ).subscribe();
  }

  private refresh() {
    this.loggerService.info('[i18n service] redirecting.');

    this.localStorage.setItem(LOCAL_STORAGE_KEY_I18N_RELOADED, 'true');

    this.locationService.refresh();
  }

  private updateAcceptableLocaleIds(localeIds: string[]) {
    dataConstants.ACCEPTABLE_LOCALE_IDS = uniq(localeIds);

    this.acceptableLocaleIdsSubject.next(localeIds);
  }
}
