import * as tslib_1 from "tslib";
import dataConstants from './constants';
import * as i0 from "@angular/core";
import * as i1 from "./op-pull.service";
var EnoWatchService = /** @class */ (function () {
    function EnoWatchService(_opPullService) {
        this._opPullService = _opPullService;
        this._enoTipsWatched = new Set();
    }
    EnoWatchService.prototype._getKey = function (tip, branch) {
        return tip + ":" + branch;
    };
    EnoWatchService.prototype.isWatched = function (tip, branch) {
        if (branch === void 0) { branch = dataConstants.BRANCH_MASTER; }
        return this._enoTipsWatched.has(this._getKey(tip, branch));
    };
    EnoWatchService.prototype.markAsWatched = function (tipOrTips, branch) {
        var _this = this;
        if (branch === void 0) { branch = dataConstants.BRANCH_MASTER; }
        var tips = typeof tipOrTips === 'string' ? [tipOrTips] : tipOrTips;
        tips.forEach(function (tip) {
            _this._enoTipsWatched.add(_this._getKey(tip, branch));
            if (branch !== dataConstants.BRANCH_MASTER) {
                _this._enoTipsWatched.add(_this._getKey(tip, dataConstants.BRANCH_MASTER));
            }
        });
    };
    EnoWatchService.prototype.getWatchOpPulls = function (enos) {
        var e_1, _a, e_2, _b;
        var branchGroup = {};
        var opPulls = [];
        try {
            for (var enos_1 = tslib_1.__values(enos), enos_1_1 = enos_1.next(); !enos_1_1.done; enos_1_1 = enos_1.next()) {
                var eno = enos_1_1.value;
                if (eno.source.security === dataConstants.SECURITY.OP) {
                    continue;
                }
                var branch = eno.getBranch();
                if (this._enoTipsWatched.has(this._getKey(eno.tip, branch))) {
                    continue;
                }
                if (eno.source.deleted) {
                    continue;
                }
                if (branchGroup[branch] === undefined) {
                    branchGroup[branch] = { tips: [], ifNotSids: [] };
                }
                branchGroup[branch].tips.push(eno.tip);
                branchGroup[branch].ifNotSids.push(eno.sid);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (enos_1_1 && !enos_1_1.done && (_a = enos_1.return)) _a.call(enos_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        try {
            for (var _c = tslib_1.__values(Object.keys(branchGroup)), _d = _c.next(); !_d.done; _d = _c.next()) {
                var branch = _d.value;
                opPulls.push(this._opPullService.createOpPull({
                    tip: branchGroup[branch].tips,
                    ifNotSid: branchGroup[branch].ifNotSids,
                    branch: branch === dataConstants.BRANCH_MASTER ? [branch] : [branch, dataConstants.BRANCH_MASTER]
                }));
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return opPulls;
    };
    EnoWatchService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EnoWatchService_Factory() { return new EnoWatchService(i0.ɵɵinject(i1.OpPullService)); }, token: EnoWatchService, providedIn: "root" });
    return EnoWatchService;
}());
export { EnoWatchService };
