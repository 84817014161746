<app-auth-chrome
    i18n-formType="@@resetPassword.authChrome.formType"
    formType="Reset"
    i18n-secondaryTitle="@@resetPassword.authChrome.secondaryTitle"
    secondaryTitle="Please enter your new password below"
    [showAuthErrorDesc]="messageState === 'weakPassword'">
  <ng-container
      eim-i18n-property="message"
      i18n="@@resetPassword.authChrome.message">{
    messageState,
      select,
      weakPassword {Your password is not strong enough.}
      failure {Something is wrong and we cannot reset your password right now. Please try again later.}
      accountLock {Sorry, your account has been locked out due to too many incorrect login attempts. Please try again in 5 minutes.}
      }</ng-container>
  <ng-container
      eim-i18n-property="authErrorDesc"
      i18n="@@resetPassword.authChrome.authErrorDesc">{
    messageState,
      select,
      weakPassword {Be a password superhero! Don’t use a password from another site or something too obvious, like your birthday or pet’s name. Use at least 5 characters. Mix it up with random uppercase letters, symbols, and even try breaking the spelling rules (we won’t tell).}
      }</ng-container>
  <form
      [formGroup]="resetPasswordForm"
      (ngSubmit)="resetPasswordForm.valid && onReset()"
      [appSpinner]="state==='in-progress'">
    <div class="form-group">
      <label
          i18n="@@resetPassword.authChrome.resetPasswordForm.newPasswordLabel"
          for="password">New password</label>
      <input class="form-control" id="password" type="password" formControlName="password" data-test="field:password">
    </div>
    <button
        i18n="@@resetPassword.authChrome.resetPasswordForm.button"
        class="btn btn-block eim-button_noggin"
        type="submit"
        data-test="reset-password">Reset password</button>
  </form>
</app-auth-chrome>
