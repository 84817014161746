import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NavigationSideSheetComponent } from '../../side-sheet/generic-side-sheets/navigation-side-sheet/navigation-side-sheet.component';
import { SelectUsersSideSheetComponent } from '../select-users-side-sheet/select-users-side-sheet.component';
import { SelectProfilesSideSheetComponent } from '../select-profiles-side-sheet/select-profiles-side-sheet.component';
import { SelectTeamsSideSheetComponent } from '../select-teams-side-sheet/select-teams-side-sheet.component';
import { SideSheetListComponent } from '../../side-sheet/side-sheet-list/side-sheet-list.component';
import { SideSheetListModes } from '../../side-sheet/side-sheet-list/side-sheet-list.component';
import { first } from 'rxjs/operators';
import { pullAll } from 'lodash';
var DELETED_LABEL = '---DELETED---';
var SecurityActionComponent = /** @class */ (function () {
    function SecurityActionComponent(sideSheetService, formulaService) {
        this.sideSheetService = sideSheetService;
        this.formulaService = formulaService;
        this.selectedUsers = [];
        this.selectedProfiles = [];
        this.selectedTeams = [];
        this.selectedRoles = [];
    }
    SecurityActionComponent.prototype.ngOnInit = function () {
        this.refreshLabels();
    };
    SecurityActionComponent.prototype.onAddGrant = function () {
        var _this = this;
        var sheetRef = this.sideSheetService.push(NavigationSideSheetComponent);
        sheetRef.componentInstance.title = 'Grant ' + this.actionLabel + ' permissions to';
        sheetRef.componentInstance.list = [
            { value: 'profiles', label: 'Profiles' },
            { value: 'teams', label: 'Teams' },
            // { value: 'roles', label: 'Roles' },
            { value: 'users', label: 'Users' }
        ];
        sheetRef.componentInstance.selectItem.subscribe(function (selection) {
            _this.sideSheetService.pop();
            switch (selection.value) {
                case 'users':
                    return _this.onSelectUsers();
                case 'profiles':
                    return _this.onSelectProfiles();
                case 'teams':
                    return _this.onSelectTeams();
                // case 'roles':
                //   return this.onSelectRoles();
            }
        });
    };
    SecurityActionComponent.prototype.refreshLabels = function () {
        var _this = this;
        var tips = tslib_1.__spread(this.selectedUsers.map(function (user) { return user.tip; }), this.selectedProfiles.filter(function (profile) { return profile.tip !== '__ALL__'; }).map(function (profile) { return profile.tip; }), this.selectedTeams.map(function (team) { return team.tip; }), this.selectedRoles.map(function (role) { return role.tip; }));
        if (tips.length === 0) {
            this.updateLabels(tips, {});
            return;
        }
        this.formulaService.evaluate("MAP(VAR(\"tips\"),COALESCE(TITLE(VAR(\"tip\")),\"" + DELETED_LABEL + "\"),\"tip\")", null, [], { tips: tips }).pipe(first()).subscribe(function (labels) { return _this.updateLabels(tips, labels); }, function () { return _this.updateLabels(tips, tips); } // Fallback if need be
        );
    };
    SecurityActionComponent.prototype.updateLabels = function (tips, labels) {
        var updateLabel = function (cohorts) {
            var deletedCohorts = [];
            cohorts.forEach(function (cohort) {
                var label = labels[tips.indexOf(cohort.tip)];
                if (label === DELETED_LABEL) {
                    deletedCohorts.push(cohort);
                    return;
                }
                cohort.label = cohort.tip === '__ALL__' ? 'All profiles' : labels[tips.indexOf(cohort.tip)];
            });
            pullAll(cohorts, deletedCohorts);
        };
        updateLabel(this.selectedUsers);
        updateLabel(this.selectedTeams);
        updateLabel(this.selectedRoles);
        updateLabel(this.selectedProfiles);
    };
    SecurityActionComponent.prototype.onSave = function () {
        this.onDone({
            users: this.selectedUsers,
            profiles: this.selectedProfiles,
            teams: this.selectedTeams,
            roles: this.selectedRoles
        });
        this.sideSheetService.pop();
    };
    SecurityActionComponent.prototype.onSelectUsers = function () {
        var _this = this;
        if (this.readonly) {
            return;
        }
        var sheetRef = this.sideSheetService.push(SelectUsersSideSheetComponent);
        sheetRef.componentInstance.title = 'Select users';
        sheetRef.componentInstance.mode = SideSheetListModes.MULTI_SELECT;
        sheetRef.componentInstance.initiallySelectedTips = this.selectedUsers.map(function (user) { return user.tip; });
        sheetRef.componentInstance.onDone = function (tips) {
            _this.selectedUsers = tips.map(function (tip) { return ({ tip: tip }); });
            _this.refreshLabels();
            _this.sideSheetService.pop();
        };
    };
    SecurityActionComponent.prototype.onSelectProfiles = function () {
        var _this = this;
        if (this.readonly) {
            return;
        }
        var sheetRef = this.sideSheetService.push(SelectProfilesSideSheetComponent);
        sheetRef.componentInstance.title = 'Select profiles';
        sheetRef.componentInstance.mode = SideSheetListModes.MULTI_SELECT;
        sheetRef.componentInstance.initiallySelectedTips = this.selectedProfiles.map(function (profile) { return profile.tip; });
        sheetRef.componentInstance.onDone = function (tips) {
            if (tips.indexOf('__ALL__') > -1) {
                _this.selectedProfiles = [{ tip: '__ALL__' }];
            }
            else {
                _this.selectedProfiles = tips.map(function (tip) { return ({ tip: tip }); });
            }
            _this.refreshLabels();
            _this.sideSheetService.pop();
        };
    };
    SecurityActionComponent.prototype.onSelectTeams = function () {
        var _this = this;
        if (this.readonly) {
            return;
        }
        var sheetRef = this.sideSheetService.push(SelectTeamsSideSheetComponent);
        sheetRef.componentInstance.title = 'Select teams';
        sheetRef.componentInstance.mode = SideSheetListModes.MULTI_SELECT;
        sheetRef.componentInstance.initiallySelectedTips = this.selectedTeams.map(function (team) { return team.tip; });
        sheetRef.componentInstance.onDone = function (tips) {
            _this.selectedTeams = tips.map(function (tip) { return ({ tip: tip }); });
            _this.refreshLabels();
            _this.sideSheetService.pop();
        };
    };
    // onSelectRoles() {
    // if (this.readonly) {
    //   return;
    // }
    //   const sheetRef = this.sideSheetService.push(SelectRolesSideSheetComponent);
    //   sheetRef.componentInstance.title = 'Select roles';
    //   sheetRef.componentInstance.mode = SideSheetListModes.MULTI_SELECT;
    //   sheetRef.componentInstance.initiallySelectedTips = this.selectedRoles.map(role => role.tip);
    //   sheetRef.componentInstance.onDone = (tips) => {
    //     this.selectedRoles = tips.map(tip => ({tip}));
    //     this.refreshLabels();
    //     this.sideSheetService.pop();
    //   };
    // }
    SecurityActionComponent.prototype.hasAnythingSet = function () {
        return this.selectedProfiles.length > 0
            || this.selectedUsers.length > 0
            || this.selectedTeams.length > 0;
    };
    return SecurityActionComponent;
}());
export { SecurityActionComponent };
