import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, first, tap } from 'rxjs/operators';
import { cloneDeep, size } from 'lodash';

import { LoadingState } from '../../../../shared/constants';
import { IList, IListItem } from '../../../../models/list';
import { Tip } from '../../../../data/models/types';
import { SideSheetService } from '../../../../side-sheet/side-sheet.service';
import { ToastService } from '../../../../shell/services/toast.service';
import { ListDesignerService } from '../../list-designer.service';
import { ListEditSideSheetComponent } from '../list-edit-side-sheet/list-edit-side-sheet.component';
import { ListItemSideSheetComponent } from '../list-item-side-sheet/list-item-side-sheet.component';
import { ListItemCreateSideSheetComponent } from '../list-item-create-side-sheet/list-item-create-side-sheet.component';
import { ListUtilsService } from '../../list-utils.service';
import { ModuleValidationError } from '../../../../data/errors/client/ModuleValidationError';
import { OperationalLimitService } from '../../../../data/operational-limit.service';

@Component({
  selector: 'app-list-side-sheet',
  templateUrl: './list-side-sheet.component.html',
  styleUrls: ['./list-side-sheet.component.scss']
})
export class ListSideSheetComponent implements OnInit, OnDestroy {
  list: IList;
  listTip: Tip;
  branchTip: Tip;
  moduleTip: Tip;
  isModuleUnlocked: boolean;

  unsubscribe$ = new Subject();

  loadingState: LoadingState;
  loadingStates = LoadingState;
  filterText = '';
  isDraggable = false;
  itemsLimited: number;

  listItemsBeforeReorder: IListItem[];

  constructor(
    private sideSheetService: SideSheetService,
    private listService: ListDesignerService,
    private toastService: ToastService,
    private operationalLimitService: OperationalLimitService
  ) {}

  ngOnInit() {
    this.getList();
  }

  getList() {
    this.loadingState = LoadingState.inProgress;
    this.listService
      .getList(this.listTip)
      .pipe(
        tap(() => this.listService.getListCalled.next()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        (list: IList) => {
          this.list = list;
          this.branchTip = list.$branch;
          this.loadingState = LoadingState.loaded;
        },
        () => {
          this.loadingState = LoadingState.failed;
        }
      );
      this.operationalLimitService.getValue$('numberOfItemsPerListDesigner').pipe(
        first()
      ).subscribe(operationalLimit => this.itemsLimited = operationalLimit);  
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  editList() {
    const { componentInstance } = this
      .sideSheetService
      .push(ListEditSideSheetComponent) as { componentInstance: ListEditSideSheetComponent };

    componentInstance.moduleTip = this.moduleTip;
    componentInstance
      .setProps({ list: this.list });
  }

  openListItem(value: string) {
    const sheetRef = this.sideSheetService.push(ListItemSideSheetComponent, { onPop: () => this.getList() });
    const componentInstance: ListItemSideSheetComponent = sheetRef.componentInstance;

    componentInstance.list = this.list;
    componentInstance.listItemIndex = ListUtilsService.getListItemIndex(this.list.items, value);
    componentInstance.moduleTip = this.moduleTip;
  }

  createListItem() {
    if (size(this.list.items) >= this.itemsLimited) {
      this.toastService.showErrorToast(`You can only add ${this.itemsLimited} items per list.`)
      return;
    }

    const sheetRef = this.sideSheetService.push(ListItemCreateSideSheetComponent);
    const componentInstance: ListItemCreateSideSheetComponent = sheetRef.componentInstance;

    componentInstance.list = this.list;
    componentInstance.moduleTip = this.moduleTip;
  }

  onDragStart() {
    this.isDraggable = true;
    this.listItemsBeforeReorder = cloneDeep(this.list.items);
  }

  onDragCancel() {
    this.isDraggable = false;
    this.list.items = cloneDeep(this.listItemsBeforeReorder);
  }

  onDragFinish() {
    this.loadingState = LoadingState.inProgress;
    this.listService.updateList(this.list, this.moduleTip).pipe(
      first(),
      tap(() => {
        this.isDraggable = false;
        this.loadingState = LoadingState.loaded;
      })
    ).subscribe(
      () => {},
      (error) => {
        error instanceof ModuleValidationError
        ? this.toastService.showErrorToast(error.message)
        : this.toastService.showErrorToast('Failed to update the list items order.')
      }
    );
  }

  goBack() {
    this.sideSheetService.pop();
  }
}
