import { Component, HostBinding, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { get } from 'lodash';

import { INumberFormatsQueryResult } from '../../../shared/number-format/number-format.service';
import { Tip } from '../../../data/models/types';
import { ContextTypeFieldOptions, IContextTypeFieldOption } from '../../context-type-field-option-utils/context-type-field-option-utils';

// @todo updateOnBlur may need to be passed here such that calculation for dependent fields would only happen when a text field is blurred.
// A problem with doing so is that we have to change the side sheet primary button disabled condition to use touched instead of pristine.
@Component({
  selector: 'app-input',
  template: `
    <app-text-field
        [testId]="to.testId"
        [formControl]="formControl"
        [label]="to.label"
        [placeholder]="to.placeholder"
        [required]="to.required"
        [readonly]="to.readonly"
        [type]="to.type || field.type"
        [min]="to.min"
        [max]="to.max"
        [numberFormat]="numberFormat"
        [showIcon]="to.showIcon"
        [calculated]="!!fieldOption?.calculation"
        (calculate)="calculate()"></app-text-field>
  `
})
export class FormlyFieldInputComponent extends FieldType implements OnInit {
  @HostBinding('class') classes = 'flex-fill';
  numberFormat: null | INumberFormatsQueryResult;

  fieldOption: IContextTypeFieldOption;

  ngOnInit() {
    const fieldTip: Tip = get(this, 'field.templateOptions.contextTypeTip', null);
    const fieldOptions: ContextTypeFieldOptions = get(this, 'formState.contextTypeOptions', null);

    this.fieldOption = fieldTip && fieldOptions ? fieldOptions.values.get(fieldTip) : null;
    this.numberFormat = get(this.fieldOption, 'numberFormat', null);
  }

  calculate() {
    this.formState.calculation$.next({
      fieldTip: this.field.key,
      calculationFormula: this.fieldOption.calculation,
      calculationNoValueAsZero: !!this.fieldOption.calculationNoValueAsZero
    });
  }
}
