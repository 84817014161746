<app-side-sheet-header
    i18n-action="@@sideSheetHeader.action.Done"
    [action]="isSingleSelect? '': (actionText || 'Done')"
    [hideBack]="hideBack"
    (actioned)="onDone()">
  <span i18n="@@sideSheetOptionList.sideSheetHeader.body">{label, select, Untitled {Untitled} other {{{label}}}}</span>
</app-side-sheet-header>
<app-side-sheet-section *ngIf="searchBox" [showBorder]="false">
  <app-filter-and-add [(filterText)]="filterText"></app-filter-and-add>
</app-side-sheet-section>
<app-side-sheet-list
    #optionList
    *ngIf="options?.length else empty"
    [(selected)]="selected"
    (selectedChange)="onSelectedChange()"
    [emitChangeEventOnLoad]="false"
    [mode]="listType"
    [showMoreButton]="false"
    [requiredSingleSelect]="required">
  <app-side-sheet-list-item
      *ngFor="let option of options | filter:filterText:['label']; let i = index" [key]="option.value"
      [disabled]="option?.disabled">
    <span>
        <i
            *ngIf="option.icon"
            class="pr-1 icon icon-left mdi {{ option.icon }}"></i>
      <!-- bind html so you can use <br> <strong> etc in your labels -->
        <span [innerHTML]="option.label"></span>
    </span>

  </app-side-sheet-list-item>
</app-side-sheet-list>
<ng-template #empty>
  <app-empty-state
      imgSrc="/assets/img/empty-states/no-groups.svg"
      i18n-label="@@sideSheetOptionList.emptyState.label"
      label="No options here"></app-empty-state>
</ng-template>
