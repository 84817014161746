import { DataTypes, IObjectScheme } from '../data/models/scheme';
import { IObject } from '../data/models/object';
import { IDiagram } from '../settings/workflow-designer/workflow-designer-interfaces';
import { IProcess, processScheme } from './process';
import { ALL_FIELD_DATA_TYPE, FIELD_DATA_TYPE } from './field';
import { Tip } from '../data/models/types';

export enum WORKFLOW_VAR_TYPE {
  INPUT = 'Input',
  VARIABLE = 'Variable',
  ACTOR = 'Actor'
}

// @toDo: Need to confirm the exact list of actors
export enum ACTORS {
  PERSON = 'app/person',
  ROLE = 'app/team-role',
  PROFILE = 'app/profile'
}

export interface IWorkflowProp {
  key: string;
  label: string;
  isSelected?: boolean;
  dataType?: ALL_FIELD_DATA_TYPE;
  typeRestricts?: Tip[];
  actorType?: 'user' | 'profile' | 'role';
  actorTip?: Tip;
}

export interface IWorkflowVariables {
  inputs: IWorkflowProp[];
  actors: IWorkflowProp[];
  variables: IWorkflowProp[];
}

export interface IWorkflowEditorProps extends IWorkflowVariables {
  $tip?: Tip;
  name: string;
  description: string;
  isAdminMode: boolean;
  process?: IProcess;
}


export interface IWorkflow extends IObject, IWorkflowEditorProps {
  diagramData: IDiagram;
  nodes: string; // this is json
}

export const workflowScheme: IObjectScheme = {
  'app/workflow:name': { name: 'name', type: DataTypes.i18n },
  'app/workflow:description': { name: 'description', type: DataTypes.i18n },
  'app/workflow:diagramdata': { name: 'diagramData', type: DataTypes.json },
  'app/workflow:isAdminMode': { name: 'isAdminMode', type: DataTypes.boolean },
  'app/workflow:nodes': { name: 'nodes', type: DataTypes.jsonArray },
  'app/workflow:process': { type: DataTypes.object, mutable: true, scheme: processScheme },
  'app/workflow:inputs': { name: 'inputs', type: DataTypes.jsonArray },
  'app/workflow:actors': { name: 'actors', type: DataTypes.jsonArray },
  'app/workflow:variables': { name: 'variables', type: DataTypes.jsonArray },
};

export interface IWorkflowRead extends IObject {
  name: string;
  description: string;
  isAdminMode: boolean;
  process?: Tip;
  diagramData: IDiagram;
  nodes: string;
  inputs?: Array<IWorkflowProp>;
  variables?: Array<IWorkflowProp>;
}

export const workflowReadScheme: IObjectScheme = {
  'app/workflow:name': { name: 'name', type: DataTypes.i18n },
  'app/workflow:description': { name: 'description', type: DataTypes.i18n },
  'app/workflow:diagramdata': { name: 'diagramData', type: DataTypes.json },
  'app/workflow:isAdminMode': { name: 'isAdminMode', type: DataTypes.boolean },
  'app/workflow:nodes': { name: 'nodes', type: DataTypes.jsonArray },
  'app/workflow:process': { type: DataTypes.string },
  'app/workflow:inputs': { name: 'inputs', type: DataTypes.jsonArray },
  'app/workflow:actors': { name: 'actors', type: DataTypes.jsonArray },
  'app/workflow:variables': { name: 'variables', type: DataTypes.jsonArray },
};

export interface IFlatWorkflow extends IObject {
  name: string;
  description: string;
  isAdminMode: boolean;
}

export const flatWorkflowScheme: IObjectScheme = {
  'app/workflow:name': { type: DataTypes.i18n },
  'app/workflow:description': { type: DataTypes.i18n },
  'app/workflow:isAdminMode': { type: DataTypes.boolean }
};
