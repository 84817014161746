import * as tslib_1 from "tslib";
import { FieldWrapper } from '@ngx-formly/core';
import { OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
var FormlyWrapperNogginSectionComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FormlyWrapperNogginSectionComponent, _super);
    function FormlyWrapperNogginSectionComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.collapsed = false;
        return _this;
    }
    FormlyWrapperNogginSectionComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.to.toggle) {
            this.subscription = this.to.toggle.subscribe(function (v) {
                _this.setCollapsed(v);
            });
        }
    };
    FormlyWrapperNogginSectionComponent.prototype.setCollapsed = function (collapsed) {
        this.collapsed = collapsed;
    };
    FormlyWrapperNogginSectionComponent.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    return FormlyWrapperNogginSectionComponent;
}(FieldWrapper));
export { FormlyWrapperNogginSectionComponent };
