import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { get } from 'lodash';
import dataConstants from './constants';
import { EnoFactory } from './EnoFactory';
import { ESessionMessageDataType } from './pub-sub.service';
import * as i0 from "@angular/core";
import * as i1 from "./ensrv.service";
import * as i2 from "./pub-sub.service";
import * as i3 from "./op-pull.service";
import * as i4 from "../util/logger.service";
var ProcessService = /** @class */ (function () {
    function ProcessService(_ensrvService, _pubSubService, _opPullService, loggerService) {
        var _this = this;
        this._ensrvService = _ensrvService;
        this._pubSubService = _pubSubService;
        this._opPullService = _opPullService;
        this.loggerService = loggerService;
        this._processOpFactory = new EnoFactory('op/process', dataConstants.SECURITY.OP);
        this._processSubjects = {};
        // Stores the Sids of the the process responses that have been handled.
        // Right now, for any asynchronous processes, the frond-end would receive the initial process response from both the XHR response
        // and a PubSub message.
        // We want to avoid notifying the observers of #start twice for the same process response.
        this._processResponseSids = [];
        this._pubSubService.receiveSessionMessage(ESessionMessageDataType.processResponse).pipe(filter(function (data) { return _this._processSubjects.hasOwnProperty(data.op)
            && data.tips && data.tips.length > 0; })).subscribe(function (data) { return _this._handleSessionMessageData(data); });
        this._ensrvService.getEnoReceiver('response/process').subscribe(function (processResponseEno) { return _this._handleResponse(processResponseEno); });
    }
    ProcessService.prototype.start = function (processTip, processVars) {
        var _this = this;
        if (processVars === void 0) { processVars = {}; }
        var processSubject = new Subject();
        var processOpEno = this._processOpFactory
            .setFields([
            { tip: 'op/process/process', value: [processTip] },
            { tip: 'op/process/inline-vars', value: [JSON.stringify(processVars)] }
        ])
            .makeEno();
        this._processSubjects[processOpEno.tip] = processSubject;
        this.loggerService.debug("[ProcessService] Started process \"" + processTip + "\""
            + ("(process operation \"" + processOpEno.tip + "\" with vars \"" + JSON.stringify(processVars) + "\")."));
        this._ensrvService.send([processOpEno]).subscribe(function (enos) {
            var errors = enos.filter(function (eno) { return get(eno, 'source.type', null) === 'error'; });
            if (errors.length > 0) {
                _this.logProcessFailure({ processTip: processTip, processOpEno: processOpEno, processVars: processVars, error: errors });
                processSubject.error(errors);
            }
        }, function (error) {
            _this.logProcessFailure({ processTip: processTip, processOpEno: processOpEno, processVars: processVars, error: error });
            processSubject.error(error);
        });
        return processSubject.asObservable();
    };
    ProcessService.prototype.logProcessFailure = function (_a) {
        var processTip = _a.processTip, processOpEno = _a.processOpEno, processVars = _a.processVars, error = _a.error;
        this.loggerService.error("[ProcessService] Failed to execute process \"" + processTip + "\""
            + ("(process operation \"" + processOpEno.tip + "\" with vars \"" + JSON.stringify(processVars) + "\")."), error);
    };
    ProcessService.prototype._handleResponse = function (processResponseEno) {
        if (this._processResponseSids.includes(processResponseEno.sid)) {
            return;
        }
        this._processResponseSids.push(processResponseEno.sid);
        var processOpTip = processResponseEno.getFieldStringValue('response/process/op-tip');
        var processSubject = this._processSubjects[processOpTip];
        if (!processSubject) {
            return;
        }
        var vars = processResponseEno.getFieldJsonValue('response/process/inline-vars') || {};
        var finished = processResponseEno.getFieldBooleanValue('response/process/finished');
        processSubject.next({ finished: finished, vars: vars });
        if (finished) {
            processSubject.complete();
        }
    };
    ProcessService.prototype._handleSessionMessageData = function (data) {
        var processResponseTip = data.tips[0];
        var opPullEno = this._opPullService.createOpPull({
            tip: [processResponseTip],
            watch: false
        });
        this._ensrvService.send([opPullEno]).subscribe();
    };
    ProcessService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProcessService_Factory() { return new ProcessService(i0.ɵɵinject(i1.EnsrvService), i0.ɵɵinject(i2.PubSubService), i0.ɵɵinject(i3.OpPullService), i0.ɵɵinject(i4.LoggerService)); }, token: ProcessService, providedIn: "root" });
    return ProcessService;
}());
export { ProcessService };
