import * as tslib_1 from "tslib";
import { ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
var FormlyWrapperSideSheetSectionComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FormlyWrapperSideSheetSectionComponent, _super);
    function FormlyWrapperSideSheetSectionComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return FormlyWrapperSideSheetSectionComponent;
}(FieldWrapper));
export { FormlyWrapperSideSheetSectionComponent };
