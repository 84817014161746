var _a, _b;
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNumber, isString, uniq } from 'lodash';
import { FIELD_DATA_TYPE, TYPE_GEO_RESTRICT, EXTENDED_FIELD_DATA_TYPE } from '../../models/field';
import { listScheme } from '../../models/list';
import { Parser } from '../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { getRelativeDateFormula } from '../../side-sheet/generic-side-sheets/relative-dates-side-sheet/relative-dates-side-sheet.component';
import { NOW_VAR_NAME } from '../../util/current-datetime.service';
import { MetaDataKey } from '../../object/field-formula-side-sheet/meta-data-formulas';
import { booleanOperators } from './operators/boolean-operators';
import { objectOperators } from './operators/object-operators';
import { stringOperators } from './operators/string-operators';
import { numberOperators } from './operators/number-operators';
import { dateOperators } from './operators/date-operators';
import { datetimeOperators } from './operators/datetime-operators';
import { isFormulaSpec } from '../../util/is-formula-spec';
import { geographyOperators } from './operators/geography-operators';
import { assigneeOperators } from './operators/assignee-operators';
import { personnelOperators } from './operators/personnel-operators';
import { hierarchicalOperators } from './operators/hierarchical-operators';
import { isCurrentUserFormula } from '../../util/current-user-formula';
import { listOperators, SPECIAL_VAR_NAME } from './operators/list-operators';
import { get } from 'lodash';
import { getFieldTipsFromFormulaString } from '../../util/get-field-tips-from-formula-string';
import * as i0 from "@angular/core";
import * as i1 from "../../data/object-service/object.service";
export var intersectsTypeGeoRestrictMap = (_a = {},
    _a[TYPE_GEO_RESTRICT.POINT] = [TYPE_GEO_RESTRICT.LINE, TYPE_GEO_RESTRICT.POLYGON],
    _a[TYPE_GEO_RESTRICT.LINE] = [TYPE_GEO_RESTRICT.POINT, TYPE_GEO_RESTRICT.LINE, TYPE_GEO_RESTRICT.POLYGON],
    _a[TYPE_GEO_RESTRICT.POLYGON] = [TYPE_GEO_RESTRICT.POINT, TYPE_GEO_RESTRICT.LINE, TYPE_GEO_RESTRICT.POLYGON],
    _a);
var QueryFilterEditorService = /** @class */ (function () {
    function QueryFilterEditorService(objectService) {
        this.objectService = objectService;
    }
    QueryFilterEditorService.isLineFormula = function (formula) {
        return isFormulaSpec(formula) && !QueryFilterEditorService.isGroupFormula(formula);
    };
    QueryFilterEditorService.isGroupFormula = function (formula) {
        return isFormulaSpec(formula) && (formula.name === 'AND' || formula.name === 'OR');
    };
    QueryFilterEditorService.toFormula = function (dataTypeOrMetaDataKey, op, leftArg, rightArg, filterMeta) {
        return QueryFilterEditorService.DATATYPE_OPERATOR[dataTypeOrMetaDataKey].operators[op].toFormula(leftArg, rightArg, filterMeta);
    };
    QueryFilterEditorService.getRightArgBy = function (formulaString) {
        if (!formulaString) {
            return null;
        }
        var formula = Parser(formulaString);
        var argTips = getFieldTipsFromFormulaString(formulaString);
        if (isString(formula) || isNumber(formula)) {
            return 'Entering';
        }
        if (!isFormulaSpec(formula)) {
            // TODO: replace below to loggerService
            // console.warn(`[QueryFilterEditorService] unsupported value: ${formula}.`);
            return null;
        }
        if (isCurrentUserFormula(formulaString) || get(argTips, '0', '').includes('app/user-session:user')) {
            return 'Relative';
        }
        if (formula.name === 'TIP' && formula.args.length === 0) {
            return 'Selecting';
        }
        if (formula.name === 'CONTEXT' || (formula.name === 'FIELD' && formula.args.length === 1 && isString(formula.args[0]))) {
            return 'Selecting';
        }
        if (formula.name === 'VAR' && formula.args.length === 1 && isString(formula.args[0])) {
            return 'Variable';
        }
        if (getRelativeDateFormula(formulaString)) {
            return 'Relative';
        }
        // TODO: replace below to loggerService
        // console.warn(`[QueryFilterEditorService] unsupported custom formula: ${formula}.`);
        return null;
    };
    QueryFilterEditorService.getVarNames = function (formula, varNames) {
        if (varNames === void 0) { varNames = []; }
        if (isFormulaSpec(formula)) {
            if (formula.name === 'VAR' && formula.args.length === 1 && isString(formula.args[0]) &&
                formula.args[0] !== NOW_VAR_NAME && formula.args[0] !== SPECIAL_VAR_NAME) {
                varNames.push(formula.args[0]);
                return uniq(varNames);
            }
            return formula.args.reduce(function (_varNames, _formula) {
                return QueryFilterEditorService.getVarNames(_formula, _varNames);
            }, varNames);
        }
        return uniq(varNames);
    };
    QueryFilterEditorService.prototype.getFieldItems = function (listTip) {
        if (listTip) {
            return this.objectService.getObject(listTip, listScheme)
                .pipe(map(function (listObject) { return listObject.items; }));
        }
        return of([]);
    };
    QueryFilterEditorService.DATATYPE_OPERATOR = (_b = {},
        _b[MetaDataKey.thisObject] = objectOperators,
        _b[MetaDataKey.title] = stringOperators,
        _b[MetaDataKey.createdDate] = datetimeOperators,
        _b[MetaDataKey.lastModifiedDate] = datetimeOperators,
        _b[MetaDataKey.objectStage] = stringOperators,
        _b[MetaDataKey.personnel] = personnelOperators,
        _b[MetaDataKey.urlLink] = stringOperators,
        _b[MetaDataKey.type] = stringOperators,
        _b[MetaDataKey.createdBy] = objectOperators,
        _b[MetaDataKey.lastModifiedBy] = objectOperators,
        _b[FIELD_DATA_TYPE.string] = stringOperators,
        _b[FIELD_DATA_TYPE.string_i18n] = stringOperators,
        _b[FIELD_DATA_TYPE.blob] = stringOperators,
        _b[FIELD_DATA_TYPE.number] = numberOperators,
        _b[FIELD_DATA_TYPE.decimal] = numberOperators,
        _b[FIELD_DATA_TYPE.bool] = booleanOperators,
        _b[FIELD_DATA_TYPE.date] = dateOperators,
        _b[FIELD_DATA_TYPE.datetime] = datetimeOperators,
        _b[FIELD_DATA_TYPE.object] = objectOperators,
        _b[FIELD_DATA_TYPE.email] = stringOperators,
        _b[FIELD_DATA_TYPE.phone] = stringOperators,
        _b[FIELD_DATA_TYPE.uri] = stringOperators,
        _b[FIELD_DATA_TYPE.geography] = geographyOperators,
        _b[FIELD_DATA_TYPE.formula] = stringOperators,
        _b[FIELD_DATA_TYPE.string_formula] = stringOperators,
        _b[FIELD_DATA_TYPE.string_i18n_formula] = stringOperators,
        _b[FIELD_DATA_TYPE.json] = stringOperators,
        _b[FIELD_DATA_TYPE.html] = stringOperators,
        _b[EXTENDED_FIELD_DATA_TYPE.sub_object] = objectOperators,
        _b[EXTENDED_FIELD_DATA_TYPE.file] = objectOperators,
        _b[EXTENDED_FIELD_DATA_TYPE.list] = listOperators,
        _b[EXTENDED_FIELD_DATA_TYPE.address] = objectOperators,
        _b[EXTENDED_FIELD_DATA_TYPE.point] = geographyOperators,
        _b[EXTENDED_FIELD_DATA_TYPE.line] = geographyOperators,
        _b[EXTENDED_FIELD_DATA_TYPE.polygon] = geographyOperators,
        _b[EXTENDED_FIELD_DATA_TYPE.assignable] = assigneeOperators,
        _b[EXTENDED_FIELD_DATA_TYPE.symbology] = objectOperators,
        _b[EXTENDED_FIELD_DATA_TYPE.hierarchical] = hierarchicalOperators,
        _b);
    QueryFilterEditorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function QueryFilterEditorService_Factory() { return new QueryFilterEditorService(i0.ɵɵinject(i1.ObjectService)); }, token: QueryFilterEditorService, providedIn: "root" });
    return QueryFilterEditorService;
}());
export { QueryFilterEditorService };
