import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientBrandTitleComponent } from './client-brand-title.component';

@NgModule({
  declarations: [ClientBrandTitleComponent],
  exports: [
    ClientBrandTitleComponent
  ],
  imports: [
    CommonModule
  ]
})
export class ClientBrandTitleModule { }
