import { IInnerOperator, IOperators, reverseOperator } from './operators';
import { OPERATOR_TYPE } from '../../../models/query';

export const stringEqual: IInnerOperator = {
  opNo: 2,
  toFormula: (leftArg, rightArg) => ({
    name: 'EQUALS',
    args: [leftArg, rightArg]
  })
};
export const stringNotEqual = reverseOperator(stringEqual);

const stringStartsWith: IInnerOperator = {
  opNo: 2,
  toFormula: (leftArg, rightArg) => ({
    name: 'OP',
    args: [{ name: 'FIND_TEXT', args: [leftArg, rightArg] }, '==', 0]
  })
};
const stringNotStartsWith = reverseOperator(stringStartsWith);

const stringEndsWith: IInnerOperator = {
  opNo: 2,
  toFormula: (leftArg, rightArg) => ({
    name: 'OP',
    args: [
      { name: 'FIND_TEXT', args: [leftArg, rightArg] },
      '==',
      {
        name: 'SUB', args: [
          { name: 'STRLEN', args: [leftArg] },
          { name: 'STRLEN', args: [rightArg] }
        ]
      }
    ]
  })
};
const stringNotEndsWith = reverseOperator(stringEndsWith);

export const stringContains: IInnerOperator = {
  opNo: 2,
  toFormula: (leftArg, rightArg) => ({
    name: 'OP',
    args: [{ name: 'FIND_TEXT', args: [leftArg, rightArg] }, '!=', -1]
  })
};
export const stringNotContains = reverseOperator(stringContains);

export const stringIsEmpty: IInnerOperator = {
  opNo: 1,
  toFormula: (leftArg) => ({
    name: 'INARRAY',
    args: [
      {
        name: 'ARRAY',
        args: [
          '',
          {
            name: 'ARRAY',
            args: []
          }]
      },
      leftArg
    ]
  })
};
export const stringIsNotEmpty = reverseOperator(stringIsEmpty);

const stringIn: IInnerOperator = {
  opNo: 2,
  toFormula: (leftArg, rightArg) => ({
    name: 'OP',
    args: [-1, '!=', { name: 'FIND_TEXT', args: [rightArg, leftArg] }] // to distinguish with contains
  })
};
const stringNotIn = reverseOperator(stringIn);

export const stringOperators: IOperators = {
  operators: {
    [OPERATOR_TYPE.EQUALS]: stringEqual,
    [OPERATOR_TYPE.NOT_EQUALS]: stringNotEqual,
    [OPERATOR_TYPE.STARTS_WITH]: stringStartsWith,
    [OPERATOR_TYPE.NOT_STARTS_WITH]: stringNotStartsWith,
    [OPERATOR_TYPE.ENDS_WITH]: stringEndsWith,
    [OPERATOR_TYPE.NOT_ENDS_WITH]: stringNotEndsWith,
    [OPERATOR_TYPE.CONTAINS]: stringContains,
    [OPERATOR_TYPE.NOT_CONTAINS]: stringNotContains,
    [OPERATOR_TYPE.IS_EMPTY]: stringIsEmpty,
    [OPERATOR_TYPE.NOT_IS_EMPTY]: stringIsNotEmpty,
    [OPERATOR_TYPE.IN]: stringIn,
    [OPERATOR_TYPE.NOT_IN]: stringNotIn
  },
  getParts: formula => {
    let currentPart = formula;
    let negative = false;

    if (currentPart.name === 'NOT') {
      currentPart = currentPart.args[0];
      negative = true;
    }

    if (currentPart.name === 'INARRAY') {
      return {
        operator: negative ? OPERATOR_TYPE.NOT_IS_EMPTY : OPERATOR_TYPE.IS_EMPTY,
        leftArg: currentPart.args[1]
      };
    }

    if (currentPart.name === 'OP') {
      if (currentPart.args[2] === 0) {
        return {
          operator: negative ? OPERATOR_TYPE.NOT_STARTS_WITH : OPERATOR_TYPE.STARTS_WITH,
          leftArg: currentPart.args[0].args[0],
          rightArg: currentPart.args[0].args[1]
        };
      }

      if (currentPart.args[2] === -1) {
        return {
          operator: negative ? OPERATOR_TYPE.NOT_CONTAINS : OPERATOR_TYPE.CONTAINS,
          leftArg: currentPart.args[0].args[0],
          rightArg: currentPart.args[0].args[1]
        };
      }

      if (currentPart.args[0] === -1) {
        return {
          operator: negative ? OPERATOR_TYPE.NOT_IN : OPERATOR_TYPE.IN,
          leftArg: currentPart.args[2].args[1],
          rightArg: currentPart.args[2].args[0]

        };
      }

      if (currentPart.args[0].name === 'FIND_TEXT') {
        return {
          operator: negative ? OPERATOR_TYPE.NOT_ENDS_WITH : OPERATOR_TYPE.ENDS_WITH,
          leftArg: currentPart.args[0].args[0],
          rightArg: currentPart.args[0].args[1]
        };
      }

      throw new Error('Unknown operators:' + currentPart.name);
    }

    if (currentPart.name === 'EQUALS') {
      return {
        operator: negative ? OPERATOR_TYPE.NOT_EQUALS : OPERATOR_TYPE.EQUALS,
        leftArg: currentPart.args[0],
        rightArg: currentPart.args[1]
      };
    }

    throw new Error('Unknown operators: ' + currentPart.name);
  }
};
