import { OnDestroy } from '@angular/core';
import { combineLatest, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../data/query.service";
var FeatureFlagService = /** @class */ (function () {
    function FeatureFlagService(queryService) {
        this.queryService = queryService;
        this.allFeatureFlags = new ReplaySubject(1);
        this.allFeatureFlags$ = this.allFeatureFlags.asObservable();
    }
    // Fetch a feature flag by name, returning a default if not available
    FeatureFlagService.prototype.getFeatureFlag$ = function (name, defaultEnabled) {
        if (defaultEnabled === void 0) { defaultEnabled = false; }
        return this.allFeatureFlags$
            .pipe(map(function (allFeatureFlagsMap) {
            if (allFeatureFlagsMap.has(name)) {
                return allFeatureFlagsMap.get(name);
            }
            return defaultEnabled;
        }));
    };
    FeatureFlagService.prototype.getFeatureFlags$ = function (getFeatureFlags) {
        var _this = this;
        var featureFlags$ = getFeatureFlags
            .map(function (_a) {
            var name = _a.name, defaultEnabled = _a.defaultEnabled;
            return _this.getFeatureFlag$(name, defaultEnabled);
        });
        return combineLatest(featureFlags$);
    };
    FeatureFlagService.prototype.loadAllFeatureFlags = function () {
        var _this = this;
        this.subscription = this.queryService.execute1dFirst('eim/query/get-all-feature-flags-query').pipe(map(function (featureFlags) { return featureFlags.reduce(function (allFeatureFlagsMap, featureFlag) {
            return allFeatureFlagsMap.set(featureFlag.name, featureFlag.enabled === 'true');
        }, new Map()); })).subscribe(function (allFeatureFlagsMap) {
            _this.allFeatureFlags.next(allFeatureFlagsMap);
        });
    };
    FeatureFlagService.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    FeatureFlagService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FeatureFlagService_Factory() { return new FeatureFlagService(i0.ɵɵinject(i1.QueryService)); }, token: FeatureFlagService, providedIn: "root" });
    return FeatureFlagService;
}());
export { FeatureFlagService };
