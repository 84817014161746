<div *ngIf="showBorder" class="side-sheet-section-divider"></div>
<div *ngIf="showHeader" class="card-header d-flex flex-row align-items-center">
    <span *ngIf="collapsible" class="collapse-control icon mdi d-flex flex-column align-items-center" [ngClass]="collapsed ? 'mdi-chevron-right' : 'mdi-chevron-down'" (click)="invertCollapsedState()">
  </span>
    <div class="title d-flex flex-row align-items-center flex-grow-1" [style.cursor]="collapsible ? 'pointer' : 'auto'" (click)="collapsible && invertCollapsedState()">
        <strong *ngIf="title; else titleText" data-test="section-title">{{ title }}</strong>
        <ng-template #titleText>
            <strong data-test="section-title">
        <ng-content select="[eim-i18n-property=title]"></ng-content>
      </strong>
        </ng-template>
        <div *ngIf="tallyPill" class="tally-pill ml-2" [class.empty-tally-pill]="tallyPillValue < 1">
            <span i18n="@@sideSheetSection.tallyPill">{{ tallyPillValue }} selected</span>
        </div>
    </div>
    <ng-content select="[extra-text]"></ng-content>
    <ng-content select="[buttons]" *ngIf="!buttonCollapseWithTitle || !collapsed"></ng-content>
</div>
<div class="collapsible-spacer px-3" [style.display]="collapsed ? 'none' : 'block'">
    <ng-content></ng-content>
</div>