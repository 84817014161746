import { Tip } from '../data/models/types';
import { IObjectScheme, DataTypes } from '../data/models/scheme';
import { IObject } from '../data/models/object';
import { IList, listScheme } from './list';
import { IQuery, queryScheme } from './query';
import { dashboardScheme, IDashboard } from './dashboard';
import { IWorkflow } from './workflow';
import { IObjectType, objectTypeScheme } from './object-type';
import { formScheme, IForm } from '../settings/form-designer/models/form';
import { IWorkflowPanel, workflowPanelScheme } from './workflow-panel';
import { planScheme, IPlan } from './plan';
import { chartScheme, IChart } from './chart';
import { IMapDataSource, IMapLayer, mapDataSourceScheme, mapLayerScheme, IMap, mapScheme } from './map';
import { emailOutboundTemplateScheme, IEmailOutboundTemplate } from './email';
import { ISMSOutboundTemplate, smsOutboundTemplateScheme } from './sms';
import { ISequence, sequenceScheme } from './sequence';
import { riskMatrixScheme, IRiskMatrix } from './risk-matrix';
import { voiceMessageOutboundTemplateScheme } from './voice-message';
import { IPrintTemplate, printTemplateScheme, IPrintTemplateUsage, printTemplateUsageScheme } from './print-template';

export { IModule, moduleScheme, IFlatModule, flatModuleScheme, IModuleConfigCount };

interface IModule extends IObject {
  name: string;
  description: string;
  branch: Tip;
  solution?: Tip[];
  labels?: Tip[];
  instancePolicy?: Tip;
  typePolicy?: Tip;
  workflowProcessPolicy?: Tip;
  moduleSettingInstancePolicy?: Tip;
  blacklist?: Tip[];
  optionsObjectType?: IObjectType;
  objectTypes?: IObjectType[];
  lists?: IList[];
  queries?: IQuery[];
  dashboards?: IDashboard[];
  workflows?: IWorkflow[];
  workflowPanels?: IWorkflowPanel[];
  forms?: IForm[];
  planTemplates?: IPlan[];
  messageEmailTemplates?: IEmailOutboundTemplate[];
  messageSmsTemplates?: ISMSOutboundTemplate[];
  charts?: IChart[];
  mapDataSources?: IMapDataSource[];
  mapLayers?: IMapLayer[];
  maps?: IMap[];
  sequences?: ISequence[];
  riskMatrices?: IRiskMatrix[];
  messageVoiceTemplates?: [];
  printTemplates?: IPrintTemplate[];
  printTemplateUsages?: IPrintTemplateUsage[];
}

const moduleScheme: IObjectScheme = {
  'app/module:name': { type: DataTypes.i18n },
  'app/module:description': { type: DataTypes.i18n },
  'app/module:branch': {},
  'app/module:solution': { type: DataTypes.stringArray },
  'app/module:labels': { type: DataTypes.stringArray },
  'app/module:instance-policy': {},
  'app/module:type-policy': {},
  'app/module:workflow-process-policy': {},
  'app/module:module-setting-instance-policy': {},
  'app/module:blacklist': { type: DataTypes.stringArray },
  'app/module:options-object-type': {
    type: DataTypes.object,
    scheme: objectTypeScheme
  },
  'app/module:object-types': {
    type: DataTypes.objectArray,
    scheme: objectTypeScheme
  },
  'app/module:lists': {
    type: DataTypes.objectArray,
    scheme: listScheme
  },
  'app/module:queries': {
    type: DataTypes.objectArray,
    scheme: queryScheme
  },
  'app/module:dashboards': {
    type: DataTypes.objectArray,
    scheme: dashboardScheme
  },
  'app/module:workflows': {
    type: DataTypes.objectArray,
    scheme: dashboardScheme
  },
  'app/module:workflow-panels': {
    type: DataTypes.objectArray,
    scheme: workflowPanelScheme
  },
  'app/module:forms': {
    type: DataTypes.objectArray,
    scheme: formScheme
  },
  'app/module:plan-templates': {
    type: DataTypes.objectArray,
    scheme: planScheme
  },
  'app/module:message-email-templates': {
    type: DataTypes.objectArray,
    scheme: emailOutboundTemplateScheme
  },
  'app/module:message-sms-templates': {
    type: DataTypes.objectArray,
    scheme: smsOutboundTemplateScheme
  },
  'app/module:message-voice-templates': {
    type: DataTypes.objectArray,
    scheme: voiceMessageOutboundTemplateScheme
  },
  'app/module:charts': {
    type: DataTypes.objectArray,
    scheme: chartScheme
  },
  'app/module:map-data-sources': {
    type: DataTypes.objectArray,
    scheme: mapDataSourceScheme
  },
  'app/module:map-layers': {
    type: DataTypes.objectArray,
    scheme: mapLayerScheme
  },
  'app/module:maps': {
    type: DataTypes.objectArray,
    scheme: mapScheme
  },
  'app/module:sequences': {
    type: DataTypes.objectArray,
    scheme: sequenceScheme
  },
  'app/module:risk-matrices': {
    type: DataTypes.objectArray,
    scheme: riskMatrixScheme
  },
  'app/module:print-templates': {
    type: DataTypes.objectArray,
    scheme: printTemplateScheme
  },
  'app/module:print-template-usages': {
    type: DataTypes.objectArray,
    scheme: printTemplateUsageScheme
  }
};

interface IFlatModule extends IObject {
  name: string;
  description: string;
  branch: Tip;
  solution?: Tip[];
  labels?: Tip[];
  instancePolicy?: Tip;
  typePolicy?: Tip;
  workflowProcessPolicy?: Tip;
  moduleSettingInstancePolicy?: Tip;
  blacklist?: Tip[];
  optionsObjectType?: Tip;
  objectTypes?: Tip[];
  lists?: Tip[];
  queries?: Tip[];
  dashboards?: Tip[];
  workflows?: Tip[];
  workflowPanels?: Tip[];
  forms?: Tip[];
  planTemplates?: Tip[];
  messageEmailTemplates?: Tip[];
  messageSmsTemplates?: Tip[];
  charts?: Tip[];
  mapDataSources?: Tip[];
  mapLayers?: Tip[];
  maps?: Tip[];
  sequences?: Tip[];
  riskMatrices?: Tip[];
  messageVoiceTemplates?: Tip[];
  printTemplates?: Tip[];
  printTemplateUsages?: Tip[];
}

const flatModuleScheme: IObjectScheme = {
  'app/module:name': { type: DataTypes.i18n },
  'app/module:description': { type: DataTypes.i18n },
  'app/module:branch': {},
  'app/module:solution': { type: DataTypes.stringArray },
  'app/module:labels': { type: DataTypes.stringArray },
  'app/module:instance-policy': {},
  'app/module:type-policy': {},
  'app/module:workflow-process-policy': {},
  'app/module:module-setting-instance-policy': {},
  'app/module:blacklist': { type: DataTypes.stringArray },
  'app/module:options-object-type': { type: DataTypes.string },
  'app/module:object-types': { type: DataTypes.stringArray },
  'app/module:lists': { type: DataTypes.stringArray },
  'app/module:workflows': { type: DataTypes.stringArray },
  'app/module:queries': { type: DataTypes.stringArray },
  'app/module:dashboards': { type: DataTypes.stringArray },
  'app/module:workflow-panels': { type: DataTypes.stringArray },
  'app/module:forms': { type: DataTypes.stringArray },
  'app/module:plan-templates': { type: DataTypes.stringArray },
  'app/module:message-email-templates': { type: DataTypes.stringArray },
  'app/module:message-sms-templates': { type: DataTypes.stringArray },
  'app/module:message-voice-templates': { type: DataTypes.stringArray },
  'app/module:charts': { type: DataTypes.stringArray },
  'app/module:map-data-sources': { type: DataTypes.stringArray },
  'app/module:map-layers': { type: DataTypes.stringArray },
  'app/module:maps': { type: DataTypes.stringArray },
  'app/module:sequences': { type: DataTypes.stringArray },
  'app/module:risk-matrices': { type: DataTypes.stringArray },
  'app/module:print-templates': { type: DataTypes.stringArray },
  'app/module:print-template-usages': { type: DataTypes.stringArray }
};

interface IModuleConfigCount {
  objectTypes: number;
  lists: number;
  queries: number;
  dashboards: number;
  workflows: number;
  workflowPanels: number;
  forms: number;
  planTemplates: number;
  messageEmailTemplates: number;
  messageSmsTemplates: number;
  charts: number;
  mapDataSources: number;
  mapLayers: number;
  maps: number;
  sequences: number;
  riskMatrices: number;
  messageVoiceTemplates: number;
  printTemplates: number;
  printTemplateUsages: number;
}
