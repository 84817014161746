import Big from 'big.js';
import {
  endsWith,
  isEmptyString,
  startsWith
} from './number-format-utils';
import { escapeRegExp, get, isEmpty } from 'lodash';
import { Calculations, IFormattingFunctionNumber, IFormattingFunctionString } from './number-format-types';

// tslint:disable-next-line:variable-name
export function reverseFormatCalculation({number, format}: IFormattingFunctionNumber): IFormattingFunctionNumber {
  if (format.calculations === Calculations.percent) {
    // need bigNum as JS does not like floats
    const bigNum: Big = new Big(number);
    return {
      number: parseFloat(bigNum.div(100).toString()),
      format,
    };
  }

  return {number, format};
}

export function formatParamStringToNumber({numberString, format}: IFormattingFunctionString): IFormattingFunctionNumber {
  return {
    number: parseFloat(numberString),
    format,
  };
}

// --- remove duplicates
// +- the 0-9 maybe a . then 0-9 maybe
const NUMBER_WITH_SIGN = new RegExp('[+-]?\\d+(?:\\.\\d+)?', 'g');
export function removeDuplicateAndNonPrefixOperators({numberString, format}:
                                                       IFormattingFunctionString): IFormattingFunctionString {
  if (noPlusOrMinusSign(numberString) || isEmptyString(numberString)) {
    return {
      numberString,
      format
    };
  }

  numberString.match(NUMBER_WITH_SIGN);
  return {
    numberString: get(numberString.match(NUMBER_WITH_SIGN), 0, null),
    format,
  };
}

const SIGN_PLUS_MINUS = new RegExp('[+-]', 'g');
function noPlusOrMinusSign(numberString: string) {
  return numberString.search(SIGN_PLUS_MINUS) < 0;
}
// --- remove duplicates end

// --- revertToStandardDecimalSeparatorRemoveDuplicates
// select '.' (\.) except the last '.' (?=.+\1)
const DECIMAL_REGEX = new RegExp(escapeRegExp('.'));
export function revertToStandardDecimalSeparatorRemoveDuplicates({numberString, format}: IFormattingFunctionString):
  IFormattingFunctionString {
  if (typeof format.decimalSeparator !== 'string') {
    return {
      numberString,
      format,
    };
  }

  const decimalSeparator = isEmptyString(format.decimalSeparator)
    ? '.'
    : format.decimalSeparator;

  const replaced = numberString.replace(new RegExp(escapeRegExp(decimalSeparator), 'g'), '.');
  const duplicatesRemoved = removeDuplicateDecimals(replaced); // switch to standard .

  return {
    numberString: duplicatesRemoved,
    format,
  };
}

function removeDuplicateDecimals(stringVal: string): string {
  const lastIndex = stringVal.lastIndexOf('.');
  if (lastIndex === -1) {
    // if not found return;
    return stringVal;
  }
  const index = stringVal.indexOf('.');
  const singleItemOnly = lastIndex === index;
  if (singleItemOnly) {
    return stringVal;
  }

  const newString = stringVal.replace(DECIMAL_REGEX, '');

  // recursively call this until only a single decimal is present
  return removeDuplicateDecimals(newString);
}
// --- revertToStandardDecimalSeparatorRemoveDuplicates end

// --- removeValuesOtherThanDecimalSeparatorOrSign
export function removeValuesOtherThanDecimalSeparatorOrSign({numberString, format}: IFormattingFunctionString):
  IFormattingFunctionString {
  if (typeof numberString === 'string' && isEmpty(numberString)) {
    return {
      numberString,
      format,
    };
  }

  const _decimalSeparator = get(format, 'decimalSeparator', '.');
  const decimalSeparator = isEmptyString(_decimalSeparator)
    ? '.' // use the default decimal separator if an empty string is passed
    : _decimalSeparator;

  const decimalSeparatorEscaped = escapeRegExp(decimalSeparator);

  // match 0-9 and - '[0-9\-]' or '|' decimalSeparatorEscaped (escaped so special characters will matched)
  const regex = new RegExp(`[0-9\-\+]|(${decimalSeparatorEscaped})`, 'g');

  const _numberString  = regex.test(numberString)
    ? numberString.match(regex).join('')
    : ''; // no numbers return blank string

  return {
    numberString: _numberString,
    format,
  };
}
// --- removeValuesOtherThanDecimalSeparatorOrSign

export function removePrefix({numberString, format}: IFormattingFunctionString): IFormattingFunctionString {
  if (typeof format.prefix !== 'string') {
    return { numberString, format };
  }
  if (startsWith(numberString, format.prefix)) {
    const length = format.prefix.length;
    return {
      numberString: numberString.slice(length),
      format,
    };
  }

  return {numberString, format};
}

export function removeSuffix({numberString, format}): IFormattingFunctionString {
  if (typeof format.suffix !== 'string') {
    return {
      format,
      numberString,
    };
  }
  if (endsWith(numberString, format.suffix)) {
    const updatedString = numberString.slice(0, numberString.length - format.suffix.length);
    return {
      format,
      numberString: updatedString,
    };
  }
  return {
    numberString,
    format,
  };
}
