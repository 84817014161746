import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoadingState } from '../../shared/constants';
import { Tip } from '../../data/models/types';
import { TeamService, ITeamSummary, IAnalyzedTeams } from '../../team/team.service';
import { SideSheetListModes } from '../../side-sheet/side-sheet-list/side-sheet-list.component';

@Component({
  selector: 'app-select-teams-side-sheet',
  templateUrl: './select-teams-side-sheet.component.html',
  styleUrls: ['./select-teams-side-sheet.component.scss']
})
export class SelectTeamsSideSheetComponent implements OnInit {
  @Input() mode: SideSheetListModes.SINGLE_SELECT | SideSheetListModes.MULTI_SELECT = SideSheetListModes.SINGLE_SELECT;

  title = 'Select teams';
  initiallySelectedTips: Tip[] = [];
  selectedTeams: Tip[] = [];
  doNotDisplayInitiallySelectedTeams = false;
  teams$: Observable<ITeamSummary[]>;
  teams: ITeamSummary[];
  subscriptions = new Subscription();
  filterText: string;
  loadingStates = LoadingState;
  done: (selectedTeam: ITeamSummary | ITeamSummary[]) => void;

  constructor(
    private teamService: TeamService
  ) { }

  ngOnInit() {
    this.selectedTeams = [...this.initiallySelectedTips];
    this.teams$ = this.teamService.getAnalyzedTeams().pipe(
      map((analyzedTeams: IAnalyzedTeams) => analyzedTeams.teams),
      map((teams: ITeamSummary[]) => {
        this.teams = (this.doNotDisplayInitiallySelectedTeams) ?
          teams.filter(team => this.initiallySelectedTips.indexOf(team.$tip) === -1) : teams;
        return this.teams;
      })
    );
  }

  onDone(selectedTeamTips: Tip[]) {
    switch (this.mode) {
      case SideSheetListModes.SINGLE_SELECT:
        if (selectedTeamTips && selectedTeamTips.length) {
          this.done(this.teams.find(x => x.$tip === selectedTeamTips[0]));
        }

        break;
      case SideSheetListModes.MULTI_SELECT:
        this.done(this.teams.filter((team) => {
          return selectedTeamTips.indexOf(team.$tip) > -1;
        }));

        break;
    }
  }
}
