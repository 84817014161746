import { InjectionToken } from '@angular/core';
import { Tip } from '../data/models/types';
import { GroupCreateSideSheetComponent } from '../groups/sidesheets/group-create-side-sheet/group-create-side-sheet.component';
import { GroupEditSideSheetComponent } from '../groups/sidesheets/group-edit-side-sheet/group-edit-side-sheet.component';
import { ObjectLaunchService } from './object-launch.service';
import { IFormObjectAndType } from './get-object-and-form.service';
import { PlanEditSideSheetComponent } from '../plans/plan-edit-side-sheet/plan-edit-side-sheet.component';
import { EditFormSideSheetComponent } from './form-renderer/edit-form-side-sheet/edit-form-side-sheet.component';
import { IForm } from '../settings/form-designer/models/form';
import { generalSecuritySettingsForm } from './well-known-types/general-security-settings-form';
import { apiKeyMetaCreateForm, apiKeyMetaEditForm } from './well-known-types/api-key-meta-form';
import { brandingSettingsCreateForm } from './well-known-types/branding-settings-form';
import { numberFormatForm } from './well-known-types/number-format-form';
import { localeSettingsCreateForm } from './well-known-types/locale-settings-form';
import { IObjectAndType } from '../object/object.service';

export const OBJECT_LAUNCH_SERVICE_TOKEN = new InjectionToken<ObjectLaunchService>('ObjectLaunchService');

export interface ISubObject extends IObjectAndType {
  deleteOnSave?: true; // is not defined when false
}

export interface ILoadParams {
  formTip?: Tip;
  objectTip?: Tip;
  typeTip?: Tip;
  contextTip?: Tip;
  queryParams?: any;
  operation?: OBJECT_LAUNCH_OPERATION;
  preview?: boolean;
  hideSecurityOptions?: boolean;

  // will return the object to be saved not a persisted tip
  subObject?: boolean | ISubObject;
}

export enum REDIRECT_TYPES {
  dashboard = 'dashboard',
  workflow = 'workflow'
}

export interface IObjectLaunchDoneRedirect {
  redirectLocation: string | any[]; // for navigateByUrl | navigate;
  type: REDIRECT_TYPES; // meta;
}

export type IObjectLaunchDoneWithRedirect = IObjectLaunchDone & { redirect?: IObjectLaunchDoneRedirect | null };

export interface IObjectLaunchDone {
  redirectedToDashboard?: boolean;
  objectTip?: Tip;
  subObject?: ISubObject;
  defaultDashboardTip?: Tip;
  deleted?: boolean;
  redirect?: IObjectLaunchDoneRedirect;
}

export interface IObjectLaunchOverrideProps extends ILoadParams {
  securityPolicyTip?: Tip;
  hideSecurityOptions?: boolean;
}

export interface IObjectLaunchProps extends IObjectLaunchOverrideProps {
  formObjectAndType: IFormObjectAndType;
}

export interface IWellKnownReturnValue {
  component: any;
  objectLaunchOverrides: IObjectLaunchOverrideProps;
}

export enum OBJECT_LAUNCH_OPERATION {
  CREATE = 'create',
  EDIT = 'edit',
  VIEW = 'view',
}

export enum WELL_KNOWN_OBJECT_TYPE {
  GROUP = 'app/group',
  PLAN = 'app/plan',
  PROTECTIVE_MARKING = 'app/au-gov/protective-marking',
  PROTECTIVE_MARKING_SETTINGS = 'app/au-gov/protective-markings-settings',
  GENERAL_SECURITY_SETTINGS = 'app/general-security-settings',
  API_KEY_META = 'app/api-key-meta',
  BRANDING_SETTINGS = 'app/branding-settings',
  LOCALE_SETTINGS = 'app/type/locale',
  NUMBER_FORMAT = 'number-format' // defined by the backend (no app/)
}

export const nonStandardCreationTypes: Tip[] = [
  'app/plan'
];

/**
 * Note: When adding more well-known side sheet
 * Can add done$ = new Subject <IObjectLaunchDone>() - for callback
 * Can add setProps(props: IObjectLaunchProps) - for passing inputs
 */
export function createWellKnownObjectMapping(
  { objectType, formObjectAndType }:
    { objectType: string, formObjectAndType?: IFormObjectAndType }
): IWellKnownReturnValue {
  const mapping = {
    [WELL_KNOWN_OBJECT_TYPE.GROUP]: {
      [OBJECT_LAUNCH_OPERATION.CREATE]: { component: GroupCreateSideSheetComponent, objectLaunchOverrides: {}, formObjectAndType },
      [OBJECT_LAUNCH_OPERATION.EDIT]: { component: GroupEditSideSheetComponent, objectLaunchOverrides: {}, formObjectAndType }
    },
    [WELL_KNOWN_OBJECT_TYPE.PLAN]: {
      [OBJECT_LAUNCH_OPERATION.VIEW]: { component: PlanEditSideSheetComponent, objectLaunchOverrides: {}, formObjectAndType },
      [OBJECT_LAUNCH_OPERATION.EDIT]: { component: PlanEditSideSheetComponent, objectLaunchOverrides: {}, formObjectAndType }
    }
  };

  // general security settings
  const apiKeyMetaOverrides: IObjectLaunchOverrideProps = {
    hideSecurityOptions: true,
  };

  const apiKeyMeta = (customForm: IForm) => ({
    component: EditFormSideSheetComponent,
    objectLaunchOverrides: apiKeyMetaOverrides,
    formObjectAndType: overrideForm({ formObjectAndType, customForm })
  });

  mapping[WELL_KNOWN_OBJECT_TYPE.API_KEY_META] = {
    [OBJECT_LAUNCH_OPERATION.CREATE]: apiKeyMeta(apiKeyMetaCreateForm),
    [OBJECT_LAUNCH_OPERATION.EDIT]: apiKeyMeta(apiKeyMetaEditForm),
  };

  // protective markings overrides
  const protectiveMarkingOverrides: IObjectLaunchOverrideProps = {
    hideSecurityOptions: true,
    securityPolicyTip: 'app/security-policy/instance-admin-admin'
  };

  const protectiveMarking = {
    component: EditFormSideSheetComponent,
    objectLaunchOverrides: protectiveMarkingOverrides,
    formObjectAndType
  };

  mapping[WELL_KNOWN_OBJECT_TYPE.PROTECTIVE_MARKING] = {
    [OBJECT_LAUNCH_OPERATION.CREATE]: protectiveMarking,
    [OBJECT_LAUNCH_OPERATION.EDIT]: protectiveMarking,
  };
  // protective markings overrides

  // protective marking settings
  const protectiveMarkingSettingsOverrides: IObjectLaunchOverrideProps = {
    hideSecurityOptions: true,
  };

  const protectiveMarkingSettings = {
    component: EditFormSideSheetComponent,
    objectLaunchOverrides: protectiveMarkingSettingsOverrides,
    formObjectAndType
  };

  mapping[WELL_KNOWN_OBJECT_TYPE.PROTECTIVE_MARKING_SETTINGS] = {
    [OBJECT_LAUNCH_OPERATION.EDIT]: protectiveMarkingSettings,
  };
  // protective marking settings

  // general security settings
  const generalSecuritySettingsOverrides: IObjectLaunchOverrideProps = {
    hideSecurityOptions: true,
  };

  const generalSecuritySettings = {
    component: EditFormSideSheetComponent,
    objectLaunchOverrides: generalSecuritySettingsOverrides,
    formObjectAndType: overrideForm({ formObjectAndType, customForm: generalSecuritySettingsForm })
  };

  mapping[WELL_KNOWN_OBJECT_TYPE.GENERAL_SECURITY_SETTINGS] = {
    [OBJECT_LAUNCH_OPERATION.EDIT]: generalSecuritySettings,
  };
  // general security settings

  // branding settings
  const brandingSettingsOverrides: IObjectLaunchOverrideProps = {
    hideSecurityOptions: true,
  };

  const brandingSettings = {
    component: EditFormSideSheetComponent,
    objectLaunchOverrides: brandingSettingsOverrides,
    formObjectAndType: overrideForm({ formObjectAndType, customForm: brandingSettingsCreateForm })
  };

  mapping[WELL_KNOWN_OBJECT_TYPE.BRANDING_SETTINGS] = {
    [OBJECT_LAUNCH_OPERATION.EDIT]: brandingSettings,
  };
  // branding settings

  // Locale Timezone settings
  const localeTimezoneSettingsOverrides: IObjectLaunchOverrideProps = {
    hideSecurityOptions: true,
  };

  const localeTimezoneSettings = {
    component: EditFormSideSheetComponent,
    objectLaunchOverrides: localeTimezoneSettingsOverrides,
    formObjectAndType: overrideForm({ formObjectAndType, customForm: localeSettingsCreateForm })
  };

  mapping[WELL_KNOWN_OBJECT_TYPE.LOCALE_SETTINGS] = {
    [OBJECT_LAUNCH_OPERATION.EDIT]: localeTimezoneSettings,
  };
  // Locale Timezone settings

  // number format
  const numberFormatOverrides: IObjectLaunchOverrideProps = {
    hideSecurityOptions: true,
    securityPolicyTip: 'app/security-policy/instance-user-admin'
  };

  const numberFormatSettings = {
    component: EditFormSideSheetComponent,
    objectLaunchOverrides: numberFormatOverrides,
    formObjectAndType: overrideForm({formObjectAndType, customForm: numberFormatForm})
  };

  mapping[WELL_KNOWN_OBJECT_TYPE.NUMBER_FORMAT] = {
    [OBJECT_LAUNCH_OPERATION.EDIT]: numberFormatSettings,
    [OBJECT_LAUNCH_OPERATION.CREATE]: numberFormatSettings
  };
  // number format

  return mapping[objectType];
}

function overrideForm({ formObjectAndType, customForm }: { formObjectAndType: IFormObjectAndType, customForm: IForm })
  : IFormObjectAndType {
  return { ...formObjectAndType, form: customForm };
}
