import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { first, map, mergeMap, switchMap } from 'rxjs/operators';
import { get, intersection, isArray } from 'lodash';

import { FormDesignerService } from '../settings/form-designer/services-core/form-designer.service';
import { IForm } from '../settings/form-designer/models/form';
import { IObjectAndType, ObjectService } from '../object/object.service';
import { ISupertype } from '../models/supertype';
import { IField } from '../models/field';
import { Tip } from '../data/models/types';
import { FormulaResult, FormulaService } from '../data/formula.service';
import { AllObjectTypesService } from '../data/all-object-types/all-object-type.service';
import { ILoadParams } from './object-launch';
import { createFormFromType } from './form-renderer/utils/create-form-from-type';

export interface IFormObjectAndType {
  form?: IForm;
  objectAndType?: IObjectAndType;
  isSuperType?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class GetObjectAndFormService {
  constructor(
    private objectService: ObjectService,
    private formulaService: FormulaService,
    private formDesignerService: FormDesignerService,
    private allObjectTypesService: AllObjectTypesService
  ) {}

  getFormObjectAndType({ formTip, objectTip, typeTip, contextTip, queryParams, subObject }: ILoadParams)
    : Observable<IFormObjectAndType> {
    /*
      Use cases
      - Preview (Form tip) ✓
        - load new form (disable save button)
      - Create (Form tip) ✓
        - load form then type
      - Create (Type tip and Form tip) ✓
        - load type and form
      - Create (Type tip) ✓
        - typeTip is a super type
        - typeTip type has default form (load form)
        - typeTip no default form (dynamically create form)
      - Edit (Object tip) ✓
        - typeTip type has default form (load form)
        - typeTip no default form (dynamically create form)
      - Edit (Object tip & Form Tip) ✓
        - load object, load form check types match then initialise
      - Edit (SubObject) ✓
        - use existing object and type, load form
    */


    if (formTip && objectTip) {
      return this.getFormAndObject$(formTip, objectTip);
    }

    if (typeTip && formTip && contextTip) {
      return this.getTypeThenFormWithContext$(typeTip, contextTip, formTip);
    }

    if (formTip && typeTip) {
      return this.getFormAndType$(formTip, typeTip);
    }

    if (contextTip && typeTip) {
      return this.getTypeThenFormWithContext$(typeTip, contextTip);
    }

    if (formTip) {
      return this.getFormThenType$(formTip);
    }

    if (typeTip) {
      return this.getTypeData$(typeTip, queryParams);
    }

    if (objectTip) {
      return this.getObjectThenForm$(objectTip);
    }
    if (subObject) {
      return this.useSubObjectToCreateForm(subObject);
    }
  }

  getTypeData$(typeTip: Tip, queryParams): Observable<IFormObjectAndType> {
    return this.objectService
      .isSuperType$(typeTip)
      .pipe(
        switchMap((isSuperType: boolean) => {
          if (isSuperType) {
            return of({ isSuperType: true });
          }
          return this.getTypeAndForm$(typeTip, queryParams);
        })
      );
  }

  /*
   The key to these services is they all return the same combination of IFormObjectAndType
 */
  getTypeAndForm$(typeTip, queryParams): Observable<IFormObjectAndType> {
    const getTypeGenerateForm$ = () =>
      this.getType$(typeTip)
        .pipe(
          map((objectAndType: IObjectAndType) => {
            return [createFormFromType(objectAndType), objectAndType];
          })
        );

    const getFormAndType$ = (formTip: Tip) =>
      forkJoin([
        this.getForm$(formTip),
        this.getType$(typeTip)
      ]);

    return this.getFormTip$(typeTip).pipe(
      switchMap(
        (formTip) => {
          if (formTip) {
            return getFormAndType$(formTip);
          }

          return getTypeGenerateForm$();
        }
      ),
      map(mapToFormObjectAndType)
    );
  }

  private getTypeThenFormWithContext$(typeTip: Tip, contextTip: Tip, formTip?: Tip): Observable<IFormObjectAndType> {
    const superType$ = of({ isSuperType: true });

    const getFormAndObjectAndType$ = forkJoin([
      formTip ? of(formTip) : this.getFormTip$(typeTip),
      this.getType$(typeTip),
      this.getContextTypeTip$(contextTip)
    ]).pipe(
      mergeMap(([_formTip, objectAndType, contextTypeTip]: [Tip | undefined, IObjectAndType, Tip]) => {
        const form$: Observable<IForm> = _formTip
          ? this.getForm$(_formTip)
          : of(createFormFromType(objectAndType));

        const modifiedObjectAndType$: Observable<IObjectAndType> = of(objectAndType)
          .pipe(switchMap(prePopulateWithContext.call(this, contextTypeTip, contextTip)));

        return forkJoin([form$, modifiedObjectAndType$])
          .pipe(map(mapToFormObjectAndType));
      })
    );

    return this.objectService.isSuperType$(typeTip)
      .pipe(
        switchMap(
          (isSuperType: boolean) => {
            return isSuperType
              ? superType$
              : getFormAndObjectAndType$;
          }
        )
      );
  }

  private getObjectThenForm$(objectTip): Observable<IFormObjectAndType> {
    return this.getObject$(objectTip)
      .pipe(
        switchMap((object: IObjectAndType) => {
          const typeTip = object.objectType.$tip;
          return forkJoin([this.getFormTip$(typeTip), of(object)]);
        }),
        switchMap(([formTip, object]: [string, IObjectAndType]) => {
          const form$ = formTip
            ? this.getForm$(formTip)
            : of(createFormFromType(object));

          return forkJoin([form$, of(object)]);
        }),
        map(verifyFormMatchesObjectType)
      );
  }

  private getFormThenType$(formTip): Observable<IFormObjectAndType> {
    return this.getForm$(formTip)
      .pipe(
        mergeMap((form) =>
          forkJoin([
            of(form),
            this.getType$(form.contextType.type.$tip)
          ]).pipe(map(mapToFormObjectAndType))
        )
      );
  }

  private getFormAndType$(formTip, typeTip): Observable<IFormObjectAndType> {
    return forkJoin([this.getForm$(formTip), this.getType$(typeTip)])
      .pipe(map(verifyFormMatchesObjectType));
  }

  private getFormAndObject$(formTip: Tip, objectTip: Tip): Observable<IFormObjectAndType> {
    return forkJoin([this.getForm$(formTip), this.getObject$(objectTip)]).pipe(map(verifyFormMatchesObjectType));
  }

  private useSubObjectToCreateForm(subObject: IObjectAndType | boolean): Observable<IFormObjectAndType> {
    if (typeof subObject === 'boolean') {
      return throwError(`You can only use "subObject: true" for creating new subObjects when editing you need to pass the subObject`);
    }

    const typeTip: null | Tip = get(subObject, 'objectType.$tip', null);
    if (!typeTip) {
     return throwError('Cannot load sub object without an objectTypeTip');
    }

    return this.getFormTip$(typeTip)
      .pipe(
        switchMap((formTip) => {
          const form$: Observable<IForm> = formTip
            ? this.getForm$(formTip)
            : of(createFormFromType(subObject));
          return form$;
        }),
        map((form) => ({form, objectAndType: subObject}))
      );
  }

  /*
    End [IForm, IObjectAndType]
  */

  getObject$(objectTip): Observable<IObjectAndType> {
    return this
      .objectService
      .getObjectAndType(objectTip)
      .pipe(first());
  }

  private getType$(tip: string): Observable<IObjectAndType> {
    return this.objectService.getType(tip).pipe(first());
  }

  private getContextTypeTip$(contextTip: Tip): Observable<Tip> {
    return this.formulaService.evaluate(`TYPE("${contextTip}")`).pipe(
      first(),
      map((result: FormulaResult) => result[0])
    );
  }

  private getFormTip$(typeTip): Observable<Tip | null> {
    return this.allObjectTypesService.getFormTipFromTypeTip$(typeTip).pipe(first());
  }

  private getForm$(formTip: Tip): Observable<IForm> {
    return this
      .formDesignerService
      .loadForm(formTip)
      .pipe(first());
  }
}

function verifyFormMatchesObjectType([form, type]: [IForm, IObjectAndType]): IFormObjectAndType {
  const contextTypeTip = get(form, 'contextType.type.$tip', null);
  const objectTypeTip = get(type, 'objectType.$tip', null);
  const isNull: boolean = Boolean(contextTypeTip === null) || Boolean(objectTypeTip === null);
  const contextTipNotEqualToObjectTip: boolean = contextTypeTip !== objectTypeTip;
  if (isNull || contextTipNotEqualToObjectTip) {
    throw new Error(`
              You cannot load this form with context type tip ${contextTypeTip} for type tip ${objectTypeTip}.
              Types should be the same!`);
  }

  return { form, objectAndType: type };
}

function mapToFormObjectAndType([form, type]: [IForm, IObjectAndType]): IFormObjectAndType {
  return { form, objectAndType: type };
}

// @todo Move this to a separate service.
function prePopulateWithContext(contextTypeTip: Tip, contextTip: Tip): (objectAndType: IObjectAndType) => Observable<IObjectAndType> {
  return function(objectAndType: IObjectAndType) {
    if (objectAndType.objectType.field && objectAndType.objectType.field.length) {
      const promises = objectAndType.objectType.field.map(
        (field: IField) => makePrePopulateFieldPromise.call(this, field, contextTypeTip, contextTip, objectAndType)
      );

      return forkJoin([Promise.all(promises)]).pipe(
        map(() => objectAndType)
      );
    }

    return of(objectAndType);
  }.bind(this);
}

function makePrePopulateFieldPromise(field: IField, contextTypeTip: Tip, contextTip: Tip, objectAndType: IObjectAndType) {
  return new Promise(
    resolve => {
      if (
        contextTypeTip &&
        field.tag &&
        field.tag.length &&
        field.tag[0] === 'autoPopulate--true' &&
        isArray(field.typerestrict)
      ) {
        if (field.typerestrict.includes(contextTypeTip)) {
          setObjectAndTypeForPrePopulate(field, objectAndType, contextTip);

          return resolve();
        }

        return this.objectService.getType(contextTypeTip).pipe(
          first()
        ).subscribe(
          (contextObjectAndType: IObjectAndType) => {
            if (
              isArray(contextObjectAndType.objectType.supertypes) &&
              intersection(
                field.typerestrict,
                contextObjectAndType.objectType.supertypes.map((superType: ISupertype) => superType.$tip)
              ).length
            ) {
              setObjectAndTypeForPrePopulate(field, objectAndType, contextTip);

              return resolve();
            }

            return resolve();
          }
        );
      }

      return resolve();
    }
  );
}

function setObjectAndTypeForPrePopulate(field: IField, objectAndType: IObjectAndType, contextTip: Tip) {
  if (field.maxcount === 1) {
    objectAndType.objectData[field.$tip] = contextTip;

    return;
  }

  objectAndType.objectData[field.$tip] = [contextTip];
}
