import { IInnerOperator, IOperators, reverseOperator } from './operators';
import { IFilterMeta, OPERATOR_TYPE } from '../../../models/query';
import { stringEqual, stringNotEqual, stringIsEmpty, stringIsNotEmpty } from './string-operators';
import { FormulaSpec } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { Tip } from '../../../data/models/types';


const listIn: IInnerOperator = {
  opNo: 2,
  toFormula: (leftArg, rightArg, meta: IFilterMeta) => {
    const listTip = meta && meta.fieldTip ? getListTipFormulaSpec(meta.fieldTip) : null;
    return {
      name: 'HAS_INTERSECT',
      args: [
        leftArg,
        {
          name: 'ARRAY',
          args: [
            rightArg,
            {
              name: 'JSON_DESCENDANTS',
              args: [
                {
                  name: 'CONCAT',
                  args: [
                    '[',
                    {
                      name: 'CONCAT_WS',
                      args: [
                        ',',
                        {
                          name: 'FIELD',
                          args: [
                            'app/list:items',
                            listTip
                          ]
                        }
                      ]
                    },
                    ']'
                  ]
                },
                'value',
                'value',
                'items',
                rightArg
              ]
            }
          ]
        }
      ]
    };
  }
};
const listNotIn = reverseOperator(listIn);

const listContains: IInnerOperator = {
  opNo: 2,
  toFormula: (leftArg, rightArg) => ({
    name: 'INARRAY',
    args: [{ name: 'FIND_TEXT', args: [leftArg, rightArg] }, 0]
  })
};
const listNotContains = reverseOperator(listContains);

export const SPECIAL_VAR_NAME = '---SPECIALVAR---';

export const listOperators: IOperators = {
  operators: {
    [OPERATOR_TYPE.EQUALS]: stringEqual,
    [OPERATOR_TYPE.NOT_EQUALS]: stringNotEqual,
    [OPERATOR_TYPE.CONTAINS]: listContains,
    [OPERATOR_TYPE.NOT_CONTAINS]: listNotContains,
    [OPERATOR_TYPE.IS_EMPTY]: stringIsEmpty,
    [OPERATOR_TYPE.NOT_IS_EMPTY]: stringIsNotEmpty,
    [OPERATOR_TYPE.IN]: listIn,
    [OPERATOR_TYPE.NOT_IN]: listNotIn
  },
  getParts: formula => {
    let currentPart = formula;
    let negative = false;

    if (currentPart.name === 'NOT') {
      currentPart = currentPart.args[0];
      negative = true;
    }

    if (currentPart.name === 'HAS_INTERSECT') {
      return {
        operator: negative ? OPERATOR_TYPE.NOT_IN : OPERATOR_TYPE.IN,
        leftArg: currentPart.args[0],
        rightArg: currentPart.args[1].args[0]
      };
    }

    if (currentPart.name === 'EQUALS') {
      return {
        operator: negative ? OPERATOR_TYPE.NOT_EQUALS : OPERATOR_TYPE.EQUALS,
        leftArg: currentPart.args[0],
        rightArg: currentPart.args[1]
      };
    }

    if (currentPart.args[1] === 0) {
      return {
        operator: negative ? OPERATOR_TYPE.NOT_CONTAINS : OPERATOR_TYPE.CONTAINS,
        leftArg: currentPart.args[0].args[0],
        rightArg: currentPart.args[0].args[1]
      };
    }

    if (currentPart.name === 'INARRAY') {
      return {
        operator: negative ? OPERATOR_TYPE.NOT_IS_EMPTY : OPERATOR_TYPE.IS_EMPTY,
        leftArg: currentPart.args[1]
      };
    }

    throw new Error('Unknown operators: ' + currentPart.name);
  }
};

export function getListTipFormulaSpec(fieldTip: Tip): FormulaSpec {
  return {
    name: 'LAST',
    args: [
      {
        name: 'SPLIT',
        args: [
          '--',
          {
            name: 'FILTER',
            args: [
              {
                name: 'FIELD',
                args: [
                  'field/tag',
                  fieldTip
                ]
              },
              {
                name: 'EQUALS',
                args: [
                  {
                    name: 'SUBSTR',
                    args: [
                      {
                        name: 'VAR',
                        args: [
                          SPECIAL_VAR_NAME
                        ]
                      },
                      '0',
                      {
                        name: 'STRLEN',
                        args: [
                          'listTip--'
                        ]
                      }
                    ]
                  },
                  'listTip--'
                ]
              },
              SPECIAL_VAR_NAME
            ]
          }
        ]
      }
    ]
  };
}
