import * as tslib_1 from "tslib";
import { CompositeNodeConverter } from './CompositeNodeConverter';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import { Parser, Stringify } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { isEmpty } from 'lodash';
import { createConnection, createForEachNode, createSetVarNode, createSwitchNode, createUpdateEnoNode } from './utils/create-node-utils';
import * as i0 from "@angular/core";
var NS = 'UpdateSecurityNodeConverterService';
var UpdateSecurityNodeConverterService = /** @class */ (function (_super) {
    tslib_1.__extends(UpdateSecurityNodeConverterService, _super);
    function UpdateSecurityNodeConverterService() {
        var _this = _super.call(this) || this;
        _this.type = 'processnode/data/update-eno';
        _this.processNodeType = 'processnodetype/data/update-eno';
        return _this;
    }
    UpdateSecurityNodeConverterService.prototype.convert = function (nodeData) {
        var _a, _b;
        if (!this.isValid(nodeData)) {
            throw new InvalidWorkflowNodeDataError("Please correct invalid data on update-security node [" + nodeData.fields.title + "]", nodeData);
        }
        var statusVarKey = 'Status-' + Math.random();
        var formula = Parser(nodeData.fields.formula);
        var setVarStatusHashNode = createSetVarNode((_a = {},
            _a[statusVarKey] = Stringify({
                name: 'IF',
                args: [
                    { name: 'EQUALS', args: [formula, { name: 'ARRAY', args: [] }] },
                    'failed',
                    'done'
                ]
            }),
            _a), nodeData.tip);
        var foreachWorkflowTipsNode = createForEachNode(formula, 'Tip to update');
        var doneConnectionForSetVarNode = createConnection('done', foreachWorkflowTipsNode);
        setVarStatusHashNode.connections.push(doneConnectionForSetVarNode);
        var updateEnoSecurityNode = createUpdateEnoNode({
            name: 'VAR',
            args: ['Tip to update']
        }, nodeData.fields.securityPolicy);
        var foreachConnectionForForeachNode = createConnection('foreach', updateEnoSecurityNode);
        foreachWorkflowTipsNode.connections.push(foreachConnectionForForeachNode);
        var finallyConnectionForUpdateEnoNode = createConnection('finally', foreachWorkflowTipsNode);
        updateEnoSecurityNode.connections.push(finallyConnectionForUpdateEnoNode);
        var setVarStatusHashFailed = createSetVarNode((_b = {},
            _b[statusVarKey] = Stringify({
                name: 'ARRAY',
                args: ['failed']
            }),
            _b));
        var failedConnectionForUpdateEnoNode = createConnection('failed', setVarStatusHashFailed);
        updateEnoSecurityNode.connections.push(failedConnectionForUpdateEnoNode);
        var switchStatusNode = createSwitchNode({ name: 'VAR', args: [statusVarKey] });
        var doneConnectionForForeachNode = createConnection('done', switchStatusNode);
        foreachWorkflowTipsNode.connections.push(doneConnectionForForeachNode);
        var nodesAndLink = {
            nodes: [
                setVarStatusHashNode,
                foreachWorkflowTipsNode,
                updateEnoSecurityNode,
                setVarStatusHashFailed,
                switchStatusNode,
            ],
            links: [
                doneConnectionForSetVarNode,
                foreachConnectionForForeachNode,
                finallyConnectionForUpdateEnoNode,
                failedConnectionForUpdateEnoNode,
                doneConnectionForForeachNode
            ]
        };
        this.populateOutgoingLinks(nodeData).forEach(function (link) {
            switchStatusNode.connections.push(link);
            nodesAndLink.links.push(link);
        });
        return nodesAndLink;
    };
    UpdateSecurityNodeConverterService.prototype.isValid = function (nodeData) {
        return !isEmpty(nodeData.fields.title) && !isEmpty(nodeData.fields.formula) && !isEmpty(nodeData.fields.securityPolicy);
    };
    UpdateSecurityNodeConverterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UpdateSecurityNodeConverterService_Factory() { return new UpdateSecurityNodeConverterService(); }, token: UpdateSecurityNodeConverterService, providedIn: "root" });
    return UpdateSecurityNodeConverterService;
}(CompositeNodeConverter));
export { UpdateSecurityNodeConverterService };
