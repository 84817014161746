import { Injectable } from '@angular/core';
import { NodeConverterBase } from './NodeConverterBase';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import { IProcessNodeCreate } from '../../../models/process';
import { ICreateObjectEditor } from '../node-editors/create-object-editor/create-object-editor.component';
import { Formula } from '../../../data/models/types';
import { stringInterpolateFormula } from '../utils/converter-utils';
import { isEmpty } from 'lodash';
import dataConstants from '../../../data/constants';

@Injectable({
  providedIn: 'root'
})
export class CreateObjectNodeConverterService extends NodeConverterBase {
  type = 'processnode/data/create';
  processNodeType = 'processnodetype/data/create';

  public convert(nodeData: ICreateObjectEditor): IProcessNodeCreate {
    if (!this.isValid(nodeData)) {
      throw new InvalidWorkflowNodeDataError(`Please correct invalid data on create object node [${nodeData.fields.title}]`, nodeData);
    }

    const convertedFieldValues: Formula[] = nodeData.fields.fieldItems.map(fieldItem => stringInterpolateFormula({
      formula: fieldItem.fieldValue,
      isManualEntry: fieldItem.isManualEntry,
      isStringInterpolation: fieldItem.isStringInterpolation
    }));

    return {
      $type: this.type,
      $security: dataConstants.SECURITY.INSTANCE_USER_ADMIN,
      type: this.processNodeType,
      connections: [],
      point: [0, 0],
      key: nodeData.tip,
      createType: nodeData.fields.objectTypeTip,
      createSecurity: 'app/security-policy/system-all-users-read-write-delete',
      createBranch: dataConstants.BRANCH_MASTER,
      createFields: nodeData.fields.fieldItems.map(o => o.tip),
      createData: convertedFieldValues,
      createDataCount: convertedFieldValues.map(o => `COUNT(${o})`),
      createLang: 'en-us',
      createTipVarKey: nodeData.fields.variableToSetKey,
      createTipVarScope: 'Global',
    };
  }

  private isValid(nodeData: ICreateObjectEditor): boolean {
    return !isEmpty(nodeData.fields.objectTypeTip);
  }

  constructor() {
    super();
  }
}
