import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { get } from 'lodash';

@Component({
  selector: 'app-filter-and-add',
  templateUrl: './filter-and-add.component.html',
  styleUrls: ['./filter-and-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterAndAddComponent implements OnInit {
  _show = true;
  showAdd;

  // this component has too much logic for what it is.
  @Input() filterText: string;
  @Output() filterTextChange: EventEmitter<string> = new EventEmitter();
  @Input() set showAddButton(show: boolean) {
    this._show = show;
    this.setShowAdd();
  }

  @Output() add: EventEmitter<void> = new EventEmitter();

  ngOnInit(): void {
    this.setShowAdd();
  }

  setShowAdd() {
    this.showAdd = this._show && this.addButtonConnected();
  }

  addButtonConnected(): boolean {
    // add button is connected if it has subscribers
    const length = get(this, 'add.observers.length', 0);
    return length > 0;
  }
}
