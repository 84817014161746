import { Inject, Injectable } from '@angular/core';
import { EnoService } from '../data/eno.service';
import { FormulaResult, FormulaService } from '../data/formula.service';
import { IProcessResponse, ProcessService } from '../data/process.service';
import { Eno } from '../data/models/Eno';
import { Formula, Tip, Sid } from '../data/models/types';
import { first, map, switchMap, tap, toArray } from 'rxjs/operators';
import { IVars } from '../data/vars.service';
import { EnoFactory } from '../data/EnoFactory';
import { SessionManagerService } from '../data/session-manager.service';
import { TailLogs } from './tail-logs';
import { DOCUMENT } from '@angular/common';
import { IQueryOption, QueryService } from '../data/query.service';
import * as moment from 'moment';
import { convertToCSV } from './csv/csv-util';
import { chunk, flatten, head, sortBy, get, uniq, zipObject } from 'lodash';
import { combineLatest, concat, Observable, of } from 'rxjs';
import { extractJSONFromBuildJSON } from './un-build-json/un-build-json';
import { WorkflowDesignerService } from '../settings/workflow-designer/workflow-designer.service';
import { IDiagram, INodeData, INodeEditorData } from '../settings/workflow-designer/workflow-designer-interfaces';
import { PROCESS_NODE_TYPE } from '../settings/workflow-designer/workflow-designer-enums';
import { IUpdateStageEditor } from '../settings/workflow-designer/node-editors/update-stage-editor/update-stage-editor.component';
import { GoJSLinks } from '../settings/workflow-designer/gojs-panels/gojs-links';
import { DiagramUtils } from '../settings/workflow-designer/utils/diagram-utils';
import { GraphLinksModel } from 'gojs';
import { ConnectionUtils } from '../settings/workflow-designer/utils/connection-utils';
import { IOpPullOption, OpPullService } from '../data/op-pull.service';
import { EnsrvService } from '../data/ensrv.service';

interface IConsoleTipOptions {
  branch?: Tip;
  callback?: (eno?: Eno, err?: Error) => void;
  condensed: boolean;
  recursiveDepth?: number;
  recursiveFields?: Tip[];
}

interface IConsoleFormulaOptions {
  callback?: (formulaResult?: FormulaResult, err?: Error) => void;
  vars?: IVars;
  contextTip?: Tip;
  contextBranches?: Tip[];
}

interface IPatchOptions {
  branch?: Tip;
  security?: Tip;
  lang?: string;
  callback?: (eno?: Eno, err?: Error) => void;
}

@Injectable({
  providedIn: 'root'
})
export class ConsoleService {
  private _tailLogs = new TailLogs();

  constructor(
    private sessionManagerService: SessionManagerService,
    private enoService: EnoService,
    private processService: ProcessService,
    private formulaService: FormulaService,
    private queryService: QueryService,
    @Inject(DOCUMENT) private document: Document,
    private workflowDesignerService: WorkflowDesignerService,
    private opPullService: OpPullService,
    private enSrvService: EnsrvService
  ) {}

  formula(formula: string, options?: IConsoleFormulaOptions) {
    if (!options) {
      options = {};
    }
    this.formulaService.evaluate(formula, options.contextTip, options.contextBranches, options.vars).pipe(first()).subscribe(
      formulaResult => {
        if (options && options.callback) {
          options.callback(formulaResult);
        } else {
          console.log(formulaResult);
        }
      },
      err => {
        if (options && options.callback) {
          options.callback(null, err);
        } else {
          console.error('[en-console] Failed to evaluate formula:', formula, err);
        }
      }
    );
  }

  formulaPromise(formula: string, options?: IConsoleFormulaOptions) {
    if (!options) {
      options = {};
    }
    return this.formulaService.evaluate(formula, options.contextTip, options.contextBranches, options.vars).pipe(first()).toPromise();
  }

  tip(tip: Tip, options?: IConsoleTipOptions) {
    const readEnoOptions = {
      branch: get(options, 'branch', undefined),
      recursiveDepth: get(options, 'recursiveDepth', undefined),
      recursiveFields: get(options, 'recursiveFields', undefined)
    };
    this.enoService.readEno(tip, readEnoOptions).pipe(first()).subscribe(
      eno => {
        if (options && options.callback) {
          options.callback(eno);
        } else if (options && options.condensed === false) {
          console.log(eno);
        } else {
          console.log(this.condenseEno(eno));
        }
      },
      err => {
        if (options && options.callback) {
          options.callback(null, err);
        } else {
          console.error('[en-console] Failed to get eno:', tip, err);
        }
      }
    );
  }

  sid(sid: Sid, options?: IOpPullOption) {
    const opPullOptions: IOpPullOption = { ...options, sid: [sid] };
    const pullEno = this.opPullService.createOpPull(opPullOptions);

    this
      .enSrvService
      .send([pullEno])
      .subscribe(
        resultBatch => {
          resultBatch.forEach(eno => {
            if (eno.sid === sid) {
              console.log(eno);
            }
          });
        },
        err => {
          console.error('[en-console] Failed to get eno:', sid, err);
        }
      );
  }

  tipPromise(tip: Tip, options?: IConsoleTipOptions) {
    const readEnoOptions = { branch: options ? options.branch : undefined };
    return this.enoService.readEno(tip, readEnoOptions).pipe(first()).toPromise();
  }

  patch(tip: Tip, data: { [key: string]: any }, options?: IPatchOptions) {
    const branch = options ? options.branch : undefined;
    this.enoService.readEno(tip, { branch }).pipe(first()).pipe(
      switchMap(oldEno => {
        const enoFactory = new EnoFactory();
        enoFactory.setProtoToPatch(oldEno);
        Object.keys(data).filter(fieldTip => {
          return data[fieldTip] !== undefined && data[fieldTip] !== null;
        }).forEach(fieldTip => {
          if (data[fieldTip].constructor === Array) {
            enoFactory.setField(fieldTip, data[fieldTip].map(value => '' + value));
          } else if (typeof data[fieldTip] === 'string' || typeof data[fieldTip] === 'number') {
            enoFactory.setField(fieldTip, [ '' + data[fieldTip] ]);
          } else if (data[fieldTip].formula) {
            enoFactory.setFieldFormula(
              fieldTip,
              data[fieldTip].formula.constructor === Array ? data[fieldTip].formula : [ data[fieldTip].formula ]
            );
          } else if (data[fieldTip].i18n) {
            data[fieldTip].i18n.forEach(i18n => {
              enoFactory.setI18nValue(fieldTip, i18n.value, i18n.lang);
            });
          }
        });
        return this.enoService.writeEno(enoFactory.makeEno());
      })
    ).subscribe(
      batch => {
        if (options && options.callback) {
          options.callback(batch);
        } else {
          console.log(batch);
        }
      },
      err => {
        if (options && options.callback) {
          options.callback(null, err);
        } else {
          console.error('[en-console] Failed to patch eno:', tip, err);
        }
      }
    );
  }

  startProcess(
    processTip: Tip,
    vars: { [key: string]: number | string | Array<number | string> },
    callback?: (processResponse: IProcessResponse, err?: Error) => void
  ) {
    const processVars = {};
    Object.keys(vars).forEach(key => {
      const values = (vars[key].constructor === Array ? vars[key] : [ vars[key] ]) as any[];
      processVars[key] = values.map(value => '' + value);
    });
    this.processService.start(processTip, processVars).pipe(first()).subscribe(
      processResponse => {
        if (callback) {
          callback(processResponse);
        } else {
          console.log(processResponse);
        }
      },
      err => {
        if (callback) {
          callback(null, err);
        } else {
          console.error('[en-console] Failed to start process:', processTip, vars, err);
        }
      }
    );
  }

  addUpdateStageNode(workflowObj: any): Observable<{ workflowObj: any, unchanged: boolean }> {
    if (workflowObj.nodes.some(node => node.processNodeType === PROCESS_NODE_TYPE.UPDATE_STAGE)) {
      // tslint:disable-next-line:max-line-length
      console.warn(`Workflow ${ workflowObj.$tip } ( ${ workflowObj.name }) already has Update Object Stage node, possibly already migrated, ignoring...`);
      return of({ workflowObj, unchanged: true });
    }

    const workflowUXNodesWithStage =
      workflowObj.nodes.filter(node => node.processNodeType === PROCESS_NODE_TYPE.WORKFLOW_UX && !!get(node, 'fields.stage', false));

    if (workflowUXNodesWithStage.length === 0) {
      // tslint:disable-next-line:max-line-length
      console.warn(`Workflow ${ workflowObj.$tip } ( ${ workflowObj.name }) doesn't have Workflow UX node with stage, ignoring...`);
      return of({ workflowObj, unchanged: true });
    }

    console.warn(`Adding Update Object Stage node(s) to workflow ${ workflowObj.$tip } ( ${ workflowObj.name })`);

    const diagramData: IDiagram = workflowObj.diagramData;
    let currentKey = Math.max(...workflowObj.nodes.map(node => parseInt(node.tip, 10)));

    const stageTips: string[] = uniq(workflowUXNodesWithStage.map(node => node.fields.stage));
    const titleFormula = `CONTEXT(VAR("Stage tips"), TITLE())`;
    
    return this.formulaService
               .evaluate(titleFormula, undefined, undefined, { 'Stage tips': stageTips })
               .pipe(
                 map(stageTitles => {
                   const stages: { [key: string]: string } = zipObject(stageTips, stageTitles);
                   
                   workflowUXNodesWithStage.forEach(node => {
                     const stage = node.fields.stage;
                     if (!stage) {
                       return;
                     }

                     const formula = node.fields.contextObjectFormula;
                     const objectType = node.fields.contextObjectType;
                     const objectTypeSelectedKey = node.fields.contextObjectSelectedPropertyKey;

                     const nodeEditorTemplate = {
                       processNodeType: 'UpdateStage',
                       heading: 'Update object stage',
                       fields: {
                         title: `Change stage to ${ stages[stage] }`,
                         formula,
                         objectType,
                         objectTypeSelectedKey,
                         stage
                       }
                     } as IUpdateStageEditor;

                     const nodeDataTemplate = {
                       portConfigs: [
                         { name: 'TopMiddle', direction: 3 },
                         { name: 'LeftMiddle', direction: 3 },
                         { name: 'RightMiddle', direction: 3 },
                         { name: 'BottomMiddle', direction: 3 },
                         { name: 'TopLeft', direction: 3 },
                         { name: 'TopRight', direction: 3 },
                         { name: 'BottomLeft', direction: 3 },
                         { name: 'BottomRight', direction: 3 },
                       ],
                       text: `Change stage to ${ stages[stage] }`,
                       heading: 'Update object stage',
                       type: 'StepNode',
                       loc: '0 0',
                       fill: '#12C598',
                       iconCode: '',
                       iconSize: 12,
                       linkTypes: [
                         { name: 'Finished', value: 'done', hasDivider: false },
                         { name: 'Failed', value: 'failed', hasDivider: false },
                         { name: 'Always', value: 'finally', hasDivider: false },
                       ],
                       info: {
                         mainHeading: 'Update object stage',
                         heading: `Change stage to ${ stages[stage] }`,
                         items: []
                       },
                       editorComponentName: 'UpdateStageEditorComponent'
                     } as INodeData;

                     const linkTemplate = {
                       points: [0, 0, 0, 0],
                       text: 'Finished',
                       value: 'done',
                       delaySec: 0
                     };

                     const linkDataArray = diagramData.linkDataArray.filter(link => link.from === node.tip);

                     if (linkDataArray.length === 0) {
                       // if the workflow UX node does not have connections
                       currentKey++;
                       const keyString = currentKey.toString();

                       // add outgoing link
                       diagramData.linkDataArray.push({
                         ...linkTemplate,
                         from: node.tip,
                         to: keyString
                       });

                       // add new nodeData
                       diagramData.nodeDataArray.push({
                         ...nodeDataTemplate,
                         key: keyString
                       });

                       // add new node
                       workflowObj.nodes.push({
                         ...nodeEditorTemplate,
                         tip: keyString
                       });
                     }

                     linkDataArray.forEach(link => {
                       currentKey++;
                       const keyString = currentKey.toString();
                       const targetKey = link.to;

                       // modify incoming link
                       link.to = keyString;
                       link.points = [0, 0, 0, 0];

                       // add outgoing link
                       diagramData.linkDataArray.push({
                         ...linkTemplate,
                         from: keyString,
                         to: targetKey
                       });

                       // add new nodeData
                       diagramData.nodeDataArray.push({
                         ...nodeDataTemplate,
                         key: keyString
                       });

                       // add new node
                       workflowObj.nodes.push({
                         ...nodeEditorTemplate,
                         tip: keyString
                       });

                     });
                     // add isGoverned to Workflow UX node
                     node.fields.isGoverned = true;
                   });

                   return {
                     workflowObj: {
                       ...workflowObj,
                       diagramData,
                       nodes: workflowObj.nodes
                     },
                     unchanged: false
                   };
                 })
               );
  }

  // can supply tips or formula which resolves to workflow tips
  convertWorkflows({ tips, formula, updateWorkflowFunc, dryRun, ignoreUnchangedWorkflow }: {
    tips?: Tip[],
    formula?: Formula,
    updateWorkflowFunc?: (workflowObj: any) => Observable<{ workflowObj: any, unchanged: boolean }>,
    dryRun?: boolean,
    ignoreUnchangedWorkflow?: boolean
  }) {
    if (!tips && !formula) {
      throw new Error('Need either tips or formula!');
    }

    const moduleFormula = `REFERENCES("app/module:workflows", VAR("Workflow tip"))`;
    let counter = 1;
    let total = 0;

    const getTips: () => Observable<Tip[]> = () => {
      if (tips) {
        return of(tips);
      }

      return this.formulaService.evaluate(formula);
    };

    const convertEnoToWorkflow: (workflowEno: Eno) => Observable<{ workflowObj: any, unchanged: boolean }>
      = (workflowEno: Eno) => {
      const workflowData = {
        $tip: workflowEno.tip,
        name: workflowEno.getFieldStringValue('app/workflow:name'),
        description: workflowEno.getFieldStringValue('app/workflow:description'),
        diagramData: workflowEno.getFieldJsonValue('app/workflow:diagramdata'),
        isAdminMode: workflowEno.getFieldBooleanValue('app/workflow:isAdminMode'),
        nodes: workflowEno.getFieldValues('app/workflow:nodes').map(v => JSON.parse(v)),
        inputs: workflowEno.getFieldValues('app/workflow:inputs').map(v => JSON.parse(v)),
        actors: workflowEno.getFieldValues('app/workflow:actors').map(v => JSON.parse(v)),
        variables: workflowEno.getFieldValues('app/workflow:variables').map(v => JSON.parse(v)),
      };

      if (updateWorkflowFunc) {
        return updateWorkflowFunc.call(this, workflowData);
      }

      return of({ workflowObj: workflowData, unchanged: true });
    };

    const saveWorkflow = ({ workflowObj, unchanged }) => this.formulaService.evaluate(
      moduleFormula,
      undefined,
      undefined,
      { 'Workflow tip': [workflowObj.$tip] }
    ).pipe(
      // tslint:disable-next-line:max-line-length
      tap(() => console.warn(`${dryRun ? '[ DRY-RUN ] ' : ''}[ ${ counter++ } of ${ total } ] Converting ${ workflowObj.$tip } ( ${ workflowObj.name })`)),
      switchMap(([moduleTip]) => {
        if (unchanged && ignoreUnchangedWorkflow) {
          console.warn(`Not converting workflow ${ workflowObj.$tip } ( ${ workflowObj.name }) since it is not changed.`);
          return of({});
        }

        const diagram = DiagramUtils.getDiagram(new GoJSLinks());
        diagram.model = new GraphLinksModel(workflowObj.diagramData.nodeDataArray, workflowObj.diagramData.linkDataArray);
        diagram.model.modelData = { nodeEditorDataList: workflowObj.nodes };

        const connectionUtils = new ConnectionUtils(diagram, null);
        const nodeDataForProcess: INodeEditorData[] = connectionUtils.getNodeEditorDataListFromStartNode();

        return this.workflowDesignerService.saveWorkflow(workflowObj, nodeDataForProcess, moduleTip, dryRun);
      }),
      // tslint:disable-next-line:no-console
      tap(convertedWorkflow => dryRun ? console.info(convertedWorkflow) : true),
      first()
    );

    getTips()
      .pipe(
        switchMap(workflowTips => this.enoService.readEnos(workflowTips)),
        first(),
        switchMap(enos => combineLatest(enos.map(eno => convertEnoToWorkflow(eno)))),
        tap(workflows => total = workflows.length),
        switchMap(workflows => concat(...workflows.map(workflow => saveWorkflow(workflow))).pipe(toArray()))
      ).subscribe(
        () => console.warn('Done!')
      );
  }

  get tailLogs(): TailLogs {
    // update session id
    this._tailLogs.setSessionId(this.sessionManagerService.getSessionId());
    // return a const so you get code completion in the console
    return this._tailLogs;
  }

  private condenseEno(eno: Eno): any {
    const output: any = { $tip: eno.tip };
    if (eno.source) {
      output.$type = eno.source.type;
      output.$security = eno.source.security;
      eno.source.field.forEach(field => {
        let key = field.tip;
        if (key.length > eno.source.type.length && key.substr(0, eno.source.type.length) === eno.source.type) {
          key = key.substr(eno.source.type.length + 1, key.length - eno.source.type.length - 1);
        }
        if (field.value) {
          output[key] = (field.value.length > 1 ? field.value : field.value[0]);
        } else if (field.i18n && field.i18n.length > 0) {
          output[key] = (field.i18n[0].value.length > 1 ? field.i18n[0].value : field.i18n[0].value[0]);
        } else if (field.formula && field.formula.length > 0) {
          output[key] = field.formula[0];
        }
      });
    }
    return output;
  }

  // 'getAllModuleConfig' generates a csv of all module config accessible by the user in the current instance
  // A series of tasks are performed in this method to get the module config:
  // 1. Collect all module tips via 'lookup' formula
  // 2. Split module tips into chunks of 50
  // 3. For every module chunk, create observable which executes 'get-module-config' query using the module tips in current chunk
  // 4. Run queries one after the other using rxjs 'concat'
  // 5. Flatten, format, and sort results
  // 6. Pass results onto exportCSVFile method
  getAllModuleConfig(fileTitle = `module-config-${moment().format('LL')}`) {
    const options: IQueryOption = {
      dimensionOptions: [{
        label: 'Tip dimension',
        formula: 'TIP()',
        sortby: [],
        sortdir: ['asc'],
        offset: 0,
        limit: 10000
      }]
    };

    this.formulaService
      .evaluate('LOOKUP(TIP(), ISTYPE("app/module"))')
      .pipe(
        first(),
        map(moduleTips => chunk(moduleTips, 50)),
        map(moduleChunks => moduleChunks
          .map(
            moduleChunk => this.queryService
              .execute1dArray(
                'eim/query/get-module-config',
                Object.assign(options, { vars: { 'Module tips': moduleChunk } })
              )
              .pipe(first())
          )),
        switchMap(moduleConfigQueries$ => concat(...moduleConfigQueries$)
            .pipe(
              toArray(),
              map(moduleConfigResults => flatten(moduleConfigResults))
            )
        ),
        map(results => sortBy(
          flatten(
            results
              .map(result => {
                return result
                  .configTitles
                  .map((_, i) => ({
                    type: result.configType[i] || '-',
                    name: result.configTitles[i] ? result.configTitles[i].replace(/,/g, '') : '-',
                    module: head(result.title) ? head(result.title).replace(/,/g, '') : '-',
                    modifiedDate: result.configModifiedDate[i]
                      ? moment(Number(result.configModifiedDate[i])).format('LL').replace(/,/g, '')
                      : '-'
                  }));
              })
          ),
          ['type', 'module', 'name']
        ))
      )
      .subscribe(results => {
        this.exportCSVFile(fileTitle, results, {
          type: 'Type',
          name: 'Name',
          module: 'Module',
          modifiedDate: 'Last modified date & time'
        });
      });
  }

  unBuildJSON(_string: string): void {
    const result = extractJSONFromBuildJSON(_string);
    const object = JSON.parse(result.replace(/\\\\/gi, `\\`));
    console.log(JSON.stringify(object, null, 4));
    console.log('use: https://json-to-js.com if you need to load in your editor');
  }

  private exportCSVFile(fileTitle, items, headers) {
    if (headers) {
      items.unshift(headers);
    }

    const csvContent = convertToCSV(items);

    const link = this.document.createElement('a');
    const url = URL.createObjectURL(new Blob([ csvContent ], { type: 'text/csv;charset=utf-8;' }));
    link.setAttribute('href', url);
    link.setAttribute('download', fileTitle + '.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }
}
