import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { isEmpty } from 'lodash';
import { IFormSideSheetResponse } from '../../models/form';
import { Tip } from '../../data/models/types';
import { IQueryExtraInfo } from '../../data/query.service';
import { IQueryAndFilterOptions } from '../../query/query-chooser/query-chooser.component';
import { ObjectLaunchService } from '../../formly-noggin/object-launch.service';
import { IObjectLaunchDone, OBJECT_LAUNCH_OPERATION } from '../../formly-noggin/object-launch';
import { SideSheetService } from '../side-sheet.service';
import { SideSheetListModes } from '../side-sheet-list/side-sheet-list.component';
import { tap } from 'rxjs/internal/operators/tap';
import { ObjectBehaviourService } from '../../data/object-behaviour-service/object-behaviour.service';
import { AllObjectTypesService } from '../../data/all-object-types/all-object-type.service';
import { ListCreateSideSheetComponent } from '../../settings/modules/lists/list-create-side-sheet/list-create-side-sheet.component';
import {
  ILoadSideSheetObjectChooserObjectsParams,
  ISideSheetObjectChooserObjectResults,
  SideSheetObjectChooserService
} from './side-sheet-object-chooser.service';
import { catchError, first, map, shareReplay } from 'rxjs/operators';
import { LoadingState } from '../../shared/constants';
import {
  SequenceEditSideSheetComponent
} from '../../settings/modules/sequence/sequence-edit-side-sheet/sequence-edit-side-sheet.component';
import { SentryService } from '../../util/sentry.service';

@Component({
  selector: 'app-side-sheet-object-chooser',
  templateUrl: './side-sheet-object-chooser.component.html',
  styleUrls: ['./side-sheet-object-chooser.component.scss']
})
export class SideSheetObjectChooserComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject();

  title: string;

  listType: SideSheetListModes;

  // used to show the module name when typeTip is a module config item. For example : 'app/list'
  showModuleInfo = false;

  typeTips: Tip[];

  extraFilters: IQueryExtraInfo[] = [];

  selected: Tip[] = [];

  queryAndFilter: IQueryAndFilterOptions | {} = {};

  filterText: '';

  moduleTip: Tip;

  done: (response: IFormSideSheetResponse) => void;

  goBack: () => void; // Called when the user pressed the back arrow

  allowNavAway = true;

  canCreateNew = true;

  queryContainer: Observable<ISideSheetObjectChooserObjectResults[]>[] = [];

  loadingState: LoadingState;
  limit = 50;
  offset = 0;
  showMoreButton = false;
  showError = false;
  searchValue: string;
  excludeList: Tip[] = []; // use if you dont want to show any specific tips

  hasQueryResults$: Observable<boolean> = combineLatest(this.queryContainer).pipe(
    map((results: any) => results.reduce((totalLength, result) => totalLength + result.length, 0) !== 0)
  );

  constructor(
    private sideSheetService: SideSheetService,
    private objectLaunch: ObjectLaunchService,
    private objectBehaviourService: ObjectBehaviourService,
    private objectTypeService: AllObjectTypesService,
    private sideSheetObjectChooserService: SideSheetObjectChooserService,
    private cdr: ChangeDetectorRef,
    private sentryService: SentryService
  ) { }

  ngOnInit(): void {
    this.loadData({ initialLoad: true });
    this.showLinksToObjects();
  }

  private loadData({ initialLoad }: { initialLoad?: boolean }) {
    this.loadingState = LoadingState.inProgress;

    const queryParams: ILoadSideSheetObjectChooserObjectsParams = {
      typeTips: this.typeTips,
      extraFilters: this.extraFilters,
      queryAndFilter: this.queryAndFilter,
      showModuleInfo: this.showModuleInfo,
      limit: this.limit + 1,
      offset: this.offset
    };

    const query$: Observable<ISideSheetObjectChooserObjectResults[]> = this.sideSheetObjectChooserService
      .loadObjects(queryParams, this.searchValue)
      .pipe(
        map((queryResults: ISideSheetObjectChooserObjectResults[]) => {
          return queryResults.filter((queryResult) => {
            if (this.excludeList) {
              return this.excludeList.indexOf(queryResult.$tip) === -1;
            }
            return true;
          });
        }),
        tap((queryResults: ISideSheetObjectChooserObjectResults[]) => {
          if (initialLoad && isEmpty(queryResults) && this.canCreateNew) {
            this.loadingState = LoadingState.loaded;
            this.addObject();
          } else {
            this.offset += this.limit;
            this.showMoreButton = !(queryResults.length <= this.limit);
            this.loadingState = LoadingState.loaded;
          }
        }),
        shareReplay(1),
        catchError(err => {
          this.showError = true;
          this.loadingState = LoadingState.failed;
          return of(err);
        })
      );
    this.queryContainer.push(query$);
    this.cdr.detectChanges();
  }

  showLinksToObjects() {
    if (!this.allowNavAway) { return; }

    if (!isEmpty(this.typeTips)) {
      this.objectTypeService
        .getObjectType$(this.typeTips[0])
        .subscribe(value => this.allowNavAway = !!value);
    }
  }

  onCancel() {
    this.goBack
      ? this.goBack()
      : this.sideSheetService.pop();
  }

  get isSingleSelect(): boolean {
    return this.listType === SideSheetListModes.SINGLE_SELECT;
  }

  selectedChange() {
    if (this.isSingleSelect) {
      this.onDone();
    }
  }

  onDone(): void {
    this.sideSheetService.pop();
    this.done({
      selected: this.selected
    } as IFormSideSheetResponse);
  }

  addObject() {
    const params = {
      typeTip: this.typeTips[0]
    };

    if (params.typeTip === 'app/list') {
      const { componentInstance }: { componentInstance: ListCreateSideSheetComponent } = this.sideSheetService
        .push(ListCreateSideSheetComponent);
      componentInstance.moduleTip = this.moduleTip;
      componentInstance.done = () => this.loadData({ initialLoad: false });
      return;
    } else if (params.typeTip === 'app/sequence') {
      const { componentInstance }: { componentInstance: SequenceEditSideSheetComponent } = this.sideSheetService
        .push(SequenceEditSideSheetComponent);
      componentInstance.moduleTip = this.moduleTip;
      componentInstance.done = () => this.loadData({ initialLoad: false });
      return;
    }

    this.objectLaunch.pushSheet(params, this.unsubscribe$)
      .subscribe(({ objectTip }: IObjectLaunchDone) => {
        this.selected = [...this.selected, objectTip];
        this.objectLaunch.pop();
      });
  }

  onShowMore() {
    this.loadData({ initialLoad: false });
  }

  loadSearchData(searchValue: string) {
    this.offset = 0;
    this.queryContainer = [];
    this.searchValue = searchValue;
    this.loadData({ initialLoad: false });
  }

  itemSelectedChange($event) {
    if (!this.selected || this.listType === SideSheetListModes.SINGLE_SELECT) {
      this.selectedChange();
    }
  }

  onOpenObject(object: object): void {
    const typeTip: Tip = this.typeTips ? this.typeTips[0] : null;
    const objectTip: Tip = this.getObjectTipValue(object);

    if (typeTip && objectTip) {
      this.objectBehaviourService
        .resolveObjectBehaviour(objectTip, typeTip)
        .pipe(first())
        .subscribe(value => {
          value && value.url
            ? open(value.url, this.sentryService.onNativeApp)
            : this.launchObject(objectTip);
        });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private launchObject(objectTip: Tip): void {
    this.objectLaunch
      .pushSheet({ objectTip, operation: OBJECT_LAUNCH_OPERATION.VIEW }, this.unsubscribe$)
      .subscribe(
        ({ deleted }: IObjectLaunchDone) => {
          if (deleted) {
            this.selected = this.selected.filter(tip => tip !== objectTip);
            this.loadData({ initialLoad: false });
          }
        }
      );
  }

  private getObjectTipValue(object: { $tip?: string, Tip?: string}): Tip | null {
    return object.$tip || object.Tip || null;
  }
}

function open(link: string, onNativeApp: boolean) {
  const newWindow = window.open(link, onNativeApp ? '_self' : '_blank');

  newWindow.opener = null;
}
