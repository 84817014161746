import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-object-type-chooser',
  template: `
    <app-type-chooser
        [label]="to.label"
        [placeholder]="to.placeholder"
        [required]="to.required"
        [includeSuperTypes]="to.includeSuperTypes"
        [includeSystemTypes]="to.includeSystemTypes"
        [formControl]="formControl"></app-type-chooser>
  `
})
export class FormlyFieldObjectTypeChooserComponent extends FieldType {}
