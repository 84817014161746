import * as tslib_1 from "tslib";
import { NodeConverterBase } from './NodeConverterBase';
import dataConstants from '../../../data/constants';
import * as i0 from "@angular/core";
var EndNodeConverterService = /** @class */ (function (_super) {
    tslib_1.__extends(EndNodeConverterService, _super);
    function EndNodeConverterService() {
        var _this = _super.call(this) || this;
        _this.type = 'processnode/no-op';
        _this.processNodeType = 'processnodetype/no-op';
        return _this;
    }
    EndNodeConverterService.prototype.convert = function (nodeData) {
        return {
            $type: this.type,
            $security: dataConstants.SECURITY.INSTANCE_USER_ADMIN,
            type: this.processNodeType,
            connections: [],
            point: [0, 0],
            key: nodeData.tip
        };
    };
    EndNodeConverterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EndNodeConverterService_Factory() { return new EndNodeConverterService(); }, token: EndNodeConverterService, providedIn: "root" });
    return EndNodeConverterService;
}(NodeConverterBase));
export { EndNodeConverterService };
