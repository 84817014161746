import { IForm } from '../../settings/form-designer/models/form';
import { IType } from '../../models/type';
import { IObjectType } from '../../models/object-type';
import { getTimezonesAsOptionList } from '../../settings/form-designer/services-field-properties/get-timezones-as-option-list';

const timezoneField = ({ fieldTip, label, searchBox, options }) => ({
  type: 'option-list',
  templateOptions: {
    contextTypeTip: fieldTip,
    label,
    searchBox,
    options: options
  },
});

export const localeSettingsCreateForm: IForm = {
  name: 'Locale settings form',
  description: 'A form for setting the timezone',
  size: 'regular',
  version: 1,
  contextType: {
    type: {
      $tip: 'app/type/locale',
      label: 'Locale settings' // title for form
    } as IType
  } as IObjectType,
  tabs: [
    {
      tabName: 'tab1',
      rows: [
        {
          fields: [
            timezoneField({
              fieldTip: 'app/type/locale:timezone',
              label: 'Change timezone',
              searchBox: true,
              options: getTimezonesAsOptionList(false)
            }),
          ]
        }
      ]
    }
  ]
};
