import { ParamMap } from '@angular/router';

import { DataTypes, IObjectScheme } from '../data/models/scheme';
import { IObject } from '../data/models/object';
import { Tip } from '../data/models/types';
import { Observable } from 'rxjs';

export interface IWidget {
  type: string;
  size: number;
  heightPx?: number;
  config: any;
}

export interface IRow {
  widgets: IWidget[];
}

export interface ITab {
  label: string;
  rows: IRow[];
}

export interface IDashboard extends IObject {
  title: string;
  contextTypeTip?: Tip;
  seePolicy: string[];
  tabs: ITab[];
  workflowPanel?: Tip;
  leftPanel: IRow;
  type?: DashBoardType;
  color?: string;
  titleColor?: string;
  backgroundTip?: string;
  showNavigation?: boolean;
}

export const dashboardScheme: IObjectScheme = {
  'app/dashboard:title': { type: DataTypes.i18n },
  'app/dashboard:context-type': { name: 'contextTypeTip', type: DataTypes.string },
  'app/dashboard:tabs': { type: DataTypes.jsonArray },
  'app/dashboard:workflow-panel': { name: 'workflowPanel', type: DataTypes.string },
  'app/dashboard:type': { type: DataTypes.string },
  'app/dashboard:color': { type: DataTypes.string },
  'app/dashboard:title-color': { type: DataTypes.string },
  'app/dashboard:background': { name: 'backgroundTip', type: DataTypes.string },
  'app/dashboard:show-navigation': { type: DataTypes.boolean }
};

export interface IWidgetComponent {
  widget: IWidget;
  contextTip: string;
  queryParams?: ParamMap;
  canUpdate$?: Observable<boolean>;
  canUpdateOrCollaborate$?: Observable<boolean>;
}

export interface IWidgetEditorComponent {
  widget: IWidget;
  dashboard: IDashboard;
  done: () => void;
}

export interface IWidgetEditorMap {
  [key: string]: {
    name: string;
    description: string;
    component: any;
    placeholderComponent?: any;
    allowHeightAdjust?: boolean;
    allowedToAdd?: (dashboard: IDashboard) => void;
    defaultHeight?: number;
  };
}

export enum DashBoardType {
  LAUNCHPAD = 'Launchpad',
  STANDARD = 'Standard'
}

export const sizeClassList: { [key: number]: string[] } = {
  1: ['col-12', 'col-md-4', 'col-lg-1'],
  2: ['col-12', 'col-md-4', 'col-lg-2'],
  3: ['col-12', 'col-md-6', 'col-lg-3'],
  4: ['col-12', 'col-md-12', 'col-lg-4'],
  5: ['col-12', 'col-md-12', 'col-lg-5'],
  6: ['col-12', 'col-md-12', 'col-lg-6'],
  7: ['col-12', 'col-md-12', 'col-lg-7'],
  8: ['col-12', 'col-md-12', 'col-lg-8'],
  9: ['col-12', 'col-md-12', 'col-lg-9'],
  10: ['col-12', 'col-md-12', 'col-lg-10'],
  11: ['col-12', 'col-md-12', 'col-lg-11'],
  12: ['col-12']
};
