import { OnInit } from '@angular/core';
import { SideSheetListModes } from '../side-sheet-list/side-sheet-list.component';
import { get } from 'lodash';
var SideSheetOptionListComponent = /** @class */ (function () {
    function SideSheetOptionListComponent(sideSheetService) {
        this.sideSheetService = sideSheetService;
        this.searchBox = false;
        this.filterText = '';
        this.required = true;
        this.hideBack = false;
    }
    SideSheetOptionListComponent.prototype.ngOnInit = function () {
        if (!this.selected) {
            this.selected = [null];
        }
        if (this.selected && !Array.isArray(this.selected)) {
            this.selected = [this.selected];
        }
    };
    Object.defineProperty(SideSheetOptionListComponent.prototype, "isSingleSelect", {
        get: function () {
            return this.listType === SideSheetListModes.SINGLE_SELECT;
        },
        enumerable: true,
        configurable: true
    });
    SideSheetOptionListComponent.prototype.onSelectedChange = function () {
        if (this.isSingleSelect) {
            this.onDone();
        }
    };
    SideSheetOptionListComponent.prototype.onDone = function () {
        this.done({
            selected: this.listType === SideSheetListModes.MULTI_SELECT ? this.selected : get(this.selected, '[0]')
        });
        this.sideSheetService.pop();
    };
    return SideSheetOptionListComponent;
}());
export { SideSheetOptionListComponent };
