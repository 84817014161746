import { Injectable } from '@angular/core';
import { fromPairs } from 'lodash';

import { NOW_VAR_NAME } from '../../../util/current-datetime.service';
import { Stringify } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { IProcessNodeSetVariable } from '../../../models/process';
import { IWorkflow } from '../../../models/workflow';
import { INodeEditorData } from '../workflow-designer-interfaces';
import { createSetVarNode } from './utils/create-node-utils';
import { NodeConverterBase } from './NodeConverterBase';

@Injectable({
  providedIn: 'root'
})
export class StartNodeConverterService extends NodeConverterBase {
  type = 'processnode/reference/setvar';
  processNodeType = 'processnodetype/reference/setvar';

  public convert(nodeData: INodeEditorData, workflow: IWorkflow): IProcessNodeSetVariable {
    const vars = workflow.actors.length > 0 ?
      fromPairs(workflow.actors.map(a => [a.key, Stringify({ name: 'ARRAY', args: [ a.actorTip ] })]))
      : { 'prop-empty': null };

    vars[NOW_VAR_NAME] = 'NOW()';

    return createSetVarNode(vars, nodeData.tip);
  }

  constructor() {
    super();
  }
}
