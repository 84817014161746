import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { get, intersection, isEmpty } from 'lodash';
import { EXTENDED_FIELD_ACTUAL_TYPE_MAP, EXTENDED_FIELD_DATA_TYPE } from '../../../models/field';
import { checkIfTypeImplementsHierarchicalType } from '../../../util/forms/formly-fields-util';
var FormlyFieldObjectChooserComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FormlyFieldObjectChooserComponent, _super);
    function FormlyFieldObjectChooserComponent(allObjectTypesService) {
        var _this = _super.call(this) || this;
        _this.allObjectTypesService = allObjectTypesService;
        _this.single = true;
        _this.typeTips = [];
        _this.queryAndFilter = {};
        _this.extraFilters = [];
        _this.allowNavAway = true;
        _this.canCreateNew = true;
        _this.allTypes$ = _this.allObjectTypesService.allTypes$.subscribe(function (value) {
            _this.allTypes = value;
        });
        return _this;
    }
    FormlyFieldObjectChooserComponent.prototype.ngOnInit = function () {
        this.contextTypeOptions = get(this, 'formState.contextTypeOptions', null);
        this.setViaContextParams();
    };
    FormlyFieldObjectChooserComponent.prototype.ngOnDestroy = function () {
        this.allTypes$.unsubscribe();
    };
    FormlyFieldObjectChooserComponent.prototype.setViaContextParams = function () {
        var contextTypeTip = get(this, 'field.templateOptions.contextTypeTip', null);
        var contextTypeOption = this.contextTypeOptions
            ? this.contextTypeOptions.values.get(contextTypeTip)
            : null;
        var isMulti = get(this, 'field.templateOptions.multi', false);
        if (isMulti || (contextTypeOption && contextTypeOption.maxcount !== 1)) {
            this.single = false;
            this.viewMode = 'multi';
        }
        this.typeTips = get(contextTypeOption, 'typerestrict', []);
        this.queryAndFilter = get(this, 'field.templateOptions.filter', {});
        this.extraFilters = get(this, 'field.templateOptions.extraFilters', []);
        this.allowNavAway = get(this, 'field.templateOptions.allowNavAway', true);
        this.canCreateNew = !get(this, 'field.templateOptions.hidePlusButton', false);
        var isHierarchical = this.checkIfTypeIsHierarchical(this.typeTips);
        var configuredForHierarchicalView = get(this, 'field.templateOptions.hierarchicalView', 'Flat') === 'Hierarchical';
        if (this.single) {
            if (isEmpty(this.queryAndFilter) && configuredForHierarchicalView && isHierarchical) {
                this.viewMode = 'hierarchical';
                this.hierarchyFieldTip = this.typeTips && this.typeTips.length > 0 ? this.typeTips[0] : null;
                this.hierarchyParentFieldTip = this.hierarchyFieldTip ? this.getParentFieldTip(this.typeTips[0]) : null;
            }
            else {
                this.viewMode = 'single';
            }
        }
    };
    FormlyFieldObjectChooserComponent.prototype.getParentFieldTip = function (fieldTip) {
        if (EXTENDED_FIELD_ACTUAL_TYPE_MAP[EXTENDED_FIELD_DATA_TYPE.hierarchical].typeRestrict.includes(fieldTip)) {
            return fieldTip + ":parent";
        }
        else if (this.allTypes) {
            var typeFromMetaMap = this.allTypes.get(fieldTip);
            if (typeFromMetaMap) {
                var typeImplemented = typeFromMetaMap.implementsSuperTypes;
                if (typeImplemented && typeImplemented.length) {
                    var parentTip = typeImplemented.find(function (type) {
                        return EXTENDED_FIELD_ACTUAL_TYPE_MAP[EXTENDED_FIELD_DATA_TYPE.hierarchical].typeRestrict.includes(type);
                    });
                    if (parentTip) {
                        return parentTip + ":parent";
                    }
                }
            }
            return null;
        }
    };
    FormlyFieldObjectChooserComponent.prototype.checkIfTypeIsHierarchical = function (typeRestricts) {
        if (typeRestricts && typeRestricts.length === 1) {
            var isNogginDefinedHierarchical = intersection(typeRestricts, // check if its a noggin defined hierarchical type
            EXTENDED_FIELD_ACTUAL_TYPE_MAP[EXTENDED_FIELD_DATA_TYPE.hierarchical].typeRestrict).length > 0;
            if (isNogginDefinedHierarchical) {
                return true;
            }
            else if (this.allTypes) {
                var typeRestrict = typeRestricts[0]; // just get the first element
                var typeFromMetaMap = this.allTypes.get(typeRestrict);
                if (typeFromMetaMap) {
                    return checkIfTypeImplementsHierarchicalType(typeFromMetaMap);
                }
            }
        }
        return false;
    };
    return FormlyFieldObjectChooserComponent;
}(FieldType));
export { FormlyFieldObjectChooserComponent };
