import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { get } from 'lodash';
// @todo updateOnBlur may need to be passed here such that calculation for dependent fields would only happen when a text field is blurred.
// A problem with doing so is that we have to change the side sheet primary button disabled condition to use touched instead of pristine.
var FormlyFieldInputComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FormlyFieldInputComponent, _super);
    function FormlyFieldInputComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.classes = 'flex-fill';
        return _this;
    }
    FormlyFieldInputComponent.prototype.ngOnInit = function () {
        var fieldTip = get(this, 'field.templateOptions.contextTypeTip', null);
        var fieldOptions = get(this, 'formState.contextTypeOptions', null);
        this.fieldOption = fieldTip && fieldOptions ? fieldOptions.values.get(fieldTip) : null;
        this.numberFormat = get(this.fieldOption, 'numberFormat', null);
    };
    FormlyFieldInputComponent.prototype.calculate = function () {
        this.formState.calculation$.next({
            fieldTip: this.field.key,
            calculationFormula: this.fieldOption.calculation,
            calculationNoValueAsZero: !!this.fieldOption.calculationNoValueAsZero
        });
    };
    return FormlyFieldInputComponent;
}(FieldType));
export { FormlyFieldInputComponent };
