import { Injectable } from '@angular/core';

import { NodeConverterBase } from './NodeConverterBase';
import { IDeleteObjectEditor } from '../node-editors/delete-object-editor/delete-object-editor.component';
import { IProcessNodeDelete } from '../../../models/process';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import dataConstants from '../../../data/constants';

@Injectable({
  providedIn: 'root'
})
export class DeleteNodeConverterService extends NodeConverterBase {
  type = 'processnode/data/delete';
  processNodeType = 'processnodetype/data/delete';

  constructor() {
    super();
  }

  convert(nodeData: IDeleteObjectEditor): IProcessNodeDelete {
    if (!this.isValid(nodeData)) {
      throw new InvalidWorkflowNodeDataError(`Please correct invalid data on delete node [${nodeData.fields.title}]`, nodeData);
    }

    return {
      $type: this.type,
      $security: dataConstants.SECURITY.INSTANCE_USER_ADMIN,
      type: this.processNodeType,
      connections: [],
      point: [0, 0],
      key: nodeData.tip,
      deleteTip: nodeData.fields.objects.map(x => x.formula),
      deleteSrcBranch: dataConstants.BRANCH_MASTER,
      deleteDestBranch: dataConstants.BRANCH_MASTER,
      deleteSecurity: 'app/security-policy/instance-user-user' // @toDo implement proper security
    };
  }

  private isValid(nodeData: IDeleteObjectEditor): boolean {
    return !(nodeData.fields.objects.length === 0);
  }
}
