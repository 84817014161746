/**
 * Returns a deterministic JSON string of the given object. The object is compacted as it goes to ensure that the
 * absence of a properties (for example) is the same as null or an empty array.
 */

const sortArrays = {
  field: comparisonFunctionFactory('tip'),
  i18n: comparisonFunctionFactory('lang'),
  parent: undefined // Unicode sort
};

function comparisonFunctionFactory(key) {
  return function (a, b) {
    if (typeof a === 'object' && typeof b === 'object' && a[key] !== undefined && b[key] !== undefined) {
      return (a[key] < b[key] ? -1 : (a[key] > b[key] ? 1 : 0));
    }

    return 0;
  };
}

function RFCJSONEncode(obj) {
  const json = JSON.stringify(obj);

  return json.replace(/[\u007F-\uFFFF]/g, function (chr) {
    return '\\u' + ('0000' + chr.charCodeAt(0).toString(16)).substr(-4);
  });
}

export function sidStringify(obj) {
  if (obj === undefined || obj === null) {
    return 'null';
  }

  if (obj instanceof Array) {
    return '[' + obj.map(sidStringify) + ']';
  }

  if (typeof obj === 'object') {
    return '{' + Object.keys(obj).filter(function (key) {
      return !(
        obj[key] === undefined
        || obj[key] === null
        || (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0)
      );
      // return true;
    }).sort().map(function (key) {
      if (obj[key] instanceof Array && sortArrays.hasOwnProperty(key)) {
        return RFCJSONEncode(key) + ':[' + obj[key].slice().sort(sortArrays[key]).map(sidStringify) + ']';
      }

      return RFCJSONEncode(key) + ':' + sidStringify(obj[key]);
    }) + '}';
  }

  return RFCJSONEncode(obj);
}
