import { of } from 'rxjs';
import { take, retry, map, first, switchMap } from 'rxjs/operators';
import { isString } from 'lodash';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./session-manager.service";
import * as i3 from "@angular/platform-browser";
import * as i4 from "./formula.service";
import * as i5 from "@angular/router";
import * as i6 from "./security.service";
import * as i7 from "../shell/services/my-profile.service";
import * as i8 from "../dom-tokens";
var mediaServicePath = environment.host + environment.mediaServicePath;
export var MAX_FILE_UPLOAD_SIZE = 1024 * 1024 * 15; // 15MiB
var MediaService = /** @class */ (function () {
    function MediaService(http, sessionManager, domSanitizer, formulaService, router, securityService, myProfileService, saveAs, window) {
        var _this = this;
        this.http = http;
        this.sessionManager = sessionManager;
        this.domSanitizer = domSanitizer;
        this.formulaService = formulaService;
        this.router = router;
        this.securityService = securityService;
        this.myProfileService = myProfileService;
        this.saveAs = saveAs;
        this.window = window;
        this.previewUriCache = {};
        this.fullSizeImageUriCache = {};
        this.sessionManager.getSessionInfo$().pipe(retry(3)).subscribe(function (sessionInfo) { return _this.sessionInfo = sessionInfo; });
    }
    // Creates and emits on an upload input object for a given object security policy variant
    MediaService.prototype.createUploadInputWithSecurityPolicy = function (primaryObjectTip, primarySecurityPolicyTip, variant, fileSecurityPolicyTip) {
        var _this = this;
        if (variant === void 0) { variant = 'primary'; }
        if (isString(fileSecurityPolicyTip)) {
            return of(this.createUploadInput(fileSecurityPolicyTip));
        }
        // If no primary object or security policy is passed, use the user's profile's default security policy as the primary
        var primarySecurityPolicyTip$ = of(primarySecurityPolicyTip);
        if (!primaryObjectTip && !primarySecurityPolicyTip) {
            primarySecurityPolicyTip$ = this.myProfileService.getMyProfile$().pipe(map(function (profile) { return profile.defaultPolicy; }));
        }
        return primarySecurityPolicyTip$.pipe(switchMap(function (policyTip) {
            // If we actually want the primary then return it
            if (variant === 'primary') {
                return of(policyTip);
            }
            // Otherwise ask the security service for a variant
            return _this.securityService.getSecurityPolicyVariantTip$(variant, primaryObjectTip, policyTip);
        }), map(function (uploadSecurityPolicyTip) { return _this.createUploadInput(uploadSecurityPolicyTip); }));
    };
    // Return an UploadInput object for ngx-uploader when creating new files
    MediaService.prototype.createUploadInput = function (securityPolicyTip) {
        return {
            type: 'uploadAll',
            url: mediaServicePath + '/file?ns=' + escape(environment.ns) + '&security=' + encodeURIComponent(escape(securityPolicyTip)),
            method: 'POST',
            headers: this.getAuthHeaders(),
            withCredentials: true
        };
    };
    // Return an UploadInput object for ngx-uploader when replacing existing files, not used right now.
    // replaceUploadInput(fileTip: string): UploadInput {
    //   return {
    //     type: 'uploadAll',
    //     url: mediaServicePath + '/replace?&ns=' + escape(environment.ns) + '&tip=' + encodeURIComponent(fileTip),
    //     method: 'POST',
    //     headers: this.getAuthHeaders()
    //   };
    // }
    // Retrieves the authorization headers
    MediaService.prototype.getAuthHeaders = function () {
        if (!this.sessionInfo) {
            throw new Error('Attempting to authorize against the media service without a session token');
        }
        return { Authorization: 'Bearer ' + this.sessionInfo.token };
    };
    // Returns information about a file
    MediaService.prototype.fileInfo = function (fileTip) {
        var formulaStr = 'ARRAY(FIELD("file/name"),FIELD("file/mime"),FIELD("file/size"),FIELD("file/data"))';
        return this.formulaService.evaluate(formulaStr, fileTip).pipe(take(1), map(function (fileData) { return ({
            name: fileData[0],
            mimeType: fileData[1],
            size: parseInt(fileData[2], 10),
            data: fileData[3]
        }); }));
    };
    // Downloads a file
    MediaService.prototype.download = function (fileTip, name) {
        var _this = this;
        return this.http.get(mediaServicePath + '/download?tip=' + encodeURIComponent(fileTip) + '&ns=' + encodeURIComponent(environment.ns), {
            headers: this.getAuthHeaders(),
            responseType: 'blob',
            withCredentials: true
        }).pipe(take(1)).subscribe(function (blob) { return _this.saveAs(blob, name); });
    };
    // Preview the file using a lightbox
    MediaService.prototype.preview = function (fileTip, name) {
        var path = ['preview', fileTip];
        if (name) {
            path.push(name);
        }
        this.router.navigate(['app', 's', { outlets: { preview: path } }]);
    };
    // Returns the original URI (not a blob URI)
    MediaService.prototype.originalPreviewUri = function (fileTip, thumbnailWidth) {
        var url = mediaServicePath + '/preview?tip=' + encodeURIComponent(fileTip) + '&ns=' + encodeURIComponent(environment.ns);
        if (thumbnailWidth && thumbnailWidth > 0) {
            return (url + '&w=' + thumbnailWidth);
        }
        else {
            return url;
        }
    };
    // Returns the URI for preview of a given the file
    MediaService.prototype.previewUri = function (fileTip, isBackground, thumbnailWidth) {
        if (isBackground === void 0) { isBackground = false; }
        if (this.previewUriCache.hasOwnProperty(fileTip)) {
            var blob = this.previewUriCache[fileTip];
            return of(this.getUri(blob, isBackground));
        }
        return this.getImagebyTip(fileTip, isBackground, false, thumbnailWidth);
    };
    /*
    * use this method to show full size images
     */
    MediaService.prototype.previewUriForFullImage = function (fileTip, isBackground) {
        if (isBackground === void 0) { isBackground = false; }
        if (this.fullSizeImageUriCache.hasOwnProperty(fileTip)) {
            var blob = this.fullSizeImageUriCache[fileTip];
            return of(this.getUri(blob, isBackground));
        }
        return this.getImagebyTip(fileTip, isBackground, true, -1); // negative width ensures that this argument would be ignored
    };
    MediaService.prototype.getImagebyTip = function (fileTip, isBackground, fullSize, thumbnailWidth) {
        var _this = this;
        if (isBackground === void 0) { isBackground = false; }
        return this.http.get(this.originalPreviewUri(fileTip, thumbnailWidth), {
            headers: this.getAuthHeaders(),
            responseType: 'blob',
            withCredentials: true
        }).pipe(first(), map(function (blob) {
            fullSize === true ? _this.fullSizeImageUriCache[fileTip] = blob : _this.previewUriCache[fileTip] = blob;
            return _this.getUri(blob, isBackground);
        }));
    };
    MediaService.prototype.getUri = function (blob, isBackground) {
        return isBackground ?
            this.domSanitizer.bypassSecurityTrustStyle("url('" + this.window.URL.createObjectURL(blob) + "')") :
            this.domSanitizer.bypassSecurityTrustUrl(this.window.URL.createObjectURL(blob));
    };
    MediaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MediaService_Factory() { return new MediaService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.SessionManagerService), i0.ɵɵinject(i3.DomSanitizer), i0.ɵɵinject(i4.FormulaService), i0.ɵɵinject(i5.Router), i0.ɵɵinject(i6.SecurityService), i0.ɵɵinject(i7.MyProfileService), i0.ɵɵinject(i8.SAVE_AS), i0.ɵɵinject(i8.WINDOW)); }, token: MediaService, providedIn: "root" });
    return MediaService;
}());
export { MediaService };
