import * as tslib_1 from "tslib";
import { CompositeNodeConverter } from './CompositeNodeConverter';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import { Parser, Stringify } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { isEmpty } from 'lodash';
import { createConnection, createFormulaFromStringInterpolation, createSetVarNode, createSubProcessNode, createSwitchNode } from './utils/create-node-utils';
import * as i0 from "@angular/core";
var SendNotificationConverterService = /** @class */ (function (_super) {
    tslib_1.__extends(SendNotificationConverterService, _super);
    function SendNotificationConverterService() {
        var _this = _super.call(this) || this;
        _this.type = 'processnode/logic/subprocess';
        _this.processNodeType = 'processnodetype/logic/subprocess';
        return _this;
    }
    SendNotificationConverterService.prototype.convert = function (nodeData) {
        if (!this.isValid(nodeData)) {
            // tslint:disable-next-line:max-line-length
            throw new InvalidWorkflowNodeDataError("Please correct invalid data on send notification node [" + nodeData.fields.title + "]", nodeData);
        }
        var vars = {
            'Recipients to': nodeData.fields.recipients.to && nodeData.fields.recipients.to.length > 0 ?
                Stringify({ name: 'ARRAY', args: nodeData.fields.recipients.to }) : 'ARRAY()',
            'Recipients to formulas': nodeData.fields.recipients.toFormulas && nodeData.fields.recipients.toFormulas.length > 0 ?
                Stringify({ name: 'ARRAY', args: nodeData.fields.recipients.toFormulas.map(function (item) { return Parser(item); }) }) : 'ARRAY()',
            //  'Notification title': createFormulaFromStringInterpolation(nodeData.fields.notificationTitle),  // uncomment this when relevant
            'Notification title': 'Workflow Notification',
            'Notification body': createFormulaFromStringInterpolation(nodeData.fields.notificationBody),
            'Notification category': nodeData.fields.notificationCategory,
            'Notification context': nodeData.fields.notificationContext.formula
        };
        // create the process nodes
        var setVarNode = createSetVarNode(vars, nodeData.tip);
        var subProcessNode = createSubProcessNode('eim/process/notification/preprocess-notifications', vars);
        var switchNode = createSwitchNode({ name: 'VAR', args: ['Status'] });
        // link from setvar to sub-process
        var doneConnectionForSetVarNode = createConnection('done', subProcessNode);
        setVarNode.connections.push(doneConnectionForSetVarNode);
        // link from sub-process to switch
        var doneConnectionForSubprocessNode = createConnection('done', switchNode);
        subProcessNode.connections.push(doneConnectionForSubprocessNode);
        // make the final collection of all nodes and links
        var nodesAndLink = {
            nodes: [
                setVarNode,
                subProcessNode,
                switchNode
            ],
            links: [
                doneConnectionForSetVarNode,
                doneConnectionForSubprocessNode
            ]
        };
        this.populateOutgoingLinks(nodeData).forEach(function (link) {
            switchNode.connections.push(link);
            nodesAndLink.links.push(link);
        });
        return nodesAndLink;
    };
    SendNotificationConverterService.prototype.isValid = function (nodeData) {
        return !isEmpty(nodeData.fields.title)
            && !isEmpty(nodeData.fields.recipients)
            && !isEmpty(nodeData.fields.notificationCategory);
    };
    SendNotificationConverterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SendNotificationConverterService_Factory() { return new SendNotificationConverterService(); }, token: SendNotificationConverterService, providedIn: "root" });
    return SendNotificationConverterService;
}(CompositeNodeConverter));
export { SendNotificationConverterService };
