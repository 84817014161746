import { Formula, Tip } from '../../../../data/models/types';
import {
  IProcessConnection,
  IProcessNode, IProcessNodeForeach, IProcessNodeNoOp,
  IProcessNodeSetVariable,
  IProcessNodeSubprocess,
  IProcessNodeSwitch, IProcessNodeUpdateEno
} from '../../../../models/process';
import dataConstants from '../../../../data/constants';
import { FormulaSpec, Parser, Stringify } from '../../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { ISubstitutionMeta } from '../../../../form/string-interpolation/string-interpolation.service';
import { isFormulaSpec } from '../../../../util/is-formula-spec';

export function createNoOpNode(key: string = null): IProcessNodeNoOp {
  return {
    $type: 'processnode/no-op',
    $security: dataConstants.SECURITY.INSTANCE_USER_ADMIN,
    type: 'processnodetype/no-op',
    connections: [],
    key,
    point: [0, 0],
  };
}

export function createSetVarNode(vars: {[key: string]: Formula}, key: string = null): IProcessNodeSetVariable {
  const setVarKey = Object.keys(vars);
  const setVarValue = setVarKey.map((k: string) => vars[k]);

  return {
    $type: 'processnode/reference/setvar',
    $security: dataConstants.SECURITY.INSTANCE_USER_ADMIN,
    type: 'processnodetype/reference/setvar',
    connections: [],
    point: [0, 0],
    key,
    setVarKey,
    setVarValue,
    setVarScope: 'Global'
  };
}

export function createSubProcessNode(
  process: string,
  vars: { [key: string]: Formula } | string[],
  timeoutMs?: number,
  subVars: { [key: string]: Formula } | string[] = [],
  outputSubVars: { [key: string]: Formula } | string[] = [],
  outputVars: { [key: string]: Formula } | string[] = [],
): IProcessNodeSubprocess {
  return {
    $type: 'processnode/logic/subprocess',
    $security: dataConstants.SECURITY.INSTANCE_USER_ADMIN,
    type: 'processnodetype/logic/subprocess',
    connections: [],
    point: [0, 0],
    key: null,
    process,
    varKeys: vars instanceof Array ? vars : Object.keys(vars),
    subVarKeys: subVars instanceof Array ? subVars : Object.keys(subVars),
    outputSubVarKeys: outputSubVars instanceof Array ? outputSubVars : Object.keys(outputSubVars),
    outputVarKeys: outputVars instanceof Array ? outputVars : Object.keys(outputVars),
    outputVarScope: 'Global',
    ...(timeoutMs || timeoutMs === 0 ? { timeoutMs } : []),
    waitForProcessComplete: true
  };
}

export function createSwitchNode(
  expression: FormulaSpec,
  extraCases: string[] = [],
  switchDefault = 'default',
  switchCases = ['done', 'failed']
): IProcessNodeSwitch {
  return {
    $type: 'processnode/logic/switch',
    $security: dataConstants.SECURITY.INSTANCE_USER_ADMIN,
    type: 'processnodetype/logic/switch',
    connections: [],
    point: [0, 0],
    key: null,
    switchExpr: Stringify(expression),
    switchDefault,
    switchCases: [...switchCases, ...extraCases]
  };
}

export function createForEachNode(
  source: FormulaSpec | Formula,
  currentElement: string,
  key: string = null,
): IProcessNodeForeach {
  return {
    $type: 'processnode/logic/foreach',
    $security: dataConstants.SECURITY.INSTANCE_USER_ADMIN,
    type: 'processnodetype/logic/foreach',
    connections: [],
    point: [0, 0],
    key,
    source: isFormulaSpec(source) ? Stringify(source) : source,
    currentElement
  };
}

export function createUpdateEnoNode(updateEnoObject: FormulaSpec | Formula, updateEnoSecurity: Tip): IProcessNodeUpdateEno {
  return {
    $type: 'processnode/data/update-eno',
    $security: dataConstants.SECURITY.INSTANCE_USER_ADMIN,
    type: 'processnodetype/data/update-eno',
    connections: [],
    point: [0, 0],
    key: null,
    updateEnoObject: isFormulaSpec(updateEnoObject) ? Stringify(updateEnoObject) : updateEnoObject,
    updateEnoSecurity
  };
}

export function createConnection(outcome: string, toNode: IProcessNode): IProcessConnection {
  return {
    $type: 'processconnection',
    $security: dataConstants.SECURITY.INSTANCE_USER_ADMIN,
    outcome,
    toNodes: [toNode],
    point: [0, 0],
    delaySec: 0
  };
}

export function createFormulaFromStringInterpolation(interpolation: ISubstitutionMeta) {
  return Stringify({
    name: 'REPLACE',
    args: [
      interpolation.html,
      {
        name: 'ARRAY',
        args: interpolation.substitutions.map(s => s.key)
      },
      {
        name: 'ARRAY',
        args: interpolation.substitutions.map(s => Parser(s.value))
      }
    ]
  });
}

