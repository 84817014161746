import { throwError, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { profileScheme } from '../../models/profile';
import { QueryService } from '../../data/query.service';
import * as i0 from "@angular/core";
import * as i1 from "../../data/query.service";
import * as i2 from "../../data/formula.service";
import * as i3 from "../../data/object-service/object.service";
import * as i4 from "../../data/process.service";
import * as i5 from "../../data/session-manager.service";
import * as i6 from "../../util/location.service";
import * as i7 from "./me.service";
export var SwitchProfileResultStatus;
(function (SwitchProfileResultStatus) {
    SwitchProfileResultStatus[SwitchProfileResultStatus["SUCCESS"] = 0] = "SUCCESS";
    SwitchProfileResultStatus[SwitchProfileResultStatus["FAILED"] = 1] = "FAILED";
    SwitchProfileResultStatus[SwitchProfileResultStatus["ALREADY_SAME_PROFILE"] = 2] = "ALREADY_SAME_PROFILE";
})(SwitchProfileResultStatus || (SwitchProfileResultStatus = {}));
var varStatusToResultStatusMap = {
    success: SwitchProfileResultStatus.SUCCESS,
    failed: SwitchProfileResultStatus.FAILED,
    'already same profile': SwitchProfileResultStatus.ALREADY_SAME_PROFILE
};
var MyProfileService = /** @class */ (function () {
    function MyProfileService(queryService, formulaService, objectService, processService, sessionManagerService, locationService, meService) {
        this.queryService = queryService;
        this.formulaService = formulaService;
        this.objectService = objectService;
        this.processService = processService;
        this.sessionManagerService = sessionManagerService;
        this.locationService = locationService;
        this.meService = meService;
    }
    Object.defineProperty(MyProfileService.prototype, "myProfile$", {
        // @deprecated.
        get: function () {
            return this.getMyProfile$();
        },
        enumerable: true,
        configurable: true
    });
    MyProfileService.prototype.getMyProfile$ = function () {
        var _this = this;
        return this.sessionManagerService.getSessionInfo$().pipe(switchMap(function (sessionInfo) { return _this.objectService.getObject(sessionInfo.profile, profileScheme); }));
    };
    MyProfileService.prototype.getProfileNumber$ = function () {
        var _this = this;
        return this.sessionManagerService.getSessionInfo$().pipe(switchMap(function (sessionInfo) { return _this.formulaService.evaluate('COUNT(FIELD("app/user:profiles"))', sessionInfo.user); }), map(function (result) { return parseInt(result[0], 10); }));
    };
    MyProfileService.prototype.getMyProfiles$ = function () {
        var _this = this;
        return this.sessionManagerService.getSessionInfo$().pipe(switchMap(function (sessionInfo) {
            return _this.queryService.execute1dArray('eim/query/generic-query', {
                extraAttributes: [
                    { label: 'name', formula: 'FIELD("app/profile:name")' }
                ],
                extraFilters: [
                    { label: 'Query type filter', formula: 'ISTYPE("app/profile")' },
                    {
                        label: 'Belongs to the user',
                        formula: "INARRAY(FIELD(\"app/user:profiles\", VAR(\"User tip\")), TIP())"
                    }
                ],
                vars: {
                    'User tip': [sessionInfo.user]
                },
                dimensionOptions: [{ label: 'Tip dimension', formula: 'TIP()', sortby: ['FIELD("app/profile:name")'] }]
            });
        }), map(function (result) {
            return result.map(function (r) { return ({ $tip: r.$tip[0], name: r.name[0] }); });
        }));
    };
    MyProfileService.prototype.switchProfile = function (profileTip, userTip) {
        var _this = this;
        return this.processService.start('eim/process/auth/switch-profile', {
            'Profile tip': [profileTip],
            'User tip': [userTip]
        }).pipe(first(function (processResponse) {
            return processResponse.finished;
        }), switchMap(function (processResponse) {
            var returnVars = processResponse.vars;
            if (returnVars && returnVars['Status'] && returnVars['JWT token']) {
                var resultStatus = varStatusToResultStatusMap[returnVars['Status'][0]];
                _this.sessionManagerService.updateSessionInfo(returnVars['JWT token'][0]);
                _this.meService.getMe$().pipe(first()).subscribe(function (_) { return _this.sessionManagerService.setLastProfile(_.email, profileTip); });
                _this.locationService.refreshToRoot();
                return of(resultStatus);
            }
            return throwError('Failed to switch profile.');
        }));
    };
    MyProfileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MyProfileService_Factory() { return new MyProfileService(i0.ɵɵinject(i1.QueryService), i0.ɵɵinject(i2.FormulaService), i0.ɵɵinject(i3.ObjectService), i0.ɵɵinject(i4.ProcessService), i0.ɵɵinject(i5.SessionManagerService), i0.ɵɵinject(i6.LocationService), i0.ɵɵinject(i7.MeService)); }, token: MyProfileService, providedIn: "root" });
    return MyProfileService;
}());
export { MyProfileService };
