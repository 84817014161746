import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService, SignInStatus } from '../auth.service';
import { ActivatedRoute } from '@angular/router';
import { CheckSSOProvidersService } from '../check-ssoproviders.service';
import { debounceTime, distinctUntilChanged, first } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LocationService } from '../../util/location.service';
import { SITE_SELECTOR_PAGE_URL } from '../../shared/constants';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  signInForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', Validators.required)
  });

  get emailValid(): boolean {
    return this.signInForm.get('email').valid;
  }

  @ViewChild('password', {static: false}) password: ElementRef;

  loading = false;
  message: string;
  passwordSignIn = false;
  isNativeApp = false;
  status: SignInStatus = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private checkSSOProvidersService: CheckSSOProvidersService,
    private deviceDetectorService: DeviceDetectorService,
    private locationService: LocationService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // This message shows up when a user clicks a activation link that has already been activated
    const message = this.activatedRoute.snapshot.queryParams.message;
    if (message) {
      this.message = message;
    }

    this
      .signInForm
      .get('email')
      .valueChanges
      .pipe(
        debounceTime(150),
        distinctUntilChanged()
      )
      .subscribe(() => {
        // reset password sign in if email is changed
        this.passwordSignIn = false;
      });
    this.isNativeApp = !this.deviceDetectorService.isDesktop();
  }

  checkEmailAddress() {
    this.loading = true;
    const email = this.signInForm.value.email;

    this.checkSSOProvidersService.checkEmailAddressAndRedirect$(email)
      .pipe(first())
      .subscribe(
        (passwordSignIn: boolean) => {
          this.loading = false;
          this.passwordSignIn = passwordSignIn;
          this.cdr.detectChanges();
          if (passwordSignIn) {
            this.focusPassword();
          }
        },
        err => {
          this.loading = false;
          this.status = SignInStatus.FAILURE;
        }
      );
  }

  focusPassword() {
    this.password.nativeElement.focus();
  }

  onSignIn() {
    this.loading = true;
    this.message = '';
    this.status = null;

    this.authService.signIn(this.signInForm.value.email, this.signInForm.value.password).subscribe(
      (signInStatus: SignInStatus) => {
        // Automatically refreshed with signed-in token in session-manager service due to token change
        this.loading = false;
        if (signInStatus !== SignInStatus.SUCCESS) {
          this.status = signInStatus;
        }
      },
      () => {
        this.loading = false;
        this.status = SignInStatus.FAILURE;
      }
    );
  }

  changeSite() {
    this.locationService.redirect(SITE_SELECTOR_PAGE_URL);
  }
}
