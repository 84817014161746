import { DataTypes, IObjectScheme } from '../data/models/scheme';
import { IObject } from '../data/models/object';
import { Json, Tip } from '../data/models/types';
import { IType, typeScheme } from './type';
import { IObjectStage, objectStageScheme } from './object-stage';

export { ISymbology, IObjectTypeSymbology, IObjectType, objectTypeScheme, IObjectTypeFlat, objectTypeFlatScheme, IObjectTypeEssential };

interface ISymbology {
  label?: string;
  icon?: string;
  color?: string;
}

interface IObjectTypeSymbology {
  default?: ISymbology;
  icon?: {
    listField?: Tip;
    listItemValue?: string;
  };
  color?: {
    listField?: Tip;
    listItemValue?: string;
  };
}

interface IObjectType extends IObject {
  type: IType;
  stages: IObjectStage[];
  defaultDashboard?: Tip;
  defaultForm?: Tip;
  lifecycleWorkflow?: Tip;
  symbology?: IObjectTypeSymbology;
  isUserModifiable?: boolean;
  matchingField?: Tip;
}

const objectTypeScheme: IObjectScheme = {
  'app/object-type:type': {
    type: DataTypes.object,
    mutable: true,
    scheme: typeScheme
  },
  'app/object-type:stages': {
    type: DataTypes.objectArray,
    mutable: true,
    scheme: objectStageScheme
  },
  'app/object-type:default-dashboard': {},
  'app/object-type:default-form': {},
  'app/object-type:lifecycle-workflow': {},
  'app/object-type:symbology': { type: DataTypes.json },
  'app/object-type:is-user-modifiable': { type: DataTypes.boolean },
  'app/object-type:matching-field': {}
};

interface IObjectTypeFlat extends IObject {
  type: IType;
  stages: Tip[];
  defaultDashboard?: Tip;
  defaultForm?: Tip;
  lifecycleWorkflow?: Tip;
  symbology?: IObjectTypeSymbology;
  matchingField?: Tip;
}

const objectTypeFlatScheme: IObjectScheme = {
  'app/object-type:type': {
    type: DataTypes.object,
    mutable: true,
    scheme: typeScheme
  },
  'app/object-type:stages': { type: DataTypes.stringArray },
  'app/object-type:default-dashboard': { type: DataTypes.string },
  'app/object-type:default-form': { type: DataTypes.string },
  'app/object-type:lifecycle-workflow': { type: DataTypes.string },
  'app/object-type:symbology': { type: DataTypes.json },
  'app/object-type:matching-field': { type: DataTypes.string }
};

interface IObjectTypeWithStages extends IObject {
  type: Tip;
  stages: IObjectStage[];
  defaultDashboard?: Tip;
  defaultForm?: Tip;
  matchingField?: Tip;
}

interface IObjectTypeEssential extends IObject {
  type: Tip;
  stages: Tip[];
  defaultDashboard?: Tip;
  defaultForm?: Tip;
  lifecycleWorkflow?: Tip;
  symbology?: Json;
  matchingField?: Tip;
}
