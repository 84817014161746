import { IQueryOption } from './query.service';
import { Tip, Formula } from './models/types';
import { cloneDeep } from 'lodash';

export function getSearchInFieldQueryOptions(options: IQueryOption, searchableFieldTips: Tip[], searchValue: string): IQueryOption {
  const searchInFieldOptions = cloneDeep(options);

  if (!searchInFieldOptions.vars) {
    searchInFieldOptions.vars = {};
  }

  searchInFieldOptions.vars['Type fields to search'] = searchableFieldTips;
  searchInFieldOptions.vars['Search value'] = [searchValue];

  const extraFilter = {
    label: 'If provided fields\' values have text matching provided search value',
    formula: 'INARRAY(SEARCH_IN_FIELD(VAR("Search value"), VAR("Type fields to search")), TIP())'
  };

  searchInFieldOptions.extraFilters
    ? searchInFieldOptions.extraFilters.push(extraFilter)
    : searchInFieldOptions.extraFilters = [extraFilter];

  return searchInFieldOptions;
}

export function getSearchInTypeQueryOptions(options: IQueryOption, searchableTypeTip: Tip[], searchValue: string): IQueryOption {
  const searchInTypeOptions = cloneDeep(options);

  if (!searchInTypeOptions.vars) {
    searchInTypeOptions.vars = {};
  }

  searchInTypeOptions.vars['Type tip to search'] = searchableTypeTip;
  searchInTypeOptions.vars['Search value'] = [searchValue];

  const extraFilter = {
    label: 'If provided typeTip values have text matching provided search value',
    formula: 'INARRAY(SEARCH_IN_TYPE(VAR("Search value"), VAR("Type tip to search")), TIP())'
  };

  searchInTypeOptions.extraFilters
    ? searchInTypeOptions.extraFilters.push(extraFilter)
    : searchInTypeOptions.extraFilters = [extraFilter];

  return searchInTypeOptions;
}

export function getSearchableFieldsBySuperTypeFormula(superTypeTip: Tip): Formula {
  // tslint:disable-next-line: max-line-length
  return `LOOKUP(TIP(), AND(INARRAY(CONTEXT(LOOKUP(TIP(), INARRAY(FIELD("type/implements"), "${superTypeTip}")), FIELD("type/field")), TIP()), EQUALS(FIELD("field/searchable"), "true")))`;
}
