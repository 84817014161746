/*
  Options here are not changeable by the user.

  They configure properties needed by the form editor

  We dynamically add them any time we use the forms
  They are not persisted to the saved form object
*/

/*
 Options

 - restrictEditingTo: Only allows you to edit these form property fields
 - fullWidth: Item can only be full width
 - limitContextOptionTypesTo: limits what eno field you can bind to
*/

import { EXTENDED_FIELD_DATA_TYPE, FIELD_DATA_TYPE } from '../../models/field';

export const FORM_OPTIONS_NOGGIN = {
  'section-header': {
    // we don't want to edit the title
    restrictEditingTo: ['title', 'collapsible'],
    fullWidth: true
  },
  'text-content': {
    restrictEditingTo: ['html'],
    fullWidth: true,
  },
  'chart-content': {
    restrictEditingTo: ['chartConfig'],
    fullWidth: true,
  },
  'table-content': {
    restrictEditingTo: ['tableConfig'],
    fullWidth: true,
    fieldOrder: [
      'templateOptions.tableConfig.title',
      'templateOptions.tableConfig.objectTypeTip',
      'templateOptions.tableConfig.queryMeta',
      'templateOptions.tableConfig.queryTip',
      'templateOptions.tableConfig.filterInputs',
      'templateOptions.tableConfig.limit',
      'conditionalFields.visibility',
      'templateOptions.tableConfig.columns'
    ]
  },
  'input-text': {
    limitContextOptionTypesTo: [FIELD_DATA_TYPE.string],
  },
  'multi-line-text': {
    limitContextOptionTypesTo: [FIELD_DATA_TYPE.string],
    fullWidth: true,
  },
  'rich-text': {
    // formly auto adds placeholder so we need to restrict
    restrictEditingTo: [ 'label', 'contextTypeTip' ],
    limitContextOptionTypesTo: [FIELD_DATA_TYPE.html],
    fullWidth: true
  },
  'number': {
    limitContextOptionTypesTo: [FIELD_DATA_TYPE.number, FIELD_DATA_TYPE.decimal]
  },
  // date
  'date-time-date': {
    limitContextOptionTypesTo: [FIELD_DATA_TYPE.date],
  },
  // date-time
  'date-time-datetime': {
    limitContextOptionTypesTo: [FIELD_DATA_TYPE.datetime],
  },
  'list-items-select': {
    limitContextOptionTypesTo: [EXTENDED_FIELD_DATA_TYPE.list]
  },
  'object-chooser': {
    limitContextOptionTypesTo: [FIELD_DATA_TYPE.object]
  },
  'sub-object-chooser': {
    limitContextOptionTypesTo: [EXTENDED_FIELD_DATA_TYPE.sub_object]
  },
  'phone-number-tel': {
    limitContextOptionTypesTo: [FIELD_DATA_TYPE.phone],
  },
  'email-address-email': {
    limitContextOptionTypesTo: [FIELD_DATA_TYPE.email],
  },
  'web-address-url': {
    limitContextOptionTypesTo: [FIELD_DATA_TYPE.uri],
  },
  'checkbox': {
    restrictEditingTo: [ 'label', 'contextTypeTip' ],
    limitContextOptionTypesTo: [FIELD_DATA_TYPE.bool]
  },
  'file-upload': {
    restrictEditingTo: [ 'label', 'contextTypeTip' ],
    limitContextOptionTypesTo: [EXTENDED_FIELD_DATA_TYPE.file],
    fullWidth: true,
  },
  'assignable': {
    limitContextOptionTypesTo: [EXTENDED_FIELD_DATA_TYPE.assignable],
    fullWidth: true,
  },
  'geo-chooser-point': {
    limitContextOptionTypesTo: [EXTENDED_FIELD_DATA_TYPE.point],
    fullWidth: true
  },
  'geo-chooser-polyline': {
    limitContextOptionTypesTo: [EXTENDED_FIELD_DATA_TYPE.line],
    fullWidth: true
  },
  'geo-chooser-polygon': {
    limitContextOptionTypesTo: [EXTENDED_FIELD_DATA_TYPE.polygon],
    fullWidth: true
  },
  'address-chooser': {
    limitContextOptionTypesTo: [EXTENDED_FIELD_DATA_TYPE.address],
    fullWidth: true
  }
};
