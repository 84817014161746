import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appSideSheetContent]'
})
export class SideSheetContentDirective {
  constructor(
    public viewContainerRef: ViewContainerRef
  ) {}
}
