import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-string-interpolate-rich-text',
  template: `
      <app-rich-jodit-text-field
        [formControl]="formControl"
        [label]="field.templateOptions.label"
        [readonly]="field.templateOptions.readonly"
        [required]="field.templateOptions.required"
        [enableStringInterpolation]="true"
        [recipientSuperTypeTip]="field.templateOptions.recipientSuperTypeTip"
        [interpolationType]="field.templateOptions.interpolationType"
        [workflowVariables]="field.templateOptions.workflowVariables"
        [contextTypeTips]="field.templateOptions.contextTypeTips">
      </app-rich-jodit-text-field>
  `
})
export class FormlyFieldStringInterpolateRichTextComponent extends FieldType {
}
