import { Component, ChangeDetectionStrategy } from '@angular/core';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

export const modalConfirmDeletionOptions: NgbModalOptions = {
  ariaLabelledBy: 'modal-basic-title',
  size: 'sm',
  centered: true
};

export interface IModalConfirmDeletionComponent {
  objectType?: string;
  title?: string;
  message?: string;
  cancelText?: string;
  confirmText?: string;
}

@Component({
  selector: 'app-modal-confirm-deletion',
  templateUrl: './modal-confirm-deletion.component.html',
  styleUrls: ['./modal-confirm-deletion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalConfirmDeletionComponent {

  objectType = 'item';
  title: string;
  message: string;
  cancelText = 'Cancel';
  confirmText = 'Delete';
  cancelButtonClass = 'btn-danger';

  constructor(public modal: NgbActiveModal) {}

  setProps(props: IModalConfirmDeletionComponent) {
    Object.assign(this, props);
  }
}
