import { Subject, throwError } from 'rxjs';
import { catchError, filter, map, retry, tap, timeout } from 'rxjs/operators';
import { get } from 'lodash';
import { environment } from '../../environments/environment';
import dataConstants from './constants';
import { Eno } from './models/Eno';
import { debugConfig } from '../../debugConfig';
import { captureMessage, Severity } from '@sentry/browser';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./session-manager.service";
import * as i3 from "./eno-cache.service";
import * as i4 from "../util/logger.service";
var SESSION_TOKEN_HEADER = dataConstants.HTTP_HEADER.SESSION_TOKEN_HEADER;
var SESSION_ID_HEADER = dataConstants.HTTP_HEADER.SESSION_ID_HEADER;
var NUM_RETRIES = 2;
export var XHR_TIMEOUT = 30000;
var THIN_OPERATIONS = ['op/pull', 'op/formula', 'op/query'];
var EnsrvService = /** @class */ (function () {
    function EnsrvService(_http, _sessionManager, _enoCacheService, _loggerService) {
        var _this = this;
        this._http = _http;
        this._sessionManager = _sessionManager;
        this._enoCacheService = _enoCacheService;
        this._loggerService = _loggerService;
        this._baseFatUriHttp = environment.host + environment.enSrvFatPath;
        this._baseThinUriHttp = environment.host + environment.enSrvThinPath;
        this._enoBroadcaster = null;
        this._sessionInfo = null;
        this._enoBroadcaster = new Subject();
        this._sessionManager.getSessionInfo$().subscribe(function (sessionInfo) {
            _this._sessionInfo = sessionInfo;
        });
    }
    /**
     * Given a batch, determine the best URI to post it to.
     * Batches which just contain certain operations are more performant when sent to their dedicated route
     *
     * @param batch
     */
    EnsrvService.prototype.getUri = function (batch) {
        if (singleEno(batch) && firstItemIsThinOperation(batch)) {
            return this._baseThinUriHttp + '/' + batch[0].source.type;
        }
        return this._baseFatUriHttp;
    };
    /**
     * Send a batch of enos to ensrv. Returns an
     * @param batch
     */
    EnsrvService.prototype.send = function (batch) {
        var _this = this;
        var headers = {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            'Conn-Isolation': 'true'
        };
        var sessionInfo = this._sessionInfo;
        if (sessionInfo.token) {
            headers[SESSION_TOKEN_HEADER] = sessionInfo.token;
        }
        if (sessionInfo.id) {
            headers[SESSION_ID_HEADER] = sessionInfo.id;
        }
        var XHR_ID = parseInt((Math.random() * 100000) + '', 10);
        var outBatchTypes = batch.map(function (eno) {
            return eno.source.type;
        });
        if (debugConfig.api.outboundBatchMonitor) {
            this._loggerService.debug("[Out (XHR) - " + XHR_ID + "] (" + outBatchTypes.join(', ') + ")", batch);
        }
        return this._http.post(this.getUri(batch), batch.map(function (eno) { return eno.toJson(); }), {
            headers: headers,
            params: {
                ns: environment.ns
            },
            observe: 'response'
        }).pipe(timeout(XHR_TIMEOUT), retry(NUM_RETRIES), catchError(function (err) {
            var message = 'Unexpected error returned from EnSrv:\n\n' + JSON.stringify(err);
            _this._loggerService.warn(message);
            captureMessage(message, Severity.Warning);
            return throwError(err);
        }), tap(function (response) { return _this.updateSessionInfo(response); }), map(createEnosFromResponse), tap(function (incomingBatch) {
            var inBatchTypes = incomingBatch.map(function (eno) {
                return eno.source ? eno.source.type : 'Ack';
            });
            if (debugConfig.api.inboundBatchMonitor) {
                _this._loggerService.debug("[In (XHR) - " + XHR_ID + "] (" + inBatchTypes.join(', ') + ")", incomingBatch);
            }
            // Bellow two loops are intentionally separated to cache all the eno first before broadcasting enos
            // DO NOT MERGE THESE 2 LOOPS
            incomingBatch.forEach(function (eno) {
                _this._enoCacheService.setEno(eno.tip, eno);
            });
            incomingBatch.forEach(function (eno) {
                _this._enoBroadcaster.next(eno);
            });
        }));
    };
    EnsrvService.prototype.getEnoReceiver = function (type) {
        if (type) {
            return this._enoBroadcaster.pipe(filter(function (eno) {
                return eno.source && eno.source.type === type;
            }));
        }
        return this._enoBroadcaster.asObservable();
    };
    EnsrvService.prototype.updateSessionInfo = function (response) {
        var sessionToken = response.headers.get(SESSION_TOKEN_HEADER);
        if (sessionToken) {
            this._sessionManager.updateSessionInfo(sessionToken);
        }
    };
    EnsrvService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EnsrvService_Factory() { return new EnsrvService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.SessionManagerService), i0.ɵɵinject(i3.EnoCacheService), i0.ɵɵinject(i4.LoggerService)); }, token: EnsrvService, providedIn: "root" });
    return EnsrvService;
}());
export { EnsrvService };
function createEnosFromResponse(response) {
    if (!response.body) {
        return [];
    }
    return response
        .body
        .map(function (eno) { return new Eno(eno); });
}
function singleEno(batch) {
    return batch.length === 1;
}
function firstItemIsThinOperation(batch) {
    var type = get(batch, [0, 'source', 'type'], null);
    return THIN_OPERATIONS.indexOf(type) > -1;
}
