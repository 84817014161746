import { Pipe, PipeTransform } from '@angular/core';
import { SecurityService } from '../../data/security.service';
import { Observable, of, throwError, isObservable} from 'rxjs';
import { switchMap } from 'rxjs/operators';

type CanActions = 'read' | 'update' | 'delete' | 'collaborate' | 'create';

// @deprecated
@Pipe({
  name: 'can'
})
export class CanPipe implements PipeTransform {

  constructor(private securityService: SecurityService) {}
  transform(tips: string | string[] | Observable<string | string[]>, canAction: CanActions): Observable<boolean> {
    if (isObservable(tips)) {
      return tips
        .pipe(
          switchMap( (fetchedTips) => this.checkSecurity(canAction, fetchedTips)) );
    }

    return this.checkSecurity(canAction, tips);
  }

  checkSecurity(canAction, tips) {
    switch (canAction) {
      case 'create':
        return this.securityService.canCreate(tips);
      case 'delete':
        return this.securityService.canDelete(tips);
      default:
        return throwError('Invalid action passed to CanPipe');
    }
  }
}
