import { difference, fill, find, findIndex, isString, keys, union } from 'lodash';
import { getFieldI18nValues } from '../../i18n/i18n-value/i18n-value';
import dataConstants from '../../data/constants';
import * as i0 from "@angular/core";
var ListUtilsService = /** @class */ (function () {
    function ListUtilsService() {
    }
    ListUtilsService.getListItemIndex = function (listItems, value) {
        return findIndex(listItems, function (item) { return item.value === value; });
    };
    ListUtilsService.populateListLabels = function (subject, i18ns) {
        var _this = this;
        // If i18ns was not provided, subject would be the list itself.
        i18ns = i18ns || (subject.labels || []).map(function (i18n) { return ({ lang: i18n.lang, value: [] }); });
        if (subject.items && subject.items.length) {
            subject.items.forEach(function (item) {
                var _a, _b;
                item.label = item.label || (_a = {}, _a[dataConstants.LANG_DEFAULT] = '', _a);
                if (isString(item.label)) {
                    item.label = (_b = {}, _b[dataConstants.LANG_DEFAULT] = item.label, _b);
                }
                var existingLocaleIds = i18ns.map(function (i18n) { return i18n.lang; });
                var localeIds = keys(item.label);
                var newLocaleIds = difference(localeIds, existingLocaleIds);
                var allLocaleIds = union(localeIds, existingLocaleIds);
                var numberOfLabels = i18ns[0] ? i18ns[0].value.length : 0;
                // For any new locale IDs (not existent in the original "app/list:labels"), populate with ''.
                newLocaleIds.forEach(function (localeId) {
                    i18ns.push({ lang: localeId, value: fill(Array(numberOfLabels), '') });
                });
                // For any existing locale IDs for which item.label doesn't have a value, populate with ''.
                allLocaleIds.forEach(function (localeId) {
                    find(i18ns, ['lang', localeId]).value.push(item.label[localeId] || '');
                });
                _this.populateListLabels(item, i18ns);
            });
        }
        return i18ns;
    };
    ListUtilsService.prototype.getListItemDepth = function (subject, currentDepthLevel) {
        var _this = this;
        if (currentDepthLevel === void 0) { currentDepthLevel = 1; }
        var maxDepth = currentDepthLevel;
        if (subject.items && subject.items.length) {
            subject.items.forEach(function (item) {
                if (item.items && item.items.length) {
                    var lvl = _this.getListItemDepth(item, currentDepthLevel + 1);
                    maxDepth = Math.max(lvl, maxDepth);
                }
            });
        }
        return maxDepth;
    };
    ListUtilsService.prototype.getListItemLabel = function (list, listItemValue) {
        if (!list.labels || !list.labels.length || !list.values || !list.values.length) {
            return '';
        }
        var valueIndex = list.values.indexOf(listItemValue);
        return valueIndex !== -1 ? getFieldI18nValues(list.labels)[valueIndex] : '';
    };
    ListUtilsService.prototype.populateTallyValues = function (subject, selected) {
        var _this = this;
        if (subject.items && subject.items.length) {
            subject.items.forEach(function (item) {
                item.tallyValue = _this.populateListItemTallyValue(item, selected);
            });
        }
    };
    ListUtilsService.prototype.populateListItemTallyValue = function (subject, selected) {
        var _this = this;
        var tallyCount = 0;
        if (subject.items && subject.items.length) {
            subject.items.forEach(function (item) {
                if (selected.includes(item.value)) {
                    tallyCount += 1;
                }
                if (item.items && item.items.length) {
                    tallyCount += _this.populateListItemTallyValue(item, selected);
                }
            });
        }
        return tallyCount;
    };
    ListUtilsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ListUtilsService_Factory() { return new ListUtilsService(); }, token: ListUtilsService, providedIn: "root" });
    return ListUtilsService;
}());
export { ListUtilsService };
