import { Component, ViewChild } from '@angular/core';

import { SideSheetListComponent } from '../../../side-sheet/side-sheet-list/side-sheet-list.component';
import { addDateFormatWrapper, addDatetimeFormatWrapper, addMonthFormatWrapper,
  addYearFormatWrapper } from '../../../util/datetime-format-formula-wrapper';

// tslint:disable-next-line:class-name
export class ASSIGN_TO_SYSTEM_VALUE {
  static CURRENT_DATE = addDateFormatWrapper('NOW()');
  static CURRENT_DATETIME = addDatetimeFormatWrapper('NOW()');
  static CURRENT_MONTH = addMonthFormatWrapper('NOW()');
  static CURRENT_YEAR = addYearFormatWrapper('NOW()');
  static CURRENT_USER =
    'TITLE(FIELD("app/user:person", FIELD("app/user-session:user", REFERENCES("app/user-session:session", SESSION()))))';
}

@Component({
  selector: 'app-assign-to-system-side-sheet',
  templateUrl: './assign-to-system-side-sheet.component.html',
  styleUrls: ['./assign-to-system-side-sheet.component.scss']
})
export class AssignToSystemSideSheetComponent {
  @ViewChild('systemOptions', {static: false}) systemOptions: SideSheetListComponent;
  done: (selectedValue: ASSIGN_TO_SYSTEM_VALUE) => void;

  ASSIGN_TO_SYSTEM_VALUE = ASSIGN_TO_SYSTEM_VALUE;

  hideCurrentUserOption = false;

  constructor() {}
}
