import { reverse } from 'lodash';

import { FormulaSpec, Parser, Stringify } from '../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { Tip } from '../data/models/types';
import { getMostInnerContextPartsFromContextFormula } from './context-formula-wrapper';

export function getFieldTipsFromFormulaString(formulaStr: string): Tip[] {
  if (!!formulaStr) {
    return getFieldTipsFromFormulaSpec(Parser(formulaStr));
  }

  return [];
}

// formula spec is what the Parser returns
export function getFieldTipsFromFormulaSpec(formulaSpec: FormulaSpec): string[] {
  if (formulaSpec.name === 'CONTEXT') {
    const { outerFormulas, mostInnerContextFormula } = getMostInnerContextPartsFromContextFormula(Stringify(formulaSpec));
    return [mostInnerContextFormula, ...reverse(outerFormulas)].reduce((acc, formula) => {
      const parsedFormula = Parser(formula);

      if (parsedFormula.name === 'FIELD' || parsedFormula.name === 'FMT_FIELD') {
        return [...acc, parsedFormula.args[0]];
      }

      return acc;
    }, []);
  }

  return backwardsCompatible(formulaSpec);
}

// below are for backwards compatible
function backwardsCompatible(formulaSpec: FormulaSpec): string[] {
  if (formulaSpec.name !== 'FIELD' && formulaSpec.name !== 'FMT_FIELD') {
    return [];
  }

  return formulaSpec.args.reduce((acc, formulaArg, index) => {
    // we want the first string value
    if (typeof formulaArg === 'string' && index === 0) {
      return [...acc, formulaArg];
    }

    // if the other values are objects re-run them
    if (typeof formulaArg === 'object') {
      const fieldTips = getFieldTipsFromFormulaSpec(formulaArg as any);
      return [...fieldTips, ...acc];
    }

    // not the first string or an object just ignore
    return acc;
  }, []);
}
