<div class="screen">
  <div class="main">
    <div class="image">
      <img src="/assets/v1.627.2-beta/assets/img/403/nogg_noaccess.svg" class="background" width="300px">
    </div>
    <h1>Hold up!</h1>
    <h6>You do not have access to this page.<br>You need to be an administrator to access it.</h6>
    <div>
      <a class="btn btn-primary home-button" routerLink="/">Let's go home!</a>
    </div>
  </div>
</div>