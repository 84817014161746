import { environment } from '../../environments/environment';
import { get } from 'lodash';
import { IRedoc } from './api.service';

export const NOGGIN_LOGO_SVG = `${environment.assetPath}/assets/img/logo-api-docs.svg`;

export const REDOC_OPTIONS = {
  theme: {
    colors: {
      primary: {
        main: '#273C4E'
      },
      http: {
        get: '#00AC3A', // $eim-color_symbology-dark-pastel-green
        post: '#237ACF', // $eim-color_symbology-navy-blue
        put: '#ED1AFC', // $eim-color_symbology-phlox-pink
        patch: '#FFD718', // $eim-color_symbology-candlelight-yellow
        delete: '#FF181E', // $eim-color_symbology-torch-red
      }
    },
    typography: {
      fontFamily: 'Lato, Arial, sans-serif',
      headings: {
        fontFamily: 'Lato, Arial, sans-serif',
      }
    }
  }
};

export function updateSpecLogo(openAPISpec: ArrayBuffer): ArrayBuffer {
  const logo: string | null = get(openAPISpec, 'info.x-logo.url', null);
  if (logo) {
    openAPISpec['info']['x-logo']['url'] = NOGGIN_LOGO_SVG;
  }
  return openAPISpec;
}

export function initRedoc(redoc: IRedoc, openAPISpec: {}, redocElement: HTMLDivElement) {
  redoc
    .init(
      openAPISpec,
      REDOC_OPTIONS,
      redocElement
    );
}

export function getRedocEl(nativeElement: HTMLElement): HTMLDivElement {
  return nativeElement.querySelector('#redoc');
}
