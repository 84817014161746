import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, of, Observable, throwError } from 'rxjs';
import { tap, catchError, first } from 'rxjs/operators';

import { ToastService } from '../../shell/services/toast.service';
import { SideSheetService } from '../../side-sheet/side-sheet.service';
import { IType } from '../../models/type';
import { IObjectScheme } from '../../data/models/scheme';
import { Tip } from '../../data/models/types';
import { ObjectService, IObjectAndType } from '../object.service';
import { ObjectEditFormComponent } from '../object-edit-form/object-edit-form.component';

@Component({
  selector: 'app-object-edit-side-sheet',
  templateUrl: './object-edit-side-sheet.component.html',
  styleUrls: ['./object-edit-side-sheet.component.scss']
})
export class ObjectEditSideSheetComponent implements OnInit, AfterViewInit, OnDestroy {
  createOrEdit: 'create' | 'edit';
  title: string;
  objectTip: string;
  objectBranchTip: string;
  objectData: object;
  objectType: IType;
  objectTypeTip: string;
  objectScheme: IObjectScheme;
  allowDelete = false;
  readonly = false;
  state: 'in-progress' | 'failed' = 'in-progress';
  fieldTipsToDisplay: Tip[] = [];
  mode: 'save' | 'return' = 'return';
  onDone: (object) => void;
  enoType: string;
  enoSecurity: Tip;
  subs: Subscription = new Subscription();
  preDefinedFields: { fieldTip: Tip; value: any }[] = [];

  @ViewChild(ObjectEditFormComponent, {static: false}) objectEditForm: ObjectEditFormComponent;

  constructor(
    private objectService: ObjectService,
    private sideSheetService: SideSheetService,
    private toastService: ToastService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.getObjectAndType().pipe(
      first(),
      tap((objectAndType: IObjectAndType) => {
        this.objectType = objectAndType.objectType;
        this.objectScheme = objectAndType.objectScheme;
        this.objectData = objectAndType.objectData;
        this.createOrEdit = this.objectTip ? 'edit' : 'create';
      }),
      catchError((err) => {
        this.toastService.showErrorToast('Unable to open object at this time');
        this.sideSheetService.pop();
        return of(err);
      })
    ).subscribe(() => {
      this.state = null;
      if (this.preDefinedFields && this.preDefinedFields.length) {
        this.preDefinedFields.forEach((field: { fieldTip: Tip; value: any }) => {
          if (!field.value) {
            field.value = null;
          }
          this.objectData[field.fieldTip] = field.value;
        });
      }
      this.cd.detectChanges();
    });
  }

  private getObjectAndType(): Observable<IObjectAndType> {
    return this.objectTip ?
      this.objectService.getObjectAndType(this.objectTip, this.objectBranchTip)
      : this.objectService.getType(this.enoType);
  }

  onPrimary() {
    if (this.objectEditForm.objectForm.invalid) {
      const objectForm = this.objectEditForm.objectForm;
      Object.keys(objectForm.controls).forEach(field => objectForm.get(field).markAsTouched());
      return;
    }

    if (this.mode === 'return') {
      this.sideSheetService.pop();
      if (this.onDone) {
        this.onDone(this.objectData);
      }
      return;
    }

    if (!this.objectTip) {
      this.objectData['$type'] = this.enoType;
      if (this.enoSecurity) {
        this.objectData['$security'] = this.enoSecurity;
      }
    }

    this.state = 'in-progress';

    this.subs.add(this.objectService.updateObject(this.objectData, this.objectScheme, this.objectBranchTip).subscribe(
      objectTip => {
        this.state = null;

        this.toastService.showSuccessToast('Saved successfully');
        this.sideSheetService.pop();
        if (this.onDone) {
          this.objectData['$tip'] = objectTip;
          this.onDone(this.objectData);
        }
      },
      err => {
        this.state = 'failed';
      }
    ));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onDelete(): Observable<any> {
    if (!this.allowDelete) {
      return throwError('Delete has not been allowed for this side sheet');
    }
    return this.objectService.deleteObject({ $tip: this.objectTip });
  }
}

@Component({
  selector: 'app-object-edit-sheet-launch',
  template: ''
})
export class ObjectEditSheetLaunchComponent implements OnDestroy {
  constructor(private sideSheetService: SideSheetService, private activatedRoute: ActivatedRoute) {
    const sheetRef = this.sideSheetService.push(ObjectEditSideSheetComponent);
    sheetRef.componentInstance.objectTip = activatedRoute.snapshot.paramMap.get('objectTip');
    sheetRef.componentInstance.objectBranchTip = activatedRoute.snapshot.paramMap.get('branch');
    sheetRef.componentInstance.mode = 'save';
  }

  ngOnDestroy() {
    this.sideSheetService.clear();
  }
}
