import { MonoTypeOperatorFunction, Observable, pipe, Subject } from 'rxjs';
import { filter, map, tap, merge} from 'rxjs/operators';
import { LoggerService } from '../util/logger.service';

interface ICurrentThrottle {
  skipped: number;
  data: any;
}

/*
* Throttles groups of values based on the duration passed in
*
* Only throttles excessive values it emits the last throttled value when the throttle
* times out so the user also has the latest data
*
* Group by function lets you select what values a throttled as a group
*/

// tslint:disable-next-line:max-line-length
export function groupedExcessiveOutputThrottle<T>(throttleDuration: number, groupKeyFunction: (data: any) => string, loggerService?: LoggerService): MonoTypeOperatorFunction<T> {
  const throttleMap = new Map<string, ICurrentThrottle>();

  const lastValues = new Subject<T>();
  const lastValueObs$: Observable<T> = lastValues.asObservable();

  return pipe(
    map((data) => ({data, groupKey: groupKeyFunction(data)})),
    filter(({groupKey, data}) => {
      const hasValue = throttleMap.has(groupKey);
      if (hasValue) {
        const skipped = throttleMap.get(groupKey).skipped;
        const update = {
          skipped: skipped + 1,
          data
        };
        throttleMap.set(groupKey, update);
      }
      return !hasValue;
    }),
    tap(({data, groupKey}) => {
      throttleMap.set(groupKey, {data, skipped: 0});
      setTimeout(() => {
        const currentThrottle =  throttleMap.get(groupKey);
        if (currentThrottle.skipped > 1 && loggerService) {
          // if we only have one skipped value we still get it just at the end of the throttle
          loggerService.info(`Throttled (skipped) ${currentThrottle.skipped - 1} requests for ${groupKey}`);
        }
        if (currentThrottle.skipped > 0) {
          lastValues.next(currentThrottle.data);
          if (loggerService) {
            loggerService.info(`Throttled (delayed) requests with data`, currentThrottle.data);
          }
        }
        throttleMap.delete(groupKey);
      }, throttleDuration);
    }),
    map(({data}) => data),
    merge(lastValueObs$)
  );
}
