import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ModalHeaderComponent } from './modal-header/modal-header.component';
import { SpinnerDirective } from './spinner/spinner.directive';
import { ShowMoreDirective } from './show-more/show-more.directive';
import { ShowMoreModule } from './show-more/show-more.module';
import { FormsModule } from '@angular/forms';
import { MimeTypeToImageUrlPipe } from './mime-type-to-image-url.pipe';
import { SpinnerModule } from './spinner/spinner.module';
import { EmptyStateModule } from './empty-state/empty-state.module';
import { DatetimePickerModule } from './datetime-picker/datetime-picker.module';
import { FileUriPipe } from './file-uri.pipe';
import { FilterPipe } from './filter.pipe';
import { PageHeaderModule } from './page-header/page-header.module';
import { PageHeaderComponent } from './page-header/page-header.component';
import { TextareaAutogrowModule } from './textarea-autogrow/textarea-autogrow.module';
import { TextareaAutogrowDirective } from './textarea-autogrow/textarea-autogrow.directive';
import { AlertModule } from './alert/alert.module';
import { AlertComponent } from './alert/alert.component';
import { PaginationComponent } from './pagination/pagination.component';
import { TableListModule } from './table-list/table-list.module';
import { PaginationModule } from './pagination/pagination.module';
import { TwoLineItemModule } from './two-line-item/two-line-item.module';
import { FilterPipeModule } from './pipe.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    EmptyStateModule,
    SpinnerModule,
    DatetimePickerModule,
    ShowMoreModule,
    PageHeaderModule,
    TextareaAutogrowModule,
    AlertModule,
    PaginationModule,
    TableListModule,
    TwoLineItemModule,
    FilterPipeModule
  ],
  declarations: [
    ModalHeaderComponent,
    MimeTypeToImageUrlPipe,
    FileUriPipe,
  ],
  exports: [
    TableListModule,
    PageHeaderComponent,
    AlertComponent,
    ModalHeaderComponent,
    SpinnerDirective,
    MimeTypeToImageUrlPipe,
    FilterPipe,
    ShowMoreDirective,
    TextareaAutogrowDirective,
    PaginationComponent,
  ]
})
export class SharedModule {}
