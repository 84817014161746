import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { camelCase } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AlphanumericConverterService {

  static generateAlphanumericValueFromString = (value: string): string => {
    return camelCase(value.replace(/^[^a-zA-Z]+/, '')).substr(0, 50);
  }

  static applyAlphanumericValueToControl(controlA: AbstractControl, controlB: AbstractControl) {
    const controlAValue = controlA.value;

    if (!controlAValue) {
      controlB.setValue(controlAValue);
      return;
    }

    const controlAValueAlphanumeric = AlphanumericConverterService.generateAlphanumericValueFromString(controlAValue);

    controlB.setValue(controlAValueAlphanumeric);
  }

}
