import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { startWith, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { formatNumber } from '../../../shared/number-format/number-format';
var FormlyFieldNumberFormatPreviewComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FormlyFieldNumberFormatPreviewComponent, _super);
    function FormlyFieldNumberFormatPreviewComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.classes = 'flex-fill';
        _this.PFormControl = new FormControl();
        _this.done$ = new Subject();
        return _this;
    }
    FormlyFieldNumberFormatPreviewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.PFormControl.disable(); // show in disabled state
        this.preview$ = this.form.valueChanges
            .pipe(startWith(this.form.value), takeUntil(this.done$))
            .subscribe(function (numberFormatFieldTips) {
            var numberFormat = numberFormatFieldToNumberFormat(numberFormatFieldTips);
            var previewValue = numberFormatFieldTips['number-format:preview-value'];
            var formattedNumber = formatNumber(previewValue, numberFormat);
            _this.PFormControl.patchValue(formattedNumber);
        });
    };
    FormlyFieldNumberFormatPreviewComponent.prototype.ngOnDestroy = function () {
        this.done$.next();
        this.done$.complete();
    };
    return FormlyFieldNumberFormatPreviewComponent;
}(FieldType));
export { FormlyFieldNumberFormatPreviewComponent };
function numberFormatFieldToNumberFormat(numberFormatField) {
    return {
        calculations: numberFormatField['number-format:calculations'],
        decimalPlaces: numberFormatField['number-format:decimal-places'],
        thousandsSeparator: numberFormatField['number-format:thousands-separator'],
        decimalSeparator: numberFormatField['number-format:decimal-separator'],
        suffix: numberFormatField['number-format:suffix'],
        prefix: numberFormatField['number-format:prefix'],
    };
}
