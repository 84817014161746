import { IObjectScheme, DataTypes } from '../data/models/scheme';
import { IObject } from '../data/models/object';
import { Tip, Formula } from '../data/models/types';

export interface ISequenceSubstitution extends IObject {
  key: string;
  label: string;
  value: Formula;
}

export const sequenceSubstitutionScheme: IObjectScheme = {
  'app/sequence/substitution:key': {},
  'app/sequence/substitution:label': {},
  'app/sequence/substitution:value': { type: DataTypes.formula }
};


export interface ISequence extends IObject {
  name: string;
  objectType?: Tip;
  numberOfDigits: number;
  key?: string;
  keySubstitutions?: ISequenceSubstitution[];
  label: string;
  labelSubstitutions?: ISequenceSubstitution[];
}

export const sequenceScheme: IObjectScheme = {
  'app/sequence:name': { type: DataTypes.i18n },
  'app/sequence:object-type': { type: DataTypes.string },
  'app/sequence:number-of-digits': { type: DataTypes.number },
  'app/sequence:label': { type: DataTypes.string },
  'app/sequence:label-substitutions': {
    type: DataTypes.objectArray,
    mutable: true,
    scheme: sequenceSubstitutionScheme
  },
  'app/sequence:key': { type: DataTypes.string },
  'app/sequence:key-substitutions': {
    type: DataTypes.objectArray,
    mutable: true,
    scheme: sequenceSubstitutionScheme
  }
};
