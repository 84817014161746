import { IInnerOperator, IOperators, reverseOperator } from './operators';
import { Parser } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { OPERATOR_TYPE } from '../../../models/query';
import { isCurrentUserFormula } from '../../../util/current-user-formula';
import { isFormulaSpec } from '../../../util/is-formula-spec';
// checks that assignee in leftArg is same as user in rightArg
// OR one of teams it belongs to
// OR one of roles it belongs to
const assignedTo: IInnerOperator = {
  opNo: 2,
  toFormula: (leftArg, rightArg) => {

    let rightArgFormula = rightArg;
    if (!isFormulaSpec(rightArg) && isCurrentUserFormula(rightArg as string)) {
      rightArgFormula = Parser(rightArg as string);
    }

    return {
      name: 'INARRAY',
      args: [
        {
          name: 'ARRAY',
          args: [
            rightArgFormula,
            {
              name: 'REFERENCES',
              args: [
                'app/team-role:users',
                rightArgFormula
              ]
            },
            {
              name: 'REFERENCES',
              args: [
                'app/team:roles',
                {
                  name: 'REFERENCES',
                  args: [
                    'app/team-role:users',
                    rightArgFormula
                  ]
                }
              ]
            },
            {
              name: 'REFERENCES',
              args: [
                'app/team:roles',
                rightArgFormula
              ]
            }
          ]
        },
        {
          name: 'FIELD',
          args: [
            'app/assignment:assignee',
            leftArg
          ]
        }
      ]
    };
  }
};
const notAssignedTo = reverseOperator(assignedTo);

export const assigneeOperators: IOperators = {
  operators: {
    [OPERATOR_TYPE.ASSIGNED_TO]: assignedTo,
    [OPERATOR_TYPE.NOT_ASSIGNED_TO]: notAssignedTo
  },
  getParts: formula => {
    let currentPart = formula;
    let negative = false;

    if (currentPart.name === 'NOT') {
      currentPart = currentPart.args[0];
      negative = true;
    }

    if (currentPart.name === 'INARRAY') {
      return {
        operator: negative ? OPERATOR_TYPE.NOT_ASSIGNED_TO : OPERATOR_TYPE.ASSIGNED_TO,
        leftArg: currentPart.args[1].args[1],
        rightArg: currentPart.args[0].args[0]
      };
    }

    throw new Error('Unknown operators: ' + currentPart.name);
  }
};
