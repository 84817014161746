import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SideSheetBase } from '../../../../side-sheet/side-sheet-base';
import { FormBuilder } from '@angular/forms';
import { SideSheetService } from '../../../../side-sheet/side-sheet.service';

@Component({
  selector: 'app-custom-formula-side-sheet',
  templateUrl: './custom-formula-side-sheet.component.html',
  styleUrls: [ './custom-formula-side-sheet.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomFormulaSideSheetComponent extends SideSheetBase<string, string> {

  formulaForm = this.fb.group({
    customFormulaStr: [ '']
  });

  constructor(
    private sideSheetService: SideSheetService,
    private fb: FormBuilder) {
    super();
  }


  setProps(props: string) {
    this.formulaForm.patchValue({
      customFormulaStr: props
    });
  }

  onPrimary() {
    this.done$.next(this.formulaForm.value.customFormulaStr);
    this.sideSheetService.pop();
  }
}
