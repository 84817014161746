/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./api.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./api.component";
import * as i3 from "./api.service";
var styles_APIComponent = [i0.styles];
var RenderType_APIComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_APIComponent, data: {} });
export { RenderType_APIComponent as RenderType_APIComponent };
export function View_APIComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["id", "redoc"]], null, null, null, null, null))], null, null); }
export function View_APIComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-api", [], null, null, null, View_APIComponent_0, RenderType_APIComponent)), i1.ɵdid(1, 4374528, null, 0, i2.APIComponent, [i1.ElementRef, i3.APIService], null, null)], null, null); }
var APIComponentNgFactory = i1.ɵccf("app-api", i2.APIComponent, View_APIComponent_Host_0, {}, {}, []);
export { APIComponentNgFactory as APIComponentNgFactory };
