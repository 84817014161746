import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { QueryService } from '../../../data/query.service';
import { ToastService } from '../../../shell/services/toast.service';
import { Tip } from '../../../data/models/types';
import { sortBy } from 'lodash';
import { Subject } from 'rxjs';
import { IObjectLaunchDone, IObjectLaunchProps, OBJECT_LAUNCH_SERVICE_TOKEN } from '../../object-launch';
import { takeUntil } from 'rxjs/operators';
import { ToggleType } from '../../../side-sheet/side-sheet-list-item/side-sheet-list-item.component';

interface ObjectType {
  $tip: Tip;
  label: string;
  module?: Tip;
}

@Component({
  selector: 'app-object-type-side-sheet',
  templateUrl: './object-type-side-sheet.component.html',
  styleUrls: ['./object-type-side-sheet.component.scss']
})
export class ObjectTypeSideSheetComponent implements OnInit, OnDestroy {
  isLoading = true;
  types: ObjectType[] = [];
  selectedTypeTip: Tip;
  superTypeTip: Tip;

  done$ = new Subject<IObjectLaunchDone>();
  unsubscribe$ = new Subject();
  ToggleType = ToggleType;

  filterText = '';

  constructor(
    private queryService: QueryService,
    private toastService: ToastService,
    @Inject(OBJECT_LAUNCH_SERVICE_TOKEN) private objectLaunchService,
  ) {
  }

  ngOnInit() {
    this.loadObjectTypes();
  }

  setProps(props: IObjectLaunchProps) {
    this.superTypeTip = props.typeTip;
  }

  loadObjectTypes() {
    this.queryService.execute1dFirst<ObjectType>('eim/query/get-all-objects-types-with-super-types', {
      vars: {
        superTypeTip: [this.superTypeTip]
      }
    })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        objectTypes => {
          this.types = sortBy(objectTypes, 'label');
          this.isLoading = false;
        }, () => {
          this.toastService.showErrorToast('Unable to load types');
          this.objectLaunchService.pop();
          this.isLoading = false;
        }
      );
  }

  onToggle(typeTip: Tip) {
    if (this.selectedTypeTip === typeTip) {
      this.selectedTypeTip = '';
    } else {
      this.selectedTypeTip = typeTip;
    }
    this.onPrimary();
  }

  onPrimary() {
    this.objectLaunchService.pushSheet({ typeTip: this.selectedTypeTip }, this.unsubscribe$)
      .subscribe(({ objectTip }: IObjectLaunchDone) => {
        if (objectTip) {
          this.objectLaunchService.pop();
          this.done$.next({ objectTip });
        }

      });
  }

  ngOnDestroy() {
    this.done$.complete();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

