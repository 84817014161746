/*
  Options here are not changeable by the user.

  The configure properties needed by the pallet

  We dynamically add them any time we use the forms
  They are not persisted to the saved form object
*/

// @todo we could dynamically set key to null and the list id
// list id just needs to be unique that is all could just use uuid generator

export const FORM_OPTIONS_PALLET = {
  'section-header': {
    palletButtonConfig: {
      fill: '#00B8C0', // Aqua
      iconName: 'section-header',
    }
  },
  'text-content': {
    palletButtonConfig: {
      fill: '#75A9FF', // Light blue
      iconName: 'text-content',
    }
  },
  'chart-content': {
    palletButtonConfig: {
      fill: '#75A9FF', // Light blue
      iconName: 'chart',
    }
  },
  'table-content': {
    palletButtonConfig: {
      fill: '#75A9FF', // Light blue
      iconName: 'table',
    }
  },
  // single-line-text
  'input-text': {
    palletButtonConfig: {
      fill: '#2C1DBC', // Royal blue
      iconName: 'input-text',
    }
  },
  'multi-line-text': {
    palletButtonConfig: {
      fill: '#2C1DBC',
      iconName: 'multi-line-text',
    },
  },
  'rich-text': {
    palletButtonConfig: {
      fill: '#2C1DBC',
      iconName: 'rich-text',
    },
  },
  'number': {
    palletButtonConfig: {
      fill: '#DE9DF4', // Pink
      iconName: 'number',
    }
  },
  'checkbox': {
    palletButtonConfig: {
      fill: '#DE9DF4',
      iconName: 'checkbox',
    }
  },
  'date-time-date': {
    palletButtonConfig: {
      fill: '#FF7100', // Orange
      iconName: 'date-time-date',
    }
  },
  'date-time-datetime': {
    palletButtonConfig: {
      fill: '#FF7100',
      iconName: 'date-time-date-time',
    }
  },
  'list-items-select': {
    palletButtonConfig: {
      fill: '#028BA1', // Teal
      iconName: 'option-list',
    },
  },
  'object-chooser': {
    palletButtonConfig: {
      fill: '#028BA1', // Teal
      iconName: 'object-chooser',
    }
  },
  'sub-object-chooser': {
    palletButtonConfig: {
      fill: '#028BA1', // Teal
      iconName: 'sub-object',
    }
  },
  // assignable
  'assignable': {
    palletButtonConfig: {
      fill: '#028BA1', // Teal
      iconName: 'assignee',
    }
  },
  // phone number
  'phone-number-tel': {
    palletButtonConfig: {
      fill: '#83DF28', // Green
      iconName: 'input-tel',
    }
  },
  // email
  'email-address-email': {
    palletButtonConfig: {
      fill: '#83DF28',
      iconName: 'input-email',
    }
  },
  // web address
  'web-address-url': {
    palletButtonConfig: {
      fill: '#83DF28',
      iconName: 'input-url',
    }
  },
  // file upload
  'file-upload': {
    palletButtonConfig: {
      fill: '#7C3A93', // Purple
      iconName: 'file-upload',
    }
  },
  // geo chooser point
  'geo-chooser-point': {
    palletButtonConfig: {
      fill: '#FF0050', // Pinky red
      iconName: 'point',
    }
  },
  // geo chooser line
  'geo-chooser-polyline': {
    palletButtonConfig: {
      fill: '#FF0050', // Pinky red
      iconName: 'line',
    }
  },
  // geo chooser polygon
  'geo-chooser-polygon': {
    palletButtonConfig: {
      fill: '#FF0050', // Pinky red
      iconName: 'polygon',
    }
  },
  // address chooser polygon
  'address-chooser': {
    palletButtonConfig: {
      fill: '#FF0050', // Pinky red
      iconName: 'address',
    }
  }
};
