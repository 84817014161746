import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { get, head } from 'lodash';
var FormlyFieldSubObjectChooserComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FormlyFieldSubObjectChooserComponent, _super);
    function FormlyFieldSubObjectChooserComponent(loggerService) {
        var _this = _super.call(this) || this;
        _this.loggerService = loggerService;
        _this.single = true;
        _this.typeTip = null;
        return _this;
    }
    FormlyFieldSubObjectChooserComponent.prototype.ngOnInit = function () {
        this.contextTypeOptions = get(this, 'formState.contextTypeOptions', null);
        var contextTypeTip = get(this, 'field.templateOptions.contextTypeTip', null);
        var contextTypeOption = this.contextTypeOptions
            ? this.contextTypeOptions.values.get(contextTypeTip)
            : null;
        var isMulti = get(this, 'field.templateOptions.multi', false);
        if (isMulti || (contextTypeOption && contextTypeOption.maxcount !== 1)) {
            this.single = false;
        }
        var typeTips = get(contextTypeOption, 'typerestrict', []);
        if (typeTips.length > 1) {
            this.loggerService.error("\n        The sub object chooser should only instantiated with one type.\n        Multiple types were provided. We will load the first one and hope that's right", typeTips);
        }
        if (typeTips) {
            this.typeTip = head(typeTips);
        }
    };
    return FormlyFieldSubObjectChooserComponent;
}(FieldType));
export { FormlyFieldSubObjectChooserComponent };
