import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

import { FormColors, FormIcons, LoadingState } from '../../../../shared/constants';
import { constructI18nValues, getI18nValue } from '../../../../i18n/i18n-value/i18n-value';
import { ListDesignerService } from '../../list-designer.service';
import { ToastService } from '../../../../shell/services/toast.service';
import { IList, IListItem } from '../../../../models/list';
import { AlphanumericConverterService } from '../../../../data/alphanumeric-converter/alphanumeric-converter.service';
import { Tip } from '../../../../data/models/types';
import { ModuleValidationError } from '../../../../data/errors/client/ModuleValidationError';

@Component({
  selector: 'app-list-item-edit-side-sheet',
  templateUrl: './list-item-edit-side-sheet.component.html',
  styleUrls: ['./list-item-edit-side-sheet.component.scss']
})
export class ListItemEditSideSheetComponent implements OnInit, OnDestroy {
  moduleTip: Tip;
  list: IList;
  listItem: IListItem;
  listItemIndex: number;
  parentListItem: IListItem;
  i18nLabel: string;

  colors = FormColors;
  icons = FormIcons;

  loadingState: LoadingState;
  loadingStates = LoadingState;
  errorMessage: string;

  itemForm: FormGroup = new FormGroup({
    label: new FormControl('', Validators.required),
    value: new FormControl(null, [Validators.required]),
    icon: new FormControl(null),
    color: new FormControl(null)
  });

  private unsubscribe$ = new Subject();

  done: () => void;

  constructor(
    private listService: ListDesignerService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.i18nLabel = getI18nValue(this.listItem.label);

    this.itemForm.setValue({
      label: this.i18nLabel,
      value: this.listItem.value || null,
      icon: this.listItem.icon || null,
      color: this.listItem.color || null
    });

    this.itemForm.controls.label.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(_ => {
        AlphanumericConverterService
          .applyAlphanumericValueToControl(this.itemForm.controls.label, this.itemForm.controls.value);
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  updateListItem() {
    const labelChanged: boolean = this.i18nLabel !== this.itemForm.value.label;

    this.listItem.label = labelChanged ? constructI18nValues(this.itemForm.value.label) : this.listItem.label;
    this.listItem.value = this.itemForm.value.value;
    this.listItem.icon = this.itemForm.value.icon;
    this.listItem.color = this.itemForm.value.color;

    this.loadingState = LoadingState.inProgress;
    this.listService.updateList(this.list, this.moduleTip).pipe(
      first()
    ).subscribe(
      () => {
        this.toastService.showSuccessToast('List item successfully updated');
        this.done();
      },
      (error) => {
        error instanceof ModuleValidationError
          ? this.toastService.showErrorToast(error.message)
          : this.errorMessage = 'Unable to update list item at this time';
        this.loadingState = LoadingState.failed;
      }
    );
  }

  deleteListItem() {
    this.parentListItem
      ? this.parentListItem.items.splice(this.listItemIndex, 1)
      : this.list.items.splice(this.listItemIndex, 1);

    this.loadingState = LoadingState.inProgress;
    this.listService.updateList(this.list, this.moduleTip).pipe(
      first()
    ).subscribe(
      () => {
        this.toastService.showSuccessToast('List item successfully deleted');
        this.done();
      },
      (error) => {
        error instanceof ModuleValidationError
          ? this.toastService.showErrorToast(error.message)
          : this.errorMessage = 'Unable to delete list item at this time';
        this.loadingState = LoadingState.failed;
      }
    );
  }
}
