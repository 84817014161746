import { IObject } from '../data/models/object';
import { IObjectScheme, DataTypes } from '../data/models/scheme';
import { Tip } from '../data/models/types';

export interface IPrintTemplate extends IObject {
  name: string;
  fileTip: Tip;
  template?: string;
}

export const printTemplateScheme: IObjectScheme = {
  'app/print-template:name': { type: DataTypes.string },
  'app/print-template:template': { type: DataTypes.string },
  'app/print-template:template-file': { name: 'fileTip', type: DataTypes.string }
};

export interface IPrintTemplateUsage extends IObject {
  name: string;
  objectType: Tip;
  form?: Tip;
  exportFormat: Tip;
}

export const printTemplateUsageScheme: IObjectScheme = {
  'app/print-template-usage:name': { type: DataTypes.i18n },
  'app/print-template-usage:object-type': { type: DataTypes.string },
  'app/print-template-usage:form': { type: DataTypes.string },
  'app/print-template-usage:export-format': { type: DataTypes.string }
};
