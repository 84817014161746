import * as Chart from 'chart.js';

import { INumberFormat } from '../shared/number-format/number-format-types';
import { IFilterInput, ISortingOption } from '../var/filter-input/filter-input.service';
import { DataTypes, IObjectScheme } from '../data/models/scheme';
import { IObject } from '../data/models/object';
import { Formula, Tip } from '../data/models/types';
import { FIELD_DATA_TYPE } from './field';

export interface ISeriesStyle {
  seriesDashStyle?: ELineDashStyle;
  pointMarkerSymbol?: EPointMarkerSymbol;
}

export interface IDataConfiguration {
  axes?: IAxesConfiguration;
}

export interface IAxesConfiguration {
  xAxis?: Formula;
  yAxis?: Formula;
  xAxisDataType?: FIELD_DATA_TYPE;
  yAxisDataType?: FIELD_DATA_TYPE;
  yAxisMetric?: EyAxisMetric;
  yAxisNumberFormat?: INumberFormat;
  axisLocation?: 'Y1' | 'Y2';
  trendline?: 'No trendlines' | 'Cumulative sum' | 'Moving average' | 'Standard deviation' | 'Average';
  series?: string;
  groupBy?: EDateTimeGroupBy;
}

export interface IDataSource {
  title: string;
  objectTypeTip?: Tip;
  queryTip?: Tip;
  sortingOptions?: ISortingOption[];
  chartType?: EChartType;
  dataConfiguration?: IDataConfiguration;
  seriesStyle?: ISeriesStyle;
}

export interface IChart extends IObject {
  title: string;
  configuration?: IChartConfiguration;
  dataSource?: IDataSource[];
  previewInput?: IFilterInput[];
}

export const chartScheme: IObjectScheme = {
  'app/chart:title': { type: DataTypes.i18n },
  'app/chart:configuration': { type: DataTypes.json },
  'app/chart:data-source': { name: 'dataSource', type: DataTypes.jsonArray },
  'app/chart:preview-input': { name: 'previewInput', type: DataTypes.jsonArray }
};

export interface IChartConfiguration extends Chart.ChartOptions {
  zoom?: IChartZoomOptions;
  pan?: IChartPanOptions;
}

export interface IChartZoomOptions {
  enabled?: boolean;
  // Zooming directions. Remove the appropriate direction to disable
  // Eg. 'y' would only allow zooming in the y direction
  mode?: 'x' | 'y' | 'xy';
  // Enable drag-to-zoom behavior
  drag?: boolean;
  // Speed of zoom via mouse wheel
  // (percentage of zoom on a wheel event)
  speed?: number;
  // Format of ranges depends on scale type
  rangeMin?: {
    x: any,
    y: any
  };
  rangeMax?: {
    x: any,
    y: any
  };
}

export interface IChartPanOptions {
  enabled?: boolean;
  mode?: 'x' | 'y' | 'xy';
}

export enum EChartType {
  LINE = 'Line',
  AREA = 'Area',
  STACKED_AREA = 'Stacked area',
  STACKED_AREA_100 = '100% stacked area',
  COLUMN = 'Column',
  STACKED_COLUMN = 'Stacked column',
  STACKED_COLUMN_100 = '100% stacked column',
  BAR = 'Bar',
  STACKED_BAR = 'Stacked bar',
  STACKED_BAR_100 = '100% stacked bar',
  PIE = 'Pie',
  DOUHNUT = 'Doughnut',
  SCATTER = 'Scatter',
  BUBBLE = 'Bubble',
  TIMELINE = 'Timeline'
}

export const CHART_TYPE_SETTINGS: ChartType = {
  [EChartType.LINE]: {
    datasetOptions: {
      type: 'line',
      fill: false,
      pointRadius: 3
    }
  },
  [EChartType.PIE]: {
    datasetOptions: {
      type: 'pie'
    },
    options: {
      cutoutPercentage: 0
    }
  },
  [EChartType.DOUHNUT]: {
    datasetOptions: {
      type: 'doughnut'
    },
    options: {
      cutoutPercentage: 50
    }
  },
  [EChartType.COLUMN]: {
    datasetOptions: {
      type: 'bar'
    }
  },
  [EChartType.AREA]: {
    datasetOptions: {
      type: 'line',
      fill: true
    }
  },
  [EChartType.SCATTER]: {
    datasetOptions: {
      type: 'scatter',
      showLine: false,
      fill: false
    }
  }
  // @toDo: add other chart types
};

export type ChartType = {
  [key in EChartType]?: IChartTypeSettings;
};

export interface IChartTypeSettings {
  datasetOptions: Chart.ChartDataSets;
  options?: Chart.ChartOptions;
}

// @toDo: add MOVING_AVERAGE = 'Moving average' - EIM-1279
export enum EyAxisMetric {
  AVERAGE = 'Average',
  COUNT = 'Count',
  SUM = 'Sum',
  CUMULATIVE_SUM = 'Cumulative sum',
  STANDARD_DEVIATION = 'Standard deviation',
  VALUES = 'Values'
}

export enum EDateTimeGroupBy {
  NO_GROUPING = 'No grouping',
  YEAR = 'Year',
  MONTH = 'Month',
  DATE = 'Date',
  DAY_OF_THE_WEEK = 'Day of the week',
  MONTH_PER_YEAR = 'Month per year'
}

const NO_GROUPING_DATE: Chart.ChartXAxe = {
  offset: true,
  time: { min: '', max: '', unit: 'second', displayFormats: { second: 'DD MMM YYYY' }, tooltipFormat: 'DD MMM YYYY' },
  distribution: 'series',
  ticks: { source: 'data' }
};

const NO_GROUPING_DATETIME: Chart.ChartXAxe = {
  offset: true,
  time: { min: '', max: '', unit: 'second', displayFormats: { second: 'DD MMM YYYY HH:mm' }, tooltipFormat: 'DD MMM YYYY HH:mm' },
  distribution: 'series',
  ticks: { source: 'data' }
};

const YEAR_DATE_DATETIME: Chart.ChartXAxe = {
  offset: true,
  time: { min: '', max: '', unit: 'year', tooltipFormat: 'YYYY' },
  distribution: 'series',
  ticks: { source: 'data' }
};

const MONTH_DATE_DATETIME: Chart.ChartXAxe = {
  offset: true,
  time: { min: '1999-12-15', max: '2000-12-16', unit: 'month', displayFormats: { month: 'MMM' }, tooltipFormat: 'MMM' },
  distribution: 'linear',
  ticks: { source: 'auto' }
};

const DATE_DATE_DATETIME: Chart.ChartXAxe = {
  offset: true,
  time: { min: '', max: '', unit: 'day', displayFormats: { day: 'DD MMM YYYY' }, tooltipFormat: 'DD MMM YYYY' },
  distribution: 'series',
  ticks: { source: 'data' }
};

const DAY_OF_THE_WEEK_DATE_DATETIME: Chart.ChartXAxe = {
  offset: true,
  time: { min: '2019-02-09T12:00:00', max: '2019-02-16T12:00:00', unit: 'day', displayFormats: { day: 'dddd' }, tooltipFormat: 'dddd' },
  distribution: 'linear',
  ticks: { source: 'auto' }
};

const MONTH_PER_YEAR_DATE_DATETIME: Chart.ChartXAxe = {
  offset: true,
  time: { min: '', max: '', unit: 'month', displayFormats: { month: 'MMM YYYY' }, tooltipFormat: 'MMM YYYY' },
  distribution: 'series',
  ticks: { source: 'data' }
};

export const datetimeGroupByXAxisSettings:
  {
    [key in EDateTimeGroupBy]: {
      [FIELD_DATA_TYPE.date]: Chart.ChartXAxe,
      [FIELD_DATA_TYPE.datetime]: Chart.ChartXAxe
    }
  } = {
  [EDateTimeGroupBy.NO_GROUPING]: {
    [FIELD_DATA_TYPE.date]: NO_GROUPING_DATE,
    [FIELD_DATA_TYPE.datetime]: NO_GROUPING_DATETIME
  },
  [EDateTimeGroupBy.YEAR]: {
    [FIELD_DATA_TYPE.date]: YEAR_DATE_DATETIME,
    [FIELD_DATA_TYPE.datetime]: YEAR_DATE_DATETIME
  },
  [EDateTimeGroupBy.MONTH]: {
    [FIELD_DATA_TYPE.date]: MONTH_DATE_DATETIME,
    [FIELD_DATA_TYPE.datetime]: MONTH_DATE_DATETIME
  },
  [EDateTimeGroupBy.DATE]: {
    [FIELD_DATA_TYPE.date]: DATE_DATE_DATETIME,
    [FIELD_DATA_TYPE.datetime]: DATE_DATE_DATETIME
  },
  [EDateTimeGroupBy.DAY_OF_THE_WEEK]: {
    [FIELD_DATA_TYPE.date]: DAY_OF_THE_WEEK_DATE_DATETIME,
    [FIELD_DATA_TYPE.datetime]: DAY_OF_THE_WEEK_DATE_DATETIME
  },
  [EDateTimeGroupBy.MONTH_PER_YEAR]: {
    [FIELD_DATA_TYPE.date]: MONTH_PER_YEAR_DATE_DATETIME,
    [FIELD_DATA_TYPE.datetime]: MONTH_PER_YEAR_DATE_DATETIME
  }
};

export enum ELineDashStyle {
  SOLID = 'Solid',
  SHORT_DASH = 'Short dash',
  SHORT_DOT = 'Short dot',
  SHORT_DASH_DOT = 'Short dash dot',
  SHORT_DASH_DOT_DOT = 'Short dash dot dot',
  DOT = 'Dot',
  DASH = 'Dash',
  LONG_DASH = 'Long dash',
  DASH_DOT = 'Dash dot',
  LONG_DASH_DOT = 'Long dash dot',
  LONG_DASH_DOT_DOT = 'Long dash dot dot'
}

export const DASH_STYLE_OPTIONS = {
  [ELineDashStyle.SOLID]: [],
  [ELineDashStyle.SHORT_DASH]: [5, 5],
  [ELineDashStyle.SHORT_DOT]: [1, 5],
  [ELineDashStyle.SHORT_DASH_DOT]: [5, 5, 1, 5],
  [ELineDashStyle.SHORT_DASH_DOT_DOT]: [5, 5, 1, 5, 1, 5],
  [ELineDashStyle.DOT]: [1, 10],
  [ELineDashStyle.DASH]: [10, 10],
  [ELineDashStyle.LONG_DASH]: [20, 10],
  [ELineDashStyle.DASH_DOT]: [10, 10, 1, 10],
  [ELineDashStyle.LONG_DASH_DOT]: [20, 10, 1, 10],
  [ELineDashStyle.LONG_DASH_DOT_DOT]: [20, 10, 1, 10, 1, 10]
};

export enum EPointMarkerSymbol {
  AUTO = 'Auto',
  CIRCLE = 'Circle',
  SQUARE = 'Square',
  DIAMOND = 'Diamond',
  TRIANGLE = 'Triangle',
  STAR = 'Star'
}

export const POINT_STYLE_OPTIONS: PointOptionsType = {
  [EPointMarkerSymbol.AUTO]: 'circle',
  [EPointMarkerSymbol.CIRCLE]: 'circle',
  [EPointMarkerSymbol.SQUARE]: 'rect',
  [EPointMarkerSymbol.DIAMOND]: 'rectRot',
  [EPointMarkerSymbol.TRIANGLE]: 'triangle',
  [EPointMarkerSymbol.STAR]: 'star'
};

export interface PointOptionsType {
  [key: string]: Chart.PointStyle | HTMLImageElement | HTMLCanvasElement | Array<Chart.PointStyle | HTMLImageElement | HTMLCanvasElement>;
}

export const defaultTickMinMax: Chart.TickOptions[] = [
  {
    suggestedMin: 0,
    suggestedMax: 0
  },
  {
    suggestedMin: 0,
    suggestedMax: 0
  }
];
