<div *ngIf="(avatarURL$ | async) as avatarURL; else showBackup" class="circle-avatar">
  <img [src]="avatarURL">
</div>
<ng-template #showBackup>
  <div
      *ngIf="type !== 'app/organization'"
      class="circle-initials d-flex justify-content-center align-items-center"
      [class.circle-initials-dark-font]="!useLightFont"
      [class.circle-initials-light-font]="useLightFont"
      [style.font-size]="fontSize">{{ initials }}
  </div>
  <div *ngIf="type === 'app/organization'">
    <img class="organization-default-icon pb-2" src="/assets/v1.627.2-beta/assets/img/organization-icon.svg">
  </div>
</ng-template>
