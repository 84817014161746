import { Injectable } from '@angular/core';
import { Tip } from '../data/models/types';
import { from, Observable, of, Subject } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { IObjectAndType } from '../object/object.service';
import { get } from 'lodash';
import { IFormObjectAndType } from './get-object-and-form.service';
import { Router } from '@angular/router';
import { SideSheetService } from '../side-sheet/side-sheet.service';
import { AllObjectTypesService } from '../data/all-object-types/all-object-type.service';
import { FormulaService } from '../data/formula.service';
import { Stringify } from '../object/field-formula-side-sheet/field-formula-side-sheet/formula';


@Injectable({
  providedIn: 'root'
})
export class LifecycleWorkflowRedirectService {

  constructor(
    private allObjectTypesService: AllObjectTypesService,
    private router: Router,
    private sideSheetService: SideSheetService,
    private formulaService: FormulaService
  ) { }

  redirectViaOutletWithFormAndObject(formObjectAndType: IFormObjectAndType, preview?: boolean):
    Observable<IFormObjectAndType> {
    const objectTip: Tip = get(formObjectAndType, 'objectAndType.objectData.$tip', null);
    const objectTypeTip: Tip = get(formObjectAndType, 'objectAndType.objectType.$tip', null);

    // types like supertype may not have a tip
    if (!objectTypeTip) {
      return of(formObjectAndType);
    }

    // creation
    if (!objectTip) {
      return this
        .checkHasLifecycleWorkflow(objectTypeTip)
        .pipe(
          map(hasLifecycleWorkflow => {
            if (hasLifecycleWorkflow && !preview) {
              this.navigateToWorkflowLauncher(objectTypeTip);
            }
            return formObjectAndType;
          })
        );
    }

    // editing
    return this
      .getWorkflowRuntime(objectTip)
      .pipe(
        map(workflowRuntimeTip => {
          if (workflowRuntimeTip) {
            this.navigateToWorkflowViaOutlet(workflowRuntimeTip);
          }
          return formObjectAndType;
        })
      );
  }

  redirectWithObject(object: IObjectAndType): Observable<{ object: IObjectAndType, redirected: boolean }> {
    return this.withObject({ object, viaOutlet: false });
  }

  redirectViaOutletWithObject(
    object?: IObjectAndType, cancel$?: Subject<any>
  ): Observable<{ object: IObjectAndType, redirected: boolean }> {
    return this.withObject({ object, viaOutlet: true, cancel$ });
  }

  redirectViaOutletWithObjectTip(objectTip: Tip, replaceUrl = false): Observable<boolean> {
    return this.withObject({ viaOutlet: true, objectTip, replaceUrl }).pipe(map(({ redirected }) => redirected));
  }

  private withObject(
    { object, viaOutlet, cancel$, objectTip, replaceUrl }
      : { object?: IObjectAndType, viaOutlet: boolean, cancel$?: Subject<any>, objectTip?: Tip, replaceUrl?: boolean }
  ): Observable<{ object: IObjectAndType, redirected: boolean }> {
    const tip = get(object, 'objectData.$tip', objectTip);

    if (!tip) {
      return of({ object, redirected: false });
    }

    return this
      .getWorkflowRuntime(tip)
      .pipe(
        map(workflowRuntimeTip => {
          if (workflowRuntimeTip && viaOutlet) {
            this.navigateToWorkflowViaOutlet(workflowRuntimeTip, replaceUrl);
            if (cancel$) {
              cancel$.next();
            }
            return { object, redirected: true };
          }
          if (workflowRuntimeTip && !viaOutlet) {
            this.navigateToWorkflow(workflowRuntimeTip);
            if (cancel$) {
              cancel$.next();
            }
            return { object, redirected: true };
          }
          return { object, redirected: false };
        })
      );
  }


  private checkHasLifecycleWorkflow(objectTypeTip: Tip): Observable<boolean> {
    return this
      .allObjectTypesService
      .getLifecycleWorkflowFromTypeTip$(objectTypeTip)
      .pipe(map(Boolean));
  }

  getWorkflowRuntime(objectTip: Tip): Observable<Tip> {
    if (!objectTip) {
      return of(null);
    }

    return this
      .formulaService
      .evaluate(Stringify({
        name: 'FIELD',
        args: ['app/object-stage-state:workflow-runtime', {
          name: 'REFERENCES',
          args: ['app/object-stage-state:object', objectTip]
        }]
      }))
      .pipe(map(([tip]) => tip));
  }

  private navigateToWorkflow(workflowRuntimeTip: Tip): Observable<boolean> {
    const redirectLocation = ['app', 's', 'workflow', workflowRuntimeTip];
    return from(this.router.navigate(redirectLocation));
  }

  private navigateToWorkflowViaOutlet(workflowRuntimeTip: Tip, replaceUrl = true): Observable<boolean> {
    // clear side sheet so we can push this outlet correctly
    this.sideSheetService.clear();

    // Note: this outlet also gets triggered in object-workflow-launcher.component.ts & workflow-panel.component.ts
    const redirectLocation = ['app', 's', { outlets: { sidesheet: ['workflow', workflowRuntimeTip] } }];
    return from(this.router.navigate(redirectLocation, { replaceUrl }));
  }

  private navigateToWorkflowLauncher(objectTip: Tip): Observable<boolean> {
    this.sideSheetService.clear();

    const redirectLocation = ['app', 's', { outlets: { sidesheet: ['object', 'workflow-launcher', objectTip] } }];
    return from(this.router.navigate(redirectLocation, { replaceUrl: true }));
  }
}

