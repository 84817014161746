var _a;
import { reverseOperator } from './operators';
import { OPERATOR_TYPE } from '../../../models/query';
var ɵ0 = function (leftArg, rightArg) { return ({
    name: 'EQUALS',
    args: [
        { name: 'DATE_DIFF', args: [leftArg, rightArg, 'seconds'] },
        0
    ]
}); };
var datetimeEquals = {
    opNo: 2,
    toFormula: ɵ0
};
var datetimeNotEquals = reverseOperator(datetimeEquals);
var ɵ1 = function (leftArg, rightArg) { return ({
    name: 'OP',
    args: [
        { name: 'DATE_DIFF', args: [leftArg, { name: 'FIRST', args: [rightArg] }, 'seconds'] },
        '<',
        0
    ]
}); };
var datetimeLessThan = {
    opNo: 2,
    toFormula: ɵ1
};
var datetimeGreaterThanOrEqualTo = reverseOperator(datetimeLessThan);
var ɵ2 = function (leftArg, rightArg) { return ({
    name: 'OP',
    args: [
        { name: 'DATE_DIFF', args: [leftArg, { name: 'LAST', args: [rightArg] }, 'seconds'] },
        '>',
        0
    ]
}); };
var datetimeGreaterThan = {
    opNo: 2,
    toFormula: ɵ2
};
var datetimeLessThanOrEqualTo = reverseOperator(datetimeGreaterThan);
var ɵ3 = function (leftArg, rightArg) { return ({
    name: 'OP',
    args: [
        datetimeGreaterThanOrEqualTo.toFormula(leftArg, rightArg),
        '&&',
        datetimeLessThanOrEqualTo.toFormula(leftArg, rightArg)
    ]
}); };
var datetimeWithIn = {
    opNo: 2,
    toFormula: ɵ3
};
var datetimeNotWithIn = reverseOperator(datetimeWithIn);
export var datetimeOperators = {
    operators: (_a = {},
        _a[OPERATOR_TYPE.EQUALS] = datetimeEquals,
        _a[OPERATOR_TYPE.NOT_EQUALS] = datetimeNotEquals,
        _a[OPERATOR_TYPE.WITHIN] = datetimeWithIn,
        _a[OPERATOR_TYPE.NOT_WITHIN] = datetimeNotWithIn,
        _a[OPERATOR_TYPE.LESS_THAN] = datetimeLessThan,
        _a[OPERATOR_TYPE.GREATER_THAN] = datetimeGreaterThan,
        _a[OPERATOR_TYPE.LESS_THAN_EQUAL_TO] = datetimeLessThanOrEqualTo,
        _a[OPERATOR_TYPE.GREATER_THAN_EQUAL_TO] = datetimeGreaterThanOrEqualTo,
        _a),
    getParts: function (formula) {
        var currentPart = formula;
        var negative = false;
        if (currentPart.name === 'NOT') {
            currentPart = currentPart.args[0];
            negative = true;
        }
        if (currentPart.name === 'OP') {
            if (currentPart.args[1] === '<') {
                return {
                    operator: negative ? OPERATOR_TYPE.GREATER_THAN_EQUAL_TO : OPERATOR_TYPE.LESS_THAN,
                    leftArg: currentPart.args[0].args[0],
                    rightArg: currentPart.args[0].args[1].args[0]
                };
            }
            if (currentPart.args[1] === '>') {
                return {
                    operator: negative ? OPERATOR_TYPE.LESS_THAN_EQUAL_TO : OPERATOR_TYPE.GREATER_THAN,
                    leftArg: currentPart.args[0].args[0],
                    rightArg: currentPart.args[0].args[1].args[0]
                };
            }
            if (currentPart.args[1] === '&&') {
                return {
                    operator: negative ? OPERATOR_TYPE.NOT_WITHIN : OPERATOR_TYPE.WITHIN,
                    leftArg: currentPart.args[0].args[0].args[0].args[0],
                    rightArg: currentPart.args[0].args[0].args[0].args[1].args[0]
                };
            }
            throw new Error('Unknown operators:' + currentPart.name);
        }
        if (currentPart.name === 'EQUALS') {
            return {
                operator: negative ? OPERATOR_TYPE.NOT_EQUALS : OPERATOR_TYPE.EQUALS,
                leftArg: currentPart.args[0].args[0],
                rightArg: currentPart.args[0].args[1].args[0]
            };
        }
        throw new Error('Unknown operators: ' + currentPart.name);
    }
};
export { ɵ0, ɵ1, ɵ2, ɵ3 };
