import { Inject, Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { take, retry, map, first, switchMap } from 'rxjs/operators';
import { UploadInput } from 'ngx-uploader';
import { isString } from 'lodash';

import { environment } from '../../environments/environment';
import { MyProfileService } from '../shell/services/my-profile.service';
import { SAVE_AS, WINDOW } from '../dom-tokens';
import { FormulaService } from './formula.service';
import { TVariant, SecurityService } from './security.service';
import { Tip } from './models/types';
import { ISessionInfo, SessionManagerService } from './session-manager.service';

const mediaServicePath = environment.host + environment.mediaServicePath;
export const MAX_FILE_UPLOAD_SIZE = 1024 * 1024 * 15; // 15MiB

export interface IFileInfo {
  name: string;
  size: number;
  mimeType: string;
  data?: string;
}

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  sessionInfo: ISessionInfo;

  private readonly previewUriCache: { [fileTip: string]: Blob } = {};
  private readonly fullSizeImageUriCache: { [fileTip: string]: Blob } = {};

  constructor(
    private http: HttpClient,
    private sessionManager: SessionManagerService,
    private domSanitizer: DomSanitizer,
    private formulaService: FormulaService,
    private router: Router,
    private securityService: SecurityService,
    private myProfileService: MyProfileService,
    @Inject(SAVE_AS) private saveAs: (data: Blob | string, filename?: string, disableAutoBOM?: boolean) => void,
    @Inject(WINDOW) private window: Window
  ) {
    this.sessionManager.getSessionInfo$().pipe(
      retry(3)
    ).subscribe(
      sessionInfo => this.sessionInfo = sessionInfo
    );
  }

  // Creates and emits on an upload input object for a given object security policy variant
  createUploadInputWithSecurityPolicy(
    primaryObjectTip?: Tip,
    primarySecurityPolicyTip?: Tip,
    variant: TVariant | 'primary' = 'primary',
    fileSecurityPolicyTip?: Tip
  ): Observable<UploadInput> {
    if (isString(fileSecurityPolicyTip)) {
      return of(this.createUploadInput(fileSecurityPolicyTip));
    }

    // If no primary object or security policy is passed, use the user's profile's default security policy as the primary
    let primarySecurityPolicyTip$ = of(primarySecurityPolicyTip);

    if (!primaryObjectTip && !primarySecurityPolicyTip) {
      primarySecurityPolicyTip$ = this.myProfileService.getMyProfile$().pipe(
        map(profile => profile.defaultPolicy)
      );
    }

    return primarySecurityPolicyTip$.pipe(
      switchMap(policyTip => {
        // If we actually want the primary then return it
        if (variant === 'primary') {
          return of(policyTip);
        }
        // Otherwise ask the security service for a variant
        return this.securityService.getSecurityPolicyVariantTip$(variant, primaryObjectTip, policyTip);
      }),
      map(uploadSecurityPolicyTip => this.createUploadInput(uploadSecurityPolicyTip))
    );
  }

  // Return an UploadInput object for ngx-uploader when creating new files
  createUploadInput(securityPolicyTip: string): UploadInput {
    return {
      type: 'uploadAll',
      url: mediaServicePath + '/file?ns=' + escape(environment.ns) + '&security=' + encodeURIComponent(escape(securityPolicyTip)),
      method: 'POST',
      headers: this.getAuthHeaders(),
      withCredentials: true
    };
  }

  // Return an UploadInput object for ngx-uploader when replacing existing files, not used right now.
  // replaceUploadInput(fileTip: string): UploadInput {
  //   return {
  //     type: 'uploadAll',
  //     url: mediaServicePath + '/replace?&ns=' + escape(environment.ns) + '&tip=' + encodeURIComponent(fileTip),
  //     method: 'POST',
  //     headers: this.getAuthHeaders()
  //   };
  // }

  // Retrieves the authorization headers
  getAuthHeaders() {
    if (!this.sessionInfo) {
      throw new Error('Attempting to authorize against the media service without a session token');
    }
    return { Authorization: 'Bearer ' + this.sessionInfo.token };
  }

  // Returns information about a file
  fileInfo(fileTip: string): Observable<IFileInfo> {
    const formulaStr = 'ARRAY(FIELD("file/name"),FIELD("file/mime"),FIELD("file/size"),FIELD("file/data"))';
    return this.formulaService.evaluate(formulaStr, fileTip).pipe(
      take(1),
      map((fileData: string[]) => ({
        name: fileData[0],
        mimeType: fileData[1],
        size: parseInt(fileData[2], 10),
        data: fileData[3]
      }))
    );
  }

  // Downloads a file
  download(fileTip: string, name?: string) {
    return this.http.get(
      mediaServicePath + '/download?tip=' + encodeURIComponent(fileTip) + '&ns=' + encodeURIComponent(environment.ns),
      {
        headers: this.getAuthHeaders(),
        responseType: 'blob',
        withCredentials: true
      }
    ).pipe(
      take(1)
    ).subscribe(
      blob => this.saveAs(blob, name)
    );
  }

  // Preview the file using a lightbox
  preview(fileTip: string, name?: string) {
    const path = ['preview', fileTip];
    if (name) {
      path.push(name);
    }
    this.router.navigate(['app', 's', { outlets: { preview: path } }]);
  }

  // Returns the original URI (not a blob URI)
  originalPreviewUri(fileTip: string, thumbnailWidth?: number): string {
    const url = mediaServicePath + '/preview?tip=' + encodeURIComponent(fileTip) + '&ns=' + encodeURIComponent(environment.ns);
    if (thumbnailWidth && thumbnailWidth > 0) {
      return (url + '&w=' + thumbnailWidth);
    } else {
      return url;
    }
  }

  // Returns the URI for preview of a given the file
  previewUri(fileTip: string, isBackground = false, thumbnailWidth?: number): Observable<SafeUrl> {
    if (this.previewUriCache.hasOwnProperty(fileTip)) {
      const blob: Blob = this.previewUriCache[fileTip];
      return of(this.getUri(blob, isBackground));
    }

    return this.getImagebyTip(fileTip, isBackground, false, thumbnailWidth);
  }

  /*
  * use this method to show full size images
   */
  previewUriForFullImage(fileTip: string, isBackground = false): Observable<SafeUrl> {
    if (this.fullSizeImageUriCache.hasOwnProperty(fileTip)) {
      const blob: Blob = this.fullSizeImageUriCache[fileTip];
      return of(this.getUri(blob, isBackground));
    }

    return this.getImagebyTip(fileTip, isBackground, true, -1); // negative width ensures that this argument would be ignored
  }

  private getImagebyTip(fileTip: string, isBackground = false, fullSize: boolean, thumbnailWidth: number): Observable<SafeUrl> {
    return this.http.get(
      this.originalPreviewUri(fileTip, thumbnailWidth),
      {
        headers: this.getAuthHeaders(),
        responseType: 'blob',
        withCredentials: true
      }
    ).pipe(
      first(),
      map(
        (blob: Blob) => {
          fullSize === true ? this.fullSizeImageUriCache[fileTip] = blob : this.previewUriCache[fileTip] = blob;
          return this.getUri(blob, isBackground);
        }
      )
    );
  }

  private getUri(blob: Blob, isBackground: boolean) {
    return isBackground ?
      this.domSanitizer.bypassSecurityTrustStyle(`url('${this.window.URL.createObjectURL(blob)}')`) :
      this.domSanitizer.bypassSecurityTrustUrl(this.window.URL.createObjectURL(blob));
  }
}
