// !!!If you add new type to this list, please also add type/triggers to it!!!
import { Tip } from '../../data/models/types';

export interface ISystemType {
    $tip: Tip;
    label: string;
    userSelectable: boolean;
}

export const APP_PLAN_TIP = 'app/plan';
export const APP_PLAN_TIP_LABEL = 'Plans';

export const APP_CHECKLIST_TIP = 'app/checklist';
export const APP_CHECKLIST_TIP_LABEL = 'Check lists';

export const APP_GROUP_TIP = 'app/group';
export const APP_GROUP_TIP_LABEL = 'Groups';

export const APP_CHECKLIST_ITEM_TIP = 'app/checklistitem';
export const APP_CHECKLIST_ITEM_TIP_LABEL = 'Check list items';

export const APP_DOCUMENT_TIP = 'app/document';
export const APP_DOCUMENT_TIP_LABEL = 'Documents';

export const APP_EMAIL_INBOUND_TIP = 'app/email/inbound';
export const APP_EMAIL_INBOUND_TIP_LABEL = 'Inbound emails';

export const APP_EMAIL_OUTBOUND_TIP = 'app/email/outbound';
export const APP_EMAIL_OUTBOUND_TIP_LABEL = 'Outbound emails';

export const APP_SMS_INBOUND_TIP = 'app/sms/inbound';
export const APP_SMS_INBOUND_TIP_LABEL = 'Inbound SMS';

export const APP_SMS_OUTBOUND_TIP = 'app/sms/outbound';
export const APP_SMS_OUTBOUND_TIP_LABEL = 'Outbound SMS';

export const APP_TEAM_TIP = 'app/team';
export const APP_TEAM_TIP_LABEL = 'Teams';

export const APP_TEAM_ROLE_TIP = 'app/team-role';
export const APP_TEAM_ROLE_TIP_LABEL = 'Roles in teams';

export const APP_USER_TIP = 'app/user';
export const APP_USER_TIP_LABEL = 'Users';

export const APP_PROFILE_TIP = 'app/profile';
export const APP_PROFILE_TIP_LABEL = 'Profiles';

export const APP_TIMELINE_POST_TIP = 'app/timeline-post';
export const APP_TIMELINE_POST_TIP_LABEL = 'Timeline posts';

export const USER_SELECTABLE_SYSTEM_TYPES: ISystemType[] = [
    { $tip: APP_PLAN_TIP, label: APP_PLAN_TIP_LABEL, userSelectable: true},
    { $tip: APP_CHECKLIST_TIP, label: APP_CHECKLIST_TIP_LABEL, userSelectable: true },
    { $tip: APP_CHECKLIST_ITEM_TIP, label: APP_CHECKLIST_ITEM_TIP_LABEL, userSelectable: true },
    { $tip: APP_DOCUMENT_TIP, label: APP_DOCUMENT_TIP_LABEL, userSelectable: true },
    { $tip: APP_GROUP_TIP, label: APP_GROUP_TIP_LABEL, userSelectable: true },
    { $tip: APP_EMAIL_INBOUND_TIP, label: APP_EMAIL_INBOUND_TIP_LABEL, userSelectable: true },
    { $tip: APP_EMAIL_OUTBOUND_TIP, label: APP_EMAIL_OUTBOUND_TIP_LABEL, userSelectable: true },
    { $tip: APP_SMS_INBOUND_TIP, label: APP_SMS_INBOUND_TIP_LABEL, userSelectable: true },
    { $tip: APP_SMS_OUTBOUND_TIP, label: APP_SMS_OUTBOUND_TIP_LABEL, userSelectable: true },
    { $tip: APP_TEAM_TIP, label: APP_TEAM_TIP_LABEL, userSelectable: true },
    { $tip: APP_TEAM_ROLE_TIP, label: APP_TEAM_ROLE_TIP_LABEL, userSelectable: true },
    { $tip: APP_USER_TIP, label: APP_USER_TIP_LABEL, userSelectable: true },
    { $tip: APP_PROFILE_TIP, label: APP_PROFILE_TIP_LABEL, userSelectable: true },
    { $tip: APP_TIMELINE_POST_TIP, label: APP_TIMELINE_POST_TIP_LABEL, userSelectable: true },
];

export const NON_USER_SELECTABLE_SYSTEM_TYPES: ISystemType[] = [
    { $tip: 'app/email/recipient', label: 'Email recipient', userSelectable: false},
    { $tip: 'app/sms/recipient', label: 'Email recipient', userSelectable: false},
    { $tip: 'app/sms/outbound-template', label: 'Template', userSelectable: false},
    { $tip: 'app/email/outbound-template', label: 'Template', userSelectable: false},
    { $tip: 'app/sso/saml/idp', label: 'SAML identity provider', userSelectable: false},
    { $tip: 'app/x509', label: 'x509', userSelectable: false},
    { $tip: 'app/sms/outbound-template', label: 'Template', userSelectable: false},
    { $tip: 'app/au-gov/protective-marking', label: 'Protective marking', userSelectable: false},
    { $tip: 'app/au-gov/protective-markings-settings', label: 'Protective marking settings', userSelectable: false},
    { $tip: 'app/general-security-settings', label: 'General security settings', userSelectable: false},
    { $tip: 'app/api-key-meta', label: 'API key', userSelectable: false},
    { $tip: 'number-format', label: 'Number format', userSelectable: false},
    { $tip: 'app/branding-settings', label: 'Branding', userSelectable: false},
    { $tip: 'app/type/locale', label: 'Locale', userSelectable: false},
    { $tip: 'app/voice-message/recipient', label: 'Voice message recipient', userSelectable: false},
    { $tip: 'app/voice-message/outbound-template', label: 'Template', userSelectable: false},
];

export const SYSTEM_TYPES: ISystemType[] = [
  ...USER_SELECTABLE_SYSTEM_TYPES,
  ...NON_USER_SELECTABLE_SYSTEM_TYPES
];

let systemTypesMap = null;

export function getSystemTypesMap(): Map<Tip, ISystemType> {
    if (!systemTypesMap) {
        systemTypesMap = SYSTEM_TYPES.reduce((acc, systemType) => {
            acc.set(systemType.$tip, systemType);
            return acc;
        }, new Map());
    }
    return systemTypesMap;
}
