import { Injectable } from '@angular/core';
import { Tip } from '../data/models/types';
import { Observable } from 'rxjs';
import { AllObjectTypesService } from '../data/all-object-types/all-object-type.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ObjectLaunchFormulasService {

  // todo move some of the formulas out of get-object-and-form
  constructor(
    private allObjectTypesService: AllObjectTypesService,
  ) { }

  isRestrictedSystemType$(typeTip: Tip): Observable<boolean> {
    return this.allObjectTypesService.objectTypeInCache$(typeTip)
      .pipe(map(result => !result));
  }
}

