import * as tslib_1 from "tslib";
import { FieldType } from '@ngx-formly/core';
var FormlyFieldMultiLineTextComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FormlyFieldMultiLineTextComponent, _super);
    function FormlyFieldMultiLineTextComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.classes = 'flex-fill';
        return _this;
    }
    return FormlyFieldMultiLineTextComponent;
}(FieldType));
export { FormlyFieldMultiLineTextComponent };
