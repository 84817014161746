import * as tslib_1 from "tslib";
import { FieldType } from '@ngx-formly/core';
var FormlyFieldAddressChooserComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FormlyFieldAddressChooserComponent, _super);
    function FormlyFieldAddressChooserComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return FormlyFieldAddressChooserComponent;
}(FieldType));
export { FormlyFieldAddressChooserComponent };
