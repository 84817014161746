/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./switch-profile-side-sheet.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/alert/alert.component.ngfactory";
import * as i3 from "../../shared/alert/alert.component";
import * as i4 from "../../side-sheet/side-sheet-list-item/side-sheet-list-item.component.ngfactory";
import * as i5 from "../../side-sheet/side-sheet-list-item/side-sheet-list-item.component";
import * as i6 from "../../shared/filter.pipe";
import * as i7 from "../../side-sheet/side-sheet-header/side-sheet-header.component.ngfactory";
import * as i8 from "../../side-sheet/side-sheet-header/side-sheet-header.component";
import * as i9 from "../../side-sheet/side-sheet.service";
import * as i10 from "@angular/common";
import * as i11 from "../../shared/spinner/spinner.directive";
import * as i12 from "../../side-sheet/side-sheet-section/side-sheet-section.component.ngfactory";
import * as i13 from "../../side-sheet/side-sheet-section/side-sheet-section.component";
import * as i14 from "../../shared/filter-and-add/filter-and-add.component.ngfactory";
import * as i15 from "../../shared/filter-and-add/filter-and-add.component";
import * as i16 from "./switch-profile-side-sheet.component";
import * as i17 from "../../data/session-manager.service";
import * as i18 from "../services/my-profile.service";
var styles_SwitchProfileSideSheetComponent = [i0.styles];
var RenderType_SwitchProfileSideSheetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SwitchProfileSideSheetComponent, data: {} });
export { RenderType_SwitchProfileSideSheetComponent as RenderType_SwitchProfileSideSheetComponent };
function View_SwitchProfileSideSheetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-alert", [["type", "danger"]], null, null, null, i2.View_AlertComponent_0, i2.RenderType_AlertComponent)), i1.ɵdid(1, 114688, null, 0, i3.AlertComponent, [], { type: [0, "type"] }, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { var currVal_0 = "danger"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.errorMessage; _ck(_v, 2, 0, currVal_1); }); }
function View_SwitchProfileSideSheetComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-side-sheet-list-item", [["toggleType", "single-check"]], null, [[null, "toggle"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("toggle" === en)) {
        var pd_0 = (_co.onToggle(_v.context.$implicit.$tip) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_SideSheetListItemComponent_0, i4.RenderType_SideSheetListItemComponent)), i1.ɵdid(1, 114688, null, 0, i5.SideSheetListItemComponent, [i1.ChangeDetectorRef], { toggleType: [0, "toggleType"], value: [1, "value"] }, { toggle: "toggle" }), (_l()(), i1.ɵted(2, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "single-check"; var currVal_1 = (_co.selected === _v.context.$implicit.$tip); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_2); }); }
export function View_SwitchProfileSideSheetComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.FilterPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-side-sheet-header", [], null, null, null, i7.View_SideSheetHeaderComponent_0, i7.RenderType_SideSheetHeaderComponent)), i1.ɵdid(2, 245760, null, 0, i8.SideSheetHeaderComponent, [i9.SideSheetService], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵted(-1, 0, ["Switch profile\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SwitchProfileSideSheetComponent_1)), i1.ɵdid(5, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 11, "div", [], null, null, null, null, null)), i1.ɵdid(7, 212992, null, 0, i11.SpinnerDirective, [i1.Renderer2, i1.ElementRef], { showSpinner: [0, "showSpinner"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 3, "app-side-sheet-section", [], null, null, null, i12.View_SideSheetSectionComponent_0, i12.RenderType_SideSheetSectionComponent)), i1.ɵdid(9, 49152, null, 0, i13.SideSheetSectionComponent, [], { showBorder: [0, "showBorder"] }, null), (_l()(), i1.ɵeld(10, 0, null, 3, 1, "app-filter-and-add", [], null, [[null, "filterTextChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filterTextChange" === en)) {
        var pd_0 = ((_co.filterText = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_FilterAndAddComponent_0, i14.RenderType_FilterAndAddComponent)), i1.ɵdid(11, 114688, null, 0, i15.FilterAndAddComponent, [], { filterText: [0, "filterText"] }, { filterTextChange: "filterTextChange" }), (_l()(), i1.ɵeld(12, 0, null, null, 5, "app-side-sheet-section", [["title", "Profiles"]], null, null, null, i12.View_SideSheetSectionComponent_0, i12.RenderType_SideSheetSectionComponent)), i1.ɵdid(13, 49152, null, 0, i13.SideSheetSectionComponent, [], { title: [0, "title"] }, null), (_l()(), i1.ɵand(16777216, null, 3, 3, null, View_SwitchProfileSideSheetComponent_2)), i1.ɵdid(15, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpad(16, 1), i1.ɵppd(17, 3)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((!_co.selected || !_co.selected.length) || (_co.selected === _co.myProfile.$tip)); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.loadingState === _co.loadingStates.failed); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.loadingState === _co.loadingStates.inProgress); _ck(_v, 7, 0, currVal_2); var currVal_3 = false; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.filterText; _ck(_v, 11, 0, currVal_4); var currVal_5 = "Profiles"; _ck(_v, 13, 0, currVal_5); var currVal_6 = i1.ɵunv(_v, 15, 0, _ck(_v, 17, 0, i1.ɵnov(_v, 0), _co.myProfiles, _co.filterText, _ck(_v, 16, 0, "name"))); _ck(_v, 15, 0, currVal_6); }, null); }
export function View_SwitchProfileSideSheetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-switch-profile-side-sheet", [], null, null, null, View_SwitchProfileSideSheetComponent_0, RenderType_SwitchProfileSideSheetComponent)), i1.ɵdid(1, 245760, null, 0, i16.SwitchProfileSideSheetComponent, [i17.SessionManagerService, i18.MyProfileService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SwitchProfileSideSheetComponentNgFactory = i1.ɵccf("app-switch-profile-side-sheet", i16.SwitchProfileSideSheetComponent, View_SwitchProfileSideSheetComponent_Host_0, {}, {}, []);
export { SwitchProfileSideSheetComponentNgFactory as SwitchProfileSideSheetComponentNgFactory };
