import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { get, intersection, isEmpty } from 'lodash';
import { IQueryExtraInfo } from '../../../data/query.service';
import { ContextTypeFieldOptions } from '../../context-type-field-option-utils/context-type-field-option-utils';
import { IQueryAndFilterOptions } from '../../../query/query-chooser/query-chooser.component';
import { EXTENDED_FIELD_ACTUAL_TYPE_MAP, EXTENDED_FIELD_DATA_TYPE } from '../../../models/field';
import { Tip } from '../../../data/models/types';
import { AllObjectTypesService, AllTypeMetaMap } from '../../../data/all-object-types/all-object-type.service';
import { Subscription } from 'rxjs';
import { checkIfTypeImplementsHierarchicalType } from '../../../util/forms/formly-fields-util';

@Component({
    selector: 'app-object-chooser',
  template: `
    <ng-container [ngSwitch]="viewMode">
      <ng-container *ngSwitchCase="'single'">
        <app-single-object-chooser-field
          [placeholder]="to.placeholder"
          [formControl]="formControl"
          [label]="to.label"
          [required]="to.required"
          [typeTips]="to['typeTips'] || typeTips"
          [emitStringNotArray]="true"
          [queryAndFilter]="queryAndFilter"
          [extraFilters]="extraFilters"
          [readonly]="to['readonly']"
          [allowUserToAdd]="canCreateNew">
        </app-single-object-chooser-field>
      </ng-container>
      <ng-container *ngSwitchCase="'multi'">
        <app-multi-object-chooser-field
          [formControl]="formControl"
          [label]="to.label"
          [required]="to.required"
          [allowNavAway]="allowNavAway"
          [canCreateNew]="canCreateNew"
          [typeTips]="to['typeTips'] || typeTips"
          [queryAndFilter]="queryAndFilter"
          [extraFilters]="extraFilters"
          [readonly]="to['readonly']">
        </app-multi-object-chooser-field>
    </ng-container>
    <ng-container *ngSwitchCase="'hierarchical'">
      <app-single-hierarchical-object-chooser-field
          [formControl]="formControl"
          [label]="to.label"
          [placeholder]="to.placeholder"
          [required]="to.required"
          [typeTip]="hierarchyFieldTip"
          [parentFieldTip]="hierarchyParentFieldTip"
          [readonly]="to['readonly']">
      </app-single-hierarchical-object-chooser-field>
    </ng-container>
  </ng-container>
  `
})

export class FormlyFieldObjectChooserComponent extends FieldType implements OnInit, OnDestroy {
  single = true;
  contextTypeOptions: ContextTypeFieldOptions;
  typeTips: string[] = [];
  queryAndFilter: IQueryAndFilterOptions | {} = {};
  extraFilters: IQueryExtraInfo[] = [];

  allowNavAway = true;
  canCreateNew = true;
  viewMode: 'single' | 'multi' | 'hierarchical';
  hierarchyFieldTip: string;
  hierarchyParentFieldTip: string;

  allTypes$: Subscription;
  allTypes: AllTypeMetaMap;

  constructor(
      private allObjectTypesService: AllObjectTypesService
  ) {
    super();
    this.allTypes$ = this.allObjectTypesService.allTypes$.subscribe((value) => {
      this.allTypes = value;
    });
  }

  ngOnInit(): void {
    this.contextTypeOptions = get(this, 'formState.contextTypeOptions', null);
    this.setViaContextParams();
  }

  ngOnDestroy() {
    this.allTypes$.unsubscribe();
  }

  setViaContextParams() {
    const contextTypeTip = get(this, 'field.templateOptions.contextTypeTip', null);
    const contextTypeOption = this.contextTypeOptions
        ? this.contextTypeOptions.values.get(contextTypeTip)
        : null;

    const isMulti = get(this, 'field.templateOptions.multi', false);

    if (isMulti || (contextTypeOption && contextTypeOption.maxcount !== 1)) {
      this.single = false;
      this.viewMode = 'multi';
    }

    this.typeTips = get(contextTypeOption, 'typerestrict', []);
    this.queryAndFilter = get(this, 'field.templateOptions.filter', {});
    this.extraFilters = get(this, 'field.templateOptions.extraFilters', []);
    this.allowNavAway = get(this, 'field.templateOptions.allowNavAway', true);
    this.canCreateNew = !get(this, 'field.templateOptions.hidePlusButton', false);

    const isHierarchical = this.checkIfTypeIsHierarchical(this.typeTips);
    const configuredForHierarchicalView = get(this, 'field.templateOptions.hierarchicalView', 'Flat') === 'Hierarchical';

    if (this.single) {
      if (isEmpty(this.queryAndFilter) && configuredForHierarchicalView && isHierarchical) {
        this.viewMode = 'hierarchical';
        this.hierarchyFieldTip = this.typeTips && this.typeTips.length > 0 ? this.typeTips[0] : null;
        this.hierarchyParentFieldTip = this.hierarchyFieldTip ? this.getParentFieldTip(this.typeTips[0]) : null;
      } else {
        this.viewMode = 'single';
      }
    }
  }

  private getParentFieldTip(fieldTip: Tip) {
    if (EXTENDED_FIELD_ACTUAL_TYPE_MAP[EXTENDED_FIELD_DATA_TYPE.hierarchical].typeRestrict.includes(fieldTip)) {
      return `${fieldTip}:parent`;
    } else if (this.allTypes) {
      const typeFromMetaMap = this.allTypes.get(fieldTip);
      if (typeFromMetaMap) {
        const typeImplemented = typeFromMetaMap.implementsSuperTypes;
        if (typeImplemented && typeImplemented.length) {
          const parentTip = typeImplemented.find((type) => {
            return EXTENDED_FIELD_ACTUAL_TYPE_MAP[EXTENDED_FIELD_DATA_TYPE.hierarchical].typeRestrict.includes(type);
          });
          if (parentTip) {
            return `${parentTip}:parent`;
          }
        }
      }
      return null;
    }
  }

  private checkIfTypeIsHierarchical(typeRestricts: Array<string>): boolean {
    if (typeRestricts && typeRestricts.length === 1) {
      const isNogginDefinedHierarchical = intersection(typeRestricts,    // check if its a noggin defined hierarchical type
          EXTENDED_FIELD_ACTUAL_TYPE_MAP[EXTENDED_FIELD_DATA_TYPE.hierarchical].typeRestrict).length > 0;
      if (isNogginDefinedHierarchical) {
        return true;
      } else if (this.allTypes) {
        const typeRestrict = typeRestricts[0]; // just get the first element
        const typeFromMetaMap = this.allTypes.get(typeRestrict);
        if (typeFromMetaMap) {
          return checkIfTypeImplementsHierarchicalType(typeFromMetaMap);
        }
      }
    }
    return false;
  }
}
