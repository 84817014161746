import * as tslib_1 from "tslib";
import * as createMoment from 'moment';
import { isEmpty, head } from 'lodash';
import { MESSAGE_TYPE } from '../models/message';
import { Parser, Stringify } from '../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { getContextFromContextFormula } from '../util/context-formula-wrapper';
import * as i0 from "@angular/core";
export var recipientTypeToKey = {
    'app/group': 'groups',
    'app/org-unit': 'organizationalUnits',
    'app/person': 'people',
    'app/organization': 'people',
    'app/team': 'teams',
    'app/team-role': 'roles',
    'query': 'filters',
    'app/email/addhoc-address': 'otherContacts',
    'app/sms/addhoc-number': 'otherContacts',
    'app/voice-message/addhoc-number': 'otherContacts'
};
export var customContactsToKey = {
    'customContactType': 'people'
};
export var OTHER_CONTACT_STATUS;
(function (OTHER_CONTACT_STATUS) {
    OTHER_CONTACT_STATUS[OTHER_CONTACT_STATUS["LOADING"] = 0] = "LOADING";
    OTHER_CONTACT_STATUS[OTHER_CONTACT_STATUS["EDITING"] = 1] = "EDITING";
})(OTHER_CONTACT_STATUS || (OTHER_CONTACT_STATUS = {}));
export var SENDING_STATUS;
(function (SENDING_STATUS) {
    SENDING_STATUS[SENDING_STATUS["SENDING"] = 0] = "SENDING";
    SENDING_STATUS[SENDING_STATUS["EDITING"] = 1] = "EDITING";
})(SENDING_STATUS || (SENDING_STATUS = {}));
export var MESSAGE_REQUEST_STATUS;
(function (MESSAGE_REQUEST_STATUS) {
    MESSAGE_REQUEST_STATUS["SENT"] = "Sent";
    MESSAGE_REQUEST_STATUS["FAILED"] = "Failed";
})(MESSAGE_REQUEST_STATUS || (MESSAGE_REQUEST_STATUS = {}));
export var CONTEXT_VAR_NAME = 'Dashboard context tip';
var MessageUtilsService = /** @class */ (function () {
    function MessageUtilsService() {
    }
    MessageUtilsService.generateTemplateFilters = function (contextTypeTips, messageType, innerType) {
        if (messageType === void 0) { messageType = MESSAGE_TYPE.EMAIL; }
        if (innerType === void 0) { innerType = true; }
        var formulaNoContext = "EQUALS(FIELD(\"app/" + messageType + "/outbound-template:context-type\"),ARRAY())";
        if (!isEmpty(contextTypeTips) && !isEmpty(head(contextTypeTips))) {
            var typeFormulas = Stringify({ name: 'ARRAY', args: contextTypeTips });
            if (!innerType) {
                typeFormulas = "ARRAY(" + typeFormulas + ",FIELD_VALUES(\"app/object-type:type\"," + typeFormulas + "))";
            }
            return [{
                    label: 'templates of current or no context',
                    formula: "OR(INARRAY(" + typeFormulas + ",FIELD(\"app/" + messageType + "/outbound-template:context-type\"))," + formulaNoContext + ")"
                }];
        }
        else {
            return [{
                    label: 'templates with no context',
                    formula: formulaNoContext
                }];
        }
    };
    MessageUtilsService.prototype.getEmptyCollection = function () {
        return {
            groups: [], organizationalUnits: [], people: [], teams: [], roles: [], filters: [], otherContacts: [],
            workflowInputs: [], workflowActors: [], workflowVars: []
        };
    };
    MessageUtilsService.prototype.isCollectionEmpty = function (collection) {
        if (!collection) {
            return true;
        }
        return !Object.entries(collection).some(function (_a) {
            var _b = tslib_1.__read(_a, 2), key = _b[0], value = _b[1];
            return value.length;
        });
    };
    MessageUtilsService.prototype.getFlattenedTipsFromCollection = function (collection) {
        return Object.keys(collection).reduce(function (prev, category) {
            return prev.concat(collection[category]);
        }, []);
    };
    MessageUtilsService.prototype.isMessageCapableCollectionEmpty = function (messageCapables) {
        for (var recipientType in messageCapables) {
            if (messageCapables[recipientType].length > 0) {
                return false;
            }
        }
        return true;
    };
    MessageUtilsService.prototype.datetimeToMetaString = function (datetime) {
        var dateFormat = 'DD-MM-YYYY';
        if (datetime.format(dateFormat) === createMoment().format(dateFormat)) {
            return datetime.format('LT');
        }
        return datetime.format('D MMM');
    };
    MessageUtilsService.prototype.addFormulasToCollection = function (collection, formulas) {
        collection.workflowInputs = (formulas || []).filter(function (formula) { return formula.indexOf("VAR(\"Input-") > -1; });
        collection.workflowVars = (formulas || []).filter(function (formula) { return formula.indexOf("VAR(\"Variable-") > -1; });
        collection.workflowActors = (formulas || []).filter(function (formula) { return formula.indexOf("VAR(\"Actor-") > -1; });
    };
    MessageUtilsService.prototype.getWorkflowPropFromFormula = function (formula, propTypeKey, workflowVariables) {
        // get workflow prop key by extracting part in between VAR(" and ")
        var propKey;
        var parsedFormula = Parser(formula);
        if (parsedFormula.name === 'VAR') {
            propKey = parsedFormula.args[0];
        }
        else {
            propKey = Parser(getContextFromContextFormula(formula)).args[0];
        }
        // get input property by key
        return workflowVariables[propTypeKey].find(function (x) { return x.key === propKey; });
    };
    // combines emailable workflow var formulas into single array of formulas 
    MessageUtilsService.prototype.getFlattenedFormulasFromCollection = function (collection) {
        return tslib_1.__spread(collection.workflowInputs || [], collection.workflowVars || [], collection.workflowActors || []);
    };
    MessageUtilsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MessageUtilsService_Factory() { return new MessageUtilsService(); }, token: MessageUtilsService, providedIn: "root" });
    return MessageUtilsService;
}());
export { MessageUtilsService };
