import { Injectable } from '@angular/core';
import { difference, fill, find, findIndex, isString, keys, union } from 'lodash';

import { getFieldI18nValues } from '../../i18n/i18n-value/i18n-value';
import { IList, IListItem } from '../../models/list';
import dataConstants from '../../data/constants';
import { II18n } from '../../data/models/types';
import { IListWithListItemSelectOption, IListItemWithListItemSelectOption } from './list-designer-models';

@Injectable({
  providedIn: 'root'
})
export class ListUtilsService {
  static getListItemIndex(listItems: IListItem[], value: string): number {
    return findIndex(listItems, item => item.value === value);
  }

  static populateListLabels(subject: IList | IListItem, i18ns?: II18n[]): II18n[] {
    // If i18ns was not provided, subject would be the list itself.
    i18ns = i18ns || ((subject as IList).labels || []).map((i18n: II18n) => ({ lang: i18n.lang, value: [] }));

    if (subject.items && subject.items.length) {
      subject.items.forEach((item: IListItem) => {
        item.label = item.label || { [dataConstants.LANG_DEFAULT]: '' };

        if (isString(item.label)) {
          item.label = { [dataConstants.LANG_DEFAULT]: item.label };
        }

        const existingLocaleIds: string[] = i18ns.map((i18n: II18n) => i18n.lang);
        const localeIds: string[] = keys(item.label);
        const newLocaleIds: string[] = difference(localeIds, existingLocaleIds);
        const allLocaleIds: string[] = union(localeIds, existingLocaleIds);
        const numberOfLabels: number = i18ns[0] ? i18ns[0].value.length : 0;

        // For any new locale IDs (not existent in the original "app/list:labels"), populate with ''.
        newLocaleIds.forEach((localeId: string) => {
          i18ns.push({ lang: localeId, value: fill(Array(numberOfLabels), '') });
        });

        // For any existing locale IDs for which item.label doesn't have a value, populate with ''.
        allLocaleIds.forEach((localeId: string) => {
          find(i18ns, ['lang', localeId]).value.push(item.label[localeId] || '');
        });

        this.populateListLabels(item, i18ns);
      });
    }

    return i18ns;
  }

  getListItemDepth(subject: IList | IListItem, currentDepthLevel: number = 1): number {
    let maxDepth = currentDepthLevel;

    if (subject.items && subject.items.length) {
      subject.items.forEach(item => {
        if (item.items && item.items.length) {
          const lvl = this.getListItemDepth(item, currentDepthLevel + 1);
          maxDepth = Math.max(lvl, maxDepth);
        }
      });
    }
    return maxDepth;
  }

  getListItemLabel(list: IList, listItemValue: string): string {
    if (!list.labels || !list.labels.length || !list.values || !list.values.length) {
      return '';
    }

    const valueIndex = list.values.indexOf(listItemValue);

    return valueIndex !== -1 ? getFieldI18nValues(list.labels)[valueIndex] : '';
  }

  populateTallyValues(subject: IListWithListItemSelectOption | IListItemWithListItemSelectOption, selected: string[]) {
    if (subject.items && subject.items.length) {
      subject.items.forEach(item => {
        item.tallyValue = this.populateListItemTallyValue(item, selected);
      });
    }
  }

  private populateListItemTallyValue(subject: IListItem, selected: string[]): number {
    let tallyCount = 0;

    if (subject.items && subject.items.length) {
      subject.items.forEach(item => {
        if (selected.includes(item.value)) {
          tallyCount += 1;
        }
        if (item.items && item.items.length) {
          tallyCount += this.populateListItemTallyValue(item, selected);
        }
      });
    }

    return tallyCount;
  }
}
