var _a;
import { reverseOperator } from './operators';
import { OPERATOR_TYPE } from '../../../models/query';
import { stringEqual, stringNotEqual, stringIsEmpty, stringIsNotEmpty } from './string-operators';
var ɵ0 = function (leftArg, rightArg, meta) {
    var listTip = meta && meta.fieldTip ? getListTipFormulaSpec(meta.fieldTip) : null;
    return {
        name: 'HAS_INTERSECT',
        args: [
            leftArg,
            {
                name: 'ARRAY',
                args: [
                    rightArg,
                    {
                        name: 'JSON_DESCENDANTS',
                        args: [
                            {
                                name: 'CONCAT',
                                args: [
                                    '[',
                                    {
                                        name: 'CONCAT_WS',
                                        args: [
                                            ',',
                                            {
                                                name: 'FIELD',
                                                args: [
                                                    'app/list:items',
                                                    listTip
                                                ]
                                            }
                                        ]
                                    },
                                    ']'
                                ]
                            },
                            'value',
                            'value',
                            'items',
                            rightArg
                        ]
                    }
                ]
            }
        ]
    };
};
var listIn = {
    opNo: 2,
    toFormula: ɵ0
};
var listNotIn = reverseOperator(listIn);
var ɵ1 = function (leftArg, rightArg) { return ({
    name: 'INARRAY',
    args: [{ name: 'FIND_TEXT', args: [leftArg, rightArg] }, 0]
}); };
var listContains = {
    opNo: 2,
    toFormula: ɵ1
};
var listNotContains = reverseOperator(listContains);
export var SPECIAL_VAR_NAME = '---SPECIALVAR---';
export var listOperators = {
    operators: (_a = {},
        _a[OPERATOR_TYPE.EQUALS] = stringEqual,
        _a[OPERATOR_TYPE.NOT_EQUALS] = stringNotEqual,
        _a[OPERATOR_TYPE.CONTAINS] = listContains,
        _a[OPERATOR_TYPE.NOT_CONTAINS] = listNotContains,
        _a[OPERATOR_TYPE.IS_EMPTY] = stringIsEmpty,
        _a[OPERATOR_TYPE.NOT_IS_EMPTY] = stringIsNotEmpty,
        _a[OPERATOR_TYPE.IN] = listIn,
        _a[OPERATOR_TYPE.NOT_IN] = listNotIn,
        _a),
    getParts: function (formula) {
        var currentPart = formula;
        var negative = false;
        if (currentPart.name === 'NOT') {
            currentPart = currentPart.args[0];
            negative = true;
        }
        if (currentPart.name === 'HAS_INTERSECT') {
            return {
                operator: negative ? OPERATOR_TYPE.NOT_IN : OPERATOR_TYPE.IN,
                leftArg: currentPart.args[0],
                rightArg: currentPart.args[1].args[0]
            };
        }
        if (currentPart.name === 'EQUALS') {
            return {
                operator: negative ? OPERATOR_TYPE.NOT_EQUALS : OPERATOR_TYPE.EQUALS,
                leftArg: currentPart.args[0],
                rightArg: currentPart.args[1]
            };
        }
        if (currentPart.args[1] === 0) {
            return {
                operator: negative ? OPERATOR_TYPE.NOT_CONTAINS : OPERATOR_TYPE.CONTAINS,
                leftArg: currentPart.args[0].args[0],
                rightArg: currentPart.args[0].args[1]
            };
        }
        if (currentPart.name === 'INARRAY') {
            return {
                operator: negative ? OPERATOR_TYPE.NOT_IS_EMPTY : OPERATOR_TYPE.IS_EMPTY,
                leftArg: currentPart.args[1]
            };
        }
        throw new Error('Unknown operators: ' + currentPart.name);
    }
};
export function getListTipFormulaSpec(fieldTip) {
    return {
        name: 'LAST',
        args: [
            {
                name: 'SPLIT',
                args: [
                    '--',
                    {
                        name: 'FILTER',
                        args: [
                            {
                                name: 'FIELD',
                                args: [
                                    'field/tag',
                                    fieldTip
                                ]
                            },
                            {
                                name: 'EQUALS',
                                args: [
                                    {
                                        name: 'SUBSTR',
                                        args: [
                                            {
                                                name: 'VAR',
                                                args: [
                                                    SPECIAL_VAR_NAME
                                                ]
                                            },
                                            '0',
                                            {
                                                name: 'STRLEN',
                                                args: [
                                                    'listTip--'
                                                ]
                                            }
                                        ]
                                    },
                                    'listTip--'
                                ]
                            },
                            SPECIAL_VAR_NAME
                        ]
                    }
                ]
            }
        ]
    };
}
export { ɵ0, ɵ1 };
