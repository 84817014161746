import * as tslib_1 from "tslib";
import { DataTypes } from '../data/models/scheme';
import { userSummaryScheme } from './user';
import { teamRoleScheme, teamSummaryScheme } from './team';
export { checkListItemScheme, checkListItemUpdateScheme };
var checkListItemScheme = {
    'app/checklistitem:text': { type: DataTypes.i18n },
    'app/checklistitem:assignee': {
        type: DataTypes.object,
        scheme: tslib_1.__assign({}, userSummaryScheme, teamSummaryScheme, teamRoleScheme)
    },
    'app/checklistitem:status': { type: DataTypes.string }
};
var checkListItemUpdateScheme = {
    'app/checklistitem:text': { type: DataTypes.i18n },
    'app/checklistitem:assignee': { type: DataTypes.string },
    'app/checklistitem:status': { type: DataTypes.string }
};
export var CheckListItemStatus;
(function (CheckListItemStatus) {
    CheckListItemStatus["IN_PROGRESS"] = "in-progress";
    CheckListItemStatus["NOT_STARTED"] = "not-started";
    CheckListItemStatus["COMPLETED"] = "completed";
    CheckListItemStatus["CANCELLED"] = "cancelled";
})(CheckListItemStatus || (CheckListItemStatus = {}));
