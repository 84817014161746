import { Injectable } from '@angular/core';

import dataConstants from './constants';
import { Tip } from './models/types';
import { Eno } from './models/Eno';

import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class EnoCacheService {
  private readonly _enos: Map<Tip, Eno>;

  constructor() {
    this._enos = new Map();
  }

  hasEno(tip: Tip, branch: Tip = dataConstants.BRANCH_MASTER): boolean {
    return this._enos.has(this._getKey(tip, branch));
  }

  getEno(tip: Tip, branch: Tip = dataConstants.BRANCH_MASTER): Eno {
    return this._enos.get(this._getKey(tip, branch));
  }

  // If branch argument is given, it has higher priority than eno's actual branch
  // Because we cache enos on the requested branch in op/pull
  setEno(tip: Tip, eno: Eno, branch?: Tip): void {
    if (!eno.source) {
      // Do not store acknowledgment
      return;
    }

    this._enos.set(this._getKey(tip, branch || eno.getBranch()), cloneDeep(eno));
  }

  private _getKey(tip: Tip, branch: Tip): string {
    return `${tip}:${branch}`;
  }
}
