import { EditFormSideSheetComponent } from './form-renderer/edit-form-side-sheet/edit-form-side-sheet.component';
import { createWellKnownObjectMapping, OBJECT_LAUNCH_OPERATION, } from './object-launch';
import { get } from 'lodash';
import { ObjectOpenSideSheetComponent } from '../object/object-open-side-sheet/object-open-side-sheet.component';
import * as i0 from "@angular/core";
/**
 * returns side sheet based on below conditions :
 *
 * Well known type -
 *          Create sideSheet based on WELL_KNOWN_OBJECT_MAPPING - when object data is not available
 *          Edit sideSheet based on WELL_KNOWN_OBJECT_MAPPING - when object data is available and operation is not provided.
 *                                                              or object data is available and operation is edit.
 *          View sideSheet based on WELL_KNOWN_OBJECT_MAPPING - when object data is available and operation is view
 *          ObjectOpenSideSheetComponent -  when object data is available and WELL_KNOWN_OBJECT_MAPPING is not available
 *
 * Other type -
 *          ObjectOpenSideSheetComponent - when operation is view
 *          EditFormSideSheetComponent - when operation is not view or not provided.
 *
 */
var WellKnownTypesService = /** @class */ (function () {
    function WellKnownTypesService() {
    }
    WellKnownTypesService.prototype.loadEditCreateConfig = function (formObjectAndType, formTip) {
        var objectAndType = formObjectAndType.objectAndType;
        // if we have a form tip
        // use that instead of the well know type
        if (formTip) {
            return { component: EditFormSideSheetComponent, formObjectAndType: formObjectAndType, objectLaunchOverrides: {} };
        }
        var objectType = get(objectAndType, 'objectType.$tip', null);
        var wellKnownConfig = createWellKnownObjectMapping({ objectType: objectType, formObjectAndType: formObjectAndType });
        if (wellKnownConfig && get(objectAndType, 'objectData.$tip', null)) {
            return wellKnownConfig[OBJECT_LAUNCH_OPERATION.EDIT];
        }
        if (wellKnownConfig) {
            return wellKnownConfig[OBJECT_LAUNCH_OPERATION.CREATE];
        }
        return { component: EditFormSideSheetComponent, formObjectAndType: formObjectAndType, objectLaunchOverrides: {} };
    };
    WellKnownTypesService.prototype.loadViewConfig = function (object) {
        var objectType = get(object, 'objectType.$tip', null);
        var wellKnownConfig = createWellKnownObjectMapping({ objectType: objectType });
        if (get(wellKnownConfig, [OBJECT_LAUNCH_OPERATION.VIEW])) {
            return wellKnownConfig[OBJECT_LAUNCH_OPERATION.VIEW].component;
        }
        return ObjectOpenSideSheetComponent;
    };
    WellKnownTypesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WellKnownTypesService_Factory() { return new WellKnownTypesService(); }, token: WellKnownTypesService, providedIn: "root" });
    return WellKnownTypesService;
}());
export { WellKnownTypesService };
