/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./signed-out.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../auth-chrome/auth-chrome.component.ngfactory";
import * as i3 from "../auth-chrome/auth-chrome.component";
import * as i4 from "@angular/router";
import * as i5 from "./signed-out.component";
var styles_SignedOutComponent = [i0.styles];
var RenderType_SignedOutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SignedOutComponent, data: {} });
export { RenderType_SignedOutComponent as RenderType_SignedOutComponent };
export function View_SignedOutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-auth-chrome", [["secondaryTitle", "You are logged out"]], null, null, null, i2.View_AuthChromeComponent_0, i2.RenderType_AuthChromeComponent)), i1.ɵdid(1, 114688, null, 0, i3.AuthChromeComponent, [], { secondaryTitle: [0, "secondaryTitle"] }, null), (_l()(), i1.ɵeld(2, 0, null, 3, 2, "button", [["class", "btn btn-block eim-button_noggin"], ["data-test", "logBackInButton"], ["routerLink", "/signin"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, [" Log back in "]))], function (_ck, _v) { var currVal_0 = "You are logged out"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "/signin"; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_SignedOutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-signed-out", [], null, null, null, View_SignedOutComponent_0, RenderType_SignedOutComponent)), i1.ɵdid(1, 114688, null, 0, i5.SignedOutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SignedOutComponentNgFactory = i1.ɵccf("app-signed-out", i5.SignedOutComponent, View_SignedOutComponent_Host_0, {}, {}, []);
export { SignedOutComponentNgFactory as SignedOutComponentNgFactory };
