import * as tslib_1 from "tslib";
import { getI18nValue } from '../i18n/i18n-value/i18n-value';
import { DataTypes } from '../data/models/scheme';
export { listScheme, getListItemWithStringLabel };
var listScheme = {
    'app/list:name': { type: DataTypes.i18n },
    'app/list:description': { type: DataTypes.i18n },
    'app/list:items': { type: DataTypes.jsonArray },
    'app/list:values': { type: DataTypes.stringArray },
    'app/list:labels': { type: DataTypes.i18nObject },
    'app/list:is-user-modifiable': { type: DataTypes.boolean }
};
function getListItemWithStringLabel(listItem) {
    return tslib_1.__assign({}, listItem, { label: getI18nValue(listItem.label) });
}
