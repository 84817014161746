import {compose} from 'lodash/fp';
import {isEmpty} from 'lodash';
import {
  coerceToNumberIfPossible,
  isEmptyString
} from './number-format-utils';
import {
  addPrefix,
  addSuffix,
  formatCalculation,
  formatDecimalPlaces,
  replaceDecimalSeparator,
  separateThousands
} from './format-number-functions';
import {
  formatParamStringToNumber,
  removeDuplicateAndNonPrefixOperators, removePrefix, removeSuffix, removeValuesOtherThanDecimalSeparatorOrSign,
  reverseFormatCalculation, revertToStandardDecimalSeparatorRemoveDuplicates
} from './parse-formatted-number-functions';
import {
  IFormattingFunctionString,
  INumberFormat,
  IParsedNumber,
} from './number-format-types';

export function formatNumber(number: number | string, format: INumberFormat, _console?: Console) {
  return _formatNumber({number, format}, _console).numberString;
}

export interface IFormattingFunctionParams {
  formattedNumber: string | number;
  format: INumberFormat;
  cursorPosition?: number;
}

export function _formatNumber({number, format}: IParsedNumber, _console?: Console)
  : IFormattingFunctionString {

  if (typeof number === 'string' && isEmpty(number)) {
    // if it's an empty string just return it
    // might just be an initial value
    return {
      numberString: number,
      format,
    };
  }

  const _number = coerceToNumberIfPossible(number);
  if (isNaN(_number)) {
    return {
      numberString: 'Invalid input - not a number',
      format,
    };
  }

  if (!isValidFormat(format)) {
    return {
      numberString: 'Invalid format',
      format,
    };
  }

  const fn = compose(
    addSuffix, // this is called last
    addPrefix,
    separateThousands, // do this after we change the decimal separator
    replaceDecimalSeparator, // add decimal separator
    formatDecimalPlaces, // truncate to required decimal places return string
    formatCalculation, // do calculation first return number
  );

  return fn({number: _number, format});
}

export function parseFormattedNumber({formattedNumber, format, cursorPosition}: IFormattingFunctionParams): IParsedNumber {
  // order is very important think before changing

  if (typeof formattedNumber === 'string' && isEmpty(formattedNumber)) {
    // if it's an empty string just return it
    // might just be an initial value
    return {
      number: formattedNumber,
      format
    };
  }

  const fn = compose(
    reverseFormatCalculation, // this is called last
    formatParamStringToNumber,
    removeDuplicateAndNonPrefixOperators,
    revertToStandardDecimalSeparatorRemoveDuplicates,
    removeValuesOtherThanDecimalSeparatorOrSign,
    removePrefix,
    removeSuffix, // this is called first
  );

  return fn({numberString: formattedNumber as string, format});
}

export function isValidFormat(format: INumberFormat, _console: Console = console) {
  // ignore empty string
  if (isEmptyString(format.decimalSeparator) || isEmptyString(format.thousandsSeparator)) {
    return true;
  }

  if (format.decimalSeparator && format.decimalSeparator.indexOf(format.thousandsSeparator) > -1) {
    _console.warn(`Decimal separator must not contain thousands separator`);
    return false;
  }

  if (format.thousandsSeparator && format.thousandsSeparator.indexOf(format.decimalSeparator) > -1) {
    _console.warn(`Thousands separator must not contain decimal separator`);
    return false;
  }

  return true;
}





















