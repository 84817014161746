const LIGHT_BLUE = 'rgb(32, 201, 238)';

export const COLORS = {
  WHITE: 'rgb(255, 255, 255)',
  BLACK: 'rgb(0, 0, 0)',
  LIGHT_GREY: '#999',
  DARK_GREY: 'rgb(61, 60, 60)', // #3d3c3c
  POPUP_BDR: 'rgb(138, 150, 172, 0.5)', // #8A96AC / 50%
  POPUP_TEXT: '#525252', // '#525252'
  START: '#12C598',
  END: '#FFB234',
  GRID: '#cbe4e9',
  NODE_SELECT: '#00D9FF',
  PORT_SELECT: LIGHT_BLUE,
  NODE_HEADING: 'rgb(44, 38, 228)', // '#2C26E4'
  LINK_LABEL_BG: LIGHT_BLUE, // '#20C9EE'
  BTN_SECONDARY_BORDER: 'rgb(216, 226, 235)', // '#d8e2eb'
  BTN_SECONDARY_BG_HOVER: 'rgb(226, 233, 240)', // '#E2E9F0'
  BTN_SECONDARY_BDR_HOVER: 'rgb(199, 213, 226)', // '#c7d5e2'
  BTN_SECONDARY_TEXT_ACTIVE: 'rgb(22, 34, 44)', // '#16222c'
  BTN_SECONDARY_SHADOW: 'rgb(182, 201, 218)', // '#B6C9DA'
  ICON: 'rgb(90, 113, 134)', // '#5A7186'
  LARGE_LINK_BTN_BG: '#EDF1F8', // '#EDF1F8'
  LARGE_LINK_BTN_BDR: '#E3E7ED', // '#E3E7ED'
  LARGE_LINK_BTN_ICON: '#525252' // '#525252'
};

export const PORT_OFFSET_DIRECTION = {
  MINUS: '-PORT_OFFSET',
  PLUS: '+PORT_OFFSET'
};

export const PORT_POS = {
  TOP_LEFT: 'TopLeft',
  TOP_MIDDLE: 'TopMiddle',
  TOP_RIGHT: 'TopRight',
  LEFT_MIDDLE: 'LeftMiddle',
  RIGHT_MIDDLE: 'RightMiddle',
  BOTTOM_LEFT: 'BottomLeft',
  BOTTOM_MIDDLE: 'BottomMiddle',
  BOTTOM_RIGHT: 'BottomRight'
};

export const SHAPES = {
  R_RECT: 'RoundedRectangle',
  RECT: 'Rectangle',
  CIRCLE: 'Circle',
  DIAMOND: 'Diamond'
};
export const PANEL_BORDER_RADIUS = 5;
export const PORT_PROXIMITY_WIDTH = 60;
export const LARGE_LINK_W = 33;
export const PORT_WIDTH = 11;
export const SELECT_STROKE_W = 4;
export const PORT_OFFSET = SELECT_STROKE_W / 2;
export const STEP_W = 210;
export const FONT_ICON_NAME = 'Material Icons';
export const CUSTOM_FONT_ICON_NAME = 'workflow-icons';
export const CUSTOM_FONT_ICON_CLASS = 'workflow-icon';
export const CUSTOM_FONT_ICON_PREFIX = 'icon-';
export const FONT_NAME_1 = "'Lato', Arial, sans-serif"; // tslint:disable-line
export const FONT_NAME_2 = 'Helvetica, Arial, sans-serif';

export const LAYER_FOREGROUND = 'Foreground';
export const LAYER_POPUP_LIST = 'PopupList';

export const POPUP_LIST_NODE_CATEGORY = 'popupListNodeCategory';
export const POPUP_LIST_NODE_KEY_PREFIX = 'popupList';

export const EDGE_PANEL_NAME = 'edgePanel';
export const STEP_PANEL_NAME = 'stepPanel';
export const NODE_MAIN_PANEL = 'nodeMainPanel';

export const WorkflowValueTypes = {
  VARIABLE: 'variable',
  ACTOR: 'actor',
  INPUT: 'input',
  MANUAL_ENTRY: 'manualEntry',
  QUERY_FILTER: 'queryFilter'
};

export const WorkflowInstructionIntent = {
  NORMAL: {
    label: 'Normal',
    value: 'normal'
  },
  SUCCESS: {
    label: 'Success',
    value: 'success'
  },
  DANGER: {
    label: 'Error',
    value: 'danger'
  },
  WARNING: {
    label: 'Action Required',
    value: 'warning'
  }
};
