import { Injectable } from '@angular/core';
import { Notification, NotificationsService, NotificationType, Options } from '@noggin/angular2-notifications';
import { escape } from 'lodash';

import { NotficationIcons } from './notification-icons';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(
    private _notificationsService: NotificationsService
  ) {}

  set(notification: Notification, to: boolean) {
    return this._notificationsService.set(notification, to);
  }

  remove(id) {
    return this._notificationsService.remove(id);
  }

  showToast(title: string, body: string, intent: string, options: Options = {}): Notification {
    return this._notificationsService.create(title, body, <NotificationType>intent, options);
  }

  showSuccessToast(body, options: Options = {}): Notification {
    return this.showToast('', escape(body), NotificationType.Success, this.optionsWithDefaults(options));
  }

  showErrorToast(body, options: Options = {}): Notification {
    return this.showToast('', escape(body), NotificationType.Error, this.optionsWithDefaults(options));
  }

  showWarningToast(body, options: Options = {}): Notification {
    return this.showToast('', escape(body), NotificationType.Warn, this.optionsWithDefaults(options));
  }

  private optionsWithDefaults(options: Options): Options {
    const defaultIcons = {
      icons: {
        success: NotficationIcons['app/notification-category/for-your-information'],
        alert: '',
        error: NotficationIcons['app/notification-category/for-your-information'],
        info: '',
        warn: NotficationIcons['app/notification-category/for-your-information']
      }
    };

    return { ...defaultIcons, ...options };
  }
}
