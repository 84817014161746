import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../shell/services/me.service";
import * as i2 from "@angular/router";
var AdminGuardService = /** @class */ (function () {
    function AdminGuardService(meService, router) {
        this.meService = meService;
        this.router = router;
    }
    AdminGuardService.prototype.canActivate = function (route, state) {
        var _this = this;
        return this.meService.isAdmin().pipe(switchMap(function (isAdmin) {
            if (!isAdmin) {
                _this.router.navigate(['/not-authorized']);
            }
            return of(isAdmin);
        }));
    };
    AdminGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminGuardService_Factory() { return new AdminGuardService(i0.ɵɵinject(i1.MeService), i0.ɵɵinject(i2.Router)); }, token: AdminGuardService, providedIn: "root" });
    return AdminGuardService;
}());
export { AdminGuardService };
