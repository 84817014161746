import { IObject } from '../data/models/object';
import { Formula, Tip } from '../data/models/types';
import { DataTypes, IObjectScheme } from '../data/models/scheme';
import { MESSAGE_PROCESSED_STATE } from './message';
import * as moment from 'moment';
import { IObjectTitle } from './title';

export enum COMPOSE_TYPE {
  NEW = 'new',
  DRAFT = 'draft',
  REPLY = 'reply',
  FORWARD = 'forward',
  WORKFLOW = 'workflow'
}

export enum EMAIL_OUTBOUND_SRC_STATUS {
  DRAFT = 'draft',
  SENT = 'sent'
}

export enum EMAIL_PRIMITIVE_FOLDER {
  INBOX = 'Inbox',
  SENT = 'Sent',
  DRAFT = 'Draft'
}

export enum EMAIL_RECIPIENT_TYPE {
  TO = 'To',
  CC = 'CC',
  BCC = 'BCC'
}

export interface IEmailableTipsByType {
  to: Tip[];
  cc: Tip[];
  bcc: Tip[];
  toFormulas?: string[];
  ccFormulas?: string[];
  bccFormulas?: string[];
  from?: Tip;
}

export interface IEmailableTitleByType {
  to: IObjectTitle[];
  cc: IObjectTitle[];
  bcc: IObjectTitle[];
  from?: IObjectTitle;
}

export interface IEmailSubstitution extends IObject {
  key: string;
  label: string;
  value: Formula;
}

export const emailSubstitutionScheme: IObjectScheme = {
  'app/email/substitution:key': {},
  'app/email/substitution:label': {},
  'app/email/substitution:value': { type: DataTypes.formula }
};

export interface IAddhocEmail extends IObject {
  label: string;
  email: string;
}

export const addhocEmailScheme: IObjectScheme = {
  'app/email/addhoc-address:label': { },
  'app/email/addhoc-address:email': { }
};

export interface IEmailRecipient extends IObject {
  name: string;
  email: string;
  status: string;
  statusMessage?: string;
  response?: string;
  source: Tip;
  substitutionFormulas?: string[];
  token?: string;
}

export const emailRecipientScheme: IObjectScheme = {
  'app/email/recipient:name': { },
  'app/email/recipient:email': { },
  'app/email/recipient:status': { },
  'app/email/recipient:status-message': { },
  'app/email/recipient:response': { },
  'app/email/recipient:source': { },
  'app/email/recipient:substitution-formulas': { type: DataTypes.stringArray },
  'app/email/recipient:token': { },
};

export interface IEmailFromAddress extends IObject {
  email: string;
  label: string;
  verified: boolean;
}

export const emailFromAddressScheme: IObjectScheme = {
  'app/email/from-address:email': { },
  'app/email/from-address:label': { },
  'app/email/from-address:verified': { type: DataTypes.boolean },
};

export interface IEmailOutboundSource extends IObject {
  to?: Tip[];
  cc?: Tip[];
  bcc?: Tip[];
  toAddressFormula?: string;
  ccAddressFormula?: string;
  bccAddressFormula?: string;
  fromAddress: Tip;
  context?: Tip;
  contextFormula?: Formula;
  contextFormulaMeta?: string;
  template?: Tip;
  subject: string;
  body: string;
  attachments?: Tip[];
  attachmentsFormula?: string;
  status: EMAIL_OUTBOUND_SRC_STATUS;
  draftSavedDatetime?: moment.Moment;
  attachmentSubstitutions?: IEmailSubstitution[];
  messageSubstitutions?: IEmailSubstitution[];
  subjectSubstitutions?: IEmailSubstitution[];
  // @todo This needs to be made IEmailSubstitution[] as well.
  recipientSubstitutionFormulas?: string[];
  billingCode?: Tip;
  relatedObjects?: Tip;
  protectiveMarking?: Tip;
}

export const emailOutboundSourceScheme: IObjectScheme = {
  'app/email/outbound-source:to': { type: DataTypes.stringArray },
  'app/email/outbound-source:cc': { type: DataTypes.stringArray },
  'app/email/outbound-source:bcc': { type: DataTypes.stringArray },
  'app/email/outbound-source:to-address-formula': { type: DataTypes.formula },
  'app/email/outbound-source:cc-address-formula': { type: DataTypes.formula },
  'app/email/outbound-source:bcc-address-formula': { type: DataTypes.formula },
  'app/email/outbound-source:from-address': { },
  'app/email/outbound-source:context': { },
  'app/email/outbound-source:context-formula': { type: DataTypes.formula },
  'app/email/outbound-source:context-formula-meta': { },
  'app/email/outbound-source:template': { },
  'app/email/outbound-source:subject': { },
  'app/email/outbound-source:body': { },
  'app/email/outbound-source:attachments': { type: DataTypes.stringArray },
  'app/email/outbound-source:attachments-formula': { type: DataTypes.formula },
  'app/email/outbound-source:status': { },
  'app/email/outbound-source:draft-saved-datetime': { type: DataTypes.datetime },
  'app/email/outbound-source:billing-code': { },
  'app/email/outbound-source:attachment-substitutions': {
    type: DataTypes.objectArray,
    mutable: true,
    scheme: emailSubstitutionScheme
  },
  'app/email/outbound-source:message-substitutions': {
    type: DataTypes.objectArray,
    mutable: true,
    scheme: emailSubstitutionScheme
  },
  'app/email/outbound-source:subject-substitutions': {
    type: DataTypes.objectArray,
    mutable: true,
    scheme: emailSubstitutionScheme
  },
  'app/email/outbound-source:recipient-substitution-formulas': { type: DataTypes.stringArray },
  'app/email/outbound-source:related-objects': { },
  'app/email/outbound-source:protective-marking': { }
};

export interface IEmailOutbound extends IObject {
  emailSource: IEmailOutboundSource;
  sentSource: IEmailOutboundSource;
  to: Tip[];
  cc?: Tip[];
  bcc?: Tip[];
  status: 'processing' | 'sent' | 'failed';
  statusMessage?: string;
  fromName: string;
  fromEmail: string;
  sentDatetime?: moment.Moment;
}

export const emailOutboundScheme: IObjectScheme = {
  'app/email/outbound:email-source': {
    type: DataTypes.object,
    scheme: emailOutboundSourceScheme
  },
  'app/email/outbound:sent-source': {
    type: DataTypes.object,
    scheme: emailOutboundSourceScheme
  },
  'app/email/outbound:to': { type: DataTypes.stringArray },
  'app/email/outbound:cc': { type: DataTypes.stringArray },
  'app/email/outbound:bcc': { type: DataTypes.stringArray },
  'app/email/outbound:status': { },
  'app/email/outbound:status-message': { },
  'app/email/outbound:from-name': { },
  'app/email/outbound:from-email': { },
  'app/email/outbound:sent-datetime': { type: DataTypes.datetime },
};

export interface IEmailOutboundTemplate extends IObject {
  name: string;
  contextType?: Tip;
  to: Tip[];
  cc?: Tip[];
  bcc?: Tip[];
  fromAddress: Tip;
  subject: string;
  body: string;
  billingCode?: Tip;
  attachments?: Tip[];
  attachmentSubstitutions?: IEmailSubstitution[];
  messageSubstitutions?: IEmailSubstitution[];
  subjectSubstitutions?: IEmailSubstitution[];
  // @todo This needs to be made IEmailSubstitution[] as well.
  recipientSubstitutionFormulas?: string[];
  protectiveMarking?: Tip;
}

export const emailOutboundTemplateScheme: IObjectScheme = {
  'app/email/outbound-template:name': { },
  'app/email/outbound-template:context-type': { },
  'app/email/outbound-template:to': { type: DataTypes.stringArray },
  'app/email/outbound-template:cc': { type: DataTypes.stringArray },
  'app/email/outbound-template:bcc': { type: DataTypes.stringArray },
  'app/email/outbound-template:from-address': { },
  'app/email/outbound-template:subject': { },
  'app/email/outbound-template:body': { },
  'app/email/outbound-template:billing-code': { },
  'app/email/outbound-template:attachments': { type: DataTypes.stringArray },
  'app/email/outbound-template:attachment-substitutions': {
    type: DataTypes.objectArray,
    mutable: true,
    scheme: emailSubstitutionScheme
  },
  'app/email/outbound-template:message-substitutions': {
    type: DataTypes.objectArray,
    mutable: true,
    scheme: emailSubstitutionScheme
  },
  'app/email/outbound-template:subject-substitutions': {
    type: DataTypes.objectArray,
    mutable: true,
    scheme: emailSubstitutionScheme
  },
  'app/email/outbound-template:recipient-substitution-formulas': { type: DataTypes.stringArray },
  'app/email/outbound-template:protective-marking': { }
};

export interface IEmailInfo extends IObject {
  name: string;
  email: string;
}

export const emailInfoScheme: IObjectScheme = {
  'app/email/address-info:name': { },
  'app/email/address-info:email': { }
};

export interface IEmailInbound extends IObject {
  to: Tip[];
  cc?: Tip[];
  bcc?: Tip[];
  from: Tip;
  subject: string;
  sentDatetime: moment.Moment;
  body: string;
  attachments?: Tip[];
  original: string;
  processedState: MESSAGE_PROCESSED_STATE;
  readState: boolean;
  relatedObjects?: Tip;
}

export const emailInboundScheme: IObjectScheme = {
  'app/email/inbound:to': { type: DataTypes.stringArray },
  'app/email/inbound:cc': { type: DataTypes.stringArray },
  'app/email/inbound:bcc': { type: DataTypes.stringArray },
  'app/email/inbound:from': { },
  'app/email/inbound:subject': { },
  'app/email/inbound:sent-datetime': { type: DataTypes.datetime },
  'app/email/inbound:body': { },
  'app/email/inbound:attachments': { type: DataTypes.stringArray },
  'app/email/inbound:original': {
    type: DataTypes.json
  },
  'app/email/inbound:processed-state': { },
  'app/email/inbound:read-state': { type: DataTypes.boolean },
  'app/email/inbound:related-objects': { },
};
