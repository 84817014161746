import { first, flatMap, tap } from 'rxjs/operators';
import { get } from 'lodash';
import { of, throwError } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt/src/jwthelper.service';
import * as i0 from "@angular/core";
import * as i1 from "../../data/process.service";
import * as i2 from "../../data/session-manager.service";
var SSOAuthService = /** @class */ (function () {
    function SSOAuthService(processService, sessionManager) {
        this.processService = processService;
        this.sessionManager = sessionManager;
        this.processTips = {
            saml: 'eim/process/sso/saml/saml-token-auth',
            support: 'eim/process/sso/support/support-token-auth'
        };
    }
    SSOAuthService.prototype.loginWithToken = function (token, issuingService) {
        var _this = this;
        if (issuingService === void 0) { issuingService = 'saml'; }
        var vars = {
            token: [token],
        };
        var lastProfile = this.getLastProfile(token);
        if (lastProfile) {
            vars['Profile tip'] = lastProfile;
        }
        return this.processService
            .start(this.processTips[issuingService], vars)
            .pipe(first(), flatMap(extractSessionThrowIfInvalid), tap(function (_a) {
            var sessionToken = _a.sessionToken;
            _this.sessionManager.updateSessionInfoAndRedirectToOriginalRequestedDestination(sessionToken);
        }));
    };
    SSOAuthService.prototype.getLastProfile = function (token) {
        try {
            var jwtHelperService = new JwtHelperService();
            var payload = jwtHelperService.decodeToken(token);
            var email = get(payload, 'email', '');
            return this.sessionManager.getLastProfileWithEmail(email);
        }
        catch (ex) {
            return '';
        }
    };
    SSOAuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SSOAuthService_Factory() { return new SSOAuthService(i0.ɵɵinject(i1.ProcessService), i0.ɵɵinject(i2.SessionManagerService)); }, token: SSOAuthService, providedIn: "root" });
    return SSOAuthService;
}());
export { SSOAuthService };
function extractSessionThrowIfInvalid(result) {
    var status = get(result, 'vars.Status[0]', null);
    if (status === 'failed') {
        return throwError(new Error('Failed to authenticate with token'));
    }
    return of({
        sessionToken: get(result, 'vars.JWT token[0]', null),
        status: status
    });
}
