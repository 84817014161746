import { Tip, Formula } from '../../../data/models/types';

export const getModuleOptionsLabel = (fieldName: string): string => `Module options - ${fieldName}`;

export const getModuleOptionsFormula = (value: Tip | string, searchBy: 'key' | 'tip' = 'key'): Formula => {
  return searchBy === 'key'
    ? getModuleOptionsFormulayByFieldKey(value)
    : getModuleOptionsFormulaByFieldTip(value);
};

export const getModuleOptionsFormulayByFieldKey = (fieldKey: string): Formula => {
  return `
    FIELD(
      FIRST(
        FILTER(
          FIELD(
            "type/field",
            FIELD(
              "app/object-type:type",
              FIELD(
                "app/module:options-object-type",
                REFERENCES(
                  "app/module:object-types",
                  REFERENCES(
                    "app/object-type:type",
                    VAR("Type")
                  )
                )
              )
            )
          ),
          EQUALS(
            FIELD(
              "field/name",
              VAR("Value")
            ),
            "${fieldKey}"
          )
        )
      ),
      FIRST(
        LOOKUP(
          TIP(),
          ISTYPE(
            FIELD(
              "app/object-type:type",
              FIELD(
                "app/module:options-object-type",
                REFERENCES(
                  "app/module:object-types",
                  REFERENCES(
                    "app/object-type:type",
                    VAR("Type")
                  )
                )
              )
            )
          )
        )
      )
    )
  `;
};

export const getModuleOptionsFormulaByFieldTip = (fieldTip: Tip): Formula => {
  return `
    FIELD(
      "${fieldTip}",
      FIRST(
        LOOKUP(
          TIP(),
          ISTYPE(
            FIELD(
              "app/object-type:type",
              FIELD(
                "app/module:options-object-type",
                REFERENCES(
                  "app/module:object-types",
                  REFERENCES(
                    "app/object-type:type",
                    VAR("Type")
                  )
                )
              )
            )
          )
        )
      )
    )
  `;
};
