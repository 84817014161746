import * as tslib_1 from "tslib";
import { FieldType } from '@ngx-formly/core';
import { OnDestroy } from '@angular/core';
var FormlyFieldSectionHeaderComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FormlyFieldSectionHeaderComponent, _super);
    // we only have the wrapper component in the render view (not when designing)
    function FormlyFieldSectionHeaderComponent() {
        var _this = _super.call(this) || this;
        _this.collapsed = false;
        return _this;
    }
    FormlyFieldSectionHeaderComponent.prototype.toggle = function () {
        if (this.to.toggle) {
            this.collapsed = !this.collapsed;
            this.to.toggle.next(this.collapsed);
        }
    };
    FormlyFieldSectionHeaderComponent.prototype.ngOnDestroy = function () {
        if (this.to.toggle) {
            this.to.toggle.complete();
        }
    };
    return FormlyFieldSectionHeaderComponent;
}(FieldType));
export { FormlyFieldSectionHeaderComponent };
