import { IObject } from './object';

export enum DataTypes {
  'string',
  'i18n',
  'boolean',
  'number',
  'datetime',
  'object',
  'json',
  'uri',
  'email',
  'phone',
  'geography',
  'formula',
  'stringArray',
  'i18nArray',
  'booleanArray',
  'numberArray',
  'datetimeArray',
  'objectArray',
  'jsonArray',
  'uriArray',
  'emailArray',
  'phoneArray',
  'geographyArray',
  'formulaArray',
  'i18nObject'
}

export interface IFieldScheme {
  name?: string;
  type?: DataTypes;
  mutable?: boolean;
  scheme?: IObjectScheme;
  /**
   * The circular placeholder factory will generate and return an eno that can be used as a temporary Eno to satisfy required contraints.
   * For example, say you wanted to use setObject to create Eno A and Eno B such that A references B, and B references A. Because the SID
   * for A includes the TIP for B, and the SID for B includes the TIP for A, we need to break one of the references so that we can actually
   * generate the ENOs, then patch the broken one. However if the one we break is actually a required field, then the ENO will be invalid
   * anyway. The circularPlaceHolderFactory will generate a placeholder ENO such that the instead of breaking one, we just reference our
   * placeholder, then we patch that ENO afterwards and delete the placeholder. If there is no circularPlaceHolderFactory then it will
   * assume the reference field is optional.
   */
  circularPlaceholder?: IObject;
}

export interface IObjectScheme {
  [key: string]: IFieldScheme;
}

export interface ISchemeScan {
  maxDepth: number;
  followFields: Array<string>;
  schemes: Array<IObjectScheme>;
}
