import { forkJoin, of } from 'rxjs';
import { first, map, mapTo } from 'rxjs/operators';
import * as moment from 'moment';
import { isArray, get } from 'lodash';
import { FIELD_DATA_TYPE } from '../models/field';
import * as i0 from "@angular/core";
import * as i1 from "../data/formula-multi.service";
var ObjectLaunchDefaultService = /** @class */ (function () {
    function ObjectLaunchDefaultService(formulaMultiService) {
        this.formulaMultiService = formulaMultiService;
    }
    ObjectLaunchDefaultService.prototype.populateFormDefaultValues = function (formObjectAndType, params) {
        var _this = this;
        if (formObjectAndType.objectAndType.objectData.$tip) {
            return of(formObjectAndType);
        }
        var fields = formObjectAndType.objectAndType.objectType.field.filter(function (field) { return Boolean(field.default); });
        if (!fields || fields.length === 0) {
            return of(formObjectAndType);
        }
        return forkJoin(fields.map(function (field) {
            return _this.populateFieldDefaultValue(field, params, formObjectAndType.objectAndType.objectData);
        })).pipe(mapTo(formObjectAndType));
    };
    ObjectLaunchDefaultService.prototype.populateFieldDefaultValue = function (field, params, objectData) {
        var _this = this;
        var dataType = field.datatype;
        var formula = params.typeTip
            ? { formula: field.default, context: params.contextTip, watch: false, vars: { 'Type': [params.typeTip], CONTEXT_VAR_NAME: [params.contextTip] } }
            : { formula: field.default, context: params.contextTip, watch: false, vars: { CONTEXT_VAR_NAME: [params.contextTip] } };
        // tslint:disable-next-line: max-line-length
        return this.formulaMultiService.evaluate([formula]).pipe(first(), map(function (formulaResult) {
            if (formulaResult && formulaResult[0] && formulaResult[0].length > 0) {
                _this.setObjectDataValues(field, formulaResult[0], objectData, [FIELD_DATA_TYPE.date, FIELD_DATA_TYPE.datetime].includes(dataType));
            }
            return true;
        }));
    };
    ObjectLaunchDefaultService.prototype.setObjectDataValues = function (field, fieldValue, objectData, isDateType) {
        var _this = this;
        if (isDateType === void 0) { isDateType = false; }
        if (field.maxcount === 1) {
            objectData[field.$tip] = this.getValue(isDateType, isArray(fieldValue) ? fieldValue[0] : fieldValue);
            return;
        }
        objectData[field.$tip] = isArray(fieldValue)
            ? fieldValue.map((function (value) { return _this.getValue(isDateType, value); }))
            : [this.getValue(isDateType, fieldValue)];
    };
    ObjectLaunchDefaultService.prototype.getValue = function (isDateType, fieldValue) {
        return isDateType ? moment(fieldValue) : fieldValue;
    };
    ObjectLaunchDefaultService.prototype.setSequenceFieldReadOnly = function (formObjectAndType) {
        if (!get(formObjectAndType, 'objectAndType.objectType.field', null)) {
            return of(formObjectAndType);
        }
        var sequenceField = formObjectAndType.objectAndType.objectType.field.find(function (f) {
            return f.tag && f.tag.findIndex(function (tag) { return tag.includes('sequenceTip'); }) > -1;
        });
        if (sequenceField) {
            formObjectAndType.form.tabs.forEach(function (tab) {
                tab.rows.forEach(function (row) {
                    row.fields.forEach(function (field) {
                        if (field.templateOptions.contextTypeTip === sequenceField.$tip) {
                            field.conditionalFields.readonly.value = 'true';
                        }
                    });
                });
            });
        }
        return of(formObjectAndType);
    };
    ObjectLaunchDefaultService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ObjectLaunchDefaultService_Factory() { return new ObjectLaunchDefaultService(i0.ɵɵinject(i1.FormulaMultiService)); }, token: ObjectLaunchDefaultService, providedIn: "root" });
    return ObjectLaunchDefaultService;
}());
export { ObjectLaunchDefaultService };
