import * as tslib_1 from "tslib";
import { FieldType } from '@ngx-formly/core';
var FormlyFieldNumberFormatComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FormlyFieldNumberFormatComponent, _super);
    function FormlyFieldNumberFormatComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.typeTips = ['number-format'];
        return _this;
    }
    return FormlyFieldNumberFormatComponent;
}(FieldType));
export { FormlyFieldNumberFormatComponent };
