import { IInnerOperator, IOperators, reverseOperator } from './operators';
import { OPERATOR_TYPE } from '../../../models/query';

const booleanEqual: IInnerOperator = {
  opNo: 2,
  toFormula: (leftArg, rightArg) => ({
    name: 'EQUALS',
    args: [{ name: 'COALESCE', args: [leftArg, 'false'] }, { name: 'COALESCE', args: [rightArg, 'false'] }]
  })
};
const booleanNotEqual = reverseOperator(booleanEqual);

export const booleanOperators: IOperators = {
  operators: {
    [OPERATOR_TYPE.EQUALS]: booleanEqual,
    [OPERATOR_TYPE.NOT_EQUALS]: booleanNotEqual
  },
  getParts: formula => {
    let currentPart = formula;
    let negative = false;

    if (currentPart.name === 'NOT') {
      currentPart = currentPart.args[0];
      negative = true;
    }

    if (currentPart.name === 'EQUALS') {
      return {
        operator: negative ? OPERATOR_TYPE.NOT_EQUALS : OPERATOR_TYPE.EQUALS,
        leftArg: currentPart.args[0].args[0],
        rightArg: currentPart.args[1].args[0]
      };
    }

    throw new Error('Unknown operators: ' + currentPart.name);
  }
};
