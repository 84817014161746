import * as tslib_1 from "tslib";
import { combineLatest, ReplaySubject, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, skip } from 'rxjs/operators';
import { get } from 'lodash';
import { getCache, CacheOpt } from '../util/cache';
import { Parser, QuoteString, Stringify, StaticResultAnalysis } from '../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import dataConstants from './constants';
import { EnoFactory } from './EnoFactory';
import { NOW_VAR_NAME } from '../util/current-datetime.service';
import * as i0 from "@angular/core";
import * as i1 from "./ensrv.service";
import * as i2 from "../util/logger.service";
import * as i3 from "../util/current-datetime.service";
var FORMULA_CACHE_MS = 1000 * 60 * 5; // 5 minutes
var FormulaService = /** @class */ (function () {
    function FormulaService(ensrvService, loggerService, currentDatetimeService) {
        var _this = this;
        this.ensrvService = ensrvService;
        this.loggerService = loggerService;
        this.currentDatetimeService = currentDatetimeService;
        this.formulaOpEnoFactory = new EnoFactory('op/formula', dataConstants.SECURITY.OP);
        this.formulaBroadcasters = {};
        this.ensrvService
            .getEnoReceiver('response/formula')
            .subscribe(function (formulaResponseEno) { return _this.handleResponse(formulaResponseEno); });
    }
    // These 3 methods are not unit tested as there is no point of doing test and these methods are just wrapper
    FormulaService.prototype.parse = function (formulaStr) {
        return Parser(formulaStr);
    };
    FormulaService.prototype.stringify = function (formula) {
        return Stringify(formula);
    };
    FormulaService.prototype.quoteString = function (str) {
        return QuoteString(str);
    };
    FormulaService.prototype.unquoteString = function (str) {
        return str.replace(/^\"|\"$/g, '');
    };
    FormulaService.prototype.evaluate = function (formula, context, contextBranches, vars, useCache) {
        if (contextBranches === void 0) { contextBranches = [dataConstants.BRANCH_MASTER]; }
        if (vars === void 0) { vars = {}; }
        if (useCache === void 0) { useCache = null; }
        return this.evaluateBatch([{
                formula: formula,
                context: context,
                contextBranches: contextBranches,
                vars: vars,
                cache: getCache(useCache)
            }]).pipe(map(function (v) { return v[0]; }));
    };
    /*
     * @deprecated use formula-multi service
     */
    FormulaService.prototype.evaluateBatch = function (params) {
        var _this = this;
        // If the entire formula batch has static results then simply return them
        try {
            return of(params.map(function (param) { return StaticResultAnalysis(param.formula, param.vars); }));
        }
        catch (err) { }
        var segment = this.createAndSegmentEnos(setDefaults(params));
        var _a = this.createSubjectsBatchRequests(segment), sendBatch = _a.sendBatch, skipList = _a.skipList;
        if (sendBatch.length > 0) {
            this.send(sendBatch);
        }
        // return all eno observables references in the order requested
        var enos$$ = segment.enoTips.map(function (tip) {
            var obs$ = _this.formulaBroadcasters[tip].observable;
            // skip enos that are network only which already have a cached value
            if (skipList.has(tip)) {
                return obs$.pipe(skip(1));
            }
            return obs$;
        });
        // emit this grouping as a single observable
        // only after all enos have emitted at least once
        // don't emit when return values do not change the result
        return combineLatest(enos$$).pipe(distinctUntilChanged());
    };
    FormulaService.prototype.createAndSegmentEnos = function (_params) {
        var _this = this;
        return _params
            .reduce(function (acc, params) { return tslib_1.__spread([{ eno: _this.createFormulaEno(params), params: params }], acc); }, [])
            .reduceRight(function (acc, enoWithParams) {
            // reduceRight to keep the correct tip order
            var eno = enoWithParams.eno, params = enoWithParams.params;
            var tip = eno.tip;
            // return all enos
            // maintain original request order
            acc.enoTips.push(tip);
            if (params.cache === CacheOpt.USE_CACHE) {
                acc.useCache.push(enoWithParams);
            }
            if (params.cache === CacheOpt.USE_CACHE_THEN_NETWORK) {
                acc.useCacheThenNetwork.push(enoWithParams);
            }
            if (params.cache === CacheOpt.USE_NETWORK_NO_CACHE) {
                acc.useNetworkNoCache.push(enoWithParams);
            }
            return acc;
        }, { enoTips: [], useCache: [], useCacheThenNetwork: [], useNetworkNoCache: [] });
    };
    FormulaService.prototype.createSubjectsBatchRequests = function (segment) {
        var useCache = segment.useCache, useNetworkNoCache = segment.useNetworkNoCache, useCacheThenNetwork = segment.useCacheThenNetwork;
        var sendBatch = [];
        var skipList = new Map();
        batchUseCache.call(this);
        batchUseCacheThenNetwork.call(this);
        batchUseNetworkNoCache.call(this);
        return { sendBatch: sendBatch, skipList: skipList };
        // Batch functions
        // create new subjects for any cache enos we dont already have a subject for
        // only batch (request) enos we dont have
        function batchUseCache() {
            var _this = this;
            useCache.forEach(function (enoWithParams) {
                var tip = enoWithParams.eno.tip;
                if (!_this.isCached(tip)) {
                    _this.addNewReplaySubjectToCache(enoWithParams);
                    sendBatch.push(enoWithParams.eno);
                }
            });
        }
        // create new subjects for any cache enos we dont already have a subject for
        // batch all (request) enos even if we already have them
        function batchUseCacheThenNetwork() {
            var _this = this;
            useCacheThenNetwork.forEach(function (enoWithParams) {
                var tip = enoWithParams.eno.tip;
                if (!_this.isCached(tip)) {
                    _this.addNewReplaySubjectToCache(enoWithParams);
                }
                if (!_this.inProgress(tip)) {
                    sendBatch.push(enoWithParams.eno);
                }
            });
        }
        // create new subjects for network no cache call and add to batch
        function batchUseNetworkNoCache() {
            var _this = this;
            useNetworkNoCache.forEach(function (enoWithParams) {
                var tip = enoWithParams.eno.tip;
                if (!_this.isCached(tip)) {
                    _this.addNewReplaySubjectToCache(enoWithParams);
                }
                else {
                    // sad code :( sorry this is awkward
                    // we dont want to just create a new subject
                    // as that would break older subscribers
                    // so if we already have cached subject
                    // check if it already has a value
                    // if it does skip the first result when returning
                    var hasValue_1 = false;
                    var sub = _this.formulaBroadcasters[tip]
                        .observable
                        .subscribe(function (result) { return hasValue_1 = true; });
                    sub.unsubscribe();
                    if (hasValue_1) {
                        skipList.set(tip, true);
                    }
                }
                if (!_this.inProgress(tip)) {
                    sendBatch.push(enoWithParams.eno);
                }
            });
        }
    };
    FormulaService.prototype.send = function (batch) {
        var _this = this;
        batch.forEach(function (eno) {
            _this.formulaBroadcasters[eno.tip].inProgress = true;
        });
        this.ensrvService.send(batch).subscribe(function () { }, function (error) {
            batch.forEach(function (_a) {
                var tip = _a.tip;
                var formulaBroadcaster = _this.formulaBroadcasters[tip];
                formulaBroadcaster.subject.error(error);
            });
        });
    };
    FormulaService.prototype.isCached = function (tip) {
        return Boolean(this.formulaBroadcasters[tip]);
    };
    FormulaService.prototype.inProgress = function (tip) {
        return get(this, ['formulaBroadcasters', tip, 'inProgress'], false);
    };
    FormulaService.prototype.createFormulaEno = function (_a) {
        var formula = _a.formula, context = _a.context, contextBranches = _a.contextBranches, vars = _a.vars;
        var nowVariable = {};
        nowVariable[NOW_VAR_NAME] = [this.currentDatetimeService.getCurrentDatetime()];
        return this.formulaOpEnoFactory
            .setFields([
            { tip: 'op/formula:formula', value: [formula] },
            { tip: 'op/formula:context', value: context ? [context] : [] },
            { tip: 'op/formula:context-branch', value: contextBranches },
            { tip: 'op/formula:vars', value: [JSON.stringify(tslib_1.__assign({}, vars, nowVariable))] },
            { tip: 'op/formula:lang', value: dataConstants.ACCEPTABLE_LOCALE_IDS }
        ])
            .makeEno();
    };
    FormulaService.prototype.handleResponse = function (formulaResponseEno) {
        var formulaOpTip = formulaResponseEno.getFieldStringValue('response/formula:op');
        var formulaBroadcaster = this.formulaBroadcasters[formulaOpTip];
        // In case an error happens.
        if (!formulaBroadcaster) {
            return;
        }
        formulaBroadcaster.inProgress = false;
        formulaBroadcaster.subject.next(formulaResponseEno.getFieldValues('response/formula:result'));
    };
    FormulaService.prototype.addNewReplaySubjectToCache = function (_a) {
        var _this = this;
        var eno = _a.eno, params = _a.params;
        var subject = new ReplaySubject(1, FORMULA_CACHE_MS);
        var observable = subject
            .asObservable()
            .pipe(catchError(function (error) {
            _this.loggerService.error("[FormulaService] Failed to evaluate formula \"" + params.formula + "\".", error);
            _this.clearFormulaObservable(eno.tip);
            throw error;
        }));
        this.formulaBroadcasters[eno.tip] = {
            subject: subject,
            observable: observable,
            inProgress: false
        };
    };
    FormulaService.prototype.clearFormulaObservable = function (formulaOpTip) {
        delete this.formulaBroadcasters[formulaOpTip];
    };
    FormulaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FormulaService_Factory() { return new FormulaService(i0.ɵɵinject(i1.EnsrvService), i0.ɵɵinject(i2.LoggerService), i0.ɵɵinject(i3.CurrentDatetimeService)); }, token: FormulaService, providedIn: "root" });
    return FormulaService;
}());
export { FormulaService };
function setDefaults(params) {
    return params
        .map(function (param) { return (tslib_1.__assign({ contextBranches: [dataConstants.BRANCH_MASTER], vars: {}, cache: CacheOpt.USE_CACHE_THEN_NETWORK }, param)); });
}
