import * as tslib_1 from "tslib";
import { BehaviorSubject, throwError, combineLatest, of } from 'rxjs';
import { catchError, mapTo, switchMap, map, first } from 'rxjs/operators';
import { MODULE_POLICY_TYPE } from '../../modules/module.service';
import dataConstants from '../../../data/constants';
import { flatFormScheme, formScheme } from '../models/form';
import { formOptionsGroups } from '../form-options';
import { last } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "../../../data/object-service/object.service";
import * as i2 from "../../modules/module.service";
import * as i3 from "../../../data/formula.service";
import * as i4 from "../../modules/module-package.service";
var FormDesignerService = /** @class */ (function () {
    function FormDesignerService(objectService, moduleService, formulaService, modulePackageService) {
        var _this = this;
        this.objectService = objectService;
        this.moduleService = moduleService;
        this.formulaService = formulaService;
        this.modulePackageService = modulePackageService;
        // use the map to quickly lookup a shadow element
        this.formOptions = new Map();
        this.paletteState$ = new BehaviorSubject(true);
        this.propertiesState$ = new BehaviorSubject(true);
        // we set each item in a map so we can quickly lookup
        // the listId key when creating a shadow element with dragula
        // we only need to create this list once .. can include hidden elements
        var options = formOptionsGroups(true);
        options.forEach(function (group) {
            group.options.forEach(function (option) {
                _this.formOptions.set(option.listId, option);
            });
        });
        this.formOptionGroups$ = of(formOptionsGroups(true));
        this.formPublicOptionGroups$ = of(formOptionsGroups(false, true));
    }
    FormDesignerService.prototype.loadForm = function (formTip) {
        return this
            .objectService
            .getObject(formTip, formScheme)
            .pipe(map(function (form) {
            if (!form.contextType) {
                throw new Error('Form has no object attached');
            }
            return form;
        }), catchError(function (error) { return throwError({ errorMessage: 'Error loading form', error: error }); }));
    };
    FormDesignerService.prototype.getFormModuleName = function (moduleTip) {
        return this.formulaService.evaluate('FIELD("app/module:name")', moduleTip).pipe(map(function (formulaResult) { return formulaResult[0]; }));
    };
    FormDesignerService.prototype.getFormOption = function (listId) {
        return this.formOptions.get(listId);
    };
    FormDesignerService.prototype.createNewForm = function (formParams, moduleTip) {
        var _this = this;
        var formEno = tslib_1.__assign({ $type: 'app/form' }, formParams);
        return this.moduleService
            .getModulePolicy(moduleTip, MODULE_POLICY_TYPE.INSTANCE)
            .pipe(first(), switchMap(function (instancePolicy) {
            return _this.objectService
                .setObject(formEno, flatFormScheme, dataConstants.BRANCH_MASTER, instancePolicy || 'app/security-policy/instance-user-admin' // -!-!-!- DELETE BACKUP OPTION AFTER MIGRATION - EIM-2691 -!-!-!-
            )
                .pipe(first());
        }), switchMap(function (batch) {
            var tip = batch[batch.length - 1].tip;
            return _this.moduleService
                .addTipToFlatModuleField({
                moduleTip: moduleTip,
                moduleField: 'forms',
                tip: tip
            })
                .pipe(mapTo(tip));
        }), catchError(function () {
            return _this.modulePackageService.handleError('save form', moduleTip);
        }));
    };
    FormDesignerService.prototype.cloneForm = function (_a) {
        var _this = this;
        var newForm = _a.newForm, originalFormTip = _a.originalFormTip, moduleTip = _a.moduleTip;
        var getModuleInstancePolicy$ = this.moduleService.getModulePolicy(moduleTip, MODULE_POLICY_TYPE.INSTANCE);
        var getOriginalForm$ = this.objectService.getObject(originalFormTip, flatFormScheme, undefined, undefined, true);
        return combineLatest(getOriginalForm$, getModuleInstancePolicy$)
            .pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), originalForm = _b[0], moduleInstancePolicy = _b[1];
            return (tslib_1.__assign({}, originalForm, { $tip: undefined, $security: moduleInstancePolicy || 'app/security-policy/instance-user-admin', name: newForm.name, description: newForm.description, size: newForm.size, version: 1 }));
        }), switchMap(function (formToSave) { return _this.objectService.setObject(formToSave, flatFormScheme); }), map(function (batch) { return last(batch).tip; }), switchMap(function (tip) {
            return _this.moduleService
                .addTipToFlatModuleField({ moduleTip: moduleTip, moduleField: 'forms', tip: tip })
                .pipe(mapTo(tip));
        }), catchError(function () {
            return _this.modulePackageService.handleError('clone form', moduleTip);
        }));
    };
    FormDesignerService.prototype.deleteForm = function (tip, moduleTip) {
        var _this = this;
        return this.moduleService.deleteModuleConfig([tip]).pipe(catchError(function () {
            return _this.modulePackageService.handleError('delete form', moduleTip);
        }));
    };
    FormDesignerService.prototype.saveForm = function (_form, moduleTip) {
        var _this = this;
        var form = tslib_1.__assign({}, _form, { contextType: _form.contextType.$tip });
        return this.objectService.setObject(form, flatFormScheme).pipe(catchError(function () {
            return _this.modulePackageService.handleError('save form', moduleTip);
        }));
    };
    FormDesignerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FormDesignerService_Factory() { return new FormDesignerService(i0.ɵɵinject(i1.ObjectService), i0.ɵɵinject(i2.ModuleService), i0.ɵɵinject(i3.FormulaService), i0.ɵɵinject(i4.ModulePackageService)); }, token: FormDesignerService, providedIn: "root" });
    return FormDesignerService;
}());
export { FormDesignerService };
