import * as tslib_1 from "tslib";
import { NodeConverterBase } from './NodeConverterBase';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import dataConstants from '../../../data/constants';
import { stringInterpolateFormula } from '../utils/converter-utils';
import * as i0 from "@angular/core";
var NS = 'UpdateObjectNodeConverterService';
var UpdateObjectNodeConverterService = /** @class */ (function (_super) {
    tslib_1.__extends(UpdateObjectNodeConverterService, _super);
    function UpdateObjectNodeConverterService() {
        var _this = _super.call(this) || this;
        _this.type = 'processnode/data/update-eno';
        _this.processNodeType = 'processnodetype/data/update-eno';
        return _this;
    }
    UpdateObjectNodeConverterService.prototype.convert = function (nodeData) {
        if (!this.isValid(nodeData)) {
            throw new InvalidWorkflowNodeDataError("Please correct invalid data on update object node [" + nodeData.fields.title + "]", nodeData);
        }
        var convertedFieldValues = nodeData.fields.fieldItems.map(function (fieldItem) { return stringInterpolateFormula({
            formula: fieldItem.fieldValue,
            isManualEntry: fieldItem.isManualEntry,
            isStringInterpolation: fieldItem.isStringInterpolation
        }); });
        return {
            $type: this.type,
            $security: dataConstants.SECURITY.INSTANCE_USER_ADMIN,
            type: this.processNodeType,
            connections: [],
            point: [0, 0],
            key: nodeData.tip,
            updateEnoObject: nodeData.fields.formula,
            updateEnoFields: nodeData.fields.fieldItems.map(function (x) { return x.tip; }),
            updateEnoValues: convertedFieldValues,
            // @toDo Branches are hard-coded for the moment until we support branch for update object. Severity: medium
            updateEnoBranchSrc: "ARRAY(\"" + dataConstants.BRANCH_MASTER + "\")",
            updateEnoBranchDest: "ARRAY(\"" + dataConstants.BRANCH_MASTER + "\")",
            updateEnoLang: navigator.language.toLowerCase()
        };
    };
    UpdateObjectNodeConverterService.prototype.isValid = function (nodeData) {
        return !(nodeData.fields.formula === '' || !nodeData.fields.fieldItems || !nodeData.fields.fieldItems.length);
    };
    UpdateObjectNodeConverterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UpdateObjectNodeConverterService_Factory() { return new UpdateObjectNodeConverterService(); }, token: UpdateObjectNodeConverterService, providedIn: "root" });
    return UpdateObjectNodeConverterService;
}(NodeConverterBase));
export { UpdateObjectNodeConverterService };
