import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { saveAs } from 'file-saver';
import { PrintService } from '../print.service';
import { BehaviorSubject, combineLatest, of, Subject, throwError } from 'rxjs';
import { SideSheetService } from '../../side-sheet/side-sheet.service';
import { ToastService } from '../../shell/services/toast.service';
import { filter, switchMap, takeUntil, takeWhile, first } from 'rxjs/operators';
import { IPrintProps, TRACKING_STATUS } from '../print';
import { SideSheetBase } from '../../side-sheet/side-sheet-base';
import { ObjectService } from '../../object/object.service';
import * as moment from 'moment';

@Component({
  selector: 'app-print-pdf-export-side-sheet',
  templateUrl: './print-pdf-export-side-sheet.component.html',
  styleUrls: [ './print-pdf-export-side-sheet.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintPdfExportSideSheetComponent extends SideSheetBase<IPrintProps, null> implements OnDestroy {

  readonly loading$ = new BehaviorSubject<boolean>(true);
  private readonly unsubscribe$ = new Subject();
  private props: IPrintProps;
  private contextTitle: string;

  constructor(
    private printService: PrintService,
    private objectService: ObjectService,
    private sideSheetService: SideSheetService,
    private toastService: ToastService
  ) {
    super();
  }

  setProps(props: IPrintProps) {
    this.props = props;
    this.triggerDownload();
  }

  private triggerDownload() {
    combineLatest([
      this.generatePdf(),
      this.objectService.getTitles([ this.props.contextTip ])
    ])
      .pipe(first())
      .subscribe(
        ([data, [{title}]]) => {
          this.loading$.next(false);
          this.contextTitle = title;
          this.saveAs(data);
        },
        () => {
          this.toastService.showErrorToast('Unable to download. Please try again later.');
          this.sideSheetService.pop();
        }
      );
  }

  private generatePdf() {
    const trackingTip = this.props.trackingTip;
    return this
      .printService
      .generatePdf(trackingTip)
      .pipe(
        switchMap(() => this.printService.getTrackingObject(trackingTip)),
        takeWhile(tracking => tracking.generatePdfStatus !== TRACKING_STATUS.COMPLETED, true),
        switchMap(tracking => {
          if (tracking.generateHtmlStatus === TRACKING_STATUS.FAILED) {
            return throwError('error processing pdf');
          }
          return of(tracking);
        }),
        filter(tracking => tracking.generatePdfStatus === TRACKING_STATUS.COMPLETED),
        switchMap(() => this.printService.download(trackingTip, 'pdf')),
      );
  }

  download() {
    this
      .printService
      .download(this.props.trackingTip, 'pdf')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(this.saveAs.bind(this));
  }

  saveAs(data) {
    saveAs(data as unknown as Blob, `${this.contextTitle}-${moment().format('DD-MMM-YYYY')}.pdf`);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
