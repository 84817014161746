<div class="screen">
  <div class="main">
    <div class="image">
      <img
          src="/assets/v1.627.2-beta/assets/img/404/404-background.png"
          class="background">
      <img
          src="/assets/v1.627.2-beta/assets/img/404/404-cloud.gif"
          class="cloud">
    </div>
    <h1>Whoops…</h1>
    <h6>It looks like you’re lost.<br>You may have an old link or maybe the page was moved.</h6>
    <div>
      <a
          class="btn btn-primary home-button"
          routerLink="/">Let's go home!</a>
    </div>
  </div>
</div>
