import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { get } from 'lodash';

import { ContextTypeFieldOptions, IContextTypeFieldOption } from '../../context-type-field-option-utils/context-type-field-option-utils';
import { FIELD_DATA_TYPE } from '../../../models/field';
import { Tip } from '../../../data/models/types';

@Component({
  selector: 'app-date-time',
  template: `
    <app-datetime-field
        [formControl]="formControl"
        [label]="to.label"
        [required]="to.required"
        [placeholder]="to.placeholder"
        [mode]="to.type"
        [readonly]="to.readonly"
        [dateFormat]="to.dateFormat"
        [timeFormat]="to.timeFormat"
        [timezone]="to.timezone"
        [dateValidation]="to.dateValidation"
        [calculated]="!!fieldOption?.calculation"
        (calculate)="calculate()"></app-datetime-field>
  `
})
export class FormlyFieldDateTimeComponent extends FieldType implements OnInit {
  fieldOption: IContextTypeFieldOption;

  ngOnInit() {
    const fieldTip: Tip = get(this, 'field.templateOptions.contextTypeTip', null);
    const fieldOptions: ContextTypeFieldOptions = get(this, 'formState.contextTypeOptions', null);

    this.fieldOption = fieldTip && fieldOptions ? fieldOptions.values.get(fieldTip) : null;
  }

  calculate() {
    this.formState.calculation$.next({
      fieldTip: this.field.key,
      fieldDataType: FIELD_DATA_TYPE.datetime,
      calculationFormula: this.fieldOption.calculation
    });
  }
}
