var _a, _b, _c;
import * as tslib_1 from "tslib";
import { loadModules as esriLoadModules, loadScript } from 'esri-loader';
import { concat, from, of } from 'rxjs';
import { DATA_SOURCE_TYPES, EXTENT_TYPES } from '../../../models/map';
import { catchError, first, map, switchMap, toArray } from 'rxjs/operators';
import { geojsonToArcGIS } from '@esri/arcgis-to-geojson-utils';
import { gisServicePath } from '../../../settings/map/gis.service';
import { environment } from '../../../../environments/environment';
import * as url from 'url';
import { LayerControlLegendSideSheetComponent } from '../layer-control-legend/layer-control-legend-side-sheet/layer-control-legend-side-sheet.component';
import { get, isEmpty, union, head } from 'lodash';
import { formatNumber } from '@angular/common';
import { NOW_VAR_NAME } from '../../../util/current-datetime.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../util/logger.service";
import * as i2 from "../../../settings/map/gis.service";
import * as i3 from "../../../side-sheet/side-sheet.service";
import * as i4 from "../../../util/current-datetime.service";
import * as i5 from "../../../settings/map/map.service";
var featureQueryRegex = /{=(\w+)}/gm;
export var SKETCH_TOOL;
(function (SKETCH_TOOL) {
    SKETCH_TOOL["POINT"] = "point";
    SKETCH_TOOL["POLYLINE"] = "polyline";
    SKETCH_TOOL["POLYGON"] = "polygon";
    SKETCH_TOOL["RECTANGLE"] = "rectangle";
    SKETCH_TOOL["ADDRESS"] = "address";
})(SKETCH_TOOL || (SKETCH_TOOL = {}));
export var esriSketchToolMap = (_a = {},
    _a[SKETCH_TOOL.POINT] = SKETCH_TOOL.POINT,
    _a[SKETCH_TOOL.POLYLINE] = SKETCH_TOOL.POLYLINE,
    _a[SKETCH_TOOL.POLYGON] = SKETCH_TOOL.POLYGON,
    _a[SKETCH_TOOL.RECTANGLE] = SKETCH_TOOL.RECTANGLE,
    _a[SKETCH_TOOL.ADDRESS] = SKETCH_TOOL.POINT,
    _a);
var layerModuleMap = (_b = {},
    _b[DATA_SOURCE_TYPES.NOGGIN_QUERY] = 'esri/layers/FeatureLayer',
    _b[DATA_SOURCE_TYPES.EXTERNAL_GEOJSON] = 'esri/layers/GeoJSONLayer',
    _b[DATA_SOURCE_TYPES.EXTERNAL_GEORSS] = 'esri/layers/GeoRSSLayer',
    _b[DATA_SOURCE_TYPES.EXTERNAL_KML] = 'esri/layers/KMLLayer',
    _b[DATA_SOURCE_TYPES.EXTERNAL_WMS] = 'esri/layers/WMSLayer',
    _b[DATA_SOURCE_TYPES.ESRI_FEATURE_LAYER] = 'esri/layers/FeatureLayer',
    _b[DATA_SOURCE_TYPES.ESRI_DYNAMIC_MAP_SERVICE] = 'esri/layers/MapImageLayer',
    _b[DATA_SOURCE_TYPES.ESRI_TILED_MAP_SERVICE] = 'esri/layers/TileLayer',
    _b[DATA_SOURCE_TYPES.ESRI_VECTOR_TILE_SERVICE] = 'esri/layers/VectorTileLayer',
    _b[DATA_SOURCE_TYPES.ESRI_IMAGE_SERVICE] = 'esri/layers/ImageryLayer',
    _b);
export var pointSymbol = {
    type: 'picture-marker',
    height: 38,
    width: 38,
    yoffset: 19,
    url: environment.assetPath + "/assets/img/maps/generic-marker.png",
    color: null
};
export var activePointSymbol = {
    type: 'picture-marker',
    height: 38,
    width: 38,
    yoffset: 19,
    url: environment.assetPath + "/assets/img/maps/generic-active-marker.png",
    color: null
};
var lineSymbol = {
    type: 'simple-line',
    color: [130, 130, 130, 1],
    width: 2
};
var polygonSymbol = {
    type: 'simple-fill',
    color: [150, 150, 150, .2],
    outline: {
        color: [50, 50, 50],
        width: 2
    }
};
export var DEFAULT_POINT_ZOOM_LEVEL = 15;
export var POPUP_ACTION_ID_OPEN_OBJECT = 'open-object';
var sketchToolSymbolMap = (_c = {},
    _c[SKETCH_TOOL.POINT] = pointSymbol,
    _c[SKETCH_TOOL.POLYLINE] = lineSymbol,
    _c[SKETCH_TOOL.POLYGON] = polygonSymbol,
    _c[SKETCH_TOOL.RECTANGLE] = polygonSymbol,
    _c[SKETCH_TOOL.ADDRESS] = pointSymbol,
    _c);
export var SKETCH_ID = 'sketchLayer';
var ɵ0 = function (feature) { return ([{
        type: 'fields',
        fieldInfos: get(feature, 'graphic.layer.fields').filter(function (_a) {
            var name = _a.name;
            return name !== 'OBJECTID' && name !== 'OBJECTTITLE';
        }).map(function (field) { return ({
            fieldName: field.name,
            label: field.alias
        }); })
    }]); };
export var NOGGIN_QUERY_POPUP_TEMPLATE = {
    title: '{OBJECTTITLE}',
    actions: [
        {
            title: 'Open object',
            id: POPUP_ACTION_ID_OPEN_OBJECT,
            className: 'esri-icon-launch-link-external'
        }
    ],
    content: ɵ0
};
var MapUtilService = /** @class */ (function () {
    function MapUtilService(loggerService, gisService, sideSheetService, currentDatetimeService, mapService, locale) {
        var _this = this;
        this.loggerService = loggerService;
        this.gisService = gisService;
        this.sideSheetService = sideSheetService;
        this.currentDatetimeService = currentDatetimeService;
        this.mapService = mapService;
        this.locale = locale;
        loadScript({ version: '4.12' });
        MapUtilService.loadModules(['esri/config']).subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 1), esriConfig = _b[0];
            var interceptor = {
                headers: _this.gisService.getAuthHeaders(),
                urls: gisServicePath,
                before: function (params) {
                    var urlObj = url.parse(params.url);
                    var paths = urlObj.pathname.split('/');
                    var pointer = paths.length - 1;
                    if (paths[pointer] === 'query') {
                        pointer = pointer - 2;
                    }
                    params.requestOptions.query.withExtent = decodeURIComponent(paths[pointer]);
                    paths.splice(pointer, 1);
                    pointer--;
                    var vars = decodeURIComponent(paths[pointer]);
                    paths.splice(pointer, 1);
                    params.requestOptions.query.vars = vars;
                    urlObj.pathname = paths.join('/');
                    params.url = url.format(urlObj);
                },
                // @ts-ignore
                error: function (error) {
                    if (error.name !== 'AbortError') {
                        loggerService.error('[map-util-service] error setting ESRI interceptor:' + JSON.stringify(error));
                    }
                }
            };
            esriConfig.request.interceptors = [interceptor];
        });
    }
    MapUtilService.loadModules = function (modules) {
        return from(esriLoadModules(modules));
    };
    MapUtilService.getExistingLayerIndex = function (layer, layers) {
        return layers.findIndex(function (l) { return l.id.split('-')[0] === layer.$sid; });
    };
    MapUtilService.getExistingLayer = function (layer, layers) {
        var index = MapUtilService.getExistingLayerIndex(layer, layers);
        if (index < 0) {
            return null;
        }
        return layers.getItemAt(index);
    };
    MapUtilService.getLayerById = function (id, layers) {
        return layers.find(function (l) { return l.id === id; });
    };
    MapUtilService.generateLayerId = function (layer) {
        return layer.$sid + "-" + layer.dataSource.$sid;
    };
    MapUtilService.prototype.loadGeoJSONtoGraphicsLayer = function (geo, graphicsLayer, mapView, sketchTool) {
        var _this = this;
        MapUtilService.loadModules([
            'esri/Graphic',
            'esri/geometry/support/jsonUtils',
            'esri/geometry/support/webMercatorUtils'
        ]).subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 3), esriGraphic = _b[0], jsonUtils = _b[1], webMercatorUtils = _b[2];
            var geometry = jsonUtils.fromJSON(geojsonToArcGIS(geo));
            if (!mapView.spatialReference.isWGS84 && mapView.spatialReference.isWebMercator) {
                geometry = webMercatorUtils.geographicToWebMercator(geometry);
            }
            var symbol = sketchToolSymbolMap[sketchTool];
            var graphic = new esriGraphic({ geometry: geometry, symbol: symbol });
            graphicsLayer.add(graphic);
            if (sketchTool === SKETCH_TOOL.POINT || sketchTool === SKETCH_TOOL.ADDRESS) {
                mapView.goTo({
                    target: graphic,
                    zoom: DEFAULT_POINT_ZOOM_LEVEL
                });
            }
            else {
                mapView.goTo(graphic);
            }
        }, function (error) {
            _this.loggerService.error("Error loading GeoJSON to layer: " + error);
        });
    };
    MapUtilService.prototype.refreshLayers = function (layers, esriLayers, queryAndFeatureQueryVars, extent) {
        var _this = this;
        if (queryAndFeatureQueryVars === void 0) { queryAndFeatureQueryVars = [{}, {}]; }
        var layerIds = layers.map(function (layer) { return MapUtilService.generateLayerId(layer); });
        // remove esri layers that does not exist any more except the graphic layer.
        esriLayers
            .filter(function (esriLayer) { return layerIds.indexOf(esriLayer.id) === -1 && esriLayer.id !== SKETCH_ID; })
            .forEach(function (esriLayer) { return esriLayers.remove(esriLayer); });
        // add the new layers
        return concat.apply(void 0, tslib_1.__spread(layers.map(function (layer) { return _this.addLayer(layer, esriLayers, queryAndFeatureQueryVars, _this.layerExtentOption(extent, layer)); }))).pipe(toArray(), map(function () {
            // re-order the layers to match to input layers
            layerIds.forEach(function (layerId, newPos) {
                esriLayers.reorder(MapUtilService.getLayerById(layerId, esriLayers), newPos);
            });
            return esriLayers.toArray();
        }));
    };
    MapUtilService.prototype.layerExtentOption = function (extent, layer) {
        var _this = this;
        if (isEmpty(extent)) {
            return of(false);
        }
        switch (extent.type) {
            case EXTENT_TYPES.ALL_LAYERS: return of(true);
            case EXTENT_TYPES.LOCATION:
            case EXTENT_TYPES.POLYGON: return of(false);
            case EXTENT_TYPES.LAYER: return of(!!get(extent, 'layer[0].$tip') && get(extent, 'layer').some(function (l) { return get(layer, '$tip') === l.$tip; }));
            case EXTENT_TYPES.DEFAULT:
                return this.mapService.getDefaultExtent().pipe(first(), switchMap(function (defaultExtent) { return _this.layerExtentOption(defaultExtent); }));
        }
    };
    MapUtilService.prototype.addLayer = function (layer, layers, queryAndFeatureQueryVars, withExtent$) {
        var _this = this;
        var id = MapUtilService.generateLayerId(layer);
        var existingLayer = MapUtilService.getLayerById(id, layers);
        if (existingLayer) {
            return of(existingLayer); // do not add duplicate layer
        }
        return withExtent$.pipe(switchMap(function (withExtent) { return _this.buildLayer(id, layer, queryAndFeatureQueryVars, withExtent); }), map(function (esriLayer) {
            var existLayerIndex = MapUtilService.getExistingLayerIndex(layer, layers);
            if (existLayerIndex === -1) {
                layers.add(esriLayer);
            }
            else {
                layers.splice(existLayerIndex, 1, esriLayer);
            }
            return esriLayer;
        }));
    };
    MapUtilService.prototype.buildLayer = function (id, layer, queryAndFeatureQueryVars, withExtent) {
        var _this = this;
        if (layer.dataSource.type === DATA_SOURCE_TYPES.MAPBOX_VECTOR_TILES) {
            return of();
        }
        var layerUrl;
        var additionalOption = {};
        if (layer.dataSource.type !== DATA_SOURCE_TYPES.NOGGIN_QUERY) {
            layerUrl = layer.dataSource.config.url;
            if (layer.dataSource.config.sublayerName) {
                additionalOption.sublayers = [{
                        name: layer.dataSource.config.sublayerName
                    }];
            }
            if (!isEmpty(layer.dataSource.config.featureQuery)) {
                additionalOption.definitionExpression =
                    layer.dataSource.config.featureQuery.replace(featureQueryRegex, function (match, name) { return head(queryAndFeatureQueryVars[1][name]); });
            }
        }
        else {
            // tslint:disable-next-line:max-line-length
            layerUrl = this.gisService.getQueryLayerUrl(layer.$tip) + "/" + encodeURIComponent(JSON.stringify(this.addNowVarToVars(queryAndFeatureQueryVars[0]))) + "/" + withExtent;
            additionalOption.popupTemplate = NOGGIN_QUERY_POPUP_TEMPLATE;
        }
        return MapUtilService.loadModules([layerModuleMap[layer.dataSource.type]]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 1), esriLayerClass = _b[0];
            return new esriLayerClass(tslib_1.__assign({ id: id, title: layer.name, url: layerUrl, opacity: layer.opacity ? layer.opacity / 100 : 1 }, additionalOption));
        }), catchError(function (error) {
            _this.loggerService.error("Error building a layer: " + error);
            return of(error);
        }));
    };
    MapUtilService.prototype.addNowVarToVars = function (vars) {
        var _a;
        return tslib_1.__assign({}, vars, (_a = {}, _a[NOW_VAR_NAME] = [this.currentDatetimeService.getCurrentDatetime()], _a));
    };
    MapUtilService.prototype.openLayerControlLegendSideSheet = function (initialValue, serviceId) {
        var sheetRef = this.sideSheetService.push(LayerControlLegendSideSheetComponent);
        var sheetInstance = sheetRef.componentInstance;
        sheetInstance.value = initialValue;
        sheetInstance.serviceId = serviceId;
    };
    MapUtilService.prototype.formatPointCoordinates = function (point) {
        var _this = this;
        var coordinates = get(point, 'coordinates', []);
        return coordinates.slice().reverse()
            .map(function (coordinate) { return formatNumber(coordinate, _this.locale, '1.0-5'); })
            .join(', ');
    };
    MapUtilService.prototype.extractReplacementFromFeatureQueries = function (featureQueries) {
        var replacements = [];
        featureQueries.join().replace(featureQueryRegex, function (match, name) {
            replacements = union(replacements, [name]);
            return '';
        });
        return replacements;
    };
    MapUtilService.prototype.mockFilterInputsFromFeatureQueries = function (featureQueries) {
        return this.extractReplacementFromFeatureQueries(featureQueries).map(function (replacement) { return ({
            variable: {
                name: replacement,
                type: null,
            },
            value: '',
            by: null
        }); });
    };
    MapUtilService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MapUtilService_Factory() { return new MapUtilService(i0.ɵɵinject(i1.LoggerService), i0.ɵɵinject(i2.GisService), i0.ɵɵinject(i3.SideSheetService), i0.ɵɵinject(i4.CurrentDatetimeService), i0.ɵɵinject(i5.MapService), i0.ɵɵinject(i0.LOCALE_ID)); }, token: MapUtilService, providedIn: "root" });
    return MapUtilService;
}());
export { MapUtilService };
export { ɵ0 };
