import { Injectable } from '@angular/core';
import { EnoFactory } from './EnoFactory';

import dataConstants from './constants';
import { Sid, Tip } from './models/types';
import { Eno } from './models/Eno';

export interface IOpPullOption {
  tip: Tip[];
  sid?: Sid[];
  branch?: Tip[];
  watch?: boolean;
  recursiveDepth?: number;
  recursiveField?: Tip[];
  ifNotSid?: Sid[];
}

@Injectable({
  providedIn: 'root'
})
export class OpPullService {
  private _opPullFactory = new EnoFactory('op/pull', dataConstants.SECURITY.OP);
  private _opPullCache = new Map<Tip, Eno>();

  constructor() { }

  public createOpPull(option: IOpPullOption): Eno {
    this._normalizeOpPullOption(option);

    const opPullEno = this._opPullFactory.setFields([
      { tip: 'op/pull/tip', value: option.tip },
      { tip: 'op/pull/sid', value: option.sid },
      { tip: 'op/pull/branch', value: option.branch },
      { tip: 'op/pull/watch', value: [option.watch + ''] },
      { tip: 'op/pull/recursive-depth', value: option.recursiveDepth === 0 ? [] : [option.recursiveDepth + ''] },
      { tip: 'op/pull/recursive-field', value: option.recursiveField },
      { tip: 'op/pull/if-not-sid', value: option.ifNotSid },
    ]).makeEno();

    this._opPullCache.set(opPullEno.tip, opPullEno);

    return opPullEno;
  }

  private _normalizeOpPullOption(option: IOpPullOption) {
    option.sid = option.sid || [];
    option.branch = option.branch || [dataConstants.BRANCH_MASTER];
    option.watch = option.watch === undefined ? true : option.watch;
    option.recursiveDepth = option.recursiveDepth || 0;
    option.recursiveField = option.recursiveField || [];
    option.ifNotSid = option.ifNotSid || [];
  }

  // This method is mainly for re-do op/pull triggered by tip watch
  // That's why by default it resets watch and if-not-sid
  public getOpPull(tip: Tip, watch: boolean = false): Eno {
    const opPullEno = this._opPullCache.get(tip);

    if (opPullEno === undefined) {
      return null;
    }

    const factoryToPatch = new EnoFactory();
    factoryToPatch.setProto(opPullEno);
    factoryToPatch.setField({ tip: 'op/pull/watch', value: [watch + ''] });
    factoryToPatch.setField({ tip: 'op/pull/if-not-sid', value: [] });

    return factoryToPatch.makeEno();
  }
}
