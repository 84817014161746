import { Component, OnDestroy } from '@angular/core';
import { GroupsService } from '../../groups.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LoadingState } from '../../../shared/constants';
import { IObjectLaunchDone } from '../../../formly-noggin/object-launch';
import { IFlatGroup } from '../../../models/group';
import { Tip } from '../../../data/models/types';
import { SideSheetService } from '../../../side-sheet/side-sheet.service';
import { ToastService } from '../../../shell/services/toast.service';

@Component({
  selector: 'app-group-create-side-sheet',
  templateUrl: './group-create-side-sheet.component.html',
  styleUrls: ['./group-create-side-sheet.component.scss']
})
export class GroupCreateSideSheetComponent implements OnDestroy {

  parentGroupTip: Tip;
  groupForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    description: new FormControl('')
  });

  loadingState: LoadingState;
  LoadingState = LoadingState;
  done$ = new Subject<IObjectLaunchDone>();

  constructor(
    private groupsService: GroupsService,
    private sideSheetService: SideSheetService,
    private toastService: ToastService
  ) {}

  onPrimary() {
    this.loadingState = LoadingState.inProgress;
    this.groupsService.createGroup(this.groupForm.value.name, this.groupForm.value.description, this.parentGroupTip).pipe(
      first(),
    ).subscribe(({$tip}: IFlatGroup) => {
      this.done$.next({objectTip: $tip});
      this.sideSheetService.pop();
    }, () => {
      this.toastService.showErrorToast('Failed to create folder');
      this.loadingState = LoadingState.failed;
    });
  }

  ngOnDestroy() {
    this.done$.complete();
  }
}
