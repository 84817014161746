import * as tslib_1 from "tslib";
import { EMPTY, merge, of, pipe, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';
import { cloneDeep, isEmpty } from 'lodash';
import { CacheOpt } from '../util/cache';
import dataConstants from '../data/constants';
import { ESessionMessageDataType } from './pub-sub.service';
import { EnoFactory } from './EnoFactory';
import { NOW_VAR_NAME } from '../util/current-datetime.service';
import { StaticResultAnalysis } from '../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import * as i0 from "@angular/core";
import * as i1 from "./ensrv.service";
import * as i2 from "./eno-cache.service";
import * as i3 from "./pub-sub.service";
import * as i4 from "./i18n.service";
import * as i5 from "../util/current-datetime.service";
var FormulaMultiService = /** @class */ (function () {
    function FormulaMultiService(ensrvService, enoCache, pubSubService, i18nService, currentDatetimeService) {
        this.ensrvService = ensrvService;
        this.enoCache = enoCache;
        this.pubSubService = pubSubService;
        this.i18nService = i18nService;
        this.currentDatetimeService = currentDatetimeService;
        this.requestEnoResponseEnoMap = new Map();
    }
    FormulaMultiService.prototype.evaluate = function (formulas, cache) {
        var _this = this;
        if (cache === void 0) { cache = CacheOpt.USE_CACHE_THEN_NETWORK; }
        if (isEmpty(formulas)) {
            return of([]);
        }
        // If the entire formula batch has static results then simply return them
        try {
            return of(formulas.map(function (formula) { return StaticResultAnalysis(formula.formula, formula.vars); }));
        }
        catch (err) { }
        this.addNowVariable(formulas);
        return this.makeFormulaOperationEno$(formulas).pipe(switchMap(function (formulaOperationEno) {
            var responseTip = _this.requestEnoResponseEnoMap.get(formulaOperationEno.tip) || '';
            var existingEno = _this.enoCache.getEno(responseTip);
            var formulaResponse$ = _this.ensrvService
                .getEnoReceiver('response/formula')
                .pipe(transformMultiFormulaResponse(formulaOperationEno, _this.requestEnoResponseEnoMap, _this.enoCache));
            var formulaResponseWithCachedValue$ = _this.ensrvService
                .getEnoReceiver('response/formula')
                .pipe(startWith(existingEno), transformMultiFormulaResponse(formulaOperationEno, _this.requestEnoResponseEnoMap, _this.enoCache));
            var send = function () {
                return _this.ensrvService.send([formulaOperationEno]).pipe(catchError(function (e) {
                    var error = new Error("\n            Error sending multi formula\n            ------\n            For request eno: " + JSON.stringify(formulaOperationEno, null, 4) + "\n            ------\n            Original error: " + e + "\n           ");
                    return throwError(error);
                }), 
                // we never actually want to emit values via the send
                // values are emitted by the receiver
                // we just want to subscribe to it so it will get unsubscribed
                filter(function () { return false; }));
            };
            var watch$ = formulas.filter(function (formulaParams) { return formulaParams.watch; }).length
                ? _this.pubSubService.receiveSessionMessage(ESessionMessageDataType.formulaWatch).pipe(filter(function (data) { return data.op === formulaOperationEno.tip; }), switchMap(send))
                : EMPTY;
            if (cache === CacheOpt.USE_CACHE && existingEno) {
                return merge(formulaResponseWithCachedValue$, watch$);
            }
            var send$ = send();
            if (cache === CacheOpt.USE_CACHE_THEN_NETWORK && existingEno) {
                // using merge as want to be subscribed to send$
                return merge(send$, formulaResponseWithCachedValue$, watch$);
            }
            // the default is effectively the USE_NETWORK_NO_CACHE option
            // using merge as want to be subscribed to send$
            return merge(send$, formulaResponse$, watch$);
        }));
    };
    FormulaMultiService.prototype.makeFormulaOperationEno$ = function (formulas) {
        return this.i18nService.acceptableLocaleIds$.pipe(map(function (acceptableLocaleIds) { return formulaFactory
            .setBranch(dataConstants.BRANCH_MASTER)
            .setField('op/formula:multi-formula', [JSON.stringify(setDefaults(formulas))])
            .setField('op/formula:lang', acceptableLocaleIds)
            .makeEno(); }));
    };
    FormulaMultiService.prototype.addNowVariable = function (formulas) {
        var _this = this;
        formulas.forEach(function (formula) {
            if (!formula.vars) {
                formula.vars = {};
            }
            formula.vars[NOW_VAR_NAME] = [_this.currentDatetimeService.getCurrentDatetime()];
        });
    };
    FormulaMultiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FormulaMultiService_Factory() { return new FormulaMultiService(i0.ɵɵinject(i1.EnsrvService), i0.ɵɵinject(i2.EnoCacheService), i0.ɵɵinject(i3.PubSubService), i0.ɵɵinject(i4.I18nService), i0.ɵɵinject(i5.CurrentDatetimeService)); }, token: FormulaMultiService, providedIn: "root" });
    return FormulaMultiService;
}());
export { FormulaMultiService };
function enoTip(tip) {
    return function isEnoTip(formulaResponseEno) {
        var formulaOpTip = formulaResponseEno.getFieldStringValue('response/formula:op');
        return tip === formulaOpTip;
    };
}
function transformMultiFormulaResponse(multiFormulaEno, requestResponseEnoMap, enoCache) {
    return pipe(map(cloneDeep), filter(enoTip(multiFormulaEno.tip)), distinctUntilChanged(formulaEquivalent), map(function (formulaResponseEno) {
        requestResponseEnoMap.set(multiFormulaEno.tip, formulaResponseEno.tip);
        var _a = tslib_1.__read(formulaResponseEno.getFieldValues('response/formula:multi-result'), 1), jsonResult = _a[0];
        var _b = tslib_1.__read(formulaResponseEno.getFieldValues('response/formula:multi-errors'), 1), errorsResult = _b[0];
        if (errorsResult) {
            var errorTips = JSON.parse(errorsResult) || [];
            var errors = errorTips
                .reduce(function (acc, error) {
                if (error) {
                    // the errors will be in the eno cache
                    acc.push(enoCache.getEno(error));
                }
                return acc;
            }, []);
            if (errors.length) {
                throw new Error("Error fetching formulas " + JSON.stringify(errors, null, 4));
            }
        }
        var result = JSON.parse(jsonResult);
        return result;
    }), catchError(function (e) {
        var error = new Error("\n            Error with formula response\n            \n            ------\n            For request eno: " + JSON.stringify(multiFormulaEno, null, 4) + "\n            \n            ------\n            Original error: " + e + "\n           ");
        return throwError(error);
    }));
}
var formulaFactory = new EnoFactory('op/formula', dataConstants.SECURITY.OP);
function setDefaults(formulas) {
    return formulas.map(function (formula) { return (tslib_1.__assign({ contextBranches: [dataConstants.BRANCH_MASTER], watch: true }, formula)); });
}
function formulaEquivalent(prev, next) {
    var _a = tslib_1.__read(prev.getFieldValues('response/formula:multi-result'), 1), previousJsonResult = _a[0];
    var _b = tslib_1.__read(prev.getFieldValues('response/formula:multi-errors'), 1), previousErrorsResult = _b[0];
    var _c = tslib_1.__read(next.getFieldValues('response/formula:multi-result'), 1), nextJsonResult = _c[0];
    var _d = tslib_1.__read(next.getFieldValues('response/formula:multi-errors'), 1), nextErrorsResult = _d[0];
    var resultsEqual = previousJsonResult === nextJsonResult;
    var errorsEqual = previousErrorsResult === nextErrorsResult;
    return resultsEqual && errorsEqual;
}
