import { FieldWrapper } from '@ngx-formly/core';
import { Component, HostBinding, OnDestroy, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-field-wrapper-noggin-section',
  template: `
      <ng-template #section></ng-template>
  `,
  styleUrls: ['./noggin-form-section.wrappers.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormlyWrapperNogginSectionComponent extends FieldWrapper implements OnInit, OnDestroy {
  @ViewChild('section', {static: false, read: ViewContainerRef }) fieldComponent!: ViewContainerRef;
  @HostBinding('class.toggle-closed') collapsed = false;

  subscription: Subscription;

  ngOnInit() {
    if (this.to.toggle) {
      this.subscription = this.to.toggle.subscribe((v) => {
        this.setCollapsed(v);
      });
    }
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
