import { FieldType } from '@ngx-formly/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContextTypeFieldOptions } from '../../context-type-field-option-utils/context-type-field-option-utils';
import { get } from 'lodash';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Tip } from '../../../data/models/types';

@Component({
  selector: 'app-query-filter-chooser',
  template: `
    <app-query-chooser
        [formControl]="formControl"
        [label]="field.templateOptions.label"
        [placeholder]="field.templateOptions.placeholder"
        [objectTypeTip]="objectTypeTip"
        [contextTypeTip]="to.formContext"
        [contextTypeTitle]="to.formContextTitle"
        [moduleTip]="moduleTip"
        [required]="to.required"
        [filterOutQueriesWithVars]="to.filterOutQueriesWithVars || false"
        [withFilterInputs]="true"
        [showFormFieldInputs]="to.showFormFieldInputs"></app-query-chooser>
  `
})
export class FormlyFieldQueryChooserComponent extends FieldType implements OnInit, OnDestroy {
  objectTypeTip: string | null = null;
  moduleTip: Tip;

  changes: Subscription;

  private contextTypeOptions: ContextTypeFieldOptions;

  constructor(private route: ActivatedRoute) {super(); }

  ngOnInit() {
    this.contextTypeOptions = get(this, 'formState.contextTypeOptions', null);

    // This will work for the references within primary router outlet.
    // The route params will not be available in non-routable side-sheet. Can be enchanced to chain the moduleTip as an Input.
    this.moduleTip = get(this.route, 'snapshot.params.moduleTip')
      || get(this, 'field.templateOptions.moduleTip', null);

    this.setParams();

    this.changes = this
      .form
      .valueChanges
      .subscribe(() => this.setParams());
  }

  setParams() {
    this.objectTypeTip = null;

    const formOptionsObjectTypeTip = get(this, 'field.templateOptions.objectTypeTip', null);
    if (formOptionsObjectTypeTip) {
     this.objectTypeTip = formOptionsObjectTypeTip;
    }

    // fieldToFilter used in the form designer
    const fieldToFilter = get(this, 'field.templateOptions.fieldToFilter', null);
    if (fieldToFilter && this.contextTypeOptions) {
      const contextTypeOption = this.contextTypeOptions.values.get(fieldToFilter);
      this.objectTypeTip = get(contextTypeOption, 'typerestrict[0]');
    }
  }

  ngOnDestroy() {
    this.changes.unsubscribe();
  }
}
