import { Injectable } from '@angular/core';
import * as moment from 'moment';

export const NOW_VAR_NAME = '---NOW---';

@Injectable({
  providedIn: 'root'
})
export class CurrentDatetimeService {

  constructor() { }

  getCurrentDatetime(): string {
    return moment().format('YYYY-MM-DDTHH:mm:ssZZ');
  }
}
