import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormlyFieldConfig, FormlyTemplateOptions } from '@ngx-formly/core';
import { camelCase, sentenceCase } from 'change-case';
import * as moment from 'moment';
import { first, switchMap, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { WorkflowLineValueFormUtils, IFieldData } from './workflow-line-value-form-utils';
import { SubstitutionMetaService } from '../../../../../data/string-interpolation/substitution-meta.service';
import { ALL_FIELD_DATA_TYPE, fieldScheme, TYPE_GEO_RESTRICT } from '../../../../../models/field';
import { Tip } from '../../../../../data/models/types';
import { LoadingState } from '../../../../../shared/constants';
import { LoggerService } from '../../../../../util/logger.service';
import { ObjectService } from '../../../../../data/object-service/object.service';
import { IAddress } from '../../../../../models/map';
import { SKETCH_TOOL } from '../../../../../shared/map/map/map-util.service';
import {
  ADDRESS_CHOOSER_FORMLY_CONFIG,
  BOOLEAN_CHOOSER_FORMLY_CONFIG,
  DATE_TIME_FORMLY_CONFIG,
  GEO_CHOOSER_FORMLY_CONFIG, STRING_INTERPOLATE_RICH_TEXT_FORMLY_CONFIG,
  STRING_INTERPOLATE_TEXT_FORMLY_CONFIG
} from '../../../../../formly-noggin/ngx-formly-bootstrap/bootstrap.config';

const NS = 'WorkflowLineValueFormDrillDownComponent';
export const ERR_MSG_UNSUPPORTED = 'Sorry, this field type does not support manual entry.';

export interface IManualEntryData {
  value: string;
  label: string;
}

export interface IManualEntryProps {
  fieldDataType: ALL_FIELD_DATA_TYPE; // data type of the field to enter. Set to null if passing in an object.
  objectType: Tip; // data type of the object, if the field is an object (eg 'app/address'). Set to null if passing a FIELD_DATA_TYPE.
  typeGeoRestrict: TYPE_GEO_RESTRICT; // only used for FIELD_DATA_TYPE.geography, specifies Point, Polygon or Line type
  value: any; // predefined values to populate the field with,
  allowStringInterpolation?: boolean; // if true, then the string and html field type will be the string interpolation version.
  additionalTemplateOptions?: FormlyTemplateOptions;
}

@Component({
  selector: 'app-workflow-line-value-form-drill-down',
  templateUrl: './workflow-line-value-form-drill-down.component.html',
  styleUrls: ['./workflow-line-value-form-drill-down.component.scss']
})
export class WorkflowLineValueFormDrillDownComponent implements OnInit {

  form: FormGroup;
  fields: FormlyFieldConfig[];
  model: { [key: string]: any };

  fieldDataType: ALL_FIELD_DATA_TYPE;
  objectType: Tip;
  typeGeoRestrict: TYPE_GEO_RESTRICT;
  value: any;
  allowStringInterpolation = false;
  additionalTemplateOptions: FormlyTemplateOptions = {};

  errorMessage: string;
  formReady = false;

  progressState = LoadingState.inProgress;
  loadingState: LoadingState = LoadingState.empty;


  /**
   * Double stringifies JSON so quotes get escaped, then removes the first and last quotes so it can be safely inserted
   * into a formula as a string.
   */
  static jsonSafeString(rawValue: any): string {
    const str = JSON.stringify(JSON.stringify(rawValue));
    return str.substr(1, str.length - 2);
  }

  static jsonSafeParse(value: string): any {
    return JSON.parse(JSON.parse('"' + value + '"'));
  }

  done: (value: IManualEntryData) => void = () => {};

  constructor(
    private fb: FormBuilder,
    private objectService: ObjectService,
    private loggerService: LoggerService,
    private substitutionMetaService: SubstitutionMetaService
  ) {}

  /**
   * Use set props when opening the side sheet
   */
  setProps(props: IManualEntryProps): void {
    this.fieldDataType = props.fieldDataType;
    this.objectType = props.objectType;
    this.typeGeoRestrict = props.typeGeoRestrict;
    this.value = props.value;
    this.allowStringInterpolation = props.allowStringInterpolation || false;
    this.additionalTemplateOptions = props.additionalTemplateOptions || {};
  }

  // tslint:disable-next-line:cyclomatic-complexity
  ngOnInit() {

    this.form = this.fb.group({
      item: [null, Validators.required]
    });

    const fieldData: IFieldData = WorkflowLineValueFormUtils.getFieldData().find(o => {
      return o.isObject
        ? o.dataOrObjectType.indexOf(this.objectType) !== -1
        : o.dataOrObjectType.indexOf(this.fieldDataType) !== -1;
    });

    if (fieldData) {

      if (this.value) {
        switch (this.allowStringInterpolation ? fieldData.stringInterpolatedType || fieldData.type : fieldData.type) {

          case DATE_TIME_FORMLY_CONFIG.name:
            this.value = moment(this.value);
            break;

          case GEO_CHOOSER_FORMLY_CONFIG.name:
            this.value = WorkflowLineValueFormDrillDownComponent.jsonSafeParse(this.value);
            break;
          case STRING_INTERPOLATE_TEXT_FORMLY_CONFIG.name:
          case STRING_INTERPOLATE_RICH_TEXT_FORMLY_CONFIG.name:
            try {
              this.value = JSON.parse(this.value);
            } catch (e) {
              this.value = { html: this.value, substitutions: [] };
            }
            break;
        }
      }

      switch (this.allowStringInterpolation ? fieldData.stringInterpolatedType || fieldData.type : fieldData.type) {
        case GEO_CHOOSER_FORMLY_CONFIG.name:
          fieldData.label = sentenceCase(this.getSketchToolType()) || '';
          break;
        case STRING_INTERPOLATE_TEXT_FORMLY_CONFIG.name:
        case STRING_INTERPOLATE_RICH_TEXT_FORMLY_CONFIG.name:
          this.value = this.value || { html: '', substitutions: [] };
          break;
      }

      const key: string = camelCase(this.allowStringInterpolation ? fieldData.stringInterpolatedType || fieldData.type : fieldData.type);

      this.fields = [{
        key,
        type: this.allowStringInterpolation ? fieldData.stringInterpolatedType || fieldData.type : fieldData.type,
        templateOptions: {
          label: fieldData.label,
          placeholder: fieldData.placeholder || null,
          type: this.getSketchToolType() || fieldData.inputType || null,
          required: true,
          ...this.additionalTemplateOptions
        }
      }];

      this.model = {
        [key]: this.value
      };

      this.formReady = true;
    } else {
      this.errorMessage = ERR_MSG_UNSUPPORTED;
    }
  }

  onPrimary() {
    let data$: Observable<IManualEntryData>;

    const field: FormlyFieldConfig = this.fields[0];
    const rawValue: any = this.model[field.key];

    this.loadingState = LoadingState.inProgress;

    switch (field.type) {
      case DATE_TIME_FORMLY_CONFIG.name:
        data$ = this.getDateData(rawValue, field);
        break;

      case BOOLEAN_CHOOSER_FORMLY_CONFIG.name:
        data$ = this.getBooleanData(rawValue);
        break;

      case GEO_CHOOSER_FORMLY_CONFIG.name:
        data$ = this.getGeoData(rawValue);
        break;

      case ADDRESS_CHOOSER_FORMLY_CONFIG.name:
        data$ = this.getAddressData(rawValue);
        break;

      case STRING_INTERPOLATE_TEXT_FORMLY_CONFIG.name:
      case STRING_INTERPOLATE_RICH_TEXT_FORMLY_CONFIG.name:
        data$ = of({
          label: this.substitutionMetaService.replaceSubstitutions(rawValue.html, rawValue.substitutions),
          value: JSON.stringify(rawValue)
        });
        break;

      default:
        const stringValue = (rawValue === null || rawValue === undefined) ? '' : rawValue.toString();
        data$ = of({
          value: stringValue,
          label: typeof rawValue === 'object' ? `(Object)` : stringValue
        });
    }

    data$.pipe(first()).subscribe(data => {
      this.loadingState = LoadingState.loaded;
      this.done(data);
    });
  }

  private getAddressData(rawValue: any): Observable<IManualEntryData> {

    return this.objectService.getObject(rawValue, fieldScheme)
      .pipe(
        first(),
        switchMap( (o: IAddress) => {
          return of({ value: rawValue, label: o.displayStreet });
        }),
        catchError( err => {
          this.loggerService.warn(NS, `Couldn't retrieve address via tip of "${rawValue}".`, err);
          return of({ value: rawValue, label: rawValue });
        })
      );
  }

  private getDateData(rawValue: any, field: FormlyFieldConfig): Observable<IManualEntryData> {

    let value: string;
    let label: string;

    // format that can be read by Moment.js when reading value back in
    value = rawValue.format();
    // nicer formatting for labels (either 'datetime' or 'date')
    label = rawValue.format( field.templateOptions.type === 'datetime' ? 'LLL' : 'LL');

    return of({ value, label });
  }


  private getBooleanData(rawValue: any): Observable<IManualEntryData> {

    let value: string;
    let label: string;

    if (rawValue && typeof rawValue === 'object') {
      value = rawValue.value ? rawValue.value.toString() : 'false';
      label = rawValue.label;
    } else if (typeof rawValue === 'string') {
      value = rawValue;
      label = rawValue === 'true' ? 'True' : 'False';
    } else {
      value = rawValue ? 'true' : 'false';
      label = rawValue ? 'True' : 'False';
    }

    return of({ value, label });
  }

  private getGeoData(rawValue: any): Observable<IManualEntryData> {

    let value: string;
    let label: string;

    // stringifies the object data so it can be ready back in using JSON.parse, but stil saved as a string
    value = rawValue ? WorkflowLineValueFormDrillDownComponent.jsonSafeString(rawValue) : '';
    /**
      * Checks that 'coordinates' property exists and is an array (which it should be), then reverses the array just for
      * 'Point' so that it reads as Lat,Lon. Other geo types are left as is.
      */
    label = rawValue && rawValue.coordinates && Array.isArray(rawValue.coordinates)
      ? this.typeGeoRestrict === TYPE_GEO_RESTRICT.POINT ? rawValue.coordinates.reverse().join(',') : rawValue.coordinates.join(',')
      : '(not set)';

      return of({ value, label });
  }


  private getSketchToolType(): SKETCH_TOOL {
    if (!this.typeGeoRestrict) {
      return null;
    }

    switch (this.typeGeoRestrict) {
      case TYPE_GEO_RESTRICT.POINT:
        return SKETCH_TOOL.POINT;

      case TYPE_GEO_RESTRICT.POLYGON:
        return SKETCH_TOOL.POLYGON;

      case TYPE_GEO_RESTRICT.LINE:
        return SKETCH_TOOL.POLYLINE;
    }
  }

}
