import * as tslib_1 from "tslib";
import { throwError, combineLatest, of } from 'rxjs';
import { map, first, switchMap, mapTo, catchError } from 'rxjs/operators';
import { INodeEditorData } from './workflow-designer-interfaces';
import { MODULE_POLICY_TYPE } from '../modules/module.service';
import dataConstants from '../../data/constants';
import { workflowReadScheme, workflowScheme } from '../../models/workflow';
import { processScheme } from '../../models/process';
import { head } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "./process-converter.service";
import * as i2 from "../../data/process.service";
import * as i3 from "../../data/object-service/object.service";
import * as i4 from "../modules/module.service";
import * as i5 from "../../data/formula.service";
import * as i6 from "../modules/module-package.service";
export var EMPTY_DIAGRAM = { nodeDataArray: [], linkDataArray: [] };
var WorkflowDesignerService = /** @class */ (function () {
    function WorkflowDesignerService(processConverterService, processService, objectService, moduleService, formulaService, modulePackageService) {
        this.processConverterService = processConverterService;
        this.processService = processService;
        this.objectService = objectService;
        this.moduleService = moduleService;
        this.formulaService = formulaService;
        this.modulePackageService = modulePackageService;
    }
    WorkflowDesignerService.prototype.deleteWorkflow = function (tip, moduleTip) {
        return combineLatest([
            this.deleteWorkflowTriggers(tip),
            this.softDeleteWorkflow(tip, moduleTip)
        ]).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), deleteTriggerResponse = _b[0], softDeleteWorkflowResponse = _b[1];
            return deleteTriggerResponse.finished && softDeleteWorkflowResponse;
        }));
    };
    WorkflowDesignerService.prototype.deleteWorkflowTriggers = function (tip) {
        return this.processService.start('eim/process/disable-workflow-trigger', { 'Workflow tip': [tip] });
    };
    WorkflowDesignerService.prototype.softDeleteWorkflow = function (tip, moduleTip) {
        var _this = this;
        return this.formulaService.evaluate('FIELD("app/workflow:process")', tip)
            .pipe(map(head), switchMap(function (process) {
            var tipsToPatch = process
                ? [process, tip]
                : [tip];
            return _this
                .moduleService
                .deleteModuleConfig(tipsToPatch);
        }), map(function (_) { return true; }), catchError(function () {
            return _this.modulePackageService.handleError('delete workflow', moduleTip);
        }));
    };
    // TODO EIM-6092. This should be using IWorkflowRead.
    WorkflowDesignerService.prototype.getWorkflow = function (tip) {
        var _this = this;
        return this.objectService.getObject(tip, workflowReadScheme)
            .pipe(first(function (value) {
            _this.currentWorkflow = value;
            return true;
        }));
    };
    WorkflowDesignerService.prototype.getWorkflowRead = function (tip) {
        return this.objectService.getObject(tip, workflowReadScheme).pipe(first());
    };
    WorkflowDesignerService.prototype.saveWorkflow = function (workflow, nodeDataForProcess, moduleTip, dryRun) {
        var _this = this;
        if (dryRun === void 0) { dryRun = false; }
        try {
            if (nodeDataForProcess) {
                workflow.process = this.processConverterService.convertWorkFlowToProcess(workflow, nodeDataForProcess);
            }
            else {
                // if no nodeDataForProcess, clears the nodes prop on process
                if (workflow.process) {
                    workflow.process.nodes = [];
                }
                workflow.process = workflow.process || this.getEmptyProcess();
            }
            return combineLatest([
                this.moduleService.getModulePolicy(moduleTip, MODULE_POLICY_TYPE.WORKFLOW_PROCESS),
                this.moduleService.getModulePolicy(moduleTip, MODULE_POLICY_TYPE.INSTANCE)
            ])
                .pipe(switchMap(function (_a) {
                var _b = tslib_1.__read(_a, 2), workflowProcessPolicy = _b[0], instancePolicy = _b[1];
                if (workflow && workflow.process) {
                    workflow.process.$security = workflowProcessPolicy
                        || 'app/security-policy/process-workflow-user'; // -!-!-!- DELETE BACKUP OPTION AFTER MIGRATION - EIM-2691 -!-!-!-;
                }
                if (dryRun) {
                    return of(workflow);
                }
                return _this.objectService.setObject(workflow, workflowScheme, dataConstants.BRANCH_MASTER, instancePolicy || 'app/security-policy/instance-admin-admin');
            }), map(function (convertedWorkflow) { return dryRun ? convertedWorkflow : true; }), catchError(function () {
                return _this.modulePackageService.handleError('save workflow', moduleTip);
            }));
        }
        catch (e) {
            return throwError(e);
        }
    };
    WorkflowDesignerService.prototype.saveWithoutProcess = function (workflow, moduleTip) {
        var _this = this;
        try {
            return combineLatest([
                this.moduleService.getModulePolicy(moduleTip, MODULE_POLICY_TYPE.WORKFLOW_PROCESS),
                this.moduleService.getModulePolicy(moduleTip, MODULE_POLICY_TYPE.INSTANCE)
            ])
                .pipe(switchMap(function (_a) {
                var _b = tslib_1.__read(_a, 2), workflowProcessPolicy = _b[0], instancePolicy = _b[1];
                workflow.process = _this.getEmptyProcess(workflow.name, workflow.description, workflowProcessPolicy);
                return _this.objectService.setObject(workflow, workflowScheme, dataConstants.BRANCH_MASTER, instancePolicy || 'app/security-policy/instance-admin-admin');
            }), map(function () { return true; }), catchError(function () {
                return _this.modulePackageService.handleError('save workflow', moduleTip);
            }));
        }
        catch (e) {
            return throwError(e);
        }
    };
    WorkflowDesignerService.prototype.getEmptyProcess = function (name, description, workflowProcessPolicy) {
        if (name === void 0) { name = ''; }
        if (description === void 0) { description = ''; }
        if (workflowProcessPolicy === void 0) { workflowProcessPolicy = 'app/security-policy/process-workflow-user'; }
        return {
            $type: 'process',
            $security: workflowProcessPolicy,
            title: name,
            description: description,
            nodes: [],
            env: 'Server'
        };
    };
    /**
     * When creating a new workflow, this inserts an empty process
     */
    WorkflowDesignerService.prototype.createEmptyProcess = function (name, description, workflowProcessPolicy) {
        var process = this.getEmptyProcess(name, description, workflowProcessPolicy);
        return this.objectService
            .setObject(process, processScheme)
            .pipe(first(), map(function (batch) {
            process.$tip = batch[0].tip;
            return process;
        }));
    };
    WorkflowDesignerService.prototype.createNewWorkflow = function (name, description, moduleTip) {
        var _this = this;
        return this.moduleService
            .getModulePolicy(moduleTip, MODULE_POLICY_TYPE.WORKFLOW_PROCESS)
            .pipe(first(), switchMap(function (workflowProcessPolicy) {
            return combineLatest([
                _this.createEmptyProcess(name, description, workflowProcessPolicy).pipe(first()),
                _this.moduleService.getModulePolicy(moduleTip, MODULE_POLICY_TYPE.INSTANCE).pipe(first())
            ]);
        }), switchMap(function (_a) {
            var _b = tslib_1.__read(_a, 2), process = _b[0], instancePolicy = _b[1];
            _this.currentWorkflow = {
                $type: 'app/workflow',
                process: process,
                isAdminMode: false,
                diagramData: EMPTY_DIAGRAM,
                name: name,
                description: description,
                nodes: null,
                inputs: [],
                actors: [],
                variables: []
            };
            return _this.objectService.setObject(_this.currentWorkflow, workflowScheme, dataConstants.BRANCH_MASTER, instancePolicy || 'app/security-policy/instance-admin-admin')
                .pipe(first());
        }), switchMap(function (batch) {
            var tip = batch[batch.length - 1].tip;
            _this.currentWorkflow.$tip = tip;
            return _this.moduleService
                .addTipToFlatModuleField({
                moduleTip: moduleTip,
                moduleField: 'workflows',
                tip: tip
            })
                .pipe(mapTo(tip));
        }), catchError(function () {
            return _this.modulePackageService.handleError('save workflow', moduleTip);
        }));
    };
    WorkflowDesignerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WorkflowDesignerService_Factory() { return new WorkflowDesignerService(i0.ɵɵinject(i1.ProcessConverterService), i0.ɵɵinject(i2.ProcessService), i0.ɵɵinject(i3.ObjectService), i0.ɵɵinject(i4.ModuleService), i0.ɵɵinject(i5.FormulaService), i0.ɵɵinject(i6.ModulePackageService)); }, token: WorkflowDesignerService, providedIn: "root" });
    return WorkflowDesignerService;
}());
export { WorkflowDesignerService };
