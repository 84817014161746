var _a, _b, _c;
import { DataTypes } from '../data/models/scheme';
import { environment } from '../../environments/environment';
export var GEOMETRY_TYPES;
(function (GEOMETRY_TYPES) {
    GEOMETRY_TYPES["POINT"] = "point";
    GEOMETRY_TYPES["LINE"] = "line";
    GEOMETRY_TYPES["POLYGON"] = "polygon";
})(GEOMETRY_TYPES || (GEOMETRY_TYPES = {}));
/************************* Map data source *****************************/
export var DATA_SOURCE_TYPES;
(function (DATA_SOURCE_TYPES) {
    DATA_SOURCE_TYPES["NOGGIN_QUERY"] = "noggin/query";
    DATA_SOURCE_TYPES["EXTERNAL_GEOJSON"] = "external/geojson";
    DATA_SOURCE_TYPES["EXTERNAL_GEORSS"] = "external/georss";
    DATA_SOURCE_TYPES["EXTERNAL_KML"] = "external/kml";
    DATA_SOURCE_TYPES["EXTERNAL_WMS"] = "external/wms";
    DATA_SOURCE_TYPES["MAPBOX_VECTOR_TILES"] = "mapbox/vector-tiles";
    DATA_SOURCE_TYPES["ESRI_FEATURE_LAYER"] = "esri/feature-layer";
    DATA_SOURCE_TYPES["ESRI_DYNAMIC_MAP_SERVICE"] = "esri/dynamic-map-service";
    DATA_SOURCE_TYPES["ESRI_TILED_MAP_SERVICE"] = "esri/tiled-map-service";
    DATA_SOURCE_TYPES["ESRI_VECTOR_TILE_SERVICE"] = "esri/vector-tile-service";
    DATA_SOURCE_TYPES["ESRI_IMAGE_SERVICE"] = "esri/image-service";
})(DATA_SOURCE_TYPES || (DATA_SOURCE_TYPES = {}));
export var dataSourceCategories = [
    {
        label: 'Noggin',
        types: [
            DATA_SOURCE_TYPES.NOGGIN_QUERY
        ]
    },
    {
        label: 'External feeds',
        types: [
            DATA_SOURCE_TYPES.EXTERNAL_GEOJSON,
            DATA_SOURCE_TYPES.EXTERNAL_GEORSS,
            DATA_SOURCE_TYPES.EXTERNAL_KML,
            DATA_SOURCE_TYPES.EXTERNAL_WMS
        ]
    },
    {
        label: 'MapBox',
        types: [
            DATA_SOURCE_TYPES.MAPBOX_VECTOR_TILES,
        ]
    },
    {
        label: 'Esri',
        types: [
            DATA_SOURCE_TYPES.ESRI_FEATURE_LAYER,
            DATA_SOURCE_TYPES.ESRI_DYNAMIC_MAP_SERVICE,
            DATA_SOURCE_TYPES.ESRI_TILED_MAP_SERVICE,
            DATA_SOURCE_TYPES.ESRI_VECTOR_TILE_SERVICE,
            DATA_SOURCE_TYPES.ESRI_IMAGE_SERVICE
        ]
    }
];
export var displayName = (_a = {},
    _a[DATA_SOURCE_TYPES.NOGGIN_QUERY] = 'Query',
    _a[DATA_SOURCE_TYPES.EXTERNAL_GEOJSON] = 'GeoJSON',
    _a[DATA_SOURCE_TYPES.EXTERNAL_GEORSS] = 'GeoRSS',
    _a[DATA_SOURCE_TYPES.EXTERNAL_KML] = 'KML',
    _a[DATA_SOURCE_TYPES.EXTERNAL_WMS] = 'WMS',
    _a[DATA_SOURCE_TYPES.MAPBOX_VECTOR_TILES] = 'Vector tiles',
    _a[DATA_SOURCE_TYPES.ESRI_FEATURE_LAYER] = 'Feature layer',
    _a[DATA_SOURCE_TYPES.ESRI_DYNAMIC_MAP_SERVICE] = 'Dynamic map service',
    _a[DATA_SOURCE_TYPES.ESRI_TILED_MAP_SERVICE] = 'Tiled map service',
    _a[DATA_SOURCE_TYPES.ESRI_VECTOR_TILE_SERVICE] = 'Vector tile service',
    _a[DATA_SOURCE_TYPES.ESRI_IMAGE_SERVICE] = 'Image service',
    _a);
export var mapDataSourceScheme = {
    'app/map/source:name': { type: DataTypes.i18n },
    'app/map/source:type': { type: DataTypes.string },
    'app/map/source:config': { type: DataTypes.json }
};
/************************* Map style *****************************/
export var STROKE_DASH_STYLE;
(function (STROKE_DASH_STYLE) {
    STROKE_DASH_STYLE["SOLID"] = "solid";
    STROKE_DASH_STYLE["DASH"] = "dash";
    STROKE_DASH_STYLE["DASH_DOT"] = "dash-dot";
    STROKE_DASH_STYLE["DOT"] = "dot";
    STROKE_DASH_STYLE["LONG_DASH"] = "long-dash";
    STROKE_DASH_STYLE["LONG_DASH_DOT"] = "long-dash-dot";
    STROKE_DASH_STYLE["LONG_DASH_DOT_DOT"] = "long-dash-dot-dot";
    STROKE_DASH_STYLE["SHORT_DASH"] = "short-dash";
    STROKE_DASH_STYLE["SHORT_DASH_DOT"] = "short-dash-dot";
    STROKE_DASH_STYLE["SHORT_DASH_DOT_DOT"] = "short-dash-dot-dot";
    STROKE_DASH_STYLE["SHORT_DOT"] = "short-dot";
    STROKE_DASH_STYLE["NONE"] = "none";
})(STROKE_DASH_STYLE || (STROKE_DASH_STYLE = {}));
export var lineDashStyleNamePicMap = (_b = {},
    _b[STROKE_DASH_STYLE.DASH] = {
        name: 'Dash',
        picUrl: environment.assetPath + "/assets/img/maps/dash-styles/" + STROKE_DASH_STYLE.DASH + ".png"
    },
    _b[STROKE_DASH_STYLE.DASH_DOT] = {
        name: 'Dash dot',
        picUrl: environment.assetPath + "/assets/img/maps/dash-styles/" + STROKE_DASH_STYLE.DASH_DOT + ".png"
    },
    _b[STROKE_DASH_STYLE.DOT] = {
        name: 'Dot',
        picUrl: environment.assetPath + "/assets/img/maps/dash-styles/" + STROKE_DASH_STYLE.DOT + ".png"
    },
    _b[STROKE_DASH_STYLE.LONG_DASH] = {
        name: 'Long dash',
        picUrl: environment.assetPath + "/assets/img/maps/dash-styles/" + STROKE_DASH_STYLE.LONG_DASH + ".png"
    },
    _b[STROKE_DASH_STYLE.LONG_DASH_DOT] = {
        name: 'Long dash dot',
        picUrl: environment.assetPath + "/assets/img/maps/dash-styles/" + STROKE_DASH_STYLE.LONG_DASH_DOT + ".png"
    },
    _b[STROKE_DASH_STYLE.LONG_DASH_DOT_DOT] = {
        name: 'Long dash dot dot',
        picUrl: environment.assetPath + "/assets/img/maps/dash-styles/" + STROKE_DASH_STYLE.LONG_DASH_DOT_DOT + ".png"
    },
    _b[STROKE_DASH_STYLE.NONE] = {
        name: 'None'
    },
    _b[STROKE_DASH_STYLE.SHORT_DASH] = {
        name: 'Short dash',
        picUrl: environment.assetPath + "/assets/img/maps/dash-styles/" + STROKE_DASH_STYLE.SHORT_DASH + ".png"
    },
    _b[STROKE_DASH_STYLE.SHORT_DASH_DOT] = {
        name: 'Short dash dot',
        picUrl: environment.assetPath + "/assets/img/maps/dash-styles/" + STROKE_DASH_STYLE.SHORT_DASH_DOT + ".png"
    },
    _b[STROKE_DASH_STYLE.SHORT_DASH_DOT_DOT] = {
        name: 'Short dash dot dot',
        picUrl: environment.assetPath + "/assets/img/maps/dash-styles/" + STROKE_DASH_STYLE.SHORT_DASH_DOT_DOT + ".png"
    },
    _b[STROKE_DASH_STYLE.SHORT_DOT] = {
        name: 'Short dot',
        picUrl: environment.assetPath + "/assets/img/maps/dash-styles/" + STROKE_DASH_STYLE.SHORT_DOT + ".png"
    },
    _b[STROKE_DASH_STYLE.SOLID] = {
        name: 'Solid',
        picUrl: environment.assetPath + "/assets/img/maps/dash-styles/" + STROKE_DASH_STYLE.SOLID + ".png"
    },
    _b);
export var SYMBOLOGY_SIZE;
(function (SYMBOLOGY_SIZE) {
    SYMBOLOGY_SIZE["SMALL"] = "small";
    SYMBOLOGY_SIZE["MEDIUM"] = "medium";
    SYMBOLOGY_SIZE["LARGE"] = "large"; // 42x42 icon font-size 2.6rem
})(SYMBOLOGY_SIZE || (SYMBOLOGY_SIZE = {}));
export var mapStyleScheme = {
    'app/map/style:name': { type: DataTypes.i18n },
    'app/map/style:type': { type: DataTypes.string },
    'app/map/style:config': { type: DataTypes.json }
};
/************************* Map layer *****************************/
export var STYLE_TYPES;
(function (STYLE_TYPES) {
    STYLE_TYPES["DEFAULT"] = "default";
    STYLE_TYPES["STYLE"] = "style";
    STYLE_TYPES["HEATMAP"] = "heatmap";
})(STYLE_TYPES || (STYLE_TYPES = {}));
export var mapLayerScheme = {
    'app/map/layer:name': { type: DataTypes.i18n },
    'app/map/layer:data-source': { type: DataTypes.object, scheme: mapDataSourceScheme },
    'app/map/layer:is-base-map': { type: DataTypes.boolean },
    'app/map/layer:geometry-type': { type: DataTypes.string },
    'app/map/layer:style-type': { type: DataTypes.string },
    'app/map/layer:style': { type: DataTypes.string },
    'app/map/layer:heatmap-radius': { type: DataTypes.number },
    'app/map/layer:has-min-zoom': { type: DataTypes.boolean },
    'app/map/layer:has-max-zoom': { type: DataTypes.boolean },
    'app/map/layer:min-zoom-level': { type: DataTypes.number },
    'app/map/layer:max-zoom-level': { type: DataTypes.number },
    'app/map/layer:opacity': { type: DataTypes.number },
    'app/map/layer:visible-properties': { type: DataTypes.stringArray } // TODO: replace with proper scheme when its ready
};
/************************* Map extent *****************************/
export var EXTENT_TYPES;
(function (EXTENT_TYPES) {
    EXTENT_TYPES["DEFAULT"] = "default";
    EXTENT_TYPES["LOCATION"] = "location";
    EXTENT_TYPES["POLYGON"] = "polygon";
    EXTENT_TYPES["LAYER"] = "layer";
    EXTENT_TYPES["ALL_LAYERS"] = "all-layers";
})(EXTENT_TYPES || (EXTENT_TYPES = {}));
export var extentTypeNameMap = (_c = {},
    _c[EXTENT_TYPES.DEFAULT] = 'Honor system extent default',
    _c[EXTENT_TYPES.LOCATION] = 'User\'s current location',
    _c[EXTENT_TYPES.POLYGON] = 'Define custom region on the map',
    _c[EXTENT_TYPES.LAYER] = 'Specific layers',
    _c[EXTENT_TYPES.ALL_LAYERS] = 'All layers',
    _c);
export var mapExtentScheme = {
    'app/map/extent:type': { type: DataTypes.string },
    'app/map/extent:layer': { type: DataTypes.objectArray, scheme: mapLayerScheme },
    'app/map/extent:polygon': { type: DataTypes.geography },
    'app/map/extent:zoom-level': { type: DataTypes.number }
};
export var flatMapExtentScheme = {
    'app/map/extent:type': { type: DataTypes.string },
    'app/map/extent:layer': { type: DataTypes.stringArray },
    'app/map/extent:polygon': { type: DataTypes.geography },
    'app/map/extent:zoom-level': { type: DataTypes.number }
};
export var mapScheme = {
    'app/map:name': { type: DataTypes.i18n },
    'app/map:base-map': { type: DataTypes.object, scheme: mapLayerScheme },
    'app/map:layers': { type: DataTypes.objectArray, scheme: mapLayerScheme },
    'app/map:extent': { type: DataTypes.object, scheme: mapExtentScheme }
};
export var flatMapScheme = {
    'app/map:name': { type: DataTypes.i18n },
    'app/map:base-map': { type: DataTypes.string },
    'app/map:layers': { type: DataTypes.stringArray },
    'app/map:extent': { type: DataTypes.object, scheme: flatMapExtentScheme, mutable: true }
};
export var addressScheme = {
    'app/address:display-street': { type: DataTypes.i18n },
    'app/address:point': { type: DataTypes.geography },
    'app/address:meta': { type: DataTypes.json }
};
