export function removeEscapesFromJSON(value: string): string {
  return value.replace(/\\/g, '');
}

export function castArrayAndRemoveListEscapes(value: string | string[]): string[] {
  if (Array.isArray(value)) {
    return value;
  }

  return removeListEscapes(value);
}

export function removeListEscapes(value: string) {
  // if value itself contains comma it will be escaped, so need to split here by unescaped commas
  // can not use negative lookbehind due to issue with minification tool - https://github.com/terser-js/terser/issues/269
  // pavlo originally wrote this copied from field-formatting
  return value
    .replace(/\\,/g, '&escapedquot;')
    .split(',')
    .map(x => x.replace(/\&escapedquot;/g, ','));
}
