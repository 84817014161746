import * as tslib_1 from "tslib";
import { NodeConverterBase } from './NodeConverterBase';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import { stringInterpolateFormula } from '../utils/converter-utils';
import { isEmpty } from 'lodash';
import dataConstants from '../../../data/constants';
import * as i0 from "@angular/core";
var CreateObjectNodeConverterService = /** @class */ (function (_super) {
    tslib_1.__extends(CreateObjectNodeConverterService, _super);
    function CreateObjectNodeConverterService() {
        var _this = _super.call(this) || this;
        _this.type = 'processnode/data/create';
        _this.processNodeType = 'processnodetype/data/create';
        return _this;
    }
    CreateObjectNodeConverterService.prototype.convert = function (nodeData) {
        if (!this.isValid(nodeData)) {
            throw new InvalidWorkflowNodeDataError("Please correct invalid data on create object node [" + nodeData.fields.title + "]", nodeData);
        }
        var convertedFieldValues = nodeData.fields.fieldItems.map(function (fieldItem) { return stringInterpolateFormula({
            formula: fieldItem.fieldValue,
            isManualEntry: fieldItem.isManualEntry,
            isStringInterpolation: fieldItem.isStringInterpolation
        }); });
        return {
            $type: this.type,
            $security: dataConstants.SECURITY.INSTANCE_USER_ADMIN,
            type: this.processNodeType,
            connections: [],
            point: [0, 0],
            key: nodeData.tip,
            createType: nodeData.fields.objectTypeTip,
            createSecurity: 'app/security-policy/system-all-users-read-write-delete',
            createBranch: dataConstants.BRANCH_MASTER,
            createFields: nodeData.fields.fieldItems.map(function (o) { return o.tip; }),
            createData: convertedFieldValues,
            createDataCount: convertedFieldValues.map(function (o) { return "COUNT(" + o + ")"; }),
            createLang: 'en-us',
            createTipVarKey: nodeData.fields.variableToSetKey,
            createTipVarScope: 'Global',
        };
    };
    CreateObjectNodeConverterService.prototype.isValid = function (nodeData) {
        return !isEmpty(nodeData.fields.objectTypeTip);
    };
    CreateObjectNodeConverterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CreateObjectNodeConverterService_Factory() { return new CreateObjectNodeConverterService(); }, token: CreateObjectNodeConverterService, providedIn: "root" });
    return CreateObjectNodeConverterService;
}(NodeConverterBase));
export { CreateObjectNodeConverterService };
