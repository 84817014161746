import { Pipe, PipeTransform } from '@angular/core';
import { get, has, isString } from 'lodash';
import { getI18nValue } from '../i18n/i18n-value/i18n-value';
import dataConstants from '../data/constants';

/*
 * pipe to filter array of objects by filter string
 * Usage:
 * *ngFor="let item of items | filter:filterText:['propertyName1', 'nestedObject.ropertyName2']"
 *
 * where filterText is a property holding entered text to use for filtering, prop names - properties to match filter text
 * give true to the forth parameter to make it case sensitive
 */
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(value: Array<any>, filterString: string, propNames: Array<string>, caseSensitive: boolean = false): any {
    if (!value || !value.length || !filterString) {
      return value;
    }

    if (!caseSensitive) {
      filterString = filterString.toLowerCase();
    }

    const arrResult = [];
    for (const item of value) {

      // if item is a string and there are no filter properties specified
      if ((!propNames || !propNames.length)) {
        if (this.isII18nValuesType(item)) {
          const i18nValue = getI18nValue(item);
          const targetStr = caseSensitive ? i18nValue : i18nValue.toLowerCase();
          if (targetStr.indexOf(filterString) !== -1) {
            arrResult.push(item);
            break;
          }
        }
      } else {
        // if filter properties are specified, filter by 
        for (const propName of propNames) {
          const propValue = get(item, propName, '');
          if (propValue) {
            const i18nValue = getI18nValue(propValue);
            const targetStr = caseSensitive ? i18nValue : i18nValue.toLowerCase();

            if (targetStr.indexOf(filterString) !== -1) {
              arrResult.push(item);
              break;
            }
          }
        }
      }
    }
    return arrResult;
  }

  isII18nValuesType(item: any): boolean {
    if (isString(item)) {
      return true;
    }

    for (const localeId of dataConstants.ACCEPTABLE_LOCALE_IDS) {
      if (has(item, localeId)) {
        return true;
      }
    }
    return false;
  }
}
