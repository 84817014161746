import { Injectable, isDevMode } from '@angular/core';
import { debugConfig } from '../../debugConfig';
import { DEBUG_LEVEL } from './debugLevels';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private _debugLevel: DEBUG_LEVEL;

  constructor() {
    this._debugLevel = isDevMode() ? debugConfig.debugLevel : DEBUG_LEVEL.DEBUG;
  }

  public setDebugLevel(level: DEBUG_LEVEL) {
    this._debugLevel = level;
  }

  public offAll() {
    this._debugLevel = DEBUG_LEVEL.OFF;
  }

  public onAll() {
    this._debugLevel = DEBUG_LEVEL.ALL;
  }

  public error(message: any, ...optionalParams: any[]) {
    if (this._debugLevel >= DEBUG_LEVEL.ERROR) {
      console.error(message, ...optionalParams);
    }
  }

  public warn(message?: any, ...optionalParams: any[]) {
    if (this._debugLevel >= DEBUG_LEVEL.WARN) {
      console.warn(message, ...optionalParams);
    }
  }

  public info(message?: any, ...optionalParams: any[]) {
    if (this._debugLevel >= DEBUG_LEVEL.INFO) {
      console.info(message, ...optionalParams);
    }
  }

  public debug(message?: any, ...optionalParams: any[]) {
    if (this._debugLevel >= DEBUG_LEVEL.DEBUG) {
      console.debug(message, ...optionalParams);
    }
  }
}
