import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
var FormlyFieldToggleComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FormlyFieldToggleComponent, _super);
    function FormlyFieldToggleComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FormlyFieldToggleComponent.prototype.ngOnInit = function () {
        if (typeof this.formControl.value !== 'boolean') {
            this.formControl.patchValue(false);
        }
    };
    return FormlyFieldToggleComponent;
}(FieldType));
export { FormlyFieldToggleComponent };
