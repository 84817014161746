import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  ASSIGN_TO_SYSTEM_VALUE
} from '../../shared/dynamic-field-chooser/assign-to-system-side-sheet/assign-to-system-side-sheet.component';
import { INTERPOLATION_TYPE, ISubstitutionCandidate } from '../../form/string-interpolation/string-interpolation.service';
import { FormulaLabelService } from '../../object/field-formula-side-sheet/formula-label.service';
import { QuoteString } from '../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { TIP_FORMULA } from '../../object/field-formula-side-sheet/field-formula-side-sheet/field-formula-side-sheet.component';
import { Formula, Tip } from '../models/types';
import { isEmpty } from 'lodash';
import { SEQUENCE_INTEGER_FORMULA, SEQUENCE_INTEGER_LABEL } from '../../shared/dynamic-field-chooser/sequence-integer';
import { DisplayFormulaWrapperService } from '../../util/display-formula-wrapper.service';
import { addContextFormulaWrapper } from '../../util/context-formula-wrapper';

export enum RECIPIENT_SUPER_TYPE_TIP {
  EMAIL = 'app/super-type/email-capable',
  SMS = 'app/super-type/sms-capable',
  VOICE = 'app/super-type/voice-message-capable'
}

export enum SYSTEM_LABELS {
  CURRENT_DATE = 'Current date',
  CURRENT_DATE_AND_TIME = 'Current date and time',
  CURRENT_MONTH = 'Current month',
  CURRENT_YEAR = 'Current year',
  CURRENT_USER = 'Current user'
}

@Injectable({
  providedIn: 'root'
})
export class SubstitutionMetaService {
  constructor(
    private formulaLabelService: FormulaLabelService,
    private displayFormulaWrapperService: DisplayFormulaWrapperService
  ) {}

  static getLabelFromSystemFormula(formula: Formula): string | null {
    switch (formula) {
      case ASSIGN_TO_SYSTEM_VALUE.CURRENT_DATE:
        return SYSTEM_LABELS.CURRENT_DATE;
      case ASSIGN_TO_SYSTEM_VALUE.CURRENT_DATETIME:
        return SYSTEM_LABELS.CURRENT_DATE_AND_TIME;
      case ASSIGN_TO_SYSTEM_VALUE.CURRENT_MONTH:
        return SYSTEM_LABELS.CURRENT_MONTH;
      case ASSIGN_TO_SYSTEM_VALUE.CURRENT_YEAR:
        return SYSTEM_LABELS.CURRENT_YEAR;
      case ASSIGN_TO_SYSTEM_VALUE.CURRENT_USER:
        return SYSTEM_LABELS.CURRENT_USER;
      case SEQUENCE_INTEGER_FORMULA:
        return SEQUENCE_INTEGER_LABEL;
      default:
        return null;
    }
  }

  getSubstitutionCandidateFromSystem(formula: Formula, type: INTERPOLATION_TYPE): ISubstitutionCandidate {
    switch (formula) {
      case ASSIGN_TO_SYSTEM_VALUE.CURRENT_DATE:
        return {
          type,
          label: SYSTEM_LABELS.CURRENT_DATE,
          value: ASSIGN_TO_SYSTEM_VALUE.CURRENT_DATE
        };
      case ASSIGN_TO_SYSTEM_VALUE.CURRENT_DATETIME:
        return {
          type,
          label: SYSTEM_LABELS.CURRENT_DATE_AND_TIME,
          value: ASSIGN_TO_SYSTEM_VALUE.CURRENT_DATETIME
        };
      case ASSIGN_TO_SYSTEM_VALUE.CURRENT_MONTH:
        return {
          type,
          label: SYSTEM_LABELS.CURRENT_MONTH,
          value: ASSIGN_TO_SYSTEM_VALUE.CURRENT_MONTH
        };
      case ASSIGN_TO_SYSTEM_VALUE.CURRENT_YEAR:
        return {
          type,
          label: SYSTEM_LABELS.CURRENT_YEAR,
          value: ASSIGN_TO_SYSTEM_VALUE.CURRENT_YEAR
        };
      case ASSIGN_TO_SYSTEM_VALUE.CURRENT_USER:
        return {
          type,
          label: SYSTEM_LABELS.CURRENT_USER,
          value: ASSIGN_TO_SYSTEM_VALUE.CURRENT_USER
        };
      default:
        return null;
    }
  }

  getSubstitutionCandidateFromFormula(
    formula: Formula,
    type: INTERPOLATION_TYPE,
    contextTypeTip?: Tip
  ): Observable<ISubstitutionCandidate> {
    const removedFormula = this.displayFormulaWrapperService.removeDisplayFormulaWrapper(formula);    //  for those formulas which have had a special treatment - for eg. lists, date time etc. - just used to calculate the label
    return this.formulaLabelService.transform(removedFormula, {}, contextTypeTip).pipe(
      map((label: string) => ({ type, label, value: formula }))
    );
  }

  getRecipientSubstitutionCandidateFromFormula(
    formula: Formula,
    type: INTERPOLATION_TYPE,
    contextTypeTip: RECIPIENT_SUPER_TYPE_TIP
  ): Observable<ISubstitutionCandidate> {
    let sourceTip: Tip;

    switch (contextTypeTip) {
      case RECIPIENT_SUPER_TYPE_TIP.EMAIL:
        sourceTip = 'app/email/recipient:source';
        break;
      case RECIPIENT_SUPER_TYPE_TIP.SMS:
        sourceTip = 'app/sms/recipient:source';
        break;
      case RECIPIENT_SUPER_TYPE_TIP.VOICE:
        sourceTip = 'app/voice-message/recipient:source';
        break;
    }

    let formulaWithRecipientSource;

    if (sourceTip && formula !== TIP_FORMULA) {
      const recipientSourceField = `FIELD(${QuoteString(sourceTip)})`;
      formulaWithRecipientSource = addContextFormulaWrapper(formula, recipientSourceField);
    }

    const value = formulaWithRecipientSource || formula;

    return this.formulaLabelService
      .transform(formula)
      .pipe(
        map((label: string) => ({ type, label, value }))
      );
  }

  replaceSubstitutions(text: string, substitutions: { key: string, label: string }[]) {
    if (isEmpty(substitutions)) {
      return text;
    }
    substitutions.forEach(sub => {
      text = text.replace(new RegExp(sub.key, 'g'), sub.label);
    });
    return text;
  }
}
