import { DataTypes, IObjectScheme } from '../data/models/scheme';
import { Tip } from '../data/models/types';
import { IProfile, profileScheme } from './profile';
import { IObject } from '../data/models/object';
import { IContact, contactScheme } from './contact';
import { IPerson } from './person';

export { IUserReadOnly, IUser, IUserWithProfiles, userScheme, userWithProfilesScheme, userSummaryScheme };

interface IUserReadOnly {
  $tip: Tip;
  email: string;
  status?: 'active' | 'suspended' | 'invited' | 'requested' | 'awaiting verification' | 'deprovisioned';
  profiles: Tip[];
  identityProvider?: Tip;
  preference?: Tip;
  fullName: string;
  contactTip: Tip;  // the tip of the internal contact
  avatarTip?: Tip;
  privateSecurityGrant?: string;
  localeId?: string;
}

// todo - check if can be deprecated - EIM-5642
interface IUser extends IObject {
  email: string;
  status: 'active' | 'suspended' | 'invited' | 'requested' | 'awaiting verification' | 'deprovisioned';
  person: IPerson;
  profiles: Tip[];
  identityProvider?: Tip;
  localeId?: string;
}

interface IUserWithProfiles extends IObject {
  email: string;
  status: 'active' | 'suspended' | 'invited' | 'requested' | 'awaiting verification' | 'deprovisioned';
  person: IPerson;
  profiles: IProfile[];
  localeId?: string;
}

const userScheme: IObjectScheme = {
  'app/user:email': {},
  'app/user:status': {},
  'app/user:person': {
    type: DataTypes.object,
    scheme: contactScheme
  },
  'app/user:profiles': { type: DataTypes.stringArray },
  'app/user:identity-provider': { type: DataTypes.string },
  'app/user:locale-id': {}
};

const userWithProfilesScheme: IObjectScheme = {
  'app/user:email': {},
  'app/user:status': {},
  'app/user:person': {
    type: DataTypes.object,
    scheme: contactScheme
  },
  'app/user:profiles': {
    type: DataTypes.objectArray,
    scheme: profileScheme
  },
  'app/user:locale-id': {}
};

const userSummaryScheme: IObjectScheme = {
  'app/user:email': {},
  'app/user:status': {},
  'app/user:person': { type: DataTypes.string },
  'app/user:locale-id': {}
};
