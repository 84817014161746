import { OnInit, EventEmitter, OnDestroy } from '@angular/core';
var SideSheetHeaderComponent = /** @class */ (function () {
    function SideSheetHeaderComponent(sideSheetService) {
        this.sideSheetService = sideSheetService;
        this.cancel = 'Cancel';
        // Consider using this instead of 'disabled' going forward. Check 'EditFormSideSheetComponent'
        this.size = 'sm';
        this.showCancelButton = false;
        this.showBackIcon = false;
        this.showClose = true;
        this.longerText = false;
        this.hideBack = false;
        this.actioned = new EventEmitter();
        this.goBack = new EventEmitter();
        this.closed = new EventEmitter();
        this.depth$ = this.sideSheetService.depth$;
    }
    Object.defineProperty(SideSheetHeaderComponent.prototype, "disabled", {
        get: function () {
            return this._disabled;
        },
        set: function (value) {
            this._disabled = value;
            if (value != null) {
                this.useFallBackDisable();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SideSheetHeaderComponent.prototype, "displayClose", {
        get: function () {
            return !this.action && this.showClose;
        },
        enumerable: true,
        configurable: true
    });
    SideSheetHeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sideSheetService.resizeTo(this.size);
        if (this.disabled$) {
            this.actionButtonDisabledSubscription = this.disabled$.subscribe(function (value) {
                _this.actionButtonDisabled = value;
            });
        }
        else {
            this.useFallBackDisable();
        }
    };
    SideSheetHeaderComponent.prototype.useFallBackDisable = function () {
        if (!this.disabled$) {
            this.actionButtonDisabled = this.disabled;
        }
    };
    SideSheetHeaderComponent.prototype.ngOnDestroy = function () {
        if (this.actionButtonDisabledSubscription) {
            this.actionButtonDisabledSubscription.unsubscribe();
        }
    };
    SideSheetHeaderComponent.prototype.back = function ($event) {
        if (this.goBack.observers.length) {
            this.goBack.emit($event);
        }
        else {
            this.sideSheetService.pop();
        }
    };
    SideSheetHeaderComponent.prototype.close = function ($event) {
        if (this.closed.observers.length) {
            this.closed.emit($event);
        }
        this.sideSheetService.clear(true);
    };
    SideSheetHeaderComponent.prototype.onAction = function ($event) {
        this.actioned.emit($event);
    };
    return SideSheetHeaderComponent;
}());
export { SideSheetHeaderComponent };
