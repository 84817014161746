import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Tip } from '../../../../data/models/types';
import { FormGroup, FormControl } from '@angular/forms';
import { ToastService } from '../../../../shell/services/toast.service';
import { SideSheetService } from '../../../../side-sheet/side-sheet.service';
import { first, distinctUntilChanged, switchMap, tap, takeUntil } from 'rxjs/operators';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SequenceService, ISequenceForm } from '../sequence.service';
import { ISequence } from '../../../../models/sequence';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ModalConfirmDeletionComponent, modalConfirmDeletionOptions
} from '../../../../shared/modals/modal-confirm-deletion/modal-confirm-deletion.component';
import { sequenceFormFields } from './sequence-options';
import { LoadingState } from '../../../../shared/constants';
import { get, cloneDeep } from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { ModuleValidationError } from '../../../../data/errors/client/ModuleValidationError';

@Component({
  selector: 'app-sequence-edit-side-sheet',
  templateUrl: './sequence-edit-side-sheet.component.html',
  styleUrls: ['./sequence-edit-side-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SequenceEditSideSheetComponent implements OnInit, OnDestroy {

  moduleTip: Tip;

  sequenceTip: Tip;
  sequenceSecurityPolicy: Tip;

  sequenceForm: FormGroup = new FormGroup({
    name: new FormControl(null),
    objectType: new FormControl(null),
    numOfDigits: new FormControl(0),
    label: new FormControl({ html: '', substitutions: [] }),
    key: new FormControl({ html: '', substitutions: [] }),
  });

  loadingStates = LoadingState;
  loadingState: LoadingState = LoadingState.inProgress;

  model: any;

  fields: FormlyFieldConfig[] = cloneDeep(sequenceFormFields);

  unsubscribe$ = new Subject();

  done: () => void = () => {};

  constructor(
    private toastService: ToastService,
    private sideSheetService: SideSheetService,
    private sequenceService: SequenceService,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.loadingState = LoadingState.inProgress;

    this.handleObjectTypeChanges();

    this.sequenceTip
      ? this.initEditing()
      : this.initCreating();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handleObjectTypeChanges() {
    this
      .sequenceForm
      .get('objectType')
      .valueChanges
      .pipe(
        distinctUntilChanged(),
        tap(() => this.loadingState = LoadingState.inProgress),
        switchMap(objectType => this.sequenceService.getTypeFromSequenceObjectType(objectType)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(type => {
        const labelField = this.fields.find(field => field.key === 'label');
        const keyField = this.fields.find(field => field.key === 'key');
        labelField.templateOptions.contextTypeTips = type ? [type] : [];
        keyField.templateOptions.contextTypeTips = type ? [type] : [];

        if (this.sequenceForm.touched) {
          this.sequenceForm.patchValue({
            label: { html: '', substitutions: [] },
            key: { html: '', substitutions: [] },
          });
        }

        this.loadingState = LoadingState.loaded;

        this.cdr.markForCheck();
      });
  }

  onDone() {
    this.loadingState = LoadingState.inProgress;

    const newSequence: ISequence = {
      name: this.sequenceForm.value.name,
      label: get(this, 'sequenceForm.value.label.html', null),
      labelSubstitutions: get(this, 'sequenceForm.value.label.substitutions', null),
      key: get(this, 'sequenceForm.value.key.html', null),
      keySubstitutions: get(this, 'sequenceForm.value.key.substitutions', null),
      numberOfDigits: this.sequenceForm.value.numOfDigits,
      objectType: this.sequenceForm.value.objectType
    };

    if (this.sequenceTip) {
      Object.assign(
        newSequence,
        { $tip: this.sequenceTip, $security: this.sequenceSecurityPolicy }
      );

      this
        .sequenceService
        .updateSequence(newSequence, this.moduleTip)
        .pipe(first())
        .subscribe(
          () => {
            this.toastService.showSuccessToast('Sequence successfully updated');
            this.done();
            this.sideSheetService.pop();
          },
          (err) => {
            this.loadingState = LoadingState.failed;
            err instanceof ModuleValidationError
            ? this.toastService.showErrorToast(err.message)
            : this.toastService.showErrorToast(`Failed to update sequence ${newSequence.name}.`);
          }
        );
    } else {
      this
        .sequenceService
        .createSequence(newSequence, this.moduleTip)
        .pipe(first())
        .subscribe(
          () => {
            this.toastService.showSuccessToast('Sequence successfully created');
            this.done();
            this.sideSheetService.pop();
          },
          (err) => {
            this.loadingState = LoadingState.failed;
            err instanceof ModuleValidationError
            ? this.toastService.showErrorToast(err.message)
            : this.toastService.showErrorToast(`Failed to create sequence ${newSequence.name}.`);
          });
    }
  }

  onRequestDelete() {
    const modalRef = this.modalService.open(ModalConfirmDeletionComponent, modalConfirmDeletionOptions);
    modalRef.componentInstance.objectType = 'sequence';
    modalRef.result
      .then(deleteModule => deleteModule ? this.delete() : null)
      .catch(() => { });
  }

  private delete() {
    this.loadingState = LoadingState.inProgress;
    this
      .sequenceService
      .deleteSequence(this.sequenceTip, this.moduleTip)
      .pipe(first())
      .subscribe(
        () => {
          this.toastService.showSuccessToast(`Deleted sequence item successfully.`);
          this.sideSheetService.pop();
        },
        (err) => {
          err instanceof ModuleValidationError
          ? this.toastService.showErrorToast(err.message)
          : this.toastService.showErrorToast(`Failed to delete sequence.`);
        }
      );
  }

  private initCreating() {
    const urlSegments = get(this.route, 'snapshot.firstChild.firstChild.url', null);

    // Auto-populate object-type if on object-type designer page
    if (
      urlSegments &&
      get(urlSegments, '[2].path', null) === 'object-types' &&
      get(urlSegments, '[3].path', null)
    ) {
      const objectType = get(urlSegments, '[3].path', null);
      this.model = { objectType };
      this.cdr.markForCheck();
    }

    this.loadingState = LoadingState.loaded;
  }

  private initEditing() {
    this
      .sequenceService
      .getSequence(this.sequenceTip)
      .pipe(first(Boolean))
      .subscribe((sequence: ISequence) => {
        this.sequenceSecurityPolicy = sequence.$security;

        const currentFormState = convertSequenceToSequenceForm(sequence);
        this.model = currentFormState;
        this.sequenceForm.setValue(currentFormState);

        this.loadingState = LoadingState.loaded;
        this.cdr.markForCheck();
      });
  }
}

function convertSequenceToSequenceForm(
  { name, label, labelSubstitutions, key, keySubstitutions, objectType, numberOfDigits }: ISequence
): ISequenceForm {
  return {
    name: name || null,
    label: { html: label || '', substitutions: labelSubstitutions || [] },
    objectType: objectType || null,
    key: { html: key || '', substitutions: keySubstitutions || [] },
    numOfDigits: numberOfDigits || 0
  };
}
