import { DataTypes, IObjectScheme } from '../data/models/scheme';
import { IObject } from '../data/models/object';
import { Tip } from '../data/models/types';
import { EXTENDED_FIELD_DATA_TYPE, FIELD_DATA_TYPE, TYPE_GEO_RESTRICT } from './field';
import { get } from 'lodash';
import { MetaDataKey } from '../object/field-formula-side-sheet/meta-data-formulas';

export interface IQueryAttribute extends IObject {
  label: string;
  formula: string;
}

export const queryAttributeScheme: IObjectScheme = {
  'query/attribute/label': { type: DataTypes.i18n },
  'query/attribute/formula': {}
};

export interface IQueryFilter extends IObject {
  label: string;
  formula: string;
}

export const queryFilterScheme: IObjectScheme = {
  'query/filter/label': { type: DataTypes.i18n },
  'query/filter/formula': {}
};

export interface IQueryDimension extends IObject {
  label: string;
  formula: string;
}

export const queryDimensionScheme: IObjectScheme = {
  'query/dimension/label': { type: DataTypes.i18n },
  'query/dimension/formula': {}
};

export interface IQuery extends IObject {
  name: string;
  attributes: IQueryAttribute[];
  filters: IQueryFilter[];
  dimensions: IQueryDimension[];
}

export const queryScheme: IObjectScheme = {
  'query/name': { type: DataTypes.i18n },
  'query/attributes': {
    type: DataTypes.objectArray,
    mutable: true,
    scheme: queryAttributeScheme
  },
  'query/filters': {
    type: DataTypes.objectArray,
    mutable: true,
    scheme: queryFilterScheme
  },
  'query/dimensions': {
    type: DataTypes.objectArray,
    mutable: true,
    scheme: queryDimensionScheme
  }
};

export interface IFlatQuery extends IObject {
  name: string;
  attributes: Tip[];
  filters: Tip[];
  dimensions: Tip[];
}

export const flatQueryScheme: IObjectScheme = {
  'query/name': { type: DataTypes.i18n },
  'query/attributes': {},
  'query/filters': {},
  'query/dimensions': {}
};

export interface IFilterMeta {
  fieldType?: FIELD_DATA_TYPE;
  typeRestrict?: Tip;
  typeGeoRestrict?: TYPE_GEO_RESTRICT;
  subgroup?: IFilterMeta[];
  isObjectStage?: boolean;
  isList?: boolean;
  fieldTip?: Tip;
  objectType?: Tip;
  metaDataKey?: MetaDataKey;
  extendedFieldType?: EXTENDED_FIELD_DATA_TYPE;
  implements?: Tip[];
  isHierarchical?: boolean;
  isListItem?: boolean;
  operator?: OPERATOR_TYPE;
  whitelist?: Array<any>;
}

export enum OPERATOR_TYPE {
  EQUALS = 'Equals',
  NOT_EQUALS = 'Not equals',
  STARTS_WITH = 'Starts with',
  NOT_STARTS_WITH = 'Doesn\'t start with',
  ENDS_WITH = 'Ends with',
  NOT_ENDS_WITH = 'Doesn\'t end with',
  CONTAINS = 'Contains',
  NOT_CONTAINS = 'Doesn\'t contain',
  CONTAINS_ALL = 'Contains all',
  NOT_CONTAINS_ALL = 'Doesn\'t contain all',
  CONTAINS_ANY = 'Contains any',
  NOT_CONTAINS_ANY = 'Doesn\'t contain any',
  IS_EMPTY = 'Is empty',
  NOT_IS_EMPTY = 'Is not empty',
  IN = 'In',
  NOT_IN = 'Not in',
  LESS_THAN = 'Less than',
  GREATER_THAN = 'Greater than',
  LESS_THAN_EQUAL_TO = 'Less than or equal to',
  GREATER_THAN_EQUAL_TO = 'Greater than or equal to',
  INTERSECTS_WITH = 'Intersects with',
  NOT_INTERSECTS_WITH = 'Not intersects with',
  IS_OF_TYPE = 'Is of type',
  NOT_IS_OF_TYPE = 'Is not of type',
  WITHIN = 'Within',
  NOT_WITHIN = 'Not within',
  ASSIGNED_TO = 'Is assigned to',
  NOT_ASSIGNED_TO = 'Is not assigned to',
  CONTACT_IN_GROUP = 'In a group',
  CONTACT_NOT_IN_GROUP = 'Not in group',
}

const functionMap = { // it is a FIELD_DATA_TYPE, a object type or a hard-coded type
  [FIELD_DATA_TYPE.geography]: {
    [OPERATOR_TYPE.INTERSECTS_WITH]: 'openGeographyValueForm',
    [OPERATOR_TYPE.NOT_INTERSECTS_WITH]: 'openGeographyValueForm',
    '*': 'openGeographyValueForm'
  },
  [FIELD_DATA_TYPE.object]: {
    'app/person': {
      [OPERATOR_TYPE.EQUALS]: 'someFunction' // TODO: for all supported types, if not found, will fall back to * rule
    },
    'app/personnel': {
      ['*']: 'openAssignmentValueForm'
    },
    'app/assignment': {
      ['*']: 'openAssignmentValueForm'
    },
    type: {
      '*': 'openTypeValueForm'
    },
    '*': { // if still cannot be found, then will fall back to * rule
      [OPERATOR_TYPE.IS_OF_TYPE]: 'openTypeValueForm',
      [OPERATOR_TYPE.NOT_IS_OF_TYPE]: 'openTypeValueForm',
    }
  },
  __listItem__: 'openListItemValueForm',
  __whitelist__: 'openWhitelistValueForm',
  __hierarchicalObject__: 'openHierarchicalObjectForm',
  __contactContainerObject__: 'openContactInGroupValueForm',
  '*': 'openGenericEnterValueForm'
};

export function getFunctionName(meta: {
  isListItem?: boolean,
  fieldType?: FIELD_DATA_TYPE,
  typeRestrict?: Tip,
  operator?: OPERATOR_TYPE,
  whitelist?: any[],
  isHierarchical?: boolean,
  implements?: Array<Tip>
}): string {
  if (meta.isListItem) {
    return functionMap.__listItem__;
  }

  if (meta.whitelist && meta.whitelist.length) {
    return functionMap.__whitelist__;
  }

  if ((meta.typeRestrict === 'app/super-type/contact' ||
      ( meta.implements && meta.implements.indexOf('app/super-type/contact') !== -1 ))
    && (meta.operator === OPERATOR_TYPE.CONTACT_IN_GROUP || meta.operator === OPERATOR_TYPE.CONTACT_NOT_IN_GROUP)) {
    return functionMap.__contactContainerObject__;
  }


  if (meta.isHierarchical &&  // open the HierarichalObjectChooserComponent depending LHS field type and operator
    (meta.operator !== OPERATOR_TYPE.IS_OF_TYPE && meta.operator !== OPERATOR_TYPE.NOT_IS_OF_TYPE)) {
    return functionMap.__hierarchicalObject__;
  }

  return get(functionMap, `${ meta.fieldType }['${ meta.typeRestrict }']['${ meta.operator }']`,
    get(functionMap, `${ meta.fieldType }['${ meta.typeRestrict }']['*']`,
      get(functionMap, `${ meta.fieldType }['*']['${ meta.operator }']`,
        get(functionMap, `${ meta.fieldType }['*']['*']`,
          get(functionMap, `${ meta.fieldType }['${ meta.operator }']`,
          meta.fieldType === 'object'
            ? functionMap['*']
            : get(functionMap, `${ meta.fieldType }['*']`,
              functionMap['*']
            )
          )
        )
      )
    )
  );
}

export interface IEnterValueFormComponent {
  openValueForm: () => void;
  openGenericEnterValueForm: () => void;
  openGeographyValueForm: () => void;
  openTypeValueForm: () => void;
  openListItemValueForm: (meta: IFilterMeta) => void;
  openWhitelistValueForm: () => void;
  openPredefinedDatesChooser: () => void;
  openAssignmentValueForm: () => void;
  openHierarchicalObjectForm: (meta: IFilterMeta) => void;
  openContactInGroupValueForm: (meta: IFilterMeta) => void;
}
