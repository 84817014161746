import { Component, ViewChild, OnInit } from '@angular/core';
import { SideSheetService } from '../../side-sheet/side-sheet.service';
import { NavigationSideSheetComponent } from '../../side-sheet/generic-side-sheets/navigation-side-sheet/navigation-side-sheet.component';
import { SelectUsersSideSheetComponent } from '../select-users-side-sheet/select-users-side-sheet.component';
import { SelectProfilesSideSheetComponent } from '../select-profiles-side-sheet/select-profiles-side-sheet.component';
import { SelectTeamsSideSheetComponent } from '../select-teams-side-sheet/select-teams-side-sheet.component';
import { SideSheetListComponent } from '../../side-sheet/side-sheet-list/side-sheet-list.component';
import { SideSheetListModes } from '../../side-sheet/side-sheet-list/side-sheet-list.component';
import { FormulaService } from '../../data/formula.service';
import { first } from 'rxjs/operators';
import { ISecurityCohort, ISecurityCohorts } from '../../data/security.service';
import { pullAll } from 'lodash';

const DELETED_LABEL = '---DELETED---';

@Component({
  selector: 'app-security-action',
  templateUrl: './security-action.component.html',
  styleUrls: ['./security-action.component.scss']
})
export class SecurityActionComponent implements OnInit {
  actionLabel: string;
  title: string;
  readonly: boolean;

  selectedUsers: ISecurityCohort[] = [];
  selectedProfiles: ISecurityCohort[] = [];
  selectedTeams: ISecurityCohort[] = [];
  selectedRoles: ISecurityCohort[] = [];
  onDone: (data: ISecurityCohorts) => void;

  @ViewChild(SideSheetListComponent, {static: false}) list: SideSheetListComponent;

  constructor(
    private sideSheetService: SideSheetService,
    private formulaService: FormulaService
  ) { }

  ngOnInit() {
    this.refreshLabels();
  }

  onAddGrant() {
    const sheetRef = this.sideSheetService.push(NavigationSideSheetComponent);
    sheetRef.componentInstance.title = 'Grant ' + this.actionLabel + ' permissions to';
    sheetRef.componentInstance.list = [
      { value: 'profiles', label: 'Profiles' },
      { value: 'teams', label: 'Teams' },
      // { value: 'roles', label: 'Roles' },
      { value: 'users', label: 'Users' }
    ];
    sheetRef.componentInstance.selectItem.subscribe(
      selection => {
        this.sideSheetService.pop();
        switch (selection.value) {
          case 'users':
            return this.onSelectUsers();
          case 'profiles':
            return this.onSelectProfiles();
          case 'teams':
            return this.onSelectTeams();
          // case 'roles':
          //   return this.onSelectRoles();
        }
      }
    );
  }

  refreshLabels() {
    const tips = [
      ...this.selectedUsers.map(user => user.tip),
      ...this.selectedProfiles.filter(profile => profile.tip !== '__ALL__').map(profile => profile.tip),
      ...this.selectedTeams.map(team => team.tip),
      ...this.selectedRoles.map(role => role.tip)
    ];
    if (tips.length === 0) {
      this.updateLabels(tips, {});
      return;
    }
    this.formulaService.evaluate(`MAP(VAR("tips"),COALESCE(TITLE(VAR("tip")),"${ DELETED_LABEL }"),"tip")`, null, [], { tips }).pipe(
      first()
    ).subscribe(
      labels => this.updateLabels(tips, labels),
      () => this.updateLabels(tips, tips) // Fallback if need be
    );
  }

  updateLabels(tips, labels) {
    const updateLabel = (cohorts: ISecurityCohort[]) => {
      const deletedCohorts: ISecurityCohort[] = [];
      cohorts.forEach(cohort => {
        const label = labels[tips.indexOf(cohort.tip)];
        if (label === DELETED_LABEL) {
          deletedCohorts.push(cohort);
          return;
        }

        cohort.label = cohort.tip === '__ALL__' ? 'All profiles' : labels[tips.indexOf(cohort.tip)];
      });

      pullAll(cohorts, deletedCohorts);
    };

    updateLabel(this.selectedUsers);
    updateLabel(this.selectedTeams);
    updateLabel(this.selectedRoles);
    updateLabel(this.selectedProfiles);
  }

  onSave() {
    this.onDone({
      users: this.selectedUsers,
      profiles: this.selectedProfiles,
      teams: this.selectedTeams,
      roles: this.selectedRoles
    });
    this.sideSheetService.pop();
  }

  onSelectUsers() {
    if (this.readonly) {
      return;
    }
    const sheetRef = this.sideSheetService.push(SelectUsersSideSheetComponent);
    sheetRef.componentInstance.title = 'Select users';
    sheetRef.componentInstance.mode = SideSheetListModes.MULTI_SELECT;
    sheetRef.componentInstance.initiallySelectedTips = this.selectedUsers.map(user => user.tip);
    sheetRef.componentInstance.onDone = (tips) => {
      this.selectedUsers = tips.map(tip => ({tip}));
      this.refreshLabels();
      this.sideSheetService.pop();
    };
  }

  onSelectProfiles() {
    if (this.readonly) {
      return;
    }
    const sheetRef = this.sideSheetService.push(SelectProfilesSideSheetComponent);
    sheetRef.componentInstance.title = 'Select profiles';
    sheetRef.componentInstance.mode = SideSheetListModes.MULTI_SELECT;
    sheetRef.componentInstance.initiallySelectedTips = this.selectedProfiles.map(profile => profile.tip);
    sheetRef.componentInstance.onDone = (tips) => {
      if (tips.indexOf('__ALL__') > -1) {
        this.selectedProfiles = [{ tip: '__ALL__' }];
      } else {
        this.selectedProfiles = tips.map(tip => ({tip}));
      }
      this.refreshLabels();
      this.sideSheetService.pop();
    };
  }

  onSelectTeams() {
    if (this.readonly) {
      return;
    }
    const sheetRef = this.sideSheetService.push(SelectTeamsSideSheetComponent);
    sheetRef.componentInstance.title = 'Select teams';
    sheetRef.componentInstance.mode = SideSheetListModes.MULTI_SELECT;
    sheetRef.componentInstance.initiallySelectedTips = this.selectedTeams.map(team => team.tip);
    sheetRef.componentInstance.onDone = (tips) => {
      this.selectedTeams = tips.map(tip => ({tip}));
      this.refreshLabels();
      this.sideSheetService.pop();
    };
  }

  // onSelectRoles() {
    // if (this.readonly) {
    //   return;
    // }
  //   const sheetRef = this.sideSheetService.push(SelectRolesSideSheetComponent);
  //   sheetRef.componentInstance.title = 'Select roles';
  //   sheetRef.componentInstance.mode = SideSheetListModes.MULTI_SELECT;
  //   sheetRef.componentInstance.initiallySelectedTips = this.selectedRoles.map(role => role.tip);
  //   sheetRef.componentInstance.onDone = (tips) => {
  //     this.selectedRoles = tips.map(tip => ({tip}));
  //     this.refreshLabels();
  //     this.sideSheetService.pop();
  //   };
  // }

  hasAnythingSet(): boolean {
    return this.selectedProfiles.length > 0
      || this.selectedUsers.length > 0
      || this.selectedTeams.length > 0;
  }

}
