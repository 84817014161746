import { Component, OnInit, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { SideSheetListModes, SideSheetListComponent } from '../../side-sheet-list/side-sheet-list.component';
import { SideSheetService } from '../../side-sheet.service';
import { Formula } from '../../../data/models/types';
import { NOW_VAR_NAME } from '../../../util/current-datetime.service';
import { IDatetimeDefaultValueMeta } from '../../../settings/object-type/object-type-designer-field/default-value/default-value.service';

const NOW_FORMULA = `NOW()`;
const YESTERDAY = `DATE_ADD(${ NOW_FORMULA },-1,"days")`;
const LAST_MONTH = `DATE_ADD(${ NOW_FORMULA },-1,"months")`;
const LAST_YEAR = `DATE_ADD(${ NOW_FORMULA },-1,"years")`;

export const DATE_WITHIN_FORMULAS = [
  {
    label: 'Last day',
    value: YESTERDAY
  },
  {
    label: 'Last 3 days',
    value: `DATE_ADD(${ NOW_FORMULA },-3,"days")`
  },
  {
    label: 'Last week',
    value: `DATE_ADD(${ NOW_FORMULA },-7,"days")`
  },
  {
    label: 'Last 2 weeks',
    value: `DATE_ADD(${ NOW_FORMULA },-14,"days")`
  },
  {
    label: 'Last month',
    value: LAST_MONTH
  },
  {
    label: 'Last 3 months',
    value: `DATE_ADD(${ NOW_FORMULA },-3,"months")`
  },
  {
    label: 'Last 6 months',
    value: `DATE_ADD(${ NOW_FORMULA },-6,"months")`
  },
  {
    label: 'Last year',
    value: LAST_YEAR
  }
];

@Component({
  selector: 'app-date-within-side-sheet',
  templateUrl: './date-within-side-sheet.component.html',
  styleUrls: ['./date-within-side-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class DateWithinSideSheetComponent implements OnInit {
  title = 'Select value field';
  SideSheetListModes = SideSheetListModes;
  selectedFormula: string;
  done: (dateWithinValue: Formula) => void;
  options;

  @ViewChild('optionList', {static: true}) optionList: SideSheetListComponent;
  constructor(
    private sideSheetService: SideSheetService
  ) { }

  ngOnInit() {
    this.options = DATE_WITHIN_FORMULAS;
    if (this.selectedFormula) {
      const formula = getDateWithinFormula(this.selectedFormula);
      if (formula) {
        this.optionList.selected = [formula.value];
      }
    }
  }

  onDone() {
    let result = this.optionList.selected && this.optionList.selected[0];
    if (result) {
      result = `TO_DATE(${result})`;
    }
    this.done(result);
    this.sideSheetService.pop();
  }
}

export function getDateWithinFormula(strFormula: string) {
  const formulaValue = strFormula && strFormula.startsWith('TO_DATE') ? strFormula.replace(/^TO_DATE\(|\)$/g, '') : strFormula;
  return DATE_WITHIN_FORMULAS.find(x => x.value === formulaValue);
}

