import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule, MatIconModule } from '@angular/material';
import { SideSheetHeaderComponent } from './side-sheet-header/side-sheet-header.component';
import { SideSheetComponent } from './side-sheet/side-sheet.component';
import { SideSheetContentDirective } from './side-sheet-content.directive';
import { SideSheetListItemComponent } from './side-sheet-list-item/side-sheet-list-item.component';
import { SideSheetListComponent } from './side-sheet-list/side-sheet-list.component';
import { SharedModule } from '../shared/shared.module';
import { DragulaModule } from 'ng2-dragula';
import { SideSheetSectionComponent } from './side-sheet-section/side-sheet-section.component';
import { SideSheetOptionListComponent } from './side-sheet-option-list/side-sheet-option-list.component';
import { EmptyStateModule } from '../shared/empty-state/empty-state.module';
import { AvatarViewComponent } from '../shared/avatar-view/avatar-view.component';
import { AvatarViewModule } from '../shared/avatar-view/avatar-view.module';
import { SideSheetHeaderModule } from './side-sheet-header/side-sheet-header.module';
import { FilterAndAddModule } from '../shared/filter-and-add/filter-and-add.module';
import { CanActionPipeModule } from '../shared/can-action-pipe/can-action-pipe.module';
import { SideSheetSectionModule } from './side-sheet-section/side-sheet-section.module';
import { SideSheetListItemModule } from './side-sheet-list-item/side-sheet-list-item.module';
import { SideSheetListModule } from './side-sheet-list/side-sheet-list.module';
import { SideSheetObjectChooserModule } from './side-sheet-object-chooser/side-sheet-object-chooser.module';
import { RelativeDatesSideSheetModule } from './generic-side-sheets/relative-dates-side-sheet/relative-dates-side-sheet.module';
import { DateWithinSideSheetModule } from './generic-side-sheets/date-within-side-sheet/date-within-side-sheet.module';
import { NavigationSideSheetModule } from './generic-side-sheets/navigation-side-sheet/navigation-side-sheet.module';
import { CustomDateRangeSideSheetModule } from './custom-date-range-side-sheet/custom-date-range-side-sheet.module';
import { SideSheetWrapperComponent } from './side-sheet-wrapper/side-sheet-wrapper.component';

@NgModule({
  imports: [
    CommonModule,
    MatSidenavModule,
    SharedModule,
    DragulaModule,
    EmptyStateModule,
    SideSheetHeaderModule,
    SideSheetSectionModule,
    EmptyStateModule,
    AvatarViewModule,
    FilterAndAddModule,
    CanActionPipeModule,
    MatIconModule,
    SideSheetListItemModule,
    SideSheetListModule,
    SideSheetObjectChooserModule,
    RelativeDatesSideSheetModule,
    DateWithinSideSheetModule,
    CustomDateRangeSideSheetModule,
    NavigationSideSheetModule
  ],
  declarations: [
    SideSheetComponent,
    SideSheetContentDirective,
    SideSheetOptionListComponent,
    SideSheetWrapperComponent
  ],
  entryComponents: [
    AvatarViewComponent,
    SideSheetOptionListComponent,
    SideSheetWrapperComponent
  ],
  exports: [
    SideSheetComponent,
    SideSheetListItemComponent,
    SideSheetListComponent,
    SideSheetOptionListComponent,
    SideSheetHeaderComponent,
    SideSheetSectionComponent,
    SideSheetWrapperComponent
  ]
})
export class SideSheetModule { }
