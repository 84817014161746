import * as tslib_1 from "tslib";
import { from, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { get } from 'lodash';
import { Stringify } from '../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import * as i0 from "@angular/core";
import * as i1 from "../data/all-object-types/all-object-type.service";
import * as i2 from "@angular/router";
import * as i3 from "../side-sheet/side-sheet.service";
import * as i4 from "../data/formula.service";
var LifecycleWorkflowRedirectService = /** @class */ (function () {
    function LifecycleWorkflowRedirectService(allObjectTypesService, router, sideSheetService, formulaService) {
        this.allObjectTypesService = allObjectTypesService;
        this.router = router;
        this.sideSheetService = sideSheetService;
        this.formulaService = formulaService;
    }
    LifecycleWorkflowRedirectService.prototype.redirectViaOutletWithFormAndObject = function (formObjectAndType, preview) {
        var _this = this;
        var objectTip = get(formObjectAndType, 'objectAndType.objectData.$tip', null);
        var objectTypeTip = get(formObjectAndType, 'objectAndType.objectType.$tip', null);
        // types like supertype may not have a tip
        if (!objectTypeTip) {
            return of(formObjectAndType);
        }
        // creation
        if (!objectTip) {
            return this
                .checkHasLifecycleWorkflow(objectTypeTip)
                .pipe(map(function (hasLifecycleWorkflow) {
                if (hasLifecycleWorkflow && !preview) {
                    _this.navigateToWorkflowLauncher(objectTypeTip);
                }
                return formObjectAndType;
            }));
        }
        // editing
        return this
            .getWorkflowRuntime(objectTip)
            .pipe(map(function (workflowRuntimeTip) {
            if (workflowRuntimeTip) {
                _this.navigateToWorkflowViaOutlet(workflowRuntimeTip);
            }
            return formObjectAndType;
        }));
    };
    LifecycleWorkflowRedirectService.prototype.redirectWithObject = function (object) {
        return this.withObject({ object: object, viaOutlet: false });
    };
    LifecycleWorkflowRedirectService.prototype.redirectViaOutletWithObject = function (object, cancel$) {
        return this.withObject({ object: object, viaOutlet: true, cancel$: cancel$ });
    };
    LifecycleWorkflowRedirectService.prototype.redirectViaOutletWithObjectTip = function (objectTip, replaceUrl) {
        if (replaceUrl === void 0) { replaceUrl = false; }
        return this.withObject({ viaOutlet: true, objectTip: objectTip, replaceUrl: replaceUrl }).pipe(map(function (_a) {
            var redirected = _a.redirected;
            return redirected;
        }));
    };
    LifecycleWorkflowRedirectService.prototype.withObject = function (_a) {
        var _this = this;
        var object = _a.object, viaOutlet = _a.viaOutlet, cancel$ = _a.cancel$, objectTip = _a.objectTip, replaceUrl = _a.replaceUrl;
        var tip = get(object, 'objectData.$tip', objectTip);
        if (!tip) {
            return of({ object: object, redirected: false });
        }
        return this
            .getWorkflowRuntime(tip)
            .pipe(map(function (workflowRuntimeTip) {
            if (workflowRuntimeTip && viaOutlet) {
                _this.navigateToWorkflowViaOutlet(workflowRuntimeTip, replaceUrl);
                if (cancel$) {
                    cancel$.next();
                }
                return { object: object, redirected: true };
            }
            if (workflowRuntimeTip && !viaOutlet) {
                _this.navigateToWorkflow(workflowRuntimeTip);
                if (cancel$) {
                    cancel$.next();
                }
                return { object: object, redirected: true };
            }
            return { object: object, redirected: false };
        }));
    };
    LifecycleWorkflowRedirectService.prototype.checkHasLifecycleWorkflow = function (objectTypeTip) {
        return this
            .allObjectTypesService
            .getLifecycleWorkflowFromTypeTip$(objectTypeTip)
            .pipe(map(Boolean));
    };
    LifecycleWorkflowRedirectService.prototype.getWorkflowRuntime = function (objectTip) {
        if (!objectTip) {
            return of(null);
        }
        return this
            .formulaService
            .evaluate(Stringify({
            name: 'FIELD',
            args: ['app/object-stage-state:workflow-runtime', {
                    name: 'REFERENCES',
                    args: ['app/object-stage-state:object', objectTip]
                }]
        }))
            .pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 1), tip = _b[0];
            return tip;
        }));
    };
    LifecycleWorkflowRedirectService.prototype.navigateToWorkflow = function (workflowRuntimeTip) {
        var redirectLocation = ['app', 's', 'workflow', workflowRuntimeTip];
        return from(this.router.navigate(redirectLocation));
    };
    LifecycleWorkflowRedirectService.prototype.navigateToWorkflowViaOutlet = function (workflowRuntimeTip, replaceUrl) {
        if (replaceUrl === void 0) { replaceUrl = true; }
        // clear side sheet so we can push this outlet correctly
        this.sideSheetService.clear();
        // Note: this outlet also gets triggered in object-workflow-launcher.component.ts & workflow-panel.component.ts
        var redirectLocation = ['app', 's', { outlets: { sidesheet: ['workflow', workflowRuntimeTip] } }];
        return from(this.router.navigate(redirectLocation, { replaceUrl: replaceUrl }));
    };
    LifecycleWorkflowRedirectService.prototype.navigateToWorkflowLauncher = function (objectTip) {
        this.sideSheetService.clear();
        var redirectLocation = ['app', 's', { outlets: { sidesheet: ['object', 'workflow-launcher', objectTip] } }];
        return from(this.router.navigate(redirectLocation, { replaceUrl: true }));
    };
    LifecycleWorkflowRedirectService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LifecycleWorkflowRedirectService_Factory() { return new LifecycleWorkflowRedirectService(i0.ɵɵinject(i1.AllObjectTypesService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.SideSheetService), i0.ɵɵinject(i4.FormulaService)); }, token: LifecycleWorkflowRedirectService, providedIn: "root" });
    return LifecycleWorkflowRedirectService;
}());
export { LifecycleWorkflowRedirectService };
