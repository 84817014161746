/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./empty-state.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../asset-pipe/asset.pipe";
import * as i4 from "./empty-state.component";
var styles_EmptyStateComponent = [i0.styles];
var RenderType_EmptyStateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmptyStateComponent, data: {} });
export { RenderType_EmptyStateComponent as RenderType_EmptyStateComponent };
function View_EmptyStateComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "img", [["class", "p-1"]], [[8, "src", 4]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "img-sm": 0 }), i1.ɵppd(4, 1)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "p-1"; var currVal_2 = _ck(_v, 3, 0, (_co.size === "sm")); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _co.imgSrc)); _ck(_v, 0, 0, currVal_0); }); }
function View_EmptyStateComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "span", [["class", "label"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "p-1": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "label"; var currVal_1 = _ck(_v, 3, 0, (_co.size === "default")); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.label; _ck(_v, 5, 0, currVal_2); }); }
function View_EmptyStateComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "action pb-2 border-0"], ["data-test", "action-text"]], null, [[null, "click"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onActionClick() !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (_co.onActionBlur() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "action-not-clickable": 0 }), (_l()(), i1.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "action pb-2 border-0"; var currVal_1 = _ck(_v, 3, 0, !_co.actionClick.observers.length); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.actionText; _ck(_v, 4, 0, currVal_2); }); }
function View_EmptyStateComponent_4(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_EmptyStateComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i3.AssetPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "d-flex flex-column align-items-center justify-content-center eim-forms_form-control"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "p-6": 0, "p-1": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmptyStateComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmptyStateComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmptyStateComponent_3)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["customAction", 2]], null, 0, null, View_EmptyStateComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "d-flex flex-column align-items-center justify-content-center eim-forms_form-control"; var currVal_1 = _ck(_v, 4, 0, (_co.size === "default"), (_co.size === "sm")); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.imgSrc; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.label; _ck(_v, 8, 0, currVal_3); var currVal_4 = (_co.showActionText && _co.actionText); var currVal_5 = i1.ɵnov(_v, 11); _ck(_v, 10, 0, currVal_4, currVal_5); }, null); }
export function View_EmptyStateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-empty-state", [], null, null, null, View_EmptyStateComponent_0, RenderType_EmptyStateComponent)), i1.ɵdid(1, 49152, null, 0, i4.EmptyStateComponent, [], null, null)], null, null); }
var EmptyStateComponentNgFactory = i1.ɵccf("app-empty-state", i4.EmptyStateComponent, View_EmptyStateComponent_Host_0, { size: "size", imgSrc: "imgSrc", label: "label", actionText: "actionText", showActionText: "showActionText" }, { actionClick: "actionClick", actionBlur: "actionBlur" }, ["*"]);
export { EmptyStateComponentNgFactory as EmptyStateComponentNgFactory };
