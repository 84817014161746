import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { get } from 'lodash';
import { FIELD_DATA_TYPE } from '../../../models/field';
var FormlyFieldDateTimeComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FormlyFieldDateTimeComponent, _super);
    function FormlyFieldDateTimeComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FormlyFieldDateTimeComponent.prototype.ngOnInit = function () {
        var fieldTip = get(this, 'field.templateOptions.contextTypeTip', null);
        var fieldOptions = get(this, 'formState.contextTypeOptions', null);
        this.fieldOption = fieldTip && fieldOptions ? fieldOptions.values.get(fieldTip) : null;
    };
    FormlyFieldDateTimeComponent.prototype.calculate = function () {
        this.formState.calculation$.next({
            fieldTip: this.field.key,
            fieldDataType: FIELD_DATA_TYPE.datetime,
            calculationFormula: this.fieldOption.calculation
        });
    };
    return FormlyFieldDateTimeComponent;
}(FieldType));
export { FormlyFieldDateTimeComponent };
