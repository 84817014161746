import { Injectable } from '@angular/core';
import { CompositeNodeConverter, INodeAndLink } from './CompositeNodeConverter';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import { Parser, Stringify } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { isEmpty } from 'lodash';
import { Formula } from '../../../data/models/types';
import { IGeneratePDFEditor } from '../node-editors/generate-pdf-editor/generate-pdf-editor.component';
import {
  createConnection,
  createFormulaFromStringInterpolation,
  createSetVarNode,
  createSubProcessNode,
  createSwitchNode
} from './utils/create-node-utils';

const NS = 'GeneratePdfConverterService';

@Injectable({
  providedIn: 'root'
})
export class GeneratePdfConverterService extends CompositeNodeConverter {
  type = 'processnode/logic/subprocess';
  processNodeType = 'processnodetype/logic/subprocess';

  constructor() {
    super();
  }

  public convert(nodeData: IGeneratePDFEditor): INodeAndLink {
    if (!this.isValid(nodeData)) {
      throw new InvalidWorkflowNodeDataError(`Please correct invalid data on generate-pdf node [${ nodeData.fields.title }]`, nodeData);
    }

    const { formula, variableToSetKey, filename, securityPolicy, form } = nodeData.fields;

    let filenameFormula: Formula;

    if (isEmpty(filename) || isEmpty(filename.html)) {
      filenameFormula = Stringify({
        name: 'CONCAT',
        args: [
          { name: 'TITLE', args: [Parser(formula)] },
          '-UTC',
          { name: 'FORMAT_DATETIME', args: [{ name: 'NOW', args: [] }, 'Ymd-His'] },
          '.pdf'
        ]
      });
    } else {
      filenameFormula = Stringify({
        name: 'CONCAT',
        args: [Parser(createFormulaFromStringInterpolation(filename)), '.pdf']
      });
    }

    const vars = {
      'Object tip': formula,
      'Var key': variableToSetKey,
      Filename: filenameFormula,
      'Security policy': securityPolicy || 'app/security-policy/system-all-users-read-write-delete',
      'Form tip': form || 'ARRAY()'
    };

    const setVarStatusNode = createSetVarNode(vars, nodeData.tip);

    const subprocessNode = createSubProcessNode('eim/process/workflow/generate-pdf', vars);

    const doneConnectionForSetVarNode = createConnection('done', subprocessNode);
    setVarStatusNode.connections.push(doneConnectionForSetVarNode);

    const switchStatusNode = createSwitchNode({ name: 'VAR', args: ['Status'] });

    const doneConnectionForSubprocessNode = createConnection('done', switchStatusNode);
    subprocessNode.connections.push(doneConnectionForSubprocessNode);

    const nodesAndLink: INodeAndLink = {
      nodes: [
        setVarStatusNode,
        subprocessNode,
        switchStatusNode
      ],
      links: [
        doneConnectionForSetVarNode,
        doneConnectionForSubprocessNode
      ]
    };

    this.populateOutgoingLinks(nodeData).forEach(link => {
      switchStatusNode.connections.push(link);
      nodesAndLink.links.push(link);
    });

    return nodesAndLink;
  }

  private isValid(nodeData: IGeneratePDFEditor): boolean {
    return !isEmpty(nodeData.fields.title)
      && !isEmpty(nodeData.fields.formula)
      && !isEmpty(nodeData.fields.variableToSetKey);
  }
}
