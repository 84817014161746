import { Diagram, Rect, GraphObject, Shape, Panel, Node, Link } from 'gojs';
import { IModelData } from '../workflow-designer-interfaces';
import { COLORS } from '../workflow-designer-constants';
import { GoJSLinks } from '../gojs-panels/gojs-links';
import { get } from 'lodash';

const NS = 'DiagramUtils';
const $ = GraphObject.make;
export const DIAGRAM_W = 10000;
export const DIAGRAM_H = 10000;
export const MAX_SIZE_TRIGGER = 5000;

export class DiagramUtils {

  /**
   * Returns empty model used for ui states and process data
   */
  static getNewModelData(): IModelData {
    return {
      uiStates: { // uiStates kept in a single object to help make debugging and maintaining them easier.
        nodes: {
          // will contain child objects that have the node's 'tip' as their object's property name
        }
      },
      nodeEditorDataList: [
        // will contain nodeEditore data that maps to diagram's nodes and links by their tips
      ]
    };
  }

  /**
   * Returns a goJS Diagram instance with settings
   * @param linksPanels
   */
  static getDiagram(linksPanels: GoJSLinks): Diagram {
    return $(Diagram, {
      grid: DiagramUtils.getGrid(),
      // 'draggingTool.dragsLink': true,
      // 'linkingTool.isUnconnectedLinkValid': true,
      'draggingTool.isGridSnapEnabled': true,
      'linkingTool.portGravity': 50,

      'relinkingTool.temporaryToNode': linksPanels.getTempLinkingNode(),
      'relinkingTool.temporaryFromNode': linksPanels.getTempLinkingNode(),
      'linkingTool.temporaryToNode': linksPanels.getTempLinkingNode(),
      'linkingTool.temporaryFromNode': linksPanels.getTempLinkingNode(),

      'linkingTool.linkValidation': DiagramUtils.allowedToLink,
      'relinkingTool.linkValidation': DiagramUtils.allowedToLink,

      'relinkingTool.fromHandleArchetype': linksPanels.getPortLinkingShape(),
      'relinkingTool.toHandleArchetype': linksPanels.getPortLinkingShape(),
      'linkReshapingTool.handleArchetype': linksPanels.getLinkShapingHandleShape(),
      'undoManager.isEnabled': true
    });
  }

  /**
   * Allows linking of one node to another provided it doesn't introduce a circular reference without a
   * circuit breaking node in the middle
   */
  static allowedToLink(fromNode: Node, fromPort: GraphObject, toNode: Node, toPort: GraphObject, link: Link): boolean {
    return DiagramUtils.isSafeCircular(fromNode, toNode);
  }

  /**
   * Returns true if there is either no circular reference between the from and to nodes, or if there is that it is safe
   * because there is some sort of natural circuit breaker in there.
   */
  static isSafeCircular(fromNode: Node, toNode: Node): boolean {
    if ([
      'WorkflowUXEditorComponent'
    ].indexOf(get(fromNode, 'data.editorComponentName')) > -1) {
      // Found a natural safe circuit breaker node
      return true;
    }

    const iterator = fromNode.findNodesInto();
    while (iterator.next()) {
      if (iterator.value === toNode) {
        // The predecessor node is the toNode, so we have an unsafe circular reference
        return false;
      }
      if (!DiagramUtils.isSafeCircular(iterator.value, toNode)) {
        // Recursively go back through the tree looking for unsafe circular references
        return false;
      }
    }
    return true;
  }

  /**
   * Returns a grid panel for the diagram's background
   */
  private static getGrid(): Panel {
    return $(Panel, 'Grid',
      $(Shape, 'LineH', { stroke: COLORS.GRID, strokeWidth: 0.5 }),
      $(Shape, 'LineH', { stroke: COLORS.GRID, strokeWidth: 0.5, interval: 10 }),
      $(Shape, 'LineV', { stroke: COLORS.GRID, strokeWidth: 0.5 }),
      $(Shape, 'LineV', { stroke: COLORS.GRID, strokeWidth: 0.5, interval: 10 })
    );
  }

  constructor(
    private diagram: Diagram
  ) {}


  /**
   * The scroll bars are a bit ugly, but we need to set a maximum diagram size of 10000 pixels,
   * so when the document becomes much bigger than the viewport, which would anyway trigger scroll bars
   * we set the max size of the document.
   */
  setDiagramBounds(doReset: boolean = false): void {
    const isLarge: boolean = this.diagram.documentBounds.width > MAX_SIZE_TRIGGER || this.diagram.documentBounds.height > MAX_SIZE_TRIGGER;
    const maxRect: Rect = new Rect(0, 0, DIAGRAM_W, DIAGRAM_H);
    const infiniteBoundsRect: Rect = new Rect(NaN, NaN, NaN, NaN);

    if (doReset) {
      this.diagram.fixedBounds = infiniteBoundsRect;
    }

    if (isLarge && isNaN(this.diagram.fixedBounds.width)) {
      this.diagram.fixedBounds = maxRect;
    }
  }
}
