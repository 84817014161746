import { Renderer2, ElementRef, OnInit, OnDestroy } from '@angular/core';
var SPINNER_TEMPLATE = "\n<svg width=\"40px\" height=\"40px\" viewBox=\"0 0 66 66\" xmlns=\"http://www.w3.org/2000/svg\">\n  <circle fill=\"none\" stroke-width=\"4\" stroke-linecap=\"round\" cx=\"33\" cy=\"33\" r=\"30\" class=\"circle\"></circle>\n</svg>";
var SpinnerDirective = /** @class */ (function () {
    function SpinnerDirective(renderer, el) {
        this.renderer = renderer;
        this.el = el;
        this.mask = true;
    }
    Object.defineProperty(SpinnerDirective.prototype, "showSpinner", {
        set: function (value) {
            this.setSpinner(value);
        },
        enumerable: true,
        configurable: true
    });
    SpinnerDirective.prototype.ngOnInit = function () {
        var _this = this;
        var div = this.renderer.createElement('div');
        this.renderer.addClass(div, 'be-spinner');
        this.renderer.setProperty(div, 'innerHTML', SPINNER_TEMPLATE);
        this.renderer.insertBefore(this.el.nativeElement, div, this.el.nativeElement.firstChild);
        this.renderer.addClass(this.el.nativeElement, 'be-loading');
        if (!this.mask) {
            this.renderer.addClass(this.el.nativeElement, 'be-loading-nomask');
        }
        if (this.showSpinner$) {
            this.subscription = this.showSpinner$.subscribe(function (v) { return _this.setSpinner(v); });
        }
    };
    SpinnerDirective.prototype.setSpinner = function (value) {
        if (value) {
            this.renderer.addClass(this.el.nativeElement, 'be-loading-active');
        }
        else {
            this.renderer.removeClass(this.el.nativeElement, 'be-loading-active');
        }
    };
    SpinnerDirective.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    return SpinnerDirective;
}());
export { SpinnerDirective };
