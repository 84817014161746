import * as tslib_1 from "tslib";
import { CompositeNodeConverter } from './CompositeNodeConverter';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import { isEmpty } from 'lodash';
import { createConnection, createSetVarNode, createSubProcessNode, createSwitchNode } from './utils/create-node-utils';
import * as i0 from "@angular/core";
var NS = 'QueryExternalMapConverterService';
var QueryExternalMapConverterService = /** @class */ (function (_super) {
    tslib_1.__extends(QueryExternalMapConverterService, _super);
    function QueryExternalMapConverterService() {
        var _this = _super.call(this) || this;
        _this.type = 'processnode/logic/subprocess';
        _this.processNodeType = 'processnodetype/logic/subprocess';
        return _this;
    }
    QueryExternalMapConverterService.prototype.convert = function (nodeData) {
        if (!this.isValid(nodeData)) {
            throw new InvalidWorkflowNodeDataError("Please correct invalid data on query-external-map node [" + nodeData.fields.title + "]", nodeData);
        }
        var _a = nodeData.fields, formula = _a.formula, variableToSetKey = _a.variableToSetKey, dataSourceTip = _a.dataSourceTip, queryAttribute = _a.queryAttribute;
        var vars = {
            Geography: formula,
            'Var key': variableToSetKey,
            'Data source tip': dataSourceTip,
            'Query attribute': queryAttribute
        };
        var setVarStatusNode = createSetVarNode(vars, nodeData.tip);
        var subprocessNode = createSubProcessNode('eim/process/workflow/query-external-map', vars);
        var doneConnectionForSetVarNode = createConnection('done', subprocessNode);
        setVarStatusNode.connections.push(doneConnectionForSetVarNode);
        var switchStatusNode = createSwitchNode({ name: 'VAR', args: ['Status'] });
        var doneConnectionForSubprocessNode = createConnection('done', switchStatusNode);
        subprocessNode.connections.push(doneConnectionForSubprocessNode);
        var nodesAndLink = {
            nodes: [
                setVarStatusNode,
                subprocessNode,
                switchStatusNode
            ],
            links: [
                doneConnectionForSetVarNode,
                doneConnectionForSubprocessNode
            ]
        };
        this.populateOutgoingLinks(nodeData).forEach(function (link) {
            switchStatusNode.connections.push(link);
            nodesAndLink.links.push(link);
        });
        return nodesAndLink;
    };
    QueryExternalMapConverterService.prototype.isValid = function (nodeData) {
        return !isEmpty(nodeData.fields.title)
            && !isEmpty(nodeData.fields.formula)
            && !isEmpty(nodeData.fields.variableToSetKey)
            && !isEmpty(nodeData.fields.dataSourceTip)
            && !isEmpty(nodeData.fields.queryAttribute);
    };
    QueryExternalMapConverterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function QueryExternalMapConverterService_Factory() { return new QueryExternalMapConverterService(); }, token: QueryExternalMapConverterService, providedIn: "root" });
    return QueryExternalMapConverterService;
}(CompositeNodeConverter));
export { QueryExternalMapConverterService };
