import * as tslib_1 from "tslib";
import { CompositeNodeConverter } from './CompositeNodeConverter';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import { CONTEXT_VAR_NAME } from '../../../message/message-utils.service';
import { get } from 'lodash';
import { Stringify } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { createConnection, createSetVarNode, createSubProcessNode, createSwitchNode } from './utils/create-node-utils';
import * as i0 from "@angular/core";
var SendEmailConverterService = /** @class */ (function (_super) {
    tslib_1.__extends(SendEmailConverterService, _super);
    function SendEmailConverterService() {
        return _super.call(this) || this;
    }
    SendEmailConverterService.prototype.convert = function (nodeData, workflow) {
        var _a;
        if (!this.isValid(nodeData)) {
            throw new InvalidWorkflowNodeDataError("Please correct invalid data on send email node", nodeData);
        }
        var vars = (_a = {
                'Outbound source tip': Stringify({ name: 'ARRAY', args: [nodeData.fields.outboundSource.$tip] })
            },
            _a[CONTEXT_VAR_NAME] = get(nodeData.fields.outboundSource, 'contextFormula', 'ARRAY()'),
            _a);
        var setVarStatusNode = createSetVarNode(vars, nodeData.tip);
        var additionalVarKeys = tslib_1.__spread(workflow.inputs.map(function (input) { return input.key; }), workflow.actors.map(function (actor) { return actor.key; }), workflow.variables.map(function (variable) { return variable.key; }));
        var subprocessNode = createSubProcessNode('eim/process/message/send-email', tslib_1.__spread(['Outbound source tip', CONTEXT_VAR_NAME], additionalVarKeys), 60000);
        var doneConnectionForSetVarNode = createConnection('done', subprocessNode);
        setVarStatusNode.connections.push(doneConnectionForSetVarNode);
        var switchStatusNode = createSwitchNode({ name: 'VAR', args: ['Status'] }, undefined, undefined, ['Sent', 'Failed']);
        var doneConnectionForSubprocessNode = createConnection('done', switchStatusNode);
        subprocessNode.connections.push(doneConnectionForSubprocessNode);
        var nodesAndLink = {
            nodes: [
                setVarStatusNode,
                subprocessNode,
                switchStatusNode
            ],
            links: [
                doneConnectionForSetVarNode,
                doneConnectionForSubprocessNode
            ]
        };
        this.populateOutgoingLinks(nodeData).forEach(function (link) {
            switchStatusNode.connections.push(link);
            nodesAndLink.links.push(link);
        });
        return nodesAndLink;
    };
    SendEmailConverterService.prototype.isValid = function (nodeData) {
        return !!nodeData.fields.outboundSource.$tip;
    };
    SendEmailConverterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SendEmailConverterService_Factory() { return new SendEmailConverterService(); }, token: SendEmailConverterService, providedIn: "root" });
    return SendEmailConverterService;
}(CompositeNodeConverter));
export { SendEmailConverterService };
