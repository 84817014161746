import { Subject } from 'rxjs';

export abstract class SideSheetBase<P, T> {
  done$ = new Subject<T>();

  // a good place to handle change detection
  // use strictly types props in the usage
  abstract setProps(props: P): void;

  // tslint:disable
  protected ngOnDestroy() {
  // tslint:enable
    this.done$.complete();
  }
}
