import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyStateComponent {
  @Input() size: 'default' | 'sm' = 'default';
  @Input() imgSrc: string;
  @Input() label: string;
  @Input() actionText: string;
  @Input() showActionText = true;
  @Output() actionClick: EventEmitter<void> = new EventEmitter();
  @Output() actionBlur: EventEmitter<void> = new EventEmitter();

  onActionClick(): void {
    this.actionClick.emit();
  }

  onActionBlur(): void {
    this.actionBlur.emit();
  }
}