import * as tslib_1 from "tslib";
import { AfterViewInit, ChangeDetectorRef, ElementRef, forwardRef, Renderer2 } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { uniqueId as UniqueId } from 'lodash';
import { DynamicFieldChooserSidesheetComponent } from '../../shared/dynamic-field-chooser/dynamic-field-chooser-side-sheet.component';
import { CONTENT_TYPE, INTERPOLATION_TYPE, StringInterpolationService } from '../string-interpolation/string-interpolation.service';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { MetaDataKey } from '../../object/field-formula-side-sheet/meta-data-formulas';
export var STRING_INTERPOLATE_TEXT_FIELD_MODE;
(function (STRING_INTERPOLATE_TEXT_FIELD_MODE) {
    STRING_INTERPOLATE_TEXT_FIELD_MODE["SINGLE_LINE"] = "single-line";
    STRING_INTERPOLATE_TEXT_FIELD_MODE["MULTI_LINE"] = "multi-line";
})(STRING_INTERPOLATE_TEXT_FIELD_MODE || (STRING_INTERPOLATE_TEXT_FIELD_MODE = {}));
export var VALUE_FORMAT;
(function (VALUE_FORMAT) {
    VALUE_FORMAT["SUBSTITUTIONS"] = "substitutions";
    VALUE_FORMAT["FORMULA"] = "formula";
})(VALUE_FORMAT || (VALUE_FORMAT = {}));
var STRING_INTERPOLATE_TEXT_FIELD_CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(function () { return StringInterpolateTextFieldComponent; }),
    multi: true
};
var StringInterpolateTextFieldComponent = /** @class */ (function () {
    function StringInterpolateTextFieldComponent(cdr, renderer, sideSheetService, formulaLabelService, displayFormulaWrapperService, window, document) {
        this.cdr = cdr;
        this.renderer = renderer;
        this.sideSheetService = sideSheetService;
        this.formulaLabelService = formulaLabelService;
        this.displayFormulaWrapperService = displayFormulaWrapperService;
        this.window = window;
        this.document = document;
        this._eimFormsFormControlHostClass = true;
        this.required = false;
        this.optional = false;
        this.readonly = false;
        this.placeholder = '';
        this.mode = STRING_INTERPOLATE_TEXT_FIELD_MODE.SINGLE_LINE;
        this.interpolationType = INTERPOLATION_TYPE.message;
        this.contextTypeTips = [];
        this.valueFormat = VALUE_FORMAT.SUBSTITUTIONS;
        this.useContextFormula = false;
        this.preserveLineBreaks = false;
        this.hideSystemCurrentUserOption = false;
        this.uniqueId = UniqueId('app-string-interpolate-text-field-');
        this.STRING_INTERPOLATE_TEXT_FIELD_MODE = STRING_INTERPOLATE_TEXT_FIELD_MODE;
        this.loading$ = new BehaviorSubject(false);
        this.isTextHighlighted = false;
        this.onChange = function () { };
        this.onTouched = function () { };
    }
    StringInterpolateTextFieldComponent.prototype.writeValue = function (value) {
        var _this = this;
        if (this.valueFormat === VALUE_FORMAT.SUBSTITUTIONS || typeof value !== 'string') {
            this.initValue(value);
            return;
        }
        value = StringInterpolationService.getSubstitutionMetaFromStringifiedFormula(value);
        var labelFormulasToEvaluate = value
            .substitutions
            .map(function (substitution) { return ({
            formulaString: _this.displayFormulaWrapperService.removeDisplayFormulaWrapper(substitution.value)
        }); });
        if (!labelFormulasToEvaluate.length) {
            this.initValue(value);
            this.loading$.next(false);
            return;
        }
        this.loading$.next(true);
        this.formulaLabelService.transformBatch(labelFormulasToEvaluate).pipe(first()).subscribe(function (labels) {
            value
                .substitutions
                .forEach(function (substitution, i) {
                if (labels[i]) {
                    substitution.label = labels[i];
                }
            });
            _this.initValue(value);
            _this.loading$.next(false);
        }, function () {
            value.substitutions.forEach(function (substitution, i) {
                substitution.label = '';
            });
            _this.initValue(value);
            _this.loading$.next(false);
        });
    };
    StringInterpolateTextFieldComponent.prototype.initValue = function (value) {
        // Return early if view hasn't rendered yet
        // Will nullify this.value if provided value is falsy or isn't a valid ISubstitutionMeta
        if (!this.formFieldEl) {
            this.value = value && this.instanceOfISubstitutionMeta(value) ? value : null;
            return;
        }
        // Nullify this.value and return early if provided value is falsey or isn't a valid ISubstitutionMeta
        if (!value || !this.instanceOfISubstitutionMeta(value)) {
            this.value = null;
            this.renderer.setProperty(this.formFieldEl.nativeElement, 'innerHTML', '');
            return;
        }
        var valueHtmlAsViewHtml = StringInterpolationService.getViewTextFromDataHtml(value, this.renderer);
        this.renderer.setProperty(this.formFieldEl.nativeElement, 'innerHTML', valueHtmlAsViewHtml);
        this.formatNewValue(false);
    };
    StringInterpolateTextFieldComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    StringInterpolateTextFieldComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    StringInterpolateTextFieldComponent.prototype.setDisabledState = function (isDisabled) {
        this.isDisabled = isDisabled;
        this.cdr.markForCheck();
    };
    StringInterpolateTextFieldComponent.prototype.ngAfterViewInit = function () {
        this.writeValue(this.value);
    };
    StringInterpolateTextFieldComponent.prototype.formatNewValue = function (emitChangeEvent) {
        if (emitChangeEvent === void 0) { emitChangeEvent = true; }
        var newValue = this.formFieldEl.nativeElement.innerHTML;
        var newValueFormatted = StringInterpolationService.getSubstitutionMetaFromViewHtml(newValue, this.renderer, CONTENT_TYPE.TEXT_CONTENT, this.preserveLineBreaks);
        if (this.valueFormat === VALUE_FORMAT.FORMULA) {
            StringInterpolationService.getStringifiedFormulaFromSubstitutionMeta(newValueFormatted);
        }
        this.value = newValueFormatted;
        if (emitChangeEvent) {
            var valueToEmit = this.valueFormat === VALUE_FORMAT.FORMULA
                ? StringInterpolationService.getStringifiedFormulaFromSubstitutionMeta(newValueFormatted)
                : newValueFormatted;
            this.onChange(valueToEmit);
        }
    };
    StringInterpolateTextFieldComponent.prototype.openStringInterpolationSelectSideSheet = function () {
        var _this = this;
        var sheetRef = this.sideSheetService.push(DynamicFieldChooserSidesheetComponent);
        var instance = sheetRef.componentInstance;
        instance.recipientSuperTypeTip = this.recipientSuperTypeTip;
        instance.interpolationType = this.interpolationType;
        instance.contextTypeTips = this.contextTypeTips;
        instance.workflowVariables = this.workflowVariables;
        instance.useContextFormula = this.useContextFormula;
        instance.showSequence = this.showSequence;
        instance.showModuleOptions = this.showModuleOptions;
        instance.moduleTip = this.moduleTip;
        instance.hideSystemCurrentUserOption = this.hideSystemCurrentUserOption;
        this.showSequence
            ? instance.displayFormulaRule = tslib_1.__assign({}, this.displayFormulaRule, { stripHTML: true, list: true, date: true, datetime: true })
            : instance.displayFormulaRule = tslib_1.__assign({}, this.displayFormulaRule, { stripHTML: true, date: true, datetime: true });
        if (this.interpolationType === INTERPOLATION_TYPE.general) {
            instance.metaDataKeysToExclude = [MetaDataKey.title, MetaDataKey.urlLink];
        }
        else if (this.interpolationType === INTERPOLATION_TYPE.message) {
            instance.metaDataKeysToExclude = [];
        }
        instance.done = function (substitutionCandidate) {
            _this.sideSheetService.pop();
            // Create interpolation blot
            var interpolationElement = StringInterpolationService.createInterpolationSpanElement(substitutionCandidate, _this.renderer);
            // If there is no currentCursorPositionNode (cursor isn't inside formFieldEl),
            // Append the interpolation blot to the nativeElement
            if (!_this.currentCursorPositionNode || _this.currentCursorPositionOffset === null) {
                _this.renderer.appendChild(_this.formFieldEl.nativeElement, interpolationElement);
                _this.formatNewValue();
                return;
            }
            // With the currentCursorPositionNode and the currentCursorPositionOffset within that node
            // Extract the textContent before and after the cursor offset into two separate variables
            //  Clear the currentCursorPositionNode textContent
            var textContent = _this.currentCursorPositionNode.textContent.slice();
            var contentBefore = textContent.substring(0, _this.currentCursorPositionOffset);
            var contentAfter = textContent.substring(_this.currentCursorPositionOffset);
            _this.currentCursorPositionNode.textContent = '';
            // Insert the interpolation blot inside the now cleared currentCursorPositionNode
            // Append the previously extracted before and after textContent to the now-inserted interpolation blot
            var currentCursorPositionParentNode = _this.renderer.parentNode(_this.currentCursorPositionNode);
            var currentCursorPositionNextSibling = _this.renderer.nextSibling(_this.currentCursorPositionNode);
            _this.renderer.insertBefore(currentCursorPositionParentNode, interpolationElement, currentCursorPositionNextSibling);
            interpolationElement.insertAdjacentText('beforebegin', contentBefore);
            interpolationElement.insertAdjacentText('afterend', contentAfter);
            _this.formatNewValue();
        };
    };
    // 'keyup' + 'mouseup' events
    StringInterpolateTextFieldComponent.prototype.updateCurrentCursorPositionValues = function () {
        var sel = this.window.getSelection();
        this.isTextHighlighted = !!sel.toString();
        if (this.isTextHighlighted && sel.rangeCount !== 0) {
            var selectionRange = sel.getRangeAt(0);
            this.highlightedTextStart = selectionRange.startOffset;
            this.highlightedTextEnd = selectionRange.endOffset;
        }
        else {
            this.highlightedTextStart = this.highlightedTextEnd = -1;
        }
        // Set to null if selected element is not a descendent of #formFieldEl
        if (!this.formFieldEl.nativeElement.contains(sel.anchorNode.parentElement)) {
            this.currentCursorPositionNode = null;
            this.currentCursorPositionOffset = null;
            return;
        }
        // Set to previous sibling if selected element is inside a substitution blot
        if (sel.anchorNode.parentElement.parentElement.getAttribute('data-substitution')) {
            var previousSibling = sel.anchorNode.parentElement.parentElement.previousSibling;
            this.currentCursorPositionNode = previousSibling;
            if (previousSibling) {
                this.currentCursorPositionOffset = previousSibling.textContent.length;
            }
            return;
        }
        this.currentCursorPositionNode = sel.anchorNode;
        this.currentCursorPositionOffset = sel.anchorOffset;
    };
    // 'keydown' event
    StringInterpolateTextFieldComponent.prototype.preventIfEnterKeyPressed = function (event) {
        if (event.key.toLowerCase() === 'enter') {
            event.preventDefault();
            this.formFieldEl.nativeElement.blur();
        }
    };
    // 'paste' event
    StringInterpolateTextFieldComponent.prototype.sanitizePaste = function (event) {
        event.stopPropagation();
        event.preventDefault();
        var clipboardData = event.clipboardData ||
            window.clipboardData; // to support IE
        if (!clipboardData || !clipboardData.getData) {
            return;
        }
        var clipboardDataAsText = event.clipboardData ?
            clipboardData.getData('text/plain') :
            clipboardData.getData('Text'); // to support IE
        if (!clipboardDataAsText) {
            return;
        }
        var clipboardDataAsTextEl = this.renderer.createText(clipboardDataAsText);
        if (this.currentCursorPositionNode) {
            if (this.currentCursorPositionOffset >= 0 && this.currentCursorPositionNode.textContent !== '') {
                var result = '';
                if (this.isTextHighlighted && this.highlightedTextEnd > this.highlightedTextStart) { // try to replace the highlighted text
                    var sourceText = this.currentCursorPositionNode.textContent;
                    var prefix = sourceText.substring(0, this.highlightedTextStart);
                    var suffix = sourceText.substring(this.highlightedTextEnd);
                    result = prefix + clipboardDataAsTextEl.textContent + suffix;
                }
                else { // paste the text inline
                    var sourceText = this.currentCursorPositionNode.textContent;
                    var prefix = sourceText.substring(0, this.currentCursorPositionOffset);
                    var suffix = sourceText.substring(this.currentCursorPositionOffset);
                    result = prefix + clipboardDataAsTextEl.textContent + suffix;
                }
                this.renderer.setValue(this.currentCursorPositionNode, result);
            }
            else {
                var currentCursorPositionParentNode = this.renderer.parentNode(this.currentCursorPositionNode);
                var currentCursorPositionNextSibling = this.renderer.nextSibling(this.currentCursorPositionNode);
                this.renderer.insertBefore(currentCursorPositionParentNode, clipboardDataAsTextEl, currentCursorPositionNextSibling);
            }
        }
        else {
            this.renderer.appendChild(this.formFieldEl.nativeElement, clipboardDataAsTextEl);
        }
        this.formatNewValue();
    };
    StringInterpolateTextFieldComponent.prototype.removeContextSubstitution = function () {
        var _a = StringInterpolationService.removeSubstitutionByContextFormula(this.formFieldEl.nativeElement.innerHTML, this.renderer), changed = _a.changed, newHtml = _a.newHtml;
        if (changed) {
            this.renderer.setProperty(this.formFieldEl.nativeElement, 'innerHTML', newHtml);
            this.formatNewValue(true);
        }
    };
    StringInterpolateTextFieldComponent.prototype.instanceOfISubstitutionMeta = function (substitutionMeta) {
        return substitutionMeta &&
            typeof substitutionMeta === 'object' &&
            'html' in substitutionMeta &&
            'substitutions' in substitutionMeta;
    };
    return StringInterpolateTextFieldComponent;
}());
export { StringInterpolateTextFieldComponent };
