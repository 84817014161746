import * as tslib_1 from "tslib";
import { WorkflowValueTypes } from '../workflow-designer-constants';
import { camelCase } from 'change-case';
import { UX_NODE_OUTCOME } from '../../modules/sidesheets/workflow-end-user-side-sheet/workflow-end-user.service';
import { get } from 'lodash';
var NS = 'NodeEditorLightUtils';
var NodeEditorLightUtils = /** @class */ (function () {
    function NodeEditorLightUtils() {
    }
    NodeEditorLightUtils.getSelectedProperty = function (selectedProperty, inputs, variables, actors) {
        switch (selectedProperty.type) {
            case WorkflowValueTypes.INPUT:
                return inputs[selectedProperty.selectedIndex] || null;
            case WorkflowValueTypes.ACTOR:
                return actors[selectedProperty.selectedIndex] || null;
            case WorkflowValueTypes.VARIABLE:
                return variables[selectedProperty.selectedIndex] || null;
            default:
                return null;
        }
    };
    /**
     * Returns an object used to identify the selected item from the selected type's list
     */
    NodeEditorLightUtils.getSelectedPropertyInfo = function (selectedType, inputs, variables, actors) {
        switch (selectedType) {
            case WorkflowValueTypes.INPUT:
                return {
                    type: selectedType,
                    selectedIndex: inputs.map(function (o) { return o.isSelected; }).indexOf(true)
                };
            case WorkflowValueTypes.ACTOR:
                return {
                    type: selectedType,
                    selectedIndex: actors.map(function (o) { return o.isSelected; }).indexOf(true)
                };
            case WorkflowValueTypes.VARIABLE:
                return {
                    type: selectedType,
                    selectedIndex: variables.map(function (o) { return o.isSelected; }).indexOf(true)
                };
            default:
                return null;
        }
    };
    NodeEditorLightUtils.getSelectedPropertyKey = function (selectedType, inputs, variables, actors) {
        var selectedProperty = NodeEditorLightUtils.getSelectedPropertyInfo(selectedType, inputs, variables, actors);
        return NodeEditorLightUtils.getWorkflowPropKey(selectedProperty, inputs, variables, actors);
    };
    /**
     * Returns the key of a workflow property using the selected index.
     */
    NodeEditorLightUtils.getWorkflowPropKey = function (selectedProperty, inputs, variables, actors) {
        if (!selectedProperty || !inputs || !variables || !actors) {
            return null;
        }
        switch (selectedProperty.type) {
            case WorkflowValueTypes.ACTOR:
                return get(actors, [selectedProperty.selectedIndex, 'key'], null);
            case WorkflowValueTypes.VARIABLE:
                return get(variables, [selectedProperty.selectedIndex, 'key'], null);
            case WorkflowValueTypes.INPUT:
                return get(inputs, [selectedProperty.selectedIndex, 'key'], null);
        }
        return null;
    };
    /**
     * Returns a 'ISelectedWorkflowValue' object for the first IWorkflowProp item from the provided lists
     */
    NodeEditorLightUtils.getSelectedWorkflowValueByKey = function (key, inputs, variables, actors) {
        if (!key || !inputs || !variables || !actors) {
            return null;
        }
        return inputs.find(function (o) { return o.key === key; }) ||
            actors.find(function (o) { return o.key === key; }) ||
            variables.find(function (o) { return o.key === key; }) ||
            null;
    };
    NodeEditorLightUtils.getWorkflowPropByKey = function (key, inputs, variables, actors) {
        if (!key || !inputs || !variables || !actors) {
            return null;
        }
        return inputs.concat(variables).concat(actors).find(function (o) { return o.key === key; });
    };
    NodeEditorLightUtils.shallowCopyArr = function (arr) {
        return arr ? arr.slice() : [];
    };
    /**
     * Looks through actors, variables and inputs to find the first isSelected item and returns the property type (WorkflowValueTypes)
     */
    NodeEditorLightUtils.setSelectedPropertyType = function (selectedPropertyKey, inputs, variables, actors) {
        this.resetSelectedProperties(inputs, variables, actors);
        var selectedProperty = this.getSelectedWorkflowValueByKey(selectedPropertyKey, inputs, variables, actors);
        if (!selectedProperty) {
            return;
        }
        selectedProperty.isSelected = true;
    };
    NodeEditorLightUtils.resetSelectedProperties = function (inputs, variables, actors) {
        [actors, variables, inputs].forEach(function (props) { return props.forEach(function (prop) { return prop.isSelected = false; }); });
    };
    NodeEditorLightUtils.getCombinedWorkflowProps = function (workflowData) {
        return workflowData.inputs.concat(workflowData.actors).concat(workflowData.variables);
    };
    NodeEditorLightUtils.setNodeEditorData = function (diagram, tip, data) {
        var existingProcessIndex = diagram.model.modelData.nodeEditorDataList
            .map(function (o, i) { return o.tip === tip ? i : -1; }) // maps the index of matching tip
            .find(function (o) { return o !== -1; }); // finds just the index that matches the tip
        if (existingProcessIndex !== undefined && existingProcessIndex !== -1) {
            diagram.model.modelData.nodeEditorDataList.splice(existingProcessIndex, 1, data);
        }
        else {
            diagram.model.modelData.nodeEditorDataList.push(data);
        }
    };
    /**
     * Converts a list of IScreenFieldEditItem items to ILinkType for use in process connections
     */
    NodeEditorLightUtils.getWorkflowUXConnectionTypes = function (screens) {
        var connections = [];
        screens.forEach(function (screen) {
            connections.push.apply(connections, tslib_1.__spread(NodeEditorLightUtils.getWorkflowUXConnectionTypesForScreen(screen)));
        });
        return connections;
    };
    /**
     * Converts a single IScreenFieldEditItem item to ILinkType for use in process connection
     */
    NodeEditorLightUtils.getWorkflowUXConnectionTypesForScreen = function (screen, includePrimary) {
        if (includePrimary === void 0) { includePrimary = true; }
        var connectionNames = screen.actionButtonLabels || [];
        var links = connectionNames.map(function (name) { return ({ name: name, value: camelCase(name), hasDivider: false }); });
        if (includePrimary && screen.primaryButtonLabel) {
            // Note: deliberately using 'Primary' for label, so it's in line with hardcoded 'Secondary' connection.
            // If this needs to change, use screen.primaryButtonLabel for name (but not value)
            links.unshift({ name: UX_NODE_OUTCOME.primary, value: UX_NODE_OUTCOME.primary, hasDivider: false });
        }
        return links;
    };
    return NodeEditorLightUtils;
}());
export { NodeEditorLightUtils };
