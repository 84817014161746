import { Injectable } from '@angular/core';
import { CompositeNodeConverter, INodeAndLink } from './CompositeNodeConverter';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import { ISendNotificationEditor } from '../node-editors/send-notification-editor/send-notification-editor.component';
import { Parser, Stringify } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { isEmpty } from 'lodash';

import {
  createConnection,
  createFormulaFromStringInterpolation,
  createSetVarNode,
  createSubProcessNode,
  createSwitchNode
} from './utils/create-node-utils';

@Injectable({
  providedIn: 'root'
})
export class SendNotificationConverterService extends CompositeNodeConverter {
  type = 'processnode/logic/subprocess';
  processNodeType = 'processnodetype/logic/subprocess';

  constructor() {
    super();
  }

  public convert(nodeData: ISendNotificationEditor): INodeAndLink {
    if (!this.isValid(nodeData)) {
      // tslint:disable-next-line:max-line-length
      throw new InvalidWorkflowNodeDataError(`Please correct invalid data on send notification node [${ nodeData.fields.title }]`, nodeData);
    }

    const vars = {
     'Recipients to': nodeData.fields.recipients.to && nodeData.fields.recipients.to.length > 0 ?
        Stringify({name: 'ARRAY', args: nodeData.fields.recipients.to}) : 'ARRAY()',
      'Recipients to formulas': nodeData.fields.recipients.toFormulas && nodeData.fields.recipients.toFormulas.length > 0 ?
        Stringify({name: 'ARRAY', args: nodeData.fields.recipients.toFormulas.map(item => Parser(item))}) : 'ARRAY()',
    //  'Notification title': createFormulaFromStringInterpolation(nodeData.fields.notificationTitle),  // uncomment this when relevant
      'Notification title': 'Workflow Notification',  // TODO - update when we start handling title downstream
      'Notification body': createFormulaFromStringInterpolation(nodeData.fields.notificationBody),
      'Notification category': nodeData.fields.notificationCategory,
      'Notification context': nodeData.fields.notificationContext.formula
    };

    // create the process nodes
    const setVarNode = createSetVarNode(vars, nodeData.tip);
    const subProcessNode = createSubProcessNode('eim/process/notification/preprocess-notifications', vars);
    const switchNode = createSwitchNode({name: 'VAR', args: ['Status']});

    // link from setvar to sub-process
    const doneConnectionForSetVarNode = createConnection('done', subProcessNode);
    setVarNode.connections.push(doneConnectionForSetVarNode);

    // link from sub-process to switch
    const doneConnectionForSubprocessNode = createConnection('done', switchNode);
    subProcessNode.connections.push(doneConnectionForSubprocessNode);

    // make the final collection of all nodes and links
    const nodesAndLink: INodeAndLink = {
      nodes: [
        setVarNode,
        subProcessNode,
        switchNode
      ],
      links: [
        doneConnectionForSetVarNode,
        doneConnectionForSubprocessNode
      ]
    };

    this.populateOutgoingLinks(nodeData).forEach(link => {
      switchNode.connections.push(link);
      nodesAndLink.links.push(link);
    });

    return nodesAndLink;

  }

  private isValid(nodeData: ISendNotificationEditor): boolean {
    return !isEmpty(nodeData.fields.title)
      && !isEmpty(nodeData.fields.recipients)
      && !isEmpty(nodeData.fields.notificationCategory);
  }


}
