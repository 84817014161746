import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { Tip } from '../models/types';
import { AllObjectTypesService } from '../all-object-types/all-object-type.service';
import { FormulaService } from '../formula.service';
import { head } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ObjectBehaviourService {
  constructor(
    private allObjectTypesService: AllObjectTypesService,
    private formulaService: FormulaService
  ) {}

  resolveObjectBehaviour(objectTip: Tip, typeTip?: Tip): Observable<{ url: string } | null> {
    return (typeTip ? of([typeTip]) : this.formulaService.evaluate(`TYPE(TIP())`, objectTip)).pipe(
      switchMap(_typeTip => this.allObjectTypesService.getDashboardTipFromTypeTip$(head(_typeTip))),
      map(
        (dashboardTip: Tip) => {
          if (dashboardTip) {
            const dashboardUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`
              + `/app/s/dashboard/${encodeURIComponent(dashboardTip)}/${encodeURIComponent(objectTip)}`;
            return {
              url: dashboardUrl
            };
          } else {
            return null;
          }
        }
      )
    );
  }
}
