import { ISubstitutionMeta } from '../../../form/string-interpolation/string-interpolation.service';
import { Parser, Stringify } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { Formula } from '../../../data/models/types';
import { isEmpty } from 'lodash';

export function stringInterpolateFormula<T extends {
  formula: Formula,
  isManualEntry?: boolean,
  isStringInterpolation?: boolean
}>(options: T): Formula | null {
  if (options.isManualEntry && options.isStringInterpolation) {
    try {
      return stringInterpolateReplaceFormula(JSON.parse(options.formula.substring(7, options.formula.length - 2)));
    } catch (e) {
      return options.formula;
    }
  }

  return options.formula;
}

export function stringInterpolateReplaceFormula(substitutionMeta: ISubstitutionMeta): Formula | null {
  if (isEmpty(substitutionMeta.html)) {
    return null;
  }

  if (isEmpty(substitutionMeta.substitutions)) {
    substitutionMeta.substitutions = [];
  }

  return Stringify({
    name: 'REPLACE',
    args: [
      substitutionMeta.html,
      {
        name: 'ARRAY',
        args: substitutionMeta.substitutions.map(sub => sub.key)
      },
      {
        name: 'ARRAY',
        args: substitutionMeta.substitutions.map(sub => ({
          name: 'CONCAT_WS',
          args: [
            ', ',
            Parser(sub.value)
          ]
        }))
      }
    ]
  });
}
