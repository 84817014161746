import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-field-toggle',
  template: `
      <app-toggle-field
        [testId]="to.testId"
        [formControl]="formControl"
        [label]="to.label"
        [required]="to.required"
        [readonly]="to.readonly"></app-toggle-field>
  `,
})
export class FormlyFieldToggleComponent extends FieldType implements OnInit {
  ngOnInit() {
    if (typeof this.formControl.value !== 'boolean') {
      this.formControl.patchValue(false);
    }
  }
}
