import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { startWith, takeUntil, tap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { formatNumber } from '../../../shared/number-format/number-format';
import { Calculations, INumberFormat } from '../../../shared/number-format/number-format-types';

interface INumberFormatFieldTips {
  'number-format:calculations': Calculations;
  'number-format:decimal-places': number;
  'number-format:decimal-separator': string;
  'number-format:thousands-separator': string;
  'number-format:name': string;
  'number-format:preview-value': number;
  'number-format:prefix': string;
  'number-format:suffix': string;
}

@Component({
  selector: 'app-number-format-preview-formly',
  template: `<app-text-field
          [formControl]="PFormControl"
          label="Number preview">
  </app-text-field>`
})
export class FormlyFieldNumberFormatPreviewComponent extends FieldType implements OnInit, OnDestroy {
  @HostBinding('class') classes = 'flex-fill';
  preview$;

  PFormControl = new FormControl();
  done$ = new Subject();

  ngOnInit(): void {
    this.PFormControl.disable(); // show in disabled state

    this.preview$ = this.form.valueChanges
      .pipe(
        startWith(this.form.value),
        takeUntil(this.done$)
      )
      .subscribe((numberFormatFieldTips: INumberFormatFieldTips) => {
        const numberFormat = numberFormatFieldToNumberFormat(numberFormatFieldTips);
        const previewValue = numberFormatFieldTips['number-format:preview-value'];
        const formattedNumber = formatNumber(previewValue, numberFormat);
        this.PFormControl.patchValue(formattedNumber);
      });
  }

  ngOnDestroy(): void {
    this.done$.next();
    this.done$.complete();
  }
}

function numberFormatFieldToNumberFormat(numberFormatField: INumberFormatFieldTips): INumberFormat {
  return {
    calculations: numberFormatField['number-format:calculations'],
    decimalPlaces: numberFormatField['number-format:decimal-places'],
    thousandsSeparator: numberFormatField['number-format:thousands-separator'],
    decimalSeparator: numberFormatField['number-format:decimal-separator'],
    suffix: numberFormatField['number-format:suffix'],
    prefix: numberFormatField['number-format:prefix'],
  };
}
