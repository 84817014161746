import { Injectable } from '@angular/core';
import { CompositeNodeConverter, INodeAndLink } from './CompositeNodeConverter';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import { Parser, Stringify } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { head, isEmpty, pullAt } from 'lodash';
import { ISaveFileNodeEditor } from '../node-editors/save-file-node-editor/save-file-node-editor.component';
import { Formula, Tip } from '../../../data/models/types';
import { FILE_NODE_LOCATION_TYPE, saveFileLocationProcessTips, } from '../node-editors/shared/file-location/file-location-types';
import {
  addContextFormulaWrapper,
  buildRecursiveContextFormula,
  getMostInnerContextPartsFromContextFormula
} from '../../../util/context-formula-wrapper';
import {
  createConnection,
  createFormulaFromStringInterpolation,
  createSetVarNode,
  createSubProcessNode,
  createSwitchNode
} from './utils/create-node-utils';

const NS = 'SaveFileNodeConverterService';

@Injectable({
  providedIn: 'root'
})
export class SaveFileNodeConverterService extends CompositeNodeConverter {
  type = 'processnode/logic/subprocess';
  processNodeType = 'processnodetype/logic/subprocess';

  constructor() {
    super();
  }

  public convert(nodeData: ISaveFileNodeEditor): INodeAndLink {
    if (!this.isValid(nodeData)) {
      throw new InvalidWorkflowNodeDataError(`Please correct invalid data on save-file node [${ nodeData.fields.title }]`, nodeData);
    }

    const { locationType, variableFormula, locationFormula, overwrite, locationTip, pathPrefix } = nodeData.fields;

    const vars: {[key in string]: Formula} = {
      'File tips': variableFormula,
      Overwrite: overwrite ? 'true' : 'false',
      'Location tip': locationTip || 'ARRAY()',
      'Location type': locationType
    };

    switch (locationType) {
      case FILE_NODE_LOCATION_TYPE.DOCUMENT_FOLDER:
      case FILE_NODE_LOCATION_TYPE.OBJECT_ATTACHMENTS:
        vars.Location = locationFormula;
        break;
      case FILE_NODE_LOCATION_TYPE.FILE_UPLOAD_FIELD:
        const { fieldTipFormula, objectFormula } = this.getFieldTipAndObjectFormula(locationFormula);
        vars['Field tip'] = fieldTipFormula;
        vars.Location = objectFormula;
        break;
      case FILE_NODE_LOCATION_TYPE.INTEGRATION_BUCKET:
        vars['Path prefix'] = createFormulaFromStringInterpolation(pathPrefix);
        break;
    }

    const setVarStatusNode = createSetVarNode(vars, nodeData.tip);

    const subprocessNode = createSubProcessNode(saveFileLocationProcessTips[locationType], vars);

    const doneConnectionForSetVarNode = createConnection('done', subprocessNode);
    setVarStatusNode.connections.push(doneConnectionForSetVarNode);

    const switchStatusNode = createSwitchNode({ name: 'VAR', args: ['Status'] }, ['fileError']);

    const doneConnectionForSubprocessNode = createConnection('done', switchStatusNode);
    subprocessNode.connections.push(doneConnectionForSubprocessNode);

    const nodesAndLink: INodeAndLink = {
      nodes: [
        setVarStatusNode,
        subprocessNode,
        switchStatusNode
      ],
      links: [
        doneConnectionForSetVarNode,
        doneConnectionForSubprocessNode
      ]
    };

    this.populateOutgoingLinks(nodeData).forEach(link => {
      switchStatusNode.connections.push(link);
      nodesAndLink.links.push(link);
    });

    return nodesAndLink;
  }

  private isValid(nodeData: ISaveFileNodeEditor): boolean {
    return !isEmpty(nodeData.fields.title)
      && !isEmpty(nodeData.fields.variableFormula)
      && !isEmpty(nodeData.fields.locationType)
      && !((nodeData.fields.locationType === FILE_NODE_LOCATION_TYPE.DOCUMENT_FOLDER
        || nodeData.fields.locationType === FILE_NODE_LOCATION_TYPE.FILE_UPLOAD_FIELD
        || nodeData.fields.locationType === FILE_NODE_LOCATION_TYPE.OBJECT_ATTACHMENTS
      ) && !nodeData.fields.locationFormula);
  }

  // CONTEXT(
  //    VAR("WorkflowVariableKey"),
  //    CONTEXT(
  //       CONTEXT(
  //          CONTEXT(
  //             FIELD("RelatedObjectField1"),
  //             FIELD("RelatedObjectField2")
  //          ),
  //          FIELD("FileField")
  //       ),
  //       TIP()
  //    )
  // )
  getFieldTipAndObjectFormula(formula: Formula): { fieldTipFormula: Tip, objectFormula: Formula } {
    const workflowFormulas = getMostInnerContextPartsFromContextFormula(formula);
    const workflowVariableFormula = workflowFormulas.mostInnerContextFormula; // VAR("WorkflowVariableKey")

    const fieldFormulas = getMostInnerContextPartsFromContextFormula(head(workflowFormulas.outerFormulas));
    const fieldFormulaArray = [...fieldFormulas.outerFormulas, fieldFormulas.mostInnerContextFormula];

    const fieldFormula = head(pullAt(fieldFormulaArray, [1]));
    if (isEmpty(fieldFormula)) {
      throw new Error('[save-file-node-converter] Needs to select a related object field!');
    }
    const fieldFormulaSpec = Parser(fieldFormula);
    if (fieldFormulaSpec.name !== 'FIELD') {
      throw new Error('[save-file-node-converter] Not a field formula!');
    }
    fieldFormulaSpec.name = 'ARRAY';
    const fieldTipFormula = Stringify(fieldFormulaSpec);

    const builtFieldFormula = buildRecursiveContextFormula(fieldFormulaArray);
    const objectFormula = addContextFormulaWrapper(builtFieldFormula, workflowVariableFormula);

    return { fieldTipFormula, objectFormula };
  }
}
