import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';
import { combineLatest, Observable, Subject } from 'rxjs';
import { get } from 'lodash';
import { IObjectLaunchDone, IObjectLaunchProps, OBJECT_LAUNCH_SERVICE_TOKEN } from '../../formly-noggin/object-launch';
import { SideSheetService } from '../../side-sheet/side-sheet.service';
import { IType } from '../../models/type';
import { IObjectTitle } from '../../models/title';
import { SecurityService } from '../../data/security.service';
import { IObjectAndType, IObjectTypeSchemeSubObject, ObjectService } from '../object.service';
import { ObjectRenderService } from '../object-render.service';
import { PrintHtmlPreviewSideSheetComponent } from '../../print/print-html-preview-side-sheet/print-html-preview-side-sheet.component';
import { DEFAULT_PRINT_TEMPLATE, DEFAULT_SETTING_PRINT, } from '../../print/print';

@Component({
  selector: 'app-object-open-side-sheet',
  templateUrl: './object-open-side-sheet.component.html',
  styleUrls: ['./object-open-side-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectOpenSideSheetComponent implements OnDestroy {
  loadingError: string | null = null;
  unsubscribe$ = new Subject();

  objectTip: string;

  canUpdate: boolean;
  title: string;
  objectType: IType;
  objectData: any;

  objectRenderData: any = '';

  done$ = new Subject<{ deleted?: boolean }>();

  constructor(
    private objectService: ObjectService,
    @Inject(OBJECT_LAUNCH_SERVICE_TOKEN) private objectLaunch,
    private securityService: SecurityService,
    private sideSheetService: SideSheetService,
    private objectRenderService: ObjectRenderService,
    private cdr: ChangeDetectorRef
  ) { }

  setProps({ objectTip }: IObjectLaunchProps) {
    this.objectTip = objectTip;
    this.load();

    // eventually replace renderer with object renderer
    // this.getObjectRenderData(objectTip);
  }

  getObjectRenderData(objectTip) {
    this.objectRenderService.getObjectsToRender([objectTip])
      .subscribe((v) => this.objectRenderData = v);
  }

  load() {
    this.unsubscribe$.next(); // finish any existing subscriptions
    const objectAndType$ = this.objectService.getObjectTypeSchemeSubObject(this.objectTip);
    const canUpdate$ = this.securityService.canUpdate(this.objectTip);
    const objectTitle$: Observable<string> = this.objectService.getTitles([this.objectTip]).pipe(
      map((objectTitles: IObjectTitle[]) => objectTitles[0].title || '')
    );

    combineLatest([objectAndType$, canUpdate$, objectTitle$])
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        ([objectAndType, canUpdate, title]: [IObjectTypeSchemeSubObject, boolean, string]) => {
          // clear to destroy render component
          this.clear();
          this.cdr.detectChanges();

          // reassign values
          this.objectType = get(objectAndType, 'objectType', null);
          this.objectData = get(objectAndType, 'objectData', null);
          this.title = title;
          
          const isSubObject = get(objectAndType, 'isSubObject', false);
          this.canUpdate = canUpdate && !isSubObject;
          this.cdr.markForCheck();
          if (this.canUpdate) {
            this.redirectToEditMode();
          }
        },
        () => {
          this.loadingError = 'Unable to load object at this time';
          this.cdr.markForCheck();
        }
      );
  }

  redirectToEditMode() {

    this.objectLaunch.replaceSheet({ objectTip: this.objectTip }, this.unsubscribe$)
      .subscribe(({ deleted }: IObjectLaunchDone) => {
        // pop the edit side sheet
        this.sideSheetService.pop();
      });
  }

  print() {
    const { componentInstance } = this.sideSheetService.push(PrintHtmlPreviewSideSheetComponent) as {
      componentInstance: PrintHtmlPreviewSideSheetComponent
    };

    componentInstance.setProps({ templateTip: DEFAULT_PRINT_TEMPLATE, settingTip: DEFAULT_SETTING_PRINT, contextTip: this.objectTip });
  }

  clear() {
    this.objectData = null;
    this.objectType = null;
    this.title = null;
    this.canUpdate = null;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
