import { Injectable } from '@angular/core';
import { CompositeNodeConverter, INodeAndLink } from './CompositeNodeConverter';
import { IImportEnosEditor } from '../node-editors/import-enos-editor/import-enos-editor.component';
import { createConnection, createSetVarNode, createSubProcessNode, createSwitchNode } from './utils/create-node-utils';

@Injectable({
  providedIn: 'root'
})
export class ImportEnosConverterService extends CompositeNodeConverter {
  constructor() {
    super();
  }

  convert(nodeData: IImportEnosEditor): INodeAndLink {
    const  {
      primaryKeyFieldTip,
      typeTip,
      fileToImportTip,
      matchingBehaviour,
      enoSecurityPolicy,
      importReportVariableToSetKey,
      modifiedObjectsVariableToSetKey,
      warningThreshold
    } = nodeData.fields;

    const vars = {
      'Type tip': typeTip as string,
      'Matching behaviour': matchingBehaviour,
      'Eno security policy': enoSecurityPolicy,
      'File to import tip': fileToImportTip.formula,
      'Primary key field tip': primaryKeyFieldTip as string,
      // 'Warning threshold': warningThreshold.toString(10),  // for now leave out
      'Import report variable to set key': importReportVariableToSetKey,
      'Modified objects variable to set key': modifiedObjectsVariableToSetKey
    };

    // create nodes
    const setVarNode = createSetVarNode(vars, nodeData.tip);
    const importEnosSubProcess = createSubProcessNode('eim/process/workflow/import-enos', vars);
    const switchNode = createSwitchNode({name: 'VAR', args: ['Status']});

    // connect setVar "done" to "importEnosSubProcess"
    const importEnosSubProcessDone = createConnection('done', switchNode);
    importEnosSubProcess.connections.push(importEnosSubProcessDone);

    // connect sub process "done" to "switchNode"
    const setVarDone = createConnection('done', importEnosSubProcess);
    setVarNode.connections.push(setVarDone);

    const nodesAndLink: INodeAndLink = {
      nodes: [
        setVarNode,
        importEnosSubProcess,
        switchNode
      ],
      links: [
        setVarDone,
        importEnosSubProcessDone
      ]
    };

    this.populateOutgoingLinks(nodeData).forEach(link => {
      switchNode.connections.push(link);
      nodesAndLink.links.push(link);
    });

    return nodesAndLink;
  }

}
