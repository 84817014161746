import * as tslib_1 from "tslib";
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../data/session-manager.service";
var AuthHttpClientService = /** @class */ (function () {
    function AuthHttpClientService(http, sessionManager) {
        this.http = http;
        this.sessionManager = sessionManager;
    }
    AuthHttpClientService.prototype.get = function (url, options) {
        return this.http.get(url, tslib_1.__assign({}, options, this.httpOptions()));
    };
    AuthHttpClientService.prototype.post = function (url, body) {
        return this.http.post(url, body, this.httpOptions());
    };
    AuthHttpClientService.prototype.delete = function (url) {
        return this.http.delete(url, this.httpOptions());
    };
    AuthHttpClientService.prototype.httpOptions = function () {
        var token = this.sessionManager.getAuthToken();
        var headers = new HttpHeaders({
            'Authorization': "Bearer " + token,
            'en-namespace': environment.ns
        });
        return {
            headers: headers
        };
    };
    AuthHttpClientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthHttpClientService_Factory() { return new AuthHttpClientService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.SessionManagerService)); }, token: AuthHttpClientService, providedIn: "root" });
    return AuthHttpClientService;
}());
export { AuthHttpClientService };
