import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-side-sheet-section',
  templateUrl: './side-sheet-section.component.html',
  styleUrls: ['./side-sheet-section.component.scss']
})
export class SideSheetSectionComponent {
  @Input() title: string;
  @Input() showBorder = true;
  @Input() collapsible = false;
  @Input() collapsed = false;
  @Input() buttonCollapseWithTitle = false;
  @Input() tallyPill: boolean;
  @Input() tallyPillValue = 0;
  @Input() showHeader = true;

  constructor() {}

  invertCollapsedState(): void {
    this.collapsed = !this.collapsed;
  }
}
