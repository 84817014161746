import { Injectable } from '@angular/core';
import { CompositeNodeConverter, INodeAndLink } from './CompositeNodeConverter';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import { isEmpty } from 'lodash';
import { IQueryExternalMapEditor } from '../node-editors/query-external-map-editor/query-external-map-editor.component';
import { createConnection, createSetVarNode, createSubProcessNode, createSwitchNode } from './utils/create-node-utils';

const NS = 'QueryExternalMapConverterService';

@Injectable({
  providedIn: 'root'
})
export class QueryExternalMapConverterService extends CompositeNodeConverter {
  type = 'processnode/logic/subprocess';
  processNodeType = 'processnodetype/logic/subprocess';

  constructor() {
    super();
  }

  public convert(nodeData: IQueryExternalMapEditor): INodeAndLink {
    if (!this.isValid(nodeData)) {
      throw new InvalidWorkflowNodeDataError(
        `Please correct invalid data on query-external-map node [${ nodeData.fields.title }]`, nodeData
      );
    }
    const { formula, variableToSetKey, dataSourceTip, queryAttribute } = nodeData.fields;

    const vars = {
      Geography: formula,
      'Var key': variableToSetKey,
      'Data source tip': dataSourceTip,
      'Query attribute': queryAttribute
    };

    const setVarStatusNode = createSetVarNode(vars, nodeData.tip);

    const subprocessNode = createSubProcessNode('eim/process/workflow/query-external-map', vars);

    const doneConnectionForSetVarNode = createConnection('done', subprocessNode);
    setVarStatusNode.connections.push(doneConnectionForSetVarNode);

    const switchStatusNode = createSwitchNode({ name: 'VAR', args: ['Status'] });

    const doneConnectionForSubprocessNode = createConnection('done', switchStatusNode);
    subprocessNode.connections.push(doneConnectionForSubprocessNode);

    const nodesAndLink: INodeAndLink = {
      nodes: [
        setVarStatusNode,
        subprocessNode,
        switchStatusNode
      ],
      links: [
        doneConnectionForSetVarNode,
        doneConnectionForSubprocessNode
      ]
    };

    this.populateOutgoingLinks(nodeData).forEach(link => {
      switchStatusNode.connections.push(link);
      nodesAndLink.links.push(link);
    });

    return nodesAndLink;
  }

  private isValid(nodeData: IQueryExternalMapEditor): boolean {
    return !isEmpty(nodeData.fields.title)
      && !isEmpty(nodeData.fields.formula)
      && !isEmpty(nodeData.fields.variableToSetKey)
      && !isEmpty(nodeData.fields.dataSourceTip)
      && !isEmpty(nodeData.fields.queryAttribute);
  }
}
