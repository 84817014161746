import { Injectable } from '@angular/core';
import dataConstants from '../../../data/constants';

import { NodeConverterBase } from './NodeConverterBase';
import { IProcessNodeSwitch } from '../../../models/process';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import { ISwitchEditor } from '../node-editors/switch-editor/switch-editor.component';
import { Stringify, Parser } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { ConnectionUtils } from '../utils/connection-utils';

@Injectable({
  providedIn: 'root'
})
export class SwitchNodeConverterService extends NodeConverterBase {
  type = 'processnode/logic/switch';
  processNodeType = 'processnodetype/logic/switch';

  convert(nodeData: ISwitchEditor): IProcessNodeSwitch {
    if (!this.isValid(nodeData)) {
      throw new InvalidWorkflowNodeDataError(`Please correct invalid data on switch node [${nodeData.fields.title}]`, nodeData);
    }

    const fieldFormula = Parser(nodeData.fields.formula);
    const expressionFormula = Stringify(
      {
        name: 'COALESCE',
        args: nodeData.fields.cases.map((x, index) => {
          
          const caseFormula = Parser(x.formula);
          return {
            name: 'IF',
            args: [
              {
                name: 'AND',
                args: [
                  {
                    name: 'EQUALS',
                    args: [
                      { name: 'COUNT', args: [ fieldFormula ]},
                      { name: 'COUNT', args: [ caseFormula ]}
                    ]
                  },
                  {
                    name: 'EQUALS',
                    args: [
                      { name: 'COUNT', args: [
                        { name: 'INTERSECT', args: [
                          fieldFormula,
                          caseFormula
                        ]}
                      ]},
                      { name: 'COUNT', args: [ fieldFormula ]}
                    ]
                  }
                ]
              },
              ConnectionUtils.getCaseIdentifier(x),
              { name: 'ARRAY', args: []}
            ]
          };
        })
      }
    );

    return {
      $type: this.type,
      $security: dataConstants.SECURITY.INSTANCE_USER_ADMIN,
      type: this.processNodeType,
      connections: [],
      point: [0, 0],
      key: nodeData.tip,
      switchExpr: expressionFormula,
      switchDefault: 'default',
      switchCases: nodeData.fields.cases.map((x, index) => ConnectionUtils.getCaseIdentifier(x))
    };

  }

  private isValid(nodeData: ISwitchEditor): boolean {
    return !(nodeData.fields.formula === '' || nodeData.fields.cases.length === 0);
  }

  constructor() {
    super();
  }
}
