import { DataTypes, IObjectScheme } from '../data/models/scheme';
import { IObject } from '../data/models/object';

export { IObjectStage, objectStageScheme };

interface IObjectStage extends IObject {
  name: string;
}

const objectStageScheme: IObjectScheme = {
  'app/object-stage:name': { type: DataTypes.i18n }
};
