import { IForm } from '../../settings/form-designer/models/form';
import { IType } from '../../models/type';
import { IObjectType } from '../../models/object-type';

export const brandingSettingsCreateForm: IForm = {
  name: 'Branding settings form',
  description: 'A form for Branding settings',
  size: 'regular',
  version: 1,
  contextType: {
    type: {
      $tip: 'app/branding-settings',
      label: 'Branding settings' // title for form
    } as IType
  } as IObjectType,
  tabs: [
    {
      tabName: 'tab1',
      rows: [
        {
          fields: [{
            type: 'input',
            templateOptions: {
              type: 'text',
              contextTypeTip: 'app/branding-settings:organisation-name',
              label: 'Name of the organisation',
              placeholder: 'Noggin'
            }
          }]
        },
        {
          fields: [{
            type: 'file-upload',
            templateOptions: {
              contextTypeTip: 'app/branding-settings:organisation-logo',
              label: 'Company logo',
              placeholder: 'Company logo',
              svgOptions: { concurrency: 1, allowedContentTypes: ['image/svg+xml'] },
              fileSecurityPolicyTip: 'app/security-policy/instance-user-admin'
            }
          }]
        },
        {
          fields: [{
            type: 'text-content',
            templateOptions: {
              label: 'Text content',
              // tslint:disable-next-line:max-line-length
              html: `<p>Please upload logo in SVG format with a transparent background. Note: This will be displayed on a dark blue background.</p>`
            }
          }]
        }
      ]
    }
  ]
};
