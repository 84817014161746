import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { FieldType } from '@ngx-formly/core';
import { get } from 'lodash';

import { supertypeScheme } from '../../../models/supertype';
import { IType, typeScheme } from '../../../models/type';
import { FIELD_DATA_TYPE, IField } from '../../../models/field';
import { Tip } from '../../../data/models/types';
import { ObjectService } from '../../../data/object-service/object.service';
import { QuoteString } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';

@Component({
  selector: 'app-table-columns',
  template: `
    <app-table-columns-chooser
        [objectTypeTip]="objectTypeTip"
        typeLabel="Object type"
        (labelsChanged)="updateColumnItems($event)"
        [form]="form"
        [formControl]="formControl"></app-table-columns-chooser>
    <div></div>
  `
})
export class FormlyFieldTableColumnsComponent extends FieldType implements OnInit, OnDestroy {
  objectTypeTip: Tip;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private objectService: ObjectService
  ) {
    super();
  }

  ngOnInit() {
    this.setProperties(true);

    const objectTypeTipControl = this.form.get('templateOptions').get('tableConfig').get('objectTypeTip');

    if (objectTypeTipControl) {
      this.subscriptions.add(objectTypeTipControl.valueChanges.subscribe(() => this.setProperties()));
    }
  }

  updateColumnItems(columnProps) {
    const objectTypeTipControl = this.form.get('templateOptions').get('tableConfig').get('columnItems');

    if (objectTypeTipControl) {
      objectTypeTipControl.setValue(columnProps);
    }
  }

  setProperties(firstTime: boolean = false) {
    const newObjectTypeTip: Tip = get(this, 'field.templateOptions.objectTypeTip', null);

    if (!firstTime && this.objectTypeTip !== newObjectTypeTip) {
      this.formControl.setValue([]);

      if (newObjectTypeTip) {
        const scheme = /^app\/super-type\//.test(newObjectTypeTip) ? supertypeScheme : typeScheme;

        this.objectService.getObject<IType>(newObjectTypeTip, scheme).pipe(
          first()
        ).subscribe(
          objectType => {
            this.form.get('templateOptions').get('tableConfig').get('columnItems').setValue([]);
            this.formControl.setValue(objectType.field.filter(
              (field: IField) => field.datatype !== FIELD_DATA_TYPE.object
              ).map(
              (field: IField) => `FIELD(${QuoteString(field.$tip)})`)
            );
          }
        );
      }
    }

    this.objectTypeTip = get(this, 'field.templateOptions.objectTypeTip', null);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
