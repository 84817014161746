import { IObject } from '../data/models/object';
import { Tip } from '../data/models/types';
import { DataTypes, IObjectScheme } from '../data/models/scheme';

export enum MESSAGE_PROCESSED_STATE {
  PROCESSED = 'Processed',
  NO_ACTION_REQUIRED = 'No Action Required',
  ACTION_REQUIRED = 'Action Required'
}

export enum MESSAGE_READ_STATUS {
  READ = 'read',
  UNREAD = 'unread',
}

export enum MESSAGE_TYPE {
  EMAIL = 'email',
  SMS = 'sms',
  VOICE = 'voice',
  FAX = 'fax',
  MESSAGE = 'message'
}

export interface IMessageRelatedObjects extends IObject {
  $type: 'app/message/related-objects';
  items?: Tip[];
}

export const messageRelatedObjectsScheme: IObjectScheme = {
  'app/message/related-objects:items': { type: DataTypes.stringArray },
};


export interface IMessageFilteringSelected {
  from?: {
    contact?: Tip;
    otherContact?: Tip;
  };
  to?: {
    group?: Tip;
    contact?: Tip;
    team?: Tip;
    role?: Tip;
    otherContact?: Tip;
  };
  messageType?: MESSAGE_TYPE;
  messageReadStatus?: MESSAGE_READ_STATUS;
}

export enum MessageFilterColumn {
  TO = 'To',
  FROM = 'From',
  MESSAGE_TYPE = 'Message type',
  MESSAGE_READ_STATUS = 'Message read status'
}

export enum RecipientFilterColumn {
  GROUP = 'Group',
  CONTACT = 'Contact',
  TEAM = 'Team',
  ROLE = 'Role',
  OTHER_CONTACT = 'Other Contact'
}