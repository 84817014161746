import { Injectable } from '@angular/core';
import { camelCase } from 'change-case';
import { UX_NODE_OUTCOME } from '../../modules/sidesheets/workflow-end-user-side-sheet/workflow-end-user.service';
import { IScreenFieldEditItem } from '../node-editors/workflow-ux-editor/workflow-screen-edit/workflow-screen-edit.component';
import { IWorkflowUXEditor } from '../node-editors/workflow-ux-editor/workflow-ux-editor.component';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import { CompositeNodeConverter, INodeAndLink } from './CompositeNodeConverter';
import { NodeEditorLightUtils } from '../utils/node-editor-light-utils';
import { ILinkType } from '../workflow-designer-interfaces';
import { WorkflowUXScreenItemType } from '../workflow-designer-enums';
import { stringInterpolateReplaceFormula } from '../utils/converter-utils';
import { Formula, Tip } from '../../../data/models/types';
import { createConnection, createSetVarNode, createSubProcessNode, createSwitchNode } from './utils/create-node-utils';
import { Stringify } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';

const NS = 'WorkflowUXNodeConverterService';

@Injectable({
  providedIn: 'root'
})
export class WorkflowUXNodeConverterService extends CompositeNodeConverter {
  type = 'processnode/logic/subprocess';
  processNodeType = 'processnodetype/logic/subprocess';

  constructor() {
    super();
  }

  public convert(nodeData: IWorkflowUXEditor): INodeAndLink {
    if (!this.isValid(nodeData)) {
      throw new InvalidWorkflowNodeDataError(`Please correct invalid data on workflow-ux node [${ nodeData.fields.title }]`, nodeData);
    }

    this.modifyScreensBasedOnOutcome(nodeData);
    const { screens, contextObjectType, contextObjectSelectedPropertyKey, isGoverned, forceCreation } = nodeData.fields;
    const UX = this.getUXObject(screens, contextObjectType, contextObjectSelectedPropertyKey, isGoverned, forceCreation);

    const setVarNode = createSetVarNode({
      'Object var name': contextObjectSelectedPropertyKey,
      UX
    }, nodeData.tip);

    const subprocessNode = createSubProcessNode(
      'eim/process/workflow-ux',
      ['Workflow runtime tip', 'UX', 'Object var name'],
      60000
    );

    const doneConnectionForSetVarNode = createConnection('done', subprocessNode);
    setVarNode.connections.push(doneConnectionForSetVarNode);

    const actionAndPrimaryButtonConnections: string[] =
      NodeEditorLightUtils.getWorkflowUXConnectionTypes(nodeData.fields.screens)
                          .map(o => o.value);

    const switchNode = createSwitchNode(
      { name: 'VAR', args: ['Outcome'] },
      actionAndPrimaryButtonConnections,
      UX_NODE_OUTCOME.noop,
      [UX_NODE_OUTCOME.secondary]
    );

    const doneConnectionForSubprocessNode = createConnection('done', switchNode);
    subprocessNode.connections.push(doneConnectionForSubprocessNode);

    const nodesAndLink: INodeAndLink = {
      nodes: [
        setVarNode,
        subprocessNode,
        switchNode
      ],
      links: [
        doneConnectionForSetVarNode,
        doneConnectionForSubprocessNode
      ]
    };

    this.populateOutgoingLinks(nodeData).forEach(link => {
      switchNode.connections.push(link);
      nodesAndLink.links.push(link);
    });

    return nodesAndLink;
  }

  private modifyScreensBasedOnOutcome(nodeData: IWorkflowUXEditor): void {

    const hasPrimaryOutcome: boolean = !!Object.keys(nodeData.toLink)
                                               .find(key => key.indexOf(`"outcome":"${ UX_NODE_OUTCOME.primary }"`) !== -1);

    const hasSecondaryOutcome: boolean = !!Object.keys(nodeData.toLink)
                                                 .find(key => key.indexOf(`"outcome":"${ UX_NODE_OUTCOME.secondary }"`) !== -1);

    nodeData.fields.screens.forEach((screen: IScreenFieldEditItem) => {
      // forms still need primary button so be able to save themselves
      const isForm: boolean = screen.screenItemType === WorkflowUXScreenItemType.differentForm
        || screen.screenItemType === WorkflowUXScreenItemType.objectDefaultForm;

      if (!hasPrimaryOutcome && !isForm) {
        screen.primaryButtonLabel = null;
      }

      screen.hasSecondaryConnection = hasSecondaryOutcome;

      // if action buttons exist, but are not used, sets them to null
      if (!screen.actionButtonLabels) { return; }

      const linkTypes: ILinkType[] = NodeEditorLightUtils.getWorkflowUXConnectionTypesForScreen(screen, false);
      linkTypes.forEach(linkType => {
        const outcome = Object.keys(nodeData.toLink).find(key => key.indexOf(`"outcome":"${ linkType.value }"`) !== -1);
        if (!outcome) {
          const indexOfOutcome: number = screen.actionButtonLabels.indexOf(linkType.name);
          if (indexOfOutcome !== -1) {
            screen.actionButtonLabels.splice(indexOfOutcome, 1);
          }
        }
      });
    });
  }

  private isValid(nodeData: IWorkflowUXEditor): boolean {
    return nodeData.fields.contextObjectType
      && nodeData.fields.screens
      && !!nodeData.fields.screens.length
      && !!nodeData.fields.contextObjectSelectedPropertyKey;
  }

  private getUXObject(
    screens: IScreenFieldEditItem[],
    typeTip: Tip, contextObjectSelectedPropertyKey: string,
    isGoverned = true,
    forceCreation = false
  ): Formula {
    return Stringify({
      name: 'ARRAY',
      args: screens.map(o => ({
        name: 'JSON_BUILD',
        args: [
          JSON.stringify({
            type: 'object',
            property: {
              actors: {
                type: 'stringArray',
                formula: Stringify({
                  name: 'ARRAY',
                  args: (o.selectedActorKeys || []).map(key => ({ name: 'VAR', args: [key] }))
                })
              },
              screen: {
                type: 'string',
                value: o.screenItemType
              },
              objectTip: {
                type: 'string',
                formula: Stringify({ name: 'VAR', args: [contextObjectSelectedPropertyKey] }),
                noPropertyWhenEmpty: true
              },
              isGoverned: {
                type: 'boolean',
                value: isGoverned
              },
              forceCreation: {
                type: 'boolean',
                value: forceCreation
              },
              typeTip: {
                type: 'string',
                value: typeTip
              },
              form: {
                type: 'string',
                value: o.formItemTip
              },
              sideSheetTitle: {
                type: 'string',
                formula: stringInterpolateReplaceFormula({ html: o.sideSheetTitle, substitutions: o.sideSheetTitleSubstitutions }),
              },
              primaryButtonLabel: {
                type: 'string',
                value: o.primaryButtonLabel
              },
              hasSecondaryConnection: {
                type: 'boolean',
                value: o.hasSecondaryConnection
              },
              actionButtonLabels: {
                type: 'stringArray',
                value: o.actionButtonLabels ? o.actionButtonLabels : null
              },
              actionButtonOutcomes: {
                type: 'stringArray',
                value: o.actionButtonLabels ? o.actionButtonLabels.map(name => camelCase(name)) : null
              },
              instructionsSubtitle: {
                type: 'string',
                formula: stringInterpolateReplaceFormula(
                  { html: o.instructionsSubtitle, substitutions: o.instructionsSubtitleSubstitutions }
                ),
              },
              instructionsTitle: {
                type: 'string',
                formula: stringInterpolateReplaceFormula({ html: o.instructionsTitle, substitutions: o.instructionsTitleSubstitutions }),
              },
              instructionsIntent: {
                type: 'string',
                value: o.instructionsIntent
              }
            }
          }),
          'root'
        ]
      }))
    });
  }
}
