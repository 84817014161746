var _a;
import { reverseOperator } from './operators';
import { OPERATOR_TYPE } from '../../../models/query';
import { Parser } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { isCurrentUserFormula } from '../../../util/current-user-formula';
import { isFormulaSpec } from '../../../util/is-formula-spec';
var ɵ0 = function (leftArg, rightArg) {
    var rightArgFormula = rightArg;
    if (!isFormulaSpec(rightArg) && isCurrentUserFormula(rightArg)) {
        rightArgFormula = Parser(rightArg);
    }
    // HAS_INTERSECT(
    //    ARRAY(
    //       FIELD("app/personnel:teams", ${ leftArg }),
    //       FIELD("app/personnel:users", ${ leftArg })
    //    ),
    //    ARRAY(
    //       ${ rightArg },
    //       REFERENCES("app/team:roles", ${ rightArg }),
    //       REFERENCES(
    //          "app/team:roles",
    //          REFERENCES("app/team-role:users", ${ rightArg })
    //       )
    //    )
    // )
    return {
        name: 'HAS_INTERSECT',
        args: [
            {
                name: 'ARRAY',
                args: [
                    {
                        name: 'FIELD',
                        args: [
                            'app/personnel:teams',
                            leftArg
                        ]
                    },
                    {
                        name: 'FIELD',
                        args: [
                            'app/personnel:users',
                            leftArg
                        ]
                    }
                ]
            },
            {
                name: 'ARRAY',
                args: [
                    rightArgFormula,
                    {
                        name: 'REFERENCES',
                        args: [
                            'app/team:roles',
                            rightArgFormula
                        ]
                    },
                    {
                        name: 'REFERENCES',
                        args: [
                            'app/team:roles',
                            {
                                name: 'REFERENCES',
                                args: [
                                    'app/team-role:users',
                                    rightArgFormula
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    };
};
var personnelContains = {
    opNo: 2,
    toFormula: ɵ0
};
var personnelNotContains = reverseOperator(personnelContains);
export var personnelOperators = {
    operators: (_a = {},
        _a[OPERATOR_TYPE.CONTAINS] = personnelContains,
        _a[OPERATOR_TYPE.NOT_CONTAINS] = personnelNotContains,
        _a),
    getParts: function (formula) {
        var currentPart = formula;
        var negative = false;
        if (currentPart.name === 'NOT') {
            currentPart = currentPart.args[0];
            negative = true;
        }
        if (currentPart.name === 'HAS_INTERSECT') {
            return {
                operator: negative ? OPERATOR_TYPE.NOT_CONTAINS : OPERATOR_TYPE.CONTAINS,
                leftArg: currentPart.args[0].args[0].args[1],
                rightArg: currentPart.args[1].args[0]
            };
        }
        throw new Error('Unknown operators: ' + currentPart.name);
    }
};
export { ɵ0 };
