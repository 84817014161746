import { Tip, Sid } from '../../models/types';
import { clone } from 'lodash';

export class AccessDeniedError extends Error {
  public tip: Tip;
  public branches: string[];
  public sid: string;
  public message: string;

  constructor(sid: Sid);
  constructor(tip: Tip, branches: string[]);
  constructor(enoTipOrSid: Tip | Sid, branches?: string[]) {
    super();

    this.message = branches === undefined ? 'Access denied on sid ' + enoTipOrSid :
      'Access denied on tip and branch ' + enoTipOrSid + ', ' + branches.toString();

    Object.setPrototypeOf(this, AccessDeniedError.prototype);

    this.name = 'AccessDeniedError';

    if (branches !== undefined) {
      this.tip = enoTipOrSid;
      this.branches = clone(branches);

      return;
    }

    this.sid = enoTipOrSid;
  }
}
