import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { first, takeUntil } from 'rxjs/operators';

import { LoadingState, FormColors, FormIcons } from '../../../../shared/constants';
import { ListDesignerService } from '../../list-designer.service';
import { ToastService } from '../../../../shell/services/toast.service';
import { SideSheetService } from '../../../../side-sheet/side-sheet.service';
import { IListItem, IList } from '../../../../models/list';
import { NogginValidators } from '../../../../form/noggin-form-validators';
import { AlphanumericConverterService } from '../../../../data/alphanumeric-converter/alphanumeric-converter.service';
import { Subject } from 'rxjs';
import { Tip } from '../../../../data/models/types';
import { ModuleValidationError } from '../../../../data/errors/client/ModuleValidationError';

@Component({
  selector: 'app-list-item-create-side-sheet',
  templateUrl: './list-item-create-side-sheet.component.html',
  styleUrls: ['./list-item-create-side-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListItemCreateSideSheetComponent implements OnInit, OnDestroy {
  moduleTip: Tip;
  list: IList;
  parentListItem: IListItem;

  colors = FormColors;
  icons = FormIcons;

  loadingState: LoadingState = LoadingState.loaded;
  loadingStates = LoadingState;
  errorMessage: string;

  listItemForm: FormGroup = new FormGroup({
    label: new FormControl('', Validators.required),
    value: new FormControl(null, [Validators.required, NogginValidators.alphanumericValidator]),
    icon: new FormControl(null),
    color: new FormControl(null)
  });

  private unsubscribe$ = new Subject();

  constructor(
    private listService: ListDesignerService,
    private toastService: ToastService,
    private sideSheetService: SideSheetService
  ) {}

  ngOnInit() {
    this.listItemForm.controls.label.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(_ => {
        AlphanumericConverterService
          .applyAlphanumericValueToControl(this.listItemForm.controls.label, this.listItemForm.controls.value);
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  createListItem() {
    const newListItem: IListItem = {
      label: this.listItemForm.value.label,
      value: this.listItemForm.value.value,
      icon: this.listItemForm.value.icon,
      color: this.listItemForm.value.color
    };

    switch (true) {
      case !!this.parentListItem && !!this.parentListItem.items && !!this.parentListItem.items.length:
        this.parentListItem.items.push(newListItem);
        break;
      case !!this.parentListItem:
        this.parentListItem.items = [newListItem];
        break;
      default:
        this.list.items && this.list.items.length
          ? this.list.items.push(newListItem)
          : this.list.items = [newListItem];
        break;
    }

    this.loadingState = LoadingState.inProgress;
    this.listService.updateList(this.list, this.moduleTip).pipe(
      first()
    ).subscribe(
      () => {
        this.toastService.showSuccessToast('List item successfully created');
        this.sideSheetService.pop();
      },
      (error) => {
        error instanceof ModuleValidationError
        ? this.toastService.showErrorToast(error.message)
        : this.errorMessage = 'Unable to create list item at this time';
        this.loadingState = LoadingState.failed;
      }
    );
  }
}
