import { InjectionToken, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ShellModule } from '../shell/shell.module';
import { SharedModule } from '../shared/shared.module';
import { SignInComponent } from './sign-in/sign-in.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SignedOutComponent } from './signed-out/signed-out.component';
import { AcceptInviteComponent } from './accept-invite/accept-invite.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AcceptVerificationComponent } from './accept-verification/accept-verification.component';
import { AuthChromeComponent } from './auth-chrome/auth-chrome.component';
import { SSOAuthComponent } from './sso-auth/sso-auth.component';
import { INACTIVITY_PROVIDER } from './inactivity-logout/inactivity-countdown-provider';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ShellModule,
    SharedModule
  ],
  declarations: [
    SignInComponent,
    ForgotPasswordComponent,
    SignedOutComponent,
    AcceptInviteComponent,
    ResetPasswordComponent,
    AcceptVerificationComponent,
    AuthChromeComponent,
    SSOAuthComponent,
  ],
  providers: [
    INACTIVITY_PROVIDER
  ],
})
export class AuthModule { }
