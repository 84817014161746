export enum AuthRegisterErrors {
  userExists = 'error/message/auth/user-exists',
  decodeFailed = 'error/message/auth/decode-failed',
  expiredToken = 'error/message/auth/expired-token',
  expiredCredentials = 'error/message/auth/expired-credential',
  invalidCredentials = 'error/message/auth/invalid-credential',
  invalidPayload = 'error/message/auth/invalid-payload',
  invalidToken = 'error/message/auth/invalid-token',
  weakPassword = 'error/message/auth/weak-password'
}
