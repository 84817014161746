import { NgModule } from '@angular/core';
import { AvatarViewComponent } from './avatar-view.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    AvatarViewComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AvatarViewComponent
  ]
})
export class AvatarViewModule { }
