import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { get } from 'lodash';

import { Tip } from '../data/models/types';
import { IProcessResponse, ProcessService } from '../data/process.service';
import { ISessionInfo, SessionManagerService } from '../data/session-manager.service';

@Injectable({
  providedIn: 'root'
})
export class ActivateGuard implements CanActivate {
  constructor(
    private router: Router,
    private processService: ProcessService,
    private sessionManagerService: SessionManagerService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const email: string = route.queryParams.email;

    return this.processService.start('eim/process/auth/check-user-status', { Email: [email] }).pipe(
      first((processResponse: IProcessResponse) => processResponse.finished),
      switchMap((processResponse: IProcessResponse) => {
        const userTip: Tip = get(processResponse, ['vars', 'User', '0']);
        const userStatus: string = get(processResponse, ['vars', 'User status', '0']);

        if (userStatus !== 'active') {
          return of(true);
        }

        return this.sessionManagerService.getSessionInfo$().pipe(
          first(Boolean),
          map((sessionInfo: ISessionInfo) => {
            if (sessionInfo.user === userTip) {
              this.router.navigate(['/app', 's']);
            } else {
              this.router.navigate(['/signin'], {
                queryParams: { message: 'Your account has already been activated' },
                skipLocationChange: true
              });
            }

            return false;
          })
        );
      })
    );
  }
}
