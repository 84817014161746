import { IObject } from '../data/models/object';
import { Tip } from '../data/models/types';
import { IQueryGenericResult } from '../data/query.service';
import { DataTypes, IObjectScheme } from '../data/models/scheme';

// ----------
// SUPER-TYPE
// ----------

export interface IContactType {
  $tip: Tip;
  label: string;
}

export interface IContact extends IObject {
  name: string;
  avatar?: Tip;
  email?: string;
  phone?: string;
}

export interface IContactQueryResult extends IQueryGenericResult {
  name: string;
  avatar?: Tip;
  email?: string;
  phone?: string;
  isUser?: string;
}

export const contactScheme: IObjectScheme = {
  'app/super-type/contact:avatar': { name: 'avatarTip' , type: DataTypes.string },
  'app/super-type/contact:email': { type: DataTypes.email },
  'app/super-type/contact:phone': { type: DataTypes.phone },
  'app/super-type/contact:address': { type: DataTypes.string}
};
