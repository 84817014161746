import { Injectable } from '@angular/core';
import { combineLatest, forkJoin, Observable, of, throwError, interval as interval$ } from 'rxjs';
import { distinctUntilKeyChanged, first, map, switchMap, tap, mapTo, takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { find, compact, isEqual, concat as _concat } from 'lodash';
import dataConstants from '../constants';
import { Eno } from '../models/Eno';
import { Batch, Sid, Tip } from '../models/types';
import { EnoFactory } from '../EnoFactory';
import { DataTypes, IFieldScheme, IObjectScheme, ISchemeScan } from '../models/scheme';
import { EnoService } from '../eno.service';
import { scanScheme } from './scan-scheme';
import { CAST_VALUE_FUNCS, PARSE_VALUE_FUNCS } from './js-object-eno-field-conversion';
import { castValue, fieldTipToName } from './utils';
import { SessionManagerService } from '../session-manager.service';
import { EnsrvService } from '../ensrv.service';
import { OpPullService } from '../op-pull.service';
import { IObject } from '../models/object';

interface IObjectFactoryState {
  input: IObjectInput;
  scheme: IObjectScheme;
  enoFactory?: EnoFactory;
  fieldTip?: Tip;
  branch: Tip;
  securityPolicy: Tip;
  seen: IObjectFactoryState[];
  referencedFactories?: { [key: string]: (IObjectFactoryState | Tip)[] };
  eno?: Eno;
  previousEno?: Eno;
}

interface IObjectInput extends IObject {
  [x: string]: any;
}

@Injectable({
  providedIn: 'root'
})
export class ObjectService {
  // Start listening for enos as they arrive from EnSrv
  constructor(
    private enoService: EnoService,
    private sessionManagerService: SessionManagerService,
    private enSrvService: EnsrvService,
    private opPullService: OpPullService
  ) { }

  /**
   * Scans an object scheme for the maximum nesting depth and the fields that should be followed
   */
  private scanScheme(
    scheme: IObjectScheme,
    maxDepth: number = 0,
    followFields: Array<string> = [],
    schemes: Array<IObjectScheme> = []
  ): ISchemeScan {
    return scanScheme(scheme, maxDepth, followFields, schemes);
  }

  /**
   * Converts a field tip in to a property name
   */
  fieldTipToName(fieldTip: string) {
    return fieldTipToName(fieldTip);
  }

  /**
   * Converts a field in to a value according to the given scheme
   */
  castValue(eno: Eno, fieldTip: string, fieldScheme: IFieldScheme) {
    return castValue(eno, fieldTip, fieldScheme);
  }

  /**
   * Converts a value in to field according to the given scheme
   */
  parseValue(value: any, fieldScheme: IFieldScheme) {
    return PARSE_VALUE_FUNCS[fieldScheme.type || DataTypes.string](value);
  }

  /**
   * Gets a nested object structure according to the given scheme given the root tip
   */
  getObject<T>(
    tip: Tip,
    scheme: IObjectScheme,
    branch: Tip = dataConstants.BRANCH_MASTER,
    seen: Array<Tip> = [],
    useCache?: boolean | null
  ): Observable<T> {
    if (!tip) {
      throw new Error('[ObjectService] Could not get object by tip. Object tip is not defined.');
    }

    if (seen.indexOf(tip) > -1) {
      throw new Error('[ObjectService] Circular reference detected: ' + tip);
    }
    seen.push(tip);
    const schemeScan = this.scanScheme(scheme);

    const _useCache = useCache !== undefined
      ? useCache // use explicit setting
      : (seen.length > 1 ? true : null);

    return this.enoService.readEno(
      tip,
      {
        branch,
        recursiveFields: schemeScan.followFields,
        recursiveDepth: schemeScan.maxDepth,
        useCache: _useCache
      }
    ).pipe(
      distinctUntilKeyChanged('sid'),
      switchMap(eno => this.mapEnoToScheme<T>(eno, scheme, branch, seen))
    );
  }

  /**
   * emits the eno initially and periodically pull the eno with if-not-sid, if a new version came, it emits the new eno.
   * this method does not support nested objectScheme, the scheme has to have no object or objectArray fields.
   * use it when you can't wait for the getObject to reflect the change via eno watch or as a debugging tool.
   * beware to NOT use this method heavily as this is a consuming operation.
   * the difference between this method ant the getObject:
   * 1) getObject will register a op/pull watch to the server, server notifies changes via pub-sub causing front-end to pull again
   * 2) this method will NOT register a watch but rather hard polling the object.
   */
  pollObject<T>({ tip, branch = dataConstants.BRANCH_MASTER, scheme, interval, cancel$ }: {
    tip: Tip,
    branch?: Tip,
    scheme: IObjectScheme,
    interval: number,
    cancel$: Observable<any>,
  }): Observable<T> {
    if (Object.keys(scheme).some(key => scheme[key].type === DataTypes.object || scheme[key].type === DataTypes.objectArray)) {
      throw new Error('[Poll Service] Has to be a scheme without object or objectArray fields!');
    }

    const ifNotSid: Sid[] = [];

    const pull$ = () => {
      const opPullEnoToSend: Eno = this.opPullService.createOpPull({
        tip: [tip],
        branch: [branch],
        watch: false,
        recursiveDepth: 0,
        recursiveField: [],
        ifNotSid
      });

      return this.enSrvService.send([opPullEnoToSend]).pipe(first(), map(([eno]) => eno));
    };

    const checkError: (eno: Eno) => Eno = eno => {
      if (eno.getType() === 'error') {
        throw new Error('[Poll Service] Server returns an error!');
      }
      return eno;
    };

    const distinctChecker = (prev, next) => {
      // if next doesn't exist, this means the if-not-sid options is working.
      return !next || !next.sid || prev.sid === next.sid;
    };

    return interval$(interval).pipe(
      takeUntil(cancel$),
      switchMap(pull$),
      distinctUntilChanged(distinctChecker),
      map(checkError),
      tap(eno => ifNotSid.push(eno.sid)),
      switchMap(eno => this.mapEnoToScheme<T>(eno, scheme, branch))
    );
  }

  private mapEnoToScheme<T>(
    eno: Eno,
    scheme: IObjectScheme,
    branch: Tip = dataConstants.BRANCH_MASTER,
    seen: Array<Tip> = []
  ): Observable<T> {
    const fields = [
      of({ name: '$tip', value: eno.tip }),
      of({ name: '$security', value: eno.source.security }),
      of({ name: '$branch', value: eno.getBranch() }),
      of({ name: '$type', value: eno.getType() }),
      of({ name: '$sid', value: eno.sid })
    ].concat(
      eno.source.field.filter(field =>
        eno.getFieldValues(field.tip).length > 0  // Skip empty fields
      ).map(field => {
        const fieldScheme = scheme[field.tip] || {};
        const name = fieldScheme.name || this.fieldTipToName(field.tip);
        const values = eno.getFieldValues(field.tip);

        if (fieldScheme.type === DataTypes.object) {
          return this.getObject(values[0], fieldScheme.scheme, branch, seen.slice()).pipe(
            map((subObject: T) => {
              return { name, value: subObject };
            })
          );
        }

        if (fieldScheme.type === DataTypes.objectArray) {
          return combineLatest(
            values.map(valueTip => this.getObject(valueTip, fieldScheme.scheme, branch, seen.slice()))
          ).pipe(
            map(subObjects => {
              return { name, value: subObjects };
            })
          );
        }

        if (fieldScheme.type === DataTypes.i18nObject) {
          return of({ name, value: eno.getFieldRawI18n(field.tip) });
        }

        return of({ name, value: this.castValue(eno, field.tip, fieldScheme) });
      })
    );
    return combineLatest(fields).pipe(
      map((props: Array<{ name: string, value: any }>) => {
        return props.reduce((out, prop) => {
          out[prop.name] = prop.value;
          return out;
        }, {} as T);
      })
    );
  }

  // @deprecated
  castObject<T>(object: { [fieldTip: string]: string }, scheme: IObjectScheme): Observable<T> {
    return combineLatest(Object.keys(object).map(
      (fieldTip: string) => {
        const fieldScheme = scheme[fieldTip] || {};
        const name = this.fieldTipToName(fieldTip);
        const value = object[fieldTip];

        if (name === 'tip' || name === 'security') {
          return of({ name: `$${name}`, value });
        }

        if (fieldScheme.type === DataTypes.object) {
          return this.getObject(value, fieldScheme.scheme).pipe(
            map(subObject => {
              return { name, value: subObject };
            })
          );
        }

        if (fieldScheme.type === DataTypes.objectArray) {
          const arrValue = typeof value === 'string' ? value.split(',') : value;
          return combineLatest(
            arrValue.map((tip: Tip) => this.getObject(tip, fieldScheme.scheme))
          ).pipe(
            map(subObject => {
              return { name, value: subObject };
            })
          );
        }
        return of({ name, value: CAST_VALUE_FUNCS[fieldScheme.type || DataTypes.string]([value]) });
      }
    )).pipe(
      map((props: { name: string, value: any }[]) => {
        return props.reduce((out, prop) => {
          out[prop.name] = prop.value;

          return out;
        }, {} as T);
      })
    );
  }

  // Retrieve the default security policy for the user
  defaultSecurityPolicy$(): Observable<Tip> {
    return this.sessionManagerService.getSessionInfo$().pipe(
      switchMap(sessionInfo => this.enoService.readEno(sessionInfo.profile, { useCache: true })),
      map(profileEno => profileEno.getFieldStringValue('app/profile:default-policy'))
    );
  }

  /**
   * Writes the given nested object structure according to the given scheme.
   *
   * If an object has a $tip property, then it will update the object.
   * If it doesn't, then it will create a new object. In this case $type should be given together
   * security should be given either by securityPolicy argument or $security in the obj
   *
   * If an object has a $security field, then it will set the security policy.
   * $security field is inherited down, but it can be overwritten at any point.
   *
   * $branch works differently from $security. $branch is not used to generate enos.
   * By default, branch is branch/master and inherited down.
   * If a branch is given as an argument, then all the nested objects will be created in the given branch
   */
  setObject(
    input: IObjectInput,    // The object data to save
    scheme: IObjectScheme,  // The scheme that maps the object data to an eno
    branch?: Tip,           // The branch to save it in
    securityPolicy?: Tip    // The security policy to save with
  ): Observable<Batch> {
    // 1. Convert the nested object in to factories
    return this.objectToEnoFactory$({ input, scheme, branch, securityPolicy, seen: [] }).pipe(
      // 2. Convert the factories in to batches
      map(factoryState => this.factoryStateToBatch(factoryState)),
      // 3. Send the batch
      switchMap(batches => this.writeBatches(batches))
    );
  }

  // The same as setObject but sets multiple at a time in the same batch
  setObjects(
    inputs: IObjectInput[],
    schemes: IObjectScheme[],
    branches?: Tip[],
    securityPolicies?: Tip[]
  ): Observable<Batch> {
    const batches$$ = inputs.map((input, i) => {
      const branch = branches ? branches[i] : null;
      const securityPolicy = securityPolicies ? securityPolicies[i] : null;
      const scheme = schemes[i] || schemes[0];
      return this.objectToEnoFactory$({ input, scheme, branch, securityPolicy, seen: [] }).pipe(
        map(factoryState => this.factoryStateToBatch(factoryState))
      );
    });

    return forkJoin(batches$$).pipe(
      map(batches => ({
        writeBatch: _concat([], ...batches.map(batch => batch.writeBatch)),
        deleteBatch: _concat([], ...batches.map(batch => batch.deleteBatch))
      })),
      switchMap(batches => this.writeBatches(batches))
    );
  }

  private writeBatches(batches: { writeBatch: Batch, deleteBatch: Batch }): Observable<Batch> {
    return this.enoService.writeEnos(batches.writeBatch).pipe(
      switchMap(writeResponse => {
        if (batches.deleteBatch.length > 0) {
          return this.enoService.writeEnos(batches.deleteBatch).pipe(
            map(deleteResponse => _concat(writeResponse, deleteResponse))
          );
        }
        return of(writeResponse);
      })
    );
  }

  factoryStateToBatch(factoryState: IObjectFactoryState): { writeBatch: Batch, deleteBatch: Batch } {
    const batches = this.factoryStateToBatchCallbacks(factoryState);
    return {
      writeBatch: compact(batches.writeCallbacks.map(call => call())),
      deleteBatch: compact(batches.deleteCallbacks.map(call => call()))
    };
  }

  private factoryStateToBatchCallbacks(
    factoryState: IObjectFactoryState,
    seen: IObjectFactoryState[] = []
  ): {
    writeCallbacks: Array<() => Eno>,
    deleteCallbacks: Array<() => Eno>
  } {
    let batchCallbacks: Array<() => Eno> = [];
    const originalReferencedFactories: { [key: string]: (IObjectFactoryState | Tip)[] } = {};
    const actuallyReferencedTips: { [key: string]: Tip[] } = {};
    const objectFieldTips = Object.keys(factoryState.referencedFactories || {});
    let deletedCallbacks: Array<() => Eno> = [];

    seen.push(factoryState);

    // For each object field, recursively make enos, replacing with placeholders where circular references are detected
    objectFieldTips.forEach(fieldTip => {
      originalReferencedFactories[fieldTip] = factoryState.referencedFactories[fieldTip].slice();
      actuallyReferencedTips[fieldTip] = compact(
        factoryState.referencedFactories[fieldTip].map(
          referencedFactory => {
            if (typeof referencedFactory === 'string') {
              return referencedFactory;
            }
            if (!referencedFactory.eno && seen.indexOf(referencedFactory) > -1) {
              // We've already seen this factory - circular reference detected
              const placeholderEno = this.makePlaceholderEno(factoryState, fieldTip);
              if (placeholderEno) {
                batchCallbacks.push(() => placeholderEno);
                deletedCallbacks.push(() => this.makePlaceholderDelete(placeholderEno));
                return placeholderEno.tip;
              }
              return null;
            }
            if (!referencedFactory.eno) {
              const refCallbacks = this.factoryStateToBatchCallbacks(referencedFactory, seen);
              batchCallbacks = _concat(batchCallbacks, refCallbacks.writeCallbacks);
              deletedCallbacks = _concat(deletedCallbacks, refCallbacks.deleteCallbacks);
            }
            return referencedFactory.eno.tip;
          }
        )
      );
      factoryState.enoFactory.setField(fieldTip, actuallyReferencedTips[fieldTip]);
    });

    // Make the actual eno, with placeholders
    factoryState.eno = factoryState.enoFactory.makeEno();
    if (!factoryState.previousEno || factoryState.previousEno.isContentDiff(factoryState.eno)) {
      batchCallbacks.push(() => factoryState.eno);
    }

    // Add in the patch to remove the placeholder references
    batchCallbacks.push(() => this.makePlaceholderPatch(factoryState, originalReferencedFactories, actuallyReferencedTips));

    return { writeCallbacks: batchCallbacks, deleteCallbacks: deletedCallbacks };
  }

  // Makes a placeholder eno for temporary use
  private makePlaceholderEno(factoryState: IObjectFactoryState, fieldTip: Tip): Eno | null {
    const fieldScheme = factoryState.scheme[fieldTip];
    if (!fieldScheme) {
      return null;
    }
    const placeholder = fieldScheme.circularPlaceholder;
    if (!placeholder) {
      return null;
    }
    const placeholderFactory = new EnoFactory();
    placeholderFactory.setType(placeholder.$type);
    placeholderFactory.setSecurity(factoryState.securityPolicy);
    placeholderFactory.setBranch(factoryState.branch);
    return placeholderFactory.makeEno();
  }

  // Make the patch eno that removes the references to temporary placeholders, and restores the correct references
  private makePlaceholderPatch(
    factoryState: IObjectFactoryState,
    originalReferencedFactories: { [key: string]: (IObjectFactoryState | Tip)[] },
    actuallyReferencedTips: { [key: string]: Tip[] }
  ): Eno | null {
    const objectFieldTips = Object.keys(factoryState.referencedFactories || {});
    let doPatch = false;
    const patchFactory = new EnoFactory();
    patchFactory.setProtoToPatch(factoryState.eno);
    objectFieldTips.forEach(fieldTip => {
      const originalReferencedTips = originalReferencedFactories[fieldTip].map(factory => typeof factory === 'string' ? factory : factory.eno.tip);
      if (!isEqual(originalReferencedTips, actuallyReferencedTips[fieldTip])) {
        patchFactory.setField(fieldTip, originalReferencedTips);
        doPatch = true;
      }
    });
    if (doPatch) {
      return patchFactory.makeEno();
    }
    return null;
  }

  // Makes the delete eno that actually removes the temporary placeholder
  private makePlaceholderDelete(placeholderEno: Eno): Eno {
    const deleteFactory = new EnoFactory();
    deleteFactory.setProtoToPatch(placeholderEno);
    deleteFactory.setDeleted(true);
    return deleteFactory.makeEno();
  }

  // Convert an eno factory template and object to a proper factory template
  objectToEnoFactory$(factoryState: IObjectFactoryState): Observable<IObjectFactoryState> {
    // Just return if we've already seen this object
    const seen = find(factoryState.seen, i => i.input === factoryState.input);
    if (seen) {
      return of(seen);
    }

    // Make a clean factory state based on our existing one
    factoryState = { ...factoryState, enoFactory: null, eno: null, referencedFactories: {} };

    // Mark this object as seen
    factoryState.seen.push(factoryState);

    return this.makeEnoFactoryFromObject$(factoryState).pipe(
      map(enoFactory => this.setBranch(factoryState, enoFactory)),
      switchMap(enoFactory => this.setSecurityPolicy(factoryState, enoFactory)),
      switchMap(enoFactory => this.setFields(factoryState, enoFactory)),
      mapTo(factoryState)
    );
  }

  private setFields(factoryState: IObjectFactoryState, enoFactory: EnoFactory): Observable<EnoFactory> {
    return forkJoin(
      Object.keys(factoryState.scheme).map(fieldTip => this.setEnoFactoryField$({ ...factoryState, fieldTip }))
    ).pipe(
      mapTo(enoFactory)
    );
  }

  private setBranch(factoryState: IObjectFactoryState, enoFactory: EnoFactory): EnoFactory {
    // Set the branch if provided
    if (factoryState.input.$branch) {
      enoFactory.setBranch(factoryState.input.$branch);
    } else if (factoryState.branch) {
      enoFactory.setBranch(factoryState.branch);
    } else if (!factoryState.input.$tip) {
      enoFactory.setBranch('branch/master');
    }

    return enoFactory;
  }

  private setSecurityPolicy(factoryState: IObjectFactoryState, enoFactory: EnoFactory): Observable<EnoFactory> {
    // Set the security if provided
    if (factoryState.input.$security) {
      // new security from the input object by $security
      enoFactory.setSecurity(factoryState.input.$security);
    } else if (factoryState.securityPolicy) {
      // new security from setObject securityPolicy argument
      enoFactory.setSecurity(factoryState.securityPolicy);
    } else if (!factoryState.input.$tip) {
      // new eno uses the default security policy from the profile
      return this.defaultSecurityPolicy$().pipe(
        map(securityPolicyTip => {
          // Set the security policy if there is one
          if (!securityPolicyTip) {
            throw new Error('[ObjectService] We don\'t have a default policy in our profile');
          }
          enoFactory.setSecurity(securityPolicyTip);

          return enoFactory;
        })
      );
    }

    return of(enoFactory);
  }

  // Creates a new ENO factory for an object considering if it is a new object or an update
  private makeEnoFactoryFromObject$(factoryState: IObjectFactoryState): Observable<EnoFactory> {
    const enoFactory = new EnoFactory();
    factoryState.enoFactory = enoFactory;
    if (factoryState.input.$tip) {
      return this.enoService.readEno(factoryState.input.$tip, { useCache: true }).pipe(
        first(),
        tap(eno => factoryState.previousEno = eno),
        map(eno => enoFactory.setProtoToPatch(eno)),
        mapTo(enoFactory)
      );
    }
    if (factoryState.input.$type) {
      enoFactory.setType(factoryState.input.$type);
      return of(enoFactory);
    }
    return throwError(new Error('[ObjectService] All new objects must have a $type'));
  }

  private setEnoFactoryFieldSubObject$(factoryState: IObjectFactoryState): Observable<any> {
    const fieldScheme = factoryState.scheme[factoryState.fieldTip];
    const name = fieldScheme.name || this.fieldTipToName(factoryState.fieldTip);
    const originalValue = factoryState.input[name];

    if (typeof originalValue === 'string') {
      factoryState.enoFactory.setField(factoryState.fieldTip, [originalValue]);
      return of(null);
    }

    if (fieldScheme.mutable) {
      originalValue.$security = originalValue.$security || factoryState.input.$security;
      return this.objectToEnoFactory$({ ...factoryState, input: originalValue, scheme: fieldScheme.scheme }).pipe(
        tap(subEnoFactory => factoryState.referencedFactories[factoryState.fieldTip] = [subEnoFactory])
      );
    }

    if (originalValue.$tip) {
      factoryState.enoFactory.setField(factoryState.fieldTip, [originalValue.$tip]);
      return of(null);
    }

    return throwError(new Error('Attempted to create a new object in a immmutable sub-object: ' + factoryState.fieldTip));
  }

  private setEnoFactoryFieldSubObjectArray$(factoryState: IObjectFactoryState): Observable<any> {
    const fieldScheme = factoryState.scheme[factoryState.fieldTip];
    const name = fieldScheme.name || this.fieldTipToName(factoryState.fieldTip);
    const originalValue = factoryState.input[name];

    if (fieldScheme.mutable) {
      return forkJoin(
        originalValue.map(subValue => {
          if (typeof subValue === 'string') {
            return subValue;
          }
          subValue.$security = subValue.$security || factoryState.input.$security;
          return this.objectToEnoFactory$({ ...factoryState, input: subValue, scheme: fieldScheme.scheme });
        })
      ).pipe(
        tap(subEnoFactories => factoryState.referencedFactories[factoryState.fieldTip] = subEnoFactories)
      );
    }

    if (originalValue.filter(sub => (typeof sub !== 'string' && !sub.$tip)).length > 0) {
      return throwError(new Error('Attempted to create a new object in a immutable sub-object-array: ' + factoryState.fieldTip));
    }

    factoryState.enoFactory.setField(factoryState.fieldTip, originalValue.map(sub => typeof sub === 'string' ? sub : sub.$tip));
    return of(null);
  }

  // Set field data on an eno factory
  private setEnoFactoryField$(factoryState: IObjectFactoryState): Observable<any> {
    const fieldScheme = factoryState.scheme[factoryState.fieldTip];
    const name = fieldScheme.name || this.fieldTipToName(factoryState.fieldTip);
    const originalValue = factoryState.input[name];

    // Field value is not given
    if (!factoryState.input.hasOwnProperty(name)) {
      return of(true);
    }

    // Specifically name property is given but null or undefined. Or is it an empty array
    if (originalValue === null || originalValue === undefined || (Array.isArray(originalValue) && originalValue.length === 0)) {
      factoryState.enoFactory.setField(factoryState.fieldTip, []);
      return of(null);
    }

    // Give a number field an empty value, [], if the originalValue is ''.
    if (fieldScheme.type === DataTypes.number && originalValue === '') {
      factoryState.enoFactory.setField(factoryState.fieldTip, []);
      return of(null);
    }

    if (fieldScheme.type === DataTypes.i18nObject) {
      factoryState.enoFactory.setField({ tip: factoryState.fieldTip, i18n: originalValue });

      return of(null);
    }

    // The field is object field. May need recursive call
    if (fieldScheme.type === DataTypes.object) {
      return this.setEnoFactoryFieldSubObject$(factoryState);
    }

    // The field is object field but potentially multiple values. May need recursive call
    if (fieldScheme.type === DataTypes.objectArray) {
      return this.setEnoFactoryFieldSubObjectArray$(factoryState);
    }

    // Depending on the given scheme datatype, parse value
    const parsedValue = this.parseValue(originalValue, fieldScheme);

    // Datatype is i18n. need to set as I18n.
    if (fieldScheme.type === DataTypes.i18n || fieldScheme.type === DataTypes.i18nArray) {
      factoryState.enoFactory.setI18nValue(factoryState.fieldTip, parsedValue);
      return of(null);
    }

    // Datatype is a formula
    if (fieldScheme.type === DataTypes.formula || fieldScheme.type === DataTypes.formulaArray) {
      factoryState.enoFactory.setFieldFormula(factoryState.fieldTip, parsedValue);
      return of(null);
    }

    // All other cases. Just set the given value
    factoryState.enoFactory.setField(factoryState.fieldTip, parsedValue);
    return of(null);
  }

  // Delete a single object
  deleteObject(input: IObjectInput): Observable<boolean> {
    return this.deleteObjects([input]);
  }

  // Delete multiple objects
  deleteObjects(inputs: IObjectInput[]): Observable<boolean> {
    return this.enoService.deleteEnos(
      inputs.map(input => input.$tip)
    ).pipe(
      mapTo(true)
    );
  }
}
