import { Injectable } from '@angular/core';
import { IProcessResponse, ProcessService } from '../../data/process.service';
import { first, flatMap, tap } from 'rxjs/operators';
import { SessionManagerService } from '../../data/session-manager.service';
import { get } from 'lodash';
import { of, throwError } from 'rxjs';
import { decodeJWT } from '../decode-jwt';
import { JwtHelperService } from '@auth0/angular-jwt/src/jwthelper.service';

@Injectable({
  providedIn: 'root'
})
export class SSOAuthService {

  processTips = {
    saml: 'eim/process/sso/saml/saml-token-auth',
    support: 'eim/process/sso/support/support-token-auth'
  };

  constructor(
    private processService: ProcessService,
    private sessionManager: SessionManagerService,
  ) { }

  loginWithToken(token: string, issuingService: 'saml' | 'support' = 'saml') {
    const vars = {
      token: [token],
    };

    const lastProfile = this.getLastProfile(token);
    if (lastProfile) {
      vars['Profile tip'] = lastProfile;
    }

    return this.processService
      .start(this.processTips[issuingService], vars)
      .pipe(
        first(),
        flatMap(extractSessionThrowIfInvalid),
        tap(({ sessionToken }) => {
          this.sessionManager.updateSessionInfoAndRedirectToOriginalRequestedDestination(sessionToken);
        })
      );
  }

  getLastProfile(token: string) {
    try {
      const jwtHelperService = new JwtHelperService();
      const payload = jwtHelperService.decodeToken(token);
      const email = get(payload, 'email', '');
      return this.sessionManager.getLastProfileWithEmail(email);
    } catch (ex) {
      return '';
    }
  }

}

function extractSessionThrowIfInvalid(result: IProcessResponse) {
  const status = get(result, 'vars.Status[0]', null);
  if (status === 'failed') {
    return throwError(new Error('Failed to authenticate with token'));
  }
  return of({
    sessionToken: get(result, 'vars.JWT token[0]', null),
    status
  });
}
