import * as tslib_1 from "tslib";
import { CompositeNodeConverter } from './CompositeNodeConverter';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import { Parser, Stringify } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { head, isEmpty, pullAt } from 'lodash';
import { FILE_NODE_LOCATION_TYPE, saveFileLocationProcessTips, } from '../node-editors/shared/file-location/file-location-types';
import { addContextFormulaWrapper, buildRecursiveContextFormula, getMostInnerContextPartsFromContextFormula } from '../../../util/context-formula-wrapper';
import { createConnection, createFormulaFromStringInterpolation, createSetVarNode, createSubProcessNode, createSwitchNode } from './utils/create-node-utils';
import * as i0 from "@angular/core";
var NS = 'SaveFileNodeConverterService';
var SaveFileNodeConverterService = /** @class */ (function (_super) {
    tslib_1.__extends(SaveFileNodeConverterService, _super);
    function SaveFileNodeConverterService() {
        var _this = _super.call(this) || this;
        _this.type = 'processnode/logic/subprocess';
        _this.processNodeType = 'processnodetype/logic/subprocess';
        return _this;
    }
    SaveFileNodeConverterService.prototype.convert = function (nodeData) {
        if (!this.isValid(nodeData)) {
            throw new InvalidWorkflowNodeDataError("Please correct invalid data on save-file node [" + nodeData.fields.title + "]", nodeData);
        }
        var _a = nodeData.fields, locationType = _a.locationType, variableFormula = _a.variableFormula, locationFormula = _a.locationFormula, overwrite = _a.overwrite, locationTip = _a.locationTip, pathPrefix = _a.pathPrefix;
        var vars = {
            'File tips': variableFormula,
            Overwrite: overwrite ? 'true' : 'false',
            'Location tip': locationTip || 'ARRAY()',
            'Location type': locationType
        };
        switch (locationType) {
            case FILE_NODE_LOCATION_TYPE.DOCUMENT_FOLDER:
            case FILE_NODE_LOCATION_TYPE.OBJECT_ATTACHMENTS:
                vars.Location = locationFormula;
                break;
            case FILE_NODE_LOCATION_TYPE.FILE_UPLOAD_FIELD:
                var _b = this.getFieldTipAndObjectFormula(locationFormula), fieldTipFormula = _b.fieldTipFormula, objectFormula = _b.objectFormula;
                vars['Field tip'] = fieldTipFormula;
                vars.Location = objectFormula;
                break;
            case FILE_NODE_LOCATION_TYPE.INTEGRATION_BUCKET:
                vars['Path prefix'] = createFormulaFromStringInterpolation(pathPrefix);
                break;
        }
        var setVarStatusNode = createSetVarNode(vars, nodeData.tip);
        var subprocessNode = createSubProcessNode(saveFileLocationProcessTips[locationType], vars);
        var doneConnectionForSetVarNode = createConnection('done', subprocessNode);
        setVarStatusNode.connections.push(doneConnectionForSetVarNode);
        var switchStatusNode = createSwitchNode({ name: 'VAR', args: ['Status'] }, ['fileError']);
        var doneConnectionForSubprocessNode = createConnection('done', switchStatusNode);
        subprocessNode.connections.push(doneConnectionForSubprocessNode);
        var nodesAndLink = {
            nodes: [
                setVarStatusNode,
                subprocessNode,
                switchStatusNode
            ],
            links: [
                doneConnectionForSetVarNode,
                doneConnectionForSubprocessNode
            ]
        };
        this.populateOutgoingLinks(nodeData).forEach(function (link) {
            switchStatusNode.connections.push(link);
            nodesAndLink.links.push(link);
        });
        return nodesAndLink;
    };
    SaveFileNodeConverterService.prototype.isValid = function (nodeData) {
        return !isEmpty(nodeData.fields.title)
            && !isEmpty(nodeData.fields.variableFormula)
            && !isEmpty(nodeData.fields.locationType)
            && !((nodeData.fields.locationType === FILE_NODE_LOCATION_TYPE.DOCUMENT_FOLDER
                || nodeData.fields.locationType === FILE_NODE_LOCATION_TYPE.FILE_UPLOAD_FIELD
                || nodeData.fields.locationType === FILE_NODE_LOCATION_TYPE.OBJECT_ATTACHMENTS) && !nodeData.fields.locationFormula);
    };
    // CONTEXT(
    //    VAR("WorkflowVariableKey"),
    //    CONTEXT(
    //       CONTEXT(
    //          CONTEXT(
    //             FIELD("RelatedObjectField1"),
    //             FIELD("RelatedObjectField2")
    //          ),
    //          FIELD("FileField")
    //       ),
    //       TIP()
    //    )
    // )
    SaveFileNodeConverterService.prototype.getFieldTipAndObjectFormula = function (formula) {
        var workflowFormulas = getMostInnerContextPartsFromContextFormula(formula);
        var workflowVariableFormula = workflowFormulas.mostInnerContextFormula; // VAR("WorkflowVariableKey")
        var fieldFormulas = getMostInnerContextPartsFromContextFormula(head(workflowFormulas.outerFormulas));
        var fieldFormulaArray = tslib_1.__spread(fieldFormulas.outerFormulas, [fieldFormulas.mostInnerContextFormula]);
        var fieldFormula = head(pullAt(fieldFormulaArray, [1]));
        if (isEmpty(fieldFormula)) {
            throw new Error('[save-file-node-converter] Needs to select a related object field!');
        }
        var fieldFormulaSpec = Parser(fieldFormula);
        if (fieldFormulaSpec.name !== 'FIELD') {
            throw new Error('[save-file-node-converter] Not a field formula!');
        }
        fieldFormulaSpec.name = 'ARRAY';
        var fieldTipFormula = Stringify(fieldFormulaSpec);
        var builtFieldFormula = buildRecursiveContextFormula(fieldFormulaArray);
        var objectFormula = addContextFormulaWrapper(builtFieldFormula, workflowVariableFormula);
        return { fieldTipFormula: fieldTipFormula, objectFormula: objectFormula };
    };
    SaveFileNodeConverterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SaveFileNodeConverterService_Factory() { return new SaveFileNodeConverterService(); }, token: SaveFileNodeConverterService, providedIn: "root" });
    return SaveFileNodeConverterService;
}(CompositeNodeConverter));
export { SaveFileNodeConverterService };
