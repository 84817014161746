import { Injectable } from '@angular/core';
import { CompositeNodeConverter, INodeAndLink } from '../CompositeNodeConverter';
import dataConstants from '../../../../data/constants';
import { IPauseUntilNodeEditor } from '../../node-editors/pause-until-node-editor/pause-until-node-editor.component';
import { InvalidWorkflowNodeDataError } from '../InvalidWorkflowNodeDataError';
import { IProcessNodeNoOp } from '../../../../models/process';
import { Formula } from '../../../../data/models/types';
import { ITimeAndTimezone } from '../../node-editors/shared/workflow-date-and-time-options/workflow-date-and-time-options.component';
import { IWorkflowValueData } from '../../node-editors/shared/workflow-value-selector/workflow-value-side-sheet/workflow-value-side-sheet.component';
import { Stringify } from '../../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { FormulaService } from '../../../../data/formula.service';
import { createConnection, createNoOpNode } from '../utils/create-node-utils';

@Injectable({
  providedIn: 'root'
})
export class PauseUntilNodeConverterService extends CompositeNodeConverter {

  type = 'processnode/no-op';
  processNodeType = 'processnodetype/no-op';

  constructor(
      private formulaService: FormulaService
  ) {
    super();
  }

  public convert(nodeData: IPauseUntilNodeEditor): INodeAndLink {
    if (!this.isValid(nodeData)) {
      throw new InvalidWorkflowNodeDataError(`Please correct invalid data on pause node [${nodeData.fields.title}]`, nodeData);
    }

    const startNoopNode = createNoOpNode(nodeData.tip);

    const endNoopNode = createNoOpNode();

    const doneConnectionForStartNoop = createConnection('done', endNoopNode);
    doneConnectionForStartNoop.delaySec = this.createDelayFormula(nodeData);
    startNoopNode.connections.push(doneConnectionForStartNoop);


    // make the final collection of all nodes and links
    const nodesAndLink: INodeAndLink = {
      nodes: [
        startNoopNode,
        endNoopNode
      ],
      links: [
        doneConnectionForStartNoop
      ]
    };

    this.populateOutgoingLinks(nodeData).forEach(link => {
      endNoopNode.connections.push(link);
      nodesAndLink.links.push(link);
    });

    return nodesAndLink;
  }

  private createDelayFormula(nodeData: IPauseUntilNodeEditor): number | Formula {
    if (nodeData.fields.value.manualInput) {
      return this.createDelayFormulaForManualInput(nodeData.fields.value.manualInput);
    } else if (nodeData.fields.value.relative && nodeData.fields.value.relative !== '') {
      return this.createDelayFormulaForRelativeField(nodeData.fields.value.relative);
    } else if (nodeData.fields.value.fromObject && nodeData.fields.value.fromObject.formula) {
      return this.createDelayFormulaForObjectField(nodeData.fields.value.fromObject);
    }
    return 0;
  }

  createDelayFormulaForManualInput(input: ITimeAndTimezone) {
    return Stringify({
      name: 'IF',
      args: [
        {
          name: 'OP',
          args: [
            {
              name: 'DATE_DIFF',
              args: [
                {
                  name: 'SET_SECOND',
                  args: [
                    {
                      name: 'SET_MINUTE',
                      args: [
                        {
                          name: 'SET_HOUR',
                          args: [
                            {
                              name: 'FORMAT_DATETIME',
                              args: [
                                {
                                  name: 'NOW',
                                  args: []
                                },
                                'Y-m-d\\TH:i:sO',
                                input.timezone
                              ]
                            },
                            input.hours
                          ]
                        },
                        input.minutes
                      ]
                    },
                    '00'
                  ]
                },
                {
                  name: 'NOW',
                  args: []
                },
                'seconds'
              ]
            },
            '>=',
            0
          ]
        },
        {
          name: 'DATE_DIFF',
          args: [
            {
              name: 'SET_SECOND',
              args: [
                {
                  name: 'SET_MINUTE',
                  args: [
                    {
                      name: 'SET_HOUR',
                      args: [
                        {
                          name: 'FORMAT_DATETIME',
                          args: [
                            {
                              name: 'NOW',
                              args: []
                            },
                            'Y-m-d\\TH:i:sO',
                            input.timezone
                          ]
                        },
                        input.hours
                      ]
                    },
                    input.minutes
                  ]
                },
                '0'
              ]
            },
            {
              name: 'NOW',
              args: []
            },
            'seconds'
          ]
        },
        {
          name: 'DATE_DIFF',
          args: [
            {
              name: 'SET_SECOND',
              args: [
                {
                  name: 'SET_MINUTE',
                  args: [
                    {
                      name: 'SET_HOUR',
                      args: [
                        {
                          name: 'FORMAT_DATETIME',
                          args: [
                            {
                              name: 'DATE_ADD',
                              args: [
                                {
                                  name: 'NOW',
                                  args: []
                                },
                                1,
                                'day'
                              ]
                            },
                            'Y-m-d\\TH:i:sO',
                            input.timezone
                          ]
                        },
                        input.hours
                      ]
                    },
                    input.minutes
                  ]
                },
                '00'
              ]
            },
            {
              name: 'NOW',
              args: []
            },
            'seconds'
          ]
        },
      ]
    });
  }

  createDelayFormulaForRelativeField(input: string) {
   return Stringify({
      name: 'DATE_DIFF',
      args: [
        {
          name: 'DATE_ADD',
          args: [
            {
              name: 'NOW',
              args: []
            },
            input,
            'days'
          ]
        },
        {
          name: 'NOW',
          args: []
        },
        'seconds'
      ]
    });
  }

  createDelayFormulaForObjectField(input: IWorkflowValueData) {
    return Stringify({
      name: 'MAX',
      args: [
        {
          name: 'DATE_DIFF',
          args: [
            {
              name: 'FORMAT_DATETIME',
              args: [
                this.formulaService.parse(input.formula),
                'Y-m-d\\TH:i:sO'
              ]
            },
            {
              name: 'NOW',
              args: []
            },
            'seconds'
          ]
        },
        0
      ]
    });
  }

  private isValid(nodeData: IPauseUntilNodeEditor): boolean {
    const manualInputField = nodeData.fields.value.manualInput;
    const manualInputFieldsValidity = manualInputField && (manualInputField.hours != null)
        && manualInputField.minutes != null  && manualInputField.timezone != null;

    const relativeFieldsValidity = nodeData.fields.value.relative && nodeData.fields.value.relative !== '';

    const fromObjectField = nodeData.fields.value.fromObject;
    const fromObjectFieldValidity = fromObjectField && (fromObjectField.formula != null) && (fromObjectField.selectedPropertyKey != null);

    return manualInputFieldsValidity || relativeFieldsValidity || fromObjectFieldValidity;
  }

}
