import {
  Component, ContentChildren, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges
} from '@angular/core';
import { Subscription } from 'rxjs';
import { DragulaService } from 'ng2-dragula';
import { isEqual, uniqueId } from 'lodash';

import { LoadingState } from '../../shared/constants';
import { SideSheetListItemComponent, ToggleType } from '../side-sheet-list-item/side-sheet-list-item.component';

export enum SideSheetListModes {
  DRAG = 'drag',
  MULTI_SELECT = 'multiselect',
  SINGLE_SELECT = 'singleselect',
  TALLY = 'tally',
  NONE = 'none'
}

@Component({
  selector: 'app-side-sheet-list',
  templateUrl: './side-sheet-list.component.html',
  styleUrls: ['./side-sheet-list.component.scss']
})
export class SideSheetListComponent implements OnDestroy, OnChanges {
  recentMode: SideSheetListModes = SideSheetListModes.NONE;
  @Input() mode: SideSheetListModes = SideSheetListModes.NONE;
  @Output() selectedChange = new EventEmitter<Array<any>>();
  @Output() showMore = new EventEmitter();
  @Input() showMoreButton = false;

  /**
   * If true, will emit 'selectedChange' event as soon as the page loads, which may cause issues in your logic
   * as 'selected' will always be empty. This is why 'emitChangeEventOnLoad' variable was added, so it can be switched off.
   */
  @Input() emitChangeEventOnLoad = true;
  /*
   * can be used to pass in preselected values
   */
  @Input() selected: Array<any> = [];

  /**
   * requiredSingleSelect
   * Only has affect when mode === SideSheetListModes.SINGLE_SELECT.
   * If true, will toggle item on/off when clicked.
   * If false, will not let user deselect (like a radio button).
   */
  @Input() requiredSingleSelect = false;
  @Input() loadingState: LoadingState = LoadingState.loaded;
  @Input() draggableModel: Array<any> = [];
  @Output() draggableModelChange = new EventEmitter<Array<any>>();

  listItems: SideSheetListItemComponent[] = [];
  dragGroupName = uniqueId('list-dragula-group-');

  SideSheetListModes = SideSheetListModes;

  subscriptions = new Subscription();

  @ContentChildren(SideSheetListItemComponent, {descendants: true}) set initItems(listItems: SideSheetListItemComponent[]) {
    this.listItems = listItems;
    if (!listItems.length) {
      return;
    }

    this.updateListItems(listItems, this.recentMode);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mode) {
      this.recentMode = changes.mode.currentValue;

      if (!this.listItems) {
        return;
      }

      this.updateListItems(this.listItems, changes.mode.currentValue);
      this.prepareDragulaGroups();
    }
  }

  public clearSelected() {
    if (!this.listItems) {
      return;
    }

    this.listItems.forEach(item => item.value = false);
    this.selected = [];
  }

  private updateListItems(
    listItems: SideSheetListItemComponent[],
    mode: SideSheetListModes = SideSheetListModes.NONE
  ) {
    listItems.forEach(listItem => {
      // no need to define item types if it is draggable
      if (mode === SideSheetListModes.DRAG) {
        listItem.toggleType = ToggleType.DRAG;
        return;
      }

      if (mode === SideSheetListModes.NONE) {
        listItem.toggleType = ToggleType.NO_TOGGLE;
        listItem.onToggle = () => { };
      } else if (mode === SideSheetListModes.SINGLE_SELECT) {
        listItem.toggleType = ToggleType.SINGLE_CHECK;
        listItem.onToggle = () => this.toggleItem(listItem, mode);
      } else if (mode === SideSheetListModes.MULTI_SELECT) {
        listItem.onToggle = () => this.toggleItem(listItem, mode);
        listItem.toggleType = ToggleType.MULTI_CHECK;
      } else if (mode === SideSheetListModes.TALLY) {
        listItem.toggleType = ToggleType.NUMBER;
      }
    });

    // init array of selected values if it is not provided
    if ([SideSheetListModes.SINGLE_SELECT, SideSheetListModes.MULTI_SELECT].indexOf(mode) > -1) {
      if (!this.selected.length) {
        this.selected = this.listItems.filter(item => !!item.value).map(item => item.key);
      } else {
        if (mode === SideSheetListModes.SINGLE_SELECT && this.selected.length > 1) {
          this.selected.splice(1);
        }

        this.listItems.forEach(item => {
          item.value = this.selected.filter(
            selectedItem => isEqual(selectedItem, item.key)
          ).length > 0;
        });
      }

      if (this.emitChangeEventOnLoad) {
        this.selectedChange.emit(this.selected);
      }
    }

    // needed to avoid ExpressionChangedAfterItHasBeenCheckedError on list item component
    listItems.forEach(listItem => {
      listItem.detectChanges();
    });
  }

  constructor(private dragulaService: DragulaService) {}

  private prepareDragulaGroups() {
    if (this.mode === SideSheetListModes.DRAG) {
      this.dragulaService.destroy(this.dragGroupName);
      this.dragulaService.createGroup(this.dragGroupName, {
        moves: (el, container, handle) => {
          return (
            handle.classList.contains('handle') ||
            (handle.parentElement && handle.parentElement.classList.contains('handle')) ||
            (handle.parentElement && handle.parentElement.parentElement && handle.parentElement.parentElement.classList.contains('handle'))
          );
        },
        direction: 'vertical'
      });
      this.subscriptions.add(this.dragulaService.drop(this.dragGroupName).subscribe(() => {
        this.draggableModelChange.emit(this.draggableModel);
      }));
    }
  }

  ngOnDestroy() {
    this.dragulaService.destroy(this.dragGroupName);
    this.subscriptions.unsubscribe();
  }

  toggleItem(listItem: SideSheetListItemComponent, mode: SideSheetListModes) {
    if (listItem.disabled) {
      return;
    }

    if (mode === SideSheetListModes.SINGLE_SELECT) {
      // don't allow deselecting item if it is required single select
      if (!this.requiredSingleSelect || !listItem.value) {
        this.toggleSingleSelect(listItem);
      }
    } else if (mode === SideSheetListModes.MULTI_SELECT) {
      this.toggleMultiSelect(listItem);
    }
    this.selectedChange.emit(this.selected);
  }

  toggleSingleSelect(listItem: SideSheetListItemComponent) {
    // uncheck all items
    this.listItems.filter((item) => item !== listItem).forEach(item => item.value = false);
    // toggle item
    listItem.value = !listItem.value;
    // update list of selected keys
    this.selected = listItem.value ? [listItem.key] : [];
  }

  toggleMultiSelect(listItem: SideSheetListItemComponent) {
    listItem.value = !listItem.value;
    // update list of selected keys

    const index = this.selected.indexOf(listItem.key);
    if (!listItem.value && index > -1) {
      this.selected.splice(index, 1);
    } else if (index === -1) {
      this.selected.push(listItem.key);
    }
  }

  toggleAll(state: boolean) {
    if (state) {
      this.listItems.forEach(item => {
        item.value = true;
        if (!this.selected.includes(item.key)) {
          this.selected.push(item.key);
        }
      });

    } else {
      this.selected = [];
      this.listItems.forEach(item => item.value = false);
    }
    this.selectedChange.emit(this.selected);
  }

  onShowMore() {
    this.showMore.emit();
  }
}

