import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { ProcessService } from '../data/process.service';
import { LocationService } from '../util/location.service';
import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

const forumTokenProcessTip = 'eim/process/forum/generate-forum-token';

@Component({
  selector: 'app-forum',
  templateUrl: './forum.component.html',
  styleUrls: ['./forum.component.scss']
})
export class ForumComponent implements OnInit {

  enCloudEnv = environment.enCloudEnv;

  constructor(
    private processService: ProcessService,
    private locationService: LocationService
  ) { }

  ngOnInit() {
    this.getForumUri().subscribe(
      redirectUri => this.locationService.redirect(redirectUri),
      _ => {
        this.locationService.redirect(this.environmentForumUri(environment.forum.homeUrl));
      }
    );
  }

  getForumUri(): Observable<string> {
    return this.processService.start(forumTokenProcessTip).pipe(
      first(),
      map(response => {
        if (response.finished && response.vars.responseToken && response.vars.responseToken[0]) {
          return this.environmentForumUri(environment.forum.tokenUrl)
                  + '?serviceUrl=' + escape(environment.host)
                  + '&namespace=' + escape(environment.ns)
                  + '&token=' + escape(response.vars.responseToken[0]);
        }
        return this.environmentForumUri(environment.forum.homeUrl);
      })
    );
  }

  environmentForumUri(forumUri: string): string {
    switch (this.enCloudEnv) {
      // case 'aupr':
      //   // To avoid having to rebuild the entire app just for AUPR, we rewrite the forum portal url
      //   return forumUri.replace(/forum\.noggin\.io/, 'forum.aupr.elasticnoggin.com');
      case 'test':
        return forumUri.replace(/integrations/, 'integrations-test');
      case 'dev':
      case 'development':
        return forumUri.replace(/integrations/, 'integrations-dev');
    }
    return forumUri;
  }

}
