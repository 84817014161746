import { Component, OnInit, OnDestroy } from '@angular/core';
import { SecurityService, ISecurityPolicy, ISecurityCohorts } from '../../data/security.service';
import { Tip } from '../../data/models/types';
import { LoadingState } from '../../shared/constants';
import { SideSheetService } from '../../side-sheet/side-sheet.service';
import { Subscription, Observable, of } from 'rxjs';
import { SecurityActionComponent } from '../../security/security-action/security-action.component';
import { first, tap } from 'rxjs/operators';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-object-security-side-sheet',
  templateUrl: './object-security-side-sheet.component.html',
  styleUrls: ['./object-security-side-sheet.component.scss']
})
export class ObjectSecuritySideSheetComponent implements OnInit, OnDestroy {

  bespokeSecurityPolicy: ISecurityPolicy = null;
  isBespokeSecurityPolicy = false;
  securityPolicyTip: Tip;
  onDone: (securityPolicyTip?: Tip, bespokeSecurityPolicy?: ISecurityPolicy) => void;

  subs: Subscription = new Subscription();

  readCohortCount: number;
  collaborateCohortCount: number;
  changeCohortCount: number;
  deleteCohortCount: number;

  readCohortLabel: string;
  collaborateCohortLabel: string;
  changeCohortLabel: string;
  deleteCohortLabel: string;

  loadingState: LoadingState;
  LoadingState = LoadingState;

  securityPolicyForm = new FormGroup({
    securityPolicyTip: new FormControl('')
  });

  constructor(
    private securityService: SecurityService,
    private sideSheetService: SideSheetService
  ) { }

  ngOnInit() {
    this.subscribeToPolicyChooser();
    this.progressWrap(this.initializeSecurityPolicy$());
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  // Subscribe to the policy chooser to show and hide the bespoke policy section
  subscribeToPolicyChooser() {
    this.subs.add(
      this.securityPolicyForm.valueChanges.subscribe(
        value => this.progressWrap(this.loadSecurityPolicy$(value.securityPolicyTip))
      )
    );
  }

  // Wrap determining the security policy in a loading state
  progressWrap(runObservable: Observable<any>) {
    this.loadingState = LoadingState.inProgress;
    this.subs.add(
      runObservable.subscribe(
        () => {
          this.afterSecurityPolicyChange();
          this.loadingState = LoadingState.loaded;
        },
        () => this.loadingState = LoadingState.failed
      )
    );
  }

  // Called after we load or select a security policy
  afterSecurityPolicyChange() {
    if (this.securityPolicyForm.value.securityPolicyTip !== this.securityPolicyTip) {
      this.securityPolicyForm.setValue({ securityPolicyTip: this.securityPolicyTip });
    }
    if (this.securityPolicyTip === '__CUSTOM__') {
      this.refreshCohortCounts();
      this.refreshCohortLabels();
    }
  }

  // Figure out the initial security policy
  initializeSecurityPolicy$(): Observable<any> {
    if (this.bespokeSecurityPolicy) {
      this.securityPolicyTip = '__CUSTOM__';
      return of(null);
    }
    this.bespokeSecurityPolicy = { name: 'Custom security policy', type: 'bespoke' };
    if (this.securityPolicyTip) {
      return this.loadSecurityPolicy$(this.securityPolicyTip);
    }
    return this.defaultSecurityPolicy$();
  }

  // Retrieve the default security policy for this object
  defaultSecurityPolicy$(): Observable<any> {
    return this.securityService.getNewObjectSecurityPolicyTip$().pipe(
      first(),
      tap(securityPolicyTip => this.securityPolicyTip = securityPolicyTip)
    );
  }

  // Load a security policy by tip
  loadSecurityPolicy$(securityPolicyTip: Tip): Observable<any> {
    if (securityPolicyTip === '__CUSTOM__') {
      this.securityPolicyTip = '__CUSTOM__';
      return of(null);
    }
    return this.securityService.getSecurityPolicy$(securityPolicyTip).pipe(
      first(),
      tap(securityPolicy => {
        if (securityPolicy.type === 'bespoke') {
          this.bespokeSecurityPolicy = securityPolicy;
          this.securityPolicyTip = '__CUSTOM__';
        } else {
          this.securityPolicyTip = securityPolicyTip;
        }
      })
    );
  }

  // Clicked the done button
  done() {
    if (this.onDone) {
      if (this.securityPolicyTip === '__CUSTOM__') {
        this.onDone(null, this.bespokeSecurityPolicy);
      } else {
        this.onDone(this.securityPolicyTip, null);
      }
    }
    this.sideSheetService.pop();
  }

  // Calculates the counts of cohorts against each action
  refreshCohortCounts() {
    this.readCohortCount = this.securityService.cohortsTips(this.bespokeSecurityPolicy.readCohorts).length;
    this.collaborateCohortCount = this.securityService.cohortsTips(this.bespokeSecurityPolicy.collaborateCohorts).length;
    this.changeCohortCount = this.securityService.cohortsTips(this.bespokeSecurityPolicy.changeCohorts).length;
    this.deleteCohortCount = this.securityService.cohortsTips(this.bespokeSecurityPolicy.deleteCohorts).length;
  }

  // Calculates the label for the cohorts to show against each action
  refreshCohortLabels() {
    this.readCohortLabel = this.securityService.cohortsLabelSummary(this.bespokeSecurityPolicy.readCohorts);
    this.collaborateCohortLabel = this.securityService.cohortsLabelSummary(this.bespokeSecurityPolicy.collaborateCohorts);
    this.changeCohortLabel = this.securityService.cohortsLabelSummary(this.bespokeSecurityPolicy.changeCohorts);
    this.deleteCohortLabel = this.securityService.cohortsLabelSummary(this.bespokeSecurityPolicy.deleteCohorts);
  }

  // Open the read action side sheet
  openReadAction() {
    return this.openAction('readCohorts', 'read-only');
  }

  // Open the collaborate action side sheet
  openCollaborateAction() {
    return this.openAction('collaborateCohorts', 'read & collaborate');
  }

  // Open the change action side sheet
  openChangeAction() {
    return this.openAction('changeCohorts', 'read, collaborate & edit');
  }

  // Open the delete action side sheet
  openDeleteAction() {
    return this.openAction('deleteCohorts', 'read, collaborate, edit & delete');
  }

  // Open the any action side sheet
  openAction(cohortsName: 'readCohorts' | 'collaborateCohorts' | 'changeCohorts' | 'deleteCohorts', actionLabel: string) {
    const sheetRef = this.sideSheetService.push(SecurityActionComponent);
    const cohorts = this.bespokeSecurityPolicy[cohortsName];
    sheetRef.componentInstance.selectedUsers = cohorts && cohorts.users ? cohorts.users : [];
    sheetRef.componentInstance.selectedProfiles = cohorts && cohorts.profiles ? cohorts.profiles : [];
    sheetRef.componentInstance.selectedTeams = cohorts && cohorts.teams ? cohorts.teams : [];
    sheetRef.componentInstance.selectedRoles = cohorts && cohorts.roles ? cohorts.roles : [];
    sheetRef.componentInstance.actionLabel = actionLabel;
    sheetRef.componentInstance.title = this.bespokeSecurityPolicy.name;
    sheetRef.componentInstance.readonly = false;
    sheetRef.componentInstance.onDone = (data: ISecurityCohorts) => {
      this.bespokeSecurityPolicy[cohortsName] = data;
      this.refreshCohortCounts();
      this.refreshCohortLabels();
    };
  }
}
