import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FixTabNabbingPipe } from './fix-tab-nabbing.pipe';

@NgModule({
  declarations: [FixTabNabbingPipe],
  exports: [FixTabNabbingPipe],
  imports: [
    CommonModule
  ]
})
export class FixTabNabbingModule {}
