import { of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { get } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../data/process.service";
import * as i3 from "../data/session-manager.service";
var ActivateGuard = /** @class */ (function () {
    function ActivateGuard(router, processService, sessionManagerService) {
        this.router = router;
        this.processService = processService;
        this.sessionManagerService = sessionManagerService;
    }
    ActivateGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        var email = route.queryParams.email;
        return this.processService.start('eim/process/auth/check-user-status', { Email: [email] }).pipe(first(function (processResponse) { return processResponse.finished; }), switchMap(function (processResponse) {
            var userTip = get(processResponse, ['vars', 'User', '0']);
            var userStatus = get(processResponse, ['vars', 'User status', '0']);
            if (userStatus !== 'active') {
                return of(true);
            }
            return _this.sessionManagerService.getSessionInfo$().pipe(first(Boolean), map(function (sessionInfo) {
                if (sessionInfo.user === userTip) {
                    _this.router.navigate(['/app', 's']);
                }
                else {
                    _this.router.navigate(['/signin'], {
                        queryParams: { message: 'Your account has already been activated' },
                        skipLocationChange: true
                    });
                }
                return false;
            }));
        }));
    };
    ActivateGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ActivateGuard_Factory() { return new ActivateGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.ProcessService), i0.ɵɵinject(i3.SessionManagerService)); }, token: ActivateGuard, providedIn: "root" });
    return ActivateGuard;
}());
export { ActivateGuard };
