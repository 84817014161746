import * as tslib_1 from "tslib";
import { NodeConverterBase } from './NodeConverterBase';
import { InvalidWorkflowNodeDataError } from './InvalidWorkflowNodeDataError';
import dataConstants from '../../../data/constants';
import { Stringify } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { get } from 'lodash';
import * as i0 from "@angular/core";
var SubworkflowNodeConverterService = /** @class */ (function (_super) {
    tslib_1.__extends(SubworkflowNodeConverterService, _super);
    function SubworkflowNodeConverterService() {
        var _this = _super.call(this) || this;
        _this.type = 'processnode/logic/subprocess';
        _this.processNodeType = 'processnodetype/logic/subprocess';
        return _this;
    }
    SubworkflowNodeConverterService.prototype.convert = function (nodeData) {
        if (!this.isValid(nodeData)) {
            throw new InvalidWorkflowNodeDataError("Please correct invalid data on sub-workflow node [" + nodeData.fields.title + "]", nodeData);
        }
        var subWorkflowProcessTipFormula = Stringify({
            name: 'FIELD',
            args: [
                'app/workflow:process',
                get(nodeData, 'fields.subworkflow', null)
            ]
        });
        return {
            $type: this.type,
            $security: dataConstants.SECURITY.INSTANCE_USER_ADMIN,
            type: this.processNodeType,
            connections: [],
            point: [0, 0],
            key: nodeData.tip,
            process: subWorkflowProcessTipFormula,
            varKeys: nodeData.fields.inputs,
            timeoutMs: nodeData.fields.timeoutAfter,
            waitForProcessComplete: nodeData.fields.waitForCompletion
        };
    };
    SubworkflowNodeConverterService.prototype.isValid = function (nodeData) {
        var subworkflowProcess = nodeData.fields.subworkflowProcess;
        return !(subworkflowProcess === null || subworkflowProcess === undefined || subworkflowProcess === '');
    };
    SubworkflowNodeConverterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SubworkflowNodeConverterService_Factory() { return new SubworkflowNodeConverterService(); }, token: SubworkflowNodeConverterService, providedIn: "root" });
    return SubworkflowNodeConverterService;
}(NodeConverterBase));
export { SubworkflowNodeConverterService };
