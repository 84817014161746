import { WorkflowValueTypes } from '../workflow-designer-constants';
import { ISelectedWorkflowValue, INodeEditorData, ILinkType } from '../workflow-designer-interfaces';
import { Diagram } from 'gojs';
import { IScreenFieldEditItem } from '../node-editors/workflow-ux-editor/workflow-screen-edit/workflow-screen-edit.component';
import { camelCase } from 'change-case';
import { UX_NODE_OUTCOME } from '../../modules/sidesheets/workflow-end-user-side-sheet/workflow-end-user.service';
import { IWorkflow, IWorkflowEditorProps, IWorkflowProp } from '../../../models/workflow';
import { get } from 'lodash';

const NS = 'NodeEditorLightUtils';


export class NodeEditorLightUtils {

  static getSelectedProperty(
    selectedProperty: ISelectedWorkflowValue,
    inputs: IWorkflowProp[],
    variables: IWorkflowProp[],
    actors: IWorkflowProp[]
  ): IWorkflowProp {

    switch (selectedProperty.type) {
      case WorkflowValueTypes.INPUT:
        return inputs[selectedProperty.selectedIndex] || null;

      case WorkflowValueTypes.ACTOR:
        return actors[selectedProperty.selectedIndex] || null;

      case WorkflowValueTypes.VARIABLE:
        return variables[selectedProperty.selectedIndex] || null;

      default:
        return null;
    }
  }

  /**
   * Returns an object used to identify the selected item from the selected type's list
   */
  static getSelectedPropertyInfo(
    selectedType: string,
    inputs: IWorkflowProp[],
    variables: IWorkflowProp[],
    actors: IWorkflowProp[]
  ): ISelectedWorkflowValue {

    switch (selectedType) {
      case WorkflowValueTypes.INPUT:
        return {
          type: selectedType,
          selectedIndex: inputs.map(o => o.isSelected).indexOf(true)
        };

      case WorkflowValueTypes.ACTOR:
        return {
          type: selectedType,
          selectedIndex: actors.map(o => o.isSelected).indexOf(true)
        };

      case WorkflowValueTypes.VARIABLE:
        return {
          type: selectedType,
          selectedIndex: variables.map(o => o.isSelected).indexOf(true)
        };

      default:
        return null;
    }
  }

  static getSelectedPropertyKey(
    selectedType: string,
    inputs: IWorkflowProp[],
    variables: IWorkflowProp[],
    actors: IWorkflowProp[]
  ): string {
    const selectedProperty = NodeEditorLightUtils.getSelectedPropertyInfo(selectedType, inputs, variables, actors);
    return NodeEditorLightUtils.getWorkflowPropKey(selectedProperty, inputs, variables, actors);
  }

  /**
   * Returns the key of a workflow property using the selected index.
   */
  static getWorkflowPropKey(
    selectedProperty: ISelectedWorkflowValue,
    inputs: IWorkflowProp[],
    variables: IWorkflowProp[],
    actors: IWorkflowProp[]
  ): string {
    if (!selectedProperty || !inputs || !variables || !actors) { return null; }

    switch (selectedProperty.type) {
      case WorkflowValueTypes.ACTOR:
        return get(actors, [selectedProperty.selectedIndex, 'key'], null);

      case WorkflowValueTypes.VARIABLE:
        return get (variables, [selectedProperty.selectedIndex, 'key'], null);

      case WorkflowValueTypes.INPUT:
        return get (inputs, [selectedProperty.selectedIndex, 'key'], null);
    }
    return null;
  }

  /**
   * Returns a 'ISelectedWorkflowValue' object for the first IWorkflowProp item from the provided lists
   */
  static getSelectedWorkflowValueByKey(
    key: string,
    inputs: IWorkflowProp[],
    variables: IWorkflowProp[],
    actors: IWorkflowProp[]
  ): IWorkflowProp {
    if (!key || !inputs || !variables || !actors) { return null; }

    return inputs.find(o => o.key === key) ||
           actors.find(o => o.key === key) ||
           variables.find(o => o.key === key) ||
           null;
  }

  static getWorkflowPropByKey(key: string, inputs: IWorkflowProp[], variables: IWorkflowProp[], actors: IWorkflowProp[]): IWorkflowProp {
    if (!key || !inputs || !variables || !actors) { return null; }
    return inputs.concat(variables).concat(actors).find(o => o.key === key);
  }

  static shallowCopyArr(arr: any[]): any[] {
    return arr ? arr.slice() : [];
  }

  /**
   * Looks through actors, variables and inputs to find the first isSelected item and returns the property type (WorkflowValueTypes)
   */
  static setSelectedPropertyType(
    selectedPropertyKey: string,
    inputs: IWorkflowProp[],
    variables: IWorkflowProp[],
    actors: IWorkflowProp[]
  ): void {

    this.resetSelectedProperties(inputs, variables, actors);

    const selectedProperty: IWorkflowProp = this.getSelectedWorkflowValueByKey(selectedPropertyKey, inputs, variables, actors);

    if (!selectedProperty) {
      return;
    }
    selectedProperty.isSelected = true;
  }

  static resetSelectedProperties(inputs: IWorkflowProp[], variables: IWorkflowProp[], actors: IWorkflowProp[]): void {
    [actors, variables, inputs].forEach(props => props.forEach(prop => prop.isSelected = false));
  }

  static getCombinedWorkflowProps(workflowData: IWorkflow | IWorkflowEditorProps): IWorkflowProp[] {
    return workflowData.inputs.concat(workflowData.actors).concat(workflowData.variables);
  }


  static setNodeEditorData(diagram: Diagram, tip: string, data: INodeEditorData): void {

    const existingProcessIndex: number = diagram.model.modelData.nodeEditorDataList
      .map((o, i) => o.tip === tip ? i : -1) // maps the index of matching tip
      .find(o => o !== -1); // finds just the index that matches the tip

    if (existingProcessIndex !== undefined && existingProcessIndex !== -1) {
      diagram.model.modelData.nodeEditorDataList.splice(existingProcessIndex, 1, data);
    } else {
      diagram.model.modelData.nodeEditorDataList.push(data);
    }
  }

  /**
   * Converts a list of IScreenFieldEditItem items to ILinkType for use in process connections
   */
  static getWorkflowUXConnectionTypes(screens: IScreenFieldEditItem[]): ILinkType[] {
    const connections: ILinkType[] = [];
    screens.forEach(screen => {
      connections.push(
        ...NodeEditorLightUtils.getWorkflowUXConnectionTypesForScreen(screen)
      );
    });

    return connections;
  }

  /**
   * Converts a single IScreenFieldEditItem item to ILinkType for use in process connection
   */
  static getWorkflowUXConnectionTypesForScreen(screen: IScreenFieldEditItem, includePrimary: boolean = true): ILinkType[] {
    const connectionNames: string[] = screen.actionButtonLabels || [];
    const links = connectionNames.map(name => ({ name, value: camelCase(name), hasDivider: false }));
    if (includePrimary && screen.primaryButtonLabel) {
      // Note: deliberately using 'Primary' for label, so it's in line with hardcoded 'Secondary' connection.
      // If this needs to change, use screen.primaryButtonLabel for name (but not value)
      links.unshift({ name: UX_NODE_OUTCOME.primary, value: UX_NODE_OUTCOME.primary, hasDivider: false });
    }
    return links;
  }

  constructor() {}
}
