import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService, SignInStatus } from '../auth.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });

  state: SignInStatus;
  messageState: SignInStatus = null;
  SignInStatus = SignInStatus;
  isNativeApp = false;

  constructor(
    private authService: AuthService,
    private deviceDetectorService: DeviceDetectorService,
    private router: Router
  ) {}

  ngOnInit() {
    this.isNativeApp = !this.deviceDetectorService.isDesktop();
  }

  onResetPassword() {
    this.state = SignInStatus.IN_PROGRESS;
    this.messageState = null;

    this.authService.forgotPassword(this.forgotPasswordForm.value.email).subscribe(
      () => {
        this.state = SignInStatus.SUCCESS;
      },
      () => {
        this.state = SignInStatus.FAILURE;
        this.messageState = SignInStatus.FAILURE;
      }
    );
  }

  goBackToLogin() {
    this.router.navigate(['/signin']);
  }
}
