import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { first, shareReplay } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { LoadingState } from '../../shared/constants';
import {
  ModalConfirmDeletionComponent,
  modalConfirmDeletionOptions
} from '../../shared/modals/modal-confirm-deletion/modal-confirm-deletion.component';
import { IObjectLaunchProps } from '../../formly-noggin/object-launch';
import { ToastService } from '../../shell/services/toast.service';
import { SideSheetService } from '../../side-sheet/side-sheet.service';
import { ChecklistsListComponent } from '../../checklists/checklists-list/checklists-list.component';
import { ObjectEditSideSheetComponent } from '../../object/object-edit-side-sheet/object-edit-side-sheet.component';
import { IPlan } from '../../models/plan';
import { ICheckList } from '../../models/checklist';
import { Tip } from '../../data/models/types';
import { PlanService } from '../plan.service';
import { PlanStrategySideSheetComponent } from '../plan-strategy-side-sheet/plan-strategy-side-sheet.component';
import { SecurityService } from '../../data/security.service';
import { ModuleValidationError } from '../../data/errors/client/ModuleValidationError';

@Component({
  selector: 'app-plan-edit-side-sheet',
  templateUrl: './plan-edit-side-sheet.component.html',
  styleUrls: ['./plan-edit-side-sheet.component.scss']
})
export class PlanEditSideSheetComponent implements OnInit, OnDestroy {
  filterText = '';
  errorMessage = '';
  loadingState = LoadingState.inProgress;
  plan: IPlan = {
    name: '',
    description: '',
    strategy: '',
    checklists: []
  };
  isInSelectMode = false;
  isDraggable = false;
  objectTip: Tip;
  moduleTip: Tip;
  objectBranchTip: Tip;
  canActivate = false;
  loadingStates = LoadingState;
  checklistsBeforeDrag: ICheckList[];

  canUpdate$: Observable<boolean>;

  @ViewChild('checklistsList', { static: false }) checklistsList: ChecklistsListComponent;

  private subscriptions: Subscription = new Subscription();

  done = (plan: IPlan) => {};

  closed = (plan: IPlan) => {};

  back = (plan: IPlan) => {};

  constructor(
    private planService: PlanService,
    private sideSheetService: SideSheetService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private securityService: SecurityService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.planService.getPlan(this.objectTip).subscribe(
        (plan: IPlan) => {
          this.plan = plan;
          this.canActivate = !plan.isTemplate && !plan.isActivated;
          this.loadingState = LoadingState.loaded;
        },
        () => {
          this.loadingState = LoadingState.failed;
          this.errorMessage = 'Could not get plan details at this time';
        }
      )
    );

    this.canUpdate$ = this.securityService.canUpdate(this.objectTip).pipe(shareReplay(1));
  }

  setProps({ objectTip }: IObjectLaunchProps) {
    this.objectTip = objectTip;
  }

  onClickAddChecklist() {
    if (!this.plan.checklists) {
      this.plan.checklists = [];
    }

    this.openObjectEditSideSheet({
      fieldTipsToDisplay: ['app/checklist:name'],
      onDone: (objectData) => {
        this.plan.checklists.push({
          $tip: objectData['$tip'],
          $security: objectData['$security'],
          $type: objectData['$type'],
          name: objectData['app/checklist:name'],
          items: []
        });
        this.updatePlan();
      },
      enoType: 'app/checklist',
      enoSecurity: this.plan.$security
    });
  }

  openPlanEditSideSheet() {
    this.planService.openPlanEditSideSheet(this.plan, this.moduleTip);
  }

  private openObjectEditSideSheet(
    {
      fieldTipsToDisplay = [],
      onDone = (objectData: { [fieldTip: string]: string }) => { },
      objectTip = null,
      objectBranchTip = null,
      enoType = null,
      enoSecurity = null
    }
  ) {
    const sheetRef = this.sideSheetService.push(ObjectEditSideSheetComponent);

    sheetRef.componentInstance.title = 'Add checklist';
    sheetRef.componentInstance.objectTip = objectTip;
    sheetRef.componentInstance.objectBranchTip = objectBranchTip || this.objectBranchTip;
    sheetRef.componentInstance.mode = 'save';
    sheetRef.componentInstance.fieldTipsToDisplay = fieldTipsToDisplay;
    sheetRef.componentInstance.enoType = enoType;
    sheetRef.componentInstance.enoSecurity = enoSecurity;
    sheetRef.componentInstance.onDone = onDone;
  }

  onRequestDeleteChecklists(checklists: Tip[]) {
    const modalRef = this.modalService.open(ModalConfirmDeletionComponent, modalConfirmDeletionOptions);

    modalRef.componentInstance.objectType = 'checklists';
    modalRef.componentInstance.message = 'Do you really want to delete these checklists?';
    modalRef.result.then(modalResult => {
      return modalResult ? this.deleteChecklists(checklists) : null;
    }).catch(() => {});
  }

  private deleteChecklists(checklists: Tip[]) {
    this.planService.deleteChecklists(checklists, this.moduleTip).pipe(first()).subscribe(() => {
      this.plan.checklists = this.plan.checklists.filter(x => !checklists.includes(x.$tip));
      this.isInSelectMode = false;
    }, (err) => {
      err instanceof ModuleValidationError
      ? this.toastService.showErrorToast(err.message)
      : this.toastService.showErrorToast('Failed to delete checklists at this time.');
    });
  }

  onRequestDelete() {
    const modalRef = this.modalService.open(ModalConfirmDeletionComponent, modalConfirmDeletionOptions);
    modalRef.componentInstance.objectType = 'plan';
    modalRef.result.then(modaResult => {
      return modaResult ? this.delete() : null;
    }).catch(() => {});
  }

  private delete() {
    this.planService.deletePlan(this.plan, this.moduleTip).pipe(first()).subscribe(() => {
      this.sideSheetService.pop();
      this.toastService.showSuccessToast('Deleted plan successfully');
    }, (err) => {
      err instanceof ModuleValidationError
      ? this.toastService.showErrorToast(err.message)
      : this.toastService.showErrorToast('Failed to update plan at this time');
    });
  }

  updatePlan(onUpdated?: () => void): Observable<IPlan> {
    this.loadingState = LoadingState.inProgress;
    const updatingPlan$ = this.planService.updatePlan(this.plan, false, this.moduleTip).pipe(first());
    this.subscriptions.add(updatingPlan$.subscribe(() => {
      if (onUpdated) {
        onUpdated();
      }
      this.loadingState = LoadingState.loaded;
      this.toastService.showSuccessToast('Updated plan successfully');
    }, (err) => {
      err instanceof ModuleValidationError
      ? this.toastService.showErrorToast(err.message)
      : this.toastService.showErrorToast('Failed to update plan at this time');
      this.loadingState = LoadingState.loaded;
    }));
    return updatingPlan$;
  }

  viewStrategy() {
    const sheetRef = this.sideSheetService.push(PlanStrategySideSheetComponent);
    const instance = sheetRef.componentInstance as PlanStrategySideSheetComponent;

    instance.moduleTip = this.moduleTip;
    instance.setProps({ planTip: this.plan.$tip });
  }

  onClose() {
    this.closed(this.plan);
  }

  onDone() {
    if (this.canActivate) {
      // present modal here to set plan.isActivated to true or false and set plan.Incomplete to false
      this.loadingState = LoadingState.inProgress;
      const modalRef = this.modalService.open(ModalConfirmDeletionComponent, modalConfirmDeletionOptions);
      modalRef.componentInstance.title = 'New plan';
      modalRef.componentInstance.message = 'Do you want to save this plan as a draft or activate it now?';
      modalRef.componentInstance.confirmText = 'Activate now';
      modalRef.componentInstance.cancelText = 'Save as draft';
      modalRef.componentInstance.cancelButtonClass = 'btn-secondary';

      modalRef.result
        .then((confirmActivate) => {
          if (confirmActivate) {
            // on confirm activate plan
            this.plan.isActivated = true;
            this.plan.isIncomplete = false;
          } else {
            // on cancel - save as draft
            this.plan.isActivated = false;
            this.plan.isIncomplete = false;
          }
          this.updatePlan(() => {
            this.sideSheetService.pop();
            this.done(this.plan);
          });
        })
        .catch(() => {
          this.loadingState = LoadingState.loaded;
        });
    } else {
      this.sideSheetService.pop();
      this.done(this.plan);
    }
  }

  onGoBack() {
    this.back(this.plan);
    this.sideSheetService.pop();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onDragFinish() {
    this.updatePlan(() => {
      this.isDraggable = false;
      this.checklistsList.isDragged = false;
    });
  }

  onDragStart() {
    this.isDraggable = !this.isDraggable;
    this.checklistsBeforeDrag = [...this.plan.checklists];
  }

  onDragCancel() {
    this.isDraggable = false;
    if (this.checklistsList.isDragged) {
      this.plan.checklists = this.checklistsBeforeDrag;
      this.checklistsList.isDragged = false;
    }
  }

  onChecklistsChange() {
    // don't update plan after dragNdrop, it will be updated onDragFinish
    if (!this.checklistsList.isDragged) {
      this.updatePlan();
    }
  }
}
