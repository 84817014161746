/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./side-sheet-list-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "./side-sheet-list-item.component";
var styles_SideSheetListItemComponent = [i0.styles];
var RenderType_SideSheetListItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SideSheetListItemComponent, data: {} });
export { RenderType_SideSheetListItemComponent as RenderType_SideSheetListItemComponent };
function View_SideSheetListItemComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "eim-icon_sm mat-icon notranslate"], ["data-test", "radio-button-checked"], ["role", "img"], ["svgIcon", "radio-button-checked"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_2 = "radio-button-checked"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_SideSheetListItemComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "eim-icon_sm mat-icon notranslate"], ["role", "img"], ["svgIcon", "radio-button-unchecked"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_2 = "radio-button-unchecked"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_SideSheetListItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "list-item-single-check icon"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideSheetListItemComponent_3)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideSheetListItemComponent_4)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.value; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.value; _ck(_v, 4, 0, currVal_1); }, null); }
function View_SideSheetListItemComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "eim-color_icon eim-icon_sm mat-icon notranslate"], ["data-test", "side-sheet-list-item-checked"], ["role", "img"], ["svgIcon", "checkbox"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_2 = "checkbox"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_SideSheetListItemComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "eim-color_icon eim-icon_sm mat-icon notranslate"], ["role", "img"], ["svgIcon", "checkbox-outline"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_2 = "checkbox-outline"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_SideSheetListItemComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "list-item-multi-check icon"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideSheetListItemComponent_6)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideSheetListItemComponent_7)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.value; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.value; _ck(_v, 4, 0, currVal_1); }, null); }
function View_SideSheetListItemComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "list-item-drag handle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-icon", [["class", "eim-icon_handle drag-icon mat-icon notranslate"], ["role", "img"], ["svgIcon", "move-item"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_2 = "move-item"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_SideSheetListItemComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "list-item-number"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "list-item-number-inner"]], null, null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "list-item-number-more-than-0": 0 }), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "list-item-number-inner"; var currVal_1 = _ck(_v, 4, 0, ((_co.value !== undefined) ? (_co.value > 0) : false)); _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.value; _ck(_v, 5, 0, currVal_2); }); }
function View_SideSheetListItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "d-flex flex-row align-items-center"], ["data-test", "side-sheet-list-item-clickable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "list-item-control"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideSheetListItemComponent_2)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideSheetListItemComponent_5)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideSheetListItemComponent_8)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideSheetListItemComponent_9)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "d-flex flex-row align-items-center"; var currVal_1 = _co.customCheckboxClass; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (_co.toggleType === "single-check"); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.toggleType === "multi-check"); _ck(_v, 7, 0, currVal_3); var currVal_4 = (_co.toggleType === "drag"); _ck(_v, 9, 0, currVal_4); var currVal_5 = (_co.toggleType === "number"); _ck(_v, 11, 0, currVal_5); }, null); }
function View_SideSheetListItemComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "eim-icon_info-list-item no-shrink mat-icon notranslate"], ["role", "img"], ["svgIcon", "info"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onMoreInfo() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_2 = "info"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_SideSheetListItemComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "eim-icon_chevron-right-list-item no-shrink mat-icon notranslate"], ["role", "img"], ["svgIcon", "chevron"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_2 = "chevron"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_SideSheetListItemComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "eim-color_icon eim-icon_sm ml-3 no-shrink mat-icon notranslate"], ["role", "img"], ["svgIcon", "launch"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNavAway($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_2 = "launch"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_SideSheetListItemComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "tool-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-icon", [["class", "flex-shrink-0 eim-font-color_nfc-on-white-buttons mat-icon notranslate"], ["role", "img"], ["svgIcon", "admin-tool"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickToolSection($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_2 = "admin-tool"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_SideSheetListItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [], [[2, "side-sheet-list-item-outer-container", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["data-test", "side-sheet-list-item-container"]], [[1, "data-test", 0]], null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "list-item-disabled": 0, "border-bottom-0": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideSheetListItemComponent_1)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 10, "div", [["class", "d-flex flex-row align-items-center flex-grow-1 list-item-content right-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "list-item-main-content flex-grow-1"], ["data-test", "side-sheet-list-item-text"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideSheetListItemComponent_10)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideSheetListItemComponent_11)), i1.ɵdid(13, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideSheetListItemComponent_12)), i1.ɵdid(15, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideSheetListItemComponent_13)), i1.ɵdid(17, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵinlineInterpolate(1, "side-sheet-list-item-container d-flex flex-row align-items-stretch justify-content-start mode-", _co.toggleType, ""); var currVal_3 = _ck(_v, 4, 0, _co.disabled, _co.hideUnderline); _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = (_co.toggleType !== "no-toggle"); _ck(_v, 6, 0, currVal_4); var currVal_5 = ((_co.moreInfo.observers.length > 0) && (_co.showMoreInfo !== false)); _ck(_v, 11, 0, currVal_5); var currVal_6 = _co.hasChevron; _ck(_v, 13, 0, currVal_6); var currVal_7 = _co.allowNavAway; _ck(_v, 15, 0, currVal_7); var currVal_8 = _co.hasToolSection; _ck(_v, 17, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showHoverState; _ck(_v, 0, 0, currVal_0); var currVal_1 = (_co.value ? "selected" : null); _ck(_v, 1, 0, currVal_1); }); }
export function View_SideSheetListItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-side-sheet-list-item", [], null, null, null, View_SideSheetListItemComponent_0, RenderType_SideSheetListItemComponent)), i1.ɵdid(1, 114688, null, 0, i5.SideSheetListItemComponent, [i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SideSheetListItemComponentNgFactory = i1.ɵccf("app-side-sheet-list-item", i5.SideSheetListItemComponent, View_SideSheetListItemComponent_Host_0, { toggleType: "toggleType", value: "value", disabled: "disabled", hasChevron: "hasChevron", hideUnderline: "hideUnderline", key: "key", customCheckboxClass: "customCheckboxClass", showMoreInfo: "showMoreInfo", allowNavAway: "allowNavAway", hasToolSection: "hasToolSection", showHoverState: "showHoverState" }, { toggle: "toggle", moreInfo: "moreInfo", navAway: "navAway", actionClickToolSection: "actionClickToolSection" }, ["*"]);
export { SideSheetListItemComponentNgFactory as SideSheetListItemComponentNgFactory };
