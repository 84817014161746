var _a;
import { reverseOperator } from './operators';
import { Parser, Stringify } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { OPERATOR_TYPE } from '../../../models/query';
import { isFormulaSpec } from '../../../util/is-formula-spec';
import { getFieldTipsFromFormulaSpec, getFieldTipsFromFormulaString } from '../../../util/get-field-tips-from-formula-string';
import { last } from 'lodash';
import { addContextFormulaWrapperSpec, getContextFromContextFormula } from '../../../util/context-formula-wrapper';
var ɵ0 = function (leftArg, rightArg) {
    var leftFieldTip = last(isFormulaSpec(leftArg) ? getFieldTipsFromFormulaSpec(leftArg) : getFieldTipsFromFormulaString(leftArg));
    if (isFormulaSpec(leftArg) && leftFieldTip) {
        var leftObjectFormula = leftArg.name === 'CONTEXT' ?
            Parser(getContextFromContextFormula(Stringify(leftArg)) || 'TIP()')
            : (leftArg.args[1] || { name: 'TIP', args: [] });
        return {
            name: 'INARRAY',
            args: [
                {
                    name: 'GEO_FIELD_INTERSECTS',
                    args: [
                        rightArg,
                        { name: 'ARRAY', args: [leftFieldTip] }
                    ]
                },
                leftObjectFormula
            ]
        };
    }
    else {
        throw new Error('leftArg must be FIELD formula.');
    }
};
var geographyIntersectsWith = {
    opNo: 2,
    toFormula: ɵ0
};
var geographyNotIntersectsWith = reverseOperator(geographyIntersectsWith);
export var geographyOperators = {
    operators: (_a = {},
        _a[OPERATOR_TYPE.INTERSECTS_WITH] = geographyIntersectsWith,
        _a[OPERATOR_TYPE.NOT_INTERSECTS_WITH] = geographyNotIntersectsWith,
        _a),
    getParts: function (formula) {
        var currentPart = formula;
        var negative = false;
        if (currentPart.name === 'NOT') {
            currentPart = currentPart.args[0];
            negative = true;
        }
        if (currentPart.name === 'INARRAY') {
            var operator = negative ? OPERATOR_TYPE.NOT_INTERSECTS_WITH : OPERATOR_TYPE.INTERSECTS_WITH;
            var rightArg = currentPart.args[0].args[0];
            var leftFieldFormula = { name: 'FIELD', args: [currentPart.args[0].args[1].args[0]] };
            var leftArg = void 0;
            if (currentPart.args[1].name === 'TIP') {
                leftArg = leftFieldFormula;
            }
            else {
                leftArg = addContextFormulaWrapperSpec(leftFieldFormula, currentPart.args[1]);
            }
            return { operator: operator, leftArg: leftArg, rightArg: rightArg };
        }
        throw new Error('Unknown operators: ' + currentPart.name);
    }
};
export { ɵ0 };
