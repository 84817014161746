import * as momentTz from 'moment-timezone';

export function getTimezonesAsOptionList(isUserTimezone = false): { label: string, value: string } [] {
  const userTimezone = [{
    value: 'User timezone',
    label: 'User timezone'
  }];

  const momentTimezones = momentTz.tz.names().map(name => ({
    label: name,
    value: name
  }));

  return isUserTimezone ? [...userTimezone, ...momentTimezones] : [...momentTimezones];
}

export function getTimezonesAndOffsetsAsOptionList(): { label: string, value: string, offset: number, offsetLabel: string } [] {
  return momentTz.tz.names().map(name => ({
    label: name,
    value: name,
    offset: momentTz.tz(name).utcOffset(),
    offsetLabel: 'UTC ' + convertMinutesToHrsMin(momentTz.tz(name).utcOffset()),
  }));
}

export function convertMinutesToHrsMin(minutes: number): string {
  const isNegative = minutes < 0;

  const h = Math.floor(Math.abs(minutes) / 60);
  const m = Math.abs(minutes) % 60;
  const hour = h < 10 ? '0' + h : h;
  const min = m < 10 ? '0' + m : m;
  return isNegative ? `-${hour}:${min}` : `+${hour}:${min}`;
}
