import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { LoadingState } from '../../shared/constants';
import { Tip } from '../../data/models/types';
import { ProfileSummary, ProfileEditorService } from '../../settings/profile-editor/profile-editor.service';
import { SideSheetListModes } from '../../side-sheet/side-sheet-list/side-sheet-list.component';

@Component({
  selector: 'app-select-profiles-side-sheet',
  templateUrl: './select-profiles-side-sheet.component.html',
  styleUrls: ['./select-profiles-side-sheet.component.scss']
})
export class SelectProfilesSideSheetComponent implements OnInit {
  @Input() mode: SideSheetListModes.SINGLE_SELECT | SideSheetListModes.MULTI_SELECT = SideSheetListModes.SINGLE_SELECT;

  initiallySelectedTips: Tip[] = [];
  selectedProfiles: Tip[] = [];
  doNotDisplayInitiallySelectedProfiles = false;
  profiles$: Observable<ProfileSummary[]>;
  profiles: ProfileSummary[];
  subscriptions = new Subscription();
  filterText: string;
  loadingStates = LoadingState;
  done: (selectedProfile: ProfileSummary | ProfileSummary[]) => void;
  allSelected = false;
  showAll = true;

  constructor(
    private profileService: ProfileEditorService
  ) { }

  ngOnInit() {
    this.selectedProfiles = [...this.initiallySelectedTips];
    this.allSelected = this.selectedProfiles.indexOf('__ALL__') > -1;
    this.profiles$ = this.profileService.getProfiles().pipe(
      map((profiles: ProfileSummary[]) => {
        let _profiles = [...profiles];
        _profiles = (this.doNotDisplayInitiallySelectedProfiles)
          ? _profiles.filter(profile => this.initiallySelectedTips.indexOf(profile.$tip) === -1)
          : _profiles;

        if (this.mode === SideSheetListModes.MULTI_SELECT && this.showAll) {
          _profiles.unshift({ $tip: '__ALL__', name: 'All profiles', description: 'All profiles'});
        }

        this.profiles = _profiles;
        return this.profiles;
      }),
      shareReplay()
    );
  }

  onDone(selectedProfileTips: Tip[]) {
    switch (this.mode) {
      case SideSheetListModes.SINGLE_SELECT:
        if (selectedProfileTips && selectedProfileTips.length) {
          this.done(this.profiles.find(x => x.$tip === selectedProfileTips[0]));
        }

        break;
      case SideSheetListModes.MULTI_SELECT:
        this.done(this.profiles.filter((profile) => {
          return selectedProfileTips.indexOf(profile.$tip) > -1;
        }));

        break;
    }
  }

  toggleProfile(profileTip: Tip) {
    if (profileTip === '__ALL__') {
      this.allSelected = (this.selectedProfiles.indexOf(profileTip) === -1);
    }
  }
}
