import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideSheetHeaderModule } from '../side-sheet-header/side-sheet-header.module';
import { SideSheetSectionModule } from '../side-sheet-section/side-sheet-section.module';
import { SideSheetListModule } from '../side-sheet-list/side-sheet-list.module';
import { SideSheetListItemModule } from '../side-sheet-list-item/side-sheet-list-item.module';
import { EmptyStateModule } from '../../shared/empty-state/empty-state.module';
import { SpinnerModule } from '../../shared/spinner/spinner.module';
import { NgbModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from '../../shared/shared.module';
import { FormlyModule } from '@ngx-formly/core';
import { CustomDateRangeSideSheetComponent } from './custom-date-range-side-sheet.component';


@NgModule({
  declarations: [
    CustomDateRangeSideSheetComponent
  ],
  imports: [
    CommonModule,
    SideSheetHeaderModule,
    SideSheetSectionModule,
    SideSheetListModule,
    SideSheetListItemModule,
    EmptyStateModule,
    SpinnerModule,
    NgbModule,
    NgbDropdownModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    SharedModule,
    FormlyModule.forChild()
  ],
  exports: [
    CustomDateRangeSideSheetComponent
  ],
  entryComponents: [
    CustomDateRangeSideSheetComponent
  ],
})
export class CustomDateRangeSideSheetModule { }
