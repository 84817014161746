import { IObjectScheme, DataTypes } from '../data/models/scheme';
import { IObject } from '../data/models/object';
import { Tip } from '../data/models/types';
import { INavConfig } from '../form/navigation-config-field/navigation-config';

export { IObjectStep, objectStepScheme };

interface IObjectStep extends IObject {
  name: string;
  stage?: Tip;
  navConfig?: INavConfig;
  newWindowNavigation?: boolean;
  orderNumber?: number;
}

const objectStepScheme: IObjectScheme = {
  'app/object-step:name': { type: DataTypes.i18n },
  'app/object-step:stage': {},
  'app/object-step:nav-config': { name: 'navConfig', type: DataTypes.json },
  'app/object-step:new-window-navigation': { type: DataTypes.boolean },
  'app/object-step:order-number': { type: DataTypes.number }
};
