import { Inject, Injectable, OnDestroy, NgZone } from '@angular/core';
import { SessionManagerService } from '../../data/session-manager.service';
import { ToastService } from '../../shell/services/toast.service';
import { AuthService } from '../auth.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WINDOW } from '../../dom-tokens';
import { Notification } from '@noggin/angular2-notifications';
import { LoggerService } from '../../util/logger.service';

@Injectable({
  providedIn: 'root'
})
export class SessionExpiryAlertService implements OnDestroy {
  notification: Notification | null = null;
  notificationSubscription: null | Subscription = null;
  destroy$ = new Subject();
  timerId: null | number = null;

  constructor(
    private sessionInfo: SessionManagerService,
    private toastService: ToastService,
    private authService: AuthService,
    private loggerService: LoggerService,
    @Inject(WINDOW) private window: Window,
    private ngZone: NgZone
  ) { }

  init() {
    this.sessionInfo.getSessionInfo$()
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(({token, sessionExpiry}) => {
        this.clearTimerAndMessage();
        if (token && sessionExpiry) {
          this.startTimer(sessionExpiry);
        }
      });
  }

  alert() {
    const message = `
      For security reasons, you will be forced to re-authenticate in 15 minutes.
      Please save your work now, and click here to re-authenticate.
    `;
    if (!this.notification) {
      this.notification = this.toastService.showWarningToast(message, {timeOut: 0});
      this.notificationSubscription = this.notification.click.subscribe(() => {
        this.authService.signOut();
      });
    }
  }

  startTimer(sessionExpiry: Date) {
    // calculate timeout
    const fifteenMinutes = 15 * 60 * 1000; // 15 minutes
    const unixEpochTimeOut = sessionExpiry.valueOf() - fifteenMinutes;
    const now = Date.now();
    const timeoutIn = unixEpochTimeOut - now;

    this.loggerService.info(`[session-expiry-alert-service] expiry alert scheduled for ${new Date(unixEpochTimeOut)}.`);

    // needed for protractor please see README.md for more info
    this.ngZone.runOutsideAngular(() => {
      // set timeout
      this.timerId = this.window
        .setTimeout(() => this.alert(), timeoutIn);
    });
  }

  clearTimerAndMessage() {
    if (this.timerId) {
      this.window.clearTimeout(this.timerId);
      this.loggerService.info(`[session-expiry-alert-service] expiry alert cleared`);
      this.timerId = null;
    }
    if (this.notification) {
      this.toastService.remove(this.notification.id);
      this.notification = null;
    }
    if (this.notificationSubscription) {
      this.notificationSubscription.unsubscribe();
      this.notificationSubscription = null;
    }
  }

  ngOnDestroy(): void {
    this.clearTimerAndMessage();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
