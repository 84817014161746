import { environment } from '../environments/environment';
var xRayIncludesWhiteList = [
    environment.host
];
var XRayInterceptor = /** @class */ (function () {
    function XRayInterceptor(sessionManager) {
        this.sessionManager = sessionManager;
    }
    XRayInterceptor.prototype.intercept = function (req, next) {
        var isWhiteListed = xRayIncludesWhiteList
            .some(function (listValue) { return req.url.includes(listValue); });
        if (isWhiteListed) {
            var traceId = createAmznTraceId({
                sessionId: this.sessionManager.getSessionId(),
                namespace: environment.ns
            });
            var updatedReq = req.clone({
                setHeaders: {
                    'X-Amzn-Trace-Id': traceId
                }
            });
            return next.handle(updatedReq);
        }
        else {
            return next.handle(req);
        }
    };
    return XRayInterceptor;
}());
export { XRayInterceptor };
export function createAmznTraceId(_a, random) {
    var sessionId = _a.sessionId, namespace = _a.namespace, resetCountTo = _a.resetCountTo;
    if (random === void 0) { random = randomSeven; }
    var nowInSeconds = Math.floor(Date.now() / 1000);
    var nowInHex = nowInSeconds.toString(16);
    var count = hexCount(resetCountTo);
    var _sessionId = sessionId || "nos" + random();
    var tenCharNameSpace = tenCharString(namespace);
    var tenCharSessionId = tenCharString(_sessionId);
    var currentCount = count;
    return "Root=1-" + nowInHex + "-" + tenCharNameSpace + tenCharSessionId + currentCount;
}
export function randomSeven() {
    return Math.floor((Math.random() * 1000000) + 1).toString().padStart(7, '0');
}
var _map = new Map();
export function tenCharString(string, map) {
    if (map === void 0) { map = _map; }
    var existing = map.get(string);
    if (existing) {
        return existing;
    }
    var length = string.length;
    var maxLength = string.length > 10
        ? 10
        : length;
    var result = string.substr(0, maxLength).padStart(10, '0');
    map.set(string, result);
    return result;
}
var _current = 0;
export function hexCount(current) {
    if (current === void 0) { current = _current; }
    // 4294967295 is the maximum number for an 8 char hex number
    // parseInt(FFFF, 16)
    if (current < 65535) {
        current++;
    }
    else {
        current = 0;
    }
    // update our stateful current count
    _current = current;
    return current.toString(16).padStart(4, '0');
}
