var _a;
import { reverseOperator } from './operators';
import { Parser } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { OPERATOR_TYPE } from '../../../models/query';
import { isCurrentUserFormula } from '../../../util/current-user-formula';
import { isFormulaSpec } from '../../../util/is-formula-spec';
var ɵ0 = function (leftArg, rightArg) {
    var rightArgFormula = rightArg;
    if (!isFormulaSpec(rightArg) && isCurrentUserFormula(rightArg)) {
        rightArgFormula = Parser(rightArg);
    }
    return {
        name: 'INARRAY',
        args: [
            {
                name: 'ARRAY',
                args: [
                    rightArgFormula,
                    {
                        name: 'REFERENCES',
                        args: [
                            'app/team-role:users',
                            rightArgFormula
                        ]
                    },
                    {
                        name: 'REFERENCES',
                        args: [
                            'app/team:roles',
                            {
                                name: 'REFERENCES',
                                args: [
                                    'app/team-role:users',
                                    rightArgFormula
                                ]
                            }
                        ]
                    },
                    {
                        name: 'REFERENCES',
                        args: [
                            'app/team:roles',
                            rightArgFormula
                        ]
                    }
                ]
            },
            {
                name: 'FIELD',
                args: [
                    'app/assignment:assignee',
                    leftArg
                ]
            }
        ]
    };
};
// checks that assignee in leftArg is same as user in rightArg
// OR one of teams it belongs to
// OR one of roles it belongs to
var assignedTo = {
    opNo: 2,
    toFormula: ɵ0
};
var notAssignedTo = reverseOperator(assignedTo);
export var assigneeOperators = {
    operators: (_a = {},
        _a[OPERATOR_TYPE.ASSIGNED_TO] = assignedTo,
        _a[OPERATOR_TYPE.NOT_ASSIGNED_TO] = notAssignedTo,
        _a),
    getParts: function (formula) {
        var currentPart = formula;
        var negative = false;
        if (currentPart.name === 'NOT') {
            currentPart = currentPart.args[0];
            negative = true;
        }
        if (currentPart.name === 'INARRAY') {
            return {
                operator: negative ? OPERATOR_TYPE.NOT_ASSIGNED_TO : OPERATOR_TYPE.ASSIGNED_TO,
                leftArg: currentPart.args[1].args[1],
                rightArg: currentPart.args[0].args[0]
            };
        }
        throw new Error('Unknown operators: ' + currentPart.name);
    }
};
export { ɵ0 };
