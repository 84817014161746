import { Injectable } from '@angular/core';
import { Formula, Tip } from '../../data/models/types';
import { FIELD_DATA_TYPE, TYPE_GEO_RESTRICT } from '../../models/field';
import { QueryFilterEditorService } from '../../query/query-filter-editor/query-filter-editor.service';
import { FormulaSpec, Parser } from '../../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { IQuery, OPERATOR_TYPE, queryScheme } from '../../models/query';
import { ObjectService } from '../../data/object-service/object.service';
import { first, map, switchMap, filter } from 'rxjs/operators';
import { Observable, combineLatest, of } from 'rxjs';
import { chartScheme, IChart } from '../../models/chart';
import { isEmpty, chunk } from 'lodash';
import { IVars } from '../../data/vars.service';
import { ParamMap } from '@angular/router';
import { FormulaMultiService } from '../../data/formula-multi.service';
import { riskMatrixScheme, IRiskMatrix } from '../../models/risk-matrix';

export type FilterInputBy = 'Entering' | 'Context' | 'URL' | 'Relative' | 'Field' | 'Workflow-actor' | 'Workflow-variable' | 'Workflow-input';

export interface IFilterInputVar {
  name: string;
  type: FIELD_DATA_TYPE;
  typeRestrict?: Tip;
  typeGeoRestricts?: TYPE_GEO_RESTRICT[];
  isObjectStage?: boolean;
  isList?: boolean;
  tip?: Tip;
  fieldTip?: Tip;
  isHierarchical?: boolean;
  implements?: Tip[];
  operator?: OPERATOR_TYPE;
}

export interface IFilterInput {
  variable: IFilterInputVar;
  value: string;
  by: FilterInputBy;
  formFieldTip?: Tip;
  workflowVarKey?: string;
}

export interface ISortingOption {
  formula: string;
  sortDirection: 'asc' | 'desc';
  label?: string;
}

@Injectable({
  providedIn: 'root'
})
export class FilterInputService {
  static parseVarNames(varNames: string[]): IFilterInputVar[] {
    return varNames.map(varName => JSON.parse(atob(varName)));
  }

  static compareFilterInputVars(a: IFilterInputVar, b: IFilterInputVar): number {
    return a.name === b.name ? 0 : (a.name > b.name ? 1 : -1);
  }

  static getVarsFromFormula(formula: FormulaSpec): IFilterInputVar[] {
    return FilterInputService.parseVarNames(QueryFilterEditorService.getVarNames(formula))
      .sort(FilterInputService.compareFilterInputVars);
  }

  static getEmptyFilterInputsFromFormulaStr(formulaStr: string): IFilterInput[] {
    const fullFilterFormula: FormulaSpec = Parser(formulaStr);

    const filterInputVars = FilterInputService.getVarsFromFormula(fullFilterFormula);
    return filterInputVars.map(filterInputVar => ({ variable: filterInputVar, value: '', by: null }));
  }

  static getEmptyFilterInputsFromFormulaStrs(formulaStrs: string[]): IFilterInput[] {
    const formulaStr = `AND(${formulaStrs.join(',')})`;

    return FilterInputService.getEmptyFilterInputsFromFormulaStr(formulaStr);

  }

  // e.g.
  // filterInputs: [
  //   { variable: { name: 'A', type: 'string'}, value: '', by: null },
  //   { variable: { name: 'B', type: 'string'}, value: '', by: null }
  // ]
  // values: [
  //   { variable: { name: 'A', type: 'string'}, value: 'VALUE', by: 'Entering' },
  //   { variable: { name: 'C', type: 'string'}, value: 'ANOTHER VALUE', by: 'Entering' }
  // ]
  // filterInputs will be changed to: [
  //   { variable: { name: 'A', type: 'string'}, value: 'VALUE', by: 'Entering' },
  //   { variable: { name: 'B', type: 'string'}, value: '', by: null }
  // ]
  // as you can see, Variable C and its value was dropped
  static mergeExistingValues(filterInputs: IFilterInput[], values: IFilterInput[]): void {
    if (values && values.length !== 0) {
      values.map(value => filterInputs.forEach(filterInput => {
        if (JSON.stringify(value.variable) === JSON.stringify(filterInput.variable)) {
          filterInput.value = value.value;
          filterInput.by = value.by;
        }
      }));
    }
  }

  static filterInputFulfilled(filterInputs: IFilterInput[]): boolean {
    return isEmpty(filterInputs) || filterInputs.filter(filterInput => isEmpty(filterInput.value)).length === 0;
  }

  constructor(
    private objectService: ObjectService,
    private formulaMultiService: FormulaMultiService
  ) {}

  /*
  try using convertWorkflowFilterInputsToVars if a non-observable version is required. check below.
   */
  convertFilterInputsToVars(filterInputs: IFilterInput[], contextTip?: Tip, queryParams?: ParamMap, noBase64 = false): Observable<IVars> {
    if (!filterInputs) {
      filterInputs = [];
    }

    const vars: IVars = {};
    const contextVarNames: string[] = [];
    const contextFormulas: Formula[] = [];
    const relativeVarNames: string[] = [];
    const relativeFormulas: Formula[] = [];

    filterInputs.forEach(filterInput => {
      const varName = noBase64 ? filterInput.variable.name : btoa(JSON.stringify(filterInput.variable));
      if (filterInput.by === 'Entering') {
        vars[varName] = [filterInput.value];
      } else if (filterInput.by === 'Context') {
        contextVarNames.push(varName);
        contextFormulas.push(filterInput.value);
      } else if (filterInput.by === 'Relative') {
        relativeVarNames.push(varName);
        relativeFormulas.push(filterInput.value);
      } else if (filterInput.by === 'URL' && queryParams) {
        vars[varName] = queryParams.getAll(filterInput.value);
      } else if (filterInput.by === 'Field' && filterInput.formFieldTip && filterInput.formFieldTip !== filterInput.value) {
        vars[varName] = [filterInput.value];
      }
    });

    if (contextVarNames.length === 0 && relativeVarNames.length === 0) {
      return of(vars);
    }

    return this.formulaMultiService.evaluate([
      ...contextFormulas.map(contextFormula => ({
        formula: contextFormula,
        context: contextTip,
        watch: false
      })),
      ...relativeFormulas.map(relativeFormula => ({
        formula: relativeFormula,
        context: contextTip,
        watch: false
      }))
    ]).pipe(
      first(),
      map((results: string[][]) => {
        // context formulas
        const contextResults = results.slice(0, contextFormulas.length);
        contextResults.forEach((result: string[], i: number) => {
          vars[contextVarNames[i]] = result;
        });

        // relative datetime formulas
        const relativeResults = results.slice(contextFormulas.length);
        relativeResults.forEach((result: string[], i: number) => {
          vars[relativeVarNames[i]] = result;
        });
        return vars;
      })
    );
  }

  /*
  this is a simplified version of convertFilterInputsToVars for limited use cases and doesn't return an observable
  consider using/expanding this iff using observable is an issue
   */
  convertWorkflowFilterInputsToVars(filterInputs: IFilterInput[] = []): IVars {
    const vars: IVars = {};
    filterInputs.forEach(filterInput => {
      const varName =  btoa(JSON.stringify(filterInput.variable));
      vars[varName] = [filterInput.value];
    });
    return vars;
  }

  getEmptyFilterInputsFromQueryTips(queryTips: Tip[]): Observable<IFilterInput[]> {
    if (isEmpty(queryTips)) {
      return of([]);
    }
    return combineLatest(
      queryTips.map(queryTip => this.objectService.getObject<IQuery>(queryTip, queryScheme).pipe(first()))
    ).pipe(
      map((queries: IQuery[]): IFilterInput[] => {
          const formulaStrs = queries.map(query => query.filters[0].formula);
          return FilterInputService.getEmptyFilterInputsFromFormulaStrs(formulaStrs);
        }
      )
    );
  }

  getEmptyFilterInputsFromChartTip(chartTip: Tip): Observable<IFilterInput[]> {
    return this.objectService.getObject<IChart>(chartTip, chartScheme).pipe(
      first(),
      switchMap((chart: IChart) => {
        return this.getEmptyFilterInputsFromQueryTips(chart.dataSource.map(dataSource => dataSource.queryTip));
      })
    );
  }

  getEmptyFilterInputsFromRiskMatrixTip(riskMatrixTip: Tip): Observable<IFilterInput[]> {
    return this.objectService.getObject<IRiskMatrix>(riskMatrixTip, riskMatrixScheme).pipe(
      first(),
      switchMap((riskMatrix: IRiskMatrix) => {
        return this.getEmptyFilterInputsFromQueryTips([riskMatrix.filter]);
      })
    );
  }
}
