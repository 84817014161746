/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./side-sheet-option-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../side-sheet-section/side-sheet-section.component.ngfactory";
import * as i3 from "../side-sheet-section/side-sheet-section.component";
import * as i4 from "../../shared/filter-and-add/filter-and-add.component.ngfactory";
import * as i5 from "../../shared/filter-and-add/filter-and-add.component";
import * as i6 from "../side-sheet-list-item/side-sheet-list-item.component.ngfactory";
import * as i7 from "../side-sheet-list-item/side-sheet-list-item.component";
import * as i8 from "@angular/common";
import * as i9 from "../side-sheet-list/side-sheet-list.component.ngfactory";
import * as i10 from "../side-sheet-list/side-sheet-list.component";
import * as i11 from "ng2-dragula";
import * as i12 from "../../shared/empty-state/empty-state.component.ngfactory";
import * as i13 from "../../shared/empty-state/empty-state.component";
import * as i14 from "../../shared/filter.pipe";
import * as i15 from "../side-sheet-header/side-sheet-header.component.ngfactory";
import * as i16 from "../side-sheet-header/side-sheet-header.component";
import * as i17 from "../side-sheet.service";
import * as i18 from "./side-sheet-option-list.component";
var styles_SideSheetOptionListComponent = [i0.styles];
var RenderType_SideSheetOptionListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SideSheetOptionListComponent, data: {} });
export { RenderType_SideSheetOptionListComponent as RenderType_SideSheetOptionListComponent };
function View_SideSheetOptionListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Untitled"]))], null, null); }
function View_SideSheetOptionListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 0, 0, currVal_0); }); }
function View_SideSheetOptionListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-side-sheet-section", [], null, null, null, i2.View_SideSheetSectionComponent_0, i2.RenderType_SideSheetSectionComponent)), i1.ɵdid(1, 49152, null, 0, i3.SideSheetSectionComponent, [], { showBorder: [0, "showBorder"] }, null), (_l()(), i1.ɵeld(2, 0, null, 3, 1, "app-filter-and-add", [], null, [[null, "filterTextChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filterTextChange" === en)) {
        var pd_0 = ((_co.filterText = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_FilterAndAddComponent_0, i4.RenderType_FilterAndAddComponent)), i1.ɵdid(3, 114688, null, 0, i5.FilterAndAddComponent, [], { filterText: [0, "filterText"] }, { filterTextChange: "filterTextChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.filterText; _ck(_v, 3, 0, currVal_1); }, null); }
function View_SideSheetOptionListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "pr-1 icon icon-left mdi ", _v.parent.context.$implicit.icon, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_SideSheetOptionListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "app-side-sheet-list-item", [], null, null, null, i6.View_SideSheetListItemComponent_0, i6.RenderType_SideSheetListItemComponent)), i1.ɵdid(1, 114688, [[2, 4]], 0, i7.SideSheetListItemComponent, [i1.ChangeDetectorRef], { disabled: [0, "disabled"], key: [1, "key"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideSheetOptionListComponent_6)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.disabled); var currVal_1 = _v.context.$implicit.value; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.icon; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_3 = _v.context.$implicit.label; _ck(_v, 5, 0, currVal_3); }); }
function View_SideSheetOptionListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "app-side-sheet-list", [], null, [[null, "selectedChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedChange" === en)) {
        var pd_0 = ((_co.selected = $event) !== false);
        ad = (pd_0 && ad);
    } if (("selectedChange" === en)) {
        var pd_1 = (_co.onSelectedChange() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i9.View_SideSheetListComponent_0, i9.RenderType_SideSheetListComponent)), i1.ɵdid(1, 704512, [[1, 4], ["optionList", 4]], 1, i10.SideSheetListComponent, [i11.DragulaService], { mode: [0, "mode"], showMoreButton: [1, "showMoreButton"], emitChangeEventOnLoad: [2, "emitChangeEventOnLoad"], selected: [3, "selected"], requiredSingleSelect: [4, "requiredSingleSelect"] }, { selectedChange: "selectedChange" }), i1.ɵqud(603979776, 2, { initItems: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 3, null, View_SideSheetOptionListComponent_5)), i1.ɵdid(4, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpad(5, 1), i1.ɵppd(6, 3)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listType; var currVal_1 = false; var currVal_2 = false; var currVal_3 = _co.selected; var currVal_4 = _co.required; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = i1.ɵunv(_v, 4, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 0), _co.options, _co.filterText, _ck(_v, 5, 0, "label"))); _ck(_v, 4, 0, currVal_5); }, null); }
function View_SideSheetOptionListComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-empty-state", [["imgSrc", "/assets/img/empty-states/no-groups.svg"], ["label", "No options here"]], null, null, null, i12.View_EmptyStateComponent_0, i12.RenderType_EmptyStateComponent)), i1.ɵdid(1, 49152, null, 0, i13.EmptyStateComponent, [], { imgSrc: [0, "imgSrc"], label: [1, "label"] }, null)], function (_ck, _v) { var currVal_0 = "/assets/img/empty-states/no-groups.svg"; var currVal_1 = "No options here"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_SideSheetOptionListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i14.FilterPipe, []), i1.ɵqud(671088640, 1, { optionList: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 8, "app-side-sheet-header", [], null, [[null, "actioned"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("actioned" === en)) {
        var pd_0 = (_co.onDone() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_SideSheetHeaderComponent_0, i15.RenderType_SideSheetHeaderComponent)), i1.ɵdid(3, 245760, null, 0, i16.SideSheetHeaderComponent, [i17.SideSheetService], { action: [0, "action"], hideBack: [1, "hideBack"] }, { actioned: "actioned" }), (_l()(), i1.ɵeld(4, 0, null, 0, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, null, null, null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i8.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideSheetOptionListComponent_1)), i1.ɵdid(8, 278528, null, 0, i8.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i8.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideSheetOptionListComponent_2)), i1.ɵdid(10, 16384, null, 0, i8.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i8.NgSwitch], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideSheetOptionListComponent_3)), i1.ɵdid(12, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideSheetOptionListComponent_4)), i1.ɵdid(14, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["empty", 2]], null, 0, null, View_SideSheetOptionListComponent_7))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isSingleSelect ? "" : (_co.actionText || "Done")); var currVal_1 = _co.hideBack; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.label; _ck(_v, 6, 0, currVal_2); var currVal_3 = "Untitled"; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.searchBox; _ck(_v, 12, 0, currVal_4); var currVal_5 = ((_co.options == null) ? null : _co.options.length); var currVal_6 = i1.ɵnov(_v, 15); _ck(_v, 14, 0, currVal_5, currVal_6); }, null); }
export function View_SideSheetOptionListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-side-sheet-option-list", [], null, null, null, View_SideSheetOptionListComponent_0, RenderType_SideSheetOptionListComponent)), i1.ɵdid(1, 114688, null, 0, i18.SideSheetOptionListComponent, [i17.SideSheetService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SideSheetOptionListComponentNgFactory = i1.ɵccf("app-side-sheet-option-list", i18.SideSheetOptionListComponent, View_SideSheetOptionListComponent_Host_0, {}, {}, []);
export { SideSheetOptionListComponentNgFactory as SideSheetOptionListComponentNgFactory };
