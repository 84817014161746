import * as tslib_1 from "tslib";
import { NotificationType } from '@noggin/angular2-notifications';
import { escape } from 'lodash';
import { NotficationIcons } from './notification-icons';
import * as i0 from "@angular/core";
import * as i1 from "@noggin/angular2-notifications";
var ToastService = /** @class */ (function () {
    function ToastService(_notificationsService) {
        this._notificationsService = _notificationsService;
    }
    ToastService.prototype.set = function (notification, to) {
        return this._notificationsService.set(notification, to);
    };
    ToastService.prototype.remove = function (id) {
        return this._notificationsService.remove(id);
    };
    ToastService.prototype.showToast = function (title, body, intent, options) {
        if (options === void 0) { options = {}; }
        return this._notificationsService.create(title, body, intent, options);
    };
    ToastService.prototype.showSuccessToast = function (body, options) {
        if (options === void 0) { options = {}; }
        return this.showToast('', escape(body), NotificationType.Success, this.optionsWithDefaults(options));
    };
    ToastService.prototype.showErrorToast = function (body, options) {
        if (options === void 0) { options = {}; }
        return this.showToast('', escape(body), NotificationType.Error, this.optionsWithDefaults(options));
    };
    ToastService.prototype.showWarningToast = function (body, options) {
        if (options === void 0) { options = {}; }
        return this.showToast('', escape(body), NotificationType.Warn, this.optionsWithDefaults(options));
    };
    ToastService.prototype.optionsWithDefaults = function (options) {
        var defaultIcons = {
            icons: {
                success: NotficationIcons['app/notification-category/for-your-information'],
                alert: '',
                error: NotficationIcons['app/notification-category/for-your-information'],
                info: '',
                warn: NotficationIcons['app/notification-category/for-your-information']
            }
        };
        return tslib_1.__assign({}, defaultIcons, options);
    };
    ToastService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ToastService_Factory() { return new ToastService(i0.ɵɵinject(i1.NotificationsService)); }, token: ToastService, providedIn: "root" });
    return ToastService;
}());
export { ToastService };
