import * as tslib_1 from "tslib";
import { addressScheme } from '../../models/map';
import { of, ReplaySubject } from 'rxjs';
import { isEmpty, cloneDeep } from 'lodash';
import { map, catchError, distinctUntilChanged, retry, switchMap, first } from 'rxjs/operators';
import dataConstants from '../../data/constants';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../data/object-service/object.service";
import * as i3 from "../../data/session-manager.service";
import * as i4 from "../../util/logger.service";
// export const gisServicePath = `http://localhost:8888${ environment.gisServicePath }`;
export var gisServicePath = "" + environment.host + environment.gisServicePath;
var GisService = /** @class */ (function () {
    function GisService(httpClient, objectService, sessionManager, loggerService) {
        var _this = this;
        this.httpClient = httpClient;
        this.objectService = objectService;
        this.sessionManager = sessionManager;
        this.loggerService = loggerService;
        this.browserSupportsGeolocation = ('geolocation' in navigator);
        this.sessionManager.getSessionInfo$().pipe(retry(3)).subscribe(function (sessionInfo) { return _this.sessionInfo = sessionInfo; });
    }
    GisService.prototype.getAddress = function (addressTip) {
        return this.objectService.getObject(addressTip, addressScheme);
    };
    GisService.prototype.setAddress = function (address) {
        var newAddress = cloneDeep(address);
        delete newAddress.$tip;
        delete newAddress.$sid;
        // this will create a eno with empty nonce in EMPTY_NONCE_TYPES in EnoFactory.ts
        return this.objectService.setObject(newAddress, addressScheme, dataConstants.BRANCH_MASTER, 'app/security-policy/instance-user-runtime').pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 1), tip = _b[0].tip;
            return tip;
        }));
    };
    GisService.prototype.newAddress = function (displayStreet, point, meta) {
        return {
            $type: 'app/address',
            displayStreet: displayStreet || '',
            point: point || null,
            meta: meta || null
        };
    };
    GisService.prototype.sendGeocodeRequest = function (queryTerm) {
        var _this = this;
        var encodedQueryURI = encodeURIComponent(queryTerm) + ".json";
        var url = gisServicePath + "/geocode/" + encodedQueryURI;
        return this.getUserLocation().pipe(first(), map(function (point) { return ({ proximity: point.coordinates.join(',') }); }), catchError(function () { return of({}); }), switchMap(function (proximity) { return _this.httpClient.get(url, {
            headers: _this.getAuthHeaders(),
            params: tslib_1.__assign({ autocomplete: 'true' }, proximity)
        }); }), distinctUntilChanged(), map(function (_a) {
            var features = _a.features;
            return features;
        }), catchError(function (err) {
            _this.loggerService.error("Unable to reach gis service: " + err.message);
            return of(err);
        }));
    };
    GisService.prototype.getGeocodeResult = function (queryTerm) {
        return this.sendGeocodeRequest(queryTerm).pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 1), meta = _b[0];
            var displayStreet;
            var point;
            if (meta) {
                displayStreet = meta.place_name;
                point = meta.geometry;
            }
            else {
                displayStreet = null;
                point = null;
            }
            return { point: point, displayStreet: displayStreet, meta: meta };
        }));
    };
    GisService.prototype.reverseGeocode = function (point) {
        var _this = this;
        return this.getGeocodeResult(point.coordinates[0] + "," + point.coordinates[1]).pipe(map(function (_a) {
            var displayStreet = _a.displayStreet, meta = _a.meta;
            return _this.newAddress(displayStreet, point, meta);
        }));
    };
    GisService.prototype.getAutocompleteResult = function (searchTerm) {
        var _this = this;
        if (isEmpty(searchTerm)) {
            return of([]);
        }
        return this.sendGeocodeRequest(searchTerm).pipe(map(function (features) { return features.map(function (meta) { return _this.newAddress(meta.place_name, meta.geometry, meta); }); }), catchError(function () { return of([]); }));
    };
    GisService.prototype.getUserLocation = function () {
        var _this = this;
        if (!this.geolocation$) {
            this.geolocation$ = new ReplaySubject(1);
            if (this.browserSupportsGeolocation) {
                navigator.geolocation.watchPosition(function (position) {
                    if (!position) {
                        throw new Error('Unable to locate');
                    }
                    _this.geolocation$.next({
                        type: 'Point',
                        coordinates: [position.coords.longitude, position.coords.latitude]
                    });
                }, function (error) {
                    _this.geolocation$.error(new Error('Unable to locate: ' + error.message));
                }, {
                    enableHighAccuracy: true,
                    timeout: 10000,
                    maximumAge: 0
                });
            }
            else {
                this.geolocation$.error(new Error('Unable to locate as the browser does not support it or has declined'));
            }
        }
        return this.geolocation$;
    };
    // Retrieve the authorization headers
    GisService.prototype.getAuthHeaders = function () {
        if (!this.sessionInfo) {
            throw new Error('Attempting to authorize against the gis service without a session token');
        }
        return {
            Authorization: "Bearer " + this.sessionInfo.token,
            'en-namespace': environment.ns
        };
    };
    GisService.prototype.getQueryLayerUrl = function (layerTip) {
        return gisServicePath + "/esri/layer/" + environment.ns + "/" + encodeURIComponent(layerTip);
    };
    GisService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GisService_Factory() { return new GisService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ObjectService), i0.ɵɵinject(i3.SessionManagerService), i0.ɵɵinject(i4.LoggerService)); }, token: GisService, providedIn: "root" });
    return GisService;
}());
export { GisService };
