import * as tslib_1 from "tslib";
import { SideSheetBase } from '../../../../side-sheet/side-sheet-base';
var CustomFormulaSideSheetComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CustomFormulaSideSheetComponent, _super);
    function CustomFormulaSideSheetComponent(sideSheetService, fb) {
        var _this = _super.call(this) || this;
        _this.sideSheetService = sideSheetService;
        _this.fb = fb;
        _this.formulaForm = _this.fb.group({
            customFormulaStr: ['']
        });
        return _this;
    }
    CustomFormulaSideSheetComponent.prototype.setProps = function (props) {
        this.formulaForm.patchValue({
            customFormulaStr: props
        });
    };
    CustomFormulaSideSheetComponent.prototype.onPrimary = function () {
        this.done$.next(this.formulaForm.value.customFormulaStr);
        this.sideSheetService.pop();
    };
    return CustomFormulaSideSheetComponent;
}(SideSheetBase));
export { CustomFormulaSideSheetComponent };
