import * as tslib_1 from "tslib";
import { Parser, Stringify } from '../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { get } from 'lodash';
import { isFormulaSpec } from './is-formula-spec';
// formulas: [FORMULA_1(), FORMULA_2()],
// contextFormula: [CONTEXT_1(), CONTEXT_2()]
// => [CONTEXT(CONTEXT_1(),FORMULA_1()), CONTEXT(CONTEXT_2(),FORMULA_2())]
export function addContextFormulaWrappers(formulas, contextFormula) {
    return formulas.map(function (formula, index) { return addContextFormulaWrapper(formula, contextFormula[index]); });
}
// formula: FORMULA_1()
// context: CONTEXT_1()
// => CONTEXT(CONTEXT_1(), FORMULA_1())
export function addContextFormulaWrapper(formula, contextFormula) {
    return Stringify(addContextFormulaWrapperSpec(formula, contextFormula));
}
export function addContextFormulaWrapperSpec(formula, contextFormula) {
    return {
        name: 'CONTEXT',
        args: [
            isFormulaSpec(contextFormula) ? contextFormula : Parser(contextFormula),
            isFormulaSpec(formula) ? formula : Parser(formula)
        ]
    };
}
// formulas: [CONTEXT(CONTEXT_1(),FORMULA_1()), CONTEXT(CONTEXT_2(),FORMULA_2())]
// => [FORMULA_1(), FORMULA_2()]
export function removeContextFormulaWrappers(formulas) {
    return getPartsFromContextFormulas(formulas).formula;
}
// formulas: CONTEXT(CONTEXT_1(), FORMULA_1())
// => FORMULA_1()
export function removeContextFormulaWrapper(formula) {
    return getPartsFromContextFormula(formula).formula;
}
// formulas: [CONTEXT(CONTEXT_1(),FORMULA_1()), CONTEXT(CONTEXT_2(),FORMULA_2()), FORMULA_3()]
// => [CONTEXT_1(), CONTEXT_2(), null]
export function getContextFromContextFormulas(formulas) {
    return getPartsFromContextFormulas(formulas).context;
}
// formulas: CONTEXT(CONTEXT_1(),FORMULA_1())
// => CONTEXT_1()
// formulas: FORMULA_2()
// => null
export function getContextFromContextFormula(formula) {
    return getPartsFromContextFormula(formula).context;
}
// formula: CONTEXT(CONTEXT(FORMULA_3(),FORMULA_2()),FORMULA_1())
// => FORMULA_3()
export function getMostInnerContextFromContextFormula(formula) {
    return getMostInnerContextPartsFromContextFormula(formula).mostInnerContextFormula;
}
// formula: CONTEXT(CONTEXT(FORMULA_3(),FORMULA_2()),FORMULA_1())
// => CONTEXT(FORMULA_2(),FORMULA_1())
export function removeMostInnerContextFromContextFormula(formula) {
    return buildRecursiveContextFormula(getMostInnerContextPartsFromContextFormula(formula).outerFormulas);
}
// formula: CONTEXT(FORMULA_2(),FORMULA_1())
// contextFormula: FORMULA_3()
// => CONTEXT(CONTEXT(FORMULA_3(),FORMULA_2()),FORMULA_1())
export function insertMostInnerContextToContextFormula(formula, contextFormula) {
    var _a = getMostInnerContextPartsFromContextFormula(formula), outerFormulas = _a.outerFormulas, mostInnerContextFormula = _a.mostInnerContextFormula;
    return buildRecursiveContextFormula(tslib_1.__spread(outerFormulas, [mostInnerContextFormula, contextFormula]));
}
// formula: CONTEXT(CONTEXT(FORMULA_3(),FORMULA_2()),FORMULA_1())
// => {
//   outerFormulas: [FORMULA_1(), FORMULA_2()],
//   mostInnerContextFormula: FORMULA_3()
// }
export function getMostInnerContextPartsFromContextFormula(input) {
    var extractedFormulas = [];
    var lastContext = null;
    var _a = getPartsFromContextFormula(input), formula = _a.formula, context = _a.context;
    while (context) {
        extractedFormulas.push(formula);
        lastContext = context;
        var result = getPartsFromContextFormula(lastContext);
        formula = result.formula;
        context = result.context;
    }
    return { outerFormulas: extractedFormulas, mostInnerContextFormula: formula };
}
// inputs: [FORMULA_1(), FORMULA_2(), FORMULA_3()]
// => CONTEXT(CONTEXT(FORMULA_3(),FORMULA_2()),FORMULA_1())
export function buildRecursiveContextFormula(inputs) {
    var clonedInputs = inputs.slice();
    var lastContext;
    var lastFormula;
    while (clonedInputs.length > 1) {
        lastContext = clonedInputs.pop();
        lastFormula = clonedInputs.pop();
        clonedInputs.push(addContextFormulaWrapper(lastFormula, lastContext));
    }
    return clonedInputs.pop();
}
// formulas: [CONTEXT(CONTEXT_1(),FORMULA_1()), CONTEXT(CONTEXT_2(),FORMULA_2()), FORMULA_3()]
// => {
//   formula: [FORMULA_1(), FORMULA_2(), FORMULA_3()],
//   context: [CONTEXT_1(), CONTEXT_2(), null]
// }
export function getPartsFromContextFormulas(formulas) {
    var formula = [];
    var context = [];
    formulas.forEach(function (f) {
        var result = getPartsFromContextFormula(f);
        formula.push(result.formula);
        context.push(result.context);
    });
    return { formula: formula, context: context };
}
// input: CONTEXT(CONTEXT_1(),FORMULA_1())
// => {
//   formula: FORMULA_1(),
//   context: CONTEXT_1()
// }
//
// input: FORMULA_1()
// => {
//   formula: FORMULA_1(),
//   context: null
// }
export function getPartsFromContextFormula(input) {
    var parsedFormula = Parser(input);
    if (parsedFormula.name === 'CONTEXT') {
        return {
            formula: Stringify(get(parsedFormula, 'args[1]')),
            context: Stringify(get(parsedFormula, 'args[0]'))
        };
    }
    return {
        formula: input,
        context: null
    };
}
