import * as tslib_1 from "tslib";
import { FieldType } from '@ngx-formly/core';
var FormlyFieldStringEncryptionComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FormlyFieldStringEncryptionComponent, _super);
    function FormlyFieldStringEncryptionComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FormlyFieldStringEncryptionComponent.prototype.reset = function ($event) {
        this.formControl.markAsTouched();
        this.formControl.updateValueAndValidity();
    };
    return FormlyFieldStringEncryptionComponent;
}(FieldType));
export { FormlyFieldStringEncryptionComponent };
