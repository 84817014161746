import * as i0 from "@angular/core";
var LocationService = /** @class */ (function () {
    function LocationService() {
    }
    LocationService.prototype.redirect = function (url) {
        window.location.href = url;
    };
    LocationService.prototype.refresh = function () {
        window.location.reload();
    };
    LocationService.prototype.refreshToRoot = function () {
        window.location.href = window.location.href.replace(window.location.pathname, '');
    };
    LocationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocationService_Factory() { return new LocationService(); }, token: LocationService, providedIn: "root" });
    return LocationService;
}());
export { LocationService };
