import { DataTypes, IObjectScheme, ISchemeScan } from '../models/scheme';

// originally written by Owen living in object.service
export function scanScheme(
  scheme: IObjectScheme,
  maxDepth: number = 0,
  followFields: Array<string> = [],
  schemes: Array<IObjectScheme> = []
): ISchemeScan {
  const thisDepth = maxDepth;
  const fieldTips = Object.keys(scheme);
  schemes.push(scheme);

  fieldTips.forEach(fieldTip => {
    if (scheme[fieldTip].type === DataTypes.object || scheme[fieldTip].type === DataTypes.objectArray) {
      if (!scheme[fieldTip].scheme) {
        throw new Error('[ObjectService] Missing scheme on object datatype: ' + fieldTip);
      }
      if (followFields.indexOf(fieldTip) === -1) {
        // Add this field to the list of follow fields if not already there
        followFields.push(fieldTip);
      }
      if (schemes.indexOf(scheme[fieldTip].scheme) === -1) {
        // Recurse in to the scheme if we haven't been there already
        const out = scanScheme(scheme[fieldTip].scheme, thisDepth + 1, followFields, schemes);
        if (out.maxDepth > maxDepth) {
          maxDepth = out.maxDepth;
        }
        out.followFields.forEach(followField => {
          if (followFields.indexOf(followField) === -1) {
            followFields.push(followField);
          }
        });
        out.schemes.forEach(val => {
          if (schemes.indexOf(val) === -1) {
            schemes.push(val);
          }
        });
      }
    }
  });

  return { maxDepth, followFields, schemes };
}
