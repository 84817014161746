import { Injectable } from '@angular/core';
import { combineLatest, Observable, ReplaySubject, Subject, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, skip } from 'rxjs/operators';
import { get } from 'lodash';

import { getCache, CacheOpt } from '../util/cache';
import { LoggerService } from '../util/logger.service';
import { FormulaSpec, Parser, QuoteString, Stringify, StaticResultAnalysis } from '../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import dataConstants from './constants';
import { EnoFactory } from './EnoFactory';
import { Batch, Formula, Tip } from './models/types';
import { Eno } from './models/Eno';
import { IVars } from './vars.service';
import { EnsrvService } from './ensrv.service';
import { CurrentDatetimeService, NOW_VAR_NAME } from '../util/current-datetime.service';

const FORMULA_CACHE_MS = 1000 * 60 * 5; // 5 minutes

export interface IFormulaParams {
  formula: Formula;
  context?: Tip;
  contextBranches?: Tip[];
  vars?: IVars;
  cache?: CacheOpt;
}

export interface EnoWithParams {
  eno: Eno;
  params: IFormulaParams;
}

export interface ISegmentResults {
  enoTips: Tip[];
  useCache: EnoWithParams[];
  useCacheThenNetwork: EnoWithParams[];
  useNetworkNoCache: EnoWithParams[];
}

export type FormulaResult = string[];

@Injectable({
  providedIn: 'root'
})
export class FormulaService {
  private formulaOpEnoFactory: EnoFactory = new EnoFactory('op/formula', dataConstants.SECURITY.OP);
  private formulaBroadcasters: {
    [formulaOpTip: string]: {
      subject: Subject<FormulaResult>,
      observable: Observable<FormulaResult>,
      inProgress: boolean;
    }
  } = {};

  constructor(
    private ensrvService: EnsrvService,
    private loggerService: LoggerService,
    private currentDatetimeService: CurrentDatetimeService
  ) {
    this.ensrvService
      .getEnoReceiver('response/formula')
      .subscribe(
        (formulaResponseEno: Eno) => this.handleResponse(formulaResponseEno)
      );
  }

  // These 3 methods are not unit tested as there is no point of doing test and these methods are just wrapper
  public parse(formulaStr: string): FormulaSpec {
    return Parser(formulaStr);
  }

  public stringify(formula: FormulaSpec): string {
    return Stringify(formula);
  }

  public quoteString(str: string): string {
    return QuoteString(str);
  }

  public unquoteString(str: string): string {
    return str.replace(/^\"|\"$/g, '');
  }

  evaluate(
    formula: Formula,
    context?: Tip,
    contextBranches: Tip[] = [dataConstants.BRANCH_MASTER],
    vars: IVars = {},
    useCache: boolean | null = null
  ): Observable<FormulaResult> {
    return this.evaluateBatch([{
      formula,
      context,
      contextBranches,
      vars,
      cache: getCache(useCache)
    }]).pipe(map((v) => v[0]));
  }

  /*
   * @deprecated use formula-multi service
   */
  evaluateBatch(params: IFormulaParams[]): Observable<FormulaResult[]> {

    // If the entire formula batch has static results then simply return them
    try {
      return of(params.map(param => StaticResultAnalysis(param.formula, param.vars)));
    } catch (err) {}

    const segment = this.createAndSegmentEnos(setDefaults(params));
    const { sendBatch, skipList } = this.createSubjectsBatchRequests(segment);

    if (sendBatch.length > 0) {
      this.send(sendBatch);
    }

    // return all eno observables references in the order requested
    const enos$$ = segment.enoTips.map(tip => {
      const obs$ = this.formulaBroadcasters[tip].observable;
      // skip enos that are network only which already have a cached value
      if (skipList.has(tip)) {
        return obs$.pipe(skip(1));
      }
      return obs$;
    });

    // emit this grouping as a single observable
    // only after all enos have emitted at least once
    // don't emit when return values do not change the result
    return combineLatest(enos$$).pipe(distinctUntilChanged());
  }

  createAndSegmentEnos(_params: IFormulaParams[]): ISegmentResults {
    return _params
      .reduce((acc: EnoWithParams[], params) => [{ eno: this.createFormulaEno(params), params }, ...acc], [])
      .reduceRight((acc: ISegmentResults, enoWithParams: EnoWithParams) => {
        // reduceRight to keep the correct tip order

        const { eno, params } = enoWithParams;
        const tip: Tip = eno.tip;

        // return all enos
        // maintain original request order
        acc.enoTips.push(tip);
        if (params.cache === CacheOpt.USE_CACHE) {
          acc.useCache.push(enoWithParams);
        }
        if (params.cache === CacheOpt.USE_CACHE_THEN_NETWORK) {
          acc.useCacheThenNetwork.push(enoWithParams);
        }
        if (params.cache === CacheOpt.USE_NETWORK_NO_CACHE) {
          acc.useNetworkNoCache.push(enoWithParams);
        }

        return acc;
      }, { enoTips: [], useCache: [], useCacheThenNetwork: [], useNetworkNoCache: [] });
  }

  createSubjectsBatchRequests(segment: ISegmentResults): { sendBatch: Batch, skipList: Map<string, boolean> } {
    const { useCache, useNetworkNoCache, useCacheThenNetwork } = segment;
    const sendBatch = [];
    const skipList = new Map();

    batchUseCache.call(this);
    batchUseCacheThenNetwork.call(this);
    batchUseNetworkNoCache.call(this);

    return { sendBatch, skipList };

    // Batch functions

    // create new subjects for any cache enos we dont already have a subject for
    // only batch (request) enos we dont have
    function batchUseCache() {
      useCache.forEach((enoWithParams: EnoWithParams) => {
        const tip = enoWithParams.eno.tip;
        if (!this.isCached(tip)) {
          this.addNewReplaySubjectToCache(enoWithParams);
          sendBatch.push(enoWithParams.eno);
        }
      });
    }

    // create new subjects for any cache enos we dont already have a subject for
    // batch all (request) enos even if we already have them
    function batchUseCacheThenNetwork() {
      useCacheThenNetwork.forEach((enoWithParams) => {
        const tip = enoWithParams.eno.tip;
        if (!this.isCached(tip)) {
          this.addNewReplaySubjectToCache(enoWithParams);
        }
        if (!this.inProgress(tip)) {
          sendBatch.push(enoWithParams.eno);
        }
      });
    }

    // create new subjects for network no cache call and add to batch
    function batchUseNetworkNoCache() {
      useNetworkNoCache.forEach((enoWithParams) => {
        const tip = enoWithParams.eno.tip;
        if (!this.isCached(tip)) {
          this.addNewReplaySubjectToCache(enoWithParams);
        } else {
          // sad code :( sorry this is awkward
          // we dont want to just create a new subject
          // as that would break older subscribers
          // so if we already have cached subject
          // check if it already has a value
          // if it does skip the first result when returning
          let hasValue = false;
          const sub = this.formulaBroadcasters[tip]
            .observable
            .subscribe((result) => hasValue = true);
          sub.unsubscribe();

          if (hasValue) {
            skipList.set(tip, true);
          }
        }
        if (!this.inProgress(tip)) {
          sendBatch.push(enoWithParams.eno);
        }
      });
    }
  }

  private send(batch: Batch) {
    batch.forEach((eno) => {
      this.formulaBroadcasters[eno.tip].inProgress = true;
    });

    this.ensrvService.send(batch).subscribe(
      () => {},
      (error) => {
        batch.forEach(({ tip }) => {
          const formulaBroadcaster = this.formulaBroadcasters[tip];
          formulaBroadcaster.subject.error(error);
        });
      }
    );
  }

  private isCached(tip: Tip): Boolean {
    return Boolean(this.formulaBroadcasters[tip]);
  }

  private inProgress(tip: Tip): Boolean {
    return get(this, ['formulaBroadcasters', tip, 'inProgress'], false);
  }

  private createFormulaEno({ formula, context, contextBranches, vars }: IFormulaParams) {
    const nowVariable = {};
    nowVariable[NOW_VAR_NAME] = [this.currentDatetimeService.getCurrentDatetime()];

    return this.formulaOpEnoFactory
      .setFields([
        { tip: 'op/formula:formula', value: [formula] },
        { tip: 'op/formula:context', value: context ? [context] : [] },
        { tip: 'op/formula:context-branch', value: contextBranches },
        { tip: 'op/formula:vars', value: [JSON.stringify({ ...vars, ...nowVariable })] },
        { tip: 'op/formula:lang', value: dataConstants.ACCEPTABLE_LOCALE_IDS }
      ])
      .makeEno();
  }

  private handleResponse(formulaResponseEno: Eno): void {
    const formulaOpTip = formulaResponseEno.getFieldStringValue('response/formula:op');
    const formulaBroadcaster = this.formulaBroadcasters[formulaOpTip];

    // In case an error happens.
    if (!formulaBroadcaster) {
      return;
    }

    formulaBroadcaster.inProgress = false;
    formulaBroadcaster.subject.next(formulaResponseEno.getFieldValues('response/formula:result'));
  }

  private addNewReplaySubjectToCache({ eno, params }: EnoWithParams) {
    const subject = new ReplaySubject<FormulaResult>(1, FORMULA_CACHE_MS);
    const observable = subject
      .asObservable()
      .pipe(
        catchError((error) => {
          this.loggerService.error(`[FormulaService] Failed to evaluate formula "${params.formula}".`, error);
          this.clearFormulaObservable(eno.tip);
          throw error;
        })
      );

    this.formulaBroadcasters[eno.tip] = {
      subject,
      observable,
      inProgress: false
    };
  }

  private clearFormulaObservable(formulaOpTip) {
    delete this.formulaBroadcasters[formulaOpTip];
  }
}

function setDefaults(params: IFormulaParams[]): IFormulaParams[] {
  return params
    .map((param) => ({
        contextBranches: [dataConstants.BRANCH_MASTER],
        vars: {},
        cache: CacheOpt.USE_CACHE_THEN_NETWORK,
        ...param
      })
    );
}


