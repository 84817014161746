import { ALL_FIELD_DATA_TYPE, EXTENDED_FIELD_DATA_TYPE, FIELD_DATA_TYPE, IField } from '../models/field';
import { DataTypes, IFieldScheme, IObjectScheme } from '../data/models/scheme';
import { AssignedScheme } from '../models/assignable';
import { getDataTypeAndMetaFromField } from './get-data-type-and-meta-from-field';

// array types
const arrayTypeMap = {
  [FIELD_DATA_TYPE.string]: DataTypes.stringArray,
  [FIELD_DATA_TYPE.string_i18n]: DataTypes.i18nArray,
  [FIELD_DATA_TYPE.json]: DataTypes.jsonArray,
  [FIELD_DATA_TYPE.bool]: DataTypes.booleanArray,
  [FIELD_DATA_TYPE.number]: DataTypes.numberArray,
  [FIELD_DATA_TYPE.object]: DataTypes.stringArray,
  [FIELD_DATA_TYPE.datetime]: DataTypes.datetimeArray,
  [FIELD_DATA_TYPE.date]: DataTypes.datetimeArray,
  [FIELD_DATA_TYPE.uri]: DataTypes.uriArray,
  [FIELD_DATA_TYPE.email]: DataTypes.emailArray,
  [FIELD_DATA_TYPE.phone]: DataTypes.phoneArray,
  [FIELD_DATA_TYPE.geography]: DataTypes.geographyArray
};

// types
const typeMap = {
  [FIELD_DATA_TYPE.string]: DataTypes.string,
  [FIELD_DATA_TYPE.string_i18n]: DataTypes.i18n,
  [FIELD_DATA_TYPE.json]: DataTypes.json,
  [FIELD_DATA_TYPE.bool]: DataTypes.boolean,
  [FIELD_DATA_TYPE.number]: DataTypes.number,
  [FIELD_DATA_TYPE.object]: DataTypes.string,
  [FIELD_DATA_TYPE.datetime]: DataTypes.datetime,
  [FIELD_DATA_TYPE.date]: DataTypes.datetime,
  [FIELD_DATA_TYPE.uri]: DataTypes.uri,
  [FIELD_DATA_TYPE.email]: DataTypes.email,
  [FIELD_DATA_TYPE.phone]: DataTypes.phone,
  [FIELD_DATA_TYPE.geography]: DataTypes.geography
};

export function fieldToScheme(field: IField, allFieldDataType?: ALL_FIELD_DATA_TYPE): IFieldScheme {
  const {$tip, datatype, maxcount} = field;

  // TODO test ternary
  const _allFieldDataType = allFieldDataType
    ? allFieldDataType
    : getDataTypeAndMetaFromField({field});
  // extendedDataType will return extended computed data types:
  // file, address etc

  if (_allFieldDataType === EXTENDED_FIELD_DATA_TYPE.assignable) {
    return {
      name: $tip,
      type: DataTypes.object,
      scheme: AssignedScheme,
      mutable: true
    };
  }

  if (maxcount && maxcount === 1) {
    return simpleTypeScheme($tip, typeMap[datatype]);
  }
  return simpleTypeScheme($tip, arrayTypeMap[datatype]);
}

function simpleTypeScheme($tip, type): IObjectScheme {
  return {
    name: $tip,
    type
  };
}


