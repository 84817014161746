import * as tslib_1 from "tslib";
import { FORM_OPTIONS_NOGGIN } from '../form-options-noggin';
import { FORM_OPTIONS_PALLET } from '../form-options-pallet';
export function uniqueFieldKey(field) {
    /*
      Unique field key is just:
       - The "type"
       - & "templateOption.type" (effectively a subtype)
    */
    var subtypePresent = field.templateOptions && field.templateOptions.type;
    var key = subtypePresent
        ? field.type + "-" + field.templateOptions.type
        : field.type;
    return key;
}
export function addNogginConfigToFormField(field) {
    var props = FORM_OPTIONS_NOGGIN[uniqueFieldKey(field)];
    return tslib_1.__assign({}, field, props);
}
export function addPaletteConfigToFormField(field) {
    var uniqueKey = uniqueFieldKey(field);
    var props = FORM_OPTIONS_PALLET[uniqueKey];
    return tslib_1.__assign({}, field, { listId: uniqueKey, key: null }, props);
}
export var transformExtendFormlyFieldToFormBuilderField = function (field) { return addPaletteConfigToFormField(addNogginConfigToFormField(field)); };
