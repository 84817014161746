<div
  class="d-flex flex-column align-items-center justify-content-center eim-forms_form-control"
  [ngClass]="{
    'p-6': size === 'default',
    'p-1': size === 'sm'
  }">
  <img *ngIf="imgSrc" [src]="imgSrc | asset" class="p-1" [ngClass]="{ 'img-sm': size === 'sm' }">
  <span *ngIf="label" class="label" [ngClass]="{ 'p-1': size === 'default' }"><strong >{{ label }}</strong></span>
  <button
    *ngIf="showActionText && actionText; else customAction"
    data-test="action-text"
    class="action pb-2 border-0"
    [ngClass]="{ 'action-not-clickable': !actionClick.observers.length }"
    (click)="onActionClick()"
    (blur)="onActionBlur()">
    {{ actionText }}
  </button>

  <ng-template #customAction>
    <ng-content></ng-content>
  </ng-template>
</div>