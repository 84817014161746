import { Injectable } from '@angular/core';
import { first, map, switchMap } from 'rxjs/operators';
import { IProcessResponse, ProcessService } from '../../data/process.service';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { EnsrvService } from '../../data/ensrv.service';
import { CheckSSOProvidersService } from '../check-ssoproviders.service';
import { get, isEmpty } from 'lodash';
import { EnoFactory } from '../../data/EnoFactory';
import dataConstants from '../../data/constants';
import { Batch } from '../../data/models/types';
import { Eno } from '../../data/models/Eno';
import { AuthService, SignInStatus } from '../auth.service';
import { AuthRegisterErrors } from './auth-register-errors';

interface InviteParams {
  token: string;
  email: string;
  password?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AcceptInviteService {

  constructor(
    private processService: ProcessService,
    private ensrvService: EnsrvService,
    private checkSSOProvider: CheckSSOProvidersService,
    private authService: AuthService
  ) { }

  acceptInvitePassword(inviteParams: InviteParams): Observable<SignInStatus> {

    /*
    * we want to call set password then updateInviteInfo
    * as as soon as we update the invite info we can no longer set the password
    * as there is a guard on this route
    */

    return this.setPassword(inviteParams).pipe(
      switchMap(() =>  this.updateInviteInfo(inviteParams)),
      switchMap(() => {
        return this.authService.signIn(inviteParams.email, inviteParams.password);
      })
    );
  }

  acceptInviteSSO(inviteParams: InviteParams) {
    return this.updateInviteInfo(inviteParams)
      .pipe(
        switchMap(() => {
          return this.checkSSOProvider.checkEmailAddressAndRedirect$(inviteParams.email);
        }));
  }

  setPassword({email, token, password}: InviteParams) {
    const registerOpEnoFactory: EnoFactory = new EnoFactory('op/auth/register', dataConstants.SECURITY.OP);
    const registerOpEno = registerOpEnoFactory
      .setFields([
        { tip: 'op/auth/register:key', value: [email] },
        { tip: 'op/auth/register:token', value: [token] },
        { tip: 'op/auth/register:secret', value: [password] },
        { tip: 'op/auth/register:payload', value: [email] }
      ])
      .makeEno();

    return this.ensrvService.send([registerOpEno])
      .pipe(
        first(),
        map(extractRegisterEnoAndErrorsIfPresent),
        switchMap(({errors, registerEno}) => {
          /*
          * We don't want to throw an error if the user already exists
          * As the update invite might have just failed and we want to
          * let the user retry that method after a refresh
          */
          if (errorOtherThanUserExists(errors)) {
            return throwError(errors);
          }

          return of(registerEno);
        }),
      );
  }

  updateInviteInfo({email, token}: InviteParams): Observable<IProcessResponse> {
    return this.processService
      .start('eim/process/users/update-invite-info', {
        'Email': [email],
        'Token': [token]
      })
      .pipe(
        first(),
        switchMap((res: IProcessResponse) => {
          if (get(res, 'vars.Token invalid[0]', false)) {
            return throwError('Invalid token passed');
          }
          if (get(res, 'vars.User status not invited[0]', false)) {
            return throwError('User status not invited');
          }
          return of(res);
        }));
  }
}

interface IRegisterEnoPlusError {
  registerEno: Eno | null;
  errors: string[] | null;
}

export function errorOtherThanUserExists(errors): boolean {
  return !isEmpty(errors) && !errors.includes(AuthRegisterErrors.userExists);
}

export function extractRegisterEnoAndErrorsIfPresent(batch: Batch): IRegisterEnoPlusError {
  return batch.reduce(
    (acc: IRegisterEnoPlusError, eno: Eno) => {
    if (eno.getType() === 'response/auth/register') {
      acc.registerEno = eno;
    }

    if (eno.getType() === 'error') {
      const errors = eno.getFieldValues('error/message/tip');
      acc.errors = [...acc.errors, ...errors];
    }

    return acc;
  }, {registerEno: null, errors: []});
}
