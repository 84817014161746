import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
var FormlyFieldCheckboxComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FormlyFieldCheckboxComponent, _super);
    function FormlyFieldCheckboxComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.defaultOptions = {
            templateOptions: {
                indeterminate: false,
            },
        };
        return _this;
    }
    FormlyFieldCheckboxComponent.prototype.ngOnInit = function () {
        if (typeof this.formControl.value !== 'boolean') {
            this.formControl.patchValue(false);
        }
    };
    return FormlyFieldCheckboxComponent;
}(FieldType));
export { FormlyFieldCheckboxComponent };
