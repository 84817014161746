<app-auth-chrome
    *ngIf="isTokenValid; else invalidToken"
    [showMessage]="!!errorStatus"
    [showAuthErrorDesc]="errorStatus === 'weakPassword'"
    i18n-primaryTitle="@@acceptInvite.authChrome.primaryTitle"
    primaryTitle="Welcome! You accepted an invite for {{ email }}.">
  <ng-container
      eim-i18n-property="secondaryTitle"
      i18n="@@acceptInvite.authChrome.secondaryTitle">{authSecondaryTitle, select, enter {Please enter your details to activate your account} wait {Please wait while we activate your account}}</ng-container>
  <ng-container
      eim-i18n-property="message"
      i18n="@@acceptInvite.authChrome.message">{
    errorStatus,
      select,
      weakPassword {Your password is not strong enough.}
      otherError {This invitation is no longer valid. Please contact your administrator to resend.}
      }</ng-container>
  <ng-container
      eim-i18n-property="authErrorDesc"
      i18n="@@acceptInvite.authChrome.authErrorDesc">{
    errorStatus,
      select,
      weakPassword {Be a password superhero! Don’t use a password from another site or something too obvious, like your birthday or pet’s name. Use at least 5 characters. Mix it up with random uppercase letters, symbols, and even try breaking the spelling rules (we won’t tell).}
      }</ng-container>
  <div data-test="checking-email-spinner" [appSpinner]="checkingEmail"></div>
  <form
      *ngIf="!checkingEmail && passwordSignIn"
      [formGroup]="acceptInviteForm"
      [appSpinner]="state==='in-progress'"
      (ngSubmit)="acceptInviteForm.valid && onAcceptInvite()">
    <div class="form-group">
      <label
          i18n="@@acceptInvite.authChrome.acceptInviteForm.newPasswordLabel"
          for="password">New password</label>
      <input class="form-control" id="password" type="password" formControlName="password">
    </div>
    <div class="form-group">
      <label
          i18n="@@acceptInvite.authChrome.acceptInviteForm.confirmPasswordLabel"
          for="confirmPassword">Confirm password</label>
      <input class="form-control" id="confirmPassword" type="password" formControlName="again">
    </div>
    <button
        i18n="@@acceptInvite.authChrome.acceptInviteForm.button"
        class="btn btn-block eim-button_noggin"
        type="submit">{buttonText, select, logIn {Log in} activateAccount {Activate account}}</button>
  </form>
</app-auth-chrome>
<ng-template #invalidToken>
  <app-auth-chrome>
    <div class="text-center">
      <h6 i18n="@@acceptInvite.authChrome.text-1">Sorry, we cannot activate your account as your invitation is expired.</h6>
      <h6 i18n="@@acceptInvite.authChrome.text-2">Please contact your system administrator.</h6>
    </div>
  </app-auth-chrome>
</ng-template>
