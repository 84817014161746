/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./side-sheet-wrapper.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../side-sheet-content.directive";
import * as i3 from "./side-sheet-wrapper.component";
var styles_SideSheetWrapperComponent = [i0.styles];
var RenderType_SideSheetWrapperComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SideSheetWrapperComponent, data: {} });
export { RenderType_SideSheetWrapperComponent as RenderType_SideSheetWrapperComponent };
function View_SideSheetWrapperComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_SideSheetWrapperComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { contentHost: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "sidesheet-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideSheetWrapperComponent_1)), i1.ɵdid(3, 16384, [[1, 4]], 0, i2.SideSheetContentDirective, [i1.ViewContainerRef], null, null)], null, null); }
export function View_SideSheetWrapperComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-side-sheet-wrapper", [], [[4, "width", null]], null, null, View_SideSheetWrapperComponent_0, RenderType_SideSheetWrapperComponent)), i1.ɵdid(1, 4243456, null, 0, i3.SideSheetWrapperComponent, [i1.ComponentFactoryResolver], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).width; _ck(_v, 0, 0, currVal_0); }); }
var SideSheetWrapperComponentNgFactory = i1.ɵccf("app-side-sheet-wrapper", i3.SideSheetWrapperComponent, View_SideSheetWrapperComponent_Host_0, {}, {}, []);
export { SideSheetWrapperComponentNgFactory as SideSheetWrapperComponentNgFactory };
