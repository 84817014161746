import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { first, map, switchMap, take, tap } from 'rxjs/operators';
import { head, get } from 'lodash';
import { ObjectService as DataObjectService } from '../data/object-service/object.service';
import { EnoService } from '../data/eno.service';
import { IObjectScheme } from '../data/models/scheme';
import { IType, typeScheme } from '../models/type';
import { Batch, Tip } from '../data/models/types';
import { fieldToScheme } from '../util/field-to-scheme';
import { IObjectTitle } from '../models/title';
import { FormulaResult, FormulaService } from '../data/formula.service';
import { IQueryExtraInfo, QueryService } from '../data/query.service';
import { isSuperType } from './type-chooser-side-sheet/super-types';
import { CacheOpt } from '../util/cache';
import { supertypeScheme } from '../models/supertype';
import { getDataTypeAndMetaFromField } from '../util/get-data-type-and-meta-from-field';
import { IField } from '../models/field';
import { Eno } from '../data/models/Eno';
import { hasSubObjectSecurityLabel } from '../util/sub-objects/sub-object-security';

export interface IObjectTypeAndScheme {
  objectType: IType;
  objectScheme: IObjectScheme;
}

export interface IObjectAndType extends IObjectTypeAndScheme {
  objectData: any;
  deleteOnSave?: true;
}

export interface IObjectTypeSchemeSubObject extends IObjectAndType {
  isSubObject: boolean;
}

interface IFieldAndScheme {
  field: IField;
  scheme: IObjectScheme;
}

@Injectable({
  providedIn: 'root'
})
export class ObjectService {
  constructor(
    private dataObjectService: DataObjectService,
    private enoService: EnoService,
    private formulaService: FormulaService,
    private queryService: QueryService
  ) {}

  // Generates and returns a scheme for the given object type
  getScheme(type: IType): IObjectScheme {
    return type.field.reduce((scheme: IObjectScheme, field) => {
      const { $tip } = field;
      const { _datatype } = getDataTypeAndMetaFromField({ field });
      scheme[$tip] = fieldToScheme(field, _datatype);
      return scheme;
    }, {});
  }

  // Returns an observable with the object data, type, and scheme
  // todo rename to objectTypeAndScheme because that's what it is
  getObjectAndType(tip: string, branchTip?: string, cache?: CacheOpt): Observable<IObjectAndType> {
    const objectAndType: IObjectAndType = { objectData: null, objectType: null, objectScheme: null };

    return this.enoService.readEno(tip, { branch: branchTip, useCache: cache }).pipe(
      take(1),
      switchMap(objectEno => this.dataObjectService.getObject<IType>(objectEno.source.type, typeScheme)),
      tap(objectType => objectAndType.objectType = objectType),
      map((objectType: IType) => this.getScheme(objectType)),
      tap((scheme: IObjectScheme) => objectAndType.objectScheme = scheme),
      // this is the same object we already just got
      switchMap(() => this.dataObjectService.getObject(tip, objectAndType.objectScheme, branchTip)),
      tap(objectData => objectAndType.objectData = objectData),
      map(() => objectAndType)
    );
  }

  getObjectTypeSchemeSubObject(tip: string, branchTip?: string, cache?: CacheOpt): Observable<IObjectTypeSchemeSubObject> {
    return this.getObjectAndType(tip, branchTip, cache)
      .pipe(
        switchMap((objectAndType: IObjectAndType) => {
          const security = get(objectAndType, 'objectData.$security', null);
          return this.enoService
            .readEno(security)
            .pipe(map((securityEno: Eno) => {
              // all sub objects will have this variant label
              const securityTip: Tip = head(securityEno.getFieldValues('security/policy/label'));
              const isSubObject = hasSubObjectSecurityLabel(securityTip);

              return {
                ...objectAndType,
                isSubObject
              };
            }));
        })
      );
  }

  getObjectsDataWithTypeAndScheme(tips: Tip[]): Observable<IObjectAndType[]> {
    // eventually make this better than just
    // plural get objects just get the object type once if multiple objects are the same type
    const obt$$ = tips.map((tip: Tip) => this.getObjectAndType(tip).pipe(first()));
    return forkJoin(obt$$);
  }

  // Returns an observable with type and scheme, but empty data
  getType(typeTip: string): Observable<IObjectAndType> {
    const objectAndType: IObjectAndType = { objectData: {}, objectType: null, objectScheme: null };

    return this.getPlainType(typeTip)
      .pipe(
        tap(objectType => objectAndType.objectType = objectType),
        map((objectType) => this.getScheme(objectType)),
        tap((scheme: IObjectScheme) => objectAndType.objectScheme = scheme),
        map(() => objectAndType)
      );
  }

  getPlainType(typeTip: Tip): Observable<IType> {
    const objectTypeScheme = isSuperType(typeTip) ? supertypeScheme : typeScheme;
    return this.dataObjectService.getObject<IType>(typeTip, objectTypeScheme);
  }

  // Updates an object
  updateObject(objectData: object, objectScheme: IObjectScheme, branchTip?: string): Observable<string> {
    return this.dataObjectService.setObject(objectData, objectScheme, branchTip).pipe(
      map((batch: Batch) => {
        if (batch.length > 0) {
          return batch[batch.length - 1].tip;
        }
        // Nothing has been changed
        return null;
      })
    );
  }

  // Deletes an object
  deleteObject(objectData: object): Observable<boolean> {
    return this.dataObjectService.deleteObject(objectData).pipe(take(1));
  }

  getTitles(tips: Tip[]): Observable<IObjectTitle[]> {
    if (!tips || tips.length === 0) {
      return of([]);
    }

    if (!Array.isArray(tips)) {
      tips = [tips];
    }

    return this.formulaService.evaluate(`TITLE(ARRAY("${tips.join('","')}"))`).pipe(
      map((result: FormulaResult) => {
        return tips.map((tip, index: number) => {
          return {
            $tip: tip,
            title: result[index]
          };
        });
      })
    );
  }

  getAllTypesExtendingSupertypes$<T>(
    superTypeTips: Tip[],
    dynamicFilters: IQueryExtraInfo[] = [],
    dynamicAttrs: IQueryExtraInfo[] = []
  ): Observable<T[]> {
    return this.queryService.execute1dFirst<T>(
      'eim/query/get-all-type-tips-extending-super-type',
      {
        dimensionOptions: [
          {
            label: 'Tip dimension',
            formula: 'TIP()'
          }
        ],
        extraAttributes: dynamicAttrs,
        extraFilters: dynamicFilters,
        vars: {
          'Super type tips': superTypeTips
        }
      }
    );
  }

  isSuperType$(typeTip): Observable<boolean> {
    return of(isSuperType(typeTip));
  }
}
