/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./accept-verification.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "../auth-chrome/auth-chrome.component.ngfactory";
import * as i4 from "../auth-chrome/auth-chrome.component";
import * as i5 from "@angular/common";
import * as i6 from "./accept-verification.component";
var styles_AcceptVerificationComponent = [i0.styles];
var RenderType_AcceptVerificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AcceptVerificationComponent, data: {} });
export { RenderType_AcceptVerificationComponent as RenderType_AcceptVerificationComponent };
function View_AcceptVerificationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h6", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your email address is verified."])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["class", "btn btn-block eim-button_noggin"], ["routerLink", "/signin"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Log back in"]))], function (_ck, _v) { var currVal_0 = "/signin"; _ck(_v, 4, 0, currVal_0); }, null); }
function View_AcceptVerificationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h6", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sorry, we cannot verify your email address."])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h6", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please contact your system administrator."]))], null, null); }
export function View_AcceptVerificationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-auth-chrome", [], null, null, null, i3.View_AuthChromeComponent_0, i3.RenderType_AuthChromeComponent)), i1.ɵdid(1, 114688, null, 0, i4.AuthChromeComponent, [], null, null), (_l()(), i1.ɵand(16777216, null, 3, 1, null, View_AcceptVerificationComponent_1)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["invalidToken", 2]], 3, 0, null, View_AcceptVerificationComponent_2))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.isTokenValid; var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_AcceptVerificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-accept-verification", [], null, null, null, View_AcceptVerificationComponent_0, RenderType_AcceptVerificationComponent)), i1.ɵdid(1, 114688, null, 0, i6.AcceptVerificationComponent, [i2.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AcceptVerificationComponentNgFactory = i1.ɵccf("app-accept-verification", i6.AcceptVerificationComponent, View_AcceptVerificationComponent_Host_0, {}, {}, []);
export { AcceptVerificationComponentNgFactory as AcceptVerificationComponentNgFactory };
