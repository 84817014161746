import { Injectable } from '@angular/core';
import { IMessageCapableCollection } from './shared/recipient-category-chooser/recipient-category-chooser.component';
import { Formula, Tip } from '../data/models/types';
import * as createMoment from 'moment';
import { Moment } from 'moment';
import { IQueryExtraInfo } from '../data/query.service';
import { isEmpty, head } from 'lodash';
import { MESSAGE_TYPE } from '../models/message';
import { IWorkflowVariables } from '../models/workflow';
import { FormulaSpec, Parser, Stringify } from '../object/field-formula-side-sheet/field-formula-side-sheet/formula';
import { getContextFromContextFormula } from '../util/context-formula-wrapper';

interface ITypeToKey {
  [enoType: string]: string;
}

export const recipientTypeToKey: ITypeToKey = {
  'app/group': 'groups',
  'app/org-unit': 'organizationalUnits',
  'app/person': 'people',
  'app/organization': 'people',
  'app/team': 'teams',
  'app/team-role': 'roles',
  'query': 'filters',
  'app/email/addhoc-address': 'otherContacts',
  'app/sms/addhoc-number': 'otherContacts',
  'app/voice-message/addhoc-number': 'otherContacts'
};

export const customContactsToKey = {
  'customContactType': 'people'
}

export enum OTHER_CONTACT_STATUS {
  LOADING,
  EDITING
}

export enum SENDING_STATUS {
  SENDING,
  EDITING
}

export enum MESSAGE_REQUEST_STATUS {
  SENT = 'Sent',
  FAILED = 'Failed'
}

export const CONTEXT_VAR_NAME = 'Dashboard context tip';

@Injectable({
  providedIn: 'root'
})
export class MessageUtilsService {

  constructor() {
  }

  static generateTemplateFilters(
    contextTypeTips?: Tip[],
    messageType: MESSAGE_TYPE = MESSAGE_TYPE.EMAIL,
    innerType: boolean = true
  ): IQueryExtraInfo[] {
    const formulaNoContext = `EQUALS(FIELD("app/${ messageType }/outbound-template:context-type"),ARRAY())`;
    if (!isEmpty(contextTypeTips) && !isEmpty(head(contextTypeTips))) {
      let typeFormulas: Formula = Stringify({ name: 'ARRAY', args: contextTypeTips });
      if (!innerType) {
        typeFormulas = `ARRAY(${ typeFormulas },FIELD_VALUES("app/object-type:type",${ typeFormulas }))`;
      }

      return [{
        label: 'templates of current or no context',
        formula: `OR(INARRAY(${ typeFormulas },FIELD("app/${ messageType }/outbound-template:context-type")),${ formulaNoContext })`
      }];
    } else {
      return [{
        label: 'templates with no context',
        formula: formulaNoContext
      }];
    }
  }

  getEmptyCollection(): IMessageCapableCollection {
    return {
      groups: [], organizationalUnits: [], people: [], teams: [], roles: [], filters: [], otherContacts: [],
      workflowInputs: [], workflowActors: [], workflowVars: []
    };
  }

  isCollectionEmpty(collection: IMessageCapableCollection): boolean {
    if (!collection) {
      return true;
    }
    return !Object.entries(collection).some(([key, value]) => value.length);
  }

  getFlattenedTipsFromCollection(collection: IMessageCapableCollection): Tip[] {
    return Object.keys(collection).reduce((prev: Tip[], category: string) => {
      return prev.concat(collection[category]);
    }, []);
  }

  isMessageCapableCollectionEmpty(messageCapables: IMessageCapableCollection): boolean {
    for (const recipientType in messageCapables) {
      if (messageCapables[recipientType].length > 0) {
        return false;
      }
    }

    return true;
  }

  datetimeToMetaString(datetime: Moment): string {
    const dateFormat = 'DD-MM-YYYY';

    if (datetime.format(dateFormat) === createMoment().format(dateFormat)) {
      return datetime.format('LT');
    }

    return datetime.format('D MMM');
  }

  addFormulasToCollection(collection: IMessageCapableCollection, formulas: string[]) {
    collection.workflowInputs = (formulas || []).filter(formula => formula.indexOf(`VAR("Input-`) > -1);
    collection.workflowVars = (formulas || []).filter(formula => formula.indexOf(`VAR("Variable-`) > -1);
    collection.workflowActors = (formulas || []).filter(formula => formula.indexOf(`VAR("Actor-`) > -1);
  }

  getWorkflowPropFromFormula(formula: string, propTypeKey: 'inputs' | 'actors' | 'variables', workflowVariables: IWorkflowVariables) {
    // get workflow prop key by extracting part in between VAR(" and ")
    let propKey;
    const parsedFormula = Parser(formula);
    if (parsedFormula.name === 'VAR') {
      propKey = parsedFormula.args[0] as string;
    } else {
      propKey = Parser(getContextFromContextFormula(formula)).args[0] as string;
    }
    // get input property by key
    return workflowVariables[propTypeKey].find(x => x.key === propKey);
  }

  // combines emailable workflow var formulas into single array of formulas 
  getFlattenedFormulasFromCollection(collection: IMessageCapableCollection): string[] {
    return [
      ...collection.workflowInputs || [],
      ...collection.workflowVars || [],
      ...collection.workflowActors || []
    ];
  }

}
