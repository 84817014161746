import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, of, Observable, from, Subscription } from 'rxjs';
import { map, first, tap, take } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { get } from 'lodash';

import {
  ModalConfirmDeletionComponent,
  modalConfirmDeletionOptions
} from '../../../shared/modals/modal-confirm-deletion/modal-confirm-deletion.component';
import { SheetSize, SideSheetService } from '../../../side-sheet/side-sheet.service';
import { IPrintProps, DEFAULT_PRINT_TEMPLATE, DEFAULT_SETTING_PRINT } from '../../../print/print';
import { PrintHtmlPreviewSideSheetComponent } from '../../../print/print-html-preview-side-sheet/print-html-preview-side-sheet.component';
import { Tip } from '../../../data/models/types';
import { ISecurityPolicy } from '../../../data/security.service';
import { ObjectSecuritySideSheetComponent } from '../../../object/object-security-side-sheet/object-security-side-sheet.component';
import { IObjectLaunchProps } from '../../object-launch';
import { EditFormInstanceService } from './edit-form-instance.service';
import { FormGroup } from '@angular/forms';
import { FormulaResult, FormulaService } from '../../../data/formula.service';
import { Stringify } from '../../../object/field-formula-side-sheet/field-formula-side-sheet/formula';

@Component({
  selector: 'app-edit-form-side-sheet',
  templateUrl: './edit-form-side-sheet.component.html',
  styleUrls: ['./edit-form-side-sheet.component.scss'],
  // THIS IS IMPORTANT TO PREVENT EXPRESSION
  // CHANGED AFTER CHECKED ERROR
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EditFormInstanceService]
})
export class EditFormSideSheetComponent implements OnDestroy {
  // these four observables could be rationalised into one
  form$ = this.editFormInstanceService.form$;
  typeFields$ = this.editFormInstanceService.typeFields$;
  objectData$ = this.editFormInstanceService.objectData$;
  formOptions$ = this.editFormInstanceService.formOptions$;

  loading$ = this.editFormInstanceService.loading$;
  appAlert$ = this.editFormInstanceService.appAlert$;
  deletable$ = this.editFormInstanceService.deletable$;
  formDisabledStatus$: BehaviorSubject<boolean> = this.editFormInstanceService.formDisabledStatus$;
  formTitlePrefix$ = new BehaviorSubject<IFormTitlePrefixPostFix>({ prefix: '', postfix: '' });
  formTitle$ = combineLatest([this.form$, this.formTitlePrefix$]).pipe(map(extractTitle));
  formSize: SheetSize = 'sm';

  object;
  securityPolicyTip: Tip;
  bespokeSecurityPolicy: ISecurityPolicy;
  actionText = '';
  typeLabel = '';

  formValid = false;
  formDirty = false;

  preview = false;
  hideSecurityOptions = false;

  done$ = this.editFormInstanceService.done$;

  printProps: IPrintProps;
  isEdit = false;
  showFormBreadcrumbs = false;
  formBreadcrumbs$ = new BehaviorSubject<string>('');
  subs: Subscription;
  formGroup = new FormGroup({});
  showErrorBanner = false;

  constructor(
    private sideSheetService: SideSheetService,
    private formulaService: FormulaService,
    private editFormInstanceService: EditFormInstanceService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef
  ) {}

  print() {
    const { componentInstance } = this.sideSheetService.push(PrintHtmlPreviewSideSheetComponent) as {
      componentInstance: PrintHtmlPreviewSideSheetComponent
    };

    componentInstance.setProps(this.printProps);
  }

  setProps(params: IObjectLaunchProps) {
    this.formSize = this.getFormSize(get(params, 'formObjectAndType.form.size'));
    this.showFormBreadcrumbs = get(params, 'formObjectAndType.form.showFormBreadcrumbs', false);
    this.hideSecurityOptions = get(params, 'hideSecurityOptions', false);
    this.securityPolicyTip = get(params, 'securityPolicyTip', undefined);
    this.editFormInstanceService.load(params);
    this.formTitlePrefix$.next(formTitlePrefixPostFix(params));
    this.actionText = this.getActionText(params);
    this.typeLabel = getTypeLabel(params.formObjectAndType.form);
    this.preview = params.preview || false;
    this.printProps = this.getPrintProps(params);
    this.isEdit = !!params.objectTip;

    this.subs = this.getTitleForObject(params).pipe(
      ).subscribe((value: FormulaResult | string) => {
      if (value) {
        if (Array.isArray(value)) {
          this.sideSheetService.pushBreadcrumbSuffix(value[0] as string);
        } else {
          this.sideSheetService.pushBreadcrumbSuffix(value as string);
        }
      } else {
        this.sideSheetService.pushBreadcrumbSuffix('');
      }
      this.formBreadcrumbs$.next(this.sideSheetService.generateBreadcrumbString());
    });
  }

  private getTitleForObject(params: IObjectLaunchProps): Observable<FormulaResult | string> {
    if (formTitlePrefixPostFix(params).prefix === 'New') {
      return of('new');
    } else {
      const objectTip = get(params, 'formObjectAndType.objectAndType.objectData.$tip', null);
      if (objectTip) {
        const titleFormula = Stringify({
          name: 'TITLE',
          args: [
            objectTip
          ]
        });
        return this.formulaService.evaluate(titleFormula);
      } else {
        return of('new');
      }
    }
  }

  getPrintProps(params: IObjectLaunchProps) {
    let printProps: IPrintProps;
    const form = params.formObjectAndType.form;

    printProps = {
      templateTip: DEFAULT_PRINT_TEMPLATE,
      settingTip: DEFAULT_SETTING_PRINT,
      contextTip: params.objectTip
    };

    if (get(form, '$tip', false)) {
      printProps.formTip = form.$tip;
    }

    return printProps;
  }

  save() {
    this.formGroup.updateValueAndValidity();

    if (!this.formValid) {
      this.showErrorBanner = true;
      this.formGroup.markAllAsTouched();
      return;
    }
    this.editFormInstanceService.save(
      this.object,
      { securityPolicyTip: this.securityPolicyTip, bespokeSecurityPolicy: this.bespokeSecurityPolicy }
    );

    this.sideSheetService.makeClean();
  }

  delete() {
    const modalRef = this.modalService.open(ModalConfirmDeletionComponent, modalConfirmDeletionOptions);
    const confirmDeleteComponent = modalRef.componentInstance as ModalConfirmDeletionComponent;
    confirmDeleteComponent.objectType = this.typeLabel;
    modalRef.result
      .then((confirm) => {
        if (confirm) {
          this.editFormInstanceService.delete();
        }
      });
  }

  setFormObject($event) {
    this.object = $event;
  }

  formStatus($event) {
    this.formValid = $event.valid;
    if ($event.dirty && !this.formDirty) {
      this.formDirty = true;
      this.sideSheetService.makeDirty();
    }
    this.setFormStatus(this.formValid);
  }

  setFormGroup($event) {
    this.formGroup = $event;
  }

  getActionText(params: IObjectLaunchProps) {
    if (params.preview) {
      return '';
    }

    if (params.subObject) {
      return 'Done';
    }

    return 'Save';
  }

  // Opens the object security side sheet
  setSecurity() {
    this.getSecurity$().pipe(
      first(),
      tap(securityPolicyTip => {
        const sheetRef = this.sideSheetService.push(ObjectSecuritySideSheetComponent);
        if (this.bespokeSecurityPolicy) {
          sheetRef.componentInstance.bespokeSecurityPolicy = this.bespokeSecurityPolicy;
        } else {
          sheetRef.componentInstance.securityPolicyTip = securityPolicyTip;
        }
        sheetRef.componentInstance.onDone = (newSecurityPolicyTip: Tip, newBespokeSecurityPolicy: ISecurityPolicy) => {
          if (newSecurityPolicyTip) {
            this.securityPolicyTip = newSecurityPolicyTip;
            this.bespokeSecurityPolicy = null;
          } else {
            this.securityPolicyTip = null;
            this.bespokeSecurityPolicy = newBespokeSecurityPolicy;
          }
          this.setFormStatus(this.formValid);
          this.cdr.detectChanges();
        };
      })
    ).subscribe();
  }

  // Returns an observable with the current security tip
  getSecurity$(): Observable<Tip> {
    if (this.securityPolicyTip) {
      return of(this.securityPolicyTip);
    }

    return this.objectData$.pipe(
      map(objectData => objectData.$security)
    );
  }

  setFormStatus($valid: boolean) {
    const disabled = !$valid;
    this.formDisabledStatus$.next(disabled);
  }

  getFormSize(formSize) {
    switch (formSize) {
      case 'large':
        return 'lg';
      case 'extraLarge':
        return 'xl';
      default:
        return 'sm';
    }
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }
}

interface IFormTitlePrefixPostFix {
  prefix: string;
  postfix: string;
}

function formTitlePrefixPostFix(params: IObjectLaunchProps): IFormTitlePrefixPostFix {
  if (params.preview) {
    return { prefix: 'Preview', postfix: '' };
  }

  if (params.subObject) {
    if (typeof params.subObject === 'boolean') {
      return { prefix: 'New', postfix: '' };
    }
    return { prefix: 'Edit', postfix: '' };
  }

  return params.objectTip
    ? { prefix: 'Edit', postfix: '' }
    : { prefix: 'New', postfix: '' };
}

function getTypeLabel(form) {
  return get(form, 'contextType.type.label', '');
}

function getTitleOverride(form) {
  return get(form, 'titleOverride', null);
}

function extractTitle([form, { prefix, postfix }]: [string, IFormTitlePrefixPostFix]) {
  const typeTitle = getTypeLabel(form);
  const titleOverride = getTitleOverride(form);

  if (titleOverride) {
    return titleOverride;
  }

  if (typeTitle) {
    return `${prefix} ${typeTitle} ${postfix}`;
  }

  return '';
}
