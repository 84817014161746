import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, of, Subject } from 'rxjs';
import { isEmpty } from 'lodash';
import { OBJECT_LAUNCH_OPERATION } from '../../formly-noggin/object-launch';
import { SideSheetListModes } from '../side-sheet-list/side-sheet-list.component';
import { tap } from 'rxjs/internal/operators/tap';
import { ListCreateSideSheetComponent } from '../../settings/modules/lists/list-create-side-sheet/list-create-side-sheet.component';
import { catchError, first, map, shareReplay } from 'rxjs/operators';
import { LoadingState } from '../../shared/constants';
import { SequenceEditSideSheetComponent } from '../../settings/modules/sequence/sequence-edit-side-sheet/sequence-edit-side-sheet.component';
var SideSheetObjectChooserComponent = /** @class */ (function () {
    function SideSheetObjectChooserComponent(sideSheetService, objectLaunch, objectBehaviourService, objectTypeService, sideSheetObjectChooserService, cdr, sentryService) {
        this.sideSheetService = sideSheetService;
        this.objectLaunch = objectLaunch;
        this.objectBehaviourService = objectBehaviourService;
        this.objectTypeService = objectTypeService;
        this.sideSheetObjectChooserService = sideSheetObjectChooserService;
        this.cdr = cdr;
        this.sentryService = sentryService;
        this.unsubscribe$ = new Subject();
        // used to show the module name when typeTip is a module config item. For example : 'app/list'
        this.showModuleInfo = false;
        this.extraFilters = [];
        this.selected = [];
        this.queryAndFilter = {};
        this.allowNavAway = true;
        this.canCreateNew = true;
        this.queryContainer = [];
        this.limit = 50;
        this.offset = 0;
        this.showMoreButton = false;
        this.showError = false;
        this.excludeList = []; // use if you dont want to show any specific tips
        this.hasQueryResults$ = combineLatest(this.queryContainer).pipe(map(function (results) { return results.reduce(function (totalLength, result) { return totalLength + result.length; }, 0) !== 0; }));
    }
    SideSheetObjectChooserComponent.prototype.ngOnInit = function () {
        this.loadData({ initialLoad: true });
        this.showLinksToObjects();
    };
    SideSheetObjectChooserComponent.prototype.loadData = function (_a) {
        var _this = this;
        var initialLoad = _a.initialLoad;
        this.loadingState = LoadingState.inProgress;
        var queryParams = {
            typeTips: this.typeTips,
            extraFilters: this.extraFilters,
            queryAndFilter: this.queryAndFilter,
            showModuleInfo: this.showModuleInfo,
            limit: this.limit + 1,
            offset: this.offset
        };
        var query$ = this.sideSheetObjectChooserService
            .loadObjects(queryParams, this.searchValue)
            .pipe(map(function (queryResults) {
            return queryResults.filter(function (queryResult) {
                if (_this.excludeList) {
                    return _this.excludeList.indexOf(queryResult.$tip) === -1;
                }
                return true;
            });
        }), tap(function (queryResults) {
            if (initialLoad && isEmpty(queryResults) && _this.canCreateNew) {
                _this.loadingState = LoadingState.loaded;
                _this.addObject();
            }
            else {
                _this.offset += _this.limit;
                _this.showMoreButton = !(queryResults.length <= _this.limit);
                _this.loadingState = LoadingState.loaded;
            }
        }), shareReplay(1), catchError(function (err) {
            _this.showError = true;
            _this.loadingState = LoadingState.failed;
            return of(err);
        }));
        this.queryContainer.push(query$);
        this.cdr.detectChanges();
    };
    SideSheetObjectChooserComponent.prototype.showLinksToObjects = function () {
        var _this = this;
        if (!this.allowNavAway) {
            return;
        }
        if (!isEmpty(this.typeTips)) {
            this.objectTypeService
                .getObjectType$(this.typeTips[0])
                .subscribe(function (value) { return _this.allowNavAway = !!value; });
        }
    };
    SideSheetObjectChooserComponent.prototype.onCancel = function () {
        this.goBack
            ? this.goBack()
            : this.sideSheetService.pop();
    };
    Object.defineProperty(SideSheetObjectChooserComponent.prototype, "isSingleSelect", {
        get: function () {
            return this.listType === SideSheetListModes.SINGLE_SELECT;
        },
        enumerable: true,
        configurable: true
    });
    SideSheetObjectChooserComponent.prototype.selectedChange = function () {
        if (this.isSingleSelect) {
            this.onDone();
        }
    };
    SideSheetObjectChooserComponent.prototype.onDone = function () {
        this.sideSheetService.pop();
        this.done({
            selected: this.selected
        });
    };
    SideSheetObjectChooserComponent.prototype.addObject = function () {
        var _this = this;
        var params = {
            typeTip: this.typeTips[0]
        };
        if (params.typeTip === 'app/list') {
            var componentInstance = this.sideSheetService
                .push(ListCreateSideSheetComponent).componentInstance;
            componentInstance.moduleTip = this.moduleTip;
            componentInstance.done = function () { return _this.loadData({ initialLoad: false }); };
            return;
        }
        else if (params.typeTip === 'app/sequence') {
            var componentInstance = this.sideSheetService
                .push(SequenceEditSideSheetComponent).componentInstance;
            componentInstance.moduleTip = this.moduleTip;
            componentInstance.done = function () { return _this.loadData({ initialLoad: false }); };
            return;
        }
        this.objectLaunch.pushSheet(params, this.unsubscribe$)
            .subscribe(function (_a) {
            var objectTip = _a.objectTip;
            _this.selected = tslib_1.__spread(_this.selected, [objectTip]);
            _this.objectLaunch.pop();
        });
    };
    SideSheetObjectChooserComponent.prototype.onShowMore = function () {
        this.loadData({ initialLoad: false });
    };
    SideSheetObjectChooserComponent.prototype.loadSearchData = function (searchValue) {
        this.offset = 0;
        this.queryContainer = [];
        this.searchValue = searchValue;
        this.loadData({ initialLoad: false });
    };
    SideSheetObjectChooserComponent.prototype.itemSelectedChange = function ($event) {
        if (!this.selected || this.listType === SideSheetListModes.SINGLE_SELECT) {
            this.selectedChange();
        }
    };
    SideSheetObjectChooserComponent.prototype.onOpenObject = function (object) {
        var _this = this;
        var typeTip = this.typeTips ? this.typeTips[0] : null;
        var objectTip = this.getObjectTipValue(object);
        if (typeTip && objectTip) {
            this.objectBehaviourService
                .resolveObjectBehaviour(objectTip, typeTip)
                .pipe(first())
                .subscribe(function (value) {
                value && value.url
                    ? open(value.url, _this.sentryService.onNativeApp)
                    : _this.launchObject(objectTip);
            });
        }
    };
    SideSheetObjectChooserComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    };
    SideSheetObjectChooserComponent.prototype.launchObject = function (objectTip) {
        var _this = this;
        this.objectLaunch
            .pushSheet({ objectTip: objectTip, operation: OBJECT_LAUNCH_OPERATION.VIEW }, this.unsubscribe$)
            .subscribe(function (_a) {
            var deleted = _a.deleted;
            if (deleted) {
                _this.selected = _this.selected.filter(function (tip) { return tip !== objectTip; });
                _this.loadData({ initialLoad: false });
            }
        });
    };
    SideSheetObjectChooserComponent.prototype.getObjectTipValue = function (object) {
        return object.$tip || object.Tip || null;
    };
    return SideSheetObjectChooserComponent;
}());
export { SideSheetObjectChooserComponent };
function open(link, onNativeApp) {
    var newWindow = window.open(link, onNativeApp ? '_self' : '_blank');
    newWindow.opener = null;
}
