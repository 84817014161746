import { Renderer2 } from '@angular/core';
import { cloneDeep, forEach } from 'lodash';
import { CONTEXT_VAR_NAME } from '../../message/message-utils.service';
import { getContextFromContextFormula } from '../../util/context-formula-wrapper';
import { SubstitutionMetaService } from '../../data/string-interpolation/substitution-meta.service';
import { decodeHtmlEntities, replaceHtmlTags, stripHtml } from '../../util/html-utils';
import * as i0 from "@angular/core";
var INTERPOLATION_KEY_REGEX = /\{\{([G|M|P|R|S])\d+\}\}/;
export var INTERPOLATION_TYPE;
(function (INTERPOLATION_TYPE) {
    INTERPOLATION_TYPE["general"] = "G";
    INTERPOLATION_TYPE["message"] = "M";
    INTERPOLATION_TYPE["recipient"] = "P";
    INTERPOLATION_TYPE["responseLink"] = "R";
    INTERPOLATION_TYPE["subject"] = "S";
})(INTERPOLATION_TYPE || (INTERPOLATION_TYPE = {}));
// INPUT used for rich-text-editor
// SPAN used for string-interpolate-text-field
export var INTERPOLATION_TAG_TYPE;
(function (INTERPOLATION_TAG_TYPE) {
    INTERPOLATION_TAG_TYPE["INPUT"] = "input";
    INTERPOLATION_TAG_TYPE["SPAN"] = "span";
})(INTERPOLATION_TAG_TYPE || (INTERPOLATION_TAG_TYPE = {}));
export var SUBSTITUTION_PROPERTY;
(function (SUBSTITUTION_PROPERTY) {
    SUBSTITUTION_PROPERTY["MESSAGE"] = "messageSubstitutions";
    SUBSTITUTION_PROPERTY["SUBJECT"] = "subjectSubstitutions";
})(SUBSTITUTION_PROPERTY || (SUBSTITUTION_PROPERTY = {}));
export var CONTENT_TYPE;
(function (CONTENT_TYPE) {
    CONTENT_TYPE["INNER_HTML"] = "innerHTML";
    CONTENT_TYPE["TEXT_CONTENT"] = "textContent";
})(CONTENT_TYPE || (CONTENT_TYPE = {}));
var StringInterpolationService = /** @class */ (function () {
    function StringInterpolationService() {
    }
    StringInterpolationService.getSubstitutionKeyFromType = function (substitutionType, index) {
        return "{{" + substitutionType + index + "}}";
    };
    StringInterpolationService.getSubstitutionTypeFromKey = function (substitutionKey) {
        return substitutionKey.match(INTERPOLATION_KEY_REGEX)[1];
    };
    StringInterpolationService.makeInterpolationElement = function (substitution, renderer, interpolationTagType) {
        if (interpolationTagType === void 0) { interpolationTagType = INTERPOLATION_TAG_TYPE.INPUT; }
        var substitutionCandidate = {
            type: StringInterpolationService.getSubstitutionTypeFromKey(substitution.key),
            label: substitution.label,
            value: substitution.value,
        };
        var wrapperHtml = renderer.createElement('div');
        var tag = interpolationTagType === INTERPOLATION_TAG_TYPE.INPUT
            ? StringInterpolationService.createInterpolationInputElement(substitutionCandidate, renderer)
            : StringInterpolationService.createInterpolationSpanElement(substitutionCandidate, renderer);
        renderer.appendChild(wrapperHtml, tag);
        return wrapperHtml.innerHTML;
    };
    StringInterpolationService.getSubstitutionFromEmailSMSSubstitution = function (emailSubstitution, interpolationType) {
        if (interpolationType === void 0) { interpolationType = INTERPOLATION_TYPE.message; }
        var substitution = cloneDeep(emailSubstitution);
        substitution.type = interpolationType;
        return substitution;
    };
    StringInterpolationService.createInterpolationInputElement = function (substitutionCandidate, renderer) {
        var input = renderer.createElement('input');
        renderer.addClass(input, 'eim-forms_form-control-string-interpolation_interpolation-blot');
        renderer.setAttribute(input, 'disabled', 'true');
        renderer.setAttribute(input, 'readonly', 'readonly');
        renderer.setAttribute(input, 'value', substitutionCandidate.label);
        renderer.setAttribute(input, 'size', substitutionCandidate.label.length.toString());
        renderer.setAttribute(input, 'data-substitution', JSON.stringify(substitutionCandidate));
        return input;
    };
    StringInterpolationService.createInterpolationSpanElement = function (substitutionCandidate, renderer) {
        var span = renderer.createElement('span');
        renderer.addClass(span, 'eim-forms_form-control-string-interpolation_interpolation-blot');
        renderer.setAttribute(span, 'data-substitution', JSON.stringify(substitutionCandidate));
        var spanInner = renderer.createElement('span');
        renderer.setAttribute(span, 'contenteditable', 'false');
        var spanInnerText = renderer.createText(substitutionCandidate.label);
        renderer.appendChild(spanInner, spanInnerText);
        renderer.appendChild(span, spanInner);
        return span;
    };
    StringInterpolationService.getSubstitutionMetaFromEmailOutboundSource = function (emailOutboundSource, substitutionProperty) {
        var _this = this;
        if (substitutionProperty === void 0) { substitutionProperty = SUBSTITUTION_PROPERTY.MESSAGE; }
        var html;
        var interpolationType;
        switch (substitutionProperty) {
            case SUBSTITUTION_PROPERTY.MESSAGE:
                html = emailOutboundSource.body;
                interpolationType = INTERPOLATION_TYPE.message;
                break;
            case SUBSTITUTION_PROPERTY.SUBJECT:
                html = emailOutboundSource.subject;
                interpolationType = INTERPOLATION_TYPE.subject;
                break;
        }
        var substitutions = emailOutboundSource[substitutionProperty] || [];
        return {
            html: html,
            substitutions: substitutions.map(function (substitution) { return _this.getSubstitutionFromEmailSMSSubstitution(substitution, interpolationType); })
        };
    };
    StringInterpolationService.getSubstitutionMetaFromSmsOutboundSource = function (smsOutboundSource) {
        var _this = this;
        var html = smsOutboundSource.body;
        var interpolationType = INTERPOLATION_TYPE.message;
        var substitutions = smsOutboundSource[SUBSTITUTION_PROPERTY.MESSAGE] || [];
        return {
            html: html,
            substitutions: substitutions.map(function (substitution) { return _this.getSubstitutionFromEmailSMSSubstitution(substitution, interpolationType); })
        };
    };
    StringInterpolationService.extractEmailSMSSubstitutions = function (substitutions, enoType) {
        if (enoType === void 0) { enoType = 'app/email/substitution'; }
        return (substitutions || []).map(function (substitution) {
            substitution.$type = enoType;
            return substitution;
        });
    };
    /**
     * Converts the HTML to be presented (in Quill for example) to the HTML to be saved in ENOS (e.g. "app/email/outbound-source:body").
     */
    StringInterpolationService.getSubstitutionMetaFromViewHtml = function (viewHtml, renderer, contentType, preserveLineBreaks) {
        if (contentType === void 0) { contentType = CONTENT_TYPE.INNER_HTML; }
        if (preserveLineBreaks === void 0) { preserveLineBreaks = false; }
        var wrapperHtml = renderer.createElement('div');
        wrapperHtml.innerHTML = viewHtml;
        var interpolationSpans = wrapperHtml.querySelectorAll('[data-substitution]');
        if (interpolationSpans.length === 0) {
            var html = contentType === CONTENT_TYPE.INNER_HTML ? viewHtml :
                preserveLineBreaks ? this.removeHtmlAndCreateLineBreaks(viewHtml) : wrapperHtml.textContent;
            return {
                html: html,
                substitutions: []
            };
        }
        var substitutions = [];
        forEach(interpolationSpans, function (interpolationSpan, index) {
            var substitutionCandidate = JSON.parse(interpolationSpan.getAttribute('data-substitution'));
            var substitutionKey = StringInterpolationService.getSubstitutionKeyFromType(substitutionCandidate.type, index);
            interpolationSpan.insertAdjacentHTML('afterend', substitutionKey);
            interpolationSpan.parentNode.removeChild(interpolationSpan);
            substitutions.push({
                key: substitutionKey,
                type: substitutionCandidate.type,
                label: substitutionCandidate.label,
                value: substitutionCandidate.value
            });
        });
        if (contentType === CONTENT_TYPE.TEXT_CONTENT) {
            var textContent = this.removeHtmlAndCreateLineBreaks(wrapperHtml.innerHTML);
            if (textContent && !preserveLineBreaks && (textContent.includes('\n') || textContent.includes('\r'))) { // check if new lines have to be removed
                // remove escaped newlines
                textContent = textContent.replace(/[\r\n]/g, '');
            }
            return {
                html: textContent,
                substitutions: substitutions
            };
        }
        var metaDiv = renderer.createElement('div');
        renderer.setAttribute(metaDiv, 'data-substitutions', JSON.stringify(substitutions).replace(/\{\{/g, '{-{'));
        renderer.appendChild(wrapperHtml, metaDiv);
        return {
            html: wrapperHtml.innerHTML,
            substitutions: substitutions
        };
    };
    StringInterpolationService.removeSubstitutionByContextFormula = function (viewHtml, renderer, contextFormula) {
        if (contextFormula === void 0) { contextFormula = "VAR(\"" + CONTEXT_VAR_NAME + "\")"; }
        var wrapperHtml = renderer.createElement('div');
        wrapperHtml.innerHTML = viewHtml;
        var interpolationSpans = wrapperHtml.querySelectorAll("[data-substitution]");
        var changed = false;
        interpolationSpans.forEach(function (interpolationSpan) {
            var substitution = JSON.parse(interpolationSpan.dataset.substitution);
            var contextOfSubstitution = getContextFromContextFormula(substitution.value);
            if (contextOfSubstitution && contextOfSubstitution === contextFormula) {
                interpolationSpan.parentNode.removeChild(interpolationSpan);
                changed = true;
            }
        });
        return { changed: changed, newHtml: wrapperHtml.innerHTML };
    };
    /**
     * Converts the HTML to be saved in ENOS (e.g. "app/email/outbound-source:body") to the HTML to be presented (in Quill for example).
     */
    StringInterpolationService.getViewHtmlFromDataHtml = function (dataHtml, renderer) {
        var wrapperHtml = renderer.createElement('div');
        wrapperHtml.innerHTML = dataHtml;
        var metaDiv = wrapperHtml.querySelector('[data-substitutions]');
        if (!metaDiv) {
            return dataHtml;
        }
        wrapperHtml.removeChild(metaDiv);
        dataHtml = wrapperHtml.innerHTML;
        var substitutions = JSON.parse(metaDiv.getAttribute('data-substitutions').replace(/\{\-\{/g, '{{'));
        substitutions.forEach(function (substitution) {
            var interpolationHtml = StringInterpolationService.makeInterpolationElement(substitution, renderer, INTERPOLATION_TAG_TYPE.INPUT);
            if (interpolationHtml) {
                dataHtml = dataHtml.replace(substitution.key, interpolationHtml);
            }
        });
        return dataHtml;
    };
    /**
     * Converts the subMeta to be saved in ENOS
     * (e.g. subMeta: { html: "app/email/outbound-source:subject", substitutions: "app/email/outbound-source:subject-substitutions"})
     * to the HTML to be presented (in Quill for example).
     */
    StringInterpolationService.getViewTextFromDataHtml = function (subMeta, renderer) {
        var dataHtml = subMeta.html;
        var substitutions = subMeta.substitutions;
        if (!dataHtml) {
            return '';
        }
        if (!substitutions.length) {
            return dataHtml;
        }
        substitutions.forEach(function (substitution) {
            var interpolationHtml = StringInterpolationService.makeInterpolationElement(substitution, renderer, INTERPOLATION_TAG_TYPE.SPAN);
            if (interpolationHtml) {
                dataHtml = dataHtml.replace(substitution.key, interpolationHtml);
            }
        });
        return dataHtml;
    };
    StringInterpolationService.getStringifiedFormulaFromSubstitutionMeta = function (subMeta) {
        var substitutions = subMeta.substitutions;
        var html = subMeta.html;
        if (!html) {
            return '';
        }
        if (!substitutions.length) {
            return html;
        }
        substitutions
            .forEach(function (substitution) { return html = html.replace(substitution.key, "{=" + substitution.value + "}"); });
        return html;
    };
    StringInterpolationService.getSubstitutionMetaFromStringifiedFormula = function (formula) {
        var subMeta = {
            html: formula,
            substitutions: []
        };
        var count = 0;
        formula.replace(/{=([A-Z|_]+\([^=]*\))}/g, function (match, sub, string) {
            subMeta.substitutions.push({
                key: "{{" + INTERPOLATION_TYPE.general + count + "}}",
                type: INTERPOLATION_TYPE.general,
                label: SubstitutionMetaService.getLabelFromSystemFormula(sub) || "Label " + count,
                value: sub
            });
            subMeta.html = subMeta.html.replace(match, subMeta.substitutions[count].key);
            count++;
            return subMeta.substitutions[count - 1].key;
        });
        return subMeta;
    };
    /**
     * @param input
     * this method will find all 'divs' and convert them into new line characters
     */
    StringInterpolationService.removeHtmlAndCreateLineBreaks = function (input) {
        return stripHtml(replaceHtmlTags(decodeHtmlEntities(input)));
    };
    StringInterpolationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StringInterpolationService_Factory() { return new StringInterpolationService(); }, token: StringInterpolationService, providedIn: "root" });
    return StringInterpolationService;
}());
export { StringInterpolationService };
