import * as tslib_1 from "tslib";
import { FieldType } from '@ngx-formly/core';
var FormlyFieldGeoChooserComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FormlyFieldGeoChooserComponent, _super);
    function FormlyFieldGeoChooserComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return FormlyFieldGeoChooserComponent;
}(FieldType));
export { FormlyFieldGeoChooserComponent };
