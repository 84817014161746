import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../data/all-object-types/all-object-type.service";
var ObjectLaunchFormulasService = /** @class */ (function () {
    // todo move some of the formulas out of get-object-and-form
    function ObjectLaunchFormulasService(allObjectTypesService) {
        this.allObjectTypesService = allObjectTypesService;
    }
    ObjectLaunchFormulasService.prototype.isRestrictedSystemType$ = function (typeTip) {
        return this.allObjectTypesService.objectTypeInCache$(typeTip)
            .pipe(map(function (result) { return !result; }));
    };
    ObjectLaunchFormulasService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ObjectLaunchFormulasService_Factory() { return new ObjectLaunchFormulasService(i0.ɵɵinject(i1.AllObjectTypesService)); }, token: ObjectLaunchFormulasService, providedIn: "root" });
    return ObjectLaunchFormulasService;
}());
export { ObjectLaunchFormulasService };
