import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-hidden-input',
  styles: [`
      :host {
          display: none;
      }
  `],
  template: `
    <app-text-field [formControl]="formControl"></app-text-field>
  `,
})
export class FormlyFieldHiddenInputComponent extends FieldType {}
