import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { get } from 'lodash';

import { Tip } from '../../../data/models/types';
import { ContextTypeFieldOptions } from '../../context-type-field-option-utils/context-type-field-option-utils';

@Component({
  selector: 'app-file-upload',
  template: `
    <app-file-upload-field
        [isMulti]="multi"
        [formControl]="formControl"
        [label]="to.label"
        [securityPolicyTip]="securityPolicyTip"
        [fileSecurityPolicyTip]="to.fileSecurityPolicyTip"
        [readonly]="to.readonly"
        [required]="to.required"
        [options]="to.svgOptions">
    </app-file-upload-field>
  `
})
export class FormlyFieldFileUploadComponent extends FieldType implements OnInit {
  multi = false;

  contextTypeOptions: ContextTypeFieldOptions;

  securityPolicyTip: Tip;

  ngOnInit(): void {
    this.contextTypeOptions = get(this, 'formState.contextTypeOptions', null);
    this.securityPolicyTip = get(this, 'formState.contextSecurityPolicyTip', null);
    this.setParams();
  }

  setParams() {
    const contextTypeTip = get(this, 'field.templateOptions.contextTypeTip', null);
    const contextTypeOption = contextTypeTip ? this.contextTypeOptions.values.get(contextTypeTip) : null;

    this.multi = !!contextTypeOption && contextTypeOption.maxcount !== 1;
  }
}
