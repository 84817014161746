import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { SSOAuthService } from './sso-auth.service';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { LoggerService } from '../../util/logger.service';
import { SessionManagerService } from '../../data/session-manager.service';
import { LocationService } from '../../util/location.service';
import { get } from 'lodash';

@Component({
  selector: 'app-sso-auth',
  templateUrl: './sso-auth.component.html',
  styleUrls: ['./sso-auth.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SSOAuthComponent implements OnInit {

  showErrorMessage = false;
  showGoToAppButton = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private ssoAuthService: SSOAuthService,
    private loggerService: LoggerService,
    private sessionManager: SessionManagerService,
    private locationService: LocationService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    const token = this.activatedRoute.snapshot.queryParamMap.get('token');
    const issuingService = get(this, 'activatedRoute.snapshot.data.issuingService');
    this.ssoAuthService.loginWithToken(token, issuingService)
      .pipe(first())
      .subscribe( () => {
          this.loggerService.info('successfully loaded token');
          this.showGoToAppButton = true;
          this.cdr.detectChanges();
        },
        () => {
          this.showErrorMessage = true;
          this.showGoToAppButton = false;
          this.cdr.detectChanges();
        }
      );
  }

  goToApp() {
    this.locationService.redirect(this.sessionManager.originalRequestedDestination);
  }
}
