import { ReplaySubject, of } from 'rxjs';
import { distinctUntilChanged, filter, map, publishReplay, refCount, switchMap } from 'rxjs/operators';
import { cloneDeep, isEqual, head, mapValues } from 'lodash';
import dataConstants from './constants';
import { EnoFactory } from './EnoFactory';
import { ESessionMessageDataType } from './pub-sub.service';
import { debugConfig } from '../../debugConfig';
import { removeListEscapes } from '../util/remove-escapes';
import { NOW_VAR_NAME } from '../util/current-datetime.service';
import { groupedExcessiveOutputThrottle } from './grouped-excessive-output-throttle';
import * as i0 from "@angular/core";
import * as i1 from "./vars.service";
import * as i2 from "./eno.service";
import * as i3 from "./object-service/object.service";
import * as i4 from "./ensrv.service";
import * as i5 from "./pub-sub.service";
import * as i6 from "../util/logger.service";
import * as i7 from "./query-response-parser.service";
import * as i8 from "../util/current-datetime.service";
import * as i9 from "./i18n.service";
export var DEFAULT_DIMENSION = [{ label: 'Tip dimension', formula: 'TIP()' }];
// To avoid negative lookbehind issue with minification tool (https://github.com/terser-js/terser/issues/269),
// RegExp has been split and will only be evaluated at runtime.
// If this issue is resolved, this line should become:
// export const UNESCAPED_COMMA_REGEXP: RegExp = /(?<!\\),/;
var QueryService = /** @class */ (function () {
    /**
     * Start listening for enos as they arrive from Ensrv
     */
    function QueryService(_varsService, _enoService, _objectService, _ensrvService, _pubSubService, _loggerService, _queryResponseParserService, _currentDatetimeService, i18nService) {
        var _this = this;
        this._varsService = _varsService;
        this._enoService = _enoService;
        this._objectService = _objectService;
        this._ensrvService = _ensrvService;
        this._pubSubService = _pubSubService;
        this._loggerService = _loggerService;
        this._queryResponseParserService = _queryResponseParserService;
        this._currentDatetimeService = _currentDatetimeService;
        this.i18nService = i18nService;
        this.debugConfig = debugConfig;
        this._responseStation = {};
        this._opQueryFactory = new EnoFactory('op/query', dataConstants.SECURITY.OP);
        this.opStorage = {};
        this._pubSubService.receiveSessionMessage(ESessionMessageDataType.queryWatch).pipe(filter(function (data) {
            return _this._responseStation[data.op] !== undefined && _this.opStorage[data.op] !== undefined;
        }), groupedExcessiveOutputThrottle(10000, function (data) { return data.op; }, _loggerService)).subscribe(function (data) { return _this.handleQueryWatchData(_this.opStorage[data.op]); });
        this._ensrvService.getEnoReceiver('response/query').subscribe(function (responseEno) {
            _this._broadcastResult(responseEno);
        });
    }
    /** return a comma separated result from a single array result */
    QueryService.commaSeparatedResult = function (input) {
        return mapValues(input, function (value) { return value.join(', '); });
    };
    /** return a comma separated result from an array result in array */
    QueryService.commaSeparatedResults = function (inputs) {
        return inputs.map(function (i) { return QueryService.commaSeparatedResult(i); });
    };
    QueryService.firstResult = function (input) {
        return mapValues(input, head);
    };
    QueryService.firstResults = function (inputs) {
        return inputs.map(function (i) { return QueryService.firstResult(i); });
    };
    QueryService.prototype.handleQueryWatchData = function (opQuery) {
        var _this = this;
        this._enoService.writeEno(opQuery).subscribe(function () {
            if (_this.debugConfig.query.watch.watchProgress) {
                _this._loggerService.debug('op/query is sent triggered by query watch', opQuery);
            }
        }, function (e) {
            if (_this.debugConfig.query.watch.watchProgress) {
                _this._loggerService.error('op/query failed triggered by query watch', opQuery, e);
            }
        });
    };
    QueryService.prototype._broadcastResult = function (responseEno) {
        var _this = this;
        var opQueryTip = responseEno.getFieldStringValue('response/query/op-tip');
        var responseStation = this._responseStation[opQueryTip];
        if (!responseStation) {
            return;
        }
        this._queryResponseParserService.parse(responseEno).subscribe(function (queryResponse) { return responseStation.broadcaster.next(queryResponse); }, function (err) { return _this._sendResponseBroadcasterError(opQueryTip, 'Unable to parse query response'); });
    };
    /**
     * Executes a query mapping the results to a simple array of objects on the assumption the query is one-dimensional
     * Returns each attribute's first result, if you expect a comma separated value, use execute1dCommaSeparated
     * or if you expect the full array of result for the attribute, use execute1dArray
     */
    QueryService.prototype.execute1dFirst = function (queryTip, options) {
        if (options === void 0) { options = {
            branch: dataConstants.BRANCH_MASTER,
            watch: true,
            vars: {},
            extraFilters: [],
            extraAttributes: [],
            dimensionOptions: DEFAULT_DIMENSION
        }; }
        return this.execute1dArray(queryTip, options).pipe(map(function (results) { return QueryService.firstResults(results); }));
    };
    /**
     * Executes a query mapping the results to a simple array of objects on the assumption the query is one-dimensional
     * Returns each attribute's comma separated joined result, if you expect only the first value, use execute1dFirst
     * or if you expect the full array of result for the attribute, use execute1dArray
     */
    QueryService.prototype.execute1dCommaSeparated = function (queryTip, options) {
        if (options === void 0) { options = {
            branch: dataConstants.BRANCH_MASTER,
            watch: true,
            vars: {},
            extraFilters: [],
            extraAttributes: [],
            dimensionOptions: DEFAULT_DIMENSION
        }; }
        return this.execute1dArray(queryTip, options).pipe(map(function (results) { return QueryService.commaSeparatedResults(results); }));
    };
    /**
     * Executes a query mapping the results to a simple array of objects on the assumption the query is one-dimensional
     * Returns each attribute result as an array, if you expect a comma separated value, use execute1dCommaSeparated
     * or if you expect only the first value, use execute1dFirst
     */
    QueryService.prototype.execute1dArray = function (queryTip, options) {
        if (options === void 0) { options = {
            branch: dataConstants.BRANCH_MASTER,
            watch: true,
            vars: {},
            extraFilters: [],
            extraAttributes: [],
            dimensionOptions: DEFAULT_DIMENSION
        }; }
        return this.execute(queryTip, options).pipe(map(function (response) {
            var keys = response.dimensions[0].values;
            return response.results.map(function (resultWrapper, i) {
                var result = resultWrapper[keys[i]];
                for (var property in result) {
                    if (result.hasOwnProperty(property) && typeof result[property] === 'string') {
                        var formattedResult = removeListEscapes(result[property]);
                        result[property] = singleFilter(formattedResult);
                    }
                }
                return result;
            });
        }));
    };
    /**
     * Executes a query on Ensrv
     */
    QueryService.prototype.execute = function (queryTip, options) {
        var _this = this;
        if (options === void 0) { options = {
            branch: dataConstants.BRANCH_MASTER,
            watch: true,
            vars: {},
            extraFilters: [],
            extraAttributes: [],
            dimensionOptions: DEFAULT_DIMENSION
        }; }
        return this.getLangsOption(options).pipe(switchMap(function (langs) {
            _this._normalizeQueryOptions(options, langs);
            var opQuery = _this._prepareInlineOperation(queryTip, options);
            _this.opStorage[opQuery.tip] = opQuery;
            var responseStationInfo = _this._getResponseBroadcaster(opQuery.tip);
            _this._ensrvService.send([opQuery]).subscribe(function (responseBatch) {
                if (responseBatch.filter(function (eno) { return eno.getType() === 'response/query'; }).length === 0) {
                    return _this._sendResponseBroadcasterError(opQuery.tip, 'Query execution failed. Probably an invalid query.');
                }
            }, function () { return _this._sendResponseBroadcasterError(opQuery.tip, 'Query execution failed'); });
            return responseStationInfo.observable.pipe(distinctUntilChanged(function (prev, next) {
                return isEqual(prev.results, next.results);
            }), 
            // need this as downstream service mutate the response :(
            map(function (v) { return cloneDeep(v); }));
        }));
    };
    QueryService.prototype.getLangsOption = function (options) {
        if (Array.isArray(options.langs) && options.langs.length > 0) {
            return of(options.langs);
        }
        return this.i18nService.acceptableLocaleIds$;
    };
    QueryService.prototype._normalizeQueryOptions = function (options, langs) {
        options.branch = options.branch || dataConstants.BRANCH_MASTER;
        options.langs = langs;
        options.watch = options.watch === undefined ? true : options.watch;
        options.vars = options.vars || {};
        options.vars[NOW_VAR_NAME] = [this._currentDatetimeService.getCurrentDatetime()];
        options.extraFilters = options.extraFilters || [];
        options.extraAttributes = options.extraAttributes || [];
        options.dimensionOptions = options.dimensionOptions || DEFAULT_DIMENSION;
    };
    QueryService.prototype._prepareInlineOperation = function (queryTip, options) {
        this._opQueryFactory
            .setField({ tip: 'op/query/tip', value: [queryTip] })
            .setField({ tip: 'op/query/branch', value: [options.branch] })
            .setField({ tip: 'op/query/watch', value: [options.watch ? 'true' : 'false'] })
            .setField({ tip: 'op/query/lang', value: options.langs });
        var opQueryQuery = {
            attributes: options.extraAttributes,
            filters: options.extraFilters,
            dimensions: options.dimensionOptions,
            vars: options.vars
        };
        this._opQueryFactory.setField({ tip: 'op/query/query', value: [JSON.stringify(opQueryQuery)] });
        return this._opQueryFactory.makeEno();
    };
    QueryService.prototype._getResponseBroadcaster = function (opQueryTip) {
        if (!this._responseStation[opQueryTip]) {
            var broadcaster = new ReplaySubject(1);
            var observable = broadcaster.pipe(
            // finalize(() => this._ungetResponseBroadcaster(opQueryTip)), // todo - this broke SideSheetObjectChooserComponent - seems an issue with async pipe and finalize()
            publishReplay(1), refCount());
            this._responseStation[opQueryTip] = { broadcaster: broadcaster, observable: observable };
        }
        return this._responseStation[opQueryTip];
    };
    QueryService.prototype._ungetResponseBroadcaster = function (opQueryTip) {
        if (this._responseStation[opQueryTip]) {
            var unwatchOpEnofactory = new EnoFactory('op/watch/unregister', dataConstants.SECURITY.OP);
            var unwatchOpEno = unwatchOpEnofactory
                .setFields([{ tip: 'op/watch/unregister:op-id', value: [opQueryTip] }])
                .makeEno();
            this
                ._ensrvService
                .send([unwatchOpEno])
                .subscribe();
            delete this._responseStation[opQueryTip];
        }
    };
    QueryService.prototype._sendResponseBroadcasterError = function (opQueryTip, message) {
        if (this._responseStation[opQueryTip]) {
            this._responseStation[opQueryTip].broadcaster.error(new Error(message));
            delete this._responseStation[opQueryTip];
        }
    };
    QueryService.prototype.deleteEmptyProp = function (results, propsToDelete) {
        results.forEach(function (result) {
            propsToDelete.forEach(function (prop) {
                if (result[prop] === '') {
                    delete result[prop];
                }
            });
        });
        return results;
    };
    QueryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function QueryService_Factory() { return new QueryService(i0.ɵɵinject(i1.VarsService), i0.ɵɵinject(i2.EnoService), i0.ɵɵinject(i3.ObjectService), i0.ɵɵinject(i4.EnsrvService), i0.ɵɵinject(i5.PubSubService), i0.ɵɵinject(i6.LoggerService), i0.ɵɵinject(i7.QueryResponseParserService), i0.ɵɵinject(i8.CurrentDatetimeService), i0.ɵɵinject(i9.I18nService)); }, token: QueryService, providedIn: "root" });
    return QueryService;
}());
export { QueryService };
function singleFilter(array) {
    if (array.length === 1 && array[0].length === 0) {
        return [];
    }
    return array;
}
