import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { isEqual } from 'lodash';

import { IProcessResponse, ProcessService } from '../data/process.service';

@Injectable({
  providedIn: 'root'
})
export class AcceptInviteResolverService implements Resolve<boolean> {
  constructor(
    private _processService: ProcessService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this._processService.start('eim/process/auth/validate-token', {
      Token: [route.queryParams.token],
      Email: [route.queryParams.email]
    }).pipe(
      first((processResponse: IProcessResponse) => processResponse.finished),
      map((processResponse: IProcessResponse) => isEqual(processResponse.vars['Token valid'], ['true']))
    );
  }
}
