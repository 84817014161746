/**
 * TypeScript documentation for new.target
 * https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html#support-for-newtarget
 */
import * as tslib_1 from "tslib";
var ModuleValidationError = /** @class */ (function (_super) {
    tslib_1.__extends(ModuleValidationError, _super);
    function ModuleValidationError(message) {
        var _newTarget = this.constructor;
        var _this = _super.call(this, message) || this;
        Object.setPrototypeOf(_this, _newTarget.prototype);
        return _this;
    }
    return ModuleValidationError;
}(Error));
export { ModuleValidationError };
