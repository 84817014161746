import * as tslib_1 from "tslib";
import { FieldType } from '@ngx-formly/core';
import { OnDestroy, OnInit } from '@angular/core';
import { get } from 'lodash';
var FormlyFieldQueryChooserComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FormlyFieldQueryChooserComponent, _super);
    function FormlyFieldQueryChooserComponent(route) {
        var _this = _super.call(this) || this;
        _this.route = route;
        _this.objectTypeTip = null;
        return _this;
    }
    FormlyFieldQueryChooserComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.contextTypeOptions = get(this, 'formState.contextTypeOptions', null);
        // This will work for the references within primary router outlet.
        // The route params will not be available in non-routable side-sheet. Can be enchanced to chain the moduleTip as an Input.
        this.moduleTip = get(this.route, 'snapshot.params.moduleTip')
            || get(this, 'field.templateOptions.moduleTip', null);
        this.setParams();
        this.changes = this
            .form
            .valueChanges
            .subscribe(function () { return _this.setParams(); });
    };
    FormlyFieldQueryChooserComponent.prototype.setParams = function () {
        this.objectTypeTip = null;
        var formOptionsObjectTypeTip = get(this, 'field.templateOptions.objectTypeTip', null);
        if (formOptionsObjectTypeTip) {
            this.objectTypeTip = formOptionsObjectTypeTip;
        }
        // fieldToFilter used in the form designer
        var fieldToFilter = get(this, 'field.templateOptions.fieldToFilter', null);
        if (fieldToFilter && this.contextTypeOptions) {
            var contextTypeOption = this.contextTypeOptions.values.get(fieldToFilter);
            this.objectTypeTip = get(contextTypeOption, 'typerestrict[0]');
        }
    };
    FormlyFieldQueryChooserComponent.prototype.ngOnDestroy = function () {
        this.changes.unsubscribe();
    };
    return FormlyFieldQueryChooserComponent;
}(FieldType));
export { FormlyFieldQueryChooserComponent };
