import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { camelCase, sentenceCase } from 'change-case';
import * as moment from 'moment';
import { first, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { WorkflowLineValueFormUtils } from './workflow-line-value-form-utils';
import { fieldScheme, TYPE_GEO_RESTRICT } from '../../../../../models/field';
import { LoadingState } from '../../../../../shared/constants';
import { SKETCH_TOOL } from '../../../../../shared/map/map/map-util.service';
import { ADDRESS_CHOOSER_FORMLY_CONFIG, BOOLEAN_CHOOSER_FORMLY_CONFIG, DATE_TIME_FORMLY_CONFIG, GEO_CHOOSER_FORMLY_CONFIG, STRING_INTERPOLATE_RICH_TEXT_FORMLY_CONFIG, STRING_INTERPOLATE_TEXT_FORMLY_CONFIG } from '../../../../../formly-noggin/ngx-formly-bootstrap/bootstrap.config';
var NS = 'WorkflowLineValueFormDrillDownComponent';
export var ERR_MSG_UNSUPPORTED = 'Sorry, this field type does not support manual entry.';
var WorkflowLineValueFormDrillDownComponent = /** @class */ (function () {
    function WorkflowLineValueFormDrillDownComponent(fb, objectService, loggerService, substitutionMetaService) {
        this.fb = fb;
        this.objectService = objectService;
        this.loggerService = loggerService;
        this.substitutionMetaService = substitutionMetaService;
        this.allowStringInterpolation = false;
        this.additionalTemplateOptions = {};
        this.formReady = false;
        this.progressState = LoadingState.inProgress;
        this.loadingState = LoadingState.empty;
        this.done = function () { };
    }
    /**
     * Double stringifies JSON so quotes get escaped, then removes the first and last quotes so it can be safely inserted
     * into a formula as a string.
     */
    WorkflowLineValueFormDrillDownComponent.jsonSafeString = function (rawValue) {
        var str = JSON.stringify(JSON.stringify(rawValue));
        return str.substr(1, str.length - 2);
    };
    WorkflowLineValueFormDrillDownComponent.jsonSafeParse = function (value) {
        return JSON.parse(JSON.parse('"' + value + '"'));
    };
    /**
     * Use set props when opening the side sheet
     */
    WorkflowLineValueFormDrillDownComponent.prototype.setProps = function (props) {
        this.fieldDataType = props.fieldDataType;
        this.objectType = props.objectType;
        this.typeGeoRestrict = props.typeGeoRestrict;
        this.value = props.value;
        this.allowStringInterpolation = props.allowStringInterpolation || false;
        this.additionalTemplateOptions = props.additionalTemplateOptions || {};
    };
    // tslint:disable-next-line:cyclomatic-complexity
    WorkflowLineValueFormDrillDownComponent.prototype.ngOnInit = function () {
        var _a;
        var _this = this;
        this.form = this.fb.group({
            item: [null, Validators.required]
        });
        var fieldData = WorkflowLineValueFormUtils.getFieldData().find(function (o) {
            return o.isObject
                ? o.dataOrObjectType.indexOf(_this.objectType) !== -1
                : o.dataOrObjectType.indexOf(_this.fieldDataType) !== -1;
        });
        if (fieldData) {
            if (this.value) {
                switch (this.allowStringInterpolation ? fieldData.stringInterpolatedType || fieldData.type : fieldData.type) {
                    case DATE_TIME_FORMLY_CONFIG.name:
                        this.value = moment(this.value);
                        break;
                    case GEO_CHOOSER_FORMLY_CONFIG.name:
                        this.value = WorkflowLineValueFormDrillDownComponent.jsonSafeParse(this.value);
                        break;
                    case STRING_INTERPOLATE_TEXT_FORMLY_CONFIG.name:
                    case STRING_INTERPOLATE_RICH_TEXT_FORMLY_CONFIG.name:
                        try {
                            this.value = JSON.parse(this.value);
                        }
                        catch (e) {
                            this.value = { html: this.value, substitutions: [] };
                        }
                        break;
                }
            }
            switch (this.allowStringInterpolation ? fieldData.stringInterpolatedType || fieldData.type : fieldData.type) {
                case GEO_CHOOSER_FORMLY_CONFIG.name:
                    fieldData.label = sentenceCase(this.getSketchToolType()) || '';
                    break;
                case STRING_INTERPOLATE_TEXT_FORMLY_CONFIG.name:
                case STRING_INTERPOLATE_RICH_TEXT_FORMLY_CONFIG.name:
                    this.value = this.value || { html: '', substitutions: [] };
                    break;
            }
            var key = camelCase(this.allowStringInterpolation ? fieldData.stringInterpolatedType || fieldData.type : fieldData.type);
            this.fields = [{
                    key: key,
                    type: this.allowStringInterpolation ? fieldData.stringInterpolatedType || fieldData.type : fieldData.type,
                    templateOptions: tslib_1.__assign({ label: fieldData.label, placeholder: fieldData.placeholder || null, type: this.getSketchToolType() || fieldData.inputType || null, required: true }, this.additionalTemplateOptions)
                }];
            this.model = (_a = {},
                _a[key] = this.value,
                _a);
            this.formReady = true;
        }
        else {
            this.errorMessage = ERR_MSG_UNSUPPORTED;
        }
    };
    WorkflowLineValueFormDrillDownComponent.prototype.onPrimary = function () {
        var _this = this;
        var data$;
        var field = this.fields[0];
        var rawValue = this.model[field.key];
        this.loadingState = LoadingState.inProgress;
        switch (field.type) {
            case DATE_TIME_FORMLY_CONFIG.name:
                data$ = this.getDateData(rawValue, field);
                break;
            case BOOLEAN_CHOOSER_FORMLY_CONFIG.name:
                data$ = this.getBooleanData(rawValue);
                break;
            case GEO_CHOOSER_FORMLY_CONFIG.name:
                data$ = this.getGeoData(rawValue);
                break;
            case ADDRESS_CHOOSER_FORMLY_CONFIG.name:
                data$ = this.getAddressData(rawValue);
                break;
            case STRING_INTERPOLATE_TEXT_FORMLY_CONFIG.name:
            case STRING_INTERPOLATE_RICH_TEXT_FORMLY_CONFIG.name:
                data$ = of({
                    label: this.substitutionMetaService.replaceSubstitutions(rawValue.html, rawValue.substitutions),
                    value: JSON.stringify(rawValue)
                });
                break;
            default:
                var stringValue = (rawValue === null || rawValue === undefined) ? '' : rawValue.toString();
                data$ = of({
                    value: stringValue,
                    label: typeof rawValue === 'object' ? "(Object)" : stringValue
                });
        }
        data$.pipe(first()).subscribe(function (data) {
            _this.loadingState = LoadingState.loaded;
            _this.done(data);
        });
    };
    WorkflowLineValueFormDrillDownComponent.prototype.getAddressData = function (rawValue) {
        var _this = this;
        return this.objectService.getObject(rawValue, fieldScheme)
            .pipe(first(), switchMap(function (o) {
            return of({ value: rawValue, label: o.displayStreet });
        }), catchError(function (err) {
            _this.loggerService.warn(NS, "Couldn't retrieve address via tip of \"" + rawValue + "\".", err);
            return of({ value: rawValue, label: rawValue });
        }));
    };
    WorkflowLineValueFormDrillDownComponent.prototype.getDateData = function (rawValue, field) {
        var value;
        var label;
        // format that can be read by Moment.js when reading value back in
        value = rawValue.format();
        // nicer formatting for labels (either 'datetime' or 'date')
        label = rawValue.format(field.templateOptions.type === 'datetime' ? 'LLL' : 'LL');
        return of({ value: value, label: label });
    };
    WorkflowLineValueFormDrillDownComponent.prototype.getBooleanData = function (rawValue) {
        var value;
        var label;
        if (rawValue && typeof rawValue === 'object') {
            value = rawValue.value ? rawValue.value.toString() : 'false';
            label = rawValue.label;
        }
        else if (typeof rawValue === 'string') {
            value = rawValue;
            label = rawValue === 'true' ? 'True' : 'False';
        }
        else {
            value = rawValue ? 'true' : 'false';
            label = rawValue ? 'True' : 'False';
        }
        return of({ value: value, label: label });
    };
    WorkflowLineValueFormDrillDownComponent.prototype.getGeoData = function (rawValue) {
        var value;
        var label;
        // stringifies the object data so it can be ready back in using JSON.parse, but stil saved as a string
        value = rawValue ? WorkflowLineValueFormDrillDownComponent.jsonSafeString(rawValue) : '';
        /**
          * Checks that 'coordinates' property exists and is an array (which it should be), then reverses the array just for
          * 'Point' so that it reads as Lat,Lon. Other geo types are left as is.
          */
        label = rawValue && rawValue.coordinates && Array.isArray(rawValue.coordinates)
            ? this.typeGeoRestrict === TYPE_GEO_RESTRICT.POINT ? rawValue.coordinates.reverse().join(',') : rawValue.coordinates.join(',')
            : '(not set)';
        return of({ value: value, label: label });
    };
    WorkflowLineValueFormDrillDownComponent.prototype.getSketchToolType = function () {
        if (!this.typeGeoRestrict) {
            return null;
        }
        switch (this.typeGeoRestrict) {
            case TYPE_GEO_RESTRICT.POINT:
                return SKETCH_TOOL.POINT;
            case TYPE_GEO_RESTRICT.POLYGON:
                return SKETCH_TOOL.POLYGON;
            case TYPE_GEO_RESTRICT.LINE:
                return SKETCH_TOOL.POLYLINE;
        }
    };
    return WorkflowLineValueFormDrillDownComponent;
}());
export { WorkflowLineValueFormDrillDownComponent };
