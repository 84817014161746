import { BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { uniq } from 'lodash';
import dataConstants from './constants';
import * as i0 from "@angular/core";
import * as i1 from "../dom-tokens";
import * as i2 from "ngx-cookie-service";
import * as i3 from "../util/logger.service";
import * as i4 from "../util/location.service";
import * as i5 from "./locale.service";
export var COOKIE_NAME_LOCALE_ID = 'noggin_apps_locale_id';
export var LOCAL_STORAGE_KEY_I18N_RELOADED = 'noggin_apps_i18n_reloaded';
var I18nService = /** @class */ (function () {
    function I18nService(localeId, localStorage, cookieService, loggerService, locationService, localeService) {
        this.localeId = localeId;
        this.localStorage = localStorage;
        this.cookieService = cookieService;
        this.loggerService = loggerService;
        this.locationService = locationService;
        this.localeService = localeService;
        this.acceptableLocaleIdsSubject = new BehaviorSubject([dataConstants.LANG_DEFAULT]);
        this.acceptableLocaleIds$ = this.acceptableLocaleIdsSubject.asObservable();
        var currentLocaleId = localeId.toLowerCase();
        dataConstants.LOCALE_ID = currentLocaleId;
        this.updateAcceptableLocaleIds(uniq([currentLocaleId, dataConstants.LANG_DEFAULT]));
    }
    I18nService.prototype.checkLocale$ = function (me) {
        var _this = this;
        var currentLocaleId = dataConstants.LOCALE_ID;
        // Set the locale_id cookie to be the currently served locale ID.
        this.cookieService.set(COOKIE_NAME_LOCALE_ID, currentLocaleId, 60, '/');
        // Not signed in.
        // No need to reload.
        // The locale_id cookie or the Accept-Language HTTP header tells the server which locale to load.
        if (!me) {
            return;
        }
        this.updateAcceptableLocaleIds(uniq([currentLocaleId, me.localeId, dataConstants.LANG_DEFAULT]));
        var i18nReloaded = this.localStorage.getItem(LOCAL_STORAGE_KEY_I18N_RELOADED) === 'true';
        // There was no build the server was able to serve for the user's preferred locale ID.
        // i18nReloaded being true means the server has already tried to serve the desired locale ID and would have served "en-us" should the
        // desired locale ID request could not be met.
        if (i18nReloaded) {
            this.localStorage.removeItem(LOCAL_STORAGE_KEY_I18N_RELOADED);
            return;
        }
        this.localeService.localeSubject.pipe(first(), map(function (locale) {
            _this.updateAcceptableLocaleIds(uniq([dataConstants.LOCALE_ID, me.localeId, locale.localeId, dataConstants.LANG_DEFAULT]));
            var myLocaleId = me.localeId || locale.localeId || dataConstants.LANG_DEFAULT;
            if (currentLocaleId === myLocaleId) {
                return;
            }
            // A potential problem here is that if the server is not able to serve the user's preferred locale ID
            // an infinite reloading may happen.
            _this.cookieService.set(COOKIE_NAME_LOCALE_ID, myLocaleId, 60, '/');
            _this.refresh();
        })).subscribe();
    };
    I18nService.prototype.refresh = function () {
        this.loggerService.info('[i18n service] redirecting.');
        this.localStorage.setItem(LOCAL_STORAGE_KEY_I18N_RELOADED, 'true');
        this.locationService.refresh();
    };
    I18nService.prototype.updateAcceptableLocaleIds = function (localeIds) {
        dataConstants.ACCEPTABLE_LOCALE_IDS = uniq(localeIds);
        this.acceptableLocaleIdsSubject.next(localeIds);
    };
    I18nService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function I18nService_Factory() { return new I18nService(i0.ɵɵinject(i0.LOCALE_ID), i0.ɵɵinject(i1.LOCAL_STORAGE), i0.ɵɵinject(i2.CookieService), i0.ɵɵinject(i3.LoggerService), i0.ɵɵinject(i4.LocationService), i0.ɵɵinject(i5.LocaleService)); }, token: I18nService, providedIn: "root" });
    return I18nService;
}());
export { I18nService };
